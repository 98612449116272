import React from 'react';
import HeaderTable from './HeaderTable';
import BodyTable from './BodyTable';
import { Container, TypeHeader } from './styles';
import TypeFrom from './TypeFrom';
import { CROSS, TIMES } from 'utils/constants/icons';
import { CloseButtonWrapper } from 'shared/styles/constants/tagsStyles';

const PermissionsTable = ({
  rolValues,
  selectedRol,
  setRolValues,
  handleCheckboxClick,
  handleOnClick,
  handleDelete,
  handleClickShowFrom,
  showFrom,
  setShowForm,
  selectedData,
  readPermission,
  updatePermission,
  deletePermission,
  createPermission
}) => {
  return (
    <Container>
      <div className="wrapper d-flex">
        <div className={'w-50'}>
          <table className="table table-sm">
            <thead>
              <HeaderTable
                selectedRol={selectedRol}
                handleOnClick={handleOnClick}
                createPermission={createPermission}
              />
            </thead>
            <tbody>
              <BodyTable
                handleOnClick={handleOnClick}
                handleCheckboxClick={handleCheckboxClick}
                rolValues={rolValues}
                selectedRol={selectedRol}
                setRolValues={setRolValues}
                handleDelete={handleDelete}
                handleClickShowFrom={handleClickShowFrom}
                updatePermission={updatePermission}
                deletePermission={deletePermission}
                createPermission={createPermission}
              />
            </tbody>
          </table>
        </div>
        <div className={'w-50 border ms-2 p-2 mb-3'}>
          <TypeFrom
            values={selectedData}
            showFrom={showFrom}
            setShowForm={setShowForm}
          />
        </div>
      </div>
    </Container>
  );
};

export default PermissionsTable;
