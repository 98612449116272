import React, { Fragment, useState, useEffect, useContext } from 'react';
import { CloseButtonWrapper, ErrorMessage, TitleSection, WarningMessage } from 'shared/styles/constants/tagsStyles';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { TIMES, FLOPPY } from 'utils/constants/icons';
import {
  CLIENT_SERVICE_CONTRACT,
  WEB_DELETE_PERMISSION,
  CLIENT_FUNDER_CONTACT,
  CLIENT_FUNDER,
  CLIENT_SERVICE_VISITS,
  CLIENT_SERVICE,
  CLIENT_SERVICE_ADDRESS,
  CLIENT_FUNDER_BILL_RATE_CARD,
  CLIENT_FUNDER_PAY_RATE_CARD,
  WEB_CREATE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION,
} from '../../../../utils/constants/permissions';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'context';
import AddMedicationDose from './AddMedicationDose';
import AddNewDose from './AddNewDose';
import { FaPlus } from 'react-icons/fa';
import AddMedicationReview from './AddMedicationReview';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { useRef } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { Toast } from 'primereact/toast';
import AddBodyParts from './AddBodyParts';
import ModalSignature from './ModalSignature';
import { useModal } from 'hooks/ModalHook';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import Spinner from 'components/SharedComponents/Spinner';

const ModalServiceClient = ({
  dropDownHandleSelect,
  values,
  setValues,
  useInput,

  choices,
  idSelectedMed,
  clientId,
  showError,
  errorMessage,
  setErrorSplitPercentage,
  errorSplitPercentage,
  errors,
  loadingFinish,
  loadingSave,
  textStatus,
  buttonIcon,
  addressValues,
  clientFullName,
  setDateError,
  dateError,
  setTimeError,
  timeError,
  filterAdmin,
  filterSuperAdmin,
  contextChoicesMap,
  contextSubscription,
  rightPermission,
  userClient,
  updateServicePermissions,
  selectedClientId,
  editMode,
  setEditMode,
  isValid,
  allErrors,
  setCheckRequires,
  promisesWithErrors,
  setShowError,
  clientErrors,
  setErrorMessage,
  DetailsNotCreated,
  InvalidOrEmptyValues,
  setSavedContract,
  checkBeforeSend,
  earlieErrors,
  setShowEarlieError,
  setEarlieMessage,
  earlieMessage,
  setEarlieError,
  showEarlieError,
  loadingSaveMedicationPres,
  setModalConfirmValue,
  modalConfirmValue,
  fullBindBulkAlert,
  loading
}) => {
  const toast = useRef(null)

  const [isBackMode_, setIsBackMode_] = useState(false)
  const sameClientValue = values.service_address
    ? values.service_address.same_as_client
    : false;
  const uploadFileTeam = useRef(null);
  const control_drug_hook = useInput(`is_controlled_drug`)
  const [toDeleteVisits] = useState([]);
  const [toDeleteReview] = useState([]);
  const [toDeleteVisitSkill, setToDeleteVisitSkill] = useState([]);
  const [toDeleteVisitEmployee, setToDeleteVisitEmployee] = useState([]);
  const [toDeleteSplitFunder] = useState([]);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const [showDeleteVisitsModal, setShowDeleteVisitsModal] = useState(false);
  const [UploadTeamFile, setUploadTeamFile] = useState([]);
  const [toggleUploadFile, setToggleUploadFile] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [disabledValue, setDisabledValue] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [fileSizeEror, setFileSizeEror] = useState('');
  const [erorSize, setErorSize] = useState(false);

  const [openSignature, setOpenSignature] = useState(false);
  const sigRef = useRef();
  const [signature, setSignature] = useState(null);
  const [signatureItem, setSignatureItem] = useState({});
  const {
    setValue: setSignModalValue,
    value: signModalValue,
    // setBulkAlertTitle,
    fullBind: fullBindSign,
  } = useModal(false);

  // console.log(values)
  const [
    clientServicesFunderContactCreate,
    setClientServicesFunderContactCreate,
  ] = useState(false);
  const [
    clientServicesFunderContactUpdate,
    setClientServicesFunderContactUpdate,
  ] = useState(false);
  const [clientServicesFunderContactRead, setClientServicesFunderContactRead] =
    useState(false);
  const [clientServicesFunderCreate, setClientServicesFunderCreate] =
    useState(false);
  const [clientServicesFunderUpdate, setClientServicesFunderUpdate] =
    useState(false);
  const [clientServicesFunderRead, setClientServicesFunderRead] =
    useState(false);
  const [clientServicesServiceVisitsRead, setClientServicesServiceVisitsRead] =
    useState(false);
  const [
    clientServicesServiceVisitsCreate,
    setClientServicesServiceVisitsCreate,
  ] = useState(false);
  const [
    clientServicesServiceVisitsUpdate,
    setClientServicesServiceVisitsUpdate,
  ] = useState(false);
  const [clientServicesServiceRead, setClientServicesServiceRead] =
    useState(false);
  const [clientServicesServiceCreate, setClientServicesServiceCreate] =
    useState(false);
  const [clientServicesServiceUpdate, setClientServicesServiceUpdate] =
    useState(false);
  const [
    clientServicesServiceAddressRead,
    setClientServicesServiceAddressRead,
  ] = useState(false);
  const [
    clientServicesServiceAddressCreate,
    setClientServicesServiceAddressCreate,
  ] = useState(false);
  const [
    clientServicesServiceAddressUpdate,
    setClientServicesServiceAddressUpdate,
  ] = useState(false);
  const [
    clientServicesFunderBillRateCardRead,
    setClientServicesFunderBillRateCardRead,
  ] = useState(false);
  const [
    clientServicesFunderBillRateCardCreate,
    setClientServicesFunderBillRateCardCreate,
  ] = useState(false);
  const [
    clientServicesFunderBillRateCardUpdate,
    setClientServicesFunderBillRateCardUpdate,
  ] = useState(false);
  const [
    clientServicesFunderPayRateCardRead,
    setClientServicesFunderPayRateCardRead,
  ] = useState(false);
  const [
    clientServicesFunderPayRateCardCreate,
    setClientServicesFunderPayRateCardCreate,
  ] = useState(false);
  const [
    clientServicesFunderPayRateCardUpdate,
    setClientServicesFunderPayRateCardUpdate,
  ] = useState(false);

  // service contract inner section below
  const [subSectionServiceContractCreate, setSubSectionServiceContractCreate] =
    useState(false);
  const [subSectionServiceUpdate, setSubSectionServiceContractUpdate] =
    useState(false);
  const [subSectionServiceContractDelete, setSubSectionServiceContractDelete] =
    useState(false);
  const [subSectionServiceContractRead, setSubSectionServiceContractRead] =
    useState(false);

  const history = useHistory();
  const {
    contextPermissions,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
  } = useContext(AppContext);



  const prn_dose_id_hook = useInput(`prn_dose_id`, 'isRequired')


  useEffect(() => {
    // setEditMode(false)
    if (filterSuperAdmin) {
      setClientServicesFunderContactCreate(true);
      setClientServicesFunderContactUpdate(true);
      setClientServicesFunderContactRead(true);
      setClientServicesFunderCreate(true);
      setClientServicesFunderUpdate(true);
      setClientServicesFunderRead(true);
      setClientServicesServiceVisitsRead(true);
      setClientServicesServiceVisitsUpdate(true);
      setClientServicesServiceVisitsCreate(true);
      setClientServicesServiceRead(true);
      setClientServicesServiceCreate(true);
      setClientServicesServiceUpdate(true);
      setClientServicesServiceAddressRead(true);
      setClientServicesServiceAddressUpdate(true);
      setClientServicesServiceAddressCreate(true);
      setClientServicesFunderBillRateCardRead(true);
      setClientServicesFunderBillRateCardUpdate(true);
      setClientServicesFunderBillRateCardCreate(true);
      setClientServicesFunderPayRateCardRead(true);
      setClientServicesFunderPayRateCardUpdate(true);
      setClientServicesFunderPayRateCardCreate(true);
    } else if (filterAdmin) {
      setClientServicesFunderContactCreate(true);
      setClientServicesFunderContactUpdate(true);
      setClientServicesFunderContactRead(
        contextSubscription?.[CLIENT_FUNDER_CONTACT]?.[WEB_PERMISSION]
      );
      setClientServicesFunderCreate(true);
      setClientServicesFunderUpdate(true);
      setClientServicesFunderRead(
        contextSubscription?.[CLIENT_FUNDER]?.[WEB_PERMISSION]
      );
      setClientServicesServiceVisitsRead(
        contextSubscription?.[CLIENT_SERVICE_VISITS]?.[WEB_PERMISSION]
      );
      setClientServicesServiceVisitsUpdate(true);
      setClientServicesServiceVisitsCreate(true);
      setClientServicesServiceRead(
        contextSubscription?.[CLIENT_SERVICE]?.[WEB_PERMISSION]
      );
      setClientServicesServiceCreate(true);
      setClientServicesServiceUpdate(true);
      setClientServicesServiceAddressRead(
        contextSubscription?.[CLIENT_SERVICE_ADDRESS]?.[WEB_PERMISSION]
      );
      setClientServicesServiceAddressUpdate(true);
      setClientServicesServiceAddressCreate(true);
      setClientServicesFunderBillRateCardRead(
        contextSubscription?.[CLIENT_FUNDER_BILL_RATE_CARD]?.[WEB_PERMISSION]
      );
      setClientServicesFunderBillRateCardUpdate(true);
      setClientServicesFunderBillRateCardCreate(true);
      setClientServicesFunderPayRateCardRead(
        contextSubscription?.[CLIENT_FUNDER_PAY_RATE_CARD]?.[WEB_PERMISSION]
      );
      setClientServicesFunderPayRateCardUpdate(true);
      setClientServicesFunderPayRateCardCreate(true);
    } else {
      if (rightPermission[CLIENT_FUNDER_CONTACT] !== undefined) {
        setClientServicesFunderContactCreate(
          rightPermission[CLIENT_FUNDER_CONTACT][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_FUNDER_CONTACT][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
        setClientServicesFunderContactUpdate(
          rightPermission[CLIENT_FUNDER_CONTACT][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_FUNDER_CONTACT][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
        setClientServicesFunderContactRead(
          contextSubscription?.[CLIENT_FUNDER_CONTACT]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_FUNDER_CONTACT][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rightPermission[CLIENT_FUNDER_CONTACT][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] &&
              userClient == selectedClientId))
        );
      }

      if (rightPermission[CLIENT_FUNDER] !== undefined) {
        setClientServicesFunderCreate(
          rightPermission[CLIENT_FUNDER][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_FUNDER][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
        setClientServicesFunderUpdate(
          rightPermission[CLIENT_FUNDER][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_FUNDER][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
        setClientServicesFunderRead(
          contextSubscription?.[CLIENT_FUNDER]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_FUNDER][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rightPermission[CLIENT_FUNDER][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] &&
              userClient == selectedClientId))
        );
      }

      if (rightPermission[CLIENT_SERVICE_VISITS] !== undefined) {
        setClientServicesServiceVisitsRead(
          contextSubscription?.[CLIENT_SERVICE_VISITS]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_SERVICE_VISITS][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rightPermission[CLIENT_SERVICE_VISITS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] &&
              userClient == selectedClientId))
        );
        setClientServicesServiceVisitsUpdate(
          rightPermission[CLIENT_SERVICE_VISITS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_SERVICE_VISITS][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
        setClientServicesServiceVisitsCreate(
          rightPermission[CLIENT_SERVICE_VISITS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_SERVICE_VISITS][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
      }
      if (rightPermission[CLIENT_SERVICE]) {
        setClientServicesServiceRead(
          contextSubscription?.[CLIENT_SERVICE]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_SERVICE][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rightPermission[CLIENT_SERVICE][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] &&
              userClient == selectedClientId))
        );
        setClientServicesServiceCreate(
          rightPermission[CLIENT_SERVICE][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_SERVICE][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
        setClientServicesServiceUpdate(
          rightPermission[CLIENT_SERVICE][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_SERVICE][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
      }
      if (rightPermission[CLIENT_SERVICE_ADDRESS] !== undefined) {
        setClientServicesServiceAddressRead(
          contextSubscription?.[CLIENT_SERVICE_ADDRESS]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_SERVICE_ADDRESS][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rightPermission[CLIENT_SERVICE_ADDRESS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] &&
              userClient == selectedClientId))
        );
        setClientServicesServiceAddressUpdate(
          rightPermission[CLIENT_SERVICE_ADDRESS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_SERVICE_ADDRESS][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
        setClientServicesServiceAddressCreate(
          rightPermission[CLIENT_SERVICE_ADDRESS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_SERVICE_ADDRESS][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
      }
      if (rightPermission[CLIENT_FUNDER_BILL_RATE_CARD] !== undefined) {
        setClientServicesFunderBillRateCardRead(
          contextSubscription?.[CLIENT_FUNDER_BILL_RATE_CARD]?.[
          WEB_PERMISSION
          ] &&
          (rightPermission[CLIENT_FUNDER_BILL_RATE_CARD][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rightPermission[CLIENT_FUNDER_BILL_RATE_CARD][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] &&
              userClient == selectedClientId))
        );
        setClientServicesFunderBillRateCardUpdate(
          rightPermission[CLIENT_FUNDER_BILL_RATE_CARD][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_FUNDER_BILL_RATE_CARD][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
        setClientServicesFunderBillRateCardCreate(
          rightPermission[CLIENT_FUNDER_BILL_RATE_CARD][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_FUNDER_BILL_RATE_CARD][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
      }
      if (rightPermission[CLIENT_FUNDER_PAY_RATE_CARD] !== undefined) {
        setClientServicesFunderPayRateCardRead(
          contextSubscription?.[CLIENT_FUNDER_PAY_RATE_CARD]?.[
          WEB_PERMISSION
          ] &&
          (rightPermission[CLIENT_FUNDER_PAY_RATE_CARD][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rightPermission[CLIENT_FUNDER_PAY_RATE_CARD][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] &&
              userClient == selectedClientId))
        );
        setClientServicesFunderPayRateCardUpdate(
          rightPermission[CLIENT_FUNDER_PAY_RATE_CARD][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_FUNDER_PAY_RATE_CARD][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
        setClientServicesFunderPayRateCardCreate(
          rightPermission[CLIENT_FUNDER_PAY_RATE_CARD][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_FUNDER_PAY_RATE_CARD][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ] &&
            userClient == selectedClientId)
        );
      }
    }
  }, [filterAdmin, filterSuperAdmin, rightPermission, contextSubscription]);



  useEffect(() => {
    // getPayRates().then(res => {
    //   setBillRate(
    //     res.filter(data => data.rate_card_type == 'Bill Rates') || []
    //   );
    //   setPayRate(res.filter(data => data.rate_card_type == 'Pay Rates') || []);
    //   setTravelRate(res.filter(data => data.rate_card_type == 'Travel Rates') || []);
    // });

    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
        // }
      );
    }
    const roles = contextSubSectionPermissions;
    if (roles.length > 0) {
      roles.forEach(
        item => {
          // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
        // }
      );
    }
    if (filterAdmin == true || filterSuperAdmin == true) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);
      setSubSectionServiceContractCreate(true);
      setSubSectionServiceContractRead(true);
      setSubSectionServiceContractUpdate(true);
      setSubSectionServiceContractDelete(true);
    } else {
      setCreatePermissions(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
        WEB_CREATE_PERMISSION
        ]
      );
      setDeletePermissions(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
        WEB_DELETE_PERMISSION
        ]
      );
      setUpdatePermissions(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
        WEB_UPDATE_PERMISSION
        ]
      );

      setReadPermissions(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
        WEB_READ_PERMISSION
        ]
      );

      // inner section  below
      setSubSectionServiceContractCreate(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
        WEB_CREATE_PERMISSION
        ]
      );

      setSubSectionServiceContractRead(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
        WEB_READ_PERMISSION
        ]
      );
      setSubSectionServiceContractUpdate(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
        WEB_UPDATE_PERMISSION
        ]
      );

      setSubSectionServiceContractDelete(
        rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
        WEB_DELETE_PERMISSION
        ]
      );
    }
  }, []);

  useEffect(() => {
    if (values.id) {
      if (values.upload_file?.length > 0) {
        let uploadfiledata = values.upload_file.map((fileupload) => {
          return { id: fileupload.id ? fileupload.id : '', name: fileupload.name, file: fileupload.file }
        })
        setUploadedFiles(uploadfiledata);
      } else {
        setUploadedFiles([]);
      }
    }

  }, [
    values,
    values.upload_file,
    values.id,
  ])

  const addNewVisit = () => {
    if (!values.dose_list) {
      values.dose_list = [];
    }
    values.dose_list.push({});
    setValues({ ...values });
  };

  const addNewReview = () => {
    if (!values.review_data) {
      values.review_data = [];
    }
    values.review_data.push({});
    setValues({ ...values });
  };


  const deleteVisit = (dataVisit, indexVisit) => {
    toDeleteVisits.push({ ...dataVisit });

    const possibleError = `dose_list[${indexVisit}]`;

    for (var i = errors.length; i--;) {
      if (errors[i].includes(possibleError)) {
        errors.splice(i, 1);
      }
    }

    values.dose_list.splice(indexVisit, 1);

    setValues({ ...values });
  };

  const deleteReview = (dataVisit, indexVisit) => {
    toDeleteReview.push({ ...dataVisit });

    const possibleError = `review_data[${indexVisit}]`;

    for (var i = errors.length; i--;) {
      if (errors[i].includes(possibleError)) {
        errors.splice(i, 1);
      }
    }

    values.review_data.splice(indexVisit, 1);

    setValues({ ...values });
  }

  const addDoseCopy = index => {
    let length = values.dose_list.length;
    const array1 = values.dose_list.slice(0, index + 1);
    const array2 = values.dose_list.slice(index + 1, length + 1);
    array1.push({ id: "" });
    let arr1Length = array1.length;
    array1[arr1Length - 1] = _.cloneDeep(values.dose_list[index]);
    delete array1[arr1Length - 1].id;
    values.dose_list = array1.concat(array2);
    setValues({ ...values });
  };

  const showAllService = () => {
    setShowDeleteVisitsModal(true);
  };
  const hideAllService = () => {
    setShowDeleteVisitsModal(false);
  };


  const handleUploadFiles = (files, keyName) => {
    const uploaded = [...uploadedFiles];
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    })
    const result = _.merge(values, { [keyName]: uploaded });
    setValues({ ...result });
    setUploadedFiles(uploaded)
  }

  const handleChange = async (event, keyName) => {

    const chosenFiles = Array.prototype.slice.call(event.target.files)
    handleUploadFiles(chosenFiles, keyName);


    // const fileUploaded = event.target.files[0];
    // const fileResult = await pickFile(event)
    // const result = _.merge(values, { [key]: { [keyName]: fileResult.file } });
    // setValues({ ...result });
    // if (key === 'upload_file') {
    //     setUploadTeamFile([...UploadTeamFile,fileUploaded.name])
    //     setToggleUploadFile(true)
    // }
  }

  const handleUpload = ref => {
    ref.current.click();
  };

  const deletePic = (item, index) => {
    values.upload_file = [];
    let newUploadedFiles = uploadedFiles;
    newUploadedFiles.splice(index, 1);
    values.upload_file = newUploadedFiles;
    setValues({ ...values });
    setUploadedFiles(newUploadedFiles)
  }

  const openInNewTab = (url) => {

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }


  useEffect(() => {
    values.signature = signature
    setValues({ ...values })
  }, [signature])

  return (
    <>
      {loading ? (
        <div style={{ marginTop: '345px' }}>
          <Spinner />
        </div>
      ) : (
        <div max_width={'92%'} style={{ background: '#fff' }}>
          <Toast ref={toast} />
          <div className="p-2 p-sm-3 p-md-4">
            <div className='mb-3'>
              <span className={'pb-0 pe-2 mt-1'} htmlFor="exemption-proof">Upload Prescription</span>
              <input type="file"
                style={{ display: 'none' }}
                ref={uploadFileTeam}
                onChange={(e) => handleChange(e, 'upload_file')}
              />
              <PrimaryButtonForm
                minWidth="2rem"
                onClick={() => handleUpload(uploadFileTeam)}
                className={'me-2'}
              >
                <span className={''}>{'Choose File'}</span>

              </PrimaryButtonForm>
              <div className="uploaded-files-list">
                {uploadedFiles.length > 0 && uploadedFiles?.map((file, index) => {
                  return (

                    <div key={index} className='d-flex'>
                      <div style={{ cursor: 'pointer' }}>
                        <CloseButtonWrapper
                          onClick={() => {
                            deletePic(file, index);
                          }}
                        >
                          {TIMES}
                        </CloseButtonWrapper>
                      </div>
                      <div onClick={() => openInNewTab(file.file)} style={{ cursor: 'pointer', color: "#696969", fontStyle: "italic" }}><small>{file.name}</small> </div>
                    </div>
                  )
                }
                )}
              </div>

            </div>
            <div>
            </div>
            <AddMedicationDose
              values={values}
              useInput={useInput}
              setValues={setValues}
              editMode={editMode}
              choices={choices}
              dropDownHandleSelect={dropDownHandleSelect}
              contextChoicesMap={contextChoicesMap}
              idSelectedMed={idSelectedMed}
            />
            {values.id &&
              <>
                <div className=''>
                  <ToggleButton
                    label={'Is Controlled Drug'}
                    id="checkbox_drug"
                    useInput={control_drug_hook}
                  />
                </div>
                <div className={'mb-3'}>
                  {(values.is_controlled_drug && values.review_data && (values.review_data.length < 2)) &&
                    <WarningMessage>
                      <label className={'mt-1'}>Please add more than one review</label>
                    </WarningMessage>
                  }
                </div>
              </>
            }
            {/* Added Signaturn */}
            <div className={'mt-3'}>
              <div className={'mb-2'}>
                <TitleSection>Add Signature</TitleSection>
              </div>
              <div className="d-flex align-items-center" >
                {
                  values.signature &&
                  <img
                    style={{ background: '#eef1f38c' }}
                    height={100} width={200}
                    src={values.signature} alt='sign' />
                }
                {values.signature ?
                  <CloseButtonWrapper
                    style={{ height: '40px' }}
                    onClick={() => {
                      setSignature(null)
                    }}>
                    {TIMES}
                  </CloseButtonWrapper> :
                  <PrimaryButtonForm minWidth="6rem" onClick={() => {
                    setOpenSignature(true);
                    setSignModalValue(true)
                  }}>
                    <FaPlus />
                  </PrimaryButtonForm>
                  // <PrimaryButtonForm
                  //   style={{ height: '40px' }}
                  //   onClick={() => {
                  //     setOpenSignature(true);
                  //     setSignModalValue(true)
                  //   }}
                  // >Signature</PrimaryButtonForm>
                }
              </div>
            </div>
            {
              !(values.medication_type_name === 'PRN') ?
                <div className={'mt-3'}>
                  <div className={'mb-2'}>
                    <TitleSection>Time Of Doses</TitleSection>
                  </div>
                  {values.dose_list && values.dose_list.length >= 1 && (
                    values.dose_list.map((dataVisit, indexVisit) => {
                      return (
                        <AddNewDose
                          editMode={editMode}
                          addNewVisit={addNewVisit}
                          deleteVisit={deleteVisit}
                          useInput={useInput}
                          nameSection={'dose_list'}
                          choices={choices}
                          dataVisit={dataVisit}
                          values={values}
                          setValues={setValues}
                          clientId={clientId}
                          indexVisit={indexVisit}
                          dropDownHandleSelect={dropDownHandleSelect}
                          addDoseCopy={addDoseCopy}
                        />
                      )
                    })
                  )}
                  <div className={'mt-2'}>
                    <PrimaryButtonForm minWidth="6rem" onClick={addNewVisit}>
                      <FaPlus />
                    </PrimaryButtonForm>
                  </div>
                </div>
                :
                <div className="w-100 pe-1 mt-2">
                  <DropdownSearchable
                    required={true}
                    disabled={!editMode}
                    placeHolder={'Medication Dose'}
                    {...prn_dose_id_hook}
                    options={choices.medication_dose || []}
                    selectedOption={
                      values.prn_dose_id
                        ? values.prn_dose_id
                        : null
                    }
                    onChange={value =>
                      dropDownHandleSelect(
                        { prn_dose_id: value ? value : null },
                        'prn_dose_id'
                      )
                    }
                  />
                </div>
            }
            {/* add body part */}
            <AddBodyParts
              values={values}
              setValues={setValues}
              useInput={useInput}
              toast={toast}
              isBackMode_={isBackMode_}
              setIsBackMode_={setIsBackMode_}
            />


            <div className={'mt-3'}>
              <div className={'mb-2'}>
                <TitleSection>Review</TitleSection>
              </div>
              {values.review_data && values.review_data.map((item, index) => {
                return (
                  <>
                    <AddMedicationReview
                      values={values}
                      useInput={useInput}
                      setValues={setValues}
                      editMode={editMode}
                      choices={choices}
                      contextChoicesMap={contextChoicesMap}
                      itemData={item}
                      indexReview={index}
                      nameSection={'review_data'}
                      deleteReview={deleteReview}
                    />
                  </>
                )
              })}
              < div className={'mt-2'}>
                <PrimaryButtonForm minWidth="6rem" onClick={addNewReview}>
                  <FaPlus />
                </PrimaryButtonForm>
              </div>
            </div>
            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {showError ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>

              <div
                className={'d-flex justify-content-center justify-content-md-end'}>
                <div className={'ms-0 ms-md-3 me-2'}>
                  <PrimaryButtonForm
                    disabled={false}
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    className={'me-2'}
                    onClick={() => {
                      // setModalValue()
                      history.push('/client');
                    }}>
                    Cancel
                  </PrimaryButtonForm>
                  <PrimaryButtonForm
                    disabled={loadingSaveMedicationPres}
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    onClick={() => { idSelectedMed ? setModalConfirmValue(true) : checkBeforeSend() }}>
                    <span className={'me-2'}>{loadingSaveMedicationPres ? "Saving" : 'Save'}</span>
                    {!loadingSaveMedicationPres ? (
                      FLOPPY
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          {/* {modalConfirmValue && (
        <ConfirmationModal
          type={'warning'}
          title={'CONFIRM MEIDCATION PRESCRIPTION DATA'}
          subTitle={'This action will update all medication data from effective date mentioned below. Do you confirm for the changes?'}
          okTitle={'NO, CANCEL'}
          onCancelTitle={'YES, PROCEED'}
          icon={ERROR_OUTLINE}
          setModalValue={setModalConfirmValue}
          fullBind={fullBindBulkAlert}
          values={values}
          toDeleteVisits={toDeleteVisits}
          toDeleteSplitFunder={toDeleteSplitFunder}
          toDeleteVisitSkill={toDeleteVisitSkill}
          toDeleteVisitEmployee={toDeleteVisitEmployee}
          checkBeforeSend={checkBeforeSend}
          // onOk={() => {
          //   setShowDeleteVisitsModal(false);
          // }}
          // showModal={setShowDeleteVisitsModal}
        />
      )} */}

          {signModalValue &&
            <ModalSignature
              fullBind={fullBindSign}
              openSignature={openSignature}
              setOpenSignature={setOpenSignature}
              setSignature={setSignature}
              sigRef={sigRef}
              setSignModalValue={setSignModalValue}
            />
          }


        </div>
      )}
    </>
  );
};

ModalServiceClient.propTypes = {
  idSelectedMed: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  dropDownHandleVisits: PropTypes.func.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  checkBeforeSend: PropTypes.func.isRequired,
  showError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setErrorSplitPercentage: PropTypes.func.isRequired,
  errorSplitPercentage: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  loadingFinish: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  textStatus: PropTypes.string.isRequired,
  addressValues: PropTypes.object.isRequired,
};

export default ModalServiceClient;