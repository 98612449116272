import React from 'react'
import { ModalContainer } from '../styles';
import { MessageOptaPlanner } from 'components/DashboardComponents/Scheduler/styles';
import { useState } from 'react';
import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';
import { Fragment } from 'react';
import DetailsList from './DetailsList';
import AssignBulk from 'components/DashboardComponents/Scheduler/Modals/AssignBulk';
import { useModal } from 'hooks/ModalHook';
import { bulkDeleteRegularVisits, deleteShiftBulk, UnassignAllVisits } from 'utils/api/SchedulerApi';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import SetEndDatePopup from './SetEndDatePopup';
import ShiftDetailsList from './ShiftDetailsList';

function ActiveVisitsAndShiftsLists({
    type,
    icon,
    fullBind,
    VisitDetails,
    toast,
    choices,
    shouldUpdateEmployeeStatus
}) {

    const {
        setValue: setAssignVisits,
        value: assignVisits,
        fullBind: fullBindAssignVisit,
    } = useModal(false);

    const {
        setValue: setShiftEndDate,
        value: shiftEndDate,
        setTitle: setShiftEndDateTitle,
        fullBind: fullBindShiftEndDate,
    } = useModal(false);

    const {
        setValue: setModalUnassignValue,
        bind: bindModalUnassign
    } = useModal(false);

    const {
        setValue: setModalDeleteShift,
        bind: bindModalDeleteShift
    } = useModal(false);

    const {
        setValue: setModalDeleteRegularVisit,
        bind: bindModalDeleteRegularVisit
    } = useModal(false);


    const REGULAR_SHIFTS = "regularShifts"
    const REGULAR_VISITS = "regularVisits"
    const SHIFTS = "shifts"
    const VISITS = "visits"




    // const { regularShifts, regularVisits, shifts, visits } = VisitDetails || {};

    const [expandedSections, setExpandedSections] = useState({ regularShifts: false, regularVisits: false, shifts: false, visits: false })
    const [checkedRows, setCheckedRows] = useState({ regularShifts: [], regularVisits: [], shifts: [], visits: [] })
    const [isAllSelected, setIsAllSelected] = useState({ regularShifts: false, regularVisits: false, shifts: false, visits: false })


    const handleAccordionClick = (type) => {
        setExpandedSections(prev =>
            ({ ...prev, [type]: !expandedSections[type] }))
    }

    const handleAllSelect = (checked, itemName) => {
        // const { checked } = e.target;
        setIsAllSelected({ ...isAllSelected, [itemName]: checked })
        if (checked) {
            if (itemName == REGULAR_VISITS) {
                let allIds = VisitDetails[itemName].map(item => (
                    { id: item.id, pinned_employee_id: item.pinned_employee_id }
                ))
                setCheckedRows({ ...checkedRows, [itemName]: allIds })
            } else {
                let allIds = VisitDetails[itemName].map(item => item.id)
                setCheckedRows({ ...checkedRows, [itemName]: allIds })
            }
        } else {
            setCheckedRows({ ...checkedRows, [itemName]: [] })
        }
    }

    const handleSelect = (e, rowId, itemName) => {
        const { checked } = e.target;
        let allChecked = false
        // setIsAllSelected(checkedRows.length === data.length)
        if (checked) {
            if (itemName == REGULAR_VISITS) {
                setCheckedRows({ ...checkedRows, [itemName]: [...checkedRows[itemName], { id: rowId.id, pinned_employee_id: rowId.pinned_employee_id }] })
                allChecked = [...checkedRows[itemName], rowId].length === VisitDetails[itemName].length
            } else {
                setCheckedRows({ ...checkedRows, [itemName]: [...checkedRows[itemName], rowId] })
                allChecked = [...checkedRows[itemName], rowId].length === VisitDetails[itemName].length
            }
        } else {
            if (itemName == REGULAR_VISITS) {
                setCheckedRows({
                    ...checkedRows, [itemName]: checkedRows[itemName]
                        .filter(item => (
                            (item.id !== rowId.id) || (item.pinned_employee_id !== rowId.pinned_employee_id))
                        )
                })
                allChecked = false
            } else {
                setCheckedRows({ ...checkedRows, [itemName]: checkedRows[itemName].filter(item => item !== rowId) })
                allChecked = false
            }
        }
        setIsAllSelected(allChecked)
    }

    const handleAllSelectShift = (checked, itemName, key) => {
        if (checked) {
            if (key == 'parent') {
                let shifts = Object.keys(VisitDetails[itemName])
                let checked = []
                shifts.length && shifts.forEach(shift => {
                    let a = VisitDetails[itemName][shift].length && VisitDetails[itemName][shift]?.forEach((item) => {
                        checked.push(item.id)
                    })
                })
                const uniqueArray = [...new Set(checked)];
                setCheckedRows({ ...checkedRows, [itemName]: [...uniqueArray] })
            } else {
                let checked = []
                let a = VisitDetails[itemName][key].length && VisitDetails[itemName][key]?.forEach((item) => {
                    checked.push(item.id)
                })

                const uniqueArray = [...new Set(checked)];
                setCheckedRows({ ...checkedRows, [itemName]: [...checkedRows[itemName], ...uniqueArray] })
            }
        } else {
            if (key == 'parent') {
                setCheckedRows({ ...checkedRows, [itemName]: [] })
            } else {
                setCheckedRows((row) => (
                    {
                        ...row,
                        [itemName]: checkedRows[itemName].filter(item =>
                            !VisitDetails[itemName][key].some(s => s.id === item))
                    }))

            }
        }
    }


    const actionItems = {
        // Regular Shift items
        regularShifts: [{
            name: "Select All",
            onClick: function (itemName) {
                handleAllSelect(true, itemName)
            }
        },
        {
            name: "Clear Selection",
            onClick: function (itemName) {
                handleAllSelect(false, itemName)
            }

        },
        {
            name: "Set End Date",
            onClick: function (itemName) {
                validateSelectedVisits(itemName, "Set End Date")
            }
        }],


        // Regular Visit items
        regularVisits: [{
            name: "Select All",
            onClick: function (itemName) {
                handleAllSelect(true, itemName)
            }
        },
        {
            name: "Clear Selection",
            onClick: function (itemName) {
                handleAllSelect(false, itemName)
            }

        },
        {
            name: "Delete",
            onClick: function (itemName) {
                validateSelectedVisits(itemName, "Delete Regular Visit")
            }
        }],


        // Normal Shift items
        shifts: [{
            name: "Select All",
            onClick: function (itemName, key) {
                handleAllSelectShift(true, itemName, key)
            }
        },
        {
            name: "Clear Selection",
            onClick: function (itemName, key) {
                handleAllSelectShift(false, itemName, key)
            }

        },
        {
            name: "Delete",
            onClick: function (itemName, key) {
                validateSelectedVisits(itemName, "Delete Shift")
            }
        }],


        // Normal Visit items
        visits: [{
            name: "Select All",
            onClick: function (itemName) {
                handleAllSelect(true, itemName)
            }
        },
        {
            name: "Clear Selection",
            onClick: function (itemName) {
                handleAllSelect(false, itemName)
            }

        },
        {
            name: "Reassign",
            onClick: function (itemName) {
                validateSelectedVisits(itemName, "Reassign")
            }

        },
        {
            name: "Unassign",
            onClick: function (itemName) {
                validateSelectedVisits(itemName, "Unassign")
            }
        }]
    }

    const validateSelectedVisits = (itemName, action) => {
        if (checkedRows[itemName] && checkedRows[itemName].length) {
            if (action === "Reassign") {
                setAssignVisits(true);
            } else if (action === "Unassign") {
                setModalUnassignValue(true);
            } else if (action === "Set End Date") {
                setShiftEndDate(true);
            } else if (action === "Delete Shift") {
                setModalDeleteShift(true)
            } else if (action === "Delete Regular Visit") {
                setModalDeleteRegularVisit(true)
            }

        } else {
            const message = `No rows selected. Please select one or more rows to proceed.`
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `${message}`,
            });
        }
    }

    // Normal Visit fun
    const handleUnAssignVisits = async () => {
        try {
            let body = {
                visit_ids: checkedRows[VISITS].join(",")
            }
            let response = await UnassignAllVisits(body)
            if (response.status == true) {
                setModalUnassignValue(false)
                setCheckedRows({ ...checkedRows, [VISITS]: [] });
                shouldUpdateEmployeeStatus()
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${response.message}`,
                });
            }
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `${'Something went wrong!'}`,
            });
        }
    }


    // Normal Shift items
    const handleShiftDelete = async () => {
        try {
            const body = {
                employee_shift: checkedRows[SHIFTS]
            }
            const res = await deleteShiftBulk(body)
            if (res.status == true) {
                setModalDeleteShift(false)
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${res.message}`,
                });
                setCheckedRows(prev => ({ ...prev, [SHIFTS]: [] }))
                shouldUpdateEmployeeStatus()
            }
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `${'Something went wrong!'}`,
            });
        }
    }

    // Reguler visit bulk delete 
    const handleRegulerVisitBulkDelete = async () => {
        let groupedBy = {}
        if (checkedRows[REGULAR_VISITS].length)
            checkedRows[REGULAR_VISITS].forEach(row => {
                
                if (groupedBy[row.pinned_employee_id]) {
                    groupedBy[row.pinned_employee_id] = {
                        "client_pinned_employee_id": row.pinned_employee_id,
                        "client_pinned_employee_day_ids":
                            [...groupedBy[row.pinned_employee_id]['client_pinned_employee_day_ids'],
                            row.id]
                    }
                } else {
                    groupedBy[row.pinned_employee_id] = {
                        "client_pinned_employee_id": row.pinned_employee_id,
                        "client_pinned_employee_day_ids": [row.id]

                    }
                }
            })

        try {
            const body = {
                regularVisitsForDelete: Object.values(groupedBy)
            }
            const res = await bulkDeleteRegularVisits(body)
            if (res.status == "FAIL") {
                throw res.message
            }
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: `${res?.message}`,
            });
            setModalDeleteRegularVisit(false);
            setCheckedRows({ ...checkedRows, [REGULAR_VISITS]: [] });

        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `${error}`,
            });
            setModalDeleteRegularVisit(false);
        }
    }

    console.log({ VisitDetails, expandedSections, checkedRows, isAllSelected }, "VisitDetails")
    return (
        <div>
            <ModalContainer min_width={'60%'} max_width={'80%'}>
                <ModalNoInfo {...fullBind} loading={false} title={"Employee's active shifts and visits"}>
                    {/* <IconStyle type={type}>{icon}</IconStyle> */}
                    <MessageOptaPlanner className={'mt- mb-3'}>
                        <div style={{ marginTop: '20px' }}>
                            {VisitDetails[REGULAR_SHIFTS]?.length ? (
                                <DetailsList
                                    list={VisitDetails[REGULAR_SHIFTS]}
                                    handleAccordionClick={handleAccordionClick}
                                    expandedSections={expandedSections}
                                    checkedRows={checkedRows}
                                    setCheckedRows={setCheckedRows}
                                    handleSelect={handleSelect}
                                    setIsAllSelected={setIsAllSelected}
                                    itemName={REGULAR_SHIFTS}
                                    actionItems={actionItems[REGULAR_SHIFTS]}
                                    title={'Regular Shifts'}
                                />
                            ) : <Fragment />}

                            {VisitDetails[REGULAR_VISITS]?.length ? (
                                <DetailsList
                                    list={VisitDetails[REGULAR_VISITS]}
                                    handleAccordionClick={handleAccordionClick}
                                    expandedSections={expandedSections}
                                    checkedRows={checkedRows}
                                    setCheckedRows={setCheckedRows}
                                    handleSelect={handleSelect}
                                    setIsAllSelected={setIsAllSelected}
                                    itemName={REGULAR_VISITS}
                                    actionItems={actionItems[REGULAR_VISITS]}
                                    title={'Regular Visits'}
                                    isRegularVisit={true}
                                />) : <Fragment />}

                            {Object.keys(VisitDetails[SHIFTS] || {})?.length ? (
                                <ShiftDetailsList
                                    list={VisitDetails[SHIFTS]}
                                    handleAccordionClick={handleAccordionClick}
                                    expandedSections={expandedSections}
                                    checkedRows={checkedRows}
                                    setCheckedRows={setCheckedRows}
                                    handleSelect={handleSelect}
                                    setIsAllSelected={setIsAllSelected}
                                    itemName={SHIFTS}
                                    actionItems={actionItems[SHIFTS]}
                                    title={'Shifts'}
                                    specialType={true}
                                />) : <Fragment />}

                            {VisitDetails[VISITS]?.length ? (
                                <DetailsList
                                    list={VisitDetails[VISITS]}
                                    handleAccordionClick={handleAccordionClick}
                                    expandedSections={expandedSections}
                                    checkedRows={checkedRows}
                                    setCheckedRows={setCheckedRows}
                                    handleSelect={handleSelect}
                                    setIsAllSelected={setIsAllSelected}
                                    itemName={VISITS}
                                    actionItems={actionItems[VISITS]}
                                    title={'Visits'}
                                />) : <Fragment />}
                        </div>
                    </MessageOptaPlanner>
                </ModalNoInfo>
            </ModalContainer>

            {/* Normal Visit popup */}
            {assignVisits &&
                <AssignBulk
                    fullBind={fullBindAssignVisit}
                    employees={choices && choices?.employee_listing && choices?.employee_listing}
                    checkedRows={checkedRows[VISITS]}
                    choices={choices}
                    travelRate={[]}
                    closeAssignModal={() => { setAssignVisits(false); }}
                    searchScheduler={() => {
                        shouldUpdateEmployeeStatus()
                        setCheckedRows({ ...checkedRows, [VISITS]: [] });
                    }}
                    actionType={"Reassign"}
                    setAssignVisits={setAssignVisits}
                    toast={toast}
                />}

            {/* Regular Shift popup */}
            {shiftEndDate && (
                <div className='regular-shift-end-date'>
                    <SetEndDatePopup
                        itemName={REGULAR_SHIFTS}
                        shouldUpdateEmployeeStatus={shouldUpdateEmployeeStatus}
                        checkedRows={checkedRows[REGULAR_SHIFTS]}
                        fullBind={fullBindShiftEndDate}
                        setTitle={setShiftEndDateTitle}
                        setShiftEndDate={setShiftEndDate}
                        toast={toast}
                        setCheckedRows={setCheckedRows}
                    />
                </div>)}

            {/* Normal Visit popup */}
            <ConfirmDecision
                type="confirm"
                title="Confirm"
                body={'Are you sure you want to Unassign these visits ?'}
                onOk={() => {
                    handleUnAssignVisits()
                }}
                onNo={() => {
                    setModalUnassignValue(false)
                    setCheckedRows({ ...checkedRows, [VISITS]: [] });
                }}
                {...bindModalUnassign}
            />


            {/* Normal Shift popup */}
            <ConfirmDecision
                type="confirm"
                title="Confirm"
                body={'Are you sure you want to Delete these Shifts ?'}
                onOk={() => {
                    handleShiftDelete()
                }}
                onNo={() => {
                    setModalDeleteShift(false)
                    setCheckedRows({ ...checkedRows, [SHIFTS]: [] });
                }}
                {...bindModalDeleteShift}
            />


            {/* Regular Visits  popup */}
            <ConfirmDecision
                type="confirm"
                title="Confirm"
                body={'Are you sure you want to Delete these Regular Visits ?'}
                onOk={() => {
                    handleRegulerVisitBulkDelete()
                }}
                onNo={() => {
                    setModalDeleteRegularVisit(false)
                    setCheckedRows({ ...checkedRows, [REGULAR_VISITS]: [] });
                }}
                {...bindModalDeleteRegularVisit}
            />
        </div >
    )
}

export default ActiveVisitsAndShiftsLists