import React, { useState, useEffect, useRef, } from 'react';
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { deleteBranchAbsenceOverview, getBranchAbsenceOverViewById, getBranchAbsenceOverview, getCalendarRelatedEmployees, getEmployeeCalendarType, setBranchAbsenceOverview, setUpdateCalendarType, updateEmployeeCalendarType } from 'utils/api/SettingsApi';
import { Header, IconWrapper } from 'shared/styles/constants/tagsStyles';
import { Toast } from 'primereact/toast';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import AddAbsence from './AddModal/Index';
import { useModal } from 'hooks/ModalHook';
import { EX_LARGE_COL } from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import { Col, ColsGrouper, Row } from 'components/SharedComponents/Table/styles';
import { MdEdit } from 'react-icons/md';
import { branch_setting_absence_overview } from 'utils/choiceConstant';
import { AppContext } from 'context';
import { useContext } from 'react';
import queryString from 'query-string';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { RiDeleteBinLine } from 'react-icons/ri';
import _ from 'lodash';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles';
import { getPageToSearchParam } from 'shared/methods';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import './styles.css'
import UpdateEmployeesAbsence from './AddModal/UpdateEmployeesAbsence';

const headerColumns = [
  {
    label: 'Calendar Type',
    name: 'send_to',
    status: 0,
    className: 'sm-hidden',
    max_width: EX_LARGE_COL,
  },
  {
    label: 'Applicable From',
    name: 'send_by',
    status: 0,
    className: 'sm-hidden',
    max_width: EX_LARGE_COL,
  },
  {
    label: 'Applicable To',
    name: 'mail_subject',
    status: 0,
    className: 'sm-hidden',
    max_width: EX_LARGE_COL,
  }
];

const AbsenceOverview = ({
  branch,
  choices,
  // readOnly,
  readPermission,
  deletePermission,
  createPermisssion,
  updatePermission
}) => {
  const toast = useRef(null);
  const {
    setValue: setAddModalValue,
    value: addModalValue,
    setTitle: setModalTitle,
    fullBind: addFullBind,
  } = useModal(false);

  const {
    setValue: setAddEmployeeUpdateModal,
    value: addEmployeeUpdateModal,
    setTitle: setEmployeeUpdateModalTitle,
    fullBind: AddEmployeeUpdateFullBind,
  } = useModal(false);

  const {
    setValue: setModalDeleteValue,
    bind: bindModalDelete }
    = useModal(false);
  const {
    setValue: setModalWarningValue,
    bind: bindModalWarning }
    = useModal(false);

    const {
      setValue: setModalWarningValueEmployee,
      bind: bindModalWarningEmployee }
      = useModal(false);

  const {
    contextChoices,
    handleGetChoices
  } = useContext(AppContext);

  const NEW = 'new'
  const NAME_SPACE = 'employee_absence_overview'
  const { values, setValues, useInput, errors, setCheckRequires, setFormErrors, } = useForm({ limitperpage: PAGE_SIZE_RENDER });
  const [errorMessage, setErrorMessage] = useState('');
  const [disableSave, setDisableSave] = useState(false)
  const [loading, setLoading] = useState(true)
  const [listOfAbsence, setListOfAbsence] = useState([])
  const [textStatus, setTextStatus] = useState(SAVE);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [loadingSave, setLoadingSave] = useState(false);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [seletedId, setSeletedId] = useState(null)
  const [modalLoading, setModalLoading] = useState(false)
  const [deleteDisabled, setDeleteDisabled] = useState(false)
  const [employeeUpdatelist, setEmploueeUpdateList] = useState([])

  const [relatedEmployees, setRelatedEmployees] = useState([])
  const [relatedEmployeesCount, setRelatedEmployeesCount] = useState(1)
  const [checkedRows, setCheckedRows] = useState([])

  useEffect(() => {
    handleGetChoices(branch_setting_absence_overview)
  }, [])

  const getListOfAbsence = async () => {
    // const pageToSearch = getPageToSearchParam(
    //   history.location.search,
    //   setCurrentPage
    // );
    try {
      const res = await getBranchAbsenceOverview({ ...values }, currentPage, branch?.id)
      setListOfAbsence(res.results || [])
      setPages(Math.ceil(res.count / values.limitperpage));
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  console.log(currentPage, "currentPage")
  useEffect(() => {
    getListOfAbsence()
  }, [values.limitperpage,
    currentPage])


  const getRelatedEmployees = async (values) => {
    const body = {
      applicable_date_from: values.applicable_date_from,
      applicable_date_to: values.applicable_date_to,
      branch_id: values.branch_id,
      calendar_type: values.calendar_type,
    };
    const response = await getCalendarRelatedEmployees(body)
    if (response.status == 200) {
      setRelatedEmployees(response?.absence_ids_list)
      setRelatedEmployeesCount(response?.count)
      return response
    }

  }

  const HandleSubmit = async () => {
    if (loadingSave) {
      return;
    }
    console.log(errors, "errors")
    if (errors.length > 0) {
      setErrorMessage(InvalidOrEmptyValues);
      setCheckRequires(true);
      return;
    }
    debugger
    // if (new Date(`01-01-${values.applicable_date_from}`) > new Date(`31-12-${values.applicable_date_to}`)) {
    //   toast.current && toast.current.show({
    //     severity: 'error',
    //     summary: 'Failed',
    //     detail: `Year from must be greater than or equal to date year to`
    //   });
    //   return;
    // }
    const body = {
      branch_id: branch?.id,
      calendar_type: values.calendar_type,
      applicable_date_from: values.applicable_date_from.length > 4
        ? values.applicable_date_from?.slice(0, 4)
        : String(values.applicable_date_from),
      applicable_date_to: values.applicable_date_to.length > 4
        ? values.applicable_date_to?.slice(0, 4)
        : String(values.applicable_date_to),
      absence_overview: values[NAME_SPACE].map(el => ({
        absence_type: el.absence_type,
        absence_paid: el.absence_paid,
        absence_allowance: el.absence_allowance,
        time_unit: el.time_unit,
        ...(el.id && { id: el.id })
      }))
    };
    setLoadingSave(true);
    try {
      const res = await setBranchAbsenceOverview(body, seletedId);
      if (res.status == 201 || res.status == 200) {
        let message = res.message
        if (seletedId) {
          const data = await getRelatedEmployees(body)
          
          if (data.count > 0) {
            // setModalWarningValue(true)
            setAddModalValue(true)
            message = 'Your current changes are saved, but below are employees which are having different calendar type.'
          }else{
             setAddModalValue(false)
          }
        } else {
          const newBody = { 
            branch_id: body.branch_id,
            calendarType: body.calendar_type,
            applicable_date_from: body.applicable_date_from,
            applicable_date_to: body.applicable_date_to
          }
          const res = await getListOfEmployeeWithDate(newBody)
          if (res && res.count > 0) {
            setAddModalValue(false)
            setCheckedRows([])
            setAddEmployeeUpdateModal(true)
            setEmploueeUpdateList(res.employee_list || [])
          }
        }
        onSuccess(message);
      } else {
        onError(res);
      }
    } catch (error) {
      onError(error);
    }
  }

  const onSuccess = (message) => {
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: `${message}`,
    });
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    getListOfAbsence()
  }

  const onError = err => {
    let message = ""
    if (typeof err.message == 'object') {
      message = Object.values(err.message)[0][0]
    } else {
      message = err.message
    }
    toast.current && toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: `${message}`
    });
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
  };

  const handleModal = (props) => {
    setTextStatus(SAVE)
    setButtonIcon(FLOPPY)
    setLoadingSave(false)

    if (props === NEW) {
      setModalTitle('Add Absence Overview')
      values.calendar_type = null;
      values.applicable_date_from = null;
      values.applicable_date_to = null;
      values[NAME_SPACE] = [{}];
      setRelatedEmployees([])
      setRelatedEmployeesCount(0)
      setSeletedId(null)
      setValues({ ...values });
    } else {
      setModalTitle('Update Absence Overview')
    }
    setAddModalValue(!addModalValue)
  }

  const dropDownHandleSelect = (item, nameItem) => {
    //recursive objects merge
    let result = _.merge(values, item);
    setValues({ ...result });
  };

  const handleEditClick = (item) => {
    handleModal("")
    setRelatedEmployees([])
    setRelatedEmployeesCount(0)
    setSeletedId(item.id)
    getAbsenceOverViewById(item.id)
  }

  const getAbsenceOverViewById = async (id) => {
    setModalLoading(true)
    try {
      const res = await getBranchAbsenceOverViewById(id)
      if (res) {
        values.applicable_date_to = res.applicable_date_to.length > 4
          ? res.applicable_date_to.slice(0, 4) : res.applicable_date_to
        values.applicable_date_from = res.applicable_date_from.length > 4
          ? res.applicable_date_from.slice(0, 4) : res.applicable_date_from
        setValues({ ...values, ...res })
      }
      setModalLoading(false)
    } catch (error) {
      console.error(error)
      setModalLoading(false)
    }
  }

  const handleDeleteRow = (id) => {
    setSeletedId(id)
    setModalDeleteValue(true)
  }


  const deleteOverView = async (id) => {
    setDeleteDisabled(true)
    try {
      const res = await deleteBranchAbsenceOverview(id)
      if (res.status === 204) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${res?.message}`,
        })
        getListOfAbsence()
      } else {
        toast.current && toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${res?.message}`
        });
      }
      setSeletedId(null)
      setModalDeleteValue(false)
      setDeleteDisabled(false)
    } catch (error) {
      toast.current && toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${error?.message}`
      });
    }
    setSeletedId(null)
    setModalDeleteValue(false)
  }


  const getNameFromChoice = (id, key) => {
    const match = contextChoices && contextChoices[key] && contextChoices[key].find(x => x.id === id)
    return match ? match.name : ""
  }


  const handleSelectChange = (e, item) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, item.id])
    } else {
      let index = checkedRows.indexOf(item.id)
      if (index != -1) {
        let tempCheckedRows = [...checkedRows]
        tempCheckedRows.splice(index, 1)
        setCheckedRows([...tempCheckedRows])
      }
    }
  }


  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists = id => {
      return checkedRows.some(function (el) {
        return el === id;
      });
    };
    if (checkedRows && checkedRows.length > 0) {
      relatedEmployees.map(item => {
        if (!visitExists(item.id)) {
          checkAll = false;
        }
      });
    } else {
      checkAll = false;
    }
    return checkAll;
  };



  const handleAllSelectChange = valcheck => {
    let checked = [...checkedRows];
    if (valcheck.target.checked) {
      relatedEmployees.map(item => {
        checked.push(item.id);
      });
    } else {
      relatedEmployees.map(el => {
        checked.map((item, index) => {
          if (item === el.id) {
            checked.splice(index, 1);
          }
        });
      });
    }
    setCheckedRows(checked);
  };

  const handleUpdateCalenderType = async () => {
    if (!checkedRows.length) return;
    const body = {
      absence_overview_ids: checkedRows,
      calendar_type: values.calendar_type,
    };
    try {
      const res = await setUpdateCalendarType(body)
      setModalWarningValue(false)
      setAddModalValue(false)
      setCheckedRows([])
      setRelatedEmployees([])
      setRelatedEmployeesCount(0)
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `Calendar Type has been changed for ${checkedRows?.length && checkedRows?.length} employees`,
      });
    } catch (error) {
      setModalWarningValue(false)
      setAddModalValue(false)
      setCheckedRows([])
      toast.current && toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Something went wrong`
      });
    }
  }


  const getListOfEmployeeWithDate = async (body) => {
    try {
      const res = await getEmployeeCalendarType(body)
      return res
    } catch (error) {

    }
  }


  const handleUpdate = async () => {
    const body = {
      employee_ids: checkedRows,
      branch_id: branch?.id,
      calendar_type: values.calendar_type,
      applicable_date_from: values.applicable_date_from.length > 4 ? values.applicable_date_from?.slice(0, 4) : String(values.applicable_date_from),
      applicable_date_to: values.applicable_date_to.length > 4 ? values.applicable_date_to?.slice(0, 4) : String(values.applicable_date_to),
      absence_overview: values[NAME_SPACE].map(el => ({
        absence_type: el.absence_type,
        absence_paid: el.absence_paid,
        absence_allowance: el.absence_allowance,
        time_unit: el.time_unit,
        ...(el.id && { id: el.id })
      }))
    }
    try {
      const res = await updateEmployeeCalendarType(body)
      if(res.status == true){
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: res.message,
        })
        }else{
          toast.current && toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `Something went wrong`
          });
          setModalWarningValueEmployee(false)
        }
        setModalWarningValueEmployee(false)
        setAddEmployeeUpdateModal(false)
        setCheckedRows([])
    } catch (error) {
      toast.current && toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Something went wrong`
      });
      setModalWarningValueEmployee(false)
    }
  }

  return (
    <>{
      readPermission ? (
        <div className='w-100 mb-4' style={{ marginTop: "8rem" }}>
          <Toast ref={toast} position='top-right' />
          <Header className='mb-2'>
            <div className='w-100 d-flex justify-content-end'>
              {createPermisssion && <PrimaryButtonForm
                className={'ms-3 mt-1 mb-2'}
                bgcolor="BLUE"
                style={{ width: '5px' }}
                onClick={() => handleModal(NEW)}>
                <span>{<BsFillPlusSquareFill />}New Absence Overview</span>
              </PrimaryButtonForm>}
            </div>
          </Header>
          <ComponentDynamic loading={loading}>
            {listOfAbsence.length ?
              <div className='mt-3'>
                <Table
                  headerColumns={headerColumns}
                  // callBackQuerySort={setQuerySort}
                  headerPadding={false}
                  noEditOption={true}
                >
                  <div>
                    {
                      listOfAbsence && listOfAbsence?.map(item => {
                        return (
                          <Row key={Math.random()} bgColor style={{ marginTop: '5px', marginBottom: '5px' }}>
                            <ColsGrouper className="d-flex justify-content-start">
                              <Col
                                max_width={EX_LARGE_COL}
                                Center
                                className="sm-hidden"
                                overlap={true}>
                                <label>{getNameFromChoice(item.calendar_type, "calendar_type")}</label>
                              </Col>
                              <Col
                                max_width={EX_LARGE_COL}
                                Center
                                className="sm-hidden"
                                overlap={true}>
                                <label>{item.applicable_date_from}</label>
                              </Col>
                              <Col
                                max_width={EX_LARGE_COL}
                                Center
                                className="sm-hidden"
                                overlap={true}>
                                <label>{item.applicable_date_to}</label>
                              </Col>

                              <Col
                                className="sm-hidden"
                                //max_width={SMALL_COL}
                                onClick={() => handleEditClick(item)}
                                style={{ marginRight: '10px' }}
                                Center
                                Shrink
                                NoFlexGrow Purple>
                                <IconWrapper>
                                  <MdEdit />
                                </IconWrapper>
                              </Col>
                            </ColsGrouper>
                            <Col className="sm-hidden" Center Shrink NoFlexGrow>
                              <IconWrapper
                                onClick={() => {
                                  handleDeleteRow(item.id);
                                }}>
                                <RiDeleteBinLine />
                              </IconWrapper>
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </div>
                </Table>
                <div className="mt-3">
                  <Pagination
                    totalPages={pages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    dropdownEntriesLimit={false}
                    // number={PAGE_SIZE_RENDER}
                    dataLength={listOfAbsence.length}
                    simplePagination={true}
                  />
                </div>
              </div> :
              <NoDataFoundMessage>No data found</NoDataFoundMessage>
            }
          </ComponentDynamic>
          {addModalValue && (
            <AddAbsence
              addFullBind={addFullBind}
              values={values}
              errorMessage={errorMessage}
              errors={errors}
              useInput={useInput}
              setCheckRequires={setCheckRequires}
              setErrorMessage={setErrorMessage}
              setFormErrors={setFormErrors}
              setValues={setValues}
              choices={choices}
              toast={toast}
              HandleSubmit={HandleSubmit}
              textStatus={textStatus}
              loadingSave={loadingSave}
              buttonIcon={buttonIcon}
              dropDownHandleSelect={dropDownHandleSelect}
              NAME_SPACE={NAME_SPACE}
              modalLoading={modalLoading}
              relatedEmployees={relatedEmployees}
              relatedEmployeesCount={relatedEmployeesCount}
              handleSelectChange={handleSelectChange}
              handleAllCheck={handleAllCheck}
              handleAllSelectChange={handleAllSelectChange}
              checkedRows={checkedRows}
              setModalWarningValue={setModalWarningValue}
              getNameFromChoice={getNameFromChoice}
            />)}

          {addEmployeeUpdateModal &&
            <UpdateEmployeesAbsence
              setAddEmployeeUpdateModal={setAddEmployeeUpdateModal}
              fullBind={AddEmployeeUpdateFullBind}
              employeeList={employeeUpdatelist}
              checkedRows={checkedRows}
              setCheckedRows={setCheckedRows}
              handleUpdate={handleUpdate}
              setModalWarningValue={setModalWarningValueEmployee}
              bindModalWarning={bindModalWarningEmployee}
            />}
        </div>
      ) : (
        <div className='d-flex w-100 justify-content-center'>
          <NoPermissionContainer>
            You don't have permission to read the information.
          </NoPermissionContainer>
        </div>
      )
    }

      <ModalDecision
        type="delete"
        title="Warning"
        body={`Are you sure you want to delete this Absence Overview ? \n delete the Absence Overview will remove all records from database.`}
        onOk={() => !deleteDisabled && deleteOverView(seletedId)}
        onCancel={() => {
          setSeletedId('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />

      <ModalDecision
        type="warning"
        title="Warning"
        body={'Are you sure you want to update this calender type for selected employees?'}
        // subBody={'Do you still want to continue?'}
        onOk={() => handleUpdateCalenderType()}
        onCancel={() => {
          setModalWarningValue(false);
        }}
        okTitle={'Confirm'}
        {...bindModalWarning}
      />
    </>


  );
};

export default AbsenceOverview;
