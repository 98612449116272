import React, {useState, useEffect, useRef, Fragment, useContext} from 'react';
import { reports_kpi_choices } from 'utils/choiceConstant';
import {DropdownTypesContainer} from 'shared/styles/constants/tagsStyles';
import {Header, SearchAddContainer} from './styles';
import {getClientTypes} from 'utils/api/CoreApi';
import {useForm} from 'hooks/FormHook';
import {
  ModalContainer,
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import { AppContext, BranchItemsContext } from 'context';
import { EmploymentTypesContainer } from './styles';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import moment from 'moment';
import {getClientKpiDailyFilter, getClientsList} from 'utils/api/ClientApi';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {Toast} from 'primereact/toast';
import FilterTypeDropDownSearchable from 'components/SharedComponents/FilterTypeDropDownSearchable';
const DailyWeaklyFilter = ({tabClick, contextChoices}) => {
  const [branchFilter, setBranchFilter] = useState([]);
  const {
   handleGetChoices
  } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState(tabClick);
  const {values, setValues, useInput} = useForm({});
  const [runRouteData, setRunRouteData] = useState([]);
  const [clientStatus, setClientStatus] = useState([]);
  const [clientTypes, setClientTypes] = useState([]);
  const [clientList, setclientList] = useState([]);
  const [kpiData, setKpiData] = useState();
  const [clientTypeFilter, setClientTypeFilter] = useState();
  const [editButton, setEditButton] = useState();
  const toast = useRef();
  const {branchData} = useContext(BranchItemsContext);
  const mountedRef = useRef(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [downloadDisable, SetDownloadDisable] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [branch, setBranch] = useState([]);
  const [employmentTypeFilter, setEmploymentTypeFilter] = useState([]);
  useEffect(() => {
    handleGetChoices(reports_kpi_choices)
    
  }, [])
  
  useEffect(() => {
    setKpiData(contextChoices && contextChoices.kpi);
    setBranch(
      contextChoices &&
        contextChoices.branch &&
        contextChoices.branch.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
  }, [contextChoices]);
  useEffect(() => {
    if (values.start_date) {
      var daysDiff = moment(values.end_date).diff(
        moment(values.start_date),
        'days'
      );
      if (values.kpi === 'day' && daysDiff > 60) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(60, 'days')
          .format('YYYY-MM-DD');
        setValues({...values});
        values.start_date = moment(values.end_date)
          .subtract(60, 'days')
          .format('YYYY-MM-DD');

        setValues({...values});
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max  range should range  be 60 days',
        });
      }

      if (values.kpi === 'month' && daysDiff > 365) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(12, 'month')
          .format('YYYY-MM-DD');
        setValues({...values});
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should  be 12 months',
        });
      }
      if (values.kpi === 'week' && daysDiff > 91) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(13, 'week')
          .format('YYYY-MM-DD');
        setValues({...values});
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should be 13 weeks',
        });
      }
      if (values.kpi === 'quarter' && daysDiff > 4567) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(15, 'month')
          .format('YYYY-MM-DD');
        setValues({...values});
        toast.current.show({
          severity: 'error',
          summary: 'Warning',
          detail: 'max range  should be 1.5 year',
        });
      }
    }
  }, [values.start_date || values.kpi]);

  useEffect(() => {
    if (values.end_date) {
      var daysDiff = moment(values.end_date).diff(
        moment(values.start_date),
        'days'
      );

      if (values.kpi === 'day' && daysDiff > 60) {
        values.start_date = moment(values.end_date)
          .subtract(60, 'days')
          .format('YYYY-MM-DD');
        setValues({...values});

        setValues({...values});
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max  range should range  be 60 days',
        });
      }
      if (values.kpi === 'month' && daysDiff > 365) {
        values.start_date = moment(values.end_date)
          .subtract(12, 'month')
          .format('YYYY-MM-DD');
        setValues({...values});
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should  be 12 months',
        });
      }
      if (values.kpi === 'week' && daysDiff > 91) {
        values.start_date = moment(values.end_date)
          .subtract(13, 'week')
          .format('YYYY-MM-DD');
        setValues({...values});
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should be 13 weeks',
        });
      }
      if (values.kpi === 'quarter' && daysDiff > 457) {
        values.start_date = moment(values.end_date)
          .subtract(15, 'month')
          .format('YYYY-MM-DD');
        setValues({...values});
        toast.current.show({
          severity: 'error',
          summary: 'Warning',
          detail: 'max range  should be 1.5 year',
        });
      }
      setValues({...values});
    }
  }, [values.end_date || values.kpi]);

  useEffect(() => {
    setValues([]);
  }, [tabClick]);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    setActiveTab(tabClick);
  }, [tabClick]);
  const handleDownload = () => {
    if (!values.kpi) {
      setShowError(true);
      setErrorMessage('Please select Frequency ');
    } else {
      let fileName =
        'report_client_' + activeTab + '_' + moment().format('YYYY-MM-DD');
      getClientKpiDailyFilter({...branchData, ...values}, activeTab)
        .then(response => {
          if (response) {
            setLoadingButton(true);
            SetDownloadDisable(true);
          }
          response.blob().then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
          });
          setTimeout(() => {
            setLoadingButton(false);
            SetDownloadDisable(false);
          }, 2000);
        })
        .catch(err => {});
      SetDownloadDisable(false);
    }
  };
  let startDate = useInput('start_date', 'isRequired');
  let endDate = useInput('end_date', 'isRequired');

  const dropDownHandleSelect = item => {
    setValues({...values, ...item});

    if (item.kpi) {
      setShowError(false);

      var daysDiff = moment(values.end_date).diff(
        moment(values.start_date),
        'days'
      );

      var month = moment(values.end_date).diff(
        moment(values.start_date),
        'months'
      );

      if (daysDiff > 60 && item.kpi === 'day') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max  range should range  be 60 days',
        });
        values.start_date = moment(values.end_date)
          .subtract(60, 'days')
          .format('YYYY-MM-DD');

        setValues({...values});
      }
      if (daysDiff > 365 && item.kpi === 'month') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should  be 12 months',
        });
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(12, 'month')
          .format('YYYY-MM-DD');
        setValues({...values});
      }

      if (daysDiff > 91 && item.kpi === 'week') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should be 13 weeks',
        });
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(13, 'week')
          .format('YYYY-MM-DD');
        setValues({...values});
      }
      if (daysDiff > 457 && item.kpi === 'quarter') {
        toast.current.show({
          severity: 'error',
          summary: 'Warning',
          detail: 'max range  should be 1.5 year',
        });
        values.start_date = moment(values.end_date)
          .subtract(15, 'month')
          .format('YYYY-MM-DD');
        setValues({...values});
      }
      setValues({...values, ...item});
    }
  };
  const setEmploymentType = item => {
    setEmploymentTypeFilter([...item]);
  };
  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };
  console.log(values,"FFFFFFFFF")
  return (
    <div>
      <Header>
        <Toast ref={toast} position="top-right" />
        <div className="d-flex ">
       
          <DropdownTypesContainer className="me-2">
            {' '}
            <DateSelector
              isRequired
              // data-error={true}
              typeFilter={true}
              labelTop="Date from"
              value={values.start_date ? values.start_date : ''}
              //onChange={(val)=>onDateChange(val)}
              {...startDate}
            />
          </DropdownTypesContainer>
          <DropdownTypesContainer className="me-2">
            {' '}
            <DateSelector
              isRequired
              typeFilter={true}
              labelTop="Date to"
              value={values.end_date ? values.end_date : ''}
              // minDate={values.date_from}
              {...endDate}
            />
          </DropdownTypesContainer>
          <DropdownTypesContainer className="me-2">
              <NoSelectAllDropdownFilter
              
                items={branch ? branch : []}
                setItemsSelected={items => {
                  dropDownHandleSelect({branch: items});
                }}
                itemsSelected={branchFilter}
                setSelectedFilter={setFilterBranch}
                placeHolder={'Branch'}
                isObject
                allOption
                // hitApiAfterClear={hitApiAfterClear}
              />
            </DropdownTypesContainer>
          <DropdownTypesContainer className="ms-1 me-1">
            <FilterTypeDropDownSearchable
              required
              placeHolder={'Frequency'}
              options={kpiData && kpiData}
              selectedOption={values.kpi}
              onChange={value =>
                dropDownHandleSelect({kpi: value ? value : null})
              }
            />
          </DropdownTypesContainer>
       
        </div>
      </Header>
      <div className={'mb-3'}>
        {showError ? (
          <ErrorMessage>
            <label className={'mt-2'}>{errorMessage}</label>
          </ErrorMessage>
        ) : (
          <Fragment />
        )}
      </div>
      {loadingButton ? (
        <Fragment>
          <span
            className="spinner-border spinner-border-sm me-1"
            role="status"
          />
          <span>Downloading...</span>
        </Fragment>
      ) : (
        <Fragment />
      )}
      <SearchAddContainer className="d-flex align-items-center">
        <PrimaryButtonForm
          onClick={!downloadDisable && handleDownload}
          disabled={downloadDisable}>
          <label>Download List</label>
        </PrimaryButtonForm>
      </SearchAddContainer>
    </div>
  );
};

export default DailyWeaklyFilter;
