import React, { useState, useEffect, Fragment, useContext, useRef } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import {
  DropdownTypesContainer,
  FiltersContainer,
  FiltersLeftGroup,
  SearchAddContainer,
  SearchContainer,
  SearchIcon,
} from 'shared/styles/constants/tagsStyles';
import { useForm } from 'hooks/FormHook';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { deleteClientTadks, getClientTasks } from 'utils/api/ClientApi';
import { useModal } from 'hooks/ModalHook';
import { PrimaryButton, PrimaryButtonForm } from 'shared/styles/buttons';

import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { pickFile } from 'utils/files';
import _ from 'lodash';
import Table from 'components/SharedComponents/Table';
import { Toast } from 'primereact/toast';

import {
  CHECK_BOX,
  EX_LARGE_COL,
  EX_SMALL_COL,
  EX_SMALL_COL4,
  EX_VERY_LARGE_COL,
  LARGE_COL,
  MEDIUM_COL,
  SMALL_COL,
} from 'shared/styles/constants/columns';

import { FloatingMobileButton } from 'shared/styles/buttons';
import { HeaderVisits } from 'shared/styles/constants/tagsStyles';
import { AppContext } from 'context';
import TaskPopUp from './TaskPopUp';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { client_task_choices, client_task_modal } from 'utils/choiceConstant';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import DateSelector from 'components/SharedComponents/DateSelector';
import { GrSearch } from 'react-icons/gr';
import { Fade } from '@mui/material';
import { EmploymentTypesContainer } from 'components/DashboardComponents/Team/styles';
import { CloseButtonWrapper } from './showFormModal/styles';
import { CHECK, TIMES } from 'utils/constants/icons';
import { RED } from 'shared/styles/constants/colors';
import moment from 'moment';
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import ReactHtmlParser from 'react-html-parser';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
export const headerColumns = [
  {
    label: '',
    name: 'expand',
    status: 0,
    className: 'm-0 p-0 ps-1',
    max_width: CHECK_BOX,
  },
  {
    label: 'Essential',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },

  {
    label: 'Care Category',
    name: 'care_task__task_type__care_category__name',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_LARGE_COL,
  },
  {
    label: 'Task Type',
    name: 'care_task__task_type__name',
    status: 0,
    className: 'grow-mobile sm-hidden',
    max_width: EX_LARGE_COL,
  },
  {
    label: 'Care Task',
    name: 'care_task__name',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: EX_VERY_LARGE_COL,
  },
  {
    label: 'Start Date',
    name: 'requirement_visit_wise__visit__start_date',
    status: 1,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Scheduled Time',
    name: 'requirement_visit_wise__visit__end_date',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Task Status',
    name: 'task_status',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
  // {
  //   label: 'Feedback',
  //   name: 'uploaded',
  //   status: 0,
  //   className: 'grow-mobile sm-hidden md-hidden',
  //   max_width: LARGE_COL,
  // },
  {
    label: 'Result',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Updated At',
    name: '',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Updated By',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
];

const TaskModal = ({
  // cliId,
  selectedClientId,
  selectedData,
  updatePermissionTask,
  location,
  deletePermissionTask,
  createPermissionTask,
  props,
}) => {
  const { values, setValues, useInput, errors, setCheckRequires } = useForm({
    validation: false,
    ordering: 'requirement_visit_wise__visit__start_date',
  });
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const toast = useRef();
  const history = useHistory();
  const [taskData, setTaskData] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const [dataCheck, setDataCheck] = useState(false);
  const [simplePagination] = useState(true);
  const [editData, setEditData] = useState();
  const [addData, setAddData] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [careCategoryFilter, setCareCategoryFilter] = useState([]);
  const [taskTypeFilter, setTaskTypeFilter] = useState([]);
  const [careTaskFilter, setCareTaskFilter] = useState([]);
  const [taskStatusFilter, setTaskStatusFilter] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [expandFields, setExpandFields] = useState({});
  const [loader, setLoader] = useState(true);


  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
  const { contextChoices, handleGetChoices , contextChoicesMap , loadingChoices} = useContext(AppContext);
  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    let today = new Date().toISOString().slice(0, 10);

    values.start_date__gte = today;
    values.end_date__lte = today;
    values.client_id = selectedClientId;
    setValues({ ...values });
    handleGetChoices(client_task_choices);
  }, []);
  // console.log(taskData, "taskDatata_taskData_taskData")
  useEffect(() => {
    if ((props.location.search, values.limitperpage, values.ordering)) {
      loadTask();
    }
  }, [props.location.search, values.limitperpage, values.ordering]);
  const loadTask = () => {
    setLoader(true)
    const pageToSearch = getPageToSearchParam(props.location.search);

    getClientTasks({ ...values, ...pageToSearch }).then(res => {
      // setTaskData(res.data);
      if (res) {
        setTaskData((res.data && res.data) || []);
        setPages(Math.ceil(res.count / values.limitperpage));
        res.data && res.data.map((item, index) => {
          expandFields[index] = { open: true };
        })
        setExpandFields({ ...expandFields });
        setDataCheck(false);
        setLoader(false)
      }
    }).catch(() => {
      setLoader(false)
    });
  };
  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };
  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };
  console.log(deletePermissionTask, 'deletePermissionTask')
  const getCareCategoryName = ID => {
    if (ID && contextChoices.care_category) {
      return contextChoices?.care_category.find(data => data.id == ID)?.name;
    } else {
      return null;
    }
  };
  const getTaskName = ID => {
    if (ID && contextChoices.task_type) {
      return contextChoices?.task_type.find(data => data.id == ID)?.name;
    } else {
      return null;
    }
  };
  const getCareTaskName = ID => {
    if (ID && contextChoices.care_task) {
      return contextChoices?.care_task.find(data => data.id == ID)?.name;
    } else {
      return null;
    }
  };
  const getStartDate = time => {
    if (
      (time && contextChoices.care_task) ||
      contextChoices.task_type ||
      contextChoices.care_category
    ) {
      return time.slice(0, 10);
    } else {
      return null;
    }
  };
  const getEndDate = time => {
    if (
      (time && contextChoices.care_task) ||
      contextChoices.task_type ||
      contextChoices.care_category
    ) {
      return time.slice(0, 10);
    } else {
      return null;
    }
  };
  const handleModal = () => {
    setModalValue(!modalValue);
  };
  const handleRowClick = ID => {
    let newTemp = taskData.find(item => {
      if (item.id === ID) {
        return item;
      }
    });

    setEditData({ ...newTemp });
    setIdSelected(ID);
    handleModal();
  };
  const setCareCategory = item => {
    setCareCategoryFilter([...item]);
  };
  const setTaskTypes = item => {
    setTaskTypeFilter([...item]);
  };
  const setCareTask = item => {
    setCareTaskFilter([...item]);
  };
  const setTaskStatus = item => {
    setTaskStatusFilter([...item]);
  };
  const handleAdd = ID => {
    let newTemp = taskData.find(item => {
      if (item.id === ID) {
        return item;
      }
    });
    setAddData(true);
    if (!modalValue) {
      setAddData(false);
    }

    setEditData({ ...newTemp });

    setModalValue(!modalValue);
    setIdSelected('');
  };
  const toDeleteBranch = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };
  const handleDeleteBranch = async () => {
    let response = await deleteClientTadks(idSelected);

    if (response.status === true) {
      loadTask();
      setDataCheck(true);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${response.message}`,
      });
      setModalDeleteValue(false);
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });

      // setIdSelected(response.id);
      setDataCheck(true);

      setModalValue(false);

      setModalDeleteValue(false);
      setIdSelected('');
    }
  };
  const handleSelectChange = inx => {
    let temp = [];
    taskData.forEach((item, index) => {
      if (index == inx) {
        item.is_essential = !item.is_essential;
      }
      temp.push({ ...item });
    });
    setTaskData([...temp]);
  };
  const dropDownHandleSelect = item => {
    setValues({ ...values, ...item });
  };
  const handleSearch = () => {
    if (
      values.care_category ||
      values.care_task ||
      values.task_type ||
      values.task_status ||
      values.start_date__gte ||
      values.end_date__lte
    ) {
      loadTask();
    }
  };
  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d);
    };
  };
  const hitSearch = value => {
    values.search = value;
    loadTask();
  };
  const debounceOnchange = useRef(debounce(hitSearch, 300));
  const keyPressed = e => {
    setSearchKeyword(e.target.value);
    debounceOnchange.current(e.target.value);
  };

  const handleExpansion = (index) => {
    if (expandFields[index] !== undefined
      && expandFields[index]['open']) {
      expandFields[index] = { open: false };
      setExpandFields({ ...expandFields });
    } else {
      expandFields[index] = { open: true };
      setExpandFields({ ...expandFields });
    }
  };

  const calculateNoOfIcons = () => {
    let count = 0;
    if (selectedClientId ? updatePermissionTask : true)
      count = count + 1;
    if (deletePermissionTask)
      count = count + 1;
    return count || 0
  }

  const getUserName = (id) => {
    let name  = ""
    if(id && contextChoicesMap && contextChoicesMap?.user_list && contextChoicesMap?.user_list[id])
      name = contextChoicesMap.user_list[id] && contextChoicesMap.user_list[id]?.name
   return name || ""
  }
  console.log(values, 'VVVVVVVV');
  return (
    <div>
      <div className='d-flex justify-content-between mb-4'>
        <div className="d-flex">
          <FiltersContainer>
            <div
              className={'ms-1 me-3 me-md-0 justify-content-center flex-wrap d-flex'}>
              <EmploymentTypesContainer className="mb-1">
                <DropdownTypesContainer className="ms-1 me-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('start_date__gte')}
                  />
                </DropdownTypesContainer>
              </EmploymentTypesContainer>
              <EmploymentTypesContainer className="mb-1">
                <DropdownTypesContainer className="ms-1 me-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date to"
                    {...useInput('end_date__lte')}
                  />
                </DropdownTypesContainer>
              </EmploymentTypesContainer>
              <EmploymentTypesContainer className="mb-1 ms-1 me-1">
                <NoSelectAllDropdownFilter
                  items={
                    contextChoices.care_category
                      ? contextChoices.care_category
                      : []
                  }
                  setItemsSelected={items => {
                    dropDownHandleSelect({ care_category: items });
                  }}
                  itemsSelected={careCategoryFilter}
                  setSelectedFilter={setCareCategory}
                  placeHolder={'Care Category'}
                  isObject
                  allOption
                />
              </EmploymentTypesContainer>

              <EmploymentTypesContainer className="ms-1 me-1 mb-1">
                <NoSelectAllDropdownFilter
                  items={
                    contextChoices.task_type ? contextChoices.task_type : []
                  }
                  setItemsSelected={items => {
                    dropDownHandleSelect({ task_type: items });
                  }}
                  itemsSelected={taskTypeFilter}
                  setSelectedFilter={setTaskTypes}
                  placeHolder={'Task Type'}
                  isObject
                  allOption
                />
              </EmploymentTypesContainer>
              <EmploymentTypesContainer className="ms-1 me-1 mb-1">
                <NoSelectAllDropdownFilter
                  items={
                    contextChoices.care_task ? contextChoices.care_task : []
                  }
                  setItemsSelected={items => {
                    dropDownHandleSelect({ care_task: items });
                  }}
                  itemsSelected={careTaskFilter}
                  setSelectedFilter={setCareTask}
                  placeHolder={'Care Task'}
                  isObject
                  allOption
                />
              </EmploymentTypesContainer>
              <EmploymentTypesContainer className="ms-1 me-1 mb-1">
                <NoSelectAllDropdownFilter
                  items={
                    contextChoices.task_status ? [{ id: "Not Needed", name: "Not Needed" }, ...contextChoices.task_status] : []
                  }
                  setItemsSelected={items => {
                    dropDownHandleSelect({ task_status: items });
                  }}
                  itemsSelected={taskStatusFilter}
                  setSelectedFilter={setTaskStatus}
                  placeHolder={'Task Status '}
                  isObject
                  allOption
                />
              </EmploymentTypesContainer>

                <PrimaryButton
                    type="button"
                    onClick={handleSearch}
                    style={{ minWidth: '6rem' }}>
                    <span className={'font-weight-bold'}>Search</span>
                  </PrimaryButton>
            </div>
          </FiltersContainer>
        </div>

        <div className={'text-primary font-weight-bold'}>
                <div>
                  <PrimaryButtonForm
                    style={{ minWidth: "12rem" }}
                    // minWidth="12rem"
                    onClick={handleAdd}>
                    <FaPlus size={18} />
                    <span className={'ms-2'}>NEW TASK</span>
                  </PrimaryButtonForm>
                </div>
              </div>
      </div>
      <ComponentDynamic
        loading={loader || loadingChoices}
      >
      {taskData && taskData.length ?
        <>
          <Table
            headerColumns={headerColumns}
            headerPadding={false}
            noEditOption={true}
            noNeed={false}
            numberOfIcons={calculateNoOfIcons()}
            fontSize="0.75rem"
            callBackQuerySort={setQuerySort}>
            <Toast ref={toast} position="top-right" />

            <div>
              {contextChoices &&
                taskData.map((item, index) => {
                  console.log(item.message, "message")
                  return (
                    <div key={index}>
                      <Row bgColor>
                        {/* <ColsGrouper
                          className="d-flex"
                          onClick={() => {
                            //   handleRowClick(item.id);
                          }}> */}

                          <Col
                            max_width={CHECK_BOX}
                            className="d-flex justify-content-center align-content-center">
                            <div
                              className="closeButtonWrapper client-service-expand-icon"
                              onClick={() => {
                                handleExpansion(index);
                              }}>
                              {expandFields && expandFields[index] && expandFields[index]?.open ? (
                                <IoIosArrowDown
                                  style={{ color: '#6200f9', fontWeight: 'bold' }}
                                />
                              ) : (
                                <IoIosArrowForward
                                  style={{ color: '#6200f9', fontWeight: 'bold' }}
                                />
                              )}
                            </div>
                          </Col>
                          {/*Care category */}
                          <Col
                            max_width={SMALL_COL}
                            Center
                            overlap={true}>
                            <input
                              style={{ width: '20px', height: '20px' }}
                              disabled={true}
                              type="checkbox"
                              checked={item.is_essential}
                              onChange={() => {
                                handleSelectChange(index);
                              }}
                            />
                          </Col>

                          <Col
                            max_width={EX_LARGE_COL}
                            Center
                            overlap={true}>
                            <label>
                              {contextChoices
                                ? getCareCategoryName(item?.care_category)
                                : ''}
                            </label>
                          </Col>

                          {/*TASK TYPE */}
                          <Col
                            max_width={EX_LARGE_COL}
                            Center
                            className="align-self-center mb-0 sm-hidden"
                            overlap={true}>
                            {contextChoices ? getTaskName(item.task_type) : ''}
                          </Col>

                          {/*Care TASK */}
                          <Col
                            max_width={EX_VERY_LARGE_COL}
                            Center
                            className="sm-hidden md-hidden"
                            overlap={true}>
                            <label>
                              {contextChoices
                                ? getCareTaskName(item.care_task)
                                : ''}
                            </label>
                          </Col>

                          {/*START DATE */}
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden md-hidden"
                            overlap={true}>
                            <label>
                              {contextChoices ? getStartDate(item.start_date) : ''}
                            </label>
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden md-hidden"
                            overlap={true}>
                            <label>{item.start_date ? moment(item.start_date).format('HH:mm') : ''}</label>
                          </Col>
                          {/*TASK STATUS*/}
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden md-hidden"
                            overlap={true}>
                            <label>{item?.status ? item.status : null}</label>
                          </Col>

                          {/* FEEDBACK */}
                          {/* <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden md-hidden"
                            overlap={true}>
                            <label>{item?.feedback ? item.feedback : null}</label>
                          </Col> */}

                          {/* RESULT */}
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden md-hidden"
                            overlap={true}>
                            <label>
                              {item?.result_type === 'text'
                                ? item.result_option
                                : null}
                            </label>
                          </Col>

                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden md-hidden"
                            overlap={true}>
                            <label>
                              {item.updated_at &&  moment(item.updated_at).format('YYYY-MM-DD HH:mm')}
                            </label>
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden md-hidden"
                            overlap={true}>
                            <label>
                              {item.updated_by && getUserName(item.updated_by)}
                            </label>
                          </Col>
                          
                          

                          {(selectedClientId ? updatePermissionTask : true) && (
                            <Col className="" Center Width Purple>
                              <IconWrapper onClick={() => handleRowClick(item.id)}>
                                <MdEdit />
                              </IconWrapper>
                            </Col>
                          )}

                        {/* </ColsGrouper> */}
                        {deletePermissionTask && (
                          <Col className="" Center Width >
                            <IconWrapper
                              onClick={() => {
                                toDeleteBranch(item.id);
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>
                          </Col>

                        )}

                      </Row>
                      <div className="mb-1" />
                      {expandFields && expandFields[index] && expandFields[index]?.open && (
                        <div className='ms-3 me-3'>
                          <div
                            className={'pt-2 ms-0'}
                            style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                            Feedback
                          </div>
                          <div className='mt-1 mb-2 ' style={{ border: "solid 1px #ccc", borderRadius: "0.25rem", padding: "0.75rem" }}>
                            <div style={{ minHeight: "2.5rem" }}>{item?.feedback ? item.feedback : null}</div>
                          </div>
                          <div
                            className={'pt-1 ms-0'}
                            style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                            Message
                          </div>
                          <div className='mt-1 mb-2 ' style={{ border: "solid 1px #ccc", borderRadius: "0.25rem", padding: "0.75rem" }}>
                            <div style={{ minHeight: "2.5rem" }}>{item?.message ? ReactHtmlParser(item.message) : null}</div>
                          </div>
                        </div>

                      )}
                    </div>
                  );
                })}

            </div>
          </Table>
          <div className="mt-3">
            <Pagination
              totalPages={pages}
              currentPage={currentPage}
              values={values}
              dropDownHandleSelect={dropDownHandleSelectPagination}
              number={PAGE_SIZE_RENDER}
              dataLength={taskData.length}
            />
          </div>
        </>
        :
        <NoDataFoundMessage>No data found</NoDataFoundMessage>
      }
      </ComponentDynamic>
      <div className={'d-flex justify-content-center justify-content-md-end'}>
        <div>
          <PrimaryButtonForm
            bgcolor="PURPLE_GRAY_500"
            minWidth="6rem"
            className={'me-2'}
            onClick={() => {
              history.push('/client');
            }}>
            <span className={'me-2'}>{'Cancel'}</span>
          </PrimaryButtonForm>
        </div>
      </div>
      {!modalValue ? null : (
        <TaskPopUp
          loadTask={loadTask}
          selectedClientId={selectedClientId}
          setDataCheck={setDataCheck}
          fullBind={fullBind}
          setTitle={setTitle}
          idSelected={idSelected}
          editDatas={editData && editData}
          setModalValue={setModalValue}
          addData={addData}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this Task ? \n deleting the task will remove all records from database.'
        }
        onOk={handleDeleteBranch}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default TaskModal;