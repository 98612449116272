import React, {useState, useEffect, useRef, useContext} from 'react';
import {GrSearch} from 'react-icons/gr';
import {FaPlus} from 'react-icons/fa';
import {FloatingMobileButton} from 'shared/styles/buttons';
import {DropdownTypesContainer} from 'shared/styles/constants/tagsStyles';
import {Header, SearchAddContainer} from './styles';
import {getClientTypes} from 'utils/api/CoreApi';
import {useForm} from 'hooks/FormHook';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {AppContext} from 'context';
import {BranchItemsContext} from 'context';
import moment from 'moment';
import {getClientsFilter, getClientsList} from 'utils/api/ClientApi';
import DateSelector from 'components/SharedComponents/DateSelector';

const NewVisitCare = ({
  location,
  updateFunderSettings,
  createFunderSettings,
  deletePermissionFunderSettings,
  readPermissionFunderSettings,
  filterAdmin,
  filterSuperAdmin,
  userStaff,
  tabClick,
}) => {
  const [activeTab, setActiveTab] = useState(tabClick);
  const {values, setValues, useInput} = useForm({});
  const [runRouteData, setRunRouteData] = useState([]);
  const [clientStatus, setClientStatus] = useState([]);
  const [branch, setBranch] = useState([]);
  const [clientTypes, setClientTypes] = useState([]);
  const [clientList, setclientList] = useState([]);

  const [clientTypeFilter, setClientTypeFilter] = useState([]);
  const {contextChoices} = useContext(AppContext);
  const {branchData} = useContext(BranchItemsContext);
  const mountedRef = useRef(true);

  useEffect(() => {
    loadClientTypes();
  }, []);

  const loadClientTypes = () => {
    getClientTypes().then(response => {
      if (!mountedRef.current) return null;
      setClientTypes(response.results);
    });
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    setActiveTab(tabClick);
  }, [tabClick]);

  const handleDownload = () => {
    let fileName =
      'report_client_' + activeTab + '_' + moment().format('YYYY-MM-DD');
    getClientsFilter({...branchData, ...values}, activeTab)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
      })
      .catch(err => {});
  };

  return (
    <div>
      <Header>
        <div className="d-flex ">
          <DropdownTypesContainer className="me-2">
            {' '}
            <DateSelector
              typeFilter={true}
              labelTop="Date from"
              value={values.start_date ? values.start_date : ''}
              //onChange={(val)=>onDateChange(val)}
              {...useInput('start_date')}
            />
          </DropdownTypesContainer>
          <DropdownTypesContainer className="me-2">
            {' '}
            <DateSelector
              typeFilter={true}
              labelTop="Date to"
              value={values.end_date ? values.end_date : ''}
              // minDate={values.date_from}
              {...useInput('end_date')}
            />
          </DropdownTypesContainer>
        </div>
      </Header>
      <SearchAddContainer className="d-flex align-items-center">
        <FloatingMobileButton onClick={handleDownload}>
          <label>Download List</label>
        </FloatingMobileButton>
      </SearchAddContainer>
    </div>
  );
};

export default NewVisitCare;
