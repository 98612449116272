import React from 'react';
import { DurationStyled, Icon } from './styles';
import { MdAccessTime } from 'react-icons/md';
// @ts-ignore
import DurationInputMask from 'react-duration-input-mask';
import { ErrorLabel } from '../UpwardListDropdown/style';

function DurationInput(props) {
  const myClasses =  props['data-error'] ? 'error-border': props.value? 'value-border filled' : '';
  return (
    <DurationStyled Disabled={props.disabled}>
      <DurationInputMask
        {...(!props.noOtherProps && props)}
        handleChange={props.disabled ? () => { } : props.handleChange}
        onKeyDown={props.disabled ? () => { } : props.onKeyDown}
        className={myClasses}
        onChange={props.disabled ? () => { } : () => { }}
      />
      <label>{props.label_top}<span>{props.isRequired ? '*' : ''}</span>
      </label>
      {props['data-error'] ? (
        <ErrorLabel>{props.errorMessage || 'Is required'}</ErrorLabel>
      ) : (
        <></>
      )}
      <Icon>
        <MdAccessTime />
      </Icon>
    </DurationStyled>
  );
}
export default DurationInput;
