import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import {
  EX_LARGE_COL,
  LARGE_COL,
  EX_SMALL_COL4,
  SMALL_COL,
  MEDIUM_COL,
  EX_VERY_LARGE_COL,
  EX_VERY_SMALL_COL,
  EX_SMALL_COL,
} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import { CLIENT_HANDBACK, CLIENT_SWAP_VISIT, CLIENT_VISIT_AUDIT } from 'utils/constants/permissions';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';

import { EmploymentTypesContainer } from 'components/DashboardComponents/Navbar/styles';
import {
  WEB_PERMISSION,
  WEB_READ_PERMISSION,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_DELETE_PERMISSION,
} from 'utils/constants/permissions';
import {
  getHandBackList,
  getSwapVisitList,
  patchApprovalSwapVisit,
  patchDeleteSwapVisit, getVisitAudit
} from 'utils/api/ClientApi';
import { PrimaryButton, PrimaryButtonForm } from 'shared/styles/buttons';

import { patchDeleteHandBack } from 'utils/api/ClientApi';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { calendar_visit_audit } from 'utils/choiceConstant/index'
import { RiDeleteBinLine } from 'react-icons/ri';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { DOWNLOAD } from 'utils/constants/icons';

import queryString from 'query-string';
import { AppContext } from 'context';
import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper,
  DayPill,
} from 'components/SharedComponents/Table/styles';
import {
  HeaderVisits,
  Title,
  SearchIcon,
  SearchAddContainer,
  SearchContainer,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
  CloseButtonWrapper,
  TitleSection,
  PublishButton,
} from 'shared/styles/constants/tagsStyles';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {
  client_hand_bag_choices,
  client_swap_visit_choices,
} from 'utils/choiceConstant';
import { LeftContainer } from 'components/SharedComponents/ProgressInformation/styles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { RED } from 'shared/styles/constants/colors';
import { TIMES } from 'utils/constants/icons';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { getVisitAuditExport } from 'utils/api/EvvApi';
import CustomInputLabelTop from 'components/SharedComponents/CustomInput';
const headerColumns = [
 
  {
    label: 'BRANCH',
    name: 'Client',
    status: 0,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'CLIENT',
    name: 'Client',
    status: 0,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'EMPLOYEE',
    name: 'employee',
    status: 0,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'START Date',
    name: 'start date',
    status: 1,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'START TIME',
    name: 'client_service_visit__client__full_name',
    status: 1,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'VISIT STATUS',
    name: 'SWAP END TIME',
    status: 0,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },

  {
    label: 'TOTAL PLANNED TASK',
    name: 'start_date',
    status: 0,
    className: '  sm-hidden ',
    max_width: SMALL_COL,
  },

  {
    label: 'NO OF COMPLETED TASK',
    name: 'end_date',
    status: 0,
    className: ' sm-hidden ',
    max_width: SMALL_COL,
  },
  {
    label: 'NO OF PARTIAL COMPLETED TASK',
    name: 'end_date',
    status: 0,
    className: ' sm-hidden ',
    max_width: SMALL_COL,
  },
  {
    label: 'NO OF NOT STARTED TASK',
    name: 'end_date',
    status: 0,
    className: ' sm-hidden ',
    max_width: SMALL_COL,
  },
  {
    label: 'NO OF  NOTES',
    name: 'end_date',
    status: 0,
    className: ' sm-hidden ',
    max_width: SMALL_COL,
  },
  {
    label: 'NO OF TRIAGE',
    name: 'end_date',
    status: 0,
    className: ' sm-hidden ',
    max_width: SMALL_COL,
  },
];

function VisitAudit(props) {
  const { values, setValues, useInput, isValid, errors, setFormErrors, setCheckRequires } =
    useForm({});
  const [cancelledvisits, setCancelledVisits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [visitAuditList, setVisitAuditList] = useState([])
  const mountedRef = useRef(true);
  const [visitStatusFilter, setVisitStatusFilter] = useState([]);

  const {
    contextChoices,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextPermissions,
    contextIsStaff,
    contextSubscription,
    contextCategoryPermissions,
  } = useContext(AppContext);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);
  const { setValue: setModalWarningValue, bind: bindModalWarning } =
    useModal(false);

  const [employees, setEmployees] = useState([]);
  const [data, setData] = useState([{ name: 'dummy Data' }, { name: 'dummy Data1' }, { name: 'dummy Data2' }, { name: 'dummy Data3' }, { name: 'dummy Data4' }])
  const [idToDelete, setIdToDelete] = useState();
  const [HandBackList, setHandBackList] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [events, setEvents] = useState([]);
  const [checkedId, setCheckedId] = useState('');
  const [deleteId, setDeleteId] = useState([]);
  const [checkedIdArr, setCheckedIdArr] = useState([]);
  const [branch, setBranch] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [employeesFilter, setEmployeesFilter] = useState([]);
  const [loadingModal, setLoadingModal] = useState(true);
  const [countCheck, setCountCheck] = useState('')
  const [clientsFilter, setClientsFilter] = useState([]);
  const [clients, setClients] = useState([]);
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState(100);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [NoOfPlannedTasks, setNoOfPlannedTasks] = useState(0);
  const [NoOfNotStarted, setNoOfNotStarted] = useState(0);
  const [NoOfCompleted, setNoOfCompleted] = useState(0);
  const [NoOfPartialComplete, setNoOfPartialComplete] = useState(0);
  const [NoOfTriage, setNoOfTriage] = useState(0);
  const [readPermission, setReadPermissions] = useState(false);
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const startDate = useInput(`start_date__gte`, 'isRequired')
  const endDate = useInput(`end_date__lte`, 'isRequired')
  const [downloadDisable, setDownloadDisable] = useState(false)
  const [visitStatus, setVisitStatus] = useState([]);
  const [partialCompleteGte, setPartialGte] = useState()
  const {
    setValue: setAddEvent,
    value: addEvent,
    fullBind: fullBindAddEvent,
  } = useModal(false);
  const {
    setValue: setCancelledVisit,
    value: cancelledVisit,
    fullBind: fullBindCancelledVisit,
  } = useModal(false);
  const { handleGetChoices } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    handleGetChoices(calendar_visit_audit);
    values.limitperpage = PAGE_SIZE_RENDER;
    let currentDate = new Date().toJSON().slice(0, 10);
    values.start_date__gte = currentDate;
    values.end_date__lte = currentDate;
    setValues({ ...values });
  }, []);
  useEffect(() => {
    values.partial = null;
    setValues({ ...values })
    return () => {
      mountedRef.current = false;
    };
  }, []);


  useEffect(() => {
    if (

      values.limitperpage ||
      props.location.search
    ) {
      search();
    }
  }, [

    values.limitperpage,
    props.location.search,
  ]);
  useEffect(() => {

    let selfPermission = {};
    const self = contextPermissions;
    if (self.length > 0) {
      self.forEach(item => {
        selfPermission[item.section.name] = {};
        selfPermission[item.section.name][WEB_PERMISSION] = {};
        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_READ_PERMISSION
        ] = item?.[SELF_WEB_READ_PERMISSION];

        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_CREATE_PERMISSION
        ] = item?.[SELF_WEB_CREATE_PERMISSION];

        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_UPDATE_PERMISSION
        ] = item?.[SELF_WEB_UPDATE_PERMISSION];

        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_DELETE_PERMISSION
        ] = item?.[SELF_WEB_DELETE_PERMISSION];
      });
    }
    let rolePermissions = {};

    const roles = contextPermissions;
    if (roles.length > 0) {
      roles.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
      });
      contextPermissions.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
      });
    }
    if (filterSuperAdmin) {
      setReadPermissions(true);
      setUpdatePermissions(true);
      setDeletePermissions(true);
      setCreatePermissions(true);
    }
    if (filterAdmin) {
      setReadPermissions(
        contextSubscription?.[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION]
      );
      setUpdatePermissions(true);
      setDeletePermissions(true);
      setCreatePermissions(true);
    }

    if (rolePermissions[CLIENT_VISIT_AUDIT] !== undefined) {
      setCreatePermissions(
        (contextSubscription?.[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION]?.[
          WEB_CREATE_PERMISSION
          ]) ||
        selfPermission[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION]?.[
        SELF_WEB_CREATE_PERMISSION
        ]
      );
      setDeletePermissions(
        (contextSubscription?.[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION]?.[
          WEB_DELETE_PERMISSION
          ]) ||
        selfPermission[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION]?.[
        SELF_WEB_DELETE_PERMISSION
        ]
      );
      setUpdatePermissions(
        (contextSubscription?.[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION]?.[
          WEB_UPDATE_PERMISSION
          ]) ||
        selfPermission[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION]?.[
        SELF_WEB_UPDATE_PERMISSION
        ]
      );

      setReadPermissions(
        (contextSubscription?.[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
          ]) ||
        selfPermission[CLIENT_VISIT_AUDIT]?.[WEB_PERMISSION]?.[
        SELF_WEB_READ_PERMISSION
        ]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contextIsAdmin,
    contextIsSuperAdmin,
    contextPermissions,
    contextCategoryPermissions,
    contextSubscription,
  ]);
  useEffect(() => {


    setBranch(
      contextChoices.branch &&

      contextChoices.branch.map(y => {
        return { id: y && y.id, name: y && y.name };
      })
    );

    setClients(
      contextChoices &&
      contextChoices.client_listing &&
      contextChoices.client_listing.map(y => {
        return { id: y && y.id, name: y && y.full_name };
      })
    );

    setEmployees(
      contextChoices &&
      contextChoices.employee_listing &&
      contextChoices.employee_listing.map(y => {
        return { id: y && y.id, name: y && y.full_name };
      })
    );
    setVisitStatus(contextChoices.visit_status && contextChoices.visit_status.map(y => {
      return { id: y && y.id, name: y && y.name }
    }));
  }, [contextChoices]);

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };

  const handleRowClick = type => {
    if (type == 'edit') {
      setCancelledVisit(!cancelledVisit);
    } else {
      setCancelledVisit(!cancelledVisit);
    }
  };
  const search = () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage
    );

    getVisitAudit({ ...values, ...pageToSearch }, values.limitperpage).then(response => {
      if (response) {

        setLoadingModal(false);
        setCountCheck(response.count)
        setVisitAuditList(response.data);
        setNoOfPlannedTasks(response?.visitAuditCountRecord?.sumTotalPlannedTask || 0)
        setNoOfNotStarted(response?.visitAuditCountRecord?.sumTaskNotStarted || 0)
        setNoOfCompleted(response?.visitAuditCountRecord?.sumTaskCompleted || 0)
        setNoOfPartialComplete(response?.visitAuditCountRecord?.sumTaskPartiallyComplete || 0)
        setNoOfTriage(response?.visitAuditCountRecord?.sumVisitAlert || 0)
      }
      setPages(Math.ceil(response.count && response.count / values.limitperpage));
      if (!mountedRef.current) return null;
    });
  };




  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };
  const toDeleteVisit = async id => {
    setModalDeleteValue(true);
    setIdToDelete(id);
  };
  const toApproval = async id => {
    setModalWarningValue(true);
    setIdToDelete(checkedId);
  };
  const handleDeleteVisit = async () => {
    if (idToDelete || checkedId) {
      const is_deleted = true;
      await patchDeleteSwapVisit([idToDelete]);
      setModalDeleteValue(false);
    }
    search();
  };





  const dropDownHandleSelect = (item, namesection) => {
    setValues({ ...values, ...item });
  };



  const setFilterEmployees = item => {
    setEmployeesFilter([...item]);
  };

  const setFilterClients = item => {
    setClientsFilter([...item]);
  };
  const getClientBranchName = (ID) => {
    if (ID && contextChoices) {
      return contextChoices?.branch?.find((item) => item.id === ID)?.name
    }
    else {
      return null
    }
  }
  const handleSearch = () => {
    if (values.start_date__gte || values.end_date__lte || values.employees || values.branch || values.clients || values.visitstatus
      || values.taskPartiallyComplete__lte || values.taskNotStarted__lte || values.visitAlert__lte || values.visitCareNotes__lte ||

      values.taskPartiallyComplete__gte || values.taskNotStarted__gte || values.visitAlert__gte || values.visitCareNotes__gte


    ) {
      const urlParams = queryString.parse(props.location.search);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: '/visit-audit',
        search: stringified,
      });
      search()
    }
  }
  const handleMinChange = (event) => {
    // debugger;
    const newMinValue = parseInt(event.target.value);

    if (newMinValue >=0) {
      let currentValue = event.currentTarget.name

      switch (currentValue) {
        case 'taskPartiallyComplete__lte':
          values.taskPartiallyComplete__lte = newMinValue;
          setValues({ ...values })



          break;
        case 'taskNotStarted__lte':
          values.taskNotStarted__lte = newMinValue;
          setValues({ ...values })

          break;

        case 'visitAlert__lte':
          values.visitAlert__lte = newMinValue;
          setValues({ ...values })

          break;

        case 'visitCareNotes__lte':
          values.visitCareNotes__lte = newMinValue;
          setValues({ ...values })

          break;

        default:

          break;
      }





      // values.partial = newMinValue
    }
  };

  const handleMaxChange = (event) => {



    const newMaxValue = parseInt(event.target.value);
    if (newMaxValue >=0) {
      let currentValue = event.currentTarget.name

      switch (currentValue) {
        case 'taskPartiallyComplete__gte':
          values.taskPartiallyComplete__gte = newMaxValue;
          setValues({ ...values })

          break;
        case 'taskNotStarted__gte':
          values.taskNotStarted__gte = newMaxValue;
          setValues({ ...values })

          break;
        case 'visitAlert__gte':
          values.visitAlert__gte = newMaxValue;
          setValues({ ...values })

          break;
        case 'visitCareNotes__gte':
          values.visitCareNotes__gte = newMaxValue;
          setValues({ ...values })

          break;
        default:

          break;
      }
    }
  }
  const handleDownload = () => {
    let fileName = "Visit_Audit_" + values.start_date__gte + "_" + values.end_date__lte
    setDownloadDisable(true)
    getVisitAuditExport(values.start_date__gte, values.end_date__lte, 'Shifts').then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        setDownloadDisable(false)
      })
    }
    )
      .catch(error => {
        setDownloadDisable(false)
      })
  }
  const setFiltersetVisitStatus = (item) => {
    setVisitStatusFilter([...item])
  }
  console.log(values, "HHHHH")
  return (
    <div>
      {readPermission && (
        <div>

          <Title> Visit Audit </Title>
          <HeaderVisits>
            <FiltersContainer>
              <div
                className={'ms-1 me-3 me-md-0 flex-wrap d-flex justify-content-center'}>
                <FiltersLeftGroup className="d-flex justify-content-center mb-2">
                  <DropdownTypesContainer className="ms-1 me-1">
                    <div>
                      <DateSelector
                        isRequired
                        typeFilter={true}
                        labelTop="Date from"
                        value={values.start_date__gte}
                        {...startDate}
                      />
                    </div>

                  </DropdownTypesContainer>
                </FiltersLeftGroup>
                <FiltersLeftGroup className="d-flex justify-content-center mb-2">

                  <DropdownTypesContainer className="ms-1 me-1">
                    <div>
                      <DateSelector
                        isRequired
                        typeFilter={true}
                        labelTop="Date to"
                        {...endDate}
                      />
                    </div>

                  </DropdownTypesContainer>
                </FiltersLeftGroup>
                <FiltersLeftGroup className="d-flex justify-content-center mb-2">
                  <DropdownTypesContainer className="ms-1 me-1">
                    <NoSelectAllDropdownFilter
                      items={employees ? employees : []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ employees: items });
                      }}
                      itemsSelected={employeesFilter}
                      setSelectedFilter={setFilterEmployees}
                      placeHolder={'Employee name'}
                      isObject
                      allOption
                    />
                  </DropdownTypesContainer>
                </FiltersLeftGroup>
                <FiltersLeftGroup className="d-flex justify-content-center mb-2">
                  <DropdownTypesContainer className="ms-1 me-1">
                    <NoSelectAllDropdownFilter
                      items={clients ? clients : []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ clients: items });
                      }}
                      itemsSelected={clientsFilter}
                      setSelectedFilter={setFilterClients}
                      placeHolder={'Client name'}
                      isObject
                      allOption
                    />
                  </DropdownTypesContainer>
                </FiltersLeftGroup>
                <FiltersLeftGroup className="d-flex justify-content-center mb-2">
                  <DropdownTypesContainer className="ms-1 me-1">
                    <NoSelectAllDropdownFilter
                      items={branch || []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ branch: items });
                      }}
                      itemsSelected={branchFilter}
                      setSelectedFilter={items =>
                        setBranchFilter([...items])
                      }
                      placeHolder={'Branch'}
                      isObject
                      allOption
                    />
                  </DropdownTypesContainer>
                  <DropdownTypesContainer>
                    <NoSelectAllDropdownFilter
                      items={visitStatus ? visitStatus : []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ visitstatus: items });
                      }}
                      itemsSelected={visitStatusFilter}
                      setSelectedFilter={setFiltersetVisitStatus}
                      placeHolder={'Visit status'}
                      isObject
                      allOption
                    />
                  </DropdownTypesContainer>
                </FiltersLeftGroup>
                <DropdownTypesContainer style={{ marginLeft: '4px', width: '13%' }}>


                  <CustomInputLabelTop
                    name={'taskPartiallyComplete__lte'}
                    id={'taskPartiallyComplete__lte'}
                    type="number"
                    label={'Partial complete min'}

                    value={values.taskPartiallyComplete__lte}
                    onChange={handleMinChange}
                  />


                </DropdownTypesContainer>

                <DropdownTypesContainer style={{ marginLeft: '4px', width: '13%' }}>


                  <CustomInputLabelTop
                    name={`taskPartiallyComplete__gte`}
                    type="number"
                    label={'Partial complete max'}
                    id={`taskPartiallyComplete__gte`}
                    value={values.taskPartiallyComplete__gte}
                    onChange={handleMaxChange}
                  />


                </DropdownTypesContainer>

                <DropdownTypesContainer style={{ marginLeft: '4px', width: '13%' }}>


                  <CustomInputLabelTop
                    name={'taskNotStarted__lte'}
                    id={'taskNotStarted__lte'}
                    type="number"
                    label={'Not Started tasks min'}

                    value={values.taskNotStarted__lte}
                    onChange={handleMinChange}
                  />


                </DropdownTypesContainer>

                <DropdownTypesContainer style={{ marginLeft: '4px', width: '13%' }}>


                  <CustomInputLabelTop
                    name={'taskNotStarted__gte'}
                    id={'taskNotStarted__gte'}
                    type="number"
                    label={'Not Started tasks max'}

                    value={values.taskNotStarted__gte}
                    onChange={handleMaxChange}
                  />


                </DropdownTypesContainer>

                <DropdownTypesContainer style={{ marginLeft: '4px', width: '13%' }}>


                  <CustomInputLabelTop
                    name={'visitCareNotes__lte'}
                    id={'visitCareNotes__lte'}
                    type="number"
                    label={'No. of Notes min'}

                    value={values.visitCareNotes__lte}
                    onChange={handleMinChange}
                  />


                </DropdownTypesContainer>

                <DropdownTypesContainer style={{ marginLeft: '4px', width: '13%' }}>


                  <CustomInputLabelTop
                    name={'visitCareNotes__gte'}
                    id={'visitCareNotes__gte'}
                    type="number"
                    label={'No. of Notes max'}

                    value={values.visitCareNotes__gte}
                    onChange={handleMaxChange}
                  />


                </DropdownTypesContainer>



                <DropdownTypesContainer style={{ marginLeft: '4px', width: '13%' }}>


                  <CustomInputLabelTop
                    name={'visitAlert__lte'}
                    id={'visitAlert__lte'}
                    type="number"
                    label={'No. of Triage min'}

                    value={values.visitAlert__lte}
                    onChange={handleMinChange}
                  />


                </DropdownTypesContainer>

                <DropdownTypesContainer style={{ marginLeft: '4px', width: '13%' }}>


                  <CustomInputLabelTop
                    name={'visitAlert__gte'}
                    id={'visitAlert__gte'}
                    type="number"
                    label={'No. of Triage  max'}

                    value={values.visitAlert__gte}
                    onChange={handleMaxChange}
                  />


                </DropdownTypesContainer>


                <EmploymentTypesContainer className="me-2 mb-1">
                  <PrimaryButton
                    style={{ marginLeft: '0.2rem' }}
                    onClick={handleSearch}
                  >
                    Search
                  </PrimaryButton>
                </EmploymentTypesContainer>
              </div>
            </FiltersContainer>
            <PublishButton className=" ms-auto ps-2 me-4" disable={downloadDisable || !values.start_date__gte || !values.end_date__lte} onClick={!downloadDisable && handleDownload}>
              {DOWNLOAD}
            </PublishButton>
          </HeaderVisits>
          <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-md-end">
          <div className="data-container">
                <div className="me-2">
                  <p>{'Planned Taks: ' + NoOfPlannedTasks}</p>
                </div>

                <div className="me-2">
                  <p>{'Completed Tasks: ' + NoOfCompleted} </p>
                </div>
                <div className="me-2">
                  <p>{'Partial Complete Tasks: ' + NoOfPartialComplete}</p>
                </div>
                <div className="me-2">
                  <p>{'Not Started Tasks: ' + NoOfNotStarted} </p>
                </div>
                <div className="me-2">
                  <p>{'Triage: ' + NoOfTriage} </p>
                </div>
              </div>
              </DataContainer>
          <ComponentDynamic loading={loadingModal}>
            {countCheck !== 0 ? (
              <Table
                headerColumns={headerColumns}
                headerPadding={false}
                noEditOption
                fontSize="0.75rem"
                callBackQuerySort={setQuerySort}>

                <div>
                  {
                    visitAuditList?.map((item, index) => {
                      // console.log(item, " taskData.map((item, index)   taskData.map((item, index)  taskData.map((item, index)")
                      return (
                        <div key={index}>
                          <Row bgColor>
                            <ColsGrouper
                              className="d-flex"
                              onClick={() => {
                                //   handleRowClick(item.id);
                              }}>
                              {/*Care category */}

                             

                              <Col
                                max_width={SMALL_COL}
                                Center
                                className="align-self-center mb-0"
                                overlap={true}>
                                {contextChoices ? getClientBranchName(item.clientBranch) : ''}
                              </Col>
                              <Col
                                max_width={SMALL_COL}
                                style={{ marginTop: '1%', marginBottom: '1%' }}
                                Center
                                className="sm-hidden"
                                overlap={true}>
                                <label>
                                  {item.clientName}
                                </label>
                              </Col>
                              {/*Care TASK */}
                              <Col
                                max_width={SMALL_COL}
                                Center
                                className="sm-hidden"
                                overlap={true}>
                                <label>
                                  {item.EmployeeName}

                                </label>
                              </Col>

                              {/*START DATE */}
                              <Col
                                max_width={SMALL_COL}
                                Center
                                className="md-hidden"
                                overlap={true}>
                                <label>
                                  {item.start_date.slice(0, 10)}
                                </label>
                              </Col>
                              <Col
                                max_width={SMALL_COL}
                                Center
                                className="md-hidden"
                                overlap={true}>
                                <label>
                                  {item.start_date.slice(11, 16)}
                                </label>
                              </Col>
                              {/*TASK STATUS*/}
                              <Col
                                max_width={SMALL_COL}
                                Center
                                className="md-hidden"
                                overlap={true}>
                                <label>                        {item.visit_status__name}
                                </label>
                              </Col>

                              {/* FEEDBACK */}
                              <Col
                                max_width={SMALL_COL}
                                Center
                                className="md-hidden"
                                overlap={true}>
                                <label>                        {item.totalPlannedTask}
                                </label>
                              </Col>

                              {/* RESULT */}
                              <Col
                                max_width={SMALL_COL}
                                Center
                                className="md-hidden"
                                overlap={true}>
                                <label>
                                  {item.taskCompleted}

                                </label>
                              </Col>
                              <Col
                                max_width={SMALL_COL}
                                Center
                                className="md-hidden"
                                overlap={true}>
                                <label>
                                  {item.taskPartiallyComplete}

                                </label>
                              </Col>
                              <Col
                                max_width={SMALL_COL}
                                Center
                                className="md-hidden"
                                overlap={true}>
                                <label>
                                  {item.taskNotStarted}

                                </label>
                              </Col>
                              <Col
                                max_width={SMALL_COL}
                                Center
                                className="md-hidden"
                                overlap={true}>
                                <label style={{ marginRight: '20px' }}>
                                  {item.visitCareNotes}

                                </label>
                              </Col>
                              <Col
                                max_width={SMALL_COL}
                                Center
                                className="md-hidden"
                                overlap={true}>
                                <label style={{ marginRight: '30px' }}>
                                  {item.visitAlert}

                                </label>
                              </Col>

                            </ColsGrouper>


                          </Row>
                          <div className="mb-1" />
                        </div>
                      );
                    })}{
                    visitAuditList && (
                      <div className="mt-3">
                        <Pagination
                          totalPages={pages}
                          currentPage={currentPage}
                          values={values}
                          dropDownHandleSelect={dropDownHandleSelectPagination}
                          number={PAGE_SIZE_RENDER}
                          dataLength={data.length}
                        />
                      </div>
                    )
                  }
                </div>
              </Table>
            ) : <TitleSection>No data found</TitleSection>}
          </ComponentDynamic>
          <ModalDecision
            type="delete"
            title="Warning"
            body={`Are you sure you want to delete this Swap Visit ? \n delete the Swap Visit will remove all records from database.`}
            onOk={handleDeleteVisit}
            onCancel={() => {
              setIdToDelete('');
            }}
            okTitle={'DELETE'}
            {...bindModalDelete}
          />
          <ModalDecision
            title="Warning"
            body={`Are you sure you want to Approval Swap Visit ?`}
            // onOk={handleWarningVisit}
            onCancel={() => {
              setIdToDelete('');
            }}
            okTitle={'Approve'}
            {...bindModalWarning}
          />
        </div>
      )}
    </div>
  );
}
export default VisitAudit;
