import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';
import { Toast } from 'primereact/toast';
import React from 'react'
import { Fragment } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ErrorMessage } from 'shared/styles/AuthComponents';
import { PrimaryButtonForm, Toggle, ToggleLabel, ToggleWrapper } from 'shared/styles/buttons';
import { ERR_COLOR } from 'shared/styles/constants/colors';
import { FlexGrid3Container, FlexGrid3Item } from 'shared/styles/constants/tagsStyles'
import { deleteORGPassCode, getORGPassCode, resendShiftCronJobs, setORGPassCode } from 'utils/api/SettingsApi';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';

function Organization() {
    const toast = useRef()
    const { values, setValues, useInput } = useForm({});

    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingResend, setLoadingResend] = useState(false);
    const [loadingModal, setLoadingModal] = useState(true);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [textStatus, setTextStatus] = useState(SAVE);
    const [errorMessage, setErrorMessage] = useState("")
    const [showError, setShowError] = useState(false)

    const {
        setValue: setModalDeleteValue,
        bind: bindModalDelete
    } = useModal(false);

    const getPasscodeData = async () => {
        try {
            const res = await getORGPassCode()
            if (res?.results?.length) {
                setValues({ ...res.results[0], ...values })
            } else {
                setValues({})
            }
            setLoadingModal(false)
        } catch (error) {
            setLoadingModal(false)
        }
    }

    useEffect(() => {
        getPasscodeData()
    }, [])

    const handleSubmit = async () => {
        if (loadingSave) {
            return;
        }
        setLoadingSave(true)
        try {
            const body = {
                "email": values.email,
                "password": values.password,
                "two_factor_auth": values.two_factor_auth
            };
            const res = await setORGPassCode(body, values?.id)
            // console.log(res, "setORGPassCode res")
            onSuccess(res)
        } catch (error) {
            onError(error)
            console.log("Error: in setORGPassCode function", error)
        }
    }

    const onSuccess = () => {
        setLoadingSave(false);
        setButtonIcon(CHECK);
        setTextStatus(SAVED);
        getPasscodeData()
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully',
        });
    }

    const onError = (error) => {
        let getErrorMessage = 'something went wrong !!'
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        setShowError(true);
        setErrorMessage(getErrorMessage || 'something went wrong !!');
        toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: getErrorMessage,
        });
    }

    const handleDelete = async () => {
        if (values.loadingDetete) {
            return;
        }
        values.loadingDetete = true
        try {
            const res = await deleteORGPassCode(values.id)
            if (res.status === true) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: res.message,
                });
                setValues({})
                getPasscodeData()
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: 'Something went wrong',
                });
            }
            setModalDeleteValue(false)
            values.loadingDetete = false
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: 'Something went wrong',
            });
            setModalDeleteValue(false)
            values.loadingDetete = false
        }
    }

    const handleResend = async () => {
        if (loadingResend) {
            return;
        }
        setLoadingResend(true)
        try {
            const res = await resendShiftCronJobs(values.id)
            if (res.status === true) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: res.message,
                });
            }
            setLoadingResend(false)
        } catch (error) {
            setLoadingResend(false)
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: 'Something went wrong',
            });
        }
    }
    // console.log(values, "values")
    return (
        <div>
            <Toast ref={toast} />
            <ComponentDynamic loading={loadingModal}>
                <FlexGrid3Container className={'w-100 mb-1 mt-1 '}>
                    {values.id ? <div
                        onClick={() => setModalDeleteValue(true)}
                        className='pt-1 pe-3 ps-2'>
                        <RiDeleteBinLine color={ERR_COLOR} />
                    </div> : null}
                    <FlexGrid3Item ResizeWidth={values.id ? "34%" : "35%"} className='pe-1'>
                        <InputLabelTop
                            //   required
                            type="text"
                            id="enail"
                            label={'Email'}
                            {...useInput('email')}
                        //   maxlength={50}
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item ResizeWidth={values.id ? "34%" : "35%"} className='ps-1'>
                        <InputLabelTop
                            // required
                            type="text"
                            id="password"
                            label={'Passcode'}
                            {...useInput('password')}
                        //   maxlength={50}
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item ResizeWidth={values.id ? "26%" : "28%"} className='ps-1'>
                        <ToggleDiv>
                            <div className="toggle-label ms-2 mb-3 pb-3 me-2">
                                <p>Multi Factor Authentication</p>
                            </div>
                            <ToggleWrapper>
                                <Toggle
                                    id={`multi_factor_auth`}
                                    type="checkbox"
                                    disabled={false}
                                    checked={values.two_factor_auth ? true : false}
                                    onClick={() => {
                                        setValues({ ...values, two_factor_auth: !values.two_factor_auth })
                                    }}
                                />
                                <ToggleLabel htmlFor={`multi_factor_auth`} />
                            </ToggleWrapper>
                        </ToggleDiv>
                    </FlexGrid3Item>
                </FlexGrid3Container>

                <div
                    className={'d-flex justify-content-center justify-content-md-end'}
                // style={{ width: "44%" }}
                >
                    <div className='d-flex'>
                        {(showError) ? (
                            <ErrorMessage style={{ color: "#e44011" }}>
                                <label className={'mt-2'}>{errorMessage}</label>
                            </ErrorMessage>
                        ) : (
                            <Fragment />
                        )}
                        <div className={'d-flex mt-1 ms-2 mb-1'}>
                            {values.id ? <PrimaryButtonForm
                                className='me-2'
                                minWidth="6rem"
                                disabled={false}
                                onClick={() => handleResend()}>
                                <span className={'me-2'}>Resend</span>
                                {loadingResend &&
                                    <span
                                        className="spinner-border spinner-border-sm me-1"
                                        role="status"
                                    />}
                            </PrimaryButtonForm> : null}
                            <PrimaryButtonForm
                                minWidth="6rem"
                                disabled={false}
                                onClick={() => handleSubmit()}>
                                <span className={'me-2'}>{textStatus}</span>
                                {!loadingSave ? (
                                    buttonIcon
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm me-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </div>
                </div>
            </ComponentDynamic>

            <ModalDecision
                type="delete"
                title="Warning"
                body={
                    'Are you sure you want to delete this Passcode? \n This action will remove all records from database.'
                }
                onOk={handleDelete}
                onCancel={() => {
                    setModalDeleteValue(false)

                }}
                okTitle={'DELETE'}
                {...bindModalDelete}
            />
        </div>
    )
}

export default Organization