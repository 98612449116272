import React, { useEffect, useRef, useState, useContext } from 'react';
import Fade from 'react-reveal/Fade';
import Table from 'components/SharedComponents/Table';
import AddModal from 'components/DashboardComponents/Team/AddModal';
import { GrSearch } from 'react-icons/gr';
import { FaPlus } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { GrView } from 'react-icons/gr';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FloatingMobileButton } from 'shared/styles/buttons';
import { deleteEmployee, getEmployees } from 'utils/api/EmployeeApi';
import { useModal } from 'hooks/ModalHook';
import { useForm } from 'hooks/FormHook';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import queryString from 'query-string';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import Image from 'components/SharedComponents/Image';
import { AppContext } from "context"
import { BranchItemsContext } from "context"
import { useHistory, useLocation } from 'react-router-dom';
import {
  FiltersContainer,
  FiltersLeftGroup,
} from 'shared/styles/constants/tagsStyles';
import {
  getEmployeeCategories,
  getEmployeesStatus,
  getEmploymentTypes,
  getJobTitles,
} from 'utils/api/CoreApi';
import {
  EmploymentTypesContainer,
  Header,
  SearchAddContainer,
  SearchContainer,
  SearchIcon,
  Title,
} from './styles';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {
  Col,
  ColsGrouper,
  IconWrapper,
  Row,
  UserImg,
  UserImgContainer,
} from 'components/SharedComponents/Table/styles';
import {
  LARGE_COL,
  MEDIUM_COL,
  SMALL_COL,
} from 'shared/styles/constants/columns';
import { getCategoryPermission, getEmail, getPermissions, getStaff, getClient, getEmployee } from '../../../utils/localStorage/user';
import { getAdmin, getSuperAdmin } from "../../../utils/localStorage/token"
import {
  TEAM_PERMISSION,
  LIST_OF_EMPLOYEES_SETTINGS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  SELF_WEB_DELETE_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
} from '../../../utils/constants/permissions';
import { DataContainer } from 'shared/styles/constants/tagsStyles';
import { setRole } from '../../../utils/api/UsersApi';
import NoSelectAllDropdownFilterCustom from 'components/SharedComponents/NoSelectAllDropdownFilterCustom';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { list_of_employees_choices } from 'utils/choiceConstant';

const Team = props => {

  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);


  const [employees, setEmployees] = useState([]);
  const [employeesStatus, setEmployeesStatus] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [employeeCategories, setEmployeeCategories] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idToDelete, setIdToDelete] = useState('');
  const [idSelected, setIdSelected] = useState('');
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [updateSelf, setUpdateSelfPermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const [tempChoices, setTempChoices] = useState({ employee_status: [] });
  const mountedRef = useRef(true);
  const [numEmp, setNumEmp] = useState(0);
  const [runRouteData, setRunRouteData] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState([]);
  const [branch, setBranch] = useState([]);
  const [searchItem, setSearchItem] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [modalValue, setModalValue] = useState(false)
  // const[clearAll,setClearAll]=useState(false);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [employeeStatusFilter, setEmployeeStatusFilter] = useState([]);
  const [employeeStatusFilterTemp, setEmployeeStatusFilterTemp] = useState([]);
  const [employmentTypeFilter, setEmploymentTypeFilter] = useState([]);
  const [jobTitleFilter, setJobTitleFilter] = useState([]);
  const [changed, setChanged] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [title, setTitle] = useState('')
  const history = useHistory()
  const {handleChangeChoices, contextChoices, contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff,handleGetChoices } = useContext(AppContext);
  const { branchData } = useContext(BranchItemsContext);
  const { headerFilterClearValue } = useContext(BranchItemsContext);
  const [loadingModal, setLoadingModal] = useState(true);
  const { values, setValues, useInput } = useForm({ ordering: 'full_name' });

   
  useEffect(()=>{
    handleGetChoices(list_of_employees_choices)
  },[])

  const headerColumns = [
    {
      label: 'Full Name',
      name: 'full_name',
      status: 1,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Reference',
      name: 'reference',
      status: 0,
      className: '',
      max_width: SMALL_COL,
    },
    {
      label: 'Status',
      name: 'employee_status__name',
      status: 0,
      className: 'sm-hidden',
      max_width: SMALL_COL,
    },
    {
      label: 'Address',
      name: 'address__line_1',
      status: 0,
      className: 'sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Branch Name',
      name: 'job_detail__branch__name',
      status: 0,
      className: 'md-hidden',
      max_width: MEDIUM_COL,
    },
    {
      label: 'Group Area',
      name: 'job_detail__run_routes',
      status: 0,
      className: 'md-hidden',
      max_width: MEDIUM_COL,
    },

  ];

  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const location = useLocation();

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [PAGE_SIZE_RENDER])

  useEffect(() => {
    setTitle('Add Employee');
    const roles = contextPermissions;

    let rolePermissions = {};

    if (contextIsAdmin || contextIsSuperAdmin) {
      setCreatePermissions(true);
      setUpdateSelfPermissions(true)
      setDeletePermissions(true);
      setUpdatePermissions(true);
    }
    else {
      if (roles.length > 0) {
        roles.forEach(item => {

          if (item?.section?.name === LIST_OF_EMPLOYEES_SETTINGS) {
            rolePermissions[LIST_OF_EMPLOYEES_SETTINGS] = {};
            rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION] = {};
            rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION][WEB_CREATE_PERMISSION] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION][WEB_DELETE_PERMISSION] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION][SELF_WEB_DELETE_PERMISSION] = item?.[SELF_WEB_DELETE_PERMISSION];
            rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] = item?.[SELF_WEB_UPDATE_PERMISSION];
          }
        });

        setCreatePermissions(
          rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION][WEB_CREATE_PERMISSION]
        );


        setDeletePermissions(
          rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        );
        setUpdatePermissions(
          rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION][WEB_UPDATE_PERMISSION]
        );
        setUpdateSelfPermissions(rolePermissions[LIST_OF_EMPLOYEES_SETTINGS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION])
      }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextIsAdmin, contextIsSuperAdmin]);

  useEffect(() => {
    let status = contextChoices && contextChoices.employee_status && contextChoices.employee_status.filter(y => y.name === "Active")
    let status1 = contextChoices && contextChoices.employee_status && contextChoices.employee_status.filter(y => y.name === "Active - Probation")
    setValues({ ...values, employee_status: (status && status1) ? [...status, ...status1] : null })
  }, [contextChoices])


  useEffect(() => {
    if (values && values.employee_status) {
      search();
    }
  }, [
    values.employee_status,
    values.job_title,
    values.employment_type,
    values.run_route,
    values.branch,
    values.client_type,
    branchData,
    values.ordering,
    props.location.search,
    values.limitperpage,
    contextChoices
  ]);

 
  const {pathname} = useLocation();

  useEffect(() => {
    setTempChoices(contextChoices || []);
    setRunRouteData(contextChoices && contextChoices.run_routes && contextChoices.run_routes.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setEmployeeStatus(contextChoices.employee_status && contextChoices.employee_status.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setBranch(contextChoices && contextChoices.branch && contextChoices.branch.map(y => {
      return { id: y && y.id, name: y && y.name }
    }));

    setEmploymentTypes(contextChoices && contextChoices.employment_type && contextChoices.employment_type.map(y => {
      return { id: y && y.id, name: y && y.name }
    }));
    setJobTitles(contextChoices && contextChoices.job_title && contextChoices.job_title.map(y => {
      return { id: y && y.id, name: y && y.name }
    }));

    setEmployeeCategories(contextChoices && contextChoices.employee_category && contextChoices.employee_category.map(y => {
      return { id: y && y.id, name: y && y.name }
    }));

    setEmployeesStatus(contextChoices && contextChoices.employee_status && contextChoices.employee_status.map(y => {
      return { id: y && y.id, name: y && y.name }
    }));

    let status_obj = contextChoices && contextChoices.employee_status && contextChoices.employee_status.find(y => y.name === "Active")
    let status_obj2 = contextChoices && contextChoices.employee_status && contextChoices.employee_status.find(y => y.name === "Active - Probation")
    setEmployeeStatusFilter([{ id: status_obj && status_obj.id, name: status_obj && status_obj.name }, { id: status_obj2 && status_obj2.id, name: status_obj2 && status_obj2.name }])
    setEmployeeStatusFilterTemp([{ id: status_obj && status_obj.id, name: status_obj && status_obj.name }, { id: status_obj2 && status_obj2.id, name: status_obj2 && status_obj2.name }])

  }, [contextChoices])


  const getRunRoutes = (runRoute) => {
    let runroutes = runRoute && runRoute.map(item => {
      if (tempChoices && tempChoices.run_routes &&
        tempChoices.run_routes.find(choice => choice.id === item.run_routes) &&
        tempChoices.run_routes.find(choice => choice.id === item.run_routes).name) {
        return tempChoices.run_routes.find(choice => choice.id === item.run_routes).name
      }

    })
    let routes = runroutes && runroutes.join(", ")
    return routes
  }


  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =offset * values.limitperpage;    return {
      offset,
    };
  };


  const search = () => {
    const pageToSearch = getPageToSearchParam(props.location.search);

    getEmployees({ ...branchData, ...values, ...pageToSearch }, values.limitperpage).
      then(
        response => {
          if (!mountedRef.current) return null;
          if (response) {
            setLoadingModal(false);
          }
          setEmployees(response.results || []);
          setPages(Math.ceil(response.count / values.limitperpage));
          setNumEmp(Math.ceil(response.count))
        }
      );
  };


  const setRunroutefilter = (item) => {
    setRunRoutesFilter([...item])
  }
  const setFilterBranch = (item) => {

    setBranchFilter([...item])
  }
  const setFilterEmployeeStatus = (item) => {
    setEmployeeStatusFilter([...item])
    setEmployeeStatusFilterTemp([...item])
  }
  const setEmploymentType = (item) => {
    setEmploymentTypeFilter([...item])
  }
  const setJobTitle = (item) => {
    setJobTitleFilter([...item])
  }


  const dropDownHandleSelect = item => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/team",
      search: stringified,
    });
    setValues({ ...values, ...item });
  };


  const getEmployeeStatus = id => {
    if (!id) {
      return '';
    }
    let status = employeesStatus && employeesStatus.find(x => x.id === id) || {
      name: 'Unspecified',
    };
    return status.name;
  };

  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const handleAddEmployee = () => {
    setIdSelected('');
    setTitle('Add Employee');
    handleModal();
    history.push('/team/add/personalDetails')
  };

  const hitSearch = (value) => {
    values.search = value
    search();
  }

  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d)
    }
  }

  const debounceOnchange = useRef(debounce(hitSearch, 300));
  const keyPressed = e => {
    setSearchKeyword(e.target.value)
    debounceOnchange.current(e.target.value)
  };


  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };


  const toDeleteEmployee = id => {
    setModalDeleteValue(true);
    setIdToDelete(id);
  };

  const handleDeleteEmployee = async () => {
    await deleteEmployee(idToDelete);
    setModalDeleteValue(false);
    search();
    handleChangeChoices()
  };

  const handleRowClick = id => {
    setIdSelected(id);
    setTitle('Edit Employee');
    setModalValue(true);
    history.push({
      pathname: `/team/edit/${id}/personalDetails`,
      state: {
        updateSelfListOfEmployee: updateSelf,
        updateAllListOfEmployee: update
      }
    })
  };

  const createAddressFormat = address => {
    const name = address.name ? `${address.name}, ` : '';
    const line_1 = address.line_1 ? `${address.line_1}, ` : '';
    const city = address.city ? `${address.city}, ` : '';
    const zip_code = address.zip_code ? `${address.zip_code}` : '';

    return `${name}${line_1}${city}${zip_code}`;
  };


  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }

  const getBranchName = (branchId) => {
    if (branchId) {
      let branchName = branch?.find(branchdata => branchdata.id == branchId)?.name;
      return branchName;
    } else {
      return null;
    }

  }

  return (
    <div>

      <>
        <Title>List of employees</Title>
        <Header noBorder>
          <FiltersContainer>
            <FiltersLeftGroup>
              <EmploymentTypesContainer className="me-2">
                <NoSelectAllDropdownFilter
                  items={employmentTypes ? employmentTypes : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ employment_type: items });
                  }}
                  itemsSelected={employmentTypeFilter}
                  setSelectedFilter={setEmploymentType}
                  placeHolder={'Employment Type'}
                  isObject
                  allOption
                // hitApiAfterClear={hitApiAfterClear}                           
                />
              </EmploymentTypesContainer>
            </FiltersLeftGroup>
            <FiltersLeftGroup>
              <EmploymentTypesContainer className="me-2">
                {/* <Dropdown
              items={jobTitles}
              onSelect={item => {
                dropDownHandleSelect({job_title: item});
              }}
              placeHolder={'Job Title'}
              appendItem=""
              isObject
              allOption
            /> */}
                <NoSelectAllDropdownFilter
                  items={jobTitles ? jobTitles : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ job_title: items });
                  }}
                  itemsSelected={jobTitleFilter}
                  setSelectedFilter={setJobTitle}
                  placeHolder={'Job Title'}
                  isObject
                  allOption
                // hitApiAfterClear={hitApiAfterClear}                           
                />
              </EmploymentTypesContainer>

              <EmploymentTypesContainer className="me-2">
                <NoSelectAllDropdownFilter
                  items={branch ? branch : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ branch: items });
                  }}
                  itemsSelected={branchFilter}
                  setSelectedFilter={setFilterBranch}
                  placeHolder={'Branch'}
                  isObject
                  allOption
                // hitApiAfterClear={hitApiAfterClear}    
                />
              </EmploymentTypesContainer>
            </FiltersLeftGroup>
            <FiltersLeftGroup>
              <EmploymentTypesContainer className="me-2">
                <NoSelectAllDropdownFilter
                  items={runRouteData ? runRouteData : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ run_route: items });
                  }}
                  itemsSelected={runRoutesFilter}
                  setSelectedFilter={setRunroutefilter}
                  placeHolder={'Group Area'}
                  isObject
                  allOption
                // hitApiAfterClear={hitApiAfterClear}                           
                />
              </EmploymentTypesContainer>
              <EmploymentTypesContainer className=" me-2">
                <NoSelectAllDropdownFilterCustom
                  items={employeeStatus ? employeeStatus : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ employee_status: items });
                  }}

                  clientStatusFilterTemp={employeeStatusFilterTemp}

                  itemsSelected={employeeStatusFilter}
                  setSelectedFilter={setFilterEmployeeStatus}
                  placeHolder={'Employee Status'}
                  isObject
                  allOption
                // hitApiAfterClear={hitApiAfterClear}                 
                />
              </EmploymentTypesContainer>

            </FiltersLeftGroup>
          </FiltersContainer>

          <SearchAddContainer className="d-flex align-items-center">
            <SearchIcon
              onClick={() => {
                setShowSearch(!showSearch);
              }}>
              <GrSearch />
            </SearchIcon>

            <SearchContainer show={showSearch}>
              <Fade collapse when={showSearch}>
                <InputLabelTop
                  no_internal_margin="true"
                  type="text"
                  id="search"
                  label={'Search'}
                  // {...useInput('search')}
                  // onBlur={() => {
                  //   search();
                  // }}
                  // onKeyPress={e => {
                  //   keyPressed(e);
                  // }}       
                  onChange={(e) => {
                    keyPressed(e)
                  }
                  }
                  value={searchKeyword}
                />
              </Fade>
            </SearchContainer>


            {(create || (((userClient == 'null') && (userEmployee == 'null')) || filterAdmin || filterSuperAdmin)) && (
              <FloatingMobileButton onClick={handleAddEmployee} >
                <FaPlus />
                <label>NEW EMPLOYEE</label>
              </FloatingMobileButton>
            )}
          </SearchAddContainer>
        </Header>
        <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-start">

          <div className="data-container">

            <div className="me-2">
              <p>{'Employees: ' + numEmp}</p>
            </div>

          </div>
        </DataContainer>
        <ComponentDynamic loading={loadingModal} >
          <Table 
          headerColumns={headerColumns} 
          callBackQuerySort={setQuerySort} 
          // callBackQuerySort={setQuerySort}
          headerPadding={false}
          // noEditOption
          noNeed={false}  
          >
            <div>
              {employees.map((employee, index) => {
                return (
                  <div key={index}>
                    <Row bgColor>
                      <ColsGrouper
                        className="d-flex"
                        onClick={() => {
                          return update ? handleRowClick(employee.id) : null;
                        }}>
                        <Col
                          Initials
                          max_width={LARGE_COL}
                          Shrink
                          ShadowMobile
                          Capitalize
                          Center
                          overlap={true}
                        >
                          {/* <img src={purpleElipse} alt="elipse" /> */}
                          <UserImgContainer>
                            <UserImg>
                              <Image
                                src={employee.photo ? employee.photo : ''}
                                name={employee.first_name || employee.full_name}
                                lastName={employee.last_name}
                                alt={'employee-img'}
                                fontSize={'0.9rem'}
                              />
                            </UserImg>
                            <label className="align-self-center mb-0">
                              {employee.full_name}
                            </label>
                          </UserImgContainer>
                        </Col>
                        <Col max_width={SMALL_COL} Center overlap={true}>
                          <label style={{ maxWidth: '100%' }}>{employee.reference}</label>
                        </Col>
                        <Col
                          className="sm-hidden"
                          max_width={SMALL_COL}
                          Capitalize
                          Center
                          overlap={true}
                          Color={getEmployeeStatus(employee.employee_status)}>
                          {getEmployeeStatus(employee.employee_status)}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {employee.address
                            ? createAddressFormat(employee.address)
                            : ''}
                        </Col>
                        <Col
                          className="md-hidden"
                          Capitalize
                          Center
                          max_width={MEDIUM_COL}
                          overlap={true}
                        >
                          {getBranchName(employee.branch)}
                        </Col>
                        <Col
                          className="md-hidden"
                          Capitalize
                          Center
                          max_width={MEDIUM_COL} overlap={true}>
                          {getRunRoutes(employee && employee.employee_run_routes)}
                        </Col>
                        {(update || (updateSelf && (userEmployee == employee.id)) || ((userClient == 'null') && (userEmployee == 'null')) || filterAdmin || filterSuperAdmin) && (
                          <Col className="sm-hidden" Center Icon Purple>
                            <IconWrapper
                              onClick={() => {
                                handleRowClick(employee.id);
                              }}>
                              <MdEdit />
                            </IconWrapper>
                          </Col>
                        )}
                      </ColsGrouper>

                      {(deletePermission || (((userClient === 'null') && (userEmployee === 'null')) || filterAdmin || filterSuperAdmin)) && (
                        <Col className="sm-hidden" Center Shrink NoFlexGrow>
                          <IconWrapper
                            onClick={() => {
                              toDeleteEmployee(employee.id);
                            }}>
                            <RiDeleteBinLine />
                          </IconWrapper>
                        </Col>
                      )}
                    </Row>
                    <div className="mb-1" />
                  </div>
                );
              })}
              <div className="mt-3">
                <Pagination totalPages={pages} currentPage={currentPage} values={values} dropDownHandleSelect={dropDownHandleSelectPagination} number={PAGE_SIZE_RENDER} dataLength={employees.length} limitperpage={values.limitperpage} />
              </div>
            </div>
          </Table>
        </ComponentDynamic>
      </>

      {modalValue ? (
        <AddModal
          Id={idSelected}
          updateSelfListOfEmployee={updateSelf}
          updateAllListOfEmployee={update}
          setModalValue={handleModal}
          successCallback={search}
        />
      ) : (
        <></>
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this employee? \n DELETING the employee will remove ALL records from database.'
        }
        subBody={
          'TIP (Recommended) - you could change the employee status to discard instead to retain database records.'
        }
        onOk={handleDeleteEmployee}
        onCancel={() => {
          setIdToDelete('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default Team;
