import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { DropdownButton, ItemsDropdownStyle, NoSelectionFilterContainer, SelectClearButton } from '../NoSelectAllDropdownFilter/styles';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import Checkbox from '../Checkbox';
import {
  SearchAddContainer,
  SearchContainer,
} from 'shared/styles/constants/tagsStyles';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import colors from 'shared/styles/constants/colors';
import ToggleButton from '../ToggleButton';
import { useOutsideClickClosePopup } from 'shared/methods';
import { useRef } from 'react';


const NoSelectAllDropdownFilter = ({
  items,
  onSelect = () => { },
  placeHolder = 'Select',
  appendItem = '',
  alignmentRight = false,
  initialValue = '',
  isObject = false,
  width = '',
  disabled = false,
  type = '',
  maxheight = '',
  required = false,
  justifyContent = '',
  disableSelection = false,
  noPadding = false,
  // itemsSelected = [],
  // setItemsSelected = () => {},
  itemsSelected,
  setItemsSelected,
  setSelectedFilter,
  hitApiAfterClear = () => { },
  onSelectAllHitApi = () => { },
  darkPlaceHolder = false,
  maxHeight = "",
  className = ""
}) => {
  let dropdownReference = useRef(null)


  const [showDropdown, setShowDropDown] = useState(false);
  const [filteredItems, setFilteredItems] = useState([...items]);
  const [allCompanyList, setAllCompanyList] = useState([])
  const [checked, setChecked] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownLabel, setDropdownLabel] = useState('Various');





  useEffect(() => {
    const checkObj = {};
    items.forEach(data => {
      if(itemsSelected && itemsSelected.length){
        itemsSelected.forEach(item => {
          if(item.id === data.id){
            checkObj[data.id] = true;
          }
        })
      }else{
        checkObj[data.id] = false;
      }
      
    });
    setChecked({ ...checkObj });
    const arr = []
    setFilteredItems([...items]);
    setAllCompanyList(arr)
  }, [items,itemsSelected]);



  useEffect(() => {
    return function cleanup() {
      dropdownReference = null;
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, []);

  useEffect(() => {
    if (!itemsSelected?.length) setDropdownLabel("Select...");

    if (itemsSelected?.length === 1) setDropdownLabel(itemsSelected[0].name);

    if (itemsSelected?.length > 1) setDropdownLabel('Various');

  }, [itemsSelected]);

  //Dropdown formatting and logic

  const composeDropdownClasses = () => {
    let classes = 'dropdown-menu ';
    if (alignmentRight) {
      classes = classes + ' dropdown-menu-right';
    }
    if (showDropdown) {
      classes = classes + ' show';
    }

    return classes;
  };

  const setNode = nodeParam => {
    dropdownReference = nodeParam;
  };

  const handleOutsideClick = e => {
    // ignore clicks on the component itself
    if (dropdownReference !== null) {
      if (dropdownReference.contains(e.target)) {
        return;
      }
    }
    document.getElementById('search').value = '';
    search('');
    setShowDropDown(false);
  };

  const handleComboClick = () => {
    if (!showDropdown) {
    //   // attach/remove event handler
    //   document.addEventListener('click', handleOutsideClick, true);
    // } else {
    //   document.removeEventListener('click', handleOutsideClick, false);

    }
    setShowDropDown(prev => !prev);
  };

  const onClose = () => setShowDropDown(false)
  useOutsideClickClosePopup(dropdownReference, onClose)

  const theme = {
    main: type,
  };



  const search = inputValue => {
    if (inputValue === '') {
      setFilteredItems([...items]);
      setSearchTerm('');

      return;
    }

    const inputRegEx = new RegExp(inputValue.toLowerCase(), '');

    const res = items.filter(item => {
      if (inputRegEx.test(item.name.toLowerCase())) return item;

    });

    setSearchTerm(inputValue);

    setFilteredItems([...res]);

  };

  const handleClearAll = () => {
    setItemsSelected([]);
    setSelectedFilter([])
    for (let key in checked) {
      checked[key] = false;
    }
    hitApiAfterClear(true);

    setChecked({ ...checked });
  };

  const handleSelectAll = () => {
    setItemsSelected([...items]);
    setSelectedFilter([...items])
    // for (let key in checked) {
    //   checked[key] = true;
    // }

    // onSelectAllHitApi(true);
    items.forEach(data => {
      checked[data.id] = true;
    });
    setChecked({ ...checked });
  };

  const handleSelectItem = (e, item) => {
    // e.preventDefault();

    const selection = [...itemsSelected];
    selection.some((i) => i.id === item.id)
      ? selection.splice(selection.findIndex(a => a.id === item.id), 1)
      : selection.push(item);
    setItemsSelected([...selection]);
    setSelectedFilter([...selection])
    checked[item.id] = !checked[item.id];
    setChecked({ ...checked });
  };


  return (
    <NoSelectionFilterContainer
    showDropdown={showDropdown}
    ref={dropdownReference}
    >
    <div
      className={showDropdown ? `w-100 btn-group show ${className}` : `w-100 btn-group ${className}`}
      >
      <ThemeProvider theme={theme}>
        <DropdownButton
          style={{maxHeight: maxHeight || ''}}
          onClick={disabled ? () => { } : () => handleComboClick()}
          justifyContent={justifyContent}
          className="button-selected"
          width={width}
          itemsSelected={itemsSelected}
          title={itemsSelected ? 'Various' : placeHolder}
          borderColour={(itemsSelected?.length > 0) ? true : false}
          darkPlaceHolder={darkPlaceHolder}
          noPadding={noPadding}>
          <h6>
            {placeHolder} <span>{required ? '*' : ''}</span>
          </h6>
          <label>
            {dropdownLabel} <span>{required && !itemsSelected ? '*' : ''}</span>
          </label>
          <i>
            {showDropdown ? (
              <FiChevronUp className="align-self-center me-0" />
            ) : (
              <FiChevronDown className="align-self-center me-0" />
            )}
          </i>
        </DropdownButton>
      </ThemeProvider>
      <div className={`${composeDropdownClasses()} ${width}`} >
        <ItemsDropdownStyle maxheight={maxheight}>
          {/* <div className="d-flex" style={{justifyContent: 'center'}}>
            <label
              style={{
                fontSize: '0.875rem',
                marginRight: '7px',
                alignSelf: 'center',
              }}>
              Include
            </label>
            <div class="custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitches" />
              <label class="custom-control-label" for="customSwitches">Exclude</label>
            </div>
          </div> */}
          {/* <div className="d-flex p-1">
            <div className="form-check form-switch" style={{paddingLeft: '2.5rem'}}>
              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDisabled"/>
                <label className="form-check-label" for="flexSwitchCheckDisabled">Not Include</label>
            </div>
          </div> */}
          <SearchAddContainer className="d-flex align-items-center justify-content-center">
            <SearchContainer style={{ width: '100%', marginLeft: '10%' }}>
              <InputLabelTop
                title={'Select Employees'}
                no_internal_margin="true"
                type="text"
                id="search"
                label={'Search'}
                onChange={e => {
                  search(e.target.value);
                }}
                value={searchTerm}
              />
            </SearchContainer>
          </SearchAddContainer>
          <div className="d-flex" title={'save-clear-all'}>
            <SelectClearButton
              onClick={handleSelectAll}
              className="text-capitalize">
              Select All
            </SelectClearButton>
            <SelectClearButton
              onClick={handleClearAll}
              className="text-capitalize">
              Clear All
            </SelectClearButton>
          </div>
          {filteredItems ? (
            filteredItems.map((item, index) => {
              return (
                <div key={index}>
                  <button
                    className="dropdown-item text-capitalize  "
                    key={`dropdown-item-${index}`}
                    type="button"
                    onClick={
                      disabled || disableSelection
                        ? () => {
                          return;
                        }
                        : e => handleSelectItem(e, item)
                    }
                    title={!isObject ? item : item.name}>
                    <Checkbox
                      id={!isObject ? item : item.id}
                      label={!isObject ? item : item.name}
                      item={item}
                      checked={checked}
                      setChecked={setChecked}
                      handleSelectItem={handleSelectItem}
                    />
                  </button>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </ItemsDropdownStyle>
      </div>
    </div>
    </NoSelectionFilterContainer>
  );
};

export default NoSelectAllDropdownFilter;
