import React, {
  useState,
  useEffect,
  useContext,
  useModal,
  useRef,
  Fragment,
} from 'react';
import moment from 'moment';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { Toast } from 'primereact/toast';
import { getChoices } from 'utils/api/CoreApi';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { AppContext } from 'context';
import {
  getVisitTask,
  setClientTasks,
  setClientTasks1,
} from 'utils/api/ClientApi';
import { setVisitTask } from 'utils/api/ClientApi';
import DateSelector from 'components/SharedComponents/DateSelector';
import { getClientTasksById } from 'utils/api/ClientApi';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import {
  ERROR,
  SAVE,
  SAVED,
  InvalidOrEmptyValues,
} from 'utils/constants/messages';
import Geocode from 'react-geocode';
import { getModifiedVisitsId } from 'utils/api/AlertsApi';
import { AiFillPlaySquare } from 'react-icons/ai';
import { SquareStyle } from 'components/DashboardComponents/Settings/SettingsRolesAndPermissions/Table/styles';
import { PURPLE } from 'shared/styles/constants/colors';
import { useMemo } from 'react';
import './../ClientTask/taskStyle.css'
import RichTextEditor from 'components/SharedComponents/RichTextEditor';
import InputTextArea from 'components/SharedComponents/InputTextArea';


const edit_company = 'Edit Task';
const new_company = 'Add Task';
const Data_Status = [
  { id: 'Not Started', name: 'Not Started' },
  { id: 'Completed', name: 'Completed' },
  { id: 'Partially Completed', name: 'Partially Completed' },
];

// ID_NAME_CARE_CATEGORY
const TaskPopUp = ({
  fullBind,
  idSelected,
  setTitle,
  editDatas,
  setDataCheck,
  setModalValue,
  selectedClientId, loadTask,
  addData,
}) => {

  const TASK_VALUE = 'task_value';
  const [taskData, setTaskData] = useState([]);
  const [employeenameFilter, setEmployeeNameFilter] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [Data, setData] = useState();
  const [category, setCategory] = useState('');
  const [visitid, setVisitID] = useState('');
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [loadingSave, setLoadingSave] = useState(false);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [statusName, setStatusName] = useState();
  const [newArray, setNewArray] = useState([]);
  const [modalLoading, setModalLoading] = useState(true);
  const [clientTaskData, setClientTaskData] = useState([]);
  const [newCare, setCare] = useState();
  const [indexData, setIndexData] = useState();
  const [loadingModal, setLoadingModal] = useState(false);
  const [visitIDData, setVisitIDData] = useState([]);
  const toast = useRef();
  const {
    contextChoices,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextPermissions,
    contextIsStaff,
  } = useContext(AppContext);
  const [value, setValue] = useState({
    care_task: null,
    message: null,
    status: null,
  });

  useEffect(() => {
    if (idSelected) {
      setTitle(edit_company);
      // selectedClientId([])
    } else {
      setTitle(new_company);
      setLoadingModal(false);
    }
  }, [idSelected]);
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setFormErrors,
    setCheckRequires,
  } = useForm({ [TASK_VALUE]: [] });
  let addResult = useInput('result');
  useEffect(() => {
    values.care_category = editDatas.care_category;
    values.task_type = editDatas.task_type;
    values.care_task = editDatas.care_task;
    values.feedback = editDatas.feedback;
    values.message = editDatas.message;
    values.result_type = editDatas.result_type;
    values.status = editDatas.status;
    values.support_level = editDatas.support_level_id;

    values.is_essential = editDatas.is_essential;
    values.visit = editDatas.visit;
    values.client_service_visit_date =
      editDatas.start_date && editDatas.start_date.slice(0, 10);
    setValue({ ...values });
  }, [contextChoices || idSelected]);
  // console.log(contextChoices, "contextChoicescontextChoicescontextChoicescontextChoices", values, "IdSelected", idSelected)
  useEffect(() => {
    setEmployeeName(
      contextChoices &&
      contextChoices.employee_listing &&
      contextChoices.employee_listing.map(y => {
        return { id: y && y.id, name: y && y.full_name };
      })
    );
  }, [contextChoices]);

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setErrorMessage('');
  };

  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setErrorMessage(InvalidOrEmptyValues);
  };
  useEffect(() => {
    if (values.client_service_visit_date && contextChoices) visitCompleteData();
  }, [values.client_service_visit_date, idSelected]);
  const setNewTask = async () => {
    if (!isValid && errors.length > 0) {
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review'
      );
      setCheckRequires(true);
      return;
    }

    const body_data = idSelected
      ? {
        "status": values.status,
        "result": values.result,
        "message": values.message,
        "task_status": values.status,
        "support_level": values.support_level,
        "feedback": values.feedback,
        "is_essential": values.is_essential,
        "service_visit": getServiceVisitId(values.visit)
      }
      :
      {
        "care_task": values.care_task,
        "feedback": values.feedback,
        "is_essential": values.is_essential,
        "message": values.message,
        "support_level": values.support_level,
        "visit": values.visit,
        "effective_date": values.client_service_visit_date,
        "service_visit": getServiceVisitId(values.visit),
        "staus": values.status
      };
    try {
      let response = await setClientTasks(
        body_data,
        idSelected ? editDatas.id : ''
      );
      if (response.status === true) {
        setDataCheck(true);
        setModalValue(false)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${response.message}`,
        });

        loadTask()
        // setTimeout(() => {
        //   setModalValue(false);
        // }, 1000);

        onSuccess();
      } else {
        onError();
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${response.message}`,
        });
      }
    } catch {
      onError();
    }
  };

  const getOptions = value => {
    let arr = value.split(',');
    let finalArray = arr.map(item => {
      return {
        id: item,
        name: item,
      };
    });
    return finalArray;
  };
  let client_service = useInput(`client_service_visit_date`);
  let visits = useInput(`visit`, 'isRequired');

  {
    /* Function which handle the dropdown */
  }
  const handleDropDownSelect = (item, nameItem, value) => {
    // console.log({
    //   item: item,
    //   nameItem: nameItem,
    //   value: value,
    //   valueNameItem: values[nameItem],
    //   itemNameItem: item[nameItem],
    //   values: values
    // }, "_handleDropDownSelect")

    values[nameItem] = item[nameItem];
    switch (nameItem) {
      case 'care_category':
        let newTask = contextChoices.care_category.find(items => {
          if (items.id === value) {
            return items;
          }
        });
        values['task_type_option'] = newTask.task_type;
        break;
      case 'task_type':
        let newCare = contextChoices?.task_type.find(items => {
          if (items.id === value) {
            return items;
          }
        });
        values['care_task_option'] = newCare.care_task;

      case 'care_task':
        let newCareValue = contextChoices?.care_task.find(items => {
          if (items.id === value) {
            return items;
          }
        });
        let newTaskValue = contextChoices?.task_type.find(items => {
          if (items.id === newCareValue?.task_type) {
            return items;
          }
        });
        if (newCareValue) {
          values['task_type'] = newCareValue?.task_type;

          values['care_category'] = newTaskValue?.care_category;
        }
      default:
    }
    setValues({ ...values });
  };

  const getServiceVisitId = (id) => {
    const match = visitIDData && visitIDData.find(visit => visit.id === id)
    return match ? match.service_visit_id : null
  }

  const dropDownHandleSelect = (item, nameItem) => {
    const match = visitIDData && visitIDData.find(visit => visit.id === item.visit)
    values.service_visit_id = match ? match.service_visit_id : null;
    setValues({ ...values, ...item });
  };
  const fomatVisitname = (visitData) => {
    let name = visitData.job_title
      ? visitData.job_title +
      '' +
      ' ' +
      visitData.start_date?.split('T')[1] +
      '-' +
      visitData.end_date?.split('T')[1]
      : visitData.start_date?.split('T')[1] +
      '-' +
      visitData.end_date?.split('T')[1]
    return name;
  }
  const visitCompleteData = async () => {
    if (values.client_service_visit_date) {
      const response = await getModifiedVisitsId(
        selectedClientId,
        values.client_service_visit_date
      );

      let VisitDropdown = response?.data?.map(visitData => {
        return {
          id: visitData.id,
          name: fomatVisitname(visitData),
          service_visit_id: visitData.service_visit_id

        };
      });

      setVisitIDData(VisitDropdown);
      setValues({ ...values });
    }
  };

  const handleSelectChange = (value) => {
    if (value) {
      values.is_essential = false;
    }
    else {
      values.is_essential = true;

    }
    setValues({ ...values });
  };


  const handleMessage = (val) => {
    values.message = val
    setValues({ ...values })
  };

  const handleFeedback = (e, item, index) => {
    values.feedback = e.target.value;
    setValues({ ...values });
  };



  console.log("visit id001 ", contextChoices.support_level)
  return (
    <ModalContainer max_width={'80%'}>
      <ModalDynamic {...fullBind} minWidthTitle="8.5rem" loading={loadingModal}>
        <Toast ref={toast} position="top-right" />

        <div className="d-flex flex-column mt-2">
          <div className="d-flex mb-2">
            <div
              className="w-100 me-2"
              style={{ marginLeft: '10px', marginRight: '10px' }}>
              <DateSelector
                disabled={idSelected ? true : false}
                labelTop="Client service visit date"
                {...client_service}
              />
            </div>
            <div
              className="w-100 me-2"
              style={{ marginLeft: '10px', marginRight: '10px' }}>
              <DropdownSearchable
                // disabled={idSelected ? true : false}
                required
                {...visits}
                placeHolder={'Visit'}
                width={'100%'}
                options={visitIDData ? visitIDData : []}
                selectedOption={values ? values.visit : null}
                onChange={value =>
                  dropDownHandleSelect({ visit: value ? value : null }, 'visit')
                }
              />
            </div>
          </div>
        </div>

        <div className="d-flex flex-column mt-2">
          <div className="d-flex mb-2">
            <div
              className="w-100 me-2"
              style={{ marginLeft: '10px', marginRight: '10px' }}>
              <DropdownSearchable
                // disabled={idSelected ? true : false}
                placeHolder="Care Category"
                options={contextChoices.care_category}
                selectedOption={
                  values.care_category ? values.care_category : null
                }
                onChange={value =>
                  handleDropDownSelect(
                    { care_category: value ? value : null },
                    'care_category',

                    value
                  )
                }
              />
            </div>
            <div className="w-100" style={{ marginRight: '10px' }}>
              <DropdownSearchable
                // disabled={idSelected ? true : false}
                placeHolder="Task Type"
                options={
                  values.task_type_option
                    ? values.task_type_option
                    : contextChoices.task_type
                }
                selectedOption={values.task_type ? values.task_type : null}
                onChange={value =>
                  handleDropDownSelect(
                    {
                      task_type: value ? value : null,
                    },
                    'task_type',

                    value
                  )
                }
              />
            </div>

            <div
              className="w-100"
              style={{ marginLeft: '10px', marginRight: '10px' }}>
              <DropdownSearchable
                required
                // disabled={idSelected ? true : false}
                max_width={'100%'}
                {...useInput('care_task', 'isRequired')}
                placeHolder="Care Task"
                options={
                  values.care_task_option
                    ? values.care_task_option
                    : contextChoices.care_task
                }
                selectedOption={values.care_task ? values.care_task : null}
                onChange={value =>
                  handleDropDownSelect(
                    {
                      care_task: value ? value : null,
                    },
                    'care_task',

                    value
                  )
                }
              // style={{max_width: '500px'}}
              />
            </div>

            {idSelected && (
              <div className="w-100" style={{ marginRight: '10px' }}>
                <DropdownSearchable
                  // disabled={idSelected ? true : false}
                  placeHolder="Task Status"
                  options={Data_Status}
                  selectedOption={values.status ? values.status : null}
                  onChange={value =>
                    handleDropDownSelect(
                      { status: value ? value : null },
                      'status',
                      value
                    )
                  }
                />
              </div>
            )}
          </div>
        </div>

        <div className="d-flex flex-column mt-2">
          <div className="d-flex mb-2">
            <div
              className="w-100 me-2"
              style={{ marginLeft: '10px', marginRight: '10px' }}>
              <DropdownSearchable
                placeHolder="Support Level"
                // marginLeft={'0.5%'}
                options={contextChoices.support_level}
                selectedOption={
                  values.support_level ? values.support_level : null
                }
                onChange={value =>
                  handleDropDownSelect(
                    { support_level: value ? value : [] },
                    'support_level',

                    value
                  )
                }
              />
            </div>
            {/* <div
              className="w-100 me-2"
              style={{marginLeft: '10px', marginRight: '10px'}}>
              <InputLabelTop
                // margin={'5px'}
                readOnly={false}
                label={'Message'}
                type="text"
                id={`message`}
                // onChange={val =>

                // }
                {...useInput('message')}
                maxlength={100}
                key={contextChoices}
              />
            </div> */}
            <div
              className="w-100 me-2"
              style={{ marginLeft: '10px', marginRight: '10px' }}>
              <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                <div className=" mt-1 d-flex">
                  <div className=" ">
                    <small>
                      {' '}
                      <TitleSection>Essential</TitleSection>
                    </small>
                  </div>
                  <div className=" ps-1  ms-1">
                    <input
                      style={{ width: '24px', height: '24px' }}
                      type="checkbox"
                      checked={values.is_essential}
                      onChange={() => {
                        handleSelectChange(values.is_essential);
                      }}
                    />
                  </div>
                </div>
              </FlexGrid3Item>
            </div>
            <div
              className="w-100 me-2"
              style={{ marginLeft: '10px', marginRight: '10px' }}>
              {editDatas.result_type === 'option' ? (
                <DropdownSearchable
                  width={'90%'}
                  // margin={'3px'}
                  placeHolder="Result"
                  options={
                    contextChoices.result_options
                      ? getOptions(contextChoices.result_options)
                      : []
                  }
                  selectedOption={
                    values.result_option ? values.result_option : null
                  }
                  onChange={value =>
                    handleDropDownSelect(
                      {
                        result_option: value ? value : null,
                      },
                      'result_option',

                      value
                    )
                  }
                />
              ) : editDatas.result_type === 'text' ? (
                <InputLabelTop
                  placeHolder="Result"
                  // readOnly={
                  //   contextChoices.result_type === 'text' ? false : true
                  // }
                  style={{ margin: '2px' }}
                  type="text"
                  id={`result`}
                  {...addResult}
                  maxlength={100}
                  key={contextChoices}
                />
              ) : (
                <Fragment />
              )}
            </div>
          </div>
        </div>

        <div className='wrapper-class'>
          <div className='pb-3'>
            <small className="pe-1 " style={{ color: 'black' }}>Feedback</small>
            <InputTextArea
              type="text"
              height={'4rem'}
              id="feedback"
              placeholder={'Feedback'}
              value={values.feedback ? values.feedback : ''}
              onChange={e => handleFeedback(e)}
            />
          </div>
          <RichTextEditor
            value={values.message}
            onChange={newContent => handleMessage(newContent)}
          />
        </div>

        <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div
            className={'mb-3'}
            style={{ display: 'flex', justifyContent: 'right' }}>
            {errorMessage ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>
          <div
            className={'d-flex justify-content-center justify-content-md-end'}>
            <div className={'ms-0 ms-md-3 me-2'}>
              <PrimaryButtonForm
                minWidth="6rem"
                onClick={setNewTask}
                style={{ marginBottom: '5px' }}>
                <span className={'me-2'}>{textStatus}</span>
                {!loadingSave ? (
                  buttonIcon
                ) : (
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  />
                )}
              </PrimaryButtonForm>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default TaskPopUp;
