import React, { useState, useEffect, Fragment, useRef, useContext } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { useForm } from 'hooks/FormHook';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { editSettingFinance, } from 'utils/api/SettingsApi';
import {
    ERROR,
    SAVE,
    SAVED,
} from 'utils/constants/messages';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import { AppContext } from "context"
import EditableDropdownList from 'components/SharedComponents/EditableDropdownList';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';


function RateCardSettings({
    payrollTabData,
    getApiData,
    readPermission,
    createPermission,
    updatePermission,
    deletePermission,
}) {
    const readOnly = !createPermission || !updatePermission
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingSave, setLoadingSave] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { contextChoices } = useContext(AppContext);
    const [payTimeUnit, setPayTimeUnit] = useState([]);
    const [payrollPattern, setPayrollPattern] = useState([]);
    const [optionNumberData, setOptionNumberData] = useState([]);
    const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({});

    useEffect(() => {
        setPayTimeUnit(contextChoices.bill_time_unit && contextChoices.bill_time_unit.map(y => {
            return { id: y && y.id, name: y && y.name }
        }));

        setPayrollPattern(contextChoices && contextChoices.Billing_patterns && contextChoices.Billing_patterns.map(y => {
            return { id: y && y.id, name: y && y.name }
        }));

    }, [contextChoices])
    useEffect(() => {
        getApiData();
    }, [])
    useEffect(() => {
        getPayrollTabDataa();
    }, [payrollTabData])

    useEffect(() => {
        numberDropdown();
    }, [])

    const numberDropdown = () => {
        let array = [];
        for (var i = 1; i <= 21; i++) {
            array.push({ id: 5 * i - 5, name: 5 * i - 5 });
        }
        setOptionNumberData(array);

    }

    const getPayrollTabDataa = () => {
        if (payrollTabData) {
            values.actual_pay_rate_card = payrollTabData.actual_pay_rate_card && payrollTabData.actual_pay_rate_card;
            values.contract_employee_pay_rate = Number(payrollTabData.contract_employee_pay_rate && payrollTabData.contract_employee_pay_rate);
            values.contract_visit_pay_rate_card = payrollTabData.contract_visit_pay_rate_card && payrollTabData.contract_visit_pay_rate_card;
            values.pay_duration_source = payrollTabData.pay_duration_source && payrollTabData.pay_duration_source;
            values.pay_duration_type = payrollTabData.pay_duration_type && payrollTabData.pay_duration_type;
            values.pay_rate_model = payrollTabData.pay_rate_model && payrollTabData.pay_rate_model;
            values.pay_roll_pattern = payrollTabData.pay_roll_pattern && payrollTabData.pay_roll_pattern;
            values.pay_time_unit = payrollTabData.pay_time_unit && payrollTabData.pay_time_unit;

            setValues({ ...payrollTabData, ...values })
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        if (textStatus === 'SAVED') { return; }
        setLoadingSave(true);

        const bodyData = {
            payroll: {
                id: values.id,
                contract_visit_pay_rate_card: values.contract_visit_pay_rate_card, actual_pay_rate_card: values.actual_pay_rate_card,
                pay_rate_model: values.pay_rate_model,
                pay_duration_type: values.pay_duration_type,
                pay_duration_source: values.pay_duration_source,
                pay_time_unit: values.pay_time_unit,
                contract_employee_pay_rate: values.contract_employee_pay_rate,
                pay_roll_pattern: values.pay_roll_pattern
            }
        }
        await editSettingFinance(bodyData)
            .then(res => {
                if (res.message === "Finance Data Successfully Updated") {
                    onSuccess()
                }
                else { onError() }
            })
            .catch(onError)

    };

    const onSuccess = () => {
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CHECK);
        setTextStatus(SAVED);
    };

    const onError = () => {
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        // setShowError(true);
        setCheckRequires(true);
        setErrorMessage(
            "Some of the fields have invalid or empty values. Please review."
        );
    };

    const dropDownHandleSelect = (item, nameItem) => {
        values[nameItem] = item[nameItem];
        setValues({ ...values });
    };

    const handleChange = (e) => {
        values.contract_employee_pay_rate = e.target.value;
        setValues({ ...values })
    }
    return (
        <>{readPermission ? (
            <div>
                <div className="d-flex align-items-center mt-2">
                    <FlexGrid3Container className={'w-100 mb-2'}>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Contract Visit Pay Rate Card'}
                                options={[
                                    { id: 'Scheduled Visit Pay Rate Card', name: 'Scheduled Visit Pay Rate Card' },
                                    { id: 'Funder Pay Rate Card', name: 'Funder Pay Rate Card' },
                                    { id: 'Manual Pay Rate Card', name: 'Manual Pay Rate Card' },
                                ]}
                                selectedOption={values.contract_visit_pay_rate_card ? values.contract_visit_pay_rate_card : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { contract_visit_pay_rate_card: value ? value : null }, 'contract_visit_pay_rate_card'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Actual Pay Rate Card'}
                                options={[
                                    { id: 'Scheduled Visit Pay Rate Card', name: 'Scheduled Visit Pay Rate Card' },
                                    { id: 'Funder Pay Rate Card', name: 'Funder Pay Rate Card' },
                                    { id: 'Manual Pay Rate Card', name: 'Manual Pay Rate Card' },
                                ]}
                                selectedOption={values.actual_pay_rate_card ? values.actual_pay_rate_card : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { actual_pay_rate_card: value ? value : null }, 'actual_pay_rate_card'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Pay Rate Modal'}
                                options={[
                                    { id: 'Scheduled Visit Pay Rate Card', name: 'Scheduled Visit Pay Rate Card"' },
                                    { id: 'Funder Pay Rate Card', name: 'Funder Pay Rate Card' },
                                    { id: 'Manual Pay Rate Card', name: 'Manual Pay Rate Card' },
                                ]}
                                selectedOption={values.pay_rate_model ? values.pay_rate_model : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { pay_rate_model: value ? value : null }, 'pay_rate_model'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>

                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Pay Duration Type'}
                                options={[
                                    { id: 'Actual Duration', name: 'Actual Duration' },
                                    { id: 'Timesheet', name: 'Timesheet' },
                                ]}
                                selectedOption={values.pay_duration_type ? values.pay_duration_type : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { pay_duration_type: value ? value : null }, 'pay_duration_type'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Pay Duration Source'}
                                options={[
                                    { id: 'Actuals', name: 'Actuals' },
                                    { id: 'Timesheet', name: 'Timesheet' },
                                ]}
                                selectedOption={values.pay_duration_source ? values.pay_duration_source : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { pay_duration_source: value ? value : null }, 'pay_duration_source'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Pay Time Unit'}
                                options={payTimeUnit ? payTimeUnit : []}
                                selectedOption={values ? values.pay_time_unit : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { pay_time_unit: value ? value : null }, 'pay_time_unit'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            {/* <DropdownSearchable
        placeHolder={'Contract/Employee Pay Rate'}
        options={optionNumberData?optionNumberData:[]}
        selectedOption={ values.contract_employee_pay_rate  ?  values.contract_employee_pay_rate : null }
        onChange={value =>
        dropDownHandleSelect(
         {contract_employee_pay_rate: value ? value : null},  'contract_employee_pay_rate'
          )  }
        /> */}
                            <EditableDropdownList
                                placeHolder={'Contract/Employee Pay Rate'}
                                options={optionNumberData ? optionNumberData : []}
                                data={values.contract_employee_pay_rate}
                                onChnageData={handleChange}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Payroll Pattern'}
                                options={payrollPattern ? payrollPattern : []}
                                selectedOption={values.pay_roll_pattern ? values.pay_roll_pattern : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { pay_roll_pattern: value ? value : null }, 'pay_roll_pattern'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>

                    </FlexGrid3Container>
                </div>

                <div
                    className={
                        'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
                    }>
                    <div className={'mb-3'}>
                        {errorMessage ? (
                            <ErrorMessage>
                                <label className={'mt-2'}>{errorMessage}</label>
                            </ErrorMessage>
                        ) : (
                            <Fragment />
                        )}
                    </div>
                    <div className={'d-flex justify-content-center justify-content-md-end'}>
                        <div className={'ms-0 ms-md-3 me-2'}>
                            <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit} disabled={readOnly}>
                                <span className={'me-2'}>{textStatus}</span>
                                {!loadingSave ? (
                                    buttonIcon
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm me-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <NoPermissionContainer>
                You don't have permission to read the information.
            </NoPermissionContainer>
        )
        } </>
    )
}

export default RateCardSettings