import moment from 'moment'
import React from 'react'
import { AuditBody, AuditContainer } from "./styles";

function RenderTimeLine({ item }) {
    const capitalizeText = (text) => text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
    
    return (
        <AuditContainer
            markerColor={item.backgroundColor}
        >
            <div className="font-weight-bold">
                {item.category && <p>{capitalizeText(item.category)}</p>}
                <p>{item.created_at ? moment(item.created_at).format("DD-MM-YYYY") : ""}</p>
                <p>{item.created_at ? moment(item.created_at).format("HH:mm") : ""}</p>
            </div>
            <AuditBody backgroundColor={item.backgroundColor}>
                <div className='d-flex justify-content-between'>
                    <p className='font-weight-bold m-0'>{item.created_by_name}</p>
                </div>
                {/* <p className='m-0'>{`${item.updated_by_name} ${item.category}`}</p> */}
                {item.change_type && <p>{item.change_type}</p>}
                <ul className='font-weight-light'>
                    <li>{item.change_details}</li>
                </ul>
            </AuditBody>
        </AuditContainer>
    )
}

export default RenderTimeLine