import React from 'react'
import TabContent from './TabContent'
import { ErrorMessage } from 'shared/styles/AuthComponents';
import { Fragment } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function TabWraper({
    tabData,
    setValues,
    values,
    textStatus,
    loadingSave,
    loadingModal,
    buttonIcon,
    errorMessage,
    showError,
    handleSubmit,
    permision }) {

    const history = useHistory()
    console.log({ tabData, permision }, values[`${tabData?.section}`], "tabData")
    const readOnly = values[`${tabData?.section}`]?.id
        ? !permision?.updatePermission
        : !permision?.createPermission

    return (
        <>
            {permision?.readPermission ? (
                <ComponentDynamic loading={loadingModal}>
                    {
                        tabData && tabData.content && tabData.content.map(item => {
                            return (
                                <TabContent
                                    item={item}
                                    tabData={tabData}
                                    setValues={setValues}
                                    values={values}
                                    readOnly={readOnly}
                                />
                            )
                        })
                    }
                    <div className={'d-flex justify-content-center justify-content-md-end mt-3 mb-3'}>
                        <div className={'mb-3'}>
                            {showError ? (
                                <ErrorMessage>
                                    <label className={'mt-2'}>{errorMessage}</label>
                                </ErrorMessage>
                            ) : (
                                <Fragment />
                            )}
                        </div>

                        <PrimaryButtonForm
                            minWidth="6rem"
                            bgcolor="PURPLE_GRAY_500"
                            onClick={() => {
                                history.push('/client')
                            }}
                            style={{ marginBottom: '5px' }}>
                            <span className={'me-2'}>{"Cancel"}</span>
                        </PrimaryButtonForm>
                        <div className={'ms-0 ms-md-3 me-1'}>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                bgcolor="PURPLE_GRAY_500"
                                disabled={readOnly}
                                onClick={handleSubmit}
                                style={{ marginBottom: '5px' }}>
                                <span className={'me-2'}>{textStatus}</span>
                                {!loadingSave ? (
                                    buttonIcon
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm me-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </div>
                </ComponentDynamic>
            ) : (
                <NoPermissionContainer>
                    You don't have permission to access the information.
                </NoPermissionContainer>
            )
            }

        </>
    )
}

export default TabWraper