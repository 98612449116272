import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Radio, RadioGroup, Switch, TextField, Tooltip } from '@mui/material'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import DropDown from '../FormDesigns/dropDown'
import DropDownForChoices from '../FormDesigns/dropDownForChoices'
import { setTag } from '../service'
import Backdrop from '@mui/material/Backdrop';
import MatBox from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import SignatureCanvas from 'react-signature-canvas'
import { useState } from 'react'
import Table from 'components/SharedComponents/Table'
import { Col, ColsGrouper, IconWrapper, Row } from 'components/SharedComponents/Table/styles'
import { LARGE_COL } from 'shared/styles/constants/columns'
import { HTML_NAMES } from '../constants'
import DropDownTableOptions from '../FormDesigns/dropDownTableOptions'
import {
    Unstable_NumberInput as BaseNumberInput,
    numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import './../../Client/ClientTask/taskStyle.css'
import { useRef } from 'react'
import { useMemo } from 'react'
import { ERR_COLOR } from 'shared/styles/constants/colors'
import { DOWNLOAD, INFO_ICON } from 'utils/constants/icons'
import './../formdata.css'
import { RiDeleteBinLine } from 'react-icons/ri'
import ReactHtmlParser from 'react-html-parser';
import { useEffect } from 'react'
import { GrTableAdd } from "react-icons/gr";
import { useModal } from 'hooks/ModalHook'
import SearchModalRichText from './SearchModalRichText'
import { UploadPhotoContainer } from 'shared/styles/constants/tagsStyles'
import { PrimaryButtonForm, UploadInput } from 'shared/styles/buttons'
import { FiUpload } from 'react-icons/fi'
import moment from 'moment'
import Model, { IExerciseData, IMuscleStats } from 'react-body-highlighter';
import { FaPlus } from 'react-icons/fa'
import BodyMapModal from '../BodyMapModal'
import * as html2pdf from 'html2pdf.js'
import RichTextEditor from 'components/SharedComponents/RichTextEditor'

const blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const StyledInputRoot = styled('div')(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 400;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
        };
    display: grid;
    grid-template-columns: 1fr 19px;
    grid-template-rows: 1fr 1fr;
    overflow: hidden;
    column-gap: 8px;
    padding: 4px;
  
    &.${numberInputClasses.focused} {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
    }
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const StyledInputElement = styled('input')(
    ({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;
    grid-column: 1/2;
    grid-row: 1/3;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: inherit;
    border: none;
    border-radius: inherit;
    padding: 8px 12px;
    outline: 0;
  `,
);

const StyledButton = styled('button')(
    ({ theme }) => `
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    appearance: none;
    padding: 0;
    width: 19px;
    height: 19px;
    font-family: system-ui, sans-serif;
    font-size: 0.875rem;
    line-height: 1;
    box-sizing: border-box;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 0;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
      cursor: pointer;
    }
  
    &.${numberInputClasses.incrementButton} {
      grid-column: 2/3;
      grid-row: 1/2;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid;
      border-bottom: 0;
      border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  
      &:hover {
        cursor: pointer;
        color: #FFF;
        background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
        border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
      }
    }
  
    &.${numberInputClasses.decrementButton} {
      grid-column: 2/3;
      grid-row: 2/3;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid;
      border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    }
  
    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
    }
  
    & .arrow {
      transform: translateY(-1px);
    }
  
    & .arrow {
      transform: translateY(-1px);
    }
  `,
);


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40vw",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function FormViewer({ lastDroppedItem, contextChoices ,title="", handleChangeChoices = () => {}}) {
    const [jsonData, setJsonData] = useState([])
    const [popUpData, setPopUpData] = useState({})
    const [loadingExport, setLoadingExport] = useState(false)
    const {
        setValue: setSearchValueRich,
        value: searchValueRich,
        fullBind: fullBindSearchValueRich,
    } = useModal(false);
    const {
        setValue: setModalBodyMap,
        value: modalBodyMap,
        fullBind: fullBindBodyMap,
      } = useModal(false);
    const [openSignature, setOpenSignature] = useState(false);
    useEffect(() => {
        if (Array.isArray(lastDroppedItem) && lastDroppedItem.length === 0) return;
        setJsonData(lastDroppedItem);
        // lastDroppedItem.filter(item => item.type === HTML_NAMES.choices).map(item => item.choices);
        let choicesToFetch = []
        lastDroppedItem.forEach((item => {
            if (item.type === "Table" || item.type === "Section") {
                let _ = item?.cols && item?.cols?.length && item?.cols?.forEach(col => {
                    if (col.type === "Choice Dropdown") {
                        choicesToFetch.push(col.options)
                    }
                })

            } else if (item.type === "Choices") {
                choicesToFetch.push(item.choices)
            }

        }))
        let unique = [...new Set(choicesToFetch)];
        if (unique.length)
            handleChangeChoices(unique)

    }, [lastDroppedItem])

    const CustomNumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
        return (
            <BaseNumberInput
                slots={{
                    root: StyledInputRoot,
                    input: StyledInputElement,
                    incrementButton: StyledButton,
                    decrementButton: StyledButton,
                }}
                slotProps={{
                    incrementButton: {
                        children: '▴',
                    },
                    decrementButton: {
                        children: '▾',
                    },
                }}
                {...props}
                ref={ref}
            />
        );
    });

    const renderCols = (counts) => {
        switch (counts) {
            case "1":
                return "12";
            case "2":
                return "6";
            case "3":
                return "4";
            case "4":
                return "3";
            case "5":
                return "2";
            case "6":
                return "2";
            default:
                return "3";
        }
    }

    const renderTableRow = (val, item, index) => {
        if(!val) return null;
        switch (val?.type) {
            case "Text":
                return (
                    <div className='w-100'>
                        <label className='w-100' for="fname" style={{}} >{val?.value}</label>
                    </div>
                )
            case HTML_NAMES.textInput:
                return (
                    <div className='w-100'>
                        <input className='w-100' type="text" id="fname" name="fname" value={val?.value} placeholder={val?.placeholder} style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }} />
                    </div>
                )
            case HTML_NAMES.dropdown:
                return (
                    <div className='w-100'>
                        <DropDownTableOptions className='w-100' contextChoices={contextChoices} json={val} minWidth={'100%'} />
                    </div>
                )
            case 'Choice Dropdown':
                return (
                    <div className='w-100'>
                        <DropDownTableOptions className='w-100' contextChoices={contextChoices} json={val} minWidth={'100%'} />
                    </div>
                )
            case HTML_NAMES.headingText:
                return (
                    <div className='w-100'>
                        {setTag({ tag: val?.html, label: val?.value })}
                    </div>
                )
            case HTML_NAMES.numberInput:
                return (
                    <div className='w-100'>
                        <CustomNumberInput className='w-100' aria-label="Demo number input" placeholder="Type a number…" />
                    </div>
                )
            case HTML_NAMES.decimalInput:
                return (
                    <div className='w-100'>
                        <input className='w-100' type="number" onkeydown="if(this.key==='.'){this.preventDefault();}" id="myInput" name="inputBox" placeholder={item?.placeholder} style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }} />
                    </div>
                )
            case HTML_NAMES.multidropdown:
                return (
                    <div className='w-100'>
                        <DropDownTableOptions className='w-100' contextChoices={contextChoices} json={val} minWidth={'100%'} multiple={true} />
                    </div>
                )
            case HTML_NAMES.switch:
                return (
                    <div className='w-100'>
                        <FormControlLabel
                            value="start"
                            control={
                                <Switch color="primary" />}
                            label={<div>
                                <label for="fname" style={{ marginRight: 10, fontWeight: "bold" }} >{val?.placeholder}:</label>
                                {val?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{val?.description}</p>}
                            </div>}
                            style={{ width: '100%', fontWeight: "bold", justifyContent: "flex-start" }}
                            labelPlacement="top"
                        />
                    </div>
                )
            case HTML_NAMES.hyperLink:
                return (
                    <div className='w-100'>
                        {<p style={{ width: '100%', marginBottom: 8, fontSize: 12 }}>
                            <a href={`${val?.description}`}>{val?.placeholder}</a>
                        </p>}
                    </div>
                )
            case HTML_NAMES.radioGroup:
                return (
                    <div className='w-100'>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                            className='w-100'
                        >
                            {
                               val?.options && val?.options?.map((val, index) => {
                                    return (
                                        <FormControlLabel key={index} value={val?.value} control={<Radio />} label={val?.value} />
                                    )
                                })
                            }
                        </RadioGroup>
                    </div>
                )
            case HTML_NAMES.checkbox:
                return (
                    <div className='w-100'>
                        <FormGroup row className='w-100'>
                            {
                               val?.options && val?.options?.map((val, index) => {
                                    return (
                                        <FormControlLabel control={<Checkbox />} label={val?.value} />
                                    )
                                })
                            }
                        </FormGroup>
                    </div>
                )
            case HTML_NAMES.textArea:
                return (
                    <div className='w-100'>
                        <TextField
                            className='w-100'
                            id="outlined-multiline-static"
                            label={val?.label}
                            multiline
                            rows={Number(val?.html)}
                            placeholder={val?.placeholder}
                        // style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                        />
                    </div>
                )
            case HTML_NAMES.datePicker:
                return (
                    <div className='w-100'>
                        <ReactDatePicker
                            dateFormat={val.format==="" ? undefined: val.format}
                            className='w-100'
                            selected={""}
                            style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                            //   onSelect={handleDateSelect}
                            //   onChange={handleDateChange} 
                            label="Basic date picker" />
                    </div>
                )
            case HTML_NAMES.timePicker:
                return (
                    <div className='w-100'>
                        <ReactDatePicker
                            className='w-100'
                            selected={""}
                            style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeFormat={val.format==="" ? undefined: val.format}
                            dateFormat={val.format==="" ? "p": val.format}
                            label="Basic date picker" />
                    </div>
                )
            case HTML_NAMES.dateTime:
                let dateFormat = val.format;
                let timeFormat = "";
                if (val.format) {
                timeFormat = val.format.split(" ")[1];
                if (val.format.split(" ")[2])
                    timeFormat = timeFormat+" "+val.format.split(" ")[2]
                } else timeFormat = undefined
                return (
                    <div className='w-100'>
                        <ReactDatePicker
                            dateFormat={item.format==="" ? "Pp": dateFormat}
                            timeFormat={timeFormat}
                            className='w-100'
                            selected={""}
                            style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                            showTimeSelect
                        />
                    </div>
                )
            case HTML_NAMES.signature:
                return (
                    <div className='w-100'>
                        <Button onClick={() => setOpenSignature(true)} variant={val?.html || "outlined"}>{val?.label}</Button>
                    </div>
                )
                default : break;
        }
    }

    const handleDownlaodPdf = () => {
        const input = document.querySelector('#dustbin-pdf');
        const current_date = moment().format('DD-MM-YYY')
        var opt = {
            filename: `form_${current_date}`,
            pagebreak: { mode: 'avoid-all' },
            image: { quality: 1 },
            html2canvas: {
                dpi: 192,
                scale: 1,                
                letterRendering: true,
                useCORS: true
              },
          };
        setLoadingExport(true)
        setTimeout(() => {
            html2pdf().set(opt).from(input).save().then(() => {
                setLoadingExport(false)
            })
        },1)
    };

    function isNumberOrString(value) {
        // Convert the value to a number using the unary plus operator
        // If the conversion results in NaN, it's not a number
        if (!isNaN(+value)) {
            return true;
        } else {
            return false;
        }
    }


    function evaluateLogicalExpression(expression) {
        // Split the expression into individual tokens (operands and operators)

        const tokens = expression


        let result = false; // Initialize result as false

        // Function to perform arithmetic operations
        const evaluateArithmetic = (num1, op, num2) => {
            switch (op) {
                case '+':
                    return num1 + num2;
                case '-':
                    return num1 - num2;
                case '*':
                    return num1 * num2;
                case '/':
                    return num1 / num2;
                default:
                    return NaN; // Invalid operator
            }
        };

        // Function to perform comparison operations
        const evaluateComparison = (num1, op, num2) => {
            switch (op) {
                case '==':
                    return num1 == num2;
                case '>':
                    return num1 > num2;
                case '<':
                    return num1 < num2;
                case '>=':
                    return num1 >= num2;
                case '<=':
                    return num1 <= num2;
                default:
                    return false; // Invalid operator
            }
        };

        for (let i = 0; i < tokens.length; i += 2) {
            if (i === tokens.length - 1) {
                break; // Exit loop if there's no operator after the last token
            }
            let num1, op, num2, op2;

            if ((tokens[i + 1] == '&&') || tokens[i + 1] == '||') {
                num1 = isNumberOrString(tokens[i + 2]) ? parseInt(tokens[i + 2]) : tokens[i + 2];
                op = tokens[i + 1];
                op2 = tokens[i + 3]
                num2 = isNumberOrString(tokens[i + 4]) ? parseInt(tokens[i + 4]) : tokens[i + 4];
            } else {
                num1 = isNumberOrString(tokens[i]) ? parseInt(tokens[i]) : tokens[i];
                op = tokens[i + 1];
                num2 = isNumberOrString(tokens[i + 2]) ? parseInt(tokens[i + 2]) : tokens[i + 2];
            }

            if ((i == 0) && ((op === '==') || (op === '<') || (op === '>') || (op === '<=') || (op === '>='))) {
                result = (i == 0) ? evaluateComparison(num1, op, num2) : (result && evaluateComparison(num1, op, num2));
            } else if (op === '&&') {
                result = result && evaluateComparison(num1, op2, num2);
            } else if (op === '||') {
                result = result || evaluateComparison(num1, op2, num2);
            } else {
                // Perform arithmetic operations
                const res = evaluateArithmetic(num1, op, num2);
                if (!isNaN(res)) {
                    tokens.splice(i, 3, res.toString()); // Replace the operands and operator with the result
                    i -= 2; // Adjust the loop index to account for the replaced tokens
                }
            }
        }
        console.log(result); // Output: true (if the expression evaluates to true)

        return result

    }

    function evaluateArithmetic(expression) {
        // Regular expression to split the expression into operands and operators
        const tokens = expression.match(/\d+|\+|\-|\*|\//g);

        if (!tokens) {
            return NaN; // Invalid expression
        }

        let result = parseFloat(tokens[0]); // Initialize result with the first operand

        for (let i = 1; i < tokens.length; i += 2) {
            const operator = tokens[i];
            const operand2 = parseFloat(tokens[i + 1]);

            if (isNaN(operand2)) {
                return NaN; // Invalid expression
            }

            // Perform arithmetic operation based on the operator
            switch (operator) {
                case '+':
                    result += operand2;
                    break;
                case '-':
                    result -= operand2;
                    break;
                case '*':
                    result *= operand2;
                    break;
                case '/':
                    if (operand2 === 0) {
                        return NaN; // Division by zero
                    }
                    result /= operand2;
                    break;
                default:
                    return NaN; // Invalid operator
            }
        }

        return result;
    }

    console.log('preview data', jsonData)


    const checkRuleExpression = (item) => {
        // let sym = ['&&', '||','!=', '!==', '==', '>', '>', '>=', '<='].find(sym => item?.ruleExpression.includes(sym));
        // if (!sym) return false;

        let fieldValues = item?.ruleExpression.split(' ').map(exp => exp.trim());
        const arr = fieldValues?.map(ev => {
            if (ev.startsWith('Field')) {
                const fieldData = ((jsonData?.find(data => `Field${data.id}` == ev)?.value == false) ? 'false' : (jsonData.find(data => `Field${data.id}` == ev)?.value || ''));
                return `${fieldData}`;
            } else {
                return ev;
            }
        });

        const data = arr.join(' ');

        let result = '';
        try {
            result = evaluateLogicalExpression(arr);

        } catch (error) {
            console.error('check exp_rule error', error);
        }

        return result
    }



    const calculateExpressions = (item, field_value) => {
        const val = item?.evaluateExpression.split(' ');

        const arr = val?.map(ev => {
            if (ev.startsWith('Field')) {
                const fieldData = field_value;
                return fieldData;
            } else {
                return ev;
            }
        });

        const data = arr.join('');

        let result = '';
        try {
            result = evaluateArithmetic(data);

        } catch (error) {
            console.error('Error evaluating expression:', error);
        }

        return result || '';
    };



    function findAllIndicesOfValue(arr, value) {
        const indices = [];

        // Iterate over the array
        for (let i = 0; i < arr.length; i++) {
            // If the value at the current index matches the target value, push the index to the indices array
            if (arr[i].evaluateExpression && arr[i].evaluateExpression.includes(`Field${value.id}`)) {
                indices.push(i);
            }
        }

        return indices;
    }

    const calculationChecks = (item, value) => {
        const indices = findAllIndicesOfValue(jsonData, item)
        // const fieldDataIndex = item?.id && jsonData.findIndex(data => data.evaluateExpression && data.evaluateExpression.includes(`Field${item?.id}`));
        indices.forEach((index_d) => {
            jsonData[index_d].value = jsonData[index_d].evaluateCondition && calculateExpressions(jsonData[index_d], value)
        })
        // jsonData[fieldDataIndex].value = jsonData[fieldDataIndex].evaluateCondition && calculateExpressions(jsonData[fieldDataIndex], value)
        setJsonData([...jsonData])
    }

    const getBodyData=(body_data)=>{
        const listOfBodyParts = ['trapezius', 'upper-back', 'lower-back', 'chest', 'biceps', 'triceps', 'forearm', 'back-deltoids', 'front-deltoids', 'abs', 'obliques', 'adductor', 'hamstring', 'quadriceps', 'abductors', 'calves', 'gluteal', 'head', 'neck','knees','left-soleus', 'right-soleus']
        const muscleFromBody = body_data?.every(item => listOfBodyParts.includes(item));
        const data = [
            { name: '', muscles: (muscleFromBody && body_data) ? [...body_data] : [] },
        ];
    return data
    }
    function isDateValid(dateStr) {
        return !isNaN(new Date(dateStr));
      }

    const renderDesign = (item, index) => {
        let show = (item?.showCondition && item?.ruleExpression) ? checkRuleExpression(item) : true
        // let result = item?.evaluateCondition ? calculateExpressions(item) : item?.value
        if (show) {
            switch (item?.type) {
                case "Heading text":
                    return setTag({ tag: item?.html, label: item?.value })

                case "Text Input":
                    return <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                        <div style={{ flex: 1, marginBottom: '8px' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                {item?.instruction &&
                                    <Tooltip title={item?.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item?.description && <p style={{ fontSize: 12, padding: 0, margin: 0 }}>{item?.description}</p>}
                        </div>
                        <input
                            type="text"
                            id="text1"
                            name="text1"
                            // disabled={item?.showCondition}
                            placeholder={item?.placeholder}
                            value={item?.value}
                            style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8", flex: 1 }}
                            onChange={(text) => {
                                if (isNumberOrString(text.target.value)) {
                                    calculationChecks(item, text.target.value)
                                }
                                const data = jsonData.map((val, newIndex) => {
                                    if (newIndex === index) {
                                        return (
                                            {
                                                ...val,
                                                value: text.target.value
                                            }
                                        )
                                    }
                                    return val
                                })
                                setJsonData(data)
                            }}
                        />
                    </div>

                case "Number Input":
                    return (<div style={{ width: '100%', display: "flex", flexDirection: 'column', alignItems: item?.description ? "flex-start" : "center" }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                            {item?.instruction &&
                                <Tooltip title={item?.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                        <CustomNumberInput
                            className='w-100'
                            aria-label="Demo number input"
                            placeholder="Type a number…"
                            value={item?.value}
                            disabled={item?.readOnly}
                            onChange={(event) => {
                                calculationChecks(item, event.target.value)
                                const data = jsonData?.map((j_val, newIndex) => {
                                    if (newIndex === index) {
                                        return (
                                            {
                                                ...j_val,
                                                value: event.target.value
                                            }
                                        )
                                    }
                                    return j_val
                                })
                                setJsonData(data)
                            }}
                        />
                        {/* <input type="number"  id="myInput"  name="inputBox" placeholder={item?.placeholder} style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }} /> */}
                    </div>)

                case "Decimal Input":
                    return (<div style={{ width: '100%', display: "flex", flexDirection: 'column', alignItems: item?.description ? "flex-start" : "center" }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                            {item?.instruction &&
                                <Tooltip title={item?.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                        {/* <input type="number" className='w-100' onkeydown="if(this.key==='.'){this.preventDefault();}" id="myInput" name="inputBox" placeholder={item?.placeholder} style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }} /> */}
                        <input
                            type="number"
                            className='w-100'
                            // onkeydown="if(this.key==='.'){this.preventDefault();}" 
                            id="inputdecimal"
                            disabled={item?.readOnly}
                            name="inputDecimal"
                            placeholder={item?.placeholder}
                            value={item?.value}
                            style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                            onChange={(event) => {
                                calculationChecks(item, event.target.value)
                                const data = jsonData.map((j_val, newIndex) => {
                                    if (newIndex === index) {
                                        return (
                                            {
                                                ...j_val,
                                                value: event.target.value
                                            }
                                        )
                                    }
                                    return j_val
                                })
                                setJsonData(data)
                            }}
                        />
                    </div>)
                case "Multi Dropdown":
                    return (
                        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                {item?.instruction &&
                                    <Tooltip title={item?.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                            <DropDown minWidth={'100%'} jsonData={jsonData} setJsonData={setJsonData} json={item} index={index} multiple={true} />
                        </div>)
                case "Hyper Link":
                    return (
                        <div style={{ display: "flex", flexDirection: 'column', alignItems: item?.description ? "flex-start" : "center" }}>
                            <div style={{ textAlign: 'left' }}>
                                <div className='w-100 d-flex justify-content-between'>
                                    <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                    {item?.instruction &&
                                        <Tooltip title={item?.instruction}>
                                            <IconButton size={'small'}>
                                                {INFO_ICON}
                                            </IconButton>
                                        </Tooltip>}
                                </div>
                                {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                            </div>
                            {<p style={{ marginBottom: 8, fontSize: 12 }}>
                                <a href={`${item?.value}`}>{item?.placeholder}</a>
                            </p>}
                        </div>)

                case "Dropdown":
                    return (
                        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                {item?.instruction &&
                                    <Tooltip title={item?.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                            <DropDown minWidth={'100%'} jsonData={jsonData} setJsonData={setJsonData} json={item} index={index} />
                        </div>)

                case "Toggle":
                    return (
                        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ flex: 1 }}>
                                <div className='w-100 d-flex'>
                                    <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                    {item?.instruction &&
                                        <Tooltip title={item?.instruction}>
                                            <IconButton size={'small'}>
                                                {INFO_ICON}
                                            </IconButton>
                                        </Tooltip>}
                                </div>
                                {item?.description && <p style={{ marginBottom: 8, fontSize: 12, margin: 0, padding: 0 }}>{item?.description}</p>}
                            </div>
                            <FormControlLabel
                                value="start"
                                control={
                                    <Switch
                                        color="primary"
                                        checked={item?.value}
                                        onChange={(item) => {
                                            calculationChecks(item, item?.target.checked)
                                            const data = jsonData.map((val, newIndex) => {
                                                if (newIndex === index) {
                                                    return (
                                                        {
                                                            ...val,
                                                            value: item?.target.checked
                                                        }
                                                    )
                                                }
                                                return val
                                            })
                                            setJsonData(data)

                                        }}
                                    />}
                                label={""}
                                style={{ fontWeight: "bold", justifyContent: "left", marginLeft: '0px', marginTop: '5px' }}
                                labelPlacement="start"
                            />
                        </div>)
                case 'Table':
                    return (
                        <div className='w-100'>
                            <div style={{ textAlign: 'left' }}>
                                <div className='w-100 d-flex justify-content-between'>
                                    <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                    {item?.instruction &&
                                        <Tooltip title={item?.instruction}>
                                            <IconButton size={'small'}>
                                                {INFO_ICON}
                                            </IconButton>
                                        </Tooltip>}
                                </div>
                                {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                            </div>
                            <div style={{ border: '2px solid #fafafc' }}>
                                <Table
                                    headerColumns={item?.cols ?
                                        item?.cols.map((val) => ({
                                            label: val?.column_name,
                                            name: '',
                                            status: 0,
                                            className: 'grow-mobile',
                                            max_width: LARGE_COL,
                                        }))
                                        :
                                        []
                                    }
                                    // callBackQuerySort={setQuerySort}
                                    headerPadding={false}
                                    handleAllCheck={() => { }}
                                    noEditOption
                                    noNeed={true}
                                    handleAllSelectChange={() => { }}
                                    noFade={true}>
                                    <div>
                                        {item?.rows &&
                                            item?.rows.map((table_d, index) => {
                                                return (
                                                    <div>
                                                        <Row bgColor>
                                                            <ColsGrouper className="d-flex">
                                                                {item?.cols && item?.cols.map((val, i) => {
                                                                    return (
                                                                        <>
                                                                            <Col
                                                                                max_width={LARGE_COL}
                                                                                Center>
                                                                                {renderTableRow(val, table_d, index)}
                                                                            </Col>
                                                                        </>
                                                                    )
                                                                })
                                                                }
                                                                <Col Center Shrink NoFlexGrow>
                                                                    <IconWrapper
                                                                        onClick={() => {
                                                                            // toDeleteClient(client.id);
                                                                        }}>
                                                                        <RiDeleteBinLine />
                                                                    </IconWrapper>
                                                                </Col>
                                                            </ColsGrouper>
                                                        </Row>

                                                        <div className="mb-1" />
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </Table>
                            </div>
                        </div>
                    )

                case "Section":
                    return (
                        <div style={{ border: '2px solid #fafafc', width: '100%' }}>
                            <div className='text-start ms-2'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold", fontSize: '20px' }} >{item?.label}</label>
                            </div>
                            <div className="container">
                                <div className="row pt-2">
                                    {item?.cols &&
                                        item?.cols.map((sec_d, index) => {
                                            if(!sec_d) return null;
                                            return (
                                                <div className={`col-${renderCols(item?.sectionColsCount)}`}>
                                                    <div style={{ textAlign: 'left', width: '100%' }}>
                                                        <div className='w-100 d-flex justify-content-between'>
                                                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{sec_d?.label}<span style={{ color: ERR_COLOR }}>{sec_d?.required && '*'}</span></label>
                                                            {item?.instruction &&
                                                                <Tooltip title={item?.instruction}>
                                                                    <IconButton size={'small'}>
                                                                        {INFO_ICON}
                                                                    </IconButton>
                                                                </Tooltip>}
                                                        </div>
                                                        {sec_d?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{sec_d?.description}</p>}
                                                    </div>
                                                    <div className='w-100 my-1'>
                                                        {renderTableRow(sec_d, sec_d, index)}
                                                    </div>
                                                    {/* {sec_d.sectionRowCount} */}
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    )

                case "Button":
                    return (<div style={{
                        display: "flex",
                        flexDirection: "row"
                    }}>
                        <Button variant={item?.html}>{item?.label}</Button>
                    </div>)

                case "Radio Group":
                    return (
                        <FormControl style={{ alignSelf: "flex-start", display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between", marginLeft: '8px' }}>
                            <div style={{ textAlign: 'left' }}>
                                <div className='w-100 d-flex justify-content-between'>
                                    <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                    {item?.instruction &&
                                        <Tooltip title={item?.instruction}>
                                            <IconButton size={'small'}>
                                                {INFO_ICON}
                                            </IconButton>
                                        </Tooltip>}
                                </div>
                                {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                            </div>
                            <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                value={item?.value}
                                onChange={nextValue => {
                                    console.log('radio', nextValue.target.value)
                                    // calculationChecks(item, nextValue.target.value)
                                    const data = jsonData?.map((val, newIndex) => {
                                        if (newIndex === index) {
                                            return (
                                                {
                                                    ...val,
                                                    value: nextValue.target.value
                                                }
                                            )
                                        }
                                        return val
                                    })
                                    setJsonData(data)
                                }}
                            >
                                {
                                    item?.options?.map((val, index) => {
                                        return (
                                            <FormControlLabel key={index} value={val?.value} control={<Radio />} label={val?.value} />
                                        )
                                    })
                                }
                            </RadioGroup>
                        </FormControl >
                    )

                case "Button Group":
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column"
                            }}>
                            {
                                item?.options?.map((val, index) => {
                                    return (
                                        <Button key={index} variant={val?.type} style={{ marginRight: 5 }}>{val?.value}</Button>
                                    )
                                })
                            }
                        </div>
                    )


                case "Checkbox":
                    return (
                        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between", marginLeft: '8px' }}>
                            <div style={{ textAlign: 'left' }}>
                                <div className='w-100 d-flex justify-content-between'>
                                    <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                    {item?.instruction &&
                                        <Tooltip title={item?.instruction}>
                                            <IconButton size={'small'}>
                                                {INFO_ICON}
                                            </IconButton>
                                        </Tooltip>}
                                </div>
                                {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                            </div>
                            <FormGroup row >
                                {
                                    item?.options?.map((val1, index2) => {
                                        return (
                                            <FormControlLabel control={
                                                <Checkbox
                                                    checked={(item?.value.includes(val1.value)) ? true : false}
                                                    key={index2}
                                                    onChange={(text) => {
                                                        const data = jsonData.map((val, newIndex) => {
                                                            if (newIndex === index) {
                                                                if (val?.value.includes(val1.value)) {
                                                                    const array = val?.value;
                                                                    const index_arr = array.indexOf(val1.value);
                                                                    array.splice(index_arr, 1);
                                                                    let final_val = array;
                                                                    return (
                                                                        {
                                                                            ...val,
                                                                            value: final_val
                                                                        }
                                                                    )
                                                                } else {
                                                                    return (
                                                                        {
                                                                            ...val,
                                                                            value: val?.value ? [...val?.value, val1.value] : [val1.value]
                                                                        }
                                                                    )
                                                                }
                                                            }
                                                            return val
                                                        })
                                                        setJsonData(data)
                                                    }}
                                                />
                                            } label={val1.value} />
                                        )
                                    })
                                }
                            </FormGroup>
                        </div>
                    )

                case "Text Area":
                    return (
                        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "flex-start" }}>
                            <div style={{ textAlign: 'left' }}>
                                <div className='w-100 d-flex justify-content-between'>
                                    <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                    {item?.instruction &&
                                        <Tooltip title={item?.instruction}>
                                            <IconButton size={'small'}>
                                                {INFO_ICON}
                                            </IconButton>
                                        </Tooltip>}
                                </div>
                                {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                            </div>
                            <TextField
                                id="outlined-multiline-static"
                                label={item?.label}
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& .MuiInputLabel-shrink': { opacity: 0, transition: "all 0.2s ease-in" }
                                }}
                                multiline
                                rows={Number(item?.html)}
                                style={{ width: "100%" }}
                                placeholder={item?.placeholder}
                                value={item?.value}
                                onChange={(text) => {
                                    if (isNumberOrString(text.target.value)) {
                                        calculationChecks(item, text.target.value)
                                    }
                                    const data = jsonData.map((val, newIndex) => {
                                        if (newIndex === index) {
                                            return ({
                                                ...val,
                                                value: text.target.value
                                            })
                                        }
                                        return val
                                    })
                                    setJsonData(data)
                                }}
                            />
                        </div>
                    )

                case "Choices":
                    return (
                        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ textAlign: 'left' }}>
                                <div className='w-100 d-flex justify-content-between'>
                                    <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                    {item?.instruction &&
                                        <Tooltip title={item?.instruction}>
                                            <IconButton size={'small'}>
                                                {INFO_ICON}
                                            </IconButton>
                                        </Tooltip>}
                                </div>
                                {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                            </div>
                            <DropDownForChoices jsonData={jsonData} setJsonData={setJsonData} json={item} index={index} />
                        </div>
                    )

                case "Date":
                    return (
                        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ textAlign: 'left' }}>
                                <div className='w-100 d-flex justify-content-between'>
                                    <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                    {item?.instruction &&
                                        <Tooltip title={item?.instruction}>
                                            <IconButton size={'small'}>
                                                {INFO_ICON}
                                            </IconButton>
                                        </Tooltip>}
                                </div>
                                {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                            </div>
                            <div className='formdata w-100'>
                                <ReactDatePicker
                                    dateFormat={item.format==="" ? undefined: item.format}
                                    // selected={new Date()}
                                    style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                                    //   onSelect={handleDateSelect}
                                    //   onChange={handleDateChange} 
                                    className='formDataDatePicker'
                                    selected={isDateValid(item?.value) ? new Date(item?.value) : ""}
                                    onChange={(event) => {
                                        const data = jsonData.map((val, newIndex) => {
                                            if (newIndex === index) {
                                                return ({
                                                    ...val,
                                                    value: event
                                                })
                                            }
                                            return val
                                        })
                                        setJsonData(data)
                                    }
                                    }
                                    label="Basic date picker" />
                            </div>
                        </div>
                    )

                case "Time":
                    return (
                        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ textAlign: 'left' }}>
                                <div className='w-100 d-flex justify-content-between'>
                                    <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                    {item?.instruction &&
                                        <Tooltip title={item?.instruction}>
                                            <IconButton size={'small'}>
                                                {INFO_ICON}
                                            </IconButton>
                                        </Tooltip>}
                                </div>
                                {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                            </div>
                            <div className='formdata w-100'>
                                <ReactDatePicker
                                    // selected={new Date()}
                                    style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeFormat={item.format==="" ? undefined: item.format}
                                    dateFormat={item.format==="" ? "p": item.format}
                                    className='formDataDatePicker'
                                    selected={isDateValid(item?.value) ? new Date(item?.value) : ""}
                                    onChange={(event) => {
                                        const data = jsonData.map((val, newIndex) => {
                                            if (newIndex === index) {
                                                return ({
                                                    ...val,
                                                    value: event
                                                })
                                            }
                                            return val
                                        })
                                        setJsonData(data)
                                    }
                                    }
                                    label="Basic date picker" />
                            </div>
                        </div>
                    )

                case "Date/Time":
                    let dateFormat = item.format;
                    let timeFormat = "";
                    if (item.format) {
                    timeFormat = item.format.split(" ")[1];
                    if (item.format.split(" ")[2])
                        timeFormat = timeFormat+" "+item.format.split(" ")[2]
                    } else timeFormat = undefined
                    return (
                        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                            <div style={{ textAlign: 'left' }}>
                                <div className='w-100 d-flex justify-content-between'>
                                    <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                    {item?.instruction &&
                                        <Tooltip title={item?.instruction}>
                                            <IconButton size={'small'}>
                                                {INFO_ICON}
                                            </IconButton>
                                        </Tooltip>}
                                </div>
                                {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                            </div>
                            <div className='formdata w-100'>
                                <ReactDatePicker
                                    // selected={new Date()}
                                    style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                                    showTimeSelect
                                    dateFormat={item.format==="" ? "Pp": dateFormat}
                                    timeFormat={timeFormat}
                                    className='formDataDatePicker'
                                    selected={isDateValid(item?.value) ? new Date(item?.value) : ""}
                                    onChange={(event) => {
                                        const data = jsonData.map((val, newIndex) => {
                                            if (newIndex === index) {
                                                return ({
                                                    ...val,
                                                    value: event
                                                })
                                            }
                                            return val
                                        })
                                        setJsonData(data)
                                    }
                                    }
                                />
                            </div>
                        </div>
                    )
                case "Signature":
                    return (<div style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: '8px'
                    }}>
                        <Button onClick={() => {
                            setOpenSignature(true)
                        }} variant={item?.html || "outlined"}>{item?.label}</Button>
                    </div>)
                case "Rich Text Input":
                    return <div style={{ width: '100%' }}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                {item?.instruction &&
                                    <Tooltip title={item?.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}

                            </div>
                            <div className='w-100 d-flex justify-content-between'>
                                <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>
                                <Tooltip title={'Search'}>
                                    <IconButton size={'small'} onClick={() => {
                                        setSearchValueRich(true)
                                        setPopUpData(item)
                                    }}>
                                        <GrTableAdd />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        <RichTextEditor
                            value={item?.value || ''}
                            tabIndex={''} // tabIndex of textarea
                            onChange={newContent => { }}
                        />
                    </div>
                case "Rich Text Heading":
                    return <div style={{ width: '100%' }}>
                        {ReactHtmlParser(item?.label)}
                    </div>
                case "Photo Attach":
                    return <div style={{}}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                {item?.instruction &&
                                    <Tooltip title={item?.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                        </div>
                        <div>
                            <img style={{ width: '100%', height: '100%' }} src={item?.value} />
                        </div>
                    </div>
                case "File Upload":
                    return <div style={{}}>
                        <div style={{ textAlign: 'left' }}>
                            <div className='w-100 d-flex justify-content-between'>
                                <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                                {item?.instruction &&
                                    <Tooltip title={item?.instruction}>
                                        <IconButton size={'small'}>
                                            {INFO_ICON}
                                        </IconButton>
                                    </Tooltip>}
                            </div>
                            {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                        </div>
                        <div>
                            <UploadPhotoContainer className="d-flex align-items-center mt-2">
                                <UploadInput
                                    onInput={e => {
                                        // handlePickFile(e, 'photo');
                                    }}
                                    onClick={event => {
                                        event.target.value = null;
                                    }}
                                    type="file"
                                    // accept={'.png,.jpg,.jpeg'}
                                    id="upload-file"
                                    name="upload-file"
                                    placeholder={'Upload File'}
                                />
                                <FiUpload />
                                {
                                    <label htmlFor="upload-file">Upload File</label>
                                }

                            </UploadPhotoContainer>
                        </div>
                    </div>
                case "File Download":
                    return <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                            {item?.instruction &&
                                <Tooltip title={item?.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                        {item?.placeholder &&
                            <div className="copy-list mt-1 download- w-100">
                                <a className='copy-item ' style={{ justifyContent: 'left' }} target='_blank' href={item?.value}>
                                    <span className='me-1'>{DOWNLOAD}</span>{item?.placeholder}
                                </a>
                            </div>}
                    </div>

                case "BodyMap Display":
                    return <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start', width:'100%' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                            {item?.instruction &&
                                <Tooltip title={item?.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                        <input type="text" id="fname" name="fname" value={item?.title?.join(",")} placeholder={item?.placeholder} style={{ width: '100%', padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }} />

                        <div className="d-flex justify-content-center w-100">
                            {((item?.view == 'front') || (item?.view == 'both')) && <Model
                                data={getBodyData(item?.title)}
                                style={{ width: '16rem', padding: '1rem' }}
                                // onClick={handleClick}
                                type={'anterior'}
                            />}
                            {((item?.view == 'back') || (item?.view == 'both')) && <Model
                                data={getBodyData(item?.title)}
                                style={{ width: '16rem', padding: '1rem' }}
                                // onClick={handleClick}
                                type={'posterior'}
                            />}

                        </div>
                    </div>
                case "BodyMap Input":
                    return <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div className='w-100 d-flex justify-content-between'>
                            <label for="fname" style={{ marginTop: 'auto', fontWeight: "bold" }} >{item?.label}<span style={{ color: ERR_COLOR }}>{item?.required && '*'}</span></label>
                            {item?.instruction &&
                                <Tooltip title={item?.instruction}>
                                    <IconButton size={'small'}>
                                        {INFO_ICON}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                        {item?.description && <p style={{ marginBottom: 8, fontSize: 12 }}>{item?.description}</p>}
                        <PrimaryButtonForm minWidth="6rem" onClick={() => setModalBodyMap(true)}>
                            <FaPlus />
                            Create body map
                        </PrimaryButtonForm>
                    </div>

                 default : break;

            }
        }
        return null
    }

    return (
        <>
            <div className='w-100' style={{ textAlign: 'end' }}>
                <PrimaryButtonForm className='me-2' minWidth="6rem" disabled={loadingExport} onClick={() => handleDownlaodPdf()}>
                    <span className={'me-2'}>Export PDF</span>
                    {loadingExport && (
                        <span
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                        />
                    )}
                </PrimaryButtonForm>
            </div>
            <div id='dustbin-pdf' className='px-3' data-testid="dustbin">
            {loadingExport && <h4 className='w-100 mt-2 d-flex justify-content-center'>{title}</h4>}
                {jsonData.length > 0 ? (
                    jsonData.filter(item=>{
                        let show = (item?.showCondition && item?.ruleExpression) ? checkRuleExpression(item) : true
                        return show
                    }).map((item, index) => {
                        return (
                            <>
                            <div key={index} style={{ display: "flex", justifyContent: "space-between", padding: 10, }}>
                                {renderDesign(item, index)}
                            </div>
                            </>
                        )
                    })
                )
                    :
                    <p style={{ color: "grey" }}>Dropzone</p>
                }

            </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openSignature}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={openSignature}>

                        <MatBox sx={{ ...style, maxHeight: "80vh", overflow: "scroll" }}>
                            <div style={{ marginBottom: 20 }}>
                                <h6>Signature</h6>
                            </div>
                            <div>
                                <SignatureCanvas penColor='black'
                                    canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }} />,
                            </div>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Button variant="contained" style={{ marginRight: 10 }} onClick={() => {
                                    setOpenSignature(false)
                                }}>Close</Button>
                            </div>
                        </MatBox>
                    </Fade>
                </Modal>
                {
                    searchValueRich &&
                    <SearchModalRichText
                        fullBind={fullBindSearchValueRich}
                        jsonData={jsonData}
                        setJsonData={setJsonData}
                        popUpData={popUpData}
                        setSearchValueRich={setSearchValueRich}
                    />
                }

{!modalBodyMap ? null : (
          <BodyMapModal
            fullBind={fullBindBodyMap}
            setModalBodyMap={setModalBodyMap}
          />
        )}
        </>
    )
}
