import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import {
  LARGE_COL,
  SMALL_COL,
  MEDIUM_COL,
} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import Dropdown from 'components/SharedComponents/Dropdown';
import Pagination from 'components/SharedComponents/Pagination';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DateSelector from 'components/SharedComponents/DateSelector';
import {DOWNLOAD} from 'utils/constants/icons';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'hooks/FormHook';
import {useModal} from 'hooks/ModalHook';
import ModalDecision from 'components/SharedComponents/ModalDecision';

import CancelVisitModals from './EventModal/CanceledVisitModals';
// import { IconWrapper } from 'components/SharedComponents/Table/styles';
import {
  deleteOfferVisit,
  getCancelledVisits,
  getDownload,
  getOfferVisits,
  patchDeleteIcon,
} from 'utils/api/ClientApi';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';

import moment from 'moment';
import Fade from 'react-reveal/Fade';
import {GrSearch} from 'react-icons/gr';
import {RiDeleteBinLine} from 'react-icons/ri';
import {getTimesArray, getCodeDaysArray, getDaysArray} from 'utils/Array';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import queryString from 'query-string';
import {AppContext} from 'context';
import {BranchItemsContext} from 'context';
import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper,
  DayPill,
} from 'components/SharedComponents/Table/styles';
import {
  HeaderVisits,
  Title,
  SearchIcon,
  SearchAddContainer,
  SearchContainer,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
} from 'shared/styles/constants/tagsStyles';
import {
  CLIENT_CANCELLED_VISITS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  CLIENT_OFFER_VISITS,
} from '../../../utils/constants/permissions';
import {
  ItemContainer,
  MainContainer,
  PublishButton,
  RightContainer,
} from 'components/SharedComponents/ProgressInformation/styles';
import {
  getCategoryPermission,
  getPermissions,
  getStaff,
  getClient,
  getEmployee,
} from '../../../utils/localStorage/user';

import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import EventModal from './EventModal';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {
  CHECK,
  CROSS,
  FLOPPY,
  Home_ICON,
  BIKE_ICON,
  CAR_ICON,
  FOOT_ICON,
  High_PRIORITY,
  LOW_PRIORITY,
  MEDIUM_PRIORITY,
} from 'utils/constants/icons';
import ModalDynamicOffers from './modalOffer/offerModal';
import {
  accpetAllOffersForVisits,
  getAllOffersForVisits,
} from 'utils/api/SchedulerApi';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {Toast} from 'primereact/toast';
import {Fragment} from 'react';
import { client_offer_visits } from 'utils/choiceConstant';
import { dateToStringDate } from 'shared/methods';

const headerColumns = [
  {
    label: 'VISIT No.',
    name: 'visit_number',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'CLIENT FULL NAME',
    name: 'client_service_visit__client__full_name',
    status: 1,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  // {
  //   label: 'REASON FOR CANCEL',
  //   name: 'visit_cancellation_reason__name',
  //   status: 0,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // },
  // {
  //   label: 'NOTIFACTION METHOD OF CANCEL',
  //   name: 'visit_cancellation_notification_method__name',
  //   status: 0,
  //   className: '  sm-hidden ',
  //   max_width: LARGE_COL,
  // },
  {
    label: 'VISIT START DATE',
    name: 'start_date',
    status: 0,
    className: '  sm-hidden ',
    max_width: LARGE_COL,
  },
  {
    label: 'VISIT START TIME',
    name: 'start_date',
    status: 0,
    className: ' sm-hidden  ',
    max_width: LARGE_COL,
  },
  {
    label: 'VISIT END DATE',
    name: 'end_date',
    status: 0,
    className: ' sm-hidden ',
    max_width: LARGE_COL,
  },
  {
    label: 'VISIT END TIME',
    name: 'end_date',
    status: 0,
    className: '  sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'STATUS',
    name: 'is_approved',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: '',
    name: '',
    status: 0,
    className: '  sm-hidden',
    max_width: LARGE_COL,
  },
  // {
  //   label: 'CLIENT TO BE BILLED',
  //   name: 'client_to_be_billed',
  //   status: 0,
  //   className: 'grow-mobile  ',
  //   max_width: LARGE_COL,
  // },
  // {
  //   label: 'STAFF TO BE PAID',
  //   name: 'staff_to_be_billed',
  //   status: 0,
  //   className: ' sm-hidden',
  //   max_width: LARGE_COL,
  // },
  // {
  //   label: 'CANCEL UPDATED BY ',
  //   name: 'updated_by',
  //   status: 0,
  //   className: '  md-hidden ',
  //   max_width: LARGE_COL,
  // },
  // {
  //   label: 'CANCEL NOTES ',
  //   name: 'visit_cancellation_notes',
  //   status: 0,
  //   className: ' md-hidden ',
  //   max_width: LARGE_COL,
  // },
];
// offer_employee
const headerColumnsForOffer = [
  {
    label: 'Employee name',
    name: 'employee_name',
    status: 1,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Start date',
    name: 'start_date',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Start time',
    name: 'start_date',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'End date',
    name: 'end_date',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'End time',
    name: 'end_date',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },

  {
    label: '',
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
];

function OfferVisits(props) {
  const NEW_DATE = new Date();
  const {values, setValues, useInput} = useForm({
    ordering: 'client_service_visit__client__full_name',
    start_date__gte: dateToStringDate(NEW_DATE),
    end_date__lte: dateToStringDate(NEW_DATE),
  });
  const [cancelledvisits, setCancelledVisits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const mountedRef = useRef(true);
  const toast = useRef(null);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [branch, setBranch] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [employeesFilter, setEmployeesFilter] = useState([]);
  const [clientsFilter, setClientsFilter] = useState([]);
  const [runRouteData, setRunRouteData] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);
  const [loadingSave, setLoadingSave] = useState(false);
  const [showDetailsModal, setShowDetailModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [fetchedVisitDetail, setFetchedVisitDetail] = useState(null);
  const [visitFailData, setvisitFailData] = useState({});
  const [disableButton, setDisableButton] = useState([]);
  const [offerEmployeeId, setOfferEmployeeId] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const CANCELED_VISIT_VALUES = 'cancelVisit';
  const {
    contextChoices,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextPermissions,
    contextIsStaff,
  } = useContext(AppContext);
  const {branchData} = useContext(BranchItemsContext);
  const {headerFilterClearValue} = useContext(BranchItemsContext);
  const [clearAll, setClearAll] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const userClient = getClient();
  const userEmployee = getEmployee();
  const [employees, setEmployees] = useState([]);
  const [clients, setClients] = useState([]);
  const [eventValues, setEventValues] = useState([]);
  const userStaff = contextIsStaff;
  const history = useHistory();
  const [loadingModal, setLoadingModal] = useState(true);
  const [downloadDisable, SetDownloadDisable] = useState(false);
const [offerId ,setOfferId]=useState(null)
const { setValue: setModalDeleteValue, bind: bindModalDelete } =
useModal(false);
  const {
    setValue: setAddEvent,
    value: addEvent,
    fullBind: fullBindAddEvent,
  } = useModal(false);
  const {
    setValue: setCancelledVisit,
    value: cancelledVisit,
    fullBind: fullBindCancelledVisit,
  } = useModal(false);
  const { handleGetChoices } = useContext(AppContext)

   
  useEffect(()=>{
    handleGetChoices(client_offer_visits)
  },[])
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    let limitperpage = {};
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({...values});
  }, [PAGE_SIZE_RENDER]);

  useEffect(() => {
    const roles = contextPermissions;

    let rolePermissions = {};
    if (contextIsAdmin || contextIsSuperAdmin) {
      setDeletePermissions(true);
    } else {
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === CLIENT_CANCELLED_VISITS) {
            rolePermissions[CLIENT_CANCELLED_VISITS] = {};
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION] = {};
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });
        if (
          rolePermissions[CLIENT_CANCELLED_VISITS] &&
          rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION]
        )
          setDeletePermissions(
            rolePermissions[CLIENT_CANCELLED_VISITS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ]
          );
      }
    }
  }, [contextIsAdmin, contextIsSuperAdmin]);

  useEffect(() => {
    if (eventValues.id && showDetailsModal) {
      setSubmitted(true);
      getAllOffersForVisits(eventValues.id)
        .then(res => {
          setSubmitted(false);
          if (res.data.length) {
            setFetchedVisitDetail(res.data);
          } else {
            setShowDetailModal(false);
            setFetchedVisitDetail([]);
            showInfo();
          }
        })
        .catch(err => console.log(err));
    } else {
      setEventValues([]);
    }
  }, [showDetailsModal]);

  useEffect(() => {
    setRunRouteData(
      contextChoices &&
        contextChoices.run_routes &&
        contextChoices.run_routes.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setBranch(
      contextChoices &&
        contextChoices.run_routes &&
        contextChoices.branch.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setClients(
      contextChoices &&
        contextChoices.client_listing &&
        contextChoices.client_listing.map(y => {
          return {id: y && y.id, name: y && y.full_name};
        })
    );

    setEmployees(
      contextChoices &&
        contextChoices.employee_listing &&
        contextChoices.employee_listing.map(y => {
          return {id: y && y.id, name: y && y.full_name};
        })
    );
  }, [contextChoices]);

  useEffect(() => {
    const roles = contextPermissions;

    let rolePermissions = {};
    if (contextIsAdmin || contextIsSuperAdmin) {
      setDeletePermissions(true);
    } else {
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === CLIENT_OFFER_VISITS) {
            rolePermissions[CLIENT_OFFER_VISITS] = {};
            rolePermissions[CLIENT_OFFER_VISITS][WEB_PERMISSION] = {};
            rolePermissions[CLIENT_OFFER_VISITS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[CLIENT_OFFER_VISITS][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[CLIENT_OFFER_VISITS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[CLIENT_OFFER_VISITS][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });
        if (
          rolePermissions[CLIENT_OFFER_VISITS] &&
          rolePermissions[CLIENT_OFFER_VISITS][WEB_PERMISSION]
        )
          setDeletePermissions(
            rolePermissions[CLIENT_OFFER_VISITS][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ]
          );
      }
    }
  }, [contextIsAdmin, contextIsSuperAdmin]);

  useEffect(() => {
    if (
      values.runroutes ||
      values.branch ||
      values.clients ||
      values.employees ||
      branchData ||
      values.limitperpage ||
      values.start_date__gte ||
      values.end_date__lte
    ) {
      const urlParams = queryString.parse(props.location.search);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: '/offer-visits',
        search: stringified,
      });

      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.runroutes,
    values.branch,
    values.employees,
    values.clients,
    branchData,
    values.limitperpage,
    values.start_date__gte,
    values.end_date__lte,
  ]);

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.ordering, props.location.search]);

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;

    setCurrentPage(offset + 1);

    offset =values.limitperpage===100?0: offset * values.limitperpage;
    return {
      offset,
    };
  };

  const search = () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage
    );
    getOfferVisits(
      {...branchData, ...values, ...pageToSearch},
      values.limitperpage,
      values.start_date_gte,
      values.end_date_lte
    ).then(response => {
      if (response) {
        setLoadingModal(false);
      }
      setPages(Math.ceil(response.count / values.limitperpage));
      if (!mountedRef.current) return null;
      setCancelledVisits(response.results);
    });
  };

  const deleteVisitOffer = id => {
   
  };

  const toDeleteVisitOffer=(ID)=>{
    setModalDeleteValue(true);
    setOfferId(ID)


  }
  const handleOfferVisit = async () => {
    deleteOfferVisit(offerId)
    .then(res => {
      setModalDeleteValue(false);

      search();
    })
    .catch(err => console.log(err, 'ERROR'));
  };

  const setRunroutefilter = item => {
    setRunRoutesFilter([...item]);
  };

  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };

  const setFilterEmployees = item => {
    setEmployeesFilter([...item]);
  };

  const setFilterClients = item => {
    setClientsFilter([...item]);
  };

  const hitSearch = value => {
    values.search = value;
    search();
  };

  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d);
    };
  };

  const showInfo = () => {
    toast.current.show({
      severity: 'info',
      summary: 'Info',
      detail: 'No offers found',
      life: 3000,
    });
  };

  const debounceOnchange = useRef(debounce(hitSearch, 300));
  const keyPressed = e => {
    setSearchKeyword(e.target.value);
    debounceOnchange.current(e.target.value);
  };
  const handleDownload = () => {
    setLoadingButton(true);
    SetDownloadDisable(true);
    let fileName = 'CancelVisit' + moment().format('YYYY-MM-DD');
    getDownload('CancelVisit', {...values})
      .then(response => {
        if (response) {
          
            setLoadingButton(false);
            SetDownloadDisable(false);
        
          
       
        }

        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          setLoadingModal(false);
          
            setLoadingButton(false);
         

        });
     
      })
      .catch(error => {
       
      });
     

  };
  const dropDownHandleSelect = item => {
    if (item.runroutes) {
      item.runroutes =
        item.runroutes.length === (runRouteData ? runRouteData : 0).length ||
        item.runroutes.length === 0
          ? []
          : item.runroutes;
    }

    if (item.branch) {
      item.branch =
        item.branch.length === (branch ? branch : 0).length ||
        item.branch.length === 0
          ? []
          : item.branch;
    }

    setValues({...values, ...item});
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({...values});
  };

  const eventClickHandler = async evvVisit => {
    try {
      setEventValues({...evvVisit, ...{newDate: ''}});
      setShowDetailModal(true);
    } catch (error) {}
  };

  const handleAcceptOffers = (id, offer_employee, force) => {
    accpetAllOffersForVisits(id, offer_employee, force)
      .then(res => {
        let visitf = [];
        if (!res.status && res.visitFail) {
          cancelledvisits.forEach(item => {
            if (res.visitFail[item.id]) {
              visitf.push({...res.visitFail[item.id], visit_id: item.id});
            }
          });
          setOfferEmployeeId(offer_employee);
          setvisitFailData(visitf);
        }
      })
      .catch(err => console.log(err));
  };
console.log(cancelledvisits,"cancelledvisits")
  return (
    <div>
      <Title>List of Offer Visits</Title>
      <HeaderVisits>
        <div className="d-flex align-items-center justify-content-between">
          <FiltersContainer>
              <FiltersLeftGroup className="d-flex justify-content-center mb-2">
                <DropdownTypesContainer className="ms-1 me-1">
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({runroutes: items});
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Group Area'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>

                <DropdownTypesContainer className="ms-1 me-1">
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({branch: items});
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>

                <DropdownTypesContainer className="ms-1 me-1">
                  <NoSelectAllDropdownFilter
                    items={employees ? employees : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employees: items});
                    }}
                    itemsSelected={employeesFilter}
                    setSelectedFilter={setFilterEmployees}
                    placeHolder={'Employee name'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>

                <DropdownTypesContainer className="ms-1 me-1">
                  <NoSelectAllDropdownFilter
                    items={clients ? clients : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({clients: items});
                    }}
                    itemsSelected={clientsFilter}
                    setSelectedFilter={setFilterClients}
                    placeHolder={'Client name'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
               
                  <DropdownTypesContainer className="ms-1 me-1">
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date from"
                      {...useInput('start_date__gte')}
                    />
                  </DropdownTypesContainer>
                  <DropdownTypesContainer className="ms-1 me-1">
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date to"
                      {...useInput('end_date__lte')}
                    />
                  </DropdownTypesContainer>
                </FiltersLeftGroup>
        </FiltersContainer>
        </div>
        <SearchAddContainer className="d-flex align-items-center">
          <SearchIcon
            noMarginLeft
            onClick={() => {
              setShowSearch(!showSearch);
            }}>
            <GrSearch />
          </SearchIcon>
          <SearchContainer show={showSearch}>
            <Fade collapse when={showSearch}>
              <InputLabelTop
                no_internal_margin="true"
                type="text"
                id="search"
                label={'Search'}
                // {...useInput('search')}
                // onBlur={() => {
                //   search();
                // }}
                // onKeyPress={e => {
                //   keyPressed(e);
                // }}
                onChange={e => {
                  keyPressed(e);
                }}
                value={searchKeyword}
              />
            </Fade>

          </SearchContainer>
          
          {loadingButton ? (
            <Fragment>
              <span
                className="spinner-border spinner-border-sm me-1"
                role="status"
              />
              <span>Downloading...</span>
            </Fragment>
          ) : (
            <Fragment />
          )}
          
           
              <ItemContainer>
                <PublishButton
                  disable={downloadDisable}
                  onClick={!downloadDisable&&
                  handleDownload}>
                  {DOWNLOAD}
                </PublishButton>
              </ItemContainer>
        </SearchAddContainer>
      </HeaderVisits>
      <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-start"></DataContainer>
      <ComponentDynamic loading={loadingModal}>
        <Table
          headerColumns={headerColumns}
          headerPadding={false}
          noEditOption
          noNeed={false}
          fontSize="0.75rem"
          callBackQuerySort={setQuerySort}>
          <div>
            {/* {
    "visit_number": 292977,
    "start_date": "2023-02-13T11:00:00",
    "end_date": "2023-02-13T13:00:00",
    "client_name": "15 Dec Client 1",
    "is_approved": false,
    "id": "b6b6fcd6-3bd2-46d7-990c-f4848a4f817f"
} */}
            {cancelledvisits &&
              cancelledvisits.map((visitcancelled, index) => {
                const visitEndDate = visitcancelled.end_date
                  ? moment(visitcancelled.end_date).format('DD-MM-YYYY')
                  : 'Unset';
                const visitEndTime = visitcancelled.end_date
                  ? moment(visitcancelled.end_date).format('HH:mm')
                  : 'Unset';
                const visitStartDate = visitcancelled.start_date
                  ? moment(visitcancelled.start_date).format('DD-MM-YYYY')
                  : 'Unset';
                const visitStartTime = visitcancelled.start_date
                  ? moment(visitcancelled.start_date).format('HH:mm')
                  : 'Unset';
                return (
                  <div key={index}>
                    <Row bgColor Shadow>
                      <ColsGrouper
                        className="d-flex"
                        onClick={() => {
                          if(visitcancelled.offer_count > 0)
                            eventClickHandler(visitcancelled);
                          else showInfo();
                        }}
                      >
                        <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          overlap={true}
                          Center>
                          {visitcancelled.visit_number}
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          overlap={true}
                          Center>
                          {visitcancelled.client_name}
                        </Col>

                        <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          className="sm-hidden"
                          Capitalize
                          overlap={true}
                          Center>
                          {
                            visitStartDate //visit start date
                          }
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          className="sm-hidden"
                          Capitalize
                          overlap={true}
                          Center>
                          {
                            visitStartTime //visite start time
                          }
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          className="sm-hidden"
                          Capitalize
                          overlap={true}
                          Center>
                          {visitEndDate}
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          className="sm-hidden"
                          Capitalize
                          overlap={true}
                          Center>
                          {visitEndTime}
                        </Col>
                        <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          overlap={true}
                          Center>
                          {visitcancelled.is_approved
                            ? 'Approved'
                            : visitcancelled.offer_count > 0
                            ? 'Pending'
                            : 'No offers'}
                        </Col>

                        <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          className="sm-hidden"
                          Capitalize
                          overlap={true}
                          Center>
                          <IconWrapper
                            onClick={(e) => {
                              e.stopPropagation();
                              toDeleteVisitOffer(visitcancelled.id);
                            }}>
                            <RiDeleteBinLine />
                          </IconWrapper>
                        </Col>
                        {/* <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          className="  "
                          Capitalize
                          overlap={true}
                          Center>
                          {visitcancelled.client_to_be_billed +
                            (visitcancelled.client_to_be_billed && '%')}
                        </Col> */}
                        {/* <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          className="sm-hidden"
                          Capitalize
                          overlap={true}
                          Center>
                          {visitcancelled.staff_to_be_billed +
                            (visitcancelled.staff_to_be_billed && '%')}
                        </Col> */}
                        {/* <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          className="md-hidden"
                          Capitalize
                          overlap={true}
                          Center>
                          {visitcancelled.updated_by &&
                            visitcancelled.updated_by.name}
                        </Col> */}
                        {/* <Col
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          overlap={true}
                          className="md-hidden"
                          Capitalize
                          Center>
                          {visitcancelled.visit_cancellation_notes}
                        </Col> */}
                      </ColsGrouper>

                      {/* {deletePermission && (
                        <Col className="sm-hidden" Center Shrink NoFlexGrow>
                          <Col className="sm-hidden" Center Icon Purple>
                            <IconWrapper
                            onClick={() =>{ eventClickHandler(visitcancelled);handleRowClick('edit')}}
                             
                              
                              >
                              <MdEdit />
                            </IconWrapper>
                          </Col>
                          <IconWrapper
                            onClick={() => {
                              toDeleteVisit(visitcancelled.id);
                            }}>
                            <RiDeleteBinLine />
                          </IconWrapper>
                        </Col>
                      )} */}
                    </Row>
                    <div className="mb-1" />
                  </div>
                );
              })}

            <div className="mt-3">
              <Pagination
                setCurrentPage={setCurrentPage}
                totalPages={pages}
                currentPage={currentPage}
                values={values}
                dropDownHandleSelect={dropDownHandleSelectPagination}
                number={PAGE_SIZE_RENDER}
                dataLength={cancelledvisits ? cancelledvisits.length : 0}
              />
            </div>
          </div>
        </Table>
      </ComponentDynamic>
      {!addEvent ? null : (
        <EventModal
          readOnly={true}
          fullBind={fullBindAddEvent}
          eventValues={eventValues}
          employees={employees}
        />
      )}
      {cancelledVisit && (
        <CancelVisitModals
          search={search}
          eventValues={eventValues}
          fullBind={fullBindCancelledVisit}
          useInput={useInput}
          cancelledvisits={cancelledvisits}
          setEventValues={setEventValues}
          // errorMessage={errorMessage}
          choices={contextChoices}
          nameSection={CANCELED_VISIT_VALUES}
          setCancelledVisit={setCancelledVisit}
          setValues={setValues}
          values={values}
          saveButtonIcon={saveButtonIcon}
          loadingSave={loadingSave}
          // textStatus={textStatus}
          // saveCancelVisit={saveCancelVisit}
          // dropDownHandleCancelVisit={dropDownHandleCancelVisit}
          // listView={listView}
        />
      )}

      <Toast ref={toast} />

      <ModalDynamicOffers
        children={
          <div>
            {fetchedVisitDetail && !fetchedVisitDetail.length ? (
              <p style={{textAlign: 'center'}}>NO DATA FOUND</p>
            ) : (
              <ComponentDynamic loading={loadingModal}>
                <Table
                  headerColumns={headerColumnsForOffer}
                  headerPadding={false}
                  noEditOption
                  noNeed={false}
                  fontSize="0.75rem"
                  callBackQuerySort={setQuerySort}>
                  <div>
                    {fetchedVisitDetail &&
                      fetchedVisitDetail.map((visitcancelled, index) => {
                        const visitEndDate = visitcancelled.end_date
                          ? moment(visitcancelled.end_date).format('DD-MM-YYYY')
                          : 'Unset';
                        const visitEndTime = visitcancelled.end_date
                          ? moment(visitcancelled.end_date).format('HH:mm')
                          : 'Unset';
                        const visitStartDate = visitcancelled.start_date
                          ? moment(visitcancelled.start_date).format(
                              'DD-MM-YYYY'
                            )
                          : 'Unset';
                        const visitStartTime = visitcancelled.start_date
                          ? moment(visitcancelled.start_date).format('HH:mm')
                          : 'Unset';
                        return (
                          <div key={index}>
                            <Row bgColor Shadow>
                              <ColsGrouper
                                className="d-flex"
                                onClick={() => {}}>
                                <Col
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center>
                                  {visitcancelled.employee_name}
                                </Col>
                                <Col
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center>
                                  {
                                    visitStartDate //visit start date
                                  }
                                </Col>
                                <Col
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  className="sm-hidden"
                                  Capitalize
                                  overlap={true}
                                  Center>
                                  {
                                    visitStartTime //visite start time
                                  }
                                </Col>

                                <Col
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center>
                                  {
                                    visitEndDate //visit start date
                                  }
                                </Col>
                                <Col
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  className="sm-hidden"
                                  Capitalize
                                  overlap={true}
                                  Center>
                                  {
                                    visitEndTime //visite start time
                                  }
                                </Col>

                                <Col
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center>
                                  <PrimaryButtonForm
                                    minWidth="9rem"
                                    disabled={visitcancelled.is_approved}
                                    onClick={() =>
                                      handleAcceptOffers(
                                        eventValues.id,
                                        visitcancelled.offer_employee
                                      )
                                    }>
                                    <span className={'me-2'}>Accept</span>
                                  </PrimaryButtonForm>
                                </Col>
                              </ColsGrouper>
                            </Row>
                            <div className="mb-1" />
                          </div>
                        );
                      })}

                    {/* <div className="mt-3">
                      <Pagination
                        setCurrentPage={setCurrentPage}
                        totalPages={pages}
                        currentPage={currentPage}
                        values={values}
                        dropDownHandleSelect={dropDownHandleSelectPagination}
                        number={PAGE_SIZE_RENDER}
                        dataLength={
                          cancelledvisits ? cancelledvisits.length : 0
                        }
                      />
                    </div> */}
                  </div>
                </Table>
              </ComponentDynamic>
            )}
            <div className="mt-4 mx-2">
              {visitFailData && visitFailData.length > 0 && (
                <Title>Visit Fails</Title>
              )}
              {visitFailData &&
                visitFailData.length > 0 &&
                visitFailData.map((item, index) => {
                  return (
                    <div className="d-flex justify-content-between my-1">
                      <div>
                        {index + 1}. {item.HEADING} - {item.MESSAGE}
                      </div>
                      <div>
                        <PrimaryButtonForm
                          minWidth="6rem"
                          disabled={
                            disableButton.find(ele => ele === index + 1)
                              ? true
                              : false
                          }
                          onClick={() =>
                            handleAcceptOffers(
                              eventValues.id,
                              offerEmployeeId,
                              true
                            )
                          }>
                          <span className={'me-2'}>Force assign</span>
                        </PrimaryButtonForm>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        }
        title="List of offers"
        onClose={() => {
          setShowDetailModal(false);
        }}
        showModal={showDetailsModal}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={submitted}
        data={eventValues}
      />
       <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this offer visit ? \n DELETING the offer visit will remove ALL records from database.'
        }
        onOk={handleOfferVisit}
        onCancel={() => {
          setOfferId('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
}

export default OfferVisits;
