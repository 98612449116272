import React from 'react';
import {
    UserImgContainer,
    UserImgWrapper,
    TitleSection,
    ContainerResponsive,
    UploadPhotoContainer,
  } from '../styles';
  import Image from 'components/SharedComponents/Image';
  import InputLabelTop from 'components/SharedComponents/InputLabelTop';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {
    FlexGrid3Container,
    FlexGrid3Item,
  } from 'shared/styles/constants/tagsStyles';
  import DateSelector from 'components/SharedComponents/DateSelector';
  import { UploadInput } from 'shared/styles/buttons';
  import { FiUpload } from 'react-icons/fi';
import { useEffect } from 'react';
const BasicInformation = ({
    values,
    useInput,
    employeePhoto,
    choices,
    dropDownHandleSelect,
    handlePickFile,
    clientList,
    readOnly,
    setPhotoById,
    contextChoicesMap
}) => {


  let status_name = values?.employee_status && contextChoicesMap?.employee_status && contextChoicesMap?.employee_status[values?.employee_status]?.name
  let isRequiredEndDate= ((status_name && (status_name != 'Active') && (status_name != 'Active - Probation')))

  let employment_end_date_hook = useInput('employeeJobDetailsBranchAccess.employment_end_date', isRequiredEndDate ? "isRequired" : "")
  

useEffect(()=>{
  setPhotoById(values.photo);
},[
  values.photo
])
    return (
        <>
        <TitleSection>Basic Information</TitleSection>
            <div className="d-flex flex-column mt-2">
              <ContainerResponsive className="d-flex">

                <div className="me-md-2 mb-2 align-items-stretch">
                  <UserImgWrapper>
                    <UserImgContainer>
                      <Image
                        src={
                          employeePhoto
                            ? URL.createObjectURL(employeePhoto)
                            : values.photo
                              ? values.photo
                              : ''
                        }
                        name={values.first_name || values.full_name}
                        lastName={values.last_name}
                        alt={'employee-img'}
                        fontSize={'1.5rem'}
                      />
                    </UserImgContainer>

                    <UploadPhotoContainer className="d-flex align-items-center mt-2">
                      <UploadInput
                        onInput={e => {
                          handlePickFile(e, 'photo');
                        }}
                        onClick={event => {
                          event.target.value = null;
                        }}
                        type="file"
                        accept={'.png,.jpg,.jpeg'}
                        id="employee-photo"
                        name="employee-photo"
                        placeholder={'Employee Photo'}
                      />
                      <FiUpload />
                      {
                    !readOnly? <label htmlFor="employee-photo">Upload</label>:null
                      }
                     
                    </UploadPhotoContainer>
                  </UserImgWrapper>
                </div>

                <FlexGrid3Container className="w-100">
                  <FlexGrid3Item className="pe-1">
                    <InputLabelTop
                      required
                      readOnly={readOnly}
                      type="text"
                      id="first_name"
                      label={'First Name'}
                      {...useInput('first_name', 'isRequired')}
                      maxlength={100}
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                    <InputLabelTop
                      required
                      readOnly={readOnly}
                      type="text"
                      id="last_name"
                      label={'Last Name'}
                      {...useInput('last_name', 'isRequired')}
                      maxlength={100}
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                    <InputLabelTop
                      type="text"
                      readOnly={readOnly}
                      id="middle_name"
                      label={'Middle Name'}
                      {...useInput('middle_name')}
                      maxlength={100}
                    />
                  </FlexGrid3Item>

                  <FlexGrid3Item className="pe-0 pe-md-1 ps-1 ps-md-0">
                    <InputLabelTop
                      required
                      type="text"
                      readOnly={readOnly}
                      id="full_name"
                      label={'Full Name'}
                      {...useInput('full_name', 'isRequired')}
                      maxlength={255}
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="ps-0 ps-md-1 pe-1">
                    <InputLabelTop
                      type="text"
                      readOnly={readOnly}
                      id="preferred_name"
                      label={'Preferred Name'}
                      {...useInput('preferred_name')}
                      maxlength={100}
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="ps-1">
                    <InputLabelTop
                      type="text"
                      readOnly={readOnly}
                      id="prevName"
                      label={'Previous Name'}
                      {...useInput('previous_name')}
                      maxlength={50}
                    />
                  </FlexGrid3Item>

                  <FlexGrid3Item className="pe-0 pe-md-1 ps-1 ps-md-0">
                    <InputLabelTop
                      type="text"
                      readOnly={readOnly}
                      id="initials"
                      label={'Initials'}
                      {...useInput('initials')}
                      maxlength={50}
                    />
                  </FlexGrid3Item>

                  <FlexGrid3Item className="ps-0 ps-md-1 pe-1">
                    <DropdownSearchable
                      placeHolder={'Title'}
                      disabled={readOnly}
                      options={choices.title}
                      selectedOption={values.title}
                      onChange={value =>
                        dropDownHandleSelect({ title: value ? value : null })
                      }
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="ps-0 ps-md-1 pe-1">
                    <DropdownSearchable
                      placeHolder={'Gender'}
                      disabled={readOnly}
                      options={choices.gender}
                      selectedOption={values.gender}
                      onChange={value =>
                        dropDownHandleSelect({ gender: value ? value : null })
                      }
                    />
                  </FlexGrid3Item>
              
                </FlexGrid3Container>
              </ContainerResponsive>

              <FlexGrid3Container className="w-100">

              <FlexGrid3Item className="pe-1 mb-2">
                    <DropdownSearchable
                      placeHolder={'Pronoun Category'}
                      disabled={readOnly}
                      options={choices.pronoun_category}
                      selectedOption={values.pronoun_category}
                      onChange={value =>
                        dropDownHandleSelect({ pronoun_category: value ? value : null })
                      }
                    />
                  </FlexGrid3Item>

                  <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                    <DateSelector
                     disabled={readOnly}
                      labelTop="Date of birth"
                      {...useInput('date_of_birth')}
                    />
                  </FlexGrid3Item>

                <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                  <DropdownSearchable
                  required
                    placeHolder={'Status'}
                    disabled={readOnly}
                    options={choices.employee_status}
                    selectedOption={values.employee_status}
                    {...useInput(`employee_status`, 'isRequired')}
                    onChange={value =>
                      dropDownHandleSelect({
                        employee_status: value ? value : null,
                      })
                    }
                  />
                </FlexGrid3Item>

                <FlexGrid3Item className="pe-1">
                  <DropdownSearchable
                    placeHolder={'Marital Status'}
                    disabled={readOnly}
                    options={choices.marital_status}
                    selectedOption={values.marital_status}
                    onChange={value =>
                      dropDownHandleSelect({
                        marital_status: value ? value : null,
                      })
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                  <DropdownSearchable
                    placeHolder={'Linked to Client'}
                    disabled={readOnly}
                    options={clientList}
                    selectedOption={values.linked_client}
                    onChange={value =>
                      dropDownHandleSelect({
                        linked_client: value ? value : null,
                      })
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                <InputLabelTop
                    type="text"
                    readOnly={readOnly}
                    id="reference"
                    label={'Reference No.'}
                    {...useInput('reference')}
                    maxlength={50}
                  />
                </FlexGrid3Item>
              </FlexGrid3Container>
              
              <FlexGrid3Container className="w-100">
               
                <FlexGrid3Item className="pe-1">
                <InputLabelTop
                    type="text"
                    readOnly={readOnly}
                    id="ni_no"
                    label={'NI Number / SS Ref'}
                    {...useInput('ni_no')}
                    maxlength={25}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                <InputLabelTop
                    type="text"
                    readOnly={readOnly}
                    id="payroll_ref"
                    label={'Payroll Reference'}
                    {...useInput('payroll_ref')}
                    maxlength={25}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                <InputLabelTop
                    type="text"
                    id="nhs-no"
                    readOnly={readOnly}
                    label={'NHS No.'}
                    {...useInput('nhs')}
                    maxlength={25}
                  />
                </FlexGrid3Item>
              </FlexGrid3Container>


              <FlexGrid3Container className="w-100 ">
               
                <FlexGrid3Item className="pe-1 mb-2 ">
                <DateSelector
                disabled={true}
                      labelTop="Last Worked Date"
                      {...useInput('last_working_date')}
                    />
                </FlexGrid3Item>
                <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                <InputLabelTop
                    disabled={true}
                    type="text"
                    id="dlw-no"
                    label={'Days since last worked date'}
                    {...useInput('no_days_since_last_worked')}
                    maxlength={25}
                  />
                </FlexGrid3Item>
            <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
              <DropdownSearchable
               required
               {...useInput(`employeeJobDetailsBranchAccess.branch`, 'isRequired')}
                placeHolder={'Branch Name'}
                options={choices.branch}
                disabled={false}
                //{...useInput(`employeeJobDetailsBranchAccess`,'isRequired')}
                selectedOption={
                  values.employeeJobDetailsBranchAccess
                    ? values.employeeJobDetailsBranchAccess.branch
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect({ branch: value ? value : null }, 'branch')
                }
              />
            </FlexGrid3Item>
                        
              </FlexGrid3Container>
          <FlexGrid3Container className="w-100 ">
            <FlexGrid3Item className="pe-1">
              <DropdownSearchable
                required
                isMulti={true}
                {...useInput(`employeeJobDetailsBranchAccess.accessBranch`, 'isRequired')}
                placeHolder={'Branch Access'}
                options={choices.branch}
                disabled={false
                }
                // {...useInput(`employeeJobDetailsBranchAccess.accessBranch`,'isRequired')}

                selectedOptionsProps={
                  values
                    ? values.employeeJobDetailsBranchAccess
                      ? values.employeeJobDetailsBranchAccess.accessBranch
                      : null
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect(
                    { accessBranch: value ? value : null },
                    'accessBranch'
                  )
                }
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
              <DateSelector
               isRequired
                labelTop="Date Hired"
                {...useInput('employeeJobDetailsBranchAccess.employment_start_date','isRequired')}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
              <DateSelector
                labelTop="Employment end date"
                isRequired={isRequiredEndDate}
                {...employment_end_date_hook}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
            </div>
            </>
    );
}

export default BasicInformation;
