import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ErrorMessage, ModalContainer } from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { FlexGrid3Container, FlexGrid3Item, TitleSection } from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
  HOUR,
  MINUTE
} from 'utils/constants/serviceVisits';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { Fragment } from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import moment from 'moment';
import { BorderContainer } from 'components/DashboardComponents/Team/AddSkills/styles';
import { ERR_COLOR, GRAY_700, PURPLE } from 'shared/styles/constants/colors';
import { useModal } from 'hooks/ModalHook';
import BodyMapModal from './BodyMapModal';
import { IconWrapper } from 'components/SharedComponents/Table/styles';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoIosBody } from 'react-icons/io';
import { FaPlus } from 'react-icons/fa';
import { FLOPPY } from 'utils/constants/icons';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import './medication.css'

const AddMedication = ({
  fullBind,
  useInput,
  errorMessage,
  values,
  choices,
  editMode,
  handleSubmit,
  medList,
  setValues,
  dropDownHandleSelect = () => { },
  loadingSaveMedication,
}) => {
  const [clients, setClients] = useState([]);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [signature, setSignature] = useState(null);

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const ins_hook = useInput(`medication.instructions`);
  const med_hook = useInput(`medication.medication_instructions`)
  const newDoseTime = useInput(
    `medication.medication_dose_time`, 'isRequired');
  const actualMedicationDoseTime = useInput(
    `medication.actual_medication_dose_time`);

  const newDoseStartTime = useInput(
    `medication.medication_dose_time`, 'isRequired'
  );
  const newDoseEndTime = useInput(
    `medication.medication_dose_end_time`, 'isRequired'
  );

  //New field inputs
  let date = useInput(`date`)
  let strength = useInput(`strength`)
  let minimum_interval_between_doses = useInput(`min_interval_between_doses`)
  let maximum_dose_in_24_hours = useInput(`max_dose_in_24_hours`)
  let medication_for = useInput(`medication_purpose`)
  let symptoms = useInput(`symptoms_to_look_for`)
  let client_needs_medication = useInput(`client_need_medication_criteria`)
  let describe_any_alternative = useInput(`alternative_administering_interventions`)
  let medication_work_for = useInput(`medication_duration`)
  let guidance_from_healthcare = useInput(`healthcare_professional_guidance_criteria`)
  let completed_by = useInput('completed_by')

  const { setValue: setModalDetachBodyMap, bind: bindModalDetachBodyMap } = useModal(false);
  const [bodyMapDeleteIndex, setBodyMapDeleteIndex] = useState(null);
  const {
    setValue: setModalBodyMap,
    value: modalBodyMap,
    fullBind: fullBindBodyMap,
  } = useModal(false);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Add Medication',
    });
  }, [fullBind]);

  useEffect(() => {
    let clients = [];
    if (choices.client_listing) {
      clients = choices.client_listing.map(client => {
        return { id: client.id, name: client.full_name };
      });

      setClients(clients || []);
    }
  }, [choices.client_listing])

  const toDeleteBodyData = (index) => {
    values.medication_body_part.splice(index, 1);
    setValues({ ...values })
    setModalDetachBodyMap(false)
  }

  const handleRowBodyClick = (index) => {
    setModalBodyMap(true)
    values.tempBodyMapData = { ...values.medication_body_part[index], index: `${index}` }
    setValues({ ...values })
  }

  const createNewBodyMap = () => {
    setModalBodyMap(true)
    values.tempBodyMapData = {}
    if (!values.medication_body_part) {
      values.medication_body_part = []
    }
    setValues({ ...values })
  }

  useEffect(() => {
    if (values.alternative_administering_interventions && values.alternative_administering_interventions.length > 500) {
      // setErrorText('Ensure this field has no more than 500 characters.')
      values.alternative_field_error = true
    } else {
      values.alternative_field_error = false
    }
    if (values.healthcare_professional_guidance_criteria && values.healthcare_professional_guidance_criteria.length > 500) {
      values.professional_guidance_field_error = true
    } else {
      values.professional_guidance_field_error = false
    }
    setValues({ ...values })
  }, [values.alternative_administering_interventions, values.healthcare_professional_guidance_criteria])


  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={false}>
        <div className='pt-2 ps-3 pe-3 pb-3'>
          <div className="d-flex mb-2">
            <div className="w-100 pe-1">
              <DropdownSearchable
                required
                disabled={!editMode}
                placeHolder={'Client'}
                options={clients || []}
                {...useInput('medication.client', "isRequired")}
                selectedOption={values.medication ? values.medication.client : null}
                onChange={value =>
                  dropDownHandleSelect({ client: value ? value : null }, 'client')
                }
              />
            </div>

          </div>
          <div className="d-flex mb-2">
            <div className="w-100 pe-1">
              <DropdownSearchable
                required
                disabled={!editMode}
                placeHolder={'Medication Type'}
                options={choices.medication_type || []}
                selectedOption={values.medication ? values.medication.medication_type : null}
                {...useInput('medication.medication_type', "isRequired")}
                onChange={(value, label, obj) =>
                  dropDownHandleSelect({ medication_type: obj ? obj : null }, 'medication_type')
                }
              />
            </div>

            <div className="w-100 pe-1">
              <DropdownSearchable
                required
                disabled={!editMode}
                placeHolder={'Medication Name'}
                options={!(values.medication.medication_type_name === 'PRN') ? choices.medication_name : medList}
                {...useInput(`medication.name`, "isRequired")}
                selectedOption={values.medication ? values.medication.name : null}
                onChange={(value, label, obj) =>
                  dropDownHandleSelect({ name: obj ? obj : null }, 'name')
                }
              />
            </div>



          </div>
          <div className="d-flex mb-2">
            <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Route'}
                options={choices.medication_route || []}
                selectedOption={values.medication ? values.medication.medication_route : null}
                onChange={value =>
                  dropDownHandleSelect({ medication_route: value ? value : null }, 'medication_route')
                }
              />
            </div>
            <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Form'}
                options={choices.medication_form || []}
                selectedOption={values.medication ? values.medication.medication_form : null}
                onChange={value =>
                  dropDownHandleSelect({ medication_form: value ? value : null }, 'medication_form')
                }
              />
            </div>
          </div>
          <div className="d-flex mb-2">


            <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Medication Assistance'}
                options={choices.medication_assistance || []}
                selectedOption={values.medication ? values.medication.medication_assistance : null}
                {...useInput('medication.medication_assistance')}
                onChange={value =>
                  dropDownHandleSelect({ medication_assistance: value ? value : null }, 'medication_assistance')
                }
              />
            </div>
            <div className="w-100 pe-1">
              <DropdownSearchable
                required
                disabled={!editMode}
                placeHolder={'Medication Dose'}
                {...useInput(`medication.core_medication_dose`, 'isRequired')}
                options={choices.medication_dose || []}
                selectedOption={values.medication ? values.medication.core_medication_dose : null}
                onChange={value =>
                  dropDownHandleSelect(
                    { core_medication_dose: value ? value : null },
                    'core_medication_dose')
                }
              />
            </div>
          </div>
          {/* Added extra fields */}
          <div className='d-flex mb-2'>
            <div className="w-100 me-2">
              <InputLabelTop
                readOnly={false}
                type="text"
                id="minimum_interval_between_doses"
                label={'Minimum interval between doses'}
                {...minimum_interval_between_doses}
                maxlength={100}
              />
            </div>
            <div className="w-100 me-2">
              <InputLabelTop
                readOnly={false}
                type="text"
                id="maximum_dose_in_24_hours"
                label={'Maximum dose in 24 hours'}
                {...maximum_dose_in_24_hours}
                maxlength={100}
              />
            </div>
            <div className="w-100 me-2">
              <InputLabelTop
                readOnly={false}
                type="text"
                id="medication_for"
                label={'What is the medication for?'}
                {...medication_for}
                maxlength={100}
              />
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className="w-100 me-2">
              <InputLabelTop
                readOnly={false}
                type="text"
                id="symptoms"
                label={'what are the symptoms to look out for?'}
                {...symptoms}
                maxlength={100}
              />
            </div>
            <div className="w-100 me-2">
              <InputLabelTop
                readOnly={false}
                type="text"
                id="client_needs_medication"
                label={' How will you know if the client will needs the medication?'}
                {...client_needs_medication}
                maxlength={100}
              />
            </div>
            <div className="w-100 me-2">
              <InputLabelTop
                readOnly={false}
                type="text"
                id="medication_work_for"
                label={'How long should the medication work for?'}
                {...medication_work_for}
                maxlength={100}
              />
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className="w-100 me-2">
              <InputLabelTop
                readOnly={false}
                type="text"
                id="strength"
                label={'Strength'}
                {...strength}
                maxlength={100}
              />
            </div>
            <div className="w-100 me-2">
              <InputLabelTop
                readOnly={false}
                type="text"
                id="completed_by"
                label={'Completed by'}
                {...completed_by}
                maxlength={100}
              />
            </div>
            <div className="w-100 me-2">
              <DateSelector
                // disabled={!editMode}
                labelTop="Date"
                {...date}
              />
            </div>
          </div>
          <div className="d-flex mb-2">
            <div className="w-100 pe-1">
              <div
                className={'pt-2 ms-md-1 ms-0'}
                style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                When should guidance sought from a healthcare professional?
              </div>
              <InputTextArea
                type="text"
                height={'4.4rem'}
                // disabled={!editMode}
                // label={'Comments'}
                // onKeyPress={KeyPress.INTEGER}
                {...guidance_from_healthcare}
              />
              {values.professional_guidance_field_error && <div
                className={'pt-1 ms-md-1 ms-0'}
                style={{ fontSize: '0.6rem', fontWeight: 'bold', color: ERR_COLOR }}>
                Ensure this field has no more than 500 characters.
              </div>
              }
            </div>

            <div className="w-100 pe-1">
              <div
                className={'pt-2 ms-md-1 ms-0'}
                style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                Describe any alternative interventions to consider before administering the medication.
              </div>
              <InputTextArea
                type="text"
                maxlength={5000}
                height={'4.4rem'}
                // disabled={!editMode}
                // label={'Prescription Instructions'}
                // onKeyPress={KeyPress.INTEGER}
                {...describe_any_alternative}
              />
              {values.alternative_field_error && <div
                className={'pt-1 ms-md-1 ms-0'}
                style={{ fontSize: '0.6rem', fontWeight: 'bold', color: ERR_COLOR }}>
                Ensure this field has no more than 500 characters.
              </div>}
            </div>
          </div>



          <div className="d-flex mb-2">


            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Frequency'}
                type="text"
                // onKeyPress={KeyPress.INTEGER}
                {...useInput('medication.frequency')}
              />
            </div>

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Important'}
                type="text"
                {...useInput('medication.important')}
              />
            </div>

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Prescription volume'}
                type="text"
                {...useInput('medication.prescription_volume')}
              />
            </div>
          </div>

          {/* Added by divyansh */}



          <div className="d-flex mb-2">


            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Reason'}
                type="text"
                {...useInput('medication.reason')}
              />
            </div>

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Potential side effects'}
                type="text"
                {...useInput('medication.potential_side_effects')}
              />
            </div>

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Comments'}
                type="text"
                {...useInput('medication.comments')}
              />
            </div>
          </div>

          <div className="d-flex mb-2">


            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Marking style'}
                type="text"
                {...useInput('medication.marking_style')}
              />
            </div>

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Storage location'}
                type="text"
                // onKeyPress={KeyPress.INTEGER}
                {...useInput('medication.storage_location')}
              />
            </div>

            {/* <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Feedback'}
                type="text"

                // onKeyPress={KeyPress.INTEGER}
                {...useInput('medication.feedback')}
              />
            </div> */}
          </div>


          <div className="d-flex mb-2">


            {/* <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Medication reason'}
                type="text"
                // onKeyPress={KeyPress.INTEGER}
                {...useInput('medication.medication_reason')}
              />
            </div> */}

            {/* <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Client'}
                options={choices.administration_type || []}
                selectedOption={values.medication ? values.medication.administration_type : null}
                onChange={value =>
                  dropDownHandleSelect({ administration_type: value ? value : null }, 'administration_type')
                }
              />
            </div> */}



            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Dose Regime'}
                type="text"
                {...useInput('medication.dose_regime')}
              />
            </div>



          </div>





          <div className="d-flex mb-2">



            <div className='w-100 pe-1'>

              <div
                className={'pt-2 ms-md-1 ms-0'}
                style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                Medication Instruction
              </div>

              <InputTextArea
                type="text"
                height={'2.4rem'}
                id="med_desc"
                placeHolder={'Medication Instruction'}
                label={'Medication Instruction'}
                {...med_hook}
              //   maxlength={5}
              />
            </div>


          </div>




          {/* Add by divyansh end  */}
          <TitleSection>Administration</TitleSection>
          <div className="d-flex mt-2">
            <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Administration Reason'}
                options={choices.administration_reason || []}
                selectedOption={values.medication ? values.medication.administration_reason : null}
                onChange={value =>
                  dropDownHandleSelect({ administration_reason: value ? value : null }, 'administration_reason')
                }
              />
            </div>
            <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Administration Type'}
                options={choices.administration_type || []}
                selectedOption={values.medication ? values.medication.administration_type : null}
                onChange={value =>
                  dropDownHandleSelect({ administration_type: value ? value : null }, 'administration_type')
                }
              />
            </div>
          </div>

          <div className="d-flex mt-2">


            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Administration support'}
                type="text"
                {...useInput('administration_support')}
              />
            </div>

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Administration Area'}
                type="text"
                {...useInput('administration_area')}
              />
            </div>

          </div>
          <div className="d-flex mt-2">
            <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Employee'}
                options={choices.employee_listing.map(val => ({ ...val, name: val.full_name })) || []}
                selectedOption={values.medication ? values.medication.employee : null}
                {...useInput('medication.employee')}
                onChange={value =>
                  dropDownHandleSelect({ employee: value ? value : null }, 'employee')
                }
              />
            </div>
            <div className="w-100 pe-1">

              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Medication status'}
                options={choices.medication_status || []}
                selectedOption={values.medication ? values.medication.medication_status : null}
                {...useInput('medication.medication_status')}

                onChange={value =>
                  dropDownHandleSelect({ medication_status: value ? value : null }, 'medication_status')
                }
              />
            </div>
          </div>

          <div className="d-flex mt-2">
            <div className="w-100 pe-1">
              <DateSelector
                isRequired
                disabled={!editMode}
                labelTop="Start Date"
                {...useInput('medication.start_date', "isRequired")}
              />
            </div>
            <div className="w-100 pe-1">
              <DropdownSearchable
                // required={((values.every === HOUR) || (values.every === MINUTE)) ? false : true}
                disabled={!editMode}
                placeHolder={'Medication Dose Timing'}
                {...useInput(`medication_dose_timing`)}
                options={choices.medication_dose_timing || []}
                selectedOption={
                  values.medication
                    ? values.medication.medication_dose_timing
                    : null
                }
                onChange={(value, label, obj) =>
                  dropDownHandleSelect(
                    { medication_dose_timing: obj ? obj : null },
                    'medication_dose_timing')
                }
              />
              {/* <TimeSelector
            required={((values.every === HOUR) || (values.every === MINUTE)) ? false : true}
            disabled={!editMode}
            labelTop={'Dose Time'}
            {...newDoseStartTime}
            //added below code  for time in 24hrs format
            timeFormat={'HH:mm'}
            dateFormat={'HH:mm'}
          /> */}
            </div>
          </div>
          <div className="d-flex mt-2">
            <div className="w-100 pe-1">
              <TimeSelector
                disabled={values.medication
                  ? !(values.medication.medication_dose_timing_name == 'Fixed')
                  : false}
                // data-error={timeError[index]}
                // errorMessage={timeErrorMessage}
                isRequired={true}
                labelTop={'Start Dose Time'}
                {...newDoseStartTime}
                //added below code  for time in 24hrs format
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              />
            </div>
            <div className="w-100 pe-1">
              <TimeSelector
                disabled={values.medication
                  ? !(values.medication.medication_dose_timing_name == 'Fixed')
                  : false}
                // data-error={timeError[index]}
                // errorMessage={timeErrorMessage}
                isRequired={true}
                labelTop={'End Dose Time'}
                {...newDoseEndTime}
                //added below code  for time in 24hrs format
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              />
            </div>
          </div>
          {/* <div className="d-flex mt-2 mb-1">

<div className="w-100 pe-1">
    <DateSelector
      disabled={!editMode}
      labelTop="Actual medication start date"
      {...useInput('medication.actual_start_date')}
    />

  </div>

  <div className="w-100 pe-1">
    <TimeSelector
      disabled={!editMode}
      // data-error={timeError[index]}
      // errorMessage={timeErrorMessage}
      //isRequired={((values.every === HOUR) || (values.every === MINUTE)) ? false : true}
      labelTop={'Actual medication dose time'}
      {...actualMedicationDoseTime}
      //added below code  for time in 24hrs format
      timeFormat={'HH:mm'}
      dateFormat={'HH:mm'}
    />
  </div>
</div> */}

          <div className="d-flex mt-2">
            <div className="w-100 pe-1">
              <div
                className={'pt-2 ms-md-1 ms-0'}
                style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                Additional Instructions
              </div>
              <InputTextArea
                type="text"
                height={'2.4rem'}
                id="emp_desc"
                placeHolder={'Additional Instructions'}
                label={'Additional Instructions'}
                {...ins_hook}
              //   maxlength={5}
              />
            </div>
          </div>

          {/* Signature Added */}
          {/* <div className={'mt-3'}>
            <div className={'mb-2'}>
              <TitleSection>Signature</TitleSection>
            </div>
            <div className="d-flex align-items-center" >
              {
                signature &&
                <img
                  style={{ background: '#eef1f38c' }}
                  height={100} width={200}
                  src={signature} alt='sign' />
              }
            </div>
          </div> */}

          <div>
            <TitleSection>Are there any additional instructions ?</TitleSection>
            <div>
              {values?.medication_body_part?.map((item, indexBody) => {
                return (<>
                  <BorderContainer className={'ps-1 pe-2 pt-1 pb-1 mb-2'}>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex w-100'>
                        <div className='ms-1 me-2' style={{ color: PURPLE }}>
                          <IoIosBody />
                        </div>
                        <div className='d-flex w-100'>
                          <div className='' style={{ width: '30%' }}>
                            {item.tittle}
                          </div>
                          <div className='ms-2' style={{ color: GRAY_700, width: '70%' }}>
                            {item.description}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex'>
                        <IconWrapper
                          style={{ color: PURPLE }}
                          onClick={() => {
                            handleRowBodyClick(indexBody);
                          }}>
                          <MdEdit />
                        </IconWrapper>
                        <IconWrapper
                          style={{ color: ERR_COLOR, marginLeft: '1rem' }}
                          onClick={() => {
                            setModalDetachBodyMap(true)
                            setBodyMapDeleteIndex(indexBody)
                          }}>
                          <RiDeleteBinLine />
                        </IconWrapper>
                      </div>
                    </div>
                  </BorderContainer>
                </>)
              })}
            </div>
            <div className={'mt-2 mb-2'}>
              <PrimaryButtonForm minWidth="6rem" onClick={() => createNewBodyMap()}>
                <FaPlus />
                Create body map
              </PrimaryButtonForm>
            </div>
          </div>

          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {false ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={'d-flex justify-content-center justify-content-md-end'}>
              <div className={'ms-0 ms-md-3 me-2'}>
                {/* <PrimaryButtonForm
                disabled={false}
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                className={'me-2'}
                onClick={() => {
                  // setModalValue()
                  history.push('/client');
                }}>
                Cancel
              </PrimaryButtonForm> */}
                <PrimaryButtonForm
                  disabled={false}
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                  onClick={handleSubmit}
                >
                  <span className={'me-2'}>Save</span>
                  {!loadingSaveMedication ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
              <div></div>
            </div>
          </div>
        </div>

        {!modalBodyMap ? null : (
          <BodyMapModal
            fullBind={fullBindBodyMap}
            setModalBodyMap={setModalBodyMap}
            useInput={useInput}
            values={values}
            setValues={setValues}
          />
        )}
        <div className='body-map-modal'>
          <ConfirmDecision
            type="confirm"
            title="Confirm"
            body={
              'Are you sure you want to delete the row? '
            }
            onOk={() => {
              toDeleteBodyData(bodyMapDeleteIndex);
            }
            }
            onNo={
              () => {
                setModalDetachBodyMap(false)
              }
            }
            {...bindModalDetachBodyMap}
          />
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default AddMedication;