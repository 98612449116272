
import { BLACK_900, WHITE } from 'shared/styles/constants/colors';
import styled from 'styled-components';
// className='d-flex' style={{ flexWrap: 'wrap', minHeight: 'auto', maxHeight: '200px', overflowY: 'auto' }}
export const ListContainer = styled.div`
display: flex;
flex-wrap: wrap;
min-height: auto;
max-height: 200px;
overflow-y: auto;
padding-top: 2px;
padding-bottom: 20px;
}
`

    // marginLeft: '30px',
    // padding: '4px',
    // width: '46%',
    // display: 'flex',
    // justifyContent: 'space-between',
    // textAlign: 'left',
    // alignItems: 'center',
    // height: 'fit-content',
    // fontSize: '14px',
    // fontWeight: '430',
    // boxShadow: '0px 0.1875rem 0.375rem #00000029'

   export const ListItem = styled.div`
    margin-left: 30px;
    padding: 4px;
    width: 46%;
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;
    height: fit-content;
    font-size: 14px;
    font-weight: 430;
    box-shadow: 0px 0.1875rem 0.375rem #00000029;
  `;

  export const Actions = styled.div`

  .dropdown-toggle::after {
    display: none;
    margin-left: 0;
    vertical-align: 0;
    content: "";
    border-top: none;
    border-right: none;
    border-bottom: 0;
    border-left: none;
  }
`;


export const BorderContainer = styled.div`
  background: ${WHITE}

  border-top: 3px solid white
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  width: ${props => (props.width ? props.width : 'auto')};

  min-height: ${props => (props.minHeight ? props.minHeight : 'auto')};
  max-height: ${props => (props.maxHeight ? props.maxHeight : 'auto')};

  -webkit-box-shadow:  0.1875rem 0.1875rem 0.25rem #00000029, -0.1875rem -0.1875rem 0.25rem ${BLACK_900};
  box-shadow: 0.1875rem 0.1875rem 0.25rem #00000029, -0.1875rem -0.1875rem 0.25rem ${BLACK_900};
  
`;