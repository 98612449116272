import TimeSelector from 'components/SharedComponents/TimeSelector';
import React, { Fragment, useEffect } from 'react';
import DurationInput from 'components/SharedComponents/DurationInput';
import moment from 'moment';

const ServiceTime = ({
  indexEmployee,
  setValues,
  useInput,
  index,
  nameSection,
  values,editMode
}) => {
  const start_time =
    values.visits[index].employee_required[indexEmployee].start_time;
  const end_time =
    values.visits[index].employee_required[indexEmployee].end_time;

  useEffect(() => {
    //debugger
    if (
      values.visits[index].employee_required[indexEmployee].start_time &&
      values.visits[index].employee_required[indexEmployee].end_time
    ) {
      let start_time = moment(
        values.visits[index].employee_required[indexEmployee].start_time,
        'h:mma'
      );
      let end_time = moment(
        values.visits[index].employee_required[indexEmployee].end_time,
        'h:mma'
      );
      if (end_time > start_time) {

        // let seconds = end_time.diff(
        //   start_time,
        //   'seconds'
        // );
        // seconds = parseInt(seconds) //because moment js dont know to handle number in string format
        // let formatted = Math.floor(moment.duration(seconds, 'seconds').asHours()) + ':' + moment.duration(seconds, 'seconds').minutes();

        values.visits[index].employee_required[indexEmployee].duration = end_time.diff(
          start_time,
          'seconds'
        );
      } else {
        let add_date = end_time.add(1, 'days')
        // let seconds = add_date.diff(
        //   start_time,
        //   'seconds'
        // );
        // seconds = parseInt(seconds) //because moment js dont know to handle number in string format
        // let formatted = Math.floor(moment.duration(seconds, 'seconds').asHours()) + ':' + moment.duration(seconds, 'seconds').minutes();
        values.visits[index].employee_required[indexEmployee].duration = add_date.diff(
          start_time,
          'seconds'
        );
      }

    } else {
      values.visits[index].employee_required[indexEmployee].duration = 0;
    }
    setValues({ ...values });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_time, end_time]);

console.log(moment.utc(values.visits[index].employee_required[indexEmployee].duration * 1000).format('HH:mm:ss'),"5569998")

  return (
    <>
      <div className='w-100 ms-2'>
      <TimeSelector
      disabled={!editMode}
      //  disabled={
      //   values.visits[index]
      //   .employee_required[indexEmployee].id 
      //     ? true
      //     : false
      // }
        //data-error={timeError[index]}
        //errorMessage={timeErrorMessage}
        //isRequired={isFullDay ? false : true}
        labelTop={'Start time'}
        {...useInput(
          `${[nameSection]}.start_time`
        )}
                 //added below code  for time in 24hrs format 
                 timeFormat={"HH:mm"}
                 dateFormat={"HH:mm"}
      />
      </div>
      <div className='w-100 ms-2'>
      <TimeSelector
       disabled={!editMode}
      //  disabled={
      //   values.visits[index]
      //   .employee_required[indexEmployee].id 
      //     ? true
      //     : false
      // }
        //data-error={timeError[index]}
        //errorMessage={timeErrorMessage}
        //isRequired={isFullDay ? false : true}
        labelTop={'End time'}
        {...useInput(
          `${[nameSection]}.end_time`
        )}
                 //added below code  for time in 24hrs format 
                 timeFormat={"HH:mm"}
                 dateFormat={"HH:mm"}
      />
      </div>
      <div className='w-100 ms-2'>
      <DurationInput
      
      disabled={!editMode}
        label_top={'Duration'}
        {...useInput(`${[nameSection]}.duration`)}
      />
      </div>
    </>
  )
}

export default ServiceTime;