import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import Table from 'components/SharedComponents/Table';
import { Col, ColsGrouper, Row } from 'components/SharedComponents/Table/styles';
import { useModal } from 'hooks/ModalHook';
import React from 'react'
import { useState } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { EX_LARGE_COL, EX_SMALL_COL4 } from 'shared/styles/constants/columns';
import { ModalContainer } from 'shared/styles/constants/tagsStyles'
import { updateEmployeeCalendarType } from 'utils/api/SettingsApi';
const headerColumns = [
    {
        label: 1,
        checkbox: true,
        name: 'checkbox',
        status: 0,
        className: 'grow-mobile',
        max_width: EX_SMALL_COL4,
    },
    {
        label: 'Employee Name',
        name: '',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_LARGE_COL,
    },
    {
        label: 'Calender Type',
        name: '',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_LARGE_COL,
    },
    {
        label: 'Hired Date',
        name: '',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_LARGE_COL,
    },
    {
        label: 'Employment End Date',
        name: '',
        status: 0,
        className: 'sm-hidden',
        max_width: EX_LARGE_COL,
    }

];

function UpdateEmployeesAbsence({
    setAddEmployeeUpdateModal,
    fullBind,
    employeeList,
    checkedRows,
    setCheckedRows,
    handleUpdate,
    setModalWarningValue,
    bindModalWarning
 }) {
    
  

    const handleSelectChange = (e, item) => {
        if (e.target.checked) {
            setCheckedRows([...checkedRows, item.id])
        } else {
            let index = checkedRows.indexOf(item.id)
            if (index != -1) {
                let tempCheckedRows = [...checkedRows]
                tempCheckedRows.splice(index, 1)
                setCheckedRows([...tempCheckedRows])
            }
        }
    }


    const handleAllCheck = () => {
        let checkAll = true;

        const visitExists = id => {
            return checkedRows.some(function (el) {
                return el === id;
            });
        };
        if (checkedRows && checkedRows.length > 0) {
            employeeList.map(item => {
                if (!visitExists(item.id)) {
                    checkAll = false;
                }
            });
        } else {
            checkAll = false;
        }
        return checkAll;
    };



    const handleAllSelectChange = valcheck => {
        let checked = [...checkedRows];
        if (valcheck.target.checked) {
            employeeList.map(item => {
                checked.push(item.id);
            });
        } else {
            employeeList.map(el => {
                checked.map((item, index) => {
                    if (item === el.id) {
                        checked.splice(index, 1);
                    }
                });
            });
        }
        setCheckedRows(checked);
        // setCheckedId(() => checked.map(item => item.id));
    };
    
    return (
        <div>
            <ModalContainer max_width={'65%'}>
                <ModalNoInfo
                    {...fullBind}
                    max_width={'60%'}
                    title={"Add Employee Absence"}
                    minWidthTitle="6.5rem"
                    loading={false}
                >
                    <div className='ps-3 pe-3 pb-3 setting-absence'>
                        <>
                            <div className='d-flex justify-content-end m-2'>
                                {checkedRows && checkedRows.length > 0 &&
                                    <div class="  me-2">
                                        <PrimaryButtonForm
                                            minWidth="6rem"
                                            onClick={() => setModalWarningValue(true)}>
                                            <span className={'me-2'}>Save</span>
                                        </PrimaryButtonForm>
                                    </div>
                                }
                            </div>
                            <div className='mt-3 mb-4 table-container'>
                                <Table
                                    headerColumns={headerColumns}
                                    // callBackQuerySort={setQuerySort}
                                    handleAllCheck={handleAllCheck}
                                    handleAllSelectChange={handleAllSelectChange}
                                    headerPadding={false}
                                    noEditOption={true}
                                    noNeed={false}
                                >
                                    <div>
                                        {
                                            employeeList && employeeList?.map((item, index) => {
                                                return (
                                                    <div>
                                                        <Row key={Math.random()} bgColor Shadow >
                                                            <ColsGrouper className="d-flex justify-content-start h-30">
                                                                <Col
                                                                    className='custom-padding'
                                                                    max_width={EX_SMALL_COL4}
                                                                    Shrink={false}
                                                                    NoFlexGrow={true}
                                                                    reducePadding
                                                                    Capitalize
                                                                    Center
                                                                >
                                                                    <input
                                                                        class="form-check-input"
                                                                        type="checkbox"
                                                                        checked={checkedRows.includes(item.id)}
                                                                        id="flexCheckDefault"
                                                                        onChange={event => {
                                                                            handleSelectChange(event, item, index);
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col
                                                                    max_width={EX_LARGE_COL}
                                                                    Center
                                                                    className="sm-hidden"
                                                                    overlap={true}>
                                                                    <label>{item.full_name}</label>
                                                                </Col>
                                                                <Col
                                                                    max_width={EX_LARGE_COL}
                                                                    Center
                                                                    className="sm-hidden"
                                                                    overlap={true}>
                                                                    <label>{item.job_detail__absenceCalendarType__name}</label>
                                                                </Col>
                                                                <Col
                                                                    max_width={EX_LARGE_COL}
                                                                    Center
                                                                    className="sm-hidden"
                                                                    overlap={true}>
                                                                    <label>{item.job_detail__start_date}</label>
                                                                </Col>
                                                                <Col
                                                                    max_width={EX_LARGE_COL}
                                                                    Center
                                                                    className="sm-hidden"
                                                                    overlap={true}>
                                                                    <label>{item.job_detail__end_date}</label>
                                                                </Col>
                                                            </ColsGrouper>

                                                        </Row>
                                                        <div className='mb-1' />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Table>
                            </div>
                        </>
                        <div className='emp-warning'>
                            <ModalDecision
                                type="warning"
                                title="Warning"
                                body={'Are you sure you want to update this calender type for selected employees?'}
                                // subBody={'Do you still want to continue?'}
                                onOk={() => handleUpdate()}
                                onCancel={() => {
                                    setModalWarningValue(false);
                                }}
                                okTitle={'Confirm'}
                                {...bindModalWarning}
                            />
                        </div>
                    </div>
                </ModalNoInfo>
            </ModalContainer>
        </div>

    )
}

export default UpdateEmployeesAbsence