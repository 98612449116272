import React, { Fragment } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { BsChevronLeft, BsChevronRight, BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";
import { PageLink, PaginationItem } from './styles';
import { LAST_PAGE_VALUE } from 'utils/constants/pagination';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { PURPLE } from 'shared/styles/constants/colors';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import UpwardListDropdown from 'components/SharedComponents/UpwardListDropdown';
import { values } from 'lodash';

function Pagination({
  theme = '',
  currentPage = 0,
  totalPages = 0,
  pathname = '',
  simplePagination = false,
  setCurrentPage,
  reduceMargin = false,
  dropDownHandleSelect = () => { },
  values,
  number,
  dataLength,
  dropdownEntriesLimit = true,
}) {


  const history = useHistory();
  const location = useLocation();

  function handlePageClick(pageClicked) {

    if (pageClicked < 1 || pageClicked > totalPages) {
      return;
    }

    if (pageClicked === LAST_PAGE_VALUE) {
      pageClicked = totalPages - 1;
    }

    if (simplePagination) {
      setCurrentPage(pageClicked);
      return;
    }
if(!dataLength){

  setCurrentPage(pageClicked)
}
    const urlParams = queryString.parse(location.search);
    urlParams[PAGINATION_PARAM] = pageClicked;
    const stringified = queryString.stringify(urlParams);

    history.push({
      pathname: pathname,
      search: stringified,
    });
  }

  function isCurrentPage(index, current) {
    return current === index ? 'active' : '';
  }

  function checkDisabledStatusBack(currentPage) {
    return currentPage === 1 ? ' disabled' : '';
  }

  function checkDisabledStatusForward(currentPage, totalPages) {
    return currentPage === totalPages ? ' disabled' : '';
  }

  function generatePagination(current, last) {
    // max elements in the pagination = leftDebt + current + rightDebt
    let leftDebt = 2;
    let rightDebt = 1;

    // generate the left number pages before the current page
    let leftPagination = generateLeftPagination(current, leftDebt);

    //set remaining left debt (Ej. if the selected page was 1 then the leftDebt stays untouched so that debt needs to carry to the rightDebt)
    leftDebt = leftPagination.leftDebt;
    rightDebt += leftDebt;

    // generate the right number pages after the current page
    let rightPagination = generateRightPagination(
      current,
      rightDebt,
      leftPagination.range,
      last,
    );

    let finalPagination;
    if (rightPagination.rightDebt) {
      // if the rightDebt was not used completely that's because the last page is on the final range, so the rightDebt is passed to the leftDebt one last time
      finalPagination = generateRemainingDebtLeftPagination(
        rightPagination.range,
        rightPagination.rightDebt,
      );
    } else {
      // if the rightDebt is completely used then we have the final range
      finalPagination = rightPagination;
    }

    // check if the last page number is on the range, if not then set LAST_PAGE_VALUE at the last option
    if (current + 1 < last - rightDebt) {
      finalPagination.range.push(LAST_PAGE_VALUE);
    }

    if (current + 2 === last && totalPages >= 5) {
      finalPagination.range.push(last);
    }

    return finalPagination.range;
  }

  function generateLeftPagination(current, leftDebt = 2) {
    let range = [];
    let left = leftDebt;
    let currentMoved = current;

    //set current page number like first element of the rage
    range.push(current);

    for (let i = 1; i <= left; i++) {
      // if no leftDebt pending or current number page is 1 then break
      if (leftDebt <= 0 || currentMoved === 1) {
        break;
      }

      // Ej. range -> [4] ; current -> 4 ; -> [3,4]
      range.unshift(currentMoved - 1);
      currentMoved -= 1;
      leftDebt -= 1;
    }

    return {
      range,
      leftDebt,
    };
  }

  function generateRightPagination(current, rightDebt = 1, range = [], last) {
    let right = rightDebt;
    let currentMoved = current;
    for (let i = 1; i <= right; i++) {
      // if no rightDebt pending or current number page is equal to the last one then break
      if (rightDebt <= 0 || currentMoved === last) {
        break;
      }

      // Ej. range -> [2,3,4] ; current -> 4 ; -> [2,3,4,5]
      range.push(currentMoved + 1);
      currentMoved += 1;
      rightDebt -= 1;
    }

    return {
      range,
      rightDebt,
    };
  }

  function generateRemainingDebtLeftPagination(range = [], leftDebt = 0) {
    let left = leftDebt;
    let currentMoved = range[0];

    for (let i = 1; i <= left; i++) {
      // if no leftDebt pending or current number page is equal to 1 then break
      if (leftDebt <= 0 || currentMoved === 1) {
        break;
      }

      // Ej. range -> [2,3,4,5] ; current -> 2 ; -> [1,2,3,4,5]
      range.unshift(currentMoved - 1);
      currentMoved -= 1;
      leftDebt -= 1;
    }

    return {
      range,
      leftDebt,
    };
  }
  // const limitperpage='20';totalPages>1
  return (
    <Fragment>
      {dataLength >= 1 ? (
        <nav aria-label="Search activity records">
          <div
            className={
              'd-flex justify-content-center pagination-component ' + theme
            }>
            <ul className="pagination align-self-baseline">
              {/* for first page only  */}
              <li className={checkDisabledStatusBack(currentPage)}>
                <PageLink
                  className="page-link"
                  aria-label="DoublePrevious"
                  onClick={() => handlePageClick(1)}>
                  <div >
                    <BsChevronDoubleLeft color={PURPLE} size={15} />
                  </div>
                </PageLink>
              </li>


              <li className={checkDisabledStatusBack(currentPage)}>
                <PageLink
                  className="page-link"
                  aria-label="Previous"
                  onClick={() => handlePageClick(currentPage - 1)}>
                  <div>
                    {/* <IoIosArrowBack color={PURPLE} size={17} /> */}
                    <BsChevronLeft color={PURPLE} size={15} />
                  </div>
                </PageLink>
              </li>



              {generatePagination(currentPage, totalPages).map(
                (item, index) => (
                  <PaginationItem
                    reduceMargin={reduceMargin}
                    key={'pagination-item-' + index}
                    className={'page-item ' + isCurrentPage(item, currentPage)}>
                    <PageLink
                      className="page-link"
                      onClick={() => handlePageClick(item)}>
                      {item}
                    </PageLink>
                  </PaginationItem>
                ),
              )}

              {currentPage >= totalPages - 2 || totalPages <= 4 ? null : (
                <PaginationItem
                  reduceMargin={reduceMargin}
                  key={'pagination-item-totalpages'}
                  className={'page-item '}>
                  <PageLink
                    className="page-link"
                    onClick={() => handlePageClick(totalPages)}>
                    {totalPages}
                  </PageLink>
                </PaginationItem>
              )}

              <li
                className={checkDisabledStatusForward(currentPage, totalPages)}>
                <PageLink
                  // className="page-link justify-content-between"
                  className="page-link"
                  aria-label="Next"
                  onClick={() => handlePageClick(currentPage + 1)}>
                  {/* <div>Next</div> */}
                  <div >
                    <BsChevronRight color={PURPLE} size={15} />
                  </div>

                </PageLink>
              </li>

              {/* for last page */}
              <li
                className={checkDisabledStatusForward(currentPage, totalPages)} >
                <PageLink
                  // className="page-link justify-content-between"
                  className="page-link "
                  aria-label="DoubleNext"
                  onClick={() => handlePageClick(totalPages)}>
                  <div  >
                    <BsChevronDoubleRight color={PURPLE} size={15} />
                  </div>
                </PageLink>
              </li>
            </ul>
            {/* use w-25  h-12  in div  for width  below  */}
            {dropdownEntriesLimit ? (
              <div className=' ms-1 ps-1 d-block '  >
                <div className=' ms-1 ps-1  d-block   '   >
                  <UpwardListDropdown
                    width={'110%'}
                    clearable={false}
                    // placeHolder={'Entries'}
                    options={[
                      { id: 20, name: '20' },
                      { id: 30, name: '30' },
                      { id: 40, name: '40' },
                      { id: 50, name: '50' },
                      { id: 100, name: '100' },
                    ]}
                    selectedOption={values?.limitperpage ? values?.limitperpage : number}
                    onChange={value =>{
                      dropDownHandleSelect(
                        { limitperpage: value ? value : number }, 'limitperpage'
                      )
                     

                        if(values.limitperpage!==20){
                            const urlParams = queryString.parse(location.search);
                            urlParams[PAGINATION_PARAM] = 1;
                            const stringified = queryString.stringify(urlParams);
                          
                            history.push({
                              pathname: pathname,
                              search: stringified,
                            });
                          
                          }
                    }
                     }
                  />
                </div>
              </div>
            ) : null}
          </div>


        </nav>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
}

export default Pagination;
