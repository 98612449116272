import React, { useEffect, useState, useRef, useContext } from 'react';
import { Title, TitleSection } from 'shared/styles/constants/tagsStyles';
import Template from 'components/SharedComponents/SettingsTemplate';
import LineContainer from 'components/SharedComponents/LineContainer';
import PermissionsTable from './Table';
import {
  getRoles,
  deleteRole,
  setRole,
  getSections,
  getRolesAndPer,
  getSelectedPermissions,
  changePermission,
  changeCategoryPermission,
  changeSectionPermission,
  changeSubsectionPermission,
  updateRowLevelPermissions,
} from 'utils/api/UsersApi';
import { FLOPPY, CHECK } from 'utils/constants/icons';
import { SAVED, FINISH } from 'utils/constants/messages';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {
  ROLES_AND_PERMISSIONS_SETTINGS,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from 'utils/constants/permissions';
import { getCategoryPermission, getPermissions, getStaff, getClient, getEmployee } from 'utils/localStorage/user';
import { getAdmin, getSuperAdmin } from "utils/localStorage/token"
import { AppContext } from "context"
import { Toast } from 'primereact/toast';
import {SettingsGridContainer} from 'components/SharedComponents/SettingsTemplate/styles'
let userTypeDropdownItems = [
  { name: "Important Contact", id: "important contact" },
  { name: "Employee", id: "employee" },
  { name: "Client", id: "client" }
];

export const ENABLE_ALL = "Enable All Rights"
export const DISABLE_ALL ="Disable All Rights"
export const ENABEL_SELF ="Enable Self Rights"
export const DISABLE_SELF ="Disable Self Rights"

const RolesAndPermissions = (props) => {
  const [permissionsSections, setPermissionsSections] = useState([]);
  const [permissions, setPermissions] = useState([]);
  
  const [selectedRol, setSelectedRol] = useState({});
  const [rolValues, setRolValues] = useState([]);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(FINISH);
  const [loadingSave, setLoadingSave] = useState(false);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const { contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff } = useContext(AppContext);
  const [userType, setUserType] = useState(null)
  const [loadingRolValues, setLoadingRolValues] = useState(false);
  const [permissionLoading, setPermissionLoading] = useState(false)
  const [expandable, setExpandable] = useState({});
  const scrollValue = useRef(0);

  const mountedRef = useRef(true);
  const toast = useRef(null)
  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  

  useEffect(() => {
    loadSections();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    const roles = contextPermissions;

    let rolePermissions = {};
    if (roles.length > 0) {
      roles.forEach(item => {
        if (item?.section?.name === ROLES_AND_PERMISSIONS_SETTINGS) {
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS] = {};
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION] = {};
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
      });

      if (filterAdmin == true || filterSuperAdmin == true) {
        setCreatePermissions(true);
        setDeletePermissions(true);
        setUpdatePermissions(true);
      }
      else {
        setCreatePermissions(
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissions(
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissions(
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ]
        );

        setReadPermissions(
          rolePermissions[ROLES_AND_PERMISSIONS_SETTINGS][WEB_PERMISSION][
          WEB_READ_PERMISSION
          ]
        );
      }

    }


  }, [])


  useEffect(() => {
    setButtonIcon(FLOPPY);
    setTextStatus(FINISH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(rolValues)]);

  useEffect(() => {
    if (Object.keys(selectedRol).length >= 1) {
      loadRolPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRol]);

  const loadSections = async () => {
    return getRolesAndPer()
      .then(response => {
        setPermissionsSections(response);
        return response;
      })
      .catch(() => { });
  };

  const loadselectedPermission = async () => {
    return getSelectedPermissions(selectedRol.id)
      .then(response => {
        setPermissions(response)
        return response;
      })
      .catch(() => { });
  }

  const loadRolPermission = async () => {
    setLoadingRolValues(true);
    const mySections = await loadSections();
    const addArrayList = JSON.parse(JSON.stringify(mySections));

    const permissionData = await loadselectedPermission();
   
    formatPermissionData(addArrayList,permissionData)

    setLoadingRolValues(false);
  };


  const formatPermissionData = (addArrayList, permissionData) => {
    const myRolPermissions = [];
    const addWebValues = myValue => {
      if (myValue) {
        return {
          web_create_self: myValue.self_web_create || false,
          web_read_self: myValue.self_web_read || false,
          web_update_self: myValue.self_web_update || false,
          web_delete_self: myValue.self_web_delete || false,
          web_create_all: myValue.web_create || false,
          web_read_all: myValue.web_read || false,
          web_update_all: myValue.web_update || false,
          web_delete_all: myValue.web_delete || false,
        };
      } else {
        return {
          web_create_self: false,
          web_read_self: false,
          web_update_self: false,
          web_delete_self: false,
          web_create_all: false,
          web_read_all: false,
          web_update_all: false,
          web_delete_all: false,
        };
      }
    };
    const addMobileValues = myValue => {
      if (myValue) {
        return {
          mobile_create_self: myValue.self_mobile_create || false,
          mobile_read_self: myValue.self_mobile_read || false,
          mobile_update_self: myValue.self_mobile_update || false,
          mobile_delete_self: myValue.self_mobile_delete || false,
          mobile_create_all: myValue.mobile_create || false,
          mobile_read_all: myValue.mobile_read || false,
          mobile_update_all: myValue.mobile_update || false,
          mobile_delete_all: myValue.mobile_delete || false,
        };
      } else {
        return {
          mobile_create_self: false,
          mobile_read_self: false,
          mobile_update_self: false,
          mobile_delete_self: false,
          mobile_create_all: false,
          mobile_read_all: false,
          mobile_update_all: false,
          mobile_delete_all: false,
        };
      }
    };

    //check all sections
    addArrayList.forEach((principalArray, index) => {

      let categoryCheck = [];

      permissionData.forEach((val) => {
        if (val.category_name.id === principalArray.id) {
          categoryCheck = val;
        }
      })

      //add new index in myRolPermissions
      myRolPermissions.push({});
      myRolPermissions[index].name = principalArray.name;
      myRolPermissions[index].id = principalArray.id;
      myRolPermissions[index].principal = true;

      //add sections for each principal menu
      const myPrincipalSections = [];
      principalArray.section.forEach((principalSection, indexSections) => {
        // add new index in myPrincipalSections
        myPrincipalSections.push({});
        // add principal values in new index
        myPrincipalSections[indexSections].name = principalSection.name;
        myPrincipalSections[indexSections].id = principalSection.id;
        myPrincipalSections[indexSections].category = principalArray.id;
        myPrincipalSections[indexSections].principal = false;
        myPrincipalSections[indexSections].isSubsection = false;

        let sectionCheck = [];


        permissionData.forEach((val) => {
          if (val.category_name.id === principalArray.id) {
            val.section.forEach((subSecVal) => {
              if (subSecVal.section === principalSection.id) {
                sectionCheck = subSecVal;
              }
            })
          }
        })


        //add sections(sub_list) in first subList values
        myPrincipalSections[indexSections].sections = [];
        principalSection.sub_section.forEach((subSection, indexSubSection) => {


          myPrincipalSections[indexSections].sections.push({});
          // add principal aux
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].principal = false;
          // add isSubsection aux
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].isSubsection = true;
          //add name
          myPrincipalSections[indexSections].sections[indexSubSection].name =
            subSection.name;
          //add id
          myPrincipalSections[indexSections].sections[indexSubSection].id =
            subSection.id;
          //add section
          myPrincipalSections[indexSections].sections[indexSubSection].section =
            principalSection.id;
          // add category
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].category = principalArray.id;
          // add permissionId
          myPrincipalSections[indexSections].sections[
            indexSubSection
          ].permissionId = subSection.id;
          // add web
          let subSectionCheck = [];
          permissionData.forEach((val) => {
            if (val.category_name.id === principalArray.id) {
              val.section.forEach((subSecVal) => {
                if (subSecVal.section === principalSection.id) {
                  subSecVal.sub_section.forEach((sub) => {
                    if (sub.section === subSection.id) {
                      subSectionCheck = sub;
                    }
                  })
                }
              })
            }
          })
          myPrincipalSections[indexSections].sections[indexSubSection].web = addWebValues(subSectionCheck);

          // add mobile

          myPrincipalSections[indexSections].sections[indexSubSection].mobile = addMobileValues(subSectionCheck);

        });
        //add web and mobile values for first subList values
        myPrincipalSections[indexSections].web = addWebValues(sectionCheck);
        myPrincipalSections[indexSections].mobile = addMobileValues(sectionCheck);
      });


      // add web and mobile values for principal menu
      myRolPermissions[index].web = addWebValues(categoryCheck);
      myRolPermissions[index].mobile = addMobileValues(categoryCheck);
      myRolPermissions[index].sections = myPrincipalSections;
    });
    setRolValues([...myRolPermissions]);
  }
  const changeRol = async rolData => {
    setRolValues([]);
    if (Object.keys(selectedRol).length >= 1) {
      if (rolData.id === selectedRol.id) {
        setSelectedRol({});
      } else {
        setSelectedRol(rolData);
      }

      setButtonIcon(FLOPPY);
      setTextStatus(FINISH);
      return;
    }

    setSelectedRol(rolData);
    setButtonIcon(FLOPPY);
    setTextStatus(FINISH);
  };

  const handleSavePermissions = async () => {
    setLoadingSave(true);

    const savePermissionsSubSections = function () {
      return async function (permissions) {
        const objToSend = {
          sub_section_id: permissions.id,
          web_create: permissions.web.web_create,
          web_read: permissions.web.web_read,
          web_update: permissions.web.web_update,
          web_delete: permissions.web.web_delete,
          mobile_create: permissions.mobile.mobile_create,
          mobile_read: permissions.mobile.mobile_read,
          mobile_update: permissions.mobile.mobile_update,
          mobile_delete: permissions.mobile.mobile_delete,
          role_id: selectedRol.id,
        };

        try {
          const response = await changeSubsectionPermission(objToSend);

          return response;
        } catch (error) { }
      };
    };

    const savePermissionsSections = function () {
      return async function (permissions) {
        const objToSend = {
          section_id: permissions.id,
          web_create: permissions.web.web_create,
          web_read: permissions.web.web_read,
          web_update: permissions.web.web_update,
          web_delete: permissions.web.web_delete,
          mobile_create: permissions.mobile.mobile_create,
          mobile_read: permissions.mobile.mobile_read,
          mobile_update: permissions.mobile.mobile_update,
          mobile_delete: permissions.mobile.mobile_delete,
          role_id: selectedRol.id,
        };

        try {
          const response = await changeSectionPermission(objToSend);
          await Promise.all(
            permissions.sections.map(savePermissionsSubSections())
          );
          return response;
        } catch (error) { }
      };
    };

    const saveRolPermissionsSections = function () {
      return async function (permissions) {
        const objToSend = {
          category_id: permissions.id,
          web_create: permissions.web.web_create,
          web_read: permissions.web.web_read,
          web_update: permissions.web.web_update,
          web_delete: permissions.web.web_delete,
          mobile_create: permissions.mobile.mobile_create,
          mobile_read: permissions.mobile.mobile_read,
          mobile_update: permissions.mobile.mobile_update,
          mobile_delete: permissions.mobile.mobile_delete,
          role_id: selectedRol.id,
        };

        try {
          const response = await saveRolCategory(objToSend);
          await Promise.all(
            permissions.sections.map(savePermissionsSections())
          );
          return response;
        } catch (error) { }
      };
    };

    await Promise.all(rolValues.map(saveRolPermissionsSections()));

    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setLoadingSave(false);
  };

  const updateRolValues = () => {
    setRolValues([]);
    setSelectedRol({});
  };

  const saveRolCategory = async objToSend => {
    try {
      const response = await changeCategoryPermission(objToSend);
      return response;
    } catch (error) { }
  };

  const saveRolSection = async objToSend => {
    try {
      const response = await changeSectionPermission(objToSend);
      return response;
    } catch (error) { }
  };

  const saveRol = rol => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await setRole(rol);

        if (response.id) {
          resolve(response);
          props.handleChangePermissions();
        } else {
         return reject(response);
        }
        if (response.status === "FAIL" || response.status ===  'False') {
          onError(response)
         return reject(response);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const onError = (res) => {
    // {"status":"FAIL","message":"Please delete existing user_type client"}
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: `${res?.message}`,
    })
  }

  const handleCheckboxClick = (val, check, optionType, checkSelfAll) => {
    let cat_Id = val.principal ? val.id : val.category;
    let body = {
      status: check,
      view: val.columnType,
      sub_view: optionType === 'self' ? 'self' : null,
      operation: val.type,
      role_id: selectedRol.id,
    };
    if (val.principal) {
      let temp = val.sections.map((item) => {
        let subTemp = item.sections.map((subItem) => (subItem.id))
        return ({ section: item.id, sub_section: subTemp })
      })
      body.permission = [...temp]
    } else {
      let subSec = [];
      if (val.isSubsection) {
        subSec.push(val.id);
      } else {
        if (val.sections.length > 0) {
          val.sections.forEach((val) => {
            subSec.push(val.id);
          })
        }
      }
      body.permission = [{
        section: val.isSubsection ? val.section : val.id,
        sub_section: subSec,
      }]
    }
    changePer(body, cat_Id)
    // if(checkSelfAll){
    //   body.status = !check
    //   body.sub_view = optionType === 'self' ? null : 'self'
    //   changePer(body, cat_Id)
    // }

  }

  const changePer = (body, cat_Id) => {
    setPermissionLoading(true)
    changePermission(body, cat_Id)
      .then((res) => {
        if(res?.data)
          updateCategoryPermission(res?.data[0])
        setPermissionLoading(false)
       })
      .catch((err) => {
        setPermissionLoading(false)
       })
  }


  const updateCategoryPermission = (data) => {
   console.log(data)
    // Find the index of the item to update
    const categoryIndex = permissions.findIndex(item => item.id === data.id);
    
    if (categoryIndex !== -1) {
      const updatedPermissions = [...permissions];
      updatedPermissions[categoryIndex] = {...data};
      
      setPermissions(updatedPermissions);
      const addArrayList = JSON.parse(JSON.stringify(permissionsSections));
      formatPermissionData(addArrayList, updatedPermissions);
  
      console.log(categoryIndex, "categoryIndex");
    } 
  }

  const checkView = (view) => {
    switch (view) {
      case ENABLE_ALL:
        return "web"
      case DISABLE_ALL:
        return "web"
      case ENABEL_SELF:
        return "self"
      case DISABLE_SELF:
        return "self"
      default:
        break;
    }
  }


  const updateRowLevelPermission = async (values, view, isChecked) => {
    // console.log(values, view, isChecked,"values")

    let cat_Id = values.principal ? values.id : values.category;

    try {
      const body = {
        "role": selectedRol.id,
        "permission": isChecked,
        "view": checkView(view),
      }

      if (values.principal) {
        // console.log(values,"category")
        let temp = values.sections.map((item) => {
          let subTemp = item.sections.map((subItem) => (subItem.id))
          return (
            { section_id: item.id, sub_section: subTemp })
        })
        // Set category payload in body object
        body.category = {
          "category_id": cat_Id,
          "section": [...temp]
        }
      } else {
        if (values.isSubsection) {
          // console.log(values,"isSubsection")
           // Set sub_section payload in body object
          body.sub_section = {
            "category_id": cat_Id,
            "section_id": values.section,
            "sub_section_id": values.id
          }
        } else {
          // console.log(values,"section")
          let sub_sec = [];
          if (values.sections.length > 0) {
            sub_sec = values.sections.map((val) => val.id)
          }
           // Set section payload in body object
          body.section = {
            "category_id": cat_Id,
            "section_id": values.id,
            "sub_section": sub_sec
          };
        }
      }

      // console.log(body)
      const res = await updateRowLevelPermissions(body)
      if (res.status == "FAIL") throw res.message
      if (res.status == 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${res.message}`,
        });
        loadRolPermission();
      }

    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${error}`,
      })
      console.error('Error: error in updateRowLevelPermission function', error)
    }
  }

  
  return (
    <div>
      <Toast ref={toast} position='top-right' />
      <Title className={'ms-1 mb-2'}>Roles and permissions</Title>
      <SettingsGridContainer>
          <Template
            titleSection={'Roles'}
            labelInputTitle={'Role name'}
            getMethod={getRoles}
            deleteMethod={deleteRole}
            setMethod={saveRol}
            textToMessageDelete={'role'}
            specializeCase={'role'}
            specializedMethods={{
              changeRol: changeRol,
              selectedRol: selectedRol,
              updateRolValues: updateRolValues,
            }}
            addInput={[
              {
                label: 'User Type',
                value: 'user_type',
                type: "dropdown",
                required: true,
                options: [...userTypeDropdownItems],
              }]}
            staffCheck={true}
            setUserType={setUserType}
            setExpandable={setExpandable}
          />
          <LineContainer>
              <TitleSection className='px-3 py-3'>Permissions</TitleSection>
              {/*{Object.keys(selectedRol).length <= 0 ? (*/}
              <div className={'px-3'}>
             
                  <PermissionsTable 
                    rolValues={rolValues}
                    changeRol={changeRol}
                    selectedRol={selectedRol}
                    setRolValues={setRolValues}
                    handleCheckboxClick={handleCheckboxClick}
                    userType={userType}
                    permissionLoading={permissionLoading}
                    loadingRolValues={loadingRolValues}
                    updateRowLevelPermission={updateRowLevelPermission}
                    expandable={expandable}
                    setExpandable={setExpandable}
                    scrollValue={scrollValue}
                  />
                            
                {/* <div className={'d-flex justify-content-end'}>
                  <PrimaryButtonForm
                    minWidth="6rem"
                    disabled={Object.keys(selectedRol).length <= 0}
                    onClick={handleSavePermissions}>
                    <span className={'me-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                </div> */}
              </div>
          </LineContainer>
      </SettingsGridContainer>
    </div>
  );
};

export default RolesAndPermissions;