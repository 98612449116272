import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { TIMES } from 'utils/constants/icons';
import {
  CloseButtonWrapper,
  SampleMessage,
} from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { useInput } from 'hooks/InputHook';
import DurationInput from 'components/SharedComponents/DurationInput';
import moment from 'moment';
import { getSchedulerPlanned } from 'utils/api/ClientApi';
const ServiceReplicaEmployee = ({
  choices,
  nameSection,
  values,
  setValues, 
  useInput,
  dropdownHandleReplicaEmployee,
  dropdownHandleReplicaEmployeeSkill,
  editMode,
  readOnly = false,
  clientDropdownId,
}) => {
  const [plannedTasks, setPlannedTasks] = useState([]);



  useEffect(() => {

    if (
      values[nameSection] && values[nameSection].req_replica && values[nameSection].req_replica.start_time &&
      values[nameSection] && values[nameSection].req_replica && values[nameSection].req_replica.end_time
    ) {
      // debugger
      const start_time =
        values[nameSection] && values[nameSection].req_replica && values[nameSection].req_replica.start_time;
      const end_time =
        values[nameSection] && values[nameSection].req_replica && values[nameSection].req_replica.end_time;

      if (end_time && start_time) {
        // debugger
        values[nameSection].req_replica.duration = moment.utc(moment(end_time, "HH:mm:ss").diff(moment(start_time, " HH:mm:ss"))).format("HH:mm:ss")
      } else {
        // debugger
        values[nameSection].req_replica.duration = 0;
      }
    }
    setValues({ ...values });

  }, [values[nameSection] && values[nameSection].req_replica && values[nameSection].req_replica.start_time, values[nameSection] && values[nameSection].req_replica && values[nameSection].req_replica.end_time])

  useEffect(() => {
    if (clientDropdownId) {
      getPlannedTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDropdownId])


  const getPlannedTasks = async () => {
    const response = await getSchedulerPlanned(clientDropdownId);

    if (response.results) {
      setPlannedTasks(response.results.map((item) => {
        return { id: item.id, name: item.group_name }
      }));

    }

  };


  return (
    <div className={'mb-3'}   >
      <div className={`d-flex mb-1 `} >
        <div className={'w-100 p-0 d-flex mb-1'}>
          <DropdownSearchable
            disabled={readOnly || editMode}
            width={'35%'}
            required
            {...useInput(
              `${[nameSection]}.req_replica.no_of_employees`,
              'isRequired'
            )}
            placeHolder={'No. of Employees'}
            options={[
              { id: 1, name: 1 },
              { id: 2, name: 2 },
              { id: 3, name: 3 },
              { id: 4, name: 4 },
              { id: 5, name: 5 },
            ]}
            selectedOption={values[nameSection]
              ? values[nameSection].req_replica
                ? values[nameSection].req_replica.no_of_employees
                : null
              : null
            }
            onChange={value =>
              dropdownHandleReplicaEmployee(
                { no_of_employees: value ? value : null },
                'no_of_employees'
              )
            }
          />

          <DropdownSearchable
            //  disabled={readOnly} 
            disabled={editMode}
            {...useInput(
              `${[nameSection]}.req_replica.skill_level`,

            )}
            placeHolder={'Employee Type'}
            marginLeft={'1%'}
            width={'50%'}
            options={choices.job_title}
            selectedOption={
              values[nameSection]
                ? values[nameSection].req_replica
                  ? values[nameSection].req_replica.skill_level
                  : null
                : null
            }
            onChange={value =>
              dropdownHandleReplicaEmployee(
                { skill_level: value ? value : null },
                'skill_level'
              )
            }
          />
          <DropdownSearchable
            width={'100%'}
            // disabled={readOnly}
            disabled={editMode}
            marginLeft={'1%'}
            placeHolder={'Skills required'}
            options={choices.skill}
            isMulti={true}
            selectedOptionsProps={
              values[nameSection]
                ? values[nameSection].skill_replica
                : null
            }
            onChange={value =>
              // console.log(value,999)
              dropdownHandleReplicaEmployeeSkill(
                { skill: value.length ? value : [] }

              )
            }

          />
        </div>
      </div>
      <div className={'w-100 d-flex'}>
        <DropdownSearchable
          width={'100%'}
          disabled={editMode}
          // marginLeft={'2.4%'}
          placeHolder={'Competency required'}
          options={choices.competency_name}
          isMulti={true}
          selectedOptionsProps={
            values[nameSection].competency
          }
          onChange={value =>
            dropdownHandleReplicaEmployeeSkill(
              { competency: value.length ? value : [] }
            )

          }
        />

        {/* <DropdownSearchable
          width={'30%'}
          marginLeft={'0.5%'}
          placeHolder={'Planned Tasks'}
          options={plannedTasks ? plannedTasks : []}
          isMulti={true}
          selectedOptionsProps={
            values[nameSection].specific_groups
          }
          onChange={value =>
            dropdownHandleReplicaEmployeeSkill(
              { specific_groups: value.length ? value : [] }
            )

          }
        /> */}
      </div>
      <div className={'w-100 d-flex mt-2'}>
        <div className='w-100 pe-2' >
          <DropdownSearchable
            placeHolder={'Preferred Gender'}
            // disabled={readOnly}
            disabled={editMode}
            // width={'19%'}
            // marginRight={'1%'}
            options={choices.gender}
            selectedOption={
              values[nameSection]
                ? values[nameSection].req_replica
                  ? values[nameSection].req_replica.preferred_gender
                  : null
                : null
            }
            onChange={value =>
              dropdownHandleReplicaEmployee(
                { preferred_gender: value ? value : null },
                'preferred_gender'
              )
            }
          />
        </div>
        <div className='w-100 pe-2' >
          <TimeSelector
            labelTop={'Shift Start'}
            // disabled={readOnly}
            disabled={editMode}
            {...useInput(`${[nameSection]}.req_replica.start_time`)}
            //added below code  for time in 24hrs format 
            timeFormat={"HH:mm"}
            dateFormat={"HH:mm"}
          />
        </div>
        <div className='w-100 pe-2'>
          <TimeSelector
            labelTop={'Shift End'}
            // disabled={readOnly}
            disabled={editMode}
            {...useInput(`${[nameSection]}.req_replica.end_time`)}
            //added below code  for time in 24hrs format 
            timeFormat={"HH:mm"}
            dateFormat={"HH:mm"}
          />
        </div>
        <div className='w-100' >
          <DurationInput
            // disabled={true}
            disabled={editMode}
            label_top={'Duration'}
            {...useInput(`${[nameSection]}.req_replica.duration`)}
          />
        </div>
      </div>

    </div>
  );

}

export default ServiceReplicaEmployee;
