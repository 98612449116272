import React, {useState, useEffect, Fragment, useRef} from 'react';
import {
  ModalContainer,
  FlexGrid3Container,
  FlexGrid3Item,
  MessageSpan,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import EditableDropdownList from 'components/SharedComponents/EditableDropdownList';
import {Toast} from 'primereact/toast';
import EditableDropdown from 'components/SharedComponents/EditableDropdown';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DateSelector from 'components/SharedComponents/DateSelector';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import {useForm} from 'hooks/FormHook';
import {editCancelledVisit} from 'utils/api/ClientApi';
import moment from 'moment';
const CancelVisitModals = ({
  fullBind,
  errorMessage,
  textStatus,
  saveButtonIcon,
  loadingSave,
  eventValues,
  setEventValues,
  nameSection,
  values,
  setValues,
  choices,
  listView,
  cancelledvisits,
  search,
  useInput,
  setCancelledVisit,
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [textAreaValue, setTextAreaValue] = useState(
    values.visit_cancellation_notes
  );
  const toast = useRef();
  const deep = 'cancelValues';
  const [employees, setEmployees] = useState([]);
  const [newTime, setTime] = useState('');
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Edit Cancel Visit',
    });
  }, [fullBind]);

  useEffect(() => {
    if (eventValues) {
      let newEmployee =
        choices.employee_listing &&
        choices.employee_listing.find(item => {
          if (item.id === eventValues.employee) {
            return item;
          }
        });
      let newDate = eventValues.visit_cancelled_datetime && moment(
        eventValues.visit_cancelled_datetime &&
          eventValues.visit_cancelled_datetime
      ).format('YYYY-MM-DD');

      let newTime = eventValues.visit_cancelled_datetime && moment(
        eventValues.visit_cancelled_datetime &&
          eventValues.visit_cancelled_datetime
      ).format('HH:mm');

      setValues({
        ...values,
        ...{visit_cancelled_datetime: newDate},
        ...{time_of_cancel: newTime},
        ...{is_fixed_cancel_amount: eventValues.is_fixed_cancel_amount},
      });

      setEmployees(
        choices &&
          choices.employee_listing &&
          choices.employee_listing.map(y => {
            return {id: y && y.id, name: y && y.full_name};
          })
      );
    }
  }, []);

  const handleChangeStaff = (e, val) => {
    eventValues.staff_to_be_billed = e === 'selected' ? val : e.target.value;
    setEventValues({...eventValues});
  };

  const handleChangeClient = (e, val) => {
    eventValues.client_to_be_billed = e === 'selected' ? val : e.target.value;
    setEventValues({...eventValues});
  };
  const dropDownHandleSelects = item => {
    setEventValues({...eventValues, ...item});
  };
  const handleTextArea = e => {
    eventValues.visit_cancellation_notes = e.target.value;
    setEventValues({...eventValues});
  };

  const handleSubmit = async () => {
    let body = {
      visit_ids: [eventValues.id],
      visit_cancellation_reason:
        eventValues.visit_cancellation_reason.id ||
        eventValues.visit_cancellation_reason,
      visit_cancellation_notes: eventValues.visit_cancellation_notes,
      visit_cancellation_notification_method:
        eventValues.visit_cancellation_notification_method.id ||
        eventValues.visit_cancellation_notification_method,
      visit_cancelled_datetime: values.visit_cancelled_datetime+"T"+ values.time_of_cancel,
      client_to_be_billed: eventValues.client_to_be_billed,
      is_fixed_cancel_amount: values.is_fixed_cancel_amount,
      employee_id: eventValues.employee,
      staff_to_be_billed:eventValues.staff_to_be_billed
    };
    let response = await editCancelledVisit(body);
    if (response.status === 'True') {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Data Updated Successfully',
      });
      setTimeout(() => {
        setCancelledVisit(false);
      }, 1000);
      search()
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: 'something went wrong',
      });
    }

  };
 
  return (
    <ModalContainer max_width={'50%'}>
      <Toast ref={toast} position="top-right" />
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="6.5rem"
        loading={false}>
        <div className={' ps-3 pe-3 pb-3'}>
          <div className="mt-3 mb-2">
            <div className="d-flex">
              <FlexGrid3Item className="pe-1 pb-2 mb-2">
                <DropdownSearchable
                  required
                  placeHolder={'Reason For Cancellation'}
                  width="145%"
                  options={choices.cancellation_reason}
                  {...useInput(`visit_cancellation_reason`, 'isRequired')}
                  selectedOption={
                    eventValues.visit_cancellation_reason?
                    eventValues.visit_cancellation_reason.id
                    :null
                  }
                  onChange={value =>
                    dropDownHandleSelects({
                      visit_cancellation_reason: value ? value : null,
                    })
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 pb-2 mb-2">
                <DropdownSearchable
                  required
                  placeHolder={'Notification Method Of Cancellation'}
                  width="145%"
                  marginLeft="50%"
                  options={choices.notification}
                  {...useInput(
                    `visit_cancellation_notification_method`,
                    'isRequired'
                  )}
                  selectedOption={
                    eventValues.visit_cancellation_notification_method?
                    eventValues.visit_cancellation_notification_method.id
                    :null
                  }
                  onChange={value =>
                    dropDownHandleSelects(
                      {
                        visit_cancellation_notification_method: value
                          ? value
                          : null,
                      },
                      'visit_cancellation_notification_method'
                    )
                  }
                />
              </FlexGrid3Item>
            </div>

            <InputTextArea
              type="text"
              height={'4.5rem'}
              id="visit_cancellation_notes"
              placeholder={'Cancellation Notes'}
              value={eventValues.visit_cancellation_notes?eventValues.visit_cancellation_notes:''}
              onChange={e => handleTextArea(e)}
            />
            <div className="d-flex">
              <FlexGrid3Item className="pe-0 pe-md-1 mt-2 pb-2 pb-md-0">
                <DateSelector
                  isRequired
                  labelTop={'Date of cancellation'}
                  value={eventValues.visit_cancelled_datetime}
                  {...useInput(`visit_cancelled_datetime`, 'isRequired')}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-0 pe-md-1 mt-2 pb-2 pb-md-0">
                <TimeSelector
                  isRequired
                  labelTop={'Time of cancellation'}
                  timeFormat={'HH:mm'}
                  dateFormat={'HH:mm'}
                  value={eventValues.time_of_cancel}
                  {...useInput(`time_of_cancel`, 'isRequired')}
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pe-0 pe-md-1 mt-2 pb-2 pb-md-0">
                <DropdownSearchable
                  required
                  placeHolder={'Employee name'}
                  options={employees ? employees : []}
                  selectedOption={eventValues.employee}
                  onChange={value =>
                    dropDownHandleSelects({
                      employee: value ? value : null,
                    })
                  }
                />
              </FlexGrid3Item>
            </div>
            <div className="d-flex">
              <div className="d-flex pe-3 pt-0">
                <label
                  style={{
                    fontSize: '0.875rem',
                    marginRight: '7px',
                    alignSelf: 'center',
                  }}>
                  Is fixed cancel amount
                </label>
                <ToggleButton
                  label=" "
                  disabled={false}
                  useInput={useInput(`is_fixed_cancel_amount`)}
                  id="test"
                  // onClick={handelOverNight}
                />
              </div>
              <FlexGrid3Item className="pe-0 pe-md-1 mt-2 pb-2 pb-md-0">
                <EditableDropdown
                  placeHolder={
                    values.is_fixed_cancel_amount
                      ? 'Client to be Billed ( % )'
                      : 'Client to be Billed'
                  }
                  options={
                    values.is_fixed_cancel_amount
                      ? [
                          {id: '100', name: '100%'},
                          {id: '75', name: '75%'},
                          {id: '50', name: '50%'},
                          {id: '25', name: '25%'},
                          {id: '0', name: '0%'},
                        ]
                      : [
                          {id: '100', name: '100'},
                          {id: '75', name: '75'},
                          {id: '50', name: '50'},
                          {id: '25', name: '25'},
                          {id: '0', name: '0'},
                        ]
                  }
                  data={eventValues.client_to_be_billed}
                  onChnageData={handleChangeClient}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-0 pe-md-1 mt-2 pb-2 pb-md-0">
                <EditableDropdown
                  placeHolder={
                    values.is_fixed_cancel_amount
                      ? 'staff to be billed ( % )'
                      : 'staff to billed'
                  }
                  options={
                    values.is_fixed_cancel_amount
                      ? [
                          {id: '100', name: '100%'},
                          {id: '75', name: '75%'},
                          {id: '50', name: '50%'},
                          {id: '25', name: '25%'},
                          {id: '0', name: '0%'},
                        ]
                      : [
                          {id: '100', name: '100'},
                          {id: '75', name: '75'},
                          {id: '50', name: '50'},
                          {id: '25', name: '25'},
                          {id: '0', name: '0'},
                        ]
                  }
                  data={eventValues.staff_to_be_billed}
                  onChnageData={handleChangeStaff}
                />
              </FlexGrid3Item>
            </div>
          </div>
          <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ms-0 ms-md-3'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'me-2'}>Save</span>
                  {!loadingSave ? (
                    saveButtonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default CancelVisitModals;
