import React, { useEffect, useState, Fragment, useContext } from 'react';
import {
  CLIENT_SERVICE_CONTRACT,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from '../../../../utils/constants/permissions';
import { getRegularShift, deleteRegularShift } from '../../../../utils/api/RotaShift'
import { getCategoryPermission, getPermissions, getSubSectionPermissions, getStaff, getClient, getEmployee } from '../../../../utils/localStorage/user';
import { getAdmin, getSuperAdmin } from '../../../../utils/localStorage/token';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFolderPlus } from 'react-icons/bs';
import { TiArrowBackOutline } from 'react-icons/ti';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from './headerColumnsList';
import { LARGE_COL } from 'shared/styles/constants/columns';
import { getPageToSearchParam } from 'shared/methods';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { RiDeleteBinLine } from 'react-icons/ri';
import Pagination from 'components/SharedComponents/Pagination';
import { useHistory, useLocation } from 'react-router';

import {

  Toggle,
  ToggleLabel,
  ToggleWrapper,
} from 'shared/styles/buttons';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';
import {
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import {
  Row,
  Col,
  IconWrapper,
  DayPill,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import moment from 'moment';
import Dropdown from 'components/SharedComponents/Dropdown';
import ModalRegularShifts from "./ModalRegularShifts"
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { FLOPPY } from 'utils/constants/icons';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import {
  InvalidOrEmptyValues,
  DetailsNotCreated,
  SAVE,
} from 'utils/constants/messages';
import { useModal } from 'hooks/ModalHook';
import { AppContext } from "context"
import { NoPermissionContainer } from '../AddModal/styles';
import { getShiftPatterns } from 'utils/api/SchedulerApi';
import { regular_shift_emp_choices } from 'utils/choiceConstant';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';

const NEW_REGULAR_SHIFT = 'new';

const RegularShifts = ({
  setShowForm,
  showForm,
  employeeId,
  selectedId,
  empId,
  values,
  setValues,
  useInput,
  choices,
  updateErrors,
  setLoadingModal,
  timeError,
  errorMessage,
  handleSubmit,
  textStatusMain,
  shiftModal,
  setShiftModal,
  showError,
  loadingSave,
  buttonIconMain,
  setTimeError,
  setCheckRequires,
  createPermissionRegularShifts,
  updatePermissionRegularShifts,
  readPermissionRegularShifts,
  deletePermissionRegularShifts,
  setLoadingSave,
  setLoadingFinish, isValid, setErrorMessage, errors, setFormErrors, errorResponse,  setErrorResponse
}) => {



  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const [regularShifts, setRegularShifts] = useState([]);
  const [regularShiftstLength, setRegularShiftstLength] = useState();
  const [modalLoading, setModalLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [simplePagination] = useState(true);
  const [pages, setPages] = useState(0);
  const [idSelected, setIdSelected] = useState('');
  const [editToggle, setEditToggle] = useState(false)
  const [editMode, setEditMode] = useState(true)
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const { contextPermissions, contextSubSectionPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff, handleGetChoices } = useContext(AppContext);
  const [shiftPatterns, setShiftPatterns] = useState([]);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );

  const toast = useRef()
  useEffect(()=>{
    handleGetChoices(regular_shift_emp_choices)
  },[])
  const userClient = getClient();
  const userEmployee = getEmployee();
  const userStaff = contextIsStaff;
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const history = useHistory()

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const handleRowClick = id => {
    setEditToggle(true)
    setEditMode(false)
    if (id) {
      values.regularShifts.core_id = id;
      setValues({ ...values });
    }
    setShowForm(true);
    setIdSelected(id);
    setShiftModal(true);
    loadShiftPatterns()
  };

  const handleModal = event => {
    setShiftModal(!shiftModal);
    setEditToggle(false)
    setEditMode(true)
    setIdSelected('');
    if (event == 'Back') {
      getClientContracts()
    }

    if (event === NEW_REGULAR_SHIFT) {

      setButtonIcon(FLOPPY);
      loadShiftPatterns()
      setEditMode(true)
      setTextStatus(SAVE);
      setCheckRequires(false)
      setShowForm(true);
    } else {
      setShowForm(false);
      setEditMode(false)
    }
  };

  const thandleDeleteRegularShift = async () => {
    let is_deleted;
    if (idSelected) {
      is_deleted = true;
    }
    await deleteRegularShift(idSelected, is_deleted);

    setModalDeleteValue(false);
    getClientContracts();
    setIdSelected('');


  };


  const toDeleteShift = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const loadShiftPatterns = () => {
    getShiftPatterns({...values},800).then(
      response => {
        if(response.results && response.results.length >0){
          let pattern = response.results.map((item)=>{
            return {id: item.id, name: item.name}
          })
          setShiftPatterns(pattern || []);
        }
      }
    );
  };

  const getClientContracts = async () => {
    const pageToSearch = getPageToSearchParam(
      '',
      setCurrentPage,
      simplePagination,
      currentPage,
    );
    let tempId = selectedId ? selectedId : empId
    const response = await getRegularShift({ tempId, ...pageToSearch }, PAGE_SIZE_RENDER);
    setRegularShifts(response.results || []);
    setRegularShiftstLength(response.results.length);
    setPages(Math.ceil(response.count / PAGE_SIZE_RENDER));
    setModalLoading(false)
  };

  // added new lines below 
  useEffect(() => {
    setShowForm(false)
    setShiftModal(false)
  }, []);
  // ends of new lines above 


  useEffect(() => {
    if (selectedId) {
      getClientContracts();
    }
    if (empId) {
      setModalLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, empId, currentPage])

  useEffect(() => {


    if (!values.regularShifts) {
      values.regularShifts = {}
      values.regularShifts.shifts = [{
        repeat_on: [
          { day: 'MON', dayValue: 1, selected: false },
          { day: 'TUE', dayValue: 2, selected: false },
          { day: 'WED', dayValue: 3, selected: false },
          { day: 'THU', dayValue: 4, selected: false },
          { day: 'FRI', dayValue: 5, selected: false },
          { day: 'SAT', dayValue: 6, selected: false },
          { day: 'SUN', dayValue: 7, selected: false },
        ]
      }]
      setValues({ ...values })
    }
  }, [
    // employeeId ,  values.regularShifts 
  ])
  useEffect(() => {
    if (contextIsAdmin || contextIsSuperAdmin) {
      setCreatePermissions(true);
      setDeletePermissions(true);
      setUpdatePermissions(true);
      setReadPermissions(true);
    }
    else {

      const rolesPermissions = contextPermissions;

      let rolePermissions = {};
      if (rolesPermissions.length > 0) {
        rolesPermissions.forEach(item => {

          // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];


        }
          // }
        );
      }
      const roles = contextSubSectionPermissions;
      if (roles.length > 0) {
        roles.forEach(item => {
          // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
          // }
        );
      }

      if (rolePermissions[CLIENT_SERVICE_CONTRACT] !== undefined) {
        setCreatePermissions(
          rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ]
        );
        setDeletePermissions(
          rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ]
        );
        setUpdatePermissions(
          rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ]
        );

        setReadPermissions(
          rolePermissions[CLIENT_SERVICE_CONTRACT][WEB_PERMISSION][
          WEB_READ_PERMISSION
          ]
        );
      }
    }
  }, [contextIsAdmin, contextIsSuperAdmin]);

  // start of codes  for dropdown  START TIME - END TIME
  const getTimesVisit = visits => {
    // const times =  visits && visits.map(visit => {
    //   return (
    //     moment(visit.start_time, 'HH:mm').format('hh:mm A') +
    //     ' - ' +
    //     moment(visit.end_time, 'HH:mm').format('hh:mm A')
    //   );
    // });
    // return times;
    let times = []
    times = visits && visits.map(visit => {
      // return (
      //   moment(visit.start_time, 'HH:mm').format('HH:mm') +
      //   ' - ' +
      //   moment(visit.end_time, 'HH:mm').format('HH:mm')
      // );
      return (
        visit.start_time +
        ' - ' +
        visit.end_time
      );
    });
    return times;

  };

  const getTimeVisitPlaceHolder = visits => {
    let placeHolder;
    // if (visits && visits.length) {
    //   placeHolder =
    //     moment(visits[0].start_time, 'HH:mm').format('hh:mm A') +
    //     ' - ' +
    //     moment(visits[0].end_time, 'HH:mm').format('hh:mm A');
    // }
    if (visits && visits.length) {
      placeHolder =
        moment(visits[0].start_time, 'HH:mm').format('HH:mm') +
        ' - ' +
        moment(visits[0].end_time, 'HH:mm').format('HH:mm');
    }
    return placeHolder;
  };
  // end of codes  for dropdown  START TIME - END TIME
  const addNewShift = () => {
    values.regularShifts.shifts = [...values.regularShifts.shifts, {
      repeat_on: [
        { day: 'MON', dayValue: 1, selected: false },
        { day: 'TUE', dayValue: 2, selected: false },
        { day: 'WED', dayValue: 3, selected: false },
        { day: 'THU', dayValue: 4, selected: false },
        { day: 'FRI', dayValue: 5, selected: false },
        { day: 'SAT', dayValue: 6, selected: false },
        { day: 'SUN', dayValue: 7, selected: false },
      ]
    }]
    setValues({ ...values })

  }

  const isDaySelected = (name, daysSelected) => {
    let result = daysSelected.indexOf(name);

    return result > -1;
  };

  const getDays = () => {
    return [
      { name: 'MON' },
      { name: 'TUE' },
      { name: 'WED' },
      { name: 'THU' },
      { name: 'FRI' },
      { name: 'SAT' },
      { name: 'SUN' },
    ];
  };

  const getDaysSelected = visits => {
    // get the string days and make them arrays
    let days = visits && visits.map(visit => {
      return visit.repeat_on.split(',');
    });
    // flatten array of arrays
    let days_merged = [].concat.apply([], days);

    // remove duplicates
    let uniqueArray = days_merged.filter(function (item, pos) {
      return days_merged.indexOf(item) === pos;
    });

    return uniqueArray;
  };



  const deleteShift = (shiftIndex) => {
    if (values.regularShifts.shifts.length > 0) {
      values.regularShifts.shifts.splice(shiftIndex, 1)
      timeError[shiftIndex] = false
      setTimeError(timeError)
      updateErrors(shiftIndex)
      setValues({ ...values })
    }

    // setModalDeleteValue(false);
  }

const getBranchName = (id) => {
 let match =  choices?.branch && choices?.branch?.find(el => el?.id === id)
 return match ? match?.name : ""
}

  return (
    <ComponentDynamic
      loading={modalLoading}
    >
      <Toast ref={toast} position="top-right" />
      {(selectedId ? readPermissionRegularShifts : createPermissionRegularShifts) ?
        <div>
          {!showForm && !shiftModal ? (

            <div className={'w-100 d-flex justify-content-center mb-3 mt-1'}>
              <div className={'text-primary font-weight-bold'}>
                {createPermissionRegularShifts && (
                  <div style={{ marginTop: "20px" }}>
                    <PrimaryButtonForm
                      minWidth="6rem"
                      onClick={() => handleModal(NEW_REGULAR_SHIFT)}
                    >
                      <BsFolderPlus size={18} />
                      <span className={'ms-2'}>ADD REGULAR SHIFT</span>
                    </PrimaryButtonForm>
                  </div>
                )}
              </div>

            </div>

          ) : (
            <Fragment />
          )}
          {shiftModal ? (
            <div className={'w-100 d-flex justify-content-center mt-4'} >
              <div style={{ merginTop: "20px" }}>
                <PrimaryButtonForm
                  bgcolor={'PURPLE_GRAY_500'}
                  minWidth="6rem"
                  onClick={() => handleModal('Back')}
                >
                  <div className={'d-flex align-items-center'}>
                    <TiArrowBackOutline size={24} />
                    <div>BACK</div>
                  </div>
                </PrimaryButtonForm>
              </div>
            </div>
          ) : (
            <Fragment />
          )}
          {editToggle ?
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>

              <ToggleDiv>
                <ToggleWrapper>
                  <Toggle
                    id="checkbox"
                    type="checkbox"
                    onClick={() => {
                      editMode ? setEditMode(false) : setEditMode(true);
                    }}
                  />
                  <ToggleLabel htmlFor="checkbox" />
                </ToggleWrapper>
                <div className="toggle-label ms-2  mb-3 pb-3 me-2">
                  <p>Edit Mode</p>
                </div>
              </ToggleDiv>

            </div> :
            <Fragment />}
          {!shiftModal ? (
            <>
              {
                regularShifts.length > 0 ? (
                  <Table
                    marginTop={'20px'}
                    headerColumns={headerColumns}
                    headerPadding={false}
                    noEditOption
                    fontSize="0.75rem"
                    callBackQuerySort={setQuerySort}>
                    <div>
                      {regularShifts.map((shift, index) => {
                        const endDate = shift && shift.end_date
                          ? moment(shift.end_date).format('DD-MM-YYYY')
                          : 'Unset';

                        const startDate = shift && shift.start_date
                          ? moment(shift.start_date).format('DD-MM-YYYY')
                          : 'Unset';
                        const daysSelected = getDaysSelected(shift.visits);
                        return (
                          <div key={index}>
                            <Row bgColor Shadow
                              zIndex={regularShiftstLength - index}
                            >
                              <ColsGrouper
                                className="d-flex"
                              //  onClick={() => {
                              //   handleRowClick(shift.id);
                              // }}
                              >
                                <Col
                                  onClick={() => {
                                    handleRowClick(shift.id);
                                  }}
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center>
                                  {shift && shift.name}
                                </Col>
                                
                                <Col
                                  onClick={() => {
                                    handleRowClick(shift.id);
                                  }}
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center>
                                  {shift.branch && getBranchName(shift.branch)}
                                </Col>
                                <Col
                                  onClick={() => {
                                    handleRowClick(shift.id);
                                  }}
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center>
                                  {startDate}
                                </Col>

                                <Col
                                  onClick={() => {
                                    handleRowClick(shift.id);
                                  }}
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center >
                                  {endDate}
                                </Col>
                                <Col
                                  onClick={() => {
                                    handleRowClick(shift.id);
                                  }}
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center
                                >
                                  {shift && shift.every}
                                </Col>
                                <Col
                                  onClick={() => {
                                    handleRowClick(shift.id);
                                  }}
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  Center
                                  overlap={true}
                                >
                                  {shift && shift.repeat}
                                </Col>
                                <Col
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center
                                >
                                  <Dropdown
                                    noPadding
                                    disableSelection
                                    alignmentRight
                                    justifyContent={'center'}
                                    items={getTimesVisit(shift.details_list)}
                                    onSelect={() => { }}
                                    placeHolder={
                                      getTimeVisitPlaceHolder(shift.details_list) ||
                                      'Unset'
                                    }
                                    appendItem=""
                                  />
                                </Col>
                                {/* </ColsGrouper> */}
                                {/* <Col
                          onClick={() => {
                            handleRowClick(shift.id);
                          }}
                          max_width={LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          Center
                          className="visits-custom-row sm-hidden">
                          {getDays().map(day => {
                            return (
                              <DayPill
                                key={`day_pill_` + day.name}
                                Active={isDaySelected(day.name, daysSelected)}>
                                <label>{day.name}</label>
                              </DayPill>

                              
                            );
                          })}
                        </Col> */}
                              </ColsGrouper>
                              {deletePermissionRegularShifts && <Col className="sm-hidden" Center Shrink NoFlexGrow>
                                <IconWrapper
                                  onClick={() => {
                                    toDeleteShift(shift.id);
                                  }}>
                                  <RiDeleteBinLine />
                                </IconWrapper>
                              </Col>}
                              {/* </ColsGrouper> */}
                            </Row>
                            <div className="mb-1" />
                          </div>
                        );
                      })}
                      <div className="mt-3">
                        <Pagination
                          simplePagination={simplePagination}
                          setCurrentPage={setCurrentPage}
                          totalPages={pages}
                          currentPage={currentPage}
                          dataLength={regularShifts.length}
                          dropdownEntriesLimit={false}
                        />
                      </div>
                    </div>


                  </Table>
                ):(
                  <div className='d-flex justify-content-center align-items-center font-weight-bold'
                    style={{
                      height: '30vh',
                    }}
                  >
                    No data found
                  </div>
                )
              }
            </>
          ) : (
            <>
              <Fragment />
              {!shiftModal &&
                <div
                  className={'d-flex justify-content-center justify-content-md-end'}>
                  <div>
                    <PrimaryButtonForm
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      className={'me-2'}
                      onClick={() => {
                        history.push('/team')
                      }}>
                      <span className={'me-2'}>{'Cancel'}</span>
                    </PrimaryButtonForm>
                  </div>
                </div>
              }

            </>
          )}

          {!shiftModal && regularShifts.length >= 1 ? null : (
            showForm ? <ModalRegularShifts
              editMode={editMode}
              toast={toast}
              setEditMode={setEditMode}
              setEditToggle={setEditToggle}
              setErrorMessage={setErrorMessage}
              errors={errors}
              setFormErrors={setFormErrors}
              isValid={isValid}
              setCheckRequires={setCheckRequires}
              setLoadingSave={setLoadingSave}
              setLoadingFinish={setLoadingFinish}
              values={values}
              setValues={setValues}
              useInput={useInput}
              errorMessage={errorMessage}
              handleSubmit={handleSubmit}
              errorResponse={errorResponse}
              setErrorResponse={setErrorResponse}
              textStatusMain={textStatusMain}
              showError={showError}
              loadingSave={loadingSave}
              buttonIconMain={buttonIconMain}
              choices={choices}
              addNewShift={addNewShift}
              deleteShift={deleteShift}
              nameSection={'regularShifts'}
              idSelected={idSelected}
              setIdSelected={setIdSelected}
              getClientContracts={getClientContracts}
              shiftModal={shiftModal}
              timeError={timeError}
              setTimeError={setTimeError}
              selectedIdEmp={selectedId}
              createPermissionRegularShifts={createPermissionRegularShifts}
              updatePermissionRegularShifts={updatePermissionRegularShifts}
              deletePermissionRegularShifts={deletePermissionRegularShifts}
              shiftPatterns={shiftPatterns}
            /> : null
          )}
          <div className='mod_dec'>
            <ModalDecision
              type="delete"
              title="Warning"
              width={"50%"}
              max_width={'fit-Content'}
              body={
                'Are you sure you want to delete this Regular shift?'
              }
              subBody={
                'TIP (Recommended) - you could change the regular shift instead of deleting it.'
              }
              onOk={thandleDeleteRegularShift}
              onCancel={() => {
                setIdSelected('');
              }}
              okTitle={'DELETE'}
              {...bindModalDelete}
            />
          </div>


        </div>
        :
        <NoPermissionContainer>
          You don't have permission to {selectedId ? 'read' : 'create'} the information.
        </NoPermissionContainer>
      }
    </ComponentDynamic>
  );
}

export default RegularShifts;
