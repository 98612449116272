import React, {
    useState,
    useEffect,
    useContext,
    useModal,
    useRef,
    Fragment,
  } from 'react';
  import InputTextArea from 'components/SharedComponents/InputTextArea';
  import {setEvvTravel,SetEvvDistance, setEvvVerification} from 'utils/api/EmployeeApi';
  import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
  
  import {PrimaryButtonForm} from 'shared/styles/buttons';
  import {useForm} from 'hooks/FormHook';
  import {Toast} from 'primereact/toast';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {AppContext} from 'context';
  
  import {
    FlexGrid3Container,
    FlexGrid3Item,
    ErrorMessage,
    ModalContainer,
  } from 'shared/styles/constants/tagsStyles';
  import { getAllVisitEsvslist } from 'utils/api/SchedulerApi';
import EvvTravel from '.';
  
  const EvvBulkTravelModal = ({
    fullBind,
    bulkId,
    setTitle,
    evvDistance,
    setModalValue,
    EvvTravel,loadEvvs,loadData,
    clearSelectedElId
  }) => {
    const [errorMessage, setErrorMessage] = useState('');
 

    const [loadingModal, setLoadingModal] = useState(false);
    const toast = useRef();
  
    const [verificationStatusList ,setVerificationLisi]=useState([])
const [optionList ,setOptionList]=useState([{id:'Confirmed',name:'Confirmed'},{id:'Not Confirmed',name:'Not Confirmed'}])
   
    const {values, setValues, useInput, isValid, errors, setCheckRequires} =
      useForm({});
  useEffect(()=>{
  if(evvDistance){
    setTitle('Distance Confirmation')

  }
  else {
    setTitle('Travel Confimation ')

}
  },[])
  const distance =useInput('clocked_distance_confirmation',evvDistance?'isRequired':'')
  const travel = useInput('clocked_travel_confirmation', EvvTravel?'isRequired':'')
    const handleDropDownSelect = item => {
      setValues({...values, ...item});
    };
    const handleSubmit = async () => {
      if (!isValid && errors.length > 0) {
        setErrorMessage(
          'Some of the fields have invalid or empty values. Please review'
        );
        setCheckRequires(true);
        return;
      }
      else{
        console.log(values,"iiiiiiii")

      let body = {

        // id: bulkId,
        // confirmation: values.clocked_distance_confirmation||values.clocked_travel_confirmation==='Confirmed'?true:false
      };

      if(evvDistance){
        body={
        id: bulkId,
        confirmation: values.clocked_distance_confirmation==='Confirmed'?true:false
        }
      }
      else{
        body={
          id: bulkId,
          confirmation: values.clocked_travel_confirmation==='Confirmed'?true:false
          }


      }
let response =  evvDistance? await SetEvvDistance(body): await setEvvTravel(body)

      if (response.status === true) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${response.message}`,
        });
        clearSelectedElId();
        loadData()
        setTimeout(() => {
            setModalValue(false);
        }, 1000);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${response.message}`,
        });
      }
    }
    };
    return (
      <ModalContainer max_width={'40%'}>
        <ModalDynamic {...fullBind()} minWidthTitle="8.5rem" loading={loadingModal}>
          <Toast ref={toast} position="top-right" />
          <div className="d-flex flex-column mt-2">
            <div className="d-flex mb-2">
              <div
                className="w-100 me-2"
                style={{marginLeft: '10px', marginRight: '10px'}}>
                    {evvDistance?(
                <DropdownSearchable
                  required

{...distance}

                  placeHolder={evvDistance ?"Distance Confirmation":'Travel Confirmation'}
                  options={optionList}
                  selectedOption={
                    values.clocked_distance_confirmation ? values.clocked_distance_confirmation : null
                  }
                  onChange={value =>
                    handleDropDownSelect(
                      {
                        clocked_distance_confirmation: value ? value : null,
                      },
                      'clocked_distance_confirmation',
  
                      value
                    )
                  }
                />
                    ):(

                        <DropdownSearchable
                        required
      {...travel}
      
                        placeHolder="Travel Confirmation"
                        options={optionList}
                        selectedOption={
                          values.clocked_travel_confirmation ? values.clocked_travel_confirmation : null
                        }
                        onChange={value =>
                          handleDropDownSelect(
                            {
                              clocked_travel_confirmation: value ? value : null,
                            },
                            'clocked_travel_confirmation',
        
                            value
                          )
                        }
                      />

                    )}
                        
               
                 
              </div>
            
            </div>
          </div>
  
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div
              className={'mb-3'}
              style={{display: 'flex', justifyContent: 'right'}}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={'d-flex justify-content-center justify-content-md-end'}>
              <div className={'ms-0 ms-md-3 me-2'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  style={{marginBottom: '5px'}}
                  onClick={handleSubmit}>
                  Save
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </ModalDynamic>
      </ModalContainer>
    );
  };
  
  export default EvvBulkTravelModal;
  