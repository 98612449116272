import styled from 'styled-components';
import {PURPLE, BLACK_900, WHITE, PURPLE_800} from 'shared/styles/constants/colors';

export const StyledCheck = styled.div`
  padding-left: ${props => props.paddingLeft ? props.paddingLeft : "0"};

  .form-check-input {
    width: 20px;
    border-radius: 50% !important;
    height: 20px;
    accent-color: ${PURPLE_800} !important;
    }

  .form-check-label {
    font-size: 0.875rem;
    line-height: 2;
    padding-left: 0.4rem;
  }
  .form-check-label::after,
  .form-check-label::before {
    height: 20px;
    width: 20px;
  }
  .form-check-label::before {
    background-color: ${WHITE};
    border: 1px solid ${BLACK_900};
    border-radius: 50%;
  }

  /* When I remove background the icon goes back to that default size */
  .form-check-input:checked ~ .form-check-label::before {
    background-color: ${PURPLE} !important;
    accent-color: ${PURPLE} !important;
  }
`;
