import React, { useState, useEffect, Fragment, useRef } from 'react';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import './invoice.css'
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { DOWNLOAD } from 'utils/constants/icons';
import { PrimaryButtonForm } from 'shared/styles/buttons';
const InvoicePdfPage = ({
  values,
  invoiceDetailData,
  funderList,
  invoiceDetailTaxAmt,
    invoiceDetailDataTaxPer,
    invoiceDetailDataDiscountAmt,
    invoiceDetailDataDiscountPer,
    taxInput,
    sub_total_count,
    client_list,
    fullBind,
    handleDownlaodPdf,
    loadingSave
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);


  return (
    <>
     <ModalContainer max_width={'80%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'80%'}
        loading={false}>
      <div id='mypdfinvoice'>
      <div className='invoice p-2 mt-2'>
        <table className="invoice-info-container">
          <tr>
            <td className="client-name">
              {values.client ? client_list.find((i)=>i.id == values.client)?.name :  funderList.find((i)=>i.id == values.funder)?.name}
            </td>
            <td className='d-flex justify-content-end'>
              Invoice Date:
              <strong>
                <div style={{marginLeft: '20px' }}>
                  {values.issue_date}
                </div>
              </strong>
            </td>

          </tr>

          <tr>
            <td>
              <FlexGrid3Item className="pe-1 mt-2 mb-2" ResizeWidth={'60%'}>
                {values.invoice_address}
              </FlexGrid3Item>
            </td>
            <td className='d-flex justify-content-end'>
              Due Date :
              <strong>
                <div style={{marginLeft: '20px' }}>
                  {values.due_date}
                </div>
              </strong>
            </td>
          </tr>
        </table>

        <table className="line-items-container">
          <thead>
            <tr className='text-600 text-white bgc-default-tp1 py-25'>
              <th className="heading-subtotal">Type</th>
              <th className="heading-subtotal">Visit Type Name</th>
              <th className="heading-subtotal">Service Category</th>
              <th className="heading-subtotal">Start Date</th>
              <th className="heading-subtotal">End Date</th>
              <th className="heading-subtotal">Start Time</th>
              <th className="heading-subtotal">End Time</th>
              {taxInput && <th className="heading-subtotal">Tax(%)</th>}
              <th className="heading-subtotal" style={{ paddingRight: '10px' }}>Amount</th>
            </tr>
          </thead>


          {(invoiceDetailData && (invoiceDetailData.length > 0)) &&
            <tbody>
              {invoiceDetailData.map((item, index) => {
                return (
                  <tr className='text-95 text-secondary-d3'>
                    <td>{item.type}</td>
                    <td>{item.visit_type__name}</td>
                    <td>{item.service_category__name}</td>
                    <td>{item.schedule_start_date}</td>
                    <td>{item.schedule_end_date}</td>
                    <td>{item.schedule_start_time}</td>
                    <td>{item.schedule_end_time}</td>
                    {taxInput && <td style={{ textAlign: 'left' }}>
                      {item.tax_invoice}
                    </td>}
                    <td className="bold" style={{ paddingRight: '10px'}}>
                      {item.amount || ''}
                    </td>

                  </tr>
                )
              })}
            </tbody>
          }


        </table>
        {!(invoiceDetailData && (invoiceDetailData.length > 0)) &&
          <div style={{ textAlign: 'center', padding: '3rem' }}>
            No data found
          </div>
        }
        <div class="mt-4">
          <div class="row border-b-2 brc-default-l2"></div>
          <div class="row mt-3">
            <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
            </div>
            <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
              <div class="row">
                <div class="col-7 text-end">
                  SubTotal
                </div>
                <div class="col-5 text-end">
                  <span class="text-120 text-secondary-d1">
                    {sub_total_count}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-7 text-end">
                  Tax(%)
                </div>
                <div class="col-5 text-end">
                  <span class="text-120 text-secondary-d1">
                    {invoiceDetailDataTaxPer}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-7 text-end">
                  Tax Amount
                </div>
                <div class="col-5 text-end">
                  <span class="text-120 text-secondary-d1">
                    {invoiceDetailTaxAmt}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-7 text-end">
                  Discount(%)
                </div>
                <div class="col-5 text-end">
                  <span class="text-120 text-secondary-d1">
                    {invoiceDetailDataDiscountPer}
                  </span>
                </div>
              </div>

              <div class="row">
                <div class="col-7 text-end">
                  Discount Amount
                </div>
                <div class="col-5 text-end">
                  <span class="text-110 text-secondary-d1">
                    {invoiceDetailDataDiscountAmt}
                  </span>
                </div>
              </div>

              <div class="row bgc-primary-l3">
                <div class="col-7 text-end">
                  Total Amount
                </div>
                <div class="col-5 text-end">
                  <span class="text-150 text-success-d3 opacity-2">
                    {sub_total_count + invoiceDetailTaxAmt - invoiceDetailDataDiscountAmt}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <hr />
        </div>
      

      </div>
      </div>
      <div className={'ms-0 ms-md-3 me-2 text-end mb-2'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={() => handleDownlaodPdf()}
                  disabled={false}>
                  <span className={'me-2'}>Export</span>
                  {!loadingSave ? (
                    DOWNLOAD
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
      </ModalDynamic>
    </ModalContainer>
    </>
  );
};

export default InvoicePdfPage;
