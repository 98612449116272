import TimeSelector from 'components/SharedComponents/TimeSelector';
import { useForm } from 'hooks/FormHook';
import React, {useState, useEffect, Fragment, useContext, useRef} from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { editSettingEvv, getSettingEvv } from 'utils/api/SettingsApi';
import {Toast} from 'primereact/toast';
import moment from 'moment';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import { ErrorMessage } from 'shared/styles/constants/tagsStyles';

const MobileAuthTime = ({}) => {
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const {values, setValues, useInput, isValid, errors, setCheckRequires} =
  useForm({});
  const toast = useRef();

  useEffect(() => {
    loadSettingEvv();
  }, []);

  const loadSettingEvv = async () => {
    await getSettingEvv().then(response => {
      if (response) {
        const data = response.authenticationTime &&  response.authenticationTime[0]
      
      setValues({...data});
      }
    });
  };

  const handleSubmit = async evt => {
    evt.preventDefault();
    if (loadingSave) {
      return;
    }
    const body = {
      authenticationTime:{
        id: values.id,
        mobileAuthenticationTime: values.mobileAuthenticationTime
      }
    }
    setLoadingSave(true);
      await editSettingEvv(null, body)
        .then(res => {
          if (res.status === true) {
            onSuccess();
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: `${res.message}`,
            });
          } else {
            onError();
            toast.current.show({
              severity: 'error',
              summary: 'Failed',
              detail: `${res.message}`,
            });
          }
        })
        .catch(onError);
  };

  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
  };

  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    // setShowError(true);
    setCheckRequires(true);
    setErrorMessage(
      'Some of the fields have invalid or empty values. Please review.'
    );
  };

  return (
    <>
      <div>
      <Toast ref={toast} position="top-right" />
        <TimeSelector
          labelTop={'Mobile Authentication Time'}
          timeFormat={'HH:mm'}
          dateFormat={'HH:mm'}
          {...useInput('mobileAuthenticationTime')}
          placeholderText={'hh:mm'}
          locale={'sv-sv'}
          timeCaption={'Duration'}
        />
           <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div className={'mb-3'}>
            {errorMessage ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>

          <div
            className={'d-flex justify-content-center justify-content-md-end'}>
            <div className={'ms-0 ms-md-3 mb-2 pb-1 mt-1 pt-1 me-2'}>
              <PrimaryButtonForm minWidth="6rem" o onClick={handleSubmit}>
                <span className={'me-2 mb-2 pb-1 pt-1'}>{textStatus}</span>
                {!loadingSave ? (
                  buttonIcon
                ) : (
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  />
                )}
              </PrimaryButtonForm>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileAuthTime;
