import React, { useState, useEffect, Fragment, useRef } from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useForm } from 'hooks/FormHook';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useHistory, useLocation } from 'react-router';
import {
    setClientUploadFile,
    getUploadedFiles,
    deleteUploadedFile,
    getUploadedFilesDownload,
    fileStoreInOffline
} from 'utils/api/ClientApi';
import { DOWNLOAD } from 'utils/constants/icons';
import { TitleSection, ErrorMessage, FlexGrid3Container, FlexGrid3Item } from 'shared/styles/constants/tagsStyles';
import {
    InvalidOrEmptyValues,
    ERROR,
    SAVE,
    SAVED,
} from 'utils/constants/messages';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { pickFile } from 'utils/files';
import _ from 'lodash';
import Table from 'components/SharedComponents/Table';
import { LARGE_COL } from 'shared/styles/constants/columns';
import {
    Row,
    Col,
    IconWrapper,
    DayPill,
    ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { RiDeleteBinLine } from 'react-icons/ri';
import moment from 'moment';
import { useModal } from 'hooks/ModalHook';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import Pagination from 'components/SharedComponents/Pagination';
import { getPageToSearchParam } from 'shared/methods';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import queryString from 'query-string';
import { Toast } from 'primereact/toast';
import ToggleButton from 'components/SharedComponents/ToggleButton';

export const headerColumns = [
    {
        label: 'FileName',
        name: 'file_name',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },

    {
        label: 'Description',
        name: 'description',
        status: 0,
        className: 'grow-mobile sm-hidden md-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'Uploaded',
        name: 'uploaded',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },
    {
        label: 'Store In Offline',
        name: 'uploaded',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },
];

const Files = ({
    // cliId,
    props,
    selectedClientId,
    readPermissionClientFile,
    createPermissionClientFile,
    updatePermissionClientFile,
    deletePermissionClientFile
}) => {
    const [fileSize, setFileSize] = useState(0);
    const [modalLoading, setModalLoading] = useState(true)
    const [textStatus, setTextStatus] = useState(SAVE);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [loadingSave, setLoadingSave] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [UploadTeamFile, setUploadTeamFile] = useState('No File Chosen');
    const [toggleUploadFile, setToggleUploadFile] = useState(false)
    const [clientUploadedFiles, setClientUploadedFiles] = useState([])
    const [idSelected, setIdSelected] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(0);
    const [simplePagination] = useState(true);
    const [clientUploadedFilesCount, setClientUploadedFilesCount] = useState([])
    const { values, setValues, useInput, setCheckRequires } = useForm({ limitperpage: PAGE_SIZE_RENDER });
    const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
        false,
    );
    const history = useHistory()
    const location = useLocation()
    const toast = useRef(null)
    const uploadFileTeam = useRef(null);

    useEffect(() => {
        if (selectedClientId) {
            loadEmpNotes()
        }
        else {
            setModalLoading(false)
        }

    }, [history.location.search, values.limitperpage,])



    const setQuerySort = querySort => {
        setValues({ ...values, ...querySort });
    };

    const handleChange = async (event, key, keyName) => {
        const fileUploaded = event.target.files[0];
        setFileSize(fileUploaded['size'])
        const fileResult = await pickFile(event)
        const result = _.merge(values, { [key]: { [keyName]: fileResult.file } });
        setValues({ ...result, ...values});
        if (key === 'upload_file') {
            setUploadTeamFile(fileUploaded.name)
            setToggleUploadFile(true)
        }
    }

    const handleUpload = ref => {
        setShowError(false);
        ref.current.click();
    };

    const loadEmpNotes = async () => {
        const pageToSearch = getPageToSearchParam(
            history.location.search,
            setCurrentPage
        );
        let id = selectedClientId;
        try {
            const response = await getUploadedFiles({ id, ...pageToSearch, ...values }, values.limitperpage)
                setClientUploadedFiles(response.results);
                setClientUploadedFilesCount(response.count);
                setPages(Math.ceil(response?.count / (values?.limitperpage || PAGE_SIZE_RENDER)));
            setModalLoading(false)
        } catch {
            setModalLoading(false)
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (loadingSave) {
            return;
        }
        const maxSize = 5 * 1024 * 1024;
        if (fileSize > maxSize) {
            setShowError(true)
            setErrorMessage('The file size is greater than 5 MB. Please upload a compressed file.')
            return;
        }

        if (values && values.upload_file && values.upload_file.upload_file_for_client) {
            setLoadingSave(true);
            setCheckRequires(false);
            setShowError(false);
        } else {
            setCheckRequires(true);
            setShowError(true);
            setErrorMessage(`Please choose a file `);
            return;
        }

        let body = {
            client: selectedClientId,
            description: values.Upload_file_description,
            file_name: values.upload_file.upload_file_for_client,
            name: UploadTeamFile,
            poor_connectivity: values.store_in_offline ? true : false
        };


        setClientUploadFile(body)
            .then(response => {
                if (response) {
                    setLoadingSave(false);
                    values.Upload_file_description = ""
                    values.upload_file = {}
                    values.store_in_offline = false
                    setValues({...values}); 
                    setUploadTeamFile('No File Chosen')
                    setToggleUploadFile(false)
                    loadEmpNotes();
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: `Success`,
                    });
                }
            })
            .catch((error) => {
                let getErrorMessage = error ? error : 'Something went wrong!'
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: `${getErrorMessage}`,
                });
                setLoadingSave(false);
            });
    };

    const toDeleteShift = id => {
        setModalDeleteValue(true);
        setIdSelected(id);
    };

    const thandleDeleteRegularShift = async () => {
        await deleteUploadedFile(idSelected);
        setModalDeleteValue(false);
        loadEmpNotes();
        setIdSelected('');


    };

    const dropDownHandleSelectPagination = (item, namesection) => {
        values.limitperpage = item[namesection]
        setValues({ ...values });
    }

    const Upload_file_description = useInput('Upload_file_description')
    const store_in_offline_hook = useInput('store_in_offline_list')
    const storeInOfflineHook = useInput('store_in_offline')

    const handleStoreInOffline = async (fileId, toggleValue) => {
        setModalLoading(true)
        values.store_in_offline_list = toggleValue
        setValues({ ...values })
        try {
            const body  = {
                poor_connectivity: toggleValue
            }
            const response = await fileStoreInOffline(fileId, body);
            if (response.status === "FAIL") throw response.message;
            if (response.message == 'Connectivity updated successfully') {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `Success`,
                });
                loadEmpNotes()
            }
            console.log(response, "response")

        } catch (error) {
            let getErrorMessage = error ? error : 'Something went wrong!'
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `${getErrorMessage}`,
            });
        }
    }

    const calculateNoOfIcons = () => {
        let count = 1;
        if (deletePermissionClientFile)
            count += 1;
        return count
    }

    const handleToggle = () => {
        values.store_in_offline = !values.store_in_offline;
        setValues({ ...values })
    }

console.log({clientUploadedFiles,
    pages,
    values,
    PAGE_SIZE_RENDER},"PAGE_SIZE_RENDER")
    return (
        <div>
            <Toast ref={toast} />
          
                <div className="d-flex">

                    {/* <TitleSection className='ms-1'>Store In Offline</TitleSection> */}
                </div>

                {createPermissionClientFile ?
                    <>
                        <TitleSection>Upload a file</TitleSection>
                        <div className="d-flex align-items-center py-3">
                            <div style={{ width: '60%' }} className='d-flex me-2'>
                                <div style={{ width: '100%' }}>
                                    <InputLabelTop
                                        type="text"
                                        id="emp_desc"
                                        label={'Full description'}
                                        {...Upload_file_description}
                                        maxlength={50}
                                    />
                                </div>
                                <div className='ps-2'>
                                    <ToggleButton
                                        label="Store In Offline"
                                        status={values?.store_in_offline}
                                        onClick={() => handleToggle()}
                                        id="store_in_offline"
                                    />
                                </div>
                            </div>
                            <div style={{ width: '40%' }}>
                                <input type="file"
                                    style={{ display: 'none' }}
                                    ref={uploadFileTeam}
                                    onChange={(e) => handleChange(e, 'upload_file', 'upload_file_for_client')}
                                />
                                <PrimaryButtonForm
                                    minWidth="2rem"
                                    onClick={() => handleUpload(uploadFileTeam)}
                                    className={'me-2'}
                                >
                                    <span className={''}>{'Choose File'}</span>

                                </PrimaryButtonForm>
                                <a href={values.upload_file && values.upload_file.upload_file_for_client} style={{
                                    pointerEvents: UploadTeamFile === 'No File Chosen' ? "none"
                                        : toggleUploadFile ? "none" : "", color: "#696969", fontStyle: "italic"
                                }}
                                    target="_blank" rel="noopener noreferrer"
                                    classname={'ms-1 mt-2'}
                                >
                                    {UploadTeamFile}
                                </a>
                            </div>
                        </div>

                        <div
                            className={
                                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2 ms-2'
                            }>
                            <div className={'mb-3'}>
                                {showError ? (
                                    <ErrorMessage>
                                        <label className={'mt-2'}>{errorMessage}</label>
                                    </ErrorMessage>
                                ) : (
                                    <Fragment />
                                )}
                            </div>
                            <div
                                className={
                                    'd-flex justify-content-center justify-content-md-end'
                                }>

                                <div className={'ms-0 me-2'}>
                                    <PrimaryButtonForm
                                        bgcolor="PURPLE_GRAY_500"
                                        minWidth="6rem"
                                        className={'me-2'}
                                        onClick={() => {
                                            history.push('/client')
                                        }}>
                                        <span className={'me-2'}>{'Cancel'}</span>
                                    </PrimaryButtonForm>
                                    {(selectedClientId ? updatePermissionClientFile : true) && (
                                        <PrimaryButtonForm
                                            bgcolor="PURPLE_GRAY_500"
                                            minWidth="6rem"
                                            onClick={handleSubmit}>
                                            <span className={'me-2'}>Upload</span>
                                            {!loadingSave ? (
                                                buttonIcon
                                            ) : (
                                                <span
                                                    className="spinner-border spinner-border-sm me-1"
                                                    role="status"
                                                />
                                            )}
                                        </PrimaryButtonForm>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <NoPermissionContainer>
                        You don't have permission to create the information.
                    </NoPermissionContainer>
                }
                {(selectedClientId ? readPermissionClientFile : createPermissionClientFile) ?
                    <>
                      <ComponentDynamic loading={modalLoading}>
                        <div className='mt-3'>
                            {(clientUploadedFiles?.length > 0) &&
                                <Table
                                    headerColumns={headerColumns}
                                    headerPadding={false}
                                    noEditOption={true}
                                    noNeed={false}
                                    fontSize="0.75rem"
                                    callBackQuerySort={setQuerySort}
                                    numberOfIcons={calculateNoOfIcons()}>
                                    <div>
                                        {clientUploadedFiles && clientUploadedFiles.map((shift, index) => {

                                            return (
                                                <div key={index}>
                                                    <Row bgColor Shadow>
                                                        <ColsGrouper
                                                            className="d-flex"
                                                        >
                                                            <Col
                                                                max_width={LARGE_COL}
                                                                Shrink
                                                                reducePadding
                                                                Capitalize
                                                                Center>
                                                                {shift.name}
                                                            </Col>
                                                            <Col
                                                                max_width={LARGE_COL}
                                                                className='sm-hidden md-hidden'
                                                                Shrink
                                                                reducePadding
                                                                Capitalize
                                                                Center>
                                                                {shift.description === 'undefined' ? '' : shift.description}
                                                            </Col>
                                                            <Col
                                                                max_width={LARGE_COL}
                                                                Shrink
                                                                reducePadding
                                                                Capitalize
                                                                Center
                                                            >
                                                                Uploaded at {moment(shift.created_at).format('hh:mm A DD-MM-YYYY')}
                                                            </Col>
                                                            <Col
                                                                max_width={LARGE_COL}
                                                                Shrink
                                                                reducePadding
                                                                Capitalize
                                                                Center
                                                            >
                                                                <div>
                                                                    <ToggleButton
                                                                        label=""
                                                                        {...store_in_offline_hook}
                                                                        status={shift?.poor_connectivity}
                                                                        id="store_in_offlinee"
                                                                        onClick={() => handleStoreInOffline(shift.id,!shift?.poor_connectivity)}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col className="" Center Width Purple>
                                                                <IconWrapper
                                                                    className=''
                                                                    onClick={() => {
                                                                        window.open(shift.file_name, '_blank');
                                                                    }}
                                                                >
                                                                    {DOWNLOAD}
                                                                </IconWrapper>
                                                            </Col>
                                                            <Col className="" Center Shrink NoFlexGrow>
                                                                {deletePermissionClientFile && <IconWrapper
                                                                    className=''
                                                                    onClick={() => {
                                                                        toDeleteShift(shift.id);
                                                                    }}
                                                                >
                                                                    <RiDeleteBinLine />
                                                                </IconWrapper>}
                                                            </Col>
                                                        </ColsGrouper>
                                                    </Row>
                                                    <div className="mb-1" />
                                                </div>
                                            );
                                        })}
                                        <div className="mt-3">
                                            <Pagination
                                                totalPages={pages}
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                values={values}
                                                dropDownHandleSelect={dropDownHandleSelectPagination}
                                                number={PAGE_SIZE_RENDER}
                                                dataLength={clientUploadedFiles.length}
                                            />
                                        </div>

                                    </div>


                                </Table>}
                            <div className='mod_dec'>
                                <ModalDecision
                                    type="delete"
                                    title="Warning"
                                    width={"50%"}
                                    max_width={'fit-Content'}
                                    body={
                                        'Are you sure you want to delete this file?'
                                    }
                                    onOk={thandleDeleteRegularShift}
                                    onCancel={() => {
                                        setIdSelected('');
                                    }}
                                    okTitle={'DELETE'}
                                    {...bindModalDelete}
                                />
                            </div>
                        </div>
                        </ComponentDynamic>
                    </>
                    :
                    <NoPermissionContainer>
                        You don't have permission to {selectedClientId ? 'read' : 'create'} the information.
                    </NoPermissionContainer>
                }
        </div>
    );
}

export default Files;
