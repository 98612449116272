import React, { useState, useEffect, Fragment, useRef } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { SOFT_RED } from 'shared/styles/constants/colors';
import { DELETE } from 'utils/constants/icons';
import { IconWrapper } from 'shared/styles/constants/tagsStyles';
import _ from 'lodash';
import { RiDeleteBinLine } from 'react-icons/ri';
const MultiFileUpload = ({
    setValues,
    values,
    MAX_COUNT,
    keyName,
}) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileLimit, setFileLimit] = useState(false);
    const [disabledValue, setDisabledValue] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [fileSizeEror, setFileSizeEror] = useState('');
    const [erorSize, setErorSize] = useState(false);
    const uploadFileTeam = useRef(null);


    useEffect(() => {
        if (values.id) {
            if (values.file_upload?.length > 0) {
                let uploadfiledata = values.file_upload.map((fileupload) => {
                    return { id: fileupload.id ? fileupload.id : '', name: fileupload.name }
                })
                setUploadedFiles(uploadfiledata);
            } else {
                setUploadedFiles([]);
            }
        }

    }, [
        values,
        values.file_upload,
        values.id,
    ])

    const handleUploadFiles = (files, keyName) => {
        const uploaded = [...uploadedFiles];
        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);

                if (uploaded.length > MAX_COUNT) {
                    setAlertMsg(<p className='text-danger'>You can only add a maximum of {MAX_COUNT} files !! </p>);
                    setFileLimit(false);
                    setDisabledValue(true);
                    limitExceeded = true;
                    return true;
                }

                if (erorSize) {
                    setDisabledValue(true);
                    setFileSizeEror(
                        <p className='text-danger'>You can only upload file size less than 5MB  </p>
                    );
                    return false;
                }
            }
        })

        if (!limitExceeded || !erorSize) {

            const result = _.merge(values, { [keyName]: uploaded });
            setValues({ ...result });
            setUploadedFiles(uploaded)
        }
    }

    const handleFileEvent = (e, keyName) => {

        if (e.target.files[0].size >= 5000000) {
            setErorSize(true);
        }

        const chosenFiles = Array.prototype.slice.call(e.target.files)
        handleUploadFiles(chosenFiles, keyName);
    }

    const handleUpload = () => {
        uploadFileTeam.current.click();
    };

    const deleteUploadedFile = (file, index) => {
        values.file_upload = [];
        let newUploadedFiles = uploadedFiles;
        newUploadedFiles.splice(index, 1);
        values.file_upload = newUploadedFiles;
        setValues({ ...values });
        setUploadedFiles(newUploadedFiles)

    }

    const openInNewTab = (url) => {

        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }


    return (
        <div >
            <span className={'pb-0 pe-2 mt-1'} htmlFor="exemption-proof"><small className="pe-1 "  >Upload Documents</small></span>
            <input id='fileUpload' type='file' multiple
                // accept='application/pdf, image/png'
                disabled={disabledValue}
                style={{ display: 'none' }}
                ref={uploadFileTeam}
                onChange={(e) => handleFileEvent(e, keyName)}
            />

            <PrimaryButtonForm
                minWidth="2rem"
                onClick={() => handleUpload()}
                className={'me-2'}
            >
                <span className={`${!fileLimit ? '' : 'disabled'} `}>{'Choose File'}</span>

            </PrimaryButtonForm>

            <div className="uploaded-files-list">
                {uploadedFiles.length > 0 && uploadedFiles?.map((file, index) => {
                    return (

                        <div key={index} >
                            <div className='d-flex'>
                                <div onClick={() => openInNewTab(file.name)} style={{ cursor: 'pointer' }}><small>{file.name}</small> </div>

                                <div style={{ color: 'red', cursor: 'pointer' }}>
                                    <RiDeleteBinLine
                                        onClick={() => deleteUploadedFile(file, index)}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
                )}
                {!fileLimit ? alertMsg : ''}
                {erorSize ? fileSizeEror : ''}
            </div>

        </div>
    )
}

export default MultiFileUpload