import { useForm } from 'hooks/FormHook';
import React, { useState, useEffect } from 'react';
import { getVisitCareNotesOnVisit } from 'utils/api/SchedulerApi';
import Table from 'components/SharedComponents/Table';
import { Row, Col, IconWrapper, ColsGrouper } from 'components/SharedComponents/Table/styles';
import { SMALL_COL, MEDIUM_COL, EX_VERY_LARGE_COL, EX_SMALL_COL, EX_SMALL_COL4, CHECK_BOX } from 'shared/styles/constants/columns';
import { AppContext } from "context"
import { useContext } from 'react';
import moment from 'moment';
import { TitleSection } from 'shared/styles/constants/tagsStyles';
import { MdEdit } from 'react-icons/md';

import { RiCheckboxCircleFill, RiCloseCircleFill, RiDeleteBinLine } from 'react-icons/ri';
import { PURPLE } from 'shared/styles/constants/colors';
import CareNotesModal from './CareNotesModal';
import { deleteVisitCareNotes, getVisitCareNotesByRowsId, postBulkReviewStatus, setVisitCareNotesDetails } from 'utils/api/ClientApi';
import Spinner from 'components/SharedComponents/Spinner';
import { FaPlus } from 'react-icons/fa';
import { visit_popup_care_notes } from 'utils/choiceConstant';
import { CHECK, CROSS, FLOPPY, READ_INFO } from 'utils/constants/icons';
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { getUserId } from 'utils/localStorage/user';
import { IconContext } from 'react-icons';
import { useModal } from 'hooks/ModalHook';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import './CareNotes.css'
import ReviewSection from 'components/DashboardComponents/Client/AddModal/VisitCareNotes/ReviewSection/ReviewSection';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { setOnlyforTriagePopupTriageLabel } from 'utils/api/SettingsApi';


const CareNotes = ({
  visitId,
  clientSelectedId,
  employeeId,
  readPermissionCareNotes,
  createPermissionCareNotes,
  updatePermissionCareNotes,
  deletePermissionCareNotes,

  readPermissionCareNotesReview,
  createPermissionCareNotesReview,
  updatePermissionCareNotesReview,
  deletePermissionCareNotesReview,

  visitData
}) => {
  const headerColumns = [
    {
      label: '',
      name: 'expand',
      status: 0,
      className: 'm-0 p-0 ps-1',
      max_width: EX_SMALL_COL4,
    },
    {
      label: 'Updated BY',
      name: 'visit__start_date',
      status: 0,
      className: 'grow-mobile',
      max_width: SMALL_COL,
    },
    {
      label: 'Time',
      name: 'visit__start_date',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: SMALL_COL,
    },
    {
      label: 'Employee Name',
      name: 'care_notes_created_by__first_name',
      status: 0,
      className: 'grow-mobile',
      max_width: SMALL_COL,
    },
    {
      label: 'category',
      name: 'visit__client_service_visit__service_category__name',
      status: 0,
      className: 'grow-mobile sm-hidden md-hidden',
      max_width: MEDIUM_COL,
    },
    {
      label: 'Visit Type',
      name: 'visit__client_service_visit__visit_type__name',
      status: 0,
      className: 'grow-mobile sm-hidden md-hidden',
      max_width: SMALL_COL,
    },

    // {
    //   label: 'Visit Care Notes',
    //   name: 'care_notes',
    //   status: 0,
    //   className: 'grow-mobile sm-hidden md-hidden',
    //   max_width: EX_VERY_LARGE_COL,
    // },
    {
      label: 'Privacy',
      name: 'Privacy',
      status: 0,
      className: 'grow-mobile sm-hidden md-hidden',
      max_width: EX_VERY_LARGE_COL,
    },
    {
      label: 'Labels',
      name: 'Labels',
      status: 0,
      className: 'grow-mobile sm-hidden md-hidden',
      max_width: EX_VERY_LARGE_COL,
    },
  ];


  const toast = useRef(null)
  const userId = getUserId()
  const { contextChoices, handleGetChoices, handleChangeChoices } = useContext(AppContext);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(false);
  console.log(visitData, "visitData")
  const { values, setValues, useInput, setCheckRequires, errors } = useForm({
    visit: visitId,
    alert_created_by: employeeId || null,
  });


  const { setValue: setModalReviewSection, value: modalReviewSection, setTitle: setTitleReviewSection, fullBind: fullBindRiviewSection, } = useModal(false);

  const [visitCareNotes, setVisitCareNotes] = useState([]);
  // const [pages, setPages] = useState(0);
  // const [currentPage, setCurrentPage] = useState(1);
  const [countCheck, setCoutCheck] = useState(0)
  const [loadingModal, setLoadingModal] = useState(true);
  const [loadingRowModal, setLoadingRowModal] = useState(false)
  const [selectedId, setSelectedId] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [editMode, setEditMode] = useState(false)
  const [idSelected, setIdSelected] = useState(null)
  const [expandFields, setExpandFields] = useState({});
  useEffect(() => {
    handleGetChoices([visit_popup_care_notes])
    loadVisitCareNotes()
  }, [])


  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };


  const loadVisitCareNotes = () => {
    getVisitCareNotesOnVisit({ ...values }, values.limitperpage, visitId)
      .then(response => {
        setVisitCareNotes(response?.data || []);
        response.data && response.data.map((note,index) => {
          expandFields[index] = { open: true };
        })
        
        setExpandFields({ ...expandFields });
        setLoadingModal(false);
      });
  };


  const displayEmployeeName = (empId) => {
    if (empId) {
      let Name = contextChoices?.user_list?.find(userdata => userdata?.id === empId)?.name;
      return Name;
    } else {
      return
    }
  }

  const displayVisitType = (visitTypeId) => {
    if (visitTypeId) {
      let Name = contextChoices?.visit_type?.find(userdata => userdata.id === visitTypeId)?.name;
      return Name;
    } else {
      return
    }
  }

  const displayCategoryName = (catgoryId) => {
    if (catgoryId) {
      let Name = contextChoices?.service_category?.find(service => service.id === catgoryId)?.name;
      return Name;
    } else {
      return
    }
  }
  const displayPrivacyName = (PrivacyId) => {
    if (PrivacyId) {
      let Name = contextChoices?.alert_privacy?.find(privacy => privacy.id === PrivacyId)?.name;
      return Name;
    } else {
      return
    }
  }


  const getStartEndTime = (starttime, endtime) => {
    let startTime = starttime ? moment(starttime.split('T')[1], 'HH:mm:ss').format('HH:mm') : '';

    let endTime = endtime ? moment(endtime.split('T')[1], 'HH:mm:ss').format('HH:mm') : '';

    let Time = startTime + '' + '-' + '' + endTime;

    return Time
  }

  const sliceString = string => {
    return string.length > 30 ? string.slice(0, 30) + '...' : string;
  }

  const loadVisitCareNotesByRowsId = async (id, client) => {
    setLoadingRowModal(true)
    const response = await getVisitCareNotesByRowsId(id, client);
    if (response) {
      values.post_description = response.care_notes ? response.care_notes : '';
      values.alert_created_by = response.care_notes_created_by
        ? response.care_notes_created_by
        : '';
      values.alert_label = response.care_notes_label?.map(x => {
        return {
          value: x.label_id,
          label: contextChoices.alert_label?.find(dataid => dataid.id == x.label_id)?.name,
          id: x.label_id,
          lebelId: x.id
        };
      });
      values.alert_privacy = response.notes_privacy
        ? response.notes_privacy
        : '';
      values.visit = response.visit ? response.visit : '';
      values.client_service_visit_date = response.visit_start_date
        ? response.visit_start_date.split('T')[0]
        : '';
      values.client_service_visit_timerange = response.csv ? response.csv : '';
      values.clientName = response.client ? response.client : '';
      values.id = response.id ? response.id : '';
      values.review_status = response.review_status;
      values.review_comment = response.review_comment;
      values.review_date = response.review_date_time?.split('T')[0];
      values.review_time = response.review_date_time?.split('T')[1];
      values.review_by = response.review_by
      setValues({ ...values });
    }
    setLoadingRowModal(false)
  };

  const handleRowClick = (row) => {
    setShowModal(true)
    setSelectedId(row.id)
    setEditMode(true)
    setEditMode(false)
    setButtonIcon(FLOPPY)
    setTextStatus(SAVE);
    loadVisitCareNotesByRowsId(row.id, row.client)
  }

  const addNewCateNotes = () => {
    setLoadingRowModal(false)
    setShowModal(true)
    setEditMode(false)
    setButtonIcon(FLOPPY)
    setTextStatus(SAVE);
    setValues({ visit: visitId, client_service_visit_date: visitData?.schedule_start_date })
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if (loadingSave) {
      return;
    }
    let alertNewName = [];
    let arrIds = [];
    if (values.alert_label) {
      let data = values?.alert_label?.forEach(name => {
        if (name.className === 'Select-create-option-placeholder') {
          alertNewName.push(name.label);
        }
      });



      if (alertNewName && (alertNewName.length > 0)) {
        try {
          const response = await setOnlyforTriagePopupTriageLabel(alertNewName)
          if (response && response.data && response.data.length) {
            handleChangeChoices('alert_label')
            let data = values?.alert_label?.forEach(dataname => {
              if (dataname.className === 'Select-create-option-placeholder') {
                let alertNewIds = response.data.find(item => item.name == dataname.label).id;
                delete dataname.value;
                arrIds.push({ ...dataname, value: alertNewIds });
              } else {
                arrIds.push({ ...dataname });
              }
            });
            values.alert_label_data = arrIds.map(label => {
              if (label.lebelId) {
                return { id: label.lebelId, label_id: label.value };
              } else if (
                label.className === 'Select-create-option-placeholder'
              ) {
                return { label_id: label.value };
              } else {
                return { label_id: label.value };
              }
            });
          }
        } catch (error) {
          onError(error);
        }


      }
    }

    if (!values.post_description || !values.alert_privacy) {
      setErrorMessage(InvalidOrEmptyValues);
      setCheckRequires(true)
    } else {
      const bodyData = {
        client: clientSelectedId ? clientSelectedId : '',
        visit: values.visit ? values.visit : visitId,
        notes_privacy: values.alert_privacy ? values.alert_privacy : '',
        care_notes_created_by: values.alert_created_by
          ? values.alert_created_by
          : '',
        care_notes_label: values.alert_label_data || (
          Array.isArray(values?.alert_label) && values.alert_label.length
            ? values.alert_label.map(label => ({
              ...(label?.lebelId && { id: label.lebelId }),
              label_id: label?.value
            }))
            : ""
        ),
        care_notes: values.post_description ? values.post_description : '',
        notes_date: values.client_service_visit_date ? values.client_service_visit_date : null,
      };

      // if (values.review_status) {
      //   bodyData.review_status = values.review_status
      // }
      // if (values.review_by) {
      //   bodyData.review_by = values.review_by
      // }
      // if (values.review_comment) {
      //   bodyData.review_comment = values.review_comment
      // }
      // if (values.review_date || values.review_time) {
      //   bodyData.review_date_time = `${values.review_date || ''} ${values.review_time || ''}`
      // }

      setLoadingSave(true);
      setVisitCareNotesDetails(bodyData, values.id, clientSelectedId)
        .then(response => {
          // {"status":"FAIL","message":"Something went wrong. Try Again"}
          if (response.status == "FAIL") {
            throw response.message
          }
          if (response?.id) {
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: `${response?.message}`,
            });
            onSuccess(response)

          }
          if (response.message.detail) {
            onError(response.message.detail);
          } else {
            onSuccess(response);
          }
        })
        .catch(onError);
    }
  };
  const onSuccess = response => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setShowModal(false)
    loadVisitCareNotes();
  };

  const onError = err => {
    let getErrorMessage = err ? err : 'something went wrong !!';
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(getErrorMessage);
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: `${getErrorMessage}`,
    });
  };


  const displayLabel = (labels) => {
    let ids = labels.map(el => el.label_id)
    const fill = contextChoices?.alert_label?.filter(item => ids.includes(item.id))
    return fill ? fill.map(el => el.name).join(",") : ""
  };

  const handelActiveOrInActive = async (rev_d, is_active) => {
    let review_s = is_active ? contextChoices?.medication_review_status?.find((val) => val.name == 'Not Reviewed') : contextChoices?.medication_review_status?.find((val) => val.name == 'Reviewed')
    let body = {
      client_care_notes_ids: [rev_d.id],
      review_status: review_s.id
    }
    try {
      const response = await postBulkReviewStatus(body);
      loadVisitCareNotes()
    } catch (err) {
    }
  }
  const handleDeleteVisitCareNote = async () => {
    const res = await deleteVisitCareNotes(idSelected, clientSelectedId);
    if (res && res[0] === "Data Deleted Successful") {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${res[0]}`,
      });
    }
    setModalDeleteValue(false);
    setIdSelected('');
    loadVisitCareNotes();
  };

  const handleDeleteRow = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const handleReviewSection = (id) => {
    setModalReviewSection(true)
    setIdSelected(id)
  }

  const handleExpansion = (index) => {
    if (expandFields[index] !== undefined
      && expandFields[index]['open']) {
      expandFields[index] = { open: false };
      setExpandFields({ ...expandFields });
    } else {
      expandFields[index] = { open: true };
      setExpandFields({ ...expandFields });
    }
  };

  const calculateNumberOfIcons = () => {
    let count = 0;
    if (updatePermissionCareNotes || updatePermissionCareNotesReview)
      count = count + 1;
    if ((!updatePermissionCareNotes && !updatePermissionCareNotesReview) && (readPermissionCareNotes || readPermissionCareNotesReview))
      count = count + 1;
    if (deletePermissionCareNotes || deletePermissionCareNotesReview)
      count = count + 1;
    if (updatePermissionCareNotesReview)
      count = count + 1;
    return count || 0
  }


  const checkReviewStatus =  id => {
    let status = id && contextChoices?.medication_review_status?.find(item => item?.id == id)
    && contextChoices?.medication_review_status?.find(item => item?.id == id)
    return status?.name == 'Reviewed'
  }

  console.log(values, "values")
  return (
    <div className='visit-popup-care-notes'>
      {(readPermissionCareNotes || readPermissionCareNotesReview)
        ?
        <div>
          <Toast ref={toast} />
          {loadingModal ? (
            <div style={{ marginTop: "300px" }}>
              <Spinner />
            </div>
          ) : (
            <>
              {!showModal ? (
                <>
                  {(createPermissionCareNotes || createPermissionCareNotesReview) &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: "10px" }}>
                      <FaPlus color={PURPLE} size={40} onClick={addNewCateNotes} />
                    </div>}
                  {visitCareNotes?.length ? (
                    <Table
                      headerColumns={headerColumns}
                      headerPadding={false}
                      noNeed={false}
                      numberOfIcons={calculateNumberOfIcons()}
                      noEditOption={true}
                      callBackQuerySort={setQuerySort}
                    // headerPadding={true}
                    >
                      <div>
                        {visitCareNotes?.map((notesData, index) => {
                          return (
                            <div>
                              <Row bgColor Shadow>
                                <ColsGrouper className='d-flex'>
                                  <Col
                                    max_width={CHECK_BOX}
                                    className="d-flex justify-content-center align-content-center">
                                    <div
                                      className="closeButtonWrapper client-service-expand-icon"
                                      onClick={() => {
                                        handleExpansion(index);
                                      }}>
                                      {expandFields && expandFields[index] && expandFields[index]?.open ? (
                                        <IoIosArrowDown
                                          style={{ color: '#6200f9', fontWeight: 'bold' }}
                                        />
                                      ) : (
                                        <IoIosArrowForward
                                          style={{ color: '#6200f9', fontWeight: 'bold' }}
                                        />
                                      )}
                                    </div>
                          </Col>

                                  <Col
                                    max_width={SMALL_COL}
                                    Center
                                    overlap={true}>
                                    {notesData?.updated_by && notesData?.updated_by?.name}
                                  </Col>
                                  <Col
                                    max_width={SMALL_COL}
                                    Center
                                    className='sm-hidden'
                                    overlap={true}>
                                    {getStartEndTime(notesData.visit_start_date && notesData.visit_start_date, notesData.visit_end_date && notesData.visit_end_date)}
                                  </Col>
                                  <Col
                                    max_width={SMALL_COL}
                                    Center
                                    overlap={true}>
                                    {displayEmployeeName(notesData.care_notes_created_by && notesData.care_notes_created_by)}
                                  </Col>
                                  <Col
                                    max_width={SMALL_COL}
                                    Center
                                    className='sm-hidden md-hidden'
                                    overlap={true}>
                                    {displayCategoryName(notesData.service_category && notesData.service_category)}
                                  </Col>
                                  <Col
                                    max_width={MEDIUM_COL}
                                    Center
                                    className='sm-hidden md-hidden'
                                    overlap={true}>
                                    {displayVisitType(notesData.visit_type && notesData.visit_type)}
                                  </Col>
                                  {/* <Col
                                    max_width={EX_VERY_LARGE_COL}
                                    CentervisitCareNotes
                                    className='sm-hidden md-hidden'
                                    overlap={true}>
                                    {notesData.care_notes && notesData.care_notes}
                                  </Col> */}
                                  <Col
                                    max_width={EX_VERY_LARGE_COL}
                                    CentervisitCareNotes
                                    className='sm-hidden md-hidden'
                                    overlap={true}>
                                    {displayPrivacyName(notesData.notes_privacy && notesData.notes_privacy)}
                                  </Col>
                                  <Col
                                    max_width={EX_VERY_LARGE_COL}
                                    CentervisitCareNotes
                                    className='sm-hidden md-hidden'
                                    overlap={true}>
                                    {notesData.care_notes_label && displayLabel(notesData.care_notes_label)}
                                  </Col>
                                  {(updatePermissionCareNotes || updatePermissionCareNotesReview) ? (
                                    <Col
                                      max_width={EX_SMALL_COL}
                                      Center
                                      overlap={true}
                                      Icon
                                      Purple
                                    >
                                      <IconWrapper
                                        style={{ marginRight: "5px" }}
                                        onClick={() => {
                                          handleRowClick(notesData && notesData);
                                        }}>
                                        <MdEdit />
                                      </IconWrapper>
                                    </Col>
                                  ) : (
                                    <>
                                      {(readPermissionCareNotes || readPermissionCareNotesReview) &&
                                        <Col
                                          max_width={EX_SMALL_COL}
                                          Center
                                          overlap={true}
                                          Icon
                                          Purple
                                        >
                                          <IconWrapper
                                            style={{ marginRight: "5px" }}
                                            onClick={() => {
                                              handleRowClick(notesData && notesData);
                                            }}>
                                            {READ_INFO}
                                          </IconWrapper>
                                        </Col>}
                                    </>
                                  )}

                                  {(updatePermissionCareNotesReview) && (
                                    <Col
                                      // max_width={EX_SMALL_COL}
                                      Center
                                      overlap={true}
                                      Icon
                                      Purple
                                    >
                                      {checkReviewStatus(notesData?.review_section?.review_status)&& (
                                        <IconWrapper
                                          onClick={() =>
                                            // handelActiveOrInActive(notesData, true)
                                            handleReviewSection(notesData.id)
                                          }>
                                          <IconContext.Provider
                                            value={{ color: 'green', size: '2.1rem' }}>
                                            <RiCheckboxCircleFill />
                                          </IconContext.Provider>
                                        </IconWrapper>
                                      )}
                                      {!checkReviewStatus(notesData?.review_section?.review_status) && (
                                        <IconWrapper
                                          onClick={() =>
                                            // handelActiveOrInActive(notesData, false)
                                            handleReviewSection(notesData.id)
                                          }>
                                          <IconContext.Provider
                                            value={{ color: 'red', size: '2.1rem' }}>
                                            <RiCloseCircleFill />
                                          </IconContext.Provider>
                                        </IconWrapper>
                                      )}
                                    </Col>
                                  )}

                                </ColsGrouper>
                                {(deletePermissionCareNotes || deletePermissionCareNotesReview) && (
                                  <Col Center Shrink NoFlexGrow>
                                    <IconWrapper
                                      onClick={() => {
                                        handleDeleteRow(notesData.id);
                                      }}>
                                      <RiDeleteBinLine />
                                    </IconWrapper>
                                  </Col>
                                )}
                              </Row>
                              <div className="mb-1" />
                              {/* Expanded Content */}
                              {
                                expandFields && expandFields[index] && expandFields[index]?.open && (
                                  <div className='ms-3 me-3'>
                                    {(notesData.review_section && notesData?.review_section?.review_comment) && (
                                      <>
                                        <div
                                          className={'pt-2 ms-0'}
                                          style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                                          Review Comment
                                        </div>
                                        <div className='mt-1 mb-2 ' style={{ border: "solid 1px #ccc", borderRadius: "0.25rem", padding: "0.75rem" }}>
                                          <div style={{ minHeight: "2.5rem" }}>{notesData?.review_section?.review_comment}</div>
                                        </div>
                                      </>)}

                                    <div
                                      className={'pt-2 mt-2 ms-0'}
                                      style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                                      Care Notes
                                    </div>
                                    <div className='mt-1 mb-2 ' style={{ border: "solid 1px #ccc", borderRadius: "0.25rem", padding: "0.75rem" }}>
                                      <div style={{ minHeight: "2.5rem" }}>{notesData.care_notes && notesData.care_notes}</div>
                                    </div>

                                  </div>
                                )
                              }
                            </div>
                          );
                        })}
                        {/* <div className="mt-3">
                <Pagination totalPages={pages} currentPage={currentPage} values={values} dropDownHandleSelect={dropDownHandleSelectPagination} number={PAGE_SIZE_RENDER} dataLength={visitCareNotes.length} />
              </div> */}
                      </div>
                    </Table>
                  ) : (
                    <TitleSection style={{ textAlign: "center" }}>No data found</TitleSection>
                  )}
                </>
              ) : (
                <>
                  {loadingRowModal ? (
                    <div style={{ marginTop: "300px" }}>
                      <Spinner />
                    </div>
                  ) : (
                    <CareNotesModal
                      values={values}
                      setValues={setValues}
                      useInput={useInput}
                      setCheckRequires={setCheckRequires}
                      errors={errors}
                      choices={contextChoices}
                      setShowModal={setShowModal}
                      errorMessage={errorMessage}
                      handleSubmit={handleSubmit}
                      textStatus={textStatus}
                      loadingSave={loadingSave}
                      buttonIcon={buttonIcon}
                      clientId={clientSelectedId}
                      editMode={editMode}
                      readPermissionCareNotes={readPermissionCareNotes}
                      createPermissionCareNotes={createPermissionCareNotes}
                      updatePermissionCareNotes={updatePermissionCareNotes}
                      deletePermissionCareNotes={deletePermissionCareNotes}

                      readPermissionCareNotesReview={readPermissionCareNotesReview}
                      createPermissionCareNotesReview={createPermissionCareNotesReview}
                      updatePermissionCareNotesReview={updatePermissionCareNotesReview}
                      deletePermissionCareNotesReview={deletePermissionCareNotesReview}
                    />
                  )
                  }

                </>
              )
              }
            </>
          )}

          {/* Review Section Popup */}
          <div className='review-cection'>
            {modalReviewSection ?
              <ReviewSection
                idSelected={idSelected}
                setModalValue={setModalReviewSection}
                fullBind={fullBindRiviewSection}
                loadVisitCareNotes={loadVisitCareNotes}
                contextChoices={contextChoices}
                setIdSelected={setIdSelected}
                readPermission={readPermissionCareNotesReview}
                createPermission={createPermissionCareNotesReview}
                updatePermission={updatePermissionCareNotesReview}
                deletePermission={deletePermissionCareNotesReview}
                toast={toast}
              /> : null
            }
          </div>

        </div>
        :
        <TitleSection className='text-center mb-4 mt-4'>You don't have permission to access the information.</TitleSection>

      }
      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this Visit Care Note? \n DELETING the Visit Care Note will remove ALL records from database.'
        }
        // subBody={
        //   'TIP (Recommended) - you could change the Visit Care Note to discard instead to retain database records.'
        // }
        onOk={handleDeleteVisitCareNote}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
}

export default CareNotes;
