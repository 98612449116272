import React from 'react'
import { Actions, BorderContainer, ListContainer, ListItem } from './styles';
import { AccordionArrow, AccordionContainer } from 'components/DashboardComponents/LeftSidebar/styles';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { TitleSection } from '../styles';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GRAY_700, PURPLE } from 'shared/styles/constants/colors';
import { useState } from 'react';
import { useEffect } from 'react';

function ShiftDetailsList({
    list,
    handleAccordionClick,
    expandedSections,
    checkedRows,
    setCheckedRows,
    handleSelect,
    setIsAllSelected,
    actionItems,
    itemName,
    title,
    specialType }) {


    const [expanded, setExpanded] = useState({})

    useEffect(() => {
        Object.keys(list).forEach(((_, index) => {
            expanded[index] = { open: true }
        }))
        setExpanded({ ...expanded })
    }, [])

    const handleExpand = (index) => {
        if (expanded[index] !== undefined && expanded[index] && expanded[index]?.open) {
            expanded[index] = { open: false }
        } else {
            expanded[index] = { open: true }
        }
        setExpanded({ ...expanded })
    }
    console.log(expanded, "expandedSections")
    return (
        <div>
            <AccordionContainer style={{ backgroundColor: '#f7f7f7' }}>
                <AccordionArrow className='ml-1'
                    onClick={() =>
                        handleAccordionClick(itemName,)
                    }>
                    <div>
                        {expandedSections[itemName] ? (
                            <IoIosArrowUp size={18} color={PURPLE} />
                        ) : (
                            <IoIosArrowDown size={18} color={PURPLE} />
                        )}
                    </div>
                </AccordionArrow>
                <div className='d-flex justify-content-between w-100'>
                    <TitleSection>{title}</TitleSection>
                    <div style={{ marginRight: '10px' }}>
                        <Actions>
                            <div
                                class="dropdown-toggle"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                                <BsThreeDotsVertical />
                            </div>
                            <div class="dropdown-menu mr-2" aria-labelledby="dropdownMenuButton" style={{ zIndex: 9999 }}>

                                {actionItems.map(action => {
                                    return (
                                        <li class="dropdown-item"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => action.onClick(itemName, "parent")}>
                                            {action.name}</li>
                                    )
                                })}
                            </div>
                        </Actions>
                    </div>
                </div>
            </AccordionContainer>
            {!expandedSections.shifts &&
                <ListContainer style={{ paddingLeft: "35px", paddingRight: "35px" }}>
                    {Object.keys(list).map((shift, index) => {
                        return (
                            <>
                                <BorderContainer className={`w-100 pl-1 pr-2 pt-1 pb-1 mb-2 ${index > 0 ? "mt-2" :""}`}>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex w-100'>
                                            <div className='ml-1 mr-2' style={{ color: PURPLE }}
                                                onClick={() => handleExpand(index)} >
                                                {!(expanded[index] && expanded[index]?.open) ? (
                                                    <IoIosArrowUp size={18} color={PURPLE} />
                                                ) : (
                                                    <IoIosArrowDown size={18} color={PURPLE} />
                                                )}
                                            </div>
                                            <div className='d-flex w-100'>
                                                <div className='' style={{ width: 'fit-content', fontWeight: "500" }}>
                                                    {shift}
                                                </div>
                                                <div className='ml-2' style={{ color: GRAY_700, width: '70%' }}>
                                                    {/* {item.description} */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex'>
                                            <Actions>
                                                <div
                                                    class="dropdown-toggle"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <BsThreeDotsVertical />
                                                </div>
                                                <div class="dropdown-menu mr-2" aria-labelledby="dropdownMenuButton" style={{ zIndex: 9999 }}>

                                                    {actionItems.map(action => {
                                                        return (
                                                            <li class="dropdown-item"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => action.onClick(itemName, shift)}>
                                                                {action.name}</li>
                                                        )
                                                    })}
                                                </div>
                                            </Actions>
                                        </div>
                                    </div>
                                </BorderContainer>
                                {(expanded[index] && expanded[index]?.open) ?
                                    list[shift].length && list[shift].map(item => {
                                        return (
                                            <ListItem>
                                                <div style={{ maxWidth: "95%" }}>{item.message}</div>
                                                <input
                                                    style={{
                                                        marginTop: '0.3rem',
                                                        marginLeft: '-1.25rem'
                                                    }}
                                                    type="checkbox"
                                                    checked={checkedRows[itemName].includes(item.id)}
                                                    id="flexCheckDefault"
                                                    onChange={e => {
                                                        handleSelect(e, item.id, itemName);
                                                    }}
                                                />

                                            </ListItem>
                                        );
                                    })
                                    : null}
                            </>
                        )
                    })
                    }
                </ListContainer>
            }
        </div>
    )
}

export default ShiftDetailsList