import styled from 'styled-components';
import {PURPLE} from 'shared/styles/constants/colors';

export const SpinnerStyled = styled.div`
  position: absolute;
  left: 57%;
  top: ${props => (props.spinnerTop ? props.spinnerTop : '62%')};
  z-index: 1;
  width: 9.375rem;
  height: 9.375rem;
  margin: -75px 0 0 -75px;
  border: 1rem solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid ${PURPLE};
  width: 7.5rem;
  height: 7.5rem;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export const SpinnerLabel = styled.div`
  position: absolute;
  left: 57%;
  top: ${props => (props.textTop ? props.textTop : '61%')};
  z-index: 1;
  transform: translate(-75%, -50%);
`;
