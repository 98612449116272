
import DateSelector from 'components/SharedComponents/DateSelector';
import Spinner from 'components/SharedComponents/Spinner';
import { useForm } from 'hooks/FormHook';
import moment from 'moment';
import React, { useEffect, useState, Fragment, useRef } from 'react';
import { WHITE } from 'shared/styles/constants/colors';
import { DropdownTypesContainer } from 'shared/styles/constants/tagsStyles';
import { getClientDiary, multiClientDiary, } from 'utils/api/ClientApi';
import './dairy.css';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { DOWNLOAD } from 'utils/constants/icons';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { Toast } from 'primereact/toast';
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles';

const ClientDiary = ({
  selectedClientId,
  // cliId
  clientFullName
}) => {
  let clientId = selectedClientId
  const [events, setEvents] = useState([]);
  const [multiEvents, setMultiEvents] = useState([]);
  const [resourcesMedication, setResourcesMedication] = useState([]);
  const currentDate = moment('2024-02-02');

  // Calculate a week before
  const weekBeforeDate = currentDate.clone().subtract(1, 'weeks');

  // Format and display the result
  console.log('Current Date:', currentDate.format('YYYY-MM-DD'));
  console.log('Week Before Date:', weekBeforeDate.format('YYYY-MM-DD'));
  const { values, setValues, useInput } = useForm({
    choose_date: moment().format('YYYY-MM-DD'),
    date_to: moment().add(1, 'weeks').format('YYYY-MM-DD'),
    date_from: moment().format('YYYY-MM-DD')
  });
  const [loading, setLoading] = useState(true)
  const [downloadDisable, setDownloadDisable] = useState(false);
  const [showHeader, setShowHeader] = useState(false)
  const mountedRef = useRef(true);
  const toast = useRef(null)

  useEffect(() => {
    if (clientId && values.choose_date) {
      searchScheduler()
    }
  }, [])

  const getbackGrouncolor = (recordsOf) => {
    switch (recordsOf) {
      case "Med":
        return '#d3fcf5'
      case "In":
        return '#ffd5d3'
      case "Out":
        return '#c6ebd5'
      case "Task":
        return '#e9e2a7'
      case "Triage":
        return '#a7d1e9'
      case "Note":
        return '#dab6c5'
      default:
        return '#9f9f9f'
    }
  }


  const searchScheduler = async () => {
    if (!loading) {
      setLoading(true)
    }
    setEvents([]);
    let clientId = selectedClientId

    Promise.all([getClientDiary({ ...values }, 800, clientId)])
      .then(response => {
        if (!mountedRef.current) return null;
        let data = [];
        let resource_data = []
        //data = 
        response[0] && response[0].data.map(val => {
          data.push({
            id: val.id,
            resourceId: val.id,
            title: val.title,
            backgroundColor: getbackGrouncolor(val.recordsOf),
            textColor: WHITE,
            constraint: {
              resourceIds: [val.id],
            },
            dateOfRecord: val.dateOfRecord,
            description: val.description,
            recordsOf: val.recordsOf,
            plannedDate: val.plannedDate
          });
        });


        console.log(data, "response")
        let final_data = [...data]
        setEvents(final_data.flat());
        setResourcesMedication(resource_data.flat());
        setLoading(false)
      })
  };

  const handleSearch = () => {
    searchScheduler()
  }

  const searchMultiScheduler = async () => {
    if (!loading) {
      setLoading(true)
    }
    setEvents([]);
    let clientId = selectedClientId

    Promise.all([multiClientDiary({ ...values }, 800, clientId)])
      .then(response => {
        if (!mountedRef.current) return null;
        let data = Object.entries(response[0] && response[0]?.data)?.map(([date, events]) => {
          return {
            date: date,
            events: events.map(val => ({
              id: val.id,
              resourceId: val.id,
              title: val.title,
              backgroundColor: getbackGrouncolor(val.recordsOf),
              textColor: WHITE,
              constraint: {
                resourceIds: [val.id],
              },
              dateOfRecord: val.dateOfRecord,
              description: val.description,
              recordsOf: val.recordsOf,
              plannedDate: val.plannedDate
            }))
          }
        })
        setMultiEvents(data);
        setLoading(false)
      })
  };
  const handleMultiSearch = () => {
    if (clientId && values.date_from && values.date_to && values.is_multi) {
      searchMultiScheduler()
    }
  }
  useEffect(() => {
    values.is_multi ? handleMultiSearch() : handleSearch()
  }, [values.is_multi])
  const exportAsPDF = () => {
    setDownloadDisable(true);
    setShowHeader(true)
    const input = document.getElementById('export-client-diary');
    setTimeout(() => {
      html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            orientation: window.innerWidth > window.innerHeight ? 'landscape' : 'portrait',
          });

          let position = 0;
          let pageHeight = pdf.internal.pageSize.getHeight();
          let imgProps = pdf.getImageProperties(imgData);
          let pdfWidth = pdf.internal.pageSize.getWidth();
          let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          let heightLeft = pdfHeight;

          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - pdfHeight;
            pdf.addPage();
            console.log({ position, pdfWidth, pdfHeight }, "dimentions")
            pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
            heightLeft -= pageHeight;
          }
          pdf.save(`${clientFullName}-Client_Diary_${moment().format('YYYY-MM-DD')}`);
          setDownloadDisable(false);
          setShowHeader(false)
        }).catch((err) => {
          // debugger
        })
    }, 1)
  };

  useEffect(() => {
    const date_from = new Date(values.date_from)
    const date_to = new Date(values.date_to)
    if (date_from && date_to) {
      if (date_from > date_to) {
        toast && toast.current && toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: ('Date from should be greater than date to')
        });
        setValues({ ...values })
      }
    }
  }, [values.date_to, values.date_from])



  const handleChangeMulti = (e) => {
    values.is_multi = !values.is_multi
    setValues({ ...values })
  }

  const choose_date_hook = useInput('choose_date')
  const end_date_hook = useInput('date_to')
  const start_date_hook = useInput('date_from')
  const is_multi_hook = useInput('is_multi')
  return (
    <>
      <Toast ref={toast} />
      <div className='custom-margin'>
        {
          loading ?
            <Spinner
              spinnerTop={"70%"}
              textTop={'68%'}
            />
            :
            <>
              <DropdownTypesContainer className="ms-1 me-1 mb-2 mt-2 d-flex justify-content-between flex-wrap ">
                <div className='d-flex flex-wrap'>
                  {/* <Title style={{ marginTop: '4px' }} className='me-1'>Select Date</Title> */}
                  <ToggleButton
                    label={values.is_multi ? "Multi" : "Single"}
                    useInput={is_multi_hook}
                    id={`make_user`}
                    switch={true}
                    onClick={handleChangeMulti}
                  />

                  {
                    values.is_multi ? (
                      <>
                        <div style={{ marginLeft: '4px' }}>
                          <DateSelector
                            typeFilter={true}
                            icon={true}
                            labelTop={"Date from"}
                            // labelTop={"Select Date"}
                            value={values.date_from ? values.date_from : ''}
                            {...start_date_hook}
                          />
                        </div>
                        <div style={{ marginLeft: '4px' }}>
                          <DateSelector
                            typeFilter={true}
                            icon={true}
                            labelTop="Data to"
                            value={values.date_to ? values.date_to : ''}
                            {...end_date_hook}
                          />
                        </div>
                      </>
                    ) : (
                      <div style={{ marginLeft: '4px' }}>
                        <DateSelector
                          typeFilter={true}
                          icon={true}
                          labelTop={values.is_multi ? "Date from" : "Select Date"}
                          // labelTop={"Select Date"}
                          value={values.choose_date ? values.choose_date : ''}
                          {...choose_date_hook}
                        />
                      </div>
                    )}
                  <div>
                    <PrimaryButtonForm
                      style={{ marginLeft: '4px' }}
                      minWidth="6rem"
                      onClick={() => values.is_multi ? handleMultiSearch() : handleSearch()}
                      disabled={downloadDisable}
                    >
                      <span className={'me-2'}>Search</span>
                    </PrimaryButtonForm>
                  </div>
                </div>
                {(values.is_multi ? multiEvents.length > 0 : events.length > 0) &&
                <PrimaryButtonForm
                  style={{ marginLeft: '4px', maxHeight: '37px' }}
                  minWidth="6rem"
                  onClick={() => exportAsPDF()}
                  disabled={downloadDisable}>
                  <div className='d-flex'>
                    <span className={'me-2 '}>Export</span>
                    {!downloadDisable ? (
                      DOWNLOAD
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                  </div>
                </PrimaryButtonForm>}
              </DropdownTypesContainer>
              <Fragment>
                {values.is_multi ?
                  (multiEvents && multiEvents.length > 0) ?
                    <div div id='export-client-diary'>
                      {showHeader && <DiaryHeading heading={`Client Diary - ${clientFullName} - ${values.is_multi ? values.date_from : values.choose_date}${values.is_multi && values.date_to ? "-" + values.date_to : ""}`} />}
                      <div className="timeline">
                        {multiEvents.map((item) => {

                          return (
                            <>
                              <div className='w-100 d-flex'>
                                <div style={{ width: '30%' }}></div>
                                <div className='date-text'>{item.date}</div>
                              </div>
                              {
                                item.events.map((item, index) => {
                                  return (
                                    <div className="entry">
                                      <div className="title">
                                        {item.dateOfRecord && (item.dateOfRecord !== 'None') && <h3>{item.dateOfRecord ? ((item.dateOfRecord == 'None') ? '' : moment(item.dateOfRecord).format('HH:mm')) : ''}</h3>}
                                        <p>{item.recordsOf}</p>
                                        {item.plannedDate && (item.plannedDate !== 'None') && <h4>{item.plannedDate ? ((item.plannedDate == 'None') ? '' : moment(item.plannedDate).format('HH:mm')) : ''}</h4>}
                                      </div>
                                      <div className='border_body'>
                                        <div className="body" style={{ backgroundColor: item.backgroundColor }}>
                                          <p>{item.title}</p>
                                          <ul>
                                            <li>{item.description}</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </>
                          )
                        })}
                      </div>
                    </div>
                    :
                    <NoDataFoundMessage className='mb-3'>No data found</NoDataFoundMessage>
                  : (
                    <>
                      {(events && events.length > 0) ?
                        <div id='export-client-diary'>
                          {showHeader && <DiaryHeading heading={`Client Diary - ${clientFullName} - ${values.choose_date}`} />}
                          <div className="timeline">
                            {events.map((item) => {
                              return (
                                <div className="entry">
                                  <div className="title">
                                    {item.dateOfRecord && (item.dateOfRecord !== 'None') && <h3>{item.dateOfRecord ? ((item.dateOfRecord == 'None') ? '' : moment(item.dateOfRecord).format('HH:mm')) : ''}</h3>}
                                    <p>{item.recordsOf}</p>
                                    {item.plannedDate && (item.plannedDate !== 'None') && <h4>{item.plannedDate ? ((item.plannedDate == 'None') ? '' : moment(item.plannedDate).format('HH:mm')) : ''}</h4>}
                                  </div>
                                  <div className='border_body'>
                                    <div className="body" style={{ backgroundColor: item.backgroundColor }}>
                                      <p>{item.title}</p>
                                      <ul>
                                        <li>{item.description}</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        :
                        <NoDataFoundMessage>No data found</NoDataFoundMessage>
                      }

                    </>
                  )}


              </Fragment>

              {/* {(events && events.length > 0) ? 
          <div className="timeline">
          {events.map((item) => {
            return (
              <div className="page">
                <div className="timeline">
                  <div className="timeline__group">
                    <span className="timeline__year time" aria-hidden="true">{item.dateOfRecord ? moment(item.dateOfRecord).format('HH:mm') : ''}</span>
                    <div className="timeline__cards">
                      <div className="timeline__card card">
                        <header className="card__header">
                          <time className="time" datetime="2008-09-01">
                            <span className="time__month">{item.recordsOf}</span>
                          </time>
                          <h3 className="card__title r-title">{item.title}</h3>
                        </header>
                        <div className="card__content">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          </div>
          :
          <div className="card mt-2">
          <header className="card__header">
            <h3 className="card__title">No data found for today</h3>
          </header>
        </div>
        } */}
            </>
        }
      </div >
    </>
  );
};



export default ClientDiary;

const DiaryHeading = ({ heading }) => {
  return (
    <div className='d-flex justify-content-center' style={{ background: "rgb(159 157 162)", marginTop: "50px" }}>
      <h4 style={{ fontSize: "1.05rem", marginTop: "0.5rem" }}>{heading}</h4>
    </div>
  )
}