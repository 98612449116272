import React, { useRef, useState, useEffect, Fragment, useContext } from 'react';
import HealthInformation from 'components/SharedComponents/FormSections/HealthDetail';
import { TitleSection } from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import AvoidDetails from 'components/SharedComponents/FormSections/Team/AvoidDetails';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { useHistory, useLocation } from 'react-router-dom';
import {
  setClientHealthDetails,
  getClientHealthDetails,
} from 'utils/api/ClientApi';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {
  setMedCondition,
  setNewMedCondition,
  setAllergySource,
} from 'utils/api/SettingsApi';
import { ErrorMessage } from 'shared/styles/constants/tagsStyles';
import _ from 'lodash';
import { pickFile } from 'utils/files';
import { Toast } from 'primereact/toast';

import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import { AppContext } from 'context';
import {
  WEB_CREATE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  CLIENT_MEDICAL_INFO,
  CLIENT_ALLERGIES_INTOLERANCES,
  CLIENT_VACCINATIONS,
  CLIENT_MEDICATION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION,
} from '../../../../utils/constants/permissions';
import { client_health_details_choices } from 'utils/choiceConstant';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import AllergiesAndIntolerances from './AllergiesAndIntolerances';

const HealthDetails = ({
  choices,
  employees,
  selectedClientId,
  userClient,
  // cliId,
  healthDet,
  setHealthDet,
  handleChangeChoices,
  filterAdmin,
  filterSuperAdmin,
  contextSubscription,
  rightPermission,
  permissionDetailsUpdateHealth,
  contextRelativesClient
}) => {
  const { values, setValues, useInput, isValid, errors, setCheckRequires, setFormErrors } =
    useForm({});
  const [proofExcemption, setProofExcemption] = useState('No File Chosen');
  const [showError, setShowError] = useState(false);
  const [toggleExcProof, setToggleExcProof] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [newMedical, setNewMedical] = useState();
  const [indexMed, setIndexMed] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [tempCheck, setTempCheck] = useState()
  const [
    employeeDetailsMedicalInformationCreate,
    setEmployeeDetailsMedicalInformationCreate,
  ] = useState(false);
  const [
    employeeDetailsMedicalInformationUpdate,
    setEmployeeDetailsMedicalInformationUpdate,
  ] = useState(false);
  const [
    employeeDetailsMedicalInformationRead,
    setEmployeeDetailsMedicalInformationRead,
  ] = useState(false);
  const [
    employeeDetailsVaccinationsCreate,
    setEmployeeDetailsVaccinationsCreate,
  ] = useState(false);
  const [
    employeeDetailsVaccinationsUpdate,
    setEmployeeDetailsVaccinationsUpdate,
  ] = useState(false);
  const [employeeDetailsVaccinationsRead, setEmployeeDetailsVaccinationsRead] =
    useState(false);
  const [employeeDetailsMedicationRead, setEmployeeDetailsMedicationRead] =
    useState(false);
  const [employeeDetailsMedicationCreate, setEmployeeDetailsMedicationCreate] =
    useState(false);
  const [employeeDetailsMedicationUpdate, setEmployeeDetailsMedicationUpdate] =
    useState(false);
  const [
    employeeDetailsAllergiesAndIntolerancesRead,
    setEmployeeDetailsAllergiesAndIntolerancesRead,
  ] = useState(false);
  const [
    employeeDetailsAllergiesAndIntolerancesCreate,
    setEmployeeDetailsAllergiesAndIntolerancesCreate,
  ] = useState(false);
  const [
    employeeDetailsAllergiesAndIntolerancesUpdate,
    setEmployeeDetailsAllergiesAndIntolerancesUpdate,
  ] = useState(false);
  const fileExcemptionProof = useRef(null);
  const [modalLoading, setModalLoading] = useState(true);
  const [medicalConditionList, setMedicalConditionList] = useState([]);
  const [allegrySourceList, setAllegrySourceList] = useState([]);
  const { contextChoices, handleGetChoices } = useContext(AppContext);
  const [dataCheck, setDataCheck] = useState();
  const history = useHistory();
  const location = useLocation();
  const toast = useRef();



  useEffect(() => {
    handleGetChoices(client_health_details_choices)
  }, [])

  useEffect(() => {
    let medical_condition = [];
    let allergy_source = [];

    if (contextChoices && contextChoices.all_medical_condition) {
      medical_condition = contextChoices.all_medical_condition.map(item => {
        return { id: item.id, name: item.name };
      });
      medical_condition.unshift({
        id: 'newMedical',
        name: '+ new medical condition',
      });
      setMedicalConditionList(medical_condition || []);
    }

    if (contextChoices && contextChoices.allergy_source) {
      allergy_source = contextChoices.allergy_source.map(item => {
        return { id: item.id, name: item.name };
      });
      allergy_source.unshift({ id: 'newAllegry', name: '+ new allergy source' });
      setAllegrySourceList(allergy_source || []);
    }
  }, [contextChoices.allergy_source, contextChoices.allergy_source]);

  useEffect(() => {
    if (filterSuperAdmin) {
      setEmployeeDetailsMedicalInformationCreate(true);
      setEmployeeDetailsMedicalInformationUpdate(true);
      setEmployeeDetailsMedicalInformationRead(true);
      setEmployeeDetailsVaccinationsCreate(true);
      setEmployeeDetailsVaccinationsUpdate(true);
      setEmployeeDetailsVaccinationsRead(true);
      setEmployeeDetailsMedicationRead(true);
      setEmployeeDetailsMedicationCreate(true);
      setEmployeeDetailsMedicationUpdate(true);
      setEmployeeDetailsAllergiesAndIntolerancesRead(true);
      setEmployeeDetailsAllergiesAndIntolerancesUpdate(true);
      setEmployeeDetailsAllergiesAndIntolerancesCreate(true);
    } else if (filterAdmin) {
      setEmployeeDetailsMedicalInformationCreate(true);
      setEmployeeDetailsMedicalInformationUpdate(true);
      setEmployeeDetailsMedicalInformationRead(
        contextSubscription?.[CLIENT_MEDICAL_INFO]?.[WEB_PERMISSION]
      );
      setEmployeeDetailsVaccinationsCreate(true);
      setEmployeeDetailsVaccinationsUpdate(true);
      setEmployeeDetailsVaccinationsRead(
        contextSubscription?.[CLIENT_VACCINATIONS]?.[WEB_PERMISSION]
      );
      setEmployeeDetailsMedicationRead(
        contextSubscription?.[CLIENT_MEDICATION]?.[WEB_PERMISSION]
      );
      setEmployeeDetailsMedicationCreate(true);
      setEmployeeDetailsMedicationUpdate(true);
      setEmployeeDetailsAllergiesAndIntolerancesRead(
        contextSubscription?.[CLIENT_ALLERGIES_INTOLERANCES]?.[WEB_PERMISSION]
      );
      setEmployeeDetailsAllergiesAndIntolerancesUpdate(true);
      setEmployeeDetailsAllergiesAndIntolerancesCreate(true);
    } else {
      if (rightPermission[CLIENT_MEDICAL_INFO] !== undefined) {
        setEmployeeDetailsMedicalInformationCreate(
          rightPermission[CLIENT_MEDICAL_INFO][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_MEDICAL_INFO][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setEmployeeDetailsMedicalInformationUpdate(
          rightPermission[CLIENT_MEDICAL_INFO][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_MEDICAL_INFO][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setEmployeeDetailsMedicalInformationRead(
          contextSubscription?.[CLIENT_MEDICAL_INFO]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_MEDICAL_INFO][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rightPermission[CLIENT_MEDICAL_INFO][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ]
              && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
      }

      if (rightPermission[CLIENT_VACCINATIONS] !== undefined) {
        setEmployeeDetailsVaccinationsCreate(
          rightPermission[CLIENT_VACCINATIONS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_VACCINATIONS][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setEmployeeDetailsVaccinationsUpdate(
          rightPermission[CLIENT_VACCINATIONS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_VACCINATIONS][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setEmployeeDetailsVaccinationsRead(
          contextSubscription?.[CLIENT_VACCINATIONS]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_VACCINATIONS][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rightPermission[CLIENT_VACCINATIONS][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ]
              && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
      }

      if (rightPermission[CLIENT_MEDICATION]) {
        setEmployeeDetailsMedicationRead(
          contextSubscription?.[CLIENT_MEDICATION]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_MEDICATION][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rightPermission[CLIENT_MEDICATION][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ]
              && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setEmployeeDetailsMedicationCreate(
          rightPermission[CLIENT_MEDICATION][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_MEDICATION][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setEmployeeDetailsMedicationUpdate(
          rightPermission[CLIENT_MEDICATION][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_MEDICATION][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
      }
      if (rightPermission[CLIENT_ALLERGIES_INTOLERANCES] !== undefined) {
        setEmployeeDetailsAllergiesAndIntolerancesRead(
          contextSubscription?.[CLIENT_ALLERGIES_INTOLERANCES]?.[
          WEB_PERMISSION
          ] &&
          (rightPermission[CLIENT_ALLERGIES_INTOLERANCES][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] ||
            (rightPermission[CLIENT_ALLERGIES_INTOLERANCES][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ]
              && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setEmployeeDetailsAllergiesAndIntolerancesUpdate(
          rightPermission[CLIENT_ALLERGIES_INTOLERANCES][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_ALLERGIES_INTOLERANCES][WEB_PERMISSION][
            SELF_WEB_UPDATE_PERMISSION
          ]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setEmployeeDetailsAllergiesAndIntolerancesCreate(
          rightPermission[CLIENT_ALLERGIES_INTOLERANCES][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rightPermission[CLIENT_ALLERGIES_INTOLERANCES][WEB_PERMISSION][
            SELF_WEB_CREATE_PERMISSION
          ]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
      }
    }
  }, [filterAdmin, filterSuperAdmin, rightPermission, contextSubscription, contextRelativesClient]);
  useEffect(() => {
    if (!values.client_medical_condition) {
      values.client_medical_condition = [];
      setValues({ ...values });
    }
  }, []);
  useEffect(() => {
    if (selectedClientId || healthDet) {
      loadClientHealthDetails();
    } else {
      setModalLoading(false);
    }
  }, []);

  const handleSubmit = async (evt, triggerSave = true) => {
    evt.preventDefault()
    debugger
    if (values.client_medical_condition) {
      let medicalCondition = [];
      let data = values?.client_medical_condition?.map(name => {
        if (name.client_medical_condition === 'newMedical') {
          let newTemp = values?.client_medical_condition?.filter(
            name => { return name.new_medical_condition != null }
          );

          setDataCheck(medicalCondition);
          let newTem = newTemp.map((item) => item.new_medical_condition)
          medicalCondition = [...newTem]

        }
      });

      if ((values.emergency_info && values.emergency_info.length > 5000) || values.DoLS && values.DoLS.length > 5000) {
        setErrorMessage('This filed should be limited to 5000 characters or fewer')
        setShowError(true);
        return;
      } else {
        setErrorMessage('')
        setShowError(false);
      }

      if (errors.length) {
        setErrorMessage(
          'Some of the fields have invalid or empty values. Please review'
        );
        setShowError(true)
        setCheckRequires(true);
        return;
      }

      if (medicalCondition.length) {
        let arrIds = [];
        await setMedCondition(medicalCondition)
          .then(response => {
            if (response.status === 'SUCCESS') {
              setDataCheck(true);

              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Medication condition  created successfully',
              });


              onSuccess();
            } else {
              onError();
              toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `${response.message}`,
              });
            }
            if (response) {
              let data = values?.client_medical_condition?.forEach(dataname => {
                if (dataname.client_medical_condition == 'newMedical') {
                  let medicalNewIds =
                    response.data &&
                    response.data.find(
                      medicalData =>
                        medicalData.name == dataname.new_medical_condition
                    ).id;
                  delete dataname.client_medical_condition;
                  delete dataname.booleanValue;
                  delete dataname.new_medical_condition;
                  arrIds.push({
                    ...dataname,
                    client_medical_condition: medicalNewIds,
                  });
                } else {
                  delete dataname.booleanValue;
                  arrIds.push({ ...dataname });
                }
              });

              values.client_medical_condition = arrIds;
            }
          })

          .catch(res => {
            onError();
          });
      }
    }

  

    if (values['allergy_and_intolerances'] && values['allergy_and_intolerances'].length) {
      values['allergy_and_intolerances'] = values['allergy_and_intolerances']?.map((item, index) => {
        if (item.allergy_source == "newAllegry") {
          let allergyName = item.new_allergy_source;
          delete item.allergy_source;
          delete item.new_allergy_source;
          return { ...item, newAllergySource: allergyName }
        } else {
          return { ...item }
        }
      })
    }

    let body = {
      ...values,
    };
    if (!toggleExcProof && body.client_vaccination) {
      delete body.client_vaccination.proof_vaccination_exemption;
    }
    if (body.client_specific_communication_needs) {
      body.client_specific_communication_needs =
        body.client_specific_communication_needs.map(item => {
          return { client_specific_communication_needs: item.value };
        });
    }
    if (body.client_client_group) {
      body.client_client_group = body.client_client_group.map(item => {
        return { client_client_group: item.value };
      });
    }
    if (body.client_regulated_services) {
      body.client_regulated_services = body.client_regulated_services.map(
        item => {
          return { client_regulated_services: item.value };
        }
      );
    }
    if (body.client_user_type_description) {
      body.client_user_type_description = body.client_user_type_description.map(
        item => {
          return { client_user_type_description: item.value };
        }
      );
    }
    if (body.client_client_mobility) {
      body.client_client_mobility = body.client_client_mobility.map(item => {
        return { client_client_mobility: item.value };
      });
    }
    if (body.client_user_type_category) {
      delete body.client_user_type_category;
    }

    if (triggerSave) {
      setLoadingSave(true);
    }

    let value = [];
    // let clientId = '';
    // if (location.pathname.includes('/client/add')) {
    //   clientId = cliId;
    // } else {
    //   clientId = selectedClientId;
    // }
    setClientHealthDetails(body, selectedClientId)
      .then(response => {
        if (response.status === 400) {
          if (response.message) {
            value = response.message;
          } else {
            value = Object.keys(response)[0] + '-' + Object.values(response)[0];
          }
          throw value;
        }
        onSuccess(response);
        handleChangeChoices(client_health_details_choices);
      })
      .catch(res => {
        onError();
      });
  };

  const onSuccess = async response => {
    if (response?.status === 200 || response?.status === 201) {
      // let clientId = '';
      // if (location.pathname.includes('/client/add')) {
      //   clientId = cliId;
      // } else {
      //   clientId = selectedClientId;
      // }

      let healthId = response.message.id;
      // if employee id

      setValues({
        ...values,
        id: healthId,
      });

      setHealthDet(selectedClientId);
      setLoadingSave(false);
      setButtonIcon('');
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
    }
  };

  const onError = err => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);

  };
  const loadClientHealthDetails = async () => {
    let id = selectedClientId ? selectedClientId : healthDet;
    const response = await getClientHealthDetails(id);
    if (response.results.length > 0) {
      if (response.results[0].client_regulated_services) {
        response.results[0].client_regulated_services =
          response.results[0].client_regulated_services &&
          response.results[0].client_regulated_services.map(emp_Client => {
            return {
              value: emp_Client.inner_id,
              label: emp_Client.name,
            };
          });
      }
      if (response.results[0].client_client_group) {
        response.results[0].client_client_group =
          response.results[0].client_client_group &&
          response.results[0].client_client_group.map(emp_Client => {
            return {
              value: emp_Client.inner_id,
              label: emp_Client.name,
            };
          });
      }
      if (response.results[0].client_client_mobility) {
        response.results[0].client_client_mobility =
          response.results[0].client_client_mobility &&
          response.results[0].client_client_mobility.map(emp_Client => {
            return {
              value: emp_Client.inner_id,
              label: emp_Client.name,
            };
          });
      }
      if (response.results[0].client_specific_communication_needs) {
        response.results[0].client_specific_communication_needs =
          response.results[0].client_specific_communication_needs &&
          response.results[0].client_specific_communication_needs.map(
            emp_Client => {
              return {
                value: emp_Client.inner_id,
                label: emp_Client.name,
              };
            }
          );
      }
      if (response.results[0].client_medical_condition) {
        response.results[0].client_medical_condition =
          response.results[0].client_medical_condition &&
          response.results[0].client_medical_condition.map(emp_Client => {
            return {
              client_medical_condition: emp_Client.id,
              medical_desc: emp_Client.medical_desc,
            };
          });
      }
      if (response.results[0].client_user_type_description) {
        response.results[0].client_user_type_description =
          response.results[0].client_user_type_description &&
          response.results[0].client_user_type_description.map(emp_Client => {
            return {
              value: emp_Client.inner_id,
              label: emp_Client.name,
            };
          });
      }
      // if(response.results[0].client_user_type_category){
      //   response.results[0].client_user_type_category = response.results[0].client_user_type_category && response.results[0].client_user_type_category.map(emp_Client => {
      //     return (
      //       {
      //         value: emp_Client.inner_id,
      //         label: emp_Client.name
      //       }
      //     )
      //   })
      // }
      if (
        response.results[0].client_vaccination &&
        response.results[0].client_vaccination.proof_vaccination_exemption
      ) {
        setProofExcemption(
          response.results[0].client_vaccination.proof_vaccination_exemption
            .split('/')
            .reverse()[0]
        );
      }

      if (response.results[0].allergy_and_intolerances) {
        response.results[0].allergy_and_intolerances =
          response.results[0].allergy_and_intolerances.map((item) => {
            return {
              ...item,
              severity: item?.severity?.id,
              sign_symptoms: item?.sign_symptoms?.id,
              allergy_source: item?.allergy_source?.id,
              allergy_type: item?.allergy_type?.id,
            }
          })
      }
      setValues({ ...values, ...response?.results[0] });
    }

    setModalLoading(false);
  };
  const preferredHandleSelect = preferred => {
    if (preferred.description) {
      values.client_user_type_description = preferred.description;
      setValues({ ...values });
    }
    if (preferred.communication_needs) {
      values.client_specific_communication_needs =
        preferred.communication_needs;
      setValues({ ...values });
    }
    if (preferred.client_group) {
      values.client_client_group = preferred.client_group;
      setValues({ ...values });
    }
    if (preferred.regulated_services) {
      values.client_regulated_services = preferred.regulated_services;
      setValues({ ...values });
    }
    if (preferred.client_mobility) {
      values.client_client_mobility = preferred.client_mobility;
      setValues({ ...values });
    }
  };
  // const dropDownHandleSelect = item => {
  //   if(item.client_user_type_category){
  //     values.client_user_type_description=null
  //   }
  //   //recursive objects merge
  //   const result = _.merge(values, item);
  //   setValues({ ...result });
  // };
  const dropDownHandleSelect = item => {
    if (item.client_user_type_category) {
      values.client_user_type_description = null;
    }
    // recursive objects merge
    const result = _.merge(values, item);
    setValues({ ...result });
  };


  const handleUpload = ref => {
    ref.current.click();
  };

  //     const dropDownHandleAvoid=(item,nameSection,index,list_key)=>{
  //       values[nameSection][index][list_key]=item.avoid
  //       setValues({...values})
  // }
  const dropDownHandleAvoid = (item, nameSection, index, list_key) => {
    if (item.avoid == 'newMedical') {
      setNewMedical('booleanValue');
      setIndexMed(index);
      let booleanValue = 'booleanValue';
      values[nameSection][index][booleanValue] = true;
      values[nameSection][index][list_key] = item.avoid;
      setValues({ ...values });
    } else {
      let booleanValue = 'booleanValue';
      setNewMedical('booleanValue');
      values[nameSection][index][booleanValue] = false;
      values[nameSection][index][list_key] = item.avoid;
      setValues({ ...values });
    }
  };

  const deleteAvoid = (nameSection, index) => {
    let error = errors.filter(err => !err.includes(`${[nameSection]}[${index}]`))
    setFormErrors(error)
    values[nameSection].splice(index, 1);
    setValues({ ...values });
  };
  const addNewAvoid = nameSection => {
    values[nameSection] = [...values[nameSection], {}];
    setValues({ ...values });
  };
  const handleChange = async (event, key, keyName) => {
    const fileUploaded = event.target.files[0];
    const fileResult = await pickFile(event);
    const result = _.merge(values, { [key]: { [keyName]: fileResult.file } });
    setValues({ ...result });
    if (key === 'client_vaccination') {
      setProofExcemption(fileUploaded.name);
      setToggleExcProof(true);
    }
  };




  const covid_vaccination_part_1_date_hook = useInput(
    'client_vaccination.covid_vaccination_part_1_date'
  );

  const covid_vaccination_part_2_date_hook = useInput(
    'client_vaccination.covid_vaccination_part_2_date'
  );

  const covid_vaccination_part_3_date_hook = useInput(
    'client_vaccination.covid_vaccination_part_3_date'
  );

  const covid_vaccination_part_4_date_hook = useInput(
    'client_vaccination.covid_vaccination_part_4_date'
  );

  const vaccination_exemption_note_hook = useInput(
    'client_vaccination.vaccination_exemption_note'
  );
  const date_checked_hook = useInput('client_vaccination.date_checked');

  const last_flu_vaccination_date_hook = useInput(
    'client_vaccination.last_flu_vaccination_date'
  );
  const medication_collection_day_hook = useInput('medication_collection_day');
  const who_collects_medication_hook = useInput('who_collects_medication');
  const is_fully_covid_vaccinated_hook = useInput(
    'client_vaccination.is_fully_covid_vaccinated'
  );
  const allergy_notes_hook = useInput('allergy_notes');

  const addNewAllergies = nameSection => {
    values[nameSection] = values[nameSection] ? [...values[nameSection], {}] : [{}];
    setValues({ ...values });
  };
  const style = {
    fontSize: '0.7rem',
    margin: '0',
    color: 'black',
    fontWeight: '600'
  }
  return (
    <div>
      <Toast ref={toast} position="top-right" />

      <ComponentDynamic loading={modalLoading}>
        {(selectedClientId
          ? employeeDetailsMedicalInformationRead
          : employeeDetailsMedicalInformationCreate) && (
            <>
              <HealthInformation
                employeeDetailsMedicalInformationRead={
                  employeeDetailsMedicalInformationRead
                }
                useInput={useInput}
                choices={choices}
                values={values}
                dropDownHandleSelect={dropDownHandleSelect}
                preferredHandleSelect={preferredHandleSelect}
                errorMessage={errorMessage}
              />
              <div>
                {values.client_medical_condition &&
                  values.client_medical_condition.map((medical, indexMedical) => {
                    return (
                      <AvoidDetails
                        setTempCheck={setTempCheck}
                        values={values}
                        dropDownLabel={'Medical Condition'}
                        // dropDownList={choices.all_medical_condition}
                        dropDownList={medicalConditionList}
                        list_key={'client_medical_condition'}
                        dropDownHandleAvoid={dropDownHandleAvoid}
                        useInput={useInput}
                        medical={true}
                        nameSection={'client_medical_condition'}
                        index={indexMedical}
                        deleteAvoid={deleteAvoid}
                        newMedical={newMedical}
                        indexMed={indexMed}
                      />
                    );
                  })}
              </div>
              <PrimaryButtonForm
                className={'ms-3 mt-1 mb-2'}
                bgcolor="BLUE"
                minWidth="3rem"
                onClick={() => addNewAvoid('client_medical_condition')}>
                <span>{<BsFillPlusSquareFill />}Medical Condition</span>
              </PrimaryButtonForm>
            </>
          )}
        {(selectedClientId
          ? employeeDetailsAllergiesAndIntolerancesRead
          : employeeDetailsAllergiesAndIntolerancesCreate) &&
          <div>
            <TitleSection className={'mt-4'}>
              Allergies And Intolerances
            </TitleSection>

            {values?.allergy_and_intolerances &&
              values?.allergy_and_intolerances?.map((_, itemIndex) => {
                return (
                  <AllergiesAndIntolerances
                    useInput={useInput}
                    values={values}
                    setValues={setValues}
                    allegrySourceList={allegrySourceList}
                    choices={choices}
                    nameSection={'allergy_and_intolerances'}
                    index={itemIndex}
                    errors={errors}
                    setFormErrors={setFormErrors}
                  />
                )
              })
            }
            <PrimaryButtonForm
              className={'ms-3 mt-1 mb-2'}
              bgcolor="BLUE"
              minWidth="3rem"
              onClick={() => addNewAllergies('allergy_and_intolerances')}>
              <span>{<BsFillPlusSquareFill />}Allergies and Intolerances</span>
            </PrimaryButtonForm>

            <div className="w-100 mb-2">
              <small className="pe-1 " style={style}>
                Other Allergy Or Intolerance Notes
              </small>
              <InputTextArea
                type="text"
                id="other_allergy"
                label={'Other Allergy Or Intolerance Notes'}
                {...allergy_notes_hook}
                height={'4rem'}
              />
            </div>

          </div>
        }

        {
          (selectedClientId
            ? employeeDetailsVaccinationsRead
            : employeeDetailsVaccinationsCreate) && (
            <>
              <TitleSection className={'mt-3 mb-2'}>Vaccinations</TitleSection>
              <div className="d-flex mb-2">
                <div className="w-100 me-2">
                  <ToggleButton
                    label="Fully Covid Vaccinated"
                    useInput={is_fully_covid_vaccinated_hook}
                    id="jd_is_right_to_work"
                  />
                </div>
                <div className="w-100 me-2">
                  <DropdownSearchable
                    placeHolder={'Vaccination Country'}
                    options={choices.country}
                    selectedOption={
                      values.client_vaccination
                        ? values.client_vaccination.vaccination_country
                          ? values.client_vaccination.vaccination_country
                          : null
                        : null
                    }
                    onChange={value =>
                      dropDownHandleSelect({
                        client_vaccination: {
                          vaccination_country: value ? value : null,
                        },
                      })
                    }
                  />
                </div>
                <div className="w-100">
                  <DateSelector
                    labelTop="Covid Vaccination Date Part 1"
                    {...covid_vaccination_part_1_date_hook}
                  />
                </div>
              </div>
              <div className="d-flex mb-2">
                <div className="w-100 me-2">
                  <DateSelector
                    labelTop="Covid Vaccination Date Part 2"
                    {...covid_vaccination_part_2_date_hook}
                  />
                </div>
                <div className="w-100 me-2">
                  <DateSelector
                    labelTop="Covid Vaccination Date Booster 1"
                    {...covid_vaccination_part_3_date_hook}
                  />
                </div>
                <div className="w-100">
                  <DateSelector
                    labelTop="Covid Vaccination Date Booster 2"
                    {...covid_vaccination_part_4_date_hook}
                  />
                </div>
              </div>
              <div className="d-flex mb-1">
                <div className="w-100 me-2">
                  <DropdownSearchable
                    placeHolder={'Vaccination Exemption'}
                    options={choices.vaccination_exemption_type}
                    selectedOption={
                      values.client_vaccination
                        ? values.client_vaccination.vaccination_exemption_type
                          ? values.client_vaccination.vaccination_exemption_type
                          : null
                        : null
                    }
                    onChange={value =>
                      dropDownHandleSelect({
                        client_vaccination: {
                          vaccination_exemption_type: value ? value : null,
                        },
                      })
                    }
                  />
                </div>
                <div className="w-100 me-2">
                  <InputLabelTop
                    type="text"
                    id="jd_dbs_checked_by"
                    label={'Vaccination Exemption Note'}
                    {...vaccination_exemption_note_hook}
                    maxlength={255}
                  />
                </div>
                <div className="w-100 me-2">
                  <DateSelector labelTop="Date Checked" {...date_checked_hook} />
                </div>
                <div className="w-100">
                  <DropdownSearchable
                    placeHolder={'Checked By'}
                    options={employees}
                    selectedOption={
                      values.client_vaccination
                        ? values.client_vaccination.checked_by
                          ? values.client_vaccination.checked_by
                          : null
                        : null
                    }
                    onChange={value =>
                      dropDownHandleSelect({
                        client_vaccination: { checked_by: value ? value : null },
                      })
                    }
                  />
                </div>
              </div>

              <div className="d-flex mb-2">
                <div className="w-100 me-2">
                  <DropdownSearchable
                    placeHolder={'Flu Vaccination'}
                    options={choices.flu_vaccination}
                    selectedOption={
                      values.client_vaccination
                        ? values.client_vaccination.flu_vaccination
                          ? values.client_vaccination.flu_vaccination
                          : null
                        : null
                    }
                    onChange={value =>
                      dropDownHandleSelect({
                        client_vaccination: {
                          flu_vaccination: value ? value : null,
                        },
                      })
                    }
                  />
                </div>
                <div className="w-100">
                  <DateSelector
                    labelTop="Last Flu Vaccination Date"
                    {...last_flu_vaccination_date_hook}
                  />
                </div>
              </div>
              <div className="d-flex mb-2">
                <span className={'pb-0 pt-0'} htmlFor="exemption-proof">
                  Proof Of Exemption
                </span>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileExcemptionProof}
                  onChange={e =>
                    handleChange(
                      e,
                      'client_vaccination',
                      'proof_vaccination_exemption'
                    )
                  }
                />
                <PrimaryButtonForm
                  minWidth="2rem"
                  onClick={() => handleUpload(fileExcemptionProof)}
                  className={'ms-2 me-2'}>
                  <span className={''}>{'Choose File'}</span>
                </PrimaryButtonForm>
                <a
                  href={
                    values.client_vaccination &&
                    values.client_vaccination.proof_vaccination_exemption
                  }
                  style={{
                    pointerEvents:
                      proofExcemption === 'No File Chosen'
                        ? 'none'
                        : toggleExcProof
                          ? 'none'
                          : '',
                    color: '#696969',
                    fontStyle: 'italic',
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  classname={'ms-1 mt-2'}>
                  {proofExcemption}
                </a>
              </div>
            </>
          )
        }
        {
          (selectedClientId
            ? employeeDetailsMedicationRead
            : employeeDetailsMedicationCreate) && (
            <>
              <TitleSection className={'mt-3'}>Medication</TitleSection>
              <div className="d-flex mb-2">
                <div className="w-100 me-2">
                  <InputLabelTop
                    type="text"
                    id="med_collection_day"
                    label={'Medication Collection Day'}
                    {...medication_collection_day_hook}
                    maxlength={255}
                  />
                </div>
                <div className="w-100">
                  <InputLabelTop
                    type="text"
                    id="med_collection_person"
                    label={'Who Collects Medication'}
                    {...who_collects_medication_hook}
                    maxlength={255}
                  />
                </div>
              </div>
              <div
                className={
                  'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
                }>
                <div className={'mb-3'}>
                  {showError ? (
                    <ErrorMessage>
                      <label className={'mt-2'}>{errorMessage}</label>
                    </ErrorMessage>
                  ) : (
                    <Fragment />
                  )}
                </div>

                <div
                  className={
                    'd-flex justify-content-center justify-content-md-end'
                  }>
                  {/* { ( (subSectionPrefrencesCreate ||  subSectionPrefrencesUpdate || createPermissionPrefrences || updatePermissionPrefrences)  || (userStaff == true) ||(userClient ==='true')  )   &&   ( */}
                  {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) ) || ( ((subSectionPrefrencesCreate ||  subSectionPrefrencesUpdate || createPermissionPrefrences || updatePermissionPrefrences)  || (userStaff == true) ||(userClient ==='true')  &&  (userStaff ==='null' )  ) ) ||  ( (subSectionPrefrencesCreate ||  subSectionPrefrencesUpdate || createPermissionPrefrences || updatePermissionPrefrences)  || (userStaff == true) ||(userClient ==='true')  || (userStaff == true) ||(userClient ==='true')  ) ) && (  */}

                  <div className={'ms-0 ms-md-3 me-2'}>
                    <PrimaryButtonForm
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      className={'me-2'}
                      onClick={() => {
                        // setModalValue()
                        history.push('/client');
                      }}>
                      <span className={'me-2'}>{'Cancel'}</span>
                    </PrimaryButtonForm>
                    {(selectedClientId
                      ? permissionDetailsUpdateHealth
                      : true) && (
                        <PrimaryButtonForm
                          bgcolor="PURPLE_GRAY_500"
                          minWidth="6rem"
                          onClick={handleSubmit}>
                          <span className={'me-2'}>{textStatus}</span>
                          {!loadingSave ? (
                            buttonIcon
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                            />
                          )}
                        </PrimaryButtonForm>
                      )}
                  </div>
                </div>
              </div>
            </>
          )
        }
      </ComponentDynamic >
    </div >
  );
};

export default HealthDetails;
