import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React, {useState, useEffect} from 'react';
import {ModalContainer} from 'shared/styles/constants/tagsStyles';
import {PrimaryButtonForm, Toggle, ToggleLabel, ToggleWrapper} from 'shared/styles/buttons';
import {IoIosArrowDroprightCircle} from 'react-icons/io';
import './splitOverlap.css';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import {getUnassignedList} from 'utils/api/RotaShift';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';

const SplitOverlapModal = ({
  fullBind,
  shiftOverlapData,
  unassignedVisitList,
  setOverlapData,
  employees,
  loadingOverlap,
  loadingProceed,
  handleOverlapSubmit,
  disableButton,
  overlapMessage,
  currentModal,
  values,
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [splitOverallData, setSplitOverallData] = useState([]);
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Overlap Shifts',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);

  const BootstrapTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#6E6BFA',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#6E6BFA',
    },
  }));

  const unassignedList = (item, selected_index) => {
    let tem = [...shiftOverlapData]
    tem[selected_index].show = true;
    setOverlapData([...tem]);
  };

  const unassignedToggleClick=(item, selected_index)=>{
    let tem = [...shiftOverlapData]
    tem[selected_index].force_unassigned_visit = !tem[selected_index].force_unassigned_visit;
    setOverlapData([...tem]);
  }

  return (
    <ModalContainer max_width={'70%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'70%'}
        minWidthTitle="6.5rem"
        loading={false}>
        <div className={'pt-2 ps-3 pe-3 pb-3'}>
          <p style={{marginBottom: '5px'}}>{overlapMessage}</p>
          {shiftOverlapData &&
            shiftOverlapData.map((item, index) => {
              return (
                <>
                  <div className="shift__warp">
                    <div className='d-flex w-100'>
                      <div style={{display: 'flex'}}>
                        <div style={{marginRight: '3px'}}>
                          <IoIosArrowDroprightCircle />
                        </div>
                          <p
                            className="emp__det"
                            onClick={() => unassignedList(item, index)}>
                            {
                                employees?.find(
                                  employee => employee.id === item.employee_id
                                )?.full_name +
                                  item.overlap_data.map(itemData => {
                                    return `(${itemData.start_date} - ${itemData.end_date})`;
                                  })
                              //  employees && employees.find(employee=>employee.id===item.employee_id)?.name
                              //     +`(${item.start_date} - ${item.end_date})`
                            }
                          </p>
                        {/* </BootstrapTooltip> */}
                        </div>
                        <div style={{ display: "flex" ,justifyContent:"right", flex:'auto'  }}>
                        <div className='ms-3'>
                          <ToggleDiv>
                            <ToggleWrapper>
                              <Toggle
                                id="checkbox"
                                type="checkbox"
                                onClick={() => unassignedToggleClick(item, index)}
                              />
                              <ToggleLabel htmlFor="checkbox" />
                            </ToggleWrapper>
                            <div className="toggle-label ms-2  mb-3 pb-3 me-2">
                              <p>Force Unassigned</p>
                            </div>
                          </ToggleDiv>
                        </div>
                      <div style={{ marginTop: "-5px", marginLeft: "10px" }} className={'me-1'}>
                        <PrimaryButtonForm
                          minWidth="10rem"
                          disabled={disableButton.find(ele => ele === index + 1) ? true : false}
                           onClick={() => {
                            // splitAvailability(currentModal, true,item.employee_id,item.start_date,item.end_date,item.details,index);
                            handleOverlapSubmit(item, index, true);
                            //  closeModal();
                           }}
                        >
                          <span className={'me-1 ps-0.5 pe-0.5'}>Split the existing shift</span>
                          {!loadingOverlap.find(ele => ele === index + 1) ? (
                            null
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                            />
                          )}
                        </PrimaryButtonForm>
                      </div>
                      <div style={{ marginTop: "-5px" }} className={'me-1'}>


                        <PrimaryButtonForm
                          minWidth="10rem"
                          disabled={disableButton.find(ele => ele === index + 1) ? true : false}
                           onClick={() => {
                            handleOverlapSubmit(item, index, false);
                           }}

                         
                        >
                          <span className={'me-1 ps-0.5 pe-0.5'}>Fully override the existing shift</span>
                          {!loadingProceed.find(ele => ele === index + 1) ? (
                            null
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                            />
                          )}
                        </PrimaryButtonForm>
                     
                    </div>
                      </div>
                     
                
                      <div>
                        {item &&
                          item.unassigned_data &&
                          item.unassigned_data.map(val => {
                            return (
                              <p className="emp__det">
                                {`(${val.start_date} - ${val.end_date})`}
                              </p>
                              
                            );
                          })}
                           
                      </div>
                      
                      <div>
                       
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default SplitOverlapModal;
