import { Switch, TextField } from '@mui/material'
import React from 'react'

const TextInput = ({ setJson, json }) => {
    return (
        <div>

            <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", marginBottom: 10 }}>
                <div style={{ marginBottom: 10, display: "flex", flex: 1 }}>

                    <TextField
                        id="outlined-error"
                        label="Input Label *"
                        placeholder='Input label'
                        defaultValue={json.textInput.label}
                        style={{ marginRight: 10, flex: 1 }}
                        onChange={(event) => {
                            console.log(event.target.value)
                            setJson({
                                ...json, textInput: {
                                    ...json.textInput,
                                    label: event.target.value
                                }
                            })
                        }}
                    />
                    <TextField
                        id="outlined-error-helper-text"
                        label="Placeholder"
                        placeholder="placeholder"
                        defaultValue={json.textInput.placeholder}
                        style={{ flex: 1 }}
                        onChange={(event) => {
                            console.log(event.target.value)
                            setJson({
                                ...json, textInput: {
                                    ...json.textInput,
                                    placeholder: event.target.value
                                }
                            })
                        }}
                    />
                </div>

               <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.textInput.description}
                    placeholder="Description"
                    onChange={(event) => {
                        setJson({
                            ...json, textInput: {
                                ...json.textInput,
                                description: event.target.value
                            }
                        })
                    }}
                />
                 <TextField
                    id="outlined-multiline-static"
                    label="Instruction"
                    multiline
                    rows={3}
                    value={json.textInput.instruction}
                    placeholder="Instruction"
                    onChange={(event) => {
                        setJson({
                            ...json, textInput: {
                                ...json.textInput,
                                instruction: event.target.value
                            }
                        })
                    }}
                />
                <div className='d-flex'>
                    <div className='d-flex'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.textInput.required}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, textInput: {
                                                ...json.textInput,
                                                required: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ms-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.textInput.readOnly}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, textInput: {
                                                ...json.textInput,
                                                readOnly: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ms-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Show Expression'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.textInput.showCondition}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, textInput: {
                                                ...json.textInput,
                                                showCondition: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    
                </div>
               {json.textInput.showCondition &&
                <TextField
                    id="outlined-multiline-static"
                    label="Rule Expression"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.textInput.ruleExpression}
                    placeholder="Rule Expression"
                    onChange={(event) => {
                        setJson({
                            ...json, textInput: {
                                ...json.textInput,
                                ruleExpression: event.target.value
                            }
                        })
                    }}
                />}
            </div>
        </div>

    )
}

export default TextInput;
