import { apiURL } from '../env';
import { get, post, deleteFetch } from './Api';
import { getTenant } from '../localStorage/token';
import { methods } from 'utils/constants/api';


const { PATCH , POST} = methods

const getBaseUrl = () => {
    let tenant = getTenant();
    return `${apiURL}/${tenant}/api/v1`;
};

export const getNotificationSettings = async (employeeId) => {
    const baseUrl = getBaseUrl();
    const url = employeeId ?
        `${baseUrl}/employees/${employeeId}/notification/`
        : `${baseUrl}/core/push-notification/notification-setting/?limit=100&offset=0`
// http://localhost:8000/org/bill-test-org-1/api/v1/employees/67e13de8-d78e-4dca-bb3e-fe5b5714142e/notification/ ---- URL For Employee section

    return await get(url)
}

export const getNotificationSettingsSections = async () => {
    const baseUrl = getBaseUrl();
    const url = `${baseUrl}/core/push-notification/notification-section/?limit=100&offset=0`
    // http://localhost:8000/org/bill-test-org-1/api/v1/core/push-notification/notification-section/ ---- URL For Employee section
    return await get(url)
}

export const updateNotificationSettings = async (value) => {
    const baseUrl = getBaseUrl();
    const method = PATCH;
    const url = `${baseUrl}/core/push-notification/notification-setting/update_notification_setting/`
    const body = value

    return await post(url, body, method)
}

export const updateEmployeeNotification = async (value, employeeId) => {
    const baseUrl = getBaseUrl();
    const method = value.id ? PATCH : POST;
    const url = value.id
        ? `${baseUrl}/employees/${employeeId}/notification/${value.id}/`
        : `${baseUrl}/employees/${employeeId}/notification/`;
    const body = value

    return await post(url, body, method)
}

//http://localhost:8000/org/bill-test-org-1/api/v1/employees/67e13de8-d78e-4dca-bb3e-fe5b5714142e/notification/ea2ca480-4d64-405e-b497-2712a2f4f017/  

export const updateNotificationSettingAll = async (value) => {
   const baseUrl = getBaseUrl();
   const method = PATCH;
   const url = `${baseUrl}/core/push-notification/notification-setting/update_all/`
   const body = value;

     console.log("all update",url, body, method)
   return post(url, body, method)
}


export const updateAllNotification = async (value, employeeId) => {
    const baseUrl = getBaseUrl();
    const method = PATCH;
    const url = `${baseUrl}/employees/${employeeId}/notification/bulkUpdate/`
    //http://localhost:8000/org/bill-test-org-1/api/v1/employees/90bb5325-e9b5-4808-ba08-ad0ba0bcb104/notification/bulkUpdate/
    const body = value;
 
      console.log("all update",url, body, method)
    return post(url, body, method)
 }