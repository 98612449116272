import DateSelector from 'components/SharedComponents/DateSelector';
import CustomYearInput from 'components/SharedComponents/DateSelector/CustomYearInput';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { IconWrapper } from 'components/SharedComponents/Table/styles';
import React from 'react'
import { MdEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { PURPLE, RED } from 'shared/styles/constants/colors';

function AddForm({
    values,
    setValues,
    useInput,
    toast,
    choices,
    index,
    errorMessage,
    addNewAvoid,
    updatePermissionTax,
    deletePermission,
    getTaxType,
    setModalDeleteValue,
    setSelectedId
}) {
    const BRANCH_TAX = 'tax_branch';


    const dropDownHandleSelect = (item, nameSection, index) => {
        values[BRANCH_TAX][index][nameSection] = item[nameSection];
        setValues({ ...values });
    };


    const deleteAddRate = (id) => {
        setModalDeleteValue(true)
        setSelectedId(id)
    };

    return (
        <div>
            <>
                <div className="d-flex flex-column mt-2" style={{ marginLeft: '15px' }}>
                    <div className="d-flex mb-2">
                        {(!updatePermissionTax && !deletePermission)
                            ?
                            <div style={{ width: "100px" }}></div>
                            :
                            <>
                                {updatePermissionTax &&
                                    <IconWrapper
                                        Purple style={{
                                            minWidth: "25px",
                                            marginRight: "4px",
                                            color: PURPLE
                                        }}
                                        onClick={() => {
                                            addNewAvoid(values[BRANCH_TAX][index].id, index, values[BRANCH_TAX][index]);
                                        }}>
                                        <MdEdit />
                                    </IconWrapper>}
                                {deletePermission &&
                                    <IconWrapper
                                        Red
                                        style={{
                                            minWidth: "25px",
                                            color: RED
                                        }}
                                        onClick={() => {
                                            deleteAddRate(values[BRANCH_TAX][index].id);
                                        }}>
                                        <RiDeleteBinLine />
                                    </IconWrapper>}
                            </>}
                        {/* {values.tax_branch?.length >= 1 && (
                            <CloseButtonWrapper
                                onClick={() => {
                                    deleteAddRate(values, index);
                                }}>
                                {TIMES}
                            </CloseButtonWrapper>
                        )} */}

                        <div
                            className="w-100 me-2"
                            style={{ marginLeft: '8px', marginRight: '10px' }}>
                            <DropdownSearchable
                                // required
                                disabled={true}
                                placeHolder={'Tax Type'}
                                options={[
                                    { id: 'is_visit', name: 'Visit' },
                                    { id: 'is_travel', name: 'Travel' },
                                    { id: 'is_distance', name: 'Distance' },
                                    { id: 'is_expenses', name: 'Expenses' }
                                ]}
                                selectedOption={getTaxType(values[BRANCH_TAX][index])}
                                {...useInput(
                                    `tax_branch[${index}].tax_type`,
                                    // 'isRequired'
                                )}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        {
                                            tax_type: value ? value : null,
                                        },
                                        'tax_type',
                                        index
                                    )
                                }
                            />
                        </div>
                        <div
                            className="w-100 me-2"
                            style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <InputLabelTop
                                // margin={'5px'}
                                readOnly
                                label='Discount Percentage'
                                type="number"
                                id={`discount_percentage`}
                                {...useInput(`tax_branch[${index}].discount_percentage`)}
                                maxlength={100}
                            />
                        </div>
                        <div
                            className="w-100 me-2"
                            style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <InputLabelTop
                                // margin={'5px'}
                                readOnly
                                label='Discount Amount'
                                type="number"
                                id={`discount_amount`}
                                {...useInput(`tax_branch[${index}].discount_amount`)}
                                maxlength={100}
                            />
                        </div>
                        <div
                            className="w-100"
                            style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <InputLabelTop
                                // margin={'5px'}
                                readOnly
                                label='Tax Amount'
                                type="number"
                                id={`tax_amount`}
                                {...useInput(`tax_branch[${index}].tax_amount`)}
                                maxlength={100}
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column mt-2">
                    <div className="d-flex mb-2">
                        <div style={{ width: "10rem" }} />
                        <div
                            className="w-100 me-2"
                            style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <InputLabelTop
                                // margin={'5px'}
                                readOnly
                                label='Tax Percentage'
                                type="number"
                                id={`tax_percentage`}
                                {...useInput(`tax_branch[${index}].tax_percentage`)}
                                maxlength={100}
                            />
                        </div>
                        <div
                            className="w-100 me-2"
                            style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <DropdownSearchable
                                // required
                                {...useInput(
                                    `tax_branch[${index}].service_category`,
                                    // 'isRequired'
                                )}
                                disabled={true}
                                placeHolder={'Service Category'}
                                options={choices.service_category || []}
                                selectedOption={values[BRANCH_TAX][index].service_category}
                                onChange={value =>
                                    dropDownHandleSelect({
                                        service_category: value ? value : null,
                                    },
                                        'service_category',
                                        index
                                    )
                                }
                            />

                        </div>

                        <div
                            className="w-100 me-2"
                            style={{ marginLeft: '10px', marginRight: '10px' }}>

                            <DateSelector
                                disabled
                                labelTop="Effective from"
                                {...useInput(`tax_branch[${index}].effective_date_from`,
                                    //  'isRequired'
                                )}

                            />
                        </div>
                        <div
                            className="w-100 "
                            style={{ marginLeft: '10px', marginRight: '11px' }}>

                            <DateSelector
                                disabled
                                // isRequired
                                labelTop="Effective to"
                                {...useInput(`tax_branch[${index}].effective_date_to`)}

                            />
                        </div>
                    </div>
                </div>

                <div
                    className={
                        'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
                    }>
                    {/* <div
                        className={'mb-3'}
                        style={{ display: 'flex', justifyContent: 'right' }}>
                        {errorMessage ? (
                            <ErrorMessage>
                                <label className={'mt-2'}>{errorMessage}</label>
                            </ErrorMessage>
                        ) : (
                            <Fragment />
                        )}
                    </div> */}

                    <div
                        className={
                            'd-flex justify-content-center justify-content-md-end'
                        }></div>
                </div>
            </>
        </div>
    )
}

export default AddForm