import React, {useState, useEffect, Fragment} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {
  ModalContainer,
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  Title,
  HeaderVisits,
} from 'shared/styles/constants/tagsStyles';
import {RiDeleteBinLine} from 'react-icons/ri';
import {
  ShiftExistingShift,
  ShiftExistingAvailable,
  ShiftExistingUnavailable,
} from '../RotaValidations';
import {
  SHIFT_EXISTING_SHIFT,
  SHIFT_EXISTING_AVAILABLE,
  SHIFT_EXISTING_UNAVAILABLE,
} from 'utils/constants/rotaShift';
import {format} from 'date-fns';
import {dateStringToDate} from 'shared/methods';
import PropTypes from 'prop-types';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {getTableData} from 'utils/api/RotaShift';
import {LARGE_COL, SMALL_COL, MEDIUM_COL} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import Table from 'components/SharedComponents/Table';
import { getEmployeeBranchAccess } from 'utils/api/EmployeeApi';
import moment from 'moment';

const headerColumns = [
  {
    label: 'Clock in Time',
    name: 'clock_in_time',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock in Location',
    name: 'clock_in_location',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock Out Time',
    name: 'clock_out_time',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Clock Out Location',
    name: 'clock_out_location',  
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Action',
    name: 'action',
    status: 0,
   className: 'grow-mobile  ',
    max_width: SMALL_COL,
  },
  
];

const AssignShiftModal = ({
  fullBind,
  dropDownHandleSelect,
  patterns,
  employees,
  useInput,
  values,
  setValues = () => {},
  readOnly,
  dateMessage,
  dateError,
  buttonIcon,
  loadingSave,
  textStatus,
  errorMessage,
  addShift,
  deleteShiftDetail,
  employeesHandleSelect,
  isDateAssigned,
  closeModal,
  typeOfMessage,
  choices,
  filterEmployee,
  filterClient,
  userStaff,
  filterSuperAdmin,
  filterAdmin,
  display,
  regularshiftName,
  setDisplay,
  detailId,
  createAssignShift,
  createSelfAssignShift,
  travelRate
}) => {

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [empAccessBranchs, setEmpAccessBranchs] = useState([])
  const branch_hook = useInput(`branch_id`, 'isRequired')
  const employee_hook = useInput(`employee`, 'isRequired')
  const pattern_hook = useInput(`pattern`, 'isRequired')

  const [data,setData]=useState([])
  useEffect(() => {
    setAddFullBind({...addFullBind, title: 'Assign shift'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);


  useEffect(()=>{
    if(values.shiftId){
    loadTableData()
   }
   
  },[values.shiftId])

  const addValidationMessage = () => {
    if (isDateAssigned) {
      switch (typeOfMessage) {
        case SHIFT_EXISTING_SHIFT:
          return (
            <ShiftExistingShift
              startDate={
                values.start_day
                  ? format(dateStringToDate(values.start_day), 'd MMM yyyy')
                  : null
              }
              endDate={
                values.end_day
                  ? format(dateStringToDate(values.end_day), 'd MMM yyyy')
                  : null
              }
            />
          );
        case SHIFT_EXISTING_AVAILABLE:
          return (
            <ShiftExistingAvailable
              startDate={
                values.start_day
                  ? format(dateStringToDate(values.start_day), 'd MMM yyyy')
                  : null
              }
              endDate={
                values.end_day
                  ? format(dateStringToDate(values.end_day), 'd MMM yyyy')
                  : null
              }
            />
          );
        case SHIFT_EXISTING_UNAVAILABLE:
          return (
            <ShiftExistingUnavailable
              startDate={
                values.start_day
                  ? format(dateStringToDate(values.start_day), 'd MMM yyyy')
                  : null
              }
              endDate={
                values.end_day
                  ? format(dateStringToDate(values.end_day), 'd MMM yyyy')
                  : null
              }
            />
          );
        default:
          return <Fragment />;
      }
    }
  };


  const loadTableData=async()=>{
    const response= await getTableData(detailId, true);
    setData(response.data ||[])
  
  }


  const getEmployeeBranches = async () => {
    try {
      if (values.employee && typeof values.employee == "string") {
        const res = await getEmployeeBranchAccess(values.employee)
        setEmpAccessBranchs(choices?.branch?.filter(el => res?.employee_access_branches?.includes(el.id)))
      } else if (values?.employee && typeof values?.employee == "object" && values?.employee?.length === 1) {
        const res = await getEmployeeBranchAccess(values?.employee[0]?.id)
        setEmpAccessBranchs(choices?.branch?.filter(el => res?.employee_access_branches?.includes(el.id)))
      } else if (typeof values.employee == "object" && values.employee.length > 1) {
        setEmpAccessBranchs(choices?.branch)
      } else {
        setEmpAccessBranchs([])
        values.branch_id = null
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    if(values.employee){
    getEmployeeBranches()
    }else{
      setEmpAccessBranchs([])
      values.branch_id = null
    }
  }, [values.employee])

  // To validated maximum date range
  const getStartEndValidation = (date, type) => {
    if (type == "start") {
      return moment(date).subtract(1, "month").format("YYYY-MM-DD")
    } else {
      return moment(date).add(1, "month").format("YYYY-MM-DD")
    }
  }
  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={false}>
        <div className={'pt-2 ps-3 pe-3 pb-3'}>
          { createAssignShift &&
          <FlexGrid3Item className="d-flex">
             <div className={'w-100 me-1'}>
            <DropdownSearchable
              isMulti={true}
              placeHolder={'Select employees'}
              required
              {...employee_hook}
              disabled={readOnly}
              options={employees}
              selectedOptionsProps={values.employee}
              selectedOption={values.employee}
              onChange={value =>{
                employeesHandleSelect({employee: value ? value : null})
              }}
            />
            </div>
            <div className={'w-100 ms-1'}>
              <DropdownSearchable
                placeHolder={'Select Branch'}
                required
                {...branch_hook}
                disabled={false}
                options={empAccessBranchs || []}
                selectedOption={values?.branch_id}
                onChange={value =>
                  employeesHandleSelect({ branch_id: value ? value : null })
                }
              />
              </div>   
          </FlexGrid3Item>
}
          <FlexGrid3Container className="mt-2">
            <FlexGrid3Item className="pe-1 mb-2">
        {display ?  
              <DropdownSearchable
                placeHolder={'Shift Pattern Name'}
                required
                {...pattern_hook}
                disabled={readOnly}
                options={patterns}
                selectedOption={values.pattern}
                onChange={value =>
                  dropDownHandleSelect({pattern: value ? value : null})
                }
              />
              :
             <InputLabelTop
             disabled={readOnly}
              type="text"
              id="regualar_shift_details"
              label={'Regualar Shift Details)'}
              value={regularshiftName}
            /> 
              }      

            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
              <DateSelector
                isRequired
                disabled={readOnly}
                minDate={values.end_day && getStartEndValidation(values.end_day, "start")}
                errorMessage={dateMessage}
                labelTop="Start day"
                data-error={dateError}
                {...useInput('start_day', 'isRequired')}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
              <DateSelector
                isRequired
                disabled={readOnly}
                maxDate={values.start_day && getStartEndValidation(values.start_day, "")}
                minDate={values.start_day && moment(values.start_day).format("YYYY-MM-DD")}
                errorMessage={dateMessage}
                labelTop="End day"
                data-error={dateError}
                {...useInput('end_day', 'isRequired')}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-0 pe-1">
              <DropdownSearchable
                placeHolder={'Travel method'}
                disabled={readOnly}
                options={choices.travel_type}
                selectedOption={values.travel_mode}
                onChange={value =>
                  dropDownHandleSelect({travel_mode: value ? value : null})
                }
              />
            </FlexGrid3Item>
            {/* <FlexGrid3Item>
              <DropdownSearchable
                disabled={readOnly}
                placeHolder={'Travel Rate'}
                options={travelRate}
                selectedOption={values.travel_rate}
                onChange={value =>
                  dropDownHandleSelect({
                    travel_rate: value ? value : null,
                  })
                }
              />
            </FlexGrid3Item> */}
          </FlexGrid3Container>

          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ms-0 ms-md-3 me-2'}>
                {values.idSelected ? (
                  <PrimaryButtonForm
                    bgcolor="ERR_COLOR"
                    minWidth="9rem"
                    onClick={() => {
                      deleteShiftDetail();
                    }}>
                    <span className={'me-2'}>{'DELETE'}</span>
                    {!loadingSave ? (
                      <RiDeleteBinLine />
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                ) : (
                  <PrimaryButtonForm
                    minWidth="9rem"
                    disabled={isDateAssigned}
                    onClick={() => addShift()}>
                    <span className={'me-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                )}
              </div>
            </div>
          </div>

          {addValidationMessage()}
          {/* {!isDateAssigned ? (
            <Fragment />
          ) : (
            <div className={'d-flex justify-content-center mt-3'}>
              <div className={'me-4'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={() => {
                    addShift(true);
                    closeModal();
                  }}>
                  <span className={'me-2 ps-3 pe-3'}>YES, PROCEED</span>
                </PrimaryButtonForm>
              </div>
              <div className={'ms-2'}>
                <PrimaryButtonForm
                  bgcolor={'PURPLE_GRAY_500'}
                  minWidth="6rem"
                  onClick={closeModal}>
                  <span className={'ps-4 pe-4'}>NO, CANCEL</span>
                </PrimaryButtonForm>
              </div>
            </div>
          )} */}
        </div>

        {data.length >0 ?   (
        <div    >  
     
        <div  className={'ms-1 me-1 mb-2 mt-1'}  >   
        <Title  >Time and location  data</Title>    
          </div>
   
          <Table
        headerColumns={headerColumns}
        headerPadding={false}
        noEditOption
        fontSize="0.75rem"
        // style={{cursor:pointer}}
        // callBackQuerySort={setQuerySort}
        >

        <div>
        {data?.map( (list,index) =>{

const  clockInDate =   list.clock_in_date ? list.clock_in_date :'';
const  clockInTime = list.clock_in_time ? list.clock_in_time :'';
const  clockInTimeAndDate = clockInDate + ' , ' + clockInTime;

const  clockOutDate = list.clock_out_date ? list.clock_out_date : '';
const  clockOutTime = list.clock_out_time ? list.clock_out_time :'';
const  clockOutTimeAndDate = clockOutDate + ', ' + clockOutTime;


            return (
              <div key={index}>
                <Row 
                bgColor Shadow
              //  cursor={pointer}
              NoShadow
              // mb-1
           
                >
                <ColsGrouper
                    className="d-flex"
                  >
                  <Col
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center              
                    >
                   {clockInTimeAndDate}
                  </Col>
                  <Col
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                    >
     { list.actual_start_location ? list.actual_start_location  :''}
                  </Col>
                  <Col
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                    >
                 {
           clockOutTimeAndDate          
                    }
                  </Col>
                  <Col
                    max_width={LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize               
                    Center
                    // Left
                    >
    { list.actual_end_location ? list.actual_end_location  :''}
                  </Col>
                  <Col
                    max_width={MEDIUM_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    Center
                    className={'ms-0 me-0 ps-0 pe-0'}
                    // Left
                    >
                   {list && list.type && list.type }
                  </Col>
                  </ColsGrouper>
                </Row>
                <div className=" mb-1" />
              </div>
            );
          })}
         
        </div>
      </Table>

      </div>
         ) : null}

      </ModalDynamic>
    </ModalContainer>
  );
};

AssignShiftModal.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  fullBind: PropTypes.object.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  patterns: PropTypes.array,
  employees: PropTypes.array,
  useInput: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  dateMessage: PropTypes.string.isRequired,
  dateError: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  textStatus: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  addShift: PropTypes.func.isRequired,
  deleteShiftDetail: PropTypes.func.isRequired,
  employeesHandleSelect: PropTypes.func.isRequired,
  isDateAssigned: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  typeOfMessage: PropTypes.string.isRequired,
};

AssignShiftModal.defaultProps = {
  patterns: [],
  employees: [],
};

export default AssignShiftModal;
