import React, {useEffect, useState, useRef, useContext} from 'react';

import {
  LARGE_COL,
  SMALL_COL,
  MEDIUM_COL,
  EX_SMALL_COL4,
  EX_VERY_LARGE_COL,
  VERY_EX_SMALL_COL_CHECKBOX,
} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {useForm} from 'hooks/FormHook';
import {useModal} from 'hooks/ModalHook';
import {getChoices} from 'utils/api/CoreApi';
import {EmploymentTypesContainer} from 'components/DashboardComponents/Navbar/styles';
import {downloadAbsenceList, getAbsenceList} from 'utils/api/EmployeeApi';
import queryString from 'query-string';
import DateSelector from 'components/SharedComponents/DateSelector';
import {Fragment} from 'react';
import HistoryModal from './HistoryModal';
import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper,
} from 'components/SharedComponents/Table/styles';
import {
  HeaderVisits,
  Title,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
  SearchIcon,
  SearchAddContainer,
  SearchContainer,
  PublishButton,
} from 'shared/styles/constants/tagsStyles';
import {RiDeleteBinLine} from 'react-icons/ri';
import {PrimaryButton, PrimaryButtonForm} from 'shared/styles/buttons';
import AbsenceApproval from './AbsenceApproval';
import {
  ABSENCE_LIST,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
} from 'utils/constants/permissions';
import {CloseButtonWrapper} from 'shared/styles/constants/tagsStyles';
import {PLUS, TIMES} from 'utils/constants/icons';
import AddModal from 'components/DashboardComponents/Team/AbsenceHistoryList/AddModal';
import {getEmployees, deleteAbsenceListData} from 'utils/api/EmployeeApi';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import Fade from 'react-reveal/Fade';
import {GrSearch} from 'react-icons/gr';
import {AppContext} from 'context';
import {BranchItemsContext} from 'context';
import {useHistory, useLocation} from 'react-router-dom';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {ItemContainer} from 'components/SharedComponents/ProgressInformation/styles';
import {DOWNLOAD} from 'utils/constants/icons';
import moment from 'moment';
import {team_history_overview_choices} from 'utils/choiceConstant';
import { Toast } from 'primereact/toast';
import { NoPermissionContainer } from '../../AddModal/styles';
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles';
import ContextMenu, { useContextMenu } from 'components/SharedComponents/ContextMenu/ContextMenu';

const AbsenceHistoryList = ({
  newDatas,
  readPermissionAbsenceHistory,
  updatePermissionAbsenceHistory,

  deletePermissionAbsenceHistory,

  createPermissionAbsenceHistory,
}) => {
  const {
    
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);

  const { values, setValues, useInput } = useForm({
    // ordering: 'ee.full_name',
    absence_ids: [],
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    limitperpage : PAGE_SIZE_RENDER
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const toast = useRef(null)
  const [absenceList, setAbsenceList] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [branch, setBranch] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [runRouteData, setRunRouteData] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [employees, setEmployees] = useState([]);
  const [employeeFilter, setEmployeeFilter] = useState([]);
  const [absenceFilter, setAbsenceFilter] = useState([]);
  const [dateFromFilter, setDateFromFilter] = useState([]);
  const [dateToFilter, setDateToFilter] = useState([]);
  const [absenceListRowsId, setAbsenceListRowsId] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const history = useHistory();
  const [checkedRowsEmp, setCheckedRowsEmp] = useState([]);
  const [checkedIdArr, setCheckedIdArr] = useState([]);
  const [checkedValue, setCheckedValue] = useState(true)

  const {
    contextChoices,
    contextPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    handleGetChoices,
  } = useContext(AppContext);

  const {branchData} = useContext(BranchItemsContext);
  const {headerFilterClearValue} = useContext(BranchItemsContext);
  const [downloadDisable, SetDownloadDisable] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [events, setEvents] = useState([]);
  const [deleteId, setDeleteId] = useState([]);
  const [loadingModal, setLoadingModal] = useState(true);
  const [dataIndex, setDataIndex] = useState();
  const mountedRef = useRef(true);
  const [absence_type, setAbsenceData] = useState([]);
  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
  const {setValue: setAbsenceModal, bind: bindAbsenceModal} = useModal(false);
  useEffect(() => {

    handleGetChoices(team_history_overview_choices);
  }, []);
  useEffect(() => {
    const roles = contextPermissions;

    let rolePermissions = {};

    if (contextIsAdmin || contextIsSuperAdmin) {
      setDeletePermissions(true);
    } else {
      if (roles.length > 0) {
        roles.forEach(item => {
          if (item?.section?.name === ABSENCE_LIST) {
            rolePermissions[ABSENCE_LIST] = {};
            rolePermissions[ABSENCE_LIST][WEB_PERMISSION] = {};
            rolePermissions[ABSENCE_LIST][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[ABSENCE_LIST][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[ABSENCE_LIST][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
          }
        });

        setDeletePermissions(
          rolePermissions[ABSENCE_LIST][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextIsAdmin, contextIsSuperAdmin]);
  const {
    setValue: setAbsenceModalValue,
    value: absenceModalValue,
    setTitle: absenceTitle,
    fullBind: absenceBind,
  } = useModal(false);
  const headerColumns = [
    {
      label: 1,
      checkbox: true,
      name: 'checkbox',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_SMALL_COL4,
    },
    {
      label: ' EMPLOYEE NAME',
      name: 'ee.full_name',
      status: 0,
      className: 'grow-mobile',
      max_width: MEDIUM_COL,
    },
    {
      label: 'ABSENCE TYPE',
      name: 'ccat.name',
      status: 0,
      className: 'grow-mobile',
      max_width: MEDIUM_COL,
    },

    {
      label: 'ABSENCE REASON',
      name: 'ccsr.name',
      status: 0,
      className: 'grow-mobile',
      max_width: MEDIUM_COL,
    },

    {
      label: 'ABSENCE START',
      name: 'start_date',
      status: 0,
      className: 'grow-mobile  sm-hidden',
      max_width: EX_VERY_LARGE_COL,
    },

    {
      label: 'ABSENCE END ',
      name: 'end_date',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: EX_VERY_LARGE_COL,
    },

    {
      label: 'ABSENCE DURATION (Hours)',
      name: 'absence_hours',
      status: 0,
      className: 'grow-mobile  ',
      max_width: SMALL_COL,
    },
    {
      label: 'ABSENCE DURATION (DAYS)',
      name: 'absence_days',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: MEDIUM_COL,
    },

    {
      label: 'Status',
      name: 'status',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: EX_VERY_LARGE_COL,
    },

    {
      label: 'Authorisation Comments',
      name: 'authorisation_comments',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: EX_VERY_LARGE_COL,
    },
    {
      label: '',
      name: '',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: VERY_EX_SMALL_COL_CHECKBOX,
    },
  ];

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };

  // useEffect(() => {
  //   return () => {
  //     mountedRef.current = false;
  //   };
  // }, []);

  useEffect(() => {
    setRunRouteData(
      contextChoices &&
        contextChoices.run_routes &&
        contextChoices.run_routes.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setBranch(
      contextChoices &&
        contextChoices.branch &&
        contextChoices.branch.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setEmployees(
      contextChoices &&
        contextChoices.employee_listing &&
        contextChoices.employee_listing.map(y => {
          return {id: y && y.id, name: y && y.full_name};
        })
    );
    setAbsenceData(
      contextChoices &&
        contextChoices.absence_type &&
        contextChoices.absence_type.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
  }, [contextChoices]);


  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDatas, values.limitperpage, branchData]);
  const startDate = useInput('start_date');
  const endDate = useInput('end_date');

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };

  const search = () => {

    if(!values.start_date && values.end_date){
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Please select Date From first!`,
      });
      return ;
    }
    if(new Date(values.start_date) > new Date(values.end_date)){
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Date From should be greater than Date To`,
      });
       return ;
    }
    setLoadingModal(true);
    const pageToSearch = getPageToSearchParam(newDatas, setCurrentPage);
    getAbsenceList(
      {...branchData, ...values, ...pageToSearch},
      values.limitperpage
    ).then(response => {
      if (!mountedRef.current) return null;
      setPages(Math.ceil(response.count / values.limitperpage));

      setAbsenceList(response.data || []);
    }).finally(()=>{
      setLoadingModal(false)
    })
  };

  const setRunroutefilter = item => {
    setRunRoutesFilter([...item]);
  };

  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };

  const setEmployeefilter = item => {
    setEmployeeFilter([...item]);
  };
  const setAbsencefilter = item => {
    setAbsenceFilter([...item]);
  };

  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const handleRowClick = (id, index) => {
    setDataIndex(index);
    setIdSelected(id);
    setTitle('Absence  History');
    handleModal();
  };

  const handleDownlaod = () => {
      setLoadingButton(true);
      SetDownloadDisable(true);
    const pageToSearch = getPageToSearchParam(newDatas);
    let fileName =
      'Absence_list_' +
      moment(values.start_date).format('YYYY-MM-DD') +
      '_' +
      moment(values.end_date).format('YYYY-MM-DD');

    downloadAbsenceList(
      {...branchData, ...values, ...pageToSearch},
      values.limitperpage,
      values.start_date,
      values.end_date,
      values.absence_type
    )
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          setLoadingButton(false);
          SetDownloadDisable(false);
        });
      })
      .catch(error => {
        setLoadingButton(false);
        SetDownloadDisable(false);
      })
  };
  const dropDownHandleSelect = item => {
   
    setValues({...values, ...item});
  };

  const hitSearch = value => {
    values.search = value;
    search();
  };

  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d);
    };
  };

  const debounceOnchange = useRef(debounce(hitSearch, 300));
  const keyPressed = e => {
    setSearchKeyword(e.target.value);
    debounceOnchange.current(e.target.value);
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(newDatas);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/absence-history-list',
      search: stringified,
    });
    values.limitperpage = item[namesection];
    setValues({...values});
  };

  const handleSelectChange = (e, item) => {
    if (e.target.checked) {
        setCheckedRows([...checkedRows, item.id])
    } else {
        let index = checkedRows.indexOf(item.id)
        if (index != -1) {
            let tempCheckedRows = [...checkedRows]
            tempCheckedRows.splice(index, 1)
            setCheckedRows([...tempCheckedRows])
        }
    }
}

console.log(checkedRows,checkedRows,"checkedRows")
  const deleteDeatils = () => {
    const listIds = selectedElId?[selectedElId]:checkedRows;
    // what is this ddeleteid??
    deleteAbsenceListData(listIds.length !== 0 ? listIds : deleteId).then(
      res => {
        if (res.status == true) {
          search();
          setModalDeleteValue(false);
          clearSelectedElId()
          setValues({...values, absence_ids: []});
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: res.message,
          });
    
          
        }else{
          toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: res.message,
          });
        }
      }
    );
  };

  const deleteClick = id => {
    if (values.absence_ids && values.absence_ids.length === 0) {
      setDeleteId(id);
      values.absence_ids = [id];
      setValues({...values});
    }
    setModalDeleteValue(true);
  };
  const handleModals = () => {
    setAbsenceModalValue(!absenceModalValue);
  };
  const getDateTime = starttime => {
    let startTime = starttime
      ? moment(starttime.split('T')[1], 'HH:mm:ss').format('HH:mm')
      : '';

    let endTime = starttime?.slice(0, 10);

    let Time = endTime + '' + ' ' + '' + startTime;

    return Time || "-";
  };

  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists = id => {
      return checkedRows.some(function (el) {
        return el === id;
      });
    };
    if (checkedRows && checkedRows.length > 0) {
      absenceList.map(visit => {
        if (!visitExists(visit.id)) {
          checkAll = false;
        }
      });
    } else {
      checkAll = false;
    }
    return checkAll;
  };

  const handleAllSelectChange = e => {
    setCheckedValue(!checkedValue)
    if (!checkedRows) {
      checkedRows = [];
    }
    if (e.target.checked) {
      absenceList.map((item) => {
        checkedRows.push(item.id)
      })
    } else {
      absenceList.map((el) => {
        checkedRows.map((item, index) => {
          if (item === el.id) {
            checkedRows.splice(index, 1);
          }
        })
      })
    }
    setCheckedRows([...checkedRows])

    // let checked = [...checkedRows];
    // if (valcheck.target.checked) {
    //   absenceList.map(visit => {
    //     checked.push(visit);
    //   });
    // } else {
    //   absenceList.map(visit => {
    //     checked.map((item, index) => {
    //       if (item.id === visit.id) {
    //         checked.splice(index, 1);
    //       }
    //     });
    //   });
    // }
    // setCheckedRows(checked);
    // setCheckedId(() => checked.map(item => item.absence_ref));
  };
  
  const handleSearch = () => {
    if (
      values.runroutes ||
      values.branch ||
      values.employees ||
      values.absence_type ||
      values.start_date ||
      values.end_date ||
      values.limitperpage
    ) {
      const urlParams = queryString.parse(newDatas);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: '/absence-history-list',
        search: stringified,
      });
      search();
    }
  };

  const absenceHistoryMenu = [
    {
      name: "Delete",
      onClick: ()=>setModalDeleteValue(true)
    },
    {
      name: "Absence Approval",
      onClick: handleModals
    }
  ]
  const {
    displayMenu, selectedElId, clearSelectedElId, encloseModalBind
  } = useContextMenu()

  return (
    <div>
      <ContextMenu menuItems={absenceHistoryMenu} />
      <Toast ref={toast} />
      {readPermissionAbsenceHistory && (
        <HeaderVisits>
          <div className="d-flex align-items-center">
            <FiltersContainer>
              <div
                className={
                  'ms-1 me-3 me-md-0 flex-wrap d-flex justify-content-'
                }>
                <EmploymentTypesContainer className="me-2 mb-1">
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({runroutes: items});
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    //  placeHolder={'Runroute'}
                    placeHolder={'Group Area'}
                    isObject
                    allOption
                  />
                </EmploymentTypesContainer>

                <EmploymentTypesContainer className="me-2 mb-1">
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({branch: items});
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
                  />
                </EmploymentTypesContainer>
                <EmploymentTypesContainer className="me-2 mb-1">
                  <NoSelectAllDropdownFilter
                    items={employees ? employees : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employees: items});
                    }}
                    itemsSelected={employeeFilter}
                    setSelectedFilter={setEmployeefilter}
                    placeHolder={'Employees'}
                    isObject
                    allOption
                  />
                </EmploymentTypesContainer>
                <EmploymentTypesContainer className="me-2 mb-1">
                  <NoSelectAllDropdownFilter
                    items={absence_type ? absence_type : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({absence_type: items});
                    }}
                    itemsSelected={absenceFilter}
                    setSelectedFilter={setAbsencefilter}
                    placeHolder={'Absence Type'}
                    isObject
                    allOption
                  />
                </EmploymentTypesContainer>

                <EmploymentTypesContainer className="me-2 mb-1">
                  <DropdownTypesContainer >
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date from"
                      {...startDate}
                    />
                    {/* <div>
                      <CloseButtonWrapper
                        onClick={() => {
                          clearDateStartFilter('start_date', values.start_date);
                        }}>
                        {TIMES}
                      </CloseButtonWrapper>
                    </div> */}
                  </DropdownTypesContainer>
                </EmploymentTypesContainer>

                <EmploymentTypesContainer className="me-2 mb-1">
                  <DropdownTypesContainer >
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date to"
                      {...endDate}
                    />
                    {/* <div>
                      <CloseButtonWrapper
                        onClick={() => {
                          clearDateEndFilter('end_date', values.end_date);
                        }}>
                        {TIMES}
                      </CloseButtonWrapper>
                    </div> */}
                  </DropdownTypesContainer>
                </EmploymentTypesContainer>
                  <PrimaryButton
                  className=""
                    style={{maxHeight: "37px"}}
                    onClick={handleSearch}>
                    Search
                  </PrimaryButton>
              </div>
            </FiltersContainer>
          </div>

          <SearchAddContainer
            className="d-flex align-items-center"
            style={{paddingLeft: '100px'}}>
            {checkedRows && checkedRows.length > 0 && (
              <div class="dropdown  me-2">
                <PrimaryButtonForm
                  minWidth="6rem"
                  class="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  // aria-expanded="false"
                >
                  <span className={'me-2'}>Action</span>
                </PrimaryButtonForm>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      setModalDeleteValue(true);
                    }}>
                    Delete
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      handleModals();
                    }}>
                    Absence Approval
                  </a>
                </div>
              </div>
            )}
            <SearchIcon
              onClick={() => {
                setShowSearch(true);
              }}>
              <GrSearch />
            </SearchIcon>

            <SearchContainer show={showSearch}>
              <Fade collapse when={showSearch}>
                <InputLabelTop
                  no_internal_margin="true"
                  type="text"
                  id="search"
                  label={'Search'}
                  onChange={e => {
                    keyPressed(e);
                  }}
                  value={searchKeyword}
                />
              </Fade>
            </SearchContainer>
            {readPermissionAbsenceHistory && (
              <ItemContainer>
                <PublishButton
                  disable={downloadDisable}
                  onClick={!downloadDisable && handleDownlaod}>
                  {DOWNLOAD}
                </PublishButton>
              </ItemContainer>
            )}
          </SearchAddContainer>
        </HeaderVisits>
      )}
      <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-start"></DataContainer>
      <ComponentDynamic loading={loadingModal}>
        {readPermissionAbsenceHistory ? (
          <>
            {absenceList && absenceList.length ? (
              <Table
                headerColumns={headerColumns}
                headerPadding={false}
                fontSize="0.75rem"
                callBackQuerySort={setQuerySort}
                handleAllCheck={handleAllCheck}
                handleAllSelectChange={handleAllSelectChange}
                noEditOption={true}
                noNeed={false}>
                <div>
                  {absenceList &&
                    absenceList.map((list, index) => {
                      return (
                        <div className='absence-list-item' id={index}
                          onContextMenu={(e)=>{
                            e.preventDefault();
                            displayMenu(e, list.absence_ref, {x:0,y:0})
                          }}
                        >
                          <Row bgColor Shadow className='pt-1 pb-1'>
                            <ColsGrouper className="d-flex">
                              {deletePermissionAbsenceHistory &&
                                updatePermissionAbsenceHistory && (
                                  <Col
                                    max_width={EX_SMALL_COL4}
                                    Shrink={false}
                                    NoFlexGrow={true}
                                    reducePadding
                                    Capitalize
                                    Center>
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      // checked={handleCheck(list)}
                                      checked={checkedRows.includes(list.id)}
                                      id="flexCheckDefault"
                                      onChange={e => {
                                        handleSelectChange(e, list, index);
                                      }}
                                    />
                                  </Col>
                                )}
                              <ColsGrouper
                                className='d-flex'
                                onClick={() => {
                                  handleRowClick(list.id, index);
                                }}>
                                <Col
                                  max_width={MEDIUM_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  Center
                                  overlap={true}>
                                  {list.employee_name ? list.employee_name : "-"}
                                </Col>
                                <Col
                                  max_width={MEDIUM_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center>
                                  {list && list.absence_type ? list.absence_type :"-"}
                                </Col>
                                <Col
                                  max_width={SMALL_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center>
                                  {list && list.absence_reason ? list.absence_reason : "-"}
                                </Col>
                                <Col
                                  max_width={SMALL_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  className="sm-hidden"
                                  overlap={true}
                                  Center>
                                  {getDateTime(list && list.start_date)}
                                </Col>

                                <Col
                                  max_width={SMALL_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  className="sm-hidden"
                                  overlap={true}
                                  Center>
                                  {getDateTime(list && list.end_date)}
                                </Col>

                                <Col
                                  max_width={SMALL_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  Center>
                                  {list && list?.absence_hours ? list.absence_hours : "-"}
                                </Col>
                                <Col
                                  max_width={MEDIUM_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  className="sm-hidden"
                                  Center>
                                  {list && list.absence_days ? list.absence_days :"-"}
                                </Col>
                                <Col
                                  max_width={SMALL_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  className="sm-hidden"
                                  Center>
                                  {list && list.absence_status ? list.absence_status :"-"}
                                </Col>
                                <Col
                                  max_width={SMALL_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  className="sm-hidden"
                                  Center>
                                  {list && list.absence_authorisation_comments ? list.absence_authorisation_comments : "-"}
                                </Col>
                              </ColsGrouper>
                              <Col className="sm-hidden" Center Shrink NoFlexGrow>
                                {deletePermissionAbsenceHistory && (
                                  <IconWrapper
                                    onClick={() => {
                                      deleteClick(list.id);
                                    }}>
                                    <RiDeleteBinLine />
                                  </IconWrapper>
                                )}
                              </Col>
                            </ColsGrouper>
                          </Row>
                          <div className="mb-1" />
                        </div>
                      );
                    })}
                  <div className="mt-3">
                    <Pagination
                      setCurrentPage={setCurrentPage}
                      totalPages={pages}
                      currentPage={currentPage}
                      values={values}
                      dropDownHandleSelect={dropDownHandleSelectPagination}
                      number={PAGE_SIZE_RENDER}
                      dataLength={absenceList.length}
                    />
                  </div>
                </div>
              </Table>
            ) : (
              <NoDataFoundMessage top={'15%'}>No data found</NoDataFoundMessage>
            )}
          </>
        ) :
          <NoPermissionContainer>
            You don't have permission to access the information.
          </NoPermissionContainer>}
      </ComponentDynamic>
      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this Absence History? \n DELETING the Absence History will remove ALL records from database.'
        }
        onOk={deleteDeatils}
        onCancel={() => {
          clearSelectedElId()
        }}
        okTitle={'DELETE'}
        {...encloseModalBind(bindModalDelete)}
      />
      {!absenceModalValue ? null : (
        <AbsenceApproval
          setTitle={absenceTitle}
          resetSelectedIds={() => {
            clearSelectedElId();
            setCheckedRows([]);
            setValues({ ...values, absence_ids: [] });
          }}
          fullBind={()=>encloseModalBind(absenceBind)}
          setAbsenceModalValue={setAbsenceModalValue}
          idSelected={selectedElId?[selectedElId]:checkedRows}
          absenceListData={absenceList}
          search={search}
        />
      )}
      {modalValue ? (
        <HistoryModal
          Id={idSelected}
          setModalValue={handleModal}
          successCallback={search}
          fullBind={fullBind}
          absenceListData={absenceList}
          absenceListRowsId={absenceListRowsId}
          location={newDatas?.location?.search}
          dataIndex={dataIndex}
          deletePermission={deletePermission}
          contextChoices={contextChoices}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AbsenceHistoryList;
