import React, {Fragment, useContext, useEffect, useState} from 'react';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  CloseButtonWrapper,
} from 'shared/styles/constants/tagsStyles';
import {PLUS, TIMES} from 'utils/constants/icons';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import {Header, Title, TitleSection} from 'shared/styles/constants/tagsStyles';
import ToggleButton from 'components/SharedComponents/ToggleButton';

const ClockInToVisit = ({
  index,
  values,
  data,
  addClockInToVisit,
  deleteClockInToVisit,
  useInput,
}) => {
  return (
    <div>
      <>
        <div
          key={index}
          className={`mt-1 `}
          style={{marginLeft: '15px', marginRight: '12px',width:'73%'}}>
          <div className="d-flex">
            <Fragment />
            {/* {index!==0 &&  */}
            <CloseButtonWrapper
              onClick={() => {
                deleteClockInToVisit(data, index);
              }}>
              {TIMES}
            </CloseButtonWrapper>
            {/* } */}
            <div className="p-0  w-100 d-flex align-items-center" >
              <FlexGrid3Container className={'w-100 mb-1 mt-1 '}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1" >
                  <TimeSelector
                    
                    //   disabled={!checkUpdatePermission}
                    labelTop={'Time  From'}
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                    {...useInput(`clock_in_to_visit[${index}].reminder_time`)}
                    placeholderText={'hh:mm'}
                    locale={'sv-sv'}
                    timeCaption={'Duration'}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1" style={{marginLeft:'10px'}}>
                  <ToggleButton
                    useInput={useInput(`clock_in_to_visit[${index}].is_active`)}
                    id="clock_in_to_visit[index].is_active"
                  />
                </FlexGrid3Item>
              </FlexGrid3Container>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ClockInToVisit;
