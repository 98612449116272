import React from 'react'
import ShowFormModal from './ShowFormModal/ShowFormModal'
import FormViewer from 'components/SharedComponents/FormViewer'
import { ErrorMessage } from 'shared/styles/constants/tagsStyles'
import { Fragment } from 'react'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import { useRef } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useState } from 'react'
import { FLOPPY } from 'utils/constants/icons'
import { useContext } from 'react'
import { AppContext } from 'context'
import { InvalidOrEmptyValues } from 'utils/constants/messages'
import { useForm } from 'hooks/FormHook'
import { getPageToSearchParam } from 'shared/methods'
import { Toast } from 'primereact/toast'
import Table from 'components/SharedComponents/Table'
import { Col, ColsGrouper, IconWrapper, Row } from 'components/SharedComponents/Table/styles'
import { LARGE_COL } from 'shared/styles/constants/columns'
import moment from 'moment'
import { MdEdit } from 'react-icons/md'
import { RiDeleteBinLine } from 'react-icons/ri'
import ModalDecision from 'components/SharedComponents/ModalDecision'
import { useModal } from 'hooks/ModalHook'
import Pagination from 'components/SharedComponents/Pagination'
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination'
import { PAGINATION_PARAM } from 'utils/constants/queryParams'
import queryString from 'query-string';
import { useEffect } from 'react'
import Spinner from 'components/SharedComponents/Spinner'
import './style.css'
import { deleteVisitCareForm, editCareForumVisit, getVisitCareForm, setVisitFormAssign } from 'utils/api/SchedulerApi'
import { visit_care_forms_choices } from 'utils/choiceConstant'
import AddVisitForm from './AddModal'
import { FaPlus } from 'react-icons/fa'
import { PURPLE } from 'shared/styles/constants/colors'
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles'
const headerColumns = [
    {
        label: 'Form Name',
        name: 'uploaded',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },
    {
        label: 'Start Date',
        name: '',
        status: 0,
        className: 'grow-mobile sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'End Date',
        name: '',
        status: 0,
        className: 'grow-mobile sm-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'Created By',
        name: '',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },
];

function VisitFroms({
    visitId,
    clientSelectedId,
    employeeId,
    visitData,
    readPermission,
    createPermission,
    updatePermission,
    deletePermission
}) {
    const { contextChoices, handleChangeChoices, handleGetChoices, contextChoicesMap } = useContext(AppContext)
    const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({ limitperpage: PAGE_SIZE_RENDER });
    const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(false);
    const { setValue: setModalAttachValue, bind: bindModalAttach, value: modalAttachValue } = useModal(false);


    let selectedVisitId = visitId;


    const toast = useRef();
    const history = useHistory();
    const [modalLoading, setModalLoading] = useState(true);
    const [visitForms, setVisitForms] = useState([]);
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [idSelected, setIdSelected] = useState('');
    const [simplePagination] = useState(true);
    const [createdForms, setCreatedForms] = useState([])
    const [showForm, setShowForm] = useState({ name: "", visible: false })
    const [loadingSave, setLoadingSave] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [formData, setFormData] = useState(null)
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [formsChoices, setFormsChoices] = useState([])


    const loadVisitForms = async () => {
        values.visit_id = selectedVisitId;
        setValues({ ...values });
        const pageToSearch = getPageToSearchParam(
            history.location.search,
            setCurrentPage
        );

        try {
            const res = await getVisitCareForm({ ...values, ...pageToSearch })
            if (res.results) {
                setVisitForms(res.results || [])
                setPages(Math.ceil(res?.count / values?.limitperpage));
            }
            setModalLoading(false)
        } catch (error) {

        }
    }

    useEffect(() => {
        handleGetChoices(visit_care_forms_choices)
    }, [])

    useEffect(() => {
        if (!visitId) return;
        loadVisitForms()
    }, [visitId, values.limitperpage, history.location.search])
    console.log(visitForms, "visitForms")


    const editVisitform = async () => {

        let validationError = false;

        createdForms.forEach((form_val) => {
            if (form_val.required) {
                if (!form_val.value) {
                    validationError = true
                }
            }
        })
        if (validationError) {
            setShowError(validationError)
            return
        }

        setShowError(false)

        const body = {
            id: formData?.id,
            start_date: formData?.start_date,
            end_date: formData?.end_date,
            client: clientSelectedId,
            visit: selectedVisitId,
            form: formData?.form,
            name: formData?.name,
            json_data: JSON.stringify(createdForms),
        };

        await editCareForumVisit(body, formData?.id)
            .then((resp) => {
                if (resp.status) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: `${resp.message}`,
                    });
                    setShowForm({ name: "", visible: false })
                    loadVisitForms()
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Failed',
                        detail: `${resp.message}`,
                    });
                }
            }).catch((err) => {
            })
    }

    const toDeleteForm = (form_id) => {
        setIdSelected(form_id);
        setModalDeleteValue(true)
    }

    const handleDeleteForm = async () => {
        try {
            let response = await deleteVisitCareForm(idSelected);

            if (response.status === 'SUCCESS') {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${response.message}`,
                });
                loadVisitForms()
                setIdSelected('');
                setModalDeleteValue(false);
            }
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `${error.message}`,
            });
            setModalDeleteValue(false);
            setIdSelected('');
        }
    }

    const dropDownHandleSelectPagination = (item, namesection) => {
        const urlParams = queryString.parse(history.location.search);
        urlParams[PAGINATION_PARAM] = 1;
        const stringified = queryString.stringify(urlParams);
        history.push({
            pathname: history.location.pathname,
            search: stringified,
        });
        values.limitperpage = item.limitperpage
        setValues({ ...values });
    };


    const handleSubmit = async () => {
        if (errors.length) {
            setCheckRequires(true)
            setErrorMessage(InvalidOrEmptyValues)
            setShowError(true)
            return;
        }

        const { visit_start_date, visit_start_time, visit_end_date, visit_end_time } = visitData;
        try {
            const body = {
                name: values.name,
                form: values.form,
                start_date: visit_start_date == values.start_date ? `${values.start_date} ${visit_start_time.slice(0, 5)}` : `${values.start_date} 00:00`,
                end_date: visit_end_date == values.end_date ? `${values.end_date} ${visit_end_time.slice(0, 5)}` : `${values.end_date} 00:00`,
                visit: values.visit_id ? values.visit_id : selectedVisitId,
                client: clientSelectedId
            };

            let response = await setVisitFormAssign(body)
            if (response.status === true) {
                onSubmit()
            } else {
                onError(response)
            }
        } catch (error) {
            onError(error)
        }

    }

    const onSubmit = () => {
        setLoadingSave(false);
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Saved Successfully',
        });
        loadVisitForms()
        setCheckRequires(false)
        setErrorMessage('')
        setShowError(false)
        setModalAttachValue(false)
    }

    const onError = (error) => {
        toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `${error.message}`,
        });
        setModalAttachValue(false)
        setIdSelected('');
    }



    const getNameFromChoice = (id) => {
        if (!id) return;
        let match = contextChoices && contextChoices.user_list && contextChoices.user_list.find(user => user.id == id)
        return match ? match.name : ""
    }

    const handleModal = () => {
        const { visit_start_date, visit_end_date } = visitData;
        values.start_date = visit_start_date;
        values.end_date = visit_end_date;
        values.form = null;
        setValues({ ...values })
        setModalAttachValue(true)
    }

    const dropDownHandleSelect = (value) => {
        values.form = value.form
        let match = contextChoicesMap && contextChoicesMap?.form_builder_list[value.form]
        if (match) values.name = match?.name || ""
        setValues({ ...values })
    }

    useEffect(() => {
        setFormsChoices(contextChoices?.form_builder_list)
    }, [contextChoices?.form_builder_list])


    return (
        <div>
            <Toast ref={toast} position="top-right" />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FaPlus color={PURPLE} size={40} onClick={handleModal} />
            </div>
            {modalLoading ? (
                <div style={{ marginTop: '300px' }}>
                    <Spinner />
                </div>
            ) : (
                <div className="mt-3">
                   {visitForms?.length?
                    <>
                        <Table
                            headerColumns={headerColumns}
                            headerPadding={false}
                            noEditOption
                            fontSize="0.75rem">
                            <div>
                                {contextChoices &&
                                    visitForms.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <Row bgColor Shadow>
                                                    <ColsGrouper
                                                        className="d-flex"
                                                    >
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            Center
                                                            className=""
                                                            overlap={true}>
                                                            {item.name}
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            Center
                                                            className="sm-hidden"
                                                            overlap={true}>
                                                            {item.start_date ? moment(item.start_date).format('YYYY-MM-DD') : ''}
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            Center
                                                            className="sm-hidden"
                                                            overlap={true}>
                                                            {item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : ''}
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            Center
                                                            className="sm-hidden"
                                                            overlap={true}>
                                                            {getNameFromChoice(item.created_by)}
                                                        </Col>
                                                        {/* <Col max_width={LARGE_COL} Center overlap={true}>
                                                        {updatePermission && item.showAttachButton && (
                                                            <PrimaryButtonForm
                                                                minWidth="6rem"
                                                                onClick={() => {
                                                                    toAttachClick(item.id);
                                                                }}>
                                                                <span className={'me-2'}>
                                                                    Attach To Visit
                                                                </span>
                                                            </PrimaryButtonForm>
                                                        )}
                                                    </Col> */}
                                                    </ColsGrouper>
                                                    <Col className="" Center Shrink NoFlexGrow>
                                                        <Col className="" Center Icon Purple>
                                                            <IconWrapper
                                                                onClick={() => {
                                                                    setCreatedForms(() => {
                                                                        return JSON.parse(item.json_data)
                                                                    })
                                                                    setShowForm({ name: item.name, visible: true })
                                                                    setFormData(item)
                                                                }}
                                                            >
                                                                <MdEdit />
                                                            </IconWrapper>
                                                        </Col>
                                                        <IconWrapper
                                                            onClick={() => {
                                                                toDeleteForm(item.id);
                                                            }}>
                                                            <RiDeleteBinLine />
                                                        </IconWrapper>
                                                    </Col>

                                                </Row>
                                                <div className="mb-1" />
                                            </div>
                                        );
                                    })}
                            </div>
                        </Table>
                        <div className="mt-3">
                            <Pagination
                                totalPages={pages}
                                currentPage={currentPage}
                                values={values}
                                dropDownHandleSelect={dropDownHandleSelectPagination}
                                number={PAGE_SIZE_RENDER}
                                dataLength={visitForms.length}
                            />
                        </div>
                    </> : 
                    <NoDataFoundMessage style={{paddingBottom:"30px"}} top={'5%'}>No data found</NoDataFoundMessage>}
                </div>
            )}

            {modalAttachValue &&
                <AddVisitForm
                    fullBind={bindModalAttach}
                    useInput={useInput}
                    values={values}
                    readOnly={false}
                    errors={errors}
                    errorMessage={errorMessage}
                    showError={showError}
                    loadingSave={loadingSave}
                    handleSubmit={handleSubmit}
                    contextChoices={contextChoices}
                    formsChoices={formsChoices}
                    dropDownHandleSelect={dropDownHandleSelect}
                />}



            <ShowFormModal
                children={
                    <>
                        <FormViewer jsonData={createdForms} contextChoices={contextChoices} setJsonData={setCreatedForms} name={"name"} title={showForm.name} handleChangeChoices={handleChangeChoices} />
                        <div style={{ alignSelf: 'flex-end' }} className='d-flex mb-2 me-2'>
                            <div className={'mb-3 me-2'}>
                                {showError ? (
                                    <ErrorMessage>
                                        <label className={'mt-2'}>{InvalidOrEmptyValues}</label>
                                    </ErrorMessage>
                                ) : (
                                    <Fragment />
                                )}
                            </div>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                onClick={editVisitform}
                            >
                                <span className={'me-2'}>Save</span>
                                {!loadingSave ? (
                                    buttonIcon
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm me-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </>
                }
                title={showForm.name}
                onClose={() => {
                    setShowForm({ name: "", visible: false })
                }}
                showModal={showForm.visible}
                max_width={'60%'}
                minWidthTitle="8.5rem"
                loading={false}
                data={[]}
            />



            <div className='visit-form-popup'>
                <ModalDecision
                    type="delete"
                    title="Warning"
                    body={
                        'Are you sure you want to delete this form?'
                    }
                    subBody={
                        'TIP (Recommended) - you could change the task to discard instead to retain database records.'
                    }
                    onOk={handleDeleteForm}
                    onCancel={() => {
                        setIdSelected('');
                    }}
                    okTitle={'DELETE'}
                    {...bindModalDelete}
                />



                {/* <ModalDecision
                    type="confirm"
                    title="Warning"
                    body={'Are you sure you want to attach this form?'}
                    // subBody={
                    //     'TIP (Recommended) - you could change the task to discard instead to retain database records.'
                    // }
                    onOk={() => handleAttachForm(idSelected)}
                    onCancel={() => {
                        setIdSelected('');
                    }}
                    okTitle={'Confirm'}
                    {...bindModalAttach}
                /> */}
            </div>
        </div>
    )
}

export default VisitFroms