import React, { useState, useEffect, Fragment } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';

import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import DateSelector from 'components/SharedComponents/DateSelector';
import DurationInput from 'components/SharedComponents/DurationInput';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { FLOPPY } from 'utils/constants/icons';
import { useHistory } from 'react-router-dom';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { Toggle, ToggleLabel, ToggleWrapper } from 'shared/styles/buttons';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';
import {
  setUpdateVisit,
  setUpdateOptaplannerVisit,
  setUpdateSchedularVisit,
  AssignAllVisits,
} from 'utils/api/SchedulerApi';
import {
  TitleSection,
  ModalContainer,
  FlexGrid3Container,
  TabStyled,
  ShowTab,
  FlexGrid3Item,
  MessageSpan,
  ErrorMessage,
  Title,
} from 'shared/styles/constants/tagsStyles';
import {
  LARGE_COL,
  SMALL_COL,
  EX_SMALL_COL,
  MEDIUM_COL,
  EX_LARGE_COL,
  EX_VERY_LARGE_COL,
} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { getFundersList } from 'utils/api/ClientApi';
import { Row, Col, ColsGrouper } from 'components/SharedComponents/Table/styles';
import { ProgressBarContainer } from '../../../SharedComponents/TimelineCalendar/styles';
import PropTypes from 'prop-types';
import CustomProgressBar from 'components/SharedComponents/CustomProgressBar';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import ServiceAdhocEmployee from '../../../SharedComponents/FormSections/Service/ServiceAdhocEmployee';
import ServiceReplicaEmployee from '../../../SharedComponents/FormSections/Service/ServiceReplicaEmployee';
import AddNewEmployee from '../../../SharedComponents/FormSections/Service/AddNewEmployee';
import ServiceContractDetails from '../../../SharedComponents/FormSections/Service/ServiceContractDetails';
import RateDetails from '../../../SharedComponents/FormSections/Rate/RateDetails';
import SuggestedEmployee from './SuggestedEmployee';
import AuditHistory from './AuditHistory';
import moment from 'moment';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { PLUS, TIMES } from 'utils/constants/icons';
import { getScoreData } from 'utils/api/SchedulerApi';
import queryString from 'query-string';
import { PAGINATION_PARAM } from '../../../../utils/constants/queryParams';
import { PER_PAGE_TEN } from '../../../../utils/constants/pagination';
import { getFunderDetails } from 'utils/api/ClientApi';
import VisitTasks from 'components/DashboardComponents/Client/VisitTasks';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import { useModal } from 'hooks/ModalHook';
import MedicationPopup from './MedicationPopup';
import BodyMap from '../BodyMap';
import { useContext } from 'react';
import { AppContext } from 'context';
import { SELF_WEB_CREATE_PERMISSION, SELF_WEB_DELETE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, VISIT_AUDIT_HISTORY, VISIT_BODY_MAP, VISIT_CARE_NOTES, VISIT_CARE_NOTES_DETAILS, VISIT_CARE_NOTES_REVIEWERS, VISIT_EMPLOYEE_SUGGESTION, VISIT_EXPENSE, VISIT_MEDICATION, VISIT_POPUP, VISIT_POP_UP, VISIT_RATE, VISIT_SCORE, VISIT_TASK, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions';
import { getClient as localStorageClient } from 'utils/localStorage/token';
import Expenses from './Expenses';
import VisitTabContent from './VisitTabContent';
import CareNotes from './CareNotes';
import VisitFroms from './VisitForms';
const tab1 = 1;
const tab2 = 2;
const tab3 = 3;
const tab4 = 4;
const tab5 = 5;
const tab6 = 6;
const tab7 = 7;
const tab8 = 8;
const tab9 = 9
const tab10 = 10
const tab11 = 11;



const headerColumns = [
  {
    label: 'Name',
    name: 'constraint_name',
    status: 0,
    className: 'grow-mobile  ',
    max_width: EX_VERY_LARGE_COL,
  },
  {
    label: 'Score',
    name: 'score',
    status: 0,
    className: 'grow-mobile    ',
    max_width: EX_SMALL_COL,
  },
];

const AddVisit = ({
  props,
  readOnly,
  fullBind,
  dropDownHandleVisit,
  dropDownHandleEmployee,
  values,
  setValues,
  useInput,
  nameSection,
  choices,
  assignedEmployee,
  employees,
  handleAddVisitModal,
  searchScheduler,
  handleCancelVisit,
  handleSchedulerRevert,
  handleSchedulerPublishOrUnpublish,
  handleDeleteVisit,
  onShowAlert,
  clientdata,
  editMode,
  schedularVisitId,
  dropdownHandleAdhocEmployeeSkill,
  dropdownHandleAdhocEmployee,
  dropdownHandleReplicaEmployee,
  dropdownHandleReplicaEmployeeSkill,
  scoreDataId,
  clientSelectedId,
  setAddVisit,
  toast,
  setLoadingNew,
  iSPublished,
  loadingNew,
  //cancel props
  fullBindCancel,
  useInputCancel,
  nameSectionCancel,
  saveButtonIcon,
  textStatus,
  saveCancelVisit,
  dropDownHandleCancelVisit,
  listView,
  updateCancelVisit,
  clients,
  disableLockUnlock,
  handleLockUnlockVisit
}) => {
  const {
    contextChoicesMap,
    contextPermissions,
    contextSubscription,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    contextChoices,

    contextRelativesClient, } = useContext(AppContext)

  const userClient = localStorageClient();

  const [rightPermission, setRightPermission] = useState([])
  // namesection=visitValues
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [showQuestion, setShowQuestion] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [funderList, setFunderList] = useState([]);
  const [name, setName] = useState('');
  const [pic, setPic] = useState('');
  const [dob, setDOB] = useState();
  // const [initials, setInitials] = useState();
  const [intialsOfFirstName, setIntialsOfFirstName] = useState();
  const [intialsOfLastName, setIntialsOfLastName] = useState();
  const [visitStart, setVisitStart] = useState();
  const [visitEnd, setVisitEnd] = useState();
  const [tabSelected, setTabSelected] = useState(null);
  const [showServiceContractDetails, setShowServiceContractDetails] =
    useState(false);
  const [skillReplica, setSkillReplica] = useState([]);
  const [skillAddedMessage, setSkillAddedMessage] = useState('');
  const [toDeleteVisitEmployee, setToDeleteVisitEmployee] = useState([]);
  const [scoreDataById, setScoreDataById] = useState();
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [nameAssigned, setNameAssigned] = useState(null);
  const [isSaveDisable, setIsSaveDisable] = useState(false);
  const [competencyArr, setCompetencyArr] = useState([]);
  const [specificArr, setSpecificArr] = useState([]);
  const [loadingSave, setLoadingSave] = useState(false);


  const { setValue: setModalUnassignValue, bind: bindModalUnassign } =
    useModal(false);
  const [editModes, setEditModes] = useState(true);
  // const [loadingModal, setLoadingModal] = useState(true);

  //Permisstions 
  // Visit Body Map
  const [readPermissionBodyMap, setReadPermissionBodyMap] = useState(false)
  const [createPermissionBodyMap, setCreatePermissionBodyMap] = useState(false)
  const [updatePermissionBodyMap, setUpdatePermissionBodyMap] = useState(false)
  const [deletePermissionBodyMap, setDeletePermissionBodyMap] = useState(false)


  //Expense tab
  const [readPermissionVisitExpense, setReadPermissionVisitExpense] = useState(false)
  const [createPermissionVisitExpense, setCreatePermissionVisitExpense] = useState(false)
  const [updatePermissionVisitExpense, setUpdatePermissionVisitExpense] = useState(false)
  const [deletePermissionVisitExpense, setDeletePermissionVisitExpense] = useState(false)

  //Vist tab
  const [readPermissionVisitTab, setReadPermissionVisitTab] = useState(false)
  const [createPermissionVisitTab, setCreatePermissionVisitTab] = useState(false)
  const [updatePermissionVisitTab, setUpdatePermissionVisitTab] = useState(false)
  const [deletePermissionVisitTab, setDeletePermissionVisitTab] = useState(false)

  //Vist Rate
  const [readPermissionVisitRate, setReadPermissionVisitRate] = useState(false)
  const [createPermissionVisitRate, setCreatePermissionVisitRate] = useState(false)
  const [updatePermissionVisitRate, setUpdatePermissionVisitRate] = useState(false)
  const [deletePermissionVisitRate, setDeletePermissionVisitRate] = useState(false)

  //Vist Score
  const [readPermissionVisitScore, setReadPermissionVisitScore] = useState(false)
  const [createPermissionVisitScore, setCreatePermissionVisitScore] = useState(false)
  const [updatePermissionVisitScore, setUpdatePermissionVisitScore] = useState(false)
  const [deletePermissionVisitScore, setDeletePermissionVisitScore] = useState(false)

  //Vist Score
  const [readPermissionVisitEmployeeSuggestion, setReadPermissionVisitEmployeeSuggestion] = useState(false)
  const [createPermissionVisitEmployeeSuggestion, setCreatePermissionVisitEmployeeSuggestion] = useState(false)
  const [updatePermissionVisitEmployeeSuggestion, setUpdatePermissionVisitEmployeeSuggestion] = useState(false)
  const [deletePermissionVisitEmployeeSuggestion, setDeletePermissionVisitEmployeeSuggestion] = useState(false)

  //Vist Audit History
  const [readPermissionVisitAuditHistory, setReadPermissionVisitAuditHistory] = useState(false)
  const [createPermissionVisitAuditHistory, setCreatePermissionVisitAuditHistory] = useState(false)
  const [updatePermissionVisitAuditHistory, setUpdatePermissionVisitAuditHistory] = useState(false)
  const [deletePermissionVisitAuditHistory, setDeletePermissionVisitAuditHistory] = useState(false)

  //Vist Medication
  const [readPermissionVisitMedication, setReadPermissionVisitMedication] = useState(false)
  const [createPermissionVisitMedication, setCreatePermissionVisitMedication] = useState(false)
  const [updatePermissionVisitMedication, setUpdatePermissionVisitMedication] = useState(false)
  const [deletePermissionVisitMedication, setDeletePermissionVisitMedication] = useState(false)

  //Vist Task
  const [readPermissionVisitTask, setReadPermissionVisitTask] = useState(false)
  const [createPermissionVisitTask, setCreatePermissionVisitTask] = useState(false)
  const [updatePermissionVisitTask, setUpdatePermissionVisitTask] = useState(false)
  const [deletePermissionVisitTask, setDeletePermissionVisitTask] = useState(false)
  //Visit care notes
  const [readPermissionCareNotes, setReadPermissionCareNotes] = useState(false)
  const [createPermissionCareNotes, setCreatePermissionCareNotes] = useState(false)
  const [updatePermissionCareNotes, setUpdatePermissionCareNotes] = useState(false)
  const [deletePermissionCareNotes, setDeletePermissionCareNotes] = useState(false)
  // Visit care notes review
  const [readPermissionCareNotesReview, setReadPermissionCareNotesReview] = useState(false)
  const [createPermissionCareNotesReview, setCreatePermissionCareNotesReview] = useState(false)
  const [updatePermissionCareNotesReview, setUpdatePermissionCareNotesReview] = useState(false)
  const [deletePermissionCareNotesReview, setDeletePermissionCareNotesReview] = useState(false)
  // Permissions useEffect
  useEffect(() => {
    const rolesPermissions = contextPermissions;
    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          if (item.section !== null) {
            // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }
    console.log(rolePermissions, "role")
    const roles = contextSubSectionPermissions;

    if (roles.length > 0) {
      roles.forEach(
        item => {
          if (item.section !== null) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }
    setRightPermission({ ...rolePermissions });
    if (contextIsSuperAdmin) {
      // Visit Expense
      setReadPermissionVisitExpense(true);
      setCreatePermissionVisitExpense(true);
      setUpdatePermissionVisitExpense(true);
      setDeletePermissionVisitExpense(true);

      //Visit tab
      setReadPermissionVisitTab(true)
      setCreatePermissionVisitTab(true)
      setUpdatePermissionVisitTab(true)
      setDeletePermissionVisitTab(true)

      //Visit Rate
      setReadPermissionVisitRate(true)
      setCreatePermissionVisitRate(true)
      setUpdatePermissionVisitRate(true)
      setDeletePermissionVisitRate(true)

      //Visit Score
      setReadPermissionVisitScore(true)
      setCreatePermissionVisitScore(true)
      setUpdatePermissionVisitScore(true)
      setDeletePermissionVisitScore(true)

      //Visit Employee Suggestion
      setReadPermissionVisitEmployeeSuggestion(true)
      setCreatePermissionVisitEmployeeSuggestion(true)
      setUpdatePermissionVisitEmployeeSuggestion(true)
      setDeletePermissionVisitEmployeeSuggestion(true)

      //Visit Audit History
      setReadPermissionVisitAuditHistory(true)
      setCreatePermissionVisitAuditHistory(true)
      setUpdatePermissionVisitAuditHistory(true)
      setDeletePermissionVisitAuditHistory(true)

      //Visit Medication
      setReadPermissionVisitMedication(true)
      setCreatePermissionVisitMedication(true)
      setUpdatePermissionVisitMedication(true)
      setDeletePermissionVisitMedication(true)

      //Visit Task
      setReadPermissionVisitTask(true)
      setCreatePermissionVisitTask(true)
      setUpdatePermissionVisitTask(true)
      setDeletePermissionVisitTask(true)

      //body parts
      setReadPermissionBodyMap(true)
      setCreatePermissionBodyMap(true)
      setUpdatePermissionBodyMap(true)
      setDeletePermissionBodyMap(true)
      //Care notes
      setReadPermissionCareNotes(true)
      setCreatePermissionCareNotes(true)
      setUpdatePermissionCareNotes(true)
      setDeletePermissionCareNotes(true)


    } else if (contextIsAdmin) {
      // Visit Expense
      setReadPermissionVisitExpense(contextSubscription?.[VISIT_EXPENSE]?.[WEB_PERMISSION]);
      setCreatePermissionVisitExpense(true);
      setUpdatePermissionVisitExpense(true);
      setDeletePermissionVisitExpense(true);

      //Visit pop up
      setReadPermissionVisitTab(contextSubscription?.[VISIT_POPUP]?.[WEB_PERMISSION])
      setCreatePermissionVisitTab(true)
      setUpdatePermissionVisitTab(true)
      setDeletePermissionVisitTab(true)

      //Visit Rate
      setReadPermissionVisitRate(contextSubscription?.[VISIT_RATE]?.[WEB_PERMISSION])
      setCreatePermissionVisitRate(true)
      setUpdatePermissionVisitRate(true)
      setDeletePermissionVisitRate(true)

      //Visit Score
      setReadPermissionVisitScore(contextSubscription?.[VISIT_SCORE]?.[WEB_PERMISSION])
      setCreatePermissionVisitScore(true)
      setUpdatePermissionVisitScore(true)
      setDeletePermissionVisitScore(true)

      //Visit Employee Suggestion
      setReadPermissionVisitEmployeeSuggestion(contextSubscription?.[VISIT_EMPLOYEE_SUGGESTION]?.[WEB_PERMISSION])
      setCreatePermissionVisitEmployeeSuggestion(true)
      setUpdatePermissionVisitEmployeeSuggestion(true)
      setDeletePermissionVisitEmployeeSuggestion(true)

      //Visit Audit History
      setReadPermissionVisitAuditHistory(contextSubscription?.[VISIT_AUDIT_HISTORY]?.[WEB_PERMISSION])
      setCreatePermissionVisitAuditHistory(true)
      setUpdatePermissionVisitAuditHistory(true)
      setDeletePermissionVisitAuditHistory(true)

      //Visit Medication
      setReadPermissionVisitMedication(contextSubscription?.[VISIT_MEDICATION]?.[WEB_PERMISSION])
      setCreatePermissionVisitMedication(true)
      setUpdatePermissionVisitMedication(true)
      setDeletePermissionVisitMedication(true)

      //Visit Task
      setReadPermissionVisitTask(contextSubscription?.[VISIT_TASK]?.[WEB_PERMISSION])
      setCreatePermissionVisitTask(true)
      setUpdatePermissionVisitTask(true)
      setDeletePermissionVisitTask(true)

      //body parts
      setReadPermissionBodyMap(true)
      setCreatePermissionBodyMap(true)
      setUpdatePermissionBodyMap(true)
      setDeletePermissionBodyMap(true)

      //Care notes
      setReadPermissionCareNotes(contextSubscription?.[VISIT_CARE_NOTES]?.[WEB_PERMISSION])
      setCreatePermissionCareNotes(true)
      setUpdatePermissionCareNotes(true)
      setDeletePermissionCareNotes(true)

      //Care notes Review
      setReadPermissionCareNotesReview(contextSubscription?.[VISIT_CARE_NOTES]?.[WEB_PERMISSION])
      setCreatePermissionCareNotesReview(true)
      setUpdatePermissionCareNotesReview(true)
      setDeletePermissionCareNotesReview(true)


    } else {
      // For Visit Expense 
      if (rolePermissions[VISIT_EXPENSE] !== undefined) {
        setReadPermissionVisitExpense(
          contextSubscription?.[VISIT_EXPENSE]?.[WEB_PERMISSION] &&
          (rolePermissions[VISIT_EXPENSE][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[VISIT_EXPENSE]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
              ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId)))))
        );

        setCreatePermissionVisitExpense(
          rolePermissions[VISIT_EXPENSE][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[VISIT_EXPENSE]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setUpdatePermissionVisitExpense(rolePermissions[VISIT_EXPENSE][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[VISIT_EXPENSE]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setDeletePermissionVisitExpense(
          rolePermissions[VISIT_EXPENSE][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        );


      }

      //For  Visit PopUp
      if (rolePermissions[VISIT_POPUP] !== undefined) {
        setReadPermissionVisitTab(
          contextSubscription?.[VISIT_POPUP]?.[WEB_PERMISSION] &&
          (rolePermissions[VISIT_POPUP][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[VISIT_POPUP]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
              ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId)))))
        );
        setCreatePermissionVisitTab(
          rolePermissions[VISIT_POPUP][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[VISIT_POPUP]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setUpdatePermissionVisitTab(
          rolePermissions[VISIT_POPUP][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[VISIT_POPUP]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setDeletePermissionVisitTab(
          rolePermissions[VISIT_POPUP][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        );
      }

      //For Visit Rate
      if (rolePermissions[VISIT_RATE] !== undefined) {
        setReadPermissionVisitRate(
          contextSubscription?.[VISIT_RATE]?.[WEB_PERMISSION] &&
          (rolePermissions[VISIT_RATE][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[VISIT_RATE]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
              ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId)))))
        );
        setCreatePermissionVisitRate(
          rolePermissions[VISIT_RATE][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[VISIT_RATE]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setUpdatePermissionVisitRate(
          rolePermissions[VISIT_RATE][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[VISIT_RATE]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setDeletePermissionVisitRate(
          rolePermissions[VISIT_RATE][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        );
      }

      //For Visit Score
      if (rolePermissions[VISIT_SCORE] !== undefined) {
        setReadPermissionVisitScore(
          contextSubscription?.[VISIT_SCORE]?.[WEB_PERMISSION] &&
          (rolePermissions[VISIT_SCORE][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[VISIT_SCORE]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
              ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId)))))
        );
        setCreatePermissionVisitScore(
          rolePermissions[VISIT_SCORE][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[VISIT_SCORE]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setUpdatePermissionVisitScore(
          rolePermissions[VISIT_SCORE][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[VISIT_SCORE]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setDeletePermissionVisitScore(
          rolePermissions[VISIT_SCORE][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        );
      }

      //For Visit Employee Suggestion
      if (rolePermissions[VISIT_EMPLOYEE_SUGGESTION] !== undefined) {
        setReadPermissionVisitEmployeeSuggestion(
          contextSubscription?.[VISIT_EMPLOYEE_SUGGESTION]?.[WEB_PERMISSION] &&
          (rolePermissions[VISIT_EMPLOYEE_SUGGESTION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[VISIT_EMPLOYEE_SUGGESTION]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
              ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId)))))
        );
        setCreatePermissionVisitEmployeeSuggestion(
          rolePermissions[VISIT_EMPLOYEE_SUGGESTION][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[VISIT_EMPLOYEE_SUGGESTION]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setUpdatePermissionVisitEmployeeSuggestion(
          rolePermissions[VISIT_EMPLOYEE_SUGGESTION][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[VISIT_EMPLOYEE_SUGGESTION]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setDeletePermissionVisitEmployeeSuggestion(
          rolePermissions[VISIT_EMPLOYEE_SUGGESTION][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        );
      }

      //For Visit Audit History
      if (rolePermissions[VISIT_AUDIT_HISTORY] !== undefined) {
        setReadPermissionVisitAuditHistory(
          contextSubscription?.[VISIT_AUDIT_HISTORY]?.[WEB_PERMISSION] &&
          (rolePermissions[VISIT_AUDIT_HISTORY][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[VISIT_AUDIT_HISTORY]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
              ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId)))))
        );
        setCreatePermissionVisitAuditHistory(
          rolePermissions[VISIT_AUDIT_HISTORY][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[VISIT_AUDIT_HISTORY]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setUpdatePermissionVisitAuditHistory(
          rolePermissions[VISIT_AUDIT_HISTORY][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[VISIT_AUDIT_HISTORY]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setDeletePermissionVisitAuditHistory(
          rolePermissions[VISIT_AUDIT_HISTORY][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        );
      }

      //For Visit Medication
      if (rolePermissions[VISIT_MEDICATION] !== undefined) {
        setReadPermissionVisitMedication(
          contextSubscription?.[VISIT_MEDICATION]?.[WEB_PERMISSION] &&
          (rolePermissions[VISIT_MEDICATION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[VISIT_MEDICATION]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
              ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId)))))
        );
        setCreatePermissionVisitMedication(
          rolePermissions[VISIT_MEDICATION][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[VISIT_MEDICATION]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setUpdatePermissionVisitMedication(
          rolePermissions[VISIT_MEDICATION][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[VISIT_MEDICATION]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setDeletePermissionVisitMedication(
          rolePermissions[VISIT_MEDICATION][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        );
      }

      //For Visit Medication
      if (rolePermissions[VISIT_TASK] !== undefined) {
        setReadPermissionVisitTask(
          contextSubscription?.[VISIT_TASK]?.[WEB_PERMISSION] &&
          (rolePermissions[VISIT_TASK][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rolePermissions[VISIT_TASK]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
              ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId)))))
        );
        setCreatePermissionVisitTask(
          rolePermissions[VISIT_TASK][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[VISIT_TASK]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setUpdatePermissionVisitTask(
          rolePermissions[VISIT_TASK][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[VISIT_TASK]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] &&
            ((userClient == clientSelectedId) || (contextRelativesClient.find(id => id === clientSelectedId))))
        );

        setDeletePermissionVisitTask(
          rolePermissions[VISIT_TASK][WEB_PERMISSION][WEB_DELETE_PERMISSION]
        );
      }
      //Body Map
      if (rolePermissions[VISIT_BODY_MAP] !== undefined) {
        setReadPermissionBodyMap(
          rolePermissions[VISIT_BODY_MAP][WEB_PERMISSION]?.[WEB_READ_PERMISSION] ||
          (rolePermissions[VISIT_BODY_MAP]?.[WEB_PERMISSION]?.[SELF_WEB_READ_PERMISSION] &&
            (userClient == clientSelectedId) || (contextRelativesClient.find(id => id == clientSelectedId)))

        )
        setCreatePermissionBodyMap(
          rolePermissions[VISIT_BODY_MAP][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rolePermissions[VISIT_BODY_MAP]?.[WEB_PERMISSION]?.[SELF_WEB_CREATE_PERMISSION] &&
            (userClient == clientSelectedId) || (contextRelativesClient.find(id => id == clientSelectedId)))
        )
        setUpdatePermissionBodyMap(
          rolePermissions[VISIT_BODY_MAP][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rolePermissions[VISIT_BODY_MAP]?.[WEB_PERMISSION]?.[SELF_WEB_UPDATE_PERMISSION] &&
            (userClient == clientSelectedId) || (contextRelativesClient.find(id => id == clientSelectedId)))

        )
        setDeletePermissionBodyMap(
          rolePermissions[VISIT_BODY_MAP][WEB_PERMISSION][WEB_DELETE_PERMISSION] ||
          (rolePermissions[VISIT_BODY_MAP]?.[WEB_PERMISSION]?.[SELF_WEB_DELETE_PERMISSION] &&
            (userClient == clientSelectedId) || (contextRelativesClient.find(id => id == clientSelectedId)))

        )

      }


      if (rolePermissions[VISIT_CARE_NOTES_DETAILS]) {
        setReadPermissionCareNotes(
          rolePermissions[VISIT_CARE_NOTES_DETAILS][WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            (userClient == clientSelectedId ||
              contextRelativesClient.find(id => id == clientSelectedId)))
        );
        setCreatePermissionCareNotes(
          rolePermissions[VISIT_CARE_NOTES_DETAILS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            (userClient == clientSelectedId ||
              contextRelativesClient.find(id => id == clientSelectedId)))
        );
        setUpdatePermissionCareNotes(
          rolePermissions[VISIT_CARE_NOTES_DETAILS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            (userClient == clientSelectedId ||
              contextRelativesClient.find(id => id == clientSelectedId)))
        );
        setDeletePermissionCareNotes(
          rolePermissions[VISIT_CARE_NOTES_DETAILS][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_DELETE_PERMISSION
          ] &&
            (userClient == clientSelectedId ||
              contextRelativesClient.find(id => id == clientSelectedId)))
        );
      }

      if (rolePermissions[VISIT_CARE_NOTES_REVIEWERS]) {
        setReadPermissionCareNotesReview(
          rolePermissions[VISIT_CARE_NOTES_REVIEWERS][WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_REVIEWERS]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            (userClient == clientSelectedId ||
              contextRelativesClient.find(id => id == clientSelectedId)))
        );
        setCreatePermissionCareNotesReview(
          rolePermissions[VISIT_CARE_NOTES_REVIEWERS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_REVIEWERS]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            (userClient == clientSelectedId ||
              contextRelativesClient.find(id => id == clientSelectedId)))
        );
        setUpdatePermissionCareNotesReview(
          rolePermissions[VISIT_CARE_NOTES_REVIEWERS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_REVIEWERS]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            (userClient == clientSelectedId ||
              contextRelativesClient.find(id => id == clientSelectedId)))
        );
        setDeletePermissionCareNotesReview(
          rolePermissions[VISIT_CARE_NOTES_REVIEWERS][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_REVIEWERS]?.[WEB_PERMISSION]?.[
            SELF_WEB_DELETE_PERMISSION
          ] &&
            (userClient == clientSelectedId ||
              contextRelativesClient.find(id => id == clientSelectedId)))
        );
      }
    }
  }, [contextChoices, contextIsSuperAdmin, contextIsAdmin, contextSubscription]);

  const arrayOfDepensencies = [readPermissionVisitExpense, readPermissionVisitMedication, readPermissionVisitTask, readPermissionVisitAuditHistory, readPermissionVisitEmployeeSuggestion, readPermissionVisitScore, readPermissionVisitTab, readPermissionCareNotes]
  useEffect(() => {
    if (readPermissionVisitTab) {
      //visit tab
      setTabSelected(tab1);
    } else if (readPermissionVisitRate) {
      //Rate tab
      setTabSelected(tab2);
    } else if (readPermissionVisitScore) {
      //Score tab
      setTabSelected(tab3);
    } else if (readPermissionVisitEmployeeSuggestion) {
      //Employee Suggestion tab
      setTabSelected(tab4);
    } else if (readPermissionVisitAuditHistory) {
      // Audit History tab
      setTabSelected(tab5);
    } else if (readPermissionVisitTask) {
      // Task tab
      setTabSelected(tab6)
    } else if (readPermissionVisitMedication) {
      // Medication tab
      setTabSelected(tab7)
    } else if (readPermissionBodyMap) {
      //Boy part tab
      setTabSelected(tab8)
    } else if (readPermissionVisitExpense) {
      //Expense tab
      setTabSelected(tab9)
    } else if (readPermissionBodyMap) {
      //Expense tab
      setTabSelected(tab10)
    }
  }, arrayOfDepensencies)


  const VISITS_VALUES = 'visitValues';
  const history = useHistory();


  const skillIdValues =
    values[VISITS_VALUES] &&
    values[VISITS_VALUES].skill_replica &&
    values[VISITS_VALUES].skill_replica.map(y => {
      if (y.skill_id) {
        return {
          id: y && y.skill_id,
          skill: y.value,
        };
      } else {
        return {
          skill: y.value,
        }
      }

    });

  // const specific_groupsValues = values[VISITS_VALUES]?.specific_groups?.map(
  //   obj => {
  //     return { value: obj.specific_group, label: obj.specific_group_name };
  //   }
  // );

  const competencyValues = values[VISITS_VALUES]?.competency?.map(obj => {
    if (obj.competency_id) {
      return { id: obj.competency_id, value: obj.value, label: obj.label };
    } else {
      return { value: obj.value, label: obj.label };
    }

  });


  useEffect(() => {
    if (
      values[nameSection].evv_data &&
      values[nameSection].evv_data.schedule_start_datetime
    ) {
      let newTravelStartDateTime = moment(
        values[nameSection].evv_data.schedule_start_datetime
      ).format('HH:mm:ss');

      let newTravelEndDateTime = moment(
        values[nameSection].evv_data.schedule_end_datetime
      ).format('HH:mm:ss');
      let newTravelStartDate = moment(
        values[nameSection].evv_data.schedule_start_datetime
      ).format('YYYY-MM-DD');
      let newTravelEndDate = moment(
        values[nameSection].evv_data.schedule_end_datetime
      ).format('YYYY-MM-DD');
      console.log(newTravelEndDate, 'newTravelEndDate');
      values[nameSection].travel_end_datetime = newTravelEndDateTime;
      values[nameSection].travel_start_datetime = newTravelStartDateTime;
      values[nameSection].travel_end_dates = newTravelEndDate;
      values[nameSection].travel_start_dates = newTravelStartDate;
      setValues({ ...values });
    }
  }, [values[nameSection]?.evv_data]);
  const skillIdNewEmployeeValues =
    values[VISITS_VALUES] &&
    values[VISITS_VALUES].new_requirement &&
    values[VISITS_VALUES].new_requirement.map(y => {
      return {
        skill: y.skills,
      };
    });

  const new_requirement_add_new_employee =
    values[VISITS_VALUES] &&
    values[VISITS_VALUES].new_requirement &&
    values[VISITS_VALUES].new_requirement.map(y => {
      return {
        no_of_employees: y.no_of_employees,
        start_time: y.start_time,
        end_time: y.end_time,
        duration: y.duration,
        skill_level: y.skill_level,
        preferred_gender: y.gender,
        // skills: skillIdNewEmployeeValues,
        skills:
          y &&
          y.skills &&
          y.skills.map(x => {
            return { skill: x && x.value && x.value };
          }),
        competency:
          y &&
          y.competency &&
          y.competency.map(x => {
            return { value: x.value, label: x.label };
          }),
        // specific_groups: y.specific_groups,
      };
    });

  const currentVisitStatus = () => {
    if (values[nameSection]?.evv_data?.actual_end_time) {
      return 4;
    } else if (values[nameSection]?.evv_data?.actual_start_time) {
      return 3;
    } else if (values[nameSection]?.evv_data?.employee) {
      return 2;
    } else if (values[nameSection]?.evv_data?.employee) {
    } else {
      return 1;
    }
  };

  useEffect(() => {
    if (choices.travel_type && choices.travel_type.length > 0) {
      let data = choices.travel_type.find(item => {
        let newData =
          values[nameSection]?.evv_data &&
          values[nameSection]?.evv_data.travel_mode;
        if (item.name === newData) {
          return item;
        }
      });
      values[nameSection].travel_mode = data && data.id;
      setValues({ ...values });
    }
  }, [values[nameSection]?.evv_data, choices.travel_type]);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: (
        <div style={{ display: 'flex' }}>
          {/* <p style={{width:"120px"}}>Visit View</p> */}
          {!values.is_visit_cancelled && (
            <div className={'me-4 ms-4'}>
              <ProgressBarContainer>
                <CustomProgressBar
                  currentStep={currentVisitStatus}
                  items={['Unassigned', 'Assigned', 'Completed']}
                // loadingOptaPlanner={loadingOptaPlanner}
                // optaplannerStatus={optaplannerStatus}
                // runningDate={runningDate}
                // actualDate={values.start_date}
                />
              </ProgressBarContainer>
            </div>
          )}
          {values.is_visit_cancelled && (
            <p style={{ color: '#6E6BFA' }}>Cancelled</p>
          )}
        </div>
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);

  useEffect(() => {
    funderLists();
  }, []);

  const createAddressFormat = address => {
    if (address) {
      const name = address.name ? `${address.name}, ` : '';
      const line_1 = address.line_1 ? `${address.line_1}, ` : '';
      const city = address.city ? `${address.city}, ` : '';
      const zip_code = address.zip_code ? `${address.zip_code}` : '';

      return `${name}${line_1}${city}${zip_code}`;
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (
      values[nameSection] &&
      values[nameSection].duration &&
      typeof values[nameSection].duration === 'string'
    ) {
      const res = values[nameSection].duration
        .split(':')
        .reduce((acc, time) => 60 * acc + +time);

      values[nameSection].duration = res;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[nameSection] && values[nameSection].duration]);

  useEffect(() => {
    if (
      values[nameSection] &&
      values[nameSection].evv_data &&
      values[nameSection].evv_data.actual_visit_start_time &&
      values[nameSection].evv_data.actual_visit_end_time
    ) {
      let start_time = moment(
        values[nameSection].evv_data.actual_visit_start_time,
        'HH:mm:ss'
      );
      let end_time = moment(
        values[nameSection].evv_data.actual_visit_end_time,
        'HH:mm:ss'
      );

      if (end_time > start_time) {
        values[nameSection].evv_data.actual_visit_duration = end_time.diff(
          start_time,
          'seconds'
        );
      } else {
        let add_date = end_time.add(1, 'days');
        values[nameSection].evv_data.actual_visit_duration = add_date.diff(
          start_time,
          'seconds'
        );
      }
    } else {
      if (values[nameSection] && values[nameSection].evv_data && !(values[nameSection].evv_data == {})) {
        values[nameSection].evv_data.actual_visit_duration = 0;
      }
    }
    setValues({ ...values });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[nameSection] && values[nameSection].evv_data]);

  useEffect(() => {
    if (!values.employee || (values.employee && !values.employee.id)) {
      setShowQuestion(false);
    } else {
      setErrorMessage('');
    }

    //to pass name as a props
    setNameAssigned(values.employee ? values.employee : null);
  }, [values]);

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;

    if (values[VISITS_VALUES].funder) {
      let id = values[VISITS_VALUES]?.funder;
      getFunderDetails(id).then(res => {
        if (res) {
          values[VISITS_VALUES].category = res?.funder_category;
          setValues({ ...values });
        }
      });
    }

    setValues({ ...values });
  }, []);

  const handleSaveEmployee = () => {
    if (editMode) {
      //msg will be shown
      // setShowQuestion(true);
      setShowQuestion(false);
      updateEmployeeSchedularVisit();
      // assignApiResMsgCheck();
      // }else{
      //   //msg will not be shown
    }
  };

  const assignApiResMsgCheck = async () => {
    const obj = {
      employee: values && values.employee ? values.employee : null,
      id: values.visit_id,
    };

    let res = await setUpdateOptaplannerVisit(obj);
    if (res.message == 'Unable to reach on visit time.') {
      setShowQuestion(true);
    } else {
      try {
        if (res) {
          handleAddVisitModal();

          onShowAlert(res.status ? 'success' : 'error', res.message);
        }
      } catch (error) {
      } finally {
        searchScheduler();
        handleAddVisitModal();
      }
    }
  };

  const forceTrueupdateEmployee = async () => {
    const obj = {
      employee: values && values.employee ? values.employee.id : null,
      id: values.visit_id,
      force: true,
    };

    try {
      let res = await setUpdateOptaplannerVisit(obj);
      if (res) {
        handleAddVisitModal();
        onShowAlert(res.status ? 'success' : 'error', res.message);
      }
    } catch (error) {
    } finally {
      searchScheduler();
      handleAddVisitModal();
    }
  };

  const updateEmployee = async () => {
    const obj = {
      // employee assigned
      employee: values && values.employee ? values.employee : null,

      //visitid
      id: values.visit_id,

      // visit start date with time
      start_date:
        values[nameSection] &&
        values[nameSection].visit_start_date &&
        values[nameSection].visit_start_time &&
        values[nameSection].visit_start_date +
        'T' +
        moment(values[nameSection].visit_start_time, 'HH:mm:ss').format(
          'HH:mm:ss'
        ),

      // visit end date  with time
      end_date:
        values[nameSection] &&
        values[nameSection].visit_end_date &&
        values[nameSection].visit_end_time &&
        values[nameSection].visit_end_date +
        'T' +
        moment(values[nameSection].visit_end_time, 'HH:mm:ss').format(
          'HH:mm:ss'
        ),

      //earliest and latest time plus critical time
      latest_start_time:
        values[nameSection] &&
        values[nameSection].visit_end_date &&
        values[nameSection].latest_start_time,

      earliest_start_time:
        values[nameSection] &&
        values[nameSection].visit_end_date &&
        values[nameSection].earliest_start_time,

      time_critical:
        values[nameSection] &&
        values[nameSection].visit_end_date &&
        values[nameSection].time_critical,

      visit_status: values[nameSection]
        ? values[nameSection]?.visit_status
        : null,

      // actual_visit start date with time
      actual_visit_start_time:
        values[nameSection].evv_data.actual_start_date &&
          values[nameSection].evv_data.actual_visit_start_time
          ? values[nameSection] &&
          values[nameSection].evv_data.actual_start_date &&
          values[nameSection].evv_data.actual_visit_start_time &&
          values[nameSection].evv_data.actual_start_date +
          'T' +
          moment(
            values[nameSection].evv_data.actual_visit_start_time,
            'HH:mm:ss'
          ).format('HH:mm:ss')
          : null,

      // actual_visit end date with time
      actual_visit_end_time:
        values[nameSection].evv_data.actual_end_date &&
          values[nameSection].evv_data.actual_visit_end_time
          ? values[nameSection] &&
          values[nameSection].evv_data.actual_end_date &&
          values[nameSection].evv_data.actual_visit_end_time &&
          values[nameSection].evv_data.actual_end_date +
          'T' +
          moment(
            values[nameSection].evv_data.actual_visit_end_time,
            'HH:mm:ss'
          ).format('HH:mm:ss')
          : null,

      //actual_time_notes
      actual_time_notes:
        values[nameSection].evv_data.warning_message &&
        values[nameSection].evv_data.warning_message,

      self_funded: values[VISITS_VALUES].self_funded == 1 ? true : false,
      funder: values[VISITS_VALUES].funder
        ? values[VISITS_VALUES].funder
        : null,
      category: values[VISITS_VALUES].category,
      //client type
      type_id: values[nameSection] ? values[nameSection].type : null,

      ////service category
      service_category: values[nameSection]
        ? values[nameSection].service_category
        : null,
      travel_mode: values[nameSection] ? values[nameSection].travel_mode : null,
      visit_type: values[nameSection] ? values[nameSection].visit_type : null,
      visit_priority: values[nameSection]
        ? values[nameSection].visit_priority
        : null,

      requirement: [
        {
          id: values.visitValues.req_replica.id,
          no_of_employees: values[VISITS_VALUES]
            ? values[VISITS_VALUES].req_replica.no_of_employees
            : null,
          start_time: values[VISITS_VALUES].req_replica.start_time,
          end_time: values[VISITS_VALUES].req_replica.end_time,
          duration: values[VISITS_VALUES].req_replica.duration,
          // visitid get api end id
          visit: values.visit_id,
          skill_level: values[VISITS_VALUES]
            ? values[VISITS_VALUES].req_replica.skill_level
            : null,
          preferred_gender: values[VISITS_VALUES]
            ? values[VISITS_VALUES].req_replica.preferred_gender
            : null,

          skill: skillIdValues,
        },
      ],
    };

    try {
      let res = await setUpdateOptaplannerVisit(obj);
      if (res) {
        handleAddVisitModal();
        onShowAlert(res.status ? 'success' : 'error', res.message);
      }
    } catch (error) {
    } finally {
      searchScheduler();
      handleAddVisitModal();
    }
  };
  const handleValidation = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffInHours = (end - start) / (1000 * 60 * 60); // Calculate difference in hours
    return diffInHours <= 24;
  };
  const updateEmployeeSchedularVisit = async force => {
    debugger
    // const specific_groupsValues = values[VISITS_VALUES]?.specific_groups?.map(
    //   el => {
    //     // return {value: obj.specific_group, label: obj.specific_group_name};
    //     if (el.client_task) {
    //       return { id: el.client_task, specific_group_id: el.value, specific_group_name: el.value }
    //     } else {
    //       return { specific_group_id: el.value, specific_group_name: el.label }
    //     }
    //   }
    // );

    // visit start date with time
    const startDateTime = values[nameSection] &&
      values[nameSection].visit_start_date &&
      values[nameSection].visit_start_time &&
      values[nameSection].visit_start_date + 'T' +
      moment((values[nameSection].visit_start_time || "00:00"), 'HH:mm:ss')
        .format('HH:mm:ss');

    // visit end date  with time
    const endDateTime = values[nameSection] &&
      values[nameSection].visit_end_date &&
      values[nameSection].visit_end_time &&
      values[nameSection].visit_end_date + 'T' +
      moment((values[nameSection].visit_end_time || "00:00"), 'HH:mm:ss')
        .format('HH:mm:ss');

    // Validate the selected visit start date time range must be smaller than 24 HR
    if (startDateTime && endDateTime) {
      const isValid = handleValidation(startDateTime, endDateTime)
      if (!isValid) {
        let message = 'The Visit Start Date-Time and End Date-Time can’t be longer than 24 hours. Please adjust the Visit Start or End Date-Time.'
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: message,
        });
        return;
      }
    }

    const schedule_start_date = values[nameSection]?.evv_data?.schedule_start_date;
    const schedule_start_time = schedule_start_date &&(values[nameSection]?.evv_data?.schedule_start_time || "00:00");
    const schedule_end_date = values[nameSection]?.evv_data?.schedule_end_date;
    const schedule_end_time = schedule_end_date && (values[nameSection]?.evv_data?.schedule_end_time || "00:00");

    let scheduledStartDateTime = schedule_start_date && schedule_start_date + 'T' +
      moment(schedule_start_time, 'HH:mm:ss').format('HH:mm:ss');

    let scheduledEndDateTime = schedule_end_date && schedule_end_date + 'T' +
      moment(schedule_end_time, 'HH:mm:ss').format('HH:mm:ss');

    if (scheduledStartDateTime && scheduledEndDateTime) {
      const isValidScheduled = handleValidation(scheduledStartDateTime, scheduledEndDateTime);
      if (!isValidScheduled) {
        let message = 'The Schedule Start Date-Time and End Date-Time can’t be longer than 24 hours. Please adjust the Schedule Start or End Date-Time.'
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: message,
        });
        return;
      }
    }

    console.log({
      schedule_start_date: values[nameSection].evv_data.schedule_start_date,
      schedule_start_time: values[nameSection].evv_data.schedule_start_time,
      schedule_end_date: values[nameSection].evv_data.schedule_end_date,
      schedule_end_time: values[nameSection].evv_data.schedule_end_time,
    }, "scheduled")
    const obj = {
      // employee assigned
      employee: values && values.employee ? values.employee : null,

      //visitid
      id: values.visit_id,


      start_date: values[nameSection] &&
      values[nameSection].visit_start_date &&
      values[nameSection].visit_start_time &&
      values[nameSection].visit_start_date + 'T' +
      moment(values[nameSection].visit_start_time, 'HH:mm:ss')
        .format('HH:mm:ss'),


      end_date: values[nameSection] &&
      values[nameSection].visit_end_date &&
      values[nameSection].visit_end_time &&
      values[nameSection].visit_end_date + 'T' +
      moment(values[nameSection].visit_end_time, 'HH:mm:ss')
        .format('HH:mm:ss'),

      evv: {
        schedule_start_time: values[nameSection].evv_data.schedule_start_time,
        schedule_end_time: values[nameSection].evv_data.schedule_end_time,
        travel_mode: values[nameSection].travel_mode,

        schedule_start_datetime: `${values[nameSection].travel_start_dates} ${moment(values[nameSection].travel_start_dates + ' ' + values[nameSection].travel_start_datetime).format('HH:mm:ss')}`,
        //   values[nameSection].travel_start_dates +
        //   'T' +
        //  values[nameSection].travel_start_datetime,

        schedule_end_datetime: `${values[nameSection].travel_end_dates} ${moment(values[nameSection].travel_end_dates + ' ' + values[nameSection].travel_end_datetime).format('HH:mm:ss')}`,
        // values[nameSection].travel_end_dates +
        // 'T' +
        // values[nameSection].travel_end_datetime,
        manual_assign: values[nameSection].manualAssigned,
        schedule_start_date: values[nameSection].evv_data.schedule_start_date,
        schedule_end_date: values[nameSection].evv_data.schedule_end_date,
      },
      //earliest and latest time plus critical time
      latest_start_time:
        values[nameSection] &&
        values[nameSection].visit_end_date &&
        values[nameSection].latest_start_time,

      earliest_start_time:
        values[nameSection] &&
        values[nameSection].visit_end_date &&
        values[nameSection].earliest_start_time,

      time_critical:
        values[nameSection] &&
        values[nameSection].visit_end_date &&
        values[nameSection].time_critical,
      // actual_visit start date with time
      actual_visit_start_time:
        values[nameSection].evv_data.actual_start_date &&
          values[nameSection].evv_data.actual_visit_start_time
          ? values[nameSection] &&
          values[nameSection].evv_data.actual_start_date &&
          values[nameSection].evv_data.actual_visit_start_time &&
          values[nameSection].evv_data.actual_start_date +
          'T' +
          moment(
            values[nameSection].evv_data.actual_visit_start_time,
            'HH:mm:ss'
          ).format('HH:mm:ss')
          : null,

      // actual_visit end date with time
      actual_visit_end_time:
        values[nameSection].evv_data.actual_end_date &&
          values[nameSection].evv_data.actual_visit_end_time
          ? values[nameSection] &&
          values[nameSection].evv_data.actual_end_date &&
          values[nameSection].evv_data.actual_visit_end_time &&
          values[nameSection].evv_data.actual_end_date +
          'T' +
          moment(
            values[nameSection].evv_data.actual_visit_end_time,
            'HH:mm:ss'
          ).format('HH:mm:ss')
          : null,

      //actual_time_notes
      actual_time_notes:
        values[nameSection].evv_data.warning_message &&
        values[nameSection].evv_data.warning_message,

      //service notes
      notes: values[nameSection].notes ? values[nameSection].notes : null,
      //client type
      type_id: values[nameSection] ? values[nameSection].type : null,

      self_funded: values[VISITS_VALUES].self_funded == 1 ? true : false,
      funder: values[VISITS_VALUES].funder
        ? values[VISITS_VALUES].funder
        : null,
      category: values[VISITS_VALUES].category,

      ////service category
      service_category: values[nameSection]
        ? values[nameSection].service_category
        : null,
      visit_status: values[nameSection]
        ? values[nameSection].visit_status
        : null,
      visit_type: values[nameSection] ? values[nameSection].visit_type : null,
      visit_priority: values[nameSection]
        ? values[nameSection].visit_priority
        : null,
      service_destination: values[nameSection]
        ? values[nameSection].service_destination
        : null,
      type_id: values[nameSection] ? values[nameSection].type : null,
      self_funded: values[VISITS_VALUES].self_funded == 1 ? true : false,
      funder: values[VISITS_VALUES].funder
        ? values[VISITS_VALUES].funder
        : null,
      category: values[VISITS_VALUES].category,
      requirement: [
        {
          id: values.visitValues.req_replica.id,
          no_of_employees: values[VISITS_VALUES]
            ? values[VISITS_VALUES].req_replica.no_of_employees
            : null,
          start_time: values[VISITS_VALUES].req_replica.start_time,
          end_time: values[VISITS_VALUES].req_replica.end_time,
          duration: values[VISITS_VALUES].req_replica.duration,
          // visitid get api end id
          visit: values.visit_id,
          skill_level: values[VISITS_VALUES]
            ? values[VISITS_VALUES].req_replica.skill_level
            : null,
          preferred_gender: values[VISITS_VALUES]
            ? values[VISITS_VALUES].req_replica.preferred_gender
            : null,
          skill: skillIdValues,
          // specific_groups: specific_groupsValues,
          competency: competencyValues,
        },
      ],
      new_requirement: new_requirement_add_new_employee,
    };

    if (values[nameSection].evv_data.id) {
      obj.evv.id = values[nameSection].evv_data.id
    } else {
      delete obj.evv
    }
    if (force) {
      obj.force = true;
      setIsSaveDisable(true)
    }
    try {
      setLoadingSave(true)
      let res = await setUpdateSchedularVisit(obj, schedularVisitId);
      if (res.status == 'FAIL') {
        setLoadingSave(false)
        if (res.message == 'Unable to reach on visit time.') {
          if (force) {
            setModalUnassignValue(false);
            handleAddVisitModal();
            setIsSaveDisable(false)
            onShowAlert('error', res.message)
            searchScheduler();
          } else {
            setModalUnassignValue(true);
          }
        } else {
          setModalUnassignValue(false);
          handleAddVisitModal();
          setIsSaveDisable(false)
          onShowAlert('error', res.message)
          searchScheduler();
        }
        // onShowAlert(res.status?'success':'error',res.message)
      } else {
        setModalUnassignValue(false);
        handleAddVisitModal();
        setIsSaveDisable(false);
        setLoadingSave(false)
        searchScheduler();
      }
    } catch (error) {
      setLoadingSave(false)
      setIsSaveDisable(false)
    } finally {
      //searchScheduler();
      // handleAddVisitModal();
    }
  };
  console.log(values, 'HHHHHHHHH');
  const employeeName = () => {
    if (values.employee && values.employee.id) {
      return employees.find(_employee => _employee.id === values.employee.id)
        .name;
    } else {
      return '';
    }
  };

  useEffect(() => {
    data();
  }, [clientdata.full_name]);

  const data = async () => {
    if (clientdata.full_name != undefined) {
      const clientName = await clientdata.full_name;
      const arrName = clientName.split(' ');
      const initialOfFirstName = clientName.charAt(0).toUpperCase();
      const initialOfLastName = arrName[arrName.length - 1]
        .charAt(0)
        .toUpperCase();
      setIntialsOfFirstName(initialOfFirstName);
      setIntialsOfLastName(initialOfLastName);
    }
  };

  useEffect(() => {
    const visitStartTime = values[nameSection].start_date
      ? moment(values[nameSection].start_date).format('h:mm A')
      : 'Unset';
    const visitEndTime = values[nameSection].end_date
      ? moment(values[nameSection].end_date).format('h:mm A')
      : 'Unset';

    setVisitStart(visitStartTime);
    setVisitEnd(visitEndTime);
  }, [values[nameSection]]);

  const toggleVisitView = () => {
    setShowServiceContractDetails(!showServiceContractDetails);
  };

  //data for add new employee
  const addNewVisitEmployee = () => {
    if (!values.visitValues.new_requirement)
      values.visitValues.new_requirement = [];
    values.visitValues.new_requirement.push({
      id: '',
      // client_service_visit: dataVisit.id || '',
      // skillEdit:true,
    });
    setValues({ ...values });
    setSkillAddedMessage('');
  };

  const dropDownHandleSkillNewEmployee = (item, indexEmployee) => {
    const reviewSkills = values.visitValues.new_requirement;
    reviewSkills.forEach(employeeElement => {
      if (!employeeElement.skills) {
        employeeElement.skills = [];
      }
    });

    setSkillAddedMessage('');
    values.visitValues.new_requirement[indexEmployee].skills = item.skill;

    if (item.competency) {
      const reviewCompetency = values.visitValues.new_requirement;
      reviewCompetency.forEach(employeeElement => {
        if (!employeeElement.competency) {
          employeeElement.competency = [];
        }
      });

      values.visitValues.new_requirement[indexEmployee].competency =
        item.competency;
    }
    if (item.specific_groups) {
      const reviewCompetency = values.visitValues.new_requirement;
      reviewCompetency.forEach(employeeElement => {
        if (!employeeElement.specific_groups) {
          employeeElement.specific_groups = [];
        }
      });

      values.visitValues.new_requirement[indexEmployee].specific_groups =
        item.specific_groups;
    }

    setValues({ ...values });
  };

  const assignSuggestedEmployee = employeeId => {
    let body = {
      visit_ids: schedularVisitId,
      employee_id: employeeId,
    };
    AssignAllVisits(body).then(res => {
      if (res.statusCode == 400) {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: res.message,
        });
      } else {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Employee Assigned Sucessfully!',
        });
      }
      //setAddVisit(false)
      searchScheduler();
    });
  };

  const dropDownHandleEmployeeNewEmployee = (item, indexEmployee) => {
    const reviewEmployees = values.visitValues.new_requirement;
    reviewEmployees.forEach(employeeElement => {
      if (!employeeElement.skill_level) {
        employeeElement.skill_level = '';
      }
    });

    values.visitValues.new_requirement[indexEmployee].skill_level =
      item.job_title;

    setValues({ ...values });
  };

  const dropDownHandleGenderNewEmployee = (item, indexGender) => {
    const reviewEmployees = values.visitValues.new_requirement;
    reviewEmployees.forEach(employeeElement => {
      if (!employeeElement.gender) {
        employeeElement.gender = '';
      }
    });

    values.visitValues.new_requirement[indexGender].gender = item.gender;

    setValues({ ...values });
  };

  const deleteVisitEmployeeNewEmployee = (data, indexEmployee) => {
    toDeleteVisitEmployee.push({ ...data });

    if (
      values.visitValues.new_requirement &&
      values.visitValues.new_requirement.length > 0
    ) {
      values.visitValues.new_requirement.splice(indexEmployee, 1);
    }
    setValues({ ...values });
    setSkillAddedMessage('');
  };

  const isCurrentTab = tab => {
    return tabSelected === tab;
  };

  const checkTabSwitch = tabName => {
    setTabSelected(tabName);
    if (tabName !== tab3) {
      history.push({
        pathname: '/scheduler',
      });
    }
  };

  const dropDownHandleQuantityNewEmployee = (item, indexEmployee) => {
    values.visitValues.new_requirement[indexEmployee].no_of_employees =
      item.no_of_employees;
    setValues({ ...values });
  };

  useEffect(() => {
    if (tabSelected == tab3) {
      loadScore();
    }
  }, [props.location.search, values.limitperpage, tabSelected]);

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * PER_PAGE_TEN;
    return {
      offset,
    };
  };

  const loadScore = () => {
    const pageToSearch = getPageToSearchParam(props.location.search);
    // if (!loadingNew) {
    //   setLoadingNew(true);
    // }
    getScoreData(
      scoreDataId,
      { ...values, ...pageToSearch },
      values.limitperpage
    ).then(response => {
      if (response) {
        // setLoadingNew(false);
      }
      setScoreDataById(response.results);
      setPages(Math.ceil(response.count / values.limitperpage));
    });
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/scheduler',
      search: stringified,
    });
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };

  // const changeFunderName = (e) => {
  //   console.log(e.target.value,"QQQQQQQ")
  //   values[nameSection].name = e.target.value
  //   setValues({ ...values })
  // }

  const funderLists = () => {
    getFundersList().then(res => {
      if (res.length > 0) {
        let newFunders = res.map(funder => {
          return {
            id: funder.id,
            name: funder.funder_name,
          };
        });
        setFunderList([...newFunders]);
      }
    });
  };
  const dropDownHandleSelects = (item, nameItem) => {
    values[nameItem][nameSection] = item[nameItem];
    setValues({ ...values });
  };

  const displayValue = clientdata.branch_name ||
    (clientdata.date_of_birth
      ? `${moment(clientdata.date_of_birth).format('DD-MM-YYYY')} (${moment().diff(
        clientdata.date_of_birth,
        'years'
      )})`
      : '');
  console.log(values, tabSelected, 'ooooo');
  return (
    <ModalContainer max_width={'80%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'70%'}
        showRowInfo
        minWidthTitle="8.5rem"
        photoRow={clientdata?.photo || null}
        name={clientdata?.full_name || intialsOfFirstName}
        lastName={intialsOfLastName}
        rowInfoData={[
          {
            label: clientdata?.location ? "Location :" : 'Name :',
            value: clientdata?.full_name,
          },
          {
            label: clientdata?.branch_name ? 'Branch' : 'DOB:',
            value: displayValue,
          },
        ]}
        loading={loadingNew}>
        <div className="p-2 p-sm-3 p-md-4">
          <div className="d-flex justify-content-around text-center">
            {readPermissionVisitTab && (
              <TabStyled
                active={isCurrentTab(tab1)}
                // onClick={() => {
                //   setTabSelected(tab1);
                // }}
                onClick={() => checkTabSwitch(tab1)}>
                Visit
              </TabStyled>)
            }
            {readPermissionVisitRate && (
              <TabStyled
                active={isCurrentTab(tab2)}
                // onClick={() => {
                //   setTabSelected(tab2);
                // }}
                onClick={() => checkTabSwitch(tab2)}>
                Rate
              </TabStyled>)
            }
            {readPermissionVisitScore && (
              <TabStyled
                active={isCurrentTab(tab3)}
                // onClick={() => {
                //   setTabSelected(tab3);
                // }}
                onClick={() => checkTabSwitch(tab3)}>
                Score
              </TabStyled>)
            }
            {readPermissionVisitEmployeeSuggestion && (
              <TabStyled
                active={isCurrentTab(tab4)}
                // onClick={() => {
                //   setTabSelected(tab4);
                // }}
                onClick={() => checkTabSwitch(tab4)}>
                Employee Suggestion
              </TabStyled>)
            }
            {readPermissionVisitAuditHistory && (
              <TabStyled
                active={isCurrentTab(tab5)}
                // onClick={() => {
                //   setTabSelected(tab4);
                // }}
                onClick={() => checkTabSwitch(tab5)}>
                Audit History
              </TabStyled>)
            }
            {(readPermissionVisitTask && !clientdata?.location) && (
              <TabStyled
                active={isCurrentTab(tab6)}
                onClick={() => checkTabSwitch(tab6)}
              >
                Tasks
              </TabStyled>)
            }
            {(readPermissionVisitMedication && !clientdata?.location) && (
              <TabStyled
                active={isCurrentTab(tab7)}
                onClick={() => checkTabSwitch(tab7)}
              >
                Medication
              </TabStyled>)
            }
            {(readPermissionVisitExpense && !clientdata?.location) && (
              <TabStyled
                active={isCurrentTab(tab9)}
                onClick={() => checkTabSwitch(tab9)}
              >
                Expenses
              </TabStyled>)
            }
            {(readPermissionBodyMap && !clientdata?.location) && <TabStyled
              active={isCurrentTab(tab8)}
              onClick={() => checkTabSwitch(tab8)}
            >
              Body Map
            </TabStyled>}
            {((readPermissionCareNotes || readPermissionCareNotesReview) && !clientdata?.location) && <TabStyled
              active={isCurrentTab(tab10)}
              onClick={() => checkTabSwitch(tab10)}
            >
              Care Notes
            </TabStyled>}

            {true && <TabStyled
              active={isCurrentTab(tab11)}
              onClick={() => checkTabSwitch(tab11)}
            >
              Care Forms
            </TabStyled>}

          </div>
        </div>
        <ShowTab show={isCurrentTab(tab1)}>
          {tabSelected == tab1 && (
            <VisitTabContent
              disableLockUnlock={disableLockUnlock}
              handleLockUnlockVisit={handleLockUnlockVisit}
              isLocation={clientdata?.location}
              editMode={editMode}
              employees={employees}
              values={values}
              dropDownHandleEmployee={dropDownHandleEmployee}
              choices={choices}
              setValues={setValues}
              useInput={useInput}
              nameSection={nameSection}
              dropDownHandleVisit={dropDownHandleVisit}
              readOnly={readOnly}
              dropdownHandleReplicaEmployee={dropdownHandleReplicaEmployee}
              createAddressFormat={createAddressFormat}
              dropdownHandleReplicaEmployeeSkill={dropdownHandleReplicaEmployeeSkill}
              clientSelectedId={clientSelectedId}
              clientdata={clientdata}
              funderList={funderList}
              toggleVisitView={toggleVisitView}

              showServiceContractDetails={showServiceContractDetails}
              fullBind={fullBind}
              assignedEmployee={assignedEmployee}
              handleAddVisitModal={handleAddVisitModal}
              searchScheduler={searchScheduler}
              handleCancelVisit={handleCancelVisit}
              handleDeleteVisit={handleDeleteVisit}

              onShowAlert={onShowAlert}
              dropdownHandleAdhocEmployeeSkill={dropdownHandleAdhocEmployeeSkill}
              dropdownHandleAdhocEmployee={dropdownHandleAdhocEmployee}
              dropDownHandleSkillNewEmployee={dropDownHandleSkillNewEmployee}
              dropDownHandleEmployeeNewEmployee={dropDownHandleEmployeeNewEmployee}
              dropDownHandleQuantityNewEmployee={dropDownHandleQuantityNewEmployee}
              dropDownHandleGenderNewEmployee={dropDownHandleGenderNewEmployee}
              addNewVisitEmployee={addNewVisitEmployee}
              deleteVisitEmployeeNewEmployee={deleteVisitEmployeeNewEmployee}
              skillAddedMessage={skillAddedMessage}
              errorMessage={errorMessage}
              handleSchedulerPublishOrUnpublish={handleSchedulerPublishOrUnpublish}
              iSPublished={iSPublished}
              handleSchedulerRevert={handleSchedulerRevert}
              loadingSave={loadingSave}
              handleSaveEmployee={handleSaveEmployee}
              showQuestion={showQuestion}
              employeeName={employeeName}
              forceTrueupdateEmployee={forceTrueupdateEmployee}
              //cancel props
              fullBindCancel={fullBindCancel}
              useInputCancel={useInput}
              nameSectionCancel={nameSectionCancel}
              saveButtonIcon={saveButtonIcon}
              // loadingSave={loadingSave}
              textStatus={textStatus}
              saveCancelVisit={saveCancelVisit}
              updateCancelVisit={updateCancelVisit}
              dropDownHandleCancelVisit={dropDownHandleCancelVisit}
              listView={listView}
            />)}
        </ShowTab>

        {/* for  score tab below  */}
        <ComponentDynamic loading={loadingNew}>
          <ShowTab show={isCurrentTab(tab3)}>
            <div
              className={
                ' ps-3 pe-3 pb-3 mb-4  justify-content-center  d-flex'
              }>
              {scoreDataById && scoreDataById.length > 0 ? (
                <div className={' mt-2 '} style={{ width: '100%' }}>
                  <Table
                    headerColumns={headerColumns}
                    headerPadding={false}
                    noEditOption
                    fontSize="0.75rem">
                    <div>
                      {scoreDataById &&
                        scoreDataById.map((list, index) => {
                          return (
                            <div key={index}>
                              <Row bgColor Shadow>
                                <Col
                                  max_width={EX_VERY_LARGE_COL}
                                  Capitalize
                                  Center>
                                  {list &&
                                    list.constraint_name &&
                                    list.constraint_name}
                                </Col>
                                <Col max_width={EX_SMALL_COL} Capitalize Center>
                                  {list && list.score && list.score}
                                </Col>
                              </Row>
                              <div className="pb-2 mb-2" />
                            </div>
                          );
                        })}

                      <div className="mt-3">
                        <Pagination
                          totalPages={pages}
                          currentPage={currentPage}
                          values={values}
                          dropDownHandleSelect={dropDownHandleSelectPagination}
                          number={PAGE_SIZE_RENDER}
                          dataLength={scoreDataById.length}
                        />
                      </div>
                    </div>
                  </Table>
                </div>
              ) : (
                <div>
                  <p class="text-danger">
                    Visit is not assigned by scheduler !!
                  </p>
                </div>
              )}
            </div>
          </ShowTab>
        </ComponentDynamic>
        {/* for Rate  below */}

        <ShowTab show={isCurrentTab(tab2)}>
          {/* <div max_width={'92%'}> */}
          <div className="ps-3 pe-3 pb-3 ">
            <RateDetails
              scoreDataId={scoreDataId}
              editMode={editMode}
              handleAddVisitModal={handleAddVisitModal}
              searchScheduler={searchScheduler}
              AssignedName={nameAssigned}
              tabSelected={tabSelected}
            />
          </div>
        </ShowTab>
        <ShowTab show={isCurrentTab(tab4)}>
          {tabSelected == tab4 && (
            <div className="ps-3 pe-3 pb-3 ">
              <SuggestedEmployee
                visit_id={schedularVisitId}
                employees={employees}
                searchScheduler={searchScheduler}
                assignSuggestedEmployee={assignSuggestedEmployee}
              />
            </div>
          )}
        </ShowTab>

        <ShowTab show={isCurrentTab(tab5)}>
          {tabSelected == tab5 && (
            <div className="ps-3 pe-3 pb-3 ">
              <AuditHistory
                visitId={scoreDataId}
                props={props}
                choices={choices}
              />
            </div>
          )}
        </ShowTab>
        <ShowTab show={isCurrentTab(tab6)}>
          {tabSelected == tab6 && (
            <div className="ps-3 pe-3 pb-3 ">
              <VisitTasks
                visitId={scoreDataId}
                props={props}
                choices={choices}
                visitData={values[nameSection]}
              />
            </div>
          )}
        </ShowTab>
        <ShowTab show={isCurrentTab(tab7)}>
          {tabSelected == tab7 && (
            <div className="ps-3 pe-3 pb-3 ">
              <MedicationPopup
                visitId={scoreDataId}
                props={props}
                choices={choices}
                client_name={clientdata.full_name}
                clientSelectedId={clientSelectedId}
                visitData={values[nameSection]}
                employees={employees}
                clients={clients}
              />
            </div>
          )}
        </ShowTab>

        {isCurrentTab(tab9) && (
          <ShowTab show={isCurrentTab(tab9)}>
            <Expenses
              choices={choices}
              visitId={scoreDataId}
              clientSelectedId={clientSelectedId}
              assignedEmployee={assignedEmployee}
              props={props}
              values={values}
              readPermissionVisitExpense={readPermissionVisitExpense}
              createPermissionVisitExpense={createPermissionVisitExpense}
              updatePermissionVisitExpense={updatePermissionVisitExpense}
              deletePermissionVisitExpense={deletePermissionVisitExpense}
              employees={employees}

            />
          </ShowTab>)}

        <ShowTab show={isCurrentTab(tab8)}>
          {tabSelected == tab8 && (
            <div className="ps-3 pe-3 pb-3 ">
              <BodyMap
                visitId={scoreDataId}
                props={props}
                clientSelectedId={clientSelectedId}
                readPermissionBodyMap={readPermissionBodyMap}
                createPermissionBodyMap={createPermissionBodyMap}
                updatePermissionBodyMap={updatePermissionBodyMap}
                deletePermissionBodyMap={deletePermissionBodyMap}
              />
            </div>
          )

          }
        </ShowTab>
        <ShowTab show={isCurrentTab(tab10)}>
          {tabSelected == tab10 && (
            <div className="ps-3 pe-3 pb-3 ">
              <CareNotes
                visitId={scoreDataId}
                clientSelectedId={clientSelectedId}
                employeeId={values.employee}
                visitData={values[nameSection].evv_data}

                readPermissionCareNotes={readPermissionCareNotes}
                createPermissionCareNotes={createPermissionCareNotes}
                updatePermissionCareNotes={updatePermissionCareNotes}
                deletePermissionCareNotes={deletePermissionCareNotes}

                readPermissionCareNotesReview={readPermissionCareNotesReview}
                createPermissionCareNotesReview={createPermissionCareNotesReview}
                updatePermissionCareNotesReview={updatePermissionCareNotesReview}
                deletePermissionCareNotesReview={deletePermissionCareNotesReview}
              />
            </div>
          )

          }
        </ShowTab>

        <ShowTab show={isCurrentTab(tab11)}>
          {tabSelected == tab11 && (
            <div className="ps-3 pe-3 pb-3 ">
              <VisitFroms
                visitId={scoreDataId}
                clientSelectedId={clientSelectedId}
                employeeId={values.employee}
                visitData={values[nameSection]}

                readPermission={true}
                createPermission={true}
                updatePermission={true}
                deletePermission={true}
              />
            </div>
          )}
        </ShowTab>


        <ConfirmDecision
          type="confirm"
          title="Confirm"
          isDisable={isSaveDisable}
          body={
            'Unable to reach on visit time. Do you really you want to assign the visit ? '
          }
          onOk={() => {
            updateEmployeeSchedularVisit(true);
          }}
          onNo={() => {
            setModalUnassignValue(false);
          }}
          {...bindModalUnassign}
        />
      </ModalDynamic>
    </ModalContainer >
    // </ComponentDynamic>
  );
};

AddVisit.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  fullBind: PropTypes.object.isRequired,
  dropDownHandleVisit: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default AddVisit;
