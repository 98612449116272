import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo'
import { useModal } from 'hooks/ModalHook';
import React from 'react'
import { useEffect } from 'react';
import { ModalContainer } from 'shared/styles/constants/tagsStyles'
import { InputFieldChangePwd } from '../styles';
import { useState } from 'react';
import { MdRemoveRedEye } from 'react-icons/md';
import { useInput } from 'hooks/InputHook';
import { ErrorMessage } from 'shared/styles/AuthComponents';
import { Fragment } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { PURPLE } from 'shared/styles/constants/colors';
import { deleteAccount, verifyToken } from 'utils/api/SettingsApi';
import { clearToken } from 'utils/localStorage/token';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';
import ConfirmationModal from './ConfirmationModal';

function DeleteAccount({ setDeleteAccountModalValue, deleteAccountModalValue, fullBindDeleteAccount }) {
  const location = useLocation();
  const history = useHistory()
  const toast = useRef(null)
  const [typeInput, setTypeInput] = useState("text");
  const [showErrorMessage, setShowErrorMessage] = useState({ status: false, message: '', })
  const [loadingSave, setLoadingSave] = useState(false);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);

  const { value: password, bind: bindPassword } = useInput('');
  const {
    setValue: setModalValueConfirmSave,
    value: modalValueConfirmSave,
    fullBind: fullBindConfirmSave,
  } = useModal(false);

  const handleClick = (type) => {
    setTypeInput(type)
  };

  const handelSaveClick = async () => {
    try {
      const body = { password: password }
      const res = await verifyToken(body)
      console.log(res, "res")
      if (res.statusCode == 200) onSuccess(res)
      else onError(res)
    } catch (error) {
      onError(error)
    }
  }
  const onSuccess = (res) => {
    setLoadingSave(false)
    setModalValueConfirmSave(true)
    setTextStatus(SAVED)
    setButtonIcon(CHECK)
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: `Password verified.`,
    });
  }

  const onError = (err) => {
    const message = typeof err?.message == "string" ? err?.message : "Something went wrong!"
    setLoadingSave(false)
    // setModalChangePassword(false)
    setButtonIcon(CROSS)
    setTextStatus(ERROR)
    showErrorMessage.message = message
    showErrorMessage.status = true;
    setShowErrorMessage({ ...showErrorMessage })
    toast.current && toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: `${message}`,
    });
  }

  const handleSubmit = async () => {
    try {
      const body = { email: localStorage.getItem("user_email") }
      const res = await deleteAccount(body)
      if (res.statusCode == 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${res?.message}`,
        });
        clearToken();
        window.location.reload();
      }
    } catch (error) {
      const message = typeof error?.message == "string" ? error?.message : "Something went wrong!"
      toast.current && toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${message}`,
      });
    }
  }
  return (
    <ModalContainer max_width={'40%'}>
      <Toast ref={toast} />
      <ModalNoInfo
        loading={false}
        title={"Delete Account"}
        loadingMaxWidth={'10rem'}
        {...fullBindDeleteAccount}
        max_width={'90%'}
        minWidthTitle="8.5rem">
        <div className={'d-block p-3 mt-4'}>
          <div className={'font-weight-normal mb-2 '}>
            <div>Verify Password</div>
          </div>
          <ChangePwdStyle>
            <InputFieldChangePwd
              type={typeInput}
              autoComplete="new-password"
              placeholder="**********"
              value={password}
              error={showErrorMessage.status}
              {...bindPassword}
              width='100%'
            />
            <span
              onMouseDown={() => handleClick('text')}
              onMouseUp={() => handleClick('password')}>
              <MdRemoveRedEye />
            </span>
          </ChangePwdStyle>

          <div className={'d-sm-block text-center text-md-right justify-content-end mt-2'}>
            <div className={'mb-2'}>
              {showErrorMessage.status ? (
                <ErrorMessage className='ms-4' style={{ textAlign: "start" }}>
                  <label className={'mt-2'}>{showErrorMessage.message}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={`ms-0 ms-md-3 me-2 ${showErrorMessage.status ? "" : "mt-3"}`}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  disabled={loadingSave}
                  onClick={handelSaveClick}>
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>

      </ModalNoInfo>
      {modalValueConfirmSave &&
        <ConfirmationModal
          fullBind={fullBindConfirmSave}
          setModalValue={setModalValueConfirmSave}
          type={'delete'}
          title
          handleSubmit={handleSubmit}
        />}
    </ModalContainer>
  )
}

export default DeleteAccount



const ChangePwdStyle = styled.div`
 
  span {
    z-index: 1;
    position: relative;
    top: -42px;
    right: -550px;
    color: #868686;
    cursor: pointer;
    font-size: 1.2rem;
  }

  span:active {
    color: ${PURPLE};
  }
`;