
import React from 'react'
import { NotificationContainer } from './styles'

function NotificationTable({
    groupedData,
    handleAllCheck,
    selectEmailAll,
    selectDesktopAll,
    selectInAppAll,
    handleEmailChange
}) {

    const fs13 = {
        fontSize: "14px",
        fontWeight: 400,
        paddingTop: "15px"
    }
    return (
        <NotificationContainer>
            <div style={{background:"gainsboro"}} className='w-75 parent'>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col d-flex align-items-center" style={{ width: "8.125rem" }}>
                                <div className={'d-flex align-items-center checkbox-container'}>
                                    <label
                                        style={{ top: "-2px", position: "relative" }}
                                        className={'checkbox-label me-2'}>
                                        <input
                                            type="checkbox"
                                            title="Update all column"
                                            aria-label={""}
                                            checked={selectEmailAll}
                                            onChange={() => handleAllCheck('email')}
                                        />
                                        <span className="checkbox-custom" />
                                    </label>
                                    <span className='fs-13-b'> Email</span>
                                </div>
                            </th>
                            <th scope="col" style={{ width: "8.125rem" }}>
                                <div className={'d-flex align-items-center checkbox-container'}>
                                    <label
                                        style={{ top: "-2px", position: "relative" }}
                                        className={'checkbox-label me-2'}>
                                        <input
                                            type="checkbox"
                                             title="Update all column"
                                            aria-label={""}
                                            checked={selectDesktopAll}
                                            onChange={() => handleAllCheck('desktop')}
                                        />
                                        <span className="checkbox-custom" />
                                    </label>
                                    <span className='fs-13-b'> Desktop</span>
                                </div>
                            </th>
                            <th scope="col" style={{ width: "8.125rem" }}>

                                <div className={'d-flex align-items-center checkbox-container'}>
                                    <label
                                        style={{ top: "-2px", position: "relative" }}
                                        className={'checkbox-label me-2'}>
                                        <input
                                            type="checkbox"
                                            // ref={scrollValue}
                                            aria-label={""}
                                            checked={selectInAppAll}
                                            onChange={() => handleAllCheck('inapp')}
                                        />
                                        <span className="checkbox-custom" />
                                    </label>
                                    <span className='fs-13-b'> In-App</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            groupedData.map((item) => {
                                return (
                                    <>
                                        <p className='setting-list-label'>{item.category}</p>
                                        {item.items.map((el) => {
                                            return (
                                                <tr>
                                                    <th scope="row fs-13" style={fs13}>{el.name}</th>
                                                    <td style={{ width: "8.125rem" }}>
                                                        <div className={'d-flex align-items-center checkbox-container'}>
                                                            <label
                                                                style={{ top: "8px", position: "relative" }}
                                                                className={'checkbox-label me-2'}>
                                                                <input
                                                                    type="checkbox"
                                                                    aria-label={""}
                                                                    checked={el.isEmail}
                                                                    onChange={() => handleEmailChange(el.item, 'email')}
                                                                />
                                                                <span className="checkbox-custom" />
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: "8.125rem" }}>
                                                        <div className={'d-flex align-items-center checkbox-container'}>
                                                            <label
                                                                style={{ top: "8px", position: "relative" }}
                                                                className={'checkbox-label me-2'}>
                                                                <input
                                                                    type="checkbox"
                                                                    aria-label={""}
                                                                    checked={el.isDesktop}
                                                                    onChange={() => handleEmailChange(el.item, 'desktop')}
                                                                />
                                                                <span className="checkbox-custom" />
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td style={{ width: "8.125rem" }}>
                                                        <div
                                                            style={{ top: "8px", position: "relative" }}
                                                            className={'d-flex align-items-center checkbox-container'}>
                                                            <label className={'checkbox-label me-2'}>
                                                                <input
                                                                    type="checkbox"
                                                                    aria-label={""}
                                                                    checked={el.isInApp}
                                                                    onChange={() => handleEmailChange(el.item, 'inapp')}
                                                                />
                                                                <span className="checkbox-custom" />
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        )}
                                    </>
                                )
                            })

                        }

                    </tbody>
                </table>
            </div>
        </NotificationContainer>
    )
}

export default NotificationTable