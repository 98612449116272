import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo'
import React from 'react'
import { ErrorMessage, ModalContainer } from '../styles'
import DateSelector from 'components/SharedComponents/DateSelector';
import { useForm } from 'hooks/FormHook';
import { setRegularShiftEndDate } from 'utils/api/EmployeeApi';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED, SERVER_ERROR } from 'utils/constants/messages';
import { Fragment } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { useState } from 'react';
import { FlexGrid3Container, FlexGrid3Item } from 'shared/styles/constants/tagsStyles';
import { useEffect } from 'react';

function SetEndDatePopup({
    itemName,
    shouldUpdateEmployeeStatus,
    checkedRows,
    fullBind,
    toast,
    setShiftEndDate,
    setCheckedRows,
    setTitle }) {
    const { values, setValues, useInput, errors, setCheckRequires } = useForm({});
    const [errorMessage, setErrorMessage] = useState('')
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingSave, setLoadingSave] = useState(false);
    const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);
    const [disableSave, setDisableSave] = useState(false)
    const [addFullBind, setAddFullBind] = useState(fullBind);

    useEffect(() => {
      setAddFullBind({
        ...addFullBind,
        title: 'Set Regular Shift End Date',
      });
    }, [fullBind]);
   
    const handleSubmit = async () => {
        if (loadingSave) {
            return
        }
        if (errors.length > 0) {
            setCheckRequires(true)
            setErrorMessage(InvalidOrEmptyValues);
            return
        }
        setLoadingSave(true)
        setDisableSave(true)
        setErrorMessage('');

        let body = {
            regularShiftIds: checkedRows.join(","),
            end_date: values.end_date
        }


        try {
            const res = await setRegularShiftEndDate(body)
            if (res.status == 200) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${res.message}`,
                });
                setShiftEndDate(false)
                shouldUpdateEmployeeStatus()
                setSaveButtonIcon(CHECK);
                setTextStatus(SAVED);
                setLoadingSave(false);
                setCheckedRows(prev => ({ ...prev, [itemName]: [] }))
                setErrorMessage('')
                setDisableSave(false)
            } else {
                setSaveButtonIcon(CROSS);
                setTextStatus(ERROR);
                setLoadingSave(false);
                setErrorMessage(res.message)
                setDisableSave(false)
            }
        }
        catch (error) {
            setSaveButtonIcon(CROSS);
            setTextStatus(ERROR);
            setLoadingSave(false);
            setErrorMessage(SERVER_ERROR)
            // closeAssignModal()
        }
    }
    return (
        <div>
            <ModalContainer max_width={'40%'}>
                <ModalNoInfo
                    title ='Set Regular Shift End Date'
                    {...addFullBind}
                    max_width={'60%'}
                    // minWidthTitle=""
                    loading={false} >
                    <div className='w-100 p-3'>
                        <FlexGrid3Container className="w-100">
                            <FlexGrid3Item ResizeWidth={"50"} className="pe-1">
                                <DateSelector
                                    isRequired
                                    labelTop={'End Date'}
                                    {...useInput(`end_date`, 'isRequired')}
                                />
                            </FlexGrid3Item>
                        </FlexGrid3Container>


                        <div className={'d-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'}>
                            {errorMessage ? (
                                <div className={'m-2'}>
                                    <ErrorMessage>
                                        <label className={'mt-2'}>{errorMessage}</label>
                                    </ErrorMessage>
                                </div>) : (
                                <Fragment />
                            )}

                            <div className={'d-flex justify-content-center justify-content-md-end'}>

                                <div className={'ms-0 ms-md-3 mb-1'}>
                                    <PrimaryButtonForm minWidth="6rem"
                                        onClick={handleSubmit}
                                        disabled={disableSave}>
                                        <span className={'me-2'}>{textStatus}</span>
                                        {!loadingSave ? (
                                            saveButtonIcon
                                        ) : (
                                            <span
                                                className="spinner-border spinner-border-sm me-1"
                                                role="status"
                                            />
                                        )}
                                    </PrimaryButtonForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalNoInfo>
            </ModalContainer>
        </div>
    )
}

export default SetEndDatePopup