import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { DropdownButton, ItemsDropdownStyle, SelectClearButton } from '../NoSelectAllDropdownFilter/styles';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import Checkbox from '../Checkbox';
import {
    SearchAddContainer,
    SearchContainer,
} from 'shared/styles/constants/tagsStyles';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import colors from 'shared/styles/constants/colors';
import CheckboxBranch from '../CheckboxBranch';
import { CustomDropdownContainer } from './CustomDropdownContainer';
import { useRef } from 'react';
import { useOutsideClickClosePopup } from 'shared/methods';



const CustumSelectorAllDropdownFilter = ({
    items,
    onSelect = () => { },
    placeHolder = 'Select',
    appendItem = '',
    alignmentRight = false,
    initialValue = '',
    isObject = false,
    width = '',
    disabled = false,
    type = '',
    maxheight = '',
    required = false,
    justifyContent = '',
    disableSelection = false,
    noPadding = false,
    // itemsSelected = [],
    // setItemsSelected = () => {},
    itemsSelected,
    setItemsSelected,
    setSelectedFilter,
    hitApiAfterClear = () => { },
    onSelectAllHitApi = () => { },

}) => {
    let dropdownReference = useRef(null)


    const [showDropdown, setShowDropDown] = useState(false);
    const [filteredItems, setFilteredItems] = useState([...items]);
    const [allCompanyList, setAllCompanyList] = useState([])
    const [checked, setChecked] = useState({});
    // const [checkedCompany, setCheckedCompany] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [dropdownLabel, setDropdownLabel] = useState('Various');

    // useEffect(() => {
    //   const checkObj = {};
    //   items.forEach(data => {
    //     checkObj[data.id] = false;
    //   });
    //   // setChecked({...checkObj});  
    //   setFilteredItems([...items]);
    //   // setItemsSelected([...items]);

    // }, [items]);



    useEffect(() => {
        const checkObjCompany = {};
        const arr = []
        setFilteredItems([...items]);
        items.forEach((val) => { if (!arr.includes(val.company) && val.company) arr.push({ company_name: val.company, expand: false }) })
        let uniqueCompanies = {};

        // Filter the array to keep only the first occurrence of each company_name
        let uniqueArr = arr.filter(item => {
            if (!uniqueCompanies[item.company_name]) {
                uniqueCompanies[item.company_name] = true;
                return true;
            }
            return false;
        });

        setAllCompanyList(uniqueArr)
        // setItemsSelected([...items]);
    }, [items]);

    useEffect(() => {
        const checkObj = {};
        items.forEach(data => {
            checkObj[data.id] = false;
        });
        setChecked({ ...checkObj });
    }, []);


    useEffect(() => {
        return function cleanup() {
            dropdownReference.current = null;
            document.removeEventListener('click', handleOutsideClick, false);
        };
    }, []);

    useEffect(() => {
        if (!itemsSelected.length) setDropdownLabel(placeHolder);

        if (itemsSelected.length === 1) setDropdownLabel(itemsSelected[0].name);

        if (itemsSelected.length > 1) setDropdownLabel('Various');

    }, [itemsSelected]);

    //Dropdown formatting and logic

    const composeDropdownClasses = () => {
        let classes = 'dropdown-menu ';
        if (alignmentRight) {
            classes = classes + ' dropdown-menu-right';
        }
        if (showDropdown) {
            classes = classes + ' show';
        }

        return classes;
    };

    const setNode = nodeParam => {
        dropdownReference = nodeParam;
    };

    const handleOutsideClick = e => {
        // ignore clicks on the component itself
        if (dropdownReference !== null) {
            if (dropdownReference.contains(e.target)) {
                return;
            }
        }
        document.getElementById('search').value = '';
        search('');
        setShowDropDown(false);
    };

    const handleComboClick = () => {
        if (!showDropdown) {
            // attach/remove event handler
        //     document.addEventListener('click', handleOutsideClick, true);
        // } else {
        //     document.removeEventListener('click', handleOutsideClick, false);

        }
        setShowDropDown(!showDropdown);
    };
    const onClose = () => setShowDropDown(false)
  useOutsideClickClosePopup(dropdownReference, onClose)

    const theme = {
        main: type,
    };

    const handleOptionClick = (arrow, company) => {
        let arr = allCompanyList.map((item) => {
            if (item.company_name == company) {
                return { company_name: item.company_name, expand: !item.expand }
            } else {
                return { company_name: item.company_name, expand: item.expand }
            }
        })
        setAllCompanyList(arr);
    }

    const search = inputValue => {
        if (inputValue === '') {
            setFilteredItems([...items]);
            setSearchTerm('');

            return;
        }

        const inputRegEx = new RegExp(inputValue.toLowerCase(), '');

        const res = items.filter(item => {
            if (inputRegEx.test(item.name.toLowerCase())) return item;

        });

        setSearchTerm(inputValue);

        setFilteredItems([...res]);

    };

    const handleClearAll = () => {
        setItemsSelected([]);
        setSelectedFilter([])
        for (let key in checked) {
            checked[key] = false;
        }
        hitApiAfterClear(true);

        setChecked({ ...checked });
    };

    const handleSelectAll = () => {
        setItemsSelected([...items]);
        setSelectedFilter([...items])
        // for (let key in checked) {
        //     checked[key] = true;
        // }
        items.forEach(data => {
            checked[data.id] = true;
        });
        setChecked({ ...checked });
        // onSelectAllHitApi(true);
    };

    const handleSelectItem = (e, item) => {
        // e.preventDefault();

        const selection = [...itemsSelected];
        let checkInclude = selection.find((val) => val.id == item.id);
        let indexSelectedItem = selection.findIndex((f) => f.id === item.id)

        checkInclude
            ? selection.splice(indexSelectedItem, 1)
            : selection.push(item);

        setItemsSelected([...selection]);
        setSelectedFilter([...selection])
        checked[item.id] = !checked[item.id];
        // if(checked[item.id]){
        // }
        setChecked({ ...checked });
    };

    const handleSelectCompanyItem = (e, item) => {
        // e.preventDefault();

        const selection = [...itemsSelected];
        filteredItems.filter(val => val.company === item).map((val) => {
            checked[val.id] = !checked[val.id];
            let checkInclude = selection.find((v) => v.id == val.id);
            let indexSelectedItem = selection.findIndex((f) => f.id === val.id)

            checkInclude
                ? selection.splice(indexSelectedItem, 1)
                : selection.push(val);
        })

        setItemsSelected([...selection]);
        setSelectedFilter([...selection])
        // checkedCompany[item] = !checkedCompany[item];
        // if(checkedCompany[item.id]){
        // }
        // setCheckedCompany({ ...checkedCompany });
        setChecked({ ...checked });
    };

    const checkedCompany = (name) => {
        let check = true;
        let temp_filter_data = [...filteredItems]
        const selection = [...itemsSelected];
        temp_filter_data.filter(val => val.company === name).forEach((item) => {
            if ((selection.findIndex((f) => f.id === item.id) === -1)) {
                check = false
            }
        })
        return check;
    }

    return (
        <CustomDropdownContainer
           className={showDropdown ? `w-100 btn-group show` : `w-100 btn-group`}
           ref={dropdownReference}>
            <ThemeProvider theme={theme}>
                <DropdownButton
                    onClick={disabled ? () => { } : () => handleComboClick()}
                    justifyContent={justifyContent}
                    className="button-selected"
                    width={width}
                    itemsSelected={itemsSelected}
                    title={itemsSelected ? 'Various' : placeHolder}
                    noPadding={noPadding}>
                    <h6>
                        {placeHolder} <span>{required ? '*' : ''}</span>
                    </h6>
                    <label>
                        {dropdownLabel} <span>{required && !itemsSelected ? '*' : ''}</span>
                    </label>
                    <i>
                        {showDropdown ? (
                            <FiChevronUp className="align-self-center me-0" />
                        ) : (
                            <FiChevronDown className="align-self-center me-0" />
                        )}
                    </i>
                </DropdownButton>
            </ThemeProvider>
            <div className={`${composeDropdownClasses()} ${width}`} >
                <ItemsDropdownStyle maxheight={maxheight}>
                    <SearchAddContainer className="d-flex align-items-center justify-content-center">
                        <SearchContainer style={{ width: '100%', marginLeft: '10%' }}>
                            <InputLabelTop
                                title={'Select Employees'}
                                no_internal_margin="true"
                                type="text"
                                id="search"
                                label={'Search'}
                                onChange={e => {
                                    search(e.target.value);
                                }}
                                value={searchTerm}
                            />
                        </SearchContainer>
                    </SearchAddContainer>
                    <div className="d-flex" title={'save-clear-all'}>
                        <SelectClearButton
                            onClick={handleSelectAll}
                            className="text-capitalize">
                            Select All
                        </SelectClearButton>
                        <SelectClearButton
                            onClick={handleClearAll}
                            className="text-capitalize">
                            Clear All
                        </SelectClearButton>
                    </div>
                    {filteredItems ? (
                        allCompanyList.map((company, index) => {

                            return (
                                <div>
                                    <div className='d-flex' style={{ backgroundColor: '#f5f2f2' }}>

                                        {/* <p style={{ textAlign: "center", paddingTop: 7, paddingBottom: 7, color: colors.GRAY_700 }}>
                                        {company}
                                    </p> */}
                                        {company.expand ? (
                                            <FiChevronUp className="align-self-center me-0" onClick={() => handleOptionClick('up', company.company_name)} style={{ color: '#6200f9', fontSize: '1.2rem', cursor: 'pointer' }} />
                                        ) : (
                                            <FiChevronDown className="align-self-center me-0" onClick={() => handleOptionClick('down', company.company_name)} style={{ color: '#6200f9', fontSize: '1.2rem', cursor: 'pointer' }} />
                                        )}
                                        <div key={index}>
                                            <button
                                                style={{ paddingLeft: '0.1rem', fontWeight: 600 }}
                                                className="dropdown-item text-capitalize  "
                                                key={`dropdown-item-${index}`}
                                                type="button"
                                                onClick={
                                                    disabled || disableSelection
                                                        ? () => {
                                                            return;
                                                        }
                                                        : e => handleSelectCompanyItem(e, company.company_name)
                                                }
                                                title={!isObject ? company : company.company_name}>
                                                <CheckboxBranch
                                                    id={!isObject ? company : company.id}
                                                    label={!isObject ? company : company.company_name}
                                                    item={company.company_name}
                                                    checked={checkedCompany(company.company_name)}
                                                />
                                            </button>
                                        </div>

                                    </div>
                                    {company.expand &&
                                        filteredItems.filter(val => val.company === company.company_name).map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <button
                                                        className="dropdown-item text-capitalize  "
                                                        key={`dropdown-item-${index}`}
                                                        type="button"
                                                        onClick={
                                                            disabled || disableSelection
                                                                ? () => {
                                                                    return;
                                                                }
                                                                : e => handleSelectItem(e, item)
                                                        }
                                                        title={!isObject ? item : item.name}>
                                                        <Checkbox
                                                            id={!isObject ? item : item.id}
                                                            label={!isObject ? item : item.name}
                                                            item={item}
                                                            checked={checked}
                                                            typeHeader={true}
                                                        />
                                                    </button>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            )
                        })
                    ) : (
                        <></>
                    )}
                </ItemsDropdownStyle>
            </div>
        </CustomDropdownContainer>
    );
};

export default CustumSelectorAllDropdownFilter;
