import Spinner from 'components/SharedComponents/Spinner'
import Table from 'components/SharedComponents/Table'
import { Col, ColsGrouper, IconWrapper, Row } from 'components/SharedComponents/Table/styles'
import { Toast } from 'primereact/toast'
import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { RiDeleteBinLine } from 'react-icons/ri'
import { FloatingMobileButton, PrimaryButtonForm } from 'shared/styles/buttons'
import { EX_LARGE_COL, EX_SMALL_COL, EX_SMALL_COL4, EX_VERY_LARGE_COL, LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns'
import AddEventTask from '../Modals/AddEventTask'
import { useModal } from 'hooks/ModalHook'
import { useForm } from 'hooks/FormHook'
import { useRef } from 'react'
import { AppContext, BranchItemsContext } from 'context'
import { useContext } from 'react'
import { useEffect } from 'react'
import { event_task_choices } from 'utils/choiceConstant'
import { deleteEventTasks, getEventTaskListApi, getEventsList, retriveEventTasks } from 'utils/api/SettingsApi'
import './styles.css'
import ModalDecision from 'components/SharedComponents/ModalDecision'
import { FlexGrid3Container, FlexGrid3Item, Header } from 'shared/styles/constants/tagsStyles'
import { getPageToSearchParam, useOutsideClickClosePopup } from 'shared/methods'
import Pagination from 'components/SharedComponents/Pagination'
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination'

const ADD_MESSAGE = 'Event QA task added successfully!'
const UPDATE_MESSAGE = 'Event QA task updated successfully!'
const DELETE_MESSAGE = 'Event QA task deleted successfully!'
const SOMETHING_WENT_WRORNG = "Something went wrong!"

function EventTask({
  props,
  readPermission,
  createPermission,
  updatePermission,
  deletePermission
}) {
  const toast = useRef();
  const autoCloseRef = useRef(null)
  const { contextChoices: choices, handleGetChoices, setContextChoices } = useContext(AppContext);
  const { branchData } = useContext(BranchItemsContext);
  const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({});
  const [checkedRows, setCheckedRows] = useState([])
  const [seletedId, setSeletedId] = useState([])
  const [checkedValue, setCheckedValue] = useState(true)
  const [isdisabledDeleteButton, setIsDisabledDeleteButton] = useState(false)
  const [bulkToggle, setBulkToggle] = useState(false)
  const [rowClicked, setRowClicked] = useState(null)
  const [errorMessage, setErrorMessage] = useState("")
  const [showError, setShowError] = useState(false)

  const headerColumns = [
    {
      checkbox: deletePermission ? true : false,
      name: deletePermission ? 'checkbox' : "",
      status: 0,
      className: 'grow-mobile',
      max_width: EX_SMALL_COL4,
    },
    {
      label: "Branch",
      name: '',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_LARGE_COL,
    },
    {
      label: "Event",
      name: '',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_LARGE_COL,
    },
    {
      label: "Tasks",
      name: '',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_VERY_LARGE_COL,
    },
    {
      label: '',
      name: '',
      status: 0,
      className: ' sm-hidden ',
      max_width: EX_SMALL_COL,
    },
    {
      label: '',
      name: '',
      status: 0,
      className: ' sm-hidden ',
      max_width: EX_SMALL_COL,
    },]
  if (deletePermission) {
    headerColumns[0].label = 1
  }
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle: setTitle,
    fullBind: fullBind,
  } = useModal(false);

  const {
    setValue: setModalDeleteValue,
    bind: bindModalDelete
  } = useModal(false);

  const [isLoading, setIsLoading] = useState(true)
  const [eventTasks, setEventTasks] = useState([])
  const [allEvents, setAllEvents] = useState([])
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getEventTaskListData = async () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage,
    )
    try {
      const res = await getEventTaskListApi({ ...pageToSearch, ...values, ...branchData })
      setEventTasks(res?.results)
      setPages(Math.ceil(res?.count / values?.limitperpage));
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }



  const deleteEventTaskRows = async () => {
    setModalDeleteValue(false)
    setIsDisabledDeleteButton(true)
    const body = {
      events_ids: seletedId?.length ? seletedId : checkedRows
    }
    try {
      const res = await deleteEventTasks(body)
      setIsDisabledDeleteButton(false)
      setCheckedRows([])
      setSeletedId([])
      if (res.status) {
        getEventTaskListData()
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: DELETE_MESSAGE,
        })
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: SOMETHING_WENT_WRORNG,
        });
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getEventTaskListData()
  }, [values.limitperpage, props.location.search, branchData])

  //Add Modal open
  const handleModalOpen = () => {
    setTitle('Add Event Task')
    setShowError(false)
    setCheckRequires(false)
    setRowClicked(null)
    values.tasks = []
    values.branch = ''
    values.event = ""
    setValues({ ...values })
    setModalValue(true)
  }

  useEffect(() => {
    console.log('useeffect called')
    handleGetChoices(event_task_choices)
  }, [])

  const getAllEvents = async () => {
    try {
      const res = await getEventsList()
      setAllEvents(res?.results)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getAllEvents()
  }, [])

  const findNameFrom = (id, name) => {
    if (name === 'task') {
      const task = choices?.hr_task?.find(t => t?.id === id)
      return task?.title
    } else if (name == 'event') {
      const event = allEvents?.find(event => event?.id === id)
      return event?.event
    } else if (name === "branch") {
      const branch = choices?.branch?.find(br => br?.id === id)
      return branch.name
    }
  }

  const handleAllCheck = () => {
    let checkAll = true;
    const existingData = (id) => {
      return checkedRows.some(function (el) {
        return el === id;
      });
    }
    if (checkedRows && checkedRows.length > 0) {
      eventTasks.map((item) => {
        if (!existingData(item.id)) {
          checkAll = false
        }
      })
    } else {
      checkAll = false;
    }
    return checkAll;
  }

  const handleAllSelectChange = (e) => {
    setSeletedId([])
    setCheckedValue(!checkedValue)
    if (!checkedRows) {
      checkedRows = [];
    }
    if (e.target.checked) {
      eventTasks.map((item) => {
        checkedRows.push(item.id)
      })
    } else {
      eventTasks.map((el) => {
        checkedRows.map((item, index) => {
          if (item === el.id) {
            checkedRows.splice(index, 1);
          }
        })
      })
    }
    setCheckedRows([...checkedRows])
  }

  const handleSelectChange = (e, item) => {
    setSeletedId([])
    if (e.target.checked) {
      setCheckedRows([...checkedRows, item.id])
    } else {
      let index = checkedRows.indexOf(item.id)
      if (index != -1) {
        let tempCheckedRows = [...checkedRows]
        tempCheckedRows.splice(index, 1)
        setCheckedRows([...tempCheckedRows])
      }
    }
  }

  const handleDeleteEvent = () => {
    deleteEventTaskRows()
  }
  const getRetrivedEventTask = async (id) => {
    try {
      const res = await retriveEventTasks(id)
      // const findData = res.find(el => el.event_id == id)
      setRowClicked(res?.event_hr_task || [])
      values.branch = res?.branch_id
      values.event_id = res?.id
      values.event = res?.event
      values.tasks = res?.event_hr_task?.map(t => t?.core_hr_task)
      setValues({ ...values })
    } catch (error) {
      console.error("Error fetching event retrive", error)
    }


  }
  const handleRowClick = (id) => {
    setTitle('Update Event Task')
    setModalValue(true)
    setShowError(false)
    setCheckRequires(false)
    getRetrivedEventTask(id)

  }

  const togglePopUp = () => {
    setBulkToggle(!bulkToggle)
  }

  const autoClose = _ => setBulkToggle(false)
  useOutsideClickClosePopup(autoCloseRef, autoClose);

  //Limit per page dropdown
  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [PAGE_SIZE_RENDER])

  return (
    <div className='w-100 event-task'>
      <Toast ref={toast} />
      {createPermission && (
        <div className="d-flex w-100 justify-content-end" >
          <FloatingMobileButton 
           style={{ marginTop: "-25px" , marginBottom: "-10px"}}
          onClick={handleModalOpen}>
            <FaPlus />
            <label>NEW EVENT TASK</label>
          </FloatingMobileButton>
        </div>
      )}

      <FlexGrid3Container>
        <FlexGrid3Item className="pe-1" >
          {checkedRows?.length > 0 &&
            (<div ref={autoCloseRef} style={{ maxWidth: "fit-content" }} class={`dropdown me-2${bulkToggle ? 'show' : ""}`}>
              <PrimaryButtonForm
                minWidth="6rem"
                class="btn dropdown-toggle"
                type="button"
                // id="dropdownMenuButton"
                // data-toggle="dropdown"
                aria-haspopup={bulkToggle ? "true" : "false"}
                onClick={() => togglePopUp()}
              // aria-expanded="false"
              >
                <span className={'me-2'} >Action</span>
              </PrimaryButtonForm>
              <div class={`dropdown-menu ${bulkToggle ? 'show' : ""}`} aria-labelledby="dropdownMenuButton">
                <button
                  class="dropdown-item"
                  onClick={() => {
                    setModalDeleteValue(true)
                  }}>
                  Bulk Delete
                </button>

              </div>
            </div>

            )}
        </FlexGrid3Item>
      </FlexGrid3Container>
      {/* <Header></Header> */}
      <div>
        {isLoading ?
          (<div style={{ marginTop: "300px" }} >
            <Spinner />
          </div>) :

          <div className='mt-4'>
            {eventTasks?.length ?
              (<>
                <Table
                  headerColumns={headerColumns}
                  handleAllCheck={handleAllCheck}
                  handleAllSelectChange={handleAllSelectChange}
                  headerPadding={false}
                  noNeed={false}
                  noEditOption={true}
                >
                  {eventTasks?.map((item, index) => {
                    return (
                      <div key={index}>
                        <Row bgColor Shadow>
                          <ColsGrouper className="d-flex">
                            <div className="p-left-2">
                              <Col
                                max_width={EX_SMALL_COL4}
                                Shrink={false}
                                NoFlexGrow={true}
                                reducePadding
                                Capitalize
                                Center
                              >
                                {deletePermission && (
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    checked={checkedRows.includes(item.id)}
                                    id="flexCheckDefault"
                                    onChange={event => {
                                      handleSelectChange(event, item, index);
                                    }}
                                  />)}
                              </Col>
                            </div>
                            <Col
                              max_width={EX_LARGE_COL}
                              Center
                              className="sm-hidden"
                              Capitalize
                              overlap={true}>
                              {item?.branch__name}
                            </Col>
                            <Col
                              max_width={EX_LARGE_COL}
                              Center
                              className="sm-hidden"
                              Capitalize
                              overlap={true}>
                              {findNameFrom(item?.id, 'event')}
                            </Col>
                            <Col
                              max_width={EX_VERY_LARGE_COL}
                              style={{ maxWidth: " 54%" }}
                              Center
                              className="sm-hidden"
                              Capitalize
                              overlap={true}>
                              {item?.core_hr_task_name}
                            </Col>
                            <Col
                              max_width={EX_SMALL_COL}
                              className={deletePermission ? "sm-hidden" : 'sm-hidden ms-2rem'}
                              Center
                              style={{
                                minWidth: "",
                                // marginLeft: '2.5rem'
                              }}
                              Icon
                              Purple>
                              <IconWrapper
                                onClick={() => handleRowClick(item.id)}
                              >
                                <MdEdit />
                              </IconWrapper>
                            </Col>
                          </ColsGrouper>

                          {deletePermission && <Col
                            className="sm-hidden"
                            Center
                            style={{ marginRight: "0.5rem" }}
                            Shrink
                            NoFlexGrow
                          >
                            {/* {deletePermission && ( */}
                            <IconWrapper
                              onClick={() => {
                                setSeletedId([item?.id])
                                setModalDeleteValue(true)
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>
                            {/* )} */}
                          </Col>}

                        </Row>
                        <div className="mb-1" />
                      </div>

                    )
                  })

                  }
                </Table>
                <div className="mt-3">
                  <Pagination
                    totalPages={pages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    values={values}
                    dropDownHandleSelect={dropDownHandleSelectPagination}
                    number={PAGE_SIZE_RENDER}
                    dataLength={eventTasks?.length}
                  />
                </div>
              </>
              ) :
              (<div className='w-100 d-flex justify-content-center'>
                <h4 style={{ fontSize: "1rem" }}>No data found</h4>
              </div>)}
          </div>
        }
      </div>
      {modalValue && (
        <AddEventTask
          fullBind={fullBind}
          useInput={useInput}
          values={values}
          setValues={setValues}
          setModalValue={setModalValue}
          toast={toast}
          choices={choices}
          getEventTaskListData={getEventTaskListData}
          rowClicked={rowClicked}
          allEvents={allEvents}
          readOnly={rowClicked ? !updatePermission : !createPermission}
          getAllEvents={getAllEvents}
          errors={errors}
          setCheckRequires={setCheckRequires}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          showError={showError}
          setShowError={setShowError}
          ADD_MESSAGE={ADD_MESSAGE}
          UPDATE_MESSAGE={UPDATE_MESSAGE}
          SOMETHING_WENT_WRORNG={SOMETHING_WENT_WRORNG}

        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        isdisabled={isdisabledDeleteButton}
        body={'Are you sure want to delete QA Event Task?'}
        onOk={() => handleDeleteEvent()}
        onCancel={() => setCheckedRows([])}
        okTitle={'DELETE'}
        {...bindModalDelete}

      />
    </div>
  )
}


export default EventTask