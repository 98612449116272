import React, { useState, useEffect, useRef, useContext } from 'react';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from './HeaderColumns';
import VisitCareNotesModal from 'components/DashboardComponents/Client/AddModal/VisitCareNotes/VisitCareNotesModal/VisitCareNotesModal';
import { FaPlus } from 'react-icons/fa';
import { TiArrowBack } from 'react-icons/ti';
import { RiCheckboxCircleFill, RiCloseCircleFill, RiDeleteBinLine } from 'react-icons/ri';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import { FloatingMobileButton } from 'shared/styles/buttons';
import { MdEdit } from 'react-icons/md';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { SearchAddContainer, HeaderLgBreak, Title, DropdownTypesContainer, HeaderVisits, TitleSection, FiltersContainer, PublishButton, } from 'shared/styles/constants/tagsStyles';
import { Row, Col, IconWrapper } from 'components/SharedComponents/Table/styles';
import { LARGE_COL, SMALL_COL, MEDIUM_COL, EX_LARGE_COL, EX_VERY_LARGE_COL, EX_SMALL_COL4, EX_SMALL_COL, COL_CONTAIN_WORD, CHECK_BOX } from 'shared/styles/constants/columns';
import { useForm } from 'hooks/FormHook';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext, BranchItemsContext } from "context"
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import moment from 'moment';
import { getVisitCareNotes, deleteVisitCareNotes, postBulkReviewStatus, exportClientVisitCareNotes } from 'utils/api/ClientApi';
import { setRunName } from 'utils/api/SettingsApi';
import { Fragment } from 'react';
import { BsFolderPlus } from 'react-icons/bs';
import { client_visit_care_notes } from 'utils/choiceConstant';
import { IconContext } from 'react-icons';
import { EmploymentTypesContainer } from 'components/DashboardComponents/Team/styles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { CloseButtonWrapper } from '../showFormModal/styles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { DOWNLOAD, READ_INFO } from 'utils/constants/icons';
import { Toast } from 'primereact/toast';
import { EXPORT_VISIT_CARE_NOTES, SELF_WEB_CREATE_PERMISSION, SELF_WEB_DELETE_PERMISSION, SELF_WEB_READ_PERMISSION, SELF_WEB_UPDATE_PERMISSION, VISIT_CARE_NOTES_DETAILS, VISIT_CARE_NOTES_REVIEWERS, WEB_CREATE_PERMISSION, WEB_DELETE_PERMISSION, WEB_PERMISSION, WEB_READ_PERMISSION, WEB_UPDATE_PERMISSION } from 'utils/constants/permissions';
import { getClient as localStorageClient } from 'utils/localStorage/token';
import ContextMenu, { useContextMenu } from 'components/SharedComponents/ContextMenu/ContextMenu';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import ReviewSection from './ReviewSection/ReviewSection';

const VisitCareNote = ({
  selectedClientId,
  choices,
  props,
  handleChangeChoices,
  rowData,
  displayPhoto,
}) => {
  const {
    contextChoicesMap,
    contextPermissions,
    contextSubscription,
    contextSubSectionPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextChoices,
    handleGetChoices,
    contextRelativesClient, } = useContext(AppContext)

  const userClient = localStorageClient();
  const endDate = moment().format('YYYY-MM-DD');
  const startDate = moment().subtract(30,'days').format('YYYY-MM-DD');
  const { values, setValues, useInput } = useForm({ 
    limitperpage: PAGE_SIZE_RENDER, 
    ordering: "-updated_at", 
    start_date: startDate, 
    end_date: endDate,
    dateFilterType:"Updated At"});
  const [visitCareNotes, setVisitCareNotes] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const history = useHistory();
  const { branchData } = useContext(BranchItemsContext);
  const [alertPrivacy, setAlertPrivacy] = useState([]);
  const [labels, setLabels] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [userList, setUserList] = useState([]);
  const [visitType, setVisitType] = useState([]);
  const [serviceCategory, setServiceCategory] = useState([]);
  const [privacyName, setPrivacyName] = useState([])
  const [countCheck, setCoutCheck] = useState(0)
  const [checkedRows, setCheckedRows] = useState([])
  const [checkedValue, setCheckedValue] = useState(true)
  const [seletedId, setSeletedId] = useState([])
  const [disableExportButton, setDisableExportButton] = useState(false)

  const { setValue: setModalValue, value: modalValue, setTitle, fullBind, } = useModal(false);
  const { setValue: setModalReviewSection, value: modalReviewSection, setTitle: setTitleReviewSection, fullBind: fullBindRiviewSection, } = useModal(false);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(false);
  const { setValue: setModalReviewValue, bind: bindModalReview } = useModal(false);
  let startDateInput = useInput('start_date')
  let endDateInput = useInput('end_date')
  const [loadingModal, setLoadingModal] = useState(true);
  const schemaName = localStorage.getItem("schema_name");
  const toast = useRef(null)

  //Visit care notes
  const [readPermissionCareNotes, setReadPermissionCareNotes] = useState(false)
  const [createPermissionCareNotes, setCreatePermissionCareNotes] = useState(false)
  const [updatePermissionCareNotes, setUpdatePermissionCareNotes] = useState(false)
  const [deletePermissionCareNotes, setDeletePermissionCareNotes] = useState(false)
  // Visit care notes review
  const [readPermissionCareNotesReview, setReadPermissionCareNotesReview] = useState(false)
  const [createPermissionCareNotesReview, setCreatePermissionCareNotesReview] = useState(false)
  const [updatePermissionCareNotesReview, setUpdatePermissionCareNotesReview] = useState(false)
  const [deletePermissionCareNotesReview, setDeletePermissionCareNotesReview] = useState(false)

  const [exportPermission, setExportPermission] = useState(false)
  const [expandFields, setExpandFields] = useState({});
  const [privacyTypeFilter, setPrivacyTypeFilter] = useState([]);
 

  

  useEffect(() => {
    const rolesPermissions = contextPermissions;
    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          if (item.section !== null) {
            // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }
    console.log(rolePermissions, "role")
    const roles = contextSubSectionPermissions;

    if (roles.length > 0) {
      roles.forEach(
        item => {
          if (item.section !== null) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }

    if (contextIsSuperAdmin) {

      setReadPermissionCareNotes(true)
      setCreatePermissionCareNotes(true)
      setUpdatePermissionCareNotes(true)
      setDeletePermissionCareNotes(true)

      setReadPermissionCareNotesReview(true)
      setCreatePermissionCareNotesReview(true)
      setUpdatePermissionCareNotesReview(true)
      setDeletePermissionCareNotesReview(true)
      setExportPermission(true)
    } else if (contextIsAdmin) {

      setReadPermissionCareNotes(true)
      setCreatePermissionCareNotes(true)
      setUpdatePermissionCareNotes(true)
      setDeletePermissionCareNotes(true)
      setReadPermissionCareNotesReview(true)
      setCreatePermissionCareNotesReview(true)
      setUpdatePermissionCareNotesReview(true)
      setDeletePermissionCareNotesReview(true)
      setExportPermission(true)
    } else {
      if (rolePermissions[VISIT_CARE_NOTES_DETAILS]) {
        setReadPermissionCareNotes(
          rolePermissions[VISIT_CARE_NOTES_DETAILS][WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            (userClient == selectedClientId ||
              contextRelativesClient.find(id => id == selectedClientId)))
        );
        setCreatePermissionCareNotes(
          rolePermissions[VISIT_CARE_NOTES_DETAILS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            (userClient == selectedClientId ||
              contextRelativesClient.find(id => id == selectedClientId)))
        );
        setUpdatePermissionCareNotes(
          rolePermissions[VISIT_CARE_NOTES_DETAILS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            (userClient == selectedClientId ||
              contextRelativesClient.find(id => id == selectedClientId)))
        );
        setDeletePermissionCareNotes(
          rolePermissions[VISIT_CARE_NOTES_DETAILS][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_DETAILS]?.[WEB_PERMISSION]?.[
            SELF_WEB_DELETE_PERMISSION
          ] &&
            (userClient == selectedClientId ||
              contextRelativesClient.find(id => id == selectedClientId)))
        );
      }

      if (rolePermissions[VISIT_CARE_NOTES_REVIEWERS]) {
        setReadPermissionCareNotesReview(
          rolePermissions[VISIT_CARE_NOTES_REVIEWERS][WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_REVIEWERS]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            (userClient == selectedClientId ||
              contextRelativesClient.find(id => id == selectedClientId)))
        );
        setCreatePermissionCareNotesReview(
          rolePermissions[VISIT_CARE_NOTES_REVIEWERS][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_REVIEWERS]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ] &&
            (userClient == selectedClientId ||
              contextRelativesClient.find(id => id == selectedClientId)))
        );
        setUpdatePermissionCareNotesReview(
          rolePermissions[VISIT_CARE_NOTES_REVIEWERS][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_REVIEWERS]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ] &&
            (userClient == selectedClientId ||
              contextRelativesClient.find(id => id == selectedClientId)))
        );
        setDeletePermissionCareNotesReview(
          rolePermissions[VISIT_CARE_NOTES_REVIEWERS][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
          ] ||
          (rolePermissions[VISIT_CARE_NOTES_REVIEWERS]?.[WEB_PERMISSION]?.[
            SELF_WEB_DELETE_PERMISSION
          ] &&
            (userClient == selectedClientId ||
              contextRelativesClient.find(id => id == selectedClientId)))
        );
      }

      if (rolePermissions[EXPORT_VISIT_CARE_NOTES]) {
        setExportPermission(
          rolePermissions[EXPORT_VISIT_CARE_NOTES][WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
          ] ||
          (rolePermissions[EXPORT_VISIT_CARE_NOTES]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ] &&
            (userClient == selectedClientId ||
              contextRelativesClient.find(id => id == selectedClientId)))
        )
      }
    }
  }, [contextChoices, contextIsSuperAdmin, contextIsAdmin, contextSubscription])

  useEffect(() => {
    handleGetChoices(client_visit_care_notes)
  }, [])


  useEffect(() => {

    setEmployeeName((contextChoices && contextChoices.employee_listing && contextChoices.employee_listing.map(y => {
      return { id: y && y.id, name: y && y.full_name }
    })))

    setLabels((contextChoices && contextChoices.alert_label && contextChoices.alert_label.map(y => {
      return { id: y && y.id, name: y && y.name }
    })))

    setAlertPrivacy((contextChoices && contextChoices.alert_privacy && contextChoices.alert_privacy.map(y => {
      return { id: y && y.id, name: y && y.name }
    })))

    setUserList((contextChoices && contextChoices.user_list && contextChoices.user_list.filter(data => data.schema_name == schemaName).map(y => {
      return { id: y && y.id, name: y && y.name }
    })
    ))
    setPrivacyName((contextChoices && contextChoices.alert_privacy && contextChoices.alert_privacy.map(y => {
      return { id: y && y.id, name: y && y.name }
    })))
    setVisitType((contextChoices && contextChoices.visit_type && contextChoices.visit_type.map(y => {
      return { id: y && y.id, name: y && y.name }
    })))

    setServiceCategory((contextChoices && contextChoices.service_category
      && contextChoices.service_category.map(y => {
        return { id: y && y.id, name: y && y.name }
      })))

  }, [contextChoices])


  useEffect(() => {
    if (values.limitperpage !== 'undefined ' || props.location.search || values.ordering)
      loadVisitCareNotes()
  }, [
    values.limitperpage,
    props.location.search,
    values.ordering,

  ])



  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage; return {
      offset,
    };
  };

  const loadVisitCareNotes = () => {
    const pageToSearch = getPageToSearchParam(props.location.search, setCurrentPage);
    getVisitCareNotes({ ...branchData, ...values, ...pageToSearch }, values.limitperpage, selectedClientId).then(response => {
      if (response) {
        setLoadingModal(false);
      }
      setCoutCheck(response?.count || 0)
      setVisitCareNotes(response?.results || []);
      response.results && response.results.map((note,index) => {
        expandFields[index] = { open: true };
      })
      
      setExpandFields({ ...expandFields });
      setPages(Math.ceil(response.count / values.limitperpage));
    });
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      search: stringified,
    });
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }

  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const handleRowClick = id => {
    setIdSelected(id);
    setModalValue(true);
  };

  const handleNewVisitCareNotes = () => {
    setIdSelected('');
    handleModal();
  };
  const handleDeleteRow = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };
  const handleDeleteVisitCareNote = async () => {
    await deleteVisitCareNotes(idSelected, selectedClientId);
    setModalDeleteValue(false);
    setIdSelected('');
    loadVisitCareNotes();
  };

  const displayEmployeeName = (empId) => {
    if (empId) {
      let Name = userList?.find(userdata => userdata?.id === empId)?.name;
      return Name;
    } else {
      return
    }
  }

  const displayVisitType = (visitTypeId) => {
    if (visitTypeId) {
      let Name = visitType?.find(userdata => userdata.id === visitTypeId)?.name;
      return Name;
    } else {
      return
    }
  }

  const displayCategoryName = (catgoryId) => {
    if (catgoryId) {
      let Name = serviceCategory?.find(service => service.id === catgoryId)?.name;
      return Name;
    } else {
      return
    }
  }
  const displayPrivacyName = (PrivacyId) => {
    if (PrivacyId) {
      let Name = privacyName?.find(privacy => privacy.id === PrivacyId)?.name;
      return Name;
    } else {
      return
    }
  }

  const handelActiveOrInActive = async (rev_d, is_active, is_bulk) => {

    let review_s = is_active ? contextChoices?.medication_review_status?.find((val) => val.name == 'Not Reviewed') : contextChoices?.medication_review_status?.find((val) => val.name == 'Reviewed')
    const tempList = selectedElId ? [selectedClientId] : checkedRows
    let body = {
      client_care_notes_ids: is_bulk ? tempList : [rev_d.id],
      review_status: review_s.id
    }
    try {
      const response = await postBulkReviewStatus(body);
      loadVisitCareNotes()
      setCheckedRows([])
      setModalReviewValue(false)
    } catch (err) {
    }
  }


  const getStartEndTime = (starttime, endtime) => {
    let startTime = starttime ? moment(starttime.split('T')[1], 'HH:mm:ss').format('HH:mm') : '';

    let endTime = endtime ? moment(endtime.split('T')[1], 'HH:mm:ss').format('HH:mm') : '';

    let Time = startTime + '' + '-' + '' + endTime;

    return Time
  }

  const sliceString = string => {
    // return string.length > 60 ? string.slice(0, 60) + '...' : string;
    return string
  }


  const handleAllCheck = () => {
    let checkAll = true;
    const existingData = (id) => {
      return checkedRows.some(function (el) {
        return el === id;
      });
    }
    if (checkedRows && checkedRows.length > 0) {
      visitCareNotes.map((item) => {
        if (!existingData(item.id)) {
          checkAll = false
        }
      })
    } else {
      checkAll = false;
    }
    return checkAll;
  }

  const handleAllSelectChange = (e) => {
    setSeletedId([])
    setCheckedValue(!checkedValue)
    if (!checkedRows) {
      checkedRows = [];
    }
    if (e.target.checked) {
      visitCareNotes.map((item) => {
        checkedRows.push(item.id)
      })
    } else {
      visitCareNotes.map((el) => {
        checkedRows.map((item, index) => {
          if (item === el.id) {
            checkedRows.splice(index, 1);
          }
        })
      })
    }
    setCheckedRows([...checkedRows])
  }


  const handleSelectChange = (e, item) => {
    setSeletedId([])
    if (e.target.checked) {
      setCheckedRows([...checkedRows, item.id])
    } else {
      let index = checkedRows.indexOf(item.id)
      if (index != -1) {
        let tempCheckedRows = [...checkedRows]
        tempCheckedRows.splice(index, 1)
        setCheckedRows([...tempCheckedRows])
      }
    }
  }

  const dropDownHandleSelect = (item, itemName) => {
    if (itemName == 'privacy_type') {
      values.privacy_type = item[itemName]
      setValues({ ...values, ...item })
    } else if (itemName === 'dateFilterType') {
      if (item[itemName] === 'Visit Date')
        values.ordering = '-visit__start_date'
      else if (item[itemName] === 'Updated At') {
        values.ordering = "-updated_at"
      }
      setValues({ ...values, ...item })
    } else {
      setValues({ ...values, ...item })
    }
  }

  const handleExport = async () => {

    if (values.end_date && !values.start_date) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Plese choose start date first`,
      });
      return;
    }
    if (values.start_date && values.end_date && new Date(values.start_date) > new Date(values.end_date)) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Start Date should be greater than end date`,
      });
      return;
    }
    setDisableExportButton(true)
    let fileName = `${rowData.first_name || rowData.full_name} Visit-Care-Notes ${moment().format('YYYY-MM-DD')}`;
    await exportClientVisitCareNotes(values, selectedClientId)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
          setDisableExportButton(false)
        })
      }
      )
      .catch(error => {
        setDisableExportButton(false)
      })
  }
  const handleSearch = () => {

    if (!values.start_date && values.end_date) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Plese choose start date first`,
      });
      return;
    }
    if (values.start_date && values.end_date && new Date(values.start_date) > new Date(values.end_date)) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Start Date should be greater than end date`,
      });
      return;
    }
    loadVisitCareNotes()

  }
  console.log({
    readPermissionCareNotes,
    createPermissionCareNotes,
    updatePermissionCareNotes,
    deletePermissionCareNotes,

    readPermissionCareNotesReview,
    createPermissionCareNotesReview,
    updatePermissionCareNotesReview,
    deletePermissionCareNotesReview
  }, "permission")

  const {
    displayMenu, selectedElId, encloseModalBind
  } = useContextMenu()
  const visitCareNotesMenu = [
    {
      name: "Review",
      onClick: () => setModalReviewValue(true)
    }
  ]

  const handleExpansion = (index) => {
    if (expandFields[index] !== undefined
      && expandFields[index]['open']) {
      expandFields[index] = { open: false };
      setExpandFields({ ...expandFields });
    } else {
      expandFields[index] = { open: true };
      setExpandFields({ ...expandFields });
    }
  };


  const calculateNumberOfIcons = () => {
    let count = 0;
    if (updatePermissionCareNotes || updatePermissionCareNotesReview)
      count = count + 1;
    if ((!updatePermissionCareNotes && !updatePermissionCareNotesReview) && (readPermissionCareNotes || readPermissionCareNotesReview))
      count = count + 1;
    if (deletePermissionCareNotes || deletePermissionCareNotesReview)
      count = count + 1;
    if (updatePermissionCareNotesReview)
      count = count + 1;
    return count || 0
  }

  const filterPrivacyType = (item) => {
    setPrivacyTypeFilter([...item])
  }


  const handleReviewSection = (id) => {
    setModalReviewSection(true)
    setIdSelected(id)
  }

  const checkReviewStatus =  id => {
    let status = id && contextChoices?.medication_review_status?.find(item => item?.id == id)
    && contextChoices?.medication_review_status?.find(item => item?.id == id)
    return status?.name == 'Reviewed'
  }

  return (
    <div className='client-visit-care-notes'>
      <ContextMenu menuItems={visitCareNotesMenu} />
      <Toast ref={toast} />
      <div className='mb-3 mt-1 d-flex justify-content-between align-items-center'>
        <div className="w-50 d-flex align-items-center justiify-contnet-between">
          <FiltersContainer>
            <EmploymentTypesContainer className="me-2">
              <DropdownTypesContainer className="ms-1 me-1  d-flex">
                <DateSelector
                  typeFilter={true}
                  labelTop="Start Date"
                  {...startDateInput}
                />

              </DropdownTypesContainer>
            </EmploymentTypesContainer>

            <EmploymentTypesContainer className="me-2">
              <DropdownTypesContainer className="ms-1 me-1  d-flex">
                <DateSelector
                  typeFilter={true}
                  labelTop="End Date"
                  {...endDateInput}
                />

              </DropdownTypesContainer>
            </EmploymentTypesContainer>

           
            <EmploymentTypesContainer className="me-2">
              <DropdownTypesContainer className="ms-1 me-1  d-flex">
                <div className='w-100'>
                  <DropdownSearchable
                    typeFilter={true}
                    placeHolder={'Date Type'}
                    options={[
                      { id: 'Visit Date', name: 'Visit Date' },
                      { id: 'Updated At', name: 'Updated At' }
                    ]}
                    selectedOption={values.dateFilterType}
                    onChange={value =>
                      dropDownHandleSelect({
                        dateFilterType: value ? value : null,
                      },"dateFilterType")
                    }
                  />

                </div>
              </DropdownTypesContainer>
            </EmploymentTypesContainer>

            <EmploymentTypesContainer className="me-2">
              <DropdownTypesContainer className="ms-1 me-1  d-flex">
                <div className='w-100'>
                  <NoSelectAllDropdownFilter
                    items={alertPrivacy ? alertPrivacy : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ privacy_type: items },"privacy_type");
                    }}
                    itemsSelected={privacyTypeFilter}
                    setSelectedFilter={filterPrivacyType}
                    placeHolder={'Privacy'}
                    isObject
                    allOption
                  />
                </div>
              </DropdownTypesContainer>
            </EmploymentTypesContainer>
        
            <EmploymentTypesContainer className="me-2">
              <DropdownTypesContainer className="ms-1 me-1  d-flex">
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={handleSearch}>
                  <span>Search</span>
                </PrimaryButtonForm>
              </DropdownTypesContainer>
            </EmploymentTypesContainer>
          </FiltersContainer>
        </div>
        <div>
          </div>

          <div className={'d-flex '}>
            {(checkedRows.length > 0 && updatePermissionCareNotesReview) &&
              <div class="dropdown me-3">
                <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className={'me-2'}>Action</span>
                </PrimaryButtonForm>
                <div class="dropdown-menu me-2" aria-labelledby="dropdownMenuButton" style={{ zIndex: 9999 }}>
                  <a class="dropdown-item"
                    onClick={() => setModalReviewValue(true)}
                  >Bulk Review</a>
                </div>
              </div>
            }
            {(createPermissionCareNotes || createPermissionCareNotesReview) ? (
              <div className={'text-primary font-weight-bold'}>
                <div>
                <FloatingMobileButton onClick={handleNewVisitCareNotes}>
              <FaPlus />
              <label>NEW VISIT CARE NOTES</label>
            </FloatingMobileButton>
                </div>
              </div>
            ) : (
              <Fragment />
            )}
            {exportPermission && (
              <div className='ms-3'>
                <PublishButton
                  disable={disableExportButton}
                  onClick={!disableExportButton && handleExport}
                >
                  {DOWNLOAD}
                </PublishButton>
              </div>)}
          </div>
        </div>
     
      <>
        {(readPermissionCareNotes || readPermissionCareNotesReview) ?
          <ComponentDynamic loading={loadingModal} >
            {countCheck !== 0 ? (
              <Table
                headerColumns={headerColumns}
                callBackQuerySort={setQuerySort}
                headerPadding={false}
                handleAllCheck={handleAllCheck}
                handleAllSelectChange={handleAllSelectChange}
                noNeed={false}
                numberOfIcons={calculateNumberOfIcons()}
                noEditOption={true}
              >
                <div>
                  {visitCareNotes.map((notesData, index) => {
                    return (
                      <div >
                        <Row bgColor
                          onContextMenu={(e) => {
                            e.preventDefault();
                            displayMenu(e, notesData.id, { x: 0, y: 0 })
                          }}>
                          <div className="check-p-2_">
                            <Col
                              max_width={EX_SMALL_COL4}
                              Shrink={false}
                              NoFlexGrow={true}
                              reducePadding
                              Capitalize
                              Center
                            >
                              {(
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={checkedRows.includes(notesData.id)}
                                  id="flexCheckDefault"
                                  onChange={event => {
                                    handleSelectChange(event, notesData, index);
                                  }}
                                />)}
                            </Col>
                          </div>
                          <Col
                            max_width={CHECK_BOX}
                            className="d-flex justify-content-center align-content-center">
                            <div
                              className="closeButtonWrapper client-service-expand-icon"
                              onClick={() => {
                                handleExpansion(index);
                              }}>
                              {expandFields && expandFields[index] && expandFields[index]?.open ? (
                                <IoIosArrowDown
                                  style={{ color: '#6200f9', fontWeight: 'bold' }}
                                />
                              ) : (
                                <IoIosArrowForward
                                  style={{ color: '#6200f9', fontWeight: 'bold' }}
                                />
                              )}
                            </div>
                          </Col>

                          <Col max_width={COL_CONTAIN_WORD} Center
                            overlap={true}>
                            {notesData?.updated_by && notesData?.updated_by?.name ? notesData?.updated_by?.name : ""}
                          </Col>

                          <Col max_width={COL_CONTAIN_WORD} Center
                            overlap={true}>
                            {notesData.updated_at && moment(notesData.updated_at).format('YYYY-MM-DD HH:mm')}
                          </Col>
                          <Col max_width={COL_CONTAIN_WORD} Center
                            overlap={true}>
                            {notesData.notes_date}
                          </Col>
                          <Col max_width={COL_CONTAIN_WORD} Center
                            className='sm-hidden'
                            overlap={true}>
                            {getStartEndTime(notesData.visit_start_date && notesData.visit_start_date, notesData.visit_end_date && notesData.visit_end_date)}
                          </Col>
                          <Col max_width={SMALL_COL} Center
                            overlap={true}>
                            {displayEmployeeName(notesData.care_notes_created_by && notesData.care_notes_created_by)}
                          </Col>
                          <Col max_width={SMALL_COL} Center
                            className='sm-hidden md-hidden'
                            overlap={true}>
                            {displayCategoryName(notesData.service_category && notesData.service_category)}

                          </Col>
                          <Col max_width={SMALL_COL} Center
                            className='sm-hidden md-hidden'
                            overlap={true}>

                            {displayVisitType(notesData.visit_type && notesData.visit_type)}
                          </Col>
                          {/* <Col max_width={EX_VERY_LARGE_COL} CentervisitCareNotes
                            className='sm-hidden md-hidden'
                            overlap={true}>
                            {notesData.care_notes && sliceString(notesData.care_notes)}
                          </Col> */}
                          <Col max_width={SMALL_COL} CentervisitCareNotes
                            className='sm-hidden md-hidden'
                            overlap={true}>
                            {displayPrivacyName(notesData.notes_privacy && notesData.notes_privacy)}
                          </Col>
                          <Col max_width={EX_VERY_LARGE_COL} CentervisitCareNotes
                            className='sm-hidden md-hidden'
                            overlap={true}>
                            {notesData.care_notes_label && sliceString(notesData.care_notes_label)}
                          </Col>
                          
                          <Col max_width={SMALL_COL} CentervisitCareNotes
                            className='sm-hidden md-hidden'
                            overlap={true}>
                            {notesData?.review_section?.reviewed_by && displayEmployeeName(notesData?.review_section?.reviewed_by)}
                          </Col>

                          <Col max_width={SMALL_COL} CentervisitCareNotes
                            className='sm-hidden md-hidden'
                            overlap={true}>
                              {notesData?.review_section?.review_date_time &&  moment(notesData?.review_section?.review_date_time).format('YYYY-MM-DD HH:mm')}
                          </Col>
                          
                          <Col className="" Center Icon Purple>
                            {(updatePermissionCareNotes || updatePermissionCareNotesReview) ?
                              <IconWrapper
                                onClick={() => {
                                  handleRowClick(notesData.id && notesData.id);
                                }}>
                                <MdEdit />
                              </IconWrapper>
                              : <>
                                {(readPermissionCareNotes || readPermissionCareNotesReview) &&
                                  <IconWrapper
                                    onClick={() => {
                                      handleRowClick(notesData.id && notesData.id);
                                    }}>
                                    {READ_INFO}
                                  </IconWrapper>
                                }
                              </>
                            }
                         
                          </Col>
                         
                          {updatePermissionCareNotesReview &&  
                          <Col className="" Center Icon>
                              {checkReviewStatus(notesData?.review_section?.review_status) && (
                                <IconWrapper
                                  onClick={() =>
                                    // handelActiveOrInActive(notesData, true, false)
                                    handleReviewSection(notesData.id)
                                  }>
                                  <IconContext.Provider
                                    value={{ color: 'green', size: '2.1rem' }}>
                                    <RiCheckboxCircleFill />
                                  </IconContext.Provider>
                                </IconWrapper>
                              )}
                              {!checkReviewStatus(notesData?.review_section?.review_status) && (
                                <IconWrapper
                                  onClick={() =>
                                    // handelActiveOrInActive(notesData, false, false)
                                    handleReviewSection(notesData.id)
                                  }>
                                  <IconContext.Provider
                                    value={{ color: 'red', size: '2.1rem' }}>
                                    <RiCloseCircleFill />
                                  </IconContext.Provider>
                                </IconWrapper>
                              )}
                            </Col>
                          }
                          
                          {(deletePermissionCareNotes || deletePermissionCareNotesReview) &&
                            <Col className="" Center Shrink NoFlexGrow>
                              <IconWrapper
                                onClick={() => {
                                  handleDeleteRow(notesData.id && notesData.id);
                                }}>
                                <RiDeleteBinLine />
                              </IconWrapper>
                            </Col>}

                        </Row>
                        <div className="mb-1" />

                        {/* Expanded Content */}
                        {
                          expandFields && expandFields[index] && expandFields[index]?.open && (
                            <div className='ms-3 me-3'>
                              {(notesData.review_section && notesData?.review_section?.review_comment) && (
                                <>
                                  <div
                                    className={'pt-2 ms-0'}
                                    style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                                    Review Comment
                                  </div>
                                  <div className='mt-1 mb-2 ' style={{ border: "solid 1px #ccc", borderRadius: "0.25rem", padding: "0.75rem" }}>
                                    <div style={{ minHeight: "2.5rem" }}>{notesData?.review_section?.review_comment}</div>
                                  </div>
                                </>)}

                              <div
                                className={'pt-2 mt-2 ms-0'}
                                style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>
                                Care Notes
                              </div>
                              <div className='mt-1 mb-2 ' style={{ border: "solid 1px #ccc", borderRadius: "0.25rem", padding: "0.75rem" }}>
                                <div style={{ minHeight: "2.5rem" }}>{notesData.care_notes && notesData.care_notes}</div>
                              </div>

                            </div>
                          )
                        }
                      </div>
                    );
                  })}
                  <div className="mt-3">
                    <Pagination totalPages={pages} currentPage={currentPage} values={values} dropDownHandleSelect={dropDownHandleSelectPagination} number={PAGE_SIZE_RENDER} dataLength={visitCareNotes.length} />
                  </div>
                  <div className='d-flex justify-content-end'>
                    <PrimaryButtonForm
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      className={'me-2'}
                      onClick={() => {
                        history.push('/client')
                      }}>
                      <span className={'me-2'}>{'Cancel'}</span>
                    </PrimaryButtonForm>
                  </div>
                </div>
              </Table>
            ) : (
              <NoDataFoundMessage>No data found!</NoDataFoundMessage>
            )}
          </ComponentDynamic>
          :
          <TitleSection className='text-center'>
            You don't have permission to access the information.
          </TitleSection>
        }
      </>

      {!modalValue ? null : (
        <VisitCareNotesModal
          labels={labels}
          employeeName={employeeName}
          alertPrivacy={alertPrivacy}
          idSelected={idSelected}
          setModalValue={handleModal}
          fullBind={fullBind}
          setTitle={setTitle}
          pageClientID={selectedClientId}
          loadVisitCareNotes={loadVisitCareNotes}
          handleChangeChoices={handleChangeChoices}
          contextChoices={contextChoices}
          userList={userList}
          rowData={rowData}
          displayPhoto={displayPhoto}

          readPermissionCareNotes={readPermissionCareNotes}
          createPermissionCareNotes={createPermissionCareNotes}
          updatePermissionCareNotes={updatePermissionCareNotes}
          deletePermissionCareNotes={deletePermissionCareNotes}

          readPermissionCareNotesReview={readPermissionCareNotesReview}
          createPermissionCareNotesReview={createPermissionCareNotesReview}
          updatePermissionCareNotesReview={updatePermissionCareNotesReview}
          deletePermissionCareNotesReview={deletePermissionCareNotesReview}
        />
      )}

      {/* Review Section Popup */}
      {modalReviewSection ?
        <ReviewSection
          idSelected={idSelected}
          setModalValue={setModalReviewSection}
          fullBind={fullBindRiviewSection}
          loadVisitCareNotes={loadVisitCareNotes}
          contextChoices={contextChoices}
          readPermission={readPermissionCareNotesReview}
          createPermission={createPermissionCareNotesReview}
          updatePermission={updatePermissionCareNotesReview}
          deletePermission={deletePermissionCareNotesReview}
          toast={toast}
        /> : null
      }

      <ModalDecision
        type=""
        title="Confirm"
        body={"Are you sure you want to perform multiple reviews?\n Reviewing these Visit Care Notes will be marked as reviewed."}
        // subBody={
        //   'TIP (Recommended) - you could change the Visit Care Note to discard instead to retain database records.'
        // }
        onOk={() => {
          handelActiveOrInActive(null, false, true)
        }
        }
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'Review'}
        {...encloseModalBind(bindModalReview)}
      />


      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this Visit Care Note? \n DELETING the Visit Care Note will remove ALL records from database.'
        }
        // subBody={
        //   'TIP (Recommended) - you could change the Visit Care Note to discard instead to retain database records.'
        // }
        onOk={handleDeleteVisitCareNote}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  )
}

export default VisitCareNote