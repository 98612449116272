import React from "react";
import { AddAvailability, AddUnavailability } from "components/DashboardComponents/RotaShifts/ButtonsModals";
import moment from "moment";
import { getChoices, getChoicesMap } from 'utils/api/CoreApi';
import {
  ERROR,
  InvalidOrEmptyValues,
  SAVED,
} from 'utils/constants/messages';
import {
  CHECK,
  CROSS,
  ERROR_OUTLINE,
  FLOPPY,
} from 'utils/constants/icons';
import {
  deleteAvailabilities,
  setAvailability,
  setAvailabilityDetails,
  setSplitAvailability,
  setUnassignedVisitOnRota,
} from 'utils/api/RotaShift';
import { useState } from "react";
import { validateDate, validateTime } from 'shared/methods';
import { AppContext } from "context";
import { useContext } from "react";
import { AVAILABLE_EXISTING_SHIFT, AVAILABLE_EXISTING_UNAVAILABLE, UNAVAILABLE_EXISTING_AVAILABLE, UNAVAILABLE_EXISTING_SHIFT } from "utils/constants/rotaShift";
import { useEffect } from "react";
import { useModal } from "hooks/ModalHook";
import ConfirmationModal from "components/DashboardComponents/RotaShifts/ConfirmationMdal";
import SplitOverlapModal from "components/DashboardComponents/RotaShifts/ButtonsModals/SplitOverlapModal";
import { getPayRates } from "utils/api/ClientApi";

const SHIFT_VALUES = 'shiftValues';

const RotaShiftsActions = ({
  readOnly,
  travelRate,
  fullBind,
  useInput,
  employees,
  values,
  errorMessage,
  loadingSave,
  textStatus,
  dateMessage,
  timeMessage,
  dateError,
  timeError,
  choices,
  isDateAssigned,
  // typeOfMessage,
  //added new props below
  daysOfWeek,
  setDaysOfWeek,
  requireDayOfWeek,
  setValues,
  filterEmployee,
  filterClient,
  filterSuperAdmin,
  filterAdmin,
  invalidOvernight,
  setInvalidOvernight,
  availabilitiesEmployeeId,
  availabilitiesEmployeeFullName,
  availabilitiesEmployeeIdName,
  availabilityDetailsDays,
  detailId,
  // createAvailable={createAvailable}
  // createSelfAvailable={createSelfAvailable}
  shiftTypePopup,
  employeeDisable,
  setEmployeeDisable,
  // errorTime,
  // createUnAvailable={createUnAvailable}
  // createSelfUnAvailable={createSelfUnAvailable}
  fullBindAddAvailability,
  fullBindAddUnavailability,
  setAddAvailability,
  setAddUnavailability,
  addAvailability,
  addUnavailability,
  // TravelData,
  shiftTempOnPopup,
  employeeTempOnPopup,
  reloadData,

  errors,
  setCheckRequires,
  // setLoadingProceed,
  // loadingProceed

  setErrorMessage,
  setLoadingSave,
  setTextStatus,
  setDisableButton,
  disableButton,
  setIsDateAssigned,
  setSelectedEmployees,
  handleAddUnavailability,
  handleAddAvailability,

  setOverlapModal,
  overlapModal,
  fullBindOverlapModal,
  shiftOverlapData,
   setOverlapData,
unassignedVisitList,
setUnassignedVisitList,
disableLoader = false,
isShiftDragAndDrop,
toast = null,
loadingShiftPage = false,
copyShift = false
}) => {

  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [loadingOverlap, setLoadingOverlap] = useState([]);
  const [currentModal, setCurrentModal] = useState(false);
  const [overlapMessage, setOverlapMessage] = useState('');
  const [loadingProceed, setLoadingProceed] = useState([]);
  const [newShiftId, setNewShiftId] = useState({});
  const [absenceId, setAbsenceId] = useState({});
  const [typeOfMessage, setTypeOfMessage] = useState('');
  const [errorTime, setErrorTime] = useState({});
  const [unassignedData, setUnassignedData] = useState([]);
  // const [shiftOverlapData, setOverlapData] = useState([]);
  // const [unassignedVisitList, setUnassignedVisitList] = useState([]);


  const {
    contextChoices,
    contextChoicesMap,
    setContextChoices,
    setContextChoicesMap,
  } = useContext(AppContext);

  const {
    setValue: setModalConfirmUnassignVisits,
    value: modalValueUnassignVisits,
    setTitle,
    fullBind: fullBindUnassignVisits,
  } = useModal(false);


  // const {
  //   setValue: setOverlapModal,
  //   value: overlapModal,
  //   fullBind: fullBindOverlapModal,
  // } = useModal(false);

  useEffect(() => {
    if (!values.shift) {

      let newDat =
        contextChoices &&
        contextChoices.regular_shift_type && contextChoices.regular_shift_type.find(item => {
          if (item.name === 'Unavailability') {
            return item;
          }

        });
      setNewShiftId(newDat);

      let newD =
        contextChoices &&
        contextChoices.regular_shift_type && contextChoices.regular_shift_type.find(item => {
          if (item.name === 'Absence') {
            return item;

          }

        });
      setAbsenceId(newD);
      // console.log(newDat, newD, '9999');
      // console.log(newShiftId, absenceId, 'oooooo');
    }
  }, [values])

  // const dropDownHandleRate = item => {
  //   values.travel_rate = item.travel_rate;
  //   values.travel_rate_per_hour = TravelData.find(
  //     travelid => travelid.id == item.travel_rate
  //   ).rate_per_hour;
  //   setValues({ ...values });
  // };

  const dropDownHandleSelect = (item, name) => {
    if (item.jobDepartment) {
      item.jobDepartment =
        item.jobDepartment.length ===
          (choices.job_category ? choices.job_category : 0).length ||
          item.jobDepartment.length === 0
          ? []
          : item.jobDepartment;
    }

    if (item.branch) {
      item.branch =
        item.branch.length === (choices.branch ? choices.branch : 0).length ||
          item.branch.length === 0
          ? []
          : item.branch;
    }

    if (item.employees) {
      item.employees =
        item.employees.length === (employees ? employees : 0).length ||
          item.employees.length === 0
          ? []
          : item.employees;
    }


    const always_disable = ['Availability', 'Unavailability', 'Bank-Flexible', 'Absence', 'Car - Driver - Shift'].some((item) => item === shiftTempOnPopup);

    if (item.shift_type && !always_disable && values.idSelected) {
      const result = ['Availability', 'Unavailability', 'Bank-Flexible', 'Absence', 'Car - Driver - Shift'].some((item) => item === name);
      if (result) {
        setEmployeeDisable(true)
        values.employee = employeeTempOnPopup;
      } else {
        setEmployeeDisable(false)
      }
    }

    setValues({ ...values, ...item });
  };

  const handleDeleteAvailabilityDetail = async () => {
    const res = await deleteAvailabilities(values?.shiftAvailabilityId);
    const json = await res.json()
    if (json.unassigned_visit_data && (json.unassigned_visit_data.length > 0)) {
      setModalConfirmUnassignVisits(true)
      setUnassignedData(json.unassigned_visit_data)
    }
    reloadData();
    handleAddAvailability();
  };

  const assignMessageAvailableUnavailable = (available, result) => {
    console.log()
    if (result && result[0].shift_dates) {
      if (available) {
        setTypeOfMessage(AVAILABLE_EXISTING_SHIFT);
      } else {
        setTypeOfMessage(UNAVAILABLE_EXISTING_SHIFT);
      }
    } else if (result && result[0].shift_availabilities_dates) {
      let find_availability = null;
      if (result && result[0].shift_availabilities_dates) {
        find_availability = result[0].shift_availabilities_dates.find(
          result => result.is_available === 'True'
        );
      }

      if (find_availability) {
        setErrorTime({
          start: moment(
            result[0].shift_availabilities_dates[0].start_date
          ).format('LT'),
          end: moment(result[0].shift_availabilities_dates[0].end_date).format(
            'LT'
          ),
        });
        setTypeOfMessage(UNAVAILABLE_EXISTING_AVAILABLE);
      } else {
        setTypeOfMessage(AVAILABLE_EXISTING_UNAVAILABLE);
      }
    }
  };

  const addNewAvailability = async (
    available,
    force_submit,
    split_submit,
    employee_id,
    index,
    start_date,
    end_date,
    item,
    force_unassigned_visit,
  ) => {
    debugger
    let newData = {};
    let responseData = {};
    if (values.shift_type === 'new_shift') {
      responseData = await getChoices('regular_shift_type').then(response => {
        newData = response.regular_shift_type.find(items => {
          if (items.name == values.name) {
            return items;
          }
        });
        setContextChoices({ ...contextChoices, ...response });
        return newData;
      });
      getChoicesMap('regular_shift_type')
        .then(res => {
          setContextChoicesMap({ ...contextChoicesMap, ...res })
        })
    }

    let detail_tem_id = [];
    if (force_submit) {
      item.forEach(val => {
        detail_tem_id.push(val.detail_id);
      });
    }
    let detail_ids = {};
    if (detail_tem_id.length > 0) {
      detail_ids = { detail_id: detail_tem_id };
    }
    const availabilityValues = [];
    if (values.full_day) {
      if (!values.end_day) values.end_day = values.start_day;
      values.start = '00:00';
      values.end = '23:59:59';
    }
    let errorValue = values.full_day ? false : errors.length > 0;
    if (!values?.shiftDragDrop && (errorValue ||
      !values.employee ||
      (!values.start_day && !values.end_day) ||
      (!values.start && !values.end) ||
      !validateDate(!values.full_day, values.start_day, values.end_day) ||
      invalidOvernight)
    ) {
      setCheckRequires(!values.full_day);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }
    if (employee_id) {
      setLoadingProceed([...loadingProceed, index + 1]);
    }
    setLoadingSave(true);
    setErrorMessage('');
    let forceUpdate = { force: false };

    if (force_submit) {
      forceUpdate = { force: true };
    }

    let firstDayValue = employee_id
      ? start_date.split(' ')[0]
      : values.start_day;
    let weeks = [];
    let day_information = [];

    let no_of_week = moment(firstDayValue).isoWeek();
    weeks.push(no_of_week);

    let endDayValue = employee_id ? end_date.split(' ')[0] : values.end_day;
    while (moment(firstDayValue).isSameOrBefore(endDayValue)) {
      const searchWeek = weeks.indexOf(moment(firstDayValue).isoWeek());

      if (searchWeek <= -1) {
        weeks.push(moment(firstDayValue).isoWeek());
      }

      const beginningTime = moment(values.start, 'h:mma');
      const endTime = moment(values.end, 'h:mma');
      const start_date_format = firstDayValue + ' ' + values.start + ':00';
      const end_date_format =
        firstDayValue +
        ' ' +
        (values.full_day ? values.end : values.end + ':00');
      day_information.push({
        week_no: moment(firstDayValue).isoWeek(),
        details: {
          is_available: available,
          start_date: start_date_format,
          end_date: end_date_format,
        },
      });

      firstDayValue = moment(firstDayValue).add(1, 'days').format('YYYY-MM-DD');
    }

    weeks.forEach(week => {
      const my_days = [];

      day_information.forEach(day_info => {
        if (day_info.week_no === week) {
          delete day_info.week_no;
          my_days.push({ ...day_info.details });
        }
      });
      const weeks = [...daysOfWeek];
      const weekDays = weeks.filter(weeks => weeks.selected === true);
      const weekArray = [];
      weekDays.forEach(w => {
        weekArray.push(w.day);
      });
      const week_of_day = weekArray.join();

      const empArray = [];
      if (employee_id) {
        empArray[0] = employee_id;
      } else {
        if (Array.isArray(values.employee)) {
          values.employee.forEach(e => {
            empArray.push(e.value);
          });
        } else {
          empArray.push(values.employee);
        }
      }
      let unavailable_id = ''

      if (!available) {
        unavailable_id = values.is_absent ? choices.regular_shift_type.find(val => val.name == 'Absence').id : choices.regular_shift_type.find(val => val.name == 'Unavailability').id
      }

      if (values.idSelected) {
        (available || isShiftDragAndDrop)
          ? availabilityValues.push({
            ...forceUpdate,
            id: values.idSelected,
            break_details: values.break_details.length ? values.break_details : [],
            employee: empArray[0],
            week_of_day: week_of_day ? week_of_day : null,
            start_date: (employee_id ? start_date.split(' ')[0] : values.start_day) + ' ' + values.start + ':00',
            end_date: (employee_id ? end_date.split(' ')[0] : values.end_day) + ' ' + (values.full_day ? values.end : values.end + ':00'),
            travel_mode: values.travel_method ? values.travel_method : null,
            travel_time: values.travel_time ? values.travel_time : null,
            pay_rate_card: values.regular_shift_pay_rate_id ? values.regular_shift_pay_rate_id : null,
            shift_bill_rate: values.travel_rate ? values.travel_rate : null,
            shift_type:
              values.shift_type === 'new_shift'
                ? responseData.id
                : (values.shift_type ? values.shift_type : null),
            comment: values.comment ? values.comment : null,
            is_overnight: values.is_overnight,
            is_split: split_submit || false,
            force_unassigned_visit: force_unassigned_visit,
            is_available: true,
            branch: values.branch_id,
            remote_work : values.remote_work || false,
          })
          : availabilityValues.push({
            ...forceUpdate,
            id: values.idSelected,
            employee: values?.shiftDragDrop ? empArray[0]: empArray,
            week_of_day: week_of_day,
            start_date: (employee_id ? start_date.split(' ')[0] : values.start_day) + ' ' + values.start + ':00',
            end_date: (employee_id ? end_date.split(' ')[0] : values.end_day) + ' ' + (values.full_day ? values.end : values.end + ':00'),
            travel_mode: values.travel_method ? values.travel_method : null,
            travel_time: values.travel_time ? values.travel_time : null,
            pay_rate_card: values.regular_shift_pay_rate_id,
            shift_bill_rate: values.travel_rate,
            shift_type: unavailable_id,
            comment: values.comment,
            is_overnight: values.is_overnight,
            is_split: split_submit || false,
            force_unassigned_visit: force_unassigned_visit,
            is_available: false,
            is_absent: values.is_absent ? values.is_absent : false,
            absence_informed_method: values.absence_informed_method
              ? values.absence_informed_method
              : null,
            absence_paid: values.absence_paid ? values.absence_paid : null,
            absence_planned: values.absence_planned
              ? values.absence_planned
              : null,
            absence_reason: values.absence_reason
              ? values.absence_reason
              : null,
            absence_type: values.absence_type ? values.absence_type : null,
            absence_notes: values.absence_notes ? values.absence_notes : null,
            sickness_reason: values.sickness_reason
              ? values.sickness_reason
              : null,
            branch: values.branch_id
          });
      } else {
        (available || isShiftDragAndDrop)
          ? availabilityValues.push({
            ...forceUpdate,
            employee: empArray,
            break_details: values.break_details.length ? values.break_details : [],
            week_of_day: week_of_day ? week_of_day : null,
            start_date: (employee_id ? start_date.split(' ')[0] : values.start_day) + ' ' + values.start + ':00',
            end_date: (employee_id ? end_date.split(' ')[0] : values.end_day) + ' ' + (values.full_day ? values.end : values.end + ':00'),
            travel_mode: values.travel_method ? values.travel_method : null,
            travel_time: values.travel_time ? values.travel_time : null,
            pay_rate_card: values.regular_shift_pay_rate_id ? values.regular_shift_pay_rate_id : null,
            shift_bill_rate: values.travel_rate ? values.travel_rate : null,
            shift_type:
              values.shift_type === 'new_shift'
                ? responseData.id
                : (values.shift_type ? values.shift_type : null),
            comment: values.comment ? values.comment : null,
            is_overnight: values.is_overnight,
            is_split: split_submit || false,
            force_unassigned_visit: force_unassigned_visit,
            is_available: true,
            branch: values.branch_id,
            remote_work : values.remote_work || false,
          })
          : availabilityValues.push({
            ...forceUpdate,
            employee: empArray,
            week_of_day: week_of_day,
            start_date: (employee_id ? start_date.split(' ')[0] : values.start_day) + ' ' + values.start + ':00',
            end_date: (employee_id ? end_date.split(' ')[0] : values.end_day) + ' ' + (values.full_day ? values.end : values.end + ':00'),
            travel_mode: values.travel_method ? values.travel_method : null,
            travel_time: values.travel_time ? values.travel_time : null,
            pay_rate_card: values.regular_shift_pay_rate_id,
            shift_bill_rate: values.travel_rate,
            shift_type: unavailable_id,
            comment: values.comment,
            is_overnight: values.is_overnight,
            is_split: split_submit || false,
            force_unassigned_visit: force_unassigned_visit,
            is_available: false,
            is_absent: values.is_absent ? values.is_absent : false,
            absence_informed_method: values.absence_informed_method
              ? values.absence_informed_method
              : null,
            absence_paid: values.absence_paid ? values.absence_paid : null,
            absence_planned: values.absence_planned
              ? values.absence_planned
              : null,
            absence_reason: values.absence_reason
              ? values.absence_reason
              : null,
            absence_type: values.absence_type ? values.absence_type : null,
            absence_notes: values.absence_notes ? values.absence_notes : null,
            sickness_reason: values.sickness_reason
              ? values.sickness_reason
              : null,
              branch: values.branch_id
          });
      }
    });

    const saveAvailability = () => {
      return async function (availabilities) {
        let response = await setAvailability(availabilities);
        console.log(response, "response")
        if (response.status == "FAIL" && response.message == "Please enter valid branch") {
          toast && toast.current.show({ severity: 'error', summary: 'Failed', detail: response.message});
          setLoadingSave(false);
          setErrorMessage(response.message);
          return;
        }
        // {"status":"FAIL","message":"Please enter valid branch"}
        if (response.status == 'Warning') {
          setLoadingSave(false);
          setErrorMessage(response.message);
        } else {
          return response;
        }
      };
    };

    const saveAvailabilitiesDetails = function (availability_Id) {
      return async function (details) {
        return await setAvailabilityDetails(details, availability_Id);
      };
    };

    let savedAvailabilities = [];

    let removeDuplicateAvailablity = [availabilityValues[0]];
    const resultAvailabilities = await Promise.all(
      removeDuplicateAvailablity.map(saveAvailability())
    )

      .then(result => {
        if (result[0].message) {
          if ((result[0].message === 'Employee Shift has been created') || (result[0].message === 'Employee Shift Successfully created') || (result[0].message === 'Employee Shift Successfully updated')) {
            setButtonIcon('');
            setButtonIcon(CHECK);
            setTextStatus(SAVED);
            setAddAvailability(false);
            setLoadingSave(false);
            let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
            setLoadingProceed([...loadingArr]);
            setDisableButton([...disableButton, index + 1]);
            if (result[0].unassigned_visit_data && (result[0].unassigned_visit_data.length > 0)) {
              setModalConfirmUnassignVisits(true)
              setUnassignedData(result[0].unassigned_visit_data)
            }
            reloadData();
            return result;
          } else {
            setIsDateAssigned(true);
            setCurrentModal(available);
            setOverlapMessage(result[0].message.message);
            if (available) {
              setAddAvailability(false);
            } else {
              setAddUnavailability(false);
            }
            setUnassignedVisitList(result[0].unassigned_visit_list)  
            if (result[0]?.message?.shift_availabilities_dates) {
              setOverlapData(result[0].message.shift_availabilities_dates && result[0].message.shift_availabilities_dates.map((val) => ({ ...val, force_unassigned_visit: false })));
              console.log(result[0].message.shift_availabilities_dates, "result[0].message.shift_availabilities_dates")
              setOverlapModal(!overlapModal);
            }
            assignMessageAvailableUnavailable(available, result);
            throw new Error();
          }
        } else {
          setButtonIcon('');
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
          setLoadingProceed([...loadingArr]);
          setDisableButton([...disableButton, index + 1]);
          reloadData();
          return result;
        }
      })
      .catch(error => {
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        setLoadingSave(false);
        let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
        setLoadingProceed([...loadingArr]);
        return [];
      });

    if (!resultAvailabilities) {
      return;
    }
    setLoadingSave(false);
  };

  const splitAvailability = async (
    available,
    force_submit,
    employee_id,
    start_date,
    end_date,
    item,
    index
  ) => {
    let detail_tem_id = [];

    item.forEach(val => {
      detail_tem_id.push(val.detail_id);
    });

    const availabilityValues = [];

    if (
      errors.length > 0 ||
      !values.employee ||
      (!values.start_day && !values.end_day) ||
      (!values.start && !values.end) ||
      !validateDate(true, values.start_day, values.end_day) ||
      !validateTime(
        true,
        values.start_day,
        values.end_day,
        values.start,
        values.end
      )
    ) {
      setCheckRequires(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    let newData = {};
    newData = choices.regular_shift_type.find(items => {
      if (items.name === values.name) {
        return items;
      }
    });

    setLoadingOverlap([...loadingOverlap, index + 1]);
    let forceUpdate = { force: false };

    if (force_submit) {
      forceUpdate = { force: true };
    }

    let firstDayValue = start_date.split(' ')[0];
    // let firstDayValue = start_date[0].split(" ")[0];
    //changed the start  as its changed in splitOverlapModal.js
    let weeks = [];
    let day_information = [];

    let no_of_week = moment(firstDayValue).isoWeek();
    weeks.push(no_of_week);

    while (moment(firstDayValue).isSameOrBefore(end_date.split(' ')[0])) {
      // while (moment(firstDayValue).isSameOrBefore(end_date[0].split(" ")[0])) {
      //changed the end dtae as its changed in splitOverlapModal.js
      const searchWeek = weeks.indexOf(moment(firstDayValue).isoWeek());

      if (searchWeek <= -1) {
        weeks.push(moment(firstDayValue).isoWeek());
      }

      const beginningTime = moment(values.start, 'h:mma');
      const endTime = moment(values.end, 'h:mma');
      // const crossDays =
      // start_date.split(" ")[0]!== end_date.split(" ")[0] &&
      //   endTime.isSameOrBefore(beginningTime);

      // const start_date_format = createDateWithTime(
      //   firstDayValue,
      //   values.start + ':00'
      // );
      const start_date_format = firstDayValue + ' ' + values.start;
      const end_date_format = firstDayValue + ' ' + values.end;
      day_information.push({
        week_no: moment(firstDayValue).isoWeek(),
        details: {
          id: values.idSelected,
          is_available: available,
          start_date: start_date_format,
          end_date: end_date_format,
          is_overnight: values.is_overnight,
          shift_type:
            values.shift_type === 'new_shift' ? newData.id : values.shift_type,
          travel_mode: values.travel_method ? values.travel_method : null,
          travel_time: values.travel_time ? values.travel_time : null,
          pay_rate_card: values.regular_shift_pay_rate_id,
          is_absent: values.is_absent ? values.is_absent : false,
          absence_informed_method: values.absence_informed_method
            ? values.absence_informed_method
            : null,
          absence_paid: values.absence_paid ? values.absence_paid : null,
          absence_planned: values.absence_planned
            ? values.absence_planned
            : null,
          absence_reason: values.absence_reason ? values.absence_reason : null,
          absence_type: values.absence_type ? values.absence_type : null,
          absence_notes: values.absence_notes ? values.absence_notes : null,
          sickness_reason: values.sickness_reason
            ? values.sickness_reason
            : null,
        },
      });

      firstDayValue = moment(firstDayValue).add(1, 'days').format('YYYY-MM-DD');
    }

    weeks.forEach(week => {
      const my_days = [];
      day_information.forEach(day_info => {
        if (day_info.week_no === week) {
          delete day_info.week_no;
          my_days.push({ ...day_info.details });
        }
      });
      // const weeks = [...daysOfWeek]
      // const weekDays = weeks.filter(weeks => weeks.selected === true);
      //  const weekArray =[] ;
      //  weekDays.forEach(w=>{
      // weekArray.push(w.day);
      //  });
      //  const week_of_day = weekArray.join();

      let update_keys = {};

      if (values.idSelected) {
        update_keys = {
          update_detail: { ...my_days[0] },
          is_update: true,
        };
      } else {
        update_keys = {
          details: [...my_days],
        };
      }

      availabilityValues.push({
        employee: employee_id,
        force: true,
        start_date: start_date.split(' ')[0] + ' ' + values.start + ':00',
        end_date: end_date.split(' ')[0] + ' ' + values.end,
        detail_id: [...detail_tem_id],
        travel_method: values.travel_method ? values.travel_method : null,
        travel_time: values.travel_time ? values.travel_time : null,
        shift_type:
          values.shift_type === 'new_shift' ? newData.id : values.shift_type || values.is_absent ? absenceId.id : newShiftId.id,
        shift_bill_rate: values.travel_rate,
        is_overnight: values.is_overnight,

        ...update_keys,
      });
    });

    const saveAvailability = () => {
      return async function (availabilities) {
        return await setSplitAvailability(availabilities);
      };
    };

    const saveAvailabilitiesDetails = function (availability_Id) {
      return async function (details) {
        return await setAvailabilityDetails(details, availability_Id);
      };
    };

    let savedAvailabilities = [];
    const resultAvailabilities = await Promise.all(
      availabilityValues.map(saveAvailability())
    )
      .then(result => {
        console.log(result, 75946);
        if (result[0].message) {
          setIsDateAssigned(true);
          assignMessageAvailableUnavailable(available, result);
          throw new Error();
        } else {
          setButtonIcon('');
          setButtonIcon(CHECK);
          setTextStatus(SAVED);
          let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
          setLoadingOverlap([...loadingArr]);
          setDisableButton([...disableButton, index + 1]);
          return result;
        }
      })
      .catch(error => {
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        let loadingArr = loadingOverlap.filter(ele => ele !== index + 1);
        setLoadingOverlap([...loadingArr]);
        return [];
      });

    if (!resultAvailabilities) {
      return;
    }

    savedAvailabilities.push(...resultAvailabilities);
    if (!values.idSelected) {
      savedAvailabilities.forEach((availability, index_availability) => {
        Promise.all(
          availabilityValues[index_availability].details.map(
            saveAvailabilitiesDetails(availability.id)
          )
        );
      });
    }
    setLoadingSave(false);
    reloadData();
  };

  const employeesHandleSelect = preferred => {
    setValues({ ...values, ...preferred });
    setSelectedEmployees(preferred.employee);
  };

  const handleDropAbsenceType = item => {
    if (item.absence_type || item.absence_type == null) {
      values.absence_reason = null;
      values.sickness_reason = null;
    }
    setValues({ ...values, ...item });
  };

  const handleDeleteUnavailabilityDetail = async () => {
    await deleteAvailabilities(values?.shiftAvailabilityId);
    // }
    reloadData();
    handleAddUnavailability();
  };

  const handleConfirmUnassignVisitsPopup = () => {
    let unassignIds = unassignedData.map((item) => {
      return item.visit_id
    })
    let body = {
      unassigned_visit_ids: unassignIds
    }
    setUnassignedVisitOnRota(body)
      .then((res) => {
        if (res.status)
          setModalConfirmUnassignVisits(false)
        reloadData()
      })
      .catch((error) => { })
  }

  return (
    <>
      <div>
        {!addAvailability
          ? null
          :
          <AddAvailability
           loadingShiftPage={loadingShiftPage}
            readOnly={readOnly}
            travelRate={travelRate}
            // dropDownHandleRate={dropDownHandleRate}
            dropDownHandleSelect={dropDownHandleSelect}
            fullBind={fullBindAddAvailability}
            useInput={useInput}
            employees={employees}
            values={values}
            addNewAvailability={addNewAvailability}
            splitAvailability={splitAvailability}
            deleteAvailabilityDetail={handleDeleteAvailabilityDetail}
            errorMessage={errorMessage}
            buttonIcon={buttonIcon}
            loadingSave={loadingSave}
            textStatus={textStatus}
            dateMessage={dateMessage}
            timeMessage={timeMessage}
            dateError={dateError}
            timeError={timeError}
            choices={choices}
            closeModal={() => {
              setAddAvailability(false);
            }}
            isDateAssigned={isDateAssigned}
            typeOfMessage={typeOfMessage}
            //added new props below
            shiftIndex={0}
            daysOfWeek={daysOfWeek}
            setDaysOfWeek={setDaysOfWeek}
            requireDayOfWeek={requireDayOfWeek}
            nameSection={`${SHIFT_VALUES}`}
            setValues={setValues}
            filterEmployee={filterEmployee}
            filterClient={filterClient}
            userStaff={true}
            filterSuperAdmin={filterSuperAdmin}
            filterAdmin={filterAdmin}
            invalidOvernight={invalidOvernight}
            setInvalidOvernight={setInvalidOvernight}
            availabilitiesEmployeeId={availabilitiesEmployeeId}
            availabilitiesEmployeeFullName={availabilitiesEmployeeFullName}
            availabilitiesEmployeeIdName={availabilitiesEmployeeIdName}
            employeesHandleSelect={employeesHandleSelect}
            availabilityDetailsDays={availabilityDetailsDays}
            detailId={detailId}
            createAvailable={true}
            createSelfAvailable={true}
            shiftTypePopup={shiftTypePopup}
            employeeDisable={employeeDisable}
            copyShift={copyShift}
          />
        }
        {!addUnavailability
          ? null
          : (
            <AddUnavailability
              readOnly={readOnly}
              dropDownHandleSelect={dropDownHandleSelect}
              fullBind={fullBindAddUnavailability}
              useInput={useInput}
              employees={employees}
              choices={choices}
              values={values}
              errorTime={errorTime}
              splitAvailability={splitAvailability}
              addNewAvailability={addNewAvailability}
              deleteAvailabilityDetail={handleDeleteUnavailabilityDetail}
              errorMessage={errorMessage}
              buttonIcon={buttonIcon}
              loadingSave={loadingSave}
              textStatus={textStatus}
              dateMessage={dateMessage}
              timeMessage={timeMessage}
              dateError={dateError}
              timeError={timeError}
              closeModal={() => {
                setAddUnavailability(false);
              }}
              isDateAssigned={isDateAssigned}
              typeOfMessage={typeOfMessage}
              //added new props below
              shiftIndex={0}
              daysOfWeek={daysOfWeek}
              setDaysOfWeek={setDaysOfWeek}
              requireDayOfWeek={requireDayOfWeek}
              nameSection={`${SHIFT_VALUES}`}
              setValues={setValues}
              filterEmployee={filterEmployee}
              filterClient={filterClient}
              userStaff={true}
              filterSuperAdmin={filterSuperAdmin}
              filterAdmin={filterAdmin}
              invalidOvernight={invalidOvernight}
              setInvalidOvernight={setInvalidOvernight}
              handleDropAbsenceType={handleDropAbsenceType}
              employeesHandleSelect={employeesHandleSelect}
              availabilityDetailsDays={availabilityDetailsDays}
              createUnAvailable={true}
              createSelfUnAvailable={true}
              copyShift={copyShift}
            />
          )}

        {!overlapModal ? null : (
          <SplitOverlapModal
            fullBind={fullBindOverlapModal}
            shiftOverlapData={shiftOverlapData}
            unassignedVisitList={unassignedVisitList}
            setOverlapData={setOverlapData}
            employees={employees}
            splitAvailability={splitAvailability}
            loadingOverlap={loadingOverlap}
            addNewAvailability={addNewAvailability}
            loadingProceed={loadingProceed}
            disableButton={disableButton}
            overlapMessage={overlapMessage}
            currentModal={currentModal}
            values={values}
          />
        )}
        {/* {!overlapModalShift ? null : (
          <ShiftSplitOverlapModal
            fullBind={fullBindOverlapModalShift}
            shiftOverlapData={shiftOverlapData}
            setOverlapData={setOverlapData}
            employees={employees}
            loadingOverlap={loadingOverlap}
            addShift={addShift}
            loadingProceed={loadingProceed}
            disableButton={disableButton}
            overlapMessage={overlapMessage}
            currentModal={currentModal}
            values={values}
          />
        )} */}

        {modalValueUnassignVisits && (
          <ConfirmationModal
            // selectedClientId={selectedClientId}
            type={'warning'}
            // title={''}
            // okTitle={'NO, CANCEL'}
            // onCancelTitle={'YES, PROCEED'}
            icon={ERROR_OUTLINE}
            // hideOnCancel={false}
            // onCancel={hideAllService}
            unassignedData={unassignedData}
            setModalValue={setModalConfirmUnassignVisits}
            fullBind={fullBindUnassignVisits}
            checkBeforeSend={() => {
              handleConfirmUnassignVisitsPopup()
            }}
            // loadingUnassigned={loadingUnassigned}
            // disableButtonUnassigned={disableButtonUnassigned}
            reloadData={reloadData}
          />)
        }
      </div>
    </>
  )
}


export default RotaShiftsActions;