import React, {Fragment} from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {
    IconWrapper,
  } from 'components/SharedComponents/Table/styles';
  import AdjustEmployee from './AdjustEmployee'
  import {RiDeleteBinLine} from 'react-icons/ri';
  import {PrimaryButtonForm} from 'shared/styles/buttons';
import TimeSelector from 'components/SharedComponents/TimeSelector';


const RequirementEmployeePost = ({
    values,
    setValues,
    index,
    clients,
    dropDownHandleRegEmpDet,
    useInput,
    deleteEmployeeDetails,
    employeeRequired,
    empEmployeeReq,
    empDayOptions,
    empVisit,
    runSpecific,
    employees,
    visitDetails
}) => {

  // const addNewAdjustDetails=()=>{
  //   if(!visitDetails.adjustDetails){
  //     values.employee_day_detail[index].adjustDetails=[]
  //   }
  //   values.employee_day_detail[index].adjustDetails=[...values.employee_day_detail[index].adjustDetails,{}]
  //   setValues({...values})
  // }

  // const deleteEmployeeAdjust=(innerIndex)=>{
  //   values.employee_day_detail[index].adjustDetails.splice(innerIndex,1)
  //   setValues({...values})
  // }

  const switchShiftField = props => {
    if (visitDetails && visitDetails.employee && runSpecific) {
     return( 
      <div className={'w-50'}>
      <DropdownSearchable
           required
           {...props}
            placeHolder={'Shift Name'}
            options={visitDetails.shiftArray ? visitDetails.shiftArray : []}
            selectedOption={values && values.employee_day_detail &&
              values.employee_day_detail[index] &&
              values.employee_day_detail[index].shift
              ?values.employee_day_detail[index].shift:null}
            onChange={value =>
              dropDownHandleRegEmpDet({shift: value ? value : null}, 'shift',index)
            }
          />
      </div>
     )
    } else {
      return(
        <Fragment />
      )
    }
  };
    return (
      <>
        <div className={`d-flex mt-2 mb-3 me-4`}>
       
        <div className={'w-50 me-2'}>
      <DropdownSearchable
       required
       {...useInput(
        `employee_day_detail[${index}].client`,
        'isRequired'
      )}
            placeHolder={'Client'}
            options={clients}
            selectedOption={values && values.employee_day_detail &&
              values.employee_day_detail[index] &&
              values.employee_day_detail[index].client
              ?values.employee_day_detail[index].client:null}
            onChange={value =>
              dropDownHandleRegEmpDet({client: value ? value : null}, 'client',index)
            }
          />
      </div>
      <div className={'w-50 me-2'}>
        <DropdownSearchable
              required
              {...useInput(
               `employee_day_detail[${index}].repeat_on`,
               'isRequired'
             )}
             width={'100%'}
             isMulti={true}
              placeHolder={'Select Day'}
              options={[
                {id:"1",name:"MON"},
                {id:"2",name:"TUE"},
                {id:"3",name:"WED"},
                {id:"4",name:"THU"},
                {id:"5",name:"FRI"},
                {id:"6",name:"SAT"},
                {id:"7",name:"SUN"},
                ]}
                  selectedOptionsProps={
                 values && values.employee_day_detail &&
                 values.employee_day_detail[index] &&
                 values.employee_day_detail[index].repeat_on
                 ?values.employee_day_detail[index].repeat_on:null
              }
              onChange={values =>
                  dropDownHandleRegEmpDet(
                  {repeat_on: values.length ? values : null},
                  'repeat_on',index
                )
              }
            />
        </div>
      <div className={'w-50 me-2'}>
      <DropdownSearchable
           required
           {...useInput(
            `employee_day_detail[${index}].visit`,
            'isRequired'
          )}
            placeHolder={'Visits'}
            options={empVisit[index]}
            selectedOption={values && values.employee_day_detail &&
              values.employee_day_detail[index] &&
              values.employee_day_detail[index].visit
              ?values.employee_day_detail[index].visit:null}
            onChange={value =>
              dropDownHandleRegEmpDet({visit: value ? value : null}, 'visit',index)
            }
          />
      </div>

      <div className={'w-50 me-2'}>
            <TimeSelector
                  isRequired
                  labelTop={'Pin Start Time'}
                  timeFormat={'HH:mm'}
                  dateFormat={'HH:mm'}
                  value={values.employee_day_detail[index].start_time}
                  {...useInput(`employee_day_detail[${index}].start_time`,)}
                />
                </div>
                <div className={'w-50 me-2'}>
                 <TimeSelector
                  isRequired
                  labelTop={'Pin End Time'}
                  timeFormat={'HH:mm'}
                  dateFormat={'HH:mm'}
                  value={values.employee_day_detail[index].end_time}
                  {...useInput(`employee_day_detail[${index}].end_time`,
                  'isRequired')}
                />
            </div>
      
      <div className={'w-50 me-2'}>
      <DropdownSearchable
            placeHolder={'Requirement'}
            options={empEmployeeReq[index]}
            selectedOption={values && values.employee_day_detail &&
              values.employee_day_detail[index] &&
              values.employee_day_detail[index].requirement
              ?values.employee_day_detail[index].requirement:null}
            onChange={value =>
              dropDownHandleRegEmpDet({requirement: value ? value : null}, 'requirement',index)
            }
          />
      </div>
      {runSpecific && 
         <div className={'w-50 me-2'}>
         <DropdownSearchable
               placeHolder={'Employees'}
               options={employees}
               selectedOption={values && values.employee_day_detail &&
                 values.employee_day_detail[index] &&
                 values.employee_day_detail[index].employee
                 ?values.employee_day_detail[index].employee:null}
               onChange={value =>
                 dropDownHandleRegEmpDet({employee: value ? value : null}, 'employee',index)
               }
             />
         </div>
      }
   
   {switchShiftField( {...useInput(`employee_day_detail[${index}].shift`,'isRequired')})}
           {/* {index!==0 && */}
           <IconWrapper
           className={'ms-2'}
           onClick={() => {
             deleteEmployeeDetails(index);
            }}
            >
          <RiDeleteBinLine 
          color="red"
          cursor='pointer'
          width='6rem'
          />
      </IconWrapper>
          {/* } */}
          </div>
               {/* <div>
               {visitDetails && visitDetails.adjustDetails && visitDetails.adjustDetails.map((adjustDet,adjustIndex)=>{
                 return (
                      <AdjustEmployee
                       index={adjustIndex}
                       employees={employees}
                       deleteEmployeeAdjust={deleteEmployeeAdjust}
                      />
                 )
                     
               })}
                <PrimaryButtonForm
                         bgcolor="BLUE"
                         minWidth="0.5rem"
                         className={'mb-2 ms-2'}
                         onClick={addNewAdjustDetails}
                         >
                         <span>{'Add Adjustment'}</span>
                       </PrimaryButtonForm>
                       </div> */}
                       </>
    );
}

export default RequirementEmployeePost;
