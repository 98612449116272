import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ErrorMessage, ModalContainer } from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { Fragment } from 'react';
import ModalNoInfo from '../ModalDynamic/ModalNoInfo';


const MarkReviewPopup = ({
    fullBind,
    useInput,
    values,
    setValues,
    choices,
    editMode,
    handleMarkReview,
    errorMessage,
    showError,
    dropDownHandleSelect = () => { },
    title = "",
    textStatus,
    loadingSave,
    buttonIcon
}) => {

    const [addFullBind, setAddFullBind] = useState(fullBind);
    const ins_hook = useInput(`review_comment`);

    useEffect(() => {
        setAddFullBind({
            ...addFullBind,
            title: `${title} Review`,
        });
    }, [fullBind]);

    return (
        <ModalContainer max_width={'60%'}>
            <ModalNoInfo
                {...addFullBind}
                max_width={'60%'}
                minWidthTitle="8.5rem"
                loading={false}>
                <div className='pt-2 ps-3 pe-3 pb-3'>
                    <div className='d-flex'>
                        <div className="w-50 me-1">
                            <DropdownSearchable
                                required
                                // disabled={!editMode}
                                placeHolder={'Review Status'}
                                options={choices?.medication_review_status || []}
                                {...useInput(`review_status`, 'isRequired')}
                                selectedOption={
                                    values.review_status || null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { review_status: value ? value : null },
                                        'review_status'
                                    )
                                }
                            />
                        </div>
                        <div className="d-flex w-50">
                            <div className="w-100 ps-1">
                                {/* <div
                                    className={'pt-2 ms-md-1 ms-0'}
                                    style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                                    Review Comment
                                </div> */}
                                <InputTextArea
                                    type="text"
                                    height={'3.4rem'}
                                    id="emp_desc"
                                    placeholder={'Review Comment'}
                                    label={'Review Comment'}
                                    {...ins_hook}
                                //   maxlength={5}
                                />
                            </div>
                        </div>
                    </div>

                    <div
                        className={
                            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
                        }>
                        <div className={'mb-3'}>
                            {showError ? (
                                <ErrorMessage>
                                    <label className={'mt-2'}>{errorMessage}</label>
                                </ErrorMessage>
                            ) : (
                                <Fragment />
                            )}
                        </div>

                        <div
                            className={'d-flex justify-content-center justify-content-md-end'}>
                            <div className={'ms-0 ms-md-3 mt-2 me-2'}>
                                <PrimaryButtonForm
                                    bgcolor="PURPLE_GRAY_500"
                                    minWidth="6rem"
                                    onClick={handleMarkReview}>
                                    <span className={'me-2'}>{textStatus}</span>
                                    {!loadingSave ? (
                                        buttonIcon
                                    ) : (
                                        <span
                                            className="spinner-border spinner-border-sm me-1"
                                            role="status"
                                        />
                                    )}
                                </PrimaryButtonForm>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </ModalNoInfo>
        </ModalContainer>
    );
};

export default MarkReviewPopup;