
import React, { useState, useEffect, Fragment } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';

import { setAssessment, setAssessmentCategory, setAssessmentType } from 'utils/api/SettingsApi';
import {
  FlexGrid3Container,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import _ from 'lodash';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';
import { useRef } from 'react';
import { useMemo } from 'react';
import { KEY_WORDS } from '..';
import RichTextEditor from 'components/SharedComponents/RichTextEditor';

function AssessmentModal({
  selectedData,
  selectedMethod,
  selectedType,
  setModalValue,
  fullBind,
  setTitle,
  setselectedData,
  setselectedMethod,
  setselectedType,
  loadRolPermission,
  handleChangeChoices,
  values,
  setValues,
  useInput,
  isValid,
  errors,
  setCheckRequires,
  readOnly
}) {


  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');


  useEffect(() => {
    if (selectedMethod === 'edit') {
      setCheckRequires(false)
      setTitle(`Edit ${selectedType}`)
      if (selectedType == KEY_WORDS.ASSESSMENT_TYPE) {
        values.message = selectedData.message
      }
      setValues({ ...values, name: selectedData.name })
      setLoadingModal(false);

    } else {
      setTitle(`Add ${selectedType}`)
      setCheckRequires(false)
      values.message = ""
      values.name = ""
      setValues({ ...values })
      setLoadingModal(false);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMethod, selectedType, selectedData]);

  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);


  const handleSubmit = () => {
    if (!isValid && errors.length > 0) {
      setErrorMessage('Name required');
      setCheckRequires(true);
      return;
    }
    setLoadingSave(true);
    if (selectedType === KEY_WORDS.ASSESSMENT) {
      let body = (selectedMethod === 'add') ? {
        name: values.name
      } :
        {
          name: values.name,
          id: selectedData.id
        }
      setAssessment(body)
        .then(response => {
          values.id = response.id;
          onSuccess(response);
        })
        .catch(onError);
    } else if (selectedType === KEY_WORDS.ASSESSMENT_CATEGORY) {
      let body = (selectedMethod === 'add')
        ? {
          name: values.name,
          assessment: selectedData.category ? selectedData.category : selectedData.id
        } : {
          id: selectedData.id,
          name: values.name,
          assessment: selectedData.category ? selectedData.category : selectedData.id

        }
      setAssessmentCategory(body)
        .then(response => {
          values.id = response.id;
          onSuccess(response);
        })
        .catch(onError);
    } else if (selectedType === KEY_WORDS.ASSESSMENT_TYPE) {
      let body = (selectedMethod === 'add') ? {
        name: values.name,
        message: values.message,
        category: selectedData.type_id
          ? selectedData.type_id
          : selectedData.id
      } : {
        id: selectedData.id,
        name: values.name,
        message: values.message,
        category: selectedData.type_id
          ? selectedData.type_id
          : selectedData.id
      }
      setAssessmentType(body)
        .then(response => {
          values.id = response.id;
          onSuccess(response);
        })
        .catch(onError);
    }
  };

  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setModalValue();
    loadRolPermission()
    handleChangeChoices()
  };

  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
  };

  const config = useMemo(
    () => ({
      readonly: readOnly,
    }),
    []
  )


  const handleMessage = (content) => {
    values.message = content
    setValues({ ...values })
  }
  console.log(values, 'values')
  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>


        <div className="ps-3 pe-3 pb-3 pt-0">


          <FlexGrid3Container className={'w-100 mb-2'}>
            <InputLabelTop
              required
              type="text"
              id="name"
              readOnly={readOnly}
              label={'Name'}
              {...useInput('name', 'isRequired')}
              maxlength={5000}
            />
          </FlexGrid3Container>

          {(selectedType === KEY_WORDS.ASSESSMENT_TYPE) && (
            <div className={'w-100 mb-2'}>
              <div className="w-100 ps-0 ps-md-1 pe-1 pb-1">
                <small className='ms-1'>Message</small>
                <RichTextEditor
                  value={values.message}
                  tabIndex={0} // tabIndex of textarea
                  onChange={newContent => handleMessage(newContent)}
                  config={config}
                />
              </div>
            </div>
          )}


          <div className={'d-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'}>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div className={'d-flex justify-content-center justify-content-md-end'}>
              <div className={'ms-0 ms-md-3 me-2'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={handleSubmit}
                  disabled={readOnly}
                >
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>


        </div>
      </ModalDynamic>
    </ModalContainer>
  );
}

export default AssessmentModal