import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  CloseButtonWrapper,
} from 'shared/styles/constants/tagsStyles';
import { PLUS, TIMES } from 'utils/constants/icons';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { Header, Title, TitleSection } from 'shared/styles/constants/tagsStyles';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';

const AddVisitReminder = ({
  index,
  values,
  item,
  deleteVisitReminder,
  useInput,
  readOnly,
  deletePermission,
  setValues,
  timeValues
}) => {

  

  const handleUpdate = (index) => {
    let final = timeValues;
    for (let i = 0; i < timeValues.length; i++) {
      if (i !== index && values['visit_reminders'][i]
        && values['visit_reminders'][i].reminder !== undefined) {
        final = final.filter(el => (el.id !== values['visit_reminders'][i].reminder));
      }
    }

    return final;
};
const dropDownHandleSelect = (value,name,index) => {
  values['visit_reminders'][index].reminder = value[name]
  setValues({...values})
}
  return (
    <div>
      <>
        <div
          key={index}
          className={`mt-1 `}
          style={{ marginLeft: '15px', marginRight: '12px', width: '100%' }}>
          <div className="d-flex">
            <Fragment />
            {deletePermission &&
              <CloseButtonWrapper className='ms-3 me-2'
                onClick={() => {
                  deleteVisitReminder(index);
                }}>
                {TIMES}
              </CloseButtonWrapper>}
            <div className="p-0  w-100 d-flex align-items-center" >
              <FlexGrid3Container className={'w-100 mb-1 mt-1 '}>
                <FlexGrid3Item
                  className="pe-1  pe-0 pe-md-1 pt-1" >
                    <DropdownSearchable
                            required
                            {...useInput(`visit_reminders[${index}].reminder`, 'isRequired')}
                            disabled={readOnly}
                            placeHolder={'Select Time'}
                            options={handleUpdate(index) || []}
                            selectedOption={values['visit_reminders'][index].reminder}
                            onChange={value =>
                                dropDownHandleSelect({
                                  reminder: value ? value : null,
                                },
                                    'reminder',
                                    index
                                )
                            }
                        />
                  {/* <TimeSelector
                    isRequired
                    disabled={readOnly}
                    labelTop={'Time From'}
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                    {...useInput(`visit_reminders[${index}].reminder`, 'isRequired')}
                    placeholderText={'hh:mm'}
                    locale={'sv-sv'}
                    timeCaption={'Duration'}
                    timeIntervals={'10'}
                    excludeTimes={
                      values?.visit_reminders
                      && values?.visit_reminders?.filter(el => el?.reminder)
                        ?.map(el => el?.reminder)}
                  /> */}

                </FlexGrid3Item>
                <FlexGrid3Item
                  className="pe-1 ms-4  pe-0 pe-md-1 pt-1" style={{ marginLeft: '10px' }}>
                  <ToggleButton
                    disabled={readOnly}
                    useInput={useInput(`visit_reminders[${index}].is_active`)}
                    id="visit_reminders[index].is_active"
                  />
                </FlexGrid3Item>
              </FlexGrid3Container>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddVisitReminder;
