import {
  EX_SMALL_COL,
    LARGE_COL,
    SMALL_COL,
    VERY_EX_SMALL_COL_CHECKBOX
  } from 'shared/styles/constants/columns';
  
  export const headerColumns = [
    {
      label: 1,
      checkbox: true,
      name: 'checkbox',
      status: 0,
      id: 'checkbox',
      removeCusCol: true,
      columnSelected: true,
      className: 'grow-mobile',
      max_width: VERY_EX_SMALL_COL_CHECKBOX,
},
{
  label: 'Branch Name',
  name: 'branch_name',
  status: 1,
  custum_column: true,
  id: 'branch_name',
  columnSelected: true,
  className: 'grow-mobile',
  max_width: LARGE_COL,
overlap: true
},
    {
        label: 'Client Name',
        name: 'client__full_name',
        status: 1,
        custum_column: true,
        id: 'client_name',
        columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
      {
        label: 'Employee Name',
        name: 'employee__id',
        status: 1,
        custum_column: true,
        id: 'employee_name',
        columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
        overlap: true
      },
      {
        label: 'Service Category',
        name: 'visit__service_category__id',
        status: 1,
        custum_column: true,
        id: 'service_category',
        columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
      {
        label: 'Start Date',
        name: 'schedule_start_date',
        status: 1,
        custum_column: true,
        id: 'start_date',
        columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
      {
        label: 'End Date',
        name: 'schedule_end_date',
        status: 1,
        custum_column: true,
        id: 'end_date',
          columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
        overlap: true
      },
      {
        label: 'Schedule Start Time',
        name: 'schedule_start_time',
        status: 1,
        custum_column: true,
        id: 'schedule_start_time',
        columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
      {
        label: 'Schedule End Time',
        name: 'schedule_end_time',
        status: 1,
        custum_column: true,
        id: 'schedule_end_time',
        columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
    {
      label: 'Schedule Duration(min)',
      name: 'schedule_duration',
      status: 1,
      custum_column: true,
      id: 'schedule_duration_hrs',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Travel Code',
      name: 'travel_code',
      status: 0,
      custum_column: true,
      id: 'travel_code',
      columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    overlap: true
    },
    {
        label: 'Travel Mode',
        name: 'travel_mode',
        status: 0,
        custum_column: true,
        id: 'travel_mode',
        columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
      {
        label: 'Postcode From',
        name: 'act_end_time',
        status: 0,
        custum_column: true,
        id: 'post_code_from',
        columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      overlap: true
      },
    {
      label: 'Postcode To',
      name: 'act_duration',
      status: 0,
      custum_column: true,
      id: 'post_code_to',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Visit Status',
      name: 'Visit Billing Amt',
      status: 0,
      custum_column: true,
      id: 'visit_status',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Bill Pattern',
      name: 'bill_pattern',
      status: 0,
      id: 'bill_pattern',
      columnSelected: true,
      custum_column: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'EVV Visit Confirmation',
      name: 'Visit Billing Amt',
      status: 0,
      custum_column: true,
      id: 'evv_visit_confirmation',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Travel Rate card',
      name: 'act_duration',
      status: 0,
      custum_column: true,
      id: 'travel_rate_card',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Sch Travel Time Hrs',
      name: 'Visit Billing Amt',
      status: 0,
      custum_column: true,
      id: 'schedule_travel_time_hrs',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Travel Time Pay Rate',
      name: 'Visit Billing Amt',
      status: 0,
      custum_column: true,
      id: 'schedule_travel_time_pay_rate',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Actual Travel Time Hrs',
      name: 'Visit Billing Amt',
      status: 0,
      custum_column: true,
      id: 'actual_travel_time_hrs',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Actual Travel Time Pay Rate',
      name: 'actual_travel_time_pay_rate',
      status: 0,
      custum_column: true,
      id: 'actual_travel_time_pay_rate',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Scheduled Travel Amt',
      name: 'visit__schedule_bill_amount',
      status: 1,
      custum_column: true,
      id: 'schedule_travel_amount',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Actual Travel Amt',
      name: 'actual_bill_rate_amount',
      status: 0,
      custum_column: true,
      id: 'actual_travel_amount',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Visit Bill Confirmation',
      name: 'Visit Billing Amt',
      status: 0,
      custum_column: true,
      id: 'vist_bill_confirmation',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Visit Travel Bill Confirmation',
      name: 'Visit Billing Amt',
      status: 0,
      custum_column: true,
      id: 'travel_bill_confirmation',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Cancelled Bill Percentage',
      name: 'cancelled_bill_percentage',
      status: 0,
      custum_column: true,
      id: 'cancelled_bill_percentage',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Visit Cancellation Reason',
      name: 'visit_cancellation_reason',
      status: 1,
      custum_column: true,
      id: 'visit_cancellation_reason',
      columnSelected: false,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Non-commute Scheduled Travel Amt',
      name: 'non_commute_schedule_travel_bill_amount',
      status: 0,
      custum_column: true,
      id: 'non_commute_schedule_travel_bill_amount',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Non-commute Scheduled Travel Duration (Hrs)',
      name: 'non_commute_schedule_travel_duration',
      status: 0,
      custum_column: true,
      id: 'non_commute_schedule_travel_duration',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Non-commute Scheduled Travel Distance',
      name: 'non_commute_schedule_travel_miles',
      status: 0,
      custum_column: true,
      id: 'non_commute_schedule_travel_miles',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Non-commute Scheduled Mileage Amt',
      name: 'non_commute_schedule_travel_miles_amount',
      status: 0,
      custum_column: true,
      id: 'non_commute_schedule_travel_miles_amount',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Non-commute Actual Travel Amt',
      name: 'non_commute_actual_travel_bill_amount',
      status: 0,
      custum_column: true,
      id: 'non_commute_actual_travel_bill_amount',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Non-commute Actual Travel Duration (Hrs)',
      name: 'non_commute_actual_travel_duration',
      status: 0,
      custum_column: true,
      id: 'non_commute_actual_travel_duration',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Non-commute Actual Travel Distance',
      name: 'non_commute_actual_travel_miles',
      status: 0,
      custum_column: true,
      id: 'non_commute_actual_travel_miles',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Non-commute Actual Mileage Amt',
      name: 'non_commute_actual_travel_miles_amount',
      status: 0,
      custum_column: true,
      id: 'non_commute_actual_travel_miles_amount',
        columnSelected: true,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Client Reference',
      name: 'client_reference',
      status: 1,
      custum_column: true, 
      id: 'client_reference',
        columnSelected: false,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Commisoned hrs (weekly)',
      name: 'commisoned_hours',
      status: 1,
      custum_column: true,
      id: 'commisoned_hours',
        columnSelected: false,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
    {
      label: 'Is Billable',
      name: 'is_billable',
      status: 1,
      custum_column: true,
      id: 'is_billable',
        columnSelected: false,
      className: 'grow-mobile',
      max_width: LARGE_COL,
      overlap: true
    },
 
  ];

  export const headerColumnsSelector = [
    {
      name: 'Checkbox',
      id: 'checkbox',
},
    {
        name: 'Client Name',
        id: 'client_name',
      },
      {
        name: 'Employee Name',
        id: 'employee_name',
      },
      {
        name: 'Service Category',
        id: 'service_category',
      },
      {
        name: 'Start Date',
        id: 'start_date'
      },
      {
        name: 'Schedule Start Time',
        id: 'schedule_start_time',
      },
      {
        name: 'Schedule End Time',
        id: 'schedule_end_time',
      },
    {
      name: 'Schedule Duration(min)',
      id: 'schedule_duration_hrs',
    },
    {
        name: 'Travel Mode',
        id: 'travel_mode',
      },
      {
        name: 'Postcode From',
        id: 'post_code_from',
      },
    {
      name: 'Postcode To',
      id: 'post_code_to',
    },
    {
      name: 'Visit Status',
      id: 'visit_status',
    },
    {
      name: 'EVV Visit Confirmation',
      id: 'evv_visit_confirmation',
    },
    {
      name: 'Travel Rate card',
      id: 'travel_rate_card',
    },
    {
      name: 'Sch Travel Time Hrs',
      id: 'schedule_travel_time_hrs',
    },
    {
      name: 'Travel Time Pay Rate',
      id: 'schedule_travel_time_pay_rate',
    },
    {
      name: 'Actual Travel Time Hrs',
      id: 'actual_travel_time_hrs',
    },
    {
      name: 'Scheduled Travel Amt',
      id: 'schedule_travel_amount',
    },
    {
      name: 'Actual Travel Amt',
      id: 'actual_travel_amount',
    },
    {
      name: 'Visit Bill Confirmation',
      id: 'vist_bill_confirmation',
    },
    {
      name: 'Visit Travel Bill Confirmation',
      id: 'travel_bill_confirmation',
    },
  ];
  
  export const headerColumnsSelectorByDefault = [
    {
      name: 'Checkbox',
      id: 'checkbox',
},
    {
        name: 'Client Name',
        id: 'client_name',
      },
      {
        name: 'Employee Name',
        id: 'employee_name',
      },
      {
        name: 'Service Category',
        id: 'service_category',
      },
      {
        name: 'Start Date',
        id: 'start_date'
      },
      {
        name: 'Schedule Start Time',
        id: 'schedule_start_time',
      },
      {
        name: 'Schedule End Time',
        id: 'schedule_end_time7',
      },
    {
      name: 'Schedule Duration(min)',
      id: 'schedule_duration_hrs',
    },
    {
        name: 'Travel Mode',
        id: 'travel_mode',
      },
      {
        name: 'Postcode From',
        id: 'post_code_from',
      },
    {
      name: 'Postcode To',
      id: 'post_code_to',
    },
    {
      name: 'Visit Status',
      id: 'visit_status',
    },
    {
      name: 'EVV Visit Confirmation',
      id: 'evv_visit_confirmation',
    },
    {
      name: 'Travel Rate card',
      id: 'travel_rate_card',
    },
    {
      name: 'Sch Travel Time Hrs',
      id: 'schedule_travel_time_hrs',
    },
    {
      name: 'Travel Time Pay Rate',
      id: 'schedule_travel_time_pay_rate',
    },
    {
      name: 'Actual Travel Time Hrs',
      id: 'actual_travel_time_hrs',
    },
    {
      name: 'Scheduled Travel Amt',
      id: 'schedule_travel_amount',
    },
    {
      name: 'Actual Travel Amt',
      id: 'actual_travel_amount',
    },
    {
      name: 'Visit Bill Confirmation',
      id: 'vist_bill_confirmation',
    },
    {
      name: 'Visit Travel Bill Confirmation',
      id: 'travel_bill_confirmation',
    },
  ];