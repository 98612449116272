import React, {useEffect, useState, useRef ,useContext} from 'react';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {KeyPress} from 'utils/constants/regularExpressions';
import {EVV, EVV_TRAVEL} from 'utils/constants/routes';
import {LARGE_COL,MEDIUM_COL,EX_SMALL_COL4,EX_LARGE_COL,SMALL_COL,EX_SMALL_COL} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {PrimaryButton, } from 'shared/styles/buttons';

import {
  HeaderVisits,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
  PublishButton
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import {getEvvTravel,getEvvExport} from 'utils/api/EvvApi';
import {useHistory, useLocation} from 'react-router-dom';
import {
  Row,
  Col,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import {MdRemoveRedEye, MdPanoramaFishEye} from 'react-icons/md';

import Pagination from 'components/SharedComponents/Pagination';
import queryString from 'query-string';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {getAdmin,getSuperAdmin,getStaff} from "../../../../utils/localStorage/token"
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {DOWNLOAD} from 'utils/constants/icons';
import NoSelectAllDropdownFilter from  'components/SharedComponents/NoSelectAllDropdownFilter';
import {AppContext} from "context"
import {BranchItemsContext} from "context"
import EvvBulkTravelModal from './EvvTraveBulklModal';
import {useModal} from 'hooks/ModalHook';
import {PURPLE, GRAY_300} from 'shared/styles/constants/colors';
import { getLocationList } from 'utils/api/SchedulerApi';
import ContextMenu, {useContextMenu} from 'components/SharedComponents/ContextMenu/ContextMenu';

const EvvTravel = ({
  values,
  setValues,
  useInput,
  dropDownHandleSelect,
  events,
  clients,
  employees,
  eventClickHandler,
  loadingCalendar,
  filterClient,
  filterEmployee,
  pages,
  runRouteData,
  branch,
  setFilterBranch,
  setRunroutefilter,
  branchFilter,
  runRoutesFilter,
  setClientfilter,
  setEmloyeefilter,
  employeesFilter,
  clientsFilter,
  loadingModal,
  loadEvvs,
  employeeRunRoutesFilter,
  setEmployeeRunRoutefilter,
  clearFilter,
  counts
}) => {
  const columns_values = [
    {
      label: 1,
      checkbox: true,
      name: 'checkbox',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_SMALL_COL4,
    },
    {
      label: 'Visit #',
      name: 'visit',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Client name',
      name: 'client__full_name',
      status: 1,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Location name',
      name: '',
      status: 1,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Employee name',
      name: 'employee__full_name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Scheduled start date',
      name: 'schedule_start_date',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Scheduled end date',
      name: 'schedule_end_date',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Travel code',
      name: 'travel_code',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Travel mode',
      name: 'travel_mode',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },

    {
      label: 'Journey Start Postcode',
      name: 'journey_start_zipcode',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Journey End Postcode',
      name: 'journey_end_zipcode',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Scheduled Distance (Miles)',
      name: 'schedule_travel_miles',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Scheduled Travel Time (h:mm)',
      name: 'schedule_travel_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Scheduled Travel Start Time (h:mm)',
      name: 'start_travel_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Scheduled Travel End Time (h:mm)',
      name: 'end_travel_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Actual Distance (Miles)',
      name: 'actual_travel_miles',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Actual Travel Time (h:mm)',
      name: 'actual_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },

    {
      label: 'Distance Confirmation',
      name: 'distance confirmation',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_LARGE_COL,
    },
    {
      label: 'Travel Confirmation',
      name: 'travel confirmation',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_LARGE_COL,
    },
    {
      label: '',
      name: '',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_SMALL_COL,
    },
  ];

  //added  4 datas  of travel to dispalyed -usestate below 
  const [numTravels, setNumTravels] = useState(0);
  const [visitProvisionFilter, setVisitProvisionFilter] = useState([]);
  const [visitConfirmationFilter, setVisitConfirmationFilter] = useState([]);
  const [timeDistanceFilter, setTimeDistanceFilter] = useState([]);
  const [sumTravelDuration, setSumTravelDuration] = useState(0);
  const [sumTravelDistance, setSumTravelDistance] = useState(0);
  const [numClients, setNumClients] = useState(0);
  const [numOfLocations, setNumOfLocations] = useState(0);
  const[downloadDisable,setDownloadDisable]=useState(false)
  const [numEmployees, setNumEmployees] = useState(0);
  const[currentPage,setCurrentPage]=useState(1);
  const[clearAll,setClearAll]=useState(false);
  const {headerFilterClearValue}=useContext(BranchItemsContext) ;
  const {branchData}=useContext(BranchItemsContext) ;
  const {contextIsAdmin, contextIsSuperAdmin, contextIsStaff}=useContext(AppContext)
  const [checkedIdArr, setCheckedIdArr] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [checkedId, setCheckedId] = useState([]);
  const [bulkId ,setBulkId]=useState([])
  const [evvDistance,SetEvvDistance]=useState(false)
  const [evvTravel ,setEvvTravel]=useState(false)
  const [locationFilter, setLocationFilter] = useState([])
  const [locations, setLocations] = useState([])
    const mountedRef = useRef(true);
  let location=useLocation()
  const history=useHistory();
  const filterAdmin= contextIsAdmin;
  const filterSuperAdmin= contextIsSuperAdmin;
  const filterStaff= contextIsStaff;
  
  const {
    setValue: setModalValue,
    value: ModalValue,
    setTitle: setTitle,
    fullBind: fullBind,
  } = useModal(false);

  useEffect(() => {
    getListOfLocations()
    clearFilter()
    // values.offset = 0;
    // values.limitperpage = PAGE_SIZE_RENDER;
    // setValues({ ...values })
  }, [])
 


  const loadData = () => {
    const obj_travel = {
      date_from: values.date_from,
      date_to: values.date_to,
      client_id: values.client_id,
      employee_id: values.employee_id,
      visit_no: values.visit_no,
      runroutes: values.runroutes,
      branch: values.branch,
    };
    // maintainPageNoOnFilter()
    const pageToSearch = getPageToSearchParam(location.search);
    loadEvvs({ ...branchData, ...obj_travel, ...pageToSearch }, EVV + EVV_TRAVEL, values.limitperpage);

  }
  useEffect(() => {
    if( values.ordering && values.date_from && values.date_to ){
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [

    values.limitperpage,
    location.search,
    values.ordering,
  ]);
  
  
  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };
  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =  offset * values.limitperpage; return {
      offset,
    };
  };




  const displayData = () => { 
    
    // getEvvTravel({...branchData, ...values}).then(response => {
    //   if (!mountedRef.current) return null;
      setNumTravels(counts.travel_count > 0 ? Math.ceil(counts.travel_count) : 0) ;
      setSumTravelDuration(counts.sum_of_travel_durations > 0 ? (counts.sum_of_travel_durations).toPrecision(): 0) ;
      setSumTravelDistance(counts.travel_distance > 0 ? (counts.travel_distance).toPrecision(): 0) ;
      setNumClients(counts.no_of_client > 0 ? Math.ceil(counts.no_of_client): 0) ;
      setNumEmployees(counts.no_of_employee ? Math.ceil(counts.no_of_employee): 0) ;
      setNumOfLocations(counts.no_of_locations > 0 ? Math.ceil(counts.no_of_locations) : 0)
    // });
  };
useEffect(() => {
  displayData()
},[ location.search,values.ordering,counts])

  const setLocationfilterFn = item => {
    setLocationFilter([...item]);
  };
  const handleDownload = () => {
    let fileName = "Evv_" + values.date_from + "_" + values.date_to
    setDownloadDisable(true)
    getEvvExport(values.date_from, values.date_to, values).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        setDownloadDisable(false)
      })
    }
    )
      .catch(error=>{
        setDownloadDisable(false)
      })
  }

  const dropDownHandleSelectPagination=(item,namesection)=>{
    const urlParams = queryString.parse(location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/evv/travel",
      search: stringified,
    });
    values.limitperpage=item[namesection]
    setValues({...values});
  }
  const handleSelectChange = (valcheck, visit, selectedIndex) => {

    setCheckedIdArr(visit.id);
    let checked = [...checkedRows];
    if (valcheck.target.checked) {
      checked.push(visit);
    } else {
      checked.map((item, index) => {
        if (item.id === visit.id) {
          checked.splice(index, 1);
        }
      });
    }
    setCheckedRows(checked);
    setCheckedId(() => checked.map(item => item.absence_ref));
    setBulkId(()=>checked.map((item)=>item.id))
    //setClients(arr);
  };

  const handleCheck = visit => {
    let check = false;
    if (checkedRows) {
      checkedRows.map(item => {
        if (visit.id === item.id) {
          check = true;
        }
      });
    }
    return check;
  };
  const handleAllSelectChange = valcheck => {
    let checked = [...checkedRows];
    if (valcheck.target.checked) {
      events.map(visit => {
        checked.push(visit);
      });
    } else {
      events.map(visit => {
        checked.map((item, index) => {
          if (item.id === visit.id) {
            checked.splice(index, 1);
          }
        });
      });
    }
    setCheckedRows(checked);
    setBulkId(()=>checked.map((item)=>item.id))
    setCheckedId(() => checked.map(item => item.absence_ref));
  };
  const handleModals = () => {
    setModalValue(!ModalValue);
  };
  const handleSearch=()=>{
    const urlSearchParams = new URLSearchParams(history.location.search);
    urlSearchParams.set('page', 1);
    const newUrl = `${history.location.pathname}?${urlSearchParams.toString()}`;
    history.push(newUrl);
    displayData();
    loadData()
  }

  const setProvisionFilter = item => {
    setVisitProvisionFilter([...item]);
  };
  const setConfirmationFilter = item => {
    setVisitConfirmationFilter([...item]);
  };
  const setDistanceFilter = item => {
    setTimeDistanceFilter([...item]);
  };

  const getListOfLocations = async () => {
    try {
      const res = await getLocationList()
      if (res?.status) {
        const data = res?.message?.map(item => ({ ...item, name: item.full_name }))
        setLocations(data || [])
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleBulkDistConfirmation = () => {
    handleModals();
    SetEvvDistance(true);
    setEvvTravel(false)
  }
  const handleBulkTravelConfirmation = () => {
    handleModals();
    SetEvvDistance(false)
    setEvvTravel(true);
  }

  const {
    displayMenu, selectedElId, clearSelectedElId, encloseModalBind
  } = useContextMenu()
  const evvTravelMenu = [
    {
      name: "Distance Confirmation",
      onClick: handleBulkDistConfirmation
    },
    {
      name: "Travel Confirmation",
      onClick: handleBulkTravelConfirmation
    }
  ]

  return (
    <div>
      <ContextMenu menuItems={evvTravelMenu} />
      <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
          <FiltersContainer>
            <div
              className={'ms-1 me-3 me-md-0 flex-wrap d-flex justify-content-center'}>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ms-1 me-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('date_from')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ms-1 me-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date to"
                    {...useInput('date_to')}
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>
              <DropdownTypesContainer>
                <NoSelectAllDropdownFilter
                  items={clients?clients:[]}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ client_id: items });
                  }}
                  itemsSelected={clientsFilter}
                  setSelectedFilter={setClientfilter}
                  placeHolder={'Client name'}
                  isObject
                  allOption

                />
              </DropdownTypesContainer>
              <DropdownTypesContainer>
                <NoSelectAllDropdownFilter
                  items={locations ? locations : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ location: items });
                  }}
                  itemsSelected={locationFilter}
                  setSelectedFilter={setLocationfilterFn}
                  placeHolder={'Location name'}
                  isObject
                  allOption

                />
              </DropdownTypesContainer>
              <DropdownTypesContainer>
                <NoSelectAllDropdownFilter
                  items={employees ? employees : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ employee_id: items });
                  }}
                  itemsSelected={employeesFilter}
                  setSelectedFilter={setEmloyeefilter}
                  placeHolder={'Employee name'}
                  isObject
                  allOption

                />
              </DropdownTypesContainer>
              {/* added filters in runroute + branch  */}
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ms-1 me-2">
                  <NoSelectAllDropdownFilter
                    items={branch ?branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ branch: items });
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={runRouteData?runRouteData:[]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client__clients_run_routes__run_routes__id__in: items });
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Client Group Area'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={runRouteData?runRouteData:[]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee__employee_run_routes__run_routes__id__in: items });
                    }}
                    itemsSelected={employeeRunRoutesFilter}
                    setSelectedFilter={setEmployeeRunRoutefilter}
                    placeHolder={'Employee Group Area'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={[
                      {id: 'Under Provision', name: 'Under Provision'},
                      {id: 'Over Provision', name: 'Over Provision'},
                      {id: 'On Time Provision', name: 'On Time Provision'},
                      {id:'Cancelled',name:'Cancelled'}
                    ]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        clocked_visit_provision__in: items,
                      });
                    }}
                    itemsSelected={visitProvisionFilter}
                    setSelectedFilter={setProvisionFilter}
                    placeHolder={'Clocked Visit Provision'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={[
                      {id: 'CONFIRMED', name: 'CONFIRMED'},
                      {id: 'AUTO CONFIRMED', name: 'AUTO CONFIRMED'},
                      {id: 'NOT CONFIRMED', name: 'NOT CONFIRMED'},
                      {id:'Cancelled',name:'Cancelled'}

                    ]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        evv_confirmation_status__in: items,
                      });
                    }}
                    itemsSelected={visitConfirmationFilter}
                    setSelectedFilter={setConfirmationFilter}
                    placeHolder={'EVV Visit Confirmation'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={[
                      {id: 'V V V V', name: 'V V V V'},
                      {id: 'V V V NV', name: 'V V V NV'},
                      {id: 'V V NV V', name: 'V V NV V'},
                      {id: 'V V NV NV', name: 'V V NV NV'},
                      {id: 'V NV V V', name: 'V NV V V'},
                      {id: 'V NV V V', name: 'V NV V V'},
                      {id: 'V NV NV V', name: 'V NV NV V'},
                      {id: 'V NV NV NV', name: 'V NV NV NV'},
                      {id: 'NV V V V', name: 'NV V V V'},
                      {id: 'NV V V NV', name: 'NV V V NV'},
                      {id: 'NV V NV V', name: 'NV V NV V'},
                      {id: 'NV V NV NV', name: 'NV V NV NV'},
                      {id: 'NV NV V V', name: 'NV NV V V'},
                      {id: 'NV NV V NV', name: 'NV NV V NV'},
                      {id: 'NV NV NV V', name: 'NV NV NV V'},
                      {id: 'NV NV NV NV', name: 'NV NV NV NV'},
                    ]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({timeDistance: items});
                    }}
                    itemsSelected={timeDistanceFilter}
                    setSelectedFilter={setDistanceFilter}
                    placeHolder={'Time Distance Confirmation'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <PrimaryButton type="button" onClick={handleSearch} style={{marginLeft:'4px'}} >
                  <span className={'ms-2 me-2 font-weight-bold ms-3'}>
                    Search
                  </span>
                </PrimaryButton>
              </FiltersLeftGroup>
            </div>
          </FiltersContainer>
        </div>
      </HeaderVisits>
      {/* travel dat a displayed below  */}
      {/* visit data dispalyed below  */}
      <div>
        <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-start" >
          <div className="d-flex align-items-center me-2">
          </div>
          <div className="data-container">
            <div className="me-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{' Schedule Travels: ' +  numTravels  }</p>
            </div>
          </div>

          <div className="d-flex align-items-center me-2 ps-2" >
          </div>
          <div className="data-container">
            <div className="me-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{'Schedule Travel (hrs) : ' +   sumTravelDuration   }</p>
            </div>
          </div>

          <div className="d-flex align-items-center me-2 ps-2">
          </div>
          <div className="data-container">
            <div className="me-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{'Schedule Distance: ' +   sumTravelDistance  }</p>
            </div>
          </div>


          <div className="d-flex align-items-center me-2 ps-2">
          </div>
          <div className="data-container">
            <div className="me-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{'Clients: ' +   numClients }</p>
            </div>
          </div>
          <div className="data-container me-2 ms-2">
            <div className="me-2">
              <p>{'Locations: ' + numOfLocations}</p>
            </div>
          </div>
          <div className="d-flex align-items-center me-2  ps-2">
          </div>
          <div className="data-container">
            <div className="me-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{'Employees: ' +   numEmployees }</p>
            </div>
          </div>
          <div className=" ms-auto ps-2 me-4">

            {checkedRows && checkedRows.length > 0 && (
              <div >
                <PrimaryButtonForm
                  minWidth="6rem"
                  class="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                // aria-expanded="false"
                >
                  <span className={'me-2'}>Action</span>
                </PrimaryButtonForm>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                  <a
                    class="dropdown-item"
                    onClick={handleBulkDistConfirmation}
                  >
                    Bulk Distance Confirmation
                  </a>
                  <a
                    class="dropdown-item"
                    onClick={handleBulkTravelConfirmation}
                  >
                    Bulk Travel Confirmation
                  </a>
                </div>
              </div>
            )}
          </div>
          <PublishButton   disable={downloadDisable}  onClick={!downloadDisable && handleDownload}>
            {DOWNLOAD}
          </PublishButton>
        </DataContainer>

      </div>
      <div className={'mt-5'}>
        <>    <ComponentDynamic loading={loadingModal} >
          {events.length > 0 && (


            <div style={{overflowX: 'scroll', width:'100%'}}>
              <div style={{width:'max-content'}}>
                <Table
                  headerColumns={columns_values}
                  headerPadding={false}
                  noEditOption
                  fontSize="0.75rem"
                  callBackQuerySort={setQuerySort}
                  handleAllSelectChange={handleAllSelectChange}

                >
                  <div>
                    {events && events.map((evvTravel,index)=>{
                      return (
                        <div key={index} onContextMenu={(e)=>{
                          e.preventDefault();
                          displayMenu(e, evvTravel.id, {
                            x:-250, y:0
                          })
                        }}>
                          <Row className="mb-1" bgColor>
                            <ColsGrouper
                              className="d-flex"
                            // onClick={()=>eventClickHandler(evvTravel)}
                            >
                              <Col
                                max_width={EX_SMALL_COL4}
                                Shrink={false}
                                NoFlexGrow={true}
                                reducePadding
                                Capitalize
                                Center>
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={handleCheck(evvTravel)}
                                  id="flexCheckDefault"
                                  onChange={val => {
                                    handleSelectChange(val, evvTravel, index);
                                  }}
                                />
                              </Col>
                              <Col className="sm-hidden" max_width={LARGE_COL} Left   overlap={true}>
                                {evvTravel.visit_obj && evvTravel.visit_obj.visit_number}
                              </Col>

                              <Col
                                className="sm-hidden"
                                max_width={LARGE_COL}
                                Left overlap={true}>
                                {evvTravel.client_obj && evvTravel.client_obj.full_name}
                              </Col>


                              <Col
                                className="sm-hidden"
                                max_width={LARGE_COL}
                                Left overlap={true}>
                                {evvTravel?.location_obj && evvTravel?.location_obj?.full_name ? evvTravel?.location_obj?.full_name :""}
                              </Col>

                              <Col
                                className="sm-hidden"
                                max_width={LARGE_COL}
                                Left overlap={true}>
                                {evvTravel.employee_obj && evvTravel.employee_obj.full_name}
                              </Col>

                              <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                                {evvTravel.schedule_start_date}
                              </Col>
                              <Col className="sm-hidden" max_width={LARGE_COL} Center    overlap={true}>
                                {evvTravel.schedule_end_date}
                              </Col>
                              <Col className="sm-hidden" max_width={LARGE_COL} Left   overlap={true}>
                                {evvTravel.travel_code}
                              </Col>
                              <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                                {evvTravel.travel_mode}
                              </Col>
                              <Col className="sm-hidden" max_width={LARGE_COL} Left   overlap={true}>
                                {evvTravel.journey_start_zipcode}
                              </Col>
                              <Col className="sm-hidden" max_width={LARGE_COL} Left   overlap={true}>
                                {evvTravel.journey_end_zipcode}
                              </Col>
                              <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                                {evvTravel.schedule_travel_miles}
                              </Col>
                              <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                                {evvTravel.schedule_travel_time
                                  ?evvTravel.schedule_travel_time.substring(0, 5)
                                  :''
                                }
                              </Col>
                              <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                                {evvTravel.start_travel_time
                                  ?evvTravel.start_travel_time.substring(0, 5)
                                  :''
                                }
                              </Col>
                              <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                                {evvTravel.end_travel_time
                                  ?evvTravel.end_travel_time.substring(0, 5)
                                  :''
                                }
                              </Col>
                              <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                                {evvTravel.actual_travel_miles}
                              </Col>
                              <Col className="sm-hidden" max_width={LARGE_COL} Center  overlap={true}>
                                {evvTravel.actual_time
                                  ?evvTravel.actual_time.substring(0, 5)
                                  :''
                                }
                              </Col>


                              <Col className="sm-hidden" max_width={SMALL_COL} Center   overlap={true}>
                                {evvTravel.clocked_distance_confirmation===true?'Confirmed':'Not Confirmed'}
                              </Col>
                              <Col className="sm-hidden" max_width={SMALL_COL} Center   overlap={true}>

                                <div style={{marginRight:'20px'}}>
                                  {evvTravel.clocked_travel_confirmation===true?'Confirmed':'Not Confirmed'}
                                </div>
                              </Col>
                              <Col
                                className="sm-hidden"
                                max_width={EX_SMALL_COL}
                                Center
                                overlap={true}>
                                {/* clock duration */}
                                <MdRemoveRedEye
                                  onMouseEnter={'view'}
                                  size={'23'}
                                  color={PURPLE}
                                  onClick={() =>
                                    eventClickHandler(evvTravel)

                                  }
                                />
                              </Col>
                            </ColsGrouper>
                          </Row>
                        </div>
                      )
                    })}
                    <div className="mt-3">
                      <Pagination
                        totalPages={pages}
                        currentPage={currentPage}
                        values={values}
                        dropDownHandleSelect={dropDownHandleSelectPagination}
                        number={PAGE_SIZE_RENDER}
                        dataLength={events.length}
                      />
                    </div>
                  </div>
                  {!ModalValue ? null : (
                    <EvvBulkTravelModal
                      setCheckedRows={setCheckedRows}
                      loadData={loadData}
                      loadEvvs={loadEvvs}
                      evvDistance={evvDistance}
                      EvvTravel={evvTravel}
                      bulkId={selectedElId?[selectedElId]:bulkId}
                      setTitle={setTitle}
                      fullBind={()=>encloseModalBind(fullBind)}
                      setModalValue={setModalValue}
                      SetEvvDistance={SetEvvDistance}
                      setEvvTravel={setEvvTravel}
                      clearSelectedElId={clearSelectedElId}
                    />
                  )}
                </Table>
              </div>
            </div>
          )}
        </ComponentDynamic></>
      </div>
    </div>
  );
};

EvvTravel.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  eventClickHandler: PropTypes.func.isRequired,
  loadEvvs: PropTypes.func.isRequired,
  loadingCalendar: PropTypes.bool.isRequired,
};

export default EvvTravel;
