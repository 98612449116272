import React,{useState,useEffect,useMemo,useContext} from 'react';
import {LARGE_COL,SMALL_COL} from 'shared/styles/constants/columns';
import {PrimaryButton, PrimaryButtonForm} from 'shared/styles/buttons';
import DateSelector from 'components/SharedComponents/DateSelector';
import {useForm} from 'hooks/FormHook';
import queryString from 'query-string';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import {getFinPayroll,payrollExport} from 'utils/api/finance'
import NoSelectAllDropdownFilter from  'components/SharedComponents/NoSelectAllDropdownFilter';  
import Pagination from 'components/SharedComponents/Pagination';
import {AppContext, BranchItemsContext} from "context"
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {
    HeaderVisits,
    DropdownTypesContainer,
    FiltersLeftGroup,
    FiltersContainer,
  } from 'shared/styles/constants/tagsStyles';
  import Table from 'components/SharedComponents/Table';
  import {
    Row,
    Col,
  } from 'components/SharedComponents/Table/styles';
import moment from 'moment';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { billing_payroll_choices } from 'utils/choiceConstant';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const FinPayroll = (props) => {
    const [payrollData,setPayRollData]=useState([])
    const {values, setValues,useInput,errors,isValid, setCheckRequires} = useForm({});
    const [pages, setPages] = useState(0);
    const [employeeStatusFilter,setEmployeeStatusFilter]=useState([])
    const [headers,setHeaders]=useState([])
    const [visitStatusFilter,setVisitStatusFilter]=useState([])
    const [serviceCategoryFilter,setServiceCategoryFilter]=useState([])
    const {contextChoices:choices, handleGetChoices}=useContext(AppContext);
    const [loadingModal, setLoadingModal] = useState(false);
    const [loadingModalExport, setLoadingModalExport] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [branchFilter, setBranchFilter] = useState([]);

    useEffect(()=>{
        values.limitperpage =PAGE_SIZE_RENDER;
        setValues({...values})
        },[])
  
        const { branchData } = useContext(BranchItemsContext);
        const history = useHistory();
      
        useEffect(() => {
          if (values.date_from && values.date_to) {
            handleClick()
          }
        }, [branchData?.headerbranch?.length,
          values.limitperpage,
          props.location.search
        ]);
      
        useEffect(()=>{
          const urlParams = queryString.parse(props.location.search);
            urlParams[PAGINATION_PARAM] = 1;
            const stringified = queryString.stringify(urlParams);
            history.push({
              pathname: '/finance/payroll/fin-payroll',
              search: stringified,
            });
        },[])

  useEffect(() => {
    handleGetChoices(billing_payroll_choices)
    
  }, [])

//   useEffect(()=>{
//       if(payrollData)
//     makeHeaderColumns()
//   },[payrollData])

const makeHeaderColumns=useMemo(()=>{
 const contantHeader=[   {
        label: 'Employee Name',
        name: 'client_name',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
          label: 'Pay Rate Card',
          name: 'Pay_rate_card',
          status: 0,
          className: 'grow-mobile',
          max_width: LARGE_COL,
        }]
        let dateDiff=moment(values.date_to).diff(moment(values.date_from),'days')+ 1;
        let tempArr=[]
        for(let i=0;i<dateDiff;i++){
            let column_name=moment(values.date_from).add(i,'days').format('YYYY-MM-DD')
            tempArr.push({
                label: column_name,
                name: 'date_colmn',
                status: 0,
                className: 'grow-mobile',
                max_width: LARGE_COL, 
            })
        }
        let final_header=[...contantHeader,...tempArr,{
            label: 'Grand Total',
            name: 'grand_total',
            status: 0,
            className: 'grow-mobile',
            max_width: LARGE_COL,
        }]
       return final_header
},[payrollData])

const getDynamicColumnsData=(det,index)=>{
    let dateDiff= moment(values.date_to).diff(moment(values.date_from),'days')+1;
        let tempArr=[]
        for(let i=0;i<dateDiff;i++){
            let column_name=moment(values.date_from).add(i,'days').format('YYYY-MM-DD')
            tempArr.push(
                <Col
                max_width={LARGE_COL}
                >
                {payrollData.length>0 && payrollData[index][`Start Date ${column_name}`]?
                payrollData[index][`Start Date ${column_name}`]:''
            }
                </Col>
            )
        }
        return tempArr
}

  const dropDownHandleSelectPagination=(item,namesection)=>{
    values.limitperpage=item[namesection]
    setValues({...values});
  }
  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =  offset * values.limitperpage; return {
      offset,
    };
  };
  const handleClick=()=>{
    const pageToSearch = getPageToSearchParam(props.location.search);
    if(!values.date_from || !values.date_to){
        return
    }
    else{
      setLoadingModal(true)
        getFinPayroll({...values,...pageToSearch,...branchData})
        .then(res=>{
          if(res){
            setLoadingModal(false);
          }
            setPayRollData(res.results)
            setPages(Math.ceil(res.count /   values.limitperpage));
        })
    }
}
    const setQuerySort = querySort => {
        setValues({...values, ...querySort});
      };

      const handleExport=()=>{
        const pageToSearch = getPageToSearchParam(props.location.search);
        setLoadingModalExport(true)
        payrollExport({...values,...pageToSearch}).then(response=>{
          setLoadingModalExport(false)
          response.blob().then(blob=>{
            let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `payRoll_${values.date_from}_${values.date_to}`;
          a.click();
          })
          }
    )
   
      }
      const dropDownHandleSelect=(item)=>{
        setValues({...values,...item})
      }
      
    return (
        <div>
            <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
        <FiltersContainer>
        <div
          className={'ms-1 me-3 me-md-0 flex-wrap d-flex justify-content-center'}>
               <FiltersLeftGroup
            className={'d-flex justify-content-center mb-2'}>
            <DropdownTypesContainer className="ms-1 me-1 mb-2">
              <DateSelector
                    isRequired
                typeFilter={true}
                labelTop="Date from"
                {...useInput('date_from')}
              />
            </DropdownTypesContainer>
            <DropdownTypesContainer className="ms-1 me-1 mb-2">
              <DateSelector
                 isRequired
                disabled={values.date_from?false:true}
                typeFilter={true}
                labelTop="Date to"
                minDate={values.date_from}
                maxDate={moment(values.date_from).add(7,"days").format("YYYY-MM-DD")}
                {...useInput('date_to')}
              />
            </DropdownTypesContainer>
            <DropdownTypesContainer className="ms-1 me-1 mb-2">
                <NoSelectAllDropdownFilter
                    items={choices.employee_status? choices.employee_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employee_status: items});
                    }}
                    itemsSelected={employeeStatusFilter}
                    setSelectedFilter={(items)=>setEmployeeStatusFilter([...items])}
                    placeHolder={'Employee Status'}
                    isObject
                    allOption
            
                  />
                  </DropdownTypesContainer>
                  <DropdownTypesContainer className="ms-1 me-1 mb-2">
                <NoSelectAllDropdownFilter
                    items={choices.visit_status? choices.visit_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({visit_status: items});
                    }}
                    itemsSelected={visitStatusFilter}
                    setSelectedFilter={(items)=>setVisitStatusFilter([...items])}
                    placeHolder={'Visit Status'}
                    isObject
                    allOption
            
                  />
                  </DropdownTypesContainer>
                  <DropdownTypesContainer className="ms-1 me-1 mb-2">
                  <NoSelectAllDropdownFilter
                      items={choices.branch || []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ employee__job_detail__branch__id: items });
                      }}
                      itemsSelected={branchFilter}
                      setSelectedFilter={items =>
                        setBranchFilter([...items])
                      }
                      placeHolder={'Branch'}
                      isObject
                      allOption
                    />
                    </DropdownTypesContainer>
                  <DropdownTypesContainer className="ms-1 me-1 mb-2">
                <NoSelectAllDropdownFilter
                    items={choices.service_category? choices.service_category : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({visit__service_category__id__in: items});
                    }}
                    itemsSelected={serviceCategoryFilter}
                    setSelectedFilter={(items)=>setServiceCategoryFilter([...items])}
                    placeHolder={'Service Category'}
                    isObject
                    allOption
            
                  />
                  </DropdownTypesContainer>
                  <PrimaryButtonForm className="ms-1 me-1 mb-2" type="button" onClick={handleClick} disabled={loadingModalExport || loadingModal}>
                    <div className='d-flex'>
                      <span className={'ms-2 me-2 font-weight-bold ms-3'}>Search</span>
                      {/* {!loadingModal ? (
                            null
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                            />
                          )} */}
                    </div>
                  </PrimaryButtonForm>
                  {payrollData.length > 0 &&
                    <PrimaryButtonForm type="button"
                    className="ms-1 me-1 mb-2"
                      onClick={handleExport} disabled={loadingModalExport || loadingModal}>
                      <span className={'ms-2 me-2 font-weight-bold'}>Export</span>
                      {!loadingModalExport ? (
                        null
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
}
          </FiltersLeftGroup>
              </div>
            </FiltersContainer>
        </div>
        </HeaderVisits>
        {
            payrollData.length>0 &&
            <>
              <ComponentDynamic loading={loadingModal} >
            <Table
      headerColumns={makeHeaderColumns}
      headerPadding={true}
      noEditOption={true}
                  noNeed={false}
      fontSize="0.75rem"
      callBackQuerySort={setQuerySort}>
        {payrollData.map((det,index)=>{
            //  let dateDiff=moment(values.date_to).diff(moment(values.date_from),'days')
            //  let tempArr=[]
            //  for(let i=0;i<dateDiff;i++){
            //      tempArr.push({})
            //  }
            return(
                <Row bgColor Shadow>
                  <Col
                  max_width={LARGE_COL}
                  >
                  {det.employee__full_name  }
                </Col>
                <Col
                  max_width={LARGE_COL}
                 
                  >
                   {det.visit__visit_pay_rate_card__name }
                </Col>
                {getDynamicColumnsData(det,index)}
                {/* {
                tempArr.map(ele=>{
                    return (
                        
                    )
                })
                } */}
                <Col
                  max_width={LARGE_COL}
                 
                  >
                   {det.Total }
                </Col>
               
                    </Row>
            )
        })}
        <div className="mt-3">
          <Pagination totalPages={pages} currentPage={currentPage}   values={values}   dropDownHandleSelect={dropDownHandleSelectPagination}     number={PAGE_SIZE_RENDER}    dataLength={payrollData.length} />
          </div>
      </Table>
      </ComponentDynamic>
            </>
        }
        </div>
    );
}

export default FinPayroll;
