import React, { useState, useEffect, useRef, useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import {
  USER_NAME,
  USER_FIRST_NAME,
  USER_LAST_NAME,
  USER_EMAIL,
  ORGNAME,
} from 'utils/constants/localStorage';
import {
  LARGE_COL,
  MEDIUM_COL,
  SMALL_COL,
} from 'shared/styles/constants/columns';
import {
  EmploymentTypesContainer,
  Header,
  SearchAddContainer,
  SearchContainer,
  SearchIcon,
  Title,
} from '../../Team/styles';
import {
  Col,
  ColsGrouper,
  IconWrapper,
  Row,
  UserImg,
  UserImgContainer,
} from 'components/SharedComponents/Table/styles';
import { getSubscription } from '../../../../utils/api/UsersApi';
import { GrSearch } from 'react-icons/gr';
import Fade from 'react-reveal/Fade';
import { FloatingMobileButton } from 'shared/styles/buttons';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { FaPlus, FaToggleOn } from 'react-icons/fa';
import Table from 'components/SharedComponents/Table';
import { useForm } from 'hooks/FormHook';
import Pagination from 'components/SharedComponents/Pagination';
import queryString from 'query-string';
import { useModal } from 'hooks/ModalHook';
import AddModal from 'components/DashboardComponents/Settings/SettingOrganization/AddModal';
import AddModalSubscription from 'components/DashboardComponents/Settings/SettingOrganization/AddModalSubscription';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { getOrganizations, switchOrganization, statusOrganization, postApiSwitchUserId, getSubscriptionById } from 'utils/api/SettingsApi';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { setToken, setTenant, setOrgname, setSchemaName } from 'utils/localStorage/token';
import { DASHBOARDS } from 'utils/constants/routes';
import { stubTrue } from 'lodash';
import { LoginContent } from 'components/AuthComponents/Login/styles';
import Navbar from 'components/DashboardComponents/Navbar';
import { SETTINGS, SETTINGS_ORGANIZATIONS } from 'utils/constants/routes';
import { AppContext } from "context"
import { setting_organization_choices } from 'utils/choiceConstant';

const SettingOrganization = (props) => {
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);

  const {
    setValue: setModalValueSubscription,
    value: modalValueSubscription,
    setTitle: setTitleSubscription,
    fullBind: fullBindSubscription,
  } = useModal(false);
  // const history = useHistory();
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);
  const [showSearch, setShowSearch] = useState(false);
  const { values, setValues, useInput } = useForm({});
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [pages, setPages] = useState(0);
  const [dateMessage, setDateMessage] = useState('Is required');
  const [dateError, setDateError] = useState(false);
  const { contextChoices, handleGetChoices } = useContext(AppContext);
  const [subscriptionData, setSubscriptionData] = useState([]);

  useEffect(() => {
    handleGetChoices(setting_organization_choices)
  }, [])

  const [currentPage, setCurrentPage] = useState(1);
  const mountedRef = useRef(true);
  const history = useHistory();
  const headerColumns = [
    {
      label: 'Name',
      name: 'Org_name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Address',
      name: 'Org_address',
      status: 0,
      className: 'sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Status',
      name: 'status_name',
      status: 0,
      className: 'sm-hidden',
      max_width: SMALL_COL,
    },
    {
      label: 'Switch Account',
      name: 'switch_account',
      status: 0,
      className: 'sm-hidden',
      max_width: SMALL_COL,
    },

  ];

  localStorage.setItem('switch_clicked', false);
  const switch_user_id = localStorage.getItem('user_id');


  useEffect(() => {
    getSubData()
    return () => {
      mountedRef.current = false;
    };

  }, []);


  useEffect(() => {
    let limitperpage = {};
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [PAGE_SIZE_RENDER])


  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.location.search,
    values.limitperpage,
  ]);

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const handleAddOrganization = () => {
    setTitle('Add Organization');
    handleModal();
  };

  const handleAddSubscription = (id) => {
    setTitleSubscription('Add Subscription Plan')
    setSelectedOrg(id);
    handleModalSubscription()

  };

  const getSubData = () => {
    getSubscription(values)
      .then((res) => {
        setSubscriptionData(res.results);
      })
      .catch((err) => { })
  }

  const handleModalSubscription = () => {
    setModalValueSubscription(!modalValueSubscription);
  }

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;

    setCurrentPage(offset + 1);

    offset = values.limitperpage === 100 ? 0 : offset * values.limitperpage;
    return {
      offset,
    };
  };

  const createAddressFormat = address => {
    const name = address.name ? `${address.name}, ` : '';
    const line_1 = address.line_1 ? `${address.line_1}, ` : '';
    const city = address.city ? `${address.city}, ` : '';
    const zip_code = address.zip_code ? `${address.zip_code}` : '';

    return `${name}${line_1}${city}${zip_code}`;
  };

  const search = () => {
    const pageToSearch = getPageToSearchParam(props.location.search);
    getOrganizations({ ...values, ...pageToSearch }, values.limitperpage).then(
      response => {
        if (!mountedRef.current) return null;
        setOrganizations(response.results || []);
        setPages(Math.ceil(response.count / values.limitperpage));
      }
    );
  };

  const [idSelected, setIdSelected] = useState(' ');

  const handleRowClick = (id, is_active) => {

    let temp = [...organizations]
    temp.find(v => v.id === id).is_active = is_active ? false : true
    setOrganizations([...temp]);
    statusOrganization(id, is_active)
  }

  const handleAccount = async (id, user_id, schema_name, name, endpoint, email, first_name, last_name, user_name) => {
    let temp = [...organizations]
    if (temp.find(v => v.id === id)) {
      localStorage.setItem('tenant', endpoint);
      localStorage.setItem('user_id', user_id);
      localStorage.setItem('schema_name', schema_name);
      localStorage.setItem('name', name);
      localStorage.setItem('user_email', email);
      localStorage.setItem('user_first_name', first_name);
      localStorage.setItem('user_last_name', last_name);
      localStorage.setItem('user_name', user_name);
      setOrganizations([...temp]);

      switchOrganization(user_id);
      await postApiSwitchUserId(id, user_id)
      // ssuperuser_id  replaced by  user_details>> user_id-
      localStorage.setItem('switch_clicked', true);
      // window.location.reload();
      history.push(DASHBOARDS);
      window.location.reload();

    }
  }


  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }




  return (

    <div>
      <Title>List of organizations</Title>
      <Header>
        <div style={{ marginLeft: "auto" }}>
          <SearchAddContainer className="d-flex align-items-center">
            <SearchIcon
              onClick={() => {
                setShowSearch(true);
              }}>
              <GrSearch />
            </SearchIcon>

            <SearchContainer show={showSearch}>
              <Fade collapse when={showSearch}>
                <InputLabelTop
                  no_internal_margin="true"
                  type="text"
                  id="search"
                  label={'Search'}
                // {...useInput('search')}
                // onBlur={() => {
                //   search();
                // }}
                // onKeyPress={e => {
                //   keyPressed(e);
                // }}
                />
              </Fade>
            </SearchContainer>

            <FloatingMobileButton onClick={handleAddOrganization}>
              <FaPlus />
              <label>ADD NEW ORGANIZATION</label>
            </FloatingMobileButton>

          </SearchAddContainer>
        </div>
      </Header>
      <Table headerColumns={headerColumns} callBackQuerySort={setQuerySort}>
        <div>
          {organizations && organizations.map((organization, index) => {
            return (
              <div key={index}>
                <Row bgColor>
                  <ColsGrouper
                    className="d-flex"
                  // onClick={() => {
                  //   return handleRowClick(organization.id) ;
                  // }}
                  >

                    <Col max_width={LARGE_COL}>
                      <label>{organization.name}</label>
                    </Col>

                    <Col className="sm-hidden" max_width={LARGE_COL}>
                      {organization.address
                        ? createAddressFormat(organization.address)
                        : ''}
                    </Col>


                    <Col
                      className="sm-hidden"
                      max_width={SMALL_COL}
                      Capitalize
                      Center
                      Color={"red"}
                    >

                      {organization.is_active ? 'Active ' : 'Inactive'}

                      <div className="ms-3">
                        <ToggleButton
                          onClick={() => handleRowClick(organization.id, organization.is_active)}
                          status={organization.is_active}
                        //  id={}
                        />
                      </div>

                    </Col>

                    <Col
                      className="sm-hidden"
                      max_width={SMALL_COL}
                      Capitalize
                      Center
                      Color={"red"}
                    >
                      <div className=" mt-1">
                        <button type="button" className="btn text-primary text-bold  h6 border border-primary  rounded  btn-sm  " onClick={() => {
                          handleAccount(
                            organization.id, organization.user_details.user_id, organization.schema_name, organization.name, organization.endpoint, organization.user_details.email, organization.user_details.first_name, organization.user_details.last_name, organization.user_details.user_name,
                          );
                        }}
                        >switch </button>

                      </div>

                    </Col>
                    <Col
                      className="sm-hidden"
                      max_width={SMALL_COL}
                      Capitalize
                      Center
                      Color={"red"}
                    >
                      <div className=" mt-1">
                        <button type="button" className="btn text-primary text-bold  h6 border border-primary  rounded  btn-sm  " onClick={() => { handleAddSubscription(organization.id) }}
                        >Action </button>

                      </div>

                    </Col>
                  </ColsGrouper>
                </Row>
                <div className="mb-1" />
              </div>
            );
          })}
          <div className="mt-3">
            <Pagination totalPages={pages} currentPage={currentPage}
              values={values}
              dropDownHandleSelect={dropDownHandleSelectPagination}
              number={PAGE_SIZE_RENDER}
              dataLength={organizations.length}
            />
          </div>
        </div>
      </Table>
      {modalValue ? (
        <AddModal
          // Id={idSelected}
          setModalValue={handleModal}
          successCallback={search}
          fullBind={fullBind}
          dateMessage={dateMessage}
          dateError={dateError}
          contextChoices={contextChoices}
        />
      ) : (
        <></>
      )}
      {modalValueSubscription ? (
        <AddModalSubscription
          // Id={idSelected}
          setModalValue={handleModalSubscription}
          successCallback={search}
          fullBind={fullBindSubscription}
          dateMessage={dateMessage}
          dateError={dateError}
          contextChoices={contextChoices}
          selectedOrg={selectedOrg}
          subscriptionData={subscriptionData}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default SettingOrganization;
