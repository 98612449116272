import React from 'react'
import { Header, SearchAddContainer } from './styles'
import { DropdownTypesContainer } from 'shared/styles/constants/tagsStyles'
import DateSelector from 'components/SharedComponents/DateSelector'
import { FloatingMobileButton } from 'shared/styles/buttons'
import { useForm } from 'hooks/FormHook'
import { useContext } from 'react'
import { AppContext, BranchItemsContext } from 'context'
import { exportServiceContract } from 'utils/api/ClientApi'
import moment from 'moment'
import { useEffect } from 'react'
import { reports_service_contract } from 'utils/choiceConstant'
import { useState } from 'react'
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter'
import { Toast } from 'primereact/toast'
import { useRef } from 'react'

function ServiceContract({
    // props,
    // handleChangeChoices
}) {
    const toast = useRef(null);
    const { values, setValues, useInput } = useForm({});
    const { branchData } = useContext(BranchItemsContext);
    const { contextChoices: choices, handleGetChoices } = useContext(AppContext);


    const [branchFilter, setBranchFilter] = useState([])
    const [clientsFilter, setClientsFilter] = useState([])
    const [clientOptions, setClientOptions] = useState([])
    const [branchOptions, setBranchOptions] = useState([])
const [disableExport, setDisableExport] = useState(false)
    useEffect(() => {
        setClientOptions(choices && choices?.client_listing &&
            choices?.client_listing.map(x => ({ id: x.id, name: x.full_name }))
        )
        setBranchOptions(choices && choices?.branch && choices?.branch)
    }, [choices])

    useEffect(() => {
        handleGetChoices(reports_service_contract)
    }, [])

    const handleDownload = () => {
        setDisableExport(true)
debugger
        if (!values.start_date && values.end_date) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Please select start date first!`,
            });
            return;
        }

        
        if (values.start_date && values.end_date) {
            let startDate = new Date(values.start_date)
            let endDate = new Date(values.end_date)
            if (startDate > endDate) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: `Start date must be greater than end date!`,
                });
                return;
            }
        }
        let view_set = 'Client_Service_Contracts'
        let fileName = view_set + '-' + moment().format('YYYY-MM-DD');
        exportServiceContract({ ...branchData, ...values }, view_set)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    a.click();
                    setDisableExport(false)
                });
            })
            .catch(err => setDisableExport(false));
    };

    const setClientfilter = (item) => {
        setClientsFilter([...item])
    }
    const setFilterBranch = item => {
        setBranchFilter([...item]);
    };


    const dropDownHandleSelect = (value, nameSpace) => {
        values[nameSpace] = value[nameSpace];
        setValues({ ...values })
    }
    return (
        <div>
            <Toast ref={toast} position="top-right" />
            <Header>
                <div className="d-flex ">
                    <DropdownTypesContainer className="me-2">
                        {' '}
                        <DateSelector
                            typeFilter={true}
                            labelTop="Date from"
                            value={values.start_date ? values.start_date : ''}
                            {...useInput('start_date')}
                        />
                    </DropdownTypesContainer>
                    <DropdownTypesContainer className="me-2">
                        {' '}
                        <DateSelector
                            typeFilter={true}
                            labelTop="Date to"
                            value={values.end_date ? values.end_date : ''}
                            {...useInput('end_date')}
                        />
                    </DropdownTypesContainer>
                    <DropdownTypesContainer className="me-2">
                        <NoSelectAllDropdownFilter
                            items={clientOptions || []}
                            setItemsSelected={items => {
                                dropDownHandleSelect({ clients: items }, 'clients');
                            }}
                            itemsSelected={clientsFilter}
                            setSelectedFilter={setClientfilter}
                            placeHolder={'Client Name'}
                            isObject
                            allOption
                        //  hitApiAfterClear={hitApiAfterClear}
                        />
                    </DropdownTypesContainer>
                    <DropdownTypesContainer className="me-2">
                        <NoSelectAllDropdownFilter
                            items={branchOptions || []}
                            setItemsSelected={items => {
                                dropDownHandleSelect({ branchs: items }, 'branchs');
                            }}
                            itemsSelected={branchFilter}
                            setSelectedFilter={setFilterBranch}
                            placeHolder={'Branch'}
                            isObject
                            allOption
                        />
                    </DropdownTypesContainer>
                </div>
            </Header>
            <SearchAddContainer className="d-flex align-items-center">
                <FloatingMobileButton
                disabled={disableExport}
                    onClick={handleDownload}>
                    <label>Download List</label>
                </FloatingMobileButton>
            </SearchAddContainer>
        </div>
    )
}

export default ServiceContract