import React from 'react';
import {Title} from 'shared/styles/constants/tagsStyles';
import Template from 'components/SharedComponents/SettingsTemplate';
import {
    getTriageStatus,
    deleteTriageStatus,
    setTriageStatus,
    getTriageType,
    deleteTriageType,
    setTriageType,
    getTriageLabel,
    deleteTriageLabel,
    setTriageLabel,
    getTriageSeverity,
    deleteTriageSeverity,
    setTriageSeverity,
} from 'utils/api/SettingsApi';
import {SettingsGridContainer} from "components/SharedComponents/SettingsTemplate/styles"

const SettingsTriage = ({handleChangeChoices,props}) => {


  return (
    <div>
        <Title className={'ms-1 mb-2'}>Settings Triage</Title>
      <SettingsGridContainer>
          <Template
            titleSection={'Triage Status'}
            labelInputTitle={'Triage status name'}
            getMethod={getTriageStatus}
            deleteMethod={deleteTriageStatus}
            setMethod={setTriageStatus}
            handleChangeChoices={()=>handleChangeChoices('alert_status')}
            textToMessageDelete={'Triage status'}
            fieldAttributes={{maxLength: 50}}
          />
          <Template
            titleSection={'Triage Type '}
            labelInputTitle={'Triage type name'}
            getMethod={getTriageType}
            deleteMethod={deleteTriageType}
            setMethod={setTriageType}
            handleChangeChoices={()=>handleChangeChoices('alert_type')}
            textToMessageDelete={'Triage Type'}
            fieldAttributes={{maxLength: 50}}
          />
          <Template
            titleSection={'Triage Label'}
            labelInputTitle={'Triage label name'}
            getMethod={getTriageLabel}
            deleteMethod={deleteTriageLabel}
            setMethod={setTriageLabel}
            handleChangeChoices={()=>handleChangeChoices('alert_label')}
            textToMessageDelete={'Triage Label'}
            fieldAttributes={{maxLength: 50}}
            // cardBodyName={'Alert Label'}
          />
            <Template
                     titleSection={'Triage Severity'}
                     labelInputTitle={'Triage severity name'}
                     getMethod={getTriageSeverity}
                     deleteMethod={deleteTriageSeverity}
                     setMethod={setTriageSeverity}
                     handleChangeChoices={()=>handleChangeChoices('alert_severity')}
                     textToMessageDelete={'Triage Severity'}
                     fieldAttributes={{maxLength: 50}}
            />
            </SettingsGridContainer>
    </div>
  )
}

export default SettingsTriage