import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React, { useState, useEffect } from 'react';
import { ModalContainer } from 'shared/styles/constants/tagsStyles';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { IoIosArrowDroprightCircle } from 'react-icons/io';

import { setAvailability } from 'utils/api/RotaShift';
const SwapShiftOverlapModal = ({
    fullBind,
    shiftOverlapData,
    setOverlapData,
    splitData,
    loadingOverlap = [],
    loadingProceed = [],
    proccedData,
    disableButton = [],
    overlapMessage,
    currentModal = false, overlapDetails, employees,
    values, search, setAddEvent
}) => {
    const [addFullBind, setAddFullBind] = useState(fullBind);
    const [splitOverallData, setSplitOverallData] = useState([]);
    const [isSplit, setIsSplit] = useState(false)
    const [disableIndex, setDisableIndex] = useState()
    useEffect(() => {
        setAddFullBind({
            ...addFullBind,
            title: 'Overlap Availablities',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullBind]);




    const handleSubmit = (index) => {
        console.log(index, "LLLLLLLLL")
        setDisableIndex(index)
        let newData = {}

        overlapDetails.forEach((item) => {
            {

                newData = {
                    employeeitem: item.employee_id,
                    start_date: item.start_date,
                    end_date: item.end_date,
                    shift_type: item.shift_type
                }

            }


        }
        )
        if (isSplit) {

            let body = {
                is_split: true,
                force: true,
                is_available: true,
                employee: overlapDetails.map((item) => item.employee_id),
                start_date: newData.start_date,
                end_date: newData.end_date,
                shift_type: newData.shift_type


            }
            setAvailability(body)
            search()

        } else {
            let body = {
                is_split: false,
                force: true,
                employee: overlapDetails.map((item) => item.employee_id),
                start_date: newData.start_date,
                end_date: newData.end_date,
                shift_type: newData.shift_type

            }

            setAvailability(body)
            search()
        }

    }
    return (
        <ModalContainer max_width={'70%'}>

            <ModalDynamic
                {...addFullBind}
                max_width={'70%'}
                minWidthTitle="6.5rem"
                loading={false}>
                <div className={'pt-2 ps-3 pe-3 pb-3'}>
                    {/* <p style={{marginBottom: '5px'}}>{overlapMessage}</p> */}
                    {overlapDetails &&

                        overlapDetails.map((item, index) => {
                            return (
                                <>
                                    <div className="shift__warp">
                                        <div className='d-flex w-100'>
                                            <div className='d-flex'>
                                                <div style={{ marginRight: '3px' }}>
                                                    <IoIosArrowDroprightCircle />
                                                </div>

                                                <p
                                                    className="emp__det"
                                                >
                                                    {
                                                        employees &&
                                                        employees?.find(
                                                            employee => employee.id === item.employee_id
                                                        )?.name + ' ' +
                                                        `${item.start_date} - ${item.end_date}`


                                                    }
                                                </p>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "right", flex: 'auto' }}>
                                                <div style={{ marginTop: "-5px", marginLeft: "100px" }} className={'me-1'}>
                                                    <PrimaryButtonForm
                                                        minWidth="10rem"
                                                        disabled={disableIndex === index ? true : false}
                                                        onClick={() => {
                                                            handleSubmit(index); setIsSplit(true)

                                                        }}
                                                    >
                                                        <span className={'me-1 ps-0.5 pe-0.5'}>Split the existing shift</span>
                                                        {!loadingOverlap.find(ele => ele === index + 1) ? (
                                                            null
                                                        ) : (
                                                            <span
                                                                className="spinner-border spinner-border-sm me-1"
                                                                role="status"
                                                            />
                                                        )}
                                                    </PrimaryButtonForm>
                                                </div>
                                                <div style={{ marginTop: "-5px" }} className={'me-1'}>


                                                    <PrimaryButtonForm
                                                        minWidth="10rem"
                                                        disabled={disableIndex === index ? true : false}
                                                        onClick={() => {
                                                            handleSubmit(index); setIsSplit(false)

                                                        }}


                                                    >
                                                        <span className={'me-1 ps-0.5 pe-0.5'}>Fully override the existing shift</span>
                                                        {!loadingProceed.find(ele => ele === index + 1) ? (
                                                            null
                                                        ) : (
                                                            <span
                                                                className="spinner-border spinner-border-sm me-1"
                                                                role="status"
                                                            />
                                                        )}
                                                    </PrimaryButtonForm>
                                                </div>
                                            </div>
                                        </div>


                                        <div>
                                            {item &&
                                                item.show &&
                                                item.unassign_data &&
                                                item.unassign_data.map(val => {
                                                    return (
                                                        <p className="emp__det">
                                                            {val.client_service_visit__client__full_name +
                                                                `(${val.start_date} - ${val.end_date})`}
                                                        </p>

                                                    );
                                                })}

                                        </div>

                                        <div>

                                        </div>
                                    </div>
                                </>
                            );
                        })}
                </div>
            </ModalDynamic>
        </ModalContainer>
    );
};

export default SwapShiftOverlapModal;
