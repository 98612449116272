import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {DropdownButton, ItemsDropdownStyle, SelectClearButton} from '../NoSelectAllDropdownFilterCustom/styles';
import {FiChevronUp, FiChevronDown} from 'react-icons/fi';
import Checkbox from '../Checkbox';
import {
  SearchAddContainer,
  SearchContainer,
} from 'shared/styles/constants/tagsStyles';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { MultiSelectDropdownContainer } from '../MultiSelectDropdown/MultiSelectDropdownContainer';

let dropdownReference;

const NoSelectAllDropdownFilterCustom = ({
  items  ,
  onSelect = () => {},
  placeHolder = 'Select',
  appendItem = '',
  alignmentRight = false,
  initialValue = '',
  isObject = false,
  width = '',
  disabled = false,
  type = '',
  maxheight = '',
  required = false,
  justifyContent = '',
  disableSelection = false,
  noPadding = false,
  // itemsSelected = [],
  // setItemsSelected = () => {},
  itemsSelected ,
  setItemsSelected ,
  setSelectedFilter,
  clientStatusFilterTemp = [],
  hitApiAfterClear= () => {},
  onSelectAllHitApi=()=>{},

}) => {


  const [showDropdown, setShowDropDown] = useState(false);
  const [filteredItems, setFilteredItems] = useState([...items]);
  const [checked, setChecked] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownLabel, setDropdownLabel] = useState('Various');

  // useEffect(() => {
  //   const checkObj = {};
  //   items.forEach(data => {
  //     checkObj[data.id] = false;
  //   });
  //   // setChecked({...checkObj});  
  //   setFilteredItems([...items]);
  //   // setItemsSelected([...items]);
    
  // }, [items]);



  useEffect(() => {
    const checkObj = {};
    items.forEach(data => {
      checkObj[data.id] = false;
    });
    setChecked({...checkObj});  
    setFilteredItems([...items]);
    // setItemsSelected([...items]);
  }, [items]);

console.log(itemsSelected,5555)
  useEffect(() => {
    return function cleanup() {
      dropdownReference = null;
      document.removeEventListener('click', handleOutsideClick, false);
    };
   
  }, []);

  useEffect(() => {
    if (!itemsSelected?.length) {setDropdownLabel(placeHolder);}

    if (itemsSelected?.length === 1) {
      setDropdownLabel(itemsSelected[0].name);
      }

    if (itemsSelected?.length > 1) {setDropdownLabel('Various');}

  }, [itemsSelected]);

  useEffect(()=>{
    if (itemsSelected?.length === 1) {
      setDropdownLabel(itemsSelected[0].name);
      checked[itemsSelected[0].id] = checked[itemsSelected[0].id];
      setChecked({...checked});
      }else
    if (itemsSelected?.length > 1) {
   
      setDropdownLabel('Various');
      itemsSelected.map((item)=>{
        // added  below lines  to include active item when clicked on'select All" it'll include  'Active'  item also 
        if(item.name =='Active'   ||   item.name =='Active - Probation'  ){     
          checked[item.id] = checked[itemsSelected.find(itemdata=>itemdata.name ==item.name).id?true:true];
       
          setChecked({...checked});
        }

        if(item ){
          checked[item.id] = checked[item.id]?true:true;
          setChecked({...checked});
        }
        //   checked[item.id] = !checked[item.id];
        // setChecked({...checked});
      })
    }
  },[clientStatusFilterTemp,
    itemsSelected
  ])


  //Dropdown formatting and logic

  const composeDropdownClasses = () => {
    let classes = 'dropdown-menu ';
    if (alignmentRight) {
      classes = classes + ' dropdown-menu-right';
    }
    if (showDropdown) {
      classes = classes + ' show';
    }
  
    return classes;
  };

  const setNode = nodeParam => {
    dropdownReference = nodeParam;
  };

  const handleOutsideClick = e => {
    // ignore clicks on the component itself
    if (dropdownReference !== null) {
      if (dropdownReference.contains(e.target)) {
        return;
      }
    }
    document.getElementById('search').value = '';
    search('');
    setShowDropDown(false);
  };

  const handleComboClick = () => {
    if (!showDropdown) {
      // attach/remove event handler
      document.addEventListener('click', handleOutsideClick, true);
    } else {
      document.removeEventListener('click', handleOutsideClick, false);
 
    }
    setShowDropDown(!showDropdown);
  };

  const theme = {
    main: type,
  };



  const search = inputValue => {
    if (inputValue === '') {
      setFilteredItems([...items]);
      setSearchTerm('');

      return;
    }

    const inputRegEx = new RegExp(inputValue.toLowerCase(), '');

    const res = items.filter(item => {
      if (inputRegEx.test(item.name.toLowerCase())) return item;
   
    });

    setSearchTerm(inputValue);

    setFilteredItems([...res]);

  };

  const handleClearAll = () => {
    setItemsSelected([]);
    setSelectedFilter([])
    for (let key in checked) {
      checked[key] = false;
    }
    hitApiAfterClear(true);

    setChecked({...checked});
  };

  const handleSelectAll = () => {
    setItemsSelected([...items]);
    setSelectedFilter([...items])
    for (let key in checked) {
      checked[key] = true;
    }

    // onSelectAllHitApi(true);
    setChecked({...checked});
  };

  const handleSelectItem = (e, item) => {
    e.preventDefault();
    const selection = [...itemsSelected];
    selection.some((i)=>i.id === item.id)
      ? selection.splice(selection.findIndex(a => a.id === item.id), 1)
      : selection.push(item);

    setItemsSelected([...selection]);
    setSelectedFilter([...selection])
    checked[item.id] = !checked[item.id];
    setChecked({...checked});
  };

console.log(checked,"checked")
  return (
    <MultiSelectDropdownContainer
      className={showDropdown ? `w-100 btn-group show` : `w-100 btn-group`}
      ref={setNode}>
      <ThemeProvider theme={theme}>
        <DropdownButton
          onClick={disabled ? () => {} : () => handleComboClick()}
          justifyContent={justifyContent}
          className="button-selected"
          width={width}
          itemsSelected={itemsSelected}
          borderColour={(itemsSelected?.length > 0) ? true : false}
          title={itemsSelected ? 'Various' : placeHolder}
          noPadding={noPadding}>
          <h6>
            {placeHolder} <span>{required ? '*' : ''}</span>
          </h6>
          <label>
            {dropdownLabel} <span>{required && !itemsSelected ? '*' : ''}</span>
          </label>
          <i>
            {showDropdown ? (
              <FiChevronUp className="align-self-center me-0" />
            ) : (
              <FiChevronDown className="align-self-center me-0" />
            )}
          </i>
        </DropdownButton>
      </ThemeProvider>
      <div className={`${composeDropdownClasses()} ${width}`} >
        <ItemsDropdownStyle maxheight={maxheight}>
          <SearchAddContainer className="d-flex align-items-center justify-content-center">
            <SearchContainer style={{width: '100%', marginLeft: '10%'}}>
              <InputLabelTop
                title={'Select Employees'}
                no_internal_margin="true"
                type="text"
                id="search"
                label={'Search'}
                onChange={e => {
                  search(e.target.value);
                }}
                value={searchTerm}
              />
            </SearchContainer>
          </SearchAddContainer>
          <div className="d-flex" title={'save-clear-all'}>
            <SelectClearButton
              onClick={handleSelectAll}
              className="text-capitalize">
              Select All
            </SelectClearButton>
            <SelectClearButton
              onClick={handleClearAll}
              className="text-capitalize">
              Clear All
            </SelectClearButton>
          </div>
          {filteredItems ? (
            filteredItems.map((item, index) => {
              return (
                <div key={index}>
                  <button
                    className="dropdown-item text-capitalize  "
                    key={`dropdown-item-${index}`}
                    type="button"
                    onClick={
                      disabled || disableSelection
                        ? () => {
                            return;
                          }
                        : e => handleSelectItem(e, item)
                    }
                    title={!isObject ? item : item.name}>
                    <Checkbox
                      id={!isObject ? item : item.id}
                      label={!isObject ? item : item.name}
                      item={item}
                      checked={checked}
                    />
                  </button>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </ItemsDropdownStyle>
      </div>
    </MultiSelectDropdownContainer>
  );
};

export default NoSelectAllDropdownFilterCustom;
