import React, {useState, useEffect, Fragment} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import {useForm} from 'hooks/FormHook';
import {AppContext} from "context"  ;
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  DropdownTypesContainer,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import {ERROR, SAVE, SAVED,InvalidOrEmptyValues} from 'utils/constants/messages';

import {PLUS, TIMES} from 'utils/constants/icons';
import {  postOrPatchRateCard, setRateCardById } from 'utils/api/SettingsApi';
import AddRate from "./AddRate"
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { createDateWithTime, validateDate, validateTime } from 'shared/methods';
import _ from "lodash"
import { useContext } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
const AddMoal = ({
  fullBind,
  idSelected,
  setTitle,
  setModalValue,
  loadRateCard,
  setRateCardModalValue,
  addCard,
  editCard,
  rateCardType,
  travelTab,
  copyToken,
  copiedCard,
  copiedListigData,
  setCopyToken,
  filterShiftType,
  onCallTab,
  officeTab,
  officeShiftType,
  rateSource,
  absencePaid,
absenceType,
abscenceTab,
toast,
isPayRate,
shiftType,
shiftTab,
waitTab
}) => {

  const {
    values, setValues, useInput,isValid, errors,  setFormErrors,setCheckRequires
  } = useForm({   rateValue:[] });
  const { contextChoices } = useContext(AppContext)

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [toggleBtnStatus, setToggleBtnStatus] = useState(false);
  const [hitNewRate, SetHitNewRate] = useState(false);     
  const [dummyID, setDummyID] = useState(false);    
const [skillAddedMessage, setSkillAddedMessage] = useState('');
const [forcedRateperHoursDataPassed, setForcedRateperHoursDataPassed] = useState(false);  
// const [drodownSearchName,setDropdownSearchName]=useState(service_categories)
// const [checkBoxTick, SetCheckBoxTick] = useState(false);
// const edit_rate_card = 'Edit Rate Card';
// const new_rate_card = 'Add New Rate Card';
// const RATE_VALUES = 'rateValues';
// useEffect(()=>{
// if(travelTab){
//   setDropdownSearchName(travel_categories)
// }else{
//   setDropdownSearchName(service_categories)
// }
// },[travelTab])
// const drodownSearchName =  travelTab ? 'travel_categories':'service_categories'
console.log('branch choice', contextChoices.branch)
const edit_rate_card =editCard;
const new_rate_card = addCard;
const copied_rate_card = copiedCard;


const rate_card_details =travelTab ? values.rateValue && values.rateValue.map(x=>{
  return{
    travel_type: x.travel_type,
    is_monday: x.daysofWeek && x.daysofWeek[0].checked,
    is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
    is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
    is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
    is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
    is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
    is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    travel_mode:x.travel_mode,
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: x.is_multiplier_inclusive,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    // bill_rate_per_hour:x.bill_rate_per_hour,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    over_time_rate:x.over_time_rate,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
}) 
: onCallTab ?values.rateValue && values.rateValue.map(x=>{
  return{
    shift_type: x.shift_type,
    is_monday: x.daysofWeek && x.daysofWeek[0].checked,
    is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
    is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
    is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
    is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
    is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
    is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: x.is_multiplier_inclusive,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    // bill_rate_per_hour:x.bill_rate_per_hour,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
}) 
: officeTab? values.rateValue && values.rateValue.map(x=>{
  return{
    shift_type: x.shift_type,
    is_monday: x.daysofWeek && x.daysofWeek[0].checked,
    is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
    is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
    is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
    is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
    is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
    is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: x.is_multiplier_inclusive,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    // bill_rate_per_hour:x.bill_rate_per_hour,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    rate_source:x.rate_source,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
}) 
: shiftTab ? values.rateValue && values.rateValue.map(x=>{
  return{
    shift_type: x.shift_type,
    is_monday: x.daysofWeek && x.daysofWeek[0].checked,
    is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
    is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
    is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
    is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
    is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
    is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: x.is_multiplier_inclusive,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    // bill_rate_per_hour:x.bill_rate_per_hour,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    rate_source:x.rate_source,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
}) 
: waitTab ? values.rateValue && values.rateValue.map(x=>{
  return{
    shift_type: null,
    is_monday: x.daysofWeek && x.daysofWeek[0].checked,
    is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
    is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
    is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
    is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
    is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
    is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: x.is_multiplier_inclusive,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    // bill_rate_per_hour:x.bill_rate_per_hour,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    rate_source:x.rate_source,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
})
: abscenceTab?values.rateValue && values.rateValue.map(x=>{
  return{
    absence_type: x.absence_type,
    absence_paid: x.absence_paid,
    is_monday: x.daysofWeek && x.daysofWeek[0].checked,
    is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
    is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
    is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
    is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
    is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
    is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: false,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    // bill_rate_per_hour:x.bill_rate_per_hour,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    rate_source:x.rate_source,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
}) 
:
values.rateValue && values.rateValue.map(x=>{
  return{
    service_categories: x.service_categories,
    is_monday: x.daysofWeek && x.daysofWeek[0].checked,
    is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
    is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
    is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
    is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
    is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
    is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: x.is_multiplier_inclusive,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    time_unit: x.time_unit,
    // bill_rate_per_hour:x.bill_rate_per_hour,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    over_time_rate:x.over_time_rate,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
}) 

// console.log(rate_card_details_with_id,456)

const rate_card_details_with_id=travelTab ?values.rateValue && values.rateValue.map(x=>{
  if(x.id){
  return{
    id:x.id,
    travel_type: x.travel_type,
    is_monday: x.is_monday,
    is_tuesday:x.is_tuesday,
    is_wednesday:x.is_wednesday,
    is_thursday:x.is_thursday,
    is_friday:x.is_friday,
    is_saturday:x.is_saturday,
    is_sunday:x.is_sunday,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    travel_mode:x.travel_mode,
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: x.is_multiplier_inclusive,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    // bill_rate_per_hour: parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    bill_rate_per_hour: x.bill_rate_per_hour,
    over_time_rate:x.over_time_rate,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
  } else if (dummyID ) {
    return{
      travel_type: x.travel_type,
      is_monday: x.is_monday,
      is_tuesday:x.is_tuesday,
      is_wednesday:x.is_wednesday,
      is_thursday:x.is_thursday,
      is_friday:x.is_friday,
      is_saturday:x.is_saturday,
      is_sunday:x.is_sunday,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      travel_mode:x.travel_mode,
      time_from:x.time_from,
      time_to:x.time_to,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
      date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      over_time_rate:x.over_time_rate,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
   }
  else {
    return{
      travel_type: x.travel_type,
      is_monday: x.daysofWeek && x.daysofWeek[0].checked,
      is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
      is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
      is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
      is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
      is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
      is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      travel_mode:x.travel_mode,
      time_from:x.time_from,
      time_to:x.time_to,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
    date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      over_time_rate:x.over_time_rate,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }

})
: onCallTab ?values.rateValue && values.rateValue.map(x=>{
  if(x.id  ){
  return{
    id: x.id,
    shift_type: x.shift_type,
    is_monday: x.is_monday,
    is_tuesday:x.is_tuesday,
    is_wednesday:x.is_wednesday,
    is_thursday:x.is_thursday,
    is_friday:x.is_friday,
    is_saturday:x.is_saturday,
    is_sunday:x.is_sunday,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: x.is_multiplier_inclusive,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    // bill_rate_per_hour:x.bill_rate_per_hour,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
  }else if(dummyID){
    return{
      shift_type: x.shift_type,
      is_monday: x.is_monday,
      is_tuesday:x.is_tuesday,
      is_wednesday:x.is_wednesday,
      is_thursday:x.is_thursday,
      is_friday:x.is_friday,
      is_saturday:x.is_saturday,
      is_sunday:x.is_sunday,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      time_from:x.time_from,
      time_to:x.time_to,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
      date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }else {
    return{
      shift_type: x.shift_type,
      is_monday: x.daysofWeek && x.daysofWeek[0].checked,
      is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
      is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
      is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
      is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
      is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
      is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      time_from:x.time_from,
      time_to:x.time_to,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
    date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }

})
:officeTab ? values.rateValue && values.rateValue.map(x=>{
  if(x.id  ){
  return{
    id: x.id,
    shift_type: x.shift_type,
    is_monday: x.is_monday,
    is_tuesday:x.is_tuesday,
    is_wednesday:x.is_wednesday,
    is_thursday:x.is_thursday,
    is_friday:x.is_friday,
    is_saturday:x.is_saturday,
    is_sunday:x.is_sunday,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: x.is_multiplier_inclusive,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    // bill_rate_per_hour:x.bill_rate_per_hour,
    rate_source:x.rate_source,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
  }else if(dummyID){
    return{
      shift_type: x.shift_type,
      is_monday: x.is_monday,
      is_tuesday:x.is_tuesday,
      is_wednesday:x.is_wednesday,
      is_thursday:x.is_thursday,
      is_friday:x.is_friday,
      is_saturday:x.is_saturday,
      is_sunday:x.is_sunday,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      time_from:x.time_from,
      time_to:x.time_to,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
      date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      rate_source:x.rate_source,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }else {
    return{
      shift_type: x.shift_type,
      is_monday: x.daysofWeek && x.daysofWeek[0].checked,
      is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
      is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
      is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
      is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
      is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
      is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      time_from:x.time_from,
      time_to:x.time_to,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
    date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      rate_source:x.rate_source,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }

})
:shiftTab ? values.rateValue && values.rateValue.map(x=>{
  if(x.id  ){
  return{
    id: x.id,
    shift_type: x.shift_type,
    is_monday: x.is_monday,
    is_tuesday:x.is_tuesday,
    is_wednesday:x.is_wednesday,
    is_thursday:x.is_thursday,
    is_friday:x.is_friday,
    is_saturday:x.is_saturday,
    is_sunday:x.is_sunday,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: x.is_multiplier_inclusive,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    // bill_rate_per_hour:x.bill_rate_per_hour,
    rate_source:x.rate_source,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
  }else if(dummyID){
    return{
      shift_type: x.shift_type,
      is_monday: x.is_monday,
      is_tuesday:x.is_tuesday,
      is_wednesday:x.is_wednesday,
      is_thursday:x.is_thursday,
      is_friday:x.is_friday,
      is_saturday:x.is_saturday,
      is_sunday:x.is_sunday,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      time_from:x.time_from,
      time_to:x.time_to,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
      date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      rate_source:x.rate_source,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }else {
    return{
      shift_type: x.shift_type,
      is_monday: x.daysofWeek && x.daysofWeek[0].checked,
      is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
      is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
      is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
      is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
      is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
      is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      time_from:x.time_from,
      time_to:x.time_to,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
    date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      rate_source:x.rate_source,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }

})
:waitTab ? values.rateValue && values.rateValue.map(x=>{
  if(x.id  ){
  return{
    id: x.id,
    shift_type: null,
    is_monday: x.is_monday,
    is_tuesday:x.is_tuesday,
    is_wednesday:x.is_wednesday,
    is_thursday:x.is_thursday,
    is_friday:x.is_friday,
    is_saturday:x.is_saturday,
    is_sunday:x.is_sunday,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: x.is_multiplier_inclusive,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    // bill_rate_per_hour:x.bill_rate_per_hour,
    rate_source:x.rate_source,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
  }else if(dummyID){
    return{
      shift_type: null,
      is_monday: x.is_monday,
      is_tuesday:x.is_tuesday,
      is_wednesday:x.is_wednesday,
      is_thursday:x.is_thursday,
      is_friday:x.is_friday,
      is_saturday:x.is_saturday,
      is_sunday:x.is_sunday,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      time_from:x.time_from,
      time_to:x.time_to,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
      date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      rate_source:x.rate_source,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }else {
    return{
      shift_type: null,
      is_monday: x.daysofWeek && x.daysofWeek[0].checked,
      is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
      is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
      is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
      is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
      is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
      is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      time_from:x.time_from,
      time_to:x.time_to,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
    date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      rate_source:x.rate_source,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }

})
:abscenceTab ? values.rateValue && values.rateValue.map(x=>{
  if(x.id  ){
  return{
    id: x.id,
    absence_type: x.absence_type,
    absence_paid: x.absence_paid,
    is_monday: x.is_monday,
    is_tuesday:x.is_tuesday,
    is_wednesday:x.is_wednesday,
    is_thursday:x.is_thursday,
    is_friday:x.is_friday,
    is_saturday:x.is_saturday,
    is_sunday:x.is_sunday,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: false,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    // bill_rate_per_hour:x.bill_rate_per_hour,
    rate_source:x.rate_source,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
  }else if(dummyID){
    return{
      absence_type: x.absence_type,
      absence_paid: x.absence_paid,
      is_monday: x.is_monday,
      is_tuesday:x.is_tuesday,
      is_wednesday:x.is_wednesday,
      is_thursday:x.is_thursday,
      is_friday:x.is_friday,
      is_saturday:x.is_saturday,
      is_sunday:x.is_sunday,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      time_from:x.time_from,
      time_to:x.time_to,
      // is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
      date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      rate_source:x.rate_source,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }else {
    return{
      absence_type: x.absence_type,
      absence_paid: x.absence_paid,
      is_monday: x.daysofWeek && x.daysofWeek[0].checked,
      is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
      is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
      is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
      is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
      is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
      is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      time_from:x.time_from,
      time_to:x.time_to,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
    date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      rate_source:x.rate_source,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }

})
:
values.rateValue && values.rateValue.map(x=>{
  if(x.id  ){
  return{
    id: x.id,
    service_categories: x.service_categories,
    is_monday: x.is_monday,
    is_tuesday:x.is_tuesday,
    is_wednesday:x.is_wednesday,
    is_thursday:x.is_thursday,
    is_friday:x.is_friday,
    is_saturday:x.is_saturday,
    is_sunday:x.is_sunday,
    rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
      return{
        rate_code: w.id && w.id,
      }
    }),
    time_unit: x.time_unit,
    time_from:x.time_from,
    time_to:x.time_to,
    is_multiplier_inclusive: x.is_multiplier_inclusive,
    date_from:x.date_from,
    date_to:x.date_to,
    is_active: x.is_active,
    bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
    // bill_rate_per_hour:x.bill_rate_per_hour,
    over_time_rate:x.over_time_rate,
    details_rate:x.details_rate && x.details_rate.map(detRate=>{
      return (
        {
          ...detRate,
          details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
          durations:detRate.durations && parseFloat(detRate.durations),
          charges:detRate.charges && parseFloat(detRate.charges)
        }
      )
    })
  }
  }else if(dummyID){
    return{
      service_categories: x.service_categories,
      is_monday: x.is_monday,
      is_tuesday:x.is_tuesday,
      is_wednesday:x.is_wednesday,
      is_thursday:x.is_thursday,
      is_friday:x.is_friday,
      is_saturday:x.is_saturday,
      is_sunday:x.is_sunday,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      time_from:x.time_from,
      time_to:x.time_to,
      time_unit: x.time_unit,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
      date_to:x.date_to,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0 ).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      over_time_rate:x.over_time_rate,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }else {
    return{
      service_categories: x.service_categories,
      is_monday: x.daysofWeek && x.daysofWeek[0].checked,
      is_tuesday:x.daysofWeek &&x.daysofWeek[1].checked,
      is_wednesday:x.daysofWeek &&x.daysofWeek[2].checked,
      is_thursday:x.daysofWeek &&x.daysofWeek[3].checked,
      is_friday:x.daysofWeek &&x.daysofWeek[4].checked,
      is_saturday:x.daysofWeek &&x.daysofWeek[5].checked,
      is_sunday:x.daysofWeek &&x.daysofWeek[6].checked,
      rate_card_detail_rate_code:x.rateCode && x.rateCode.filter(obj=>obj.checked == true).map(w=>{
        return{
          rate_code: w.id && w.id,
        }
      }),
      time_from:x.time_from,
      time_to:x.time_to,
      is_multiplier_inclusive: x.is_multiplier_inclusive,
      date_from:x.date_from,
    date_to:x.date_to,
    time_unit: x.time_unit,
      is_active: x.is_active,
      bill_rate_per_hour:parseFloat(x.bill_rate_per_hour?x.bill_rate_per_hour:0).toFixed(2),
      // bill_rate_per_hour:x.bill_rate_per_hour,
      over_time_rate:x.over_time_rate,
      details_rate:x.details_rate && x.details_rate.map(detRate=>{
        return (
          {
            ...detRate,
            details_rate_time_unit:detRate.details_rate_time_unit && detRate.details_rate_time_unit,
            durations:detRate.durations && parseFloat(detRate.durations),
            charges:detRate.charges && parseFloat(detRate.charges)
          }
        )
      })
    }
  }

})


  useEffect(() => {

    if (idSelected) {
      setTitle(edit_rate_card);
    }else if(idSelected=='' && copyToken){
      setTitle(copied_rate_card);
      // setLoadingModal(false);
    }else{
      setTitle(new_rate_card);
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);



useEffect(()=>{
if(values.rateValue.length===0){
  values.rateValue[0]={id:''}
}
},[])

  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);


useEffect(()=>{
  if (idSelected) {
    setCopyToken(false);
    loadRateCardById();
  }
},[])

useEffect(()=>{
  if (copyToken) {
    if(copiedListigData){
      values.name = copiedListigData.name;
      values.rate_card_reference   = copiedListigData.rate_card_reference;
      values.rateValue.is_active=copiedListigData.is_active;
      values.rateValue=copiedListigData.rate_card_details;
      setValues({...values});
    }
    setToggleBtnStatus(copiedListigData.is_active)
    setLoadingModal(false);   
  
  };
},[])


  const loadRateCardById = async() => {
    const response =await setRateCardById(idSelected)
    if(response  && copyToken==false){
      values.id=response.id;
      values.name = response.name;
      values.branch = response.branch && response.branch.map((val)=>({id: val.branch_id, label: val.branch_name, arr_id: val.id}))
      values.rate_card_reference   = response.rate_card_reference;
      values.rateValue.is_active=response.is_active;
      // values.rateValue= response.rate_card_details && response.rate_card_details.map((item)=>{
      //   delete item.details_rate ;
      //   return item
      // });
      values.rateValue=response.rate_card_details;
      setValues({...values});
    }
    setToggleBtnStatus(response.is_active)
    setLoadingModal(false);   
  };

  
  const handleSubmit = async (evt) => {

    let allGood=false
      evt.preventDefault();
      if (loadingSave) {
        return;
      }
      if(!values.name || !values.rate_card_reference || !values.branch){
        setCheckRequires(true);
        setShowError(true);
        allGood=true
        setErrorMessage(InvalidOrEmptyValues);
        return;
      }
     values.rateValue && values.rateValue.every((rate,index)=>{
// removed || !rate.date_to from checks
      if(travelTab){
        if(!rate.travel_type || !rate.date_from  || !rate.time_from ||  !rate.time_to || !rate.bill_rate_per_hour){
          setCheckRequires(true);
          setShowError(true);
          allGood=true
          setErrorMessage(InvalidOrEmptyValues);
          return false
         }
         else if(rate.details_rate){
           rate.details_rate.forEach(detRate=>{
             if(!detRate.details_rate_time_unit || !detRate.durations || !detRate.charges){
              setCheckRequires(true);
              setShowError(true);
              allGood=true
              setErrorMessage(InvalidOrEmptyValues);
             }
           })
         }
      }else if(onCallTab){
        if(!rate.shift_type || !rate.date_from || !rate.time_from ||  !rate.time_to || !rate.bill_rate_per_hour ){
          setCheckRequires(true);
          setShowError(true);
          allGood=true
          setErrorMessage(InvalidOrEmptyValues);
          return false
         }
         else if(rate.details_rate){
           rate.details_rate.forEach(detRate=>{
            // !detRate.charges
             if(!detRate.details_rate_time_unit || !detRate.durations ){
              setCheckRequires(true);
              setShowError(true);
              allGood=true
              setErrorMessage(InvalidOrEmptyValues);
             }
           })
         }
        }else if(officeTab && forcedRateperHoursDataPassed){
          if(!rate.shift_type || !rate.date_from || !rate.time_from ||  !rate.time_to || !rate.rate_source || !rate.bill_rate_per_hour){
            setCheckRequires(true);
            setShowError(true);
            allGood=true
            setErrorMessage(InvalidOrEmptyValues);
            return false
           }
           else if(rate.details_rate){
             rate.details_rate.forEach(detRate=>{
              // !detRate.charges
               if(!detRate.details_rate_time_unit || !detRate.durations ){
                setCheckRequires(true);
                setShowError(true);
                allGood=true
                setErrorMessage(InvalidOrEmptyValues);
               }
             })
           }
        }
        else if(abscenceTab && forcedRateperHoursDataPassed){
          if(!rate.absence_type ||!rate.absence_paid || !rate.date_from || !rate.time_from ||  !rate.time_to  || !rate.rate_source || !rate.bill_rate_per_hour){
            setCheckRequires(true);
            setShowError(true);
            allGood=true
            setErrorMessage(InvalidOrEmptyValues);
            return false
           }
           else if(rate.details_rate){
             rate.details_rate.forEach(detRate=>{
              // !detRate.charges
               if(!detRate.details_rate_time_unit || !detRate.durations ){
                setCheckRequires(true);
                setShowError(true);
                allGood=true
                setErrorMessage(InvalidOrEmptyValues);
               }
             })
           }
        } 
        else if( (abscenceTab ) &&  (forcedRateperHoursDataPassed == false) ){
          if( !rate.bill_rate_per_hour  ){
            setCheckRequires(false);
            setShowError(false);
            allGood=false
            setErrorMessage();
            return true
           }
           if( !rate.absence_type ||!rate.absence_paid || !rate.date_from || !rate.time_from ||  !rate.time_to  || !rate.rate_source  ){
            setCheckRequires(true);
            setShowError(true);
            allGood=true
            setErrorMessage(InvalidOrEmptyValues);
            return false
           }
           
           else if(rate.details_rate){
             rate.details_rate.forEach(detRate=>{
              // !detRate.charges
               if(!detRate.details_rate_time_unit || !detRate.durations ){
                setCheckRequires(true);
                setShowError(true);
                allGood=true
                setErrorMessage(InvalidOrEmptyValues);
               }
             })
           }
        } 
        else if( (officeTab) &&  (forcedRateperHoursDataPassed == false) ){
          if( !rate.bill_rate_per_hour  ){
            setCheckRequires(false);
            setShowError(false);
            allGood=false
            setErrorMessage();
            return true
           }
           if(!rate.shift_type || !rate.date_from || !rate.time_from ||  !rate.time_to || !rate.rate_source || !rate.bill_rate_per_hour ){
            setCheckRequires(true);
            setShowError(true);
            allGood=true
            setErrorMessage(InvalidOrEmptyValues);
            return false
           }
           
           else if(rate.details_rate){
             rate.details_rate.forEach(detRate=>{
              // !detRate.charges
               if(!detRate.details_rate_time_unit || !detRate.durations ){
                setCheckRequires(true);
                setShowError(true);
                allGood=true
                setErrorMessage(InvalidOrEmptyValues);
               }
             })
           }
        } 
        else if((shiftTab || waitTab) && forcedRateperHoursDataPassed){
          if(!rate.date_from || !rate.time_from ||  !rate.time_to || !rate.rate_source || !rate.bill_rate_per_hour){
            setCheckRequires(true);
            setShowError(true);
            allGood=true
            setErrorMessage(InvalidOrEmptyValues);
            return false
           }
           else if(rate.details_rate){
             rate.details_rate.forEach(detRate=>{
              // !detRate.charges
               if(!detRate.details_rate_time_unit || !detRate.durations ){
                setCheckRequires(true);
                setShowError(true);
                allGood=true
                setErrorMessage(InvalidOrEmptyValues);
               }
             })
           }
        }
        else if( (shiftTab || waitTab) &&  (forcedRateperHoursDataPassed == false) ){
          debugger
          if( !rate.bill_rate_per_hour  ){
            setCheckRequires(false);
            setShowError(false);
            allGood=false
            setErrorMessage();
            return true
           }
           if(!rate.date_from || !rate.time_from ||  !rate.time_to || !rate.rate_source || !rate.bill_rate_per_hour ){
            setCheckRequires(true);
            setShowError(true);
            allGood=true
            setErrorMessage(InvalidOrEmptyValues);
            return false
           }
           
           else if(rate.details_rate){
             rate.details_rate.forEach(detRate=>{
              // !detRate.charges
               if(!detRate.details_rate_time_unit || !detRate.durations ){
                setCheckRequires(true);
                setShowError(true);
                allGood=true
                setErrorMessage(InvalidOrEmptyValues);
               }
             })
           }
        } 
        else{       
        // || !rate.date_to removed it from checks
       if(!rate.service_categories || !rate.date_from || !rate.time_from ||  !rate.time_to|| !rate.bill_rate_per_hour ){
        setCheckRequires(true);
        setShowError(true);
        allGood=true
        setErrorMessage(InvalidOrEmptyValues);
        return false
       }
       else if(rate.details_rate){
         rate.details_rate.forEach(detRate=>{
          // !detRate.charges
           if(!detRate.details_rate_time_unit || !detRate.durations ){
            setCheckRequires(true);
            setShowError(true);
            allGood=true
            setErrorMessage(InvalidOrEmptyValues);
           }
         })
       }
      }
       return true
     })
 
if(allGood){
  return
}
    if (textStatus==='SAVED') {    return; }
    setLoadingSave(true);

      const bodyData=values.id ? {
        id:values.id,
        name:values.name,
        rate_category_id:rateCardType,
        is_active:  values.is_active,
        rate_card_reference: values.rate_card_reference,
        rate_card_details: rate_card_details_with_id,
      }:{
        name:values.name,
        rate_card_reference: values.rate_card_reference,
        rate_category_id:rateCardType,
        is_active:  values.is_active,
        rate_card_details: rate_card_details,
      }
      if(values.branch && !values.id){
        bodyData.branch_details = values.branch.map((val)=>({id:val.arr_id, branch_id: val.id}))
      }
     await postOrPatchRateCard(values,bodyData)
     .then(res=>{
       if( res.status==='Success'){ 
          onSuccess() 
          setModalValue(false)
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `${res.message}`,
          });
          }
  else{  onError(res.message)  }
     })
     .catch(onError)
  };


//above code  is for saving rate code  
  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    loadRateCard(rateCardType)
  };

  const onError = (mess) => {
    setRateCardModalValue(false)
    toast.current.show({
      severity: 'success',
      summary: '',
      detail: `Please wait for few minutes to get rate card updated in all visits.`,
    });
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setShowError(true);
    setCheckRequires(true);
      setErrorMessage(
        mess || "Some of the fields have invalid or empty values. Please review."
      );

  };

  const deleteAddRate = (data , index) => {
    if(values.rateValue){
     values.rateValue.splice(index,1)
      setFormErrors(errors.filter(error=>!error.includes('Added Rate Details')))
    }else{
     values.rateValue.splice(index,1)
      setFormErrors(errors.filter(error=>!error.includes('Added Rate Details')))
    }   
  setValues({...values})
      setSkillAddedMessage('');
    };


const addRateCopy=(index)=>{
  const arrayLength=values.rateValue.length
  // addRate()
 let tempCopyData=_.cloneDeep(values.rateValue[index])
 if(tempCopyData.id){
   setDummyID(true)
  delete tempCopyData.id
}

const array1=values.rateValue.slice(0,index+1)
const array2=values.rateValue.slice(index+1,arrayLength+1)
array1.push(tempCopyData)
values.rateValue=array1.concat(array2)
setValues({...values})

}


const addRate = () => {
  SetHitNewRate(true)
  if (! values.rateValue)
  values.rateValue = [];
  values.rateValue.push({  });
  setValues({...values});
  setSkillAddedMessage('');
};

const handleToggleBtn=()=>{
  setToggleBtnStatus(!toggleBtnStatus)
  values.is_active=!toggleBtnStatus;
  
  // values.rateValue.is_active=!values.rateValue.is_active;
  setValues({...values})
}

useEffect(()=>{
  if (copyToken && copiedListigData.is_active !==undefined) {
    setToggleBtnStatus(copiedListigData.is_active)
    values.is_active=copiedListigData.is_active;
  }
  setValues({...values})
},[copyToken
])
const addRateDetails=(index)=>{
  if(!values.rateValue[index].details_rate){
    values.rateValue[index].details_rate=[{}]
    setValues({...values})
  }else{
    values.rateValue[index].details_rate=[...values.rateValue[index].details_rate,{}]
    setValues({...values})
  }
}

const dropDownHandle = item => {
  setValues({...values,...item});
};

console.log(errors, "errors")
  return (
    <ModalContainer max_width={'92%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'92%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="ps-3 pe-3 pb-3 pt-0">

          <FlexGrid3Container className={'w-100 mb-2 pb-1'}>
            <FlexGrid3Item className="pe-1 w-100  " ResizeWidth={'30%'}>
              <InputLabelTop
                required
                label={'Name'}
                type="text"
                {...useInput(`name`, 'isRequired')}
                maxlength={100}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 w-100  " ResizeWidth={'30%'}>
              <InputLabelTop
                required
                label={'Ref'}
                type="text"
                {...useInput(`rate_card_reference`, 'isRequired')}
                maxlength={100}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 w-100  " ResizeWidth={'30%'}>
              <DropdownSearchable
                required
                isMulti={true}
                {...useInput(`branch`, 'isRequired')}
                placeHolder={'Branch Name'}
                options={contextChoices.branch}
                disabled={idSelected ? true : false}
                selectedOptionsProps={
                  values
                    ? values.branch
                      ? values.branch
                      : null
                    : null
                }
                onChange={value =>
                  dropDownHandle(
                    { branch: value ? value : null },
                    'branch'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 w-100  " ResizeWidth={'10%'}>

              <div className='d-flex justify-content-end align-right'  >
                <ToggleButton
                  onClick={() => handleToggleBtn()}
                  status={toggleBtnStatus}
                />
              </div>

            </FlexGrid3Item>
          </FlexGrid3Container >



          {/* below for add new rate card  button component */}
          <div className="mt-2 mb-2">
            <div className={'mb-2 mt-2'}>
              <TitleSection>Added Rate Details</TitleSection>
            </div>
            {values.rateValue && values.rateValue.length >= 1 &&
              values.rateValue.map((data, indexRate) => {
                return (
                  <div key={data.id + indexRate} >
                    <AddRate
                      readOnly={true}
                      useInput={useInput}
                      indexRate={indexRate}
                      data={data}
                      setValues={setValues}
                      nameSection={'rateValue'}
                      values={values}
                      addRate={addRate}
                      addRateCopy={addRateCopy}
                      deleteAddRate={deleteAddRate}
                      addedSkillMessage={skillAddedMessage}
                      hitNewRate={hitNewRate}
                      addRateDetails={addRateDetails}
                      travelTab={travelTab}
                      copyToken={copyToken}
                      dummyID={dummyID}
                      filterShiftType={filterShiftType}
                      onCallTab={onCallTab}
                      officeTab={officeTab}
                      shiftTab={shiftTab}
                      waitTab={waitTab}
                      officeShiftType={officeShiftType}
                      rateSource={rateSource}
                      absencePaid={absencePaid}
                      absenceType={absenceType}
                      abscenceTab={abscenceTab}
                      // checkBoxTick={checkBoxTick}
                      setForcedRateperHoursDataPassed={setForcedRateperHoursDataPassed}
                      isPayRate={isPayRate}
                      shiftType={shiftType}
                    />
                  </div>
                )
              })
            }
          </div>

          <>
            <div className={'d-flex justify-content-center justify-content-md-start'}>
              <div className={'mt-3'}>
                <PrimaryButtonForm minWidth="6rem" onClick={addRate} >
                  <span >{PLUS}Rate</span>
                </PrimaryButtonForm>
              </div>
            </div>

          </>

          <div className={'d-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'}>
            <div className={'mb-3'}>
              {showError ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div className={'d-flex justify-content-center justify-content-md-end'}>
              <div className={'ms-0 ms-md-3 me-2'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default AddMoal;
