import styled from "styled-components";

export const MultiSelectDropdownContainer = styled.div`
  .dropdown-menu {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
}
  .show{
    display: block;
    }
`;