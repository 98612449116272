import React,{Fragment} from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {TIMES} from 'utils/constants/icons';
import DateSelector from 'components/SharedComponents/DateSelector';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
    CloseButtonWrapper,
  } from 'shared/styles/constants/tagsStyles';
  
const RateCard = ({
    values,
    nameSection,
    index,
    useInput,
    deleteRate,
    list_key1,
    dropDownHandleRate,
    category,
    bill_rate,
    payRate,
    data,
    outerIndex,
    visitRate,
    payrate,
    travelrate,
    readOnly,
    editMode
}) => {

    return (
        <>
        <div className={`d-flex mt-1 me-5 ms-0}`}>
          {readOnly ?
          (
            <Fragment/>
          )
            :(
          <CloseButtonWrapper
          disabled={!editMode}
              onClick={() => {
                visitRate?deleteRate(nameSection,category,index,outerIndex) :deleteRate(nameSection,category,index);
              }}
              >
              {TIMES}
            </CloseButtonWrapper>
            )
          
          }
            
       <div className="w-100 me-2">
        <DropdownSearchable
          required
          placeHolder={payrate?'Pay Rate':(travelrate ? 'Travel Rate' :'Bill Rate')}
          options={data}
          disabled={!editMode}
          // di1RateCardsabled={
          //  visitRate?values[category][outerIndex][nameSection][index][list_key1]
          //  ? true
          //  : false :
          //  values[category][nameSection][index][list_key1]
          //     ? true
          //     : false
          // }
          {...useInput((visitRate ? `${category}.${outerIndex}.${nameSection}.${index}.${list_key1}` : (category ? `${category}.${nameSection}.${index}.${list_key1}` : `${nameSection}.${index}.${list_key1}`)), 'isRequired')}
          selectedOption={
          visitRate ? 
          values[category]?
            values[category][outerIndex][nameSection]
              ? values[category][outerIndex][nameSection][index][list_key1]
                ? values[category][outerIndex][nameSection][index][list_key1]
                : null
              : null
              :null 
            : category ? (values[category]?
            values[category][nameSection]
              ? values[category][nameSection][index][list_key1]
                ? values[category][nameSection][index][list_key1]
                : null
              : null
              :null) 
              : 
              (values?
                values[nameSection]
                  ? values[nameSection][index][list_key1]
                    ? values[nameSection][index][list_key1]
                    : null
                  : null
                  :null)
          }
          onChange={value =>
            dropDownHandleRate(
               {
                rate: value ? value : null,
              },nameSection,index,list_key1,category
            )
          }
        />
        </div>
        <div className="w-100 me-2">
        <InputLabelTop
        disabled={!editMode}
              type="text"
              id="bill_rate_hr"
              label={payrate?'Pay Rate/hr':(travelrate ? 'Travel Rate/hr' : 'Bill Rate/hr')}
              {...useInput(visitRate?`${category}[${outerIndex}].${nameSection}[${index}][${payrate?'pay_rate_per_hour':(travelrate ? 'travel_pay_rate_per_hour':'bill_rate_per_hour')}]`
              : (values[category] ? `${category}.${nameSection}[${index}][${payrate?'pay_rate_per_hour':(travelrate ? 'travel_pay_rate_per_hour' : 'bill_rate_per_hour')}]` : 
              `${nameSection}[${index}][${payrate?'pay_rate_per_hour':(travelrate ? 'travel_pay_rate_per_hour' : 'bill_rate_per_hour')}]`
              )
              )}
              maxlength={25}
            />
      </div>
      <div className="w-100 me-2">
      <DateSelector
      isRequired
                  labelTop="Effective From"
                  disabled={!editMode}
                  // disabled={
                  //     visitRate?
                  //     values[category][outerIndex][nameSection][index].effective_date_from
                  //     ? true
                  //     : false
                  //   :values[category][nameSection][index].effective_date_from
                  //     ? true
                  //     : false
                  // }
                  {...useInput((visitRate ? `${category}[${outerIndex}].${nameSection}[${index}].effective_date_from`: (values[category] ? `${category}.${nameSection}[${index}].effective_date_from` : `${nameSection}[${index}].effective_date_from`)),'isRequired')}
                />
      </div>
      <div className="w-100 me-2">
      <DateSelector
                  labelTop="Effective To"
                  disabled={!editMode}
                  // disabled={true}
                  //   disabled={
                  //     visitRate?
                  //     values[category][outerIndex][nameSection][index].effective_date_to
                  //     ? true
                  //     : false
                  //   :values[category][nameSection][index].effective_date_to
                  //     ? true
                  //     : false
                  // }
                  {...useInput(visitRate?`${category}[${outerIndex}].${nameSection}[${index}].effective_date_to`:(values[category] ? `${category}.${nameSection}[${index}].effective_date_to` : `${nameSection}[${index}].effective_date_to`))}
                />
      </div>
        </div>
      
        </>
    );
}

export default RateCard;
