import React, {useEffect,Fragment, useState} from 'react';
import {ModalContainer} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import {useForm} from 'hooks/FormHook';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {setMedicationName} from 'utils/api/SettingsApi';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {PrimaryButton} from 'shared/styles/buttons';
import {
 
  ErrorMessage,
 
} from 'shared/styles/constants/tagsStyles';
import {useRef} from 'react';

import {Toast} from 'primereact/toast';
const MedicationNameModal = ({
  fullBind,
  testingData,
  isCheckedId,
  setModalValue,
  setApiCall,
  isEdit,
}) => {
  console.log(testingData, 'testingData');
  console.log(isCheckedId, 'isCheckedId');
  const toast = useRef();
  const [errorMessage, setErrorMessage] = useState('');

  const {values, setValues, useInput, isValid, errors, setCheckRequires} =
    useForm({});
  useEffect(() => {
    if (isCheckedId) {
      values.name = testingData.name;
      values.indication = testingData.indication;
      values.medication_class = testingData.medication_class;
      values.supplier_brand_name = testingData.supplier_brand_name;
      values.id = testingData.id;
      setValues({...values});
    } else {
      setValues([]);
    }
  }, []);

  const handleSubmit = async () => {
    const body = isCheckedId
      ? {
          name: values.name,
          indication: values.indication,
          medication_class: values.medication_class,
          supplier_brand_name: values.supplier_brand_name,
          id: values.id,
        }
      : {
          name: values.name,
          indication: values.indication,
          medication_class: values.medication_class,
          supplier_brand_name: values.supplier_brand_name,
          id: values.id,
        };
        if (!isValid && errors.length > 0) {
          setErrorMessage(
            'Some of the fields have invalid or empty values. Please review'
          );
          setCheckRequires(true);
          return;
        }
        else{
    let response = await setMedicationName(body);
  
    console.log(response.message, 'response');
    if (response.message === 'Already exist') {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: 'Data Already  Exist',
      });
    } else if (response.message === 'Success') {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Data Updated Successfully',
      });
    }
    setApiCall(true);
  setTimeout(()=>{
    setModalValue(false);
  },1000)
}
  };
  return (
    <div>
      
      <ModalContainer max_width={'80%'} loading={false}>
        <ModalDynamic
          loadingMaxWidth={'10rem'}
          {...fullBind}
          title={isCheckedId ? 'Edit Medication Name' : 'Add Medication Name'}
          max_width={'150%'}
          minWidthTitle="8.5rem"
          maginLeft={'100px'}
          loading={false}>
          <div>
          <Toast ref={toast} position="top-right" />
            <ComponentDynamic loading={false}>
            {/* <Toast ref={toast} position="top-right" /> */}
              <div className="d-flex mb-2">
                <div className="w-100 pe-1" style={{marginLeft:"7px"}}>
                  <InputLabelTop
                  required
                    label={'Medication Name'}
                    type="text"
                    id={`repeat`}
                    // onKeyPress={KeyPress.INTEGER}
                    {...useInput(`name`,'isRequired')}
                  />
                </div>

                <div className="w-100 pe-1">
                  <InputLabelTop
                    label={'Supplier Brand Name'}
                    type="text"
                    id={`repeat`}
                    // onKeyPress={KeyPress.INTEGER}
                    {...useInput('supplier_brand_name')}
                  />
                </div>
              </div>
              <div className="d-flex mb-2">
                <div className="w-100 pe-1" style={{marginLeft:"7px"}}>
                  <InputLabelTop
                    label={'Medication Class'}
                    type="text"
                    id={`repeat`}
                    // onKeyPress={KeyPress.INTEGER}
                    {...useInput('medication_class')}
                  />
                </div>

                <div className="w-100 pe-1">
                  <InputLabelTop
                    label={'Indication'}
                    type="text"
                    id={`repeat`}
                    // onKeyPress={KeyPress.INTEGER}
                    {...useInput('indication')}
                  />
                </div>
              </div>
             
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: '10px',
                  justifyItems: 'flex-end',
                  marginRight:"10px"
                }}>
                   {errorMessage ? (
            <ErrorMessage>
              <label className={'mt-2'}>{errorMessage}</label>
            </ErrorMessage>
          ) : (
            <Fragment />
          )}
                <PrimaryButton onClick={handleSubmit} style={{marginLeft:"7px"}}>Save</PrimaryButton>
              </div>
            </ComponentDynamic>
          </div>
        </ModalDynamic>
      </ModalContainer>
    </div>
  );
};

export default MedicationNameModal;
