import React from 'react';
import {InputLabelTopStyle} from 'shared/styles/inputs';

function InputLabelTop(props) {
  const isRequired = props.required;
  let haveError = props['data-error'];

  let labelError = <span />;

  if (props['data-error'] && !isRequired && !props.value) {
    haveError = false;
  }

  if (props['data-error'] && !isRequired && props.value) {
    labelError = (
      <label className={'errorMessageHaveValue'}>Invalid format</label>
    );
  }

  if (props['data-error'] && isRequired && !props.value) {
    labelError = (
      <label className={'errorMessage'}>
        {props.error_msg || 'Is required'}
      </label>
    );
  }

  if (props['data-error'] && isRequired && props.value) {
    labelError = (
      <label className={'errorMessageHaveValue'}>
        {props.error_msg || 'Invalid format'}
      </label>
    );
  }


  return (
    <InputLabelTopStyle
      no_internal_margin={props.no_internal_margin}
      have_error={haveError}
      type={props.type}
      typeFilter={props.typeFilter }>
      <input  
      placeholder={props.placeholder}
      style={props.invoice ? {padding: '0px 8px', height:'2rem', fontSize: `${props.fontSize ? props.fontSize : '1rem'}`, textAlign:'end'} : {}}
      readOnly={props.readOnly}
        onKeyPress={props.onKeyPress} 
          onChange={ props.onChange } 
          step={'0.01'}
          // min={'0'}
          // max={'100'}
          //added onchange
      value={ props.value} //added value
      name={props.name}  //added name
      type={props.type}
        id={props.id}
        disabled={props.disabled}
        className={props.value ||  props.value == 0 ? 'filled' : ''}
        {...props}
      />
      
      <label>
        {props.label} <span>{isRequired ? '*' : ''}</span>
      </label>

      {labelError}
    </InputLabelTopStyle>
  );
}
export default InputLabelTop;
