import ModalDynamic from 'components/DashboardComponents/ModalDynamic'
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable'
import { Toast } from 'primereact/toast'
import React from 'react'
import { useState } from 'react'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import { ModalContainer } from 'shared/styles/constants/tagsStyles'
import { addNewCompetencyMatrix } from 'utils/api/SettingsApi'
import { FLOPPY } from 'utils/constants/icons'
import { SAVE, SAVED } from 'utils/constants/messages';

function AddCompetencyMatrixModal({ 
  fullBind, 
  loadingModal = false, 
  choices, 
  values, 
  useInput, 
  dropDownHandleSelect ,
  handleSubmit,
  readOnly,
  loadingSave
}) {
console.log(readOnly, "add")
  
  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic {...fullBind} minWidthTitle="8.5rem" loading={loadingModal}>
        <div>

          <div className="d-flex flex-column mt-2">

            <div className='m-2'>
              <DropdownSearchable
                required
                isMulti={true}
                {...useInput(`competencyMatrix.branch`, 'isRequired')}
                placeHolder={'Branch'}
                options={choices.branch}
                disabled={readOnly}
                // {...useInput(`employeeJobDetailsBranchAccess.accessBranch`,'isRequired')}

                selectedOptionsProps={
                  values
                    ? values.competencyMatrix
                      ? values.competencyMatrix.branch
                      : null
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect(
                    { branch: value ? value : null },
                    'competency_branch'
                  )
                }
              />
            </div>

            <div className='m-2'>
              <DropdownSearchable
                required
                isMulti={true}
                {...useInput(`competencyMatrix.competency`, 'isRequired')}
                placeHolder={'Competency'}
                options={choices.competency_name}
                disabled={readOnly}
                // {...useInput(`employeeJobDetailsBranchAccess.accessBranch`,'isRequired')}

                selectedOptionsProps={
                  values
                    ? values.competencyMatrix
                      ? values.competencyMatrix.competency
                      : null
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect(
                    { competency: value ? value : null },
                    'competency'
                  )
                }
              />
            </div>

          </div>

          <div className={'d-flex justify-content-center justify-content-md-end mt-2'}>
            <div className={'ms-0 ms-md-3 me-2'}>
              <PrimaryButtonForm
               bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                disabled={readOnly}
                onClick={() => handleSubmit()}
                style={{ marginBottom: '5px' }}>
                <span className={'me-2'}>{SAVE}</span>
                {!loadingSave ? (
                  FLOPPY
                ) : (
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  />
                )}
              </PrimaryButtonForm>
            </div>
          </div>
        </div>
      </ModalDynamic>

    </ModalContainer>
  )
}

export default AddCompetencyMatrixModal