import React, {useState, useEffect, Fragment} from 'react';
import moment from 'moment';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {KeyPress} from 'utils/constants/regularExpressions';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import {useForm} from 'hooks/FormHook';
import {getBranchById, setCompanyBranch} from 'utils/api/SettingsApi';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  StylePointer,
} from 'shared/styles/constants/tagsStyles';
import {
  getTaskType,
  setTaskType,
  deleteTaskType,
  getCareCategory,
  setCareCategory,
  deleteCareCategory,
  setCareTask,
  deleteCareTask,
} from 'utils/api/SettingsApi';
import _ from 'lodash';
import {ERROR, SAVE, SAVED} from 'utils/constants/messages';
import Geocode from 'react-geocode';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {FaPlus} from 'react-icons/fa';
import {PURPLE} from 'shared/styles/constants/colors';
import {RiDeleteBinLine} from 'react-icons/ri';
import {IconWrapper} from 'components/SharedComponents/Table/styles';
const edit_branch = 'Edit branch';
const new_branch = 'Add branch';
const AddNewCare = ({
  fullBind,
  modalLoading,
  setModalLoading,
  choices,
  handleChangeChoices,
  loadDataOnCareTask,
  setAddNewCareTask,
  perffredId,loadTaskData
}) => {
  const {values, setValues, useInput, isValid, errors, setCheckRequires} =
    useForm({});

  const [loadingSave, setLoadingSave] = useState(false);
  //const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');

  const [addFullBind, setAddFullBind] = useState(fullBind);
console.log(perffredId,"YYYYY")
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Add Care Task',
    });
    setModalLoading(false);
  }, [fullBind]);

  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleSubmit = () => {
    if (!isValid && errors.length > 0) {
      setErrorMessage('Name required');
      setCheckRequires(true);
      return;
    }
    setLoadingSave(true);
    let body = {
      name: values.name,
      task_type: values.task_id,
      feedback: false,
      result_type: values.result_type,
      result_option: values.result_option ? values.result_option.join(',') : '',
    };
    setCareTask(body)
      .then(response => {
        loadDataOnCareTask(response.id);

        values.id = response.id;
        setValues({...values})
        // loadTaskData(response.id)

        handleChangeChoices('care_task');

        onSuccess(response);
      })
      .catch(onError);
  };

  const onSuccess = response => {
    // loadDataOnCareTask(perffredId);
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setAddNewCareTask(false);
  };

  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
  };

  const dropDownHandleSelect = item => {
    //recursive objects merge
    let result;
    if (item.option) {
      item.option = item.option;
    }

    result = _.merge(values, item);

    setValues({...result});
  };

  const addNewService = () => {
    // const haveNew = optionsArray.find(data => data.id === '');

    // if (haveNew) {
    // return;
    // }
    setValues({
      ...values,
      result_option: values.result_option
        ? [...values.result_option, '']
        : [''],
    });
    // setOptionsArray([...optionsArray, '']);
  };

  const handleInputChange = (e, attribute, index) => {
    const newOption = values.result_option ? [...values.result_option] : [];

    //newOption[e.target.dataset.idx][attribute] = e.target.value;
    newOption[index] = e.target.value;
    values.result_option = newOption;
    // setOptionsArray(...values.result_option)
    setValues({...values});
  };

  const preferredHandleSelect = (preferred, data) => {
    if (preferred.care_category) {
      values.care_category = preferred.care_category;
      setValues({...values});
    }
    if (preferred.task_id) {
      values.task_id = preferred.task_id;
      setValues({...values});
    }
  };
  const deleteRow = selIndex => {
    const deleteTemp = [];
    values.result_option.forEach((item, index) => {
      if (!(index === selIndex)) {
        deleteTemp.push(item);
      }
    });
    values.result_option = deleteTemp;
    setValues({...values});
  };

  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={modalLoading}>
        <div className="ps-3 pe-3 pb-3 pt-0">
          <FlexGrid3Container className={'w-100'}>
            {/* <FlexGrid3Item className="ps-0 pe-1 pb-1">
                <DropdownSearchable
                    marginLeft={'0.5%'}
                    placeHolder={'Care Category'}
                    options={choices.care_category}
                    selectedOption={values.care_category}
                    onChange={(value, name, data) =>
                        preferredHandleSelect(
                            { care_category: value.length ? value : [] }, data
                        )
  useEffect(()=>{
    getMedicationName()
  },[])
                    }
                />
            </FlexGrid3Item> */}
            <FlexGrid3Item className="ps-0 pe-1 pb-1">
              <DropdownSearchable
                // width={'33%'}
                marginLeft={'0.5%'}
                placeHolder={'Task Type'}
                selectedOption={values.task_id}
                options={choices && choices?.task_type && choices?.task_type}
                onChange={(value, name, data) =>
                  preferredHandleSelect(
                    {task_id: value.length ? value : []},
                    data
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-0 pe-1 pb-1">
              <InputLabelTop
                required
                type="text"
                id="name"
                label={'Name'}
                {...useInput('name', 'isRequired')}
                maxlength={50}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
          <FlexGrid3Container>
            <FlexGrid3Item className="ps-0 pe-1 pb-1">
              <DropdownSearchable
                placeHolder={'Result Type'}
                options={[
                  {id: 'none', name: 'None'},
                  {id: 'text', name: 'Text'},
                  {id: 'option', name: 'Option'},
                ]}
                selectedOption={values.result_type}
                required={true}
                onChange={value =>
                  dropDownHandleSelect({
                    result_type: value ? value : null,
                  })
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item>
              {values.result_type === 'option' && (
                <StylePointer onClick={addNewService} className="pt-1 ps-1">
                  <FaPlus color={PURPLE} size={26} />
                </StylePointer>
              )}
            </FlexGrid3Item>
          </FlexGrid3Container>
          <FlexGrid3Container>
            {values.result_option &&
              values.result_option.map((item, index) => {
                return (
                  <FlexGrid3Item className="ps-0 ps-md-1 pe-1">
                    <div className="d-flex">
                      <InputLabelTop
                        type="text"
                        id=""
                        value={item ? item : ''}
                        label={`Result option ${index + 1}`}
                        onChange={e => {
                          handleInputChange(e, 'result_option', index);
                        }}
                        maxlength={30}
                      />
                      <div className="pt-1 ps-1">
                        <IconWrapper
                          onClick={() => {
                            deleteRow(index);
                          }}>
                          <RiDeleteBinLine />
                        </IconWrapper>
                      </div>
                    </div>
                  </FlexGrid3Item>
                );
              })}
          </FlexGrid3Container>

          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ms-0 ms-md-3 me-2'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default AddNewCare;
