import React, { Fragment, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Link, useHistory } from 'react-router-dom';
import { AUTH, DASHBOARDS, FORGOT_PASSWORD, MULTIFACTOR_AUTH } from 'utils/constants/routes';
import { ErrorMessageShake } from 'shared/styles/AuthComponents';
import { PrimaryButton } from 'shared/styles/buttons';
import { useInput } from 'hooks/InputHook';
import { useLocation } from 'react-router-dom'
import { login, resendOTPApi, VerifyOTPApi } from 'utils/api/SessionApi';
import logo from 'images/logoOutBgd.png';
import {
  ImgWrapper,
  ColumnSwap,
  LoginPageContent,
  SmallText,
  Horizontal,
  UpperCaseText,
  InputOTP,
  ResendOTP,
  ContainerOTP,
  InputOTPBox,
  ResendOTP30,
} from './styles';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { DayHeader } from 'fullcalendar';
import { setToken } from 'utils/localStorage/token';

const OTPVerification = () => {
  const toast = useRef(null)

  const [showErrorMessage, setShowErrorMessage] = useState({
    status: false,
    message: '',
  });

  const [loadingButton, setLoadingButton] = useState(false);

  const [OTP, setOTP] = useState(new Array(6).fill(""));

  const [resendAvailable, setResendAvailable] = useState(false);
  const [countdown, setCountdown] = useState(30);

  const handleOTPChange = (element, index) => {
    if (isNaN(element.value)) return;

    let newOTP = [...OTP];
    newOTP[index] = element.value;
    setOTP(newOTP);

    // Focus the next input field
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }

    // Focus the previous input field
    if (element.value === '' && element.previousSibling) {
      element.previousSibling.focus();
    }
  };

  const history = useHistory();

  const location = useLocation();
  const { token, user } = location.state.state || {};

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      if (loadingButton) return;

      setLoadingButton(true);

      const response = await VerifyOTPApi(user, OTP?.join(''), token);
      // console.log("VerifyOTPApi response : ", response)

      setLoadingButton(false);

      if (response.message === "OTP verified successfully" || response.status === true) {
        setToken(token)
        history.push(DASHBOARDS)
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Verification Failed',
          detail: response.message ? response.message : '',
        });
      }

    } catch (err) {
      setLoadingButton(false);

      toast.current.show({
        severity: 'error',
        summary: 'Verification Failed',
        detail: 'Unable to verify OTP',
      });
    }
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer); // Cleanup the timer
    } else {
      setResendAvailable(true); // Enable "Resend OTP" button after 30 seconds
    }
  }, [countdown]);

  const handleResendOTP = async () => {
    // Reset the countdown and disable the button again
    const response = await resendOTPApi(user, token)
    // console.log("resendOTPApi response : ", response)

    if (response.message === "OTP send successfully" || response.status === true) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: response.message ? response.message : 'OTP sent successfully',
      });
    } else {
      toast.current.show({
        severity: 'error',
        summary: '',
        detail: 'Failed to resend OTP',
      });
    }

    setCountdown(30);
    setResendAvailable(false);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !OTP[index] && e.target.previousSibling) {
      e.target.previousSibling.focus();
    }
  };

  const date = new Date();

  let year = date.getFullYear();

  return (
    <>
      <Toast ref={toast} />
      <LoginPageContent>
        <div className={'container-fluid bg-white'}>
          <div className={'container'}>
            <div className={'row  '}>
              <div className={'col-sm-6 col-lg-4 col-md-4 mt-3  mb-3  '}>
                <Fade>
                  <ImgWrapper>
                    <Link
                      to={AUTH}
                    >
                      <img src={logo} alt="logo-img" />
                    </Link>
                  </ImgWrapper>
                </Fade>
              </div>
            </div>

            <ColumnSwap>
              <div className={'row  bdy'}>
                <div className={'col-sm-12 col-md-6 col-lg-6  pt-5  mt-5   '}>
                  <div className={'pt-4'}>
                    <div className={'mt-1 '}>
                      {' '}
                      <Link
                        to={AUTH}
                        className={
                          '  h5  text-muted  font-weight-normal  text-decoration-none  mt-2  '
                        }>
                        {' '}
                        How to use MyHelpa{' '}
                      </Link>{' '}
                    </div>{' '}
                    <Horizontal />
                    <div className={'mt-1 '}>
                      {' '}
                      <Link
                        to={AUTH}
                        className={
                          '  h5  text-muted  font-weight-normal  text-decoration-none  mt-2    '
                        }>
                        {' '}
                        Customer support: hello@myhelpa.com{' '}
                      </Link>{' '}
                    </div>{' '}
                    <Horizontal />
                    <div className={' mt-1 '}>
                      {' '}
                      <Link
                        to={AUTH}
                        className={
                          '  h5  text-muted  font-weight-normal  text-decoration-none  mt-2     '
                        }>
                        {' '}
                        We would love your feedback
                      </Link>{' '}
                    </div>{' '}
                    <Horizontal />
                  </div>
                  <div className={'secondpara mt-5 pt-4 '}>
                    <div className={' mt-5 '}>
                      {' '}
                      <Link
                        to={AUTH}
                        className={
                          '  h5  text-muted  font-weight-normal  text-decoration-none  mt-2   '
                        }>
                        {' '}
                        Want to join MyHelpa? Get in touch
                      </Link>{' '}
                    </div>{' '}
                    <Horizontal />
                  </div>
                </div>

                <div className={'col-sm-12 col-md-6 col-lg-6    '}>
                  {/* <FormContent> */}
                  <div className={'mb-3 mt-3'}>
                    <UpperCaseText>Verify OTP</UpperCaseText>
                    <SmallText>
                      <div className={'text-black-50 '}>
                        Please enter the OTP sent on your registered email id
                      </div>
                    </SmallText>
                  </div>
                  <form onSubmit={handleSubmit} className={' pt-2 '} noValidate>
                    <div>
                      {OTP.map((data, index) => (
                        <InputOTPBox
                          key={index}
                          type="text"
                          maxLength="1"
                          value={data}
                          onChange={(e) => handleOTPChange(e.target, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          onFocus={(e) => e.target.select()}
                        />
                      ))}
                    </div>

                    <div className="mt-4">
                      {resendAvailable ? (
                        <ResendOTP onClick={handleResendOTP}>Resend OTP</ResendOTP>
                      ) : (
                        <ResendOTP30 disabled>
                          Resend OTP in {countdown} seconds
                        </ResendOTP30>
                      )}
                    </div>

                    <div>
                      {showErrorMessage.status ? (
                        <ErrorMessageShake className=" mt-3">
                          {showErrorMessage.message}
                        </ErrorMessageShake>
                      ) : (
                        <Fragment />
                      )}
                    </div>
                    <div
                      className={`${showErrorMessage.status ? 'mt-3' : 'mt-4'
                        } d-flex justify-content-md-start justify-content-center`}>
                      <PrimaryButton
                        type="submit"
                        shadow={true}
                        disabled={loadingButton ? true : false}>
                        {loadingButton ? (
                          <Fragment>
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                            />
                            <span>Loading...</span>
                          </Fragment>
                        ) : (
                          <span className={'mt-4 mb-5 font-weight-bold'}>
                            VERIFY
                          </span>
                        )}
                      </PrimaryButton>
                    </div>
                  </form>

                  {/* </FormContent> */}
                </div>
              </div>
            </ColumnSwap>

            <div className={'row d-flex justify-content-start  mb-2 bg-white'}>
              <div className={'col-sm-12 col-lg-5 col-md-5   mb-2  pt-5  '}>
                <p className={'font-weight-light   '}>
                  {' '}
                  Copyright@ {year} MyHelpa Limited Terms of Business{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </LoginPageContent>
    </>
  );
};

export default OTPVerification;
