
import React, { useState, useEffect, useContext } from 'react';
import { LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';
import { headerColumns, headerColumnsSelector, headerColumnsSelectorByDefault } from './TableHeaders/BillingCompareHeaders';
import { PrimaryButton, PrimaryButtonForm } from 'shared/styles/buttons';
import DateSelector from 'components/SharedComponents/DateSelector';
import { useForm } from 'hooks/FormHook';
import { getBillingCompare, billCompareExport, billReturnExport } from 'utils/api/finance';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {
  HeaderVisits,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
} from 'shared/styles/constants/tagsStyles';
import { AppContext, BranchItemsContext } from 'context';
import Table from 'components/SharedComponents/Table';
import { Row, Col } from 'components/SharedComponents/Table/styles';
import { useHistory, useLocation } from 'react-router-dom';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { data } from 'jquery';
import { getClientFundersList } from 'utils/api/ClientApi';
import { bill_compare_choices } from 'utils/choiceConstant';
import NoSelectAllDropdownColumnSelector from 'components/SharedComponents/NoSelectAllDropdownColumnSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import moment from 'moment';
import { useMemo } from 'react';
import { EX_LARGE_COL } from 'shared/styles/constants/columns';

const BillingCompare = props => {
  const [compareData, setCompareData] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientStatusFilter, setClientStatusFilter] = useState([]);
  const [funderSourceFilter, setFunderSourceFilter] = useState([]);
  const [visitStatusFilter, setVisitStatusFilter] = useState([]);
  const [clientFilter, setClientFilter] = useState([])
  const [serviceCategoryFilter, setServiceCategoryFilter] = useState([]);
  const [funderNameFilter, setFunderNameFilter] = useState([]);
  const [funderCategoryFilter, setFunderCategoryFilter] = useState([])

  const { values, setValues, useInput, errors, isValid, setCheckRequires } =
    useForm({});
  const [loadingModal, setLoadingModal] = useState(false);
  const { contextChoices: choices,handleGetChoices, } = useContext(AppContext);
  const [funderList,setFunderList]=useState([])
  const makeHeaderColumns=useMemo(()=>{
    const contantHeader=[   
      {
        label: 'Branch Name',
        name: 'branch_name',
        status: 0,
        // custum_column: true,
        // id: 'branch_name',
        // columnSelected: true,
        className: 'grow-mobile',
        max_width: LARGE_COL,
      },
      {
          label: 'Funder Name',
          name: 'funder_name',
          status: 0,
          // custum_column: true,
          // id: 'funder_name',
          // columnSelected: true,
          className: 'grow-mobile',
          max_width: LARGE_COL,
        },
        {
          label: 'Funder Category',
          name: 'category_name',
          status: 0,
          // custum_column: true,
          // id: 'category_name',
          // columnSelected: true,
          className: 'grow-mobile',
          max_width: LARGE_COL,
        },
      {
          label: 'Client Name',
          name: 'client_name',
          status: 0,
          // custum_column: true,
          // id: 'client_name',
          // columnSelected: true,
          className: 'grow-mobile',
          max_width: LARGE_COL,
        },
        {
          label: 'Keys',
          name: 'key',
          status: 0,
          // custum_column: true,
          // id: 'key',
          // columnSelected: true,
          className: 'grow-mobile',
          max_width: LARGE_COL,
        }
      ]
           let dateDiff=moment(values.date_to).diff(moment(values.date_from),'days')+ 1;
           let tempArr=[]
           for(let i=0;i<dateDiff;i++){
               let column_name=moment(values.date_from).add(i,'days').format('YYYY-MM-DD')
             tempArr.push({
               label: column_name,
               name: 'date_colmn',
              //  custum_column: true,
              //  id: column_name,
              //  columnSelected: true,
               status: 0,
               className: 'grow-mobile',
               max_width: EX_LARGE_COL, 
               })
           }
           let final_header=[...contantHeader,...tempArr]
          return final_header
   },[compareData])
  const headerSelectedData = makeHeaderColumns.map((val)=>(val.columnSelected && val.id))
  const [runTableOptionData, setrunTableOptionData] = useState(headerSelectedData);
  const [dropdownArraySelected, setDropdownArraySelected] = useState(makeHeaderColumns.filter((val)=>val.columnSelected));
  const [clientOptions, setClientOptions] = useState([])
  const [branchFilter, setBranchFilter] = useState([]);
  const history = useHistory();
  const { branchData } = useContext(BranchItemsContext);

  useEffect(()=>{
    setrunTableOptionData(headerSelectedData)
    setDropdownArraySelected(makeHeaderColumns.filter((val)=>val.columnSelected))
  },[makeHeaderColumns])

  useEffect(() => {
    if (values.date_from && values.date_to) {
      handleClick()
    }
  }, [branchData?.headerbranch?.length,
    values.limitperpage,
    props.location.search,
    values.ordering
  ]);

  useEffect(()=>{
    const urlParams = queryString.parse(props.location.search);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: '/finance/billing/compare',
        search: stringified,
      });
  },[])
  useEffect(() => {
    if (choices.client_listing) {
      let temp = choices.client_listing.map(client => {
        return { id: client.id, name: client.full_name };
      })
      setClientOptions(temp);
    }
  }, [choices.client_listing])
  useEffect(() => {
    handleGetChoices(bill_compare_choices)
    
  }, [])
  
  const funderLists=()=>{
    getClientFundersList(values)
    .then(res=>{
      if(res.data && (res.data.length > 0)){
       let newFunders=res.data.map(funder=>{
         return {
           id:funder.id,
           is_client: funder?.is_client,
           name:funder?.name
         }
       })
       setFunderList([...newFunders])
      }
    })
  }

  useEffect(()=>{
    if(values.date_from && values.date_to){
      funderLists();
    }
  },[values.date_from, values.date_to])

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values });
  }, []);

  


  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/finance/billing/compare',
      search: stringified,
    });
    values.limitperpage = item[namesection];
    // setValues({...values});
    setValues({ ...values, ...item });
  };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =  offset * values.limitperpage; return {
      offset,
    };
  };
  const handleClick = () => {

    const pageToSearch = getPageToSearchParam(props.location.search);
    if (!values.date_from || !values.date_to || !values.kpi) {
      return;
    } else {
      setLoadingModal(true)
      getBillingCompare({ ...values, ...pageToSearch,...branchData }).then(res => {

        if (res) {
          setLoadingModal(false);
        }
        let data = [];
           let dateDiff= moment(values.date_to).diff(moment(values.date_from),'days')+1;
           res.data.forEach(element => {
            data.push({ ...element, key: 'schedule_duration', schedule_duration: element?.date_data?.schedule_duration });
            // for(let i=0;i<dateDiff;i++){
            //   let column_name =moment(values.date_from).add(i,'days').format('YYYY-MM-DD')
            // let act_dur = {}
            // }
            data.push({ ...element,subRow: true,  });
            data.push({ ...element,subRow: true, key: 'actual_duration', actual_duration: element?.date_data?.actual_duration });
            data.push({ ...element,subRow: true, key: 'duration_diff', duration_diff: element?.date_data?.duration_diff });
            data.push({ ...element,subRow: true, key: 'schedule_amount', schedule_amount: element?.date_data?.schedule_amount });
            data.push({ ...element,subRow: true, key: 'actual_amount', actual_amount: element?.date_data?.actual_amount });
            data.push({ ...element,subRow: true, key: 'amount_diff', amount_diff: element?.date_data?.amount_diff });
        });
        setCompareData(data || []);
        setPages(Math.ceil(res.count / values.limitperpage));

      });
    }
  };

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const handleExport = () => {
    setLoadingModal(true)
    const pageToSearch = getPageToSearchParam(props.location.search);
    billReturnExport({ ...values, ...pageToSearch,...branchData }, {column_selector: runTableOptionData}).then(response => {
      setLoadingModal(false)
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `bill_compare_${values.date_from}_${values.date_to}`;
        a.click();
      });
    });
  };
  const dropDownHandleSelect = item => {
    if (item.client_service_visit__client__id__in) {
      item.client_service_visit__client__id__in = item.client_service_visit__client__id__in.length === (clientOptions ? clientOptions : 0).length || item.client_service_visit__client__id__in.length === 0 ? [] : item.client_service_visit__client__id__in
    }
    setValues({ ...values, ...item });
  };

  const dropDownHandleSelectTable = item => {
    const data = [];
    if (item.tableDropdown) {
      item && item.tableDropdown.map((item) => {
        data.push(item.id);
      })
    }
    setrunTableOptionData(data)
  };

  const getDynamicColumnsData=(det,index)=>{
    let dateDiff= moment(values.date_to).diff(moment(values.date_from),'days')+1;
        let tempArr=[]
        for(let i=0;i<dateDiff;i++){
            let column_name=moment(values.date_from).add(i,'days').format('YYYY-MM-DD')
            tempArr.push(
                <Col
                max_width={EX_LARGE_COL}
                //className={!(runTableOptionData && runTableOptionData.includes(`${column_name}`)) && 'd-none'}
                >
                  {compareData[index]?.date_data && compareData[index]?.date_data[`${column_name}`] && compareData[index]?.date_data[`${column_name}`][compareData[index]?.key]?.toFixed(2)}
                </Col>
            )
        }
        // det.date_data.forEach((item)=>{
        //   tempArr.push(
        //             <Col
        //             max_width={LARGE_COL}
        //             >
        //             {compareData.length>0 && compareData[index][`Start Date ${column_name}`]?
        //             compareData[index][`Start Date ${column_name}`]:''
        //         }
        //             </Col>
        //         )
        // })
        return tempArr
}


  return (
    <div>
      <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
          <FiltersContainer>
            <div
              className={'ms-1 me-3 me-md-0 flex-wrap d-flex justify-content-center'}>
              <FiltersLeftGroup
                className={'d-flex mb-2'} style={{ flexWrap: 'wrap', gap: "0.5rem" }}>
                <DropdownTypesContainer>
                  <DateSelector
                    isRequired
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('date_from')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <DateSelector
                    isRequired
                  disabled={values.date_from?false:true}
                  typeFilter={true}
                  labelTop="Date to"
                  minDate={values.date_from}
                  maxDate={moment(values.date_from).add(7,"days").format("YYYY-MM-DD")}
                  {...useInput('date_to')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <DropdownSearchable
                    required
                    typeFilter
                    placeHolder={'Frequency'}
                    options={choices.kpi || []}
                    selectedOption={values.kpi}
                    onChange={value =>
                      dropDownHandleSelect({ kpi: value ? value : null })
                    }
                  />
                </DropdownTypesContainer>
                {/* <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.client_status ? choices.client_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client_status: items });
                    }}
                    itemsSelected={clientStatusFilter}
                    setSelectedFilter={items =>
                      setClientStatusFilter([...items])
                    }
                    placeHolder={'Client Status'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer> */}
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={clientOptions ? clientOptions : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client_service_visit__client__id__in: items });
                    }}
                    itemsSelected={clientFilter}
                    setSelectedFilter={(items) => setClientFilter([...items])}
                    placeHolder={'Clients'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                {/* <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.funder_source ? choices.funder_source : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ funder_source: items });
                    }}
                    itemsSelected={funderSourceFilter}
                    setSelectedFilter={items =>
                      setFunderSourceFilter([...items])
                    }
                    placeHolder={'Funder Source'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer> */}
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={funderList ? funderList : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ funder_name: items });
                    }}
                    itemsSelected={funderNameFilter}
                    setSelectedFilter={items => setFunderNameFilter([...items])}
                    placeHolder={'Funder Name'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.funder_source || []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client_service_visit__client_service__service_funders__category__id__in: items });
                    }}
                    itemsSelected={funderCategoryFilter}
                    setSelectedFilter={(items) => setFunderCategoryFilter([...items])}
                    placeHolder={'Funder Category'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                      items={choices.branch || []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ employee__job_detail__branch__id: items });
                      }}
                      itemsSelected={branchFilter}
                      setSelectedFilter={items =>
                        setBranchFilter([...items])
                      }
                      placeHolder={'Branch'}
                      isObject
                      allOption
                    />
                    </DropdownTypesContainer>
                {/* <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.visit_status ? choices.visit_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visit_status: items });
                    }}
                    itemsSelected={visitStatusFilter}
                    setSelectedFilter={items =>
                      setVisitStatusFilter([...items])
                    }
                    placeHolder={'Visit Status'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={
                      choices.service_category ? choices.service_category : []
                    }
                    setItemsSelected={items => {
                      dropDownHandleSelect({ service_category__id__in: items });
                    }}
                    itemsSelected={serviceCategoryFilter}
                    setSelectedFilter={items =>
                      setServiceCategoryFilter([...items])
                    }
                    placeHolder={'Service Category'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer> */}
                <PrimaryButtonForm  onClick={handleClick} disabled={loadingModal}>
                 <span className={'ms-2 me-2 font-weight-bold'}>Search</span>
                  {!loadingModal ? (
                            null
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                            />
                          )}
                </PrimaryButtonForm>
                {compareData.length > 0 && (
                  <PrimaryButton
                    type="button"
                    className="ms-2"
                    onClick={handleExport}>
                    <span className={'ms-2 me-2 font-weight-bold ms-3'}>
                      Export
                    </span>
                  </PrimaryButton>
                )}
              </FiltersLeftGroup>
            </div>
          </FiltersContainer>
        </div>
      </HeaderVisits>
      <ComponentDynamic loading={loadingModal}>
         {/* <DropdownTypesContainer>
         <NoSelectAllDropdownColumnSelector
             items={makeHeaderColumns ? makeHeaderColumns.filter((item)=>!item.removeCusCol) : []}
              setItemsSelected={items => {
                dropDownHandleSelectTable({ tableDropdown: items });
              }}
              itemsSelected={dropdownArraySelected}
              setSelectedFilter={(item) => setDropdownArraySelected([...item])}
              placeHolder={'Column'}
              isObject
              allOption

            />
          </DropdownTypesContainer> */}
        {compareData.length > 0 && (
          <>
<div style={{overflowX: 'scroll', width:'100%'}}>
            <div style={{width:'max-content', minWidth:'-webkit-fill-available'}}>
            <Table
              headerColumns={makeHeaderColumns}
              headerPadding={true}
              noEditOption={true}
              noNeed={false}
              fontSize="0.75rem"
              // values={runTableOptionData}
              callBackQuerySort={setQuerySort}>

              {compareData.map((comp, index) => {
                return (
                  <Row bgColor Shadow NoShadow={comp.subRow}>
                    <Col
                    // className={!(runTableOptionData && runTableOptionData.includes("branch_name")) && 'd-none'}
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      Center
                      overlap={true}
                      >
                      {!comp.subRow && comp.branch_name}
                    </Col>
                    <Col
                    // className={!(runTableOptionData && runTableOptionData.includes("funder_name")) && 'd-none'}
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      overlap={true}
                      Center>
                      {!comp.subRow && comp.funder_name}
                    </Col>
                    <Col
                    // className={!(runTableOptionData && runTableOptionData.includes("category_name")) && 'd-none'}
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      overlap={true}
                      Capitalize
                      Center>
                      {!comp.subRow && comp.category_name}
                    </Col>
                    <Col
                    // className={!(runTableOptionData && runTableOptionData.includes("client_name")) && 'd-none'}
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      overlap={true}
                      Center>
                      {!comp.subRow && comp.client_name}
                    </Col>
                    <Col
                    // className={!(runTableOptionData && runTableOptionData.includes("key")) && 'd-none'}
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      Center>
                      {comp.key}
                    </Col>
                    {getDynamicColumnsData(comp,index)}

                  </Row>
                );
              })}
              <div className="mt-3">
                <Pagination
                  totalPages={pages}
                  currentPage={currentPage}
                  values={values}
                  dropDownHandleSelect={dropDownHandleSelectPagination}
                  number={PAGE_SIZE_RENDER}
                  dataLength={compareData.length}
                />
              </div>

            </Table>
</div>
</div>
          </>
        )}
      </ComponentDynamic>
    </div>
  );
};

export default BillingCompare;

