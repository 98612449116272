import Table from 'components/SharedComponents/Table'
import { Col, ColsGrouper, Row } from 'components/SharedComponents/Table/styles'
import React, { useContext } from 'react'
import { myheaderColumns } from './HeaderColumns'
import { LARGE_COL } from 'shared/styles/constants/columns'
import { DropdownTypesContainer, FiltersContainer, Header, PublishButton } from 'shared/styles/constants/tagsStyles'
import DateSelector from 'components/SharedComponents/DateSelector'
import { useForm } from 'hooks/FormHook'
import moment from 'moment'
import { EmploymentTypesContainer } from '../styles'
import { CloseButtonWrapper } from '../AddReferences/styles'
import { DOWNLOAD, TIMES } from 'utils/constants/icons';
import { useEffect } from 'react'
import { exportEmployeeAverageHours, getEmployeeAverageHours } from 'utils/api/EmployeeApi'
import { useState } from 'react'
import { AppContext, BranchItemsContext } from 'context'
import { employee_average_hours_choices } from 'utils/choiceConstant'
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic'
import { useRef } from 'react'
import { Toast } from 'primereact/toast'
import DataFromatDropdown from './DataFormatDropdown'
import { PrimaryButton } from 'shared/styles/buttons'
import "./styles.css"
import NoSelectAllDropdownColumnSelector from 'components/SharedComponents/NoSelectAllDropdownColumnSelector';

function AverageHours({ selectedEmpId }) {
    const toast = useRef();
    const currentDate = moment().format('YYYY-MM-DD');
    const oneWeekAgo = moment().subtract(1, 'week').format('YYYY-MM-DD');
    // const threeMonthsAgo = moment().subtract(90, 'days').format('YYYY-MM-DD');
    // const oneYearAgo = moment().subtract(1, 'year').format('YYYY-MM-DD');
    // const fiveYearsAgo = moment().subtract(5, 'years').format('YYYY-MM-DD');
    const {branchData} = useContext(BranchItemsContext);
    const { contextChoices, handleGetChoices } = useContext(AppContext)
    const { values, setValues, useInput } = useForm({ start_date: oneWeekAgo, end_date: currentDate, data_format: 'day' });
    const [headerColumns, setHeaderColumns] = useState(myheaderColumns)
    const [topHeader, setTopHeader] = useState([])
    const headerSelectedData = headerColumns?.map((val) => (val.columnSelected && val.id))
    const [averageHoursOptionData, setAverageHoursOptionData] = useState(headerSelectedData);
    const [dropdownArraySelected, setDropdownArraySelected] = useState(headerColumns.filter((val) => val.columnSelected));

    const [loadingModal, setLoadingModal] = useState(true)
    const [averageHoursList, setAverageHoursList] = useState([])
    const [downloadDisable, setDownloadDisable] = useState(false)
    const [headers, setHeaders] = useState()

    const roundToTwoDecimalPlaces = value => (
        (value && value.toString().includes(".")) ? Number(value.toFixed(2)) : value
    );


    useEffect(() => {
        handleGetChoices(employee_average_hours_choices)
    }, [])

    const customizeTable = (attr) => {
        if (attr?.visit_hours_scheduled && attr?.scheduleHrsVisitStatus?.length) {

        }
    }
    const genarateIdByAttribute = async (data) => {
        const keys = [];

        if (data['office_hours_actuals']) {
            keys.push('actual_office_hrs');
        }
        if (data['wait_hours_scheduled']) {
            keys.push('wait_hours_scheduled');
        }
        if (data['include_cancelled']) {
            keys.push('include_cancelled');
        }
        //Office
        const officeHours = [
            'office_hours_actuals',
            'office_hours_banded_actuals',
            'office_hours_rounded_actuals',
            'office_hours_scheduled',
            'on_call_hours_actuals',
        ];

        officeHours.forEach(type => {
            if (data[type]) {
                keys.push(`${type}`);
            }
        });
        //Visit
        const visitTypes = [
            'visit_hours_actuals',
            'visit_hours_banded_actuals',
            'visit_hours_rounded_actuals',
            'visit_hours_scheduled',
        ];

        visitTypes.forEach(type => {
            if (data[type] && data.scheduleHrsVisitStatus.length) {
                data.scheduleHrsVisitStatus.forEach(item => {
                    keys.push(`${type}_${item}`);
                });
            }
        });

        // Travel
        const travelTypes = ['travel_hours_actuals', 'travel_hours_scheduled'];

        travelTypes.forEach(type => {
            if (data[type] && data.travelHrsVisitStatus.length) {
                data.travelHrsVisitStatus.forEach(item => {
                    keys.push(`${type}_${item}`);
                });
            }
        });

        return keys.map(item => item.split(' ').join('_').toLowerCase());
    };



    const getEmployeeAverageHoursData = async () => {
        try {
            const res = await getEmployeeAverageHours(selectedEmpId, { ...values, ...branchData})
            if (res?.status) {
                setAverageHoursList(res.data)
                const result = await genarateIdByAttribute(res?.attributes);
                let defaultVisible = headerColumns?.map(h => ({ ...h, columnSelected: result?.includes(h.id) ? true : h.columnSelected }))
                setHeaderColumns(defaultVisible)
                setHeaders(res.headers)
                setLoadingModal(false)
                updateWeeklyData(defaultVisible)

            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: `${res.message}`,
                });
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleSearch()
    }, [branchData])

    const dropDownHandleSelect = item => {
        setValues({ ...values, ...item });
    }


    const threeMonthsAgoDate = (end_date) => {
        const date = moment(end_date).subtract(90, 'days').format('YYYY-MM-DD');
        return date
    }
    const oneYearAgo = (end_date) => {
        const date = moment(end_date).subtract(1, 'year').format('YYYY-MM-DD');
        return date
    }

    const fiveYearsAgo = (end_date) => {
        const date = moment(end_date).subtract(5, 'years').format('YYYY-MM-DD');
        return date
    }


    const validateApiCall = (format) => {
        switch (format) {
            case 'day':
                if (new Date(values?.end_date) >= new Date(values?.start_date)) {
                    if ((new Date(values?.start_date) >= new Date(threeMonthsAgoDate(values?.end_date)))) {
                        return { isCall: true }
                    } else {
                        return {
                            isCall: false,
                            message: `Start date should be less than 90 days!`
                        }
                    }
                } else {
                    return {
                        isCall: false,
                        message: `Start date should be greater than end date!`
                    }
                }

            case 'week':
                if (new Date(values?.end_date) >= new Date(values?.start_date)) {
                    if ((new Date(values?.start_date) >= new Date(oneYearAgo(values?.end_date)))) {
                        return { isCall: true }
                    } else {
                        return {
                            isCall: false,
                            message: `Start date should be less than 1 year!`
                        }
                    }
                } else {
                    return {
                        isCall: false,
                        message: `Start date should be greater than end date!`
                    }
                }
            case 'month':
                if (new Date(values?.end_date) >= new Date(values?.start_date)) {
                    if ((new Date(values?.start_date) >= new Date(fiveYearsAgo(values?.end_date)))) {
                        return { isCall: true }
                    } else {
                        return {
                            isCall: false,
                            message: `Start date should be less than 5 years!`
                        }
                    }
                } else {
                    return {
                        isCall: false,
                        message: `Start date should be greater than end date!`
                    }
                }
            default:
                break;
        }
    }


    const handleSearch = () => {
        if (values?.start_date && values?.end_date && values?.data_format) {
            let validate = validateApiCall(values?.data_format)
            if (validate?.isCall) {
                getEmployeeAverageHoursData()
            }
            else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: validate?.message,
                });
            }
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Please apply date range and format filters for accurate results.`,
            });
        }
    }
    let startDateInput = useInput('start_date')
    let endDateInput = useInput('end_date')

    const handleDownload = () => {
        let fileName = 'Average_hours_' + values?.start_date + '_' + values?.end_date;
        setDownloadDisable(true);
        exportEmployeeAverageHours(selectedEmpId, { ...values ,...branchData})
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    a.click();
                    setDownloadDisable(false);
                });
            })
            .catch(error => {
                console.error(error)
                setDownloadDisable(false);
            });
    };

    const dropDownHandleSelectTable = item => {
        const data = [];
        if (item.tableDropdown) {
            item && item.tableDropdown.map((item) => {
                data.push(item.id);
            })
        }
        setAverageHoursOptionData(data)

    };

    useEffect(() => {
        var tempHeader = [
            {
                label: 'Working Day',
                value: headers?.workingDay,
                id: 'working_day'
            },
            {
                label: 'Actual Office Hrs',
                value: headers?.actualOfficeHrs,
                id: 'office_hours_actuals'
            },
            {
                label: 'Schedule Office Travel Hrs',
                value: headers?.scheduleOfficeTravelHrs,
                id: "schedule_office_travel_hrs"
            },
            {
                label: 'Actual On Call Hrs',
                value: headers?.actualOncallHrs,
                id: "actual_on_call_hrs"
            },
            {
                label: 'Actual Visit Hrs',
                value: headers?.actualVisitHrs,
                id: "actual_visit_hrs"
            },
            {
                label: 'Schedule Office Hrs',
                value: headers?.scheduleOfficeHrs,
                id: "office_hours_scheduled"
            },
            {
                label: 'Schedule On Call Hrs',
                value: headers?.scheduleOncallHrs,
                id: "schedule_on_call_hrs"
            },

            {
                label: 'Visit Scheduled – Missed',
                value: headers?.scheduleVisitMissed,
                id: "visit_hours_scheduled_missed"
            },
            {
                label: 'Visit Scheduled – NotStarted',
                value: headers?.scheduleVisitNotStarted,
                id: "visit_hours_scheduled_not_started"
            },
            {
                label: 'Visit Scheduled – InProgress',
                value: headers?.scheduleVisitInProgress,
                id: "visit_hours_scheduled_in_progress"
            },
            {
                label: 'Visit Scheduled – Completed',
                value: headers?.scheduleVisitCompleted,
                id: "visit_hours_scheduled_completed"
            },
            {
                label: 'Visit Scheduled – Cancelled',
                value: headers?.scheduleVisitCancelled,
                id: "visit_hours_scheduled_cancelled"
            },

            {
                label: 'Visit Travel Scheduled – Missed',
                value: headers?.scheduleMissedVisitTravelHrs,
                id: "travel_hours_scheduled_missed"
            },
            {
                label: 'Visit Travel Scheduled – NotStarted',
                value: headers?.scheduleNotStartedVisitTravelHrs,
                id: "travel_hours_scheduled_not_started"
            },
            {
                label: 'Visit Travel Scheduled – InProgress',
                value: headers?.scheduleInProgressVisitTravelHrs,
                id: "travel_hours_scheduled_in_progress"
            },
            {
                label: 'Visit Travel Scheduled – Completed',
                value: headers?.scheduleCompletedVisitTravelHrs,
                id: "travel_hours_scheduled_completed"
            },
            {
                label: 'Visit Travel Scheduled – Cancelled',
                value: headers?.scheduleCancelledVisitTravelHrs,
                id: "travel_hours_scheduled_cancelled"
            },

            {
                label: 'Visit Travel Actual – Missed',
                value: headers?.actualMissedVisitTravel,
                id: "travel_hours_actuals_missed"
            },
            {
                label: 'Visit Travel Actual – NotStarted',
                value: headers?.actualNotStartedVisitTravel,
                id: "travel_hours_actuals_not_started"
            },
            {
                label: 'Visit Travel Actual – InProgress',
                value: headers?.actualInProgressVisitTravel,
                id: "travel_hours_actuals_in_progress"
            },
            {
                label: 'Visit Travel Actual – Completed',
                value: headers?.actualCompletedVisitTravel,
                id: "travel_hours_actuals_completed"
            },
            {
                label: 'Visit Travel Actual – Cancelled',
                value: headers?.actualCancelledVisitTravel,
                id: "travel_hours_actuals_cancelled"
            },

            {
                label: 'Rounded Actual Duration – Missed',
                value: headers?.roundedActualMissedDuration,
                id: "visit_hours_rounded_actuals_missed"
            },
            {
                label: 'Rounded Actual Duration – NotStarted',
                value: headers?.roundedActualNotStartedDuration,
                id: "visit_hours_rounded_actuals_not_started"
            },
            {
                label: 'Rounded Actual Duration – InProgress',
                value: headers?.roundedActualInProgressDuration,
                id: "visit_hours_rounded_actuals_in_progress"
            },
            {
                label: 'Rounded Actual Duration – Completed',
                value: headers?.roundedActualCompletedDuration,
                id: "visit_hours_rounded_actuals_completed"
            },
            {
                label: 'Rounded Actual Duration – Cancelled',
                value: headers?.roundedActualCancelledDuration,
                id: "visit_hours_rounded_actuals_cancelled"
            },

            {
                label: 'Banded Actual Duration – Missed',
                value: headers?.bandedActualMissedDuration,
                id: "visit_hours_banded_actuals_missed"
            },
            {
                label: 'Banded Actual Duration – NotStarted',
                value: headers?.bandedActualNotStartedDuration,
                id: "visit_hours_banded_actuals_not_started"
            },
            {
                label: 'Banded Actual Duration – inprogress',
                value: headers?.bandedActualInProgressDuration,
                id: "visit_hours_banded_actuals_cancelled"
            },
            {
                label: 'Banded Actual Duration – Completed',
                value: headers?.bandedActualCompletedDuration,
                id: "visit_hours_banded_actuals_completed"
            },
            {
                label: 'Banded Actual Duration – Cancelled',
                value: headers?.bandedActualCancelledDuration,
                id: "visit_hours_banded_actuals_cancelled"
            },
            {
                label: 'Estimated Accrual Leave',
                value: headers?.eal,
                id: "estimated_accrual_leave"
            }
        ]
        setTopHeader(tempHeader)
    }, [headers])
    const updateStateBasedOnColumns = (data) => {
        const selectedData = data?.map((val) => (val.columnSelected && val.id));
        setAverageHoursOptionData(selectedData);
        setHeaderColumns(data)
        setDropdownArraySelected(data.filter((val) => val.columnSelected));
    };

    let keys = ['weekly_contracted', 'weekly_contracted_al', 'weekly_statutory_minimum_al_accrual_days', 'weekly_statutory_minimum_al_accrual_hrs']
    const filterFunc = (item) => !keys.includes(item.id)
    const updateWeeklyData = (defaultVisible) => {
        if (values?.data_format === 'week') {
            const weeklyHeader = [
                {
                    label: 'Weekly Contracted',
                    id: 'weekly_contracted',
                    className: "grow-mobile",
                    columnSelected: true,
                    custum_column: true,
                    max_width: "large",
                    name: "weekly_contracted",
                    removeCusCol: false,
                    status: 0,
                },
                {

                    label: 'Weekly Contracted AL',
                    id: 'weekly_contracted_al',
                    className: "grow-mobile",
                    columnSelected: true,
                    custum_column: true,
                    max_width: "large",
                    name: "weekly_contracted_al",
                    removeCusCol: false,
                    status: 0,
                },
                {

                    label: 'Weekly Statutory Minimum AL Accrual Days',
                    id: 'weekly_statutory_minimum_al_accrual_days',
                    className: "grow-mobile",
                    columnSelected: true,
                    custum_column: true,
                    max_width: "large",
                    name: "weekly_statutory_minimum_al_accrual_days",
                    removeCusCol: false,
                    status: 0,
                },
                {
                    label: 'Weekly Statutory Minimum AL Accrual Hrs',
                    id: 'weekly_statutory_minimum_al_accrual_hrs',
                    className: "grow-mobile",
                    columnSelected: true,
                    custum_column: true,
                    max_width: "large",
                    name: "weekly_statutory_minimum_al_accrual_hrs",
                    removeCusCol: false,
                    status: 0,
                }]
            let alreadyExists = defaultVisible.filter(filterFunc)
            let newData = [...alreadyExists, ...weeklyHeader]
            setHeaderColumns(newData)
            setDropdownArraySelected(newData.filter((val) => val.columnSelected));
            updateStateBasedOnColumns(newData)
        } else {

            let filterHeaderColumns = defaultVisible.filter(filterFunc)
            setHeaderColumns(filterHeaderColumns)
            setDropdownArraySelected(filterHeaderColumns.filter((val) => val.columnSelected));
            updateStateBasedOnColumns(filterHeaderColumns)
        }
    }

    const getBranchName = (id) => {
       const match =  contextChoices?.branch && contextChoices?.branch.find(el => el.id == id)
       return match ? match.name : ""
    }
    return (

        <div className='mt-4'>
            <Toast ref={toast} position="top-right" />
            <div className='d-flex justify-content-between mb-4 align-items-center'>
                <FiltersContainer style={{ marginBottom: '4px' }}>
                    <EmploymentTypesContainer className="me-2">
                        <DropdownTypesContainer className="ms-1 me-1">
                            <DateSelector
                                typeFilter={true}
                                labelTop="Date from"
                                {...startDateInput}
                            />
                        </DropdownTypesContainer>
                    </EmploymentTypesContainer>

                    <EmploymentTypesContainer className="me-2">
                        <DropdownTypesContainer className="ms-1 me-1">
                            <DateSelector
                                typeFilter={true}
                                labelTop="Date to"
                                {...endDateInput}
                            />
                        </DropdownTypesContainer>

                    </EmploymentTypesContainer>
                    <EmploymentTypesContainer className="me-2">
                        {/* <DropdownTypesContainer className="ms-1 me-1  d-flex"> */}
                        <DataFromatDropdown
                            className="w-100"
                            required
                            placeHolder={'Format'}
                            options={contextChoices && contextChoices?.kpi?.filter(format => format.id !== 'quarter')}
                            selectedOption={values?.data_format}
                            onChange={value =>
                                dropDownHandleSelect({ data_format: value ? value : null })
                            }
                        />
                        {/* </DropdownTypesContainer> */}
                    </EmploymentTypesContainer>
                    <EmploymentTypesContainer className="me-2">
                        <PrimaryButton
                            type="button"
                            onClick={handleSearch}
                            style={{ marginLeft: '4px', marginBottom: "2px" }}>
                            <span className={'ms-2 me-2 font-weight-bold ms-3'}>Search</span>
                        </PrimaryButton>
                    </EmploymentTypesContainer>
                </FiltersContainer>
                {averageHoursList?.length >= 1 && (
                    <div className='d-flex'>
                        <DropdownTypesContainer className="ms-1 me-4">
                            <NoSelectAllDropdownColumnSelector
                                items={headerColumns ? headerColumns.filter((item) => !item.removeCusCol) : []}
                                setItemsSelected={items => {
                                    dropDownHandleSelectTable({ tableDropdown: items });
                                }}
                                itemsSelected={dropdownArraySelected}
                                setSelectedFilter={(item) => setDropdownArraySelected([...item])}
                                placeHolder={'Column'}
                                isObject
                                allOption

                            />
                        </DropdownTypesContainer>

                        <PublishButton
                            disable={downloadDisable}
                            onClick={!downloadDisable && handleDownload}
                        >
                            {DOWNLOAD}
                        </PublishButton>
                    </div>)}


            </div>
            {
                averageHoursList?.length >= 1 &&
                (<div className='d-flex flex-wrap w-100'>
                    {topHeader.map((item) => {
                        return (<div className='me-2 mb-2'
                            style={{
                                display: !(averageHoursOptionData && averageHoursOptionData.includes(item.id)) && 'none'
                            }}>
                            <p className='header-box'>{item.label}: <span className='header-value'>
                                {roundToTwoDecimalPlaces(item.value)}</span></p>
                        </div>)
                    })

                    }

                </div>)
            }

            <ComponentDynamic loading={loadingModal}>
                {averageHoursList?.length >= 1 ?
                    (<div style={{ overflowX: 'scroll', width: '100%', marginTop: "20px" }}>
                        <div style={{ width: 'max-content', minWidth: '-webkit-fill-available' }}>
                            <Table
                                headerColumns={headerColumns}
                                values={averageHoursOptionData}
                                headerPadding={true}
                                noNeed={false}
                                noEditOption
                                fontSize="0.75rem"
                            >
                                <div>
                                    {
                                        averageHoursList?.map((item) => {
                                            return (
                                                <Row bgColor Shadow style={{ margin: "5px 0" }}>
                                                    <ColsGrouper
                                                        className="d-flex"
                                                    // onClick={() => eventClickHandler(visitcancelled)}
                                                    >
                                                        {/* Always visible start */}
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            Shrink
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("date")) && 'd-none'}
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{item?.dateRange}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            Shrink
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("branch")) && 'd-none'}
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{item?.branchId ? getBranchName(item?.branchId) : ""}</label>
                                                        </Col>
                                                        
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("working_day")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item.workingDay)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("working_day_52_weeks")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item.workingDay52weeks)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("total_working_hrs")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.totalWorkingHrs)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("total_working_hrs_52_weeks")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.totalWorkingHrs52weeks)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("avg_working_hrs")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.avgWorkingHrs)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("avg_working_hrs_52_weeks")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.avgWorkingHrs52weeks)}</label>
                                                        </Col>
                                                        {/* Always visible end */}

                                                        {/* visit_hours_scheduled */}

                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_scheduled_not_started")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleVisitNotStarted)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_scheduled_completed")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleVisitCompleted)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_scheduled_in_progress")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleVisitInProgress)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_scheduled_missed")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleVisitMissed)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_scheduled_cancelled")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleVisitCancelled)}</label>
                                                        </Col>

                                                        {/* visit_hours_actuals */}
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_actuals_not_started")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.actualVisitHrsNotStarted)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_actuals_completed")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.actualVisitHrsCompleted)}</label>
                                                        </Col>

                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_actuals_in_progress")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.actualVisitHrsInProgress)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_actuals_missed")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.actualVisitHrsMissed)}</label>
                                                        </Col>

                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_actuals_cancelled")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.actualVisitHrsCancelled)}</label>
                                                        </Col>

                                                        {/* visit_hours_rounded_actuals */}
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_rounded_actuals_not_started")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.roundedActualNotStartedDuration)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_rounded_actuals_completed")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.roundedActualCompletedDuration)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_rounded_actuals_in_progress")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.roundedActualInProgressDuration)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_rounded_actuals_missed")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.roundedActualMissedDuration)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_rounded_actuals_cancelled")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.roundedActualCancelledDuration)}</label>
                                                        </Col>

                                                        {/* visit_hours_banded_actuals */}
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_banded_actuals_not_started")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.bandedActualNotStartedDuration)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_banded_actuals_completed")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.bandedActualCompletedDuration)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_banded_actuals_cancelled")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.bandedActualInProgressDuration)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_banded_actuals_missed")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.bandedActualMissedDuration)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("visit_hours_banded_actuals_cancelled")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.bandedActualCancelledDuration)}</label>
                                                        </Col>

                                                        {/* travel_hours_actuals */}
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("travel_hours_actuals_not_started")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.actualNotStartedVisitTravel)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("travel_hours_actuals_completed")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.actualCompletedVisitTravel)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("travel_hours_actuals_in_progress")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.actualInProgressVisitTravel)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("travel_hours_actuals_missed")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.actualMissedVisitTravel)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("travel_hours_actuals_cancelled")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.actualCancelledVisitTravel)}</label>
                                                        </Col>

                                                        {/* travel_hours_scheduled */}
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("travel_hours_scheduled_not_started")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleNotStartedVisitTravelHrs)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("travel_hours_scheduled_completed")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleCompletedVisitTravelHrs)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("travel_hours_scheduled_in_progress")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleInProgressVisitTravelHrs)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("travel_hours_scheduled_missed")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleMissedVisitTravelHrs)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("travel_hours_scheduled_cancelled")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleCancelledVisitTravelHrs)}</label>
                                                        </Col>

                                                        {/* office_hours */}
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("office_hours_actuals")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.actualOfficeHrs)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("office_hours_scheduled")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleOfficeHrs)}</label>
                                                        </Col>

                                                        {/* Estimated Accrual Leave */}
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("estimated_accrual_leave")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.eal)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("estimated_accrual_leave_52_weeks")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.eal52Weeks)}</label>
                                                        </Col>


                                                        {/* Others */}
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("actual_on_call_hrs")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.actualOncallHrs)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("schedule_on_call_hrs")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleOncallHrs)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("schedule_office_travel_hrs")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.scheduleOfficeTravelHrs)}</label>
                                                        </Col>

                                                        {/* weekly column */}
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("weekly_contracted")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.weeklyContracted)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("weekly_contracted_al")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.weeklyContractedAL)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("weekly_statutory_minimum_al_accrual_days")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.weeklyStatutoryMinimumALAccrualDays)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("weekly_statutory_minimum_al_accrual_hrs")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.weeklyStatutoryMinimumALAccrualHrs)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("capped_al_calc")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.cappedAlCalc)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("al_accrued")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{roundToTwoDecimalPlaces(item?.alAccrued)}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("accrual_start_date")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{item?.accrual_start_date ? item?.accrual_start_date: ""}</label>
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            className={!(averageHoursOptionData && averageHoursOptionData.includes("accrual_end_date")) && 'd-none'}
                                                            Shrink
                                                            reducePadding
                                                            Capitalize
                                                            overlap={true}
                                                            Center>
                                                            <label>{item?.accrual_end_date ? item?.accrual_end_date: ""}</label>
                                                        </Col>

                                                        

                                                    </ColsGrouper>
                                                </Row>
                                            )
                                        })
                                    }

                                </div>
                            </Table>
                            <div className="mt-3">
                                {/* <Pagination
                                    totalPages={pages}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    values={values}
                                    dropDownHandleSelect={dropDownHandleSelectPagination}
                                    number={PAGE_SIZE_RENDER}
                                    dataLength={emailHistoryRecord?.results?.length}
                                /> */}
                            </div>
                        </div>
                    </div>
                    ) :

                    (<div className='d-flex justify-content-center align-items-center font-weight-bold'
                      style={{
                        height: '30vh',
                      }}
                    >
                      No data found

                    </div>)
                }
            </ComponentDynamic>
        </div>
    )
}

export default AverageHours