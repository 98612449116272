import React,{useState,useEffect,Fragment} from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  ModalContainer
} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {
    ErrorMessage
  } from 'shared/styles/constants/tagsStyles';
import {
  FlexGrow,
  ModalTitle,
} from 'components/DashboardComponents/ModalDynamic/styles'
import {PrimaryButtonForm} from 'shared/styles/buttons';



const SingleSwapRun = ({
    useInput,
    values,
    errorMessage,
    dataValues,
    dropDownHandleSelect,
    fullBind,
    handleSaveVisitSwapRun,
    saveButtonIcon,
    loadingSave,
}) => {

  const [addFullBind, setAddFullBind] = useState(fullBind);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Visit Swap Run',
    });
   // setModalLoading(false)
  }, [fullBind]);


 
  
  return (
    <ModalContainer max_width={'50%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'50%'}
        minWidthTitle="8.5rem"
        loading={false}>
        <div>
          <div className="ps-3 pe-3 pb-3 pt-0">
            <DropdownSearchable
             required
             {...useInput(`run_name_id`, 'isRequired')}
              placeHolder={'Select Run Name'}
              options={dataValues}
              selectedOption={values.run_name_swap_id}
              onChange={value =>
                dropDownHandleSelect({run_name_swap_id: value ? value : null},
                  'run_name_swap_id')
              }
            />
          </div>
          <div>
            <FlexGrow>
              <ModalTitle MinWidth={"8.5rem"} className="modal-title" >
                <div className={'d-flex justify-content-center justify-content-md-end p-3'} >
                  <div >
                    {errorMessage ? (
                      <ErrorMessage>
                        <label className={'mt-2'}>{errorMessage}</label>
                      </ErrorMessage>
                    ) : (
                      <Fragment />
                    )}
                  </div>
                  <div className={'ms-0 ms-md-3'}>
                    <PrimaryButtonForm minWidth="6rem" onClick={() => handleSaveVisitSwapRun()} >
                      <span className={'me-2'}>Swap</span>
                      {!loadingSave ? (saveButtonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  </div>
                </div>
              </ModalTitle>
            </FlexGrow>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>

  );
}

export default SingleSwapRun;
