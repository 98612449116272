import React, { Fragment, useState, useEffect } from 'react';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';
import { FaAngleDown, FaAngleUp, FaPlus } from 'react-icons/fa';
import { PURPLE } from 'shared/styles/constants/colors';

import { SquareStyle, ArrowStyle } from './styles';
import { IconWrapper } from 'components/SharedComponents/Table/styles';
import { MdEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { StylePointer } from 'shared/styles/constants/tagsStyles';

import { KEY_WORDS } from '..';
let TITLE_PADDING = 4;
let TITLE_FONT_WEIGHT = 800;
const _ = require('lodash');

const BodyTable = ({
  rolValues,
  selectedRol,
  setRolValues,
  handleCheckboxClick,
  handleOnClick,
  handleDelete,
  handleClickShowFrom,
  updatePermission,
  deletePermission,
  createPermission
}) => {

  const [expandable, setExpandable] = useState({});

  useEffect(() => {
    _.forEach(expandable, function (value, key) {
      expandable[key] = false;
    });

    setExpandable({ ...expandable });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRol]);


  const createRows = (sections, keyValue = '') => {
    let tableToReturn = [];

    let valuesToMap = sections && sections ? sections : rolValues;

    valuesToMap.forEach((tableValue, index) => {

      const myKey = tableValue.name.replace(/\s/g, '') + '/' + keyValue;

      expandable[`${keyValue ? keyValue : myKey}`] =
        expandable[`${keyValue ? keyValue : myKey}`] === undefined
          ? false
          : expandable[`${keyValue ? keyValue : myKey}`];




      tableToReturn.push(
        <Fragment key={index}>
          {(expandable[`${keyValue ? keyValue : myKey}`] && index === 0) &&
            (tableValue.principal ? null : (
              <tr
                key={index}
                className={'border'}
                style={{ backgroundColor: '#ededed' }}>
                <th
                  className={'sticky-col'}
                  style={{
                    paddingLeft: tableValue.isSubsection ? 40 : tableValue.principal ? 3 : 20,
                    fontWeight: tableValue.isSubsection ? 400 : tableValue.principal ? 800 : 650,
                  }}>
                  <div className={'d-flex align-items-center'}>
                    {createPermission && (
                      <StylePointer
                        onClick={() => {
                          handleOnClick(
                            tableValue,
                            'add',
                            `${tableValue.isSubsection ? KEY_WORDS.ASSESSMENT_TYPE : KEY_WORDS.ASSESSMENT_CATEGORY}`
                          )
                        }}
                        style={{ paddingRight: 10 }}>
                        <FaPlus color={PURPLE} size={16} />
                      </StylePointer>
                    )}
                    {`${tableValue.isSubsection
                      ? 'New Assessment Type For - '
                      : 'New Assessment Category For - '
                      } ${tableValue.category_name
                        ? tableValue.category_name
                        : tableValue.type_name
                      }`}
                  </div>
                </th>
              </tr>
            ))}
        </Fragment>
      );



      tableToReturn = [
        ...tableToReturn,
        <Fragment key={index}>
          {!expandable[`${keyValue ? keyValue : myKey}`] &&
            !tableValue.principal ? null : (
            // adding rows
            <tr key={index} className={'border'}>
              <th
                className={'sticky-col'}
                style={{
                  paddingLeft: tableValue.isSubsection ? 40 : tableValue.principal ? 3 : 20,
                  fontWeight: tableValue.isSubsection ? 400 : tableValue.principal ? 800 : 650,
                }}>
                <div onClick={() =>
                  tableValue.isSubsection
                  && !tableValue.sections
                  && !tableValue.principa
                  && handleClickShowFrom(tableValue)
                }
                  style={{ cursor: tableValue.isSubsection && !tableValue.sections && !tableValue.principa ? "pointer" : "" }}
                  className={`d-flex align-items-center`}>
                  {/**adding square */}
                  {tableValue.principal
                    ? addSquare(tableValue, expandable, keyValue, myKey)
                    : null}

                  {/**adding arrows */}
                  {tableValue.sections && !tableValue.principal ? (
                    <Fragment>{addArrows(expandable, myKey)}</Fragment>
                  ) : null}
                  {/**adding name */}

                  {tableValue.name}
                </div>
              </th>
              {/**adding web checkboxes */}
              <td
                className={'align-content-center border-start checkbox Position'}>
                {checkboxComponent(tableValue, 'edit')}
              </td>
              {/**adding mobile checkboxes */}
              <td className={'align-self-center border-start checkbox Position'}>
                {checkboxComponent(tableValue, 'delete')}
              </td>
              {/* <td className={'align-self-center border-start checkbox Position'}>
                {checkboxComponent(tableValue, 'active')}
              </td>
              <td className={'align-self-center border-start checkbox Position'}>
                {checkboxComponent(tableValue, 'checkbox')}
              </td> */}
            </tr>
          )}
        </Fragment>,
      ];



      if (tableValue.sections) {
        if (tableValue.sections.length >= 1) {

          TITLE_PADDING += 20;
          TITLE_FONT_WEIGHT -= 150;

          TITLE_PADDING -= 20;
          TITLE_FONT_WEIGHT += 150;

          tableToReturn = [
            ...tableToReturn,
            createRows(tableValue.sections, `${myKey}`),
          ];



        } else {
          if (!tableValue.principal && !tableValue.isSubsection) {
            tableToReturn.push(
              <Fragment key={index}>
                {expandable[`${keyValue ? keyValue : myKey}`] && (
                  <tr
                    key={index}
                    className={'border'}
                    style={{ backgroundColor: '#ededed' }}>
                    <th
                      className={'sticky-col'}
                      style={{
                        paddingLeft: tableValue.isSubsection ? 40 : tableValue.principal ? 3 : 20,
                        fontWeight: tableValue.isSubsection ? 400 : tableValue.principal ? 800 : 650,
                      }}>
                      <div className={'d-flex align-items-center'}>
                        {createPermission && (
                          <StylePointer
                            onClick={() => {
                              handleOnClick(
                                tableValue,
                                'add',
                                `${tableValue.principal
                                  ? KEY_WORDS.ASSESSMENT
                                  : tableValue.category
                                    ? KEY_WORDS.ASSESSMENT_TYPE
                                    : KEY_WORDS.ASSESSMENT_CATEGORY
                                }`
                              );
                            }}
                            style={{ paddingRight: 10 }}>
                            <FaPlus color={PURPLE} size={16} />
                          </StylePointer>)}
                        {`${tableValue.principal
                          ? 'New Assessment Category For - '
                          : tableValue.category
                            ? 'New Assessment Type For - '
                            : ''
                          } ${tableValue.isSubsection
                            ? tableValue.type_name
                            : tableValue.name
                          }`}
                      </div>
                    </th>
                    <td
                      className={
                        'align-content-center border-start checkbox Position'
                      }></td>
                    <td
                      className={
                        'align-self-center border-start checkbox Position'
                      }></td>
                  </tr>
                )}
              </Fragment>
            );
          } else {
            tableToReturn.push(
              <Fragment key={index}>
                {expandable[`${keyValue ? keyValue : myKey}`] &&
                  (!tableValue.principal ? null : (
                    <tr
                      key={index}
                      className={'border'}
                      style={{ backgroundColor: '#ededed' }}>
                      <th
                        className={'sticky-col'}
                        style={{
                          paddingLeft: tableValue.isSubsection ? 40 : tableValue.principal ? 3 : 20,
                          fontWeight: tableValue.isSubsection ? 400 : tableValue.principal ? 800 : 650,
                        }}>
                        <div className={'d-flex align-items-center'}>
                          {createPermission && (
                            <StylePointer
                              onClick={() => {
                                handleOnClick(
                                  tableValue,
                                  'add',
                                  `${tableValue.isSubsection
                                    ? KEY_WORDS.ASSESSMENT_TYPE
                                    : tableValue.principal
                                      ? KEY_WORDS.ASSESSMENT_CATEGORY
                                      : KEY_WORDS.ASSESSMENT}`
                                )
                              }}
                              style={{ paddingRight: 10 }}>
                              <FaPlus color={PURPLE} size={16} />
                            </StylePointer>)}
                          {`${tableValue.isSubsection
                            ? 'New Assessment Type For - '
                            : 'New Assessment Category For - '
                            } ${tableValue.isSubsection
                              ? tableValue.type_name
                              : tableValue.principal
                                ? tableValue.name
                                : tableValue.category_name
                            }`}
                        </div>
                      </th>
                      <td
                        className={
                          'align-content-center border-start checkbox Position'
                        }></td>
                      <td
                        className={
                          'align-self-center border-start checkbox Position'
                        }></td>
                    </tr>
                  ))}
              </Fragment>
            );
          }
        }
      }

      if (tableValue.principal) {
        TITLE_PADDING = 3;
      }
    });

    return tableToReturn;
  };

  const addSquare = (tableValue, expandable, keyValue, myKey) => {
    if (!expandable[`${keyValue ? keyValue : myKey}`] && tableValue.principal) {
      return (
        <SquareStyle className={'pb-1'}>
          <AiFillPlusSquare
            color={PURPLE}
            onClick={() => {
              setExpandable({
                ...expandable,
                [`${keyValue ? keyValue : myKey}`]:
                  !expandable[`${keyValue ? keyValue : myKey}`],
              });
            }}
          />
        </SquareStyle>
      );
    } else {
      return (
        <SquareStyle className={'pb-1'}>
          <AiFillMinusSquare
            color={PURPLE}
            onClick={() => {
              _.forEach(expandable, function (value, key) {
                if (key.includes(myKey) && key !== myKey) {
                  expandable[key] = false;
                }
              });

              setExpandable({
                ...expandable,
                [`${keyValue ? keyValue : myKey}`]:
                  !expandable[`${keyValue ? keyValue : myKey}`],
              });
            }}
          />
        </SquareStyle>
      );
    }
  };

  const addArrows = (expandable, myKey) => {
    if (expandable[`${myKey}`]) {
      return (
        <ArrowStyle className={'pb-1 ps-0'}>
          <FaAngleUp
            color={PURPLE}
            onClick={() => {
              _.forEach(expandable, function (value, key) {
                if (key.includes(myKey) && key !== myKey) {
                  expandable[key] = false;
                }
              });

              setExpandable({
                ...expandable,
                [`${myKey}`]: !expandable[`${myKey}`],
              });
            }}
          />
        </ArrowStyle>
      );
    } else {
      return (
        <ArrowStyle className={'pb-1 ps-0'}>
          <FaAngleDown
            color={PURPLE}
            onClick={() => {
              setExpandable({
                ...expandable,
                [`${myKey}`]: !expandable[`${myKey}`],
              });
            }}
          />
        </ArrowStyle>
      );
    }
  };

  /**
   * Return horizontal checkboxes
   * @param {Object} tableValue
   * @param {String} columnType
   * @returns
   */

  const checkboxComponent = (tableValue, columnType) => {
    return (
      <div className={'d-flex  justify-content-center'}>
        <div className={'checkbox-container justify-content-center ps-3'}>
          {columnType === 'edit' && updatePermission && (
            <IconWrapper
              onClick={() => {
                handleOnClick(
                  tableValue,
                  'edit',
                  `${tableValue.isSubsection
                    ? KEY_WORDS.ASSESSMENT_TYPE
                    : tableValue.principal
                      ? KEY_WORDS.ASSESSMENT
                      : KEY_WORDS.ASSESSMENT_CATEGORY
                  }`

                );
              }}>
              <MdEdit />
            </IconWrapper>
          )}
          {columnType === 'delete' && deletePermission && (
            <IconWrapper
              onClick={() => {
                handleDelete(
                  tableValue,
                  `${tableValue.isSubsection
                    ? KEY_WORDS.ASSESSMENT_TYPE
                    : tableValue.principal
                      ? KEY_WORDS.ASSESSMENT
                      : KEY_WORDS.ASSESSMENT_CATEGORY
                  }`
                );
              }}>
              <RiDeleteBinLine />
            </IconWrapper>
          )}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {/* <SubHeader /> */}
      {createRows()}
    </Fragment>
  );
};

export default BodyTable;
