import TimeSelector from 'components/SharedComponents/TimeSelector';
import { useForm } from 'hooks/FormHook';
import React, { useState, useEffect, Fragment, useContext, useRef } from 'react';
import { editBranchMobileSettings, editSettingEvv, getBranchMobileSettings, getSettingEvv } from 'utils/api/SettingsApi';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import {
    InvalidOrEmptyValues,
    ERROR,
    SAVE,
    SAVED,
} from 'utils/constants/messages';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { ErrorMessage } from 'shared/styles/constants/tagsStyles';
import { PrimaryButtonForm, Toggle, ToggleLabel, ToggleWrapper } from 'shared/styles/buttons'
import { FlexGrid3Container, FlexGrid3Item } from 'shared/styles/constants/tagsStyles'
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { event } from 'jquery';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import { KeyPress } from 'utils/constants/regularExpressions';

const MobileSettings = ({
    branch,
    readPermission,
    createPermission,
    updatePermission,
    deletePermission
}) => {
    const [editMode, setEditMode] = useState(false)
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingSave, setLoadingSave] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { values, setValues, useInput, isValid, errors, setCheckRequires } =
        useForm({});

    // console.log("useInput : ", { ...useInput('mobileSessionTime') })
    // console.log("values : ", values)
    const toast = useRef();

    const [withoutNotes, setWithoutNotes] = useState(false)
    const [withoutCompletingTask, setWithoutCompletingTask] = useState(false)
    const [withoutUpdatingMeds, setWithoutUpdatingMeds] = useState(false)
    const [dataError, setDataError] = useState(false)

    useEffect(() => {
        loadSettingEvv();
    }, []);

    const loadSettingEvv = async () => {
        await getBranchMobileSettings(branch.id).then(response => {
            if (response) {
                console.log("getBranchMobileSettings response : ", response)

                if (response.status === true && response.data) {
                    if (response.data.hasOwnProperty("id")) {
                        setEditMode(true)
                    }
                    const [hh = "00", mm = "00", ss = "00"] = response.data.session_expire_time.split(":") || [];
                    const formatHHMM = () => {
                        const formattedHH = hh.padStart(2, "0");
                        const formattedMM = mm.padStart(2, "0");
                        return `${formattedHH}:${formattedMM}`;
                    };
                    const sessionTime = formatHHMM()
                    setValues({
                        mobileAuthenticationTime: response.data.mobileAuthenticationTime,
                        mobileSessionTime: sessionTime
                    });
                    setWithoutNotes(response.data.is_notes)
                    setWithoutUpdatingMeds(response.data.is_meds)
                    setWithoutCompletingTask(response.data.is_task)
                }
            }
        });
    };

    const handleSubmit = async evt => {
        evt.preventDefault();
        if (loadingSave) {
            return;
        }

        if (errors.length) {
            setCheckRequires(true)
            setErrorMessage(InvalidOrEmptyValues)
            return;
        }
        const mobileSession = values.mobileSessionTime;
        if (!mobileSession) {
            setDataError(true);
            setCheckRequires(true)
            setErrorMessage(InvalidOrEmptyValues)
            return;
        }

  
        if (mobileSession.split(':').length > 2) {
            setCheckRequires(true)
            setErrorMessage("Invalid Password Session Time.")
            setDataError(true);
            return;
        }

        const [hh = "00", mm = "00", ss = "00"] = mobileSession.split(":");
        if (mm > 59) {
            setCheckRequires(true)
            setErrorMessage("Minutes can  not be greater than 59")
            setDataError(true);
            return;
        }   

        const formatHHMM = () => {
            const formattedHH = hh.padStart(2, "0");
            const formattedMM = mm.padStart(2, "0");
            return `${formattedHH}:${formattedMM}:${ss}`;
        };

        const session_expire_time = formatHHMM();
        console.log(session_expire_time, "formatHHMM")
        const body = {
            id: values.id,
            mobileAuthenticationTime: values.mobileAuthenticationTime,
            "branch": branch.id,
            "session_expire_time": session_expire_time,
            "is_notes": withoutNotes,
            "is_meds": withoutUpdatingMeds,
            "is_task": withoutCompletingTask
        }
        setErrorMessage("")
        setCheckRequires(false)
        setLoadingSave(true);
        setDataError(false);
        await editBranchMobileSettings(branch.id, body)
            .then(res => {
                console.log("editBranchMobileSettings response : ", res)
                if (res.status === true) {
                    onSuccess();
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: `${res.message ? res.message : 'Successfully updated'}`,
                    });
                } else {
                    onError();
                    toast.current.show({
                        severity: 'error',
                        summary: 'Failed',
                        detail: `${res.message ? res.message : ''}`,
                    });
                }
            })
            .catch(onError);
    };

    const onSuccess = () => {
        setLoadingSave(false);
        setEditMode(true)
        setButtonIcon('');
        setButtonIcon(CHECK);
        setTextStatus(SAVED);
        setErrorMessage("")
        loadSettingEvv()
    };

    const onError = () => {
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        // setShowError(true);
        setCheckRequires(true);
        setErrorMessage(
            'Some of the fields have invalid or empty values. Please review.'
        );
    };

    const handleClockOutSettings = (e, data) => {
        if (data === 'notes') {
            setWithoutNotes(e.target.checked)
        }
        if (data === 'tasks') {
            setWithoutCompletingTask(e.target.checked)
        }
        if (data === 'meds') {
            setWithoutUpdatingMeds(e.target.checked)
        }
    }

    const mobileAuthenticationTimeHook = useInput('mobileAuthenticationTime', "isRequired")
    const mobileSessionTimeHook = useInput('mobileSessionTime', "isRequired")


    //  const handleSessiontimeCgange = (e) => {
    //     const value = e.target.value;
    //     // if (value.includes(":") && !/^\d{2,}:/.test(value)) {
    //     //     setDataError(true);
    //     //     return;
    //     //   }
    //     values.mobileSessionTime = value;
    //     setValues({...values});
    //     setDataError(false);
    //  }
    console.log(values, "values")
    let readOnly = editMode ? !updatePermission : !createPermission

    return (
        <div style={{ width: "100%", marginTop: "12rem" }}>
            {readPermission ?
                <>
                    <Toast ref={toast} position="top-right" />

                    {/* <div className='mt-2'>
                <TimeSelector
                    labelTop={'Mobile Authentication Time'}
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                    {...useInput('mobileAuthenticationTime')}
                    placeholderText={'hh:mm'}
                    // locale={'sv-sv'}
                    timeCaption={'Duration'}
                />
            </div>

            <div className='mt-3'>
                <TimeSelector
                    labelTop={'Mobile Session Time'}
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                    {...useInput('mobileSessionTime')}
                    placeholderText={'hh:mm'}
                    // locale={'sv-sv'}
                    timeCaption={'Duration'}
                />
            </div> */}

                    {/* <div className='d-flex justify-content-start flex-wrap mb-3'>
                <div className='d-flex me-2 mt-3'>
                    <div className='w-200'>
                        <TimeSelector
                            labelTop={'Mobile Authentication Time'}
                            timeFormat={'HH:mm'}
                            dateFormat={'HH:mm'}
                            {...useInput('mobileAuthenticationTime')}
                            placeholderText={'hh:mm'}
                            timeCaption={'Duration'}
                        />
                    </div>
                </div>
                <div className='d-flex me-2 mt-3'>
                    <div className='w-200'>
                        <TimeSelector
                            labelTop={'Mobile Session Time'}
                            timeFormat={'HH:mm'}
                            dateFormat={'HH:mm'}
                            {...useInput('mobileSessionTime')}
                            placeholderText={'hh:mm'}
                            timeCaption={'Duration'}
                        />
                    </div>
                </div>
            </div> */}
                    <div className='mt-3 mb-2 ms-2 payroll-non-commute'>
                        <span className='header-text' style={{ fontSize: 18 }}>Mobile Authentication</span>
                        {/* <span className='header-text'>Allow Clock Out</span> */}
                    </div>

                    <FlexGrid3Container className={'w-100 mb-1 mt-1 ms-2 me-2'}>
                        <FlexGrid3Item ResizeWidth={"49%"} className='pe-1'>
                            <TimeSelector
                                isRequired={true}
                                labelTop={'PIN Authentication Time'}
                                timeFormat={'HH:mm'}
                                dateFormat={'HH:mm'}
                                disabled={readOnly}
                                {...mobileAuthenticationTimeHook}
                                placeholderText={'hh:mm'}
                                timeCaption={'Duration'}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item ResizeWidth={"49%"} className='ps-1'>
                            {/* <TimeSelector
                        labelTop={'Password Session Time'}
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                        disabled={readOnly}
                        {...mobileSessionTimeHook}
                        placeholderText={'hh:mm'}
                        timeCaption={'Duration'}
                    /> */}
                            <InputLabelTop
                                required={true}
                                type="text"
                                data-error={dataError}
                                id="mobileSessionTime"
                                {...mobileSessionTimeHook}
                                duration={true}
                                placeholder={'hh:mm'}
                                // onKeyPress={KeyPress.DURATION}
                                label={'Password Session Time'}
                            />
                        </FlexGrid3Item>
                    </FlexGrid3Container>

                    <div className='mt-3 mb-2 ms-2 payroll-non-commute'>
                        <span className='header-text' style={{ fontSize: 18 }}>Allow Clock Out</span>
                        {/* <span className='header-text'>Allow Clock Out</span> */}
                    </div>

                    <div
                        style={{ padding: "0 1rem 1rem 1rem" }}
                        className='payroll-non-commute'
                    >
                        <div style={{ width: "100%" }}>
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <td style={{ fontSize: 16 }}>Without Adding Notes</td>
                                        <td style={{ fontSize: 16 }}>Without Completing Tasks</td>
                                        <td style={{ fontSize: 16 }}>Without Updating Medications</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <ToggleButton
                                                status={withoutNotes}
                                                isRequired={false}
                                                onClick={(event) => handleClockOutSettings(event, 'notes')}
                                                disabled={readOnly}
                                                id="withoutNotes"
                                            />
                                        </td>
                                        <td>
                                            <ToggleButton
                                                status={withoutCompletingTask}
                                                isRequired={false}
                                                onClick={(event) => handleClockOutSettings(event, 'tasks')}
                                                disabled={readOnly}
                                                id="withoutCompletingTask"
                                            />
                                        </td>
                                        <td>
                                            <ToggleButton
                                                status={withoutUpdatingMeds}
                                                isRequired={false}
                                                onClick={(event) => handleClockOutSettings(event, 'meds')}
                                                disabled={readOnly}
                                                id="withoutUpdatingMeds"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div
                        className={
                            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
                        }
                    >
                        <div className={'mb-3'}>
                            {errorMessage ? (
                                <ErrorMessage>
                                    <label className={'mt-2'}>{errorMessage}</label>
                                </ErrorMessage>
                            ) : (
                                <Fragment />
                            )}
                        </div>

                        <div
                            className={'d-flex justify-content-center justify-content-md-end'}>
                            <div className={'ms-0 ms-md-3 mb-2 pb-1 mt-1 pt-1 me-2'}>
                                <PrimaryButtonForm minWidth="6rem" o onClick={handleSubmit} disabled={readOnly}>
                                    <span className={'me-2 mb-2 pb-1 pt-1'}>{textStatus}</span>
                                    {!loadingSave ? (
                                        buttonIcon
                                    ) : (
                                        <span
                                            className="spinner-border spinner-border-sm me-1"
                                            role="status"
                                        />
                                    )}
                                </PrimaryButtonForm>
                            </div>
                        </div>
                    </div>

                </> :
                <NoPermissionContainer>You don't have permission to access the information</NoPermissionContainer>}

        </div >
    );
};

export default MobileSettings;