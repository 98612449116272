import React from 'react'
import { useMemo } from 'react';
import { TypeHeader } from './styles';
import { CloseButtonWrapper } from 'shared/styles/constants/tagsStyles';
import { TIMES } from 'utils/constants/icons';
import RichTextEditor from 'components/SharedComponents/RichTextEditor';

function TypeFrom({
    values,
    setShowForm,
    showFrom
}) {
    // console.log(values, "oooooo")

    const config = useMemo(
        () => ({
            readonly: true,
        }),
        []
    )
    const handleMessage = () => { }
    return (
        <>
            {showFrom ?
                < >
                    <div className='d-flex w-100 justify-content-between'>
                        <TypeHeader>{values?.name}</TypeHeader>
                        <CloseButtonWrapper
                            onClick={() => {
                                setShowForm(false);
                            }}>
                            {TIMES}
                        </CloseButtonWrapper>
                    </div>
                    <div>
                        <div className={'w-100 mb-2'}>
                            <div className="w-100 ps-0 ps-md-1 pe-1 pb-1">
                                <RichTextEditor
                                    value={values?.message}
                                    tabIndex={0} // tabIndex of textarea
                                    onChange={newContent => handleMessage(newContent)}
                                    config={config}
                                />
                            </div>
                        </div>
                    </div>
                </>
                : 
                <div>
                   <h6>Keywords</h6>
                   <div style={{textWrap: 'pretty'}}><span style={{fontWeight: 600}}>@</span> - This placeholder will be replaced with the first name of the person(client/employee) who is attached to the form.

</div>
                </div>
                }
        </>


    )
}

export default TypeFrom