import React, {useEffect, useState, useRef, useContext} from 'react';
import { EmploymentTypesContainer, Header, Title } from '../styles';
import { CloseButtonWrapper, DropdownTypesContainer, FiltersContainer } from 'shared/styles/constants/tagsStyles';
import { getBradfordList } from 'utils/api/EmployeeApi';
import { AppContext, BranchItemsContext } from 'context';
import { useForm } from 'hooks/FormHook';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import queryString from 'query-string';
import moment from 'moment';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import Table from 'components/SharedComponents/Table';
import { Col, ColsGrouper, Row } from 'components/SharedComponents/Table/styles';
import {
    LARGE_COL,
    SMALL_COL,
    MEDIUM_COL,
    EX_SMALL_COL4,
    EX_VERY_LARGE_COL,
    VERY_EX_SMALL_COL_CHECKBOX,
  } from 'shared/styles/constants/columns';
  import Pagination from 'components/SharedComponents/Pagination';
import { BRADFORD_ROUTE } from 'utils/constants/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { bradford_choices } from 'utils/choiceConstant';
import DateSelector from 'components/SharedComponents/DateSelector';
import {PLUS, TIMES} from 'utils/constants/icons';
import { useModal } from 'hooks/ModalHook';
import BradfordModal from './Modals/BradfordModal';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';

const headerColumns = [
    {
      label: ' EMPLOYEE NAME',
      name: 'ee.full_name',
      status: 0,
      className: 'grow-mobile',
      max_width: MEDIUM_COL,
    },
    {
      label: 'Branch',
      name: 'ccat.name',
      status: 0,
      className: 'grow-mobile',
      max_width: MEDIUM_COL,
    },

    {
      label: 'Date From',
      name: 'ccsr.name',
      status: 0,
      className: 'grow-mobile',
      max_width: MEDIUM_COL,
    },

    {
      label: 'Date To',
      name: 'start_date',
      status: 0,
      className: 'grow-mobile  sm-hidden',
      max_width: EX_VERY_LARGE_COL,
    },

    {
      label: 'Duration (In Days)',
      name: 'end_date',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: EX_VERY_LARGE_COL,
    },

    {
      label: 'Seperation Count',
      name: 'absence_hours',
      status: 0,
      className: 'grow-mobile  ',
      max_width: SMALL_COL,
    },
    {
      label: 'Score',
      name: 'absence_days',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: MEDIUM_COL,
    },
  ];

const Bradford = props => {
    const currentYear = moment().format('YYYY');
  const [bradfordData, setBradfordData] = useState([]);
  const {values, setValues, useInput} = useForm({start_date: `${currentYear-1}-01-01`,end_date: `${currentYear}-12-31`, limitperpage:PAGE_SIZE_RENDER});
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const {branchData} = useContext(BranchItemsContext);
  const mountedRef = useRef(true);
  const [employeeFilter, setEmployeeFilter] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [branch, setBranch] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [dataIndex, setDataIndex] = useState();
  const [idSelected, setIdSelected] = useState('');

  const history = useHistory();
  const {
    contextChoices,
    contextChoicesMap,
    handleGetChoices
  } = useContext(AppContext);
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const startDate = useInput('start_date');
  const endDate = useInput('end_date');
  useEffect(()=>{
    handleGetChoices(bradford_choices)
  },[])

  useEffect(() => {

    setBranch(
      contextChoices &&
        contextChoices.branch &&
        contextChoices.branch.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setEmployees(
      contextChoices &&
        contextChoices.employee_listing &&
        contextChoices.employee_listing.map(y => {
          return {id: y && y.id, name: y && y.full_name};
        })
    );
   
  }, [contextChoices]);

  useEffect(() => {
   if(values.start_date && values.end_date){
    search();
   }
  }, [
    values.start_date,
    values.end_date,
    values.employees,
    values.branch
  ]);

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);
  

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    if (values.limitperpage === 100 && searchQuery === '?page=1') {
      offset = 0;
      return {
        offset,
      };
    } else if (values.limitperpage === 100 && searchQuery !== '?page=1') {
      let newData =  searchQuery.slice(6, 7)
      offset = newData * values.limitperpage;
      return {offset
      }
    }

  };

  const search = () => {
    const newDatas = props && props?.location?.search;
    const pageToSearch = getPageToSearchParam(newDatas, setCurrentPage);
    getBradfordList(
      {...branchData, ...values, ...pageToSearch},
      values.limitperpage
    ).then(response => {
      if (!mountedRef.current) return null;
      if (response) {
       // setLoadingModal(false);
      }
      setPages(Math.ceil(response.count / values.limitperpage));
      setBradfordData(response.data || []);
    });
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    const newDatas = props && props?.location?.search;
    const urlParams = queryString.parse(newDatas);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: BRADFORD_ROUTE,
      search: stringified,
    });
    values.limitperpage = item[namesection];
    setValues({...values});
  };

  const dropDownHandleSelect = item => {
    const newDatas = props && props?.location?.search;
    const urlParams = queryString.parse(newDatas);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: BRADFORD_ROUTE,
      search: stringified,
    });
    setValues({...values, ...item});
  };

  const handleModal = () => {
    setModalValue(!modalValue);
  };

  const handleRowClick = (employee, index) => {
    setDataIndex(index);
    setIdSelected(employee);
    setTitle('Bradford Details')
    handleModal();
  }

  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };

  const setEmployeefilter = item => {
    setEmployeeFilter([...item]);
  };
 
  return (
    <>
    <div>
    <Title>Bradford</Title>
    <Header>
          <FiltersContainer>
          <EmploymentTypesContainer className="me-2 mb-1">
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({branch: items});
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
                  />
                </EmploymentTypesContainer>
                <EmploymentTypesContainer className="me-2 mb-1">
                  <NoSelectAllDropdownFilter
                    items={employees ? employees : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employees: items});
                    }}
                    itemsSelected={employeeFilter}
                    setSelectedFilter={setEmployeefilter}
                    placeHolder={'Employees'}
                    isObject
                    allOption
                  />
                </EmploymentTypesContainer>
              <EmploymentTypesContainer className="me-2 mb-1">
                  <DropdownTypesContainer className="ms-1 me-1  d-flex">
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date from"
                      {...startDate}
                    />
                  </DropdownTypesContainer>
                </EmploymentTypesContainer>

                <EmploymentTypesContainer className="me-2 mb-1">
                  <DropdownTypesContainer className="ms-1 me-1  d-flex">
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date to"
                      {...endDate}
                    />
                  </DropdownTypesContainer>
                </EmploymentTypesContainer>
          </FiltersContainer>
          </Header>
          <ComponentDynamic loading={false}>
          <Table
            headerColumns={headerColumns}
            headerPadding={false}
            fontSize="0.75rem"
            callBackQuerySort={setQuerySort}
            noEditOption={true}
            noNeed={false}>
            <div>
              {bradfordData &&
                bradfordData.map((list, index) => {
                  return (
                    <div key={index}>
                      <Row bgColor Shadow>
                        <ColsGrouper className="d-flex">
                          <ColsGrouper
                            className="d-flex"
                            onClick={() => {
                              handleRowClick(list.employee, index);
                            }}>
                            <Col
                              max_width={MEDIUM_COL}
                              Shrink
                              reducePadding
                              Capitalize
                              Center
                              overlap={true}>
                              {list.employee && contextChoicesMap.employee_listing && contextChoicesMap?.employee_listing[list.employee]?.full_name} 
                            </Col>
                            <Col
                              max_width={MEDIUM_COL}
                              Shrink
                              reducePadding
                              Capitalize
                              overlap={true}
                              Center>
                              {list.branch && contextChoicesMap.branch && contextChoicesMap?.branch[list.branch]?.name}
                            </Col>
                            <Col
                              max_width={SMALL_COL}
                              Shrink
                              reducePadding
                              Capitalize
                              overlap={true}
                              Center>
                              {list && list.date_from}
                            </Col>
                            <Col
                              max_width={SMALL_COL}
                              Shrink
                              reducePadding
                              Capitalize
                              className="sm-hidden"
                              overlap={true}
                              Center>
                              {list && list.date_to}
                            </Col>

                            <Col
                              max_width={SMALL_COL}
                              Shrink
                              reducePadding
                              Capitalize
                              className="sm-hidden"
                              overlap={true}
                              Center>
                              {list?.durationInDays}
                            </Col>

                            <Col
                              max_width={SMALL_COL}
                              Shrink
                              reducePadding
                              Capitalize
                              overlap={true}
                              Center>
                              {list && list.seperationCount}
                            </Col>
                            <Col
                              max_width={MEDIUM_COL}
                              Shrink
                              reducePadding
                              Capitalize
                              overlap={true}
                              className="sm-hidden"
                              Center>
                              {list && list.score}
                            </Col>
                          </ColsGrouper>
                          {/* <Col className="sm-hidden" Center Shrink NoFlexGrow>
                            {deletePermissionAbsenceHistory && (
                              <IconWrapper
                                onClick={() => {
                                  deleteClick(list.id);
                                }}>
                                <RiDeleteBinLine />
                              </IconWrapper>
                            )}
                          </Col> */}
                        </ColsGrouper>
                      </Row>
                      <div className="mb-1" />
                    </div>
                  );
                })}
              <div className="mt-3">
                <Pagination
                  setCurrentPage={setCurrentPage}
                  totalPages={pages}
                  currentPage={currentPage}
                  values={values}
                  dropDownHandleSelect={dropDownHandleSelectPagination}
                  number={PAGE_SIZE_RENDER}
                  dataLength={bradfordData.length}
                />
              </div>
            </div>
          </Table>
      </ComponentDynamic>
      {modalValue && (
        <BradfordModal
          Id={idSelected}
          setModalValue={handleModal}
          successCallback={search}
          fullBind={fullBind}
          bradfordData={bradfordData}
         // absenceListRowsId={absenceListRowsId}
          location={props?.location?.search}
          dataIndex={dataIndex}
          dates_data={values}
          contextChoicesMap={contextChoicesMap}
        />)}
    </div>
    </>
  );
};

export default Bradford;
