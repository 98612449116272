import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { DASHBOARDS } from 'utils/constants/routes';
import logo from 'images/logo.png';
import { NavStyled, Logo, ResponsiveBackground, LogoMain } from './styles';
import { IoMdMenu } from 'react-icons/io';
import { GRAY_700 } from 'shared/styles/constants/colors';
import { getOrgname } from '../../../utils/localStorage/user';
import { Route, useHistory } from 'react-router-dom'
import { SETTINGS, SETTINGS_ORGANIZATIONS } from 'utils/constants/routes';
import { setToken, setTenant, setOrgname, setSchemaName, setUserFirstName } from 'utils/localStorage/token';
import { getApiForSwitchBackOrg } from 'utils/api/SettingsApi';
import { useForm } from 'hooks/FormHook';
import { EmploymentTypesContainer } from './styles';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter'
import { AppContext } from "context"
import RealTimeNotification from './RealTimeNotification/RealTimeNotification';
import CustumSelectorAllDropdownFilter from 'components/SharedComponents/custumDropdownWithTag/CustomDropdown';
import AccountSettings from './AccountsSetting';
import DeleteAccount from './AccountsSetting/AddModal/DeleteAccount';
import { useModal } from 'hooks/ModalHook';
function Navbar(props) {
  const [Orgname, setOrgname] = useState('');
  const [msg, setMsg] = useState(false);
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const { values, setValues, useInput } = useForm({});
  const [branch, setBranch] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [headerFilterClearAll, setHeaderFilterClearAll] = useState(false);
  const { contextChoices } = useContext(AppContext)

  useEffect(() => {
    setOrgname(getOrgname());
  }, [Navbar])

  useEffect(() => {
    conditions();
  }, [localStorage.getItem('switch_clicked')])

  useEffect(() => {
    props.filterClearValue(headerFilterClearAll)
  }, [headerFilterClearAll])

  useEffect(() => {
    if(contextChoices.branch && contextChoices.branch.length > 0){
      let data = contextChoices && contextChoices.branch && contextChoices.branch.map(y => {
        return { id: y && y.id, name: y && y.name, company: y && y.company_name }
      });
      data && data.push({ id: 'None', name: 'No branch' });
  
      console.log("*****************", contextChoices.branch)
      setBranch(data);
    }
  }, [contextChoices.branch])

  const dropDownHandleSelect = item => {
    console.log(item,"***************** item")
    props.branchData(item, headerFilterClearAll);
  };

  const setFilterBranch = (item) => {
    setBranchFilter([...item])
  }

  const user_last_id = localStorage.getItem('user_id');

  const backToOrganization = async () => {
    var switch_back_user_id = localStorage.getItem('user_id');
    await getApiForSwitchBackOrg(switch_back_user_id).then(
      response => {
        if (response.data != undefined) {
          const email = (response.data).email
          const first_name = (response.data).first_name
          const last_name = (response.data).last_name
          const user_name = (response.data).user_name
          const user_id = (response.data).user_id
          const endpoint = (response.data).organisaction_details.endpoint
          const schema_name = (response.data).organisaction_details.schema_name
          const superuser_id = (response.data).organisaction_details.superuser_id
          const name = (response.data).organisaction_details.name
          localStorage.setItem('tenant', endpoint);
          localStorage.setItem('user_id', user_id);
          localStorage.setItem('schema_name', schema_name);
          localStorage.setItem('name', name);
          localStorage.setItem('user_email', email);
          localStorage.setItem('user_first_name', first_name);
          localStorage.setItem('user_last_name', last_name);
          localStorage.setItem('user_name', user_name);
          localStorage.setItem('superuser_id', superuser_id);
        }
      }
    )

    localStorage.setItem('switch_clicked', false);
    setMsg(false)
    window.location.reload();
    history.push(SETTINGS + SETTINGS_ORGANIZATIONS)
    window.location.reload();
  }


  const conditions = () => {
    const switchClicked = localStorage.getItem('switch_clicked');
    setMsg(switchClicked)
  }

  const hitApiAfterClear = (value) => {
    setHeaderFilterClearAll(value)
  }
  const {
    setValue: setDeleteAccountModalValue,
    value: deleteAccountModalValue,
    setTitle: setDeleteAccountTitle,
    fullBind: fullBindDeleteAccount,
  } = useModal(false);

  const daysLeft = localStorage.getItem('password_expiry_left')
  const styles = {
    position: 'relative',
    top: "16px",
    zIndex: 10000,
    width: "100%",
    textAlign: 'center',
  }
  return (
    <div className="App">
      <ResponsiveBackground showLeftSidebar={props.showLeftSidebar} />
      <NavStyled className="navbar fixed-top flex-md-nowrap py-0 px-2 shadow-sm d-flex justify-content-center justify-content-md-between justify-content-lg-between">
        <div
          onClick={props.showMenu}
          className={'menuIcon d-block d-md-none d-lg-none'}>
          <IoMdMenu size={30} color={GRAY_700} />
        </div>

        <Link
          to={DASHBOARDS}
          className="navbar-brand">
          <LogoMain>
            <img src={logo} alt={'logo-img'} />
          </LogoMain>
        </Link>

        <div>
          {
            localStorage.getItem('switch_clicked') === 'true' ? (<p className="text-primary"><button className="btn btn-outline-primary  font-weight-bold btn-sm  " onClick={backToOrganization}>Switch back to organization page</button></p>) : (null)}
        </div>
        <div >
        {
          daysLeft < 10 ?
            <p className="text-primary">{`Your password will expire in ${daysLeft} days.`}</p>
            : null
        }
      </div>
        <div className='d-flex flex-row justify-content-end'>
          <div>
            <EmploymentTypesContainer className="me-2">
              <CustumSelectorAllDropdownFilter
                items={branch ? branch : []}
                setItemsSelected={items => {
                  dropDownHandleSelect({ headerbranch: items });
                }}
                itemsSelected={branchFilter}
                setSelectedFilter={setFilterBranch}
                placeHolder={'Branch'}
                isObject
                allOption
               // hitApiAfterClear={hitApiAfterClear}
              />
            </EmploymentTypesContainer>
          </div>
          <div>
            <Link
              to={DASHBOARDS}
              className="navbar-brand ont-weight-medium mx-2">
              {Orgname}
            </Link>
          </div>
          <div>
            <AccountSettings 
            setDeleteAccountModalValue={setDeleteAccountModalValue}
            setDeleteAccountTitle={setDeleteAccountTitle}
            />
          </div>
         {props.permissionsNotificationIcon &&
          <div>
            <RealTimeNotification />
          </div>}
        </div>
      </NavStyled>
     
      {deleteAccountModalValue
        &&
        <DeleteAccount
          setDeleteAccountModalValue={setDeleteAccountModalValue}
          deleteAccountModalValue={deleteAccountModalValue}
          fullBindDeleteAccount={fullBindDeleteAccount}
        />
      }
    </div>
  );
}

export default Navbar;
