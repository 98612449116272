import React from 'react';
import { TitleSection, FlexEqual, } from '../styles';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
const PersonalContact = ({
  useInput,
  values,
  choices,
  dropDownHandleSelect,
  readOnly,
  inviteUserStatus,
}) => {
  const contactSection = 'contact';
  return (
    <>
      <FlexEqual marginTopSm noMarginLeft className="">
        <TitleSection>Contact</TitleSection>
        <div className="d-flex mt-2">
          <div className="w-100 pe-1">
            <InputLabelTop
              // required
              type="text"
              readOnly={readOnly}
              id="mobile"
              label={'Mobile Number'}
              onKeyPress={KeyPress.PHONE}
              {...useInput('contact.mobile', 'isMobilePhone')}
              maxlength={25}
            />
          </div>
          <div className="w-100 ">
            <InputLabelTop
              // required
              type="text"
              readOnly={readOnly}
              id="telephone"
              label={'Telephone (Home)'}
              onKeyPress={KeyPress.PHONE}
              {...useInput('contact.telephone', 'isMobilePhone')}
              maxlength={25}
            />
          </div>
        </div>
        <div className="d-flex mb-2">
          <div className="w-100 ">
            <DropdownSearchable
              disabled={readOnly}
              placeHolder={'Preferred Contact Method'}
              options={choices.contact_method}
              selectedOption={
                values['contact'] ? values[['contact']].contact_method : null
              }
              onChange={value =>
                dropDownHandleSelect({
                  'contact': { contact_method: value ? value : null },
                })
              }
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="w-100 pe-1">
            <InputLabelTop
              // required={inviteUserStatus ? 'required' : ''}
              readOnly={readOnly}
              disabled={readOnly}
              type="email"
              id="email_work"
              label={'Work email'}
              // id="personal_email"
              // label={'Personal email'}
              {...useInput('contact.email_work',  'isEmail')}
              // {...useInput('contact.email_personal','isEmail' )}
              maxLength={254}
            />
          </div>
          <div className="w-100">
            <InputLabelTop
              type="email"
              readOnly={readOnly}
              disabled={readOnly}
              // id="work_email"
              // label={'Work email'}
              id="email_personal"
              label={'Personal email'}
              {...useInput('contact.email_personal', 'isEmail')}
              maxlength={254}
            />
          </div>
        </div>
      </FlexEqual>
    </>
  );
}

export default PersonalContact;
