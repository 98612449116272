import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import React from 'react';
import { Fragment } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {
    ErrorMessage,
    FlexGrid3Item,
    ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import { FLOPPY } from 'utils/constants/icons';

function AddHRTask({
    fullBind,
    useInput,
    readOnly,
    errors,
    errorMessage,
    showError,
    loadingSave,
    handleSubmit
}) {
    return (
        <ModalContainer max_width={'60%'}>
            <ModalNoInfo {...fullBind} minWidthTitle="8.5rem" loading={false}>
                <div
                    style={{ padding: "0 1rem 1rem 1rem" }}
                    className="d-flex flex-column w-100">
                    <div className="w-100">
                        <FlexGrid3Item className="pe-1 mb-2">
                            <InputLabelTop
                                disabled={readOnly}
                                required
                                type="text"
                                id="name"
                                label={'Title'}
                                {...useInput('title', 'isRequired')}
                                maxlength={50}
                            />
                        </FlexGrid3Item>
                    </div>
                    <div className="w-100 ">
                        <FlexGrid3Item className="pe-1 mb-2">
                            <small className="pe-1 " style={{ color: 'black' }}>
                                Instruction
                            </small>
                            <InputTextArea
                                disabled={readOnly}
                                type="text"
                                height={'4rem'}
                                id="nok_no"
                                label={'Instruction'}
                                {...useInput('instruction')}
                                maxlength={8}
                            />
                        </FlexGrid3Item>
                    </div>
                    <div
                        className={'d-flex justify-content-center justify-content-md-end mt-3'}>
                        <div className={'mb-3'}>
                            {errors.length && showError ? (
                                <ErrorMessage>
                                    <label className={'mt-2'}>{errorMessage}</label>
                                </ErrorMessage>
                            ) : (
                                <Fragment />
                            )}
                        </div>
                        <div className={'ms-0 ms-md-3 me-2'}>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                disabled={readOnly}
                                onClick={handleSubmit}
                                style={{ marginBottom: '5px' }}>
                                <span className={'me-2'}>SAVE</span>
                                {!loadingSave ? (
                                    FLOPPY
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm me-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </div>
                </div>
            </ModalNoInfo>
        </ModalContainer>
    );
}

export default AddHRTask;
