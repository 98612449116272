import React, { useEffect, useState, useRef, useContext, Fragment } from 'react';
import {
  LARGE_COL,
  SMALL_COL,
  MEDIUM_COL,
  EX_SMALL_COL4,
  EX_VERY_LARGE_COL,
  EX_LARGE_COL,
  COL_CONTAIN_WORD,
  EX_SMALL_COL,
  VERY_SMALL_COL,
} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';
import { getChoices } from 'utils/api/CoreApi';
import { EmploymentTypesContainer } from '../../styles';
import { PURPLE } from 'shared/styles/constants/colors';
import { PLUS, TIMES } from 'utils/constants/icons';
import {
  getAbsenceOverview,
  getAbsenceList,
  downloadAbsenceList,
  deleteAbsenceOverview,
  getAbsenceCalendarType,
} from 'utils/api/EmployeeApi';
import queryString from 'query-string';
import DateSelector from 'components/SharedComponents/DateSelector';

import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper,
} from 'components/SharedComponents/Table/styles';
import { ItemContainer } from 'components/SharedComponents/ProgressInformation/styles';
import { DOWNLOAD } from 'utils/constants/icons';
import {
  HeaderVisits,
  Title,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
  SearchIcon,
  SearchAddContainer,
  SearchContainer,
  PublishButton,
} from 'shared/styles/constants/tagsStyles';
import { RiDeleteBinLine } from 'react-icons/ri';
import DatePicker from 'react-datepicker';
import AddModal from 'components/DashboardComponents/Team/AbsenceHistoryList/AddModal';
import { deleteAbsenceListData } from 'utils/api/EmployeeApi';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';

import { AppContext } from 'context';
import { BranchItemsContext } from 'context';
import { useHistory, useLocation } from 'react-router-dom';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { CloseButtonWrapper } from 'shared/styles/constants/tagsStyles';

import moment from 'moment';
import { FloatingMobileButton, PrimaryButton } from 'shared/styles/buttons';
import { FaPlus } from 'react-icons/fa';

import { MdEdit } from 'react-icons/md';
import AddAbsenceModal from './AddAbsenceModal';

import { TitleSection } from '../../AddModal/styles';
import CustomYearDropdown from 'components/SharedComponents/DateSelector/custumDateSelector';
import { downloadAbsenceOverview } from 'utils/api/EmployeeApi';
import { team_absence_overview_choices } from 'utils/choiceConstant';
import { Toast } from 'primereact/toast';
import { NoDataFoundMessage } from 'components/DashboardComponents/Location/AddLocationModal/styles';
import { startsWith } from 'lodash';
const AbsenceOverviewTab = ({
  newData,
  updatePermissionAbsenceOverview,
  deletePermissionAbsenceOverview,
  createPermissionAbsenceOverview,
  readPermissionAbsenceOverview,
}) => {
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const {
    setValue: setAddModalValue,
    value: AddModalValue,
    setTitle: AddTitle,
    fullBind: fullBindAdd,
  } = useModal(false);
  const [absenceList, setAbsenceList] = useState([]);
  let start_date_today = `${new Date().getFullYear()}-01-01`
  let end_date_today = `${new Date().getFullYear()}-12-31`
  const { values, setValues, useInput, setCheckRequires } = useForm({
    // year: new Date().getFullYear(),
    start_date: start_date_today,
    end_date:end_date_today,
    absence_ids: [],
    limitperpage: PAGE_SIZE_RENDER
  });


  // useEffect(() => {
  //   if (!values.year) {
  //     setCheckRequires(true);
  //     SetDownloadDisable(true);
  //   }
  //   if (values.year) {
  //     SetDownloadDisable(false);
  //   }
  // }, [!values.year]);


  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };

  const search = () => {
    const pageToSearch = getPageToSearchParam(newData, setCurrentPage);
    
    getAbsenceOverview({ ...values, ...pageToSearch, ...branchData }, values.limitperpage).then(
      response => {
        if (!mountedRef.current) return null;
        if (absenceList && contextChoices) {
          setLoadingModal(false);
        }
        setPages(Math.ceil(response.count / values.limitperpage));
        setCountCheck(response.count ? response.count : '0');
        setAbsenceList(response.data || []);
      }
    );
  };

  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [idSelected, setIdSelected] = useState('');
  const [employeeFilter, setEmployeeFilter] = useState([]);

  const [absenceListRowsId, setAbsenceListRowsId] = useState('');
  const [deletePermission, setDeletePermissions] = useState(false);
  const history = useHistory();
  const toast = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const { contextChoices, handleGetChoices } = useContext(AppContext);

  const [startDate, setStartDate] = useState(new Date());

  const { branchData } = useContext(BranchItemsContext);
  const { headerFilterClearValue } = useContext(BranchItemsContext);
  const [loadingModal, setLoadingModal] = useState(true);
  const [dataIndex, setDataIndex] = useState();
  const mountedRef = useRef(true);
  const [employees, setEmployees] = useState([]);
  const [absenceFilter, setAbsenceFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [absence_type, setAbsenceData] = useState([]);
  const [dataChecking, setDataChecking] = useState(false);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);
  const [absenceReasonFilter, setAbsenceReasonFilter] = useState([]);

  const [countCheck, setCountCheck] = useState('0');
  const [data, setData] = useState();
  const [loadingButton, setLoadingButton] = useState(false);
  const [downloadDisable, SetDownloadDisable] = useState(false);
  const isYear = useInput(`year`, 'isRequired');
  useEffect(() => {
    setAbsenceData(
      contextChoices &&
      contextChoices.absence_type &&
      contextChoices.absence_type.map(y => {
        return { id: y && y.id, name: y && y.name };
      })
    );
    setEmployees(
      contextChoices &&
      contextChoices.employee_listing &&
      contextChoices.employee_listing.map(y => {
        return { id: y && y.id, name: y && y.full_name };
      })
    );
  }, [contextChoices]);

  useEffect(() => {
    handleGetChoices(team_absence_overview_choices);
  }, []);
  useEffect(() => {
      search();
  }, [
    branchData,
    // values.year,
    values.limitperpage,
    values.ordering,
    currentPage,
    newData
  ]);

  const headerColumns = [
    {
      label: ' EMPLOYEE NAME',
      name: 'ee.full_name',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_VERY_LARGE_COL,
    },
    {
      label: 'BRANCH',
      name: '',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'ABSENCE TYPE',
      name: 'ccat.name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },

    // {
    //   label: 'ABSENCE REASON',
    //   name: 'name',
    //   status: 0,
    //   className: 'grow-mobile',
    //   max_width: EX_VERY_LARGE_COL,
    // },

    {
      label: 'Absence Paid / Unpaid',
      name: 'Absence Paid / Unpaid',
      status: 0,
      className: 'grow-mobile  sm-hidden',
      max_width: LARGE_COL,
    },
    // {
    //   label: 'Absence Planned / Unplanned ',
    //   name: 'start_time',
    //   status: 0,
    //   className: 'grow-mobile sm-hidden ',
    //   max_width: SMALL_COL,
    // },
    {
      label: 'Time Units',
      name: 'end_date',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },

    {
      label: ' Current Full Year Absence Allowance',
      name: 'absence_hours',
      status: 0,
      className: 'grow-mobile  ',
      max_width: LARGE_COL,
    },

    {
      label: ' Absence Used',
      name: 'absence_days',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: SMALL_COL,
    },
    {
      label: 'Absence Requested ',
      name: 'absence_days',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },

    {
      label: ' Absence Remaining Balance',
      name: 'absence_days',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },
    //New added columns 

    {
      label: 'Calendar Year',
      name: '',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'AL Start Date',
      name: '',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'AL End Date',
      name: '',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Employee Input AL Days/Week',
      name: '',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Employee Input AL Hrs/Week',
      name: 'absence_days',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'FY Statutory AL (Days)',
      name: 'absence_days',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'FY Statutory AL (Hours)',
      name: 'absence_days',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Calendar Days for Accrual',
      name: 'absence_days',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Time Based Earned Statutory AL',
      name: 'absence_days',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Time Based Earned Statutory AL (Days)',
      name: 'absence_days',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },
    {
      label: 'Time Based Earned Statutory AL (Hours)',
      name: 'absence_days',
      status: 0,
      className: 'grow-mobile sm-hidden',
      max_width: LARGE_COL,
    },

  ];

  const handleRowClick = async (id, index) => {
    let tempData = absenceList.find(item => item.id === id);
    const res = await getCalendarType(tempData?.employee_id)
    tempData.absence_calendar_year = res
    setData({ ...tempData });
    setDataIndex(index);
    setIdSelected(id);
    setTitle('Absence  List');

    handleModal();
  };

  const deleteDeatils = () => {
    deleteAbsenceListData(values.absence_ids).then(res => {
      if (res.status) {
        setModalDeleteValue(false);
        setValues({ ...values, absence_ids: [] });
      }
    });
  };

  const deleteClick = id => {
    if (values.absence_ids && values.absence_ids.length === 0) {
      values.absence_ids = [id];
      setValues({ ...values });
      setModalDeleteValue(true);
    }
  };
  const handleModal = () => {
    setModalValue(!modalValue);
  };
  const handleModal2 = () => {
    setAddModalValue(!AddModalValue);
  };
  const handleAddAbsenceOverview = () => {
    setIdSelected('');
    handleModal2();
  };
  const getAbsenceReason = id => {
    if (id && contextChoices) {
      let absenceReasons =
        contextChoices &&
        contextChoices?.absence_reason?.find(item => item.id == id)?.name;
      return absenceReasons;
    } else {
      return null;
    }
  };
  const getAbsenceType = id => {
    if (id && contextChoices) {
      let absenceTypes =
        contextChoices &&
        contextChoices?.absence_type?.find(item => item?.id == id)?.name;
      return absenceTypes;
    } else {
      return null;
    }
  };
  const getAbsencePaid = id => {
    if (id && contextChoices) {
      let absencePaids =
        contextChoices.absence_paid &&
        contextChoices?.absence_paid?.find(item => item?.id == id)?.name;
      return absencePaids || "-";
    } else {
      return "-";
    }
  };

  const getBranchType = (id) => {
      let branch = contextChoices.branch &&
        contextChoices?.branch?.find(item => item?.id == id)?.name;
      return branch;
  }
  const getAbsencePlanned = id => {
    if (id && contextChoices) {
      let absencePlanned =
        contextChoices.absence_planned &&
        contextChoices?.absence_planned.find(item => item?.id == id)?.name;
      return absencePlanned;
    } else {
      return null;
    }
  };
  const toDeleteClient = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };
  const handleDeleteOverview = async () => {
    const res = await deleteAbsenceOverview(idSelected, values);
    if (res.status === true) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${res.message}`,
      });
      setModalDeleteValue(false);
      setIdSelected('');
      search();
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${res.message}`,
      });
      setModalDeleteValue(false);
      setIdSelected('');
    }


  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };
 
  const dropDownHandleSelect = item => {

    setValues({ ...values, ...item });
  };
  const setAbsenceRfilter = item => {
    setAbsenceReasonFilter([...item]);
  };
  const setEmployeefilter = item => {
    setEmployeeFilter([...item]);
  };
  const setAbsencefilter = item => {
    setAbsenceFilter([...item]);
  };
  const setEmployeeStatusFilter = (item) => {
    setStatusFilter([...item])
  }
  const handleDownlaod = () => {
    setLoadingButton(true);
    SetDownloadDisable(true);
    const pageToSearch = getPageToSearchParam(newData);
    let fileName =
      'Absence_list_' +
      moment(values.start_date).format('YYYY-MM-DD') +
      '_' +
      moment(values.end_date).format('YYYY-MM-DD');

    downloadAbsenceOverview(
      { ...values, ...pageToSearch,...branchData },

      values.start_date,
      values.end_date,
      values.absence_type
    )
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          setLoadingButton(false);
          SetDownloadDisable(false);
        });
      })
      .catch(error => {
        setLoadingButton(false);
        SetDownloadDisable(false);
      });
  };
  const handleSearch = () => {
      const urlParams = queryString.parse(newData);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: '/absence-history-list',
        search: stringified,
      });
      search();
  };

  const getCalendarType = async (id) => {
    try {
      const res = await getAbsenceCalendarType(id)
      if (res?.status == 200) {
        return res?.calendar_type
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getCalendarName = (id, key) => {
    let match = contextChoices && contextChoices[key] && contextChoices[key].find(el => el.id === id)
    return match ? match.name : ""
  }


  let startDateHook = useInput('start_date');
  let endDateHook = useInput('end_date');
  console.log(values, data, "9999")
  return (
    <div>
      <Toast ref={toast} position="top-right" />
      {readPermissionAbsenceOverview && (
        <HeaderVisits>
          <FiltersContainer>
            <FiltersLeftGroup>
              <EmploymentTypesContainer className="me-2 mb-1">
                <NoSelectAllDropdownFilter
                  items={absence_type ? absence_type : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ absence_type: items });
                  }}
                  itemsSelected={absenceFilter}
                  setSelectedFilter={setAbsencefilter}
                  placeHolder={'Absence Type'}
                  isObject
                  allOption
                />
              </EmploymentTypesContainer>
              <EmploymentTypesContainer className="me-2 mb-1">
              <NoSelectAllDropdownFilter
                    items={contextChoices?.employee_status? contextChoices?.employee_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employee_status: items});
                    }}
                    itemsSelected={statusFilter}
                    setSelectedFilter={(items)=>setEmployeeStatusFilter([...items])}
                    placeHolder={'Employee Status'}
                    isObject
                    allOption
            
                  />
              </EmploymentTypesContainer>
              <EmploymentTypesContainer className="me-2 mb-1">
                <NoSelectAllDropdownFilter
                  items={employees ? employees : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ employees: items });
                  }}
                  itemsSelected={employeeFilter}
                  setSelectedFilter={setEmployeefilter}
                  placeHolder={'Employees'}
                  isObject
                  allOption
                />
              </EmploymentTypesContainer>
              <EmploymentTypesContainer className="me-2 mb-1">
                <DropdownTypesContainer>
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date from"
                    value={values.start_date ? values.start_date : ''}
                    {...startDateHook}
                  />
                </DropdownTypesContainer>
              </EmploymentTypesContainer>
              <EmploymentTypesContainer className="me-2 mb-1">
                <DropdownTypesContainer>
                  <DateSelector
                    typeFilter={true}
                    minDate={values.start_date}
                    labelTop="Date To"
                    value={values.end_date ? values.end_date : ''}
                    {...endDateHook}
                  />
                </DropdownTypesContainer>
              </EmploymentTypesContainer>
              <EmploymentTypesContainer className="me-2 mb-1">
                <PrimaryButton
                  style={{ marginLeft: '0.1rem' }}
                  onClick={handleSearch}>
                  Search
                </PrimaryButton>
              </EmploymentTypesContainer>
            </FiltersLeftGroup>
          </FiltersContainer>

          <SearchAddContainer className="d-flex align-items-center">
            {createPermissionAbsenceOverview && (
              <FloatingMobileButton onClick={handleAddAbsenceOverview}>
                <FaPlus />
                <label>NEW ABSENCE OVERVIEW</label>
              </FloatingMobileButton>
            )}

            {createPermissionAbsenceOverview && (
              <ItemContainer>
                <PublishButton
                  disable={downloadDisable}
                  onClick={!downloadDisable && handleDownlaod}>
                  {DOWNLOAD}
                </PublishButton>
              </ItemContainer>
            )}
          </SearchAddContainer>
        </HeaderVisits>
      )}
      <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-start"></DataContainer>
      <ComponentDynamic loading={loadingModal}>
        {readPermissionAbsenceOverview && (
          <div>
            {countCheck !== '0' ? (
              <div style={{ overflowX: 'scroll', width: '100%', marginTop: "20px" }}>
                <div style={{ width: 'max-content', minWidth: '-webkit-fill-available' }}>
                  <Table
                    headerColumns={headerColumns}
                    headerPadding={false}
                    // noNeed={false}
                    // noEditOption
                    fontSize="0.75rem"
                  // callBackQuerySort={setQuerySort}
                  >
                    <div>
                      {absenceList &&
                        absenceList.map((list, index) => {
                          return (
                            <div key={index}>
                              <Row bgColor Shadow>
                                <ColsGrouper className="d-flex">
                                  <ColsGrouper
                                    className="d-flex"
                                    onClick={() => {
                                      handleRowClick(list.id, index);
                                    }}>
                                    <Col
                                      max_width={EX_VERY_LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list.employee__full_name ? list.employee__full_name : "-"}
                                    </Col>
                                    <Col
                                      max_width={EX_LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list?.branch ? getBranchType(list?.branch) : "-"}
                                    </Col>
                                    <Col
                                      max_width={EX_LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {getAbsenceType(
                                        list.absenceType && list.absenceType ? list.absenceType : "-"
                                      )}
                                    </Col>
                                    {/* <Col
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  overlap={true}
                                  className="sm-hidden"
                                  Center>
                                  {getAbsenceReason(
                                    list.absenceReason && list.absenceReason
                                  )}
                                </Col> */}
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {getAbsencePaid(
                                        list.absencePaid && list.absencePaid
                                      )}
                                    </Col>
                                    {/* <Col
                                  max_width={SMALL_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize
                                  className="sm-hidden"
                                  overlap={true}
                                  Center>
                                  {' '}
                                  {getAbsencePlanned(
                                    list && list.absencePlanned
                                  )}
                                </Col> */}
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      className="sm-hidden"
                                      Capitalize
                                      overlap={true}
                                      Center>
                                      {list && list.timeUnit ? list.timeUnit : "-"}
                                    </Col>
                                    <Col
                                      max_width={SMALL_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      className="sm-hidden"
                                      overlap={true}
                                      Center>
                                      {list && list.absenceAllowance ? list.absenceAllowance : "-"}
                                    </Col>
                                    <Col
                                      max_width={SMALL_COL}
                                      Shrink
                                      reducePadding
                                      className="sm-hidden"
                                      Capitalize
                                      overlap={true}
                                      Center>
                                      {list && list.absenceUsed ? list.absenceUsed : "-"}
                                    </Col>
                                    <Col
                                      max_width={SMALL_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      Center>
                                      {list && list.absenceRequested ? list.absenceRequested : "-"}
                                    </Col>
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list && list.absenceBalance ? list.absenceBalance : "-"}
                                    </Col>
                                    {/* New Added columns */}
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list && list?.calendar_type && list?.calendar_type ? getCalendarName(list?.calendar_type, "calendar_type") : "-"}
                                    </Col>
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list && list.allowance_date_from ? list.allowance_date_from : "-"}
                                    </Col>
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list && list.allowance_date_to ? list.allowance_date_to : "-"}
                                    </Col>
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list && list.employee_input_accrual_days ? list.employee_input_accrual_days : "-"}
                                    </Col>
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list && list.employee_input_accrual_hours ? list.employee_input_accrual_hours : "-"}
                                    </Col>
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list && list.statutory_accrual_days ? list.statutory_accrual_days : "-"}
                                    </Col>
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list && list.statutory_accrual_hours ? list.statutory_accrual_hours : '-'}
                                    </Col>
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list && list.calendar_days_for_accrual ? list.calendar_days_for_accrual : "-"}
                                    </Col>
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list && list.time_based_earned_statutory_AL ? list.time_based_earned_statutory_AL : "-"}
                                    </Col>
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list && list.time_based_earned_statutory_AL_accrual_days ? list.time_based_earned_statutory_AL_accrual_days : "-"}
                                    </Col>
                                    <Col
                                      max_width={LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      overlap={true}
                                      className="sm-hidden"
                                      Center>
                                      {list && list.time_based_earned_statutory_AL_accrual_hours ? list.time_based_earned_statutory_AL_accrual_hours : "-"}
                                    </Col>

                                    {updatePermissionAbsenceOverview && (
                                      <Col className="sm-hidden" Center Icon Purple>
                                        <IconWrapper
                                          onClick={() => {
                                            handleRowClick(list.id);
                                          }}>
                                          <MdEdit />
                                        </IconWrapper>
                                      </Col>
                                    )}
                                  </ColsGrouper>
                                  {deletePermissionAbsenceOverview && (
                                    <Col
                                      max_width={LARGE_COL}
                                      className="sm-hidden"
                                      Center
                                      Shrink
                                      NoFlexGrow>
                                      <IconWrapper
                                        onClick={() => {
                                          toDeleteClient(list.id);
                                        }}>
                                        <RiDeleteBinLine />
                                      </IconWrapper>
                                    </Col>
                                  )}
                                </ColsGrouper>
                              </Row>
                              <div className="mb-1" />
                            </div>
                          );
                        })}
                      <div className="mt-3">
                        <Pagination
                          totalPages={pages}
                          currentPage={currentPage}
                          values={values}
                          dropDownHandleSelect={dropDownHandleSelectPagination}
                          number={PAGE_SIZE_RENDER}
                          dataLength={absenceList.length}
                        />
                      </div>
                    </div>
                  </Table>
                </div>
              </div>
            ) : (
              <NoDataFoundMessage top={'15%'}>No data found</NoDataFoundMessage>
            )}
          </div>
        )}
      </ComponentDynamic>
      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this Absence Overview? \n DELETING the Absence Overview will remove ALL records from database.'
        }
        onOk={handleDeleteOverview}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />

      {modalValue ? (
        <AddModal
          loadData={search}
          data={data}
          setDataChecking={setDataChecking}
          idSelected={idSelected}
          setModalValue={setModalValue}
          selectedClientId={setIdSelected}
          // successCallback={search}
          setTitle={setTitle}
          fullBind={fullBind}
          absenceListData={absenceList}
          absenceListRowsId={absenceListRowsId}
          location={newData?.location?.search}
          dataIndex={dataIndex}
          deletePermission={deletePermission}
          toast={toast}
          getCalendarType={getCalendarType}
          contextChoices={contextChoices}
        />
      ) : (
        <></>
      )}

      {AddModalValue ? (
        <AddAbsenceModal
          fullBind={fullBindAdd}
          setTitle={AddTitle}
          setAddModalValue={setAddModalValue}
          loadData={search}
          toast={toast}
          getCalendarType={getCalendarType}
          contextChoices={contextChoices}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AbsenceOverviewTab;
