import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import CustomDropdownSearchable from 'components/SharedComponents/DropdownSearchable/CustomDropdowns';
import RichTextEditor from 'components/SharedComponents/RichTextEditor';
import { Col, ColsGrouper, IconWrapper, Row } from 'components/SharedComponents/Table/styles';
import React from 'react'
import { useEffect } from 'react';
import { useRef } from 'react';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { RiDeleteBinLine } from 'react-icons/ri';
import { EX_LARGE_COL, EX_SMALL_COL, EX_SMALL_COL4, LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns';
import { CloseButtonWrapper } from 'shared/styles/constants/tagsStyles';

const TaskTable = React.forwardRef(({ disabled,
    contextChoices,
    taskValue,
    handleDropDown,
    deleteTaskRow,
    expandFields,
    handleMessage,
    index,
    handleExpansion,
    task,
    renderSwapArrow,
    handleSelectChange,
    visitIndex,
    config,
    plannedTaskIndex,
    nameSection,
    useInput,
    category,
    ref,
}) => {
    // {...myInputHook(`visits[${visitIndex}].planned_task[${plannedTaskIndex}].${nameSection}[${index}].care_task_data`, 'isRequired')}
    const myRef = useRef(null)

    const myUseInputHook = useInput(
        category == "visits"
            ? `visits[${visitIndex}].planned_task[${plannedTaskIndex}].${nameSection}[${index}].${'care_task_data'}`
            : `${category}.planned_task[${plannedTaskIndex}].${nameSection}[${index}].${'care_task_data'}`,
        'isRequired')

    // useEffect(() => {
    //     if (typeof taskValue[index].care_task == "object") {
    //         taskValue[index].care_task = null
    //     }
    // }, [taskValue[index].care_task])


    console.log(index == taskValue?.length - 1 ? myRef : null, "hello")
    useEffect(() => {
        if (myRef.current) {
            myRef.current.focus();
        }
    }, [taskValue]);

    console.log(taskValue[index].care_task_data, taskValue[index].care_task, "care_task_data")
    return (
        <div key={index} ref={index == taskValue?.length - 1 ? myRef : null}>
            <Row

                bgColor
                Shadow
                width={'111%'}
                zIndex={taskValue.length - index}>
                <ColsGrouper
                    className="d-flex p-2"
                    margin-left={'77px'}
                    width={'111%'}>
                    <Col
                        max_width={EX_SMALL_COL}
                        className="d-flex justify-content-center align-content-center">
                        <CloseButtonWrapper
                            className="closeButtonWrapper"
                            onClick={() => {
                                handleExpansion(index);
                            }}>
                            {(expandFields && expandFields[index] && expandFields[index]?.open) || (task['defaultExpand'] || false) ? (
                                <IoIosArrowDown
                                    style={{ color: '#6200f9', fontWeight: 'bold' }}
                                />
                            ) : (
                                <IoIosArrowForward
                                    style={{ color: '#6200f9', fontWeight: 'bold' }}
                                />
                            )}
                        </CloseButtonWrapper>
                    </Col>
                    {taskValue.length > 1 &&
                        <Col
                            max_width={EX_SMALL_COL4}
                            Shrink={false}
                            NoFlexGrow={true}
                            Capitalize
                            style={{
                                // maxWidth: '20%',
                                paddingLeft: '15px',
                                paddingRight: '24px',
                            }}>
                            {renderSwapArrow(index, taskValue?.length)}
                        </Col>}
                    <Col
                        max_width={SMALL_COL}
                        Capitalize
                        style={{ width: '80%' }}>
                        <input
                            style={{ width: '20px', height: '20px' }}
                            type="checkbox"
                            checked={task.is_essential
                            }
                            disabled={disabled}
                            onChange={() => {
                                handleSelectChange(index);
                            }}
                        />
                    </Col>

                    <Col
                        max_width={EX_LARGE_COL}
                        Capitalize
                    >
                        <div className='w-100 ps-1 pe-1'>
                            <CustomDropdownSearchable
                                placeHolder="Care Category"
                                disabled={disabled}
                                options={
                                    taskValue[index].care_category_option
                                        ? taskValue[index].care_category_option
                                        : contextChoices.care_category
                                }
                                selectedOption={
                                    taskValue[index].care_category
                                        ? taskValue[index].care_category
                                        : null
                                }
                                onChange={value =>
                                    handleDropDown(
                                        { care_category: value ? value : null },
                                        'care_category',
                                        [index],
                                        value
                                    )
                                }
                            />
                        </div>
                    </Col>
                    <Col
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize

                    >
                        <div className='w-100 ps-1 pe-1'>
                            <CustomDropdownSearchable
                                disabled={disabled}
                                placeHolder="Task Type"
                                options={
                                    taskValue[index].task_type_options
                                        ? taskValue[index].task_type_options
                                        : contextChoices.task_type
                                }
                                selectedOption={
                                    taskValue[index].task_type
                                        ? taskValue[index].task_type
                                        : typeof taskValue[index].task_type_data === "string"
                                            ? taskValue[index].task_type_data
                                            : null
                                }
                                onChange={value =>
                                    handleDropDown(
                                        {
                                            task_type_data: value
                                                ? value
                                                : null,
                                        },
                                        'task_type_data',
                                        [index],
                                        value
                                    )
                                }
                            />
                        </div>
                    </Col>
                    <Col

                        max_width={EX_LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize>
                        <div className='w-100 ps-1 pe-1'>
                            <CustomDropdownSearchable
                                disabled={disabled}
                                required
                                placeHolder="Care Task"
                                // {...(`${category}[${visitIndex}].${nameSection}[${index}].effective_to`)}
                                {...myUseInputHook}
                                options={
                                    [
                                        {
                                            id: 'new',
                                            name: '+ New Care Task',
                                        },
                                        ...(taskValue[index]?.care_task_options ? taskValue[index]?.care_task_options : contextChoices?.care_task)
                                    ]

                                }
                                selectedOption={
                                    taskValue[index].care_task_data && typeof taskValue[index].care_task_data == "string"
                                        ? taskValue[index].care_task_data
                                        : null
                                }
                                onChange={value =>
                                    handleDropDown(
                                        {
                                            care_task_data: value
                                                ? value
                                                : null,
                                        },
                                        'care_task_data',
                                        [index],
                                        value
                                    )
                                }
                            />
                        </div>
                    </Col>


                    <Col max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize>
                        <div className='w-100 ps-1'>
                            <DropdownSearchable
                                disabled={disabled}
                                options={contextChoices.support_level}
                                selectedOption={
                                    taskValue[index].support_level
                                        ? taskValue[index].support_level
                                        : null
                                }
                                onChange={value =>
                                    handleDropDown(
                                        { support_level: value ? value : null },
                                        'support_level',
                                        [index],
                                        value
                                    )
                                }
                            />
                        </div>
                    </Col>
                    <Col
                        className="sm-hidden ps-2"
                        Center
                        Shrink
                        NoFlexGrow>
                        <IconWrapper
                            onClick={() => {
                                deleteTaskRow(index)
                            }}>
                            <RiDeleteBinLine />
                        </IconWrapper>
                    </Col>
                </ColsGrouper>
            </Row>
            {
                ((expandFields && expandFields[index] && expandFields[index]?.open) || (task['defaultExpand'] || false)) &&
                <div className='mt-3 mb-3'
                    style={{
                        margin: '0 40px',
                        border: '1px solid #ccc',
                        padding: "0.4rem 1rem 1rem 1rem"
                    }}>
                    <div className=''>
                        <small className="pe-1 " style={{ color: 'black' }}>Message</small>
                        <RichTextEditor
                            config={config}
                            value={task.message}
                            tabIndex={index} // tabIndex of textarea
                            onChange={newContent => handleMessage(newContent, task, index)}
                        />
                    </div>
                </div>

            }
        </div>
    )
})

export default TaskTable