import React, { useState, useEffect, Fragment, useRef, useContext } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import DateSelector from 'components/SharedComponents/DateSelector';
import { PLUS, TIMES } from 'utils/constants/icons';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { useForm } from 'hooks/FormHook';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { Header, Title, TitleSection } from 'shared/styles/constants/tagsStyles';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import ClockOutOfVisit from '../SettingsEvv/ClockOutOfVisit';
import moment from 'moment';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import {
  editSettingEsv,
  editSettingEvv,
  getSettingEsv,
} from 'utils/api/SettingsApi';
import BandedActualDurations from '../SettingsEvv/BandedActualDurations';
import ClockInToVisit from '../SettingsEvv/ClockInToVisit';

import TravelModeRestriction from '../SettingsEvv/TravelModeRestriction';
import { AppContext } from 'context';
import TravelTimeEstimate from '../SettingsEvv/TravelTimeEstimate';
import ClockInToShift from './ClockIntoShift';
import ClockOutOfShift from './ClockOutOfShift';
import { AddAvailability } from './AddAvailability';
import TravelTimeEstimates from './TravelTimeEstimates';
import TravelModeRestrictions from './TravelModeRestrictions';
import { AddClockOutRes } from './AddClockOutRes';
import { setting_esv_choices } from 'utils/choiceConstant';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';

const SettingsEsv = ({ props, permission }) => {
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [StoredMobilReminderData, setStoredMobilReminderData] = useState();
  const [
    dataClockDistanceTimeVerification,
    setDataClockDistanceTimeVerification,
  ] = useState();
  const [vistsStatusMissed, setVistsStatusMissed] = useState();
  const [vistsStatusCompleted, setVistsStatusCompleted] = useState();
  const [vistsStatusInProgress, setVistsStatusInProgress] = useState();
  const [vistssStatusNotStatrted, setVistsStatusNotStatrted] = useState();
  const [filterShiftType, setFilterShiftType] = useState([]);
  const [filterShiftTypes, setFilterShiftTypes] = useState([]);

  const [employeeAbscenceHoursLength, setEmployeeAbscenceHoursLength] =
    useState();
  const [employeeAbscenceHoursValue, setEmployeeAbscenceHoursValue] =
    useState();
  const [employeeAbscenceMinuteLength, setEmployeeAbscenceMinuteLength] =
    useState();
  const [employeeAbscenceMinuteValue, setEmployeeAbscenceMinuteValue] =
    useState();
  const [
    employeeUnavailabilityHoursLength,
    setEmployeeUnavailabilityHoursLength,
  ] = useState();
  const [
    employeeUnavailabilityHoursValue,
    setEmployeeUnavailabilityHoursValue,
  ] = useState();
  const [
    employeeUnavailabilityMinuteLength,
    setEmployeeUnavailabilityMinuteLength,
  ] = useState();
  const [
    employeeUnavailabilityMinuteValue,
    setEmployeeUnavailabilityMinuteValue,
  ] = useState();
  const [erorEmployeAbscence, setErorEmployeAbscence] = useState('');
  const [erorEmployeUnavailability, setErorEmployeUnavailability] =
    useState('');
  const [erorTimeFormatMag, setErorTimeFormatMag] = useState();
  const [shiftStatus, setVisitStatus] = useState([]);
  const { contextChoices } = useContext(AppContext);
  const [shiftStatusNoClocked, setShiftStatusNoClocked] = useState([]);
  const [shiftStatusClockedIn, setShiftStatusClockedIn] = useState([]);
  const [shiftStatusClockedInOut, setShiftStatusClockedInOut] = useState([]);
  const [settingEvv, setSettingEvv] = useState({});
  const [clockInWarningMessage, setClockInWarningMessage] = useState('');
  const [travelType, setTravelType] = useState([]);
  const [actualTravelTimeHoursLength, setActualTravelTimeHoursLength] =
    useState();
  const [actualTravelTimeHoursValue, setActualTravelTimeHoursValue] =
    useState();
  const [actualTravelTimeMinuteLength, setActualTravelTimeMinuteLength] =
    useState();
  const [actualTravelTimeMinuteValue, setActualTravelTimeMinuteValue] =
    useState();
  const [
    actualTravelTimeErorTimeFormatMag,
    setActualTravelTimeErorTimeFormatMag,
  ] = useState();
  const [erorActualTravelTime, setErorActualTravelTime] = useState();

  const [timeRescrtictionHoursLength, setTimeRescrtictionHoursLength] =
    useState();
  const [timeRescrtictionHoursValue, setTimeRescrtictionHoursValue] =
    useState();
  const [timeRescrtictionMinuteLength, setTimeRescrtictionMinuteLength] =
    useState();
  const [timeRescrtictionMinuteValue, setTimeRescrtictionMinuteValue] =
    useState();
  const [erorTimeRescrtiction, setErorTimeRescrtiction] = useState();
  const [erorTimeRescrtictionMag, setErorTimeRescrtictionMag] = useState();
  const [checkBeforeSubmit, setCheckBeforeSubmit] = useState(false);

  //below for second field home time restriction
  const [homeTimeRescrtictionHoursLength, setHomeTimeRescrtictionHoursLength] =
    useState();
  const [homeTimeRescrtictionHoursValue, setHomeTimeRescrtictionHoursValue] =
    useState();
  const [
    homeTimeRescrtictionMinuteLength,
    setHomeTimeRescrtictionMinuteLength,
  ] = useState();
  const [homeTimeRescrtictionMinuteValue, setHomeTimeRescrtictionMinuteValue] =
    useState();
  const [homeErorTimeRescrtiction, setHomeErorTimeRescrtiction] = useState();
  const [homeErorTimeRescrtictionMag, setHomeErorTimeRescrtictionMag] =
    useState();
  const [homeCheckBeforeSubmit, setHomeCheckBeforeSubmit] = useState(false);
  const [travelModeOptions, setTravelModeOptions] = useState([]);
  const [travelName, setTravelName] = useState([]);
  const [unitName, setUnitName] = useState([]);
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});
  const [loadingModal, setLoadingModal] = useState(true)
  const { handleGetChoices } = useContext(AppContext)
  useEffect(() => {
    handleGetChoices(setting_esv_choices)
  }, [])
  const mountedRef = useRef(true);
  // useEffect(() => {
  //   if (contextChoices.regular_shift_type) {
  //     let tempValues =
  //       contextChoices &&
  //       contextChoices.regular_shift_type.filter(
  //         shift =>
  //           shift.name != 'Shift' &&
  //           shift.name != 'Unavailability' &&
  //           shift.name != 'Absence'
  //       );
  //     setFilterShiftType([...tempValues]);
  //     setFilterShiftTypes([...tempValues]);
  //   }
  // }, [contextChoices.regular_shift_type]);
  useEffect(() => {
    setTravelModeOptions(
      contextChoices &&
      contextChoices.travel_type &&
      contextChoices.travel_type.map(y => {
        return { id: y && y.id, name: y && y.name };
      })
    );
  }, [contextChoices]);

  const dataClockValidationCheck = {
    id:
      values &&
      values.ShiftClockValidationCheck &&
      values.ShiftClockValidationCheck[0] &&
      values.ShiftClockValidationCheck[0].id,
    clock_in_verification_distance: values.verification_distance_clock_in
      ? values.verification_distance_clock_in
      : null,
    clock_out_verification_distance: values.verification_distance_clock_out
      ? values.verification_distance_clock_out
      : null,
    clock_in_verification_time: values.verification_time_clock_in
      ? values.verification_time_clock_in
      : null,
    clock_out_verification_time: values.verification_time_clock_out
      ? values.verification_time_clock_out
      : null,
    incorporate_auto_clock_out_rule: values.incorporate_auto_clock_out_rule,
    incorporate_auto_clock_out_rule_message:
      values.incorporate_auto_clock_out_rule_message,
    undo_clock_in: values.undo_clock_in,
    undo_clock_out: values.undo_clock_out,
    undo_cancel_clock: values.undo_cancel_clock,
    home_to_client: values.home_to_client,
    client_to_client: values.client_to_client,
    office_to_client: values.office_to_client,
    client_to_office: values.client_to_office,
    client_to_home: values.client_to_home,

    // New Travel Type here
    home_to_home: values.home_to_home,
    home_to_office: values.home_to_office,
    office_to_office: values.office_to_office,
    office_to_home: values.office_to_home ,

    travel_time_mobile: values.schedule_travel_time,
    shift_missed_status: values.shift_missed_status,
    shift_missed_status_time: values.shift_missed_status_time,
    shift_missed_status_times: values.shift_missed_status_times,
    client_unavailability: values.client_unavailability,
    client_absence: values.client_absence,
    is_manual_clock_in: values.is_manual_clock_in,
    is_manual_clock_out: values.is_manual_clock_out,
    clock_in_qr: values.clock_in_qr,
    clock_in_nfc: values.clock_in_nfc,
    clock_out_qr: values.clock_out_qr,
    clock_out_nfc: values.clock_out_nfc,
    actual_travel_distance: values.actual_travel_distance
      ? values.actual_travel_distance
      : null,
    adjust_travel_distance: values.adjust_travel_distance,
    actual_travel_time: values.actual_travel_time
      ? values.actual_travel_time
      : null,
    adjust_travel_time: values.adjust_travel_time,
  };

  const dataClockRoundUpDuration = [
    {
      id:
        values.ClockRoundUpDuration &&
        values.ClockRoundUpDuration[0] &&
        values.ClockRoundUpDuration[0].id &&
        values.ClockRoundUpDuration[0].id,
      round_up_validation: values.round_up_validation,
      round_up_duration_nearest: values.round_up_duration_nearest,
      round_up_duration_nearest_validation:
        values.round_up_duration_nearest_validation,
      round_down_duration_nearest_validation:
        values.round_down_duration_nearest_validation,
      round_down_duration_nearest: values.round_down_duration_nearest,
    },
  ];

  const dataBandedDuration =
    values &&
    values.BandedActualDuration &&
    values.BandedActualDuration.map(bandedData => {
      return {
        id: bandedData.id,
        time_from: bandedData.time_from,
        time_to: bandedData.time_to,
        apply_time: bandedData.apply_time,
      };
    });

  const dataVisitProvision = [
    {
      id:
        values &&
        values.ShiftProvision &&
        values.ShiftProvision[0] &&
        values.ShiftProvision[0].id,
      under_provision: values.under_provision,
      over_provision: values.over_provision,
      on_time_provision:
        values &&
        values.ShiftProvision &&
        values.ShiftProvision[0] &&
        values.ShiftProvision[0].on_time_provision,
    },
  ];

  const dataVisitTimeClassified = [
    {
      id:
        values.VisitTimeClassified &&
        values.VisitTimeClassified.find(data => data.shift_type == 'Start').id,
      early: values.shift_early_start,
      late: values.shift_late_start,
      on_time:
        values.VisitTimeClassified &&
        values.VisitTimeClassified.find(data => data.shift_type == 'Start')
          .on_time,
      shift_type:
        values.VisitTimeClassified &&
        values.VisitTimeClassified.find(data => data.shift_type == 'Start')
          .shift_type,
    },
    {
      id:
        values.VisitTimeClassified &&
        values.VisitTimeClassified.find(data => data.shift_type == 'End').id,
      early: values.shift_early_end,
      late: values.shift_late_end,
      on_time:
        values.VisitTimeClassified &&
        values.VisitTimeClassified.find(data => data.shift_type == 'End')
          .on_time,
      shift_type:
        values.VisitTimeClassified &&
        values.VisitTimeClassified.find(data => data.shift_type == 'End')
          .shift_type,
    },
  ];

  const dataTravel_mode_restriction_serializer =
    values.ShiftTravelModeRestriction &&
    values.ShiftTravelModeRestriction.map(traveldata => {
      return {
        travel_mode: traveldata.travel_mode,
        travel_time: traveldata.travel_time,
        home_client_restriction: traveldata.home_client_restriction,
      };
    });

  const dataTravel_estimate_serializer =
    values.ShiftTravelEstimatedTime &&
    values.ShiftTravelEstimatedTime.map(traveldata => {
      return {
        id: traveldata.id,
        travel_mode: traveldata.travel_mode,
        travel_name: traveldata.travel_name,
        unit_name: traveldata.unit_name,
        travel_time: parseFloat(traveldata.travel_time).toFixed(2),
      };
    });
  const ShiftTypeRateRestrictionData =
    values.ShiftTypeRateRestriction &&
    values.ShiftTypeRateRestriction.map(item => {
      return {
        id: item.id,

        shift_type: item.shift_type,
        updated_by: null,
      };
    });
  const ShiftTypeClockRestrictions =
    values.ShiftTypeClockRestriction &&
    values.ShiftTypeClockRestriction.map(item => {
      return {
        id: item.id,

        shift_type: item.shift_type,
      };
    });
  useEffect(() => {
    if (contextChoices) {
      setVistsStatusMissed(
        contextChoices &&
        contextChoices &&
        contextChoices.shift_status &&
        contextChoices.shift_status.find(
          shiftname => shiftname.name == 'Missed'
        ).name
      );

      setVistsStatusCompleted(
        contextChoices &&
        contextChoices &&
        contextChoices.shift_status &&
        contextChoices.shift_status.find(
          shiftname => shiftname.name == 'Completed'
        ).name
      );

      setVistsStatusInProgress(
        contextChoices &&
        contextChoices &&
        contextChoices.shift_status &&
        contextChoices.shift_status.find(
          shiftname => shiftname.name == 'In Progress'
        ).name
      );

      setVistsStatusNotStatrted(
        contextChoices &&
        contextChoices &&
        contextChoices.shift_status &&
        contextChoices.shift_status.find(
          shiftname => shiftname.name == 'Not Started'
        ).name
      );

      setTravelType(
        contextChoices &&
        contextChoices &&
        contextChoices.travel_type &&
        contextChoices.travel_type.map(traveltype => {
          return { id: traveltype.id, name: traveltype.name };
        })
      );
    }
  }, [contextChoices]);

  useEffect(() => {
    if (values.clock_in_to_shift && values.clock_out_of_shift) {
      const clock_in_to_shift =
        values.clock_in_to_shift &&
        values.clock_in_to_shift.map(data => {
          return {
            id: data.id,
            reminder_time: data.reminder_time,
            clock_type: data.clock_type ? data.clock_type : 'Clock In',
            reminder_message: data.reminder_message
              ? data.reminder_message
              : '',
            clock_time_type: data.clock_time_type,
            is_active: data.is_active,
          };
        });

      const clock_out_of_shift =
        values.clock_out_of_shift &&
        values.clock_out_of_shift.map(data => {
          return {
            id: data.id,
            reminder_time: data.reminder_time,
            clock_type: data.clock_type ? data.clock_type : 'Clock Out',
            reminder_message: data.reminder_message
              ? data.reminder_message
              : '',
            clock_time_type: data.clock_time_type,
            is_active: data.is_active,
          };
        });
      const dataVisitMobileReminder = [
        ...clock_in_to_shift,
        ...clock_out_of_shift,
      ];
      setStoredMobilReminderData(dataVisitMobileReminder);
    }
  }, [values]);

  useEffect(() => {
    if (values.ShiftClockDistanceTimeVerification) {
      const clockDistanceTimeVerification = [
        {
          id:
            values.ShiftClockDistanceTimeVerification &&
            values.ShiftClockDistanceTimeVerification[0] &&
            values.ShiftClockDistanceTimeVerification[0].id &&
            values.ShiftClockDistanceTimeVerification[0].id,

          clock_in_distance_within_time:
            values.clock_in_distance_within_time &&
            values.clock_in_distance_within_time,
          clock_in_distance_within_time_message:
            values.clock_in_distance_within_time
              ? values.clock_in_warning
              : values.ShiftClockDistanceTimeVerification[0] &&
              values.ShiftClockDistanceTimeVerification[0]
                .clock_in_distance_within_time_message,

          clock_in_distance_outwith_time:
            values.clock_in_distance_outwith_time &&
            values.clock_in_distance_outwith_time,
          clock_in_distance_outwith_message:
            values.clock_in_distance_outwith_time
              ? values.clock_in_warning
              : values.ShiftClockDistanceTimeVerification[0] &&
              values.ShiftClockDistanceTimeVerification[0]
                .clock_in_distance_outwith_message,

          clock_in_outwith_distance_within_time:
            values.clock_in_outwith_distance_within_time &&
            values.clock_in_outwith_distance_within_time,
          clock_in_outwith_distance_within_time_message:
            values.clock_in_outwith_distance_within_time
              ? values.clock_in_warning
              : values.ShiftClockDistanceTimeVerification[0] &&
              values.ShiftClockDistanceTimeVerification[0]
                .clock_in_outwith_distance_within_time_message,

          clock_in_outwith_distance_outwith_time:
            values.clock_in_outwith_distance_outwith_time &&
            values.clock_in_outwith_distance_outwith_time,
          clock_in_outwith_distance_outwith_time_message:
            values.clock_in_outwith_distance_outwith_time
              ? values.clock_in_warning
              : values.ShiftClockDistanceTimeVerification[0] &&
              values.ShiftClockDistanceTimeVerification[0]
                .clock_in_outwith_distance_outwith_time_message,

          clock_out_distance_within_time:
            values.clock_out_distance_within_time &&
            values.clock_out_distance_within_time,
          clock_out_distance_within_time_message:
            values.clock_out_distance_within_time
              ? values.clock_out_warning
              : values.ShiftClockDistanceTimeVerification[0] &&
              values.ShiftClockDistanceTimeVerification[0]
                .clock_out_distance_within_time_message,

          clock_out_distance_outwith_time:
            values.clock_out_distance_outwith_time &&
            values.clock_out_distance_outwith_time,
          clock_out_distance_outwith_time_message:
            values.clock_out_distance_outwith_time
              ? values.clock_out_warning
              : values.ShiftClockDistanceTimeVerification[0] &&
              values.ShiftClockDistanceTimeVerification[0]
                .clock_out_distance_outwith_time_message,

          clock_out_outwith_distance_within_time:
            values.clock_out_outwith_distance_within_time &&
            values.clock_out_outwith_distance_within_time,
          clock_out_outwith_distance_within_time_message:
            values.clock_out_outwith_distance_within_time
              ? values.clock_out_warning
              : values.ShiftClockDistanceTimeVerification[0] &&
              values.ShiftClockDistanceTimeVerification[0]
                .clock_out_outwith_distance_within_time_message,

          clock_out_outwith_distance_outwith_time:
            values.clock_out_outwith_distance_outwith_time &&
            values.clock_out_outwith_distance_outwith_time,
          clock_out_outwith_distance_outwith_time_message:
            values.clock_out_outwith_distance_outwith_time
              ? values.clock_out_warning
              : values.ShiftClockDistanceTimeVerification[0] &&
              values.ShiftClockDistanceTimeVerification[0]
                .clock_out_outwith_distance_outwith_time_message,
        },
      ];

      setDataClockDistanceTimeVerification(clockDistanceTimeVerification);
    }
  }, [values]);

  useEffect(() => {
    loadSettingEvv();
  }, []);

  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
  }, [values]);

  useEffect(() => {
    setHoursMinute();
  }, [
    values.client_unavailability,
    values.client_absence,
    values.adjust_travel_time,
  ]);

  const loadSettingEvv = () => {
    getSettingEsv().then(response => {
      if (!mountedRef.current) return null;
      if (response) {
        console.log(response, 'DDDDDDDD');
        if (
          response.ShiftClockDistanceTimeVerification[0] &&
          response.ShiftClockDistanceTimeVerification[0]
            .clock_in_distance_within_time
        ) {
          values.clock_in_verfication = 'clock_in_distance_within_time';
          values.clock_in_warning =
            response.ShiftClockDistanceTimeVerification[0].clock_in_distance_within_time_message;
        }
        if (
          response.ShiftClockDistanceTimeVerification[0]
            .clock_in_distance_outwith_time
        ) {
          values.clock_in_verfication = 'clock_in_distance_outwith_time';
          values.clock_in_warning =
            response.ShiftClockDistanceTimeVerification[0].clock_in_distance_outwith_message;
        }
        if (
          response.ShiftClockDistanceTimeVerification[0]
            .clock_in_outwith_distance_within_time
        ) {
          values.clock_in_verfication = 'clock_in_outwith_distance_within_time';
          values.clock_in_warning =
            response.ShiftClockDistanceTimeVerification[0].clock_in_outwith_distance_within_time_message;
        }
        if (
          response.ShiftClockDistanceTimeVerification[0]
            .clock_in_outwith_distance_outwith_time
        ) {
          values.clock_in_verfication =
            'clock_in_outwith_distance_outwith_time';
          values.clock_in_warning =
            response.ShiftClockDistanceTimeVerification[0].clock_in_outwith_distance_outwith_time_message;
        }
        if (
          response.ShiftClockDistanceTimeVerification[0]
            .clock_out_distance_outwith_time
        ) {
          values.clock_out_verfication = 'clock_out_distance_outwith_time';
          values.clock_out_warning =
            response.ShiftClockDistanceTimeVerification[0].clock_out_distance_outwith_time_message;
        }
        if (
          response.ShiftClockDistanceTimeVerification[0]
            .clock_out_distance_within_time
        ) {
          values.clock_out_verfication = 'clock_out_distance_within_time';
          values.clock_out_warning =
            response.ShiftClockDistanceTimeVerification[0].clock_out_distance_within_time_message;
        }
        if (
          response.ShiftClockDistanceTimeVerification[0]
            .clock_out_outwith_distance_outwith_time
        ) {
          values.clock_out_verfication =
            'clock_out_outwith_distance_outwith_time';
          values.clock_out_warning =
            response.ShiftClockDistanceTimeVerification[0].clock_out_outwith_distance_outwith_time_message;
        }
        if (
          response.ShiftClockDistanceTimeVerification[0] &&
          response.ShiftClockDistanceTimeVerification[0]
            .clock_out_outwith_distance_within_time
        ) {
          values.clock_out_verfication =
            'clock_out_outwith_distance_within_time';
          values.clock_out_warning =
            response.ShiftClockDistanceTimeVerification[0].clock_out_outwith_distance_within_time_message;
        }

        //from get api  responsse  fieldvalues assigned below
        values.verification_distance_clock_in =
          response.ShiftClockValidationCheck[0].clock_in_verification_distance;
        values.verification_time_clock_in =
          response.ShiftClockValidationCheck[0].clock_in_verification_time;
        values.verification_distance_clock_out =
          response.ShiftClockValidationCheck[0].clock_out_verification_distance;
        values.verification_time_clock_out =
          response.ShiftClockValidationCheck[0].clock_out_verification_time;
        values.is_manual_clock_in =
          response.ShiftClockValidationCheck[0].is_manual_clock_in;
        values.clock_in_qr = response.ShiftClockValidationCheck[0].clock_in_qr;
        values.clock_in_nfc =
          response.ShiftClockValidationCheck[0].clock_in_nfc;

        values.is_manual_clock_out =
          response.ShiftClockValidationCheck[0].is_manual_clock_out;
        values.clock_out_nfc =
          response.ShiftClockValidationCheck[0].clock_out_nfc;
        values.clock_out_qr =
          response.ShiftClockValidationCheck[0].clock_out_qr;

        values.incorporate_auto_clock_out_rule =
          response.ShiftClockValidationCheck[0].incorporate_auto_clock_out_rule;
        values.incorporate_auto_clock_out_rule_message =
          response.ShiftClockValidationCheck[0].incorporate_auto_clock_out_rule_message;
        ///incorporate_auto_clock_out_rule -text warning missing
        values.undo_clock_in =
          response.ShiftClockValidationCheck[0].undo_clock_in;
        values.undo_clock_out =
          response.ShiftClockValidationCheck[0].undo_clock_out;
        values.undo_cancel_clock =
          response.ShiftClockValidationCheck[0].undo_cancel_clock;

        values.actual_travel_distance =
          response.ShiftClockValidationCheck[0].actual_travel_distance;
        values.adjust_travel_distance =
          response.ShiftClockValidationCheck[0].adjust_travel_distance;
        values.actual_travel_time =
          response.ShiftClockValidationCheck[0].actual_travel_time;
        values.adjust_travel_time = moment(
          response.ShiftClockValidationCheck[0].adjust_travel_time,
          'HH:mm'
        ).format('HH:mm');

        values.shift_early_start =
          response.ShiftTimeClassified &&
          response.ShiftTimeClassified &&
          response.ShiftTimeClassified.find(data => data.shift_type == 'Start')
            .early;
        values.shift_late_start =
          response.ShiftTimeClassified &&
          response.ShiftTimeClassified &&
          response.ShiftTimeClassified.find(data => data.shift_type == 'Start')
            .late;

        values.shift_early_end =
          response.ShiftTimeClassified &&
          response.ShiftTimeClassified &&
          response.ShiftTimeClassified.find(data => data.shift_type == 'End')
            .early;
        values.shift_late_end =
          response.ShiftTimeClassified &&
          response.ShiftTimeClassified &&
          response.ShiftTimeClassified.find(data => data.shift_type == 'End')
            .late;
        values.over_provision = response?.ShiftProvision[0]?.over_provision;
        values.under_provision = response?.ShiftProvision[0]?.under_provision;
        values.is_swap = response?.esvSettings[0]?.is_swap;

        //Missed visit status categorisation filed key missing from BE
        values.shift_missed_status =
          response.ShiftClockValidationCheck[0]?.shift_missed_status;

        values.shift_status = response.ShiftProvision[0]?.over_provision;

        //Visit Status 4 point -  dropdown   needs  to be binded
        values.shift_missed_status_time =
          response.ShiftClockValidationCheck[0]?.shift_missed_status_time;
        values.shift_type = response.ShiftTypeRateRestriction[0]?.shift_type;
        values.is_shift = response.ShiftTypeRateRestriction[0]?.is_shift;
        values.is_visit = response.ShiftTypeRateRestriction[0]?.is_visit;
        values.shift_missed_status_times =
          response.ShiftClockValidationCheck[0]?.shift_missed_status_times;
        values.shift_type = response.ShiftTypeClockRestriction[0]?.shift_type;
        values.is_shift = response.ShiftTypeClockRestriction[0]?.is_shift;
        values.is_visit = response.ShiftTypeClockRestriction[0]?.is_visit;

        values.round_up_validation =
          response.ShiftClockRoundUpDuration[0]?.round_up_validation;
        values.round_down_duration_nearest_validation =
          response.ShiftClockRoundUpDuration[0]?.round_down_duration_nearest_validation;
        values.round_down_duration_nearest =
          response.ShiftClockRoundUpDuration[0]?.round_down_duration_nearest;
        values.round_up_duration_nearest_validation =
          response.ShiftClockRoundUpDuration[0]?.round_up_duration_nearest_validation;
        values.round_up_duration_nearest =
          response.ShiftClockRoundUpDuration[0]?.round_up_duration_nearest;

        values.BandedActualDuration = response.BandedDuration;

        values.clock_in_to_shift = response.ShiftMobileReminder.filter(
          clockindata => clockindata.clock_type == 'Clock In'
        );

        values.clock_out_of_shift = response.ShiftMobileReminder.filter(
          clockoutdata => clockoutdata.clock_type == 'Clock Out'
        );

        values.home_to_client =
          response.ShiftClockValidationCheck[0]?.home_to_client;
        values.client_to_client =
          response.ShiftClockValidationCheck[0]?.client_to_client;
        values.office_to_client =
          response.ShiftClockValidationCheck[0]?.office_to_client;
        values.client_to_office =
          response.ShiftClockValidationCheck[0]?.client_to_office;
        values.client_to_home =
          response.ShiftClockValidationCheck[0]?.client_to_home;

        // New travel code here
        values.home_to_home = response.ShiftClockValidationCheck[0]?.home_to_home;
        values.home_to_office = response.ShiftClockValidationCheck[0]?.home_to_office;
        values.office_to_office = response.ShiftClockValidationCheck[0]?.office_to_office;
        values.office_to_home = response.ShiftClockValidationCheck[0]?.office_to_home;


        values.schedule_travel_time =
          response.ShiftClockValidationCheck[0]?.travel_time_mobile;
        values.client_absence =
          response.ShiftClockValidationCheck[0]?.client_absence;
        values.client_unavailability =
          response.ShiftClockValidationCheck[0]?.client_unavailability;

        values.ShiftTravelModeRestriction = response.ShiftTravelModeRestriction;

        values.ShiftTravelEstimatedTime = response?.ShiftTravelEstimatedTime;

        setTravelName(
          response.drop_down_menu?.travel_name.map(travelname => {
            return { id: travelname, name: travelname };
          })
        );
        setUnitName(
          response.drop_down_menu?.unit_name.map(unit => {
            return { id: unit, name: unit };
          })
        );
      }
      setValues({ ...response, ...values });
      setLoadingModal(false)
    });
  };

  const handleSubmit = async evt => {
    evt.preventDefault();
    if (textStatus === 'SAVED') {
      return;
    }
    setLoadingSave(true);
    if (
      employeeAbscenceHoursLength > 3 ||
      employeeAbscenceMinuteLength > 2 ||
      employeeUnavailabilityHoursLength > 3 ||
      employeeAbscenceHoursValue > 999 ||
      employeeUnavailabilityHoursValue > 999 ||
      employeeUnavailabilityMinuteValue > 59 ||
      employeeAbscenceMinuteValue > 59 ||
      employeeUnavailabilityMinuteLength > 2 ||
      (employeeAbscenceMinuteValue == 0 && employeeAbscenceMinuteLength < 2) ||
      (employeeUnavailabilityMinuteValue == 0 &&
        employeeUnavailabilityMinuteLength < 2)
    ) {
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    }
    if (
      actualTravelTimeHoursLength > 3 ||
      actualTravelTimeHoursValue > 999 ||
      actualTravelTimeMinuteValue > 59 ||
      actualTravelTimeMinuteLength > 2 ||
      (actualTravelTimeMinuteValue == 0 && actualTravelTimeMinuteLength < 2)
    ) {
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    }
    if (
      values.client_absence &&
      values.client_absence.toString().includes(':') == false
    ) {
      setErorEmployeAbscence('absence');
      setErorTimeFormatMag(
        ' Please enter hours & minutes separated with  semicolon in hh:mm format. If only hours then in hh:00 format . If only minutes then in 00:mm format . '
      );
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    }
    if (
      values.client_unavailability &&
      values.client_unavailability.toString().includes(':') == false
    ) {
      setErorEmployeUnavailability('unavailability');
      setErorTimeFormatMag(
        ' Please enter hours & minutes separated with  semicolon in hh:mm format. If only hours then enter in hh:00 format . If only minutes then enter in 00:mm format. '
      );
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    }
    if (
      values.adjust_travel_time &&
      values.adjust_travel_time.toString().includes(':') == false
    ) {
      setErorActualTravelTime('actualTraveltime');
      setActualTravelTimeErorTimeFormatMag(
        ' Please enter hours & minutes separated with  semicolon in hh:mm format. If only hours then in hh:00 format . If only minutes then in 00:mm format . '
      );
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    }
    if (checkBeforeSubmit) {
      setErorTimeRescrtiction('client - client time restriction');
      setErorTimeRescrtictionMag(
        ' Please  check the client - client time restriction - enter hours & minutes separated with  semicolon in hh:mm format. If only hours then enter in hh:00 format . If only minutes then enter in 00:mm format. '
      );
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    }
    if (homeCheckBeforeSubmit) {
      setHomeErorTimeRescrtiction('home - client time restriction');
      setHomeErorTimeRescrtictionMag(
        ' Please  check the home - client time restriction field - enter hours & minutes separated with  semicolon in hh:mm format. If only hours then enter in hh:00 format . If only minutes then enter in 00:mm format. '
      );
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    } else {
      const bodyData = {
        ShiftClockValidationCheck: dataClockValidationCheck,
        ShiftClockDistanceTimeVerification: dataClockDistanceTimeVerification,
        ShiftClockRoundUpDuration: dataClockRoundUpDuration,
        ShiftMobileReminder: StoredMobilReminderData,
        ShiftBandedDuration: dataBandedDuration,
        ShiftProvision: dataVisitProvision,
        ShiftTimeClassified: dataVisitTimeClassified,
        ShiftTravelModeRestriction: dataTravel_mode_restriction_serializer,
        ShiftTravelEstimatedTime: dataTravel_estimate_serializer,
        ShiftTypeRateRestriction: ShiftTypeRateRestrictionData,
        ShiftTypeClockRestriction: ShiftTypeClockRestrictions,
        esvSettings: { is_swap: values.is_swap, id: "da53f6fb-97f7-4fef-bcf8-9aa70a6090f4" }
      };
      await editSettingEsv(bodyData)
        .then(res => {
          if (res.status === 'Success') {
            onSuccess();
          } else {
            onError();
          }
        })
        .catch(onError);
    }
  };

  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
  };

  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    // setShowError(true);
    setCheckRequires(true);
    setErrorMessage(
      'Some of the fields have invalid or empty values. Please review.'
    );
  };

  const dropDownHandleSelect = (item, nameItem, index) => {
    console.log(item, nameItem, index, 'KKKKKK');
    values[nameItem] = item[nameItem];
    if (nameItem == 'travel_mode') {
      values.ShiftTravelModeRestriction[index].travel_mode = item[nameItem];
    }
    if (nameItem == 'clock_in_verfication') {
      if (item[nameItem] == 'clock_in_distance_outwith_time') {
        values.clock_in_warning =
          'Are you sure you want to clock-in now? The clock-in time is later than expected';
        values.clock_in_distance_outwith_time = true;
      } else {
        values.clock_in_distance_outwith_time = false;
      }

      if (item[nameItem] == 'clock_in_outwith_distance_within_time') {
        values.clock_in_warning =
          'Are you sure you want to clock-in now? The clock-in time is later than expected';
        values.clock_in_outwith_distance_within_time = true;
      } else {
        values.clock_in_outwith_distance_within_time = false;
      }

      if (item[nameItem] == 'clock_in_outwith_distance_outwith_time') {
        values.clock_in_warning =
          'clock-in warning - are you sure you want to clock-in now? You are outwith distance from service address and the clock-in time is late';
        values.clock_in_outwith_distance_outwith_time = true;
      } else {
        values.clock_in_outwith_distance_outwith_time = false;
      }

      if (item[nameItem] == 'clock_in_distance_within_time') {
        values.clock_in_warning =
          'Are you sure you want to clock-in now? The clock-in time is earlier than expected';
        values.clock_in_distance_within_time = true;
      } else {
        values.clock_in_distance_within_time = false;
      }

      if (item[nameItem] == null) {
        values.clock_in_warning = null;
      }
    }

    if (nameItem == 'clock_out_verfication') {
      if (item[nameItem] == 'clock_out_distance_within_time') {
        values.clock_out_warning =
          'Are you sure you want to clock-in now? The clock-in time is earlier than expected';

        values.clock_out_distance_within_time = true;
      } else {
        values.clock_out_distance_within_time = false;
      }

      if (item[nameItem] == 'clock_out_distance_outwith_time') {
        values.clock_out_warning =
          'clock-out warning - are you sure you want to clock-out now? You are outwith distance from service address and the clock-out time is late';
        values.clock_out_distance_outwith_time = true;
      } else {
        values.clock_out_distance_outwith_time = false;
      }
      if (item[nameItem] == 'clock_out_outwith_distance_within_time') {
        values.clock_out_warning =
          'clock-in warning - are you sure you want to clock-in now? You are outwith distance from service address and the clock-in time is early';
        values.clock_out_outwith_distance_within_time = true;
      } else {
        values.clock_out_outwith_distance_within_time = false;
      }
      if (item[nameItem] == 'clock_out_outwith_distance_outwith_time') {
        values.clock_out_warning =
          'Are you sure you want to clock-in now? The clock-in time is earlier than expected';
        values.clock_out_outwith_distance_outwith_time = true;
      } else {
        values.clock_out_outwith_distance_outwith_time = false;
      }
      if (item[nameItem] == null) {
        values.clock_out_warning = null;
      }
    }

    setValues({ ...values });
  };

  const addNewBandedActualDurations = () => {
    if (!values.BandedActualDuration) values.BandedActualDuration = [];
    values.BandedActualDuration.push({});
    setValues({ ...values });
  };

  const deleteBandedActualDurations = (data, index) => {
    // toDeleteVisitEmployee.push({...data});
    if (values.BandedActualDuration && values.BandedActualDuration.length > 0) {
      values.BandedActualDuration.splice(index, 1);
    }
    setValues({ ...values });
  };

  const addTravelModeRestriction = () => {
    if (!values.ShiftTravelModeRestriction)
      values.ShiftTravelModeRestriction = [];
    values.ShiftTravelModeRestriction.push({});
    setValues({ ...values });
  };
  const AddNewAvailability = () => {
    if (!values.ShiftTypeRateRestriction) values.ShiftTypeRateRestriction = [];
    values.ShiftTypeRateRestriction.push({});
    setValues({ ...values });
  };
  const AddNewAvailabilitys = () => {
    if (!values.ShiftTypeClockRestriction)
      values.ShiftTypeClockRestriction = [];
    values.ShiftTypeClockRestriction.push({});
    setValues({ ...values });
  };

  const deleteTravelModeRestriction = (data, index) => {
    if (
      values.ShiftTravelModeRestriction &&
      values.ShiftTravelModeRestriction.length > 0
    ) {
      values.ShiftTravelModeRestriction.splice(index, 1);
    }
    setValues({ ...values });
  };

  const addClockInToVisit = () => {
    if (!values.clock_in_to_shift) values.clock_in_to_shift = [];
    values.clock_in_to_shift.push({});
    setValues({ ...values });
  };

  const deleteClockInToVisit = (data, index) => {
    if (values.clock_in_to_shift && values.clock_in_to_shift.length > 0) {
      values.clock_in_to_shift.splice(index, 1);
    }
    setValues({ ...values });
  };

  const addClockOutToVisit = () => {
    if (!values.clock_out_of_shift) values.clock_out_of_shift = [];
    values.clock_out_of_shift.push({});
    setValues({ ...values });
  };

  const deleteClockOutToVisit = (data, index) => {
    if (values.clock_out_of_shift && values.clock_out_of_shift.length > 0) {
      values.clock_out_of_shift.splice(index, 1);
    }
    setValues({ ...values });
  };

  const setHoursMinute = () => {
    var EmployeeAbscence =
      values.client_absence && values.client_absence.toString().split(':');
    if (
      EmployeeAbscence &&
      EmployeeAbscence[0] &&
      EmployeeAbscence[0] !== undefined
    ) {
      setEmployeeAbscenceHoursLength(EmployeeAbscence[0].length);
      setEmployeeAbscenceHoursValue(EmployeeAbscence[0]);
    }
    if (
      EmployeeAbscence &&
      EmployeeAbscence[1] &&
      EmployeeAbscence[1] !== undefined
    ) {
      setEmployeeAbscenceMinuteLength(EmployeeAbscence[1].length);
      setEmployeeAbscenceMinuteValue(EmployeeAbscence[1]);
    }

    var EmployeeUnavailability =
      values.client_unavailability &&
      values.client_unavailability.toString().split(':');
    if (
      EmployeeUnavailability &&
      EmployeeUnavailability[0] &&
      EmployeeUnavailability[0] !== undefined
    ) {
      setEmployeeUnavailabilityHoursLength(EmployeeUnavailability[0].length);
      setEmployeeUnavailabilityHoursValue(EmployeeUnavailability[0]);
    }
    if (
      EmployeeUnavailability &&
      EmployeeUnavailability[1] &&
      EmployeeUnavailability[1] !== undefined
    ) {
      setEmployeeUnavailabilityMinuteLength(EmployeeUnavailability[1].length);
      setEmployeeUnavailabilityMinuteValue(EmployeeUnavailability[1]);
    }

    var actualTimeTravel =
      values.adjust_travel_time &&
      values.adjust_travel_time.toString().split(':');
    if (
      actualTimeTravel &&
      actualTimeTravel[0] &&
      actualTimeTravel[0] !== undefined
    ) {
      setActualTravelTimeHoursLength(actualTimeTravel[0].length);
      setActualTravelTimeHoursValue(actualTimeTravel[0]);
    }
    if (
      actualTimeTravel &&
      actualTimeTravel[1] &&
      actualTimeTravel[1] !== undefined
    ) {
      setActualTravelTimeMinuteLength(actualTimeTravel[1].length);
      setActualTravelTimeMinuteValue(actualTimeTravel[1]);
    }

    if (
      values.client_absence &&
      values.client_absence.toString().includes(':')
    ) {
      setErorEmployeAbscence('');
      setErorTimeFormatMag(null);
    }

    if (
      values.client_unavailability &&
      values.client_unavailability.toString().includes(':')
    ) {
      setErorEmployeUnavailability('');
      setErorTimeFormatMag(null);
    }

    if (
      values.adjust_travel_time &&
      values.adjust_travel_time.toString().includes(':')
    ) {
      setErorActualTravelTime('');
      setActualTravelTimeErorTimeFormatMag(null);
    }
  };

  const addNewTravelTimeEstimate = () => {
    if (!values.ShiftTravelEstimatedTime) values.ShiftTravelEstimatedTime = [];
    values.ShiftTravelEstimatedTime.push({});
    setValues({ ...values });
  };

  const deleteTravelTimeEstimate = (data, index) => {
    // toDeleteVisitEmployee.push({...data});
    if (
      values.ShiftTravelEstimatedTime &&
      values.ShiftTravelEstimatedTime.length > 0
    ) {
      values.ShiftTravelEstimatedTime.splice(index, 1);
    }
    setValues({ ...values });
  };
  const deleteAvailability = (data, index) => {
    // toDeleteVisitEmployee.push({...data});
    if (
      values.ShiftTypeRateRestriction &&
      values.ShiftTypeRateRestriction.length > 0
    ) {
      values.ShiftTypeRateRestriction.splice(index, 1);
    }
    setValues({ ...values });
  };

  const deleteAvailabilitys = (data, index) => {
    // toDeleteVisitEmployee.push({...data});
    if (
      values.ShiftTypeClockRestriction &&
      values.ShiftTypeClockRestriction.length > 0
    ) {
      values.ShiftTypeClockRestriction.splice(index, 1);
    }
    setValues({ ...values });
  };

  const dropDownHandleSelectTravelEstimate = (
    item,
    nameItem,
    TraveEstimate,
    index
  ) => {
    values[TraveEstimate][index][nameItem] = item[nameItem];
    setValues({ ...values });
  };
  const dropDownHandleSelectAvailablity = (
    item,
    nameItem,
    TraveEstimate,
    index
  ) => {

    values[TraveEstimate][index][nameItem] = item[nameItem];
    setValues({ ...values });
  };
  const dropDownHandleSelectAvailablitys = (
    item,
    nameItem,
    TraveEstimate,
    index
  ) => {

    values[TraveEstimate][index][nameItem] = item[nameItem];

    setValues({ ...values });
  };

  console.log(values, 'BBBBBBB');
  return (
    <div>
      <ComponentDynamic loading={loadingModal}>
        {/* <Title className={'ms-1 mb-2 pb-1 '}>Settings ESV</Title> */}
        <div className="mt-2  mb-1 pt-1 ms-1">
          <small>
            {' '}
            <TitleSection>Clock-in verfication</TitleSection>
          </small>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item
              className="pe-1  pe-0 pe-md-1 pt-1"
              ResizeWidth={'25%'}>
              <DropdownSearchable
                placeHolder={'Clock-in verfication'}
                options={[
                  {
                    id: 'clock_in_distance_within_time',
                    name: 'Clock-in - in distance, within time',
                  },
                  {
                    id: 'clock_in_distance_outwith_time',
                    name: 'Clock-in - in distance, outwith time',
                  },
                  {
                    id: 'clock_in_outwith_distance_within_time',
                    name: 'Clock-in - outwith distance, within time',
                  },
                  {
                    id: 'clock_in_outwith_distance_outwith_time',
                    name: 'Clock-in - outwith distance, outwith time',
                  },
                ]}
                //    disabled={!checkUpdatePermission}
                selectedOption={values ? values.clock_in_verfication : null}
                onChange={value =>
                  dropDownHandleSelect(
                    { clock_in_verfication: value ? value : null },
                    'clock_in_verfication'
                  )
                }
              />
              {/* <small> <TitleSection  className='text-warning '>{clockInWarningMessage}</TitleSection></small> */}
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pt-1  ">
              <InputLabelTop
                //   disabled={!checkUpdatePermission}
                type="text"
                // id="verification_distance"
                label={'Clock-in  warning'}
                {...useInput(`clock_in_warning`)}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pt-1  ">
              <InputLabelTop
                //   disabled={!checkUpdatePermission}
                type="number"
                id="verification_distance_clock_in"
                label={'Verification  Distance Clock-in(meters)'}
                onKeyPress={KeyPress.DECIMAL}
                {...useInput(`verification_distance_clock_in`)}
                maxlength={4}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pt-1  ">
              <TimeSelector
                labelTop={'Verification  Time Clock-in (hours)'}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                {...useInput('verification_time_clock_in')}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>

        <div className="mt-2  mb-1 pt-1 ms-1">
          <small>
            {' '}
            <TitleSection>Clock-out verification</TitleSection>
          </small>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <DropdownSearchable
                placeHolder={'Clock-out verification'}
                options={[
                  {
                    id: 'clock_out_distance_within_time',
                    name: 'Clock-out - in distance, within time',
                  },
                  {
                    id: 'clock_out_distance_outwith_time',
                    name: 'Clock-out - in distance, outwith time',
                  },
                  {
                    id: 'clock_out_outwith_distance_within_time',
                    name: 'Clock-out - outwith distance, within time',
                  },
                  {
                    id: 'clock_out_outwith_distance_outwith_time',
                    name: 'Clock-out - outwith distance, outwith time',
                  },
                ]}
                //    disabled={!checkUpdatePermission}
                selectedOption={values ? values.clock_out_verfication : null}
                onChange={value =>
                  dropDownHandleSelect(
                    { clock_out_verfication: value ? value : null },
                    'clock_out_verfication'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pt-1  ">
              <InputLabelTop
                //   disabled={!checkUpdatePermission}
                type="text"
                // id="verification_distance"
                label={'Clock-out  warning'}
                {...useInput(`clock_out_warning`)}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pt-1  ">
              <InputLabelTop
                //   disabled={!checkUpdatePermission}
                type="text"
                id="verification_distance"
                label={'Verification  Distance Clock-out (meters)'}
                onKeyPress={KeyPress.DECIMAL}
                {...useInput(`verification_distance_clock_out`)}
                maxlength={4}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pt-1  ">
              <TimeSelector
                labelTop={'Verification  Time  Clock-out (hours)'}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                {...useInput('verification_time_clock_out')}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>

        <div className="mt-3  mb-1 pt-2 ms-1">
          <small>
            {' '}
            <TitleSection>Clock-in Method</TitleSection>
          </small>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <div className=" mt-1 d-flex">
                <div className=" ">
                  <small>
                    {' '}
                    <TitleSection>Manual</TitleSection>
                  </small>
                </div>
                <div className=" ps-1  ms-1">
                  <ToggleButton
                    useInput={useInput('is_manual_clock_in')}
                    id="is_manual_clock_in"
                  />
                </div>
              </div>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <div className=" mt-1 d-flex">
                <div className="  ">
                  <small>
                    {' '}
                    <TitleSection>QR code</TitleSection>
                  </small>
                </div>
                <div className=" ps-1  ms-1">
                  <ToggleButton
                    useInput={useInput('clock_in_qr')}
                    id="clock_in_qr"
                  />
                </div>
              </div>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <div className=" mt-1 d-flex">
                <div className="  ">
                  <small>
                    {' '}
                    <TitleSection>NFC</TitleSection>
                  </small>
                </div>
                <div className=" ps-1  ms-1">
                  <ToggleButton
                    useInput={useInput('clock_in_nfc')}
                    id="clock_in_nfc"
                  />
                </div>
              </div>
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>

        <div className="mt-2  mb-1 pt-1 ms-1">
          <small>
            {' '}
            <TitleSection>Clock-out Method</TitleSection>
          </small>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <div className=" mt-1 d-flex">
                <div className=" ">
                  <small>
                    {' '}
                    <TitleSection>Manual</TitleSection>
                  </small>
                </div>
                <div className=" ps-1  ms-1">
                  <ToggleButton
                    useInput={useInput('is_manual_clock_out')}
                    id="is_manual_clock_out"
                  />
                </div>
              </div>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <div className=" mt-1 d-flex">
                <div className="  ">
                  <small>
                    {' '}
                    <TitleSection>QR code</TitleSection>
                  </small>
                </div>
                <div className=" ps-1  ms-1">
                  <ToggleButton
                    useInput={useInput('clock_out_qr')}
                    id="clock_out_qr"
                  />
                </div>
              </div>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <div className=" mt-1 d-flex">
                <div className="  ">
                  <small>
                    {' '}
                    <TitleSection>NFC</TitleSection>
                  </small>
                </div>
                <div className=" ps-1  ms-1">
                  <ToggleButton
                    useInput={useInput('clock_out_nfc')}
                    id="clock_out_nfc"
                  />
                </div>
              </div>
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>

        <div className="mt-3  mb-1 pt-2 ms-1">
          <small>
            <b>
              <TitleSection>Auto clock-out rules</TitleSection>
            </b>{' '}
          </small>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <div className=" mt-1 d-flex">
                <div className=" ">
                  <small>
                    {' '}
                    <TitleSection>Incorporate auto-clock out rule</TitleSection>
                  </small>
                </div>
                <div className=" ps-1  ms-1">
                  <ToggleButton
                    useInput={useInput('incorporate_auto_clock_out_rule')}
                    id="incorporate_auto_clock_out_rule"
                  />
                </div>
              </div>
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pt-1  ">
              <InputLabelTop
                //   disabled={!checkUpdatePermission}
                type="text"
                // id="verification_distance"
                label={'text-warning'}
                {...useInput(`incorporate_auto_clock_out_rule_message`)}
              // maxlength={4}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>
        <div className="mt-3  mb-1 pt-1 ms-1">
          <small>
            <b>
              <TitleSection>
                Allow care worker to clock-in clock-out amend on mobile
              </TitleSection>
            </b>{' '}
          </small>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <div className=" mt-1 d-flex">
                <div className=" ">
                  <small>
                    {' '}
                    <TitleSection>Un-do clock-in</TitleSection>
                  </small>
                </div>
                <div className=" ps-1  ms-1">
                  <ToggleButton
                    useInput={useInput('undo_clock_in')}
                    id="undo_clock_in"
                  />
                </div>
              </div>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <div className=" mt-1 d-flex">
                <div className=" ">
                  <small>
                    {' '}
                    <TitleSection>Un-do clock-out</TitleSection>
                  </small>
                </div>
                <div className=" ps-1  ms-1">
                  <ToggleButton
                    useInput={useInput('undo_clock_out')}
                    id="undo_clock_in"
                  />
                </div>
              </div>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <div className=" mt-1 d-flex">
                <div className=" ">
                  <small>
                    {' '}
                    <TitleSection>Cancel - clock-in and clock-out</TitleSection>
                  </small>
                </div>
                <div className=" ps-1  ms-1">
                  <ToggleButton
                    useInput={useInput('undo_cancel_clock')}
                    id="undo_cancel_clock"
                  />
                </div>
              </div>
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>

        <div className="mt-3  mb-1 pt-2 ms-1">
          <small>
            <b>
              <TitleSection>
                A shift is classified as Early or Late Start/End after the
                following time differences from scheduled start/end times:
              </TitleSection>
            </b>{' '}
          </small>
          <div>
            <small>
              <b>Visit Start Late/Early</b>
            </small>
          </div>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <TimeSelector
                labelTop={'Visit Early Start'}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                {...useInput('shift_early_start')}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <TimeSelector
                labelTop={'Visit Late Start'}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                {...useInput('shift_late_start')}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>

          <div>
            <small>
              <b>Shift End Late/Early</b>
            </small>
          </div>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <TimeSelector
                labelTop={'Visit Early End'}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                {...useInput('shift_early_end')}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <TimeSelector
                labelTop={'Visit Late End'}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                {...useInput('shift_late_end')}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>

        <div className="mt-3  mb-1 pt-2 ms-1">
          <small>
            <b>
              <TitleSection>
                A shift is classified as Under or Over Provision after the
                following duration difference from scheduled durations:
              </TitleSection>
            </b>{' '}
          </small>
          <div>
            <small>
              <b>Visit Provision</b>
            </small>
          </div>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <TimeSelector
                labelTop={'Underprovision'}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                {...useInput('under_provision')}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <TimeSelector
                labelTop={'Overprovision'}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                {...useInput('over_provision')}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>

        <div className="mt-3  mb-1 pt-1 ms-1">
          <small>
            <b>
              <TitleSection>Missed shift status categorisation</TitleSection>
            </b>{' '}
          </small>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>
                    If a shift has passed xx number of hh:mm after scheduled
                    shift start time
                  </TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <TimeSelector
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                {...useInput('shift_missed_status_time')}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>

          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>
                    If a shift has passed and the next client shift has been
                    reached then classify as missed
                  </TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('shift_missed_status')}
                id="shift_missed_status"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>

        <div className="mt-3  mb-1 pt-3 ms-1">
          <small>
            {' '}
            <TitleSection>Visit Status</TitleSection>
          </small>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                {' '}
                <TitleSection>Not clocked-in and not clocked-out</TitleSection>
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <InputLabelTop
                disabled={true}
                type="text"
                label={'Not Started'}
                // {...useInput(`visit_status_name_not_started`) }
                value={vistssStatusNotStatrted}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                {' '}
                <TitleSection>Clocked-in but not clocked-out</TitleSection>
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <InputLabelTop
                disabled={true}
                type="text"
                label={'In Progress'}
                value={vistsStatusInProgress}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                {' '}
                <TitleSection>Clocked-in and clocked-out</TitleSection>
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <InputLabelTop
                disabled={true}
                type="text"
                label={'Completed'}
                value={vistsStatusCompleted}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                {' '}
                <TitleSection>If not clocked-in by xx hh:mm after</TitleSection>
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <InputLabelTop
                disabled={true}
                type="text"
                label={'Missed'}
                value={vistsStatusMissed}
              />
              {/* //from choices missied ....ye uppar  */}
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>

        <div className="mt-3  mb-1 pt-1 ms-1">
          <small>
            <b>
              <TitleSection>Rounding settings on actual durations</TitleSection>
            </b>{' '}
          </small>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>Do not round - actual times only</TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('round_up_validation')}
                id="round_up_validation"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>

          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>Round up duration to nearest</TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <TimeSelector
                labelTop={'Round up duration to nearest'}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                {...useInput('round_up_duration_nearest')}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('round_up_duration_nearest_validation')}
                id="round_up_duration_nearest_validation"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>

          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>Round down duration to nearest</TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <TimeSelector
                labelTop={'Round down duration to nearest<'}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
                {...useInput('round_down_duration_nearest')}
                placeholderText={'hh:mm'}
                locale={'sv-sv'}
                timeCaption={'Duration'}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('round_down_duration_nearest_validation')}
                id="round_down_duration_nearest_validation"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>

        <div className="mt-3 mb-3">
          <div className="mt-1">
            {' '}
            <small>
              {' '}
              <TitleSection>Banded actual durations</TitleSection>
            </small>
          </div>
          {values.BandedActualDuration &&
            values.BandedActualDuration.length >= 1 &&
            values.BandedActualDuration.map((data, index) => {
              return (
                <BandedActualDurations
                  index={index}
                  data={data}
                  setValues={setValues}
                  useInput={useInput}
                  values={values}
                  addNewBandedActualDurations={addNewBandedActualDurations}
                  deleteBandedActualDurations={deleteBandedActualDurations}
                />
              );
            })}
        </div>
        <div
          className={'d-flex justify-content-center justify-content-md-start'}>
          <div className={'mt-2'}>
            <PrimaryButtonForm
              minWidth="6rem"
              onClick={addNewBandedActualDurations}>
              <span>{PLUS} Banded Actual Durations</span>
            </PrimaryButtonForm>
          </div>
        </div>

        <div className="mt-3  pt-2  mb-2">
          <div className="mt-1 ">
            {' '}
            <small>
              <b>
                <TitleSection>Reminders / Notifications to mobile</TitleSection>
              </b>{' '}
            </small>
          </div>
          <div className="mt-1 ">
            {' '}
            <small>
              {' '}
              <TitleSection>Clock-in to shift</TitleSection>
            </small>
          </div>
          {values.clock_in_to_shift &&
            values.clock_in_to_shift.length >= 1 &&
            values.clock_in_to_shift.map((data, index) => {
              return (
                <ClockInToShift
                  index={index}
                  data={data}
                  setValues={setValues}
                  useInput={useInput}
                  values={values}
                  addClockInToVisit={addClockInToVisit}
                  deleteClockInToVisit={deleteClockInToVisit}
                />
              );
            })}
        </div>
        <div
          className={'d-flex justify-content-center justify-content-md-start'}>
          <div className={'mt-1 mb-1'}>
            <PrimaryButtonForm minWidth="6rem" onClick={addClockInToVisit}>
              <span>{PLUS} Clock-in to shift</span>
            </PrimaryButtonForm>
          </div>
        </div>

        <div className="mt-2 pt-1 mb-2">
          <div className="mt-2">
            <small>
              {' '}
              <TitleSection>Clock-out to shift</TitleSection>
            </small>
          </div>
          {values.clock_out_of_shift &&
            values.clock_out_of_shift.length >= 1 &&
            values.clock_out_of_shift.map((data, index) => {
              return (
                <ClockOutOfShift
                  index={index}
                  data={data}
                  setValues={setValues}
                  useInput={useInput}
                  values={values}
                  addClockOutToVisit={addClockOutToVisit}
                  deleteClockOutToVisit={deleteClockOutToVisit}
                />
              );
            })}
        </div>
        <div
          className={'d-flex justify-content-center justify-content-md-start'}>
          <div className={'mt-2'}>
            <PrimaryButtonForm minWidth="6rem" onClick={addClockOutToVisit}>
              <span>{PLUS}Clock-out of shift</span>
            </PrimaryButtonForm>
          </div>
        </div>

        <div className="mt-3  mb-1 pt-2 ms-1">
          <small>
            <b>
              <TitleSection>
                Which travel times should be included in the scheduler travel
                time value:
              </TitleSection>
            </b>{' '}
          </small>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>home-client</TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('home_to_client')}
                id="home_to_client"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>

          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>client-client</TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('client_to_client')}
                id="client_to_client"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>

          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>office-client</TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('office_to_client')}
                id="office_to_client"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>client-office</TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('client_to_office')}
                id="client_to_office"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>client-home</TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('client_to_home')}
                id="client_to_home"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>

   {/* New Travel Code added here 
   //
   //
   //
   
   */}
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>home-home</TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('home_to_home')}
                id="home_to_home"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>

          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>home-office</TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('home_to_office')}
                id="home_to_office"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>


          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>office-home</TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('office_to_home')}
                id="office_to_home"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>

          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>office-office</TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('office_to_office')}
                id="office_to_office"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>


          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>
                    Add in scheduled travel time on mobile schedule
                  </TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <ToggleButton
                useInput={useInput('schedule_travel_time')}
                id="schedule_travel_time"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>

          <FlexGrid3Container className={'w-100 mb-1 mt-3 pt-2'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>
                    Employee is allowed to update unavailability within xx hh:mm
                    of shift date
                  </TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <InputLabelTop
                //   disabled={!checkUpdatePermission}
                type="text"
                id="verification_distance"
                placeholder={'hh:mm'}
                // onKeyPress={KeyPress.DECIMAL}
                {...useInput(`client_unavailability`)}
                maxlength={6}
              />
              <small>
                {employeeUnavailabilityHoursLength > 3 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format .Hours max. upto
                    3 digits{' '}
                  </p>
                ) : null}
                {employeeUnavailabilityHoursValue > 999 ? (
                  <p style={{ color: 'red' }}>Hours should be less than 999 . </p>
                ) : null}

                {employeeUnavailabilityMinuteLength > 2 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format . Minute max.
                    upto 2 digits
                  </p>
                ) : null}
                {employeeUnavailabilityMinuteValue > 59 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format . Minutes should
                    be less than 59 .
                  </p>
                ) : null}
                {employeeUnavailabilityMinuteValue == 0 &&
                  employeeUnavailabilityMinuteLength < 2 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format . If minutes is
                    0 then enter in hh:00 format .
                  </p>
                ) : null}
                {erorEmployeUnavailability == 'unavailability' ? (
                  <p style={{ color: 'red' }}>{erorTimeFormatMag}</p>
                ) : null}
              </small>
            </FlexGrid3Item>
          </FlexGrid3Container>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                <b>
                  <TitleSection>
                    Employee is allowed to update absence within xx hh:mm of
                    shift date
                  </TitleSection>
                </b>{' '}
              </small>
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <InputLabelTop
                //   disabled={!checkUpdatePermission}
                type="text"
                id="verification_distance"
                // label={'Time(hh:mm)'}
                placeholder={'hh:mm'}
                // onKeyPress={KeyPress.DECIMAL}
                {...useInput(`client_absence`)}
                maxlength={6}
              />
              <small>
                {employeeAbscenceHoursLength > 3 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format .Hours max. upto
                    3 digits .{' '}
                  </p>
                ) : null}
                {employeeAbscenceHoursValue > 999 ? (
                  <p style={{ color: 'red' }}> Hours should be less than 999 .</p>
                ) : null}

                {employeeAbscenceMinuteLength > 2 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format . Minute max.
                    upto 2 digits.
                  </p>
                ) : null}
                {employeeAbscenceMinuteValue > 59 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format . Minutes should
                    be less than 59 .
                  </p>
                ) : null}

                {erorEmployeAbscence == 'absence' ? (
                  <p style={{ color: 'red' }}> {erorTimeFormatMag}</p>
                ) : null}

                {employeeAbscenceMinuteValue == 0 &&
                  employeeAbscenceMinuteLength < 2 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format . If minutes is
                    0 then enter in hh:00 format .
                  </p>
                ) : null}
              </small>
            </FlexGrid3Item>
          </FlexGrid3Container>
          {/* //new section  */}
          <div className="mt-3  pt-2   pb-2 mb-2">
            <div className="mt-1 ">
              {' '}
              <small>
                <b>
                  <TitleSection>Travel mode restriction</TitleSection>
                </b>{' '}
              </small>
            </div>
            {values.ShiftTravelModeRestriction &&
              values.ShiftTravelModeRestriction.length >= 1 &&
              values.ShiftTravelModeRestriction.map((data, index) => {
                return (
                  <TravelModeRestrictions
                    index={index}
                    data={data}
                    setValues={setValues}
                    useInput={useInput}
                    values={values}
                    addTravelModeRestriction={addTravelModeRestriction}
                    deleteTravelModeRestriction={deleteTravelModeRestriction}
                    travelType={travelType}
                    dropDownHandleSelect={dropDownHandleSelect}
                    timeRescrtictionHoursLength={timeRescrtictionHoursLength}
                    setTimeRescrtictionHoursLength={
                      setTimeRescrtictionHoursLength
                    }
                    timeRescrtictionHoursValue={timeRescrtictionHoursValue}
                    setTimeRescrtictionHoursValue={
                      setTimeRescrtictionHoursValue
                    }
                    timeRescrtictionMinuteLength={timeRescrtictionMinuteLength}
                    setTimeRescrtictionMinuteLength={
                      setTimeRescrtictionMinuteLength
                    }
                    setTimeRescrtictionMinuteValue={
                      setTimeRescrtictionMinuteValue
                    }
                    timeRescrtictionMinuteValue={timeRescrtictionMinuteValue}
                    erorTimeRescrtiction={erorTimeRescrtiction}
                    setErorTimeRescrtiction={setErorTimeRescrtiction}
                    erorTimeRescrtictionMag={erorTimeRescrtictionMag}
                    setErorTimeRescrtictionMag={setErorTimeRescrtictionMag}
                    setCheckBeforeSubmit={setCheckBeforeSubmit}
                    //props below for second field
                    homeTimeRescrtictionHoursLength={
                      homeTimeRescrtictionHoursLength
                    }
                    setHomeTimeRescrtictionHoursLength={
                      setHomeTimeRescrtictionHoursLength
                    }
                    homeTimeRescrtictionHoursValue={
                      homeTimeRescrtictionHoursValue
                    }
                    setHomeTimeRescrtictionHoursValue={
                      setHomeTimeRescrtictionHoursValue
                    }
                    homeTimeRescrtictionMinuteLength={
                      homeTimeRescrtictionMinuteLength
                    }
                    setHomeTimeRescrtictionMinuteLength={
                      setHomeTimeRescrtictionMinuteLength
                    }
                    setHomeTimeRescrtictionMinuteValue={
                      setHomeTimeRescrtictionMinuteValue
                    }
                    homeTimeRescrtictionMinuteValue={
                      homeTimeRescrtictionMinuteValue
                    }
                    homeErorTimeRescrtiction={homeErorTimeRescrtiction}
                    setHomeErorTimeRescrtiction={setHomeErorTimeRescrtiction}
                    homeErorTimeRescrtictionMag={homeErorTimeRescrtictionMag}
                    setHomeErorimeRescrtictionMag={
                      setHomeErorTimeRescrtictionMag
                    }
                    setHomeCheckBeforeSubmit={setHomeCheckBeforeSubmit}
                  />
                );
              })}
          </div>
          <div
            className={
              'd-flex justify-content-center justify-content-md-start'
            }>
            <div className={'mt-1 mb-1'}>
              <PrimaryButtonForm
                minWidth="6rem"
                onClick={addTravelModeRestriction}>
                <span>{PLUS}Travel mode restriction</span>
              </PrimaryButtonForm>
            </div>
          </div>
          <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <small>
                {timeRescrtictionHoursLength > 3 ? (
                  <p style={{ color: 'red' }}>
                    Please check 'client - client time restriction ' : Enter the
                    hours and minute in hh: mm format .Hours max. upto 3 digits
                    .{' '}
                  </p>
                ) : null}
                {timeRescrtictionHoursValue > 999 ? (
                  <p style={{ color: 'red' }}>
                    {' '}
                    Please check 'client - client time restriction ' : Hours
                    should be less than 999 .
                  </p>
                ) : null}

                {timeRescrtictionMinuteLength > 2 ? (
                  <p style={{ color: 'red' }}>
                    Please check 'client - client time restriction ' : Enter the
                    hours and minute in hh: mm format . Minute max. upto 2
                    digits.
                  </p>
                ) : null}
                {timeRescrtictionMinuteValue > 59 ? (
                  <p style={{ color: 'red' }}>
                    Please check 'client - client time restriction ' : Enter the
                    hours and minute in hh: mm format . Minutes should be less
                    than 59 .
                  </p>
                ) : null}
                {timeRescrtictionMinuteValue == 0 &&
                  timeRescrtictionMinuteLength < 2 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format . If minutes is
                    0 then enter in hh:00 format .
                  </p>
                ) : null}
                {erorTimeRescrtiction == 'client - client time restriction ' ? (
                  <p style={{ color: 'red' }}> {erorTimeRescrtictionMag}</p>
                ) : null}
              </small>

              <small>
                {homeTimeRescrtictionHoursLength > 3 ? (
                  <p style={{ color: 'red' }}>
                    Please check 'home - client time restriction field ' : Enter
                    the hours and minute in hh: mm format .Hours max. upto 3
                    digits .{' '}
                  </p>
                ) : null}
                {homeTimeRescrtictionHoursValue > 999 ? (
                  <p style={{ color: 'red' }}>
                    {' '}
                    Please check 'home - client time restriction field ' : Hours
                    should be less than 999 .
                  </p>
                ) : null}

                {homeTimeRescrtictionMinuteLength > 2 ? (
                  <p style={{ color: 'red' }}>
                    Please check 'home - client time restriction field' : Enter
                    the hours and minute in hh: mm format . Minute max. upto 2
                    digits.
                  </p>
                ) : null}
                {homeTimeRescrtictionMinuteValue > 59 ? (
                  <p style={{ color: 'red' }}>
                    Please check 'home - client time restriction field' : Enter
                    the hours and minute in hh: mm format . Minutes should be
                    less than 59 .
                  </p>
                ) : null}
                {homeTimeRescrtictionMinuteValue == 0 &&
                  homeTimeRescrtictionMinuteLength < 2 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format . If minutes is
                    0 then enter in hh:00 format .
                  </p>
                ) : null}
                {homeErorTimeRescrtiction ==
                  'home - client time restriction' ? (
                  <p style={{ color: 'red' }}> {homeErorTimeRescrtictionMag}</p>
                ) : null}
              </small>
            </FlexGrid3Item>
          </FlexGrid3Container>
        </div>

        <div className="mt-3  mb-1 pb-2  pt-3 ms-1">
          <small>
            {' '}
            <TitleSection>Actual distance and time </TitleSection>
          </small>
          <FlexGrid3Container className={'w-100 mb-1 mt-1 pt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <DropdownSearchable
                placeHolder={'Actual travel time'}
                options={[
                  { id: 'Schedule Travel Time', name: 'Schedule Travel Time' },
                  { id: 'null', name: 'Null' },
                ]}
                selectedOption={
                  values.actual_travel_time ? values.actual_travel_time : null
                }
                onChange={value =>
                  dropDownHandleSelect(
                    { actual_travel_time: value ? value : null },
                    'actual_travel_time'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <InputLabelTop
                label={'Actual travel time (hh:mm)'}
                type="text"
                id="actual_travel_time"
                // placeholder={'hh:mm'}
                {...useInput(`adjust_travel_time`)}
                maxlength={6}
              />
              <small>
                {actualTravelTimeHoursLength > 3 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format .Hours max. upto
                    3 digits .{' '}
                  </p>
                ) : null}
                {actualTravelTimeHoursValue > 999 ? (
                  <p style={{ color: 'red' }}> Hours should be less than 999 .</p>
                ) : null}

                {actualTravelTimeMinuteLength > 2 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format . Minute max.
                    upto 2 digits.
                  </p>
                ) : null}
                {actualTravelTimeMinuteValue > 59 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format . Minutes should
                    be less than 59 .
                  </p>
                ) : null}
                {actualTravelTimeMinuteValue == 0 &&
                  actualTravelTimeMinuteLength < 2 ? (
                  <p style={{ color: 'red' }}>
                    Enter the hours and minute in hh: mm format . If minutes is
                    0 then enter in hh:00 format .
                  </p>
                ) : null}
                {erorActualTravelTime == 'actualTraveltime' ? (
                  <p style={{ color: 'red' }}>
                    {' '}
                    {actualTravelTimeErorTimeFormatMag}
                  </p>
                ) : null}
              </small>
            </FlexGrid3Item>
          </FlexGrid3Container>
          <FlexGrid3Container className={'w-100 mb-1 mt-1 pt-1'}>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <DropdownSearchable
                placeHolder={'Actual travel tidistance '}
                options={[
                  {
                    id: 'Scheduler Travel Distance',
                    name: 'Scheduler Travel Distance',
                  },
                  { id: 'null', name: 'Null' },
                ]}
                selectedOption={
                  values.actual_travel_distance
                    ? values.actual_travel_distance
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect(
                    { actual_travel_distance: value ? value : null },
                    'actual_travel_distance'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
              <InputLabelTop
                label={'Actual travel distance (m'}
                type="number"
                id="adjust_travel_distance"
                // placeholder={'hh:mm'}
                {...useInput(`adjust_travel_distance`)}
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
          <div className="mt-3 mb-3">
            <div className="mt-2 pt-1">
              {' '}
              <small>
                {' '}
                <TitleSection>Pay Rate resitriction</TitleSection>
              </small>
            </div>
            {values.ShiftTypeRateRestriction &&
              values.ShiftTypeRateRestriction.length >= 1 &&
              values.ShiftTypeRateRestriction.map((data, index) => {
                return (
                  <AddAvailability
                    index={index}
                    data={data}
                    setValues={setValues}
                    useInput={useInput}
                    values={values}
                    filterShiftType={contextChoices.regular_shift_type || []}
                    dropDownHandleSelect={dropDownHandleSelect}
                    AddNewAvailability={AddNewAvailability}
                    deleteAvailability={deleteAvailability}
                    unitName={unitName}
                    dropDownHandleSelectAvailablity={
                      dropDownHandleSelectAvailablity
                    }
                  />
                );
              })}
          </div>
        </div>
        <div
          className={'d-flex justify-content-center justify-content-md-start'}>
          <div className={'mt-2'}>
            <PrimaryButtonForm minWidth="6rem" onClick={AddNewAvailability}>
              <span>{PLUS}Add Pay Rate Restriction</span>
            </PrimaryButtonForm>
          </div>
        </div>
        <div className="mt-3 mb-3">
          <div className="mt-2 pt-1">
            {' '}
            <small>
              {' '}
              <TitleSection>Clock in-out resitriction</TitleSection>
            </small>
          </div>
          {values.ShiftTypeClockRestriction &&
            values.ShiftTypeClockRestriction.length >= 1 &&
            values.ShiftTypeClockRestriction.map((data, index) => {
              return (
                <AddClockOutRes
                  index={index}
                  data={data}
                  setValues={setValues}
                  useInput={useInput}
                  values={values}
                  filterShiftType={contextChoices.regular_shift_type || []}
                  dropDownHandleSelect={dropDownHandleSelect}
                  AddNewAvailability={AddNewAvailabilitys}
                  deleteAvailability={deleteAvailabilitys}
                  unitName={unitName}
                  dropDownHandleSelectAvailablity={
                    dropDownHandleSelectAvailablitys
                  }
                />
              );
            })}
        </div>

        <div
          className={'d-flex justify-content-center justify-content-md-start'}>
          <div className={'mt-2'}>
            <PrimaryButtonForm minWidth="6rem" onClick={AddNewAvailabilitys}>
              <span>{PLUS}Add Clock in-out resitriction</span>
            </PrimaryButtonForm>
          </div>
        </div>

        <div className="mt-3 mb-3">
          <div className="mt-2 pt-1">
            {' '}
            <small>
              {' '}
              <TitleSection>Travel time estimate</TitleSection>
            </small>
          </div>
          {values.ShiftTravelEstimatedTime &&
            values.ShiftTravelEstimatedTime.length >= 1 &&
            values.ShiftTravelEstimatedTime.map((data, index) => {
              return (
                <TravelTimeEstimates
                  index={index}
                  data={data}
                  setValues={setValues}
                  useInput={useInput}
                  values={values}
                  addNewTravelTimeEstimate={addNewTravelTimeEstimate}
                  deleteTravelTimeEstimate={deleteTravelTimeEstimate}
                  travelModeOptions={travelModeOptions}
                  travelName={travelName}
                  unitName={unitName}
                  dropDownHandleSelectTravelEstimate={
                    dropDownHandleSelectTravelEstimate
                  }
                />
              );
            })}
        </div>
        <div
          className={'d-flex justify-content-center justify-content-md-start'}>
          <div className={'mt-2'}>
            <PrimaryButtonForm
              minWidth="6rem"
              onClick={addNewTravelTimeEstimate}>
              <span>{PLUS}Travel time estimate</span>
            </PrimaryButtonForm>
          </div>
        </div>

        {/* travel time estime end  */}
        <div className="mt-3 mb-3">
          <div className="mt-2 pt-1">
            {' '}
            <small>
              {' '}
              <TitleSection>Shift swap approval process</TitleSection>
            </small>
          </div>

          <ToggleButton
            useInput={useInput('is_swap')}
            id="is_swap"
          />
        </div>
        <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div className={'mb-3'}>
            {errorMessage ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>

          <div
            className={'d-flex justify-content-center justify-content-md-end'}>
            <div className={'ms-0 ms-md-3 mb-2 pb-1 mt-1 pt-1 me-2'}>
              <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit} disabled={loadingSave}>
                <span className={'me-2 mb-2 pb-1 pt-1'}>{textStatus}</span>
                {!loadingSave ? (
                  buttonIcon
                ) : (
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  />
                )}
              </PrimaryButtonForm>
            </div>
          </div>
        </div>
      </ComponentDynamic>
    </div>
  );
};

export default SettingsEsv;
