import React, {useState, useEffect, useRef, useContext} from 'react';
import Table from 'components/SharedComponents/Table';
import AddModal from 'components/DashboardComponents/Alerts/AddModal';
import {RiDeleteBinLine} from 'react-icons/ri';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import {MdEdit} from 'react-icons/md';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import queryString from 'query-string';

import {Title} from 'shared/styles/constants/tagsStyles';
import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import {deleteFormBuilder} from 'utils/api/EmployeeApi';
import {
  LARGE_COL,
  EX_SMALL_COL4,
  EX_LARGE_COL,
  EX_VERY_LARGE_COL,
  VERY_EX_SMALL_COL_CHECKBOX,
  SMALL_COL,
  EX_SMALL_COL,
} from 'shared/styles/constants/columns';
import {AppContext, BranchItemsContext} from 'context';

import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {FloatingMobileButton, PrimaryButtonForm} from 'shared/styles/buttons';
import {FaPlus} from 'react-icons/fa';
import {useHistory, useLocation} from 'react-router-dom';
import ShowFormModal from './showFormModal/showFormModal';
import FormViewer from './formViewer/formViewer';
import {get} from 'utils/api/Api';
import {useModal} from 'hooks/ModalHook';
import AttachClientEmp from './Modals/AttachClientEmp';
import moment from 'moment';
import {Header} from '../Team/styles';
import {getToken, getTenant} from 'utils/localStorage/token';
import {apiURL} from 'utils/env';
import {useForm} from 'hooks/FormHook';
import {getFormBuilder} from 'utils/api/EmployeeApi';
import {SearchIcon, SearchAddContainer} from '../Team/styles';
import {GrSearch} from 'react-icons/gr';
import {SearchContainer} from '../Team/styles';
import {Fade} from 'react-reveal';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import {FORM_BUILDER_PERMISSION} from 'utils/constants/permissions';
import {
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  SELF_WEB_DELETE_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  SELF_WEB_READ_PERMISSION,
} from 'utils/constants/permissions';
import { formdata_choice } from 'utils/choiceConstant';
import { SOFT_RED } from 'shared/styles/constants/colors';
import { COPY } from 'utils/constants/icons';
import { Toast } from 'primereact/toast';
// import { getPageToSearchParam } from 'shared/methods';
const FormBuilderMainPage = props => {
  useEffect(() => {
    let limitperpage = {};
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({...values});
  }, [PAGE_SIZE_RENDER]);
  const getBaseUrl = () => {
    let tenant = getTenant();
    return `${apiURL}/${tenant}/api/v1`;
  };

  const baseUrl = getBaseUrl();
  const [loadingModal, setLoadingModal] = useState(true);
  const [forms, setForms] = useState([]);
  const [createdForms, setCreatedForms] = useState([]);
  const [showForm, setShowForm] = useState({name: '', visible: false});
  const [idSelected, setIdSelected] = useState('');
  const [attachTypeEmp, setAttachTypeEmp] = useState(false);
  const [formName, setFormName] = useState({});
  const {values, setValues, useInput} = useForm({ordering: "-name"});
  const [readPermission, setReadPermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [createPermission, setCreatePermission] = useState(false);
  const [rightPermission, setRightPermission] = useState({});
// console.log('formdata', forms)
  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const location = useLocation();
  const toast = useRef()
  const {
    contextPermissions,
    contextSubSectionPermissions,
    contextCategoryPermissions,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextIsStaff,
    contextSubscription,
    contextChoices,
    handleGetChoices
  } = useContext(AppContext);

  useEffect(()=>{
    handleGetChoices(formdata_choice)
  },[])

  const [showSearch, setShowSearch] = useState(false);
 


  useEffect(() => {
    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          if (item.section !== null) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }
    const roles = contextCategoryPermissions;

    if (roles.length > 0) {
      roles.forEach(
        item => {
          if (item.section !== null) {
            rolePermissions[item.section.name] = {};
            rolePermissions[item.section.name][WEB_PERMISSION] = {};
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] = item?.[WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_READ_PERMISSION
            ] = item?.[SELF_WEB_READ_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ] = item?.[WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] = item?.[SELF_WEB_CREATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ] = item?.[WEB_DELETE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ] = item?.[WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] = item?.[SELF_WEB_UPDATE_PERMISSION];
            rolePermissions[item.section.name][WEB_PERMISSION][
              SELF_WEB_DELETE_PERMISSION
            ] = item?.[SELF_WEB_DELETE_PERMISSION];
          }
        }
        // }
      );
    }
    setRightPermission({...rolePermissions});
    if (contextIsSuperAdmin) {
      setReadPermission(true);
      setCreatePermission(true);
      setDeletePermission(true);
      setUpdatePermission(true);
    }else if (contextIsAdmin) {
      setReadPermission(
        contextSubscription?.[FORM_BUILDER_PERMISSION]?.[WEB_PERMISSION]
      );
      setCreatePermission(true);
      setDeletePermission(true);
      setUpdatePermission(true);
    }else{
      if (rolePermissions?.[FORM_BUILDER_PERMISSION]) {
        setReadPermission(
          (contextSubscription?.[FORM_BUILDER_PERMISSION]?.[WEB_PERMISSION] &&
            rolePermissions[FORM_BUILDER_PERMISSION][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ]) ||
            rolePermissions[FORM_BUILDER_PERMISSION]?.[WEB_PERMISSION]?.[
              SELF_WEB_READ_PERMISSION
            ]
        );
        setCreatePermission(
          (contextSubscription?.[FORM_BUILDER_PERMISSION]?.[WEB_PERMISSION] &&
            rolePermissions[FORM_BUILDER_PERMISSION][WEB_PERMISSION][
              WEB_CREATE_PERMISSION
            ]) ||
            rolePermissions[FORM_BUILDER_PERMISSION]?.[WEB_PERMISSION]?.[
              SELF_WEB_CREATE_PERMISSION
            ]
        );
        setUpdatePermission(
          (contextSubscription?.[FORM_BUILDER_PERMISSION]?.[WEB_PERMISSION] &&
            rolePermissions[FORM_BUILDER_PERMISSION][WEB_PERMISSION][
              WEB_UPDATE_PERMISSION
            ]) ||
            rolePermissions[FORM_BUILDER_PERMISSION]?.[WEB_PERMISSION]?.[
              SELF_WEB_UPDATE_PERMISSION
            ]
        );
  
        setDeletePermission(
          (contextSubscription?.[FORM_BUILDER_PERMISSION]?.[WEB_PERMISSION] &&
            rolePermissions[FORM_BUILDER_PERMISSION][WEB_PERMISSION][
              WEB_DELETE_PERMISSION
            ]) ||
            rolePermissions[FORM_BUILDER_PERMISSION]?.[WEB_PERMISSION]?.[
              SELF_WEB_DELETE_PERMISSION
            ]
        );
      }
    }
   
  }, [
    contextIsSuperAdmin,
      contextIsAdmin,
      contextSubscription,
      contextSubSectionPermissions,
      contextPermissions,
  ]);

  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const {pathname} = useLocation();
  const categoryBuilder =
    pathname === '/settings/setting-form-builder/setting-form-builder-category';

  const headerColumns = [
    {
      label: categoryBuilder ? 'Category Name' : 'Form Name',
      name: 'triage_created_by__full_name',
      status: 0,
      className: 'grow-mobile',
      max_width: EX_LARGE_COL,
    },

    {
      label: 'Created at',
      name: 'created_at',
      status: 0,
      className: 'grow-mobile',
      max_width: SMALL_COL,
    },
  ];
  const {
    setValue: setModalValueAttach,
    value: modalValueAttach,
    setTitle: setAttachTitle,
    fullBind: fullBindAttach,
  } = useModal(false);

  const deleteForm = async url => {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token ' + getToken(),
      },
      body: {},
    });
    const json = await response.json();
    return json;
  };
  
  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };
  const getData = async(searchFlag = false) => {
    setLoadingModal(true);
    let pageToSearch = getPageToSearchParam(
      history?.location?.search,
      setCurrentPage
      );
    if(searchFlag)
      pageToSearch.offset = 0;
      try {
        const res = await getFormBuilder({...values, ...pageToSearch}, categoryBuilder)
        let results = [...res?.results];
        // console.log(results,"results")
        // let sortedData = results?.sort(function(a, b) {
        //   const nameA = a.name.toLowerCase();
        //   const nameB = b.name.toLowerCase();
        //   return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        // });
        setForms(res?.results);
        setPages(Math.ceil(res.count / values.limitperpage));
        setLoadingModal(false);
      } catch (error) {
        setLoadingModal(false);
      }
  };


  useEffect(() => {
    getData();
  }, [history.location.search,
    values.limitperpage,
    values.ordering]);


  const handleAttachClick = (id, type) => {
    setIdSelected(id);
    setModalValueAttach(true);
    if (type === 'employee') {
      setAttachTypeEmp(true);
    } else {
      setAttachTypeEmp(false);
    }
  };
  const dropDownHandleSelectPagination = (item, namesection) => {
    // history.location.pathname
    // history.location.search
    const urlParams = queryString.parse(history.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: history.location.pathname,
      search: stringified,
    });
    values.limitperpage = item.limitperpage
    setValues({ ...values });
  };

  const dropDownHandleSelect = item => {
    const urlParams = queryString.parse(props.props);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/form-builder',
      search: stringified,
    });
    setValues({...values, ...item});
  };

  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d);
    };
  };

  const hitSearch = value => {
    values.search = value;
    getData(true);
  };

  const debounceOnchange = useRef(debounce(hitSearch, 300));
  const keyPressed = e => {
    setSearchKeyword(e.target.value);
    debounceOnchange.current(e.target.value);
  };
  const toDeleteClient = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const handleDeleteFormBuilder = async () => {
    await deleteFormBuilder(idSelected);
    setModalDeleteValue(false);
    setIdSelected('');
    getData();
  };


  return (
    <div>
      <Toast ref={toast}/>
      <Header>
        <Title>{categoryBuilder ? 'Category Builder' : 'Form Builder'}</Title>
        <div></div>

        <SearchAddContainer className="d-flex align-items-center">
          <SearchIcon
            onClick={() => {
              setShowSearch(!showSearch);
            }}>
            <GrSearch />
          </SearchIcon>

          <SearchContainer show={showSearch}>
            <Fade collapse when={showSearch}>
              <InputLabelTop
                no_internal_margin="true"
                type="text"
                id="search"
                label={'Search'}
                onChange={e => {
                  keyPressed(e);
                }}
                value={searchKeyword}
              />
            </Fade>
          </SearchContainer>
          {createPermission && (
            <FloatingMobileButton
              onClick={() => {
                if (categoryBuilder) {
                  history.push({
                    pathname:
                      '/settings/setting-form-builder/setting-form-builder-category/add-category',
                    state: {editMode: false, detail: []},
                  });
                } else {
                  history.push({
                    pathname: '/add-form',
                    state: {editMode: false, detail: []},
                  });
                }
              }}>
              <FaPlus />
              <label>{categoryBuilder ? 'NEW CATEGORY' : 'NEW FORM'}</label>
            </FloatingMobileButton>
          )}
        </SearchAddContainer>
      </Header>
      <ComponentDynamic loading={loadingModal}>
        {forms?.length === 0 ? (
          <h6 style={{textAlign: 'center', marginTop: 50}}>No data found</h6>
        ) : (
          <Table
            headerColumns={headerColumns}
            // callBackQuerySort={setQuerySort}
            headerPadding={false}
            handleAllCheck={() => {}}
            noNeed={true}
            handleAllSelectChange={() => {}}>
            <div>
              {forms &&
                forms.map((alertsData, index) => {
                  
                  let sanitizedOutput = alertsData.jsonData && alertsData.jsonData.replace(/[\u0000-\u0019]+/g,"");
                  let worn = categoryBuilder ? alertsData.json_data == null : alertsData.jsonData == null
                  // console.log(`formdata${index}`, sanitizedOutput.replace('https://',""))
                  return (
                    <div>
                      <Row bgColor>
                        <ColsGrouper className="d-flex">
                          <Col
                            max_width={EX_LARGE_COL}
                            Center
                            onClick={() => {
                              if(worn){
                                toast.current.show({
                                  severity: 'error',
                                  summary: 'Failed',
                                  detail: `JSON data not available to render`,
                                });
                                return;
                              }
                              setCreatedForms(() => {
                                if (categoryBuilder) {
                                  return JSON.parse(alertsData.json_data);
                                } else {
                                  return JSON.parse(sanitizedOutput);
                                }
                              });
                              setShowForm({
                                name: alertsData.name,
                                visible: true,
                              });
                            }
                          }
                            overlap={true}>
                            {alertsData.name}
                          </Col>

                          <Col
                            max_width={LARGE_COL}
                            Center
                            onClick={() => {
                              if(worn){
                                toast.current.show({
                                  severity: 'error',
                                  summary: 'Failed',
                                  detail: `JSON data not available to render`,
                                });
                                return;
                              }
                              setCreatedForms(() => {
                                if (categoryBuilder) {
                                  return JSON.parse(alertsData.json_data);
                                } else {
                                  return JSON.parse(sanitizedOutput);
                                }
                              });
                              setShowForm({
                                name: alertsData.name,
                                visible: true,
                              });
                            }}
                            overlap={true}>
                            <label>
                              {alertsData.updated_at
                                ? moment(alertsData.updated_at).format(
                                    'YYYY-MM-DD HH:mm'
                                  )
                                : moment(alertsData.created_at).format(
                                    'YYYY-MM-DD HH:mm'
                                  )}
                            </label>
                          </Col>

                          <Col max_width={LARGE_COL} Center overlap={true}>
                            {updatePermission && (
                              <PrimaryButtonForm
                                minWidth="6rem"
                                onClick={() => {
                                  handleAttachClick(alertsData.id, 'client');
                                }}>
                                <span className={'me-2'}>
                                  Attach Client and Employee
                                </span>
                              </PrimaryButtonForm>
                            )}
                          </Col>
                        </ColsGrouper>
                        <Col
                          className="sm-hidden"
                          Center
                          Shrink
                          NoFlexGrow
                          Purple>
                          {updatePermission && (
                            <IconWrapper
                              onClick={() => {
                                if (categoryBuilder) {
                                  history.push({
                                    pathname:
                                      '/settings/setting-form-builder/setting-form-builder-category/add-category',
                                    state: {editMode: true, detail: alertsData},
                                  });
                                } else {
                                  history.push({
                                    pathname: '/add-form',
                                    state: {editMode: true, detail: alertsData},
                                  });
                                }
                              }}>
                              <MdEdit />
                            </IconWrapper>
                          )}
                        </Col>

                        <Col className="sm-hidden" Center Shrink NoFlexGrow>
                          {deletePermission && (
                            <IconWrapper
                              onClick={() => {
                                toDeleteClient(alertsData.id);
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>
                          )}
                          <IconWrapper

                            color={SOFT_RED}
                            onClick={() => {
                              history.push({
                                pathname: '/add-form',
                                state: {editMode: false, detail: alertsData},
                              });
                            }
                            }
                          >
                            {COPY}
                          </IconWrapper>
                        </Col>
                      </Row>

                      <div className="mb-1" />
                    </div>
                  );
                })}
              <div className="mt-3">
                <Pagination
                  totalPages={pages}
                  currentPage={currentPage}
                  values={values}
                  dropDownHandleSelect={dropDownHandleSelectPagination}
                  number={PAGE_SIZE_RENDER}
                  dataLength={forms?.length}
                  limitperpage={values.limitperpage}
                />
              </div>
            </div>
          </Table>
        )}
      </ComponentDynamic>

      {modalValueAttach && (
        <AttachClientEmp
          idSelected={idSelected}
          setModalValue={setModalValueAttach}
          fullBind={fullBindAttach}
          setTitle={setAttachTitle}
          attachTypeEmp={attachTypeEmp}
          //loadBranches={loadBranches}
        />
      )}

      <ShowFormModal
        children={<FormViewer handleChangeChoices={props.handleChangeChoices} contextChoices={contextChoices} lastDroppedItem={createdForms} title={showForm.name}/>}
        title={showForm.name}
        onClose={() => {
          setShowForm({name: '', visible: false});
        }}
        showModal={showForm.visible}
        max_width={'70%'}
        minWidthTitle="8.5rem"
        loading={false}
        data={[]}
      />
      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this Form Builder? \n DELETING the Form Builder will remove ALL records from database.'
        }
        onOk={handleDeleteFormBuilder}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default FormBuilderMainPage;
