import { SchedulerContainer } from 'components/DashboardComponents/Scheduler/styles';
import Spinner from 'components/SharedComponents/Spinner';
import TimelineMedicationCalendar from 'components/SharedComponents/TimelineMedicationCalendar';
import { AppContext } from 'context';
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';
import moment from 'moment';
import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';
import { GRAY_820, WHITE } from 'shared/styles/constants/colors';
import { getDownloadMedicationFile, getDownloadMedicationPdf, getMedDetailById, getMedicationChart, getMedicationDoseById, getMedicationList, setMedClickEvent } from 'utils/api/ClientApi';
import EditMedication from './Modals/EditMedication';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { jsPDF } from "jspdf";
import { client_med_chart_choices } from 'utils/choiceConstant';
import { DataContainer } from 'shared/styles/constants/tagsStyles';
import { InvalidOrEmptyValues } from 'utils/constants/messages';
import ViewToExport from './Modals/ViewToExport';

const MedicationChart = ({
  selectedClientId,
  // cliId
}) => {
  let clientId = selectedClientId
  const [events, setEvents] = useState([]);
  const [medicationData, setMedicationData] = useState([]);

  const [resourcesMedication, setResourcesMedication] = useState([]);
  const [shiftType, setShiftType] = useState('resourceTimelineDay');
  const { values, setValues, useInput, errors, setFormErrors, setCheckRequires } = useForm({});
  const { contextChoices, contextChoicesMap } = useContext(AppContext);
  const [loading, setLoading] = useState(true)
  const [medList, setMedList] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false);
  const [downloadDisable, SetDownloadDisable] = useState(false);
  const mountedRef = useRef(true);
  const [MedicationColourCode, setMedicationColourCode] = useState([]);
  const [loadingSaveMedication, setLoadingSaveMedication] = useState(false);
  const [clientResource, setClientResource] = useState(0)
  const [assignedHours, setAssignedHours] = useState(0);
  const [unassignedHours, setUnassignedHours] = useState(0);
  const [linkedMeds, setLinkedMeds] = useState(0);
  const [unLinkedMeds, setUnLinkedMeds] = useState(0);
  const [signature, setSignature] = useState(null);

  const {
    setValue: setEditMedication,
    value: editMedication,
    fullBind: fullBindEditMedication,
  } = useModal(false);

  const {
    setValue: setModalViewToExport,
    value: modalViewToExport,
    setTitle: setTitle,
    fullBind: fullBindViewToExport,
  } = useModal(false);

  const { handleGetChoices } = useContext(AppContext)


  useEffect(() => {
    handleGetChoices(client_med_chart_choices)
    getMedicationNameList()
  }, [])

  // useEffect(()=>{
  //   setResourcesMedication(resourcesMedication)
  // },[events])
  useEffect(() => {
    if (contextChoices.medication_status) {
      let temp_obj = {}
      contextChoices.medication_status.forEach((val) => {
        temp_obj[val.name] = val;
      })
      setMedicationColourCode(temp_obj)
    }
  }, [contextChoices.medication_status])
  useEffect(() => {
    if (clientId) {
      searchScheduler()
    }
  }, [
    clientId,
    shiftType,
    values.start_date,
    values.end_date,
  ])

  const getMedicationNameList = () => {
    getMedicationList(clientId)
      .then((res) => {
        const temp_opt = res?.data?.map((i) => ({ id: i.name__id, name: i.name__name, med_sch_id: i.id }));
        setMedList(temp_opt || [])
      })
      .catch((err) => { })
  }
  console.log('colour', MedicationColourCode)
  const getMedicationColor = (status) => {
    return MedicationColourCode[status]?.color_code || '#868686'
    // switch (status) {
    //   case 'Fully Taken':
    //     return '#A8E39B'
    //   case 'Not Needed':
    //   case 'Not Observed':
    //   case 'Not Reported':
    //   case 'Not Scheduled':
    //   case 'Not Taken':
    //   case 'Not Started':
    //   case 'No Visit':
    //     return '#ff3b3b'
    //   case 'Partially Taken':
    //     return '#ffa500'
    //   default:
    //     return '#868686';
    // }
  }

  const handleSubmit = () => {
  
    if(errors.length){
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues)
      setShowError(true)
      return;
    }
      
    if (values[`alternative_field_error`] || values[`professional_guidance_field_error`]) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues)
      setShowError(true)
      return;
    }
    setLoadingSaveMedication(true)

    let body = values.medication.id ?
      {
        id: values.medication.id,
        core_medication_dose_id: values.medication.core_medication_dose,
        start_date: values.medication.start_date,
        is_adhoc: false,
        instructions: values.medication.instructions,
        name: values.medication.name,
        medication_type: values.medication.medication_type,
        administration_type: values.medication.administration_type,
        administration_reason: values.medication.administration_reason,
        medication_route: values.medication.medication_route,
        medication_form: values.medication.medication_form,
        client: clientId,
        employee_id: values.medication.employee,
        medication_assistance: values.medication.medication_assistance,
        medication_instructions: values.medication.medication_instructions,
        dose_regime: values.medication.dose_regime,
        frequency: values.medication.frequency,
        important: values.medication.important,
        prescription_volume: values.medication.prescription_volume,
        reason: values.medication.reason,
        potential_side_effects: values.medication.potential_side_effects,
        comments: values.medication.comments,
        administration_area: values.medication.administration_area,
        marking_style: values.medication.marking_style,
        storage_location: values.medication.storage_location,
        prescription_expiry_date: values.medication.prescription_expiry_date,
        administration_support: values.medication.administration_area,
        actual_start_date: values.medication.actual_start_date,
        actual_medication_dose_time: values.medication.actual_medication_dose_time,
        feedback: values.medication.feedback,
        medication_reason: values.medication.medication_reason,
        // medication_dose: values.medication.medication_dose,
        medication_status_id: values.medication.medication_status,
        body_part: values.medication_body_part || [],
        medication_dose_timing: values.medication.medication_dose_timing,
        medication_dose_time: values.medication.medication_dose_time,
        medication_dose_end_time: values.medication.medication_dose_end_time,
        // New fields Added
        strength: values.strength,
        min_interval_between_doses: values.min_interval_between_doses,
        max_dose_in_24_hours: values.max_dose_in_24_hours,
        medication_purpose: values.medication_purpose,
        symptoms_to_look_for: values.symptoms_to_look_for,
        client_need_medication_criteria: values.client_need_medication_criteria,
        alternative_administering_interventions: values.alternative_administering_interventions,
        medication_duration: values.medication_duration,
        healthcare_professional_guidance_criteria: values.healthcare_professional_guidance_criteria,
        completed_by: values.completed_by,
        // signature: values.signature || null,
        date: values.date,
      } :
      {
        core_medication_dose_id: values.medication.core_medication_dose,
        start_date: values.medication.start_date,
        is_adhoc: false,
        instructions: values.medication.instructions,
        name_id: values.medication.name,
        medication_type_id: values.medication.medication_type,
        administration_type_id: values.medication.administration_type,
        administration_reason_id: values.medication.administration_reason,
        medication_route_id: values.medication.medication_route,
        medication_form_id: values.medication.medication_form,
        client_id: clientId,
        employee_id: values.medication.employee,
        medication_assistance_id: values.medication.medication_assistance,
        medication_instructions: values.medication.medication_instructions,
        dose_regime: values.medication.dose_regime,
        frequency: values.medication.frequency,
        important: values.medication.important,
        prescription_volume: values.medication.prescription_volume,
        reason: values.medication.reason,
        potential_side_effects: values.medication.potential_side_effects,
        comments: values.medication.comments,
        administration_area: values.medication.administration_area,
        marking_style: values.medication.marking_style,
        storage_location: values.medication.storage_location,
        prescription_expiry_date: values.medication.prescription_expiry_date,
        administration_support: values.medication.administration_area,
        actual_start_date: values.medication.actual_start_date,
        actual_medication_dose_time: values.medication.actual_medication_dose_time,
        feedback: values.medication.feedback,
        medication_reason: values.medication.medication_reason,
        // medication_dose: values.medication.medication_dose,
        medication_status_id: values.medication.medication_status,
        body_part: values.medication_body_part || [],
        medication_dose_timing_id: values.medication.medication_dose_timing,
        medication_dose_timing: values.medication.medication_dose_timing,
        medication_dose_time: values.medication.medication_dose_time,
        medication_dose_end_time: values.medication.medication_dose_end_time,
        body_part: values.medication_body_part || [],
        // New fields Added
        strength: values.strength,
        min_interval_between_doses: values.min_interval_between_doses,
        max_dose_in_24_hours: values.max_dose_in_24_hours,
        medication_purpose: values.medication_purpose,
        symptoms_to_look_for: values.symptoms_to_look_for,
        client_need_medication_criteria: values.client_need_medication_criteria,
        alternative_administering_interventions: values.alternative_administering_interventions,
        medication_duration: values.medication_duration,
        healthcare_professional_guidance_criteria: values.healthcare_professional_guidance_criteria,
        completed_by: values.completed_by,
        // signature: values.signature || null,
        date: values.date,
      }
    // if (values.medication.id) {
    //   body.id = values.medication.id;
    // }

    if (values.medication.medication_type_name === 'PRN') {
      body.is_prn = true;
      body.medication_schedule_id = values.medication.medication_schedule_id;
    }
    setMedClickEvent(body, clientId)
      .then(response => {
        setLoadingSaveMedication(false)
        if (response.status === "SUCCESS") {
          setEditMedication(false);
          searchScheduler()
        }
      })
      .catch(() => { onError() });
  }
  const onError = () => {
    setShowError(false);
    setErrorMessage('');
    errors.splice(0, errors.length - 1);
  }
  const handleDownlaod = () => {
    // let clientId=cliId?cliId:selectedClientId
    let fileName =
      'Medication_' +
      moment(values.start_date).format('YYYY-MM-DD') +
      '_' +
      moment(values.end_date).format('YYYY-MM-DD');
    SetDownloadDisable(true);
    getDownloadMedicationFile(values, 0, selectedClientId)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          SetDownloadDisable(false);
        });
      })
      .catch(error => {
        SetDownloadDisable(false);
      });
  };

  const getMedicationDataById = (Id) => {
    getMedDetailById(Id)
      .then((res) => {
        if ((res.status)) {
          if (!values.medication) {
            values.medication = {}
          }
          values.medication = { ...res.data }
          setValues({ ...values })
        }
      })
      .catch((err) => { })
  }

  const dropDownHandleSelect = (item, nameItem, index) => {
    if (nameItem == 'medication_type') {
      values.medication.medication_type_name = item[nameItem]?.name
      values.medication[nameItem] = item[nameItem].id
    } else if (nameItem == 'name') {
      values.medication[nameItem] = item[nameItem].id;
      values.medication.medication_schedule_id = item[nameItem].med_sch_id;
      if (values?.medication?.medication_type_name === 'PRN') {
        getMedicationDataById(item[nameItem].med_sch_id)
      }
    } else {
      values.medication[nameItem] = item[nameItem];
    }
    setValues({ ...values }); //item added

  };

  const eventClickHandler = ({ id, extendedProps }) => {
    setEditMedication(true);

    getMedicationDoseById(id, extendedProps.clientId)
      .then((res) => {
        if (res.id) {
          values.medication = {}
          console.log(res, "data")
          values.medication_body_part = res?.body_part || []
          values.medication = { ...res }
          setSignature(res.signature || null)
          setValues({ ...values, ...res })
        }
      })
      .catch((err) => { })
  }
  const keys = [
    'min_interval_between_doses',
    'max_dose_in_24_hours',
    'medication_purpose',
    'symptoms_to_look_for',
    'client_need_medication_criteria',
    'medication_duration',
    'strength',
    'completed_by',
    'date',
    'healthcare_professional_guidance_criteria',
    'alternative_administering_interventions']
  const addNewMedication = () => {
    setEditMedication(true)
    keys.forEach((key) => {
      values[key] = ''
    })
    setSignature(null)
    values.medication = {}
    values.medication_body_part = []
    setValues({ ...values })
  }



  useEffect(() => {
    updateCalendarData(medicationData)
  }, [medicationData, contextChoicesMap.medication_dose, contextChoicesMap.medication_status, contextChoicesMap.client_listing])

  const searchScheduler = async () => {
    if (!loading) {
      setLoading(true)
    }
    setEvents([]);
    // let clientId=cliId?cliId:selectedClientId

    const response = await getMedicationChart({ ...values }, 800, selectedClientId)
    // .then(response => {
    setMedicationData(response)
    // updateCalendarData(response)


    // })
  };

  const updateCalendarData = (response) => {
    if (!mountedRef.current) return null;
    let data = [];
    let resource_data = []
    let linkedCounts = 0;
    let unLinkedCounts = 0;
    const array_length = response && response?.length
    //data = 
    array_length && response.map(med => {
      data.push({
        id: med.id,
        resourceId: `${med.medication_dose__dose__id}${med.medication_name}`,
        title: (med.start_date && med.medication_dose_time) ? (moment.utc(med.start_date + ' ' + med.medication_dose_time).format("HH:mm") + (med.medication_dose_end_time ? (' - ' + moment.utc(med.start_date + ' ' + med.medication_dose_end_time).format("HH:mm")) : '')) : '',
        backgroundColor: getMedicationColor(med.medication_status_name),
        borderColor: getMedicationColor(med.medication_status_name),
        textColor: WHITE,
        constraint: {
          resourceIds: [med.medication_dose__id],
        },
        actual_medication_dose_time: med.actual_medication_dose_time,
        medication_dose_time: med.medication_dose_time,
        medication_type: med.medication_type,
        start: med.start_date + ' ' + med.medication_dose_time,
        end: med.medication_dose_end_time ? med.start_date + ' ' + med.medication_dose_end_time : moment(med.start_date + ' ' + med.medication_dose_time).add(5, "minutes").format("YYYY-MM-DD HH:mm:ss"),
        clientId: med.client,
        employeeId: med.employee,
        client_name: (med.client && contextChoicesMap && contextChoicesMap.client_listing) ? contextChoicesMap?.client_listing[med.client]?.full_name : '',
        employee_name: (med.employee && contextChoicesMap && contextChoicesMap.employee_listing) ? contextChoicesMap?.employee_listing[med.employee]?.full_name : '',
        is_adhoc: med.is_adhoc,
        medication_status_name: med.medication_status_name,
        visitId: med.visitId,
        linkedWithVisit: med.linkedWithVisit
      });
      if (med.linkedWithVisit) {
        linkedCounts = linkedCounts + 1
      } else {
        unLinkedCounts = unLinkedCounts + 1
      }
      resource_data.push({
        id: `${med.medication_dose__dose__id}${med.medication_name}`,
        title: (med.medication_dose__dose__id && contextChoicesMap && contextChoices.medication_dose) ? contextChoicesMap?.medication_dose[med.medication_dose__dose__id]?.name : '',
        med: med.medication_name
      })

    });



    let final_data = [...data]
    setLinkedMeds(linkedCounts)
    setUnLinkedMeds(unLinkedCounts)
    const adm_count = data.filter((val) => val.medication_status_name == 'Family Administered' || val.medication_status_name == 'Self Administered' || val.medication_status_name == 'Fully Taken');
    setAssignedHours(adm_count.length)
    setUnassignedHours(array_length - adm_count.length)
    setEvents(final_data.flat());
    setResourcesMedication(resource_data.flat());
    setLoading(false)
  }

  const [decreaseWidth, setDecreaseWidth] = useState(true);
  const handleDecreaseWidth = (val) => {
    // hide tabs of client page (outside of this component)
    const clientFixedTopTabs = document.querySelector("#client-fixed-top-tabs")
    const clientFixedTopHeader = document.querySelector("#client-fixed-top-header")
    const calendarContainer = document.querySelector(".tab-comp-section")
    const clientMedicationTabs = document.querySelector("#client-medication-tabs")
    if(clientFixedTopTabs) {
      if(window.getComputedStyle(clientFixedTopTabs).display === 'flex'){
        clientFixedTopTabs.style.display="none";
        if(calendarContainer) {
          calendarContainer.style.marginTop="0"
          calendarContainer.style.padding="0px !important"
        }
        if(clientMedicationTabs) {
          clientMedicationTabs.style.display="none";
          clientMedicationTabs.classList.remove('d-flex');
        }
        if(clientFixedTopHeader) clientFixedTopHeader.style.display="none";
      }
      else {
        clientFixedTopTabs.style.display="flex"
        if(calendarContainer) {
          calendarContainer.style.marginTop=`${clientFixedTopTabs.offsetHeight+50}px`;
          calendarContainer.style.padding="1rem"
        }
        if(clientMedicationTabs) clientMedicationTabs.style.display="flex";
        if(clientFixedTopHeader) clientFixedTopHeader.style.display="flex";
      }
    }
    setDecreaseWidth(val)
  }

  return (
    <>
      <div>
        {
          loading &&
          <Spinner
            spinnerTop={"90%"}
            textTop={'85%'}
          />
        }
        <SchedulerContainer loading={loading} height={decreaseWidth ?'100%':"auto"}>
          <div className='d-flex justify-content-between align-items-start'>
            {
              decreaseWidth && (
                <>
                    <DataContainer className='my-0'>
                      <div className={`data-container data-container-schedule mt-1 me-2`} style={{ marginBottom: '0px' }}>
                        <div className="data me-2">
                          <p className="fontBold">Administred</p>
                          <div className="contents">
                            <div className="sub-contents me-2">
                              <p>Meds</p> 
                              <p>{assignedHours}</p>
                            </div>
                          </div>
                        </div>
                        <div className="data leftPadding">
                          <p className="fontBold">Planned</p>
                          <div className="contents">
                            <div className="sub-contents me-2">
                              <p>Meds</p>
                              <p>{Number(unassignedHours) || 0}</p>
                            </div>
                          </div>
                        </div>
                        <div className="data leftPadding">
                          <p className="fontBold">Linked</p>
                          <div className="contents">
                            <div className="sub-contents me-2">
                              <p>Meds</p>
                              <p>{linkedMeds}</p>
                            </div>
                          </div>
                        </div>
                        <div className="data leftPadding">
                          <p className="fontBold">UnLinked</p>
                          <div className="contents">
                            <div className="sub-contents me-2">
                              <p>Meds</p>
                              <p>{unLinkedMeds}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      </DataContainer>
                      <div className='d-flex'>
                      
                        <PrimaryButtonForm minWidth="6rem" style={{ marginRight: "5px"}} onClick={() => setModalViewToExport(true)}>
                          Preview
                        </PrimaryButtonForm>

                        <PrimaryButtonForm minWidth="6rem"  onClick={addNewMedication}>
                          Add Medication
                        </PrimaryButtonForm>
                      
                      </div>
                </>
              )
            }
          </div>
          <TimelineMedicationCalendar
            editMode={false}
            showProgressSection={true}
            resourceAreaHeaderContent={'Medication Chart'}
            resources={resourcesMedication}
            events={events}
            setShiftType={setShiftType}
            shiftType={shiftType}
            values={values}
            setValues={setValues}
            resourceColoumn={[
              {
                group: true,
                field: 'med',
                headerContent: 'Medication'
              },
              {
                field: 'title',
                headerContent: 'Doses'
              }
            ]}
            eventClickHandler={data => {
              eventClickHandler(data);
            }}
            useInput={useInput}
            downloadDisable={downloadDisable}
            handleDownlaod={handleDownlaod}
            contextChoices={contextChoices}
            getViewType={type => {
              setValues({...values, viewType : type })}
            }
            decreaseWidth={decreaseWidth}
            setDecreaseWidth={handleDecreaseWidth}
          />
          {
            editMedication &&
            <EditMedication
              clientId={clientId}
              fullBind={fullBindEditMedication}
              useInput={useInput}
              values={values}
              choices={contextChoices}
              editMode={true}
              dropDownHandleSelect={dropDownHandleSelect}
              handleSubmit={handleSubmit}
              contextChoices={contextChoices}
              medList={medList}
              setValues={setValues}
              loadingSaveMedication={loadingSaveMedication}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              showError={showError}
              setShowError={setShowError}
              signature={signature}
              setSignature={setSignature}
            />
          }
          {modalViewToExport && (
            <ViewToExport
              fullBind={fullBindViewToExport}
              setModalValue={setModalViewToExport}
              values={values}
              clientId={selectedClientId}
            />
          )}
        </SchedulerContainer>
      </div>
    </>
  );
};



export default MedicationChart;
