import React,{Fragment} from 'react';
import {
    NextOfKin,
  } from 'components/SharedComponents/FormSections';
  import {PrimaryButtonForm} from 'shared/styles/buttons';
  import {
    ErrorMessage,
  } from 'shared/styles/constants/tagsStyles';
  import {getContactDetails} from 'utils/api/ClientApi'
  import { useHistory } from 'react-router-dom';
const ModalContacts = ({
    useInput,
    values,
    choices,
    handlePickFile,
    nokPhoto,
    dropDownHandleSelect,
    setValues,
    showError,
    errorMessage,
    handleSubmit,
    textStatus,
    loadingSave,
    buttonIcon,
    impContactList
}) => {

  const dropDownHandleContact=(item)=>{
    values.full_name=item.full_name
    getContactDetails(item.full_name)
    .then(res=>{
      if(res){
        values.title=res.title && res.title
        values.contact_type=res.contact_type && res.contact_type
        values.contact_status=res.contact_status && res.contact_status
        values.line_1=res.line_1 && res.line_1
        values.line_2=res.line_2 && res.line_2
        values.city=res.city && res.city
        values.zip_code=res.zip_code && res.zip_code
        values.country=res.country && res.country
        values.mobile=res.mobile && res.mobile
        values.email=res.email && res.email
        values.additional_telephone=res.additional_telephone && res.additional_telephone
        values.person_notes=res.person_notes && res.person_notes
        values.person_notes=res.person_notes && res.person_notes
        setValues({...values})
      }
    })
    setValues({...values})
  }
   
    const history=useHistory()
    return (
        <div>
            <NextOfKin
                useInput={useInput}
                handlePickFile={handlePickFile}
                dropDownHandleSelect={dropDownHandleSelect}
                dropDownHandleContact={dropDownHandleContact}
                nokPhoto={nokPhoto}
                choices={choices}
                impContactList={impContactList}
                values={values}
                setValues={setValues}
                roles={choices.roles}
              />
               <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {showError ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>

              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                
                <div className={'ms-0 ms-md-3 me-2'}>
                     <PrimaryButtonForm
                        bgcolor="PURPLE_GRAY_500"
                        minWidth="6rem"
                        className={'me-2'}
                        onClick={()=>{
                          // setModalValue()
                          history.push('/client')
                        }}>
                        <span className={'me-2'}>{'Cancel'}</span>
                      </PrimaryButtonForm>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    onClick={handleSubmit}>
                    <span className={'me-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                </div>
             
              </div>
            </div>
        </div>
    );
}

export default ModalContacts;
