import React, { Fragment } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { TIMES } from 'utils/constants/icons';
import DateSelector from 'components/SharedComponents/DateSelector';
import { CloseButtonWrapper, } from 'shared/styles/constants/tagsStyles';
import AddTask from './AddTask';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { useState } from 'react';
import { useRef } from 'react';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';

const AddPlannedTask = ({
    values,
    setValues,
    nameSection = "",
    index = "",
    visitIndex = "",
    useInput,
    category = "",
    plannedTasks = [],
    readOnly,
    editMode,
    deletePlanedTask = () => { },
    dropDownHandlePlanedTask = () => { },
    toast,
    hideAddTaskButton
}) => {
    const TASK_VALUE = "tasks"
    const newAddedTaskRef = useRef(null)
    const [expandFields, setExpandFields] = useState({});
    const [taskData, setTaskData] = useState([]);
    const {
        setValue: setModalDeleteValue,
        bind: bindModalDelete } =
        useModal(false);

    const effectiveFromHook = useInput(
        category === 'visits'
            ? `${category}[${visitIndex}].${nameSection}[${index}].effective_from`
            : `${category}.${nameSection}[${index}].effective_from`, 'isRequired')


    const effectiveToHook = useInput(category === 'visits'
        ? `${category}[${visitIndex}].${nameSection}[${index}].effective_to`
        : `${category}.${nameSection}[${index}].effective_to`)

    // const planned_task_hook = useInput(
    //     category === 'visits'
    //         ? `${category}[${visitIndex}].${nameSection}[${index}].specific_group`
    //         : `${category}.${nameSection}[${index}].specific_group`, 'isRequired')

    const addNewRow = () => {
        if (category === 'visits') {
            if (!values.visits[visitIndex].planned_task[index][TASK_VALUE])
                values.visits[visitIndex].planned_task[index][TASK_VALUE] = []
            values.visits[visitIndex].planned_task[index][TASK_VALUE] = [
                ...values.visits[visitIndex].planned_task[index][TASK_VALUE]
                    .map(item => ({ ...item, defaultExpand: false })),
                {
                    is_essential: false,
                    defaultExpand: true,
                    support_level: null,
                    care_task: null,
                    care_category: null,
                    task_type: null,
                    message: null,
                }];
                setExpandFields({[values.visits[visitIndex].planned_task[index][TASK_VALUE].length - 1]: { open: true } });

        } else {
            if (!values[category].planned_task[index][TASK_VALUE])
                values[category].planned_task[index][TASK_VALUE] = []
            values[category].planned_task[index][TASK_VALUE] = [
                ...values[category].planned_task[index][TASK_VALUE]
                    .map(item => ({ ...item, defaultExpand: false })),
                {
                    is_essential: false,
                    support_level: null,
                    defaultExpand: true,
                    care_task: null,
                    care_category: null,
                    task_type: null,
                    message: null,
                }];
                setExpandFields({[values[category].planned_task[index][TASK_VALUE].length - 1]: { open: true } });

        }

        // setExpandFields({ ...expandFields, [values[category].planned_task[index][TASK_VALUE].length - 1]: { open: true } });
        setValues({ ...values });
        setTaskData({ values });
        if (newAddedTaskRef.current)
            newAddedTaskRef.current.focus();
    };

    const deleteRow = taskIndex => {
        if (category === 'visits') {
            values.visits[visitIndex].planned_task[index][TASK_VALUE].length
                && values.visits[visitIndex].planned_task[index][TASK_VALUE][taskIndex]
                && values.visits[visitIndex].planned_task[index][TASK_VALUE].splice(taskIndex, 1);
        } else {
            values[category].planned_task[index][TASK_VALUE].length
                && values[category].planned_task[index][TASK_VALUE][taskIndex]
                && values[category].planned_task[index][TASK_VALUE].splice(taskIndex, 1);
        }
        setValues({ ...values });
    };

    console.log(category === 'visits'
        ? values.visits[visitIndex].planned_task
        : values[category][nameSection], "planned_task")

    return (
        <div className='planned-task-container p-3 mb-3 m-2'>
            <div className={`d-flex ms-0 ${index > 0 ? "mt-4" : "mt-1"}`}>
                {readOnly ? (
                    <Fragment />
                ) : (
                    <CloseButtonWrapper
                        disabled={!editMode}
                        onClick={() => {
                            // category ==='visits'? 
                            setModalDeleteValue(true);
                        }}
                    >
                        {TIMES}
                    </CloseButtonWrapper>
                )}
                <div className="w-100 me-2">
                    <DateSelector
                        isRequired
                        labelTop="Effective From"
                        disabled={!editMode}
                        {...effectiveFromHook}
                    />
                </div>
                <div className="w-100 me-2">
                    <DateSelector
                        labelTop="Effective To"
                        disabled={!editMode}
                        {...effectiveToHook}
                    />
                </div>
            </div>
            <div className={`ms-5 ${hideAddTaskButton ? "mt-2" : ""}`}>
                {/* {(!readOnly && !hideAddTaskButton) && (
                    <PrimaryButtonForm
                        disabled={!editMode}
                        className={'ms- mt-3 mb-2'}
                        bgcolor="BLUE"
                        minWidth="3rem"
                        onClick={addNewRow}>
                        <span>{<BsFillPlusSquareFill />}New Task</span>
                    </PrimaryButtonForm>
                )} */}
                {(category === 'visits' ? values.visits[visitIndex].planned_task
                    && values.visits[visitIndex].planned_task.length
                    && values.visits[visitIndex].planned_task[index][TASK_VALUE]
                    && values.visits[visitIndex].planned_task[index][TASK_VALUE].length

                    : values[category][nameSection][index][TASK_VALUE]
                    && values[category][nameSection][index][TASK_VALUE].length) ?
                    <AddTask
                        deleteRow={deleteRow}
                        setExpandFields={setExpandFields}
                        expandFields={expandFields}
                        values={values}
                        setValues={setValues}
                        useInput={useInput}
                        visitIndex={visitIndex}
                        nameSectionPlannedTask={'planned_task'}
                        plannedTaskIndex={index}
                        nameSection={TASK_VALUE}
                        category={category}
                        toast={toast}
                        disabled={!editMode}
                        ref={newAddedTaskRef}
                    // values.visits[visitIndex][nameSectionPlannedTask][index][nameSection]
                    />
                    : <React.Fragment />}
            </div>
            <div className='delete-task-popup-resize'>
            <ModalDecision
                type="delete"
                title="Warning"
                body={
                    'Are you sure you want to delete this Planned Task? \n DELETING the planned task will remove ALL records from database.'
                }
                onOk={() => {
                    deletePlanedTask(category, visitIndex, nameSection, index)
                    setModalDeleteValue(false);
                }}
                onCancel={() => {
                    setModalDeleteValue(false);
                }}
                okTitle={'DELETE'}
                {...bindModalDelete}
            />
       </div>
        </div>
    );
}

export default AddPlannedTask;
