import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ErrorMessage, ModalContainer } from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { FlexGrid3Container, FlexGrid3Item, TitleSection } from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
  HOUR,
  MINUTE
} from 'utils/constants/serviceVisits';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { Fragment } from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import moment from 'moment';
import { FLOPPY } from 'utils/constants/icons';
const AddMedication = ({
  //fullBind,
  useInput,
  errorMessage,
  values,
  choices,
  editMode,
  handleSubmit,
  loadingSave,
  medList,
  dropDownHandleSelect = () => { },
  employees,
  clients
}) => {
  // const [clients, setClients] = useState([]);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);

  //const [addFullBind, setAddFullBind] = useState(fullBind);
  const ins_hook = useInput(`medication.instructions`);
  const med_hook = useInput(`medication.medication_instructions`)
  const newDoseTime = useInput(
    `medication.medication_dose_time`, 'isRequired');
  const actualMedicationDoseTime = useInput(
    `medication.actual_medication_dose_time`);

    const newDoseStartTime = useInput(
      `medication.medication_dose_time`, 'isRequired'
    );
    const newDoseEndTime = useInput(
      `medication.medication_dose_end_time`, 'isRequired'
    );
console.log('client value', values.medication.client)
  // useEffect(() => {
  //   setAddFullBind({
  //     ...addFullBind,
  //     title: 'Add Medication',
  //   });
  // }, [fullBind]);

  // useEffect(()=>{
  //   let clients = [];
  //   if (choices.client_listing) {
  //     clients = choices.client_listing.map(client => {
  //       return { id: client.id, name: client.full_name };
  //     });

  //     setClients(clients || []);
  //   }
  // },[choices.client_listing])

  return (
   
        <div className='pt-2 ps-3 pe-3 pb-3'>
           <div className="d-flex mb-2">
        <div className="w-100 pe-1">
              <DropdownSearchable
                required
                disabled={true}
                placeHolder={'Client'}
                options={clients || []}
                {...useInput('medication.client', "isRequired")}
                selectedOption={values.medication ? values.medication.client : null}
                onChange={value =>
                  dropDownHandleSelect({ client: value ? value : null }, 'client')
                }
              />
            </div>
           
            </div>
            <div className="d-flex mb-2">
            <div className="w-100 pe-1">
            <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Medication Type'}
                required
                options={choices.medication_type || []}
                selectedOption={values.medication ? values.medication.medication_type : null}
                {...useInput('medication.medication_type','isRequired')}
                onChange={(value, label, obj) =>
                  dropDownHandleSelect({ medication_type: obj ? obj : null }, 'medication_type')
                }
              />
            </div>

            <div className="w-100 pe-1">
            <DropdownSearchable
                required
                disabled={!editMode}
                placeHolder={'Medication Name'}
                options={!(values.medication.medication_type_name === 'PRN') ? choices.medication_name : medList}
                {...useInput(`medication.name`, "isRequired")}
                selectedOption={values.medication ? values.medication.name : null}
                onChange={(value, label, obj) =>
                  dropDownHandleSelect({ name: obj ? obj : null }, 'name')
                }
              />
            </div>

           

          </div>
          <div className="d-flex mb-2">
          <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Route'}
                options={choices.medication_route || []}
                selectedOption={values.medication ? values.medication.medication_route : null}
                onChange={value =>
                  dropDownHandleSelect({ medication_route: value ? value : null }, 'medication_route')
                }
              />
            </div>
            <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Form'}
                options={choices.medication_form || []}
                selectedOption={values.medication ? values.medication.medication_form : null}
                onChange={value =>
                  dropDownHandleSelect({ medication_form: value ? value : null }, 'medication_form')
                }
              />
            </div>
          </div>
          <div className="d-flex mb-2">


          <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Medication Assistance'}
                options={choices.medication_assistance || []}
                selectedOption={values.medication ? values.medication.medication_assistance : null}
                {...useInput('medication.medication_assistance')}
                onChange={value =>
                  dropDownHandleSelect({ medication_assistance: value ? value : null }, 'medication_assistance')
                }
              />
            </div>
            <div className="w-100 pe-1">
          <DropdownSearchable
          required
            disabled={!editMode}
            placeHolder={'Medication Dose'}
            {...useInput(`medication.medication_dose`,'isRequired')}
            options={choices.medication_dose || []}
            selectedOption={values.medication ? values.medication.medication_dose : null}
            onChange={value =>
              dropDownHandleSelect(
                { medication_dose: value ? value : null },
                'medication_dose')
            }
          />
        </div>
            

          
          </div>


          <div className="d-flex mb-2">


            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Frequency.'}
                type="text"
                // onKeyPress={KeyPress.INTEGER}
                {...useInput('medication.frequency')}
              />
            </div>

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Important.'}
                type="text"
                {...useInput('medication.important')}
              />
            </div>

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Prescription volume.'}
                type="text"
                {...useInput('medication.prescription_volume')}
              />
            </div>
          </div>

          {/* Added by divyansh */}



          <div className="d-flex mb-2">


            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Reason.'}
                type="text"
                {...useInput('medication.reason')}
              />
            </div>

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Potential side effects.'}
                type="text"
                {...useInput('medication.potential_side_effects')}
              />
            </div>

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Comments.'}
                type="text"
                {...useInput('medication.comments')}
              />
            </div>
          </div>

          <div className="d-flex mb-2">


            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Marking style.'}
                type="text"
                {...useInput('medication.marking_style')}
              />
            </div>

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Storage location.'}
                type="text"
                // onKeyPress={KeyPress.INTEGER}
                {...useInput('medication.storage_location')}
              />
            </div>

            {/* <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Feedback.'}
                type="text"

                // onKeyPress={KeyPress.INTEGER}
                {...useInput('medication.feedback')}
              />
            </div> */}
          </div>


          <div className="d-flex mb-2">


            {/* <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Medication reason.'}
                type="text"
                // onKeyPress={KeyPress.INTEGER}
                {...useInput('medication.medication_reason')}
              />
            </div> */}

            {/* <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Client'}
                options={choices.administration_type || []}
                selectedOption={values.medication ? values.medication.administration_type : null}
                onChange={value =>
                  dropDownHandleSelect({ administration_type: value ? value : null }, 'administration_type')
                }
              />
            </div> */}



            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Dose Regime.'}
                type="text"
                {...useInput('medication.dose_regime')}
              />
            </div>

          

          </div>

        



          <div className="d-flex mb-2">



            <div className='w-100 pe-1'>

              <div
                className={'pt-2 ms-md-1 ms-0'}
                style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                Medication Instruction
              </div>

              <InputTextArea
                type="text"
                height={'2.4rem'}
                id="med_desc"
                placeHolder={'Medication Instruction.'}
                label={'Medication Instruction'}
                {...med_hook}
              //   maxlength={5}
              />
            </div>


          </div>




          {/* Add by divyansh end  */}
          <TitleSection>Administration</TitleSection>
          <div className="d-flex mt-2">
            <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Administration Reason'}
                options={choices.administration_reason || []}
                selectedOption={values.medication ? values.medication.administration_reason : null}
                onChange={value =>
                  dropDownHandleSelect({ administration_reason: value ? value : null }, 'administration_reason')
                }
              />
            </div>
            <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Administration Type'}
                options={choices.administration_type || []}
                selectedOption={values.medication ? values.medication.administration_type : null}
                onChange={value =>
                  dropDownHandleSelect({ administration_type: value ? value : null }, 'administration_type')
                }
              />
            </div>
          </div>

          <div className="d-flex mt-2">
           

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Administration support.'}
                type="text"
                {...useInput('administration_support')}
              />
            </div>

            <div className="w-100 pe-1">
              <InputLabelTop
                disabled={!editMode}
                label={'Administration Area.'}
                type="text"
                {...useInput('administration_area')}
              />
            </div>

          </div>
          <div className="d-flex mt-2">
          <div className="w-100 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Employee'}
                options={employees || []}
                selectedOption={values.medication ? values.medication.employee : null}
                {...useInput('medication.employee')}
                onChange={value =>
                  dropDownHandleSelect({ employee: value ? value : null }, 'employee')
                }
              />
            </div>
            <div className="w-100 pe-1">

              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Medication status'}
                options={choices.medication_status || []}
                selectedOption={values.medication ? values.medication.medication_status : null}
                {...useInput('medication.medication_status')}

                onChange={value =>
                  dropDownHandleSelect({ medication_status: value ? value : null }, 'medication_status')
                }
              />
            </div>
          </div>

          <div className="d-flex mt-2">
          <div className="w-100 pe-1">
              <DateSelector
                isRequired
                disabled={!editMode}
                labelTop="Start Date"
                {...useInput('medication.start_date', "isRequired")}
              />
            </div>
            <div className="w-100 pe-1">
        <DropdownSearchable
            required
            disabled={!editMode}
            placeHolder={'Medication Dose Timing'}
            {...useInput(`medication.medication_dose_timing`,'isRequired')}
            options={choices.medication_dose_timing || []}
            selectedOption={
              values.medication
                ? values.medication.medication_dose_timing
                : null
            }
            onChange={(value, label, obj) =>
              dropDownHandleSelect(
                { medication_dose_timing: obj ? obj : null },
                'medication_dose_timing')
            }
          />
           {/* <TimeSelector
            required={((values.every === HOUR) || (values.every === MINUTE)) ? false : true}
            disabled={!editMode}
            labelTop={'Dose Time'}
            {...newDoseStartTime}
            //added below code  for time in 24hrs format
            timeFormat={'HH:mm'}
            dateFormat={'HH:mm'}
          /> */}
        </div>
          </div>
          <div className="d-flex mt-2">
      <div className="w-100 pe-1">
        <TimeSelector
            disabled={values.medication
            ? !(values.medication.medication_dose_timing_name == 'Fixed')
            : false}
            // data-error={timeError[index]}
            // errorMessage={timeErrorMessage}
            isRequired={true}
            labelTop={'Start Dose Time'}
            {...newDoseStartTime}
            //added below code  for time in 24hrs format
            timeFormat={'HH:mm'}
            dateFormat={'HH:mm'}
          />
        </div>
        <div className="w-100 pe-1">
        <TimeSelector
             disabled={values.medication
              ? !(values.medication.medication_dose_timing_name == 'Fixed')
              : false}
            // data-error={timeError[index]}
            // errorMessage={timeErrorMessage}
            isRequired={true}
            labelTop={'End Dose Time'}
            {...newDoseEndTime}
            //added below code  for time in 24hrs format
            timeFormat={'HH:mm'}
            dateFormat={'HH:mm'}
          />
        </div>
      </div>
          {/* <div className="d-flex mt-2 mb-1">

<div className="w-100 pe-1">
    <DateSelector
      disabled={!editMode}
      labelTop="Actual medication start date"
      {...useInput('medication.actual_start_date')}
    />

  </div>

  <div className="w-100 pe-1">
    <TimeSelector
      disabled={!editMode}
      // data-error={timeError[index]}
      // errorMessage={timeErrorMessage}
      //isRequired={((values.every === HOUR) || (values.every === MINUTE)) ? false : true}
      labelTop={'Actual medication dose time'}
      {...actualMedicationDoseTime}
      //added below code  for time in 24hrs format
      timeFormat={'HH:mm'}
      dateFormat={'HH:mm'}
    />
  </div>
</div> */}

          <div className="d-flex mt-2">
            <div className="w-100 pe-1">
              <div
                className={'pt-2 ms-md-1 ms-0'}
                style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                Additional Instructions
              </div>
              <InputTextArea
                type="text"
                height={'2.4rem'}
                id="emp_desc"
                placeHolder={'Additional Instructions'}
                label={'Additional Instructions'}
                {...ins_hook}
              //   maxlength={5}
              />
            </div>
          </div>


          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {false ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={'d-flex justify-content-center justify-content-md-end'}>
              <div className={'ms-0 ms-md-3 me-2'}>
                {/* <PrimaryButtonForm
                disabled={false}
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                className={'me-2'}
                onClick={() => {
                  // setModalValue()
                  history.push('/client');
                }}>
                Cancel
              </PrimaryButtonForm> */}
                <PrimaryButtonForm
                  disabled={false}
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                  onClick={handleSubmit}
                >
                 <span className={'me-2'}>{'Save'}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
              <div></div>
            </div>
          </div>
        </div>
  );
};

export default AddMedication;