import ModalDynamic from 'components/DashboardComponents/ModalDynamic'
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable'
import { Toast } from 'primereact/toast'
import React from 'react'
import { useState } from 'react'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import { ModalContainer } from 'shared/styles/constants/tagsStyles'
import { addNewSkillMatrix } from 'utils/api/SettingsApi'
import { FLOPPY } from 'utils/constants/icons'
import { SAVE, SAVED } from 'utils/constants/messages';
import { useRef } from 'react'

function AddSkillMatrixModal({
  fullBind,
  loadingModal = false,
  choices, 
  values,
  setValues,
  useInput,
  dropDownHandleSelect,
  getSkillMatrixListData,
  setModalValue,
  toast,
  readOnly
}) {
  const [loadingSave, setLoadingSave] = useState(false);
  const [filteredskills, setFilteredSkills] = useState([])


  const HandleSubmit = async () => {
   if(values?.skillMatrix?.branch?.length && values?.skillMatrix?.skill?.length){
    const body = {
      branch: values.skillMatrix.branch,
      skill: values.skillMatrix.skill,
    };
    try {
      const res = await addNewSkillMatrix(body)
      if (res.status) {   
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${'Skill matrix added!'}`,
        })
        getSkillMatrixListData()
        setModalValue(false)
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${res.message}`,
        });
      }
    } catch (error) {
      console.log(error)
    }
  }else{
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: `Please fill the mandatory fields`,
    });
  }
  }

  const filteredSkillsByCategory = () => {
    const fileredSkill = choices.skill.filter(item => values?.skillMatrix?.skillCategory.includes(item.category))
    setFilteredSkills(fileredSkill)
  }
  const handleChange = (value) => {
    dropDownHandleSelect(
      { skillCategory: value ? value : null },
      'skillCategory'
    );
    filteredSkillsByCategory()
  }
  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic {...fullBind} minWidthTitle="8.5rem" loading={loadingModal}>
        <div>
          <div className="d-flex flex-column mt-2">
            <div className='m-2'>
              <DropdownSearchable
                required
                isMulti={true}
                {...useInput(`skillMatrix.branch`, 'isRequired')}
                placeHolder={'Branch'}
              
                options={choices.branch}
                disabled={readOnly}
                selectedOptionsProps={
                  values
                    ? values.skillMatrix
                      ? values.skillMatrix.branch
                      : null
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect(
                    { branch: value ? value : null },
                    'skill_branch'
                  )
                }
              />
            </div>

            <div className='m-2'>
              <DropdownSearchable
                required
                isMulti={true}
                {...useInput(`skillMatrix.skillCategory`, 'isRequired')}
                placeHolder={'Skill Category'}
                options={choices.skill_category}
                disabled={readOnly}
                selectedOptionsProps={
                  values
                    ? values.skillMatrix
                      ? values.skillMatrix.skillCategory
                      : null
                    : null
                }
                onChange={value => { handleChange(value) }}
              />
            </div>

            <div className='m-2'>
              <DropdownSearchable
                required
                isMulti={true}
                {...useInput(`skillMatrix.skill`, 'isRequired')}
                placeHolder={'Skill'}
                options={filteredskills}
                disabled={readOnly}
                selectedOptionsProps={
                  values
                    ? values.skillMatrix
                      ? values.skillMatrix.skill
                      : null
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect(
                    { skill: value ? value : null },
                    'skill'
                  )
                }
              />
            </div>
          </div>

          <div className={'d-flex justify-content-center justify-content-md-end mt-2'}>
            <div className={'ms-0 ms-md-3 me-2'}>
              <PrimaryButtonForm
                minWidth="6rem"
                disabled={readOnly}
                onClick={HandleSubmit}
                style={{ marginBottom: '5px' }}>
                <span className={'me-2'}>{SAVE}</span>
                {!loadingSave ? (
                  FLOPPY
                ) : (
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  />
                )}
              </PrimaryButtonForm>
            </div>
          </div>
        </div>
      </ModalDynamic>

    </ModalContainer>
  )
}

export default AddSkillMatrixModal