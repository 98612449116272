import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import React from 'react'
import { useEffect } from 'react';
import { Fragment } from 'react';
import { ErrorMessage } from 'shared/styles/AuthComponents';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {  ModalContainer } from 'shared/styles/constants/tagsStyles';

function AddFormPopup({
    values,
    setValues,
    useInput,
    toast,
    choices,
    errorMessage,
    fullBind,
    setModalAdd,
    HandleSubmit,
    isValid,
    errors,
    setTitle,
    // readOnly,
    updatePermissionTax,
    textStatus,
    buttonIcon,
    loadingSave,
}) {

    const service_cat_hook = useInput(
        `service_category`
    )
    const BRANCH_TAX = 'tax_branch';
    const dropDownHandleSelect = (item, nameSection, index) => {
        values[nameSection] = item[nameSection];
        setValues({ ...values });
    };

    useEffect(() => {
        setTitle('Add Tax Detail')
    }, [])
    return (

        <>
            <ModalContainer max_width={'60%'}>
                <ModalDynamic
                    {...fullBind}
                    max_width={'60%'}
                    minWidthTitle="8.5rem"
                    loading={false}>
                    <div>

                        <div className="d-flex flex-column mt-2 ms-1 me-1">
                            <div className="d-flex mb-2">
                                <div
                                    className="w-100 me-2"
                                    style={{ marginLeft: '10px', marginRight: '10px' }}>
                                    <DropdownSearchable
                                        required
                                        disabled={false}
                                        placeHolder={'Tax Type'}
                                        options={[
                                            { id: 'is_visit', name: 'Visit' },
                                            { id: 'is_travel', name: 'Travel' },
                                            { id: 'is_distance', name: 'Distance' },
                                            { id: 'is_expenses', name: 'Expenses' }
                                        ]}
                                        selectedOption={values.tax_type}
                                        {...useInput(
                                            `tax_type`,
                                            'isRequired'
                                        )}
                                        onChange={value =>
                                            dropDownHandleSelect(
                                                {
                                                    tax_type: value ? value : null,
                                                },
                                                'tax_type',
                                            )
                                        }
                                    />

                                </div>
                                <div
                                    className="w-100 me-2"
                                    style={{ marginLeft: '10px', marginRight: '10px' }}>
                                    <InputLabelTop
                                        // margin={'5px'}
                                        // disabled={readOnly}
                                        label='Discount Percentage'
                                        type="number"
                                        id={`discount_percentage`}
                                        {...useInput(`discount_percentage`)}
                                        maxlength={100}
                                    />
                                </div>
                                <div
                                    className="w-100 me-2"
                                    style={{ marginLeft: '10px', marginRight: '10px' }}>
                                    <InputLabelTop
                                        // margin={'5px'}
                                        label='Discount Amount'
                                        type="number"
                                        id={`discount_amount`}
                                        {...useInput(`discount_amount`)}
                                        maxlength={100}
                                    />
                                </div>
                                <div
                                    className="w-100"
                                    style={{ marginLeft: '10px', marginRight: '10px' }}>
                                    <InputLabelTop
                                        // margin={'5px'}
                                        // disabled={readOnly}
                                        label='Tax Amount'
                                        type="number"
                                        id={`tax_amount`}
                                        {...useInput(`tax_amount`)}
                                        maxlength={100}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex flex-column mt-2">
                            <div className="d-flex mb-2">

                                <div
                                    className="w-100 me-2"
                                    style={{ marginLeft: '10px', marginRight: '10px' }}>
                                    <InputLabelTop
                                        // margin={'5px'}
                                        // disabled={readOnly}
                                        label='Tax Percentage'
                                        type="number"
                                        id={`tax_percentage`}
                                        {...useInput(`tax_percentage`)}
                                        maxlength={100}
                                    />
                                </div>
                                {(values.tax_type == 'is_visit') && <div
                                    className="w-100 me-2"
                                    style={{ marginLeft: '10px', marginRight: '10px' }}>
                                    <DropdownSearchable
                                        required
                                        {...service_cat_hook}
                                        placeHolder={'Service Category'}
                                        // disabled={readOnly}
                                        options={choices.service_category || []}
                                        selectedOption={values.service_category}
                                        onChange={value =>
                                            dropDownHandleSelect({
                                                service_category: value ? value : null,
                                            },
                                                'service_category',
                                            )
                                        }
                                    />
                                </div>}

                                <div
                                    className="w-100 me-2"
                                    style={{ marginLeft: '10px', marginRight: '10px' }}>

                                    <DateSelector
                                        isRequired
                                        // disabled={readOnly}
                                        labelTop="Effective from"
                                        {...useInput(`effective_date_from`, 'isRequired')}

                                    />
                                </div>
                                <div
                                    className="w-100 "
                                    style={{ marginLeft: '10px', marginRight: '11px' }}>

                                    <DateSelector
                                        // isRequired
                                        // disabled={readOnly}
                                        labelTop="Effective to"
                                        {...useInput(`effective_date_to`)}

                                    />
                                </div>
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: values?.tax_branch?.length ? "" : "end",
                        }}>

                            <div
                                className={'ms-0 ms-md-3 me-2'}
                                style={{ display: 'flex', justifyContent: 'right', marginTop: '5px' }}>
                                <div
                                    className={'mb-3'}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'right',
                                        marginRight: '4px',

                                    }}>
                                    {(errorMessage && !isValid && errors.length > 0) ? (
                                        <ErrorMessage style={{ color: "#e44011" }}>
                                            <label className={'mt-2'}>{errorMessage}</label>
                                        </ErrorMessage>
                                    ) : (
                                        <Fragment />
                                    )}
                                </div>
                                {/* <PrimaryButtonForm
                                    // disabled={readOnly}
                                    minWidth="6rem"
                                    onClick={HandleSubmit}
                                    style={{ marginBottom: '5px' }}>
                                    Save
                                </PrimaryButtonForm> */}
                                <PrimaryButtonForm
                                    minWidth="6rem"
                                    // disabled={readOnly}
                                    onClick={HandleSubmit}
                                    style={{ marginBottom: '5px', maxHeight: "38px" }}>
                                    <span className={'me-2'}>{textStatus}</span>
                                    {!loadingSave ? (
                                        buttonIcon
                                    ) : (
                                        <span
                                            className="spinner-border spinner-border-sm me-1"
                                            role="status"
                                        />
                                    )}
                                </PrimaryButtonForm>
                            </div>
                        </div>
                    </div>
                </ModalDynamic>
            </ModalContainer>
        </>

    )
}

export default AddFormPopup