import React, { useEffect, useState, useContext } from 'react';
import Dropdown from 'components/SharedComponents/Dropdown';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import { EVV, EVV_GPS } from 'utils/constants/routes';
import { LARGE_COL } from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { useHistory, useLocation } from 'react-router-dom';
import {PrimaryButton, } from 'shared/styles/buttons';

import {
  HeaderVisits,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  PublishButton,
  DataContainer
} from 'shared/styles/constants/tagsStyles';
import {
  Row,
  Col,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import PropTypes from 'prop-types';
import { DOWNLOAD } from 'utils/constants/icons';
import { getAdmin, getSuperAdmin, getStaff } from "../../../../utils/localStorage/token"
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { getEvvExport, getEvvVisits } from 'utils/api/EvvApi';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { AppContext } from "context"
import { BranchItemsContext } from "context"
import { getLocationList } from 'utils/api/SchedulerApi';


const EvvGps = ({
  values,
  setValues,
  useInput,
  dropDownHandleSelect,
  events,
  clients,
  employees,
  eventClickHandler,
  loadEvvs,
  loadingCalendar,
  filterClient,
  filterEmployee,
  pages,
  runRouteData,
  branch,
  setFilterBranch,
  setRunroutefilter,
  branchFilter,
  runRoutesFilter,
  setClientfilter,
  setEmloyeefilter,
  employeesFilter,
  clientsFilter,
  loadingModal, 
  employeeRunRoutesFilter,
  setEmployeeRunRoutefilter,
  clearFilter,
  counts
}) => {
  const [numVisits, setNumVisits] = useState(0);
  const [sumVisitDuration, setSumVisitDuration] = useState(0);
  const [sumClockDuration, setSumClockDuration] = useState(0);
  const [numClients, setNumClients] = useState(0);
  const [numOfLocations, setNumOfLocations] = useState(0);
  const [numEmployees, setNumEmployees] = useState(0);
  const [currentPage, setCurrentPage] = useState(1)
  const [downloadDisable, setDownloadDisable] = useState(false)
  const [visitProvisionFilter, setVisitProvisionFilter] = useState([]);
  const [visitConfirmationFilter, setVisitConfirmationFilter] = useState([]);
  const [timeDistanceFilter, setTimeDistanceFilter] = useState([]);
  const { branchData } = useContext(BranchItemsContext);
  const { contextIsAdmin, contextIsSuperAdmin, contextIsStaff } = useContext(AppContext)
  const { headerFilterClearValue } = useContext(BranchItemsContext);
  const [locations, setLocations] = useState([])
  const [locationFilter, setLocationFilter] = useState([])
  let location = useLocation();
  const history = useHistory();
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const filterStaff = contextIsStaff;
  const columns_values = [
    {
      label: 'Visit #',
      name: 'visit',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Client name',
      name: 'client__full_name',
      status: 1,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Location name',
      name: '',
      status: 1,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Employee name',
      name: 'employee__full_name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Scheduled start date',
      name: 'schedule_start_date',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Scheduled end date',
      name: 'schedule_end_date',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock in method',
      name: 'actual_start_location',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock in location',
      name: 'clock_in_location',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock in verification',
      name: 'actual_start_verification',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock out method',
      name: 'clock_out_method',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock out location',
      name: 'actual_end_location',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock out verification',
      name: 'actual_end_verification',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock Out Notes',
      name: 'clock_out_notes',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
  ];

  const getListOfLocations = async () => {
    try {
      const res = await getLocationList()
      if (res?.status) {
        const data = res?.message?.map(item => ({ ...item, name: item.full_name }))
        setLocations(data || [])
      }
    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    clearFilter()
    // values.offset = 0;
    // values.limitperpage = PAGE_SIZE_RENDER;
    // setValues({ ...values })
    getListOfLocations()
  }, [])


const loadData=()=>{
  const obj_gps = {
    date_from: values.date_from,
    date_to: values.date_to,
    client_id: values.client_id,
    employee_id: values.employee_id,
    clock_in_verification_status: values.clock_in_verification_status,
    clock_out_verification_status: values.clock_out_verification_status,
    visit_no: values.visit_no,
    runroutes: values.runroutes,
    branch: values.branch,
  };
  const pageToSearch = getPageToSearchParam(location.search);
  loadEvvs({ ...branchData, ...obj_gps, ...pageToSearch }, EVV + EVV_GPS, values.limitperpage);
}

useEffect(()=>{

  // displayData();

  const obj_visits = {
    date_from: values.date_from,
    date_to: values.date_to,
    client_id: values.client_id,
    employee_id: values.employee_id,
    visit_status_id: values.visit_status,
    visit_priority_id: values.visit_priority_id,
    visit_no: values.visit_no,
    start_date: values.start_date,
    end_date: values.end_date,
    runroutes: values.runroutes,
    branch: values.branch,
    visitstatus: values.visitstatus,
  };
  const pageToSearch = getPageToSearchParam(location.search);

  loadEvvs({...branchData,...obj_visits,...pageToSearch}, EVV + EVV_GPS,values.limitperpage);

},[ location.search,values.ordering,])


  useEffect(() => {
    if (location.search || values.ordering) {
      loadData()

    }
  }, [
   
    location.search,
    values.ordering,
    values.limitperpage,
  
  ])


  const displayData = () => {
    // getEvvVisits({ ...branchData, ...values }).then(response => {
      setNumVisits(counts.no_of_visit > 0 ? Math.ceil(counts.no_of_visit) : 0);
      setSumVisitDuration(counts.visit_duration > 0 ? Math.round((counts.visit_duration + Number.EPSILON) * 100) / 100 : 0);
      setNumClients(counts.no_of_client > 0 ? Math.ceil(counts.no_of_client) : 0);
      setNumEmployees(counts.no_of_employee > 0 ? Math.ceil(counts.no_of_employee) : 0);
      setSumClockDuration(counts.clocked_duration ? Math.round((counts.clocked_duration + Number.EPSILON) * 100) / 100 : 0)
      setNumOfLocations(counts.no_of_locations > 0 ?  Math.ceil(counts.no_of_locations) : counts.no_of_locations)

    // });
  };

  useEffect(() => {
    displayData()
  },[ location.search, values.ordering, counts])
  
  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =  offset * values.limitperpage; return {
      offset,
    };
  };
  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
  const setLocationfilterFn = item => {
    setLocationFilter([...item]);
  };
  const handleDownload = () => {
    let fileName = "Evv_" + values.date_from + "_" + values.date_to
    setDownloadDisable(true)
    getEvvExport(values.date_from, values.date_to, values).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        setDownloadDisable(false)
      })
    }
    )
      .catch(error => {
        setDownloadDisable(false)
      })
  }

  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/evv/gps",
      search: stringified,
    });
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }
  const handleSearch=()=>{
    const urlSearchParams = new URLSearchParams(history.location.search);
    urlSearchParams.set('page', 1);
    const newUrl = `${history.location.pathname}?${urlSearchParams.toString()}`;
    history.push(newUrl);
    displayData();
    loadEvvs()
}

const setProvisionFilter = item => {
  setVisitProvisionFilter([...item]);
};
const setConfirmationFilter = item => {
  setVisitConfirmationFilter([...item]);
};
const setDistanceFilter = item => {
  setTimeDistanceFilter([...item]);
};
  return (
    <div>

      {/* HEADER VISIT DATE,CLIENT,VISIT,GROUP BY ETC */}
      <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
          <FiltersContainer>
            <div
              className={'ms-1 me-3 me-md-0 flex-wrap d-flex justify-content-center'}>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ms-1 me-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('date_from')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ms-1 me-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date to"
                    {...useInput('date_to')}
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={clients ? clients : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client_id: items });
                    }}
                    itemsSelected={clientsFilter}
                    setSelectedFilter={setClientfilter}
                    placeHolder={'Client name'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={locations ? locations : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ location: items });
                    }}
                    itemsSelected={locationFilter}
                    setSelectedFilter={setLocationfilterFn}
                    placeHolder={'Location name'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={employees ? employees : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee_id: items });
                    }}
                    itemsSelected={employeesFilter}
                    setSelectedFilter={setEmloyeefilter}
                    placeHolder={'Employee name'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>

              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ms-1 me-1">
                  <Dropdown
                    items={[
                      { id: '', name: 'All' },
                      { id: true, name: 'True' },
                      { id: false, name: 'False' },
                    ]}
                    onSelect={item => {
                      dropDownHandleSelect({
                        clock_in_verification_status: item,
                      });
                    }}
                    placeHolder={'Clock in verification status'}
                    appendItem=""
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ms-1 me-1">
                  <Dropdown
                    items={[
                      { id: '', name: 'All' },
                      { id: true, name: 'True' },
                      { id: false, name: 'False' },
                    ]}
                    onSelect={item => {
                      dropDownHandleSelect({
                        clock_out_verification_status: item,
                      });
                    }}
                    placeHolder={'Clock out verification status'}
                    appendItem=""
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>

              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ms-1 me-1">
                  <InputLabelTop
                    no_internal_margin="true"
                    type="text"
                    typeFilter={true}
                    label={'Visit #'}
                    {...useInput('visit_no')}
                    onKeyPress={KeyPress.INTEGER}
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>
              {/* added filters in runroute + branch  */}
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ms-1 me-2">
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ branch: items });
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
            
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ client__clients_run_routes__run_routes__id__in: items });
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Client Group Area'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee__employee_run_routes__run_routes__id__in: items });
                    }}
                    itemsSelected={employeeRunRoutesFilter}
                    setSelectedFilter={setEmployeeRunRoutefilter}
                    placeHolder={'Employee Group Area'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={[
                      {id: 'Under Provision', name: 'Under Provision'},
                      {id: 'Over Provision', name: 'Over Provision'},
                      {id: 'On Time Provision', name: 'On Time Provision'},
                      {id:'Cancelled',name:'Cancelled'}
                    ]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        clocked_visit_provision__in: items,
                      });
                    }}
                    itemsSelected={visitProvisionFilter}
                    setSelectedFilter={setProvisionFilter}
                    placeHolder={'Clocked Visit Provision'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={[
                      {id: 'CONFIRMED', name: 'CONFIRMED'},
                      {id: 'AUTO CONFIRMED', name: 'AUTO CONFIRMED'},
                      {id: 'NOT CONFIRMED', name: 'NOT CONFIRMED'},
                      {id:'Cancelled' ,name:'Cancelled'}
                    ]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({
                        evv_confirmation_status__in: items,
                      });
                    }}
                    itemsSelected={visitConfirmationFilter}
                    setSelectedFilter={setConfirmationFilter}
                    placeHolder={'EVV Visit Confirmation'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={[
                      {id: 'V V V V', name: 'V V V V'},
                      {id: 'V V V NV', name: 'V V V NV'},
                      {id: 'V V NV V', name: 'V V NV V'},
                      {id: 'V V NV NV', name: 'V V NV NV'},
                      {id: 'V NV V V', name: 'V NV V V'},
                      {id: 'V NV V V', name: 'V NV V V'},
                      {id: 'V NV NV V', name: 'V NV NV V'},
                      {id: 'V NV NV NV', name: 'V NV NV NV'},
                      {id: 'NV V V V', name: 'NV V V V'},
                      {id: 'NV V V NV', name: 'NV V V NV'},
                      {id: 'NV V NV V', name: 'NV V NV V'},
                      {id: 'NV V NV NV', name: 'NV V NV NV'},
                      {id: 'NV NV V V', name: 'NV NV V V'},
                      {id: 'NV NV V NV', name: 'NV NV V NV'},
                      {id: 'NV NV NV V', name: 'NV NV NV V'},
                      {id: 'NV NV NV NV', name: 'NV NV NV NV'},
                    ]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({timeDistance: items});
                    }}
                    itemsSelected={timeDistanceFilter}
                    setSelectedFilter={setDistanceFilter}
                    placeHolder={'Time Distance Confirmation'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
            
                <PrimaryButton type="button" onClick={handleSearch} style={{marginLeft:'4px'}} >
                  <span className={'ms-2 me-2 font-weight-bold ms-3'}>
                    Search
                  </span>
                </PrimaryButton>
              </FiltersLeftGroup>
            </div>
          </FiltersContainer>
        </div>
      </HeaderVisits>


      {/*SUB-HEADER VISITS,CLOCKED HRE,DOWNLOAD ICON ETc. */}
      <div>
        <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-start" >
          <div className="d-flex align-items-center me-2">
          </div>
          <div className="data-container">
            <div className="me-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{'Visits: ' + numVisits}</p>
            </div>
          </div>

          <div className="d-flex align-items-center me-2 ps-2" >
          </div>
          <div className="data-container">
            <div className="me-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{' Visit (hrs) : ' + sumVisitDuration}</p>
            </div>
          </div>

          <div className="d-flex align-items-center me-2 ps-2" >
          </div>
          <div className="data-container">
            <div className="me-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{' Clocked (hrs) : ' + sumClockDuration}</p>
            </div>
          </div>

          <div className="d-flex align-items-center me-2 ps-2">
          </div>
          <div className="data-container">
            <div className="me-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{'Clients: ' + numClients}</p>
            </div>
          </div>
          <div className="data-container me-2 ms-2">
            <div className="me-2">
              <p>{'Locations: ' + numOfLocations}</p>
            </div>
          </div>
          <div className="d-flex align-items-center me-2  ps-2">
          </div>
          <div className="data-container">
            <div className="me-2">
              {/* <p>{'Clients: ' + numClients}</p> */}
              <p>{' Employees: ' + numEmployees}</p>
            </div>
          </div>
          <PublishButton className=" ms-auto ps-2 me-4" disable={downloadDisable} onClick={!downloadDisable && handleDownload}>
            {DOWNLOAD}
          </PublishButton>
        </DataContainer>

      </div>


      {/*RENDERED TABLE with coloum data */}
      <div className={'mt-5'}>
        <>    <ComponentDynamic loading={loadingModal} >
          <Table
            headerColumns={columns_values}
            headerPadding={false}
            noEditOption
            fontSize="0.75rem"
            callBackQuerySort={setQuerySort}
          >
            <div>
              {events && events.map((evvGps, index) => {
                return (
                  <div key={index}>
                    <Row className="mb-1" bgColor>
                      <ColsGrouper
                        className="d-flex"
                        onClick={() => eventClickHandler(evvGps)}
                      >
                        <Col className="sm-hidden" max_width={LARGE_COL} Left overlap={true}>
                          {evvGps.visit_obj && evvGps.visit_obj.visit_number}
                        </Col>
                        
                          <Col
                            className="sm-hidden"
                            max_width={LARGE_COL}
                            Left
                            overlap={true}>
                            {evvGps?.client_obj && evvGps?.client_obj.full_name}
                          </Col>
                      
                          <Col
                            className="sm-hidden"
                            max_width={LARGE_COL}
                            Left overlap={true}>
                            {evvGps.location_obj && evvGps.location_obj.full_name && evvGps.location_obj.full_name}
                          </Col>
                      
                        <Col className="sm-hidden" max_width={LARGE_COL} Left overlap={true}>
                          {evvGps.employee_obj && evvGps.employee_obj.full_name}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvGps.schedule_start_date}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvGps.schedule_end_date}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {'Manual'}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvGps.actual_start_location}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvGps.actual_start_verification}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {'Manual'}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvGps.actual_end_location}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvGps.actual_end_verification}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvGps.clock_out_notes}
                        </Col>
                      </ColsGrouper>
                    </Row>
                  </div>
                )
              }
              )}
              <div className="mt-3">
                <Pagination totalPages={pages}
                  currentPage={currentPage}
                  values={values}
                  dropDownHandleSelect={dropDownHandleSelectPagination}
                  number={PAGE_SIZE_RENDER}
                  dataLength={events.length}
                />
              </div>
            </div>
          </Table>
        </ComponentDynamic></>
      </div>
    </div>
  );
};

EvvGps.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  eventClickHandler: PropTypes.func.isRequired,
  loadEvvs: PropTypes.func.isRequired,
  loadingCalendar: PropTypes.bool.isRequired,
};

export default EvvGps;
