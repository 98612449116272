import React, { useState, useEffect, Fragment, useRef, useContext } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import EditableDropdownList from 'components/SharedComponents/EditableDropdownList';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { useForm } from 'hooks/FormHook';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import {
    ERROR,
    SAVE,
    SAVED,
} from 'utils/constants/messages';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import { AppContext } from "context"
import { editSettingFinance } from 'utils/api/SettingsApi';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';

const BillRateCardSetting = ({
    // billingTabData,
    getApiData,
    readPermission,
    createPermission,
    updatePermission,
    deletePermission
}) => {
    const readOnly = !createPermission || !updatePermission
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingSave, setLoadingSave] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [invoiceType, setInvoiceType] = useState([]);
    const [billTimeUnit, setBillTimeUnit] = useState([]);
    const [billingPattern, setBillingPattern] = useState([]);
    const [billingTerm, setBillingTerm] = useState([]);
    const [optionNumberData, setOptionNumberData] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [billingTabData, setBillingTabData] = useState({});
    const { contextChoices } = useContext(AppContext);

    const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({});



    useEffect(() => {

        setInvoiceType(contextChoices && contextChoices.Invoice_type && contextChoices.Invoice_type.map(y => {
            return { id: y && y.id, name: y && y.name }
        }));

        setBillTimeUnit(contextChoices.bill_time_unit && contextChoices.bill_time_unit.map(y => {
            return { id: y && y.id, name: y && y.name }
        }));

        setBillingPattern(contextChoices && contextChoices.Billing_patterns && contextChoices.Billing_patterns.map(y => {
            return { id: y && y.id, name: y && y.name }
        }));

        setBillingTerm(contextChoices && contextChoices.Billing_terms && contextChoices.Billing_terms.map(y => {
            return { id: y && y.id, name: y && y.name }
        }));

        setPaymentMethod(contextChoices && contextChoices.Payment_method && contextChoices.Payment_method.map(y => {
            return { id: y && y.id, name: y && y.name }
        }));

    }, [contextChoices])

    const getData = async () => {
        const response = await getApiData();
        setBillingTabData(response?.data?.billing || {})
    }
    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getBillingData();
    }, [billingTabData])

    const getBillingData = () => {
        if (billingTabData) {
            values.contract_visit_bill_rate = billingTabData.contract_visit_bill_rate && billingTabData.contract_visit_bill_rate;
            values.scheduled_visit_bill_rate = billingTabData.scheduled_visit_bill_rate && billingTabData.scheduled_visit_bill_rate;
            values.actual_bill_rate_card = billingTabData.actual_bill_rate_card && billingTabData.actual_bill_rate_card;
            values.invoice_type = billingTabData.invoice_type && billingTabData.invoice_type;
            values.bill_rate_model = billingTabData.bill_rate_model && billingTabData.bill_rate_model;
            values.billing_duration_type = billingTabData.billing_duration_type && billingTabData.billing_duration_type;
            values.billing_duration_source = billingTabData.billing_duration_source && billingTabData.billing_duration_source;
            values.bill_time_unit = billingTabData.bill_time_unit && billingTabData.bill_time_unit;
            values.funder_discount = Number(billingTabData.funder_discount && billingTabData.funder_discount);
            values.billing_pattern = billingTabData.billing_pattern && billingTabData.billing_pattern;
            values.billing_term = billingTabData.billing_term && billingTabData.billing_term;
            values.payment_method = billingTabData.payment_method && billingTabData.payment_method;
            values.funder_cancellation_fees = Number(billingTabData.funder_cancellation_fees && billingTabData.funder_cancellation_fees);

            setValues({ ...billingTabData, ...values })
        }
    }

    useEffect(() => {
        numberDropdown();
    }, [])

    const numberDropdown = () => {
        let array = [];
        for (var i = 1; i <= 21; i++) {
            array.push({ id: 5 * i - 5, name: 5 * i - 5 });
        }
        setOptionNumberData(array);

    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        if (textStatus === 'SAVED') { return; }
        setLoadingSave(true);
        const bodyData = {
            billing: {
                id: values.id,
                contract_visit_bill_rate: values.contract_visit_bill_rate,
                scheduled_visit_bill_rate: values.scheduled_visit_bill_rate,
                actual_bill_rate_card: values.actual_bill_rate_card,
                invoice_type: values.invoice_type,
                bill_rate_model: values.bill_rate_model,
                billing_duration_type: values.billing_duration_type,
                billing_duration_source: values.billing_duration_source,
                bill_time_unit: values.bill_time_unit,
                billing_pattern: values.billing_pattern,
                billing_term: values.billing_term,
                payment_method: values.payment_method,
                funder_discount: values.funder_discount,
                funder_cancellation_fees: values.funder_cancellation_fees
            }
        }
        await editSettingFinance(bodyData)
            .then(res => {
                if (res.message === "Finance Data Successfully Updated") {
                    onSuccess()
                }
                else { onError() }
            })
            .catch(onError)

    };

    const onSuccess = () => {
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CHECK);
        setTextStatus(SAVED);
    };

    const onError = () => {
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        // setShowError(true);
        setCheckRequires(true);
        setErrorMessage(
            "Some of the fields have invalid or empty values. Please review."
        );
    };

    const dropDownHandleSelect = (item, nameItem) => {
        values[nameItem] = item[nameItem];
        setValues({ ...values });
    };

    const handleChange = (e) => {
        values.funder_cancellation_fees = e.target.value;
        setValues({ ...values })
    }

    const handleChangeDiscount = (e) => {
        values.funder_discount = e.target.value;
        setValues({ ...values })
    }


    return (
        <>{readPermission ? (
            <div>
                <div className="d-flex align-items-center mt-2">
                    <FlexGrid3Container className={'w-100 mb-2'}>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Contract Visit Bill Rate Card'}
                                options={[
                                    { id: 'Scheduled Visit Bill Rate Card', name: 'Scheduled Visit Bill Rate Card' },
                                    { id: 'Funder Bill Rate Card', name: 'Funder Bill Rate Card' },
                                    { id: 'Manual Bill Rate Card', name: 'Manual Bill Rate Card' },
                                ]}
                                selectedOption={values.contract_visit_bill_rate ? values.contract_visit_bill_rate : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { contract_visit_bill_rate: value ? value : null }, 'contract_visit_bill_rate'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Scheduled Visit Bill Rate Card'}
                                options={[
                                    { id: 'Scheduled Visit Bill Rate Card', name: 'Scheduled Visit Bill Rate Card' },
                                    { id: 'Funder Bill Rate Card', name: 'Funder Bill Rate Card' },
                                    { id: 'Manual Bill Rate Card', name: 'Manual Bill Rate Card' },
                                ]}
                                selectedOption={values.scheduled_visit_bill_rate ? values.scheduled_visit_bill_rate : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { scheduled_visit_bill_rate: value ? value : null }, 'scheduled_visit_bill_rate'
                                    )}
                                disabled={readOnly}

                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Actual Bill Rate Card'}
                                options={[
                                    { id: 'Scheduled Visit Bill Rate Card', name: 'Scheduled Visit Bill Rate Card' },
                                    { id: 'Funder Bill Rate Card', name: 'Funder Bill Rate Card' },
                                    { id: 'Manual Bill Rate Card', name: 'Manual Bill Rate Card' },
                                ]}
                                selectedOption={values.actual_bill_rate_card ? values.actual_bill_rate_card : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { actual_bill_rate_card: value ? value : null }, 'actual_bill_rate_card'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>

                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Invoice Type'}
                                options={invoiceType ? invoiceType : []}
                                selectedOption={values.invoice_type ? values.invoice_type : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { invoice_type: value ? value : null }, 'invoice_type'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Bill Rate Model'}
                                options={[
                                    { id: 'Scheduled Visit Bill Rate Card', name: 'Scheduled Visit Bill Rate Card' },
                                    { id: 'Funder Bill Rate Card', name: 'Funder Bill Rate Card' },
                                    { id: 'Manual Bill Rate Card', name: 'Manual Bill Rate Card' },
                                ]}
                                selectedOption={values.bill_rate_model ? values.bill_rate_model : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { bill_rate_model: value ? value : null }, 'bill_rate_model'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Billing Duration Type'}
                                options={[
                                    { id: 'Actual Duration', name: 'Actual Duration' },
                                    { id: 'Timesheet', name: 'Timesheet' },
                                ]}
                                selectedOption={values.billing_duration_type ? values.billing_duration_type : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { billing_duration_type: value ? value : null }, 'billing_duration_type'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Billing Duration Source'}
                                options={[
                                    { id: 'Actuals', name: 'Actuals' },
                                    { id: 'Timesheet', name: 'Timesheet' },
                                ]}
                                selectedOption={values.billing_duration_source ? values.billing_duration_source : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { billing_duration_source: value ? value : null }, 'billing_duration_source'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Bill Time Unit'}
                                options={billTimeUnit ? billTimeUnit : []}
                                selectedOption={values.bill_time_unit ? values.bill_time_unit : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { bill_time_unit: value ? value : null }, 'bill_time_unit'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            {/* <DropdownSearchable
          placeHolder={'Funder discount'}
          options={optionNumberData?optionNumberData:[]}
          selectedOption={ values.funder_discount   ? values.funder_discount : null }
          onChange={value =>
          dropDownHandleSelect(
           {funder_discount: value ? value : null},  'funder_discount'
            )  }
          /> */}
                            <EditableDropdownList
                                placeHolder={'Funder discount'}
                                options={optionNumberData ? optionNumberData : []}
                                data={values.funder_discount}
                                onChnageData={handleChangeDiscount}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Billing Pattern'}
                                options={billingPattern ? billingPattern : []}
                                selectedOption={values.billing_pattern ? values.billing_pattern : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { billing_pattern: value ? value : null }, 'billing_pattern'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Billing Term'}
                                options={billingTerm ? billingTerm : []}
                                selectedOption={values.billing_term ? values.billing_term : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { billing_term: value ? value : null }, 'billing_term'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            <DropdownSearchable
                                placeHolder={'Payment Method'}
                                options={paymentMethod ? paymentMethod : []}
                                selectedOption={values.payment_method ? values.payment_method : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { payment_method: value ? value : null }, 'payment_method'
                                    )}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                        <FlexGrid3Item className="pe-1 pt-1 pb-1 ps-1">
                            {/* <DropdownSearchable
          placeHolder={'Funder Cancellation Fees'}
          options={optionNumberData?optionNumberData:[]}
          selectedOption={ values.funder_cancellation_fees   ? values.funder_cancellation_fees : null }
          onChange={value =>
          dropDownHandleSelect(
           {funder_cancellation_fees: value ? value : null},  'funder_cancellation_fees'
            )  }
          /> */}

                            <EditableDropdownList
                                placeHolder={'Funder Cancellation Fees'}
                                options={optionNumberData ? optionNumberData : []}
                                data={values.funder_cancellation_fees}
                                onChnageData={handleChange}
                                disabled={readOnly}
                            />
                        </FlexGrid3Item>
                    </FlexGrid3Container>




                </div>

                <div
                    className={
                        'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
                    }>
                    <div className={'mb-3'}>
                        {errorMessage ? (
                            <ErrorMessage>
                                <label className={'mt-2'}>{errorMessage}</label>
                            </ErrorMessage>
                        ) : (
                            <Fragment />
                        )}
                    </div>
                    <div className={'d-flex justify-content-center justify-content-md-end'}>
                        <div className={'ms-0 ms-md-3 me-2'}>
                            <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit} disabled={readOnly}>
                                <span className={'me-2'}>{textStatus}</span>
                                {!loadingSave ? (
                                    buttonIcon
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm me-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <NoPermissionContainer>
                You don't have permission to read the information.
            </NoPermissionContainer>
        )
        }
        </>
    )
}


export default BillRateCardSetting