import React, {useState, useEffect, useRef} from 'react';
import {useForm} from 'hooks/FormHook';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  getLeftButton,
  getRightButton,
  SpecializeCaseButton,
} from './IconsMethods';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {PURPLE, SOFT_RED, GRAY_300} from 'shared/styles/constants/colors';
import {Toast} from 'primereact/toast';
import {ON_EDIT} from 'utils/constants/settingsStates';
import {
  FlexGrid3Item,
  IconWrapper,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import {RiDeleteBinLine, RiSaveLine} from 'react-icons/ri';
import {MdEdit, MdCheck, MdClose} from 'react-icons/md';
import InputColorPicker from '../InputColorPicker';
import {values} from 'lodash';

const ItemList = ({
  dataValue,
  handleSubmit,
  toEditId,
  toDeleteId,
  cancelEdit,
  removeNewService,
  leftButtonIcon,
  rightButtonIcon,
  enabledInput,
  tryReSubmit,
  labelInputTitle,
  addOneDropdown,
  addCheckBox,
  addSecondDropdown,
  specializeCase,
  specializedMethods,
  addOneInput,
  addSecondInput,
  fieldAttributes,
  userStaff,
  update,
  create,
  deletePermission,

  deletePermissionPrioritySettings,
  readPermissionPrioritySettings,
  updatePrioritySettings,
  filterAdmin,
  filterSuperAdmin,
  createPrioritySettings,
}) => {
  const {values, setValues, useInput, errors, setCheckRequires} = useForm({});
  const [buttonLeft, setButtonLeft] = useState(<div />);
  const [buttonRight, setButtonRight] = useState(<div />);
  const [specialButton, setSpecialButton] = useState(<div />);
  const [defaultChecks, setDefaultChecks] = useState(false);
  const [editButton, setEditButton] = useState(<div />);
  const toast = useRef();
  const mendatoryData = [
    'Availability',
    'Unavailability',
    'Absence',
    'Shift',
    'On Call',
  
    'Car - Driver - Shift',
  ];
  useEffect(() => {
    if (enabledInput !== dataValue.id) {
      assignValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledInput]);

  useEffect(() => {
    setButtonLeft(
      getLeftButton(
        leftButtonIcon,
        executeHandleSubmit,
        toEditId,
        dataValue,
        enabledInput,
        // tryReSubmit,
        values
      )
    );

    setEditButton(
      getLeftButton(
        leftButtonIcon,
        //  disabled={true}
        toEditId,
        dataValue,
        enabledInput,
        tryReSubmit,
        values
      )
    );
    setButtonRight(
      getRightButton(
        rightButtonIcon,
        dataValue,
        removeNewService,
        executeCancel,
        toDeleteId,
        enabledInput,
        specializeCase,
        values
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftButtonIcon, rightButtonIcon, enabledInput, values]);
  console.log(dataValue,'>>>>>>>>>>>',values, 'AAAAAAA');
  useEffect(() => {
    assignValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataValue]);
  const colorCode = useInput('color_code');
  const reasons = useInput('is_reason', );

  useEffect(() => {
    setSpecialButton(
      SpecializeCaseButton(specializeCase, specializedMethods, dataValue)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specializedMethods, values]);

  const assignValues = () => {
    addOneDropdown && addOneDropdown.careCategory
      ? addSecondDropdown
        ? setValues({
            name: dataValue.name || '',
            id: dataValue.id || '',
            disabled: true,
            is_staff: dataValue.is_staff,
            care_category:
              dataValue.task_type &&
              dataValue.task_type.care_category &&
              (typeof dataValue.task_type.care_category === 'object'
                ? dataValue.task_type.care_category.id
                : dataValue.task_type.care_category),
            task_type:
              dataValue.task_type &&
              (typeof dataValue.task_type === 'object'
                ? dataValue.task_type.id
                : dataValue.task_type),
            feedback: dataValue.feedback,
            color_code: dataValue.color_code || '',
            is_reason: dataValue.is_reason || '',
          })
        : setValues({
            name: dataValue.name || '',
            id: dataValue.id || '',
            disabled: true,
            is_staff: dataValue.is_staff,
            care_category:
              dataValue.care_category &&
              (typeof dataValue.care_category === 'object'
                ? dataValue.care_category.id
                : dataValue.care_category),
            feedback: dataValue.feedback,
            color_code: dataValue.color_code || '',
            is_reason: dataValue.is_reason || '',
          })
      : addSecondInput
      ? setValues({
          name: dataValue.name || '',
          id: dataValue.id || '',
          disabled: true,
          is_staff: dataValue.is_staff,
          category: dataValue.category || '',
          absence_type: dataValue.absence_type || '',
          user_type_category: dataValue.user_type_category || '',
          color_code: dataValue.color_code || '',
          is_reason: dataValue.is_reason || '',

          final_price: dataValue.final_price,
        })
      : setValues({
          name: dataValue.name || '',
          id: dataValue.id || '',
          disabled: true,
          is_staff: dataValue.is_staff,
          category: dataValue.category || '',
          absence_type: dataValue.absence_type || '',
          user_type_category: dataValue.user_type_category || '',
          is_reason: dataValue.is_reason || '',
        });
  };

  const executeHandleSubmit = async () => {
    if (errors.length >= 1) {
      setCheckRequires(true);
      return;
    }
    const response = await handleSubmit(values);

    if (response) {
      dataValue.id = response.id;
      dataValue.name = response.name;
      dataValue.is_staff = response.is_staff;
      if (addOneDropdown) {
        if (addSecondDropdown) {
          dataValue[addSecondDropdown.value] =
            response[addSecondDropdown.value];
        } else {
          dataValue[addOneDropdown.value] = response[addOneDropdown.value];
        }
      }
      assignValues();
    }
  };

  const executeCancel = () => {
    assignValues();
    cancelEdit();
  };

  const dropDownHandleSelect = item => {
    setValues({...values, ...item});
  };

  const handleCheckBox = val => {
    if (!(leftButtonIcon === ON_EDIT))
      if (val.target.checked) {
        values.is_reason = true;
      } else {
        values.is_reason = false;
      }
    setValues({...values});
  };

  const renderInput1 = props => {
    if (!(addOneDropdown && addOneDropdown.careCategory)) {
      return (
        <div className={'w-100'}>
          <InputLabelTop
            required
            disabled={enabledInput !== dataValue.id}
            type="text"
            label={labelInputTitle}
            {...props}
            {...fieldAttributes}
          />
        </div>
      );
    }
  };

  const renderInput2 = props => {
    if (addOneDropdown && addOneDropdown.careCategory) {
      return (
        <div className={'w-100 ms-2'}>
          <InputLabelTop
            required
            disabled={enabledInput !== dataValue.id}
            type="text"
            label={labelInputTitle}
            {...props}
            {...fieldAttributes}
          />
        </div>
      );
    }
  };
  const renderInput3 = props => {
    if (addSecondInput) {
      return (
        <div className={'w-100 ms-2'}>
          <InputColorPicker
          
            required
            disabled={enabledInput !== dataValue.id}
            type="text"
            id="colour_code_shift_pattern"
            label={addSecondInput.label}
            value={dataValue.colo}
            {...colorCode}
          />
        </div>
      );
    }
  };
  const handleToast = async event => {
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: 'You can not edit this Data',
    });
  };
  const handleDeleteToast = async event => {
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: 'You can not delete this Data',
    });
  };
  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <div className={'d-flex ps-3 pe-3'}>
        {renderInput1({...useInput('name', 'isRequired')})}
        {!addOneDropdown ||
        !addOneDropdown.options ||
        addOneDropdown.options.length <= 0 ? null : (
          <AddOneDropdown
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            values={values}
            dropDownHandleSelect={dropDownHandleSelect}
            valuesDropdown={addOneDropdown}
            specializeCase={specializeCase}
          />
        )}
        {!addSecondDropdown ||
        !addSecondDropdown.options ||
        addSecondDropdown.options.length <= 0 ? null : (
          <AddTwoDropdown
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            values={values}
            dropDownHandleSelect={dropDownHandleSelect}
            valuesDropdown={addSecondDropdown}
            specializeCase={specializeCase}
          />
        )}
        {renderInput2({...useInput('name', 'isRequired')})}
        {renderInput3({...useInput('final_price')})}
        {!addOneInput ? null : (
          <AddOneInput
            useInput={useInput}
            enabledInput={enabledInput}
            dataValue={dataValue}
            valuesInput={addOneInput}
          />
        )}

        <div className={'ms-3'}>
          <>
            <div className="d-flex pt-1">
              {addCheckBox && (
                <div className={'d-flex align-items-center pe-4'}>
                  <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                    <div className=" mt-1 d-flex">
                      <div className=" ">
                        <small>
                          {' '}
                          <TitleSection>Reason</TitleSection>
                        </small>
                      </div>
                      <div className=" ps-1  ms-1">
                        <input
                          style={{width: '20px', height: '20px'}}
                          type="checkbox"
                          checked={values.is_reason}
                          id="flexCheckDefault"
                          {...reasons}
                          onChange={val => {
                            handleCheckBox(val);

                          }}
                        />
                      </div>
                    </div>
                  </FlexGrid3Item>
                </div>
              )}
              {/* {filterAdmin ||filterSuperAdmin  || update&& ( */}

              {/* {mendatoryData.includes(values?.name) ? (
                <div className={'me-2'}>
                  {' '}
                  <IconWrapper>
                    <MdEdit color={PURPLE} onClick={() => handleToast()} />
                  </IconWrapper>
                </div>
              ) : ( */}
                <div className={'me-2'}>{buttonLeft}</div>
            
              {/* ) } */}

              {/* )}  */}

              {/* commnented pbelow , ermissions of company>>priority delete  icon beacuse its also used in setting>>team -delete  icon  . beacuse of priority delete  icon permission . delete  icon wasnt visble in setting>> team  */}

              {/* {filterAdmin || filterSuperAdmin || deletePermission && ( */}
              {
                mendatoryData.includes(values?.name) ? (
                  <div className={'me-2'}>
                    {' '}
                    <IconWrapper>
                      <RiDeleteBinLine
                        color={SOFT_RED}
                        onClick={() => handleDeleteToast()}
                      />
                    </IconWrapper>
                  </div>
                ) : (
                  <div className={'me-2'}>{buttonRight}</div>
                )
                // /* )} */}
              }
              {/* {filterAdmin ||filterSuperAdmin && ( */}

              {specialButton}
              {/* //  )} */}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

function AddOneDropdown({
  useInput,
  enabledInput,
  dataValue,
  values,
  dropDownHandleSelect,
  valuesDropdown,
}) {
  return (
    <div className={'w-100 ms-2'}>
      <DropdownSearchable
        placeHolder={valuesDropdown.placeholder}
        required={valuesDropdown.required}
        {...useInput(
          valuesDropdown.value,
          `${valuesDropdown.required ? 'isRequired' : ''}`
        )}
        disabled={enabledInput !== dataValue.id}
        options={valuesDropdown.options[valuesDropdown.choicesValue]}
        selectedOption={
          values[valuesDropdown.value]
            ? values[valuesDropdown.value]
            : values.absence_type || values.user_type_category
        }
        onChange={value =>
          dropDownHandleSelect({[valuesDropdown.value]: value ? value : null})
        }
      />
    </div>
  );
}
function AddTwoDropdown({
  useInput,
  enabledInput,
  dataValue,
  values,
  dropDownHandleSelect,
  valuesDropdown,
}) {
  const taskTypeOptions = item => {
    if (item && values.care_category) {
      let options = item.filter(val => {
        if (val.care_category.id === values.care_category) {
          return {id: val.id, name: val.name};
        }
      });
      return options || [];
    } else {
      return [];
    }
  };
  return (
    <div className={'w-100 ms-2'}>
      <DropdownSearchable
        placeHolder={valuesDropdown.placeholder}
        required={valuesDropdown.required}
        {...useInput(
          valuesDropdown.value,
          `${valuesDropdown.required ? 'isRequired' : ''}`
        )}
        disabled={enabledInput !== dataValue.id}
        options={taskTypeOptions(
          valuesDropdown.options[valuesDropdown.choicesValue]
        )}
        selectedOption={values[valuesDropdown.value]}
        onChange={value =>
          dropDownHandleSelect({[valuesDropdown.value]: value ? value : null})
        }
      />
    </div>
  );
}

function AddOneInput({useInput, enabledInput, dataValue, valuesInput}) {
  return (
    <div className={'w-100 ms-2'}>
      <div className={'d-flex align-items-center pe-4'}>
        <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
          <div className=" mt-1 d-flex">
            <div className=" ">
              <small>
                {' '}
                <TitleSection style={{width: '120%'}}>Ask Reason</TitleSection>
              </small>
            </div>
            <div className=" ps-1  ms-1">
              <input
                style={{width: '20px', height: '20px'}}
                type="checkbox"
                // checked={values[addCheckBox.value]}
                id="flexCheckDefault"
                //   onChange={val => {
                //     handleCheckBox(val);
                //   }}
                {...useInput(
                  valuesInput.value,
                  `${valuesInput.required ? 'isRequired' : ''}`
                )}
              />
            </div>
          </div>
        </FlexGrid3Item>
      </div>
    </div>
  );
}

export default ItemList;
