import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import Dropdown from 'components/SharedComponents/Dropdown';
import AddModal from 'components/DashboardComponents/Settings/SettingsClient/Funder/AddModal';
// imported  new files
import { getClientTypes } from 'utils/api/CoreApi';
import { setClientContract, getFunderDetails, getClientTask, getClientGroupTask, getPlanned, getMedicationDoseTimingOptionList, getClientsServicesAll } from 'utils/api/ClientApi';
import { getFundersList } from 'utils/api/ClientApi'
import { useHistory } from 'react-router';
import _ from "lodash"


import moment from 'moment';
import {
  InvalidOrEmptyValues,
  DetailsNotCreated,
  SAVE,
  SAVED,
  ERROR
} from 'utils/constants/messages';
import {
  Row,
  Col,
  IconWrapper,
  DayPill,
} from 'components/SharedComponents/Table/styles';
import ModalServiceClient from './ModalServiceClient';
import {
  getClientsServicesById,
  getClientsServices,
  deleteServiceContract,
} from 'utils/api/ClientApi';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useForm } from 'hooks/FormHook';
import { RiDeleteBinLine } from 'react-icons/ri';
import { TiArrowBackOutline } from 'react-icons/ti';
import { BsFolderPlus } from 'react-icons/bs';
import { headerColumns } from './headerColumsList';
import { LARGE_COL, MEDIUM_COL, EX_LARGE_COL } from 'shared/styles/constants/columns';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { getPageToSearchParam } from 'shared/methods';
import { useModal } from 'hooks/ModalHook';
import { FLOPPY, CROSS, CHECK } from 'utils/constants/icons';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { AppContext } from "context"
import {

  Toggle,
  ToggleLabel,
  ToggleWrapper,
} from 'shared/styles/buttons';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';
import { getCategoryPermission, getPermissions, getSubSectionPermissions } from '../../../../utils/localStorage/user';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import { client_service_contract_choices } from 'utils/choiceConstant';

const NEW_CONTRACT = 'new';

const ServiceClientListAll = ({
  selectedClientId,
  choices,
  allErrors,
  errorSplitPercentage,
  setErrorSplitPercentage,
  loadingFinish,
  textStatus,
  contractId,
  savedContract,
  setSavedContract,
  addressValues,
  clientFullName,
  setTextStatus,
  clientErrors,
  promisesWithErrors,
  //cliId,
  filterAdmin,
  filterSuperAdmin,
  contextSubscription,
  rightPermission,
  userClient,
  readServicePermissions,
  createServicePermissions,
  updateServicePermissions,
  deleteServicePermissions,
  contextRelativesClient
}) => {
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setCheckRequires,
    setFormErrors
  } = useForm({});
  const [idSelected, setIdSelected] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalValue, setModalValue] = useState(false);
  const [loadingSaveOnService, setLoadingSaveOnService] = useState(false);
  const [editToggle, setEditToggle] = useState(false)
  const [pages, setPages] = useState(0);
  const [serviceContracts, setServiceContracts] = useState([]);
  const [ServiceContractLength, setServiceContractLength] = useState();
  const [simplePagination] = useState(true);
  const [dateError, setDateError] = useState([]);
  const [timeError, setTimeError] = useState([])
  const [clientTaskOptions, setClientTaskOptions] = useState([])
  const [groupTaskOptions, setGroupTaskOptions] = useState([])
  const [clientTypes, setClientTypes] = useState([]);  //clienttype
  const mountedRef = useRef(true);
  const [funderList, setFunderList] = useState([])
  const [medDoseTimingList, setMedDoseTimingList] = useState([])
  const [plannedTasks, setPlannedTasks] = useState([]);
  const [editMode, setEditMode] = useState(true)
  const [details, setDetails] = useState([])
  const [earlieMessage, setEarlieMessage] = useState('')
  const [earlieErrors, setEarlieError] = useState([])
  const [showEarlieError, setShowEarlieError] = useState(false)
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );
  const {
    setValue: setModalFunderValue,
    value: modalFunderValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const [loadingSave, setLoadingSave] = useState(false)
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [modalLoading, setModalLoading] = useState(true)

  const servAddressSection = 'service_address';
  const history = useHistory()
  const location = useLocation();
  const { handleGetChoices } = useContext(AppContext)
  useEffect(() => {
    handleGetChoices(client_service_contract_choices)
  }, [])
  useEffect(() => {
    loadClientTypes();
    funderLists();
    medicationDoseTimeLists()
  }, [])




  const loadClientTypes = () => {
    getClientTypes().then(response => {
      if (!mountedRef.current) return null;
      response.results.unshift({ id: '', name: 'All ' });
      setClientTypes(response.results);
    });
  };

  const dropDownHandleSelectClient = item => {
    setValues({ ...values, ...item });
  };

  const funderLists = () => {
    getFundersList()
      .then(res => {
        if (res.length > 0) {
          let newFunders = res.map(funder => {
            return {
              id: funder.id,
              name: funder.funder_name
            }
          })
          setFunderList([...newFunders, { id: "add_funder", name: "+Add New Funder" }])
        }
      })
  }

  const medicationDoseTimeLists = () => {
    getMedicationDoseTimingOptionList(selectedClientId)
      .then(res => {
        if (res.message.length > 0) {
          let newMed = res?.message?.map(med => {
            return {
              id: med.id,
              name: med.name
            }
          })
          setMedDoseTimingList([...newMed])
        }
      })
  }




  useEffect(() => {
    if (selectedClientId) {
      getClientContracts();
      getPlannedTasks();
    }
    else {
      setModalLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId, currentPage, savedContract]);

  useEffect(() => {
    if (!selectedClientId) {
      setModalValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId]);

  useEffect(() => {
    if (contractId) {
      values.service_clients[0].id = contractId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);


  useEffect(() => {

    if (idSelected) {
      searchClientService();
      setShowError(false);
      setErrorMessage('');
      setDateError([]);
      errors.splice(0, errors.length - 1);
    } else {
      values.service_clients = [
        {
          visits: [],
          service_funders: [],
          service_address: {},
        },
      ];
      setValues({ ...values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalValue]);

  useEffect(() => {
    if (choices && choices.care_task && choices.group_task) {
      groupTaskFn();
    }
  }, [choices])

  useEffect(() => {
    const haveDateErrors = dateError.find(data => data === true);

    if (showError && errors.length === 0 && !allErrors && !haveDateErrors) {
      setShowError(false);
      setCheckRequires(false);
    }

    if (allErrors) {
      setShowError(true);
      setCheckRequires(true);
      if (clientErrors.length >= 1) {
        setErrorMessage(DetailsNotCreated);
      } else {
        setErrorMessage(InvalidOrEmptyValues);
      }
    }

    if (promisesWithErrors.length >= 1) {
      setShowError(true);
      setCheckRequires(true);

      let error = '';

      promisesWithErrors.forEach(promise => {
        error += `*${promise} `;
      });

      setErrorMessage(`Error while saving ${error}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, allErrors, dateError]);

  const checkBeforeSend = (
    evt,
    closeModal,
    saveTrigger,
    values,
    toDeleteVisits,
    toDeleteSplitFunder,
    toDeleteVisitSkill,
  ) => {

    // const haveDateErrors = dateError.find(data => data === true);
    // const haveTimeErrors = timeError.find(data => data === true);
    // if (
    //   (!isValid && errors.length > 0) ||
    //   errors.length > 0 ||
    //   (allErrors && promisesWithErrors.length <= 0) ||
    //   haveDateErrors || haveTimeErrors
    // ) {
    //   setCheckRequires(true);
    //   setShowError(true);

    //   if (allErrors && clientErrors.length >= 1) {
    //     setErrorMessage(DetailsNotCreated);
    //   } else {
    //     setErrorMessage(InvalidOrEmptyValues);
    //   }
    //   return;
    // }

    // if (promisesWithErrors.length >= 1) {
    //   let error = '';

    //   promisesWithErrors.forEach(promise => {
    //     error += `*${promise} `;
    //   });

    //   setErrorMessage(`Error while saving ${error}`);
    // }

    setSavedContract('unsaved');

    handleSubmit(
      evt,
      closeModal,
      saveTrigger,
      values,
      toDeleteVisits,
      toDeleteSplitFunder,
      toDeleteVisitSkill,
    );
  };


  const handleSubmit = (
    evt,
    closeModal = false,
    saveTrigger = true,
    serviceContractValues = { service_clients: [] },
    toDeleteVisits = [],
    toDeleteSplitFunder = [],
    toDeleteVisitSkill = []
  ) => {



    if (loadingSave) {
      return;
    }
    if ((!isValid && errors.length > 0) || errorSplitPercentage !== '') {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    let body = _.cloneDeep(values)
    if (body.service_clients) {
      delete body.service_clients
    }
    if (body.service_funder) {
      delete body.service_funder
    }

    // if(body.service_funderss){
    //   delete body.service_funderss
    // }

    if (body[servAddressSection]) {
      if (body[servAddressSection].same_as_client === undefined) {
        body[servAddressSection].same_as_client = false;
      }

      if (body[servAddressSection].smoking === undefined) {
        body[servAddressSection].smoking = false;
      }
    }
    // if(body.service_funders.status){
    //   if(body.service_funders.status==1){
    //     body.service_funders.status=true
    //   }
    //   if(body.service_funders.status==2){
    //     body.service_funders.status=false
    //   }
    // }
    // if(body.service_funders.core_funder){
    //     if(body.service_funders.self_funded==2){
    //       body.service_funders.name=funderList.find(funder=>funder.id==body.service_funders.name).name 
    //     }
    // }
    // if(body.service_funders.self_funded){
    //   if(body.service_funders.self_funded==1){
    //     body.service_funders.self_funded=true
    //   }
    //   if(body.service_funders.self_funded==2){
    //     body.service_funders.self_funded=false
    //   }
    // }
    if (body.service_funders) {
      body.service_funders = body.service_funders.map((val) => {
        let name_temp = funderList.find(funder => funder.id == val.name) && funderList.find(funder => funder.id == val.name).name
        return { ...val,name: (val.self_funded === 1) ? val.name : name_temp, self_funded: (val.self_funded === 1) ? true : false, status: (val.status === 1) ? true : false }
      })
    }
    if (body.addFunder) {
      delete body.addFunder
    }
    // if (body['service_funders'].is_split === undefined) {
    //   body['service_funders'].is_split = false;
    // }
    if (body.visits) {
      if (values.service_address.destination) {
        body.visits = body.visits.map(v => ({ ...v, service_destination: values.service_address.destination }))
      }
      body.visits && body.visits.forEach((visit, index) => {
        if (visit.id === '') {
          delete body.visits[index].id
        }
        if (visit.employee_required) {
          body.visits[index].requirement = _.cloneDeep(visit.employee_required)
          body.visits[index].requirement.forEach((req, reqIndex) => {
            if (!req.no_of_employees) {
              body.visits[index].requirement[reqIndex].no_of_employees = 1
            }
            if (req.id === '') {
              delete req.id;
            }
            if (req.client_service_visit === '') {
              delete req.client_service_visit;
            }
            if (req.duration || req.duration === 0) {
              const formatted = moment.utc(req.duration * 1000).format('HH:mm:ss');
              body.visits[index].requirement[reqIndex].duration = (formatted == 'Invalid date') ? '00:00:00' : formatted;
            }
            body.visits[index].requirement[reqIndex].client_task = {}
            if (req.task_id && req.task_id.length > 0) {
              body.visits[index].requirement[reqIndex].client_task.task_id = req.task_id.map((val) => {
                return ({ value: val.value })
              })
            }
            if (req.skills && req.skills.length > 0) {
              body.visits[index].requirement[reqIndex].skills = req.skills.map((val) => {
                if(val.skill_id){
                  return {label:val.label, value: val.value, id: val.skill_id}
                }else{
                return { label:val.label, value: val.value }
                }
              })
            }
            if (req.competency && req.competency.length > 0) {
              body.visits[index].requirement[reqIndex].competency = req.competency.map((val) => {
                if(val.competency_id){
                  return {label: val.label, value: val.value, id: val.competency_id}
                }else{
                return { label: val.label, value: val.value }
                }
              })
            }
            if (req.specific_groups && req.specific_groups.length > 0) {
              body.visits[index].requirement[reqIndex].specific_groups = req.specific_groups.map((val) => {
                if(val.planned_id){
                  return {specific_group_id: val.value, id: val.planned_id}
                }else{
                return { specific_group_id: val.value }
                }
              })
            }
            if (req.medication && req.medication.length > 0) {
              body.visits[index].requirement[reqIndex].medication = req.medication.map((val) => {
                if(val.med_id){
                  return {medication_dose_time: val.value, id: val.med_id}
                }else{
                return { medication_dose_time: val.value }
                }
              })
            }
          })
          delete body.visits[index].employee_required
        }
        if (visit.time_critical === undefined) {
          body.visits[index].time_critical = false;
        }

        if (visit.include_holiday === undefined) {
          body.visits[index].include_holiday = false;
        }

        if (visit.is_suspended === undefined) {
          body.visits[index].is_suspended = false;
        }
        if (visit.is_overnight === undefined) {
          body.visits[index].is_overnight = false;
        }
        if (visit.full_day === undefined) {
          body.visits[index].full_day = false;
        }
        // BE expects repeat default value as 1
        if (!visit.repeat || visit.repeat <= 0) {
          body.visits[index].repeat = 1;
        }
        // if(values.service_address.destination){
        //   if(!body.visit[index].service_destination){
        //     body.visit[index].service_destination=''
        //   }
        //   body.visit[index].service_destination=values.service_address.destination
        // }
        // BE expects repeat default value as 1
        if (visit.end_value === undefined || visit.end_value === null) {
          body.visits[index].end_value = '';
        }

        // format duration from seconds to HH:mm:ss
        if (visit.duration || visit.duration === 0) {
          const formatted = moment.utc(visit.duration * 1000).format('HH:mm:ss');
          body.visits[index].duration = (formatted == 'Invalid date') ? '00:00:00' : formatted;
        }

        if (visit.full_day) {
          body.visits[index].preferred_start_time = "00:00:00"
          body.visits[index].preferred_end_time = "23:59:59"
          // delete body.visits[index].full_day
        }
        visit.skills_required && delete body.visits[index].skills_required
      })


    }

    if (saveTrigger) {
      setLoadingSave(true);
    }

    let value = [];
    // let clientId = ''
    // if (location.pathname.includes('/client/add')) {
    //   clientId = cliId
    // } else {
    //   clientId = selectedClientId
    // }
    setClientContract(body, selectedClientId)
      .then(response => {
        if (response.status === 400) {
          if (response.message) {
            value = response.message
          } else {
            value = Object.keys(response)[0] + "-" + Object.values(response)[0]
          }
          throw value;
        }
        setShowError(false);
        onSuccess(response);
      })
      .catch(onError);
  };
  const onSuccess = async (response) => {
    if (response.status === 200 || response.status === 201) {
      // let clientId=''
      // if(location.pathname.includes('/client/add')){
      //   clientId=cliId
      // }else{
      //   clientId=selectedClientId
      // }
      handleModalOnSave()

      let contractId = response.message.id;
      // if employee id

      setValues({
        ...values,
        id: contractId,
      });

      // setPrefDet(clientId)
      setLoadingSave(false);
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
    }
  };
  const onError = err => {
    setLoadingSave(false);
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(
      err.message
    );
  };
  const getClientContracts = async () => {
    const pageToSearch = getPageToSearchParam(
      '',
      setCurrentPage,
      simplePagination,
      currentPage,
    );
    const response = await getClientsServicesAll({
      selectedClientId,
      idSelected,
      ...pageToSearch,
    });

    setServiceContractLength(response.results.length)
    setServiceContracts(response.results || []);
    setPages(Math.ceil(response.count / PAGE_SIZE_RENDER));
    setModalLoading(false)
  };

  const getPlannedTasks = async () => {
    const pageToSearch = getPageToSearchParam(
      '',
      setCurrentPage,
      simplePagination,
      currentPage,
    );
    const response = await getPlanned({
      selectedClientId,
      idSelected,
      ...pageToSearch,
    });

    if (response.results) {
      setPlannedTasks(response.results.map((item) => {
        return { id: item.id, name: item.group_name }
      }));
      setModalLoading(false)
    }


  };

  const getValueName = (res, type) => {
    let value = []
    if (type) {
      res.forEach((resData) => {
        choices.care_task.forEach((careData) => {
          if (careData.id === resData.care_task) {
            value.push(careData)
          }
        })
      })
    } else {
      res.forEach((resData) => {
        choices.group_task.forEach((careData) => {
          if (careData.id === resData.group_task) {
            value.push(careData)
          }
        })
      })
    }
    return value
  }

  const groupTaskFn = async () => {
    // let clientId = cliId ? cliId : selectedClientId;
    const responseGroupTask = await getClientGroupTask(selectedClientId);
    const responseTask = await getClientTask(selectedClientId);

    if (responseTask) {
      const tempTask = getValueName(responseTask, 1)
      setClientTaskOptions(tempTask);

    }
    if (responseGroupTask) {
      const tempGroupTask = getValueName(responseGroupTask, 0)
      setGroupTaskOptions(tempGroupTask)
    }
  }

  const searchClientService = async () => {
    // let clientId = cliId ? cliId : selectedClientId
    const response = await getClientsServicesById(selectedClientId, idSelected);

    if (response.visits) {
      response.visits.map((visit, visitIndex) => {
        if (visit.employee_required) {
          visit.employee_required.map((emp, empIndex) => {
            if (emp.skills) {
              emp.skills.map((skill, skillIndex) => {
                let name = choices.skill && choices.skill.find(x => x.id === skill.skill)?.name
                //response.visits[visitIndex].employee_required[empIndex].skills[skillIndex]={id:skill.id,value:skill.skill,label:name}
                response.visits[visitIndex].employee_required[empIndex].skills[skillIndex] = { value: skill.skill, label: name, skill_id: skill.id }

              })
            }
            if (emp.competency) {
              emp.competency.map((comptence, competencyIndex) => {
                let name = choices.competency_name && choices.competency_name.find(x => x.id === comptence.competency)?.name
                response.visits[visitIndex].employee_required[empIndex].competency[competencyIndex] = { value: comptence.competency, label: name, competency_id: comptence.id }
                //response.visits[visitIndex].employee_required[empIndex].competency[competencyIndex]={id:comptence.id,value:comptence.competency,label:name}

              })
            }
            if (emp.client_task) {
              response.visits[visitIndex].employee_required[empIndex].specific_groups = [];
              emp.client_task.map((planItem, planId) => {
                let name = plannedTasks && plannedTasks.find(x => x.id === planItem.specific_group)?.name;
                response.visits[visitIndex].employee_required[empIndex].specific_groups[planId] = { planned_id: planItem.id, value: planItem.specific_group, label: name }

              })
            }
            if (emp.clientMedication) {
              response.visits[visitIndex].employee_required[empIndex].medication = [];
              emp.clientMedication.map((planItem, planId) => {
                let name = choices.medication_dose_timing && choices.medication_dose_timing.find(x => x.id === planItem.medicationDoseTime)?.name;
                response.visits[visitIndex].employee_required[empIndex].medication[planId] = { med_id: planItem.id, value: planItem.medicationDoseTime, label: name }

              })
            }
          })
        }
        if (visit.preferred_start_time === "00:00:00" && visit.preferred_end_time === "23:59:59") {
          response.visits[visitIndex].full_day = true
        }
      })
    }
    if (response.service_funders) {
      response.service_funders = response.service_funders.map((val, index) => {
        let name_tem = null;
        if (val.name && !val.self_funded) {
          name_tem = funderList.find(funder => funder.name == val.name) && funderList.find(funder => funder.name == val.name).id

        }
        return { ...val, name: name_tem ? name_tem : val.name, self_funded: val.self_funded ? 1 : 2, status: val.status ? 1 : 2 }
      })
    }
    // if(response.service_funders){
    //       if(response.service_funders.status===true){
    //         response.service_funders.status=1
    //       }
    //       if(response.service_funders.status===false){
    //         response.service_funders.status=2
    //       } 
    //       if(response.service_funders.name){
    //         if(response.service_funders.self_funded===false){
    //           response.service_funders.name=funderList.find(funder=>funder.name==response.service_funders.name) && funderList.find(funder=>funder.name==response.service_funders.name).id
    //         }
    //     }
    //       if(response.service_funders.self_funded===true){
    //         response.service_funders.self_funded=1
    //       }
    //       if(response.service_funders.self_funded===false){
    //         response.service_funders.self_funded=2
    //       } 

    // }
    setValues({
      ...values,
      ...response
    });
  };

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const handleRowClick = id => {
    //for error message
    setCheckRequires(false);
    setShowError(false);
    setErrorMessage(false);
    setFormErrors([])

    setEditToggle(true)
    setEditMode(false)
    setIdSelected(id);
    setModalValue(true);
  };

  const handleModalOnSave=()=>{
    setModalValue(false);
    setEditToggle(false)
    getClientContracts();
  }

  const handleModal = event => {

    setModalValue(!modalValue);
    setIdSelected('');
    setValues({})
    setEditToggle(false)
    setEditMode(false)
    
    //error states
    setCheckRequires(false);
    setShowError(false);
    setErrorMessage(false);
    setFormErrors([])

    if (event === NEW_CONTRACT) {
      setButtonIcon(FLOPPY);

      setEditMode(true)
      setTextStatus(SAVE);
    } else {
      getClientContracts();

    }
  };

  const handleDeleteContract = async () => {
    try {
      await deleteServiceContract(selectedClientId, idSelected);
      //Todo: confirm deleted client message
    } catch (error) {
      //Todo: error message and add the methods of finally in to ok modal function
    } finally {
      setModalDeleteValue(false);
      getClientContracts();
      setIdSelected('');
    }
  };

  const toDeleteClient = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const dropDownHandleVisits = (item, nameItem, index) => {
    //recursive objects merge
    values.visits[index][nameItem] = item[nameItem];

    setValues({ ...values });
  };

  const getTimesVisit = visits => {

    const times = visits.map(visit => {
      // return (
      //   moment(visit.preferred_start_time, 'HH:mm').format('HH:mm') +
      //   ' - ' +
      //   moment(visit.preferred_end_time, 'HH:mm').format('HH:mm')
      // );
      return (
        visit.preferred_start_time +
        ' - ' +
        visit.preferred_end_time
      );
    });
    return times;
  };

  const getTimeVisitPlaceHolder = visits => {
    let placeHolder;
    if (visits.length) {
      placeHolder =
        moment(visits[0].preferred_start_time, 'HH:mm').format('HH:mm') +
        ' - ' +
        moment(visits[0].preferred_end_time, 'HH:mm').format('HH:mm');
    }
    return placeHolder;
  };

  const getDaysSelected = visits => {
    // get the string days and make them arrays
    let days = visits.map(visit => {
      return visit.repeat_on.split(',');
    });
    // flatten array of arrays
    let days_merged = [].concat.apply([], days);

    // remove duplicates
    let uniqueArray = days_merged.filter(function (item, pos) {
      return days_merged.indexOf(item) === pos;
    });

    return uniqueArray;
  };

  const isDaySelected = (name, daysSelected) => {
    let result = daysSelected.indexOf(name);

    return result > -1;
  };

  const getDays = () => {
    return [
      { name: 'MON' },
      { name: 'TUE' },
      { name: 'WED' },
      { name: 'THU' },
      { name: 'FRI' },
      { name: 'SAT' },
      { name: 'SUN' },
    ];
  };

  if (values && !values.service_clients) {
    values.service_clients = [
      { visits: [], service_funders: [] },
    ];
  }

  const dropDownHandleSelect = (item, nameItem) => {
    if (nameItem == 'self_funded') {
      if (item[nameItem] == 1) {
        values.service_funders = { bill_rate: [...values.service_funders.bill_rate], pay_rate: [...values.service_funders.pay_rate], split_funders: [...values.service_funders.split_funders] }
        values.service_funders.name = clientFullName
        values.service_funders.split_funders[0] = {}
        values.service_funders.split_funders[0] = { ...addressValues }
        delete values.service_funders.split_funders[0].id

      } else {
        values.service_funders = { bill_rate: [...values.service_funders.bill_rate], pay_rate: [...values.service_funders.pay_rate], split_funders: [...values.service_funders.split_funders] }
        values.service_funders.name = null
        values.service_funders.split_funders[0] = {}
      }

      setValues({ ...values })
    }


    if (nameItem == 'name') {
      if (item[nameItem] == 'add_funder') {
        setModalFunderValue(!modalFunderValue)
      }
      else {
        values.service_funders[nameItem] = item[nameItem];
        getFunderDetails(item[nameItem])
          .then((res) => {
            if (res) {
              values.service_funders.category = res.funder_category && res.funder_category
              values.service_funders.no = res.funder_code && res.funder_code
              values.service_funders.status = res.status ? 1 : 2
              values.service_funders.funder_accounts_code = res.funder_accounts_code && res.funder_accounts_code
              values.service_funders.reference = res.reference && res.reference
              values.service_funders.invoice_type = res.invoice_type && res.invoice_type
              values.service_funders.billing_mode = res.bill_rate_model && res.bill_rate_model
              values.service_funders.billing_duration_type = res.billing_duration_type && res.billing_duration_type
              values.service_funders.billing_duration_source = res.billing_duration_source && res.billing_duration_source
              values.service_funders.bill_time_unit = res.bill_time_unit && res.bill_time_unit
              values.service_funders.discount = res.discount && res.discount
              values.service_funders.pattern = res.billing_pattern && res.billing_pattern
              values.service_funders.billing_term = res.billing_terms && res.billing_terms
              values.service_funders.payment_method = res.billing_payment_method && res.billing_payment_method
              values.service_funders.funder_cancellation_fees = res.funder_cancellation_fee && res.funder_cancellation_fee
              values.service_funders.split_funders[0].full_name = res.funder_contact_full_name && res.funder_contact_full_name
              values.service_funders.split_funders[0].email = res.funder_contact_email && res.funder_contact_email
              values.service_funders.split_funders[0].phone_no = res.funder_contact_phone_numbers && res.funder_contact_phone_numbers
              values.service_funders.split_funders[0].funder_contact_no = res.funder_contact_no && res.funder_contact_no
              values.service_funders.split_funders[0].funder_contact_organisation_title = res.funder_contact_organisation_title && res.funder_contact_organisation_title
              values.service_funders.split_funders[0].full_name = res.funder_name && res.funder_name
              values.service_funders.split_funders[0].line_1 = res.line_1 && res.line_1
              values.service_funders.split_funders[0].line_2 = res.line_2 && res.line_2
              values.service_funders.split_funders[0].city = res.city && res.city
              values.service_funders.split_funders[0].state = res.state && res.state
              values.service_funders.split_funders[0].zip_code = res.zip_code && res.zip_code
              values.service_funders.core_funder = res.id
              setValues({ ...values })

            }
          })
        setValues({ ...values }); //item added
      }
    }
    else {
      values.service_funders[nameItem] = item[nameItem];
      setValues({ ...values }); //item added
    }

  };

  const dropDownHandleSelectFunderBilling = (item, nameItem, indexVisit) => {

    values.service_funders[indexVisit][nameItem] = item[nameItem];
    setValues({ ...values }); //item added

  };

  const dropDownHandleSelectFunder = (item, nameItem, indexVisit) => {
    if (nameItem == 'self_funded') {
      if (item[nameItem] == 1) {
        // values.service_funders[indexVisit]={bill_rate:values.service_funders && values.service_funders[indexVisit] && [...values.service_funders[indexVisit].bill_rate],pay_rate: values.service_funders && values.service_funders[indexVisit] && [...values.service_funders[indexVisit].pay_rate],split_funders: values.service_funders && values.service_funders[indexVisit] && [...values.service_funders[indexVisit].split_funders]}
        values.service_funders[indexVisit].name = clientFullName
        values.service_funders[indexVisit].split_funders[0] = {}
        values.service_funders[indexVisit].split_funders[0] = { ...addressValues }
        delete values.service_funders[indexVisit].split_funders[0].id

      } else {
        // values.service_funders[indexVisit]={bill_rate:values.service_funders && values.service_funders[indexVisit] && [...values.service_funders[indexVisit].bill_rate],pay_rate: values.service_funders && values.service_funders[indexVisit] && [...values.service_funders[indexVisit].pay_rate],split_funders: values.service_funders && values.service_funders[indexVisit] && [...values.service_funders[indexVisit].split_funders]}
        values.service_funders[indexVisit].name = null
        values.service_funders[indexVisit].split_funders[0] = {}
      }
      setValues({ ...values })
    }


    if (nameItem == 'name') {
      if (item[nameItem] == 'add_funder') {
        setModalFunderValue(!modalFunderValue)
      }
      else {
        values.service_funders[indexVisit][nameItem] = item[nameItem];
        getFunderDetails(item[nameItem])
          .then((res) => {
            if (res) {
              values.service_funders[indexVisit].category = res.funder_category && res.funder_category
              values.service_funders[indexVisit].no = res.funder_code && res.funder_code
              values.service_funders[indexVisit].status = res.status ? 1 : 2
              values.service_funders[indexVisit].funder_accounts_code = res.funder_accounts_code && res.funder_accounts_code
              values.service_funders[indexVisit].reference = res.reference && res.reference
              values.service_funders[indexVisit].invoice_type = res.invoice_type && res.invoice_type
              values.service_funders[indexVisit].billing_mode = res.bill_rate_model && res.bill_rate_model
              values.service_funders[indexVisit].billing_duration_type = res.billing_duration_type && res.billing_duration_type
              values.service_funders[indexVisit].billing_duration_source = res.billing_duration_source && res.billing_duration_source
              values.service_funders[indexVisit].bill_time_unit = res.bill_time_unit && res.bill_time_unit
              values.service_funders[indexVisit].discount = res.discount && res.discount
              values.service_funders[indexVisit].pattern = res.billing_pattern && res.billing_pattern
              values.service_funders[indexVisit].billing_term = res.billing_terms && res.billing_terms
              values.service_funders[indexVisit].payment_method = res.billing_payment_method && res.billing_payment_method
              values.service_funders[indexVisit].funder_cancellation_fees = res.funder_cancellation_fee && res.funder_cancellation_fee
              values.service_funders[indexVisit].split_funders[0].full_name = res.funder_contact_full_name && res.funder_contact_full_name
              values.service_funders[indexVisit].split_funders[0].email = res.funder_contact_email && res.funder_contact_email
              values.service_funders[indexVisit].split_funders[0].phone_no = res.funder_contact_phone_numbers && res.funder_contact_phone_numbers
              values.service_funders[indexVisit].split_funders[0].funder_contact_no = res.funder_contact_no && res.funder_contact_no
              values.service_funders[indexVisit].split_funders[0].funder_contact_organisation_title = res.funder_contact_organisation_title && res.funder_contact_organisation_title
              values.service_funders[indexVisit].split_funders[0].full_name = res.funder_name && res.funder_name
              values.service_funders[indexVisit].split_funders[0].line_1 = res.line_1 && res.line_1
              values.service_funders[indexVisit].split_funders[0].line_2 = res.line_2 && res.line_2
              values.service_funders[indexVisit].split_funders[0].city = res.city && res.city
              values.service_funders[indexVisit].split_funders[0].state = res.state && res.state
              values.service_funders[indexVisit].split_funders[0].zip_code = res.zip_code && res.zip_code
              values.service_funders[indexVisit].core_funder = res.id
              setValues({ ...values })

            }
          })
        setValues({ ...values }); //item added
      }
    }
    else {
      values.service_funders[indexVisit][nameItem] = item[nameItem];
      setValues({ ...values }); //item added
    }

  };

  const dropDownHandleSelectAddress = (item, nameItem) => {
    values.service_address[nameItem] = item[nameItem];
    setValues({ ...values });
  };



  const displayFunderType = (data) => {
    let name = ''
    if (data && data.category && data.category) {
      return choices && choices.funder_source && choices.funder_source.find(source => source.id === data.category).name
    }

    return name
  }


  return (
    <ComponentDynamic
      loading={modalLoading}
    >
      {(selectedClientId ? readServicePermissions : createServicePermissions) ?
        <div>


          {(createServicePermissions && (serviceContracts.length >= 1) && !modalValue) ? (

            <div className={'w-100 d-flex justify-content-center mb-3 mt-1'}>
              <div className={'text-primary font-weight-bold'}>
                <div>
                  <PrimaryButtonForm
                    minWidth="6rem"
                    onClick={() => handleModal(NEW_CONTRACT)}>
                    <BsFolderPlus size={18} />
                    <span className={'ms-2'}>ADD SERVICE CONTRACT</span>
                  </PrimaryButtonForm>
                </div>
              </div>

            </div>

          ) : (
            <Fragment />
          )}
          {(modalValue && serviceContracts.length >= 1) ? (
            <div className={'w-100 d-flex justify-content-center'}>
              <div>
                <PrimaryButtonForm
                  bgcolor={'PURPLE_GRAY_500'}
                  minWidth="6rem"
                  onClick={handleModal}>
                  <div className={'d-flex align-items-center'}>
                    <TiArrowBackOutline size={24} />
                    <div>BACK</div>
                  </div>
                </PrimaryButtonForm>
              </div>

            </div>
          ) : (
            <Fragment />
          )}
          {editToggle ?
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>

              <ToggleDiv>
                <ToggleWrapper>
                  <Toggle
                    id="checkbox"
                    type="checkbox"
                    checked={editMode}
                    onClick={() => {
                      editMode ? setEditMode(false) : setEditMode(true);
                    }}
                  />
                  <ToggleLabel htmlFor="checkbox" />
                </ToggleWrapper>
                <div className="toggle-label ms-2  mb-3 pb-3 me-2">
                  <p>Edit Mode</p>
                </div>
              </ToggleDiv>

            </div>
            : <Fragment />}
          {serviceContracts.length >= 1 && !modalValue ? (
            <>
              <Table
                headerColumns={headerColumns}
                headerPadding={false}
                noEditOption
                fontSize="0.75rem"
                callBackQuerySort={setQuerySort}>
                <div>

                  {serviceContracts.map((clientContract, index) => {

                    const endDate = clientContract.end_date
                      ? moment(clientContract.end_date).format('DD-MM-YYYY')
                      : 'Unset';
                    const startDate = clientContract.start_date
                      ? moment(clientContract.start_date).format('DD-MM-YYYY')
                      : 'Unset';

                    const daysSelected = getDaysSelected(clientContract.visits);
                    return (
                      <div key={index}       >
                        <Row bgColor Shadow
                          zIndex={ServiceContractLength - index}
                        >
                          <Col
                            onClick={() => {
                              handleRowClick(clientContract.id);
                            }}
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize

                            overlap={true}
                            Center>
                            {clientContract.reference_no}
                          </Col>

                          <Col
                            onClick={() => {
                              handleRowClick(clientContract.id);
                            }}
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center>
                            {displayFunderType(clientContract.service_funders)}
                          </Col>

                          <Col
                            onClick={() => {
                              handleRowClick(clientContract.id);
                            }}
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            overlap={true}
                            Capitalize
                            Center>
                            {startDate}
                          </Col>
                          <Col
                            onClick={() => {
                              handleRowClick(clientContract.id);
                            }}
                            max_width={LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center

                          >
                            {endDate}
                          </Col>
                          <Col
                            max_width={EX_LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center
                          >
                            <Dropdown
                              noPadding
                              disableSelection
                              alignmentRight
                              justifyContent={'center'}
                              items={getTimesVisit(clientContract.visits)}
                              onSelect={() => { }}
                              placeHolder={
                                getTimeVisitPlaceHolder(clientContract.visits) ||
                                'Unset'
                              }
                              appendItem=""
                            />
                          </Col>

                          <Col
                            onClick={() => {
                              handleRowClick(clientContract.id);
                            }}
                            max_width={MEDIUM_COL}
                            Shrink
                            justifyContent={'end'}
                            alignItems={'end'}
                            reducePadding
                            Capitalize
                            Center
                            overlap={true}
                            className="visits-custom-row sm-hidden">
                            {getDays().map(day => {
                              return (
                                <DayPill
                                  key={`day_pill_` + day.name}
                                  Active={isDaySelected(day.name, daysSelected)}>
                                  <label>{day.name}</label>
                                </DayPill>
                              );
                            })}
                          </Col>
                          <Col className="sm-hidden" Center Shrink NoFlexGrow>
                            {deleteServicePermissions && <IconWrapper
                              onClick={() => {
                                toDeleteClient(clientContract.id);
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>}
                          </Col>
                        </Row>
                        <div className="mb-1" />
                      </div>
                    );
                  })}
                  <div className="mt-3">
                    <Pagination
                      simplePagination={simplePagination}
                      setCurrentPage={setCurrentPage}
                      totalPages={pages}
                      currentPage={currentPage}
                      dataLength={serviceContracts.length}
                      dropdownEntriesLimit={false}
                    />
                  </div>
                </div>

              </Table>
              <div
                className={'d-flex justify-content-center justify-content-md-end'}>
                <div>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    className={'me-2'}
                    onClick={() => {
                      history.push('/client')
                    }}>
                    <span className={'me-2'}>{'Cancel'}</span>
                  </PrimaryButtonForm>
                </div>
              </div>
            </>
          ) : (
            <>
              <Fragment />
              {!modalValue &&
                <div
                  className={'d-flex justify-content-center justify-content-md-end'}>
                  <div>


                  </div>
                </div>
              }
            </>
          )}

          {!modalValue && serviceContracts.length >= 1 ? null : (
            <ModalServiceClient

              earlieErrors={earlieErrors}
              setShowEarlieError={setShowEarlieError}
              setEarlieMessage={setEarlieMessage}
              earlieMessage={earlieMessage}
              setEarlieError={setEarlieError}
              showEarlieError={showEarlieError}
              setSavedContract={setSavedContract}
              InvalidOrEmptyValues={InvalidOrEmptyValues}
              DetailsNotCreated={DetailsNotCreated}
              setErrorMessage={setErrorMessage}
              clientErrors={clientErrors}
              setShowError={setShowError}
              promisesWithErrors={promisesWithErrors}
              setCheckRequires={setCheckRequires}
              isValid={isValid}
              allErrors={allErrors}
              editMode={editMode}
              setEditMode={setEditMode}
              serviceId={idSelected}
              selectedClientId={selectedClientId}
              dropDownHandleVisits={dropDownHandleVisits}
              dropDownHandleSelect={dropDownHandleSelect}
              dropDownHandleSelectFunder={dropDownHandleSelectFunder}
              dropDownHandleSelectFunderBilling={dropDownHandleSelectFunderBilling}
              dropDownHandleSelectAddress={dropDownHandleSelectAddress}
              values={values}
              setValues={setValues}
              clientId={selectedClientId}
              useInput={useInput}
              choices={choices}
              checkBeforeSend={checkBeforeSend}
              showError={showError}
              errorMessage={errorMessage}
              setErrorSplitPercentage={setErrorSplitPercentage}
              errorSplitPercentage={errorSplitPercentage}
              errors={errors}
              loadingFinish={loadingFinish}
              loadingSave={loadingSave}
              textStatus={textStatus}
              buttonIcon={buttonIcon}
              addressValues={addressValues}
              clientFullName={clientFullName}
              setDateError={setDateError}
              dateError={dateError}
              setTimeError={setTimeError}
              timeError={timeError}
              funderList={funderList}
              updateServicePermissions={updateServicePermissions}
              filterAdmin={filterAdmin}
              filterSuperAdmin={filterSuperAdmin}
              contextSubscription={contextSubscription}
              rightPermission={rightPermission}
              userClient={userClient}
              clientTaskOptions={clientTaskOptions}
              groupTaskOptions={groupTaskOptions}
              plannedTasks={plannedTasks}
              contextRelativesClient={contextRelativesClient}
              medDoseTimingList={medDoseTimingList}
              setFormErrors={setFormErrors}
              loadingSaveOnService={loadingSaveOnService}
            />
          )}
          {!modalFunderValue ? null : (
            <AddModal
              choices={choices}
              values={values}
              setValues={setValues}
              isValid={isValid}
              errors={errors}
              useInput={useInput}
              readOnly={false}
              fullBind={fullBind}
              setTitle={setTitle}
              setIdSelected={setIdSelected}
              setCheckRequires={setCheckRequires}
              loadFunders={funderLists}
              newAddFunder={true}

            />
          )}
          <div>
            <ModalDecision
              type="delete"
              title="Warning"
              body={
                'Are you sure you want to delete this service contract? \n DELETING the service contract will remove ALL records from database.'
              }
              // subBody={
              //   'TIP (Recommended) - you could change the service contract status to discard instead to retain database records.'
              // }
              onOk={handleDeleteContract}
              onCancel={() => {
                setIdSelected('');
              }}
              okTitle={'DELETE'}
              {...bindModalDelete}
            />
          </div>
        </div>
        :
        <NoPermissionContainer>
          You don't have permission to {selectedClientId ? 'read' : 'create'} the information.
        </NoPermissionContainer>
      }
    </ComponentDynamic>
  );
};

ServiceClientListAll.propTypes = {
  choices: PropTypes.object.isRequired,
  selectedClientId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  allErrors: PropTypes.bool.isRequired,
  setErrorSplitPercentage: PropTypes.func.isRequired,
  errorSplitPercentage: PropTypes.string.isRequired,
  loadingFinish: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  textStatus: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  savedContract: PropTypes.string.isRequired,
  setSavedContract: PropTypes.func.isRequired,
  addressValues: PropTypes.object.isRequired,
  setTextStatus: PropTypes.func.isRequired,
  clientErrors: PropTypes.array.isRequired,
  promisesWithErrors: PropTypes.array.isRequired,
};

export default ServiceClientListAll;