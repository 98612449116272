import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React from 'react';
import {useState,useContext} from 'react';
import {useEffect, useRef} from 'react';
import {ErrorMessage, ModalContainer} from 'shared/styles/constants/tagsStyles';
import {useForm} from 'hooks/FormHook';
import {setMedError} from 'utils/api/ClientApi';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {getMedicationError, setMedicationError2} from 'utils/api/ClientApi';

import {
  FlexGrid3Container,
  FlexGrid3Item,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import { AppContext, BranchItemsContext } from 'context';

import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {Toast} from 'primereact/toast';

import {PrimaryButtonForm} from 'shared/styles/buttons';
import {Fragment} from 'react';
const MedicationErrorModal = ({
  fullBind,
  data,
  clientId,
  choices,
  editMode,
  contextChoices,
  errorMessage,
  showError,
  setMedBulkEdit,
  checkedRows,
  setMedicationError,comment,
  dropDownHandleSelect = () => {},
}) => {
  const {values, setValues, useInput} = useForm({});
  const toast = useRef();

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const ins_hook = useInput(`medication.review_comment`);
  const newDoseTime = useInput(`medication.review_time`, 'isRequired');
  const review_date_hook = useInput('medication.review_date', 'isRequired');
  const review_status_hook = useInput(`medication.review_status`, 'isRequired');

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Medication Error',
    });
  }, [fullBind]);

  useEffect(() => {
    getMedicationError(clientId).then(res => {
      values.medication_error_type = res?.data.map(
        item => {
          return {
            value: item.medicationError,
            label: contextChoices&&contextChoices.medication_error_type.find(dataid => dataid.id == item.medicationError)?.name,
          
           
          
          
          }
        }
      );
      values.comments = comment
      setValues({...values});
    });
  }, [clientId ||contextChoices]);
  const dropDownHandleRunRoute = item => {
    values.medication_error = item.medication_error;
    setValues({...values, ...item});
  };
  const handleAddMedicationError = async () => {
    let body = {
      medication: clientId,
      medicationError: values?.medication_error_type.map(item => {
        return {medicationError: item.value};
      }),
      comment: values.comment,
    };
    let response = await setMedicationError2(body,clientId);
    if (response.status === true) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${response.message}`,
      });
   
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });
    }

  
  };
  return (
    <div>
      <ComponentDynamic loading={false} style={{maxHeight: '100px'}}>
      <Toast ref={toast} position="top-right" />

        <div className="d-flex flex-column mt-2">
          <div className="d-flex mb-2">
            <div
              className="w-100 me-2"
              style={{marginLeft: '10px', marginRight: '10px'}}>
              <DropdownSearchable
                isMulti={true}
                placeHolder={'Medication Error'}
                options={contextChoices&&contextChoices.medication_error_type}
                selectedOptionsProps={
                  values
                    ? values.medication_error_type
                      ? values.medication_error_type
                      : null
                    : null
                }
                
                onChange={value =>
                  dropDownHandleRunRoute(
                    {medication_error_type: value ? value : null},
                    'medication_error_type'
                  )
                }
              />
            </div>
          </div>
        </div>

        <FlexGrid3Item
          className="pe-1 mb-2"
          ResizeWidth={'100%'}
          style={{marginLeft: '8px', marginRight: '2px'}}>
          <small className="pe-1 " style={{color: 'black'}}>
            Comments
          </small>
          <InputTextArea
            type="text"
            height={'4rem'}
            id="comments"
            label={'Comments'}
            {...useInput('comments')}
            maxlength={5}
          />
        </FlexGrid3Item>
        <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div className={'mb-3'}>
            {showError ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>

          <div
            className={'d-flex justify-content-center justify-content-md-end'}>
            <div className={'ms-0 ms-md-3 me-2'}>
              <PrimaryButtonForm
                disabled={false}
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                style={{marginBottom: '4px'}}
                onClick={handleAddMedicationError}>
                Save
              </PrimaryButtonForm>
            </div>
            <div></div>
          </div>
        </div>
      </ComponentDynamic>
    </div>
  );
};

export default MedicationErrorModal;
