import React, { useState, useEffect, Fragment } from 'react';
import {
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import { TitleSection } from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { ERROR, SAVE, SAVED, InvalidOrEmptyValues, } from 'utils/constants/messages';
import FunderContact from './FunderContact';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import FunderBilling from './FunderBilling'
import { addFunder, getFunderById } from 'utils/api/SettingsApi'
const AddModal = ({
  fullBind,
  setTitle,
  idSelected,
  choices,
  values,
  setValues,
  useInput,
  errors,
  isValid,
  setCheckRequires,
  loadFunders,
  readOnly,
  newAddFunder
}) => {
  const [loadingModal, setLoadingModal] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);



  useEffect(() => {
    if (idSelected) {
      setTitle('Edit Funder');
      getFunder()
    } else {
      setTitle('Add New Funder');
      setLoadingModal(false);
      if (newAddFunder) {
        values.addFunder = {}
        setValues({ ...values })
      }
    }
    setCheckRequires(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dropDownHandleSelect = (item, nameItem, funderAdd) => {
    if (funderAdd) {
      values.addFunder[nameItem] = item[nameItem]
      setValues({ ...values })
    }
    else {
      values[nameItem] = item[nameItem]
      setValues({ ...values })
    }

  }


  const getFunder = async () => {
    let response = await getFunderById(idSelected)
    if (response) {
      if (response.status === true) {
        response.status = 1
      }
      else if (response.status === false) {
        response.status = 2
      }
      if (response.branches) {
        response.branches = response.branches.map(branch => {
          return (
            {
              value: branch.branch,
              label: branch.branch_name
            }
          )
        })
      }
      setValues({ ...response })
      setLoadingModal(false)
    }
  }

  const handleSubmit = () => {
    if (loadingSave) {
      return
    }

    if (!isValid && errors.length > 0) {
      setErrorMessage(InvalidOrEmptyValues);
      setCheckRequires(true);
      return;
    }
    setLoadingSave(true);
    let body = newAddFunder ? { ...values.addFunder } : { ...values }
    if (body.branches) {
      body.branches = body.branches.map(branch => {
        return (
          { branch: branch.value }
        )
      })
    }
    if (body.status) {
      if (body.status === 1) {
        body.status = true
      }
      else {
        body.status = false
      }
    }
    addFunder(body)
      .then(res => {
        if (res.id) {
          newAddFunder ?
            setValues({
              ...values,
              addFunder: { ...values.addFunder, id: res.id }
            })
            : setValues({
              ...values,
              id: res.id
            })
        }
        onSuccess()
      })
      .catch(onError);
  }
  const onSuccess = response => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setErrorMessage('');
    loadFunders();
  };
  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');

    setButtonIcon(CROSS);
    setTextStatus(ERROR);
  };
  console.log(values, 4458888)

  return (
    <ModalContainer max_width={'75%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'75%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="ps-3 pe-3 pb-3 pt-0">
          <TitleSection className={''}>Funder Details</TitleSection>
          <div className='d-flex mt-2 mb-2'>
            <div className='w-100 me-2'>
              <DropdownSearchable
                disabled={readOnly}
                placeHolder={'Client / Funder category'}
                options={choices.funder_source}
                selectedOption={
                  newAddFunder ?
                    values.addFunder
                      ? values.addFunder.funder_category
                      : null
                    :
                    values.funder_category
                      ? values.funder_category
                      : null
                }
                onChange={(value) =>
                  dropDownHandleSelect(
                    { funder_category: value ? value : null },
                    'funder_category', newAddFunder
                  )
                }
              />
            </div>
            <div className='w-100 me-2'>
              <InputLabelTop
                required
                disabled={readOnly}
                type="text"
                id="funder_name"
                label={'Funder name'}
                {...useInput(newAddFunder ? `addFunder.funder_name` : `funder_name`, 'isRequired')}
                maxlength={255}
              />
            </div>
            <div className='w-100'>
              <InputLabelTop
                disabled={readOnly}
                type="text"
                id="funder_code"
                label={'Funder Code'}
                {...useInput(newAddFunder ? `addFunder.funder_code` : `funder_code`)}
                maxlength={255}
              />
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='w-100 me-2'>
              <DropdownSearchable
                disabled={readOnly}
                placeHolder={'Funder Status'}
                options={[
                  { id: 1, name: "Active" },
                  { id: 2, name: "Inactive" }
                ]}
                selectedOption={
                  newAddFunder ?
                    values.addFunder ?
                      values.addFunder.status
                      : null
                    :
                    values.status
                      ? values.status
                      : null
                }
                onChange={(value) =>
                  dropDownHandleSelect(
                    { status: value ? value : null },
                    'status', newAddFunder
                  )
                }
              />
            </div>
            <div className='w-100 me-2'>
              <InputLabelTop
                disabled={readOnly}
                type="text"
                id="fund_acount_code"
                label={'Funder Accounts Code'}
                {...useInput(newAddFunder ? `addFunder.funder_accounts_code` : `funder_accounts_code`)}
                maxlength={255}
              />
            </div>
            <div className='w-100'>
              <InputLabelTop
                type="text"
                id="funder_reference"
                label={'Funder reference'}
                {...useInput(newAddFunder ? `addFunder.reference` : `reference`)}
                maxlength={255}
              />
            </div>
          </div>
          <div className='d-flex mb-2'>
            <div className='w-100'>
              <DropdownSearchable
                disabled={readOnly}
                placeHolder={'Branch Name'}
                options={choices.branch}
                isMulti={true}
                selectedOptionsProps={
                  newAddFunder ?
                    values.addFunder
                      ? values.addFunder.branches
                      : null
                    : values.branches
                      ? values.branches
                      : null}
                onChange={values =>
                  dropDownHandleSelect(
                    {
                      branches: values.length ? values : [],
                    }, 'branches', newAddFunder
                  )
                }
              />

            </div>
          </div>
          <TitleSection className={'mt-3'}>Funder Contact</TitleSection>
          <FunderContact
            readOnly={readOnly}
            choices={choices}
            values={values}
            useInput={useInput}
            dropDownHandleSelect={dropDownHandleSelect}
            newAddFunder={newAddFunder}
          />
          <TitleSection className={'mt-3'}>Funder Billing</TitleSection>
          <FunderBilling
            readOnly={readOnly}
            choices={choices}
            values={values}
            useInput={useInput}
            dropDownHandleSelect={dropDownHandleSelect}
            newAddFunder={newAddFunder}
          />
        </div>
        <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div className={'mb-3'}>
            {errorMessage ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>
          <div
            className={
              'd-flex justify-content-center justify-content-md-end'
            }>
            <div className={'ms-0 ms-md-3 me-3 mb-3'}>
              <PrimaryButtonForm minWidth="6rem"
                onClick={handleSubmit}
              >
                <span className={'me-2'}>{textStatus}</span>
                {!loadingSave ? (
                  buttonIcon
                ) : (
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                  />
                )}
              </PrimaryButtonForm>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
}

export default AddModal;
