import React, { Fragment, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Link, useHistory } from 'react-router-dom';
import { AUTH, DASHBOARDS, FORGOT_PASSWORD, MULTIFACTOR_AUTH } from 'utils/constants/routes';
import { ErrorMessageShake } from 'shared/styles/AuthComponents';
import { PrimaryButton } from 'shared/styles/buttons';
import { useInput } from 'hooks/InputHook';
import {
  setToken, setTenant, setOrgname, setSchemaName, setEmployee, setClient,
  setAdmin, setStaff, setUserFirstName, setSuperAdmin
}
  from 'utils/localStorage/token';
import { setCategoryPermissions, setUser } from 'utils/localStorage/user';
import { login } from 'utils/api/SessionApi';
import { getRolePermissions } from 'utils/api/CoreApi'
import { MdRemoveRedEye } from 'react-icons/md';
import logo from 'images/logoOutBgd.png';
import {
  FormContent,
  ImgWrapper,
  ImageWrapper,
  ColumnSwap,
  LoginPageContent,
  LoginContent,
  SmallText,
  ForgetPassword,
  InputLogin,
  InputFieldLogin,
  Horizontal,
  PasswordStyle,
  UpperCaseText,
  PwdStyle,
  UpperText,
} from './styles';
import {
  setPermissions,
  setSubSectionPermissions,

} from '../../../utils/localStorage/user';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';

const Login = () => {
  const toast = useRef(null)
  const { value: email, bind: bindEmail } = useInput('');
  const { value: password, bind: bindPassword } = useInput('');
  const [showErrorMessage, setShowErrorMessage] = useState({
    status: false,
    message: '',
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const [typeInput, setTypeInput] = useState('password');
  const [selectedRol, setSelectedRol] = useState({});

  const history = useHistory();



  // const loadSections = async () => {
  //   return getSections()
  //     .then((response) => {
  //       //setPermissionsSections(response);
  //       return response;
  //     })
  //     .catch(() => {});
  // };
  //
  // const loadRolPermission = async () => {
  //   const mySections = await loadSections();
  //   const addArrayList = JSON.parse(JSON.stringify(mySections));
  //   const myRolPermissions = [];
  //   const addWebValues = (myValue) => {
  //     if (myValue) {
  //       return {
  //         web_create: myValue.web_create || false,
  //         web_read: myValue.web_read || false,
  //         web_update: myValue.web_update || false,
  //         web_delete: myValue.web_delete || false,
  //       };
  //     } else {
  //       return {
  //         web_create: false,
  //         web_read: false,
  //         web_update: false,
  //         web_delete: false,
  //       };
  //     }
  //   };
  //   const addMobileValues = (myValue) => {
  //     if (myValue) {
  //       return {
  //         mobile_create: myValue.mobile_create || false,
  //         mobile_read: myValue.mobile_read || false,
  //         mobile_update: myValue.mobile_update || false,
  //         mobile_delete: myValue.mobile_delete || false,
  //       };
  //     } else {
  //       return {
  //         mobile_create: false,
  //         mobile_read: false,
  //         mobile_update: false,
  //         mobile_delete: false,
  //       };
  //     }
  //   };
  //
  //   //check all sections
  //   addArrayList.forEach((principalArray, index) => {
  //     //add new index in myRolPermissions
  //     myRolPermissions.push({});
  //     myRolPermissions[index].name = principalArray.name;
  //     myRolPermissions[index].id = principalArray.id;
  //     myRolPermissions[index].principal = true;
  //
  //     //add sections for each principal menu
  //     const myPrincipalSections = [];
  //     principalArray.sections.forEach((principalSection, indexSections) => {
  //       // add new index in myPrincipalSections
  //       myPrincipalSections.push({});
  //       // add principal values in new index
  //       myPrincipalSections[indexSections].name = principalSection.name;
  //       myPrincipalSections[indexSections].id = principalSection.id;
  //       myPrincipalSections[indexSections].category = principalSection.category;
  //       myPrincipalSections[indexSections].principal = false;
  //       myPrincipalSections[indexSections].isSubsection = false;
  //
  //       // filter only my selected rol values
  //       const mySectionPermission = principalSection.permissions.filter(
  //         (permissionsSection) => permissionsSection.role.id === selectedRol.id
  //       );
  //
  //       //add web and mobile values for first subList values
  //       myPrincipalSections[indexSections].web = addWebValues(
  //         mySectionPermission[0] || []
  //       );
  //       myPrincipalSections[indexSections].mobile = addMobileValues(
  //         mySectionPermission[0] || []
  //       );
  //
  //       myPrincipalSections[indexSections].permissionId = mySectionPermission[0]
  //         ? mySectionPermission[0].id
  //         : "";
  //
  //       //add sections(sub_list) in first subList values
  //       myPrincipalSections[indexSections].sections = [];
  //       principalSection.sub_sections.forEach((subSection, indexSubSection) => {
  //         const mySubSectionPermission = subSection.permissions.filter(
  //           (permissionsSubSection) =>
  //             permissionsSubSection.role.id === selectedRol.id
  //         );
  //
  //         myPrincipalSections[indexSections].sections.push({});
  //         // add principal aux
  //         myPrincipalSections[indexSections].sections[
  //           indexSubSection
  //         ].principal = false;
  //         // add isSubsection aux
  //         myPrincipalSections[indexSections].sections[
  //           indexSubSection
  //         ].isSubsection = true;
  //         //add name
  //         myPrincipalSections[indexSections].sections[indexSubSection].name =
  //           subSection.name;
  //         //add id
  //         myPrincipalSections[indexSections].sections[indexSubSection].id =
  //           subSection.id;
  //         //add section
  //         myPrincipalSections[indexSections].sections[indexSubSection].section =
  //           subSection.section;
  //         // add category
  //         myPrincipalSections[indexSections].sections[
  //           indexSubSection
  //         ].category = principalSection.category;
  //         // add permissionId
  //         myPrincipalSections[indexSections].sections[
  //           indexSubSection
  //         ].permissionId = subSection.id;
  //         // add web
  //         myPrincipalSections[indexSections].sections[indexSubSection].web =
  //           addWebValues(mySubSectionPermission[0] || []);
  //         // add mobile
  //         myPrincipalSections[indexSections].sections[indexSubSection].mobile =
  //           addMobileValues(mySubSectionPermission[0] || []);
  //       });
  //     });
  //
  //     const myPrincipalArrayPermission = principalArray.permissions.filter(
  //       (permissionsPrincipal) =>
  //         permissionsPrincipal.role.id === selectedRol.id
  //     );
  //
  //     // add web and mobile values for principal menu
  //     myRolPermissions[index].web = addWebValues(
  //       myPrincipalArrayPermission[0] || []
  //     );
  //     myRolPermissions[index].mobile = addMobileValues(
  //       myPrincipalArrayPermission[0] || []
  //     );
  //     myRolPermissions[index].categoryId = myPrincipalArrayPermission[0]
  //       ? myPrincipalArrayPermission[0].id
  //       : "";
  //     myRolPermissions[index].sections = myPrincipalSections;
  //   });
  //   setRolValues([...myRolPermissions]);
  //   return myRolPermissions;
  // };

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      if (loadingButton) return;

      setShowErrorMessage({
        status: false,
        message: '',
      });

      setLoadingButton(true);

      const response = await login(email, password);
      if (!response.access_token) {
        throw response;
      }
      setSelectedRol(response.user.role_id);
      // setToken(response.access_token);
      setTenant(response.user.default_login_organization.endpoint);
      setUser(response.user);
      // setAdmin(response.is_admin)
      // setSuperAdmin(response.is_superuser)
      // setStaff(response.is_staff)
      setOrgname(response.user.default_login_organization.name);
      setSchemaName(response.user.default_login_organization.schema_name);
      //const role_perms_res=await getRolePermissions()
      //setCategoryPermissions(role_perms_res.data?.category_role_permission || '');
      //setPermissions(role_perms_res.data?.role_permission || '');
      //setSubSectionPermissions(
      // role_perms_res.data?.sub_section_role_permission || ''
      //);
      // setEmployee(role_perms_res.employee_id)
      // setClient(role_perms_res.client_id)

      let token = response.access_token
      let user = response.user.id

      if (response?.user?.multiFactorAuth === true) {
        history.push(MULTIFACTOR_AUTH, { state: { token: token, user: user } })
      } else {
        setToken(response.access_token);
        history.push(DASHBOARDS);
      }

      setLoadingButton(false);
    } catch (err) {

      setLoadingButton(false);
      let finalError = "";
      const { message, leftLoginAttempts } = err || {}; // Handle missing errorData gracefully
      if (typeof message == "object") {
        const { non_field_errors } = message;
        if (non_field_errors)
          finalError = `${non_field_errors}`;
      } else if (typeof message == "string") {
        finalError = message
      } else {
        finalError = 'Something went wrong.'
      }
      leftLoginAttempts && toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${leftLoginAttempts}`,
      });
      setShowErrorMessage({
        status: true,
        message: finalError,
      });
    }
  };

  useEffect(() => {
    setShowErrorMessage({ status: false, message: '' });
  }, [email, password]);

  const handleClick = changeType => {
    setTypeInput(changeType);
  };
  const date = new Date();


  let year = date.getFullYear();
  return (
    <>
      <Toast ref={toast} />
      <LoginPageContent>
        <div className={'container-fluid bg-white'}>
          <div className={'container'}>
            <div className={'row  '}>
              <div className={'col-sm-6 col-lg-4 col-md-4 mt-3  mb-3  '}>
                <Fade>
                  <ImgWrapper>
                    <img src={logo} alt="logo-img" />
                  </ImgWrapper>
                </Fade>
              </div>
            </div>

            <ColumnSwap>
              <div className={'row  bdy'}>
                <div className={'col-sm-12 col-md-6 col-lg-6  pt-5  mt-5   '}>
                  <div className={'pt-4'}>
                    <div className={'mt-1 '}>
                      {' '}
                      <Link
                        to={AUTH}
                        className={
                          '  h5  text-muted  font-weight-normal  text-decoration-none  mt-2  '
                        }>
                        {' '}
                        How to use MyHelpa{' '}
                      </Link>{' '}
                    </div>{' '}
                    <Horizontal />
                    <div className={'mt-1 '}>
                      {' '}
                      <Link
                        to={AUTH}
                        className={
                          '  h5  text-muted  font-weight-normal  text-decoration-none  mt-2    '
                        }>
                        {' '}
                        Customer support: hello@myhelpa.com{' '}
                      </Link>{' '}
                    </div>{' '}
                    <Horizontal />
                    <div className={' mt-1 '}>
                      {' '}
                      <Link
                        to={AUTH}
                        className={
                          '  h5  text-muted  font-weight-normal  text-decoration-none  mt-2     '
                        }>
                        {' '}
                        We would love your feedback
                      </Link>{' '}
                    </div>{' '}
                    <Horizontal />
                  </div>
                  <div className={'secondpara mt-5 pt-4 '}>
                    <div className={' mt-5 '}>
                      {' '}
                      <Link
                        to={AUTH}
                        className={
                          '  h5  text-muted  font-weight-normal  text-decoration-none  mt-2   '
                        }>
                        {' '}
                        Want to join MyHelpa? Get in touch
                      </Link>{' '}
                    </div>{' '}
                    <Horizontal />
                  </div>
                </div>

                <div className={'col-sm-12 col-md-6 col-lg-6    '}>
                  {/* <FormContent> */}
                  <div className={'mb-3 mt-3'}>
                    <UpperCaseText>Welcome to MyHelpa</UpperCaseText>
                    <SmallText>
                      <div className={'text-black-50 '}>
                        Thank you for the incredible work you do supporting
                        people's lives.
                      </div>
                    </SmallText>
                  </div>
                  <form onSubmit={handleSubmit} className={' pt-3 '} noValidate>
                    <div>
                      <div className={' h5 font-weight-normal '}>Email</div>
                      <div>
                        <InputFieldLogin
                          type="text"
                          id="email"
                          placeholder="email@example.com"
                          autoComplete="email"
                          error={showErrorMessage.status}
                          {...bindEmail}
                        />
                      </div>
                    </div>
                    <div className={'mt-4 p-0'}>
                      <div className={'h5 font-weight-normal mb-2 '}>
                        <div>Password</div>
                      </div>
                      <PwdStyle>
                        <InputFieldLogin
                          type={typeInput}
                          id="password"
                          placeholder="**********"
                          autoComplete="current-password"
                          error={showErrorMessage.status}
                          {...bindPassword}
                        // minlength="2"
                        // maxlength="20" 
                        />
                        <span
                          onMouseDown={() => handleClick('text')}
                          onMouseUp={() => handleClick('password')}>
                          <MdRemoveRedEye />
                        </span>
                      </PwdStyle>
                    </div>
                    <div className={'mt-1'}>
                      <Link
                        to={AUTH + FORGOT_PASSWORD}
                        className={
                          'text-secondary text-muted font-weight-normal'
                        }>
                        <ForgetPassword>
                          <div>Forgot password?</div>
                        </ForgetPassword>
                      </Link>
                    </div>
                    <div>
                      {showErrorMessage.status ? (
                        <ErrorMessageShake className=" mt-3">
                          {showErrorMessage.message}
                        </ErrorMessageShake>
                      ) : (
                        <Fragment />
                      )}
                    </div>
                    <div
                      className={`${showErrorMessage.status ? 'mt-3' : 'mt-5'
                        } d-flex justify-content-md-start justify-content-center`}>
                      <PrimaryButton
                        type="submit"
                        shadow={true}
                        disabled={loadingButton ? true : false}>
                        {loadingButton ? (
                          <Fragment>
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                            />
                            <span>Loading...</span>
                          </Fragment>
                        ) : (
                          <span className={'mt-5 mb-5 font-weight-bold'}>
                            SIGN IN
                          </span>
                        )}
                      </PrimaryButton>
                    </div>
                  </form>

                  {/* </FormContent> */}
                </div>
              </div>
            </ColumnSwap>

            <div className={'row d-flex justify-content-start  mb-2 bg-white'}>
              <div className={'col-sm-12 col-lg-5 col-md-5   mb-2  pt-5  '}>
                <p className={'font-weight-light   '}>
                  {' '}
                  Copyright@ {year} MyHelpa Limited Terms of Business{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </LoginPageContent>
    </>
  );
};

export default Login;
