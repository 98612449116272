import React, {Fragment} from 'react';
import {useEffect, useState, useRef, useContext} from 'react';
import {AppContext, BranchItemsContext} from 'context';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';

import {PrimaryButtonForm} from 'shared/styles/buttons';
import {PrimaryButtonModal} from 'components/SharedComponents/ModalDecision/styles';
import {Title} from 'shared/styles/constants/tagsStyles';
import {IconStyle} from 'components/SharedComponents/ModalInformation/styles';
import {CancelButton} from 'components/SharedComponents/ModalDecision/styles';
import Modal from 'react-bootstrap4-modal';
import LineContainer from 'components/SharedComponents/LineContainer';
import {Toast} from 'primereact/toast';
import {MessageOptaPlanner} from 'components/DashboardComponents/Scheduler/styles';
import { setClientContract } from 'utils/api/ClientApi';
import {IoIosArrowDroprightCircle} from 'react-icons/io';
import _ from "lodash"
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  StylePointer,
} from 'shared/styles/constants/tagsStyles';
// import {} from './styles';
import {CHECK_CIRCLE} from 'utils/constants/icons';
import PropTypes from 'prop-types';
import {red} from '@mui/material/colors';
const PlannedConfirmationModal = ({
  fullBind,
  setModalValue,
 
  type,
  title,
  icon,
handleSubmit,effective_date
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const {contextChoices,handleGetChoices} = useContext(AppContext);
  const [newDetails, setDetails] = useState([]);
  const [fullDay ,setFullDay]=useState("Full Day")
  const [currentDate,setCurrentDate]=useState()
  const toast = useRef();
  useEffect(() => {
    let newDate = new Date().toJSON().slice(0, 10); 
    setCurrentDate(newDate)
    setAddFullBind({
      ...addFullBind,
      title: 'Confirmation',
    });
  }, [fullBind]);
 
  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <ModalContainer min_width={'40%'} max_width={'50%'}>
        <ModalDynamic {...addFullBind} loading={false}>
          <IconStyle type={type}>{icon}</IconStyle>
          <MessageOptaPlanner className={'mt-4 mb-3'}>
            <div className={'warningMessage ps-3 pe-3'}>
              <h5>
              This action will update all visits data of planned task from effective date mentioned below. Do you confirm for the changes?                
               
              </h5>
            </div>
            <div>
                      <IoIosArrowDroprightCircle />
                      From Date ({effective_date?effective_date:currentDate})
                      </div>
            
          </MessageOptaPlanner>
          <div className="d-flex justify-content-center modal-footer border-0">
            <CancelButton
              type="button"
              className="btn btn-secondary"
              onClick={() => 
              setModalValue(false)}>
              NO, CANCEL
            </CancelButton>

            <PrimaryButtonModal
              // typeButton={type}

              onClick={handleSubmit
              }>
              YES, PROCEED
            </PrimaryButtonModal>
          </div>
        </ModalDynamic>
      </ModalContainer>
    </div>
  );
};
export default PlannedConfirmationModal;