import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { ModalContainer } from 'shared/styles/constants/tagsStyles'
import { UnassignAllVisits } from 'utils/api/SchedulerApi';

function BulkUnassignVisits({
    fullBind,
    visitFailData,
    setVisitFailData,
    toast,
    setvisitSuccessData,
    filterValues,
    filterExclude,

    setRightClickSeletcedId,
    setSelectedEvents,
    schedulerData,
    setSchedulerData,
    calculateTotalCounts,
    setVisitScreenData,
    setRotsScreenResponse,
    setVisitScreenResponse
}) {
    const [addFullBind, setAddFullBind] = useState(fullBind);
    const [overlapLoading, setOverlapLoading] = useState({});
    const [disableButton, setDisableButton] = useState([]);
    useEffect(() => {
        setAddFullBind({
            ...addFullBind,
            title: 'Unassign Visits'
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullBind]);

    // const handleAction = (visit_id, index) => {

    // }

    const handleUnAssignVisits = async (visit_id, index) => {
        debugger
        if (!visit_id) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: "Id not found!"
            });
            return;
        }
        let body = {
            visit_ids: visit_id,
            force: true
        }
        try {
            let response = await UnassignAllVisits(body,  filterValues, filterExclude)
            console.log(response, "response")
            // if (response.status === true) {
            //     toast.current.show({
            //       severity: 'success',
            //       summary: 'Success',
            //       detail: `${response.message}`,
            //     });
               
    
            //     // setErrorMessage('')
            //     setRightClickSeletcedId([])
            //     setSelectedEvents([])
            //     // searchScheduler()
    
            //     // if(response?.clientsVisitData && response.clientsVisitData?.length && schedulerData?.length){
            //     //   response.clientsVisitData.map(item => {
            //     //   const index = schedulerData.findIndex(el => el.id == item.id)
            //     //   if (index !== -1) {
            //     //     schedulerData[index] = item
            //     //   }
            //     //   })
            //     //   setSchedulerData([...schedulerData])
            //     // }
    
            //     if (response?.clientsVisitData?.length && schedulerData?.length) {
            //       const schedulerMap = new Map(schedulerData.map(item => [item.id, item]));
                
            //       response.clientsVisitData.forEach(item => {
            //         if (schedulerMap.has(item.id)) {
            //           schedulerMap.set(item.id, item);
            //         }
            //       });
                
            //       setSchedulerData(Array.from(schedulerMap.values()));
            //     }
    
            //     // const sch_data = [...filtered_events, ...new_data]
            //     // setSchedulerData(sch_data)
            //     // updateSchData(schedulerData, rotaData, calendarView)
            //     // setLoading(false)
            //     let data = calculateTotalCounts(response?.calculationsData?.visits, response?.calculationsData?.shifts)
            //     setVisitScreenData(data)
            //     setRotsScreenResponse(response?.calculationsData?.shifts)
            //     setVisitScreenResponse(response?.calculationsData?.visits)
            //     return;
            //   }
            let visitf = [] 
            if (Object.keys(response.visitFail).length) {
                const values = Object.values(response.visitFail)
                const keys = Object.keys(response.visitFail)
                visitf = values.map((item, index) => {
                    return {
                        ...item,
                        visit_id: keys[index]
                    }
                })
                setVisitFailData(prev => [...prev.filter(el => !keys.includes(el.visit_id)), ...visitf]);
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: typeof response.message === "string" ? response.message : `Assignment process failed`,
                });
                if (response.visitSuccess.length || response.shiftSuccess.length) {
                    setvisitSuccessData(true)
                }
            } else {
                if (response.status == true) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: response.message || "Successfully"
                    });
                }
                if (response.visitSuccess.length || response.shiftSuccess.length) {
                    setvisitSuccessData(true)
                }
                setDisableButton([...disableButton, index + 1]);
                // setErrorMessage('')
            }

            //setActionType('')
            // searchScheduler()
            setOverlapLoading({ [index]: { loading: false } });

        } catch (error) {

            setOverlapLoading({ [index]: { loading: false } });
        }
    }
    return (
        <ModalContainer max_width={'60%'}>
            <ModalNoInfo
                {...addFullBind}
                max_width={'60%'}
                minWidthTitle="6.5rem"
                loading={false}
            >
                <div className={' ps-3 pe-3 pb-2'}>

                    {visitFailData && visitFailData.length > 0 && visitFailData.map((item, index) => {
                        return (
                            <div className='d-flex justify-content-between mb-2 mt-1'>
                                <div>
                                    <span style={{ fonSize: "1rem", fontWeight: '700' }}>{index + 1}.{" "}</span>
                                    {item.HEADING} -  {item.MESSAGE}
                                </div>

                                <div style={{ minWidth: '11rem', textAlign: 'end' }}>
                                    <PrimaryButtonForm width="10rem"
                                        disabled={disableButton.find(ele => ele === index + 1) ? true : false}
                                        onClick={() => {
                                            setOverlapLoading({ [index]: { loading: true } });
                                            handleUnAssignVisits(item.visit_id, index)
                                        }}
                                    >
                                        <span className={'me-2'}>Force Unassign</span>
                                        {(overlapLoading[index] && overlapLoading[index].loading) && (
                                            <span
                                                className="spinner-border spinner-border-sm me-1"
                                                role="status"
                                            />
                                        )}
                                    </PrimaryButtonForm>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </ModalNoInfo>
        </ModalContainer>
    )
}

export default BulkUnassignVisits