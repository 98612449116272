import React from 'react';
import {
  TitleSection,
  ContainerResponsive,
  UserImgContainer,
} from '../styles';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  UploadPhotoContainer,
  UserImgWrapper,
} from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { useEffect } from 'react';
import Image from 'components/SharedComponents/Image';
import { UploadInput } from 'shared/styles/buttons';
import { FiUpload } from 'react-icons/fi';
const BasicInformation = ({
  values,
  useInput,
  employeePhoto,
  choices,
  dropDownHandleSelect,
  handlePickFile,
  clientList,
  readOnly,
  setPhotoById,
  createPermissionAccesBranch,
  readPermissionAccesBranch,
  updatePermissionAccesBranch,
  selectedId,
  basicInfoPhoto,
  setDisplayPhoto
}) => {
  const branchAccessInput = useInput(`location_access_branch`, 'isRequired')
  useEffect(() => {
    setDisplayPhoto(values.photo)
  }, [values.photo])
  return (
    <div>
      <TitleSection>Basic Information</TitleSection>
      <div className="d-flex flex-column mt-4">
        <ContainerResponsive className="d-flex">
          <div className="me-md-2 mb-2 align-items-stretch">
            <UserImgWrapper>
              <UserImgContainer>
                <Image
                  src={
                    basicInfoPhoto
                      ? URL.createObjectURL(basicInfoPhoto)
                      : values.photo
                        ? values.photo
                        : ''
                  }
                  name={values.first_name || values.full_name}
                  lastName={values.last_name}
                  alt={'basicInfo-img'}
                  fontSize={'1.5rem'}
                />
              </UserImgContainer>

              <UploadPhotoContainer className="d-flex align-items-center mt-2">
                <UploadInput
                  onInput={e => {
                    !readOnly && handlePickFile(e, 'photo');
                  }}
                  onClick={event => {
                    event.target.value = null;
                  }}
                  type="file"
                  accept={'.png,.jpg,.jpeg'}
                  id="basicInfo-photo"
                  name="basicInfo-photo"
                  placeholder={'Client Photo'}
                />
                <FiUpload />
                <label htmlFor="basicInfo-photo">Upload</label>
              </UploadPhotoContainer>
            </UserImgWrapper>
          </div>
          <div className='d-flex w-100 flex-column'>
            <FlexGrid3Container className="w-100">
              <FlexGrid3Item className="pe-1">
                <InputLabelTop
                  required
                  readOnly={readOnly}
                  type="text"
                  id="first_name"
                  label={'First Name'}
                  {...useInput('first_name', 'isRequired')}
                  maxlength={100}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1">
                <InputLabelTop
                  type="text"
                  readOnly={readOnly}
                  id="middle_name"
                  label={'Middle Name'}
                  {...useInput('middle_name')}
                  maxlength={100}
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="">
                <InputLabelTop
                  required
                  type="text"
                  readOnly={readOnly}
                  id="full_name"
                  label={'Full Name'}
                  {...useInput('full_name', 'isRequired')}
                  maxlength={255}
                />
              </FlexGrid3Item>
            </FlexGrid3Container>

            <FlexGrid3Container className="w-100">
              <FlexGrid3Item className="pe-1">
                <InputLabelTop
                  type="text"
                  readOnly={readOnly}
                  id="preferred_name"
                  label={'Preferred Name'}
                  {...useInput('preferred_name')}
                  maxlength={100}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1">
                <InputLabelTop
                  type="text"
                  readOnly={readOnly}
                  id="previous_name"
                  label={'Previous Name'}
                  {...useInput('previous_name')}
                  maxlength={50}
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="">
                <InputLabelTop
                  type="text"
                  readOnly={readOnly}
                  id="initials"
                  label={'Initials'}
                  {...useInput('initials')}
                  maxlength={50}
                />
              </FlexGrid3Item>
            </FlexGrid3Container>
            <FlexGrid3Container className="w-100 mb-2">
              <FlexGrid3Item className="pe-1">
                <DropdownSearchable
                  required
                  placeHolder={'Status'}
                  disabled={readOnly}
                  options={choices.client_status}
                  selectedOption={values.status}
                  {...useInput(`status`, 'isRequired')}
                  onChange={value =>
                    dropDownHandleSelect({
                      status: value ? value : null,
                    })
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1">
                <DateSelector
                  disabled={readOnly}
                  labelTop="Location On Boarded Date"
                  {...useInput('location_onboarded_date')}
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="">
                <DateSelector
                  disabled={readOnly}
                  labelTop="Final Location Date"
                  {...useInput('final_location_date')}
                />
              </FlexGrid3Item>
            </FlexGrid3Container>
          </div>
        </ContainerResponsive>



        <FlexGrid3Container className="w-100 mb-2">
          <div style={{ minWidth: '50%' }}>
            <FlexGrid3Item className="pe-1">
              <DropdownSearchable
                required
                {...useInput(`branch`, 'isRequired')}
                placeHolder={'Branch Name'}
                options={choices.branch}
                disabled={readOnly}
                selectedOption={
                  values.branch
                    ? values.branch
                    : null
                }
                onChange={value =>
                  dropDownHandleSelect({ branch: value ? value : null }, 'branch')
                }
              />
            </FlexGrid3Item>
          </div>
          {(selectedId ? readPermissionAccesBranch : createPermissionAccesBranch)
            &&
            <div style={{ minWidth: '50%' }}>
              <FlexGrid3Item className="">
                <DropdownSearchable
                  required
                  isMulti={true}
                  {...branchAccessInput}
                  placeHolder={'Branch Access'}
                  options={choices.branch}
                  disabled={selectedId ? !updatePermissionAccesBranch : !createPermissionAccesBranch}
                  selectedOptionsProps={
                    values
                      ? values.location_access_branch
                        ? values.location_access_branch
                        : null
                      : null
                  }
                  onChange={value =>
                    dropDownHandleSelect(
                      { branch: value ? value : null },
                      'accessBranch'
                    )
                  }
                />
              </FlexGrid3Item>
            </div>}
        </FlexGrid3Container>
      </div>
    </div>
  );
}

export default BasicInformation;
