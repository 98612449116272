import React, {useEffect, useState} from 'react';
import {ThemeProvider} from 'styled-components';
import {DropdownButton, ItemsDropdownStyle} from './styles';
import {FiChevronUp, FiChevronDown} from 'react-icons/fi';
import {GRAY_400, GRAY_700, GRAY_820, PURPLE, WHITE} from 'shared/styles/constants/colors';

let dropdownReference;

const Dropdown = ({
  items = [],
  onSelect = () => {},
  placeHolder = 'Select',
  appendItem = '',
  alignmentRight = false,
  initialValue = '',
  isObject = false,
  width = '',
  disabled = false,
  type = '',
  maxheight = '',
  required = false,
  justifyContent = '',
  disableSelection = false,
  noPadding = false,
  value = '',
  SecondValue= false,
}) => {
  const [showDropdown, setShowDropDown] = useState(false);
  const [itemsSelected, setItemsSelected] = useState('');

  useEffect(
    () => {
      if (initialValue || !value) {
        setItemsSelected(
          !isObject
            ? initialValue + ' ' + appendItem
            : initialValue.name + ' ' + appendItem,
        );
      } else {
        setItemsSelected('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValue],
  );

  useEffect(
    () => {
      if (value) {
        setItemsSelected(value + ' ' + appendItem);
      } else {
        setItemsSelected('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  useEffect(() => {
    return function cleanup() {
      dropdownReference = null;
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, []);

  const itemSelected = item => {
    onSelect(!isObject ? item + ' ' + appendItem : item.id);
    setItemsSelected(
      !isObject ? item + ' ' + appendItem : item.name + ' ' + appendItem,
    );
  };

  const composeDropdownClasses = () => {
    let classes = 'dropdown-menu ';
    if (alignmentRight) {
      classes = classes + ' dropdown-menu-right';
    }
    if (showDropdown) {
      classes = classes + ' show';
    }

    return classes;
  };

  const setNode = nodeParam => {
    dropdownReference = nodeParam;
  };

  const handleOutsideClick = e => {
    // ignore clicks on the component itself
    if (dropdownReference !== null) {
      if (dropdownReference.contains(e.target)) {
        return;
      }
    }
    setShowDropDown(false);
  };

  const handleComboClick = () => {
    if (!showDropdown) {
      // attach/remove event handler
      document.addEventListener('click', handleOutsideClick, true);
    } else {
      document.removeEventListener('click', handleOutsideClick, false);
    }
    setShowDropDown(!showDropdown);
  };

  const theme = {
    main: type,
  };

  return (
    <div
      className={showDropdown ? `w-100 btn-group show` : `w-100 btn-group`}
      onClick={disabled ? () => {} : () => handleComboClick()}
      ref={setNode}>
      <ThemeProvider theme={theme}>
        <DropdownButton
          justifyContent={justifyContent}
          className="button-selected"
          width={width}
          itemsSelected={itemsSelected}
          title={itemsSelected || placeHolder}
          noPadding={noPadding}>
          <h6>
            {placeHolder} <span>{required ? '*' : ''}</span>
          </h6>
          <label>
            {value || itemsSelected || placeHolder}{' '}
            <span>{required && !itemsSelected ? '*' : ''}</span>
          </label>
          <i>
            {showDropdown ? (
              <FiChevronUp className="align-self-center me-0" />
            ) : (
              <FiChevronDown className="align-self-center me-0" />
            )}
          </i>
        </DropdownButton>
      </ThemeProvider>
      <div className={`${composeDropdownClasses()} ${width}`}   >
        <ItemsDropdownStyle maxheight={maxheight}>
          {items.length>0 && items.map((item, index) => {
            const selectedItem = !isObject
              ? item.replace(/ /g, '')
              : item.name.replace(/ /g, '');
            let addStyle = {};

            if (
              !disableSelection &&
              ((selectedItem === itemsSelected.replace(/ /g, '') && !value) ||
                (selectedItem === placeHolder.replace(/ /g, '') && !value) ||
                selectedItem === value.replace(/ /g, ''))
            ) {
              addStyle = {
                backgroundColor: `${PURPLE}`,
                color: `${WHITE}`,
              };
            }

            return (
              <>
              <button
                className="dropdown-item text-capitalize"
                style={addStyle}
                key={`dropdown-item-${index}`}
                type="button"
                onClick={
                  disabled || disableSelection
                    ? () => {}
                    : () => itemSelected(item)
                }
                title={!isObject ? item : item.name}>
                {!isObject ? item : item.name}
                {SecondValue &&
              <div>
                {item.supplier_brand_name && <span style={{color:GRAY_700, fontSize:'10px'}}>{item.supplier_brand_name} ||</span>}
                {item.medication_class && <span style={{color:GRAY_700, fontSize:'10px'}}>{item.medication_class} ||</span>}
                {item.indication && <span style={{color:GRAY_700, fontSize:'10px'}}>{item.indication}</span>}
              </div>}
              </button>
              
              </>
            );
          })}
        </ItemsDropdownStyle>
      </div>
    </div>
  );
};

export default Dropdown;
