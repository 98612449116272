
export const DASHBOARDS = '/';
export const DASHBOARD_TEAM_KPI = '/teamkpi';
export const DASHBOARD_CLIENT_KPI = '/clientkpi';
export const DASHBOARD_VISIT_KPI = '/visitkpi';
export const DASHBOARD_INSIGHTS = '/insights';
export const DASHBOARD_AUDITS_ROUTE = '/audits';
export const DASHBOARD_MEDICATION_KPI = '/medicationkpi';
export const PAGE_404 = '/404';
export const AUTH = '/auth';
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot';
export const VERIFICATION = '/verifyEmail';
export const RESET_PASSWORD = '/resetPassword';
export const RESET_PIN = '/resetMobilePin';
export const TEAM = '/team';
export const KPI = '/kpi';
export const HEAVY_PRINT = '/heavy-print';
export const MULTIFACTOR_AUTH = '/verifyOTP'

export const CLIENT = '/client';
export const REPORT = '/report';
export const FINANCE = '/finance';
export const SUMMARY = '/summary'
export const FINPAYROLL = '/fin-payroll'
export const DETAILS = '/details'
export const SETTINGS = '/settings';
export const SETTINGS_TEAM = '/team';
export const SETTINGS_CLIENT = '/client';
export const REPORT_TEAM = '/team';
export const REPORT_CLIENT = '/client';
export const REPORT_KPI = '/kpi';
export const NOK = '/nok';
export const PERSONAL_DETAILS = '/peronalDetails';

export const JOB_DETAILS = '/jobDetails';
export const RATES = '/rates';
export const ALLERGIES = '/allergies';
export const VISIT = "/visit-care"
export const PREFERRED_CLIENTS = '/preferred-client';
export const AVOID_CLIENTS = '/avoid-clients';
export const AVOID_EMPLOYEES = '/avoid_employees';
export const POSTAL_AREA = '/postal-area';
export const AVOID_ANIMALS = '/avoid-animals';
export const AVOID_RUN = '/avoid-run';
export const PREFERRED_RUN = '/preferred-run';
export const VISIT_CARE = '/visit-care_notes';
export const EMPLOYEE_ROTA = '/employee-rota';
export const EXPENSE = '/expenses';
export const SKILLS_TRAINING = '/skills-training';
export const COMPETENCIES = '/competencies';
export const QUALIFICATION = '/qualification';
export const OVERVIEW = '/overview';
export const EXPIRY_NOTIFICATION = '/expiry-notification';
export const REFERENCES = '/references';
export const RIGHTTOWORK = '/right-to-work';
export const DBS = '/dbs';
export const VACCINATIONS = '/vaccinations';
export const DRIVERS = '/drivers';
export const EMPLOYEEID = '/employee-id';
export const SETTINGS_CALENDAR = '/setting-calendar';
export const SETTINGS_FORM_BUILDER = '/setting-form-builder';
export const SETTINGS_CHOICES = '/setting-form-builder-choices';
export const SETTINGS_CATEGORY = '/setting-form-builder-category';
export const SETTINGS_ASSESSMENT = '/setting-assessment';
export const PUBLIC_HOLIDAYS = '/public-holidays';
export const WD_WE = '/weekday-weekend';
export const DAY_NIGHT_OVER = '/day-night-over';
export const DURATION = '/duration';
export const CALENDAR_DATA = '/calendar';
export const CALENDAR_TYPE = "/calendar-type"
export const SETTINGS_ROLES_AND_PERMISSIONS = '/roles-and-permissions';
export const SETTINGS_ORGANIZATIONS = '/organizations';
export const SETTINGS_SUBSCRIPTION = '/subscription';
export const COMPANY = '/company';
export const COMPANY_PROFILE = '/profile';
export const COMPANY_LICENSE = '/license';
export const ABSCENCE_OVERVIEW = '/absence-overview'
export const ABSCENCE_HISTORY = '/absence-history'
export const ABSCENCE_ADJUSTMENT = '/absence-adjustment'
//export const  COMPANY_CALENDAR= '/calendar';
export const COMPANY_BRANCHES = '/branches';
//Branch settings
export const BRANCH_SETTING = '/branch-setting'
export const ANNUAL_LEAVE_ACCRUAL = '/accrual-leave'
export const BRANCH_ABSCENCE_OVERVIEW = '/absence-overview'

export const COMPANY_PRIORITY = '/priority';
export const ROTA_SHIFTS = '/rota-shifts';
export const SCHEDULER = '/scheduler';
export const MEDICATION = '/medication'
export const SHIFT_PATTERNS = '/shift-patterns';
export const VISITS = '/visits';
export const EVV = '/evv';
export const ESV = '/esv'
export const EVV_VISITS = '/visits';
export const EVV_GPS = '/gps';
export const EVV_TRAVEL = '/travel';
export const ESV_VISITS = '/visits';
export const ESV_GPS = '/gps';
export const ESV_TRAVEL = '/travel';
export const USERS = '/users';
export const TEAM_ROUTE_MAPPING = '/team-route-mapping';
export const REGULAR_VISIT = '/regular-visit';
export const COMPANY_RUN_ROUTE = '/run-route';
export const COMPANY_MOBILE_AUTH = '/mobile-auth'
export const CANCELLED_VISITS = '/cancelled-visits';
export const OFFER_VISITS = '/offer-visits';
export const BRADFORD_ROUTE = '/bradford';
export const HANDBACK_REQUEST = '/handback-request'
export const SWAP_VISIT = '/swap-visit'
export const SETTINGS_ALGOSCORE = '/algo-score';
export const SETTINGS_RATECARD = '/rate-card';
export const ALGO_SCORE_NEWFEILD = '/algotime';
export const ALGO_SCORE_CLIENT = '/client';
export const ALGO_SCORE_EMPLOYEE = '/employee';
export const ALGO_SCORE_TRAVEL = '/travel';
export const ALGO_SCORE_WTR = '/wtr';
export const ALGO_SCORE_FINANCIAL = '/financial';
//export const ABSENCE_HISTORY_LIST= '/absence-history-list';
export const FUNDER = '/funder';
export const HEALTH = '/health';
export const MEDICATIONS = '/medications'
export const PRIORITY = '/priority';
export const IMPCONTACT = '/important-contact';
export const CLIENT_TASK = '/tasks';
export const GROUP_CLIENT_TASK = '/group-tasks';
export const GROUP_CLIENT_SERVICE_CONTRACT = '/template-service'
export const BILLRATE = '/bill-rate';
export const PAYRATE = '/pay-rate';
export const TRAVELRATE = '/travel-rate';
export const ONCALL = '/on-call';
export const OFFICE = '/office';
export const ABSCENCE = '/absence';
export const NLW = '/nlw-rlw';
export const SHIFT = '/shift';
export const WAIT = '/wait';
export const TIMESOURCE = '/time-source';
export const SETTINGS_EVV = '/settings-evv';
export const VISIT_AUDIT = '/visit-audit'
export const SETTINGS_ESV = '/settings-esv'
export const SETTINGS_BRADFORD = '/settings-bradFord'
export const SETTINGS_EMAIL = '/email-history'
export const SETTINGS_FINANCE = '/settings-finance';
export const BILLING = '/billing';
export const PAYROLL = '/payroll';
export const COMPARE = '/compare';
export const CLIENT_DETAILS = '/client-details';
export const KPI_DAILY_DETAILS = '/kpi'
export const TEAM_KPI = '/teamkpi'
export const KPI_VISIT = '/KPI-VIST';
export const KPI_WEAKLY_DETAILS = '/weakly-details'
export const PREFERRED_EMPLOYEES = '/preferrered-employees';
export const ROTA_EMAIL = '/rota-email';
export const MEDICAL = '/medical';
export const IMPORTANT_CONTACTS = '/important-contacts';
export const VISIT_CARE_NOTES = '/visit-care-notes';
export const CLIENT_TASKS = '/tasks'
export const ALERTS_FEATURES = '/alerts';
export const SETTINGS_ALERT = '/alert';
export const BELLNOTIFICATIONTABLE = '/dashboard-notification';
export const NOTIFICATIONS_SETTING_URL = "/dashboard-notification-setting"

// export const  TRIAGE_FEATURES= '/triage';
export const INVOICE = '/invoice'
export const TRIAGE = '/triage'
export const LINKED_USER = '/linked-user'
export const SETTINGS_TRIAGE = '/triage';
export const FORM_BUILDER = '/form-builder';
export const FORM_BUILDER_ADD = '/add-form';
export const FORM_CATAGORY_ADD = '/settings/setting-form-builder/setting-form-builder-category/add-category';
export const FORM_BUILDER_EDIT = '/edit-form';
// export const BELLNOTIFICATIONTABLE = '/dashboard-notification';

export const TEAM_SKILL_MATRIX = '/skill-matrix'
export const TEAM_COMPETENCY_MATRIX = '/competency-matrix'
export const HR_TASKS = "/qa-tasks"
export const TEAM_HR_TASK_MATRIX = '/qa-task-matrix'
export const CLIENT_HR_TASK_MATRIX = '/qa-task-matrix'

//Location route
export const LOCATION = '/location'
export const REPORT_SERVICE_CONTRACT_ROUTE = '/service-contract'

export const ACCOUNT_ROUTE = "/account";
export const DELETE_ACCOUNT_ROUTE = "/delete-account";
export const CHANGE_PASSWORD_ROUTE = "/change-password"
export const SETTINGS_LOGIN_ROUTE = "/settings-login"

export const CLIENT_ABOUT_ME_ROUTE = '/about-me'
export const RISK_ASSESSMENT_ROUTE = '/risk-assessment'
export const CRON_JOB_ROUTE = '/cron-job'
export const REGULAR_SHIFT_ROUTE = '/regular-shift'
export const CLIENT_VISIT_ROUTE = '/client_visit'
export const DELETED_VISIT_ROUTE = '/deleted-visit'
export const COMPANY_ORGANIZATION = '/organization';



export const SPLIT_SHIFT_ROUTE = '/split-shift';
