import React from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {KeyPress} from 'utils/constants/regularExpressions';
import {TitleSection, FlexEqual} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';

const Contact = ({
  useInput,
  nameSection,
  sectionStyle,
  choices,
  values,
  dropDownHandleSelect,
}) => {
  return (
    <FlexEqual marginTopSm noMarginLeft className={sectionStyle}>
      {/* <TitleSection>Contact</TitleSection> */}
      {/* <div className="d-flex flex-column mt-2"> */}
        {/* <InputLabelTop
          required
          type="text"
          id="telephone"
          label={'Telephone'}
          onKeyPress={KeyPress.PHONE}
          {...useInput(
            `${[nameSection]}.telephone`,
            'isMobilePhone, isRequired',
          )}
          maxlength={25}
        /> */}

        {/* <div className="d-flex"> */}
          {/* <div className="w-100 me-2">
            <InputLabelTop
              type="email"
              id="email_personal"
              required={true}
              label={'Personal email'}
              {...useInput(`${[nameSection]}.email_personal`, 'isEmail')}
              maxlength={100}
            />
          </div> */}
          {/* <div className="w-100">
            <InputLabelTop
              type="email"
              id="work_email"
              label={'Work email'}
              {...useInput(`${[nameSection]}.email_work`, 'isEmail')}
              maxlength={100}
            />
          </div> */}
        {/* </div> */}

        {/* <div className="w-100 me-2">
          <InputLabelTop
            type="text"
            id="keysafe"
            label={'Keysafe Code'}
            {...useInput(`${[nameSection]}.keysafe`)}
            maxlength={25}
          /> */}
        {/* </div> */}
        <div className="w-100">
          <DropdownSearchable
            placeHolder={'Preferred Contact Method'}
            options={choices.contact_method}
            selectedOption={
              values[nameSection] ? values[nameSection].contact_method : null
            }
            onChange={value =>
              dropDownHandleSelect({
                [nameSection]: {contact_method: value ? value : null},
              })
            }
          />
        </div>

      {/* </div> */}
    </FlexEqual>
  );
};

Contact.propTypes = {
  useInput: PropTypes.func.isRequired,
  nameSection: PropTypes.string.isRequired,
  sectionStyle: PropTypes.string,
  values: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
};

Contact.defaultProps = {
  sectionStyle: '',
};

export default Contact;
