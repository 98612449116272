import React, { useState, useEffect, useCallback } from 'react';
import Fade from 'react-reveal/Fade';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { Row, Col, SortIcon } from './styles';
import { Fragment } from 'react';

const Table = ({
  children,
  headerColumns,
  callBackQuerySort = () => { },
  headerPadding = true,
  noEditOption = false,
  fontSize = '',
  noNeed = true,
  handleAllSelectChange,
  handleAllCheck,
  values = [],
  coloumnSelectorHeader = [],
  noFade = false,
  numberOfIcons = 0,
  sortable= true
}) => {

  //below code has been commented as its  has  disable column click sort
  // const [headers, setHeaders] = useState([]);
  // useEffect(()=>{
  // setHeaders(headerColumns)
  // },[headerColumns])

  const [headers, setHeaders] = useState(headerColumns);
  if(headerColumns.length !== headers.length){
    setHeaders(headerColumns)
  }
  const getFormatSortQuery = () => {

    let querySort = headers?.map((column, index) => {
        switch (column?.status) {
          case 0:
            return '';
          case 1:
            return column?.name;
          case 2:
            return `${column?.name ? `-${column?.name}` : ""}`;
          default:
            return '';
        }
      })
      .filter(function (el) {
        return el !== '';
      });
    querySort = { ordering: querySort.join() };
    callBackQuerySort(querySort);
  };

  const columnSort = index => {
    let newColumns = [...headers];
    newColumns[index].status = cycleColumnSortStatus(newColumns[index]?.status);
    setHeaders(newColumns);
    getFormatSortQuery();

  };


  const cycleColumnSortStatus = status => {
    switch (status) {
      case 0:
        return 1;
      case 1:
        return 2;
      case 2:
        return 0;
      default:
    }
  };

  const renderColumnStatusIcon = status => {
    switch (status) {
      case 0:
        return <></>;
      case 1:
        return (
          <SortIcon>
            <FiChevronUp />
          </SortIcon>
        );
      case 2:
        return (
          <SortIcon>
            <FiChevronDown />
          </SortIcon>
        );
      default:
    }
  };

  return (
    <Fade bottom cascade duration={noFade?0:1000}>
      <section  >
        <Row Upper NoShadow>
          {headers.map((column, index) => {
            return (column?.hasOwnProperty("custum_column") && column?.custum_column) ? (
              <Col
                className={(values && (values.includes(column?.name) || ((coloumnSelectorHeader && (coloumnSelectorHeader.length > 0)) ? values.includes(coloumnSelectorHeader[index]?.id) : values.includes(column?.id)))) ? '' : 'd-none'}
                max_width={column?.max_width}
                //className={column.className}
                overlap={column?.overlap}
                key={index}
                Upper
                Center
                Shrink={column?.shrink}
                fontSize={fontSize}
                onClick={() => {
                  !column.checkbox && columnSort(index);
                }}>
                <div
                  className={
                    'd-flex justify-content-sm-start justify-content-md-center'
                  }>
                  <span className={headerPadding ? 'ps-lg-4 p-0' : 'p-0'}>
                    {(column.label === 1)
                    ? <input checked={handleAllCheck && handleAllCheck()} type="checkbox" onChange={(val) => { handleAllSelectChange(val) }} /> 
                    : column?.label}
                  </span>
                </div>
                {sortable && renderColumnStatusIcon(column?.status)}
              </Col>
            )
              :
              (
                <Col
                  max_width={column?.max_width}
                  className={column?.className}
                  overlap={column?.overlap}
                  key={index}
                  Upper
                  Center
                  Shrink={column?.shrink}
                  fontSize={fontSize}
                  onClick={() => {
                    !column?.checkbox && columnSort(index);
                  }}>
                  <div
                    className={
                      'd-flex justify-content-sm-start justify-content-md-center'
                    }>
                    <span className={headerPadding ? 'ps-lg-4 p-0' : 'p-0'}>
                      {(column?.label === 1) ? <input checked={handleAllCheck && handleAllCheck()} type="checkbox" onChange={(val) => { handleAllSelectChange(val) }} /> : column?.label}
                    </span>
                  </div>
                  {sortable && renderColumnStatusIcon(column?.status)}
                </Col>
              )
          })}


          {numberOfIcons > 0 ? 
          Array.from({ length: numberOfIcons }, _ =>  {
            return <Col className="" Center Width />
          }) : null}


          {noEditOption ? <></> : <Col className="" Center Icon />}
          {noNeed ? <Col className="" Center Icon /> : <></>}
        </Row>
        {/* {tableNew()} */}
        <div className="mb-1" />

        {children}
      </section>
    </Fade>
  );
};

export default Table;