import React, {useState, Fragment, useEffect} from 'react';
import {FlexEqual} from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {useForm} from 'hooks/FormHook';
import AddSkills from 'components/DashboardComponents/Team/AddSkills';
import AddCompetencies from 'components/DashboardComponents/Team/AddCompetencies';
import AddQualification from 'components/DashboardComponents/Team/AddQualification';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {useHistory, useLocation} from 'react-router';
import {
  setEmployeeSkills,
  getEmployeeSkillsDetails,
} from 'utils/api/EmployeeApi';
import {
  SKILLS_TRAINING,
  COMPETENCIES,
  QUALIFICATION_APPRENTICESHIPS,
  WEB_CREATE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION,
} from '../../../../utils/constants/permissions';
import _, {isNull} from 'lodash';
import {ErrorMessage, NoPermissionContainer} from './styles';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import { AppContext } from 'context';
import { useContext } from 'react';
import { skills_emp_choices } from 'utils/choiceConstant';

const Skills = ({
  choices,
  empId,
  selectedId,
  skillDet,
  setSkillDet,
  createPermissionSkills,
  updatePermissionSkills,
  readPermissionSkills,
  filterAdmin,
  contextSubscription,
  filterSuperAdmin,
  rightPermission,
  userEmployee,
}) => {
  const [showError, setShowError] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [modalLoading, setModalLoading] = useState(false);
  const [skillsSkillsTrainingCreate, setSkillsSkillsTrainingCreate] =
    useState(false);
  const [skillsSkillsTrainingUpdate, setSkillsSkillsTrainingUpdate] =
    useState(false);
  const [skillsSkillsTrainingRead, setSkillsSkillsTrainingRead] =
    useState(false);
  const [skillsCompetenciesCreate, setSkillsCompetenciesCreate] =
    useState(false);
  const [skillsCompetenciesUpdate, setSkillsCompetenciesUpdate] =
    useState(false);
  const [skillsCompetenciesRead, setSkillsCompetenciesRead] = useState(false);
  const [
    skillsQualificationApprenticeshipsRead,
    setSkillsQualificationApprenticeshipsRead,
  ] = useState(false);
  const [
    skillsQualificationApprenticeshipsCreate,
    setSkillsQualificationApprenticeshipsCreate,
  ] = useState(false);
  const [
    skillsQualificationApprenticeshipsUpdate,
    setSkillsQualificationApprenticeshipsUpdate,
  ] = useState(false);
  const [upDateDetails, setUpdateDetails] = useState([]);
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setFormErrors,
    setCheckRequires,
  } = useForm({});
  const history = useHistory();
  const location = useLocation();
  const {handleGetChoices, loadingChoices} = useContext(AppContext)

  useEffect(()=>{
    handleGetChoices(skills_emp_choices)
  },[])
  useEffect(() => {
    if (filterSuperAdmin) {
      setSkillsSkillsTrainingCreate(true);
      setSkillsSkillsTrainingUpdate(true);
      setSkillsSkillsTrainingRead(true);
      setSkillsCompetenciesCreate(true);
      setSkillsCompetenciesUpdate(true);
      setSkillsCompetenciesRead(true);
      setSkillsQualificationApprenticeshipsRead(true);
      setSkillsQualificationApprenticeshipsUpdate(true);
      setSkillsQualificationApprenticeshipsCreate(true);
    } else if (filterAdmin) {
      setSkillsSkillsTrainingCreate(true);
      setSkillsSkillsTrainingUpdate(true);
      setSkillsSkillsTrainingRead(
        contextSubscription?.[SKILLS_TRAINING]?.[WEB_PERMISSION]
      );
      setSkillsCompetenciesCreate(true);
      setSkillsCompetenciesUpdate(true);
      setSkillsCompetenciesRead(
        contextSubscription?.[COMPETENCIES]?.[WEB_PERMISSION]
      );
      setSkillsQualificationApprenticeshipsRead(
        contextSubscription?.[QUALIFICATION_APPRENTICESHIPS]?.[WEB_PERMISSION]
      );
      setSkillsQualificationApprenticeshipsUpdate(true);
      setSkillsQualificationApprenticeshipsCreate(true);
    } else {
      if (rightPermission && rightPermission[SKILLS_TRAINING]) {
        setSkillsSkillsTrainingCreate(
          rightPermission[SKILLS_TRAINING][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[SKILLS_TRAINING][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setSkillsSkillsTrainingUpdate(
          rightPermission[SKILLS_TRAINING][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[SKILLS_TRAINING][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setSkillsSkillsTrainingRead(
          contextSubscription?.[SKILLS_TRAINING]?.[WEB_PERMISSION] &&
            (rightPermission[SKILLS_TRAINING][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[SKILLS_TRAINING][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] &&
                userEmployee == selectedId))
        );
      }

      if (rightPermission && rightPermission[COMPETENCIES]) {
        setSkillsCompetenciesCreate(
          rightPermission[COMPETENCIES][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[COMPETENCIES][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setSkillsCompetenciesUpdate(
          rightPermission[COMPETENCIES][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[COMPETENCIES][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setSkillsCompetenciesRead(
          contextSubscription?.[COMPETENCIES]?.[WEB_PERMISSION] &&
            (rightPermission[COMPETENCIES][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[COMPETENCIES][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] &&
                userEmployee == selectedId))
        );
      }

      if (rightPermission && rightPermission[QUALIFICATION_APPRENTICESHIPS]) {
        setSkillsQualificationApprenticeshipsRead(
          contextSubscription?.[QUALIFICATION_APPRENTICESHIPS]?.[
            WEB_PERMISSION
          ] &&
            (rightPermission[QUALIFICATION_APPRENTICESHIPS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[QUALIFICATION_APPRENTICESHIPS][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] &&
                userEmployee == selectedId))
        );
        setSkillsQualificationApprenticeshipsUpdate(
          rightPermission[QUALIFICATION_APPRENTICESHIPS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[QUALIFICATION_APPRENTICESHIPS][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setSkillsQualificationApprenticeshipsCreate(
          rightPermission[QUALIFICATION_APPRENTICESHIPS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[QUALIFICATION_APPRENTICESHIPS][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
      }
    }
    values.skill = [];
    setValues({...values});
  }, [filterAdmin, filterSuperAdmin, rightPermission, contextSubscription]);

  useEffect(() => {
    if ((selectedId || skillDet) && !loadingChoices) {
      loadSkillsDetails();
    }
  }, [selectedId, loadingChoices]);

  const loadSkillsDetails = async () => {
    let id = selectedId ? selectedId : skillDet;
    if(!id){
      setModalLoading(false);
      console.error("Employee id does not exist ", id)
      return
    }
   try {
    
    const response = await getEmployeeSkillsDetails(id);
    // if (response) {
    //   setModalLoading(false);
    // }
    console.log(response,"response")
    if (response.results.length > 0) {
      response.results[0] &&
        response.results[0].competency &&
        response.results[0].competency.forEach((comp, index) => {
          if (comp.is_competent == true)
            response.results[0].competency[index].is_competent = 1;
          if (comp.is_competent == false)
            response.results[0].competency[index].is_competent = 2;
        });

      setValues({...values, ...response.results[0]});
      setModalLoading(false);
    }
   } catch (error) {
    setModalLoading(false);
   }
  };
  console.log(values, 'response hhhhhh');
  const addNewSkill = () => {
    const skillDefault = {
      category: null,
      name: null,
      training_type: null,
      employee: selectedId ? selectedId : empId,
      status: null,
      completed_date: null,
      expiration_date: null,
      reapplication_frequency: '',
    };

    let newSkills = [];
    if (values.skills) {
      newSkills = values.skills.concat([skillDefault]);
    } else if (!values) {
      newSkills = [skillDefault];
    }
    values.skills = newSkills;
    setValues({...values});
  };
  const addNewCompetency = () => {
    const competencyDefault = {
      competency_number: null,
      assessment_method: null,
      competency_name: null,
      employee: selectedId ? selectedId : empId,
      assessor_name: null,
      is_competent: null,
      competency_date: null,
      competency_rating: null,
      achivement_level: null,
      reapplication_frequency: null,
      renewal_date: null,
    };

    let newCompetencies = [];
    if (values.competency) {
      newCompetencies = values.competency.concat([competencyDefault]);
    } else {
      newCompetencies = [competencyDefault];
    }
    values.competency = newCompetencies;
    setValues({...values});
  };
  const addNewQualification = () => {
    const qualificationDefault = {
      qualification_level: null,
      qualification_type: null,
      employee: selectedId ? selectedId : empId,
      qualification_status: null,
      qualification_name: null,
      completed_date: null,
    };

    let newQualification = [];
    if (values.qualification) {
      newQualification = values.qualification.concat([qualificationDefault]);
    } else {
      newQualification = [qualificationDefault];
    }
    values.qualification = newQualification;
    setValues({...values});
  };
  const deleteSkill = index => {
    let newSkills = [...values.skills];
    newSkills.splice(index, 1);
    values.skills = newSkills;
    setValues({...values});
  };
  const deleteCompetency = index => {
    let newCompetencies = [...values.competency];
    newCompetencies.splice(index, 1);
    values.competency = newCompetencies;
    setValues({...values});
  };
  const deleteQualification = index => {
    let newQualification = [...values.qualification];
    newQualification.splice(index, 1);
    values.qualification = newQualification;
    setValues({...values});
  };
  const dropDownHandleSelect = item => {
    //recursive objects merge
    const result = _.merge(values, item);
    setValues({...result});
  };

  const setSkills = newData => {
    values.skills = newData;
    setValues({...values});
  };
  const setQualification = qualifications => {
    values.qualification = qualifications;
    setValues({...values});
  };
  const setCompetency = competencies => {
    values.competency = competencies;
    setValues({...values});
  };
  const handleSubmit = evt => {
    evt.preventDefault();
    if (loadingSave) {
      return;
    }

    let body = _.cloneDeep(values);

    let newSkillsData = [];

    if (body.skills) {
      body.skills.forEach(items => {
        if (
          items.name ||
          items.training_type ||
          items.status ||
          items.category ||
          items.completed_date ||
          items.expiration_date
        ) {
          newSkillsData.push({...items});
        }
      });
    }
    body.skills = newSkillsData;

    let newCompetencyData = [];
    if (body.competency) {
      body.competency.forEach(items => {
        if (
          items.achivement_level ||
          items.assessment_method ||
          items.assessor_name ||
          items.competency_date ||
          items.competency_name ||
          items.competency_number ||
          items.competency_rating ||
          items.is_competent ||
          items.reapplication_frequency ||
          items.renewal_date
        ) {
          newCompetencyData.push({...items});
        }
      });
    }
    body.competency = newCompetencyData;

    let newQualificationData = [];
    if (body.qualification) {
      body.qualification.forEach(items => {
        if (
          items.qualification_level ||
          items.qualification_type ||
          items.completed_date ||
          items.qualification_name ||
          items.qualification_status
        ) {
          newQualificationData.push({...items});
        }
      });
    }
    body.qualification = newQualificationData;
    if (!isValid && errors.length > 0) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);

      return;
    }

    if (body.competency) {
      body.competency &&
        body.competency.forEach((comp, index) => {
          if (comp.is_competent) {
            if (comp.is_competent == 1)
              body.competency[index].is_competent = true;
            else body.competency[index].is_competent = false;
          }
        });
    }

    setLoadingSave(true);
    setCheckRequires(false);
    setShowError(false);

    let value = [];

    let employeeId = '';
    if (location.pathname.includes('/team/add')) {
      employeeId = empId;
    } else {
      employeeId = selectedId;
    }

    setEmployeeSkills(body, employeeId)
      .then(response => {
        if (response.statusCode === 400) {
          console.log(response.message, '445877mmm');
          if (response.message) {
            value = response.message;
          } else {
            value = Object.keys(response)[0] + '-' + Object.values(response)[0];
          }
          throw value;
        }

        setShowError(false);
        onSuccess(response);
      })
      .catch(onError);
  };

  const onSuccess = async response => {
    if (response.statusCode === 200 || response.statusCode === 201) {
      console.log('api calling');
      let employeeId = '';
      if (location.pathname.includes('/team/add')) {
        employeeId = empId;
      } else {
        employeeId = selectedId;
      }

      setSkillDet(employeeId);
      setLoadingSave(false);
      setButtonIcon('');
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
    }
  };

  const onError = err => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(err);
  };
  const addSkillCopy = index => {
    let length = values.skills.length;
    const array1 = values.skills.slice(0, index + 1);
    const array2 = values.skills.slice(index + 1, length + 1);
    array1.push({id: ''});
    let arr1Length = array1.length;
    array1[arr1Length - 1] = _.cloneDeep(values.skills[index]);
    delete array1[arr1Length - 1].id;
    values.skills = array1.concat(array2);
    setValues({...values});
  };
  const addCompentencyCopy = index => {
    let length = values.competency.length;
    const array1 = values.competency.slice(0, index + 1);
    const array2 = values.competency.slice(index + 1, length + 1);
    array1.push({id: ''});
    let arr1Length = array1.length;
    array1[arr1Length - 1] = _.cloneDeep(values.competency[index]);
    delete array1[arr1Length - 1].id;
    values.competency = array1.concat(array2);
    setValues({...values});
  };
  const addQualificationCopy = index => {
    let length = values.qualification.length;
    const array1 = values.qualification.slice(0, index + 1);
    const array2 = values.qualification.slice(index + 1, length + 1);
    array1.push({id: ''});
    let arr1Length = array1.length;
    array1[arr1Length - 1] = _.cloneDeep(values.qualification[index]);
    delete array1[arr1Length - 1].id;
    values.qualification = array1.concat(array2);
    setValues({...values});
  };
  return (
    <>
      <ComponentDynamic loading={false}>
        {(selectedId ? readPermissionSkills : createPermissionSkills) && (
          <div className="mt-3">
            <FlexEqual>
              <div className="d-flex mb-2">
                <div className="w-100 me-2">
                  <DropdownSearchable
                    placeHolder={'Care Certificate'}
                    options={choices.care_certificate}
                    selectedOption={
                      values
                        ? values.care_certificate
                          ? values.care_certificate
                          : null
                        : null
                    }
                    onChange={value =>
                      dropDownHandleSelect({
                        care_certificate: value ? value : null,
                      })
                    }
                  />
                </div>
                <div className="w-100 me-2">
                  <DropdownSearchable
                    placeHolder={'Approved Mental Health Professional'}
                    options={choices.common_core_confirmation}
                    selectedOption={
                      values
                        ? values.approved_mental_health_professional
                          ? values.approved_mental_health_professional
                          : null
                        : null
                    }
                    onChange={value =>
                      dropDownHandleSelect({
                        approved_mental_health_professional: value
                          ? value
                          : null,
                      })
                    }
                  />
                </div>
              </div>
              <div className="d-flex mb-2">
                <div className="w-100 me-2">
                  <DropdownSearchable
                    placeHolder={'Social Care Qualification'}
                    options={choices.social_care_qualification}
                    selectedOption={
                      values
                        ? values.social_care_qualification
                          ? values.social_care_qualification
                          : null
                        : null
                    }
                    onChange={value =>
                      dropDownHandleSelect({
                        social_care_qualification: value ? value : null,
                      })
                    }
                  />
                </div>
                <div className="w-100 me-2">
                  <DropdownSearchable
                    placeHolder={'Accredited Training'}
                    options={choices.accredited_training}
                    selectedOption={
                      values
                        ? values.accredited_training
                          ? values.accredited_training
                          : null
                        : null
                    }
                    onChange={value =>
                      dropDownHandleSelect({
                        accredited_training: value ? value : null,
                      })
                    }
                  />
                </div>
              </div>
              {(selectedId
                ? skillsSkillsTrainingRead
                : skillsSkillsTrainingCreate) && (
                <div className="mt-4 mb-3">
                  <AddSkills
                    addSkillCopy={addSkillCopy}
                    employee_skills={values.skills}
                    skill_Category={choices.skill_category}
                    skill_name={choices.skill}
                    training_type={choices.training_type}
                    skill_status={choices.skill_status}
                    addNewSkillCallback={addNewSkill}
                    deleteSkillCallback={deleteSkill}
                    setSkillsCallback={setSkills}
                    readOnly={
                      selectedId
                        ? !skillsSkillsTrainingUpdate
                        : !skillsSkillsTrainingCreate
                    }
                    noSave
                  />
                </div>
              )}

              {(selectedId
                ? skillsCompetenciesRead
                : skillsCompetenciesCreate) && (
                <div className="mt-4 mb-3">
                  <AddCompetencies
                  addCompentencyCopy={addCompentencyCopy}
                    employee_competencies={values.competency}
                    addNewCompetenciesCallback={addNewCompetency}
                    deleteCompetencyCallback={deleteCompetency}
                    competency_name={choices.competency_name}
                    setCompetenciesCallback={setCompetency}
                    readOnly={
                      selectedId
                        ? !skillsCompetenciesUpdate
                        : !skillsCompetenciesCreate
                    }
                    noSave
                  />
                </div>
              )}
              {(selectedId
                ? skillsQualificationApprenticeshipsRead
                : skillsQualificationApprenticeshipsCreate) && (
                <div className="mt-4 mb-3">
                  <AddQualification
                  addQualificationCopy={addQualificationCopy}
                    employee_qualification={values.qualification}
                    addNewQualificationCallback={addNewQualification}
                    deleteQualificationCallback={deleteQualification}
                    setQualificationsCallback={setQualification}
                    readOnly={
                      selectedId
                        ? !skillsQualificationApprenticeshipsUpdate
                        : !skillsQualificationApprenticeshipsCreate
                    }
                    choices={choices}
                    noSave
                  />
                </div>
              )}
              <div
                className={
                  'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-3'
                }>
                <div className={'mb-3'}>
                  {showError ? (
                    <ErrorMessage>
                      <label className={'mt-2'}>{errorMessage}</label>
                    </ErrorMessage>
                  ) : (
                    <Fragment />
                  )}
                </div>

                <div
                  className={
                    'd-flex justify-content-center justify-content-md-end'
                  }>
                  <div className={'ms-0 ms-md-3 me-2'}>
                    <PrimaryButtonForm
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      className={'me-2'}
                      onClick={() => {
                        history.push('/team');
                      }}>
                      <span className={'me-2'}>{'Cancel'}</span>
                    </PrimaryButtonForm>

                    {(selectedId ? updatePermissionSkills : true) && (
                      <PrimaryButtonForm
                        bgcolor="PURPLE_GRAY_500"
                        minWidth="6rem"
                        onClick={handleSubmit}>
                        <span className={'me-2'}>{textStatus}</span>
                        {!loadingSave ? (
                          buttonIcon
                        ) : (
                          <span
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                          />
                        )}
                      </PrimaryButtonForm>
                    )}
                  </div>
                </div>
              </div>
            </FlexEqual>
          </div>
        )}
      </ComponentDynamic>
    </>
  );
};

export default Skills;
