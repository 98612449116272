import ModalDecision from 'components/SharedComponents/ModalDecision';
import React from 'react'
import { SMALL_COL, EX_VERY_LARGE_COL, LARGE_COL } from 'shared/styles/constants/columns';
import AddCareNote from './AddModal/AddCareNote';
import { HeaderVisits } from 'shared/styles/constants/tagsStyles';
import { useModal } from 'hooks/ModalHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFolderPlus } from 'react-icons/bs';
import { Fragment } from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useState } from 'react';
import { Col, ColsGrouper, IconWrapper, Row } from 'components/SharedComponents/Table/styles';
import { RiDeleteBinLine } from 'react-icons/ri';
import { MdEdit } from 'react-icons/md';
import Pagination from 'components/SharedComponents/Pagination';
import { useContext } from 'react';
import { AppContext } from 'context';
import { useEffect } from 'react';
import { employee_care_notes } from 'utils/choiceConstant';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { deleteEmployeeCareNote, getEmployeeCareNotes } from 'utils/api/EmployeeApi';
import Table from 'components/SharedComponents/Table';
import { NoDataFoundMessage, NoPermissionContainer } from 'components/DashboardComponents/Location/AddLocationModal/styles';
import { getPageToSearchParam } from 'shared/methods';
import { useForm } from 'hooks/FormHook';
import { useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Header } from '../styles';

export const headerColumns = [

    {
        label: 'Note Date',
        name: '',
        status: 0,
        className: 'grow-mobile',
        max_width: SMALL_COL,
    },
    // {
    //     label: 'Employee Name',
    //     name: '',
    //     status: 0,
    //     className: 'grow-mobile',
    //     max_width: SMALL_COL,
    // },
    {
        label: 'Care Notes',
        name: '',
        status: 0,
        className: 'grow-mobile sm-hidden md-hidden',
        max_width: EX_VERY_LARGE_COL,
    },
    {
        label: 'Privacy',
        name: 'Privacy',
        status: 0,
        className: 'grow-mobile sm-hidden md-hidden',
        max_width: LARGE_COL,
    },
    {
        label: 'Created At',
        name: '',
        status: 0,
        className: 'grow-mobile',
        max_width: LARGE_COL,
    },
];

function EmployeeCareNotes({
    selectedEmpId,
    props,
    rowData,
    readPermission,
    createPermission,
    deletePermission,
    updatePermission
}) {
    const history = useHistory()
    const toast = useRef(null)
    const editTitle = 'Edit Care Notes';
    const addtitle = 'Add Care Notes';
    const {
        setValue: setModalValue,
        value: modalValue,
        setTitle,
        fullBind,
    } = useModal(false);

    const {
        setValue: setModalDeleteValue,
        bind: bindModalDelete
    } = useModal(false);

    const { contextChoices, handleGetChoices } = useContext(AppContext);
    const { values, setValues, useInput, errors, setCheckRequires } = useForm({ limitperpage: PAGE_SIZE_RENDER });

    const [loadingModal, setLoadingModal] = useState(false);
    const [careNotes, setCareNotes] = useState([])
    const [pages, setPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [idSelected, setIdSelected] = useState('');


    useEffect(() => {
        handleGetChoices(employee_care_notes)
    }, [])

    const getCareNotes = async () => {
        const pageToSearch = getPageToSearchParam(props.location.search, setCurrentPage);
        try {
            const res = await getEmployeeCareNotes({ ...values, ...pageToSearch }, selectedEmpId)
            setCareNotes(res?.results)
            setLoadingModal(false)
            setPages(Math.ceil(res.count / values.limitperpage));
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        getCareNotes()
    }, [values.limitperpage, props.location.search])



    const handleNewCareNotes = () => {
        setModalValue(true)
        setTitle(addtitle)
        resetForm()
    }
    const handleRowClick = (row) => {
        setValues({ ...values, ...row })
        setModalValue(true)
        setTitle(editTitle)
    }
    const handleDeleteRow = (id) => {
        setModalDeleteValue(true)
        setIdSelected(id)
    }

    const dropDownHandleSelectPagination = (item, namesection) => {
        values.limitperpage = item[namesection]
        setValues({ ...values });
    }

    const displayName = (id, name) => {
        let findName = contextChoices && contextChoices[name] && contextChoices[name].find(item => item.id === id)?.name
        return findName || ''
    }
    const sliceString = string => {
        return string.length > 60 ? string.slice(0, 60) + '...' : string;
    }

    const formatDate = (date) => {
        return date && `${date.split("T")[0]}  ${date.split("T")[1].slice(0, 5)}`
    }

    const deleteCareNote = async () => {
        setModalDeleteValue(false)
        try {
            const res = await deleteEmployeeCareNote(idSelected,selectedEmpId)
            setIdSelected("")
            if (res && res[0] === "Data Deleted Successful") {
                getCareNotes()
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: `${res[0]}`,
                });
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: res.detail ? res.detail : `Something went wrong!`,
                });
            }

        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Something went wrong!`,
            });
        }
    }

    const resetForm = () => {
        values.care_notes_created_by = null
        values.notes_privacy = null
        values.care_notes = ""
        values.notes_date = ""
        values.id = ""
        setValues({ ...values })
    }

    return (
        <div>
            <Toast ref={toast} position="top-right" />
                {createPermission && (
                    <div className={'w-100 d-flex justify-content-center mb-3 mt-1'}>
                        <div className={'text-primary font-weight-bold'}>
                            <div>
                                <PrimaryButtonForm
                                    minWidth="6rem"
                                    onClick={handleNewCareNotes}>
                                    <BsFolderPlus size={18} />
                                    <span className={'ms-2'}>CARE NOTES</span>
                                </PrimaryButtonForm>
                            </div>
                        </div>
                    </div>
                )}
            <>
                {readPermission ? (
                    <ComponentDynamic loading={loadingModal} >
                        {careNotes?.length ? (
                            <>
                                <Table
                                    headerColumns={headerColumns}
                                // headerPadding
                                // noEditOption={deletePermisstion ? false : true}
                                // noEditOption
                                >
                                    {careNotes?.map((note, index) => {
                                        return (
                                            <div>
                                                <Row bgColor>
                                                    <ColsGrouper className='d-flex'>

                                                        <Col
                                                            max_width={SMALL_COL}
                                                            Center
                                                            className="sm-hidden"
                                                            Capitalize
                                                            overlap={true}                                           >
                                                            {note.notes_date}
                                                        </Col>
                                                        {/* <Col
                                                        max_width={SMALL_COL}
                                                        Center
                                                        overlap={true}>
                                                        {displayName(note.care_notes_created_by && note.care_notes_created_by, 'user_list')}
                                                    </Col> */}
                                                        <Col
                                                            max_width={EX_VERY_LARGE_COL}
                                                            Center
                                                            className="sm-hidden"
                                                            Capitalize
                                                            overlap={true}>
                                                            {note.care_notes && sliceString(note.care_notes)}
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            Center
                                                            className="sm-hidden"
                                                            Capitalize
                                                            overlap={true}>
                                                            {displayName(note.notes_privacy && note.notes_privacy, "alert_privacy")}
                                                        </Col>
                                                        <Col
                                                            max_width={LARGE_COL}
                                                            Center
                                                            className="sm-hidden"
                                                            Capitalize
                                                            overlap={true}>
                                                            {formatDate(note.created_at)}
                                                        </Col>

                                                        <Col
                                                            className="sm-hidden"
                                                            style={{ marginRight: "5px" }}
                                                            Center
                                                            Icon
                                                            Purple>
                                                            <IconWrapper
                                                                onClick={() => {
                                                                    handleRowClick(note);
                                                                }}>
                                                                <MdEdit />
                                                            </IconWrapper>
                                                        </Col>
                                                    </ColsGrouper>

                                                    {deletePermission && (
                                                        <Col
                                                            className="sm-hidden"
                                                            Center
                                                            Shrink
                                                            NoFlexGrow
                                                            style={{ marginRight: "5px" }}>
                                                            <IconWrapper
                                                                onClick={() => {
                                                                    handleDeleteRow(note?.id);
                                                                }}>
                                                                <RiDeleteBinLine />
                                                            </IconWrapper>
                                                        </Col>)}
                                                </Row>
                                                <div className="mb-1" />
                                            </div>
                                        );
                                    })}
                                </Table>
                                <div className="mt-3">
                                    <Pagination
                                        totalPages={pages}
                                        currentPage={currentPage}
                                        values={values}
                                        dropDownHandleSelect={dropDownHandleSelectPagination}
                                        number={PAGE_SIZE_RENDER}
                                        dataLength={careNotes.length}
                                    />
                                </div>
                            </>

                        ) : <div className='d-flex justify-content-center align-items-center font-weight-bold'
                                style={{
                                height: '30vh',
                                }}
                            >
                                No data found
                            </div>}

                    </ComponentDynamic>
                ) : (
                    <NoPermissionContainer>
                        You don't have permission to read the information.
                    </NoPermissionContainer>
                )
                }
            </>
            {modalValue && (
                <AddCareNote
                    idSelected={idSelected}
                    fullBind={fullBind}
                    setTitle={setTitle}
                    choices={contextChoices}
                    rowData={rowData}
                    values={values}
                    setValues={setValues}
                    useInput={useInput}
                    errors={errors}
                    setCheckRequires={setCheckRequires}
                    getCareNotes={getCareNotes}
                    setModalValue={setModalValue}
                    toast={toast}
                    updatePermisstion={updatePermission}
                    readOnly={values.id ? !updatePermission : !createPermission}
                    selectedEmpId={selectedEmpId}
                />
            )}
            <ModalDecision
                type="delete"
                title="Warning"
                body={'Are you sure you want to delete this Employee Care Note? \n DELETING the Employee Care Note will remove ALL records from database.'}
                onOk={deleteCareNote}
                onCancel={() => {
                    setIdSelected('');
                }}
                okTitle={'DELETE'}
                {...bindModalDelete}
            />
        </div>
    )
}

export default EmployeeCareNotes