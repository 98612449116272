import React, {useState, Fragment, useEffect} from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {
  TitleSection,
  FlexEqual,
  ErrorMessage,
  NoPermissionContainer,
} from './styles';
import {useForm} from 'hooks/FormHook';
import _, { values } from 'lodash';
import DateSelector from 'components/SharedComponents/DateSelector';
import EmployeeRateCard from 'components/DashboardComponents/Team/AddModal/EmployeeRateCard';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {KeyPress} from 'utils/constants/regularExpressions';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import {BsFillPlusSquareFill} from 'react-icons/bs';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import AvoidDetails from 'components/SharedComponents/FormSections/Team/AvoidDetails';
import {useHistory, useLocation} from 'react-router';
import {getPayRates} from 'utils/api/ClientApi';
import {
  EMPLOYEE_PREFERENCES,
  EMPLOYEMENT_DETAILS,
  PAY_RATE_CARD,
  PROBATION,
  RECRUITMENT,
  NOTICE_AND_TERMINATION,
  INCOMPATIBILITIES,
  WEB_CREATE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION,
} from '../../../../utils/constants/permissions';
import {
  setEmployeeJobDetails,
  getEmployeeJobDetails,
  setJobDetailsPayRate,
} from 'utils/api/EmployeeApi';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import CustomDropdowns from 'components/SharedComponents/DropdownSearchable/CustomDropdowns';
import { job_detail_emp_choices } from 'utils/choiceConstant';
import { useContext } from 'react';
import { AppContext } from 'context';
import JobDetailContracts from './JobDetailContracts';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import moment from 'moment';

const JobDetails = ({
  companyBranches,
  choices,
  clientList,
  reportsTo,
  successCallback,
  createPermissionJoblDetails,
  updatePermissionJoblDetails,
  readPermissionJoblDetails,
  filterAdmin,
  filterSuperAdmin,
  contextSubscription,
  rightPermission,
  empId,
  selectedId,
  userEmployee,
  setJobDet,
  jobDet,
}) => {
  const toast = useRef();

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [employee_avoid_client, setAvoidClients] = useState([]);
  const [initialAvoidList, setInitialAvoidList] = useState([]);
  const [avoidClientsToAdd, setAvoidClientsToAdd] = useState([]);
  const [avoidedSelected, setAvoidedSelected] = useState([]);
  const [avoidClientsToDelete, setAvoidClientsToDelete] = useState([]);
  const [preferredSelected, setPreferredSelected] = useState([]);
  const [initialPreferredList, setInitialPreferredList] = useState([]);
  const [preferredClientsToAdd, setPreferredClientsToAdd] = useState([]);
  const [preferredClientsToDelete, setPreferredClientsToDelete] = useState([]);
  const [preferredClients, setPreferredClients] = useState([]);
  const [modalLoading, setModalLoading] = useState(true);
  const [billRate, setBillRate] = useState([]);
  const [payRate, setPayRate] = useState([]);
  const [travelRate, setTravelRate] = useState([]);
  const [
    jobDetailsEmployeePreferencesCreate,
    setJobDetailsEmployeePreferencesCreate,
  ] = useState(false);
  const [
    jobDetailsEmployeePreferencesUpdate,
    setJobDetailsEmployeePreferencesUpdate,
  ] = useState(false);
  const [
    jobDetailsEmployeePreferencesRead,
    setJobDetailsEmployeePreferencesRead,
  ] = useState(false);
  const [
    jobDetailsEmploymentDetailsCreate,
    setJobDetailsEmploymentDetailsCreate,
  ] = useState(false);
  const [
    jobDetailsEmploymentDetailsUpdate,
    setJobDetailsEmploymentDetailsUpdate,
  ] = useState(false);
  const [jobDetailsEmploymentDetailsRead, setJobDetailsEmploymentDetailsRead] =
    useState(false);
  const [jobDetailsRateCardCreate, setJobDetailsRateCardCreate] =
    useState(false);
  const [jobDetailsRateCardUpdate, setJobDetailsRateCardUpdate] =
    useState(false);
  const [jobDetailsRateCardRead, setJobDetailsRateCardRead] = useState(false);
  const [jobDetailsProbationRead, setJobDetailsProbationRead] = useState(false);
  const [jobDetailsProbationCreate, setJobDetailsProbationCreate] =
    useState(false);
  const [jobDetailsProbationUpdate, setJobDetailsProbationUpdate] =
    useState(false);
  const [jobDetailsRecruitmentRead, setJobDetailsRecruitmentRead] =
    useState(false);
  const [jobDetailsRecruitmentCreate, setJobDetailsRecruitmentCreate] =
    useState(false);
  const [jobDetailsRecruitmentUpdate, setJobDetailsRecruitmentUpdate] =
    useState(false);
  const [
    jobDetailsNoticeAndTerminationRead,
    setJobDetailsNoticeAndTerminationRead,
  ] = useState(false);
  const [
    jobDetailsNoticeAndTerminationCreate,
    setJobDetailsNoticeAndTerminationCreate,
  ] = useState(false);
  const [
    jobDetailsNoticeAndTerminationUpdate,
    setJobDetailsNoticeAndTerminationUpdate,
  ] = useState(false);
  const [jobDetailsIncompatibilitiesRead, setJobDetailsIncompatibilitiesRead] =
    useState(false);
  const [
    jobDetailsIncompatibilitiesCreate,
    setJobDetailsIncompatibilitiesCreate,
  ] = useState(false);
  const [
    jobDetailsIncompatibilitiesUpdate,
    setJobDetailsIncompatibilitiesUpdate,
  ] = useState(false);
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setFormErrors,
    setCheckRequires,
  } = useForm({});
  const history = useHistory();
  const location = useLocation();
  const {handleGetChoices,handleChangeChoices} = useContext(AppContext)
  useEffect(()=>{
    handleGetChoices(job_detail_emp_choices)
  },[])
  useEffect(() => {
    if (filterSuperAdmin) {
      setJobDetailsEmployeePreferencesCreate(true);
      setJobDetailsEmployeePreferencesUpdate(true);
      setJobDetailsEmployeePreferencesRead(true);
      setJobDetailsEmploymentDetailsCreate(true);
      setJobDetailsEmploymentDetailsUpdate(true);
      setJobDetailsEmploymentDetailsRead(true);
      setJobDetailsRateCardCreate(true);
      setJobDetailsRateCardUpdate(true);
      setJobDetailsRateCardRead(true);
      setJobDetailsProbationRead(true);
      setJobDetailsProbationUpdate(true);
      setJobDetailsProbationCreate(true);
      setJobDetailsRecruitmentRead(true);
      setJobDetailsRecruitmentCreate(true);
      setJobDetailsRecruitmentUpdate(true);
      setJobDetailsNoticeAndTerminationRead(true);
      setJobDetailsNoticeAndTerminationUpdate(true);
      setJobDetailsNoticeAndTerminationCreate(true);
      setJobDetailsIncompatibilitiesRead(true);
      setJobDetailsIncompatibilitiesUpdate(true);
      setJobDetailsIncompatibilitiesCreate(true);
    } else if (filterAdmin) {
      setJobDetailsEmployeePreferencesCreate(true);
      setJobDetailsEmployeePreferencesUpdate(true);
      setJobDetailsEmployeePreferencesRead(
        contextSubscription?.[EMPLOYEE_PREFERENCES]?.[WEB_PERMISSION]
      );
      setJobDetailsEmploymentDetailsCreate(true);
      setJobDetailsEmploymentDetailsUpdate(true);
      setJobDetailsEmploymentDetailsRead(
        contextSubscription?.[EMPLOYEMENT_DETAILS]?.[WEB_PERMISSION]
      );
      setJobDetailsRateCardCreate(true);
      setJobDetailsRateCardUpdate(true);
      setJobDetailsRateCardRead(
        contextSubscription?.[PAY_RATE_CARD]?.[WEB_PERMISSION]
      );
      setJobDetailsProbationRead(
        contextSubscription?.[PROBATION]?.[WEB_PERMISSION]
      );
      setJobDetailsProbationUpdate(true);
      setJobDetailsProbationCreate(true);
      setJobDetailsRecruitmentRead(
        contextSubscription?.[RECRUITMENT]?.[WEB_PERMISSION]
      );
      setJobDetailsRecruitmentCreate(true);
      setJobDetailsRecruitmentUpdate(true);
      setJobDetailsNoticeAndTerminationRead(
        contextSubscription?.[NOTICE_AND_TERMINATION]?.[WEB_PERMISSION]
      );
      setJobDetailsNoticeAndTerminationUpdate(true);
      setJobDetailsNoticeAndTerminationCreate(true);
      setJobDetailsIncompatibilitiesRead(
        contextSubscription?.[INCOMPATIBILITIES]?.[WEB_PERMISSION]
      );
      setJobDetailsIncompatibilitiesUpdate(true);
      setJobDetailsIncompatibilitiesCreate(true);
    } else {
      if (rightPermission[EMPLOYEE_PREFERENCES] !== undefined) {
        setJobDetailsEmployeePreferencesCreate(
          rightPermission[EMPLOYEE_PREFERENCES][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[EMPLOYEE_PREFERENCES][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setJobDetailsEmployeePreferencesUpdate(
          rightPermission[EMPLOYEE_PREFERENCES][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[EMPLOYEE_PREFERENCES][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setJobDetailsEmployeePreferencesRead(
          contextSubscription?.[EMPLOYEE_PREFERENCES]?.[WEB_PERMISSION] &&
            (rightPermission[EMPLOYEE_PREFERENCES][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[EMPLOYEE_PREFERENCES][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] &&
                userEmployee == selectedId))
        );
      }

      if (rightPermission[EMPLOYEMENT_DETAILS] !== undefined) {
        setJobDetailsEmploymentDetailsCreate(
          rightPermission[EMPLOYEMENT_DETAILS][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[EMPLOYEMENT_DETAILS][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setJobDetailsEmploymentDetailsUpdate(
          rightPermission[EMPLOYEMENT_DETAILS][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[EMPLOYEMENT_DETAILS][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setJobDetailsEmploymentDetailsRead(
          contextSubscription?.[EMPLOYEMENT_DETAILS]?.[WEB_PERMISSION] &&
            (rightPermission[EMPLOYEMENT_DETAILS][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[EMPLOYEMENT_DETAILS][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] &&
                userEmployee == selectedId))
        );
      }
      if (rightPermission[PAY_RATE_CARD] !== undefined) {
        setJobDetailsRateCardCreate(
          rightPermission[PAY_RATE_CARD][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[PAY_RATE_CARD][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setJobDetailsRateCardUpdate(
          rightPermission[PAY_RATE_CARD][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[PAY_RATE_CARD][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setJobDetailsRateCardRead(
          contextSubscription?.[PAY_RATE_CARD]?.[WEB_PERMISSION] &&
            (rightPermission[PAY_RATE_CARD][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[PAY_RATE_CARD][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] &&
                userEmployee == selectedId))
        );
      }

      if (rightPermission[PROBATION] !== undefined) {
        setJobDetailsProbationRead(
          contextSubscription?.[PROBATION]?.[WEB_PERMISSION] &&
            (rightPermission[PROBATION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
              (rightPermission[PROBATION][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] &&
                userEmployee == selectedId))
        );
        setJobDetailsProbationUpdate(
          rightPermission[PROBATION][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
            (rightPermission[PROBATION][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setJobDetailsProbationCreate(
          rightPermission[PROBATION][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
            (rightPermission[PROBATION][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
      }
      if (rightPermission[RECRUITMENT]) {
        setJobDetailsRecruitmentRead(
          contextSubscription?.[RECRUITMENT]?.[WEB_PERMISSION] &&
            (rightPermission[RECRUITMENT][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[RECRUITMENT][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] &&
                userEmployee == selectedId))
        );
        setJobDetailsRecruitmentCreate(
          rightPermission[RECRUITMENT][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
            (rightPermission[RECRUITMENT][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setJobDetailsRecruitmentUpdate(
          rightPermission[RECRUITMENT][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
            (rightPermission[RECRUITMENT][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
      }
      if (rightPermission[NOTICE_AND_TERMINATION] !== undefined) {
        setJobDetailsNoticeAndTerminationRead(
          contextSubscription?.[NOTICE_AND_TERMINATION]?.[WEB_PERMISSION] &&
            (rightPermission[NOTICE_AND_TERMINATION][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[NOTICE_AND_TERMINATION][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] &&
                userEmployee == selectedId))
        );
        setJobDetailsNoticeAndTerminationUpdate(
          rightPermission[NOTICE_AND_TERMINATION][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[NOTICE_AND_TERMINATION][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setJobDetailsNoticeAndTerminationCreate(
          rightPermission[NOTICE_AND_TERMINATION][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[NOTICE_AND_TERMINATION][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
      }
      if (rightPermission[INCOMPATIBILITIES] !== undefined) {
        setJobDetailsIncompatibilitiesRead(
          contextSubscription?.[INCOMPATIBILITIES]?.[WEB_PERMISSION] &&
            (rightPermission[INCOMPATIBILITIES][WEB_PERMISSION][
              WEB_READ_PERMISSION
            ] ||
              (rightPermission[INCOMPATIBILITIES][WEB_PERMISSION][
                SELF_WEB_READ_PERMISSION
              ] &&
                userEmployee == selectedId))
        );
        setJobDetailsIncompatibilitiesUpdate(
          rightPermission[INCOMPATIBILITIES][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] ||
            (rightPermission[INCOMPATIBILITIES][WEB_PERMISSION][
              SELF_WEB_UPDATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
        setJobDetailsIncompatibilitiesCreate(
          rightPermission[INCOMPATIBILITIES][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] ||
            (rightPermission[INCOMPATIBILITIES][WEB_PERMISSION][
              SELF_WEB_CREATE_PERMISSION
            ] &&
              userEmployee == selectedId)
        );
      }
    }
  }, [filterAdmin, filterSuperAdmin, rightPermission, contextSubscription]);

  useEffect(() => {
    getPayRates().then(res => {
      setBillRate(
        res.filter(data => data.rate_card_type == 'Bill Rates') || []
      );
      setPayRate(res.filter(data => data.rate_card_type == 'Pay Rates') || []);
    });
  }, []);
  useEffect(() => {
    if (selectedId || jobDet) {
      loadJobDetails();
    } else if (empId) {
      setModalLoading(false);
    }
  }, [payRate, selectedId, jobDet]);
  useEffect(() => {
    getPayRates().then(res => {
      setTravelRate(
        res.filter(data => data.rate_card_type == 'Travel Rates') || []
      );
    });
  }, []);
  useEffect(() => {
    if (!values.employee_avoid_client) {
      values.employee_avoid_client = [];
      setValues({...values});
    }
    if (!values.employee_avoid_employee) {
      values.employee_avoid_employee = [];
      setValues({...values});
    }

    if (!values.employee_avoid_postal_area) {
      values.employee_avoid_postal_area = [];
      setValues({...values});
    }

    if (!values.employee_avoid_animal) {
      values.employee_avoid_animal = [];
      setValues({...values});
    }
  }, []);



  const loadJobDetails = async () => {
    let id = selectedId ? selectedId : jobDet;
    const response = await getEmployeeJobDetails(id);

    if (response.results.length > 0) {
      response.results[0].run_routes =
        response.results[0].employee_run_routes &&
        response.results[0].employee_run_routes.map(route => {
          return {
            value: route.run_routes,
            label: route.name,
          };
        });
      response.results[0].employee_preferred_run_name =
        response.results[0].employee_preferred_run_name &&
        response.results[0].employee_preferred_run_name.map(run => {
          return {
            value: run.run_name,
            label: run.name,
          };
        });
        response.results[0].access_branch =
        response.results[0].access_branch &&
        response.results[0].access_branch.map(run => {
          return {
            value: run.branch_id,
            label: run.branch_name,
          };
        });
      response.results[0].employee_preferred_postal_area =
        response.results[0].employee_preferred_postal_area &&
        response.results[0].employee_preferred_postal_area.map(item => {
          return {
            row_id: item.id,
            value: item.area,
            label: item.area,
            id:item.area,
          };
        });
      response.results[0].employee_avoid_run_name =
        response.results[0].employee_avoid_run_name &&
        response.results[0].employee_avoid_run_name.map(run => {
          return {
            value: run.run_name,
            label: run.name,
          };
        });
      response.results[0].employee_prefered_client =
        response.results[0].employee_prefered_client &&
        response.results[0].employee_prefered_client.map(emp_Client => {
          return {
            value: emp_Client.client,
            label: emp_Client.name,
          };
        });

      response.results[0].employee_prefered_religion =
        response.results[0].employee_prefered_religion &&
        response.results[0].employee_prefered_religion.map(rel => {
          return {
            value: rel.preferred_religion,
            label: rel.name,
          };
        });

      if (
        response.results[0] &&
        response.results[0].employee_job_rate_card &&
        response.results[0].employee_job_rate_card.length < 1
      ) {
        response.results[0].job_detail_rate_card = [
          ...response.results[0].employee_job_rate_card,
        ];
        delete response.results[0].employee_job_rate_card;
      } else if (
        response.results[0] &&
        response.results[0].employee_job_rate_card &&
        response.results[0].employee_job_rate_card.length >= 1 &&
        payRate.length > 0
      ) {
        response.results[0].job_detail_rate_card = [
          ...response.results[0].employee_job_rate_card,
        ];
        delete response.results[0].employee_job_rate_card;
        response.results[0].job_detail_rate_card =
          response.results[0].job_detail_rate_card.map(data => {
          let matchRateCard = payRate && payRate.find(rateid => rateid.id == data?.rate_card)
            return {
              id: data.id,
              rate_card: data.rate_card,
              rate_per_hour: matchRateCard ? matchRateCard?.rate_per_hour : null,
              over_time_rate: matchRateCard ? matchRateCard?.over_time_rate :null,
              effective_date_from: data.effective_date_from,
              effective_date_to: data.effective_date_to,
              employee: data.employee,
              job_title: data.job_title,
            };
          });
      }
      if (response.results[0]?.subordinate_employees) {
        values.source_employee = response.results[0]?.subordinate_employees?.source_employee_id && response.results[0]?.subordinate_employees?.source_employee_id
        values.reports_to_employee =
          response.results[0]?.subordinate_employees?.reports_to_employee?.length &&
          response.results[0]?.subordinate_employees?.reports_to_employee?.map(emp => {
            let name = reportsTo?.find(item => item?.id === emp?.employee_id)
            return {
              existId: emp.id,
              value: emp.employee_id,
              id: emp.employee_id,
              label: name && name?.name,
            };
          });
      }

      setValues({...values, ...response.results[0]});
    }

    setModalLoading(false);
  };

  const avoidHandleSelect = avoided => {
    setAvoidedSelected(avoided.avoid_clients);
  };
  const preferredHandleSelect = preferred => {
    if (preferred.preferred_run) {
      values.employee_preferred_run_name = preferred.preferred_run;
      setValues({...values});
    }
    if (preferred.avoid_run) {
      values.employee_avoid_run_name = preferred.avoid_run;
      setValues({...values});
    }
    if (preferred.client) {
      values.employee_prefered_client = preferred.client;
      setValues({...values});
    }
    if (preferred.religion) {
      values.employee_prefered_religion = preferred.religion;
      setValues({...values});
    }
  };
  const deleteRate = (nameSection, index) => {
    let fill = errors.filter(el => !el.includes(nameSection))
    setFormErrors(fill)
    values[nameSection].splice(index, 1);
    setValues({...values});
  };
  const addNewRate = nameSection => {
    if (!values[nameSection]) {
      values[nameSection] = [{}];
      setValues({...values});
    } else {
      values[nameSection] = [...values[nameSection], {}];
      setValues({...values});
    }
  };

  const dropDownHandleRate = (item, nameSection, index, list_key) => {
    values[nameSection][index][list_key] = item.rate;
    if (list_key == 'rate_card') {
      let rateCard = payRate.find(rateid => rateid.id == item.rate)
      values[nameSection][index].over_time_rate =
        rateCard && rateCard.over_time_rate;
      values[nameSection][index].rate_per_hour =
        rateCard && rateCard.rate_per_hour;
    }
    setValues({ ...values });
  };

  const dropDownHandleSelect = (item, nameItem) => {
    //recursive objects merge
    if (nameItem === 'job_role') {
      values.registered_nurse = null;
    }
    const result = _.merge(values, item);
    setValues({...result});
  };
  const dropDownHandleRunRoute = item => {
    if(item.access_branch){
      values.access_branch = item.access_branch
    setValues({ ...values })
    }else{
    values.run_routes = item.run_route;
    values.reports_to_employee = item.reports_to_employee
    setValues({...values});
    }
  };

  console.log(values,"values")
  const handleSubmit = (evt, triggerSave = true) => {
    evt.preventDefault();
    if (loadingSave) {
      return;
    }
  //  let newError =  errors.filter(el => !el.includes('employee_avoid_postal_area'))
  //  console.log(newError , "error")
    if (errors.length > 0) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }
    if(new Date(values.probation_end_date) >= new Date(values.probation_start_date)){

    let body = {
      ...values,
    };

    //Employment details
    if (body) {
      if (body.is_contracted === undefined) {
        body.is_contracted = false;
      }
      if (body.run_routes) {
        body.run_routes_id = body.run_routes.map(item => item.value);
        // delete body.job_detail.run_routes
      }
      if(body.employee_avoid_postal_area){
        body.employee_avoid_postal_area = body.employee_avoid_postal_area.filter(el => Object.values(el)?.length)
      }

      if (body.job_detail_rate_card) {
        delete body.job_detail_rate_card
        // body.job_detail_rate_card = body.job_detail_rate_card.map(ratedata => {
        //   delete ratedata.over_time_rate;
        //   delete ratedata.rate_per_hour;
        //   return {
        //     ...ratedata,
        //     effective_date_from: ratedata.effective_date_from,
        //     effective_date_to: ratedata.effective_date_to,
        //     job_title: ratedata.job_title,
        //     rate_card: ratedata.rate_card,
        //   };
        // });
      }
    

      if (body.employee_preferred_postal_area) {
        function containsNumbers(str) {
          return /\d/.test(str);
        }
        body.employeePreferredPostalArea = body.employee_preferred_postal_area.map(item => {
         
            return {
              area: item.label,
              ...(item.row_id && { id: item.row_id })
            };
          
        });
      }

      if(body.access_branch){
        body.access_branch = body.access_branch.map(
          item => {
            return item.value;
          }
        );
      }

      if (body.employee_prefered_client) {
        body.employee_prefered_client = body.employee_prefered_client.map(
          item => {
            return {client: item.value};
          }
        );
      }
      if (body.employee_preferred_run_name) {
        body.employee_preferred_run_name = body.employee_preferred_run_name.map(
          item => {
            return {run_name: item.value};
          }
        );
      }
      if (body.employee_avoid_run_name) {
        body.employee_avoid_run_name = body.employee_avoid_run_name.map(
          item => {
            return {run_name: item.value};
          }
        );
      }
      if (body.employee_prefered_religion) {
        body.employee_prefered_religion = body.employee_prefered_religion.map(
          item => {
            return {preferred_religion: item.value};
          }
        );
      }
      if (body.contracted_no_hours == '') {
        body.contracted_no_hours = null;
      }
      if (body.max_before_overtime == '') {
        body.max_before_overtime = null;
      }
      if (body.max_hour_per_week == '') {
        body.max_before_overtime = null;
      }
      if (body.max_working_hours_per_shift == '') {
        body.max_working_hours_per_shift = null;
      }
      if (body.max_working_hours_per_day == '') {
        body.max_working_hours_per_day = null;
      }
      if (body.max_working_hours_per_week == '') {
        body.max_working_hours_per_week = null;
      }
      if (body.max_working_hours_per_month == '') {
        body.max_working_hours_per_month = null;
      }
      if(!body.no_of_desired_avg_days){
        body.no_of_desired_avg_days = null;
      }
      if(!body.absence_calendar_year){
        body.absence_calendar_year = null;
      }
      if(body?.reports_to_employee){
        body.source_employee = body.source_employee ? body.source_employee : selectedId;
        body.reports_to_employee = body.reports_to_employee.map(emp => {
          if(emp.existId){
              return {
                 id:  emp.existId,
                 employee:emp.value
               }
           }else{
             return  {
                   employee:emp.value
               }
          }
        })
      }
    
    }
   
    body?.subordinate_employees && delete body.subordinate_employees
    body?.start_date && delete body.start_date
    body?.end_date && delete body.end_date
    body.employee_preferred_postal_area && delete body.employee_preferred_postal_area
if (triggerSave) {
      setLoadingSave(true);
    }
    setCheckRequires(false);
    setShowError(false);
    let value = [];
    let employeeId = '';
    if (location.pathname.includes('/team/add')) {
      employeeId = empId;
    } else {
      employeeId = selectedId;
    }
    setEmployeeJobDetails(body, employeeId)
      .then(response => {
        if (response.statusCode === 400) {
          // console.log( response.message,"445877")
          if (response.message) {
            value = response.message;
          } else {
            value = Object.keys(response)[0] + '-' + Object.values(response)[0];
          }
          throw value;
        }

        setShowError(false);
        onSuccess(response);
      })
      .catch(onError);
    }
    else
    {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Probation End Date should be greater than or equal to Probation Start Date!`,
      });
      setButtonIcon('');
      setButtonIcon(CROSS);
      setTextStatus(ERROR);
}
  };

// const setJobDetailsPayRateCard = async() => {
//   const body = {
//     job_detail_rate_card: values?.job_detail_rate_card && values?.job_detail_rate_card?.map(ratedata => {
//       delete ratedata.over_time_rate;
//       delete ratedata.rate_per_hour;
//       return {
//         ...ratedata,
//         effective_date_from: ratedata.effective_date_from,
//         effective_date_to: ratedata.effective_date_to,
//         job_title: ratedata.job_title,
//         rate_card: ratedata.rate_card,
//       };
//     }),
//     employee_id: selectedId ? selectedId : jobDet
//   }

//   try {
//     const res = await setJobDetailsPayRate(body)
//     return res
    
//   } catch (error) {
    
//   }
// }
  const onSuccess = async response => {
    if (response.statusCode === 200 || response.statusCode === 201) {
    let message = ""
    //  const res = await setJobDetailsPayRateCard()
    // if(res?.statusCode && res.statusCode === 200){
    //   message = `Job details and ${res.message ? res.message : ""} updated!`
    //  }
      let employeeId = '';
      if (location.pathname.includes('/team/add')) {
        employeeId = empId;
      } else {
        employeeId = selectedId;
      }

      let jobDetailId = response.id;
      // if employee id

      setValues({
        ...values,
        id: jobDetailId,
      });

      successCallback();
      setJobDet(employeeId);
      setLoadingSave(false);
      setButtonIcon('');
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: message,
      });
      handleChangeChoices("postal_codes")
    }
  };

  const onError = err => { 
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(typeof err === "string" ? err : err.message);
  };

  const addNewAvoid = nameSection => {
    values[nameSection] = [...values[nameSection], {}];
    setValues({...values});
  };

  const deleteAvoid = (nameSection, index) => {
    let fill = errors.filter(el => !el.includes(nameSection))
    setFormErrors(fill)
    values[nameSection].splice(index, 1);
    setValues({ ...values });
  };

  const dropDownHandleAvoid = (item, nameSection, index, list_key) => {
    values[nameSection][index][list_key] = item.avoid;
    setValues({...values});
  };

  const start_date_hook = useInput('start_date');
  const end_date_hook = useInput('end_date');
  const job_code_hook = useInput('job_code');
  const passcode_hook = useInput('passcode', 'isInt');
  const is_contracted_hook = useInput('is_contracted');
  const contracted_effective_date_hook = useInput('contracted_effective_date');
  const contracted_end_date_hook = useInput('contracted_end_date');
  const contracted_no_hours_hook = useInput('contracted_no_hours', 'isDecimal');
  const max_before_overtime_hook = useInput('max_before_overtime', 'isDecimal');
  const max_hour_per_week_hook = useInput('max_hour_per_week','isRequired','isDecimal',);
  const no_of_desired_avg_days_hook = useInput("no_of_desired_avg_days",'isRequired')
  const absence_calendar_year_hook = useInput(`absence_calendar_year`,'isRequired')
  const max_working_hours_per_shift_hook = useInput(
    'max_working_hours_per_shift',
    'isDecimal'
  );
  const max_working_hours_per_day_hook = useInput(
    'max_working_hours_per_day',
    'isDecimal'
  );
  const max_working_hours_per_week_hook = useInput(
    'max_working_hours_per_week',
    'isDecimal'
  );
  const max_working_hours_per_month_hook = useInput(
    'max_working_hours_per_month',
    'isDecimal'
  );
  const probation_end_date_hook = useInput('probation_end_date');
  const recruitment_interview_1_date_hook = useInput(
    'recruitment_interview_1_date'
  );
  const recruitment_interview_2_date_hook = useInput(
    'recruitment_interview_2_date'
  );
  const recruitment_interview_3_date_hook = useInput(
    'recruitment_interview_3_date'
  );
  const max_distance_hook = useInput('preference.max_distance', 'isInt');
  const smoking_hook = useInput('preference.smoking');
  const probation_start_date_hook = useInput('probation_start_date');
  const no_of_days_week_hook = useInput(`no_of_days_a_week`)

  const HandleMultiSelect = (item, diffKeyname) => {
    console.log(item,"item")
    setValues({...values, ...item});
  };

  console.log(values.employee_preferred_postal_area,errors,"yesss")
  return (
    <>
      <Toast ref={toast} position="top-right" />
      <ComponentDynamic loading={modalLoading}>
        {(
          selectedId ? readPermissionJoblDetails : createPermissionJoblDetails
        ) ? (
          <>
            {(selectedId
              ? jobDetailsEmploymentDetailsRead
              : jobDetailsEmploymentDetailsCreate) && (
              <>
                <div className="mt-3">
                  <FlexEqual>
                    <TitleSection style={{marginTop: '20px'}}>
                      Employment details
                    </TitleSection>
                    <div className="d-flex flex-column mt-2">
                      <div className="d-flex mb-2">
                        {/* <div className="w-100 me-2">
                          <DropdownSearchable
                            placeHolder={'Branch Name'}
                            options={companyBranches}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOption={
                              values
                                ? values.branch
                                  ? values.branch
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect(
                                {branch: value ? value : null},
                                'branch'
                              )
                            }
                          />
                        </div>
                        <div className="w-100">
                          <DropdownSearchable
                          isMulti={true}
                            placeHolder={'Branch Access'}
                            options={companyBranches}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOptionsProps={
                              values
                                ? values.access_branch
                                  ? values.access_branch
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleRunRoute(
                                {access_branch: value ? value : null},
                                'access_branch'
                              )
                            }
                          />
                        </div> */}
                        <div className="w-100">
                          <DropdownSearchable
                            placeHolder={'Job Department'}
                            options={choices.job_category}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOption={
                              values
                                ? values.job_category
                                  ? values.job_category
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect(
                                {job_category: value ? value : null},
                                'job_category'
                              )
                            }
                          />
                        </div>
                      </div>
                      {/* <div className="d-flex mb-2">
                        <div className="w-100 me-2">
                          <DateSelector
                            labelTop="Date Hired"
                            {...start_date_hook}
                          />
                        </div>
                        <div className="w-100">
                          <DateSelector
                            labelTop="Employment end date"
                            {...end_date_hook}
                          />
                        </div>
                      </div> */}
                      <div className="d-md-flex">
                        <div className="w-100 mb-md-0 mb-lg-0 mb-2 me-2">
                          <DropdownSearchable
                            placeHolder={'Job Level'}
                            options={choices.job_level}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOption={
                              values
                                ? values.job_level
                                  ? values.job_level
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect(
                                {job_level: value ? value : null},
                                'job_level'
                              )
                            }
                          />
                        </div>
                        <div className="w-100 mb-md-0 mb-lg-0 mb-2 me-2">
                          <InputLabelTop
                            type="text"
                            id="jd_job_code"
                            readOnly={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            label={'Job Code'}
                            {...job_code_hook}
                            maxlength={25}
                          />
                        </div>
                        <div className="w-100 mb-md-0 mb-lg-0 mb-2">
                          <DropdownSearchable
                            placeHolder={'Job Title'}
                            options={choices.job_title}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOption={
                              values
                                ? values.job_title
                                  ? values.job_title
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect(
                                {job_title: value ? value : null},
                                'job_level'
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="d-md-flex mb-2">
                        <div className="w-100 mb-md-0 mb-lg-0 mb-2 me-2">
                          <DropdownSearchable
                            placeHolder={'Job Role-Description'}
                            options={choices.job_role}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOption={
                              values
                                ? values.job_role
                                  ? values.job_role
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect(
                                {job_role: value ? value : null},
                                'job_role'
                              )
                            }
                          />
                        </div>
                        <div className="w-100 mb-md-0 mb-lg-0 mb-2 me-2">
                          <DropdownSearchable
                            // disabled={
                            //   values.job_role !==
                            //   '0caacac8-0193-47fa-8b42-7e76a7381c1f'
                            // }
                            placeHolder={'If Registered Nurse Is Selected'}
                            options={choices.nurse_type}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOption={
                              values
                                ? values.registered_nurse
                                  ? values.registered_nurse
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect(
                                {registered_nurse: value ? value : null},
                                'registered_nurse'
                              )
                            }
                          />
                        </div>
                        <div className="w-100 mb-md-0 mb-lg-0 mb-2">
                          <DropdownSearchable
                            placeHolder={'Job Role-Specialist Area'}
                            options={choices.job_role_specialist_area}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOption={
                              values
                                ? values.job_role_specialist_area
                                  ? values.job_role_specialist_area
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect(
                                {
                                  job_role_specialist_area: value
                                    ? value
                                    : null,
                                },
                                'job_role_specialist_area'
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="w-100 me-2">
                          <DropdownSearchable
                            placeHolder={'Employee Category'}
                            options={choices.employee_category}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOption={
                              values
                                ? values.employee_category
                                  ? values.employee_category
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect(
                                {employee_category: value ? value : null},
                                'employee_category'
                              )
                            }
                          />
                        </div>
                        <div className="w-100">
                          <DropdownSearchable
                            placeHolder={'Employment Type'}
                            options={choices.employment_type}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOption={
                              values
                                ? values.employment_type
                                  ? values.employment_type
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect(
                                {employment_type: value ? value : null},
                                'employment_type'
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="w-100 me-2">
                          <DropdownSearchable
                            placeHolder={'Skill Banding'}
                            options={choices.skill_banding}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOption={
                              values
                                ? values.skill_banding
                                  ? values.skill_banding
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect(
                                {skill_banding: value ? value : null},
                                'skill_banding'
                              )
                            }
                          />
                        </div>
                        <div className="w-100">
                          <DropdownSearchable
                            placeHolder={'Reports To'}
                            options={reportsTo}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOption={
                              values
                                ? values.report_to
                                  ? values.report_to
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect(
                                {report_to: value ? value : null},
                                'report_to'
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="d-flex">
                        <div className="w-100 me-2">
                          <DropdownSearchable
                            placeHolder={'Health & Safety Role'}
                            options={choices.health_safety_role}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            selectedOption={values.health_safety_role}
                            onChange={value =>
                              dropDownHandleSelect(
                                {
                                  health_safety_role: value ? value : null,
                                },
                                'health_safety_role'
                              )
                            }
                          />
                        </div>
                        <div className="w-100">
                          <InputLabelTop
                            type="text"
                            maxheight="9rem"
                            id="jd_passcode"
                            label={'Employee Passcode'}
                            readOnly={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            onKeyPress={KeyPress.INTEGER}
                            {...passcode_hook}
                            maxlength={25}
                          />
                        </div>
                      </div>

                      <div className="d-md-flex align-items-center">
                      <div className="w-100 me-2 mb-2">
                          <DropdownSearchable
                            width={'100%'}
                            placeHolder={'Other Reports To'}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            options={reportsTo}
                            isMulti={true}
                            selectedOptionsProps={
                              values && values.reports_to_employee && values.reports_to_employee
                            }
                            onChange={value =>
                              dropDownHandleRunRoute({
                                reports_to_employee: value.length ? value : [],
                              })
                            }
                          />
                        </div>
                        <div className="w-100 mb-2">
                          <DropdownSearchable
                            width={'100%'}
                            // marginLeft={'0.5%'}
                            placeHolder={'Group Area'}
                            disabled={
                              selectedId
                                ? !jobDetailsEmploymentDetailsUpdate
                                : !jobDetailsEmploymentDetailsCreate
                            }
                            options={choices.run_routes}
                            isMulti={true}
                            selectedOptionsProps={
                              values && values.run_routes && values.run_routes
                            }
                            onChange={value =>
                              dropDownHandleRunRoute({
                                run_route: value.length ? value : [],
                              })
                            }
                          />
                        </div>
                        {/* <div className="w-100 me-2 mb-2">
                          <DateSelector
                            labelTop="Contract Effective Date"
                            {...contracted_effective_date_hook}
                          />
                        </div>
                        <div className="w-100 mb-2">
                          <DateSelector
                            labelTop="Contract End Date"
                            {...contracted_end_date_hook}
                          />
                        </div> */}
                      </div>
                      <div className="d-md-flex align-items-center mt-3 mb-3">
                      <div className="me-2 ">
                          <ToggleButton
                            label="Contracted"
                            useInput={is_contracted_hook}
                            id="is_contracted"
                          />
                        </div>
                      </div>
                        <div className="d-md-flex">
                          <div className="w-100 me-2">
                            <DropdownSearchable
                              // disabled={!editMode}
                              placeHolder={'Payroll Pattern'}
                              options={choices.Billing_patterns}
                              selectedOption={
                                values
                                  ? values.pay_pattern
                                    ? values.pay_pattern
                                    : null
                                  : null
                              }
                              onChange={(value) =>
                                dropDownHandleSelect(
                                  { pay_pattern: value ? value : null },
                                  'pattern'
                                )
                              }
                            />
                          </div>

                          <div className="w-100">
                            <InputLabelTop
                              type="text"
                              id="jd_max_before_overtime"
                              label={'Max Hours before Overtime'}
                              readOnly={
                                selectedId
                                  ? !jobDetailsEmploymentDetailsUpdate
                                  : !jobDetailsEmploymentDetailsCreate
                              }
                              onKeyPress={KeyPress.DECIMAL}
                              {...max_before_overtime_hook}
                              maxlength={8}
                            />
                          </div>
                          {/* <div className="w-100">
                            <InputLabelTop
                              label="Desired Hours"
                              type="text"
                              readOnly={
                                selectedId
                                  ? !jobDetailsEmploymentDetailsUpdate
                                  : !jobDetailsEmploymentDetailsCreate
                              }
                              id="jd_max_hour_per_week"
                              onKeyPress={KeyPress.DECIMAL}
                              {...max_hour_per_week_hook}
                            // maxlength={8}
                            />
                          </div> */}
                        </div>
                    </div>
                  </FlexEqual>
                </div>
                <div className="d-md-flex align-items-center">
                  <div className="w-100 me-2">
                    <InputLabelTop
                      label="Max working hours per period/shift"
                      type="text"
                      readOnly={
                        selectedId
                          ? !jobDetailsEmploymentDetailsUpdate
                          : !jobDetailsEmploymentDetailsCreate
                      }
                      id="max_working_per_period"
                      onKeyPress={KeyPress.DECIMAL}
                      {...max_working_hours_per_shift_hook}
                      maxlength={8}
                    />
                  </div>
                  <div className="w-100 me-2 mb-2">
                    <InputLabelTop
                      label="Max working hours per day"
                      type="text"
                      readOnly={
                        selectedId
                          ? !jobDetailsEmploymentDetailsUpdate
                          : !jobDetailsEmploymentDetailsCreate
                      }
                      id="max_hour_per_day"
                      onKeyPress={KeyPress.DECIMAL}
                      {...max_working_hours_per_day_hook}
                      maxlength={8}
                    />
                  </div>
                  <div className="w-100 me-2 mb-2">
                    <InputLabelTop
                      label="Max working hours per week"
                      type="text"
                      readOnly={
                        selectedId
                          ? !jobDetailsEmploymentDetailsUpdate
                          : !jobDetailsEmploymentDetailsCreate
                      }
                      id="max_hour_per_week"
                      onKeyPress={KeyPress.DECIMAL}
                      {...max_working_hours_per_week_hook}
                      maxlength={8}
                    />
                  </div>
                  <div className="w-100 mb-2">
                    <InputLabelTop
                      label="Max working hours per month"
                      type="text"
                      readOnly={
                        selectedId
                          ? !jobDetailsEmploymentDetailsUpdate
                          : !jobDetailsEmploymentDetailsCreate
                      }
                      id="max_hour_per_month"
                      onKeyPress={KeyPress.DECIMAL}
                      {...max_working_hours_per_month_hook}
                      maxlength={8}
                    />
                  </div>
                </div>
              </>
            )}
            {/* {(selectedId
              ? jobDetailsRateCardRead
              : jobDetailsRateCardCreate) && ( */}
              <div className="mt-3">
                <FlexEqual>
                  <TitleSection>Contract</TitleSection>
                  {values &&
                    values.employee_contracted_hours &&
                    values.employee_contracted_hours.map((rate, rateIndex) => {
                      return (
                        <JobDetailContracts
                          values={values}
                          useInput={useInput}
                          nameSection={'employee_contracted_hours'}
                          index={rateIndex}
                          deleteRate={deleteRate}
                        />
                      );
                    })}
                  <PrimaryButtonForm
                    className={'ms-3 mt-1 mb-2'}
                    bgcolor="BLUE"
                    minWidth="3rem"
                    onClick={() => addNewRate('employee_contracted_hours')}>
                    <span>{<BsFillPlusSquareFill />}Contracted Hour</span>
                  </PrimaryButtonForm>
                </FlexEqual>
              </div>


              <div className="d-flex mt-2">
                <div className="w-100 pe-2">
                  <DropdownSearchable
                  required
                    placeHolder={'Absence Calendar Year'}
                    {...absence_calendar_year_hook}
                    // options={[
                    //   {
                    //     // id: `${moment(values.start_date || new Date()).format("DD-MMM-YYYY")} - ${moment(values.start_date || new Date()).add(1, 'years').format("DD-MMM-YYYY")}`,
                    //     id: "Joining Calendar",
                    //     name: "Joining Calendar"
                    //   },
                    //   {
                    //     // id: `01-Jan-${moment().year()} - 01-Jan-${moment().add(1, 'years').year()}`,
                    //     id: "Financial Calendar",
                    //     name: "Financial Calendar"
                    //   }
                    // ]}
                    options={choices?.calendar_type && choices?.calendar_type || [] }
                    selectedOption={
                      values
                        ? values.absence_calendar_year
                          ? values.absence_calendar_year
                          : null
                        : null
                    }
                    onChange={(value) =>
                      dropDownHandleSelect(
                        { absence_calendar_year: value ? value : null },
                        'absence_calendar_year'
                      )
                    }
                  />
                </div>
                <div className="w-100 pe-2">
                  <InputLabelTop
                    label="Desired Hours"
                    required
                    type="text"
                    readOnly={
                      selectedId
                        ? !jobDetailsEmploymentDetailsUpdate
                        : !jobDetailsEmploymentDetailsCreate
                    }
                    id="jd_max_hour_per_week"
                    onKeyPress={KeyPress.DECIMAL}
                    {...max_hour_per_week_hook}
                  // maxlength={8}
                  />

                </div>
                <div className="w-100 pe-2">
                  <InputLabelTop
                  required
                    label="No of Desired Avg Days"
                    type="text"
                    readOnly={
                      selectedId
                        ? !jobDetailsEmploymentDetailsUpdate
                        : !jobDetailsEmploymentDetailsCreate
                    }
                    id="no_of_desired_avg_days"
                    onKeyPress={KeyPress.DECIMAL}
                    {...no_of_desired_avg_days_hook}
                  // maxlength={8}
                  />
                </div>
                {/* <div className="w-100 me-2">
                  <InputLabelTop
                    disabled={false}
                    readOnly={false}
                    type="number"
                    id="no_of_days_a_week"
                    label={'No. of Days a week'}
                    {...no_of_days_week_hook}

                  />
                </div> */}
              </div>

              {/* )} */}
            {/* {(selectedId
              ? jobDetailsRateCardRead
              : jobDetailsRateCardCreate) && (
              <div className="mt-3">
                <FlexEqual>
                  <TitleSection>Rate Card</TitleSection>
                  {values &&
                    values.job_detail_rate_card &&
                    values.job_detail_rate_card.map((rate, rateIndex) => {
                      return (
                        <EmployeeRateCard
                          values={values}
                          bill_rate={billRate}
                          payRate={payRate}
                          list_key1={'rate_card'}
                          list_key2={'job_title'}
                          dropDownHandleRate={dropDownHandleRate}
                          job_title={choices.job_title}
                          useInput={useInput}
                          nameSection={'job_detail_rate_card'}
                          index={rateIndex}
                          deleteRate={deleteRate}
                          setValues={setValues}
                          readOnly={
                            selectedId
                              ? !jobDetailsEmploymentDetailsUpdate
                              : !jobDetailsEmploymentDetailsCreate
                          }
                        />
                      );
                    })}
                  <PrimaryButtonForm
                    className={'ms-3 mt-1 mb-2'}
                    bgcolor="BLUE"
                    minWidth="3rem"
                    onClick={() => addNewRate('job_detail_rate_card')}>
                    <span>{<BsFillPlusSquareFill />}Rate Card</span>
                  </PrimaryButtonForm>
                </FlexEqual>
              </div>
            )} */}
            {(selectedId
              ? jobDetailsProbationRead
              : jobDetailsProbationCreate) && (
              <div className="mt-3">
                <FlexEqual>
                  <TitleSection>Probation</TitleSection>
                  <div className="d-flex flex-column mt-2">
                    <div className="d-flex mb-2">
                      <div className="w-100 me-2">
                        <DateSelector
                          labelTop="Probation Start Date"
                          {...probation_start_date_hook}
                        />
                      </div>
                      <div className="w-100 me-2">
                        <DropdownSearchable
                          placeHolder={'Probation Term'}
                          options={choices.probation_term}
                          disabled={
                            selectedId
                              ? !jobDetailsProbationUpdate
                              : !jobDetailsProbationCreate
                          }
                          selectedOption={values.probation_term}
                          onChange={value =>
                            dropDownHandleSelect({
                              probation_term: value ? value : null,
                            })
                          }
                        />
                      </div>
                      <div className="w-100 mb-2">
                        <DateSelector
                          labelTop="Probation End Date"
                          {...probation_end_date_hook}
                        />
                      </div>
                    </div>
                  </div>
                </FlexEqual>
              </div>
            )}
            {(selectedId
              ? jobDetailsRecruitmentRead
              : jobDetailsRecruitmentCreate) && (
              <div className="mt-3">
                <FlexEqual>
                  <TitleSection>Recruitment</TitleSection>
                  <div className="d-flex mt-2">
                    <div className="w-100 me-2">
                      <DropdownSearchable
                        placeHolder={'Recruitment Source'}
                        options={choices.recruitment_source}
                        disabled={
                          selectedId
                            ? !jobDetailsRecruitmentUpdate
                            : !jobDetailsRecruitmentCreate
                        }
                        selectedOption={
                          values
                            ? values.recruitment_source
                              ? values.recruitment_source
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect({
                            recruitment_source: value ? value : null,
                          })
                        }
                      />
                    </div>
                    <div className="w-100 me-2">
                      <DropdownSearchable
                        placeHolder={'Applicant Source'}
                        options={choices.applicant_source}
                        disabled={
                          selectedId
                            ? !jobDetailsRecruitmentUpdate
                            : !jobDetailsRecruitmentCreate
                        }
                        selectedOption={
                          values
                            ? values.applicant_source
                              ? values.applicant_source
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect({
                            applicant_source: value ? value : null,
                          })
                        }
                      />
                    </div>
                    <div className="w-100 mb-2">
                      <DropdownSearchable
                        placeHolder={'Recruitment Decision'}
                        options={choices.recruitment_decision}
                        disabled={
                          selectedId
                            ? !jobDetailsRecruitmentUpdate
                            : !jobDetailsRecruitmentCreate
                        }
                        selectedOption={
                          values
                            ? values.recruitment_decision
                              ? values.recruitment_decision
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect({
                            recruitment_decision: value ? value : null,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="w-100 me-2">
                      <DateSelector
                        labelTop="Recruitment Interview 1"
                        {...recruitment_interview_1_date_hook}
                      />
                    </div>
                    <div className="w-100 me-2">
                      <DateSelector
                        labelTop="Recruitment Interview 2"
                        {...recruitment_interview_2_date_hook}
                      />
                    </div>
                    <div className="w-100 mb-2">
                      <DateSelector
                        labelTop="Recruitment Interview 3"
                        {...recruitment_interview_3_date_hook}
                      />
                    </div>
                  </div>
                </FlexEqual>
              </div>
            )}
            {(selectedId
              ? jobDetailsNoticeAndTerminationRead
              : jobDetailsNoticeAndTerminationCreate) && (
              <div className="mt-3">
                <FlexEqual>
                  <TitleSection>Notice and Termination</TitleSection>
                  <div className="d-flex mt-2">
                    <div className="w-100 me-2">
                      <DropdownSearchable
                        placeHolder={'Employer Notice Period'}
                        options={choices.employer_notice_period}
                        disabled={
                          selectedId
                            ? !jobDetailsNoticeAndTerminationUpdate
                            : !jobDetailsNoticeAndTerminationCreate
                        }
                        selectedOption={
                          values
                            ? values.employer_notice_period
                              ? values.employer_notice_period
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect({
                            employer_notice_period: value ? value : null,
                          })
                        }
                      />
                    </div>
                    <div className="w-100 me-2">
                      <DropdownSearchable
                        placeHolder={'Employee Notice Period'}
                        disabled={
                          selectedId
                            ? !jobDetailsNoticeAndTerminationUpdate
                            : !jobDetailsNoticeAndTerminationCreate
                        }
                        options={choices.employee_notice_period}
                        selectedOption={
                          values
                            ? values.employee_notice_period
                              ? values.employee_notice_period
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect({
                            employee_notice_period: value ? value : null,
                          })
                        }
                      />
                    </div>
                    <div className="w-100 mb-2">
                      <DropdownSearchable
                        placeHolder={'Leaver Reason'}
                        disabled={
                          selectedId
                            ? !jobDetailsNoticeAndTerminationUpdate
                            : !jobDetailsNoticeAndTerminationCreate
                        }
                        options={choices.leaver_reason}
                        selectedOption={
                          values
                            ? values.leaver_reason
                              ? values.leaver_reason
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect({
                            leaver_reason: value ? value : null,
                          })
                        }
                      />
                    </div>
                  </div>
                </FlexEqual>
              </div>
            )}
            {(selectedId
              ? jobDetailsEmployeePreferencesRead
              : jobDetailsEmployeePreferencesCreate) && (
              <div className="mt-3">
                <FlexEqual>
                  <TitleSection>Employee Preferences</TitleSection>
                  <div className="d-flex flex-column mt-2">
                    <div className="d-flex mb-2">
                      <div className="w-100 me-2">
                        <DropdownSearchable
                          placeHolder={'Preferred Gender'}
                          disabled={
                            selectedId
                              ? !jobDetailsEmployeePreferencesUpdate
                              : !jobDetailsEmployeePreferencesCreate
                          }
                          options={choices.gender}
                          selectedOption={
                            values.preference
                              ? values.preference.preferred_gender
                                ? values.preference.preferred_gender
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect({
                              preference: {
                                preferred_gender: value ? value : null,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="w-100">
                        <DropdownSearchable
                          isMulti={true}
                          placeHolder={'Preferred Client Names'}
                          disabled={
                            selectedId
                              ? !jobDetailsEmployeePreferencesUpdate
                              : !jobDetailsEmployeePreferencesCreate
                          }
                          options={clientList}
                          selectedOptionsProps={
                            values.employee_prefered_client
                              ? values.employee_prefered_client
                              : null
                          }
                          onChange={values =>
                            preferredHandleSelect({
                              client: values.length ? values : [],
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="w-100 me-2">
                        <DropdownSearchable
                          isMulti={true}
                          placeHolder={'Preferred Run Name'}
                          disabled={
                            selectedId
                              ? !jobDetailsEmployeePreferencesUpdate
                              : !jobDetailsEmployeePreferencesCreate
                          }
                          options={choices.run_view}
                          selectedOptionsProps={
                            values.employee_preferred_run_name
                              ? values.employee_preferred_run_name
                              : null
                          }
                          onChange={values =>
                            preferredHandleSelect({
                              preferred_run: values.length ? values : [],
                            })
                          }
                        />
                      </div>
                      <div className="w-100">
                        <DropdownSearchable
                          isMulti={true}
                          placeHolder={'Avoid Run Name'}
                          disabled={
                            selectedId
                              ? !jobDetailsEmployeePreferencesUpdate
                              : !jobDetailsEmployeePreferencesCreate
                          }
                          options={choices.run_view}
                          selectedOptionsProps={
                            values.employee_avoid_run_name
                              ? values.employee_avoid_run_name
                              : null
                          }
                          onChange={values =>
                            preferredHandleSelect({
                              avoid_run: values.length ? values : [],
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="w-100 me-2">
                        <DropdownSearchable
                          isMulti={true}
                          placeHolder={'Religion Consideration'}
                          disabled={
                            selectedId
                              ? !jobDetailsEmployeePreferencesUpdate
                              : !jobDetailsEmployeePreferencesCreate
                          }
                          options={choices.religion}
                          selectedOptionsProps={
                            values.employee_prefered_religion
                              ? values.employee_prefered_religion
                              : null
                          }
                          onChange={values =>
                            preferredHandleSelect({
                              religion: values.length ? values : [],
                            })
                          }
                        />
                      </div>

                      <div className="w-100">
                        <DropdownSearchable
                          placeHolder={'Travel Method (default)'}
                          disabled={
                            selectedId
                              ? !jobDetailsEmployeePreferencesUpdate
                              : !jobDetailsEmployeePreferencesCreate
                          }
                          options={choices.travel_type}
                          selectedOption={
                            values.preference
                              ? values.preference.travel_method
                                ? values.preference.travel_method
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect({
                              preference: {travel_method: value ? value : null},
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="w-100 me-2">
                        <InputLabelTop
                          type="text"
                          id="jd_max_distance"
                          readOnly={
                            selectedId
                              ? !jobDetailsEmployeePreferencesUpdate
                              : !jobDetailsEmployeePreferencesCreate
                          }
                          label={'Max Distance from home (miles)'}
                          {...max_distance_hook}
                          onKeyPress={KeyPress.INTEGER}
                          maxlength={5}
                        />
                      </div>
                      <div className="w-100 me-2">
                        <DropdownSearchable
                          placeHolder={'Travel Rate Card(default)'}
                          options={travelRate}
                          disabled={
                            selectedId
                              ? !jobDetailsEmployeePreferencesUpdate
                              : !jobDetailsEmployeePreferencesCreate
                          }
                          selectedOption={
                            values.preference
                              ? values.preference.travel_rate_card
                                ? values.preference.travel_rate_card
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect({
                              preference: {
                                travel_rate_card: value ? value : null,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="w-100">
                        <CustomDropdowns
                          // to add items not present in the options(dropdown items)
                          options={choices && choices?.postal_codes ? choices.postal_codes : []}
                          isMulti={true}
                          isCreate={true}
                          placeHolder={'Preferred Postal Area'}
                          selectedOptionsProps={
                            values.employee_preferred_postal_area
                          }
                          onChange={value =>
                            HandleMultiSelect(
                              {
                                employee_preferred_postal_area: value
                                  ? value
                                  : null,
                              },
                              'employee_preferred_postal_area'
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="w-100 me-2">
                        <ToggleButton
                          label="Non-Smoking"
                          useInput={smoking_hook}
                          id="jd_smoking"
                        />
                      </div>
                    </div>
                  </div>
                </FlexEqual>
              </div>
            )}
            {(selectedId
              ? jobDetailsIncompatibilitiesRead
              : jobDetailsIncompatibilitiesCreate) && (
              <div className="mt-3">
                <FlexEqual>
                  <TitleSection>Incompatibilities</TitleSection>
                  <div>
                    <div>
                      {values.employee_avoid_client &&
                        values.employee_avoid_client.map(
                          (clients, indexClient) => {
                            return (
                              <AvoidDetails
                                values={values}
                                dropDownLabel={'Select Client'}
                                dropDownList={clientList}
                                list_key={'avoid_client'}
                                dropDownHandleAvoid={dropDownHandleAvoid}
                                useInput={useInput}
                                nameSection={'employee_avoid_client'}
                                index={indexClient}
                                deleteAvoid={deleteAvoid}
                                readOnly={
                                  selectedId
                                    ? !jobDetailsIncompatibilitiesUpdate
                                    : !jobDetailsIncompatibilitiesCreate
                                }
                              />
                            );
                          }
                        )}
                    </div>
                    <PrimaryButtonForm
                      className={'ms-3 mt-1 mb-2'}
                      bgcolor="BLUE"
                      minWidth="3rem"
                      onClick={() => addNewAvoid('employee_avoid_client')}>
                      <span>{<BsFillPlusSquareFill />}Avoid Clients</span>
                    </PrimaryButtonForm>
                    <div>
                      {values.employee_avoid_employee &&
                        values.employee_avoid_employee.map(
                          (employees, indexEmployee) => {
                            return (
                              <AvoidDetails
                                values={values}
                                dropDownLabel={'Select Employee'}
                                nameSection={'employee_avoid_employee'}
                                list_key={'avoid_employee'}
                                dropDownList={reportsTo}
                                dropDownHandleAvoid={dropDownHandleAvoid}
                                useInput={useInput}
                                index={indexEmployee}
                                deleteAvoid={deleteAvoid}
                                readOnly={
                                  selectedId
                                    ? !jobDetailsIncompatibilitiesUpdate
                                    : !jobDetailsIncompatibilitiesCreate
                                }
                              />
                            );
                          }
                        )}
                    </div>
                    <PrimaryButtonForm
                      className={'ms-3 mt-1 mb-2'}
                      bgcolor="BLUE"
                      minWidth="3rem"
                      onClick={() => addNewAvoid('employee_avoid_employee')}>
                      <span>{<BsFillPlusSquareFill />}Avoid Employees</span>
                    </PrimaryButtonForm>
                    <div>
                      {values.employee_avoid_postal_area &&
                        values.employee_avoid_postal_area.map(
                          (postal, indexPostal) => {
                            return (
                              <AvoidDetails
                                values={values}
                                setValues={setValues}
                                postal={true}
                                dropDownHandleAvoid={dropDownHandleAvoid}
                                nameSection={'employee_avoid_postal_area'}
                                useInput={useInput}
                                index={indexPostal}
                                deleteAvoid={deleteAvoid}
                                readOnly={
                                  selectedId
                                    ? !jobDetailsIncompatibilitiesUpdate
                                    : !jobDetailsIncompatibilitiesCreate
                                }
                              />
                            );
                          }
                        )}
                      <PrimaryButtonForm
                        className={'ms-3 mt-1 mb-2'}
                        bgcolor="BLUE"
                        minWidth="3rem"
                        onClick={() =>
                          addNewAvoid('employee_avoid_postal_area')
                        }>
                        <span>{<BsFillPlusSquareFill />}Avoid Postal Area</span>
                      </PrimaryButtonForm>
                    </div>
                    <div>
                      {values.employee_avoid_animal &&
                        values.employee_avoid_animal.map(
                          (animal, indexAnimal) => {
                            return (
                              <AvoidDetails
                                values={values}
                                dropDownLabel={'Select Animal'}
                                dropDownList={choices.animal}
                                list_key={'animal'}
                                dropDownHandleAvoid={dropDownHandleAvoid}
                                nameSection={'employee_avoid_animal'}
                                useInput={useInput}
                                index={indexAnimal}
                                deleteAvoid={deleteAvoid}
                                readOnly={
                                  selectedId
                                    ? !jobDetailsIncompatibilitiesUpdate
                                    : !jobDetailsIncompatibilitiesCreate
                                }
                              />
                            );
                          }
                        )}
                      <PrimaryButtonForm
                        className={'ms-3 mt-1 mb-2'}
                        bgcolor="BLUE"
                        minWidth="3rem"
                        onClick={() => addNewAvoid('employee_avoid_animal')}>
                        <span>{<BsFillPlusSquareFill />}Avoid Animal</span>
                      </PrimaryButtonForm>
                    </div>
                  </div>
                </FlexEqual>
              </div>
            )}
            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {showError ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>

              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                <div className={'ms-0 ms-md-3 me-2'}>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    className={'me-2'}
                    onClick={() => {
                      history.push('/team');
                    }}>
                    <span className={'me-2'}>{'Cancel'}</span>
                  </PrimaryButtonForm>
                  {(selectedId ? updatePermissionJoblDetails : true) && (
                    <PrimaryButtonForm
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      onClick={handleSubmit}>
                      <span className={'me-2'}>{textStatus}</span>
                      {!loadingSave ? (
                        buttonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <NoPermissionContainer>
            You don't have permission to {selectedId ? 'read' : 'create'} the
            information.
          </NoPermissionContainer>
        )}
      </ComponentDynamic>
    </>
  );
};

export default JobDetails;