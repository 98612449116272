import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import {
  EX_LARGE_COL,
  LARGE_COL,
  EX_SMALL_COL4,
  SMALL_COL,
  MEDIUM_COL,
  EX_VERY_LARGE_COL,
  EX_VERY_SMALL_COL,
  EX_SMALL_COL,
} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import { CLIENT_HANDBACK, CLIENT_SWAP_VISIT } from 'utils/constants/permissions';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';

import { EmploymentTypesContainer } from 'components/DashboardComponents/Navbar/styles';
import {
  WEB_PERMISSION,
  WEB_READ_PERMISSION,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_DELETE_PERMISSION,
} from 'utils/constants/permissions';
import {
  getHandBackList,
  getSwapVisitList,
  patchApprovalSwapVisit,
  patchDeleteSwapVisit,
} from 'utils/api/ClientApi';
import { PrimaryButton, PrimaryButtonForm } from 'shared/styles/buttons';

import { patchDeleteHandBack } from 'utils/api/ClientApi';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';

import { RiDeleteBinLine } from 'react-icons/ri';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import queryString from 'query-string';
import { AppContext } from 'context';
import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper,
  DayPill,
} from 'components/SharedComponents/Table/styles';
import {
  HeaderVisits,
  Title,
  SearchIcon,
  SearchAddContainer,
  SearchContainer,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
  CloseButtonWrapper,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {
  client_hand_bag_choices,
  client_swap_visit_choices,
} from 'utils/choiceConstant';
import { LeftContainer } from 'components/SharedComponents/ProgressInformation/styles';
import DateSelector from 'components/SharedComponents/DateSelector';
import { TIMES } from 'utils/constants/icons';

const headerColumns = [
  {
    label: 'EMPLOYEE NAME',
    name: 'visit_number',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_VERY_LARGE_COL,
  },
  {
    label: 'SWAP EMPLOYEE NAME',
    name: 'visit_number',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_LARGE_COL,
  },
  {
    label: 'CLIENT FULL NAME',
    name: 'client_service_visit__client__full_name',
    status: 1,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'SWAP START TIME',
    name: 'client_service_visit__client__full_name',
    status: 1,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },
  {
    label: 'SWAP END TIME',
    name: 'SWAP END TIME',
    status: 0,
    className: 'grow-mobile',
    max_width: SMALL_COL,
  },

  {
    label: 'VISIT START DATE',
    name: 'start_date',
    status: 0,
    className: '  sm-hidden ',
    max_width: LARGE_COL,
  },

  {
    label: ' VISIT END DATE',
    name: 'end_date',
    status: 0,
    className: ' sm-hidden ',
    max_width: LARGE_COL,
  },
];

function SwapVisit(props) {
  const { values, setValues, useInput } = useForm({});
  const [cancelledvisits, setCancelledVisits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const mountedRef = useRef(true);

  const {
    contextChoices,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextPermissions,
    contextIsStaff,
    contextSubscription,
    contextCategoryPermissions,
  } = useContext(AppContext);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);
  const { setValue: setModalWarningValue, bind: bindModalWarning } =
    useModal(false);

  const [employees, setEmployees] = useState([]);
  const dateFrom = useInput('date_from')
  const dateTo = useInput('date_to')
  const [idToDelete, setIdToDelete] = useState();
  const [HandBackList, setHandBackList] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [events, setEvents] = useState([]);
  const [checkedId, setCheckedId] = useState('');
  const [deleteId, setDeleteId] = useState([]);
  const [checkedIdArr, setCheckedIdArr] = useState([]);
  const [employeeFilter, setEmployeeFilter] = useState([]);
  const [branch, setBranch] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const [swapEmployeeFilter, setSwapEmployeeFilter] = useState([]);
  const [countCheck, setCountCheck] = useState()
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const {
    setValue: setAddEvent,
    value: addEvent,
    fullBind: fullBindAddEvent,
  } = useModal(false);
  const {
    setValue: setCancelledVisit,
    value: cancelledVisit,
    fullBind: fullBindCancelledVisit,
  } = useModal(false);
  const { handleGetChoices } = useContext(AppContext);

  useEffect(() => {
    handleGetChoices(client_swap_visit_choices);
  }, []);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values });
  }, []);
  useEffect(() => {
    if (

      values.limitperpage ||
      props.location.search
    ) {
      search();
    }
  }, [

    values.limitperpage,
    props.location.search,
  ]);
  useEffect(() => {
    setBranch(
      contextChoices?.branch &&
      contextChoices?.branch?.map(y => {
        return { id: y && y.id, name: y && y.name };
      })
    );

    setEmployees(
      () =>
        contextChoices?.branch &&
        contextChoices?.employee_listing?.map(item => {
          return { id: item.id, name: item.full_name };
        })
    );
  }, [contextChoices]);

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
  useEffect(() => {
    let selfPermission = {};
    const self = contextPermissions;
    if (self.length > 0) {
      self.forEach(item => {
        selfPermission[item.section.name] = {};
        selfPermission[item.section.name][WEB_PERMISSION] = {};
        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_READ_PERMISSION
        ] = item?.[SELF_WEB_READ_PERMISSION];

        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_CREATE_PERMISSION
        ] = item?.[SELF_WEB_CREATE_PERMISSION];

        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_UPDATE_PERMISSION
        ] = item?.[SELF_WEB_UPDATE_PERMISSION];

        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_DELETE_PERMISSION
        ] = item?.[SELF_WEB_DELETE_PERMISSION];
      });
    }
    let rolePermissions = {};

    const roles = contextPermissions;
    if (roles.length > 0) {
      roles.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
      });
      contextPermissions.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
      });
    }
    if (filterSuperAdmin) {
      setReadPermissions(true);
      setUpdatePermissions(true);
      setDeletePermissions(true);
      setCreatePermissions(true);
    }
    if (filterAdmin) {
      setReadPermissions(
        contextSubscription?.[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION]
      );
      setUpdatePermissions(true);
      setDeletePermissions(true);
      setCreatePermissions(true);
    }

    if (rolePermissions[CLIENT_SWAP_VISIT] !== undefined) {
      setCreatePermissions(
        (contextSubscription?.[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION]?.[
          WEB_CREATE_PERMISSION
          ]) ||
        selfPermission[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION]?.[
        SELF_WEB_CREATE_PERMISSION
        ]
      );
      setDeletePermissions(
        (contextSubscription?.[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION]?.[
          WEB_DELETE_PERMISSION
          ]) ||
        selfPermission[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION]?.[
        SELF_WEB_DELETE_PERMISSION
        ]
      );
      setUpdatePermissions(
        (contextSubscription?.[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION]?.[
          WEB_UPDATE_PERMISSION
          ]) ||
        selfPermission[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION]?.[
        SELF_WEB_UPDATE_PERMISSION
        ]
      );

      setReadPermissions(
        (contextSubscription?.[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION]?.[
          WEB_READ_PERMISSION
          ]) ||
        selfPermission[CLIENT_SWAP_VISIT]?.[WEB_PERMISSION]?.[
        SELF_WEB_READ_PERMISSION
        ]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contextIsAdmin,
    contextIsSuperAdmin,
    contextPermissions,
    contextCategoryPermissions,
    contextSubscription,
  ]);
  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };
  const handleRowClick = type => {
    if (type == 'edit') {
      setCancelledVisit(!cancelledVisit);
    } else {
      setCancelledVisit(!cancelledVisit);
    }
  };
  const search = () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage
    );
    getSwapVisitList({ ...values, ...pageToSearch }).then(response => {
      if (response) {
        setCountCheck(response.data)
        setHandBackList(response.data);
      }
      setPages(Math.ceil(response.count / values.limitperpage));
      if (!mountedRef.current) return null;
    });
  };

  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };

  const setEmployeefilter = item => {
    setEmployeeFilter([...item]);
  };
  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };
  const toDeleteVisit = async id => {
    setModalDeleteValue(true);
    setIdToDelete(id);
  };
  const toApproval = async id => {
    setModalWarningValue(true);
    setIdToDelete(checkedId);
  };
  const handleDeleteVisit = async () => {
    if (idToDelete || checkedId) {
      const is_deleted = true;
      await patchDeleteSwapVisit([idToDelete]);
      setModalDeleteValue(false);
    }
    search();
  };
  // const handleWarningVisit = async () => {
  //   if (checkedId) {
  //     const is_deleted = true;
  //     await patchApproval(idToDelete, is_deleted);
  //     search();
  //     setModalWarningValue(false);
  //     setCheckedRows([]);
  //   }
  // };
  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists = id => {
      return checkedRows.some(function (el) {
        return el.id === id;
      });
    };
    if (checkedRows && checkedRows.length > 0) {
      HandBackList.map(visit => {
        if (!visitExists(visit.id)) {
          checkAll = false;
        }
      });
    } else {
      checkAll = false;
    }
    return checkAll;
  };
  const handleAllSelectChange = valcheck => {
    let checked = [...checkedRows];
    if (valcheck.target.checked) {
      HandBackList.map(visit => {
        checked.push(visit);
      });
    } else {
      HandBackList.map(visit => {
        checked.map((item, index) => {
          if (item.id === visit.id) {
            checked.splice(index, 1);
          }
        });
      });
    }
    setCheckedRows(checked);
    setCheckedId(() => checked.map(item => item.id));
  };
  const handleCheck = visit => {
    let check = false;
    if (checkedRows) {
      checkedRows.map(item => {
        if (visit.id === item.id) {
          check = true;
        }
      });
    }
    return check;
  };
  const handleSelectChange = (valcheck, visit, selectedIndex) => {
    setCheckedIdArr(visit.id);
    let checked = [...checkedRows];
    if (valcheck.target.checked) {
      checked.push(visit);
    } else {
      checked.map((item, index) => {
        if (item.id === visit.id) {
          checked.splice(index, 1);
        }
      });
    }
    setCheckedRows(checked);
    setCheckedId(() => checked.map(item => item.id));

    //setClients(arr);
  };
  const dropDownHandleSelect = (item, namesection) => {
    setValues({ ...values, ...item });
  };
  const getEmployeeName = ID => {
    if (ID && contextChoices) {
      return contextChoices?.employee_listing?.find(item => item.id === ID)
        ?.full_name;
    } else {
      return;
    }
  };
  const getSwapName = ID => {
    if (ID && contextChoices) {
      return contextChoices?.employee_listing?.find(item => item.id === ID)
        ?.full_name;
    } else {
      return;
    }
  };
  const declineMethod = ID => {
    let strID = ID.join(',');

    patchDeleteSwapVisit(strID);

    search();
  };
  const ApprovaMethod = id => {
    let strId = id.join(',');

    patchApprovalSwapVisit(strId);
    search();
  };
  const setSwapEmployee = item => {
    setSwapEmployeeFilter([...item]);
  };
  const handleSearch = () => {
    if (
      values.employee || values.swap_employee || values.date_from || values.date_to) {
      search()
    }
  }
  return (
    <div>
      {readPermission && (
        <div>
          <Title>Swap Visit </Title>
          <HeaderVisits>
            <div className="d-flex align-items-center">
              <FiltersContainer>
                <EmploymentTypesContainer className="me-2 mb-1">
                  <NoSelectAllDropdownFilter
                    items={employees ? employees : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee: items });
                    }}
                    itemsSelected={employeeFilter}
                    setSelectedFilter={setEmployeefilter}
                    placeHolder={'Employees'}
                    isObject
                    allOption
                  // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
                <EmploymentTypesContainer>
                  <DropdownTypesContainer className="ms-1 me-1">
                    <NoSelectAllDropdownFilter
                      items={branch ? branch : []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ branch: items });
                      }}
                      itemsSelected={branchFilter}
                      setSelectedFilter={setFilterBranch}
                      placeHolder={'Branch'}
                      isObject
                      allOption
                    />
                  </DropdownTypesContainer>
                </EmploymentTypesContainer>
                <EmploymentTypesContainer className="me-2 mb-1">
                  <NoSelectAllDropdownFilter
                    items={employees ? employees : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ swap_employee: items });
                    }}
                    itemsSelected={swapEmployeeFilter}
                    setSelectedFilter={setSwapEmployee}
                    placeHolder={'Swap Employees'}
                    isObject
                    allOption
                  // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
                <DropdownTypesContainer className="ms-1 me-1  d-flex">
                  <div>
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date from"
                      {...dateFrom}
                    />
                  </div>
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ms-1 me-1 d-flex">
                  <div>
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date to"
                      minDate={values.date_from}
                      {...dateTo}
                    />
                  </div>
                </DropdownTypesContainer>
                <PrimaryButton
                  style={{ marginLeft: '1rem' }}
                  onClick={handleSearch}
                >
                  Search
                </PrimaryButton>
              </FiltersContainer>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '4px',
              }}>
              <SearchAddContainer
                className="d-flex align-items-center"
                style={{ paddingLeft: '100px' }}>
                {checkedRows && checkedRows.length > 0 && (
                  <div class="dropdown  me-2">
                    {update && (
                      <PrimaryButtonForm
                        minWidth="6rem"
                        class="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                      // aria-expanded="false"
                      >
                        <span className={'me-2'}>Action</span>
                      </PrimaryButtonForm>
                    )}
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton">
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          ApprovaMethod(checkedId);
                        }}>
                        Approval
                      </a>
                      <a
                        class="dropdown-item"
                        onClick={() => {
                          declineMethod(checkedId);
                        }}>
                        Decline
                      </a>
                    </div>
                  </div>
                )}
              </SearchAddContainer>

            </div>
          </HeaderVisits>
          {countCheck !== '0' ? (
            <Table
              headerColumns={headerColumns}
              headerPadding={false}
              fontSize="0.75rem"
              callBackQuerySort={setQuerySort}
              handleAllCheck={handleAllCheck}
              handleAllSelectChange={handleAllSelectChange}
            // callBackQuerySort={setQuerySort}
            >
              {HandBackList &&
                HandBackList.map((item, index) => {
                  return (
                    <div key={index}>
                      <Row bgColor Shadow>
                        <ColsGrouper
                          className="d-flex"
                        // onClick={() => eventClickHandler(visitcancelled)}
                        >
                          <Col
                            max_width={EX_SMALL_COL4}
                            Shrink={false}
                            NoFlexGrow={true}
                            reducePadding
                            Capitalize
                            Center>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              checked={handleCheck(item)}
                              id="flexCheckDefault"
                              onChange={val => {
                                handleSelectChange(val, item, index);
                              }}
                            />
                          </Col>
                          <Col
                            max_width={SMALL_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center>
                            <span style={{ marginRight: '40px' }}>
                              {getEmployeeName(item?.employee)}
                            </span>
                          </Col>
                          <Col
                            max_width={EX_LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center>
                            <span style={{ marginRight: '40px' }}>

                              {getSwapName(item?.swap_employee)}
                            </span>
                          </Col>

                          <Col
                            max_width={EX_LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center>
                                                          <span style={{ marginRight: '40px' }}>

                            {item?.client_service_visit__client__full_name}
                            </span>
                          </Col>
                          <Col
                            max_width={EX_VERY_LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center>
                            {item?.swap_start_time.slice(11, 16)}
                          </Col>
                          <Col
                            max_width={EX_VERY_LARGE_COL}
                            Shrink
                            reducePadding
                            Capitalize
                            overlap={true}
                            Center>
                            {item?.swap_end_time?.slice(11, 16)}
                          </Col>
                          <Col
                            max_width={EX_VERY_LARGE_COL}
                            Shrink
                            reducePadding
                            className="sm-hidden"
                            Capitalize
                            overlap={true}
                            Center>
                            {
                              item?.start_date?.slice(0, 10) //visit start date
                            }
                          </Col>
                          <Col
                            max_width={EX_VERY_LARGE_COL}
                            Shrink
                            reducePadding
                            className="sm-hidden"
                            Capitalize
                            overlap={true}
                            Center>
                            {
                              item?.end_date?.slice(0, 10) //visite start time
                            }
                          </Col>

                          <Col
                            max_width={EX_SMALL_COL}
                            Shrink
                            reducePadding
                            className="sm-hidden"
                            Capitalize
                            overlap={true}
                            Center>
                            {/* {
                              item?.end_date?.slice(0, 10) //visite start time
                            } */}
                          </Col>
                          <Col className="sm-hidden" Center Shrink NoFlexGrow>
                            <Col className="sm-hidden" Center Icon Purple>
                              {/* <IconWrapper
                            
                              //   onClick={() => { eventClickHandler(visitcancelled); handleRowClick('edit') }}
  
  
                              >
                                <MdEdit />
                              </IconWrapper> */}
                            </Col>
                            {/* {deletePermission && (
                        <IconWrapper
                          onClick={() => {
                            toDeleteVisit(item?.id);
                          }}>
                          <RiDeleteBinLine />
                        </IconWrapper>
                      )} */}
                          </Col>
                        </ColsGrouper>
                      </Row>
                      <div className="mb-1" />
                    </div>
                  );
                })}

              <div>
                <div className="mt-3">
                  <Pagination
                    setCurrentPage={setCurrentPage}
                    totalPages={pages}
                    currentPage={currentPage}
                    values={values}
                    dropDownHandleSelect={dropDownHandleSelectPagination}
                    number={PAGE_SIZE_RENDER}
                    dataLength={HandBackList ? HandBackList.length : 0}
                  />
                </div>
              </div>
            </Table>
          ) : (<TitleSection>No data found</TitleSection>)}
          <ModalDecision
            type="delete"
            title="Warning"
            body={`Are you sure you want to delete this Swap Visit ? \n delete the Swap Visit will remove all records from database.`}
            onOk={handleDeleteVisit}
            onCancel={() => {
              setIdToDelete('');
            }}
            okTitle={'DELETE'}
            {...bindModalDelete}
          />
          <ModalDecision
            title="Warning"
            body={`Are you sure you want to Approval Swap Visit ?`}
            // onOk={handleWarningVisit}
            onCancel={() => {
              setIdToDelete('');
            }}
            okTitle={'Approve'}
            {...bindModalWarning}
          />
        </div>
      )}
    </div>
  );
}
export default SwapVisit;
