import React, {useState, useEffect, Fragment} from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import {useForm} from 'hooks/FormHook';
import {bulkUpdateTriageApi} from 'utils/api/AlertsApi';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import {ERROR, SAVE, SAVED} from 'utils/constants/messages';  
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
const BulkTriageModal = ({
    fullBind,
    alertStatus,
    severity,
    setModalValue,
    loadTriage,
    contextChoices,
    dataTriageIds,
    resetAllCheck,
    clearSelectedElId
}) => {
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setCheckRequires,
  } = useForm({});
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [addFullBind, setAddFullBind] = useState(fullBind);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Update  Triage',
    });
    setLoadingModal(false);
  }, [fullBind]);


 const dropDownHandleSelect = (item, nameItem) => {
 setValues({...values, ...item});
  };

  const handleSubmit=(e)=>{
    e.preventDefault();
    if (loadingSave) {
      return;
    }
    
    if(!values.alert_severity &&  !values.alert_status ){
      onError('alteast one field must be filled ');
    }else{
    const bodyData={
    id:dataTriageIds,
    alert_severity:values.alert_severity,
    alert_status:values.alert_status,
    }
    
      setLoadingSave(true);
      bulkUpdateTriageApi(bodyData)
      .then(response => {
       if(response.status == "Success"){
        onSuccess(response);
        }
        else{
          onError(response.message ? response.message : 'Something went wrong !!');
        }
      })
      .catch(onError);
    }
    }
    
    const onSuccess = response => {
      setLoadingSave(false);
      setButtonIcon('');
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
      setModalValue(false);
      resetAllCheck();
      clearSelectedElId()
      loadTriage(); 
    };
    
    const onError = err => {
      let getErrorMessage=err?err:'something went wrong !!'
      setLoadingSave(false);
      setButtonIcon('');
      setButtonIcon(CROSS);
      setTextStatus(ERROR);
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(getErrorMessage);
    };

  
  return (
     <ModalContainer max_width={'60%'}>
      <ModalDynamic
          {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="ps-3 pe-3 pb-3 pt-0">
        <FlexGrid3Container className={'w-100 mb-2'}>
   
            <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
              <DropdownSearchable
                 placeHolder={'Severity'}
                options={severity?severity:[]}
                selectedOption={values.alert_severity}
                onChange={value =>
                  dropDownHandleSelect({alert_severity: value ? value : null})
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
              <DropdownSearchable
               placeHolder={'Triage Status'}
                options={alertStatus?alertStatus:[]}
                selectedOption={values.alert_status}
                onChange={value =>
                  dropDownHandleSelect({alert_status: value ? value : null})
                }
              />
            </FlexGrid3Item>
            </FlexGrid3Container> 
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ms-0 ms-md-3 me-2'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  )
}

export default BulkTriageModal