import Spinner from 'components/SharedComponents/Spinner'
import Table from 'components/SharedComponents/Table'
import { Col, ColsGrouper, IconWrapper, Row } from 'components/SharedComponents/Table/styles'
import { useModal } from 'hooks/ModalHook'
import { Toast } from 'primereact/toast'
import React from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import { FaPlus } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { RiDeleteBinLine } from 'react-icons/ri'
import { FloatingMobileButton } from 'shared/styles/buttons'
import { EX_LARGE_COL, EX_SMALL_COL, EX_SMALL_COL4, EX_VERY_LARGE_COL, LARGE_COL } from 'shared/styles/constants/columns'
import AddRepeatingTask from './AddRepeatingTask'
import { useForm } from 'hooks/FormHook'
import "./styles.css"
import { useContext } from 'react'
import { AppContext } from 'context'
import { useEffect } from 'react'
import { repeate_task_choices } from 'utils/choiceConstant'
import { deleteRepeatingTask, getRepeatingTaskById, getRepeatingTaskList } from 'utils/api/SettingsApi'
import Pagination from 'components/SharedComponents/Pagination'
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination'
import { getPageToSearchParam } from 'shared/methods'
import ModalDecision from 'components/SharedComponents/ModalDecision'
import { Header } from 'shared/styles/constants/tagsStyles'

const headerColumns = [
  {
    label: "QA TASK",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: "TYPE",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: "NAME",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: "START DATE",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: "END DATE",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: "END TYPE",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: "END VALUE",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: "REPEATE EVERY",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: "REPEAT NO.",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: "WEEK DAYS",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_VERY_LARGE_COL,
  },
  {
    label: '',
    name: '',
    status: 0,
    className: ' sm-hidden ',
    max_width: EX_SMALL_COL,
  },

]

// const getDays = () => {
//   return [
//     { name: 'MON' },
//     { name: 'TUE' },
//     { name: 'WED' },
//     { name: 'THU' },
//     { name: 'FRI' },
//     { name: 'SAT' },
//     { name: 'SUN' },
//   ];
// };
function RepeatingTasks({
  props,
  readPermission,
  createPermission,
  updatePermission,
  deletePermission
}) {
  const toast = useRef();
  const { contextChoices: choices, handleGetChoices, setContextChoices } = useContext(AppContext);
  const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({});
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle: setTitle,
    fullBind: fullBind,
  } = useModal(false);
  const {
    setValue: setModalDeleteValue,
    bind: bindModalDelete
  } = useModal(false);

  const [isLoading, setIsLoading] = useState(true)
  const [modalLoading, setModalLoading] = useState(false)
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("")
  const [showError, setShowError] = useState(false)
  const [repeatingTasks, setRepeatingTasks] = useState([])
  const [seletedId, setSeletedId] = useState(null)
  const [isdisabledDeleteButton, setIsdisabledDeleteButton] = useState(false)
  const [daysOfWeek, setDaysOfWeek] = useState([
    { day: 'MON', selected: false },
    { day: 'TUE', selected: false },
    { day: 'WED', selected: false },
    { day: 'THU', selected: false },
    { day: 'FRI', selected: false },
    { day: 'SAT', selected: false },
    { day: 'SUN', selected: false },
  ]);
  const DELETE_MESSAGE = 'Repeating QA task deleted successfully!'
  const SOMETHING_WENT_WRORNG = "Something went wrong!"

  const getRepeatingTaskListData = async () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage,
    )
    try {
      const res = await getRepeatingTaskList({ ...values, ...pageToSearch })
      setRepeatingTasks(res?.results)
      setPages(Math.ceil(res?.count / values?.limitperpage));
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    getRepeatingTaskListData()
  }, [values?.limitperpage, props.location.search])

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [PAGE_SIZE_RENDER])

  useEffect(() => {
    handleGetChoices(repeate_task_choices)
  }, [])

  const findFromChoice = (id, name) => {
    if (name == "hr_task") {
      const hrTask = choices[name]?.find(el => el.id === id)
      return hrTask?.title
    } else if (name === 'client_listing') {
      const client = choices[name]?.find(el => el.id === id)
      return client?.full_name
    } else if (name === 'employee_listing') {
      const employee = choices[name]?.find(el => el.id === id);
      return employee?.full_name
    }
  }

  //Limit per page dropdown
  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }
  const getRepeatingTaskDataById = async (id) => {
    setModalLoading(true)
    try {
      const res = await getRepeatingTaskById(id)
      if (res.status) {
        values.repeat_no = res?.data?.repeat_pattern
        values.type = validateType(res?.data?.employee, res?.data?.client)?.type
        setValues({ ...values, ...res?.data })
        const daysArr = res?.data?.repeat_on?.includes(",") ? res?.data?.repeat_on?.split(",") : res?.data?.repeat_on
        const updatedWeekDays = daysOfWeek.map(item => ({ ...item, selected: daysArr?.includes(item.day) }))
        setDaysOfWeek(updatedWeekDays)
        setModalLoading(false)
      }
    } catch (error) {

    }
  }

  const handleDeleteRepeatingTask = async () => {
    setModalDeleteValue(false)
    setIsdisabledDeleteButton(true)
    try {
      const res = await deleteRepeatingTask(seletedId)
      setSeletedId(null)
      if (res.status) {
        getRepeatingTaskListData()
        setIsdisabledDeleteButton(false)
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: DELETE_MESSAGE,
        })
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: SOMETHING_WENT_WRORNG,
        });
      }
    } catch (error) {
      console.error("Error deleting repeting task", error)
    }
  }
  const resetFromData = () => {
    values.core_hr_task = ""
    values.repeat_no = ""
    values.repeat_on = ""
    values.repeat_every = ""
    values.end_type = ""
    values.start_date = ""
    values.employee = ""
    values.client = ""
    values.end_date = ""
    values.end_value = ""
    setDaysOfWeek(prev => prev.map(day => ({ ...day, selected: false })))
  }

  const handleModalOpen = () => {
    setTitle('Add Repeating Task')
    setModalValue(true)
    setSeletedId(null)
    setErrorMessage("")
    setShowError(false)
    setCheckRequires(false)
    resetFromData()
    values.type = 'Employee'
    setValues({ ...values })
  }

  const handleEditClick = (id) => {
    setTitle('Update Repeating Task')
    setModalValue(true)
    setSeletedId(id)
    getRepeatingTaskDataById(id)
    setErrorMessage("")
    setCheckRequires(false)
    setShowError(false)
  }

  const handelOpenDeletePopup = (id) => {
    setSeletedId(id)
    setModalDeleteValue(true)
  }

  const validateType = (emp, cli) => {
    if (emp) {
      return {
        type: "Employee",
        name: findFromChoice(emp, "employee_listing")
      }
    } else if (cli) {
      return {
        type: "Client",
        name: findFromChoice(cli, "client_listing")
      }
    } else {
      return null
    }
  }

  return (
    <div className='w-100 repeating-task'>
      <Toast ref={toast} />
      {createPermission && (
        <div className="d-flex justify-content-end" >
          <FloatingMobileButton
            style={{ marginTop: "-25px" , marginBottom: "-10px"}}
            onClick={handleModalOpen}
          >
            <FaPlus />
            <label>NEW REPEATING TASK</label>
          </FloatingMobileButton>
        </div>
      )}
      {/* <Header>  </Header> */}
      <div>
        {isLoading ?
          (<div style={{ marginTop: "300px" }} >
            <Spinner />
          </div>) :
          <>
            {
              repeatingTasks?.length ? (
                <div className='mt-4'>
                  <Table
                    headerColumns={headerColumns}
                    // handleAllCheck={handleAllCheck}
                    // handleAllSelectChange={handleAllSelectChange}
                    headerPadding={false}
                    // noNeed={false}
                    noEditOption={!deletePermission ? false : true}
                  >
                    {repeatingTasks?.map((task, index) => {
                      return (
                        <div key={index}>
                          <Row bgColor Shadow>
                            <ColsGrouper className="d-flex">
                              <Col
                                max_width={LARGE_COL}
                                style={{ marginLeft: "10px" }}
                                Center
                                className="sm-hidden"
                                Capitalize
                                overlap={true}
                              >
                                {findFromChoice(task.core_hr_task, "hr_task")}
                              </Col>

                              <Col
                                max_width={LARGE_COL}
                                Center
                                className="sm-hidden"
                                Capitalize
                                overlap={true}
                              >

                                {validateType(task.employee, task.client)?.type}
                              </Col>
                              <Col
                                max_width={LARGE_COL}
                                Center
                                className="sm-hidden"
                                Capitalize
                                overlap={true}
                              >
                                {validateType(task.employee, task.client)?.name}
                                {/* { } */}
                              </Col>
                              <Col
                                max_width={LARGE_COL}
                                Center
                                className="sm-hidden"
                                Capitalize
                                overlap={true}
                              >
                                {task.start_date}
                              </Col>
                              <Col
                                max_width={LARGE_COL}
                                Center
                                className="sm-hidden"
                                Capitalize
                                overlap={true}
                              >
                                {task.end_date}
                              </Col>
                              <Col
                                max_width={LARGE_COL}
                                Center
                                className="sm-hidden"
                                Capitalize
                                overlap={true}
                              >
                                {task.end_type}
                              </Col>
                              <Col
                                max_width={LARGE_COL}
                                Center
                                className="sm-hidden"
                                Capitalize
                                overlap={true}
                              >
                                {task.end_value}
                              </Col>
                              <Col
                                max_width={LARGE_COL}
                                Center
                                className="sm-hidden"
                                Capitalize
                                overlap={true}
                              >
                                {task.repeat_every}
                              </Col>
                              <Col
                                max_width={LARGE_COL}
                                Center
                                className="sm-hidden"
                                Capitalize
                                overlap={true}
                              >
                                {task.repeat_pattern}
                              </Col>
                              <Col
                                max_width={EX_VERY_LARGE_COL}
                                Center
                                className="sm-hidden"
                                Capitalize
                                overlap={true}
                              >
                                {task.repeat_on}
                              </Col>
                              <Col
                                // max_width={EX_SMALL_COL}
                                onClick={() => handleEditClick(task.id)}
                                className={deletePermission ? "sm-hidden" : "sm-hidden ms-2rem"}
                                style={{ marginRight: "10px" }}
                                Center
                                Icon
                                Purple>
                                <IconWrapper>
                                  <MdEdit />
                                </IconWrapper>
                              </Col>
                            </ColsGrouper>
                            {deletePermission && <Col
                              className="sm-hidden"
                              Center
                              Shrink
                              NoFlexGrow
                              style={{ marginRight: "10px" }}
                            >
                              {/* {deletePermission && ( */}
                              <IconWrapper
                                onClick={() => {
                                  handelOpenDeletePopup(task?.id)
                                }}>
                                <RiDeleteBinLine />
                              </IconWrapper>
                              {/* )} */}
                            </Col>}
                          </Row>
                          <div className="mb-1" />
                        </div>
                      )
                    })
                    }
                  </Table>
                  <div className="mt-3">
                    <Pagination
                      totalPages={pages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      values={values}
                      dropDownHandleSelect={dropDownHandleSelectPagination}
                      number={PAGE_SIZE_RENDER}
                      dataLength={repeatingTasks?.length}
                    />
                  </div>

                </div>
              ) :
                (<div className='d-flex w-100 align-items-center justify-content-center mt-3'>
                  <h4 style={{ fontSize: "1rem" }}>No data found</h4>
                </div>)
            }
          </>

        }
      </div>
      {modalValue && (
        <AddRepeatingTask
          fullBind={fullBind}
          useInput={useInput}
          values={values}
          setValues={setValues}
          seletedId={seletedId}
          setModalValue={setModalValue}
          toast={toast}
          readOnly={seletedId ? !updatePermission : !createPermission}
          choices={choices}
          setCheckRequires={setCheckRequires}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          showError={showError}
          setShowError={setShowError}
          getRepeatingTaskListData={getRepeatingTaskListData}
          daysOfWeek={daysOfWeek}
          setDaysOfWeek={setDaysOfWeek}
          errors={errors}
          modalLoading={modalLoading}
        />
      )}

      <ModalDecision
        type="delete"
        title="Warning"
        isdisabled={isdisabledDeleteButton}
        body={
          'Are you sure you want to delete Repeating Task?'
        }
        onOk={() => handleDeleteRepeatingTask()}
        onCancel={() => { }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  )
}

export default RepeatingTasks