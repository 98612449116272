import React, { useState, useEffect, Fragment } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { createOrganization, createOrganizationWithPlan, getSubscriptionById } from "utils/api/SettingsApi"
import _ from 'lodash';
import {
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import {
  ModalContainer,
  ContainerResponsive,
  ErrorMessage,
} from './styles';
import DateSelector from 'components/SharedComponents/DateSelector';
import RadioButton from 'components/SharedComponents/RadioButton';

const edit_employee = 'Edit Employee';

const AddModalSubscription = ({
  fullBind,
  Id,
  dateMessage,
  dateError,
  contextChoices,
  selectedOrg,
  subscriptionData,
  setModalValue = () => { },
  successCallback = () => { },
}) => {
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingModal, setLoadingModal] = useState(true);
  const [radioValue, setRadioValue] = useState('Is Free Trial');


  const dropDownHandleSelect = item => {
    const result = _.merge(values, item);
    setValues({ ...result });
  };


  useEffect(() => {
    if (showError && errors.length === 0) {
      setShowError(false);
      setCheckRequires(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.length]);

  useEffect(() => {
   // getSubData();
    if (selectedOrg) {
      getSubscriptionById(selectedOrg)
      .then((res)=>{
        if(res.data.length > 0){
          const subData = res.data[0];
        values.id= subData.id
        values.start_day= subData.start_date
          values.end_day= subData.end_date
          values.subscription_type= subData.subscription_type
          values.charges= subData.charges
          values.time_unit = subData.time_unit
          setValues({...values})
          if(subData.is_free_trial){
            setRadioValue('Is Free Trial')
          }else{
            setRadioValue('Is Paid')
          }
        }
        setLoadingModal(false)
      })
      .catch((err)=>{})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrg]);


  useEffect(() => {
    if (values.subscription_type && subscriptionData && subscriptionData.length > 0) {
      values.charges = subscriptionData.find((val) => val.id == values.subscription_type).final_price;
    } else {
      values.charges = ''
    }
    setValues({ ...values });
  }, [values.subscription_type])

  



  

  const handleSubmit = (evt, closeModal = false, triggerSave = true) => {
    evt.preventDefault();
    let planType = {}
    if (radioValue === 'Is Free Trial') {
      planType.is_free_trial = true;
      planType.is_paid = false;
    } else {
      planType.is_free_trial = false;
      planType.is_paid = true;
    }

    if (triggerSave) {
      setLoadingSave(true);
    } else {
      setLoadingFinish(true);
    }

    let body = {
      organization: selectedOrg,
      start_date: values.start_day,
      end_date: values.end_day,
      subscription_type: values.subscription_type,
      ...planType,
      charges: values.charges,
      time_unit: values.time_unit
    }
    createOrganizationWithPlan(body)
    .then(response => {
      setShowError(false);
      onSuccess(response, closeModal);
    })
    .catch(onError);
    
  };

  const onSuccess = async (response, closeModal = false) => {
    if (response.status === 'True' || response.status === 'True') {
      successCallback();
      if (closeModal) {
        setModalValue();
      }
      setLoadingSave(false);
      setLoadingFinish(false);
      setButtonIcon('');
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
    } else {
      setLoadingSave(false);
      setLoadingFinish(false);
      setButtonIcon('');
      setButtonIcon(CROSS);
      setTextStatus(ERROR);
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(response.message ? response.message : InvalidOrEmptyValues);
    }
  };

  const onError = err => {
    setLoadingSave(false);
    setLoadingFinish(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(
      err ? err?.message || InvalidOrEmptyValues : InvalidOrEmptyValues
    );
  };


  const handleOnChange = (e) => {
    setRadioValue(e.currentTarget.value)
  }



  return (
    <ModalContainer max_width={'90%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'90%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="p-2 p-sm-3">
          <div className="d-flex flex-column">
            <ContainerResponsive className="d-flex">
              <FlexGrid3Container className="w-100 mb-2">
                <FlexGrid3Item className="ps-0 pe-1">
                  <DateSelector
                    isRequired
                    disabled={false}
                    errorMessage={dateMessage}
                    labelTop="Start day"
                    data-error={dateError}
                    {...useInput('start_day', 'isRequired')}
                  />
                </FlexGrid3Item>

                <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                  <DateSelector
                    isRequired
                    disabled={false}
                    errorMessage={dateMessage}
                    labelTop="End day"
                    data-error={dateError}
                    {...useInput('end_day', 'isRequired')}
                  />
                </FlexGrid3Item>
                <FlexGrid3Item>
                  <DropdownSearchable
                    disabled={false}
                    placeHolder={'Subscription Type'}
                    options={subscriptionData ? subscriptionData : []}
                    selectedOption={
                      values.subscription_type
                    }
                    onChange={value =>
                      dropDownHandleSelect(
                        {
                          subscription_type: value ? value : null,
                        }
                      )
                    }
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pt-2 pe-1">
                  <DropdownSearchable
                    disabled={false}
                    placeHolder={'Time Unit'}
                    options={contextChoices.bill_time_unit}
                    selectedOption={
                      values.time_unit
                    }
                    onChange={value =>
                      dropDownHandleSelect(
                        {
                          time_unit: value ? value : null,
                        }
                      )
                    }
                  />
                </FlexGrid3Item>

                <FlexGrid3Item className="pt-2 pe-0 pe-md-1 ps-1">
                  <InputLabelTop
                    required
                    type="text"
                    id="tenant_name"
                    label={'Charges'}
                    {...useInput('charges', 'isRequired')}
                    maxlength={255}
                  />
                </FlexGrid3Item>
                <div className='d-flex ms-2 mt-2 pt-2'>
                  <RadioButton
                    options={['Is Free Trial', 'Is Paid']}
                    onChangeRadio={handleOnChange}
                    checkedValue={radioValue}
                  />
                </div>
              </FlexGrid3Container>
            </ContainerResponsive>



          </div>



          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {showError ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ms-0 ms-md-3 me-2'}>
                <PrimaryButtonForm
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                  onClick={handleSubmit}>
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>


            </div>
          </div>


        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default AddModalSubscription;
