import React, { useEffect, useState } from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import Image from 'components/SharedComponents/Image';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { UploadInput } from 'shared/styles/buttons';
import { FiUpload } from 'react-icons/fi';
import {
  TitleSection,
  ContainerResponsive,
  UserImgWrapper,
  UserImgContainer,
  UploadPhotoContainer,
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import moment from 'moment';
import PropTypes from 'prop-types';

const BasicInformation = ({
  useInput,
  basicInfoPhoto,
  choices,
  values,
  handlePickFile,
  dropDownHandleSelect,
  sectionStyle,
  companyBranches,
  linked_to,
  dropDownHandleRunRoute,
  setDisplayPhoto,
  readOnly,
  contextChoicesMap,
  handelPoorConnectivity
}) => {
  let myUseInput = useInput
  // let status_name = contextChoicesMap?.client_status && contextChoicesMap?.client_status[values.status] && contextChoicesMap.client_status[values.status]?.name
  // let isRequiredFinaldate = (values.status && status_name != 'Active' && status_name != 'Active - Probation');
  let final_client_date_hook = myUseInput('final_client_date')
  const poor_connectivity_hook = useInput('poor_connectivity')

  const [ageValue, setAgeValue] = useState(values.age || 0);

  useEffect(() => {
    values.age = values.date_of_birth
      ? `${moment().diff(values.date_of_birth, 'years')}`
      : 0;
    setAgeValue(values.age);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.date_of_birth]);

  useEffect(() => {
    setDisplayPhoto(values.photo)
  }, [
    values.photo
  ])




  console.log('final_client_date', values.final_client_date)

  return (
    <div className={sectionStyle}>
      <TitleSection>Basic information</TitleSection>

      <div className="d-flex flex-column mt-2">
        <ContainerResponsive className="d-flex">
          <div className="me-md-2 mb-2 align-items-stretch">
            <UserImgWrapper>
              <UserImgContainer>
                <Image
                  src={
                    basicInfoPhoto
                      ? URL.createObjectURL(basicInfoPhoto)
                      : values.photo
                        ? values.photo
                        : ''
                  }
                  name={values.first_name || values.full_name}
                  lastName={values.last_name}
                  alt={'basicInfo-img'}
                  fontSize={'1.5rem'}
                />
              </UserImgContainer>

              <UploadPhotoContainer className="d-flex align-items-center mt-2">
                <UploadInput
                  onInput={e => {
                    !readOnly && handlePickFile(e, 'photo');
                  }}
                  onClick={event => {
                    event.target.value = null;
                  }}
                  type="file"
                  accept={'.png,.jpg,.jpeg'}
                  id="basicInfo-photo"
                  name="basicInfo-photo"
                  placeholder={'Client Photo'}
                />
                <FiUpload />
                <label htmlFor="basicInfo-photo">Upload</label>
              </UploadPhotoContainer>
            </UserImgWrapper>
          </div>

          <div className="d-flex w-100 flex-column">
            <FlexGrid3Container>
              <FlexGrid3Item className="pe-1">
                <InputLabelTop
                  required
                  type="text"
                  id="first_name"
                  label={'First Name'}
                  readOnly={readOnly}

                  {...useInput('first_name', 'isRequired')}
                  maxlength={100}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                <InputLabelTop
                  required
                  readOnly={readOnly}
                  type="text"
                  id="last_name"
                  label={'Last Name'}
                  {...useInput('last_name', 'isRequired')}
                  maxlength={100}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                <InputLabelTop
                  type="text"
                  readOnly={readOnly}
                  id="middle_name"
                  label={'Middle Name'}
                  {...useInput('middle_name')}
                  maxlength={100}
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pe-1">
                <InputLabelTop
                  required
                  type="text"
                  readOnly={readOnly}
                  id="full_name"
                  label={'Full Name'}
                  {...useInput('full_name', 'isRequired')}
                  maxlength={255}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                <InputLabelTop
                  type="text"
                  readOnly={readOnly}
                  id="preferred_name"
                  label={'Preferred Name'}
                  {...useInput('preferred_name')}
                  maxlength={100}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                <InputLabelTop
                  type="text"
                  readOnly={readOnly}
                  id="prev_name"
                  label={'Previous Name'}
                  {...useInput('previous_name')}
                  maxlength={50}
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pe-1">
                <InputLabelTop
                  type="text"
                  id="initials"
                  readOnly={readOnly}
                  label={'Initials'}
                  {...useInput('initials')}
                  maxlength={50}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                <DropdownSearchable
                  placeHolder={'Title'}
                  options={choices.title}
                  disabled={readOnly}
                  selectedOption={values.title}
                  onChange={value =>
                    dropDownHandleSelect({ title: value ? value : null })
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                <DropdownSearchable
                  placeHolder={'Pronoun Category'}
                  options={choices.pronoun_category}
                  disabled={readOnly}
                  selectedOption={values.pronoun_category ? values.pronoun_category : null}
                  onChange={value =>
                    dropDownHandleSelect({
                      pronoun_category: value ? value : null,
                    })
                  }
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pe-1">
                <DropdownSearchable
                  placeHolder={'Gender'}
                  options={choices.gender}
                  disabled={readOnly}
                  selectedOption={values.gender}
                  onChange={value =>
                    dropDownHandleSelect({ gender: value ? value : null })
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                <DateSelector
                  labelTop="Date of birth"
                  {...useInput('date_of_birth')}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                <InputLabelTop
                  type="text"
                  id="age"
                  label={'Age'}
                  value={ageValue}
                  {...useInput('age')}
                  disabled
                />
              </FlexGrid3Item>

            </FlexGrid3Container>
          </div>
        </ContainerResponsive>

        <FlexGrid3Container>
          <FlexGrid3Item className="pe-1 mb-2">
            <div className='d-flex justify-content-between pe-2'>
              <ToggleButton
                label="Age estimated"
                useInput={useInput('age_estimated')}
                id="age_estimated"
              />
              <ToggleButton
                label="Low Network"
                useInput={poor_connectivity_hook}
                id="make_user"
                onClick={() => handelPoorConnectivity()}
              />
            </div>
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-1 pe-0 pe-md-1 mb-2">
            <DropdownSearchable
              required
              placeHolder={'Status'}
              disabled={readOnly}
              options={choices.client_status}
              selectedOption={values.status}
              {...useInput(`status`, 'isRequired')}
              onChange={(value, name) =>
                dropDownHandleSelect({ status: value ? value : null }, name, "status")
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0 mb-2">
            <DropdownSearchable
              placeHolder={'Client type'}
              disabled={readOnly}
              options={choices.client_type}
              selectedOption={values.type}
              onChange={value =>
                dropDownHandleSelect({ type: value ? value : null })
              }
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pe-1">
            <DropdownSearchable
              placeHolder={'Marital Status'}
              options={choices.marital_status}
              disabled={readOnly}
              selectedOption={values.marital_status}
              onChange={value =>
                dropDownHandleSelect({ marital_status: value ? value : null })
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
            <DropdownSearchable
              disabled={readOnly}
              placeHolder={'Linked to Client'}
              options={linked_to}
              selectedOption={values.linked_client}
              onChange={value =>
                dropDownHandleSelect({ linked_client: value ? value : null })
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
            <InputLabelTop
              type="text"
              readOnly={readOnly}
              id="reference"
              label={'Reference No.'}
              {...useInput('reference')}
              maxlength={50}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pe-1">
            <InputLabelTop
              type="text"
              id="ni_no"
              readOnly={readOnly}
              label={'NI Number / SS Ref'}
              {...useInput('ni_no')}
              maxlength={25}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
            <InputLabelTop
              type="text"
              readOnly={readOnly}
              id="nhs"
              label={'NHS No.'}
              {...useInput('nhs')}
              maxlength={25}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
            <DropdownSearchable
              width={'100%'}
              // marginLeft={'0.5%'}
              placeHolder={'Group Area'}
              disabled={readOnly}
              options={choices.run_routes}
              isMulti={true}
              selectedOptionsProps={
                values && values.run_routes && values.run_routes
              }
              onChange={value =>
                dropDownHandleRunRoute(
                  { run_route: value.length ? value : [] },
                )
              }
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pe-1">
            <DropdownSearchable
              required
              {...useInput(`branch`, 'isRequired')}
              placeHolder={'Branch Name'}
              disabled={readOnly}
              options={companyBranches}
              selectedOption={values.branch ? values.branch : null}
              {...useInput(`branch`, 'isRequired')}
              onChange={value =>
                dropDownHandleSelect({
                  branch: value ? value : null,
                })
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
            <DropdownSearchable
              required
              isMulti={true}
              {...useInput(`access_branch`, 'isRequired')}
              placeHolder={'Branch Access'}
              options={companyBranches}
              disabled={readOnly}
              {...useInput(`access_branch`, 'isRequired')}

              selectedOptionsProps={
                values
                  ? values.access_branch
                    ? values.access_branch
                    : null
                  : null
              }
              onChange={value =>
                dropDownHandleRunRoute(
                  { access_branch: value ? value : null },
                  'access_branch'
                )
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
            <InputLabelTop
              type="text"
              id="nhs"
              readOnly={readOnly}
              label={'Client Reason Finished'}
              {...useInput('reason_client_finished')}
              maxlength={25}
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pe-1">
            <DateSelector
              labelTop="Date Client Onboarded"
              {...useInput('client_onboarded_date')}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
            <DateSelector
              labelTop="Date Of Final Client Visit"
              // isRequired={isRequiredFinaldate}
              {...final_client_date_hook}
              value={values.final_client_date}
              onChange={value =>
                dropDownHandleSelect({
                  final_client_date: (value && value.target && value.target.value) ? moment(value.target.value).format('YYYY-MM-DD') : null,
                }, 'date')
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
            <DropdownSearchable
              placeHolder={'Age Banding'}
              options={choices.agebanding}
              disabled={readOnly}
              selectedOption={values.age_banding ? values.age_banding : null}
              onChange={value =>
                dropDownHandleSelect({
                  age_banding: value ? value : null,
                })
              }
            />
          </FlexGrid3Item>

          <FlexGrid3Item className="pe-1 mt-2">
            <DateSelector
              disabled={true}
              labelTop="Last Service Visit Date"
              {...useInput('last_service_visit_date')}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-1 pe-0 pe-md-1 mt-2">
            <InputLabelTop
              readOnly={true}
              type="text"
              id="last_serv"
              label={'Days Since last service visit'}
              {...useInput('no_days_since_last_worked')}
              maxlength={25}
            />
          </FlexGrid3Item>
        </FlexGrid3Container>
      </div>
    </div>
  );
};

BasicInformation.propTypes = {
  useInput: PropTypes.func.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  companyBranches: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  sectionStyle: PropTypes.string,
  linked_to: PropTypes.array.isRequired,
};

BasicInformation.defaultProps = {
  sectionStyle: '',
};

export default BasicInformation;
