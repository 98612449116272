import React from 'react'
import ModalDynamic from 'components/DashboardComponents/ModalDynamic'
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable'
import { useState } from 'react'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import { ModalContainer } from 'shared/styles/constants/tagsStyles'
import { FLOPPY } from 'utils/constants/icons'
import { SAVE, SAVED } from 'utils/constants/messages';
import { useEffect } from 'react'
import { updateSkillMatrix } from 'utils/api/SettingsApi'
import { useRef } from 'react'
import { Toast } from 'primereact/toast'

function UpdateModal({
    fullBind,
    loadingModal = false,
    choices, values,
    setValues, useInput,
    updateModalValue,
    seletedId,
    setUpdateModalValue,
    getSkillMatrixListData,
    toast,
    readOnly
}) {
    const [loadingSave, setLoadingSave] = useState(false);
    const [filteredskills, setFilteredSkills] = useState([])

    const dropDownHandleSelect = (item, name) => {
        if (name == 'branch') {
            values.skillMatrix.branch = item.branch;
        }
        else if (name == "skillCategory") {
            values.skillMatrix.skillCategory = item.skillCategory
        } else if (name == "skill") {
            values.skillMatrix.skill = item.skill
        }
        setValues({ ...values });
    }

    const filteredSkillsByCategory = () => {
        const filerSkill = choices?.skill?.filter(item => item.category === values?.skillMatrix?.skillCategory)
        setFilteredSkills(filerSkill)
    }


    const handleChange = (value) => {
        dropDownHandleSelect(
            { skillCategory: value ? value : null },
            'skillCategory'
        );
        filteredSkillsByCategory()
    }

    useEffect(() => {
        if (updateModalValue) {
            filteredSkillsByCategory()
        }
    }, [updateModalValue])

    const HandleUpdate = async () => {
        if (values.skillMatrix.branch && values.skillMatrix.skill) {
            const body = {
                branch: values.skillMatrix.branch,
                skill: values.skillMatrix.skill,
            };
            try {
                const res = await updateSkillMatrix(seletedId, body)
                if (res.success === true) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: `${'Skill matrix updated!'}`,
                    })
                    setUpdateModalValue(false)
                    getSkillMatrixListData()
                } else {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Failed',
                        detail: `${res.message}`,
                    });
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Please fill the mandatory fields`,
            });
        }

    }

    return (
        <ModalContainer max_width={'60%'}>
            <ModalDynamic {...fullBind} minWidthTitle="8.5rem" loading={false}>
                <div>
                    <div className="d-flex flex-column mt-2">

                        <div className='m-2'>
                            <DropdownSearchable
                                required
                                {...useInput(`skillMatrix.branch`, 'isRequired')}
                                placeHolder={'Branch'}
                                disabled={readOnly}
                                options={choices?.branch}
                                selectedOption={values?.skillMatrix?.branch ? values?.skillMatrix?.branch : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { branch: value ? value : null },
                                        'branch'
                                    )
                                }
                            />
                        </div>

                        <div className='m-2'>
                            <DropdownSearchable
                                required
                                disabled={readOnly}
                                {...useInput(`skillMatrix.skillCategory`, 'isRequired')}
                                placeHolder={'Skill Category'}
                                options={choices?.skill_category}
                                selectedOption={values?.skillMatrix?.skillCategory ? values?.skillMatrix?.skillCategory : null}
                                onChange={value => handleChange(value)}
                            />
                        </div>
                        <div className='m-2'>
                            <DropdownSearchable
                                required
                                disabled={readOnly}
                                {...useInput(`skillMatrix.skill`, 'isRequired')}
                                placeHolder={'Skill'}
                                options={filteredskills}
                                selectedOption={values?.skillMatrix?.skill ? values?.skillMatrix?.skill : null}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        { skill: value ? value : null },
                                        'skill'
                                    )
                                }
                            />
                        </div>

                    </div>

                    <div className={'d-flex justify-content-center justify-content-md-end mt-2'}>
                        <div className={'ms-0 ms-md-3 me-2'}>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                disabled={readOnly}
                                onClick={() => HandleUpdate()}
                                style={{ marginBottom: '5px' }}>
                                <span className={'me-2'}>SAVE</span>
                                {!loadingSave ? (
                                    FLOPPY
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm me-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </div>
                </div>
            </ModalDynamic>

        </ModalContainer>
    )
}

export default UpdateModal