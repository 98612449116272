import React, { Fragment } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { TIMES } from 'utils/constants/icons';
import DateSelector from 'components/SharedComponents/DateSelector';
import { CloseButtonWrapper, } from 'shared/styles/constants/tagsStyles';
import moment from 'moment';
import { useEffect } from 'react';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';

const AddMedication = ({
    values,
    setValues,
    nameSection = "",
    index = "",
    visitIndex = "",
    useInput,
    category = "",
    medicationOption = [],
    readOnly,
    editMode,
    toDelete = () => { },
    dropDownHandleSelect = () => { },
    toast = null,
    itemMed,
    getMedicationPrescriptionData,
    setLoadingCount = () => {}
}) => {
    const {
        setValue: setModalDeleteValue,
        bind: bindModalDelete } =
        useModal(false);

    useEffect(() => {
        (async () => {
            if (category === 'visits') {
                debugger
                let doseId = values.visits[visitIndex][nameSection][index]?.doseTime
                    && values.visits[visitIndex][nameSection][index]?.doseTime
                let res = await getMedicationPrescriptionData(doseId, itemMed)
                if (res?.data)
                    values.visits[visitIndex][nameSection][index]["prescriptionData"] = res?.data?.map(pres => ({ id: pres.id, name: pres.prescriptionName }));
                setLoadingCount(prev => prev +1)

            } else {
                let doseId = values[category][nameSection][index]?.doseTime
                    && values[category][nameSection][index]?.doseTime
                let res = await getMedicationPrescriptionData(doseId, itemMed)
                if (res?.data)
                    values[category][nameSection][index]["prescriptionData"] = res?.data?.map(pres => ({ id: pres.id, name: pres.prescriptionName }));

            }
        })();
    }, [])

    const effectiveFromHook = useInput(
        category === 'visits'
            ? `${category}[${visitIndex}].${nameSection}[${index}].effective_from`
            : `${category}.${nameSection}[${index}].effective_from`, 'isRequired'
    )


    const effectiveToHook = useInput(category === 'visits'
        ? `${category}[${visitIndex}].${nameSection}[${index}].effective_to`
        : `${category}.${nameSection}[${index}].effective_to`
    )

    const medication_hook = useInput(
        category === 'visits'
            ? `${category}[${visitIndex}].${nameSection}[${index}].doseTime`
            : `${category}.${nameSection}[${index}].doseTime`, 'isRequired'
    )


    const medication_prescription_hook = useInput(
        category === 'visits'
            ? `${category}[${visitIndex}].${nameSection}[${index}].prescriptions`
            : `${category}.${nameSection}[${index}].prescriptions`, 'isRequired'
    )


    const handleToast = () => {
        toast.current.show({
            severity: 'warn',
            summary: 'Warning',
            detail: 'Start and end dates must be between or equal to service start and service end dates.',
        });
    }
    console.log(values[category], values[category])

    const medicationPrescriptionOptions = category === 'visits'
        ? values.visits[visitIndex][nameSection][index]?.prescriptionData
        : values[category][nameSection][index]?.prescriptionData
    return (
        <>
            <div className={`d-flex ${index === 0 ? "mt-1" : "mt-4"} me-4}`}>
                {readOnly ? (
                    <Fragment />
                ) : (
                    <CloseButtonWrapper
                        disabled={!editMode}
                        onClick={() => {
                            // category ==='visits'? 
                            setModalDeleteValue(true);
                        }}
                    >
                        {TIMES}
                    </CloseButtonWrapper>
                )}
                <div className="w-100 me-2">
                    <DateSelector
                        isRequired
                        minDate={category === 'visits' ? "" : values[category]?.start_date || ""}
                        maxDate={category === 'visits' ? "" : values[category]?.end_date || ""}
                        labelTop="Effective From"
                        {...effectiveFromHook}
                    // disabled={category === 'visits'? !editMode : !values[category]?.start_date}
                    />
                </div>
                <div className="w-100 me-2">
                    <DateSelector
                        maxDate={category === 'visits' ? "" : values[category]?.end_date || moment().format("YYYY-MM-DD")}
                        minDate={category === 'visits' ? "" : values[category][nameSection][index]['effective_from'] || moment().format("YYYY-MM-DD")}
                        labelTop="Effective To"
                        {...effectiveToHook}
                    // disabled={category === 'visits'? !editMode : !values[category]?.end_date}
                    />
                </div>
                <div className="w-100">
                    <DropdownSearchable
                        disabled={!editMode}
                        // width={'33%'}
                        {...medication_hook}
                        required
                        marginLeft={'0.5%'}
                        placeHolder={'Medication'}
                        options={medicationOption}
                        // isMulti={true}
                        // selectedOptionsProps={category === 'visits'
                        //     ?
                        //     values.visits[visitIndex][nameSection][index]?.doseTime
                        //     && values.visits[visitIndex][nameSection][index]?.doseTime
                        //     :
                        //     values[category][nameSection][index]?.doseTime
                        //     && values[category][nameSection][index]?.doseTime
                        // }
                        selectedOption={
                            category === 'visits'
                                ?
                                values.visits[visitIndex][nameSection][index]?.doseTime
                                && values.visits[visitIndex][nameSection][index]?.doseTime
                                :
                                values[category][nameSection][index]?.doseTime
                                && values[category][nameSection][index]?.doseTime
                        }
                        onChange={value =>
                            dropDownHandleSelect(
                                { doseTime: value ? value : null },
                                'doseTime',
                                category,
                                visitIndex,
                                nameSection,
                                index,
                            )

                        }
                    />
                </div>
            </div>


            <div className="w-100 ps-4 me-2 mt-2">
                <DropdownSearchable
                    className="ms-1"
                    disabled={!editMode}
                    // width={'33%'}
                    {...medication_prescription_hook}
                    required
                    marginLeft={'0.5%'}
                    placeHolder={'Prescription'}
                    options={medicationPrescriptionOptions}
                    // isMulti={true}
                    selectedOptionsProps={category === 'visits'
                        ?
                        values.visits[visitIndex][nameSection][index]?.prescriptions
                        && values.visits[visitIndex][nameSection][index]?.prescriptions
                        :
                        values[category][nameSection][index]?.prescriptions
                        && values[category][nameSection][index]?.prescriptions
                    }
                    selectedOption={
                        category === 'visits'
                            ?
                            values.visits[visitIndex][nameSection][index]?.prescriptions
                            && values.visits[visitIndex][nameSection][index]?.prescriptions
                            :
                            values[category][nameSection][index]?.prescriptions
                            && values[category][nameSection][index]?.prescriptions
                    }
                    onChange={value =>
                        dropDownHandleSelect(
                            { prescriptions: value ? value : null },
                            'prescriptions',
                            category,
                            visitIndex,
                            nameSection,
                            index,
                        )

                    }
                />
            </div>
            <div className='delete-task-popup-resize'>
            <ModalDecision
                type="delete"
                title="Warning"
                body={
                    'Are you sure you want to delete this Medication? \n DELETING the medication will remove ALL records from database.'
                }
                onOk={() => {
                    toDelete(category, visitIndex, nameSection, index)
                    setModalDeleteValue(false);
                }}
                onCancel={() => {
                    setModalDeleteValue(false);
                }}
                okTitle={'DELETE'}
                {...bindModalDelete}
            />
       </div>
        </>
    );
}

export default AddMedication;