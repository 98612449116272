module.exports = {
  WHITE: '#fff',
  GRAY_100: '#fafafc',
  GRAY_200: '#ededed',
  GRAY_300: '#d9dadf',
  GRAY_400: '#ccc',
  GRAY_700: '#868686',
  GRAY_800: '#4a4a4a',
  GRAY_820: '#212529',
  BLACK_900: '#00000029',
  ERR_COLOR: '#F67550',
  ERR_COLOR_800: '#e0663f',
  INPUT_FOCUS_BC: '#3399ff',
  GRADIENT_GREEN: '#00F1C5',
  PURPLE_GRAY_500: '#6a6477',
  PURPLE: '#6200f9',
  PURPLE_800: '#6200f936',
  SOFT_RED: '#f67550',
  DARK_VIOLET: '#4c2882',
  RED: '#FF0000',
  ROTA_SHIFT: '#ffbc42',
  ROTA_AVAILABLE: '#A8E39B',
  ROTA_UNAVAILABLE: '#828282',
  ROTA_ABSENCE: '#343434',
  SCHEDULER_ASSIGNED: '#A8E39B',
  SCHEDULER_UNASSIGNED: '#fe6d73',
  TRAVEL_TIME_COLOR: '#FFA07A',
  //WAIT_TIME_COLOR: '#e9a0c5',
  WAIT_TIME_COLOR: '#c2c2c2',
  BREAK_WAIT_COLOR: '#F0E68C',
  CANCELLED_VIST: "#696969",
  SHADOW_VISIT: '#bfc414',
  OBSERVATION_VISIT: '#dee334',
  BLUE: '#00008B',
  LIGHTBLACK: '#454545',
  ERROR: '#d3302f',
  MATRIX_RED:"#dc3545",
  MATRIX_GREEN:"#198754",
  MATRIX_YELLOW:"#ffc107",
  NEW_DOUBLE_UP : "#CF9FFF",
  DOUBLE_UP_MISSED:"#EF5757",
  BREAK_COLOR: "#FF6666",
  ACTUAL_BREAK_COLOR: "#FF9999"
};
