import React, {Fragment} from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {TIMES} from 'utils/constants/icons';
import {CloseButtonWrapper} from 'shared/styles/constants/tagsStyles';
import {useEffect} from 'react';
import {useState} from 'react';

const AvoidDetails = ({
  values,
  setValues,
  dropDownLabel,
  dropDownList,
  nameSection,
  index,
  useInput,
  deleteAvoid,
  list_key,
  postal,
  dropDownHandleAvoid,
  medical,
  newMedical,
  indexMed,
  readOnly,
  setTempCheck,
}) => {
  // const handlePostalArea = e => {
  //   values[nameSection][index].area = e.target.value;
  //   setValues({...values});
  // };
  const [tempData, setTempData] = useState();
  useEffect(() => {
    let newData = values?.client_medical_condition?.map(
      item => item.client_medical_condition
    );
    if (newData) {
      setTempCheck(newData);
      setTempData(newData);
    }
  }, []);

  const noSendEmployeeHook = useInput(`${[nameSection]}[${index}].${list_key}`,postal ? "" :"isRequired")
  const postalAreaHook = useInput(`${[nameSection]}[${index}].area`, postal ? "isRequired" :"")

  const switchMedicalNameInput = props => {
    if (values[nameSection][index][newMedical]) {
      return (
        <div className="w-100 me-3">
          <InputLabelTop
            required
            type="text"
            id="medical_condition"
            label={dropDownLabel}
            {...props}
            maxlength={100}
          />
        </div>
      );
    } else {
      return <Fragment />;
    }
  };

  return (
    <>
      <div className={`d-flex mt-1 me-5 ms-0}`}>
        <CloseButtonWrapper
          onClick={() => {
            deleteAvoid(nameSection, index);
          }}>
          {TIMES}
        </CloseButtonWrapper>
        {postal ? (
          <div className="w-100 me-3">
            <InputLabelTop
              type="text"
              required
              id="area"
              label={'Postal Area'}
              readOnly={readOnly}
              // value={values[nameSection][index].area}
              // onChange={handlePostalArea}
               {...postalAreaHook}
              maxlength={255}
            />
          </div>
        ) : (
          <div className="w-100 me-3">
            <DropdownSearchable
              placeHolder={dropDownLabel}
              options={dropDownList}
              required
              {...noSendEmployeeHook}
              disabled={readOnly}
              selectedOption={
                values[nameSection]
                  ? values[nameSection][index][list_key]
                    ? values[nameSection][index][list_key]
                    : null
                  : null
              }
              onChange={value =>
                dropDownHandleAvoid(
                  {
                    avoid: value ? value : null,
                  },
                  nameSection,
                  index,
                  list_key
                )
              }
            />
          </div>
        )}

        {switchMedicalNameInput({
          ...useInput(
            `${[nameSection]}[${index}].new_medical_condition`,
            tempData === 'newMedical' ? '' : ''
          ),
        })}
        <div className="w-100">
          <InputLabelTop
            type="text"
            id="cl_avoid_reason"
            label={medical ? 'Medical Description' : 'Avoid Reason'}
            readOnly={readOnly}
            {...useInput(
              medical
                ? `${[nameSection]}[${index}].medical_desc`
                : `${[nameSection]}[${index}].reason`
            )}
            maxlength={255}
          />
        </div>
      </div>
    </>
  );
};

export default AvoidDetails;
