import React, { useEffect, Fragment, useState, useContext } from 'react';
import {
  CLIENT_SERVICE_CONTRACT,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from '../../../../utils/constants/permissions';
import { getPermissions } from '../../../../utils/localStorage/user';
import { PLAY, ERROR_OUTLINE } from 'utils/constants/icons';

import { PrimaryButtonForm } from 'shared/styles/buttons';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { KeyPress } from 'utils/constants/regularExpressions';
import { getAttachedRateCard, getRegularShiftById } from '../../../../utils/api/RotaShift';
import TravelRateCard from './TravelRateCard';
import moment from 'moment';
import _, { isArray } from 'lodash';
import {
  ErrorMessage,
  FlexEqual,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
} from 'utils/constants/serviceVisits';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import Shift from './Shift';
import { getPayRates } from 'utils/api/ClientApi';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'context';
import { useForm } from 'hooks/FormHook';
import { useModal } from 'hooks/ModalHook';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import ConfirmRegularShift from './ConfirmRegularShift';
import SelectPattern from './SelectPattern';
import {Toast} from 'primereact/toast';
import { useRef } from 'react';
import SplitOverlapModal from './SplitOverlapModal';
import { getEmployeeBranchAccess } from 'utils/api/EmployeeApi';
import { ErrorLabel } from 'components/SharedComponents/UpwardListDropdown/style';

const ModalRegularShifts = ({
  values,
  useInput,
  choices,
  setValues,
  errorMessage,
  addNewShift,
  deleteShift,
  nameSection,
  idSelected,
  setIdSelected,
  shiftModal,
  timeError,
  setTimeError,
  handleSubmit,
  getClientContracts,
  textStatusMain,
  toast,
  loadingSave,
  buttonIconMain,
  selectedIdEmp,
  createPermissionRegularShifts,
  updatePermissionRegularShifts,
  deletePermissionRegularShifts,
  setLoadingSave,
  setLoadingFinish,
  setCheckRequires,
  isValid,
  errors,
  setFormErrors,
  setErrorMessage, editMode, setEditMode, setEditToggle,
  shiftPatterns,errorResponse,  setErrorResponse
}) => {
  const FLOATING_BREAK = "break_details"
  const [showShifts, setShowShifts] = useState(false);
  const [showShiftsTravel, setShowShiftsTravel] = useState(false);
  const [dateMessage, setDateMessage] = useState('');
  const [modalLoading, setModalLoading] = useState(true);
  const [travelRate, setTravelRate] = useState([]);
  const [TravelData, setTravelData] = useState([]);
  const [rateCards, setRateCards] = useState([])
  const history = useHistory();
  const [showError, setShowError] = useState(false);
  const { contextPermissions } = useContext(AppContext);
  const [brachData, setBranchData] = useState([])
  const [travelRateCards,setTravelRateCards] = useState([])


  // const { isValid,errors,setErrorMessage,setFormErrors, setCheckRequires } =
  // useForm({});
  
  const getEmployeeBranches = async () => {
    try {
        const res = await getEmployeeBranchAccess(selectedIdEmp)
        setBranchData(choices?.branch?.filter(el => res?.employee_access_branches.includes(el.id)))
    } catch (error) {

    }
}
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind: fullBindBulkTriage,
  } = useModal(false);

  const {
    setValue: setModalSelectPattern,
    value: modalSelectPattern,
    setTitle: setSelectPatternTitle,
    fullBind: fullBindSelectPattern,
  } = useModal(false);
  useEffect(() => {
    const rolesPermissions = contextPermissions;

    let rolePermissions = {};
    if (rolesPermissions.length > 0) {
      rolesPermissions.forEach(
        item => {
          // if (item?.section?.name === CLIENT_PERSONAL_DETAILS) {
          rolePermissions[item.section.name] = {};
          rolePermissions[item.section.name][WEB_PERMISSION] = {};
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[item.section.name][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
        // }
      );
    }
  },[contextPermissions]);

  useEffect(() => {
    const {status,response} = errorResponse
    console.log(errorResponse,"errorResponse")
    if (status == "success") {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Successfully',
      });
      setErrorResponse({})
    } else if (status == "error") {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: response?.message && typeof response?.message == "string" ? response?.message : typeof response.message == "object" ? response.message.message : "Something went wrong",
      });
      setErrorResponse({})
    }
    // setTimeout(() => {
      // setModalValue(false);
   
    // }, 950);
  }, [errorResponse]);

  console.log(errors,"error22222")

  const addShiftFromPatterns = () => {
    setModalSelectPattern(true)
  }

  const handleDropDown = (item, nameItem) => {
    values.regularShifts[nameItem] = item[nameItem];
    setValues({ ...values });
  };

  const handleDropDownEndType = (item, nameItem) => {
    if(nameItem == "branch_id"){
     if( values.regularShifts['travelRate'] && values.regularShifts['travelRate'].length > 0) {
      values.regularShifts.travelRate = values.regularShifts.travelRate.map(el => ({...el, travel_rate : null}));
     }
    }
    values.regularShifts[nameItem] = item[nameItem];
    values.regularShifts.end_value = null;
    setValues({ ...values });
  };

  const switchEndValueInput = props => {
    // render correct input type depending of the end_type selected
    switch (values.regularShifts && values.regularShifts.end_type) {
      case END_TYPE_ON:
        // check if current value has date format
        const validDate = props.value
          ? moment(props.value, 'YYYY-MM-DD', true).isValid()
          : true;
        // if current value has date format then send props
        const dateProps = validDate ? props : {};
        return (
          <div className="mb-md-0 w-100  mb-lg-0 mb-2 me-2">
          <DateSelector isRequired labelTop={'End value'} {...dateProps} />
          </div>
        );
      case END_TYPE_AFTER:
        return (
          <div className="mb-md-0 w-100  mb-lg-0 mb-2 me-2">
          <InputLabelTop
            required
            onKeyPress={values.end_value ? KeyPress.POSITIVE : KeyPress.NATURAL}
            label={'End value'}
            type="text"
            id={`end_value`}
            {...props}
          />
          </div>
        );
      case END_TYPE_NEVER:
        return <Fragment />;
      default:
        return <Fragment />;
    }
  };

  const calculateEndValueProps = value => {
    switch (value) {
      case END_TYPE_ON:
        return 'isRequired';
      case END_TYPE_AFTER:
        return 'isInt, isRequired';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (idSelected) {
      searchByIdRegularShift();
    } else {
      values.regularShifts = {};
      values.regularShifts.name = null;
      values.regularShifts.every = null;
      values.regularShifts.name = null;
      values.regularShifts.start_date = null;
      values.regularShifts.end_date = null;
      values.regularShifts.end_type = null;
      values.regularShifts.end_value = null;
      values.regularShifts.repeat = null;
      values.regularShifts.shifts = [{}];
      // values.regularShifts.travelRate=[{ }]
      setShowShifts(true);
      setValues({ ...values });
      setModalLoading(false);
    }
  }, [shiftModal]);

  const searchByIdRegularShift = async () => {
    setShowShifts(false);
    const response = await getRegularShiftById(idSelected);
    if (response.id) {
      // values.regularShifts = {}
      values.regularShifts.name = response.name;
      values.regularShifts.every = response.every;
      values.regularShifts.name = response.name;
      values.regularShifts.start_date = response.start_date;
      values.regularShifts.end_date = response.end_date;
      values.regularShifts.end_type = response.end_type;
      values.regularShifts.end_value = response.end_value;
      values.regularShifts.repeat = response.repeat;
      values.regularShifts.branch_id = response.branch || null
      values.regularShifts.shifts = response?.details_list && response?.details_list.map((obj, index) => {
        return {
          id: obj.id,
          type: obj.type.id,
          travel_mode: obj.travel_mode && obj.travel_mode.id,
          end_time: obj.end_time,
          start_time: obj.start_time,
          effective_date: obj.effective_date,
          break_start_time: obj.break_start_time,
          break_end_time: obj.break_end_time,
          is_full_day: obj.is_full_day,
          is_overnight: obj.is_overnight,
          duration: obj.duration,
          travel_time: obj.travel_time && obj.travel_time,
          repeat_on: obj.repeat_on,
          regular_shift_pay_rate_id: obj.regular_shift_pay_rate,
          remote_work: obj.remote_work,
          break_details: obj.break_details
          // applicable_pay_rate_cards : obj?.applicable_pay_rate_cards
        };
      });
      values.regularShifts.travelRate = response.bill_details.map(
        (obj, index) => {
          return {
            id: obj.id,
            effective_date_from: obj.effective_date_from,
            effective_date_to: obj.effective_date_to,
            travel_rate_per_hour: obj.bill_rate_per_hour,
            travel_rate: obj.regular_shift_bill_rate,
          };
        }
      );

      setValues({ ...values });
      setShowShifts(true);
      setModalLoading(false);
    }
  };

  useEffect(() => {
    setLoadingSave(false)
    getEmployeeBranches()
    getPayRates().then(res => {
      setTravelData(res || []);
      setRateCards(res.map(el => ({ ...el, branch: el.branch.length ? el.branch.map(x => x.branch_id) : [] })))
      setTravelRate(
        res.filter(data => data.rate_card_type == 'Travel Rates') || []
      );
    });
  }, []);

  const hideAllService = () => {
    setModalValue(false);
  };
  const dropDownHandleRate = (item, nameSection, index, list_key) => {
    values.regularShifts.travelRate[index].travel_rate = item.travel_rate;
    values.regularShifts.travelRate[index].travel_rate_per_hour =
      TravelData.find(
        travelid => travelid.id == item.travel_rate
      ).rate_per_hour;
    setValues({ ...values });
  };

  const deleteRate = (nameSection, index) => {
    if (
      values.regularShifts.travelRate &&
      values.regularShifts.travelRate.length > 0
    ) {
      values.regularShifts.travelRate.splice(index, 1);
    }
    setValues({ ...values });
  };

  const addNewRate = () => {
    if (!values.regularShifts.travelRate) {
      values.regularShifts.travelRate = [{}];
      setValues({ ...values });
    } else {
      values.regularShifts.travelRate = [
        ...values.regularShifts.travelRate,
        {},
      ];
      setValues({ ...values });
    }
  };

  const handleDropAbsenceType = item => {
    if (item.absence_type || item.absence_type == null) {
      values.absence_reason = null;
      values.sickness_reason = null;
    }
    setValues({ ...values, ...item });
  };

  const handleCheck = (triggerSave = true) => {
    let haveTimeError = timeError.find(data => data === true);
    if ((errors.length > 0) || haveTimeError) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    } else {
      let allGood = false;
      values.regularShifts.shifts.length > 0 &&
        !isArray(values.regularShifts.shifts[0].repeat_on) &&
        values.regularShifts.shifts.forEach(shiftdata => {
          if (
            !shiftdata.type ||
            !shiftdata.start_time ||
            !shiftdata.end_time ||
            haveTimeError
          ) {
            setCheckRequires(true);
            setShowError(true);
            setErrorMessage(InvalidOrEmptyValues);
            allGood = true;
            return;
          }
          if (shiftdata.type) {
            if (
              shiftdata.type === '90a621e7-0433-49c2-853b-6802e75ae05f' ||
              shiftdata.type === 'c0a92801-7c3b-4248-8006-8cc5daa7a7bd'
            ) {
              if (!shiftdata.travel_mode) {
                setCheckRequires(true);
                setShowError(true);
                setErrorMessage(InvalidOrEmptyValues);
                allGood = true;
              }
            }
          }
        });
      if (allGood) {
        return;
      }
    }

    if (triggerSave) {
      setModalValue(true);
      setEditToggle(false)
      // setLoadingSave(true);
    } else {
      setLoadingFinish(true);
    }
    setCheckRequires(false);
    setShowError(false);
  };

  const getRateCardName = (id) => {
    let match = rateCards && rateCards.find(el => el.id === id)

    return match
      ? match.name.charAt(0).toUpperCase() + match.name.slice(1)

      : ""
  }


  const getPayRateCards = async () => {
    // let shift_type = travelRateCards && travelRateCards.find(shift => shift.id === values.regularShifts.travelRate[index].travel_rate)
    if (!values.regularShifts.branch_id || !values?.regularShifts?.start_date) {
      return;
    }
    debugger
    try {
      const body = {
        employeeId: selectedIdEmp,
        branch: values.regularShifts.branch_id,
        // pay_type: shift_type?.pay_type_name,
        start_date: values?.regularShifts?.start_date,
        end_date: values?.regularShifts?.end_date
      }
      const res = await getAttachedRateCard(body,"is_travel")
      if(res.status == true){
        values.regularShifts.travelRate = res.data.rate_card
      setValues({...values})
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    getPayRateCards()
  }, [
    // travelRateCards, 
    values.regularShifts.branch_id,
    //  values.regularShifts.travelRate,
      values?.regularShifts?.start_date,
       values?.regularShifts?.end_date])

      //  useEffect(() => {
      //   setTravelRateCards(travelRate.filter(data =>  data.branch.includes(values?.regularShifts?.branch_id)))
      // },[travelRate])
console.log(values.regularShifts, "regular Shift")
  return (
    <ComponentDynamic loading={modalLoading}>
      <div max_width={'90%'}>
        <div className="d-md-flex mt-2">
          <div className="w-100 mb-md-0 mb-lg-0 mb-2 me-2">
            <InputLabelTop
              disabled={!editMode}
              required
              type="text"
              id="name_shift"
              label={'Regular Shift Name'}
              {...useInput('regularShifts.name', 'isRequired')}
              maxlength={100}
            />
          </div>
          <div className="w-100 mb-md-0 mb-lg-0 mb-2 me-2">
            <DateSelector
              disabled={!editMode}
              //   disabled={readOnly}
              isRequired
              labelTop={'Start date'}
              {...useInput(`regularShifts.start_date`, 'isRequired')}
            />
          </div>
          <div className="w-100 mb-md-0 mb-lg-0 mb-2 me-2">
            <DateSelector
              disabled={!editMode}
              //   disabled={readOnly}
              // isRequired
              minDate={values.regularShifts.start_date}
              labelTop={'End date'}
              {...useInput(`regularShifts.end_date`)}
            />
          </div>
        </div>
        <div className="d-md-flex mt-2 mb-2">
          <div className="w-100 mb-md-0 mb-lg-0 mb-2 me-2">
            <DropdownSearchable
              disabled={!editMode}
              required
              // {...useInput(`every`, 'isRequired')}

              {...useInput(`${[nameSection]}.every`, 'isRequired')}
              placeHolder={'Every'}
              options={[
                { id: EVERY_DAY, name: 'Day' },
                { id: EVERY_WEEK, name: 'Week' },
                { id: EVERY_MONTH, name: 'Month' },
                { id: EVERY_YEAR, name: 'Year' },
              ]}
              selectedOption={
                values.regularShifts ? values.regularShifts.every : null
              }
              onChange={value =>
                handleDropDown({ every: value ? value : null }, 'every')
              }
            />
          </div>
          <div className="w-100 mb-md-0 mb-lg-0 mb-2 me-2">
            <InputLabelTop
              disabled={!editMode}
              //   disabled={readOnly}
              type="text"
              label={'Repeat'}
              id={`repeat`}
              onKeyPress={KeyPress.NATURAL}
              {...useInput(`regularShifts.repeat`)}
              maxlength={5}
            />
          </div>
          <div className="w-100 mb-md-0 mb-lg-0 mb-2 me-2">
            <DropdownSearchable
              disabled={!editMode}
              required
              // {...useInput(`end_type`, 'isRequired')}
              // data_error={errors.indexOf(`end_type`)}
              //   disabled={readOnly}
              {...useInput(`${[nameSection]}.end_type`, 'isRequired')}
              placeHolder={'End'}
              options={[
                { id: END_TYPE_ON, name: 'On' },
                { id: END_TYPE_AFTER, name: 'After' },
                { id: END_TYPE_NEVER, name: 'Never' },
              ]}
              selectedOption={
                values.regularShifts ? values.regularShifts.end_type : null
              }
              onChange={value =>
                handleDropDownEndType(
                  { end_type: value ? value : null },
                  'end_type'
                )
              }
            />
          </div>
        </div>
        <div className="d-md-flex mt-2 mb-2">
        
          {switchEndValueInput({
            ...useInput(
              `regularShifts.end_value`,
              calculateEndValueProps(
                values.regularShifts && values.regularShifts.end_type
              )
            ),
          })}
        <div className="mb-md-0 w-100 mb-lg-0 mb-2 me-2">
        <DropdownSearchable
              disabled={!editMode}
              required
              {...useInput(`${[nameSection]}.branch_id`, 'isRequired')}
              placeHolder={'Branch'}
              options={brachData && brachData}
              selectedOption={
                values.regularShifts ? values.regularShifts.branch_id : null
              }
              onChange={value =>
                handleDropDownEndType(
                  { branch_id: value ? value : null },
                  'branch_id'
                )
              }
            />
        </div>
        </div>
        <div className="w-100 mb-md-0 mb-lg-0 mb-3  mt-2  pb-2 me-2">
          <FlexEqual>

            {/* {values &&
              values.regularShifts &&
              values.regularShifts.travelRate &&
              values.regularShifts.travelRate.length > 0 &&
              values.regularShifts &&
              values.regularShifts.travelRate &&
              values.regularShifts.travelRate.map((rate, index) => {
                return (
                  <TravelRateCard
                    values={values}
                    setValues={setValues}
                    travelRate={rateCards}
                    dropDownHandleRate={dropDownHandleRate}
                    useInput={useInput}
                    nameSection={`regularShifts.travelRate`}
                    index={index}
                    deleteRate={deleteRate}
                    rate={rate}
                    deletePermissionRegularShifts={
                      deletePermissionRegularShifts
                    }
                    rateCards={rateCards}
                    selectedIdEmp={selectedIdEmp}
                  />
                );
              })}
            <PrimaryButtonForm
              disabled={!editMode}
              className={'ms-3 mt-1 mb-2'}
              bgcolor="BLUE"
              minWidth="3rem"
              onClick={addNewRate}>
              <span>{<BsFillPlusSquareFill />}Travel Rate Card</span>
            </PrimaryButtonForm> */}

            {<div className='ms-4 mt-1 mb-2'>
              <TitleSection style={{ fontWeight: '450' }}>Travel Rate Cards</TitleSection>
              {values?.regularShifts?.travelRate
                && values?.regularShifts.travelRate ?
                <div>
                  <ol>{values.regularShifts?.travelRate.map(el => {
                    return (<li>{`${getRateCardName(el.rate_card_id)} - (${el.start_date} - ${el.end_date ? el.end_date : "Unset"})`}</li>)
                  })}
                  </ol>
                </div>
                :
                <ErrorLabel className='ms-2'>
                  Please attach rate card for this employee.
                </ErrorLabel>}
            </div>}
          </FlexEqual>
        </div>

        {showShifts &&
          values.regularShifts &&
          values.regularShifts.shifts &&
          values.regularShifts.shifts.map((shift, index) => {
            return (
              <div className={'mb-3'}>
                <Shift
                  editMode={editMode}
                  selectedIdEmp={selectedIdEmp}
                  values={values}
                  TravelData={TravelData}
                  rateCards={rateCards}
                  setValues={setValues}
                  useInput={useInput}
                  choices={choices}
                  deleteShift={deleteShift}
                  shiftIndex={index}
                  shift={shift}
                  nameSection={`regularShifts.shifts[${index}]`}
                  idSelected={idSelected}
                  setIdSelected={setIdSelected}
                  shiftModal={shiftModal}
                  setTimeError={setTimeError}
                  timeError={timeError}
                  handleDropAbsenceType={handleDropAbsenceType}
                  deletePermissionRegularShifts={deletePermissionRegularShifts}
                  errors={errors}
                  setFormErrors={setFormErrors}
                  FLOATING_BREAK={FLOATING_BREAK}
                />
              </div>
            );
          })}
        <div className='d-flex'>
          <div className='pe-1'>
            <PrimaryButtonForm bgcolor="BLUE" minWidth="3rem" onClick={addNewShift} disabled={!editMode}>
              <span>{<BsFillPlusSquareFill />}</span>
            </PrimaryButtonForm>
          </div>
          <div className=''>
            <PrimaryButtonForm bgcolor="BLUE" minWidth="3rem" onClick={addShiftFromPatterns} disabled={!editMode}>
              <span>{<BsFillPlusSquareFill />} shifts from shift pattern</span>
            </PrimaryButtonForm>
          </div>
        </div>
        <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div className={'mb-3'}>
            {showError ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>

          <div
            className={'d-flex justify-content-center justify-content-md-end'}>
            <div className={'ms-0 ms-md-3 me-2'}>
              <PrimaryButtonForm
                disabled={!editMode}
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                className={'me-2'}
                onClick={() => {
                  history.push('/team');
                }}>
                <span className={'me-0'}>{'Cancel'}</span>
              </PrimaryButtonForm>
              {(selectedIdEmp
                ? updatePermissionRegularShifts
                : createPermissionRegularShifts) && (
                  <PrimaryButtonForm
                    disabled={!editMode || loadingSave}
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    onClick={handleCheck}>
                    <span className={'me-2'}>{textStatusMain}</span>
                    {!loadingSave ? (
                      buttonIconMain
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                )}
            </div>
            <div className='justify-content-start text-start'>
            {modalSelectPattern && (
              <SelectPattern
                fullBind={fullBindSelectPattern}
                setModalSelectPattern={setModalSelectPattern}
                shiftPatterns={shiftPatterns}
                parentValues={values}
                setParentValues={setValues}
                toast={toast}
              />
            )}
            </div>
           
            {modalValue && (
              <ConfirmRegularShift
                getClientContracts={getClientContracts}
                // selectedClientId={selectedClientId}
                type={'warning'}
                title={'CONFIRM SERVICE CONTRACT DATA'}
                okTitle={'NO, CANCEL'}
                onCancelTitle={'YES, PROCEED'}
                icon={ERROR_OUTLINE}
                hideOnCancel={false}
                onCancel={hideAllService}
                setModalValue={setModalValue}
                // setModalValue={setBulkAlertModalValue}
                // allErrors={allErrors}
                fullBind={fullBindBulkTriage}
                values={values}
                false={false}
                true={true}
                // toDeleteVisits={toDeleteVisits}
                // toDeleteSplitFunder={toDeleteSplitFunder}
                // toDeleteVisitSkill={toDeleteVisitSkill}
                // toDeleteVisitEmployee={toDeleteVisitEmployee}
                handleSubmit={handleSubmit}
                showError={showError}
              // onOk={() => {
              //   setShowDeleteVisitsModal(false);
              // }}
              // showModal={setShowDeleteVisitsModal}
              // showAllService={showAllService}
              />
            )}
          </div>
        </div>
      </div>
    </ComponentDynamic>
  );
};

export default ModalRegularShifts;
