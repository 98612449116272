import React, { useState, useEffect, Fragment } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm, UploadInput } from 'shared/styles/buttons';
import moment from 'moment';
import {
  setEmployee,
  getEmployee,
  getEmployees,
  setEmployeeSkill,
  deleteEmployeeSkill,
  savePreferredClients,
  deletePreferredClients,
  saveAvoidClients,
  deleteAvoidClients,
  getPreferredClients,
  getAvoidClients,
} from 'utils/api/EmployeeApi';
import { createOrganization, createOrganizationWithPlan, uploadOrganizationLogo } from "utils/api/SettingsApi"
import { KeyPress } from 'utils/constants/regularExpressions';
import _ from 'lodash';
import {
  Toggle,
  ToggleLabel,
  ToggleWrapper,
} from 'shared/styles/buttons';
import {
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import {
  ModalContainer,
  UserImgContainer,
  UserImgWrapper,
  TitleSection,
  FlexEqual,
  ShowTab,
  TabStyled,
  ContainerResponsive,
  ErrorMessage,
  UploadPhotoContainer,
} from './styles';
import { getRoles, getSubscription } from '../../../../../utils/api/UsersApi';
import DateSelector from 'components/SharedComponents/DateSelector';
import RadioButton from 'components/SharedComponents/RadioButton';
import { FiUpload } from 'react-icons/fi';
import { pickFile } from 'utils/files';
import Image from 'components/SharedComponents/Image';

const tab1 = 1;
const tab2 = 2;
const tab3 = 3;
const edit_employee = 'Edit Employee';

const Organizations = ({
  fullBind,
  Id,
  dateMessage,
  dateError,
  contextChoices,
  setModalValue = () => { },
  successCallback = () => { },
}) => {
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});
  const [tabSelected, setTabSelected] = useState(tab1);
  const [choices, setChoices] = useState({ employee_status: [] });
  const [reportsTo, setReportsTo] = useState([]);
  const [companyBranches, setCompanyBranches] = useState([]);
  const [employeePhoto, setEmployeePhoto] = useState(null);
  const [nokPhoto, setNokPhoto] = useState(null);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [skillsToDelete, setSkillsToDelete] = useState([]);
  const [isRequiredValue, setIsRequiredValue] = useState('');
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingModal, setLoadingModal] = useState(true);
  const [clientList, setClientList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [preferredClients, setPreferredClients] = useState([]);
  const [avoidClients, setAvoidClients] = useState([]);
  const [preferredSelected, setPreferredSelected] = useState([]);
  const [avoidedSelected, setAvoidedSelected] = useState([]);
  const [initialPreferredList, setInitialPreferredList] = useState([]);
  const [initialAvoidList, setInitialAvoidList] = useState([]);
  const [avoidClientsToAdd, setAvoidClientsToAdd] = useState([]);
  const [avoidClientsToDelete, setAvoidClientsToDelete] = useState([]);
  const [preferredClientsToAdd, setPreferredClientsToAdd] = useState([]);
  const [preferredClientsToDelete, setPreferredClientsToDelete] = useState([]);
  const [makeUser, setMakeUser] = useState(false);
  const [freeTrial, setFreeTrial] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [radioValue, setRadioValue] = useState('Is Free Trial');
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [orgLogo, setOrgLogo] = useState()
  useEffect(() => {
    if (tabSelected === tab1) {
      setIsRequiredValue('');
    } else if (tabSelected === tab2 || tabSelected === tab3) {
      setIsRequiredValue('isRequired');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  const dropDownHandleSelect = item => {

    //recursive objects merge
    const result = _.merge(values, item);
    setValues({ ...result });
  };

  useEffect(() => {
    // load values when modal is visible true
    if (fullBind.value) {
      // loadChoices();
      // loadReportsTo();
      // loadCompanyBranches();
      loadRoles();
    }
    // get info and display it
    if (Id) {
      loadEmployee();
    } else {
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Id]);

  useEffect(() => {
    if (showError && errors.length === 0) {
      setShowError(false);
      setCheckRequires(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors.length]);

  useEffect(() => {
    if (values.id) {
      setAddFullBind({ ...addFullBind, title: edit_employee });
    }
    getSubData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id]);

  useEffect(() => {
    if (textStatus !== SAVE) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }
    if (values.make_user === undefined && values.role) {
      values.make_user = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (values.subscription_type) {
      values.charges = subscriptionData.find((val) => val.id == values.subscription_type).final_price;

    } else {
      values.charges = ''
    }
    setValues({ ...values });
  }, [values.subscription_type])

  const getSubData = () => {
    getSubscription(values)
      .then((res) => {
        setSubscriptionData(res.results);
      })
      .catch((err) => { })
  }

  // useEffect(() => {
  //   if (!values.make_user) {
  //     let result = _.omit(values, ['role', 'role_id']);
  //     setValues(result);
  //     setMakeUser(false);
  //   } else {
  //     setMakeUser(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values.make_user]);

  // useEffect(() => {
  //   if (values.preference) {
  //     getPreferredClients(values.preference.id).then(response => {
  //       setInitialPreferredList(response.results);
  //     });

  //     getAvoidClients(values.preference.id).then(response => {
  //       setInitialAvoidList(response.results);
  //     });
  //   }
  // }, [values.preference]);

  // useEffect(() => {
  //   const preferred_clients = preferredSelected.map(preferred => {
  //     return typeof preferred === 'string'
  //       ? preferred
  //       : preferred.client
  //       ? preferred.client
  //       : preferred.value;
  //   });
  //   setPreferredClients(preferred_clients);
  // }, [preferredSelected]);

  // useEffect(() => {
  //   const avoid_clients = avoidedSelected.map(avoid => {
  //     return typeof avoid === 'string'
  //       ? avoid
  //       : avoid.client
  //       ? avoid.client
  //       : avoid.value;
  //   });
  //   setAvoidClients(avoid_clients);
  // }, [avoidedSelected]);

  useEffect(() => {
    if (initialPreferredList && values.preference) {
      let initialComparison = {};
      let currentComparison = {};

      if (
        values.preference.preferred_clients.length > 0 &&
        initialPreferredList.length > 0
      ) {
        initialPreferredList.forEach(item => {
          if (!initialComparison[item.client])
            initialComparison[item.client] = item.id;
        });
      }

      let addList = preferredClients.filter(
        client => !initialComparison[client]
      );

      if (addList.length > 0) {
        setPreferredClientsToAdd(addList);
      } else {
        setPreferredClientsToAdd([]);
      }

      preferredClients.forEach(item => {
        if (!currentComparison[item]) currentComparison[item] = item;
      });

      let deleteList = initialPreferredList
        .filter(client => {
          if (!currentComparison[client.client]) {
            return client;
          }
          return null;
        })
        .map(item => item.id);

      if (deleteList.length > 0) {
        setPreferredClientsToDelete(deleteList);
      } else {
        setPreferredClientsToDelete([]);
      }
    }
  }, [preferredClients, initialPreferredList, values.preference]);

  useEffect(() => {
    if (initialAvoidList && values.preference) {
      let initialComparison = {};
      let currentComparison = {};

      if (
        values.preference.avoid_clients.length > 0 &&
        initialAvoidList.length > 0
      ) {
        initialAvoidList.forEach(item => {
          if (!initialComparison[item.client])
            initialComparison[item.client] = item.id;
        });
      }

      let addList = avoidClients.filter(client => !initialComparison[client]);

      if (addList.length > 0) {
        setAvoidClientsToAdd(addList);
      } else {
        setAvoidClientsToAdd([]);
      }

      avoidClients.forEach(item => {
        if (!currentComparison[item]) currentComparison[item] = item;
      });

      let deleteList = initialAvoidList
        .filter(client => {
          if (!currentComparison[client.client]) {
            return client;
          }
          return null;
        })
        .map(item => item.id);

      if (deleteList.length > 0) {
        setAvoidClientsToDelete(deleteList);
      } else {
        setAvoidClientsToDelete([]);
      }
    }
  }, [avoidClients, initialAvoidList, values.preference]);

  // const loadChoices = async () => {
  //   const response = await getChoices();
  //   setChoices(response || []);
  // };



  // const loadCompanyBranches = async () => {
  //   const response = await getCompanyBranches();
  //   setCompanyBranches(response.results || []);
  // };

  const loadRoles = async () => {
    const response = await getRoles(values);
    setRolesList(response.results || []);
  };

  const loadEmployee = async () => {
    const response = await getEmployee(Id);

    if (!response.skills) {
      response.skills = [];
    }
    if (
      response.preference &&
      response.preference.avoid_clients &&
      response.preference.avoid_clients.length > 0
    ) {
      setAvoidClients(response.preference.avoid_clients);
    } else {
      setAvoidClients([]);
    }
    if (
      response.preference &&
      response.preference.preferred_clients &&
      response.preference.preferred_clients.length > 0
    ) {
      setPreferredClients(response.preference.preferred_clients);
    } else {
      setPreferredClients([]);
    }

    setValues({ ...values, ...response });
    setLoadingModal(false);
  };

  const isCurrentTab = tab => {
    return tabSelected === tab;
  };

  const handleSubmit = (evt, closeModal = false, triggerSave = true) => {
    evt.preventDefault();
    // if (loadingSave || loadingFinish) {
    //   return;
    // }
    if (errors.length) {
      setCheckRequires(true)
      setShowError(true)
      setErrorMessage('Some of the fields have invalid or empty values. Please review');
      return;
    }
    setSaveDisabled(true)
    let planType = {}
    if (radioValue === 'Is Free Trial') {
      planType.is_free_trial = true;
      planType.is_paid = false;
    } else {
      planType.is_free_trial = false;
      planType.is_paid = true;
    }

    let body = {
      name: values.tenant_name,
      is_active: true,
      address: values.address,
      email: values.contact && values.contact.email_personal,
      username: values.tenant_name + "_" + Math.random().toString(36).substring(7),
      first_name: values.first_name,
      last_name: values.last_name,
      subscription: {
        start_date: values.start_day,
        end_date: values.end_day,
        subscription_type: values.subscription_type,
        ...planType,
        charges: values.charges,
        time_unit: values.time_unit
      }
    };


    if (triggerSave) {
      setLoadingSave(true);
    } else {
      setLoadingFinish(true);
    }

    let value = [];
    createOrganization(body)
      .then(response => {
        // if (response.status === 400) {
        //   if (response.message) {
        //     value = Object.values(response.message)[0];
        //   }
        //   throw new Error(value);
        // }
        setShowError(false);
        onSuccess(response, closeModal);
      })
      .catch(onError);
  };

  const addSubscriptionOrg = (response) => {
    let body = {
      organization: '69c98381-8c12-41d8-9762-2073d32c73cd',
      start_date: values.start_day,
      end_date: values.end_day,
      subscription_type: values.subscription_type,
      is_free_trial: freeTrial,
      is_paid: !freeTrial,
      charges: values.charges,
      time_unit: values.time_unit
    }
    createOrganizationWithPlan(body)
      .then(() => { })
      .catch((err) => { })
  }

  const onSuccess = async (response, closeModal = false) => {
    if (response.statusCode === 200 || response.statusCode === 201) {

      // addSubscriptionOrg(response)


      // setValues({
      //   ...values,
      //   id: employeeId,
      //   is_active: response.message.is_active,
      // });

      successCallback();
      //Upload log api call
      if (orgLogo && response?.hasOwnProperty('id')) {
        saveOrgLogo(response.id)
      }
      if (closeModal) {
        setModalValue();
      }
      
      setLoadingSave(false);
      setSaveDisabled(false)
      setLoadingFinish(false);
      setButtonIcon('');
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
    } else {
      setLoadingSave(false);
      setLoadingFinish(false);
      setSaveDisabled(false)
      setButtonIcon('');
      setButtonIcon(CROSS);
      setTextStatus(ERROR);
      setCheckRequires(true);
      setShowError(true);
      if (response.email) {
        setErrorMessage('This email ID already exit');
      } else {
        setErrorMessage(InvalidOrEmptyValues);
      }
    }
  };

  const onError = err => {
    setLoadingSave(false);
    setSaveDisabled(false)
    setLoadingFinish(false);
    setButtonIcon('');
    setCheckRequires(true);
    setShowError(true);
    if (err.email) {
      setErrorMessage('This email ID already exit');
    } else {
      setButtonIcon(CROSS);
      setTextStatus(ERROR);
      setErrorMessage(
        err ? err?.message || InvalidOrEmptyValues : InvalidOrEmptyValues
      );
    }
  };

  const contactSection = 'contact';

  const handleOnChange = (e) => {
    setRadioValue(e.currentTarget.value)
  }
  // pick file from local device
  const handlePickFile = async (evt, section) => {

    const result = await pickFile(evt);
    console.log(result, 555333)
    switch (section) {
      case 'photo':
        setOrgLogo(result.file);
        break;
      case 'nok':
        // setNokPhoto(result.file);
        break;
      default:
    }
  };

  // Save ORG Logo Function Declaration
  const saveOrgLogo = async (id) => {
    const body = orgLogo;
    try {
      await uploadOrganizationLogo(id, body)
    } catch (error) {
      console.error('Error: uploading ORG logo API', error)
    }
  }
  return (
    <ModalContainer max_width={'90%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'90%'}
        minWidthTitle="8.5rem"
        // showRowInfo
        // photoRow={
        //   employeePhoto
        //     ? URL.createObjectURL(employeePhoto)
        //     : values.photo
        //     ? values.photo
        //     : ''
        // }
        // name={values.first_name || values.full_name}
        // lastName={values.last_name}
        // rowInfoData={[
        //   {label: 'Name:', value: values.full_name},
        //   {
        //     label: 'DOB:',
        //     value: values.date_of_birth
        //       ? `${moment(values.date_of_birth).format(
        //           'DD-MM-YYYY'
        //         )} (${moment().diff(values.date_of_birth, 'years')})`
        //       : '',
        //   },
        // ]}
        loading={loadingModal}>
        <div className="p-2 p-sm-3 p-md-4">
          <div className="d-flex justify-content-around text-center">
            <TabStyled
              active={isCurrentTab(tab1)}
              onClick={() => {
                setTabSelected(tab1);
              }}>
              {' '}
              Personal Details
            </TabStyled>

          </div>

          <ShowTab show={isCurrentTab(tab1)}>
            <TitleSection>Basic Information</TitleSection>

            <div className="d-flex flex-column mt-2">
              <ContainerResponsive className="d-flex">
                <div className="me-md-2 mb-2 align-items-stretch">
                  <UserImgWrapper>
                    <UserImgContainer>
                      <Image
                        src={
                          orgLogo
                            ? URL.createObjectURL(orgLogo)
                            : values.logo
                              ? values.logo
                              : ''
                        }
                        name={values.first_name || values.full_name}
                        lastName={values.last_name}
                        alt={'organization-img'}
                        fontSize={'1.5rem'}
                      />
                    </UserImgContainer>

                    <UploadPhotoContainer className="d-flex align-items-center mt-2">
                      <UploadInput
                        onInput={e => {
                          handlePickFile(e, 'photo');
                        }}
                        onClick={event => {
                          event.target.value = null;
                        }}
                        type="file"
                        accept={'.png,.jpg,.jpeg'}
                        id="organization-logo"
                        name="organization-logo"
                        placeholder={'Logo'}
                      />
                      <FiUpload />
                      {
                        <label htmlFor="organization-logo">Upload</label>
                      }

                    </UploadPhotoContainer>
                  </UserImgWrapper>
                </div>

                <FlexGrid3Container className="w-100 mb-2">
                  <FlexGrid3Item ResizeWidth={'100%'} className="pe-1">
                    <InputLabelTop
                      required
                      type="text"
                      id="first_name"
                      label={'First Name'}
                      {...useInput('first_name', 'isRequired')}
                      maxlength={100}
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item ResizeWidth={'100%'}className=" pe-0 pe-md-1">
                    <InputLabelTop
                      required
                      type="text"
                      id="last_name"
                      label={'Last Name'}
                      {...useInput('last_name', 'isRequired')}
                      maxlength={100}
                    />
                  </FlexGrid3Item>
                  {/* <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                    <InputLabelTop
                      type="text"
                      id="user_name"
                      label={'User Name'}
                      {...useInput('user_name', 'isRequired')}
                      maxlength={100}
                    />
                  </FlexGrid3Item> */}

                  <FlexGrid3Item ResizeWidth={'100%'} className="pe-0 pe-md-1 ps-1 ps-md-0">
                    <InputLabelTop
                      required
                      type="text"
                      id="tenant_name"
                      label={'Organization Name'}
                      {...useInput('tenant_name', 'isRequired')}
                      maxlength={255}
                    />
                  </FlexGrid3Item>
                </FlexGrid3Container>
              </ContainerResponsive>

              <FlexGrid3Container className="w-100 mb-2">


                {/* <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                  <DropdownSearchable
                    placeHolder={'Status'}
                    options={[
                      {id: 1, name: 'Active'},
                      {id: 2, name: 'Inactive'},
                    ]}
                    selectedOption={values.status}
                    onChange={value =>
                      dropDownHandleSelect({
                        status: value ? value : null,
                      })
                    }
                  />
                </FlexGrid3Item>  */}
              </FlexGrid3Container>



            </div>

            <ContainerResponsive className="d-flex justify-content-between mt-3">
              <FlexEqual className="me-md-2 me-sm-0">
                <TitleSection>Address</TitleSection>
                <div className="d-flex flex-column mt-2">
                  <InputLabelTop
                    type="text"
                    id="address_name"
                    label={'House Name'}
                    {...useInput('address.name')}
                    maxlength={50}
                  />
                  <div className="d-flex">
                    <div className="w-100 me-2">
                      <InputLabelTop
                        required
                        type="text"
                        id="address_line_1"
                        label={'Address Line 1'}
                        {...useInput('address.line_1', 'isRequired')}
                        maxlength={255}
                      />
                    </div>
                    <div className="w-100">
                      <InputLabelTop
                        type="text"
                        id="address_line_2"
                        label={'Address Line 2'}
                        {...useInput('address.line_2')}
                        maxlength={255}
                      />
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="w-100 me-2">
                      <InputLabelTop
                        required
                        type="text"
                        id="town_city"
                        label={'Town / City'}
                        {...useInput('address.city', 'isRequired')}
                        maxlength={100}
                      />
                    </div>
                    <div className="w-100">
                      <InputLabelTop
                        type="text"
                        id="country_state"
                        label={'County / State'}
                        {...useInput('address.state')}
                        maxlength={100}
                      />
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="w-100 me-2">
                      <InputLabelTop
                        required
                        type="text"
                        id="postcode_zipcode"
                        label={'Postcode / Zipcode'}
                        {...useInput('address.zip_code', 'isRequired')}
                        maxlength={25}
                      />
                    </div>
                    <div className="w-100">
                      <InputLabelTop
                        required
                        type="text"
                        id="country"
                        label={'Country'}
                        {...useInput('address.country', 'isRequired')}
                        maxlength={100}
                      />
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="w-100 me-2">
                      <InputLabelTop
                        type="text"
                        id="latitude"
                        label={'Latitude'}
                        onKeyPress={KeyPress.DECIMAL}
                        {...useInput('address.latitude', 'isDecimal')}
                        maxlength={25}
                      />
                    </div>
                    <div className="w-100">
                      <InputLabelTop
                        type="text"
                        id="longitude"
                        label={'Longitude'}
                        onKeyPress={KeyPress.DECIMAL}
                        {...useInput('address.longitude', 'isDecimal')}
                        maxlength={25}
                      />
                    </div>
                  </div>
                </div>
              </FlexEqual>

              <FlexEqual marginTopSm noMarginLeft className="ms-sm-0 ms-md-2">
                <TitleSection>Contact</TitleSection>
                <div className="d-flex flex-column mt-2">



                  <div className="d-flex">
                    <div className="w-100 me-2">
                      <InputLabelTop
                        required={true}
                        type="email"
                        id="personal_email"
                        label={'Email'}
                        {...useInput('contact.email_personal', 'isEmail')}
                        maxlength={254}
                      />
                    </div>


                  </div>



                </div>
              </FlexEqual>
            </ContainerResponsive>



            <div className="d-flex flex-column mt-4">
              <TitleSection>Subscription Plan</TitleSection>
              <ContainerResponsive className="d-flex mt-2">
                <FlexGrid3Container className="w-100 mb-2">
                  <FlexGrid3Item className="ps-0 pe-1">
                    <DateSelector
                      isRequired
                      disabled={false}
                      errorMessage={dateMessage}
                      labelTop="Start day"
                      data-error={dateError}
                      {...useInput('start_day', 'isRequired')}
                    />
                  </FlexGrid3Item>

                  <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                    <DateSelector
                      isRequired
                      disabled={false}
                      errorMessage={dateMessage}
                      labelTop="End day"
                      data-error={dateError}
                      {...useInput('end_day', 'isRequired')}
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item>
                    <DropdownSearchable
                      disabled={false}
                      required
                      placeHolder={'Subscription Type'}
                      options={subscriptionData ? subscriptionData : []}
                      selectedOption={
                        values.subscription_type
                      }
                      {...useInput('subscription_type','isRequired')}
                      onChange={value =>
                        dropDownHandleSelect(
                          {
                            subscription_type: value ? value : null,
                          }
                        )
                      }
                    />
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pt-2 pe-1">
                    <DropdownSearchable
                      required
                      {...useInput('time_unit', 'isRequired')}
                      disabled={false}
                      placeHolder={'Time Unit'}
                      options={contextChoices.bill_time_unit}
                      selectedOption={
                        values.time_unit
                      }
                      onChange={value =>
                        dropDownHandleSelect(
                          {
                            time_unit: value ? value : null,
                          }
                        )
                      }
                    />
                  </FlexGrid3Item>

                  <FlexGrid3Item className="pt-2 pe-0 pe-md-1 ps-1">
                    <InputLabelTop
                      required
                      type="text"
                      id="tenant_name"
                      label={'Charges'}
                      {...useInput('charges', 'isRequired')}
                      maxlength={255}
                    />
                  </FlexGrid3Item>
                  <div className='d-flex ms-2 mt-2 pt-2'>
                    <RadioButton
                      options={['Is Free Trial', 'Is Paid']}
                      onChangeRadio={handleOnChange}
                      checkedValue={radioValue}
                    />
                    {/* <ToggleWrapper className="ms-2 ">
                    <Toggle
                      id="checkbox2"
                      type="checkbox"
                      onClick={() => {
                        freeTrial ? setFreeTrial(false) : setFreeTrial(true);
                      }}
                    />
                    <ToggleLabel htmlFor="checkbox2" />
                  </ToggleWrapper>
                  <div className="toggle-label ms-2">
                    <p>Is Free Trial</p>
                  </div> */}
                  </div>
                </FlexGrid3Container>
              </ContainerResponsive>



            </div>



            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {showError ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>

              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                <div className={'ms-0 ms-md-3 me-2'}>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    disabled={saveDisabled}
                    onClick={handleSubmit}>
                    <span className={'me-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                </div>


              </div>
            </div>
          </ShowTab>


        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default Organizations;
