import React from "react";
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { PrimaryButtonForm, Toggle, ToggleLabel, ToggleWrapper } from 'shared/styles/buttons';
import { useState } from "react";
import {
    FlexGrid3Container,
    FlexGrid3Item,
    ErrorMessage,
    IconWrapper,
    TitleSection,
} from 'shared/styles/constants/tagsStyles';
import {
    InvalidOrEmptyValues,
    ERROR,
    SAVE,
    SAVED,
} from 'utils/constants/messages';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { getAlgoTime, getAlgoTimeData, postAlgoTime } from "utils/api/SettingsApi";
import { useEffect } from "react";
import { values } from "lodash";
import DropdownSearchable from "components/SharedComponents/DropdownSearchable";
import { useContext } from "react";
import { AppContext } from "context";
import _ from 'lodash';
import { setting_algo } from "utils/choiceConstant";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { ERR_COLOR, PURPLE } from "shared/styles/constants/colors";
import { Toast } from 'primereact/toast';
import { useRef } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import TimeSelector from "components/SharedComponents/TimeSelector";
import moment from "moment";
import ToggleButton from "components/SharedComponents/ToggleButton";

let TITLE_PADDING = 3;


const Newfeild = () => {

    const [value, setValue] = useState({ run_time: null, round_up: null, round_down: null, is_fixed_time: false })
    const [textStatus, setTextStatus] = useState(SAVE);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [loadingSave, setLoadingSave] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { contextChoices, handleGetChoices, contextChoicesMap } = useContext(AppContext)
    const [jobCategory, setJobCategory] = useState([]);
    const toast = useRef();

    useEffect(() => {
        handleGetChoices(setting_algo)
    }, [])

    const handleInput = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value })
    }
    const handleTime = (e, name) => {
        setValue({
            ...value, [name]: e.target.value
                ? moment(e.target.value, 'h:mm:ss A').format('HH:mm')
                : null
        })
    };

    const onSuccess = () => {
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CHECK);
        setTextStatus(SAVED);
        setErrorMessage('');
        getAlgoTimeDetails()
    };

    const onError = () => {
        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        setErrorMessage(InvalidOrEmptyValues);

    }
    const handleSubmit = async () => {
        if (loadingSave) {
            return;
        }

        if (jobCategory.length == 0) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Please add job department`,
            });
            return
        }

        let body = {
            run_time: value.run_time,
            round_up: value.round_up,
            round_down: String(value.round_down),
            min_visit_hours: value.min_visit_hours,
            min_visit_count: value.min_visit_count,
            break_duration: value.break_duration,
            wait_time_gap: value.wait_time_gap,
            commute_wait_time: value.commute_wait_time,
            is_fixed_time: value.is_fixed_time ? true : false,
            employee_precedence: (jobCategory && (jobCategory.length > 0)) ? jobCategory.map((val, ind) => ({ ...val, ordering: ind + 1 })) : []
        }
        // if(value.id){
        //     body.id = value.id
        // }
        setLoadingSave(true)
        try {
            let response = await postAlgoTime(body)
            if (response.status == "True") {
                onSuccess();
            } else {
                onError();
            }
        } catch (e) {
            onError();
        }

    }

    const onDragComplete = (result) => {
        if (!result.destination) return;
        handleSwapDrop(result.source.index, result.destination.index);
    };

    const handleSwapDrop = (src, des) => {
        let element = jobCategory[src];
        jobCategory.splice(src, 1);
        jobCategory.splice(des, 0, element);
        setJobCategory([...jobCategory]);

    }


    const moveItemUp = (array, index) => {
        if (index <= 0 || index >= array.length) return array;

        return [
            ...array.slice(0, index - 1), // Items before the one to be moved up
            array[index], // The item to be moved up
            array[index - 1], // The item that was previously above
            ...array.slice(index + 1) // Items after the one to be moved up
        ];
    };

    const moveItemDown = (array, index) => {
        if (index < 0 || index >= array.length - 1) return array;

        return [
            ...array.slice(0, index), // Items before the one to be moved down
            array[index + 1], // The item that will be moved down
            array[index], // The item that was previously below
            ...array.slice(index + 2) // Items after the one to be moved down
        ];
    };

    const handleSwap = (index, arrow) => {

        if (arrow == 'up') {
            setJobCategory(moveItemUp(jobCategory, index))
        } else {
            setJobCategory(moveItemDown(jobCategory, index))
        }
        // let element = jobCategory[src];
        // jobCategory.splice(src, 1);
        // jobCategory.splice(des, 0, element);
        // setJobCategory([...jobCategory]);
    }

    const getAlgoTimeDetails = async () => {
        let response = await getAlgoTimeData()
        // value.id = response.id
        value.run_time = response.run_time;
        value.round_up = response.round_up;
        value.round_down = response.round_down;
        value.min_visit_hours = response.min_visit_hours;
        value.min_visit_count = response.min_visit_count;
        value.break_duration = response.break_duration;
        value.wait_time_gap = response.wait_time_gap;
        value.commute_wait_time = response.commute_wait_time;
        value.is_fixed_time = response.is_fixed_time;
        setJobCategory(response.employee_precedence || [])
        setValue(
            { ...value })
    }

    useEffect(() => { 
        getAlgoTimeDetails()
    }, [])

    const dropDownHandleSelect = (item, index) => {

        let findValue = jobCategory.find((val) => item.job_category == val.job_category)

        if (findValue) {
            toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `Already added to list`,
            });
        } else {
            setJobCategory([...jobCategory, { ...item, ordering: jobCategory.length + 1 }])
        }

        // setValue({...value, ...result});
    };

    const toDeleteJob = (index) => {
        jobCategory.splice(index, 1);
        setJobCategory([...jobCategory])
    }

    console.log(jobCategory)

    return (
        <div>
            <Toast ref={toast} position="top-right" />

            <div className=" mt-4">
                <FlexGrid3Container className={'w-100 mb-2'}>
                    <FlexGrid3Item className="pe-1 d-flex justify-content-between">
                        <small>
                            <b>
                                <TitleSection>
                                    Do you want to consider the run time as fixed?
                                </TitleSection>
                            </b>
                        </small>

                        <ToggleButton
                            label=""
                            id="is_fixed_time"
                            onClick={() => {
                                value.is_fixed_time = !value.is_fixed_time
                                setValue({ ...value })
                            }}
                            status={value.is_fixed_time}
                        />
                    </FlexGrid3Item>
                </FlexGrid3Container>

                <FlexGrid3Container className={'w-100 mb-4'}>
                    <FlexGrid3Item className="pe-1">
                        <InputLabelTop
                            type="number"

                            label='Run Time'

                            name="run_time"
                            value={value.run_time}
                            onChange={handleInput}
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                        <InputLabelTop

                            type="number"

                            label='Round Up'

                            name="round_up"
                            value={value.round_up}
                            onChange={handleInput}
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                        <InputLabelTop
                            type="number"

                            label='Round Down'

                            name="round_down"
                            value={value.round_down}
                            onChange={handleInput}
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                        <InputLabelTop
                            type="number"

                            label='Min Visit Count'

                            name="min_visit_count"
                            value={value.min_visit_count}
                            onChange={handleInput}
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                        <TimeSelector
                            id="min_visit_hours"
                            timeFormat={'HH:mm'}
                            dateFormat={'HH:mm'}
                            placeholderText={'hh:mm'}
                            locale={'sv-sv'}
                            timeCaption={'Duration'}
                            labelTop={'Min Visit Hours (HH:MM)'}
                            value={value.min_visit_hours}
                            onChange={(e) => handleTime(e, 'min_visit_hours')}
                        />

                    </FlexGrid3Item>
                    <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                        <InputLabelTop
                            type="number"

                            label='Break Duration (Min)'

                            name="break_duration"
                            value={value.break_duration}
                            onChange={handleInput}
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                        <TimeSelector
                            id="wait_time_gap"
                            timeFormat={'HH:mm'}
                            dateFormat={'HH:mm'}
                            placeholderText={'hh:mm'}
                            locale={'sv-sv'}
                            timeCaption={'Duration'}
                            labelTop={'Wait Time Gap (HH:MM)'}
                            value={value.wait_time_gap}
                            onChange={(e) => handleTime(e, 'wait_time_gap')}
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
                        <TimeSelector
                            id="commute_wait_time"
                            timeFormat={'HH:mm'}
                            dateFormat={'HH:mm'}
                            placeholderText={'hh:mm'}
                            locale={'sv-sv'}
                            timeCaption={'Duration'}
                            labelTop={'Commute Wait Time (HH:MM)'}
                            value={value.commute_wait_time}
                            onChange={(e) => handleTime(e, 'commute_wait_time')}
                        />
                    </FlexGrid3Item>
                </FlexGrid3Container>
                <div className="w-100">
                    <DropdownSearchable
                        required
                        placeHolder={'Job Department'}
                        options={contextChoices.job_category}
                        disabled={false
                        }
                        selectedOption={
                            value
                                ? value.job_category
                                    ? value.job_category
                                    : null
                                : null
                        }
                        onChange={value =>
                            dropDownHandleSelect(
                                { job_category: value ? value : null },
                                'job_category'
                            )
                        }
                    />
                </div>
            </div>
            <DragDropContext onDragEnd={onDragComplete}>
                <Droppable droppableId="drag-drop-list">
                    {(provided, snapshot) => (
                        <div
                            className="drag-drop-list-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {jobCategory.map((tableval, index) => (
                                <Draggable key={tableval.ordering} draggableId={`${tableval.ordering}`} index={index}>
                                    {(provided) => (
                                        <div
                                            className="item-card"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className='d-flex border' >
                                                <div className='d-flex flex-column pe-1 ps-1' style={{ cursor: 'pointer' }}>
                                                    <FiChevronUp className="align-self-center" size={18} color={PURPLE} onClick={() => handleSwap(index, 'up')} />
                                                    <FiChevronDown className="align-self-center" size={18} color={PURPLE} onClick={() => handleSwap(index, 'down')} />
                                                </div>
                                                <div className='d-flex align-items-center sticky-col w-100 justify-content-between'
                                                    style={{
                                                        paddingLeft: TITLE_PADDING,
                                                    }}
                                                >
                                                    <div>
                                                        {(contextChoicesMap.job_category && tableval.job_category) && contextChoicesMap?.job_category[tableval.job_category]?.name}
                                                    </div>
                                                    <IconWrapper
                                                        style={{ color: ERR_COLOR, marginRight: '0.5rem' }}
                                                        onClick={() => {
                                                            toDeleteJob(index);
                                                        }}>
                                                        <RiDeleteBinLine />
                                                    </IconWrapper>

                                                </div>
                                            </div>

                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div
                className={
                    'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
                }>

                <div className={'d-flex justify-content-center justify-content-md-end'}>
                    <div className={'ms-0 ms-md-3 me-2'}>
                        <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit} >
                            <span className={'me-2'}>{textStatus}</span>
                            {!loadingSave ? (
                                buttonIcon
                            ) : (
                                <span
                                    className="spinner-border spinner-border-sm me-1"
                                    role="status"
                                />
                            )}
                        </PrimaryButtonForm>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Newfeild;