import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { getBranchById, setCompanyBranch } from 'utils/api/SettingsApi';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  DropdownTypesContainer,
} from 'shared/styles/constants/tagsStyles';
import { ERROR, SAVE, SAVED, InvalidOrEmptyValues } from 'utils/constants/messages';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { IconWrapper } from 'components/SharedComponents/Table/styles';
import { RiDeleteBinLine } from 'react-icons/ri';
import { FaPlus } from 'react-icons/fa';
import { FloatingMobileButton, AnimationStyle } from 'shared/styles/buttons';
import { BsPlusCircleFill } from "react-icons/bs";
import Dropdown from 'components/SharedComponents/Dropdown';
import { getRunRoute, statusRunRoute, postRunRoute, postPostalCodes, editRunRouteById, setRunRouteByID } from 'utils/api/SettingsApi';
import { getUsers } from 'utils/api/UsersApi';
import { setAvailabilityDetails } from 'utils/api/RotaShift';
import { map, valuesIn } from 'lodash';
import { stringify } from 'query-string';
import PostalCodes from "./PostalCodes"
const edit_runroute = 'Edit Group Area';
const new_runroute = 'Add Group Area';

const AddMoal = ({
  fullBind,
  idSelected,
  setTitle,
  loadRunRoutes,
  setIdSelected,
  RUNROUTE_NOT_FOUND,
  loadRunRoute,
  supervisorame,
  supervisorId,
  runrotename,
  runroteid,
  getPostalCodeData,
  handleChangeChoices,
  // postalCodeData,
  toast,
  setClosePopup
}) => {

  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setFormErrors,
    setCheckRequires,
  } = useForm({
    postalCodes: []
  });

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false)
  const [supervisor, setSupervisor] = useState([]);
  const [deletedCodes, setDeletedCodes] = useState([])
  const [instaSuperId, setInstaSuperId] = useState();
  const [multiPostCode, setMultiPostCode] = useState();
  const [userPostal, setUserPostal] = useState();


  const userTemplate = {};
  const [users, setUsers] = useState([userTemplate])
  const post = users.map(x => x.postalcode)

  const routeName = values.runrotename;

  const spuervisorModalId = values.runrotename;


  // useEffect(() => {
  //   if (idSelected) {
  //     loadRunRouteById();
  //   }
  //   // postUser()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (idSelected) {
      setTitle(edit_runroute);
    } else {
      setTitle(new_runroute);
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);
  useEffect(() => {
    if (values.postalCodes.length === 0) {
      values.postalCodes[0] = { id: '' }
    }
  }, [])
  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
      setShowError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);


  const supervisiorInfo = () => {
    getUsers({ values }).then(response => {
      setSupervisor(
        (
          (response.results).map((x) => (
            {
              id: x.id,
              name: x.name
            }
          )
          )
        )
      )
    });
  }

  useEffect(() => {
    supervisiorInfo()
    if (idSelected) {
      loadRunRouteById();
    }
    // postCode()
  }, [])

  const loadRunRouteById = async () => {
    // const response = getRunRoute(values);
    const response = await setRunRouteByID(idSelected)
    if (response) {
      values.id = response.results[0].id
      values.runrotename = response.results[0].name;
      values.supervisor = response.results[0].supervisor;
      values.postalCodes = response.results[0].postal_codes
      setValues({ ...values });
    }

    // value.postalcode  = response.results[0].postal_codes.map(z=>z.name);
    setLoadingModal(false);

  };

  const handleSubmit = async () => {
    if (loadingSave) {
      return;
    }

    if (errors.length) {
      setCheckRequires(true);
      setShowError(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    // let latest_err = [...errors]
    // let allGood = false
    // values.postalCodes && values.postalCodes.map(code => {
    //   if ((!isValid && errors.length > 0) || !code.name) {
    //     setCheckRequires(true);
    //     setErrorMessage(InvalidOrEmptyValues);
    //     allGood = true
    //   }
    // })
    // if (allGood) {
    //   return
    // }
    // if (!isValid && errors.length > 0) {
    //   console.log(errors,"eee---kkkoooo")

    // }

    setLoadingSave(true);

    const bodyData = values.id ? {
      run_route_name: values.runrotename,
      run_route_supervisor: values.supervisor,
      postal_codes_list: [...values.postalCodes, ...deletedCodes]
    } : {
      run_route_name: values.runrotename,
      run_route_supervisor: values.supervisor,
      postal_codes_list: values.postalCodes
    }
    await postRunRoute(values, bodyData)
      .then(res => {
        if (res.status === 'SUCCESS') {
          onSuccess()
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Saved Successfully',
          });
        }
        else {
          onError(res.message)
          toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: res.message,
          });
        }
      })
      .catch(onError)
  };
  //above code  is for saving postal code  creates new row in table 

  const onSuccess = () => {
    setClosePopup(false)
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setCheckRequires(false);
    setErrorMessage("");
    setShowError(false)
    handleChangeChoices()
    // setIdSelected(response.id);
    loadRunRoute()
    // window.location.reload();
  };

  const onError = (message) => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setErrorMessage(message)
    setShowError(true)

  };

  //below is the  functionality to add or remove  multi user dynamically
  // const userTemplate =  {  name:' ',};
  const adduser = () => {
    values.postalCodes = [...values.postalCodes, {}]
    setValues({ ...values })
  }
  const onChange = (e, index) => {
    const updatedUsers = users.map((user, i) => index == i ? Object.assign(user, { [e.target.name]: e.target.value }) : user)
    setUsers(updatedUsers)
    setMultiPostCode(updatedUsers.map(x => x.postalcode))
    setValues({ ...values, ...multiPostCode, });
  }
  const removeUser = (index) => {
    if (values.postalCodes.length > 0) {
      if (values.postalCodes[index].id) {
        setDeletedCodes([...deletedCodes, {
          id: values.postalCodes[index].id,
          name: values.postalCodes[index].name,
          is_deleted: true
        }])
        values.postalCodes.splice(index, 1)
        setFormErrors(errors.filter(error => !error.includes('postalCodes')))
      } else {
        values.postalCodes.splice(index, 1)
        setFormErrors(errors.filter(error => !error.includes('postalCodes')))
      }

    }
    setValues({ ...values })
  }

  // const postUser=( )=>{
  //   const postalUser =  users.map(( user)=>(user) )
  //   console.log(users.map(( user)=>(user) ),978)
  // setUserPostal(...postalUser)
  // }

  const dropDownHandleSelect = item => {
    setValues({ ...values, ...item });
    setInstaSuperId(item.supervisor)
  };
  //  setValues(   ...users)

  return (
    <ModalContainer max_width={'60%'}>

      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="ps-3 pe-3 pb-3 pt-0">

          <FlexGrid3Container className={'w-100 mb-2'}>
            {/* <FlexGrid3Item className="pe-1"> */}
            <InputLabelTop
              required
              type="text"
              id="runrotename"
              label={'Group Area'}
              {...useInput('runrotename', 'isRequired')}
              // value={ }
              maxlength={105}
            />

            <FlexGrid3Item className=" w-100">
              <DropdownSearchable

                {...useInput(
                  `supervisor`,

                )}
                placeHolder={'Supervisor'}
                options={supervisor}
                selectedOption={values.supervisor}
                onChange={value =>
                  dropDownHandleSelect({
                    supervisor: value ? value : null,
                  })
                }

              />

            </FlexGrid3Item >

            {
              values.postalCodes && values.postalCodes.map((name, index) => {
                return (
                  <>
                    <FlexGrid3Container className={'w-100  mt-2'}>
                      <PostalCodes
                        index={index}
                        useInput={useInput}
                      />
                      {index !== 0 &&
                        <FlexGrid3Item className="mt-3  ps-1 ">
                          <IconWrapper onClick={() => removeUser(index)} className="ms-2 mt-1" >
                            <RiDeleteBinLine size="1.2em" color="red" />
                          </IconWrapper>
                        </FlexGrid3Item>

                      }
                    </FlexGrid3Container>
                  </>
                )
              }
              )
            }

            <FlexGrid3Item className="ms-1 ">
              {/* <button type="button" className="btn btn-outline-info  mb-4"  onClick={adduser}  >Add more </button> */}
              <FloatingMobileButton onClick={adduser} className="h6" >
                <BsPlusCircleFill color="white" />
                <label className="mb-1" >Add More PostalCode</label>
              </FloatingMobileButton>

            </FlexGrid3Item>
          </FlexGrid3Container>
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {(showError || errors.length) > 0 ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ms-0 ms-md-3 me-2'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default AddMoal;
