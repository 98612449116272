import React from 'react';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import moment from 'moment';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';


const DescriptionList = ({
    data,
    index,
    useInput,
    values,
    setValues,
    employeeName,
    contextChoices,
    readOnly
}) => {

    const nameCreatedBy = contextChoices?.user_list?.find(x => x.id == values.prev_description[index]?.updated_by
    )?.name;

    const firstName = nameCreatedBy?.split(' ').slice(0, -1).join(' ');

    const lastName = nameCreatedBy?.split(' ').slice(-1).join(' ');

    const photo = contextChoices?.user_list?.find(x => x.id == values.prev_description[index]?.updated_by)?.photo;;

    const dateCreatedAt = values.prev_description[index].updated_at ? values.prev_description[index]?.updated_at?.split('T')[0] : '';

    const timeCreatedAt = values.prev_description[index].updated_at ? moment(values.prev_description[index]?.updated_at?.split('T')[1], 'HH:mm').format('HH:mm') : '';

    const dateTime = dateCreatedAt + '  ' + '  ' + timeCreatedAt;

    return (
        <div key={data.id} className={`mt-1 mb-1 container `} >
            <div className='row'>
                <div className='col-sm-12 col-md-4 col-lg-4'>
                    <div className='me-0'>
                        <div className=' '>

                            <RowInfoModel
                                name={firstName || nameCreatedBy}
                                lastName={lastName}
                                photoRow={''
                                    // photo
                                    //   ? URL.createObjectURL(photo)
                                    //     : ''
                                }
                                rows={[
                                    { value: nameCreatedBy }
                                ]}

                            />

                        </div>
                        <div className=' mt-1 ' >
                            <p style={{ fontSize: '11.5px' }}>{dateTime} </p>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-md-8 col-lg-8'>
                    <div className='ms-0'>
                        <InputTextArea
                            disabled={readOnly}
                            color={true}
                            type="text"
                            height={'4rem'}
                            placeholder={'Previous Description'}
                            // {...useInput(`prev_description[${index}].message
                            // `)}
                            value={values.prev_description[index].message}
                        />

                    </div>

                </div>
            </div>

        </div>
    )
}

export default DescriptionList