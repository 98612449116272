import React, {Fragment} from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {
    IconWrapper,
  } from 'components/SharedComponents/Table/styles';
  import {PrimaryButtonForm} from 'shared/styles/buttons';
  import {RiDeleteBinLine} from 'react-icons/ri';
  import AdjustEmployee from './AdjustEmployee'
  import {AiOutlineSwap} from 'react-icons/ai';
import TimeSelector from 'components/SharedComponents/TimeSelector';

const RequirementEmployeePost = ({
    values,
    index,
    setValues,
    clients,
    dropDownHandleRegEmpDet,
    useInput,
    deleteEmployeeDetails,
    employeeRequired,
    empEmployeeReq,
    empVisit,
    runSpecific,
    employees,
    visitDetails,
    setSingleRun,
    idSelected,
    empDayOptions
}) => {
  const addNewAdjustDetails=()=>{
    if(!visitDetails.adjustment_details){
      values.employee_day_detail[index].adjustment_details=[]
    }
    values.employee_day_detail[index].adjustment_details=[...values.employee_day_detail[index].adjustment_details,{}]
    setValues({...values})
  }
  
  const dropDownHandleAdjust=(item,nameItem,adjustIndex)=>{
    values.employee_day_detail[index].adjustment_details[adjustIndex][nameItem]=item[nameItem]
    setValues({...values})
  }
  
  const deleteEmployeeAdjust=(innerIndex)=>{
    values.employee_day_detail[index].adjustment_details.splice(innerIndex,1)
    setValues({...values})
  }

  const handleSingleSwap=()=>{
    values.visitDetails_id = visitDetails.id;
    setValues({...values});
    setSingleRun(true);
  }
  const switchShiftField = props => {
    if (visitDetails && visitDetails.employee && runSpecific) {
     return( 
      <div className={'w-50'}>
      <DropdownSearchable
           required
           {...props}
            placeHolder={'Shift Name'}
            options={visitDetails.shiftArray ? visitDetails.shiftArray : []}
            selectedOption={values && values.employee_day_detail &&
              values.employee_day_detail[index] &&
              values.employee_day_detail[index].shift
              ?values.employee_day_detail[index].shift:null}
            onChange={value =>
              dropDownHandleRegEmpDet({shift: value ? value : null}, 'shift',index)
            }
          />
      </div>
     )
    } else {
      return(
        <Fragment />
      )
    }
  };
  
    return (
      <>
        <div className={`d-flex mt-2 mb-3 me-4`}>
        
        <div className={'w-50 me-2'}>
      <DropdownSearchable
       required
       {...useInput(
        `employee_day_detail[${index}].client`,
        'isRequired'
      )}
            placeHolder={'Client'}
            options={clients}
            selectedOption={values && values.employee_day_detail &&
              values.employee_day_detail[index] &&
              values.employee_day_detail[index].client
              ?values.employee_day_detail[index].client:null}
            onChange={value =>
              dropDownHandleRegEmpDet({client: value ? value : null}, 'client',index)
            }
          />
      </div>
      <div className={'w-50 me-2'}>
        <DropdownSearchable
              required
              {...useInput(
               `employee_day_detail[${index}].repeat_on`,
               'isRequired'
             )}
              placeHolder={'Select Day'}
              options={[
                {id:"1",name:"MON"},
                {id:"2",name:"TUE"},
                {id:"3",name:"WED"},
                {id:"4",name:"THU"},
                {id:"5",name:"FRI"},
                {id:"6",name:"SAT"},
                {id:"7",name:"SUN"},
                ]}
                  selectedOption={
                 values && values.employee_day_detail &&
                 values.employee_day_detail[index] &&
                 values.employee_day_detail[index].repeat_on
                 ?values.employee_day_detail[index].repeat_on:null
              }
              onChange={value =>
                  dropDownHandleRegEmpDet(
                  {repeat_on: value ? value : null},
                  'repeat_on',index
                )
              }
            />
        </div>
      <div className={'w-50 me-2'}>
      <DropdownSearchable
           required
           {...useInput(
            `employee_day_detail[${index}].visit`,
            'isRequired'
          )}
            placeHolder={'Visits'}
            options={empVisit[index]}
            selectedOption={values && values.employee_day_detail &&
              values.employee_day_detail[index] &&
              values.employee_day_detail[index].visit
              ?values.employee_day_detail[index].visit:null}
            onChange={value =>
              dropDownHandleRegEmpDet({visit: value ? value : null}, 'visit',index)
            }
          />
      </div>
      <div className={'w-50 me-2'}>
            <TimeSelector
                  isRequired
                  labelTop={'Pin Start Time'}
                  timeFormat={'HH:mm'}
                  dateFormat={'HH:mm'}
                  value={values.employee_day_detail[index].start_time}
                  {...useInput(`employee_day_detail[${index}].start_time`,)}
                />
                </div>
                <div className={'w-50 me-2'}>
                 <TimeSelector
                  isRequired
                  labelTop={'Pin End Time'}
                  timeFormat={'HH:mm'}
                  dateFormat={'HH:mm'}
                  value={values.employee_day_detail[index].end_time}
                  {...useInput(`employee_day_detail[${index}].end_time`,
                  'isRequired')}
                />
            </div>
      <div className={'w-100 me-2'}>
      <DropdownSearchable
            placeHolder={'Requirement'}
            options={empEmployeeReq[index]}
            selectedOption={values && values.employee_day_detail &&
              values.employee_day_detail[index] &&
              values.employee_day_detail[index].requirement
              ?values.employee_day_detail[index].requirement:null}
            onChange={value =>
              dropDownHandleRegEmpDet({requirement: value ? value : null}, 'requirement',index)
            }
          />
      </div>
      {runSpecific && 
         <div className={'w-100 me-2'}>
         <DropdownSearchable
               placeHolder={'Employees'}
               options={employees}
               selectedOption={values && values.employee_day_detail &&
                 values.employee_day_detail[index] &&
                 values.employee_day_detail[index].employee
                 ?values.employee_day_detail[index].employee:null}
               onChange={value =>
                 dropDownHandleRegEmpDet({employee: value ? value : null}, 'employee',index)
               }
             />
         </div>
      }
      {switchShiftField( {...useInput(`employee_day_detail[${index}].shift`,'isRequired')})}
   
         
           {/* {index!==0 && */}
           <IconWrapper
            className={'ms-2'}
           onClick={() => {
             deleteEmployeeDetails(index);
            }}
            >
          <RiDeleteBinLine 
          color="red"
          cursor='pointer'
          width='6rem'
          />
      </IconWrapper>
      {<IconWrapper
              className={'ms-2'}
             onClick={() => {
              handleSingleSwap();
              }}
              >
            <AiOutlineSwap 
            color="purple"
            cursor='pointer'
            width='6rem'
            />
        </IconWrapper>}
          {/* } */}
          </div>
          <div>
          {visitDetails && visitDetails.adjustment_details && visitDetails.adjustment_details.map((adjustDet,adjustIndex)=>{
          return (
               <AdjustEmployee
                index={adjustIndex}
                outerIndex={index}
                values={values}
                useInput={useInput}
                employees={employees}
                deleteEmployeeAdjust={deleteEmployeeAdjust}
                nameSection={`employee_day_detail[${index}].adjustment_details[${adjustIndex}]`}
                dropDownHandleAdjust={dropDownHandleAdjust}
               />
          )
              
        })}
         <PrimaryButtonForm
                  bgcolor="BLUE"
                  minWidth="0.5rem"
                  className={'mb-2 ms-2'}
                  onClick={addNewAdjustDetails}
                  >
                  <span>{'Add Adjustment'}</span>
                </PrimaryButtonForm>
                </div>
          </>
    );
}

export default RequirementEmployeePost;
