import React,{useEffect} from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import {KeyPress, onChangeValidations} from 'utils/constants/regularExpressions';
import {TitleSection, FlexEqual} from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import PropTypes from 'prop-types';
import Geocode from 'react-geocode' 
const ServiceAddress = ({
  useInput, 
  choices, 
  values, 
  nameSection, 
  dropDownHandleSelectAddress, 
  sectionStyle,
  setChangeAddress,
  editMode,
  templateContract = false,
  locationCom = false
}) => {

   const name_hook = useInput(`${[nameSection]}.name`)
   const line_1_hook = useInput(`${[nameSection]}.line_1`)
   const line_2_hook = useInput(`${[nameSection]}.line_2`)
   const city_hook = useInput(`${[nameSection]}.city`)
   const state_hook = useInput(`${[nameSection]}.state`)
   const country_hook = useInput(`${[nameSection]}.country`)
   const zip_code_hook = useInput(`${[nameSection]}.zip_code`)
   const latitude_hook = useInput(`${[nameSection]}.latitude`, templateContract ? '' : 'isRequired')
   const longitude_hook = useInput(`${[nameSection]}.longitude`, templateContract ? '' : 'isRequired')
   const animals_hook = useInput(`${[nameSection]}.animals`)
   const smoking_hook = useInput(`${[nameSection]}.smoking`)
   console.log("aaaaaaa", latitude_hook)


  Geocode.setApiKey(process.env.REACT_APP_GOOGLEAPI_KEY);
  Geocode.setLanguage("en");
  useEffect(()=>{
    if(values.service_address ){
      if(values.service_address.line_1 && values.service_address.city && values.service_address.country && 
        values.service_address.zip_code && !values.service_address.same_as_client ){
          const final_add=values.service_address.line_1+" "+values.service_address.city+" "+values.service_address.country+" "+values.service_address.zip_code
          Geocode.fromAddress(final_add).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              values.service_address.latitude=lat
              values.service_address.longitude=lng
        
            },
            (error) => {
              console.error(error);
            }
          );
        }
    }
  },[values.service_address && values.service_address.line_1,
    values.service_address && values.service_address.city,
    values.service_address && values.service_address.country,
    values.service_address && values.service_address.zip_code,
])
  const sameClientValue = values.service_address
    ? values.service_address.same_as_client
    : false;

  const handleAddress = () => {
    setChangeAddress(!sameClientValue);
  }
 
  const allowBackspace = (e, type) => {
    if (e.key === 'Backspace') {
      if(type === 'latitude' && latitude_hook.value.length > 0){
        latitude_hook.onChange({
          ...e,
          target: {
            ...e.target,
            value: latitude_hook.value.slice(0, -1)
          }
        })
      } else if(type === 'longitude' && longitude_hook.value.length > 0){
        longitude_hook.onChange({
          ...e,
          target: {
            ...e.target,
            value: longitude_hook.value.slice(0, -1)
          }
        })
      }
      e.preventDefault()
    }
  }
  const handleLongLatChange = (e, type) => {
    const { valid } = onChangeValidations[type](e)
    if (!valid) return;
    if (type === 'latitude') {
      latitude_hook.onChange(e)
    } else {
      longitude_hook.onChange(e)
    }
  }
  
  return (
    <FlexEqual className={sectionStyle}>
      <TitleSection>Service address</TitleSection>

      <div className="d-flex align-items-center mt-1">

        <div className={'w-100 pe-1'}>
        <DropdownSearchable
         disabled={!editMode}
              placeHolder={'Service destination'}
              options={choices.service_destination}
              selectedOption={
                values.service_address
                  ? values.service_address.destination
                  : null
              }
              onChange={(value) =>
                dropDownHandleSelectAddress(
                  {destination: value ? value : null},
                  'destination',
                )
              }
            />
        </div>

        <div className="w-100 pt-1" onClick={handleAddress}>
          <ToggleButton
           disabled={!editMode}
            label={`Service Address Same as ${locationCom ? 'location' : 'Client'} Address`}
            id="service_address_same_as_client"
            useInput={useInput(`${[nameSection]}.same_as_client`)}
          />
        </div>
        <div className="w-100">
          <ToggleButton
           disabled={!editMode}
            label="Smoking at Service Address"
            useInput={smoking_hook}
            id="service_address_smoking"
          />
        </div>
      </div>

    {!templateContract && <>
     <div className="d-flex align-items-center mt-2">
        <div className="w-100">
          <InputLabelTop
           disabled={!editMode}
            type="text"
            id="service_address_name"
            label={'House Name / Company Name'}
            {...name_hook}
            maxlength={50}
          />
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div className="w-100 me-2">
          <InputLabelTop
           disabled={!editMode}
            type="text"
            id="service_address_line_1"
            label={'Address Line 1'}
            {...line_1_hook}
            maxlength={255}
          />
        </div>
        <div className="w-100">
          <InputLabelTop
           disabled={!editMode}
            type="text"
            id="service_address_line_2"
            label={'Address Line 2'}
            {...line_2_hook}
            maxlength={255}
          />
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div className="w-100 me-2">
          <InputLabelTop
           disabled={!editMode}
            type="text"
            id="service_address_town_city"
            label={'Town / City'}
            {...city_hook}
            maxlength={100}
          />
        </div>
        <div className="w-100">
        <InputLabelTop
         disabled={!editMode}
            type="text"
            id="service_address_country_state"
            label={'County / State'}
            {...state_hook}
            maxlength={100}
          />
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div className="w-100 me-2">
        <InputLabelTop
         disabled={!editMode}
            type="text"
            id="service_address_country"
            label={'Country'}
            {...country_hook}
            maxlength={100}
          />
        </div>
        <div className="w-100">
          <InputLabelTop
           disabled={!editMode}
            type="text"
            id="service_address_postcode_zipcode"
            label={'Postcode / Zipcode'}
            {...zip_code_hook}
            maxlength={25}
          />
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div className="w-100 me-2">
          <InputLabelTop
           disabled={!editMode}
           required
            type="text"
            id="service_address_latitude"
            label={'Latitude'}
            {...latitude_hook}
            onChange={(e) => handleLongLatChange(e, 'latitude')}
            onKeyDown={(e) => allowBackspace(e, 'latitude')}
          />
        </div>
        <div className="w-100">
          <InputLabelTop
           disabled={!editMode}
         required
            type="text"
            id="service_address_longitude"
            label={'Longitude'}
            {...longitude_hook}
            onChange={(e) => handleLongLatChange(e, 'longitude')}
            onKeyDown={(e) => allowBackspace(e, 'longitude')}
          />
        </div>
      </div>

      <div className="d-flex align-items-center">
        <div className="w-100 me-2">
          <InputLabelTop
           disabled={!editMode}
            type="text"
            id="service_address_animals"
            label={'Animals at Service Address'}
            {...animals_hook}
            maxlength={255}
          />
        </div>
       
      </div> 
     </>}
     
    </FlexEqual>
  );
};

ServiceAddress.propTypes = {
  useInput: PropTypes.func.isRequired,
  nameSection: PropTypes.string.isRequired,
  sectionStyle: PropTypes.string,
  values: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  dropDownHandleSelectAddress: PropTypes.func.isRequired,
  setChangeAddress: PropTypes.func.isRequired,
  setChangeAddress: PropTypes.func.isRequired

};

ServiceAddress.defaultProps = {
  sectionStyle: '',
};

export default ServiceAddress;
