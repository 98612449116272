import React, { Fragment, useState, useEffect } from 'react';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { PURPLE } from 'shared/styles/constants/colors';
import { FaUserShield } from 'react-icons/fa';
import { SquareStyle, ArrowStyle } from './styles';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

let TITLE_PADDING = 3;
let TITLE_FONT_WEIGHT = 800;
const CREATE_TITLE = 'Create';
const READ_TITLE = 'Read';
const UPDATE_TITLE = 'Update';
const DELETE_TITLE = 'Delete';
const _ = require('lodash');

const BodyTable = ({ rolValues, selectedRol, setRolValues, handleSwap,handleSwapDrop, permissionValues }) => {
  const [expandable, setExpandable] = useState({});
  //const [dropArray, setDropArray] = useState(permissionValues);

  useEffect(() => {
    _.forEach(expandable, function (value, key) {
      expandable[key] = false;
    });

    setExpandable({ ...expandable });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRol]);

  // const SubHeader = () => {
  //   return (
  //     <tr className={'border'}>
  //       <th className={'sticky-col'}>
  //         <div className={'d-flex justify-content-center'}>
  //           <FaUserShield className={'mt-1'} size={18} />
  //         </div>
  //       </th>
  //       <td className={' border-start '}>
  //         <div className={'d-flex justify-content-between font-weight-bold'}>
  //           <div>{CREATE_TITLE}</div>
  //           <div>{READ_TITLE}</div>
  //           <div>{UPDATE_TITLE}</div>
  //           <div>{DELETE_TITLE}</div>
  //         </div>
  //       </td>
  //       <td className={' border-start '}>
  //         <div className={'d-flex justify-content-between font-weight-bold'}>
  //           <div>{CREATE_TITLE}</div>
  //           <div>{READ_TITLE}</div>
  //           <div>{UPDATE_TITLE}</div>
  //           <div>{DELETE_TITLE}</div>
  //         </div>
  //       </td>
  //     </tr>
  //   );
  // };

  const onDragComplete = (result) => {
    if (!result.destination) return;
    handleSwapDrop(result.source.index, result.destination.index);
    //const arr = [...dragDropList];

    //Changing the position of Array element
    // let removedItem = arr.splice(result.source.index, 1)[0];
    // arr.splice(result.destination.index, 0, removedItem);

    //Updating the list
    //setDragDropList(arr);
  };

  

  const createRows = (sections, keyValue = '') => {
    let tableToReturn = [];
    let filteredArray = [];
    let valuesToMap = sections ? sections : rolValues;


    valuesToMap.forEach((cat) => {
      if (cat.sections) {
        cat.sections.forEach((task) => {
          if (task.sections) {
            task.sections.forEach((care) => {
              if(care.web.web_read){
                filteredArray.push({
                  category_name: cat.name,
                  task_name: task.name,
                  care_task_name: care.name,
                  permission_id: care.permission_id
                })
              }
            })
          }
        })
      }
    })

    return(
      <DragDropContext onDragEnd={onDragComplete}>
      <Droppable droppableId="drag-drop-list">
        {(provided, snapshot) => (
          <div
            className="drag-drop-list-container"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {permissionValues.map((tableval, index) => (
              <Draggable key={tableval.id} draggableId={tableval.id} index={index}>
                {(provided) => (
                  <div
                  className="item-card"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                    <div className='d-flex border' >
                      <div className='d-flex flex-column pe-1 ps-1' style={{ cursor: 'pointer' }}>
                        <FiChevronUp className="align-self-center" size={18} color={PURPLE} onClick={() => handleSwap(tableval.id, index, 'up')} />
                        <FiChevronDown className="align-self-center" size={18} color={PURPLE} onClick={() => handleSwap(tableval.id, index, 'down')} />
                      </div>
                      <div className='d-flex align-items-center sticky-col'
                        style={{
                          paddingLeft: TITLE_PADDING,
                        }}
                      >
                        <div>{tableval.care_task.task_type.care_category.name}</div><span className='font-weight-bold'>{'>'}</span>
                        <div>{tableval.care_task.task_type.name}</div><span className='font-weight-bold'>{'>'}</span>
                        <div>{tableval.care_task.name}</div>
                      </div>
                    </div>

                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      </DragDropContext>
    )

    // permissionValues.forEach((tableval,index) => {
    //   tableToReturn = [
    //     ...tableToReturn,
    //     <Fragment key={index}>
    //       <div className='d-flex border' >
    //       <div className='d-flex flex-column pe-1 ps-1' style={{cursor: 'pointer'}}>
    //       <FiChevronUp className="align-self-center" size={18} color={PURPLE} onClick={()=>handleSwap(tableval.id, index, 'up')}/>
    //       <FiChevronDown className="align-self-center"  size={18} color={PURPLE} onClick={()=>handleSwap(tableval.id, index, 'down')}/>
    //       </div>
    //       <div className='d-flex align-items-center sticky-col'
    //       style={{
    //         paddingLeft: TITLE_PADDING,
    //       }}
    //       >
    //         <div>{tableval.care_task.task_type.care_category.name}</div><span className='font-weight-bold'>{'>'}</span>
    //         <div>{tableval.care_task.task_type.name}</div><span className='font-weight-bold'>{'>'}</span>
    //         <div>{tableval.care_task.name}</div>
    //       </div>
    //       </div>

    //     </Fragment>
    //   ]
    // })


  };

  // const addSquare = (tableValue, expandable, keyValue, myKey) => {
  //   if (!expandable[`${keyValue ? keyValue : myKey}`] && tableValue.principal) {
  //     return (
  //       <SquareStyle className={'pb-1'}>
  //         <AiFillPlusSquare
  //           color={PURPLE}
  //           onClick={() => {
  //             setExpandable({
  //               ...expandable,
  //               [`${keyValue ? keyValue : myKey}`]: !expandable[
  //                 `${keyValue ? keyValue : myKey}`
  //               ],
  //             });
  //           }}
  //         />
  //       </SquareStyle>
  //     );
  //   } else {
  //     return (
  //       <SquareStyle className={'pb-1'}>
  //         <AiFillMinusSquare
  //           color={PURPLE}
  //           onClick={() => {
  //             _.forEach(expandable, function (value, key) {
  //               if (key.includes(myKey) && key !== myKey) {
  //                 expandable[key] = false;
  //               }
  //             });

  //             setExpandable({
  //               ...expandable,
  //               [`${keyValue ? keyValue : myKey}`]: !expandable[
  //                 `${keyValue ? keyValue : myKey}`
  //               ],
  //             });
  //           }}
  //         />
  //       </SquareStyle>
  //     );
  //   }
  // };

  // const addArrows = (expandable, myKey) => {
  //   if (expandable[`${myKey}`]) {
  //     return (
  //       <ArrowStyle className={'pb-1 ps-0'}>
  //         <FaAngleUp
  //           color={PURPLE}
  //           onClick={() => {
  //             _.forEach(expandable, function (value, key) {
  //               if (key.includes(myKey) && key !== myKey) {
  //                 expandable[key] = false;
  //               }
  //             });

  //             setExpandable({
  //               ...expandable,
  //               [`${myKey}`]: !expandable[`${myKey}`],
  //             });
  //           }}
  //         />
  //       </ArrowStyle>
  //     );
  //   } else {
  //     return (
  //       <ArrowStyle className={'pb-1 ps-0'}>
  //         <FaAngleDown
  //           color={PURPLE}
  //           onClick={() => {
  //             setExpandable({
  //               ...expandable,
  //               [`${myKey}`]: !expandable[`${myKey}`],
  //             });
  //           }}
  //         />
  //       </ArrowStyle>
  //     );
  //   }
  // };

  /**
   * Return horizontal checkboxes
   * @param {Object} tableValue
   * @param {String} columnType
   * @returns
   */
  // const checkboxComponent = (tableValue, columnType) => {
  //   return (
  //     <div className={'d-flex  justify-content-between'}>

  //       <div className={'checkbox-container justify-content-center ps-3'}>
  //         <label className={'checkbox-label'}>
  //           <input
  //             type="checkbox"
  //             aria-label={READ_TITLE}
  //             checked={tableValue[columnType][`${columnType}_read`]}
  //             onChange={() => {
  //               changeCheckboxValues(
  //                 tableValue,
  //                 rolValues,
  //                 columnType,
  //                 `${columnType}_read`,
  //                 'read',
  //               );
  //             }}
  //           />
  //           <span className="checkbox-custom" />
  //         </label>
  //       </div>
  //     </div>
  //   );
  // };

  // const changeCheckboxValues = (
  //   tableValue,
  //   rolValues,
  //   columnType,
  //   typeCheckbox,
  //   type
  // ) => {
  //   let myMenuIndex = 0;

  //   if (tableValue.principal) {
  //     myMenuIndex = rolValues.findIndex(
  //       element => element.id === tableValue.id,
  //     );
  //   } else {
  //     myMenuIndex = rolValues.findIndex(
  //       element => element.id === tableValue.category,
  //     );
  //   }

  //   if (tableValue.principal) {
  //     rolValues[myMenuIndex].sections.forEach(rolSection => {
  //       //change rolValues ej. menu>subMenu.web{web_create: false or true}
  //       rolSection[columnType][typeCheckbox] = !tableValue[columnType][
  //         typeCheckbox
  //       ];

  //       //change rolValues ej. menu>subMenu>sub_subMenu.web{web_create: false or true}
  //       rolSection.sections.forEach(rolSubSection => {
  //         rolSubSection[columnType][typeCheckbox] = !tableValue[columnType][
  //           typeCheckbox
  //         ];
  //       });
  //     });
  //   } else {

  //     if (!tableValue.isSubsection) {
  //       rolValues[myMenuIndex].sections.forEach(rolSection => {

  //         //change rolValues ej. menu>subMenu>sub_subMenu.web{web_create: false or true}
  //         rolSection.sections.forEach(rolSubSection => {
  //           rolSubSection[columnType][typeCheckbox] = !tableValue[columnType][
  //             typeCheckbox
  //           ];
  //         });
  //       });
  //     } else {
  //       if (tableValue[columnType][typeCheckbox]) {
  //         rolValues[myMenuIndex][columnType][typeCheckbox] = false;
  //       }
  //     }
  //     //Change principal menu to false when submenu change to false

  //   }

  //   // change menu.web{web_create: false or true}
  //   tableValue[columnType][typeCheckbox] = !tableValue[columnType][
  //     typeCheckbox
  //   ];
  //   let check = tableValue[columnType][typeCheckbox];
  //   handleSwap({ ...tableValue, type: type, columnType: columnType }, check);

  //   setRolValues([...rolValues]);
  // };

  return (
    <Fragment>
      {/* <SubHeader /> */}
      {Object.keys(selectedRol).length >= 1 ? createRows() : null}
    </Fragment>
  );
};

export default BodyTable;
