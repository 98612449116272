import React, {useState, Fragment, useEffect, useRef, useContext} from 'react';
import {GrSearch} from 'react-icons/gr';
import {FaPlus} from 'react-icons/fa';
import {FloatingMobileButton} from 'shared/styles/buttons';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  FiltersContainer,
  FiltersLeftGroup,
} from 'shared/styles/constants/tagsStyles';
import {
  EmploymentTypesContainer,
  Header,
  SearchAddContainer,
  SearchContainer,
  SearchIcon,
  Title,
} from './styles';
import {useForm} from 'hooks/FormHook';
import {exportTeamCronJob, getEmployeesList} from 'utils/api/EmployeeApi';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {AppContext} from 'context';
import {BranchItemsContext} from 'context';
import moment from 'moment';
import NewVisitCare from '../../ReportClient/ClientFilters/NewVisitCare';
import {reports_team_choices} from 'utils/choiceConstant';
import DateSelector from 'components/SharedComponents/DateSelector';
import { Toast } from 'primereact/toast';

const capitalize = (text) => text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
  const removeUnderCcore = str => str ? str.includes("_") ? capitalize(str.split("_").join(" ")) : str : ""


 const column_selector = ["employee_name", "branch_name", "shift_type_name", "regular_shift_name", "regular_shift_detail_id", "start_date", "end_date", "last_shift_id", "created_at"];
const regularShiftColumn = column_selector.map((item) => ({id: item, name: removeUnderCcore(item)}))

const TeamFilters = ({
  location,
  updateFunderSettings,
  createFunderSettings,
  deletePermissionFunderSettings,
  readPermissionFunderSettings,
  filterAdmin,
  filterSuperAdmin,
  userStaff,
  tabClick,
}) => {
  const toast = useRef()
  const [activeTab, setActiveTab] = useState(tabClick);
  const {values, setValues, useInput, errors, setCheckRequires} = useForm({});
  const [employeesStatus, setEmployeesStatus] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [jobTitles, setJobTitles] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [runRouteData, setRunRouteData] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState([]);
  const [branch, setBranch] = useState([]);
  const [downloadDisable, SetDownloadDisable] = useState(false);
const  dateTo = useInput('complete_to')
const dateFrom = useInput('complete_from')
// const shift_start_date_hook = useInput('shift_start_date', activeTab == 'Regular Shift' ? "isRequired" : "")

const DateFilterForSpecific =  ['Skill_and_Training','Competency','Qualification_and_Appreciation','employee_expense']
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [employeeStatusFilter, setEmployeeStatusFilter] = useState([]);
  const [employmentTypeFilter, setEmploymentTypeFilter] = useState([]);
  const [shiftTypeFilter, setShiftTypeFilter] = useState([]);
  const [columnSelectorFilter, setColumnSelectorFilter] = useState([]);
  const [employeeFilter, setEmployeeFilter] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  
  const [jobTitleFilter, setJobTitleFilter] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const {contextChoices, handleGetChoices} = useContext(AppContext);
  const {branchData} = useContext(BranchItemsContext);
  useEffect(() => {
    handleGetChoices(reports_team_choices);
  }, []);

  useEffect(() => {
    setLoadingButton(false);
    SetDownloadDisable(false);
  }, [location.pathname]);


  

  const handleDownload = () => {
    if (errors.length) {
      setCheckRequires(true);
      return;
    }
    setLoadingButton(true);
    SetDownloadDisable(true);
    let fileName = `report_team_${activeTab}_${moment().format('YYYY-MM-DD')}`;
    (activeTab == 'Regular Shift'
      ? exportTeamCronJob({ ...branchData, ...values }, columnSelectorFilter)
      : getEmployeesList({ ...branchData, ...values }, activeTab))
      .then(response => {
        if (response) {
          setLoadingButton(true);
          SetDownloadDisable(true);
        }
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
        setTimeout(() => {
          setLoadingButton(false);
          SetDownloadDisable(false);
        }, 200);
      })
      .catch(err => {
        setLoadingButton(false);
        SetDownloadDisable(false);
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `Export process failed`,
        });
      });

    SetDownloadDisable(false);
  };

  

  useEffect(() => {
    setRunRouteData(
      contextChoices &&
        contextChoices.run_routes &&
        contextChoices.run_routes.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
    setEmployeeStatus(
      contextChoices.employee_status &&
        contextChoices.employee_status.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
    setBranch(
      contextChoices &&
        contextChoices.branch &&
        contextChoices.branch.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setEmploymentTypes(
      contextChoices &&
        contextChoices.employment_type &&
        contextChoices.employment_type.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
    setJobTitles(
      contextChoices &&
        contextChoices.job_title &&
        contextChoices.job_title.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    // setEmployeeCategories(contextChoices && contextChoices.employee_category && contextChoices.employee_category.map(y=>{
    //   return{ id: y && y.id , name: y && y.name}
    // }  ));

    setEmployeesStatus(
      contextChoices &&
        contextChoices.employee_status &&
        contextChoices.employee_status.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setEmployeeOptions(contextChoices &&
      contextChoices?.employee_listing &&
      contextChoices?.employee_listing?.map((y) => {
        return {id: y && y.id, name: y && y.full_name }
      })
    )
  }, [contextChoices]);

  useEffect(() => {
    setActiveTab(tabClick);
  }, [tabClick]);

  const setRunroutefilter = item => {
    setRunRoutesFilter([...item]);
  };
  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };
  const setFilterEmployeeStatus = item => {
    setEmployeeStatusFilter([...item]);
  };
  const setEmploymentType = item => {
    setEmploymentTypeFilter([...item]);
  };
  const setEmployeeType = item => {
    setEmployeeFilter([...item]);
  };
  const setShiftType = item => {
    setShiftTypeFilter([...item]);
  };
  const setJobTitle = item => {
    setJobTitleFilter([...item]);
  };
  const setColumnSelector = item => {
    setColumnSelectorFilter([...item]);
  };

  const dropDownHandleSelect = item => {
    setValues({...values, ...item});
  };
  console.log(activeTab, values ,"QQQQQQ")
  return (
    <div>
      <Toast ref={toast}></Toast>
      { DateFilterForSpecific.includes(activeTab) ? (
        <div>
          <Header>
            <FiltersContainer>
              <FiltersLeftGroup>
              
                <EmploymentTypesContainer className="me-2">
                  <div>
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date from"
                      {...dateFrom}
                    />
                  </div>
                </EmploymentTypesContainer>
                <EmploymentTypesContainer className="me-2">
                  <div>
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date to"
                      minDate={values.date_from}
                      {...dateTo}
                    />
                  </div>
                </EmploymentTypesContainer>
                <EmploymentTypesContainer className="me-2">
                  <NoSelectAllDropdownFilter
                    items={employmentTypes ? employmentTypes : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employment_type: items});
                    }}
                    itemsSelected={employmentTypeFilter}
                    setSelectedFilter={setEmploymentType}
                    placeHolder={'Employment Type'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup>
                <EmploymentTypesContainer className="me-2">
                  {/* <Dropdown
          items={jobTitles}
          onSelect={item => {
            dropDownHandleSelect({job_title: item});
          }}
          placeHolder={'Job Title'}
          appendItem=""
          isObject
          allOption
        /> */}
                  <NoSelectAllDropdownFilter
                    items={jobTitles ? jobTitles : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({job_title: items});
                    }}
                    itemsSelected={jobTitleFilter}
                    setSelectedFilter={setJobTitle}
                    placeHolder={'Job Title'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>

                <EmploymentTypesContainer className="me-2">
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({branch: items});
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup>
                <EmploymentTypesContainer className="me-2">
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({run_route: items});
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Group Area'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
                <EmploymentTypesContainer className=" me-2">
                  <NoSelectAllDropdownFilter
                    items={employeeStatus ? employeeStatus : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employee_status: items});
                    }}
                    itemsSelected={employeeStatusFilter}
                    setSelectedFilter={setFilterEmployeeStatus}
                    placeHolder={'Employee Status'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              </FiltersLeftGroup>
            </FiltersContainer>
          </Header>
        </div>
      ) : activeTab === 'Regular Shift' ? (
          <Header>
            <FiltersContainer>

            {/* <EmploymentTypesContainer className="me-2">
                  <div>
                    <DateSelector
                      typeFilter={true}
                      isRequired
                      labelTop="Shift Start Date"
                      {...shift_start_date_hook}
                    />
                  </div>
                </EmploymentTypesContainer> */}
                
            <EmploymentTypesContainer className="me-2">
                  <NoSelectAllDropdownFilter
                    items={employeeOptions || []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employee: items});
                    }}
                    itemsSelected={employeeFilter}
                    setSelectedFilter={setEmployeeType}
                    placeHolder={'Employee'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>

                <EmploymentTypesContainer className=" me-2">
                  <NoSelectAllDropdownFilter
                    items={employeeStatus ? employeeStatus : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employee_status: items});
                    }}
                    itemsSelected={employeeStatusFilter}
                    setSelectedFilter={setFilterEmployeeStatus}
                    placeHolder={'Employee Status'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>

              <EmploymentTypesContainer className="me-2">
                <NoSelectAllDropdownFilter
                  items={branch ? branch : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ branch: items });
                  }}
                  itemsSelected={branchFilter}
                  setSelectedFilter={setFilterBranch}
                  placeHolder={'Branch'}
                  isObject
                  allOption
                // hitApiAfterClear={hitApiAfterClear}
                />
              </EmploymentTypesContainer>

              

                {/* <EmploymentTypesContainer className="me-2">
                  <div>
                    <DateSelector
                      typeFilter={true}
                      labelTop="Date to"
                      minDate={values.date_from}
                      {...dateTo}
                    />
                  </div>
                </EmploymentTypesContainer> */}

               <EmploymentTypesContainer className="me-2">
                  <NoSelectAllDropdownFilter
                    items={ contextChoices && contextChoices?.regular_shift_type
                      ? contextChoices?.regular_shift_type
                      : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({shift_type: items});
                    }}
                    itemsSelected={shiftTypeFilter}
                    setSelectedFilter={setShiftType}
                    placeHolder={'Shift Type'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
                

                <EmploymentTypesContainer className="me-2">
                  <NoSelectAllDropdownFilter
                    items={regularShiftColumn}
                    setItemsSelected={items => {
                      dropDownHandleSelect({column_selector: items});
                    }}
                    itemsSelected={columnSelectorFilter}
                    setSelectedFilter={setColumnSelector}
                    placeHolder={'Select Column'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>

                
            </FiltersContainer>
        </Header>
      ) : (
        <Header>
            <FiltersContainer>
              <FiltersLeftGroup>
                <EmploymentTypesContainer className="me-2">
                  <NoSelectAllDropdownFilter
                    items={employmentTypes ? employmentTypes : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employment_type: items});
                    }}
                    itemsSelected={employmentTypeFilter}
                    setSelectedFilter={setEmploymentType}
                    placeHolder={'Employment Type'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              
              </FiltersLeftGroup>
              <FiltersLeftGroup>
                <EmploymentTypesContainer className="me-2">
                  {/* <Dropdown
          items={jobTitles}
          onSelect={item => {
            dropDownHandleSelect({job_title: item});
          }}
          placeHolder={'Job Title'}
          appendItem=""
          isObject
          allOption
        /> */}
                  <NoSelectAllDropdownFilter
                    items={jobTitles ? jobTitles : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({job_title: items});
                    }}
                    itemsSelected={jobTitleFilter}
                    setSelectedFilter={setJobTitle}
                    placeHolder={'Job Title'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>

                <EmploymentTypesContainer className="me-2">
                  <NoSelectAllDropdownFilter
                    items={branch ? branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({branch: items});
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup>
                <EmploymentTypesContainer className="me-2">
                  <NoSelectAllDropdownFilter
                    items={runRouteData ? runRouteData : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({run_route: items});
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Group Area'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
                <EmploymentTypesContainer className=" me-2">
                  <NoSelectAllDropdownFilter
                    items={employeeStatus ? employeeStatus : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({employee_status: items});
                    }}
                    itemsSelected={employeeStatusFilter}
                    setSelectedFilter={setFilterEmployeeStatus}
                    placeHolder={'Employee Status'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}
                  />
                </EmploymentTypesContainer>
              </FiltersLeftGroup>
            </FiltersContainer>
          </Header>
      )}
      
      <SearchAddContainer className="d-flex align-items-center">
        <FloatingMobileButton
          onClick={!downloadDisable && handleDownload}
          disabled={downloadDisable}>
          <label>Download List</label>
          {loadingButton ? (
            <Fragment>
              <span
                className="spinner-border spinner-border-sm me-1 ms-2"
                role="status"
              />
            </Fragment>
          ) : (
            <Fragment />
          )}
        </FloatingMobileButton>
      </SearchAddContainer>
    </div>
  );
};

export default TeamFilters;
