import React, {Fragment, useEffect, useState} from 'react';
import Fade from 'react-reveal/Fade';
import {
  FormContent,
  ImageWrapper,
  InputLogin,
  InputsContent,
  LoginContent,
  PasswordStyle,
  SmallText,
  UpperText,
} from 'components/AuthComponents/Login/styles';
import {ErrorMessageShake, SuccessMessage} from 'shared/styles/AuthComponents';
import {PrimaryButton} from 'shared/styles/buttons';
import {useInput} from 'hooks/InputHook';
import {verifyEmail} from 'utils/api/SessionApi';
import {Link, useHistory, useParams} from 'react-router-dom';
import logo from 'images/logoOutBgd.png';
import {AUTH} from 'utils/constants/routes';
import {MdRemoveRedEye} from 'react-icons/md';
import {CHECK_CIRCLE} from '../../../utils/constants/icons';
import {MessageOptaPlanner} from '../../DashboardComponents/Scheduler/styles';
import ModalInformation from '../../SharedComponents/ModalInformation';

const VerifyEmail = () => {
  const {value: confirmPassword, bind: bindConfirmPassword} = useInput('');
  const {value: password, bind: bindPassword} = useInput('');
  const [showErrorMessage, setShowErrorMessage] = useState({
    status: false,
    message: '',
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    status: false,
    message: '',
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const [typeInputConfirmPassword, setTypeInputConfirmPassword] =
    useState('password');
  const [typeInputPassword, setTypeInputPassword] = useState('password');
  let {token} = useParams();
  const history = useHistory();

  let timerPointer = '';
  const handleSubmit = e => {
    try {
      e.preventDefault();

      if (loadingButton) return;

      setShowErrorMessage({
        status: false,
        message: '',
      });

      setLoadingButton(true);
    
      if(password.length < 8){
        throw new Error('The password should be 8 character long.');
      }
      if(confirmPassword.length < 8){
        throw new Error('The confirmPassword should be 8 character long.');
      }

      if (password !== confirmPassword) {
        throw new Error('Confirm Password is not same.');
      }

      if (!token) {
        throw new Error('Verification token is not available.');
      }

      verifyEmail({token, password})
        .then(response => {
          // TODO: error codes
          if (!response.message) {
            throw response;
          }

          // if (!response.access_token) {
          //   throw response;
          // }
          // setToken(response.access_token);
          // setUser(response.user);
          timerPointer = setTimeout(() => {
            history.push(AUTH);
            setShowSuccessMessage({
              message: 'Password is set successfully',
              status: true,
            });
          }, 4000);

          setShowSuccessMessage({
            message: 'Password is set successfully',
            status: true,
          });

          setLoadingButton(false);
        })
        .catch(e => {
          setLoadingButton(false);
          setShowErrorMessage({
            status: true,
            message: 'Sign In Failure: unknown user name or bad password.',
          });
        });
    } catch (err) {
      setLoadingButton(false);
      setShowErrorMessage({
        status: true,
        message: err.message,
      });
    }
  };

  useEffect(() => {
    setShowErrorMessage({status: false, message: ''});
  }, [password]);

  const handleClick = changeType => {
    setTypeInputPassword(changeType);
  };

  const handleClickConfirmPassword = changeType => {
    setTypeInputConfirmPassword(changeType);
  };

  const handleSuccessModelCancel = () => {
    setShowSuccessMessage({...showSuccessMessage, status: false});
    clearTimeout(timerPointer);
    history.push(AUTH);
  };

  return (
    <LoginContent>
      <div
        className={
          'd-flex justify-content-md-end justify-content-center mt-4 me-lg-5 me-sm-5 me-0'
        }>
        {/* <div className={'pt-1 me-3'}>Don't have an account?</div>
        <div>
          <PrimaryButton type="button" outline={true}>
            <span className={'ms-2 me-2 font-weight-bold'}>Sign up</span>
          </PrimaryButton>
        </div> */}
      </div>

      <Fade>
        <Link to={AUTH}>
          <ImageWrapper>
            <img src={logo} alt="logo-img" />
          </ImageWrapper>
        </Link>
      </Fade>

      <FormContent>
        <InputsContent>
          <div className={'mb-3'}>
            <UpperText>Enter New Password</UpperText>

            <SmallText>
              <div className={'text-black-50'}>
               Please enter your new secure password (of atleast 8 digits ) here
              </div>
            </SmallText>
          </div>

          <form onSubmit={handleSubmit} className={'mb-5'} noValidate>
            <div>
              <div className={'font-weight-bold mb-2'}>Password</div>
              <div>
                <PasswordStyle>
                  <InputLogin
                    type={typeInputPassword}
                    id="password"
                    placeholder="**********"
                    autoComplete="current-password"
                    error={showErrorMessage.status}
                    minlength="8"
                    {...bindPassword}
                  />
                  <span
                    onMouseDown={() => handleClick('text')}
                    onMouseUp={() => handleClick('password')}>
                    <MdRemoveRedEye />
                  </span>
                </PasswordStyle>
              </div>
            </div>
            <div className={'mt-4 p-0'}>
              <div className={'font-weight-bold mb-2'}>
                <div>Confirm Password</div>
              </div>
              <PasswordStyle>
                <InputLogin
                  type={typeInputConfirmPassword}
                  id="password"
                  placeholder="**********"
                  autoComplete="current-password"
                  error={showErrorMessage.status}
                  minlength="8"
                  {...bindConfirmPassword}
                />
                <span
                  onMouseDown={() => handleClickConfirmPassword('text')}
                  onMouseUp={() => handleClickConfirmPassword('password')}>
                  <MdRemoveRedEye />
                </span>
              </PasswordStyle>
            </div>

            <div>
              {showErrorMessage.status ? (
                <ErrorMessageShake className=" mt-3">
                  {showErrorMessage.message}
                </ErrorMessageShake>
              ) : (
                <Fragment />
              )}
              {showSuccessMessage.status ? (
                <SuccessMessage className=" mt-3">
                  {showSuccessMessage.message}
                </SuccessMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={`${
                showErrorMessage.status ? 'mt-3' : 'mt-5'
              } d-flex justify-content-md-start justify-content-center`}>
              <PrimaryButton
                type="submit"
                shadow={true}
                disabled={loadingButton ? true : false}>
                {loadingButton ? (
                  <Fragment>
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                    <span>Loading...</span>
                  </Fragment>
                ) : (
                  <span className={'m-5 font-weight-bold'}>CONFIRM</span>
                )}
              </PrimaryButton>
            </div>
          </form>
        </InputsContent>
      </FormContent>

      <ModalInformation
        type={'success'}
        title={'Password is set'}
        onCancelTitle={'Cancel'}
        onOkTitle={'Ok'}
        icon={CHECK_CIRCLE}
        hideOnCancel={true}
        onOk={handleSuccessModelCancel}
        showModal={showSuccessMessage.status}>
        <MessageOptaPlanner className={'mt-4 mb-3'}>
          <div className={'warningMessage ps-3 pe-3'}>
            The password has been set, you can use the credentials for login.
          </div>
        </MessageOptaPlanner>
      </ModalInformation>
    </LoginContent>
  );
};

export default VerifyEmail;
