import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React, {useState, useEffect} from 'react';
import {ModalContainer} from 'shared/styles/constants/tagsStyles';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {Toggle, ToggleLabel, ToggleWrapper} from 'shared/styles/buttons';
import { ToggleDiv } from 'components/DashboardComponents/Scheduler/styles';
import {IoIosArrowDroprightCircle} from 'react-icons/io';
import './splitOverlap.css';
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import {getUnassignedList} from 'utils/api/RotaShift';

const SplitOverlapModal = ({
  fullBind,
  shiftOverlapData,
  unassignedVisitList,
  setOverlapData,
  employees,
  splitAvailability,
  loadingOverlap,
  loadingProceed,
  addNewAvailability,
  disableButton,
  overlapMessage,
  currentModal,
  values,
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [splitOverallData, setSplitOverallData] = useState([]);
  let disableIndex =[]
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Overlap Availablities',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);

  const BootstrapTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#6E6BFA',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#6E6BFA',
    },
  }));
  console.log(disableIndex,"disableIndex")
  const unassignedList = (item, selected_index) => {
    if(disableIndex.includes(selected_index)){
      return;
    }
   disableIndex.push(selected_index)
    const body = {
      employee: item.employee_id,
      start_date: item.start_date,
      end_date: item.end_date,
      start: values.start,
      end: values.end,
    };

   
    getUnassignedList(body)
      .then(res => {
        if (res.status == 'True') {
          const data = shiftOverlapData.map((val, index) => {
            if (selected_index === index) {
              return {
                ...val,
                show: true,
                unassign_data: res.data,
              };
            } else {
              return {
                ...val,
                show: false,
              };
            }
          });
          setOverlapData(data || []);
        }
      })
      .catch(error => {});
  };

  const unassignedToggleClick=(item, selected_index)=>{
    let tem = [...shiftOverlapData]

    tem[selected_index].force_unassigned_visit = !tem[selected_index].force_unassigned_visit;
    setOverlapData([...tem]);
  }

  return (
    <ModalContainer max_width={'70%'}>
      {/* <ModalDynamic
          {...addFullBind}
          max_width={'70%'}
          minWidthTitle="6.5rem"
          loading={false}>
      <div className={'pt-2 ps-3 pe-3 pb-3'}>
        <p style={{marginBottom:"5px"}}>{overlapMessage}</p>
        {
       shiftOverlapData && shiftOverlapData.map((item,index)=>{
             return(
              <>
              <div className="shift__warp">
                <div style={{display:"flex"}}>
              <div style={{marginRight:"3px"}}>
              <IoIosArrowDroprightCircle/>
              </div>
            <p className="emp__det">{

         employees && employees.find(employee=>employee.id===item.employee_id).name
            +`(${item.start_date} - ${item.end_date})`

            }
            
            </p>
            </div>
        <div style={{display:"flex"}}>
               <div  style={{marginTop:"-5px",marginRight:"10px"}} className={'me-1'}>
                <PrimaryButtonForm
                   minWidth="4rem"
                   disabled={disableButton.find(ele=>ele===index+1)?true:false}
                   onClick={() => {
                     splitAvailability(currentModal, true,item.employee_id,item.start_date,item.end_date,index);
                    //  closeModal();
                   }}
                   >
                   <span className={'me-1 ps-0.5 pe-0.5'}>SPLIT AVAILABLITIES</span>
                   {!loadingOverlap.find(ele=>ele===index+1) ? (
                      null
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                 </PrimaryButtonForm>
               </div>
          <div style={{marginTop:"-5px"}} className={'me-1'}>
               <PrimaryButtonForm
                 minWidth="4rem"
                 disabled={disableButton.find(ele=>ele===index+1)?true:false}
                 onClick={() => {
                   addNewAvailability(currentModal, true,item.employee_id,index,item.start_date,item.end_date,item.detail_id);
                 }}
                 >
                 <span className={'me-1 ps-0.5 pe-0.5'}>YES, PROCEED</span>
                 {!loadingProceed.find(ele=>ele===index+1) ? (
                      null
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
               </PrimaryButtonForm>
             </div>
             </div>
              </div>
              </>
             )
      })
        }
      
        </div>
       \\ </ModalDynamic> */}

      <ModalDynamic
        {...addFullBind}
        max_width={'70%'}
        minWidthTitle="6.5rem"
        loading={false}>
        <div className={'pt-2 ps-3 pe-3 pb-3'}>
          <p style={{marginBottom: '5px'}}>{overlapMessage}</p>
          {shiftOverlapData &&
            shiftOverlapData.map((item, index) => {
              return (
                <>
                  <div className="shift__warp">
                    <div className='d-flex w-100'>
                      <div style={{display: 'flex'}}>
                        <div style={{marginRight: '3px'}}>
                          <IoIosArrowDroprightCircle />
                        </div>
                        {/* <BootstrapTooltip
                          title={
                            <>
                              <div>Show Visits</div>
                            </>
                          }> */}
                          <p
                            className="emp__det"
                            onClick={() => unassignedList(item, index)}>
                            {
                              employees &&
                                employees?.find(
                                  employee => employee.id === item.employee_id
                                )?.name +
                                  item.details.map(itemData => {
                                    return `(${itemData.start_date} - ${itemData.end_date})`;
                                  })
                              //  employees && employees.find(employee=>employee.id===item.employee_id)?.name
                              //     +`(${item.start_date} - ${item.end_date})`
                            }
                          </p>
                        {/* </BootstrapTooltip> */}
                        </div>
                        <div style={{ display: "flex" ,justifyContent:"right", flex:'auto'  }}>
                        <div className='ms-3'>
                          <ToggleDiv>
                            <ToggleWrapper>
                              <Toggle
                                id="checkbox"
                                type="checkbox"
                                onClick={() => unassignedToggleClick(item, index)}
                              />
                              <ToggleLabel htmlFor="checkbox" />
                            </ToggleWrapper>
                            <div className="toggle-label ms-2  mb-3 pb-3 me-2">
                              <p>Force Unassigned</p>
                            </div>
                          </ToggleDiv>
                        </div>
                      <div style={{ marginTop: "-5px", marginLeft: "10px" }} className={'me-1'}>
                        <PrimaryButtonForm
                          minWidth="10rem"
                          disabled={disableButton.find(ele => ele === index + 1) ? true : false}
                           onClick={() => {
                            // splitAvailability(currentModal, true,item.employee_id,item.start_date,item.end_date,item.details,index);
                            addNewAvailability(currentModal, false, true, item.employee_id,index,item.start_date,item.end_date, item.details, item.force_unassigned_visit, item.force_unassigned_visit);
                            //  closeModal();
                           }}
                        >
                          <span className={'me-1 ps-0.5 pe-0.5'}>Split the existing shift</span>
                          {!loadingOverlap.find(ele => ele === index + 1) ? (
                            null
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                            />
                          )}
                        </PrimaryButtonForm>
                      </div>
                      <div style={{ marginTop: "-5px" }} className={'me-1'}>


                        <PrimaryButtonForm
                          minWidth="10rem"
                          disabled={disableButton.find(ele => ele === index + 1) ? true : false}
                           onClick={() => {
                             addNewAvailability(currentModal, true, false, item.employee_id,index,item.start_date,item.end_date, item.details, item.force_unassigned_visit);
                           }}

                         
                        >
                          <span className={'me-1 ps-0.5 pe-0.5'}>Fully override the existing shift</span>
                          {!loadingProceed.find(ele => ele === index + 1) ? (
                            null
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                            />
                          )}
                        </PrimaryButtonForm>
                     
                    </div>
                      </div>
                     
                
                     
                      
                      <div>
                       
                      </div>
                    </div>

                    {(item && item?.show && item?.show) && <div className='card p-2'>
                      {item &&
                        item.unassign_data && item.unassign_data.length > 0 ?
                        item.unassign_data.map((val, index) => {
                          return (
                            <p className="emp__det" style={{ padding: "5px" }}>
                              <span style={{ fonSize: "1rem", fontWeight: '700' }}>{index + 1}.{" "}</span>
                              {val.client_service_visit__client__full_name +
                                `(${val.start_date} - ${val.end_date})`}
                            </p>

                          );
                        }) : <div className='w-100 d-flex align-items-center justify-content-center'>
                          <h6 className='p-2'>No data found</h6>
                        </div>}

                    </div>}
                  </div>
                </>
              );
            })}
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default SplitOverlapModal;
