import React, { useState, useEffect } from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  TitleSection,
  FlexEqual,
} from '../styles';
import { KeyPress, onChangeValidations } from 'utils/constants/regularExpressions';


import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import _ from 'lodash'
const PersonalAddress = ({
  useInput,
  readOnly,
  values,
  setValues
}) => {
  const [address, setAddress] = useState("")

  const handleChange = (address) => {
    setAddress(address)
  }




  const handleSelect = async (value) => {
    handleChange(value)
    const result = await geocodeByAddress(value)
    console.log(result, "41544")
    const coordinates = await getLatLng(result[0])

    if (result) {
      let tempAddress = _.cloneDeep(result[0].address_components)
      if (!values.address) {
        values.address = {}
      }
      values.address.latitude = coordinates.lat
      values.address.longitude = coordinates.lng
      result[0].address_components.forEach((element, index) => {
        if (element.types.find(value => value == 'postal_code')) {
          values.address.zip_code = element.long_name
          //  tempAddress.splice(index,1)
        }
        else if (element.types.find(value => value == 'country')) {
          values.address.country = element.long_name
          // tempAddress.splice(index,1)
        }
        else if (element.types.find(value => value == 'administrative_area_level_1')) {
          values.address.state = element.long_name
          // tempAddress.splice(index,1)
        }
        else if (element.types.find(value => value == 'administrative_area_level_2' || value == 'locality')) {
          values.address.city = element.long_name
          // tempAddress.splice(index,1)
        }

      });
      let lineAddress = ""
      tempAddress.forEach(ele => {
        if (ele.types.includes('postal_code') || ele.types.includes('country') || ele.types.includes('administrative_area_level_1')
          || ele.types.includes('administrative_area_level_2') || ele.types.includes('locality')
        ) {
          lineAddress = lineAddress
        } else {
          lineAddress += ele.long_name + " "
        }

      })
      values.address.line_1 = lineAddress
      setValues({ ...values })
    }
  }

  const allowBackspace = (e, type) => {
      if (e.key === 'Backspace') {
        if (values.address[type] && values.address[type].length > 0)
          setValues({ ...values, address: { ...values.address, [type]: values.address[type].slice(0, -1) } })
        e.preventDefault()
      }
  }
  const handleLongLatChange = (e, type) => {
    const { valid, value } = onChangeValidations[type](e)
    if (!valid) return;
    setValues({ ...values, address: { ...values.address, [type]: value } })
  }


  return (
    <>
      <FlexEqual className="me-md-2 me-sm-0">
        <TitleSection>Address</TitleSection>
        <div className="d-flex flex-column mt-2">
          <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
          >
            {
              ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                  <InputLabelTop
                    {...getInputProps({
                      placeholder: 'Search Places ...',
                      className: 'location-search-input',
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                      console.log('address data', suggestion)
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#D3D3D3', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )


            }
          </PlacesAutocomplete>
          <InputLabelTop
            type="text"
            readOnly={readOnly}
            id="address_name"
            label={'House Name'}
            {...useInput('address.name')}
            maxlength={50}
          />
          <div className="d-flex">
            <div className="w-100 me-2">
              <InputLabelTop
                required
                readOnly={readOnly}
                type="text"
                id="address_line_1"
                label={'Address Line 1'}
                {...useInput('address.line_1', 'isRequired')}
                maxlength={255}
              />
            </div>
            <div className="w-100">
              <InputLabelTop
                type="text"
                readOnly={readOnly}
                id="address_line_2"
                label={'Address Line 2'}
                {...useInput('address.line_2')}
                maxlength={255}
              />
            </div>
          </div>

          <div className="d-flex">
            <div className="w-100 me-2">
              <InputLabelTop
                required
                readOnly={readOnly}
                type="text"
                id="town_city"
                label={'Town / City'}
                {...useInput('address.city', 'isRequired')}
                maxlength={100}
              />
            </div>
            <div className="w-100">
              <InputLabelTop
                type="text"
                readOnly={readOnly}
                id="country_state"
                label={'County / State'}
                {...useInput('address.state')}
                maxlength={100}
              />
            </div>
          </div>

          <div className="d-flex">
            <div className="w-100 me-2">
              <InputLabelTop
                required
                readOnly={readOnly}
                type="text"
                id="postcode_zipcode"
                label={'Postcode / Zipcode'}
                {...useInput('address.zip_code', 'isRequired')}
                maxlength={25}
              />
            </div>
            <div className="w-100">
              <InputLabelTop
                required
                readOnly={readOnly}
                type="text"
                id="country"
                label={'Country'}
                {...useInput('address.country', 'isRequired')}
                maxlength={100}
              />
            </div>
          </div>

          <div className="d-flex">
            <div className="w-100 me-2">
              <InputLabelTop
                // disabled={true}
                required
                type="text"
                readOnly={readOnly}
                id="latitude"
                label={'Latitude'}
                {...useInput('address.latitude', 'isRequired')}
                onChange={(e) => handleLongLatChange(e, 'latitude')}
                onKeyDown={(e) => allowBackspace(e, 'latitude')}
              />
            </div>
            <div className="w-100">
              <InputLabelTop
                // disabled={true}
                required
                type="text"
                readOnly={readOnly}
                id="longitude"
                label={'Longitude'}
                {...useInput('address.longitude', 'isRequired')}
                onChange={(e) => handleLongLatChange(e, 'longitude')}
                onKeyDown={(e) => allowBackspace(e, 'longitude')}
              />
            </div>
          </div>
        </div>

      </FlexEqual>
    </>
  );
}

export default PersonalAddress;
