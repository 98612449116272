import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import Table from 'components/SharedComponents/Table';
import React, { useState, useEffect, Fragment, useContext } from 'react';
import { AppContext } from 'context';
import { getMedicationList, setMedClickEvent } from 'utils/api/ClientApi';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { EX_VERY_LARGE_COL, LARGE_COL, } from 'shared/styles/constants/columns';
import { useForm } from 'hooks/FormHook';
import { InvalidOrEmptyValues, ERROR, SAVE, SAVED, } from 'utils/constants/messages';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { Row, Col, ColsGrouper, } from 'components/SharedComponents/Table/styles';
import { calendar_medication_choices, } from 'utils/choiceConstant';
import { getMedicationListOnSchedular, getSchedularMedication } from 'utils/api/SchedulerApi';
import { BsFolderPlus } from 'react-icons/bs';
import { TiArrowBackOutline } from 'react-icons/ti';
import AddMedication from './AddMedication';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import Spinner from "components/SharedComponents/Spinner"

export const headerColumns = [
  {
    label: 'Name',
    name: 'name',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_VERY_LARGE_COL,
  },
  {
    label: 'Medication Type',
    name: 'type',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Medication Dose Time',
    name: 'description',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  // {
  //   label: 'Medication Dose Time',
  //   name: 'uploaded',
  //   status: 0,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // },

];

const MedicationPopup = ({
  visitId,
  clientSelectedId,
  client_name,
  props,
  handleChangeChoices = () => { },
  visitData = {},
  employees=[],
  clients=[]
}) => {
  const { visit_start_date, visit_end_date, id: service_visit } = visitData;
  const { contextChoices, handleGetChoices, loadingChoices } = useContext(AppContext);

  const [Data, setData] = useState([]);
  const [modalValue, setModalValue] = useState(false);
  const toast = useRef();
  const [medList, setMedList] = useState([])
  const [loading, setLoading] = useState(true)
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false)
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setFormErrors,
    setCheckRequires,
  } = useForm({ medication: { client: clientSelectedId } });


  useEffect(() => {
    if (values.medication.client) {
      getMedicationNameList()
    }
    handleGetChoices(calendar_medication_choices);
    getMedicationData();
  }, [])

  const handleModal = event => {
    setModalValue(!modalValue);
    setValues({ medication: { client: clientSelectedId } })
    if (!(event === 'new med')) {
      getMedicationData()
    } else {
      setLoadingSave(false);
      setButtonIcon("");
      setTextStatus(SAVE);
      setCheckRequires(false)
    }
  };


  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const getMedicationData = async () => {
    try {
      const response = await getSchedularMedication(visitId, { ...values, visit_start_date, service_visit });
      setData(response.data);
    } catch { }
  };


  const getMedicationNameList = () => {
    getMedicationList(values.medication.client)
      .then((res) => {
        const temp_opt = res?.data?.map((i) => ({ id: i.name__id, name: i.name__name, med_sch_id: i.id }));
        setMedList(temp_opt || [])
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }


  const dropDownHandleSelect = (item, nameItem, index) => {
    if (nameItem == 'medication_dose_timing') {
      values.medication[nameItem] = item[nameItem].value;
      values.medication.medication_dose_timing_name = item[nameItem].label;
      values.medication.medication_dose_time = item[nameItem].start_time;
      values.medication.medication_dose_end_time = item[nameItem].end_time;
    } else if (nameItem == 'medication_type') {
      values.medication.medication_type_name = item[nameItem]?.name
      values.medication[nameItem] = item[nameItem]?.id
    } else if (nameItem == 'name') {
      values.medication[nameItem] = item[nameItem]?.id;
      values.medication.medication_schedule_id = item[nameItem]?.med_sch_id;
    } else {
      values.medication[nameItem] = item[nameItem];
    }

    setValues({ ...values }); //item added

  };

  const handleSubmit = () => {
    console.log("Handle submit values", values)
    let body = {
      core_medication_dose_id: values.medication.medication_dose,
      start_date: values.medication.start_date,
      is_adhoc: false,
      name_id: values.medication.name,
      medication_type_id: values.medication.medication_type,
      medication_dose_time: values.medication.medication_dose_time,
      administration_type_id: values.medication.administration_type,
      administration_reason_id: values.medication.administration_reason,
      medication_route_id: values.medication.medication_route,
      medication_form_id: values.medication.medication_form,
      client_id: values.medication.client,
      employee_id: values.medication.employee,
      medication_assistance_id: values.medication.medication_assistance,
      medication_instructions: values.medication.medication_instructions,
      instructions: values.medication.instructions,
      dose_regime: values.medication.dose_regime,
      frequency: values.medication.frequency,
      important: values.medication.important,
      prescription_volume: values.medication.prescription_volume,
      reason: values.medication.reason,
      potential_side_effects: values.medication.potential_side_effects,
      comments: values.medication.comments,
      administration_area: values.medication.administration_area,
      marking_style: values.medication.marking_style,
      storage_location: values.medication.storage_location,
      prescription_expiry_date: values.medication.prescription_expiry_date,
      administration_support: values.medication.administration_area,
      photo: null,
      medication_status_id: values.medication.medication_status,
      dose: {
        dose_id: values.medication.medication_dose,
        medication_dose_timing: values.medication.medication_dose_timing,
        medication_dose_time: values.medication.medication_dose_time,
        medication_dose_end_time: values.medication.medication_dose_end_time,
        instructions: values.medication.instructions
      },
    }

    if (values.medication.id) {
      body.id = values.medication.id;
      body.actual_start_date = values.medication.actual_start_date;
      body.actual_medication_dose_time = values.medication.actual_medication_dose_time;
      body.feedback = values.medication.feedback;
      body.medication_reason = values.medication.medication_reason;
      delete body.core_medication_dose_id
    }

    if (values.medication.medication_type_name === 'PRN') {
      body.is_prn = true;
      body.medication_schedule_id = values.medication.medication_schedule_id;
    }

    if (!isValid && errors.length > 0) {
      setCheckRequires(true);
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: InvalidOrEmptyValues,
      });
    }
    else {
      setLoadingSave(true)
      setMedClickEvent(body, values.medication.client, visitId)
        .then(response => {
          setLoadingSave(false)
          if (response.status === "SUCCESS") {
            handleModal()
            onSuccess()
          } else {
            onError(response.message)
          }
        })
        .catch((err) => { onError(err.message) });
    }
  }

  const onSuccess = response => {
    setModalValue(false)
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setModalValue(false);
    getMedicationData()
  };

  const onError = err => {
    let getErrorMessage = err ? err : 'something went wrong !!'
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(getErrorMessage || 'something went wrong !!');
  };
  return (
    <div>
      <Toast ref={toast} position="top-right" />
      {loading || loadingChoices ? (
        <div style={{ marginTop: '300px' }}>
          <Spinner />
        </div>
      ) : (
        <ComponentDynamic loading={false} style={{ maxHeight: '100px' }}>
          <div>
            {(Data.length >= 1) && !modalValue ? (

              <div className={'w-100 d-flex justify-content-center mb-3 mt-1'}>
                <div className={'text-primary font-weight-bold'}>
                  <div>
                    <PrimaryButtonForm
                      minWidth="6rem"
                      onClick={() => handleModal('new med')}>
                      <BsFolderPlus size={18} />
                      <span className={'ms-2'}>ADD NEW MEDICATION</span>
                    </PrimaryButtonForm>
                  </div>
                </div>

              </div>

            ) : (
              <Fragment />
            )}


            {modalValue && Data.length >= 1 ? (
              <div className={'w-100 d-flex justify-content-center'}>
                <div>
                  <PrimaryButtonForm
                    bgcolor={'PURPLE_GRAY_500'}
                    minWidth="6rem"
                    onClick={handleModal}>
                    <div className={'d-flex align-items-center'}>
                      <TiArrowBackOutline size={24} />
                      <div>BACK</div>
                    </div>
                  </PrimaryButtonForm>
                </div>

              </div>
            ) : (
              <Fragment />
            )}
            <>

              <div
                className="mt-3"
                style={{
                  maxHeight: '600px',
                  height: '20%',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                }}>
                {Data && Data.length > 0 && !modalValue && (
                  <Table
                    headerColumns={headerColumns}
                    headerPadding={false}
                    noNeed={false}
                    noEditOption={true}
                    fontSize="0.75rem"
                    callBackQuerySort={setQuerySort}>
                    <div>
                      {Data &&
                        Data.map((shift, index) => {
                          return (
                            <div key={index}>
                              <Row
                                bgColor
                                Shadow
                                width={'111%'}>
                                <ColsGrouper
                                  className="d-flex"
                                  margin-left={'77px'}
                                  width={'111%'}>


                                  <Col
                                    max_width={EX_VERY_LARGE_COL}
                                    Capitalize
                                  >
                                    {shift.name}
                                  </Col>
                                  <Col
                                    max_width={LARGE_COL}
                                    Shrink
                                    reducePadding
                                    Capitalize>
                                    {shift.medicationType}
                                  </Col>
                                  <Col
                                    max_width={LARGE_COL}
                                    Shrink
                                    reducePadding
                                    Capitalize>
                                    {shift.medicationDoseTime}
                                  </Col>
                                  {/* <Col
                                  max_width={LARGE_COL}
                                  Shrink
                                  reducePadding
                                  Capitalize>
                                 {shift.medicationDoseTimeName
}
                                </Col> */}
                                </ColsGrouper>
                              </Row>
                              <div className='mb-1' />
                            </div>
                          );
                        })}
                    </div>
                  </Table>
                )}
                {/* <div
                className={'ms-0 ms-md-3'}
                style={{ display: 'flex', justifyContent: 'right' }}>
                <PrimaryButtonForm
                  disabled={
                    values[TASK_VALUE] && values[TASK_VALUE].length > 0
                      ? false
                      : true
                  }
                  style={{ marginTop: '200px', textAlign: 'right' }}
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                  onClick={setNewTask}>
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div> */}
              </div>
            </>
          </div>
          {!modalValue && Data.length >= 1 ? null : (
            <AddMedication
              useInput={useInput}
              values={values}
              choices={contextChoices}
              editMode={true}
              dropDownHandleSelect={dropDownHandleSelect}
              handleSubmit={handleSubmit}
              errorMessage={errorMessage}
              loadingSave={loadingSave}
              medList={medList}
              employees={employees}
              clients={clients}
            />
          )}
        </ComponentDynamic>
      )}
    </div>
  );
};

export default MedicationPopup;
