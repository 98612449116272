import React, { useState, useEffect, Fragment } from 'react';
import {
  BasicInformation,
  Address,
  Contact,
} from 'components/SharedComponents/FormSections';
import { useForm } from 'hooks/FormHook';
import { pickFile } from 'utils/files';
import _ from 'lodash';
import { FlexEqual, NoPermissionContainer, TitleSection } from '../../Team/AddModal/styles';
import ToggleButton from '../../../SharedComponents/ToggleButton';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import DropdownSearchable from '../../../SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import { useHistory } from 'react-router';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import MapComponent from 'components/SharedComponents/Map/MapComponent'
import Geocode from 'react-geocode'
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {
  ContainerResponsive,
  ErrorMessage,
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import {
  WEB_CREATE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  CLIENT_BASIC_INFORMATION,
  CLIENT_CONTACT,
  CLIENT_ADDRESS,
  CLIENT_INVITE_USER,
  CLIENT_REFERRALS,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION
} from '../../../../utils/constants/permissions';
import { setClient, getClient, shouldUpdateStatus } from 'utils/api/ClientApi';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import { useModal } from 'hooks/ModalHook';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { personal_detail_client_choices } from 'utils/choiceConstant';
import { AppContext } from 'context';
import { useContext } from 'react';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';

const PersonalDetails = ({
  props,
  createPersonalDetails,
  updatePersonalDetails,
  readPersonalDetails,
  userClient,
  contextSubscription,
  rightPermission,
  choices,
  companyBranches,
  linkedTo,
  filterAdmin,
  filterSuperAdmin,
  setCliId,
  selectedClientId,
  setClientAddress,
  setClientFullName,
  setRowData,
  setDisplayPhoto,
  handleChangeChoices,
  contextRelativesClient,
  tabSelected = 1,
}) => {
  const [basicInfoPhoto, setBasicInfoPhoto] = useState(null);
  const [makeUser, setMakeUser] = useState(false);
  const [checkData, setCheckData] = useState(false)
  const [showError, setShowError] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalLoading, setModalLoading] = useState(true)
  const [dateStatus, setDateStatus] = useState('');
  const [warningText, setWarningText] = useState('null');
  const [personalDetailsBasicInfoCreate, setPersonalDetailsBasicInfoCreate] = useState(false);
  const [personalDetailsBasicInfoUpdate, setPersonalDetailsBasicInfoUpdate] = useState(false);
  const [personalDetailsBasicInfoRead, setPersonalDetailsBasicInfoRead] = useState(false);
  const [personalDetailsAddressCreate, setPersonalDetailsAddressCreate] = useState(false);
  const [personalDetailsAddressUpdate, setPersonalDetailsAddressUpdate] = useState(false);
  const [personalDetailsAddressRead, setPersonalDetailsAddressRead] = useState(false);
  const [personalDetailsContactRead, setPersonalDetailsContactRead] = useState(false);
  const [personalDetailsContactCreate, setPersonalDetailsContactCreate] = useState(false);
  const [personalDetailsContactUpdate, setPersonalDetailsContactUpdate] = useState(false);
  const [personalDetailsInviteUserRead, setPersonalDetailsInviteUserRead] = useState(false);
  const [personalDetailsInviteUserCreate, setPersonalDetailsInviteUserCreate] = useState(false);
  const [personalDetailsInviteUserUpdate, setPersonalDetailsInviteUserUpdate] = useState(false);
  const [personalDetailsClientReferralsRead, setPersonalDetailsClientReferralsRead] = useState(false);
  const [personalDetailsClientReferralsCreate, setPersonalDetailsClientReferralsCreate] = useState(false);
  const [personalDetailsClientReferralsUpdate, setPersonalDetailsClientReferralsUpdate] = useState(false);
  const { values, setValues, useInput, isValid, errors, setCheckRequires,setFormErrors } =
    useForm({});
  const { setValue: setModalWarningValue, bind: bindModalWarning } = useModal(false);
  const toast = useRef();

  Geocode.setApiKey(process.env.REACT_APP_GOOGLEAPI_KEY);
  Geocode.setLanguage("en");
  const edit_client = 'Edit Client';
  const addressSection = 'address';
  const contactSection = 'contact';
  const history = useHistory()
  let newRoleId = useInput('role_id', makeUser && 'isRequired')
  const { handleGetChoices, contextChoicesMap, loadingChoices } = useContext(AppContext)
    
  const [status_name, setStatusName] = useState(null);


  useEffect(() => {
    handleGetChoices(personal_detail_client_choices)
  }, [])

  useEffect(() => {
    if (filterSuperAdmin) {
      setPersonalDetailsBasicInfoCreate(true);
      setPersonalDetailsBasicInfoUpdate(true);
      setPersonalDetailsBasicInfoRead(true);
      setPersonalDetailsAddressCreate(true)
      setPersonalDetailsAddressUpdate(true)
      setPersonalDetailsAddressRead(true)
      setPersonalDetailsContactRead(true)
      setPersonalDetailsContactUpdate(true)
      setPersonalDetailsContactCreate(true)
      setPersonalDetailsInviteUserRead(true)
      setPersonalDetailsInviteUserCreate(true)
      setPersonalDetailsInviteUserUpdate(true)
      setPersonalDetailsClientReferralsRead(true)
      setPersonalDetailsClientReferralsCreate(true)
      setPersonalDetailsClientReferralsUpdate(true)
    } else if (filterAdmin) {
      setPersonalDetailsBasicInfoCreate(true);
      setPersonalDetailsBasicInfoUpdate(true);
      setPersonalDetailsBasicInfoRead(contextSubscription?.[CLIENT_BASIC_INFORMATION]?.[WEB_PERMISSION]);
      setPersonalDetailsAddressCreate(true)
      setPersonalDetailsAddressUpdate(true)
      setPersonalDetailsAddressRead(contextSubscription?.[CLIENT_ADDRESS]?.[WEB_PERMISSION])
      setPersonalDetailsContactRead(contextSubscription?.[CLIENT_CONTACT]?.[WEB_PERMISSION])
      setPersonalDetailsContactUpdate(true)
      setPersonalDetailsContactCreate(true)
      setPersonalDetailsInviteUserRead(contextSubscription?.[CLIENT_INVITE_USER]?.[WEB_PERMISSION])
      setPersonalDetailsInviteUserCreate(true)
      setPersonalDetailsInviteUserUpdate(true)
      setPersonalDetailsClientReferralsRead(contextSubscription?.[CLIENT_REFERRALS]?.[WEB_PERMISSION])
      setPersonalDetailsClientReferralsCreate(true)
      setPersonalDetailsClientReferralsUpdate(true)
    }
    else {
      if (rightPermission[CLIENT_BASIC_INFORMATION] !== undefined) {
        setPersonalDetailsBasicInfoCreate(
          rightPermission[CLIENT_BASIC_INFORMATION][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[CLIENT_BASIC_INFORMATION][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setPersonalDetailsBasicInfoUpdate(
          rightPermission[CLIENT_BASIC_INFORMATION][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[CLIENT_BASIC_INFORMATION][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setPersonalDetailsBasicInfoRead(contextSubscription?.[CLIENT_BASIC_INFORMATION]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_BASIC_INFORMATION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rightPermission[CLIENT_BASIC_INFORMATION][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
              && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
      }

      if (rightPermission[CLIENT_ADDRESS] !== undefined) {

        setPersonalDetailsAddressCreate(
          rightPermission[CLIENT_ADDRESS][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[CLIENT_ADDRESS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setPersonalDetailsAddressUpdate(
          rightPermission[CLIENT_ADDRESS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[CLIENT_ADDRESS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setPersonalDetailsAddressRead(contextSubscription?.[CLIENT_ADDRESS]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_ADDRESS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rightPermission[CLIENT_ADDRESS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
              && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
      }

      if (rightPermission[CLIENT_CONTACT] !== undefined) {
        setPersonalDetailsContactRead(contextSubscription?.[CLIENT_CONTACT]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_CONTACT][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rightPermission[CLIENT_CONTACT][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
              && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        );
        setPersonalDetailsContactUpdate(
          rightPermission[CLIENT_CONTACT][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[CLIENT_CONTACT][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
        setPersonalDetailsContactCreate(
          rightPermission[CLIENT_CONTACT][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[CLIENT_CONTACT][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        );
      }
      if (rightPermission[CLIENT_INVITE_USER]) {
        setPersonalDetailsInviteUserRead(contextSubscription?.[CLIENT_INVITE_USER]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_INVITE_USER][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rightPermission[CLIENT_INVITE_USER][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
              && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        )
        setPersonalDetailsInviteUserCreate(
          rightPermission[CLIENT_INVITE_USER][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[CLIENT_INVITE_USER][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        )
        setPersonalDetailsInviteUserUpdate(
          rightPermission[CLIENT_INVITE_USER][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[CLIENT_INVITE_USER][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        )
      }
      if (rightPermission[CLIENT_REFERRALS]) {
        setPersonalDetailsClientReferralsRead(contextSubscription?.[CLIENT_REFERRALS]?.[WEB_PERMISSION] &&
          (rightPermission[CLIENT_REFERRALS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
            (rightPermission[CLIENT_REFERRALS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION]
              && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId)))))
        )
        setPersonalDetailsClientReferralsCreate(
          rightPermission[CLIENT_REFERRALS][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[CLIENT_REFERRALS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        )
        setPersonalDetailsClientReferralsUpdate(
          rightPermission[CLIENT_REFERRALS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[CLIENT_REFERRALS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION]
            && ((userClient == selectedClientId) || (contextRelativesClient.find(id => id === selectedClientId))))
        )
      }
    }

  }, [filterAdmin, filterSuperAdmin, rightPermission, contextSubscription, contextRelativesClient])

  useEffect(() => {
    setRowData({
      photo: basicInfoPhoto,
      first_name: values.first_name,
      last_name: values.last_name,
      full_name: values.full_name,
      date_of_birth: values.date_of_birth
    })
  }, [basicInfoPhoto,
    values.first_name,
    values.last_name,
    values.full_name,
    values.date_of_birth])


  useEffect(() => {
    if (!values.make_user) {
      let result = _.omit(values, ['role', 'role_id']);
      setValues(result);

      setMakeUser(false);
    } else {
      setMakeUser(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.make_user]);

  useEffect(() => {
    if (selectedClientId && tabSelected == 1) {
      if (choices) {
        loadClient();
      }
    } else {
      setModalLoading(false);
    }
  }, [selectedClientId, tabSelected, choices])
  

  const loadClient = async () => {
    const response = await getClient(selectedClientId);
    if (response.role) {
      response.role_id = response.role
    }
    if (response.client_run_routes) {
      response.run_routes = response.client_run_routes.map(route => {
        if (choices && choices.run_routes && choices.run_routes.find(item => item.id === route.run_routes) &&
          choices.run_routes.find(item => item.id === route.run_routes).name) {
          return (
            {
              value: route.run_routes,
              label: choices && choices.run_routes && choices.run_routes.find(item => item.id === route.run_routes).name
            }
          )
        }

      })
    }
    if (response.client_privacy) {
      response.client_privacy = response.client_privacy.map((item) => ({ value: item.privacy, get_id: item.id, label: item.name }))
    }
    if (response.access_branch) {
      response.access_branch =
        response.access_branch &&
        response.access_branch.map(run => {
          return {
            value: run.branch_id,
            label: run.branch_name,
          };
        });
    }
    
    setValues({ ...response })
    setClientAddress({ ...response.address })
    setClientFullName(response.full_name)
    getStatusFromChoice(response.status)
    setModalLoading(false);
  }
  const handlePickFile = async (evt, section) => {
    const result = await pickFile(evt);

    switch (section) {
      case 'photo':
        setBasicInfoPhoto(result.file);
        break;

      default:
    }
  };
  const mapClickHandler = (lat, lon) => {
    Geocode.fromLatLng(lat, lon).then(
      (response) => {
        // const address = response.results[0].formatted_address;

        let tempAddress = _.cloneDeep(response.results[0].address_components)
        if (!values.address) {
          values.address = {}
        }
        values.address.latitude = lat
        values.address.longitude = lon
        response.results[0].address_components.forEach((element, index) => {
          if (element.types.find(value => value == 'postal_code')) {
            values.address.zip_code = element.long_name
            //  tempAddress.splice(index,1)
          }
          else if (element.types.find(value => value == 'country')) {
            values.address.country = element.long_name
            // tempAddress.splice(index,1)
          }
          else if (element.types.find(value => value == 'administrative_area_level_1')) {
            values.address.state = element.long_name
            // tempAddress.splice(index,1)
          }
          else if (element.types.find(value => value == 'administrative_area_level_2' || value == 'locality')) {
            values.address.city = element.long_name
            // tempAddress.splice(index,1)
          }

        });
        let lineAddress = ""
        tempAddress.forEach(ele => {
          if (ele.types.includes('postal_code') || ele.types.includes('country') || ele.types.includes('administrative_area_level_1')
            || ele.types.includes('administrative_area_level_2') || ele.types.includes('locality')
          ) {
            lineAddress = lineAddress
          } else {
            lineAddress += ele.long_name + " "
          }

        })
        values.address.line_1 = lineAddress
        setValues({ ...values })
      },
      (error) => {
        console.error(error);
      }
    );
  }

  const getStatusFromChoice = (status) => {
    if (!status) {
      setStatusName(null);
      return;
    }
    let statusObj = status && choices?.client_status && choices?.client_status?.find(c => c.id == status)
    if (statusObj) {
      setStatusName(statusObj?.name)
    }
  }

  const dropDownHandleSelect = (item, name, nameSection) => {
    //recursive objects merge
    let result;
    if (item.role) {
      item.role_id = item.role;
    }
    if (name == 'privacy') {
      values.client_privacy = item.client_privacy;
      setValues({ ...values });
      return;
    }

    if (nameSection == "status") {
      getStatusFromChoice(item?.status)
    }

    // if(item.final_client_date && name){
    //     setModalWarningValue(true)
    //     setStatusObj(item)
    //     setWarningText(`${item.final_client_date}`)
    //     return;
    // }


    result = _.merge(values, item);

    setValues({ ...result });
  };
  const dropDownHandleRunRoute = item => {
    if (item.access_branch) {
      values.access_branch = item.access_branch
      setValues({ ...values })
    } else {
      values.run_routes = item.run_route
      setValues({ ...values })
    }
  }

  const shouldUpdateClientStatus = async () => {
    if (errors.length > 0) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }
    // let status_name = values.status && contextChoicesMap?.client_status && contextChoicesMap?.client_status[values.status]?.name
    if ((status_name && (status_name != 'Active') && (status_name != 'Active - Probation')) || values.final_client_date) {
      const body = {
        client: values.id,
        final_client_date: values.final_client_date ? values.final_client_date : moment().format("YYYY-MM-DD")
      }
      try {
        const res = await shouldUpdateStatus(body)
        if (res.status == true) {
          checkClientStatus()      
        } else {
          let message = `You have active service contract, Please check contract end date, it must be lesser than ${values.final_client_date ? values.final_client_date : moment().format("YYYY-MM-DD")}.`
          toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `${message}`,
          });
          loadClient()
        }
      } catch (error) {

      }
    } else {
      handleSubmit()
    }
  }


  const checkClientStatus = () => {
    // let status_name = contextChoicesMap?.client_status[values.status]?.name
    if (status_name && (status_name != 'Active') && (status_name != 'Active - Probation')) {
      let date = values.final_client_date ? moment(values.final_client_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      setModalWarningValue(true)
      setWarningText(`${status_name}`)
      setDateStatus(date)
      return;
    } else {
      if (values.final_client_date) {
        // toast.current.show({
        //   severity: 'warning',
        //   summary: 'Failed',
        //   detail: `You haven't remove the client final date to see the changes on schedular, kindly remove the date`,
        // });
        setModalWarningValue(true)
        setWarningText(`${status_name}`)
        return;
      }
    }
    setModalWarningValue(false);
    handleSubmit()
  }


  const handelMakeUser = () => {
    setValues({ ...values, role: null, role_id: null, make_user: !values.make_user });
    // setMakeUser(prevState => !prevState);
  };

  const handleSubmit = (
    evt
  ) => {

    // if ((!isValid || errors.length > 0)) {
    if (errors.length > 0) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    let body = {
      ...values,
    };

    setModalWarningValue(false);
    if (basicInfoPhoto) {
      body.photo = basicInfoPhoto;
    } else if (body.photo) {
      delete body.photo;
    }
    if (body.run_routes && body.run_routes.length > 0) {
      body.run_routes_id = body.run_routes.map(item => item && item.value)
      delete body.run_routes
    }
    delete body.client_services;
    delete body.care_notes;
    delete body.client_health_details;
    delete body.demographic;
    delete body.description;
    delete body.detail;
    delete body.files;
    delete body.medication_notes;
    delete body.nok;

    if (body.role_id) {
      body.role = body.role_id
    }

    if (body.access_branch) {
      body.access_branch = body.access_branch.map(item => { return item.value }).join(',');
    }

    if (body.client_privacy) {
      body.client_privacy = body.client_privacy.map((val) => {
        let obj = {
          privacy: val.value
        };
        if (val.get_id) {
          obj.id = val.get_id
        }
        return obj
      })
    }

    setLoadingSave(true);

    let value = [];
    setClient(body)
      .then(response => {
        if (response.status === "FAIL") {
          if (response.message) {
            value = response.message.message
          } else {
            value = Object.keys(response)[0] + "-" + Object.values(response)[0]
          }
          throw value;
        }
        setShowError(false);
        onSuccess(response);
      })
      .catch(onError);
  };
  const onSuccess = async (response) => {
    let clientId = response?.message?.id;

   

    if (clientId) {
      if (props.location.pathname === `/client/add/personalDetails`) {
        props.history.push(`/client/edit/${clientId}/personalDetails`)

      }
      setValues({
        ...values,
        id: clientId,
      });
      setCliId(clientId)
    }

    setClientAddress({ ...response.message.address })
    setClientFullName(response.message.full_name)
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    handleChangeChoices('client_listing')

  };

  const onError = err => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(
      err
    );
  };

  const handelPoorConnectivity = () => {
    values.poor_connectivity = !values.poor_connectivity
    setValues({...values})
  }

  const referral_source_hook = useInput('referral.referral_source');
  const date_referred_hook = useInput('referral.date_referred')
  const referred_by_hook = useInput('referral.referred_by')
  const planned_start_date_hook = useInput('referral.planned_start_date')
  const referral_ref_code_hook = useInput('referral.referral_ref_code')
  const care_band_hook = useInput('referral.care_band')
  const make_user_hook = useInput('make_user')

console.log(values,"values")

  return (
    <>
      <ComponentDynamic
        loading={modalLoading || loadingChoices}
      >
        <Toast ref={toast} position="top-right" />
        {(selectedClientId ? readPersonalDetails : createPersonalDetails) ?
          <div>
            {(selectedClientId ? personalDetailsBasicInfoRead : personalDetailsBasicInfoCreate) &&
              <BasicInformation
                useInput={useInput}
                basicInfoPhoto={basicInfoPhoto}
                choices={choices}
                values={values}
                handlePickFile={handlePickFile}
                dropDownHandleSelect={dropDownHandleSelect}
                titleModal={edit_client}
                companyBranches={companyBranches}
                linked_to={linkedTo}
                dropDownHandleRunRoute={dropDownHandleRunRoute}
                setDisplayPhoto={setDisplayPhoto}
                readOnly={selectedClientId ? !personalDetailsBasicInfoUpdate : !personalDetailsBasicInfoCreate}
                contextChoicesMap={contextChoicesMap}
                handelPoorConnectivity={handelPoorConnectivity}
              />
            }
            <ContainerResponsive className="d-flex justify-content-between mt-3">
              {(selectedClientId ? personalDetailsAddressRead : personalDetailsAddressCreate) &&
                <Address
                  useInput={useInput}
                  values={values}
                  setValues={setValues}
                  country={choices && choices.country}
                  nameSection={addressSection}
                  sectionStyle={'me-md-1 me-sm-0'}
                  readOnly={selectedClientId ? !personalDetailsAddressUpdate : !personalDetailsAddressCreate}
                />
              }
              <FlexEqual className="mt-2">
                {(selectedClientId ? personalDetailsAddressRead : personalDetailsAddressCreate) &&
                  <MapComponent
                    containerElement={<div style={{ height: `300px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    latitude={values && values.address && values.address.latitude}
                    longitude={values && values.address && values.address.longitude}
                    mapClickHandler={mapClickHandler}
                  />
                }
              </FlexEqual>
            </ContainerResponsive>
            {(selectedClientId ? personalDetailsContactRead : personalDetailsContactCreate) &&
              <Contact
                useInput={useInput}
                nameSection={contactSection}
                sectionStyle={''}
                dropDownHandleSelect={dropDownHandleSelect}
                choices={choices}
                values={values}
                inviteUserStatus={values.make_user}
                readOnly={selectedClientId ? !personalDetailsContactUpdate : !personalDetailsContactCreate}
              />
            }
            {(selectedClientId ? personalDetailsClientReferralsRead : personalDetailsClientReferralsCreate) &&
              <>
                <TitleSection className={'mt-3'}>Client Referrals</TitleSection>
                <div className="d-flex flex-column mt-2">
                  <div className="d-flex mb-1">
                    <div className={'me-2 w-100'}>
                      <DateSelector
                        labelTop="Date Referred"
                        {...date_referred_hook}
                      />
                    </div>
                    <div className={'me-2 w-100'}>
                      <InputLabelTop
                        type="text"
                        id="ref_source"
                        label={'Referral Source'}
                        {...referral_source_hook}
                        maxlength={100}
                      />
                    </div>
                    <div className={'w-100'}>
                      <InputLabelTop
                        type="text"
                        id="ref_source"
                        label={'Referred By'}
                        {...referred_by_hook}
                        maxlength={100}
                      />
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className={'me-2 w-100'}>
                      <DateSelector
                        labelTop="Planned Start Date"
                        {...planned_start_date_hook}
                      />
                    </div>
                    <div className={'me-2 w-100'}>
                      <InputLabelTop
                        type="text"
                        id="ref_code"
                        label={'Referral Ref Code'}
                        {...referral_ref_code_hook}
                        maxlength={100}
                      />
                    </div>
                    <div className={'me-2 w-100'}>
                      <InputLabelTop
                        type="text"
                        id="ref_band"
                        label={'Care Band'}
                        {...care_band_hook}
                        maxlength={100}
                      />
                    </div>
                    <div className={'w-100'}>
                      <DropdownSearchable
                        placeHolder={'Referral Status'}
                        options={choices.referral_status}
                        selectedOption={values.referral && values.referral.referral_status ? values.referral.referral_status : null}
                        onChange={value =>
                          dropDownHandleSelect({
                            referral: { referral_status: value ? value : null }
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            }
            {/* <ContainerResponsive className="d-flex justify-content-between mt-3"> */}
            <FlexGrid3Container>
              <FlexGrid3Item className="pe-1">
              {(selectedClientId ? personalDetailsInviteUserRead : personalDetailsInviteUserCreate) &&
                <ToggleButton
                  label="Invite as User"
                  useInput={make_user_hook}
                  id="make_user"
                  onClick={() => handelMakeUser()}
                />
              }
              </FlexGrid3Item>
              </FlexGrid3Container>
              
            {/* </ContainerResponsive> */}

            {makeUser && (
              <ContainerResponsive>
                <FlexEqual className="me-md-2 me-sm-0">
                  <TitleSection>Roles And Permission</TitleSection>
                  <div className="d-flex flex-column mt-2">
                    <FlexGrid3Container className="w-100 mb-2">
                      <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                        <DropdownSearchable
                          required
                          disabled={selectedClientId ? !personalDetailsInviteUserRead : !personalDetailsInviteUserCreate}
                          placeHolder={'Role'}
                          options={choices && choices.roles && choices?.roles?.filter(role => role.user_type === "client")}
                          selectedOption={values.role_id}
                          {...newRoleId}

                          onChange={value =>
                            dropDownHandleSelect({
                              role_id: value ? value : null,
                            })
                          }
                        />
                      </FlexGrid3Item>
                      <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                        <DropdownSearchable
                          isMulti={true}
                          disabled={selectedClientId ? !personalDetailsInviteUserRead : !personalDetailsInviteUserCreate}
                          placeHolder={'Privacy Type'}
                          options={choices ? choices.alert_privacy : []}
                          selectedOptionsProps={values.client_privacy}
                          selectedOption={values.client_privacy}
                          onChange={value =>
                            dropDownHandleSelect({ client_privacy: value ? value : null }, 'privacy')
                          }
                        />
                      </FlexGrid3Item>
                      {choices.roles && choices.roles.find(role => role.id === values.role_id) && choices.roles.find(role => role.id === values.role_id).is_staff &&
                        <span
                          className={'ms-2 mt-1'}
                          style={{ color: "green", fontStyle: 'italic' }}
                        >OFFICE</span>
                      }
                    </FlexGrid3Container>
                  </div>

                </FlexEqual>
              </ContainerResponsive>
            )}

            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {showError  ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>

              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>


                <div className={'ms-0 ms-md-3 me-2'}>
                  <PrimaryButtonForm
                    bgcolor="PURPLE_GRAY_500"
                    minWidth="6rem"
                    className={'me-2'}
                    onClick={() => {
                      // setModalValue()
                      history.push('/client')
                    }}>
                    <span className={'me-2'}>{'Cancel'}</span>
                  </PrimaryButtonForm>
                  {(selectedClientId ? updatePersonalDetails : true) && (
                    <PrimaryButtonForm
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      onClick={shouldUpdateClientStatus}>
                      <span className={'me-2'}>{textStatus}</span>
                      {!loadingSave ? (
                        buttonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  )}
                </div>
              </div>
            </div>
          </div>
          :
          <NoPermissionContainer>
            You don't have permission to {selectedClientId ? 'read' : 'create'} the information.
          </NoPermissionContainer>
        }
        <ModalDecision
          type="warning"
          title="Warning"
          body={
            (warningText == 'Active') ? 'Visits wont be created after client final date, if you want to view the changes on scheduler please remove client final date. ' : `Status for client is set ${warningText}, Visit will be deleted from ${dateStatus}.`
          }
          subBody={'Do you still want to continue?'}
          onOk={() => handleSubmit()}
          onCancel={() => {
            setModalWarningValue(false);
          }}
          okTitle={'Confirm'}
          {...bindModalWarning}
        />
      </ComponentDynamic>
    </>
  );
}

export default PersonalDetails;
