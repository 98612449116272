import React, { useState, useEffect, Fragment } from 'react';
import {
  ModalContainer
} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { getEmployeeDetails } from 'utils/api/ClientApi';
import './preview.css'
import moment from 'moment'
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { jsPDF } from "jspdf";
import * as html2canvas from 'html2canvas';

const PreviewModal = ({
  useInput,
  values,
  modalLoading,
  setModalLoading,
  fullBind,
  clientId,
  setEmailPreviewModal
}) => {

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [templateData, setTemplateData] = useState({});
  const [maxSize, setMaxSize] = useState(0);
  const [loadToPrint , setLoadToPrint] = useState(false)

  const checkAddress = (data) => {
    const temp_arr = []

    if (data.line_1)
      temp_arr.push(data.line_1)
    if (data.city)
      temp_arr.push(data.city)
    if (data.country)
      temp_arr.push(data.country)
    if (data.zip_code)
      temp_arr.push(data.zip_code)

    return temp_arr.join(',')
  }



  const tableData = (i) => {
    const arrayOne = templateData.day_1 || [];
    const arrayTwo = templateData.day_2 || [];
    const arrayThree = templateData.day_3 || [];
    const arrayFour = templateData.day_4 || [];
    const arrayFive = templateData.day_5 || [];
    const arraySix = templateData.day_6 || [];
    const arraySeven = templateData.day_7 || [];


    let arr = []
    arrayOne[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >
          <div style={{
            paddingBottom: 1,
            //  backgroundColor: "rgb(246, 246, 246)",
            width: "100%",
            height: 140,
          }}>
            {arrayOne[i]?.client_photo ? <img
              className="img-circle"
              alt="image"
              src={`${arrayOne[i]?.client_photo}`}
              style={{
                paddingBottom: 1,
                // //  backgroundColor: "rgb(246, 246, 246)",
                width: "100%",
                height: 140,
              }}
            /> : ""}</div>

         {arrayOne[i].client_full_name && <div
            style={{
              overflowWrap: "display:block",
              padding: "2px 0",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 15
            }}
          >
            {arrayOne[i].client_full_name}
          </div>}
        {arrayOne[i].visit_time &&  <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arrayOne[i].visit_time}
          </div>}
          {arrayOne[i].visit_type && <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arrayOne[i].visit_type}
          </div>}
        {checkAddress(arrayOne[i]) &&  <div
            style={{
              paddingBottom: 1,
              overflowWrap: "display:block",
              padding: "2px 0",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 14,
              color: 'dimgray',
              fontStyle: 'italic'
            }}
          >
            {checkAddress(arrayOne[i])}
          </div>}
        </td>
      )
      : arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            // //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        ></td>);

    arrayTwo[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            // //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >
          <div style={{
            paddingBottom: 1,
            // //  backgroundColor: "rgb(246, 246, 246)",
            width: "100%",
            height: 140,
          }}>
            {arrayTwo[i]?.client_photo ? <img
              className="img-circle"
              alt="image"
              src={`${arrayTwo[i]?.client_photo}`}
              style={{
                paddingBottom: 1,
                // //  backgroundColor: "rgb(246, 246, 246)",
                width: "100%",
                height: 140,
              }}
            /> : null}</div>
            
         {arrayTwo[i].client_full_name? <div
            style={{
              overflowWrap: "display:block",
              padding: "2px 0",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 15
            }}
          >
            {arrayTwo[i].client_full_name}
          </div>:null}

        {arrayTwo[i].visit_time?  <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arrayTwo[i].visit_time}
          </div>:null}

          {arrayTwo[i].visit_type?<div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arrayTwo[i].visit_type}
          </div>:null}

         {arrayTwo[i].visit_type? <div
            style={{
              paddingBottom: 1,
              overflowWrap: "display:block",
              padding: "2px 0",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 14,
              color: 'dimgray',
              fontStyle: 'italic'
            }}
          >
            {checkAddress(arrayTwo[i])}
          </div>:null}

        </td>
      )
      : arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            // //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        ></td>);

    arrayThree[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            // //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >

          <div style={{
            paddingBottom: 1,
            // //  backgroundColor: "rgb(246, 246, 246)",
            width: "100%",
            height: 140,
          }}>
            {arrayThree[i]?.client_photo ? <img
              className="img-circle"
              alt="image"
              src={`${arrayThree[i]?.client_photo}`}
              style={{
                paddingBottom: 1,
                // //  backgroundColor: "rgb(246, 246, 246)",
                width: "100%",
                height: 140,
              }}
            /> : ""}</div>

          <div
            style={{
              overflowWrap: "display:block",
              padding: "2px 0",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 15
            }}
          >
            {arrayThree[i].client_full_name}
          </div>

          <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arrayThree[i].visit_time}
          </div>

          <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arrayThree[i].visit_type}
          </div>

          <div
            style={{
              paddingBottom: 1,
              overflowWrap: "display:block",
              padding: "2px 0",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 14,
              color: 'dimgray',
              fontStyle: 'italic'
            }}
          >
            {checkAddress(arrayThree[i])}
          </div>

        </td>
      )
      : arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            // //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        ></td>);

    arrayFour[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            // //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >

          <div style={{
            paddingBottom: 1,
            // //  backgroundColor: "rgb(246, 246, 246)",
            width: "100%",
            height: 140,
          }}>
            {arrayFour[i]?.client_photo ? <img
              className="img-circle"
              alt="image"
              src={`${arrayFour[i]?.client_photo}`}
              style={{
                paddingBottom: 1,
                // //  backgroundColor: "rgb(246, 246, 246)",
                width: "100%",
                height: 140,
              }}
            /> : ""}</div>

          <div
            style={{
              overflowWrap: "display:block",
              padding: "2px 0",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 15
            }}
          >
            {arrayFour[i].client_full_name}
          </div>

          <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arrayFour[i].visit_time}
          </div>

          <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arrayFour[i].visit_type}
          </div>

          <div
            style={{
              paddingBottom: 1,
              overflowWrap: "display:block",
              padding: "2px 0",
              // //  backgroundColor: "#f6f6f6",
              fontSize: 14,
              color: 'dimgray',
              fontStyle: 'italic'
            }}
          >
            {checkAddress(arrayFour[i])}
          </div>

        </td>
      )
      : arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            // //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        ></td>);

    arrayFive[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            // //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >
          <div style={{
            paddingBottom: 1,
            // //  backgroundColor: "rgb(246, 246, 246)",
            width: "100%",
            height: 140,
          }}>
            {arrayFive[i]?.client_photo ? <img
              className="img-circle"
              alt="image"
              src={`${arrayFive[i]?.client_photo}`}
              style={{
                paddingBottom: 1,
                //  backgroundColor: "rgb(246, 246, 246)",
                width: "100%",
                height: 140,
              }}
            /> : ""}</div>
          <div
            style={{
              overflowWrap: "display:block",
              padding: "2px 0",
              //  backgroundColor: "#f6f6f6",
              fontSize: 15
            }}
          >
            {arrayFive[i].client_full_name}
          </div>

          <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arrayFive[i].visit_time}
          </div>

          <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arrayFive[i].visit_type}
          </div>

          <div
            style={{
              paddingBottom: 1,
              overflowWrap: "display:block",
              padding: "2px 0",
              //  backgroundColor: "#f6f6f6",
              fontSize: 14,
              color: 'dimgray',
              fontStyle: 'italic'
            }}
          >
            {checkAddress(arrayFive[i])}
          </div>

        </td>
      )
      : arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        ></td>);

    arraySix[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >

          <div style={{
            paddingBottom: 1,
            //  backgroundColor: "rgb(246, 246, 246)",
            width: "100%",
            height: 140,
          }}>
            {arraySix[i]?.client_photo ? <img
              className="img-circle"
              alt="image"
              src={`${arraySix[i]?.client_photo}`}
              style={{
                paddingBottom: 1,
                //  backgroundColor: "rgb(246, 246, 246)",
                width: "100%",
                height: 140,
              }}
            /> : ""}</div>

          <div
            style={{
              overflowWrap: "display:block",
              padding: "2px 0",
              //  backgroundColor: "#f6f6f6",
              fontSize: 15
            }}
          >
            {arraySix[i].client_full_name}
          </div>

          <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arraySix[i].visit_time}
          </div>

          <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arraySix[i].visit_type}
          </div>

          <div
            style={{
              paddingBottom: 1,
              overflowWrap: "display:block",
              padding: "2px 0",
              //  backgroundColor: "#f6f6f6",
              fontSize: 14,
              color: 'dimgray',
              fontStyle: 'italic'
            }}
          >
            {checkAddress(arraySix[i])}
          </div>

        </td>
      )
      : arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        ></td>);

    arraySeven[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >
          <div style={{
            paddingBottom: 1,
            //  backgroundColor: "rgb(246, 246, 246)",
            width: "100%",
            height: 140,
          }}>
            {arraySeven[i]?.client_photo ? <img
              className="img-circle"
              alt="image"
              src={`${arraySeven[i]?.client_photo}`}
              style={{
                paddingBottom: 1,
                //  backgroundColor: "rgb(246, 246, 246)",
                width: "100%",
                height: 140,
              }}
            /> : ""}</div>
          <div
            style={{
              overflowWrap: "display:block",
              padding: "2px 0",
              //  backgroundColor: "#f6f6f6",
              fontSize: 15
            }}
          >
            {arraySeven[i].client_full_name}
          </div>

          <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arraySeven[i].visit_time}
          </div>

          <div
            style={{
              padding: "2px 0",
              overflowWrap: "display:block",
              //  backgroundColor: "#f6f6f6",
              fontSize: 14
            }}
          >
            {arraySeven[i].visit_type}
          </div>

          <div
            style={{
              paddingBottom: 1,
              overflowWrap: "display:block",
              padding: "2px 0",
              //  backgroundColor: "#f6f6f6",
              fontSize: 14,
              color: 'dimgray',
              fontStyle: 'italic'
            }}
          >
            {checkAddress(arraySeven[i])}
          </div>

        </td>
      )
      : arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            //  backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            width: 142,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        ></td>);


    return arr;
  }

  useEffect(() => {
    if (clientId) {
      const start = values.date_from;
      const end = moment(values.date_to, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
      getEmployeeDetails(start, end, clientId)
        .then((res) => {
          setTemplateData(res.data);
          if (res.data) {
            let day_1 = res.data.day_1 ? res.data.day_1.length : 0;
            let day_2 = res.data.day_2 ? res.data.day_2.length : 0;
            let day_3 = res.data.day_3 ? res.data.day_3.length : 0;
            let day_4 = res.data.day_4 ? res.data.day_4.length : 0;
            let day_5 = res.data.day_5 ? res.data.day_5.length : 0;
            let day_6 = res.data.day_6 ? res.data.day_6.length : 0;
            let day_7 = res.data.day_7 ? res.data.day_7.length : 0;
            let maxSizeArr = Math.max(day_1, day_2, day_3, day_4, day_5, day_6, day_7);
            setMaxSize(maxSizeArr)
            setModalLoading(false)
          }


        })
        .catch((err) => { })
    }
  }, [clientId])

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Email Preview',
    });

  }, [fullBind]);


  const printElement = (element) => {
    const myframe = document.createElement('iframe');
    myframe.style.position = 'absolute';
    myframe.style.top = '-10000px';
    document.body.appendChild(myframe);
    myframe.contentDocument.write(element.innerHTML);
    setTimeout(() => {
      myframe.focus();
      myframe.contentWindow.print();
      myframe.parentNode.removeChild(myframe); 
      setLoadToPrint(false)
    }, 1000);
    window.focus();
  };


  const handleDownlaodPdf = () => {
    setLoadToPrint(true)
     const element = document.getElementById("mypdf")
    printElement(element)

    // const input = document.querySelector('#mypdf');

    // html2canvas(input)
    //   .then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF({
    //       orientation: 'landscape',
    //     });
    //     let position = 0;
    //     let pageHeight = 210;
    //     let imgProps = pdf.getImageProperties(imgData);
    //     let pdfWidth = pdf.internal.pageSize.getWidth();
    //     let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    //     var heightLeft = pdfHeight;
    //     pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
    //     heightLeft -= pageHeight;

    //     while (heightLeft >= 0) {
    //       position = heightLeft - pdfHeight;
    //       pdf.addPage();
    //       pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
    //       heightLeft -= pageHeight;
    //     }
    //     const current_date = moment().format('DD-MM-YYY')
    //     pdf.save(`${templateData.employee_full_name} ${templateData.schema_name} ${templateData.start_date} ${templateData.end_date}`);
    //   });

    // const doc = new jsPDF({
    //   orientation: "landscape",
    //   format: [1000, 1000]
    // });
    // let width = doc.internal.pageSize.getWidth();
    // let height = doc.internal.pageSize.getHeight();
    // const html = document.querySelector('#mypdf');
    // const current_date = moment().format('DD-MM-YYY')
    // doc.html(html, {
    //   async callback(doc) {
    //     await doc.save(`report_${current_date}`);
    //   },
    //   autoPaging: 'text',
    //   width,
    //   height,
    //   html2canvas: {
    //     allowTaint: true,
    //     dpi: 300,
    //     letterRendering: true,
    //     logging: false,
    //     scale: .75
    //   },
    // });
    // setTimeout(() => {
    //   setEmailPreviewModal(false)
    // }, 100);
  };



  return (
    <ModalContainer max_width={'80%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'80%'}
        minWidthTitle="8.5rem"
        loading={modalLoading}>
        <div className='preview_style' >
          <div className='m-2 text-end'>
            <PrimaryButtonForm minWidth="6rem" disabled={loadToPrint} onClick={handleDownlaodPdf}>
              <span className={'me-1'}>Export to pdf</span>
            </PrimaryButtonForm>
          </div>
          <div
            id='mypdf'
            className='printable-content'
            style={{
              //  backgroundColor: "#f6f6f6",
              fontFamily: "sans-serif",
              WebkitFontSmoothing: "antialiased",
              fontSize: 14,
              lineHeight: "1.4",
              margin: 0,
              padding: 0,
              msTextSizeAdjust: "100%",
              WebkitTextSizeAdjust: "100%",
              overflow: "auto", 
              
            }}
          >
            <span
              className="preheader"
              style={{
                color: "transparent",
                display: "none",
                height: 0,
                maxHeight: 0,
                maxWidth: 0,
                opacity: 0,
                overflow: "hidden",
                msoHide: "all",
                visibility: "hidden",
                width: 0
              }}
            >

            </span>
            <table
              role="presentation"
              border={0}
              cellPadding={0}
              cellSpacing={0}
              className="body"
              style={{
                borderCollapse: "separate",
                msoTableLspace: "0pt",
                msoTableRspace: "0pt",
                //  backgroundColor: "#f6f6f6",
                width: "100%"
              }}
              width="100%"
              bgcolor="#f6f6f6"
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      verticalAlign: "top"
                    }}
                    valign="top"
                  >
                    &nbsp;
                  </td>
                  <td
                    className="container"
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      verticalAlign: "top",
                      display: "block",
                      // maxWidth: 1000,
                      padding: 10,
                      //width: 1000,
                      margin: "0 auto"
                    }}
                    //width={1000}
                    valign="top"
                  >
                    <div
                      className="content"
                      style={{
                        boxSizing: "border-box",
                        display: "block",
                        margin: "0 auto",
                        //maxWidth: 1000,
                        padding: 10
                      }}
                    >
                      {/* widt:580START CENTERED WHITE CONTAINER */}
                      <table
                        role="presentation"
                        className="main"
                        style={{
                          borderCollapse: "separate",
                          msoTableLspace: "0pt",
                          msoTableRspace: "0pt",
                          background: "#ffffff",
                          borderRadius: 3,
                          width: "100%"
                        }}
                        width="100%"
                      >
                        {/* START MAIN CONTENT AREA */}
                        <tbody>
                          <tr>
                            <td
                              className="wrapper"
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: 14,
                                verticalAlign: "top",
                                boxSizing: "border-box",
                                padding: 20
                              }}
                              valign="top"
                            >
                              <table
                                role="presentation"
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                style={{
                                  borderCollapse: "separate",
                                  msoTableLspace: "0pt",
                                  msoTableRspace: "0pt",
                                  width: "100%"
                                }}
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontSize: 14,
                                        verticalAlign: "top"
                                      }}
                                      valign="top"
                                    >
                                      <p
                                        style={{
                                          fontFamily: "sans-serif",
                                          fontSize: 14,
                                          fontWeight: "normal",
                                          margin: 0,
                                          marginBottom: 10,
                                          textAlign: "right",
                                          paddingBottom: 5
                                        }}
                                      >
                                        <small>{templateData && templateData.schema_name}</small>
                                      </p>
                                      <table
                                        role="presentation"
                                        border={0}
                                        cellPadding={0}
                                        cellSpacing={0}
                                        style={{
                                          borderCollapse: "separate",
                                          msoTableLspace: "0pt",
                                          msoTableRspace: "0pt",
                                          boxSizing: "border-box",
                                          width: "100%"
                                        }}
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 14,
                                                verticalAlign: "top",
                                                paddingBottom: 15
                                              }}
                                              valign="top"
                                            >
                                              <p
                                                style={{
                                                  fontFamily: "sans-serif",
                                                  fontSize: 14,
                                                  fontWeight: "normal",
                                                  margin: 0,
                                                  marginTop: 15,
                                                  padding: "12px 0",
                                                  marginBottom: 10,
                                                  textAlign: "center",
                                                  //  backgroundColor: "#f6f6f6"
                                                }}
                                              >
                                                {templateData && templateData.employee_full_name} {templateData && templateData.start_date && '- from'} {templateData && templateData.start_date} {templateData && templateData.start_date && 'to '}{templateData && templateData.end_date}
                                              </p>
                                              <table
                                                role="presentation"
                                                border={0}
                                                cellPadding={0}
                                                cellSpacing={0}
                                                style={{
                                                  borderCollapse: "separate",
                                                  msoTableLspace: "0pt",
                                                  msoTableRspace: "0pt",
                                                  width: "100%"
                                                }}
                                              >
                                                <tbody>
                                                  {/*- for  th date get  dynamice  day+date fro  each 7dayswith date  dynamically and  display     -*/}
                                                  <tr>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize",
                                                        width: '14.28%'
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {templateData && templateData.weekday_1 && templateData.weekday_1}
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize",
                                                        width: '14.28%'
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {templateData && templateData.weekday_2 && templateData.weekday_2}
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize",
                                                        width: '14.28%'
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {templateData && templateData.weekday_3 && templateData.weekday_3}
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize",
                                                        width: '14.28%'
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {templateData && templateData.weekday_4 && templateData.weekday_4}
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize",
                                                        width: '14.28%'
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {templateData && templateData.weekday_5 && templateData.weekday_5}
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize",
                                                        width: '14.28%'
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {templateData && templateData.weekday_6 && templateData.weekday_6}
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize",
                                                        width: '14.28%'
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {templateData && templateData.weekday_7 && templateData.weekday_7}
                                                    </th>
                                                  </tr>
                                                  <tr></tr>
                                                </tbody>
                                                <tbody
                                                  id="table-content"
                                                  style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 14,
                                                    verticalAlign: "top",
                                                    borderRadius: 5,
                                                    textAlign: "center",
                                                    width: "2%"
                                                  }}
                                                  valign="top"
                                                  align="center"
                                                >
                                                  {[...Array(maxSize)].map((item, i) => {
                                                    return (
                                                      <tr>
                                                        {tableData(i)}
                                                      </tr>
                                                    )
                                                  })}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          {/* END MAIN CONTENT AREA */}
                        </tbody>
                      </table>
                      {/* END CENTERED WHITE CONTAINER */}
                    </div>
                  </td>
                  <td
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      verticalAlign: "top"
                    }}
                    valign="top"
                  >
                    &nbsp;
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </ModalDynamic>
    </ModalContainer>

  );
}

export default PreviewModal;
