import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { CloseButtonWrapper, IconWrapper, ModalContainer, SearchAddContainer, SearchContainer, SearchIcon } from 'shared/styles/constants/tagsStyles';

import { AppContext } from 'context';
import { useContext } from 'react';
import { Fade } from 'react-reveal';
import { GrSearch } from 'react-icons/gr';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import './SearchModal.css'
import { COPY } from 'utils/constants/icons';
import { SOFT_RED } from 'shared/styles/constants/colors';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { Col, Row } from 'components/SharedComponents/Table/styles';

const SearchModalRichText = ({
  fullBind,
  jsonData,
  setJsonData,
  popUpData,
  setSearchValueRich,
  name
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const { contextChoices } = useContext(AppContext);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [listDataSearched, setListDataSearched] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [selectedHtml, setSelectedHtml] = useState('');
  const [expandFields, setExpandFields] = useState({});


  useEffect(() => {
    if (contextChoices.assessment && (contextChoices.assessment.length > 0)) {
      let tempArr = []
      contextChoices.assessment.forEach((val_a) => {
        val_a.category.forEach((val_c) => {

          let type_d = val_c.type.map((val_t) => {
            return (
              {
                type_name: val_t.name.toLowerCase(),
                name: val_t.name,
                message: val_t.message
              }
            )
          })
          tempArr.push({
            assessment_name: val_a.name.toLowerCase(),
            category_name: val_c.name.toLowerCase(),
            type_data: type_d

          })

        })
      })
      const initialExpansionState = {};
      tempArr && tempArr.forEach((client, cIndex) => {
        initialExpansionState[cIndex] = { open: false };
      });
      setExpandFields({ ...initialExpansionState });
      setListDataSearched(tempArr || [])
    }
  }, [contextChoices])

  const keyPressed = e => {
    let typeText = e.target.value.toLowerCase()
    setSearchKeyword(e.target.value)
    if (typeText) {
      const filteredData = listDataSearched.filter(obj => obj.assessment_name.includes(typeText || '') || obj.category_name.includes(typeText || '') || obj.type_data.filter((td) => td.type_name?.includes(typeText || ''))?.length);
      setFiltered(filteredData || [])
    } else {
      setFiltered([])
    }
  };

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Search',
    });
  }, [fullBind]);

  const addHtmlText = (text) => {
    const replacedStr = text.replaceAll('@', `${name}`);
    setSelectedHtml(replacedStr || '')
  }

  const clickCopy = () => {
    let indexGiven = jsonData.findIndex((find_d) => find_d.id == popUpData.id)
    let data = jsonData.map((val, newIndex) => {
      if (newIndex === indexGiven) {
        return ({
          ...val,
          value: val.value + selectedHtml
        })
      }
      return val
    })
    setJsonData(data)
    setSearchValueRich(false)
  }

  const handleExpansion = (index) => {
    if (expandFields[index].open) {
      expandFields[index].open = false;
      setExpandFields({ ...expandFields });
    } else {
      expandFields[index].open = true;
      setExpandFields({ ...expandFields });
    }
  };


  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={false}>
        <div className='ps-4 pb-4 pe-4'>
          <SearchAddContainer className="d-flex align-items-center">
            <SearchIcon
              onClick={() => {
                //   setShowSearch(!showSearch);
              }}>
              <GrSearch />
            </SearchIcon>

            <SearchContainer show={true} style={{ width: '100%' }}>
              <Fade collapse when={true}>
                <InputLabelTop
                  no_internal_margin="true"
                  type="text"
                  id="search"
                  label={'Search'}
                  // {...useInput('search')}
                  // onBlur={() => {
                  //   search();
                  // }}
                  onKeyPress={e => {
                    keyPressed(e);
                  }}
                  onChange={(e) => {
                    keyPressed(e)
                  }
                  }
                  value={searchKeyword}
                />
              </Fade>
            </SearchContainer>
          </SearchAddContainer>
          <div className='d-flex mt-3' style={{ height: '18rem' }}>
            <div className="copy-list">
              {(filtered && (filtered.length > 0))
                ?
                filtered.map((fil, index) => {
                  return (
                    <>
                      <div className='copy-item' >
                        <CloseButtonWrapper style={{ paddingBottom: 'initial' }}
                          //   className="closeButtonWrapper"
                          onClick={() => {
                            handleExpansion(index);
                          }}>
                          {expandFields && expandFields[index] && expandFields[index]?.open ? (
                            <IoIosArrowDown
                              style={{ color: '#6200f9', fontWeight: 'bold' }}
                            />
                          ) : (
                            <IoIosArrowForward
                              style={{ color: '#6200f9', fontWeight: 'bold' }}
                            />
                          )}
                        </CloseButtonWrapper>{fil.category_name}
                      </div>
                      {expandFields && expandFields[index] && expandFields[index]?.open && (
                        <div className='mb-2'>
                          {fil.type_data.map((td) => {
                            return (
                              <Row onClick={() => addHtmlText(td.message)}>
                                <Col style={{ justifyContent: 'flex-start', marginLeft: '1rem' }}>{td.type_name}</Col>
                              </Row>

                            )
                          })

                          }
                        </div>
                      )}
                    </>
                  )
                })
                :
                <div>Please search to see the list</div>
              }
            </div>
            <div className='text-copy mx-2'>
              <div className='d-flex mt-1'>
                <span style={{ marginLeft: 'auto' }}>Copy</span>
                <IconWrapper
                  style={{ marginLeft: '5px', marginRight: '8px' }}
                  color={SOFT_RED}
                  onClick={() => {
                    clickCopy()
                  }
                  }
                >
                  {COPY}
                </IconWrapper>
              </div>
              {selectedHtml ?
                <div className='html-text'>
                  <div dangerouslySetInnerHTML={{ __html: selectedHtml || '<div></div>' }} />
                </div>
                :
                <div style={{ color: "grey", textAlign: 'center', margin: 'auto' }}>Click on searched row to see the content</div>
              }
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default SearchModalRichText;