import Spinner from 'components/SharedComponents/Spinner'
import Table from 'components/SharedComponents/Table'
import { Col, ColsGrouper, IconWrapper, Row } from 'components/SharedComponents/Table/styles'
import { Toast } from 'primereact/toast'
import React from 'react'
import { useState } from 'react'
import { MdEdit } from 'react-icons/md'
import { RiDeleteBinLine } from 'react-icons/ri'
import { EX_LARGE_COL, EX_SMALL_COL, EX_VERY_LARGE_COL, LARGE_COL, SMALL_COL } from 'shared/styles/constants/columns'
import { CHECK, CROSS, FLOPPY, TIMES } from 'utils/constants/icons'
import { useRef } from 'react'
import moment from 'moment'
import { useForm } from 'hooks/FormHook'
import { useModal } from 'hooks/ModalHook'
import UpdateModal from './UpdateModal'
import { deleteHrTask, deleteHrTaskClient, getClientHrTaskById, getHrTask, getHrTaskClient, getTeamHrTaskById, setHrTask, setHrTaskClient } from 'utils/api/EmployeeApi'
import { useEffect } from 'react'
import { getPageToSearchParam } from 'shared/methods'
import { emp_hr_task_choices } from 'utils/choiceConstant'
import { AppContext } from 'context'
import { useContext } from 'react'
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages'
import ModalDecision from 'components/SharedComponents/ModalDecision'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import { BsFolderPlus } from 'react-icons/bs'
import { TiArrowBackOutline } from 'react-icons/ti'
import { Fragment } from 'react'
import Pagination from 'components/SharedComponents/Pagination'
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination'

const headerColumns = [

  {
    label: "QA Task",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: "Title",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: "Start Date",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: "Due Date",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: "Status",
    name: '',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
]

function ClientHRTasks({
  selectedClientId,
  props,
  readPermission,
  createPermission,
  updatePermission,
  deletePermission
}) {
  const toast = useRef();
  const currentDate = moment().format('YYYY-MM-DD');
  const oneWeekAgo = moment().subtract(1, 'week').format('YYYY-MM-DD');
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [idSelected, setIdSelected] = useState('');
  const { setValue: setModalDeleteValue, bind: bindModalDelete } = useModal(
    false,
  );
  const [deleteId, setDeleteId] = useState('')

  const [textStatus, setTextStatus] = useState(SAVE);
  const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({ start_date: oneWeekAgo, end_date: currentDate });
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle: setTitle,
    fullBind: fullBind,
  } = useModal(false);

  const [empHrTasks, setEmpHrTasks] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")
  const [showError, setShowError] = useState(false)
  const { handleGetChoices, contextChoicesMap, contextChoices } = useContext(AppContext)


  useEffect(() => {
    handleGetChoices(emp_hr_task_choices)
  }, [])

  useEffect(() => {
    if (idSelected) {
      searchTeamHrTask();
      setShowError(false);
      setErrorMessage('');
    }
  }, [idSelected])

  useEffect(() => {
    if (selectedClientId && contextChoices.hr_task && (contextChoices.hr_task.length > 0)) {
      loadHrTask();
    }
  }, [values.ordering, values.limitperpage, props.location.search, contextChoices.hr_task])

  const searchTeamHrTask = async () => {
    const response = await getClientHrTaskById(idSelected);

    if (response.status) {
      setValues({
        ...values,
        ...response.data
      });

    }

  };

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [])


  const loadHrTask = () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage
    );

    getHrTaskClient(
      selectedClientId, { ...pageToSearch, ...values })
      .then(response => {
        //   if (response) {
        //     setLoadingModal(false); count
        //   }
        setEmpHrTasks(response.data || []);
        setIsLoading(false)
        setPages(Math.ceil(response.count / values.limitperpage));
      });
  };

  const handleEditClick = (id) => {
    if (id) {
      setTitle('Update Client QA Task')
      setModalValue(!modalValue)
      setIdSelected(id || '')
      values.id = id || '';
      setValues({ ...values })
    } else {
      setTitle('Add Client QA Task')
      setModalValue(!modalValue)
      setIdSelected('')
      setValues({})
    }
  }

  const handleSubmit = (
    evt,
    saveTrigger = true,
  ) => {
    evt.preventDefault();

    if (loadingSave) {
      return;
    }
    setLoadingSave(true)
    if ((!isValid && errors.length > 0)) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }

    let body = {
      client: selectedClientId,
      core_hr_task: values.core_hr_task,
      title: values.title,
      instruction: values.instruction,
      notes: values.notes,
      status: values.status,
      due_date: values.due_date
    }
    if (!values.id) {
      body.start_date = values.start_date
    }
    let value = ''

    setHrTaskClient(body, values.id)
      .then(response => {
        if (response.statusCode === 400) {
          if (response.message) {
            value = response.message
          } else {
            value = Object.keys(response)[0] + "-" + Object.values(response)[0]
          }
          throw value;
        }
        if (response.status === "False") {
          toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `${response?.message?.expense_document[0]}`,
          });
        } else {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `${'Successful'}`,
          });
        }
        setShowError(false);
        onSuccess(response);
        loadHrTask()
        setModalValue(false)
        setIdSelected('')
      })
      .catch(onError);
  };
  const onSuccess = async (response) => {
    let expenseId = response.id;
    // if employee id
    setValues({
      ...values,
      id: expenseId,
    });
    setLoadingSave(false);
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
  }

  const onError = err => {
    // const onError = () => {
    setLoadingSave(false);
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(
      err
      // "Some of the fields have invalid or empty values. Please review."
    );
  };

  const toDeleteContact = id => {
    setModalDeleteValue(true);
    setDeleteId(id);
  };

  const handleDeleteHrTask = async () => {
    try {
      await deleteHrTaskClient(deleteId);
      //Todo: confirm deleted client message
    } catch (error) {
      //Todo: error message and add the methods of finally in to ok modal function
    } finally {
      setModalDeleteValue(false);
      loadHrTask();
      setDeleteId('')
    }
  };

  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({ ...values });
  };

  return (
    <div>
      <Toast ref={toast} position="top-right" />
      {/* <Header style={{ padding: 10 }}>
                <FiltersContainer style={{ marginBottom: '4px' }}>
                    <EmploymentTypesContainer className="me-2 mb-1">
                        <DropdownTypesContainer className="ms-1 me-1  d-flex">
                            <DateSelector
                                typeFilter={true}
                                labelTop="Date from"
                                {...startDateInput}
                            />
                            <div>
                                <CloseButtonWrapper
                                    onClick={() => {
                                        clearDateFilter('start_date', values?.start_date);
                                    }}>
                                    {TIMES}
                                </CloseButtonWrapper>
                            </div>
                        </DropdownTypesContainer>
                    </EmploymentTypesContainer>

                    <EmploymentTypesContainer className="me-2 mb-1">
                        <DropdownTypesContainer className="ms-1 me-1  d-flex">
                            <DateSelector
                                typeFilter={true}
                                labelTop="Date to"
                                {...endDateInput}
                            />
                            <div>
                                <CloseButtonWrapper
                                    onClick={() => {
                                        clearDateFilter('end_date', values?.start_date);
                                    }}>
                                    {TIMES}
                                </CloseButtonWrapper>
                            </div>
                        </DropdownTypesContainer>

                    </EmploymentTypesContainer>
                     <EmploymentTypesContainer className="me-2 mb-1">                     
                        <DataFromatDropdown
                            className="w-100"
                            required
                            placeHolder={'Format'}
                            options={contextChoices && contextChoices?.kpi?.filter(format => format.id !== 'quarter')}
                            selectedOption={values?.data_format}
                            onChange={value =>
                                dropDownHandleSelect({ data_format: value ? value : null })
                            }
                        />                     
                    </EmploymentTypesContainer> 
                    <EmploymentTypesContainer className="me-2 mb-1">
                        <PrimaryButton
                            type="button"
                            onClick={handleSearch}
                            style={{ marginLeft: '4px', marginBottom: "2px" }}>
                            <span className={'ms-2 me-2 font-weight-bold ms-3'}>Search</span>
                        </PrimaryButton>
                    </EmploymentTypesContainer>
                </FiltersContainer>
            </Header> */}
      <div>
        {!modalValue ? (
          <>
            <div className={'w-100 d-flex justify-content-center mb-3 mt-2'}>
              <div className={'text-primary font-weight-bold'}>
                <div>
                  {createPermission &&
                    <PrimaryButtonForm
                      minWidth="6rem"
                      onClick={() => handleEditClick()}
                    >
                      <BsFolderPlus size={18} />
                      <span className={'ms-2'}>NEW QA TASK</span>
                    </PrimaryButtonForm>}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Fragment />
        )}

        {isLoading ?
          (<div style={{ marginTop: "300px" }} >
            <Spinner />
          </div>) :
          <div className='mt-4'>
            {empHrTasks?.length ? <>
              <Table
                headerColumns={headerColumns}
              // handleAllCheck={handleAllCheck}
              // handleAllSelectChange={handleAllSelectChange}
              >
                {empHrTasks?.map((task, index) => {
                  return (
                    <div key={index}>
                      <Row bgColor Shadow>
                        <ColsGrouper className="d-flex">
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden"
                            Capitalize
                            overlap={true}
                          >
                            {(task.core_hr_task && contextChoicesMap.hr_task) && contextChoicesMap?.hr_task[task.core_hr_task]?.title}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden"
                            Capitalize
                            overlap={true}
                          >
                            {task.title}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden"
                            Capitalize
                            overlap={true}
                          >
                            {task.start_date}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden"
                            Capitalize
                            overlap={true}
                          >
                            {task.due_date}
                          </Col>
                          <Col
                            max_width={LARGE_COL}
                            Center
                            className="sm-hidden"
                            Capitalize
                            overlap={true}
                          >
                            {task.status}
                          </Col>
                          <Col
                            // max_width={EX_SMALL_COL}
                            onClick={() => { handleEditClick(task.id) }}
                            className="sm-hidden"
                            style={{ marginRight: "5px" }}
                            Center
                            Icon
                            Purple>
                            <IconWrapper>
                              <MdEdit />
                            </IconWrapper>
                          </Col>

                        </ColsGrouper>


                        <Col
                          className="sm-hidden"
                          Center
                          Shrink
                          NoFlexGrow
                          style={{ marginRight: "5px" }}
                        >
                          {deletePermission && (
                            <IconWrapper
                              onClick={() => {
                                toDeleteContact(task.id);
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>
                          )}
                        </Col>

                      </Row>
                      <div className="mb-1" />
                    </div>

                  )
                })

                }
              </Table>
              <div className="mt-3">
                <Pagination
                  totalPages={pages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  values={values}
                  dropDownHandleSelect={dropDownHandleSelectPagination}
                  number={PAGE_SIZE_RENDER}
                  dataLength={empHrTasks.length}
                />
              </div>
            </> :
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <h4 style={{ fontSize: "1rem" }}>No data found</h4>
              </div>
            }
          </div>
        }
      </div>
      <div>
        <ModalDecision
          type="delete"
          title="Warning"
          body={'Are you sure you want to delete this QA Task? \n DELETING the QA Task will remove ALL records from database.'}
          subBody={''}
          onOk={handleDeleteHrTask}
          onCancel={() => {
            setDeleteId('');
          }}
          okTitle={'DELETE'}
          {...bindModalDelete}
        />
      </div>

      {modalValue && (
        <UpdateModal
          fullBind={fullBind}
          useInput={useInput}
          values={values}
          setValues={setValues}
          errors={errors}
          setCheckRequires={setCheckRequires}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          showError={showError}
          setShowError={setShowError}
          contextChoices={contextChoices}
          handleSubmit={handleSubmit}
          setLoadingSave={setLoadingSave}
          loadingSave={loadingSave}
          readOnly={idSelected ? !updatePermission : !createPermission}
        />)}
    </div>
  )
}

export default ClientHRTasks