import React, { useState, useEffect, Fragment } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED, SERVER_ERROR } from 'utils/constants/messages';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import {
  getReviewSection,
  setReviewSection,
} from 'utils/api/ClientApi';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';
import TimeSelector from 'components/SharedComponents/TimeSelector';

const ReviewSection = ({
  // labels,
  // employeeName,
  // alertPrivacy,
  // setTitle,
  loadVisitCareNotes,
  // handleChangeChoices,
  contextChoices,
  // pageClientID,
  // userList,
  toast,
  setModalValue,
  fullBind,
  idSelected,
  readPermission,
  createPermission,
  updatePermission,
  deletePermission,
  setIdSelected = () => { }
}) => {
  const { values, setValues, useInput, isValid, errors, setCheckRequires } = useForm({});
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalTile, setModalTitle] = useState("")
  const [showError, setShowError] = useState(false);

  const review_comment_hook = useInput(`review_comment`);
  const newDoseTime = useInput(`review_time`);
  const review_date_hook = useInput(`review_date`,'isRequired');
  const review_status_hook = useInput(`review_status`, 'isRequired')


  useEffect(() => {
    if (idSelected) {
      // handleChangeChoices(client_visit_care_notes);
      getReviewSectionData();
      setModalTitle("Add Review");
    } else {
      setModalTitle("Add Review");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);





  const getReviewSectionData = async () => {
    setLoadingModal(true)
    const response = await getReviewSection(idSelected);
    if (response) {
      const [date, time] = response.review_date_time ? response.review_date_time?.split('T') : []
      values.id = response.id
      values.review_status = response.review_status;
      values.review_comment = response.review_comment;
      values.review_date = date;
      values.review_time = time;
      values.reviewed_by = response.reviewed_by
      values.reviewed_by_name = response.reviewed_by_name;
      setValues({ ...values });
    }
    setLoadingModal(false)
  };




  const dropDownHandleSelect = (item, nameItem) => {
    if (nameItem == 'client_service_visit_timerange') {
      values.client_service_visit_timerange =
        item.client_service_visit_timerange;
    }
    setValues({ ...values, ...item });
  };


  const handleSubmit = async e => {
    e.preventDefault();
    if (loadingSave) {
      return;
    }
    if (errors.length) {
      setErrorMessage(InvalidOrEmptyValues)
      setCheckRequires(true);
      setShowError(true)
      return;
    }
    const dateTime = `${values.review_date || ''}${values.review_date && values.review_time ? ' ' : ''}${values.review_time || ''}`;
    const body = {
      "clientCareNotes": idSelected,
      "reviewStatus": values.review_status,
      "reviewDateTime": dateTime,
      "reviewComment": values.review_comment
    };
    if (values.id) {
      delete body.clientCareNotes;
    }
    setLoadingSave(true);

    try {
      const response = await setReviewSection(body, values?.id)

      if (response?.id) {
        onSuccess("Success");
      } else {
        onError(SERVER_ERROR);
      }

    } catch (error) {
      onError(SERVER_ERROR);
    }
  }

  const onSuccess = message => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setModalValue(false);
    setShowError(false);
    setIdSelected(null)
    // handleChangeChoices(client_visit_care_notes);
    loadVisitCareNotes();
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: `${message}`,
    });
  };

  const onError = err => {
    let getErrorMessage = err ? err : 'something went wrong !!';
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(getErrorMessage);
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: `${getErrorMessage}`,
    });
  };


  const headerInfo = () => {
    return (
      <RowInfoModel
        name={values.first_name || values.full_name}
        lastName={values.last_name}
        photoRow={''}
        rows={[
          { label: 'Reviewed By:', value: values.reviewed_by_name },
        ]}
      // dateOfBirth={dateOfBirth}
      />
    )
  }

  const readOnly = (idSelected ? !updatePermission : !createPermission);
  return (
    <>
      <ModalContainer max_width={'60%'}>
        <ModalNoInfo
          {...fullBind}
          max_width={'60%'}
          title={modalTile}
          minWidthTitle="8.5rem"
          loading={loadingModal}
          rowInfoData={headerInfo}
        >

          <div className="ps-3 pe-3 pb-3 pt-0">
            {(idSelected ? readPermission : createPermission) && (
              <>
                {/* <TitleSection>Review Details</TitleSection> */}
                <div>
                  <div className="d-flex mt-2">
                    <div className="w-100 me-2">
                      <DropdownSearchable
                        required
                        disabled={readOnly}
                        placeHolder={'Review Status'}
                        options={contextChoices?.medication_review_status || []}
                        {...review_status_hook}
                        selectedOption={
                          values.review_status
                        }
                        onChange={value =>
                          dropDownHandleSelect(
                            { review_status: value ? value : null },
                            'review_status'
                          )
                        }
                      />
                    </div>
                    <div className="w-100 pe-1">
                      <DateSelector
                        isRequired
                        disabled={readOnly}
                        labelTop="Review Date"
                        {...review_date_hook}
                      />
                    </div>
                    <div className="w-100 pe-1">
                      <TimeSelector
                        disabled={readOnly}
                        labelTop={'Review Time'}
                        {...newDoseTime}
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                      />
                    </div>
                  </div>
                  <div className="d-flex mt-2">
                    <div className="w-100 pe-1">
                      <div
                        className={'pt-2 ms-md-1 ms-0'}
                        style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                        Review Comment
                      </div>
                      <InputTextArea
                        type="text"
                        height={'3.4rem'}
                        id="emp_desc"
                        placeHolder={'Review Comment'}
                        label={'Review Comment'}
                        {...review_comment_hook}
                        disabled={readOnly}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className={'d-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'}>
              <div className={'mb-3'}>
                {(showError && errors.length )? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>
              <div className={'d-flex justify-content-center justify-content-md-end'}>
                <div className={'ms-0 ms-md-3 me-2'}>
                  <PrimaryButtonForm
                    minWidth="6rem"
                    onClick={handleSubmit}
                    disabled={readOnly}
                  >
                    <span className={'me-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                </div>
              </div>
            </div>
          </div>
        </ModalNoInfo>
      </ModalContainer>
    </>
  )
};

export default ReviewSection;


