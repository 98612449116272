import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import React from 'react'
import { CloseButtonWrapper } from 'shared/styles/constants/tagsStyles';
import { TIMES } from 'utils/constants/icons';

function AddForm({
    useInput,
    readOnly,
    payTypeOption,
    values,
    index,
    setValues,
    SPLIT_SHIFT,
    errors,
    setFormErrors,
    toast
}) {


    const dropDownHandleSelect = (item, nameSection, index) => {
        if (nameSection === 'privacy') {
            values[SPLIT_SHIFT][index][nameSection] = item[nameSection].map(y => y.id)
        } else {
            values[SPLIT_SHIFT][index][nameSection] = item[nameSection]
            if (nameSection == "shift_type")
                handleUpdate(index)
        }
        setValues({ ...values });
    };



    const deletePrivacy = (values, index) => {
        const err = errors && errors.filter(el => !el.includes(`split_shift[${index}]`))
        setFormErrors(err || [])
        values[SPLIT_SHIFT].splice(index, 1)
        setValues({ ...values })
    }



    const handleUpdate = (index) => {
        let final = payTypeOption || [];
        for (let i = 0; i < payTypeOption?.length; i++) {
            if (i !== index && values[SPLIT_SHIFT][i]
                && values[SPLIT_SHIFT][i].shift_type !== undefined) {
                final = final.filter(el => (el.id !== values[SPLIT_SHIFT][i].shift_type));
            }
        }

        return final || [];
    };

    return (
        <div>
            <div className="d-flex flex-column mt-2" style={{ marginLeft: '15px' }}>
                <div className="d-flex">
                    <div className="d-flex w-100">
                        <CloseButtonWrapper
                            style={{
                                display: 'flex',
                                marginTop: '10px'
                            }}
                            disabled={readOnly}
                            onClick={() => {
                                !readOnly && deletePrivacy(values, index);
                            }}>
                            {TIMES}
                        </CloseButtonWrapper>
                        <div
                            className="w-100 me-2"
                            style={{ marginLeft: '8px', marginRight: '10px' }}>
                            <DropdownSearchable
                                required
                                {...useInput(`split_shift[${index}].shift_type`, 'isRequired')}
                                disabled={readOnly}
                                placeHolder={'Shift Category'}
                                options={handleUpdate(index) || []}
                                selectedOption={values[SPLIT_SHIFT][index].shift_type}
                                onChange={value =>
                                    dropDownHandleSelect({
                                        shift_type: value ? value : null,
                                    },
                                        'shift_type',
                                        index
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div
                        className="w-100 me-2"
                        style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <DropdownSearchable
                            disabled={readOnly}
                            required
                            isMulti={true}
                            {...useInput(`split_shift[${index}].split_shift_type`, 'isRequired')}
                            placeHolder={'Allow Overlap With'}
                            options={payTypeOption}
                            selectedOptionsProps={
                                values[SPLIT_SHIFT][index].split_shift_type ? values[SPLIT_SHIFT][index].split_shift_type : null
                            }
                            onChange={value =>
                                dropDownHandleSelect(
                                    {
                                        split_shift_type: value ? value : null,
                                    },
                                    'split_shift_type',
                                    index
                                )
                            }
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddForm