import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import React from 'react';
import { Fragment } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {
    ErrorMessage,
    FlexGrid3Container,
    FlexGrid3Item,
    ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import { FLOPPY } from 'utils/constants/icons';

function AddVisitForm({
    fullBind,
    useInput,
    readOnly,
    values,
    errors,
    errorMessage,
    showError,
    loadingSave,
    handleSubmit,
    contextChoices,
    formsChoices,
    dropDownHandleSelect
}) {
    return (
        <ModalContainer max_width={'60%'}>
            <ModalNoInfo title={'Attach Form'} {...fullBind} minWidthTitle="8.5rem" loading={false}>
                <div
                    style={{ padding: "0 1rem 1rem 1rem" }}
                    className="d-flex flex-column w-100">
                    <FlexGrid3Container className={'w-100 mb-2'}>
                        <FlexGrid3Item className="pe-2">
                            <DropdownSearchable 
                                // isMulti={true}
                                required
                                placeHolder={'Select Form'}
                                disabled={false}
                                options={formsChoices && formsChoices}
                                selectedOption={values?.form ? values?.form : null}
                                {...useInput('form', 'isRequired')}
                                onChange={value =>
                                    dropDownHandleSelect({
                                        form: value ? value : null,
                                    })
                                }
                            />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="ps-0 pe-1">
                                <DateSelector
                                    isRequired
                                    disabled={false}
                                    //errorMessage={dateMessage}
                                    labelTop="Start day"
                                    //data-error={dateError}  
                                    {...useInput('start_date', 'isRequired')}
                                />
                            </FlexGrid3Item>
                            <FlexGrid3Item className="ps-1 pe-0">
                                <DateSelector
                                    isRequired
                                    disabled={false}
                                    //errorMessage={dateMessage}
                                    labelTop="End day"
                                    //data-error={dateError}
                                    {...useInput('end_date', 'isRequired')}
                                />
                            </FlexGrid3Item>
                    </FlexGrid3Container>
                    <div
                        className={'d-flex justify-content-center justify-content-md-end mt-3'}>
                        <div className={'mb-3'}>
                            {errors.length && showError ? (
                                <ErrorMessage>
                                    <label className={'mt-2'}>{errorMessage}</label>
                                </ErrorMessage>
                            ) : (
                                <Fragment />
                            )}
                        </div>
                        <div className={'ms-0 ms-md-3 me-2'}>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                disabled={readOnly}
                                onClick={handleSubmit}
                                style={{ marginBottom: '5px' }}>
                                <span className={'me-2'}>SAVE</span>
                                {!loadingSave ? (
                                    FLOPPY
                                ) : (   
                                    <span
                                        className="spinner-border spinner-border-sm me-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </div>
                </div>
            </ModalNoInfo>
        </ModalContainer>
    );
}

export default AddVisitForm;
