import React, { useState, useEffect, Fragment } from 'react';
import { FiltersContainerRotaShifts, FiltersLeftGroup, FiltersTypesContainerRotaShifts, Title } from 'shared/styles/constants/tagsStyles';
import { withRouter, useParams } from 'react-router';
import { useForm } from 'hooks/FormHook';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { CANCELLED_VIST, GRAY_700, GRAY_820, OBSERVATION_VISIT, PURPLE, SCHEDULER_ASSIGNED, SCHEDULER_UNASSIGNED, SHADOW_VISIT, TRAVEL_TIME_COLOR, WAIT_TIME_COLOR, WHITE } from 'shared/styles/constants/colors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import PlannerCalendar from 'components/SharedComponents/PlannerCalendar';
import { getActiveEmp, getPlannerCompareSchVisits, getScheduler } from 'utils/api/SchedulerApi';
import moment from 'moment';
import TimelineCalendar from 'components/SharedComponents/TimelineCalendar';
import { AppContext, BranchItemsContext } from 'context';
import { useContext } from 'react';
import { SchedulerContainer } from '../styles';
import { getRotaShifts, getSchedulerRotaShifts } from 'utils/api/RotaShift';
import queryString from 'query-string';
import { TYPE_AVAILABLE, TYPE_UNAVAILABLE } from 'utils/constants/rotaShift';
import { useMemo } from 'react';
import { BIKE_ICON, CAR_ICON, FOOT_ICON, High_PRIORITY, Home_ICON, LOW_PRIORITY, MEDIUM_PRIORITY } from 'utils/constants/icons';
import { extendMoment } from 'moment-range'
import CustomDropdown from 'components/SharedComponents/NoSelectAllDropdownFilter/CustomDropdown';
import MultiSelectDropdown from 'components/SharedComponents/MultiSelectDropdown';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { scheduler_choices } from 'utils/choiceConstant';
import { roundToTwoDecimals } from 'shared/methods';
import Spinner from 'components/SharedComponents/Spinner';
import { getSchedulerWaitTime } from '../../../../utils/api/RotaShift';

let VISITS_ASSIGNED = 0; 
let VISITS_UNASSIGNED = 0;
let TOTAL_VISITS = 0;

// let VISITS_ASSIGNED_PLANNED = 0;
// let VISITS_UNASSIGNED_PLANNED = 0;
// let TOTAL_VISITS_PLANNED = 0;

const Planner = (props) => {
  const { id: selectedId } = useParams();
  let query_params_data = queryString.parse(props.location.search)
  const { values, setValues, useInput, errors, setFormErrors, setCheckRequires } = useForm({});
  const history = useHistory()
  const [eventsPlanned, setPlannedEvents] = useState([]);
  const [eventsSchedular, setEventsSchedular] = useState([]);
  const [resourcesSchedular, setResourcesSchedular] = useState([]);
  const [shiftType, setShiftType] = useState('resourceTimelineDay');
  const { plannerPropsData, contextChoices, contextChoicesMap, handleGetChoices } = useContext(AppContext);
  const { branchData } = useContext(BranchItemsContext);
  const [filterExcludeCheck, setFilterExcludeCheck] = useState({ client: false, employee: false });
  const [loading, setLoading] = useState(true)
  const [empData, setEmpData] = useState([]);
  const [evvVal, setEvvVal] = useState(null);
  const [employeeForFilter, setEmployeesForFilter] = useState([])
  const [employeesFilter, setEmployeesFilter] = useState([]);
  const [clientsFilter, setClientsFilter] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);
  const [runRoutesclientFilter, setRunRoutesclientFilter] = useState([]);
  const [jobDepartmentFilter, setJobDepartmentFilter] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [branchRunSchedularFilter, setBranchRunSchedularFilter] = useState([]);
  const [visitStatusFilter, setVisitStatusFilter] = useState([]);
  const [runNameFilter, setRunNameFilter] = useState([]);
  const [clearAll, setClearAll] = useState(false);
  const [clients, setClients] = useState([]);
  const [runRouteData, setRunRouteData] = useState([]);
  const [jobDepartment, setJobDepartment] = useState([]);
  const [branch, setBranch] = useState([]);
  const [employeeResource, setEmployeeResource] = useState('')
  const [employeeResourcePlanned, setEmployeeResourcePlanned] = useState('')
  const [scheduledData, setScheduledData] = useState([]);
  const [rotashiftData, setRotashiftData] = useState([]);
  const [plannerDataState, setPlannerDataState] = useState([]);
  const [decreaseWidthPlan, setDecreaseWidthPlan] = useState(false);
  const [decreaseWidthSch, setDecreaseWidthSch] = useState(false);
  const [employeeVisitData, setEmployeeVisitData] = useState([])
  const [employeeVisitDataPlanned, setEmployeeVisitDataPlanned] = useState([])

  // const [waitHoursBetweenVisit, setWaitHoursBetweenVisit] = useState(0);
  // const [waitHoursExcludeMoreThanTwo, setWaitHoursExcludeMoreThanTwo] = useState(0);
  // const [waitHoursCapacity, setWaitHoursCapacity] = useState(0);

  // const [waitHoursBetweenVisitPlanned, setWaitHoursBetweenVisitPlanned] = useState(0);
  // const [waitHoursExcludeMoreThanTwoPlanned, setWaitHoursExcludeMoreThanTwoPlanned] = useState(0);
  // const [waitHoursCapacityPlanned, setWaitHoursCapacityPlanned] = useState(0);
  const [regulerShiftType, setRegulerShiftType] = useState(null)
  const [visitScreenData, setVisitScreenData] = useState();
  const [loadingCount , setloadingCount] = useState(0)

  const [plannedVisitScreenData , setPlannedVisitScreenData] = useState({})
  const [rotaShiftScreenData , setRotaShiftScreenData] = useState({})
  const [plannedScreenData , setPlannedScreenData] = useState({})

  const [showWait, setShowWait] = useState({planned: false ,scheduled : false});
  const [waitTimeData, setWaitTimeData] = useState({planned: [] ,scheduled : []});
  const [waitTooltip, setWaitTooltip] = useState({planned: {} , scheduled : {}})


  


  useEffect(() => {
    handleGetChoices(scheduler_choices)
  }, [])
  
  const [timeCodes, setTimeCodes] = useState([
    { id: '111', name: "Visits" },
    { id: '222', name: 'Travel' },
    { id: '333', name: 'Wait' }
  ]);
  const [visitStatusData, setVisitStatusData] = useState([]);
  const [runNameData, setRunNameData] = useState([])
  const [serviceFilter, setServiceFilter] = useState([])

  const new_moment = extendMoment(moment);

  const hitApiAfterClear = (value) => {
    setClearAll(value)
  }

  const handleChangeFilterCheckbox = (e, dropdown_name) => {
    setFilterExcludeCheck({ ...filterExcludeCheck, [dropdown_name]: e.target.checked })
  }

  const employeeArray = () => {
    if (contextChoices.employee_listing && (contextChoices.employee_listing.length > 0)) {
      let temp = contextChoices.employee_listing.map((val) => ({ id: val.id, name: val.full_name, branch: val.branch }))
      return temp
    } else {
      return []
    }
  };

  const emp_arr = useMemo(() => employeeArray(), [contextChoices.employee_listing]);

  const getEmployeeActiveData = async () => {
    const date_val = moment(values.end_date).format('YYYY-MM-DD')
    const response = await getActiveEmp(date_val);
    if (response.data) {
      setEmpData(response.data || [])
      setloadingCount(prev => prev +1)
      //activeEmployeeResourceFn(response.data)
    }
  }

  const activeEmployeeResourceFn = (res) => {
    let employeesResources = []
    res.forEach(employee => {
      if (values.branch && values.branch.length > 0) {
        let filtered_branch_data = []
        values.branch.forEach((a) => {
          const emp_branch = emp_arr.filter((item) => item.branch == a.id);
          filtered_branch_data = [...filtered_branch_data, ...emp_branch]
        })
        filtered_branch_data.forEach((val) => {
          if (val.id == employee.id) {
            employeesResources.push({
              id: employee.id,
              title: employee.full_name,
            })
          }
        })
      } else if (branchData && branchData.headerbranch && branchData.headerbranch.length > 0) {
        let filtered_branch_data = []
        branchData.headerbranch.forEach((a) => {
          const emp_branch = emp_arr.filter((item) => item.branch == a.id);
          filtered_branch_data = [...filtered_branch_data, ...emp_branch]
        })
        filtered_branch_data.forEach((val) => {
          if (val.id == employee.id) {
            employeesResources.push({
              id: employee.id,
              title: employee.full_name,
            })
          }
        })
      } else {
        employeesResources.push({
          id: employee.id,
          title: employee.full_name,
        })
      }
    });
    setResourcesSchedular([{ id: "0000", title: "Unassigned" }, ...employeesResources]);
  }

  useEffect(() => {
    let employees = [];
    setRegulerShiftType(contextChoices && contextChoices?.regular_shift_type && contextChoices?.regular_shift_type)

    if (contextChoices.employee_listing) {
      employees = contextChoices.employee_listing.map(employee => {
        return { id: employee.id, name: employee.full_name, branch: employee.branch };
      });


      setEmployeesForFilter([{ id: "none", name: "Unassigned" }, ...employees] || []);
    }

    let clients = [];

    if (contextChoices.client_listing) {
      clients = contextChoices.client_listing.map(client => {
        return { id: client.id, name: client.full_name };
      });

      setClients(clients || []);
    }
    setVisitStatusData(contextChoices.visit_status && contextChoices.visit_status.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setRunRouteData(contextChoices.run_routes && contextChoices.run_routes.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setJobDepartment(contextChoices.job_category && contextChoices.job_category.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setBranch(contextChoices.branch && contextChoices.branch.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
    setRunNameData(contextChoices.run_view && contextChoices.run_view.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))
  }, [contextChoices])

  useEffect(() => {
    if (empData && (empData.length > 0)) {
      activeEmployeeResourceFn(empData)
    }
  }, [branchData,decreaseWidthPlan, decreaseWidthSch, emp_arr.length, empData.length])

  useEffect(() => {
    if (values.end_date) {
      getEmployeeActiveData()
    }
  }, [values.end_date])

  useEffect(() => {
    if (values.start_date && values.end_date && contextChoices?.travel_type) {
      if (plannerPropsData.events && (plannerPropsData.events.length > 0) && plannerPropsData.resources && (plannerPropsData.resources.length > 0)) {
        setEventsSchedular(plannerPropsData.events || [])
        setResourcesSchedular(plannerPropsData.resources || [])
        if (plannerPropsData.planned_filter) {
          setValues({ ...values, ...plannerPropsData.planned_filter })
        }
      }
      searchScheduler();
      getPlannedVisitFn()
    } else {
      values.start_date = query_params_data.start;
      values.end_date = query_params_data.end;
      setValues({ ...values })
    }
  }, [   
    values.start_date,
    values.end_date,
    contextChoices.travel_type
  ])

  const visitStatusColourClient = (detail) => {
    if (detail.is_visit_cancelled) {
      return CANCELLED_VIST
    } else {
      if (detail.is_shadow_visit) {
        return SHADOW_VISIT
      } else {
        if (detail.is_observation) {
          return OBSERVATION_VISIT
        } else {
          if (detail.employee_id) {
            if (detail.visit_status_name === 'Completed') {
              return '#06d187'
            } else if (detail.visit_status_name === 'In Progress') {
              return '#ffa500'
            } else if (detail.visit_status_name === 'Missed') {
              return '#ff3b3b'
            } else {
              if (detail.doubleUp) {
                return '#499937'
              } else {
                if (detail.is_pinned_employee) {
                  return '#00FF7F'
                } else {
                  return SCHEDULER_ASSIGNED
                }
              }
            }
          } else {
            return SCHEDULER_UNASSIGNED
          }
        }
      }
    }



  }

  const visitStatusColourTeam = (detail, checkDesire) => {

    if (detail.is_visit_cancelled) {
      return CANCELLED_VIST
    } else {
      if (detail.is_shadow_visit) {
        return SHADOW_VISIT
      } else {
        if (detail.is_observation) {
          return OBSERVATION_VISIT
        } else {
          if (detail.employee_id) {
            if (detail.visit_status_name === 'Completed') {
              return '#06d187'
            } else if (detail.visit_status_name === 'In Progress') {
              let sch_start = new Date(detail.start_date);
              let act_start = new Date(detail.actual_visit_start_time);
              let differenceValue = (sch_start.getTime() - act_start.getTime()) / 1000;
              differenceValue /= 60;
              let result = Math.abs(Math.round(differenceValue))

              let hms = evvVal?.early;   // your input string
              let a = hms?.split(':'); // split it at the colons

              // Hours are worth 60 minutes.
              let minutes = a && ((+a[0]) * 60 + (+a[1]));

              if ((sch_start > act_start) && (result > minutes)) {
                return '#f0b54a'
              } else {
                return '#ffa500'
              }
            } else {
              if (detail.incompatibility) {
                return '#912929'
              } else {
                if (detail.visit_status_name === 'Missed') {
                  return '#ff3b3b'
                } else {
                  // Create date from input value
                  let inputDate = new Date(detail.start_date);
                  // Get today's date
                  let todaysDate = new Date();
                  // call setHours to take the time out of the comparison
                  let differenceValue = (todaysDate.getTime() - inputDate.getTime()) / 1000;
                  differenceValue /= 60;
                  let result = Math.abs(Math.round(differenceValue))
                  let hms = evvVal?.late;   // your input string
                  let a = hms?.split(':'); // split it at the colons

                  // Hours are worth 60 minutes.
                  let minutes = a && ((+a[0]) * 60 + (+a[1]));

                  if ((inputDate < todaysDate) && (result > minutes)) {
                    return '#f74a4a'
                  } else {
                    if (detail.doubleUp) {
                      return '#5aa7f2'
                    } else {
                      if (detail.is_pinned_employee) {
                        if (checkDesire) {
                          return '#bb95f5'
                        } else {
                          return '#ADD8E6'
                        }
                      } else {
                        if (checkDesire) {
                          return '#bb95f5'
                        } else {
                          return '#1E90FF'
                        }
                      }
                    }
                  }
                }
              }
            }

          } else {
            return SCHEDULER_UNASSIGNED
          }
        }
      }
    }
  }

  const rotashiftColor = (name) => {
    switch (name) {
      case "Absence":
        return '#343434'
      case "Unavailability":
        return '#828282'
      case "On Call":
        return '#FF8C00'
      case "Car - Driver - Shift":
        return '#e0b16e'
      case "Bank-Flexible":
        return '#de5285'
      default:
        return '#e89b2e'
    }
  }

  const getPreorityIcon = (name) => {
    switch (name) {
      case "High":
      case "Very High":
      case "Mandatory / Essential":
        return High_PRIORITY;
      case "Very Low":
      case "Low":
        return LOW_PRIORITY
      default:
        return MEDIUM_PRIORITY
    }
  }

  useEffect(() => {
    if(regulerShiftType && loadingCount  >= 4)
    PlannedDataFn(plannerDataState, rotashiftData)
  }, [plannerDataState, rotashiftData, empData, regulerShiftType, loadingCount])

  useEffect(() => {
    if(regulerShiftType &&  loadingCount  >= 4)
    updateSchData(scheduledData, rotashiftData, 'team')
  }, [scheduledData, rotashiftData, empData, regulerShiftType, loadingCount])

  const PlannedDataFn = (res_data, rota_data) => {
    console.log('shivate');
    let data = []
    let travel_data = []
    let shift_availability = {}
    let final_data = []
    let final_wait_data = []
    let wait_hr_capacity = []
    let shift_data = []
    let visit_tooltip_data = empData.map((item) => {
      let obj = {
        employee_id: item.id,
        employee_name: item.full_name,
        contracted_no_hours: item.contractedHours,
        max_hour_per_week: item.maxHourPerWeek,
        actual_travel_miles: 0,
        duration: 0,
        no_visits: 0,
        remaining_contracted_desired_hrs: 0,
        remaining_desired_hrs: 0,
        schedule_travel_time: 0,
        total_visit_hours: 0
      }
      return obj;
    })

    res_data.map((detail) => {
      // let resData = employeeVisitData && employeeVisitData.find(det => det.employee_id === detail.employee_id)
      let checkDesire = 0;
      // if (resData && (resData.remaining_desired_hrs < 1)) {
      //   checkDesire = true;
      // } else {
      //   checkDesire = false;
      // }
      // BELOW TWO ARE NAN BECAUSE KEY DOESNT EXIST
      let visit_duration = ((new Date(`${detail.schedule_end_date} ${detail.schedule_end_time}`)).getTime() - (new Date(`${detail.schedule_start_date} ${detail.schedule_start_time}`)).getTime()) / 3600000;
      let travel_duration = ((new Date(detail.schedule_end_datetime)).getTime() - (new Date(detail.schedule_start_datetime)).getTime()) / 3600000;

      visit_tooltip_data = visit_tooltip_data.map((item) => {
        if (item.employee_id == detail.employee) {
          item.total_visit_hours = item.total_visit_hours + visit_duration;
          item.schedule_travel_time = item.schedule_travel_time + travel_duration;
          item.actual_travel_miles = item.actual_travel_miles + (detail.schedule_travel_miles ? detail.schedule_travel_miles : 0);
          item.no_visits = item.no_visits + 1
        }
        return item;
      })

      const visit_priority_icon = getPreorityIcon(detail.visitPriorityName)

      const planned_item = {
        id: detail.id,
        resourceId: detail.employee ? detail.employee : "0000",
        typeEvent: detail.employee ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
        unassigned: detail.employee ? false : true,
        title: detail.full_name ? detail.full_name : "Unassigned",
        backgroundColor: visitStatusColourTeam(detail, checkDesire),
        borderColor: visitStatusColourTeam(detail, checkDesire),
        textColor: detail.employee ? GRAY_820 : WHITE,
        start: (detail.schedule_start_date && detail.schedule_start_time) ? `${detail.schedule_start_date} ${detail.schedule_start_time}` : detail.start_datetime,
        end: (detail.schedule_end_date && detail.schedule_end_time) ? `${detail.schedule_end_date} ${detail.schedule_end_time}` : detail.end_datetime,
        client: detail.full_name,
        clientId: detail.client_id,
        employeeId: detail.employee,
        serviceId: detail.client_service_visit_id,
        employee_name: detail.employee_fullname,
        visit_status: detail.visitStatusName,
        run_view_id: detail.run_view_id,
        visit: true,
        icon: Home_ICON,
        address: detail.clientAddress,
        zipcode: detail.journey_end_zipcode,
        visit_type: detail.visitTypeName,
        orderEvent: 2,
        service_category: detail.serviceCategoryName,
        gender: detail.genderName || null,
        priority_icon: visit_priority_icon,
        visit_priority: detail.visitPriorityName,
        earliest_start_time: detail.earliestStartTime,
        latest_start_time: detail.latestStartTime,
        start_travel_time: detail.schedule_start_datetime,
        end_travel_time: detail.schedule_end_datetime,
        //run_view_name: detail.run_view_id && contextChoicesMap.run_view?.[detail.run_view_id]?.name,
        doubleUp: !!detail.doubleUp,
        constraint: {
          resourceIds: [detail.id],
        },
        client_group_area: detail.clientGroupArea,
        employee_group_area: detail.employeeGroupArea,
        contact_mobile: detail.clientContact,
        // run_view_id: detail.run_view_id,
        // actual_visit_end_time: detail.actual_visit_end_time,
        // actual_visit_start_time: detail.actual_visit_start_time,
        // actual_visit_duration: detail.actual_visit_duration,
        // schedule_duration: sch_duration_cal && sch_duration_cal.toFixed(2),
        // gender: genderName,
        // clientView: true,
        // address: detail.clientAddress,
        // zipcode: detail.postalCode,
        // visit_type: detail.visitTypeName,
        // service_category: detail.serviceCategoryName,
        service_category_type: capitalizeText(detail.service_category_type),
        // isLocation: scheduler.is_location,
        // earliest_start_time: detail.earliest_start_time,
        // latest_start_time: detail.latest_start_time,
        // run_view_name: detail.runViewName,
        // doubleUp: !!detail.doubleUp,
        // visit: true,
        // contact_mobile: detail.clientContact,
        // imp_mobile: detail.imp_mobile,
        // commissioned_hours_per_week : scheduler.commissioned_hours_per_week ?? 0,
        // client_preferred_employees : scheduler.client_preferred_employees || ""
      }

      data.push(planned_item)
      // data.push();
      // WILL NOT RUN BECAUSE KEY NOT EXISTS
      if (detail.employee && detail.schedule_start_datetime !== detail.schedule_end_datetime) {
        travel_data.push({
          id: detail.id,
          resourceId: detail.employee,
          typeEvent: detail.employee ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
          title: detail.full_name,
          backgroundColor: TRAVEL_TIME_COLOR,
          borderColor: TRAVEL_TIME_COLOR,
          textColor: GRAY_820,
          start: detail.schedule_start_datetime,
          end: detail.schedule_end_datetime,
          client: detail.full_name,
          clientId: detail.client_id,
          employeeId: detail.employee,
          serviceId: detail.client_service_visit_id,
          openModal: true,
          traveltime: true,
          travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
          orderEvent: 1,
          travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
            : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON
        });
      }
      // WILL NOT RUN BECAUSE KEY NOT EXISTS
      if (detail.employee && detail.tolerance_durations && detail.tolerance_durations >= 60) {
        let toleranceEnd = detail.schedule_end_datetime && moment(detail.schedule_end_datetime.split("T")[0] + " " + detail.schedule_end_datetime.split("T")[1].split(".")[0], 'YYYY-MM-DD HH:mm:ss').add(detail.tolerance_durations, 'seconds')
          .format('YYYY-MM-DDTHH:mm:ss');
        //                                  //console.log(detail.schedule_end_datetime+' -- '+toleranceEnd, 'toleranceEnd-1014');
        travel_data.push({
          id: detail.id,
          resourceId: detail.employee,
          typeEvent: detail.employee ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
          title: detail.full_name,

          backgroundColor: '#FFFF00',
          borderColor: '#FFFF00',
          textColor: GRAY_820,
          start: detail.schedule_end_datetime,
          end: toleranceEnd,
          client: detail.full_name,
          clientId: detail.client_id,
          employeeId: detail.employee,
          serviceId: detail.client_service_visit_id,
          openModal: true,
          traveltime: true,
          travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
          orderEvent: 3,
          travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
            : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON,
          typeVisit: "run"
        });
      }
    })
    rota_data.length && rota_data.forEach(rotaShift => {
      let emp = {
        id: rotaShift?.id,
        full_name: rotaShift?.employeeFullName,
        contractedHours: rotaShift?.employeeContractedHours,
        maxHourPerWeek: rotaShift?.employeeMaxHoursPerWeek,
        travel_mode: rotaShift?.employeeTravelMode,
        phone_number: rotaShift?.employeeMobileNo,
      };


      let employeeToolTip = {
        employee_id: rotaShift?.id,
        employee_name: rotaShift?.employeeFullName || 0,
        total_visits: rotaShift?.noOfVisits || 0,
        visits_hrs: rotaShift?.totalVisitHours || 0,
        contracted_hrs: rotaShift?.contractedNoOfHours || 0,
        desired_hrs: rotaShift?.employeeMaxHoursPerWeek || 0,
        travel_hrs: rotaShift?.totalTravelHours || 0,
        travel_distance: rotaShift?.totalTravelDistance || 0,
        wait_hrs: rotaShift.duration || 0,
        remain_desired_wk_hrs: rotaShift?.remainDesiredWorkingHours || 0,
        remain_contrd_wk_hrs: rotaShift?.remainContractedWorkingHours || 0,
        default_travel_mode: rotaShift?.employeeTravelMode || "N/A",
        phone_number: rotaShift?.employeeMobileNo || 0,
      }
      visit_tooltip_data.push(employeeToolTip)
      // allEmployeeData.push(emp)

      // if(rotaShift?.noOfVisits <= 0 &&  rotaShift.shifts.length <= 0){
      //   blank_resource.push(emp)
      // }

      // const employe_name = rotaShift.employee && contextChoicesMap.employee_listing?.[rotaShift.employee]?.full_name
      if (rotaShift.shifts && rotaShift.shifts.length) {
        rotaShift.shifts.map(shift => {
          const backgroundColor = shift?.absenceTypeName ? ( shift?.absenceTypeColor || GRAY_700 ) : shift?.shiftTypeName ? (shift?.shiftTypeColorCode || GRAY_700) : GRAY_700
          const textColor = getContrastColor(backgroundColor);
          const travelModeName = getTravelModeIcon(shift?.travelModeName);
          let title = "" ;
          if(shift.shiftTypeName && shift.absenceTypeName){
            title = `${shift.shiftTypeName}(${shift.absenceTypeName})`
          }else if(shift.shiftTypeName){
            title = shift.shiftTypeName
          }
          if (shift.schedule_travel_start_datetime !== shift.schedule_travel_end_datetime) {
            travel_data.push({
              id: shift.id,
              groupId: shift.id,
              resourceId: rotaShift?.id,
              typeEvent: shift.is_available ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: rotaShift?.employeeFullName,
              backgroundColor: TRAVEL_TIME_COLOR,
              borderColor: TRAVEL_TIME_COLOR,
              textColor: GRAY_820,
              start: shift.schedule_travel_start_datetime,
              end: shift.schedule_travel_end_datetime,
              // client: shift.full_name,
              // clientId: shift.client,
              employeeId: rotaShift?.id,
              // serviceId: shift.client_service_visit_id,
              openModal: true,
              traveltime: true,
              shiftTravel: true,
              orderEvent:2,
              travel_mode_name: shift.travelModeName,
              travel_code: shift.travel_code && shift.travel_code.toUpperCase(),
              travelMode: travelModeName
            });
          }
         
          shift_data.push({
            id: shift?.id,
            groupId: shift?.id,
            resourceId: rotaShift?.id,
            employeeId: rotaShift?.id,
            typeEvent: shift.is_available ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            title: title,
            textColor: textColor,
            start: shift?.start_date,
            end: shift?.end_date,
            shift_type: shift?.shift_type,
            travelModeName: shift?.travelModeName,
            // actual_shift_start_time: shift?.actual_shift_start_time,
            // actual_shift_end_time: shift?.actual_shift_end_time,
            // shift_status_name: shift?.shiftStatusName,
            travelMode: travelModeName,
            type: 'regularShift',
            orderEvent:1,
            // openModal: true,
            // waittime: true
          })
        })
      }
      
    });
    final_data = [...data, ...travel_data, ...final_wait_data,...shift_data]
    let wait_time_temp = [];

    // if (data && data.length > 0) {
    //   let temp_filter_data = data.filter((item) => item.employeeId);
    //   let temp_obj = {}

    //   temp_filter_data.forEach((item) => {
    //     if (temp_obj[item.resourceId]) {
    //       temp_obj[item.resourceId].push({ ...item })
    //     } else {
    //       temp_obj[item.resourceId] = [{ ...item }]
    //     }
    //   })

    //   console.log()


    //   Object.keys(shift_availability).map((key) => {
    //     shift_availability[key].forEach((shiftData) => {
    //       const shiftRange = new_moment.range(shiftData.start, shiftData.end);

    //       if (temp_obj[key]) {

    //         let sortedVisits = [];
    //         let sortedVisitsByEnd = [];
    //         temp_obj[key].forEach((visit) => {
    //           const visitRange = new_moment.range(visit.start_travel_time, visit.end);
    //           if (shiftRange.overlaps(visitRange) && (visit.start_travel_time)) {
    //             sortedVisits.push(visit);
    //             sortedVisitsByEnd.push(visit);
    //           }
    //         })

    //         sortedVisits = sortedVisits && sortedVisits.sort((a, b) => new Date(a.start_travel_time) - new Date(b.start_travel_time));

    //         sortedVisitsByEnd = sortedVisitsByEnd && sortedVisitsByEnd.sort((a, b) => new Date(a.end) - new Date(b.end));

    //         if (sortedVisits.length > 0) {
    //           if ((new Date(shiftData.start)).getTime() < (new Date(sortedVisits[0].start_travel_time)).getTime()) {
    //             wait_time_temp.push({
    //               resourceId: key,
    //               backgroundColor: WAIT_TIME_COLOR,
    //               borderColor: WAIT_TIME_COLOR,
    //               title: `${shiftData.zipcode} - ${sortedVisits[0].zipcode}`,
    //               textColor: GRAY_820,
    //               start: shiftData.start,
    //               end: sortedVisits[0].start_travel_time,
    //               openModal: true,
    //               waittime: true,
    //             });
    //           }

    //           if ((new Date(shiftData.end)).getTime() > (new Date(sortedVisits[sortedVisits.length - 1].end)).getTime()) {
    //             wait_time_temp.push({
    //               resourceId: key,
    //               backgroundColor: WAIT_TIME_COLOR,
    //               borderColor: WAIT_TIME_COLOR,
    //               title: `${sortedVisits[sortedVisits.length - 1].zipcode} - ${shiftData.zipcode}`,
    //               textColor: GRAY_820,
    //               start: sortedVisits[sortedVisits.length - 1].end,
    //               end: shiftData.end,
    //               openModal: true,
    //               waittime: true,
    //             });
    //           }
    //         }

    //         let wait_gap_visits = []
    //         let wait_gap_visits_less_two = []

    //         sortedVisits.map((event, i) => {
    //           if (i != 0) {
    //             let a = new Date(sortedVisits[i - 1].end)
    //             let b = new Date(sortedVisitsByEnd[i - 1].end)
    //             let dateEnd = null;
    //             if (b >= a) {
    //               dateEnd = new Date(sortedVisitsByEnd[i - 1].end)
    //             } else {
    //               dateEnd = new Date(sortedVisits[i - 1].end)
    //             }

    //             let gap = (new Date(event.start_travel_time)).getTime() - (dateEnd).getTime();
    //             if (gap > 60000) {
    //               console.log('gap', gap)
    //               let gap_in_hr = gap / 3600000;
    //               wait_gap_visits.push(gap_in_hr);
    //               if (!(gap_in_hr > 2)) {
    //                 wait_gap_visits_less_two.push(gap_in_hr)
    //               }


    //               wait_time_temp.push({
    //                 resourceId: key,
    //                 backgroundColor: WAIT_TIME_COLOR,
    //                 borderColor: WAIT_TIME_COLOR,
    //                 title: `${sortedVisits[i - 1].zipcode} - ${sortedVisits[i].zipcode}`,
    //                 textColor: GRAY_820,
    //                 start: b >= a ? sortedVisitsByEnd[i - 1].end : sortedVisits[i - 1].end,
    //                 end: event.start_travel_time,
    //                 openModal: true,
    //                 waittime: true,
    //               });
    //             }
    //           }
    //         })
    //         console.log('gap travel hrs', wait_gap_visits, wait_gap_visits_less_two)
    //         if (wait_gap_visits.length > 0) {
    //           let aHours = wait_gap_visits.reduce((total, visit) => total + visit);
    //           aHours % 0.1 === 0
    //             ? setWaitHoursBetweenVisitPlanned(aHours)
    //             : setWaitHoursBetweenVisitPlanned(Math.round(aHours * 100) / 100);
    //         } else {
    //           setWaitHoursBetweenVisitPlanned(0);
    //         }

    //         if (wait_gap_visits_less_two.length > 0) {
    //           let aHours = wait_gap_visits_less_two.reduce((total, visit) => total + visit);
    //           aHours % 0.1 === 0
    //             ? setWaitHoursExcludeMoreThanTwoPlanned(aHours)
    //             : setWaitHoursExcludeMoreThanTwoPlanned(Math.round(aHours * 100) / 100);
    //         } else {
    //           setWaitHoursExcludeMoreThanTwoPlanned(0);
    //         }

    //       }
    //       else {
           
    //         let gap_capacity_temp = (new Date(shiftData.end)).getTime() - (new Date(shiftData.start)).getTime();
    //         wait_hr_capacity.push(gap_capacity_temp)
    //         //console.log(shiftData, "0000000shiftData")
    //         wait_time_temp.push({
    //           resourceId: key,
    //           backgroundColor: WAIT_TIME_COLOR,
    //           borderColor: WAIT_TIME_COLOR,
    //           title: `${shiftData.zipcode} - ${shiftData.zipcode}`,
    //           textColor: GRAY_820,
    //           start: shiftData.start,
    //           end: shiftData.end,
    //           openModal: true,
    //           waittime: true,
    //         });
    //       }
    //       if (wait_hr_capacity.length > 0) {
    //         let aHours = wait_hr_capacity.reduce((total, visit) => total + visit);
    //         aHours % 0.1 === 0
    //           ? setWaitHoursCapacityPlanned(aHours)
    //           : setWaitHoursCapacityPlanned(Math.round(aHours * 100) / 100);
    //       } else {
    //         setWaitHoursCapacityPlanned(0);
    //       }
    //     })
    //   })

    // }
    let blank_emp = []
    let emp_count = 0;
    empData.forEach((val) => {
      let checkEmp = final_data.findIndex((item) => item.resourceId == val.id)
      let checkEmpCount = final_data.findIndex((item) => ((item.resourceId == val.id) && item.visit))
      if (checkEmp == -1) {
        blank_emp.push({
          resourceId: val.id,
          title: '',
          backgroundColor: 'rgba(54, 25, 25, .00004)',
          borderColor: 'rgba(54, 25, 25, .00004)',
          start: values.start_date,
          //end: moment(values.start_date).add(60, 'seconds'),                   
          allDay: true,
          blank: true
        })
      }
      if (!(checkEmpCount == -1)) {
        emp_count = emp_count + 1
      }
    })

    setEmployeeResourcePlanned(emp_count);
    if (values.employees && (values.employees.length > 0)) {
      let add_filtered_emp = []
      values.employees.map((val) => {
        let checkEmpBlank = blank_emp.findIndex((item) => item.resourceId == val.id)
        let check_val = filterExcludeCheck.employee ? (checkEmpBlank == -1) : !(checkEmpBlank == -1);
        if (check_val) {
          add_filtered_emp.push({
            resourceId: val.id,
            title: '',
            backgroundColor: 'rgba(54, 25, 25, .00004)',
            borderColor: 'rgba(54, 25, 25, .00004)',
            start: values.start_date,
            //end: moment(values.start_date).add(60, 'seconds'),                   
            allDay: true,
            blank: true
          })
        }
      })
      final_data = [...final_data, ...wait_time_temp, ...add_filtered_emp,...shift_data]
    } else {
      final_data = [...final_data, ...wait_time_temp, ...blank_emp,...shift_data]
    }
    setEmployeeVisitDataPlanned(visit_tooltip_data)
    setPlannedEvents([...new Set(final_data)] || [])
    setLoading(false)
  }
  function calculatePlannedTotalCounts(schduler, rotaShift) {
    const client = schduler && schduler?.client && schduler?.client;
    const location = schduler?.location && schduler?.location;

    const clientTravel = client?.travelHours && client?.travelHours;
    const locationTravel = location?.travelHours && location?.travelHours;
    const shiftTravel = rotaShift?.travelHours && rotaShift?.travelHours;
    const totalWaitTimeHrs = rotaShift && rotaShift.hasOwnProperty("total_wait_time_hrs") && rotaShift?.total_wait_time_hrs && roundToTwoDecimals(rotaShift?.total_wait_time_hrs)

    // if (calendarView === 'client')
    //   setClientResource(client?.employee)

    const totalEmployee = client?.employee + location?.employee;

    const totalVisitAssigned = client?.visitAssigned + location?.visitAssigned;

    const totalAssignedVisits = client?.assignedVisits + location?.assignedVisits;

    const totalUnassignedVisits =
      client?.unassignedVisits + location?.unassignedVisits;

    const totalPublishVisits = client?.publishVisits + location?.publishVisits;
    const totalUnpublishVisits =
      client?.unpublishVisits + location?.unpublishVisits;


    const totalAssignedHours = roundToTwoDecimals(client?.assignedHours + location?.assignedHours);
    const totalUnassignedHours = roundToTwoDecimals(client?.unassignedHours + location?.unassignedHours)

    const totalPublishHours = roundToTwoDecimals(client?.publishHours + location?.publishHours)
    const totalUnpublishHours = roundToTwoDecimals(client?.unpublishHours + location?.unpublishHours)

    // Travel hours 
    const clientTravelCode = {}
    const locationTravelCode = {}
    const shiftTravelCode = {}

    let totalTravelMinutes = 0;
    let totalClientTravel = 0
    let totalLocationTravel = 0
    let totalShiftTravel = 0

    travelCodes.forEach(item => {
      totalTravelMinutes += (locationTravel[item] || 0) + (clientTravel[item] || 0) +  (shiftTravel[item] || 0);
  
      clientTravelCode[item] = roundToTwoDecimals(clientTravel[item] || 0);
      locationTravelCode[item] = roundToTwoDecimals(locationTravel[item] || 0);
      shiftTravelCode[item] = roundToTwoDecimals(shiftTravel[item] || 0);

      totalClientTravel += clientTravel[item];
      totalLocationTravel += locationTravel[item];
      totalShiftTravel += shiftTravel[item];
  });
  

    const totalTravelHours = roundToTwoDecimals(totalTravelMinutes)


    const clientTravelMode = {}
    const locationTravelMode = {}
    const shiftTravelMode = {}


    let a = contextChoices && contextChoices?.travel_type
      && contextChoices?.travel_type?.forEach(mode => {

        clientTravelMode[mode?.name] = roundToTwoDecimals(clientTravel[mode?.name] || 0)
        locationTravelMode[mode?.name] = roundToTwoDecimals(locationTravel[mode?.name] || 0)
        shiftTravelMode[mode?.name] = roundToTwoDecimals(shiftTravel[mode?.name] || 0)
      })



    let waitHoursCapacity = 0
    let waitHoursBetweenVisit = 0
    let waitHoursExcludeMoreThanTwo = 0
    let waitHours = 0

    console.log(new Date(), {
      clientTravelCode,
      locationTravelCode,
      shiftTravelCode,

      clientTravelMode,
      locationTravelMode,
      shiftTravelMode,
    }, "hower data")
    return {
      totalEmployee,
      totalAssignedHours,
      totalAssignedVisits,
      totalVisitAssigned,
      totalUnassignedHours,
      totalUnassignedVisits,
      totalPublishHours,
      totalPublishVisits,
      totalUnpublishHours,
      totalUnpublishVisits,
      clinetData: client,
      locationData: location,
      totalTravelHours,

      clientTravelCode,
      clientTravelMode,

      locationTravelCode,
      locationTravelMode,

      shiftTravelCode,
      shiftTravelMode,

      visitScreenData: schduler,
      shiftScreenData: rotaShift,
      totalLocation: location?.location || 0,
      totalClient: client?.client || 0,

      waitHoursCapacity: waitHoursCapacity,
      waitHoursBetweenVisit: waitHoursBetweenVisit,
      waitHoursExcludeMoreThanTwo: waitHoursExcludeMoreThanTwo,
      totalWaitHours: totalWaitTimeHrs,

      totalClientTravel,
      totalLocationTravel,
      totalShiftTravel
    };
  }

  useEffect(() => {
    if (Object.keys(rotaShiftScreenData).length && Object.keys(plannedScreenData).length) {
      let data = calculatePlannedTotalCounts(plannedScreenData, rotaShiftScreenData)
      setPlannedVisitScreenData(data)
    }
  }, [rotaShiftScreenData, plannedScreenData])

  const getPlannedVisitFn = () => {
    const start_date_val = moment(values.start_date).format('YYYY-MM-DD')
    const end_date_val = moment(values.end_date).format('YYYY-MM-DD')
    getPlannerCompareSchVisits(start_date_val, end_date_val, selectedId)
      .then((res) => {
        setPlannerDataState(res.data)
        setloadingCount(prev => prev +1)
        setPlannedScreenData(res?.headerCalculations)
        //PlannedDataFn(res.data , rotashiftData)
      })
  }


  const newRotashiftColor = (name) => {
    const shift =regulerShiftType && regulerShiftType?.find(item => item.name === name);
    return shift?.color_code || GRAY_700;
  };

  const getNameFormChoicesMap = (name, id, fullItem = false) => {
    if (!id) return "";
    const data = contextChoicesMap?.[name] && contextChoicesMap?.[name]?.[id];

    if (fullItem) {
      return data || {}; 
    }
    return data?.name || data?.full_name || "";
  }


  const getTravelModeIcon = (mode) => {
    return mode ? mode === 'Walk'
      ? FOOT_ICON : mode === 'Bike' ||
        mode === 'Scooter' ||
        mode === 'Motarbike' ? BIKE_ICON
        : mode === 'Car' ? CAR_ICON
          : '' : ''
  }

  function getContrastColor(hex) {
    if(!hex) return WHITE;
    hex = hex.replace('#', '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    const value = 0.2126 * r/255 + 0.7152 * g/255 + 0.0722 * b/255;
    return value > 0.5 ? '#000000' : '#ffffff';
}
const travelCodes = ['CLIENT-CLIENT', 'CLIENT-OFFICE', 'OFFICE-CLIENT', 'CLIENT-HOME', 'HOME-CLIENT', 'HOME-OFFICE', 'OFFICE-OFFICE'];


  function calculateTotalCounts(schduler, rotaShift) {
    const client = schduler && schduler?.client && schduler?.client;
    const location = schduler?.location && schduler?.location;

    const clientTravel = client?.travelHours && client?.travelHours;
    const locationTravel = location?.travelHours && location?.travelHours;
    const shiftTravel = rotaShift?.travelHours && rotaShift?.travelHours;
    const totalWaitTimeHrs = rotaShift && rotaShift.hasOwnProperty("total_wait_time_hrs") && rotaShift?.total_wait_time_hrs && roundToTwoDecimals(rotaShift?.total_wait_time_hrs)

    // if (calendarView === 'client')
    //   setClientResource(client?.employee)

    const totalEmployee = client?.employee + location?.employee;

    const totalVisitAssigned = client?.visitAssigned + location?.visitAssigned;

    const totalAssignedVisits = client?.assignedVisits + location?.assignedVisits;

    const totalUnassignedVisits =
      client?.unassignedVisits + location?.unassignedVisits;

    const totalPublishVisits = client?.publishVisits + location?.publishVisits;
    const totalUnpublishVisits =
      client?.unpublishVisits + location?.unpublishVisits;


    const totalAssignedHours = roundToTwoDecimals(client?.assignedHours + location?.assignedHours);
    const totalUnassignedHours = roundToTwoDecimals(client?.unassignedHours + location?.unassignedHours)

    const totalPublishHours = roundToTwoDecimals(client?.publishHours + location?.publishHours)
    const totalUnpublishHours = roundToTwoDecimals(client?.unpublishHours + location?.unpublishHours)

    // Travel hours 
    const clientTravelCode = {}
    const locationTravelCode = {}
    const shiftTravelCode = {}

    let totalClientTravel = 0
    let totalLocationTravel = 0
    let totalShiftTravel = 0

    let totalTravelMinutes = 0;

    travelCodes.forEach(item => {
      totalTravelMinutes += locationTravel[item] + clientTravel[item] + shiftTravel[item];

      clientTravelCode[item] = roundToTwoDecimals(clientTravel[item] || 0)
      locationTravelCode[item] = roundToTwoDecimals(locationTravel[item] || 0)
      shiftTravelCode[item] = roundToTwoDecimals(shiftTravel[item] || 0)

      totalClientTravel += clientTravel[item];
      totalLocationTravel += locationTravel[item];
      totalShiftTravel += shiftTravel[item];

    });

    const totalTravelHours = roundToTwoDecimals(totalTravelMinutes)


    const clientTravelMode = {}
    const locationTravelMode = {}
    const shiftTravelMode = {}


    let a = contextChoices && contextChoices?.travel_type
      && contextChoices?.travel_type?.forEach(mode => {

        clientTravelMode[mode?.name] = roundToTwoDecimals(clientTravel[mode?.name] || 0)
        locationTravelMode[mode?.name] = roundToTwoDecimals(locationTravel[mode?.name] || 0)
        shiftTravelMode[mode?.name] = roundToTwoDecimals(shiftTravel[mode?.name] || 0)
      })
    console.log(new Date(), {
      clientTravelCode,
      locationTravelCode,
      shiftTravelCode,

      clientTravelMode,
      locationTravelMode,
      shiftTravelMode,
    }, "hower data")
    return {
      totalEmployee,
      totalAssignedHours,
      totalAssignedVisits,
      totalVisitAssigned,
      totalUnassignedHours,
      totalUnassignedVisits,
      totalPublishHours,
      totalPublishVisits,
      totalUnpublishHours,
      totalUnpublishVisits,
      clinetData: client,
      locationData: location,
      totalTravelHours,

      clientTravelCode,
      clientTravelMode,

      locationTravelCode,
      locationTravelMode,

      shiftTravelCode,
      shiftTravelMode,

      visitScreenData: schduler,
      shiftScreenData: rotaShift,
      totalLocation: location?.location || 0,
      totalClient: client?.client || 0,
      totalWaitHours: totalWaitTimeHrs,

      totalClientTravel,
      totalLocationTravel,
      totalShiftTravel
    };
  }

  const capitalizeText = (text) => text ? text.charAt(0).toUpperCase() + text.slice(1) : "";

  const updateSchData = (sch_data, rota_shift_data, view) => {
  
    console.log(rota_shift_data,"myLoading in fun")
    // myLoading= true
    console.log(new Date(),loading,"loading")
    let final_wait_data = []
    // console.log(new Date(),'current in schedulr start', new Date())
    // let shift_availability = {}
    let data = []
    let locationData = []
    TOTAL_VISITS = 0;
    let travel_data = []
    let shift_data = []
    let runViewData = {};
    let allEmployeeData = []
    let blank_resource =[]
    // let wait_gap_visits = []
    // let wait_gap_visits_less_two = []
    // let wait_hr_capacity = []
    // let locationresources = []
    // let activeEmpMap = {}
    let visit_tooltip_data = [];
    let client_hover_data = {};
    

    if (view === 'team') {
      rota_shift_data.length && rota_shift_data.forEach(rotaShift => {
        let emp = {
          id: rotaShift?.id,
          full_name: rotaShift?.employeeFullName,
          contractedHours: rotaShift?.employeeContractedHours,
          maxHourPerWeek: rotaShift?.employeeMaxHoursPerWeek,
          travel_mode: rotaShift?.employeeTravelMode,
          phone_number: rotaShift?.employeeMobileNo,
        };


        let employeeToolTip = {
          employee_id: rotaShift?.id,
          employee_name: rotaShift?.employeeFullName || 0,
          total_visits: rotaShift?.noOfVisits || 0,
          visits_hrs: rotaShift?.totalVisitHours || 0,
          contracted_hrs: rotaShift?.contractedNoOfHours || 0,
          desired_hrs: rotaShift?.employeeMaxHoursPerWeek || 0,
          travel_hrs: rotaShift?.totalTravelHours || 0,
          travel_distance: rotaShift?.totalTravelDistance || 0,
          wait_hrs: rotaShift.duration || 0,
          remain_desired_wk_hrs: rotaShift?.remainDesiredWorkingHours || 0,
          remain_contrd_wk_hrs: rotaShift?.remainContractedWorkingHours || 0,
          default_travel_mode: rotaShift?.employeeTravelMode || "N/A",
          phone_number: rotaShift?.employeeMobileNo || 0,
        }
        visit_tooltip_data.push(employeeToolTip)
        allEmployeeData.push(emp)

        if(rotaShift?.noOfVisits <= 0 &&  rotaShift.shifts.length <= 0){
          blank_resource.push(emp)
        }

        // const employe_name = rotaShift.employee && contextChoicesMap.employee_listing?.[rotaShift.employee]?.full_name
        if (rotaShift.shifts && rotaShift.shifts.length) {
          rotaShift.shifts.map(shift => {
         let shiftTypeData =  getNameFormChoicesMap("regular_shift_type", shift.shift_type, true)
         console.log(shiftTypeData, contextChoicesMap, shift.shift_type,"regular_shift_type")
         let is_available = !((shiftTypeData?.pay_type_name === "Absence") || (shiftTypeData?.pay_type_name === "Unavailability"))
            if (shift.schedule_travel_start_datetime !== shift.schedule_travel_end_datetime) {
              travel_data.push({
                id: shift.id,
                groupId: shift.id,
                resourceId: rotaShift?.id,
                typeEvent: is_available ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
                title: rotaShift?.employeeFullName,
                backgroundColor: TRAVEL_TIME_COLOR,
                borderColor: TRAVEL_TIME_COLOR,
                textColor: GRAY_820,
                start: shift.schedule_travel_start_datetime,
                end: shift.schedule_travel_end_datetime,
                // client: shift.full_name,
                // clientId: shift.client,
                employeeId: rotaShift?.id,
                // serviceId: shift.client_service_visit_id,
                openModal: true,
                traveltime: true,
                shiftTravel: true,
                orderEvent:2,
                travel_mode_name: shift.travelModeName,
                travel_code: shift.travel_code && shift.travel_code.toUpperCase(),
                travelMode: getTravelModeIcon(shift?.travelModeName)
              });
            }
           
            shift_data.push({
              id: shift?.id,
              groupId: shift?.id,
              resourceId: rotaShift?.id,
              employeeId: rotaShift?.id,
              typeEvent: is_available ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              backgroundColor: newRotashiftColor(shift?.shiftTypeName),
              borderColor: newRotashiftColor(shift?.shiftTypeName),
              title: shift?.shiftTypeName ?? "",
              textColor: getContrastColor(newRotashiftColor(shift?.shiftTypeName)),
              start: shift?.start_date,
              end: shift?.end_date,
              shift_type: shift?.shift_type,
              // actual_shift_start_time: shift?.actual_shift_start_time,
              // actual_shift_end_time: shift?.actual_shift_end_time,
              // shift_status_name: shift?.shiftStatusName,
              travelMode: getTravelModeIcon(shift?.travelModeName),
              type: 'regularShift',
              orderEvent:1,
              // openModal: true,
              // waittime: true
            })
          })
        }
        
      });
    }

    // setEmpData(allEmployeeData)
    // updateEmployeeResource(allEmployeeData)
    // setEmployeeVisitData(visit_tooltip_data)
    // let final_shift = (view === "client") ? [...data] :
    //   (view === 'location') ? [...locationData] :
    //     [...data, ...travel_data, ...final_wait_data, ...shift_data, ...locationData];
    // setEvents(final_shift.flat());
    // setTotalEvents(final_shift.flat())
    // setLoading(false);

    sch_data.length && sch_data.forEach(scheduler => {
      client_hover_data[scheduler.id] = {
        commissioned_hours_per_week :scheduler.commissioned_hours_per_week ?? 0,
        client_preferred_employees : scheduler.client_preferred_employees || "",
        totalVisitHrs: scheduler.totalVisitHrs ?? 0,
        totalVisit: scheduler.totalVisit ?? 0
      }

      TOTAL_VISITS = TOTAL_VISITS + scheduler.visits.length;
      // let uniquie_visit_id = []
    
      scheduler.visits.forEach(detail => {
      const genderName =  getNameFormChoicesMap('gender', detail?.gender)
      const visitPriority  = getNameFormChoicesMap('visit_priority', detail?.visit_priority) 
      const runViewName  = getNameFormChoicesMap('run_view', detail?.run_view_id) 
      
        // if (!uniquie_visit_id.find(id => id === detail.id)) {
        //   uniquie_visit_id.push(detail.id)
        //   if (detail.unassigned != undefined) {

        //     if (!detail.unassigned) {
        //       // VISITS_ASSIGNED += 1;
        //     } else {
        //       VISITS_UNASSIGNED += 1;
        //     }
        //     if (detail.is_published) {
        //       VISITS_PUBLISHED += 1
        //     } else {
        //       VISITS_UNPUBLISHED += 1
        //     }
        //   }

        // }

        let client_gp_area_str = scheduler.client_group_area ? scheduler.client_group_area : ""
        let emp_gp_area_str = detail.employee_group_area ? detail.employee_group_area : ""

        let sch_duration_cal = ((new Date(detail.end_date)).getTime() - (new Date(detail.start_date)).getTime()) / 3600000

        if (view === 'client' && !scheduler.is_location) {
          data.push({
            id: detail.id,
            resourceId: scheduler.id,
            typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            isRegular: detail.is_pinned_employee,
            assigned_by: detail.assigned_by ? detail.assigned_by : '',
            is_published: detail.is_published,
            title: detail.employee_id
              ? detail.employee_fullname
              : detail.is_visit_cancelled
                ? 'Cancelled'
                : 'Unassigned',
            unassigned: detail.employee_id ? false : true,
            backgroundColor: visitStatusColourClient(detail, !!detail.doubleUp),
            borderColor: visitStatusColourClient(detail, !!detail.doubleUp),
            textColor: detail.employee_id ? GRAY_820 : WHITE,
            constraint: {
              resourceIds: [scheduler.id],
            },
            start: detail.start_date,
            end: detail.end_date,
            client_group_area: client_gp_area_str,
            employee_group_area: emp_gp_area_str,
            client: scheduler.full_name,
            clientId: scheduler.id && scheduler.id,
            employeeId: detail.employee_id,
            employee_name: detail.employee_fullname,
            visit_status: detail.visit_status_name,
            serviceId: detail.client_service_visit_id,
            run_view_id: detail.run_view_id,
            actual_visit_end_time: detail.actual_visit_end_time,
            actual_visit_start_time: detail.actual_visit_start_time,
            actual_visit_duration: detail.actual_visit_duration,
            schedule_duration: sch_duration_cal && sch_duration_cal.toFixed(2),
            // gender: genderName,
            visit_priority:  visitPriority,
            clientView: true,
            priority_icon: (detail.visit_priority == '8575958b-a470-4cae-9c7c-faa8daa75da1'
              || detail.visit_priority == '99b04f38-0a30-4b40-9b24-d6cad1837c29'
              || detail.visit_priority == '0e3c36c0-a033-4284-b908-9ecc8a1435a4') ? High_PRIORITY
              : (detail.visit_priority == '5ffb1daf-e149-4a2b-bad6-c3a7e46cd646'
                || detail.visit_priority == 'c3cf46a4-fd8c-42b9-b7b0-86dbfe55b165')
                ? LOW_PRIORITY : MEDIUM_PRIORITY,
            address: detail.address,
            zipcode: detail.zip_code,
            visit_type: detail.visit_type_name,
            service_category: detail.service_category,
            service_category_type: capitalizeText(detail.service_category_type),
            isLocation: scheduler.is_location,
            // earliest_start_time: detail.earliest_start_time,
            // latest_start_time: detail.latest_start_time,
            run_view_name: runViewName,
            doubleUp: !!detail.doubleUp,
            visit: true,
            contact_mobile: detail.contact_mobile,
            imp_mobile: detail.imp_mobile,
            commissioned_hours_per_week : scheduler.commissioned_hours_per_week ?? 0,
            client_preferred_employees : scheduler.client_preferred_employees || "",
          });
        }
        else if (view === 'team') {
          let resData = employeeVisitData && employeeVisitData.find(det => det.employee_id === detail.employee_id)
          let checkDesire = 0;
          if (resData && (resData.remaining_desired_hrs < 0)) {
            checkDesire = true;
          } else {
            checkDesire = false;
          }

          // let visit_duration = ((new Date(detail.end_date)).getTime() - (new Date(detail.start_date)).getTime()) / 3600000;
          // let travel_duration = ((new Date(detail.end_travel_time)).getTime() - (new Date(detail.start_travel_time)).getTime()) / 3600000;

          // visit_tooltip_data = visit_tooltip_data.map((item) => {
          //   if (item.employee_id == detail.employee_id) {
          //     item.total_visit_hours = item.total_visit_hours + visit_duration;
          //     item.schedule_travel_time = item.schedule_travel_time + travel_duration;
          //     item.actual_travel_miles = item.actual_travel_miles + (detail.schedule_travel_miles ? detail.schedule_travel_miles : 0);
          //     item.no_visits = item.no_visits + 1
          //   }
          //   return item;
          // })


          data.push({
            id: detail.id,
            resourceId: detail.employee_id ? detail.employee_id : "0000",
            typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            isRegular: detail.is_pinned_employee,
            assigned_by: detail.assigned_by ? detail.assigned_by : '',
            is_published: detail.is_published,
            unassigned: detail.employee_id ? false : true,
            title: scheduler.full_name ? scheduler.full_name : "Unassigned",
            backgroundColor: visitStatusColourTeam(detail, checkDesire, !!detail.doubleUp),
            borderColor: visitStatusColourTeam(detail, checkDesire, !!detail.doubleUp),
            textColor: detail.employee_id ? GRAY_820 : WHITE,
            start: detail.start_date,
            end: detail.end_date,
            client: scheduler.full_name,
            clientId: scheduler.id,
            employeeId: detail.employee_id,
            client_group_area: client_gp_area_str,
            employee_group_area: emp_gp_area_str,
            serviceId: detail.client_service_visit_id,
            employee_name: detail.employee_fullname,
            visit_status: detail.visit_status_name,
            actual_visit_end_time: detail.actual_visit_end_time,
            actual_visit_start_time: detail.actual_visit_start_time,
            actual_visit_duration: detail.actual_visit_duration,
            schedule_duration: sch_duration_cal && sch_duration_cal.toFixed(2),
            run_view_id: detail.run_view_id,
            visit: true,
            icon: Home_ICON,
            address: detail.address,
            zipcode: detail.zip_code,
            visit_type: detail.visit_type_name,
            orderEvent: 3,
            service_category: detail.service_category,
            service_category_type: capitalizeText(detail.service_category_type),
            isLocation: scheduler.is_location,
            gender: genderName,
            priority_icon: (detail.visit_priority == '8575958b-a470-4cae-9c7c-faa8daa75da1'
              || detail.visit_priority == '99b04f38-0a30-4b40-9b24-d6cad1837c29'
              || detail.visit_priority == '0e3c36c0-a033-4284-b908-9ecc8a1435a4') ? High_PRIORITY
              : (detail.visit_priority == '5ffb1daf-e149-4a2b-bad6-c3a7e46cd646'
                || detail.visit_priority == 'c3cf46a4-fd8c-42b9-b7b0-86dbfe55b165')
                ? LOW_PRIORITY : MEDIUM_PRIORITY,
            earliest_start_time: detail.earliest_start_time,
            latest_start_time: detail.latest_start_time,
            start_travel_time: detail.start_travel_time,
            end_travel_time: detail.end_travel_time,
            run_view_name: runViewName,
            doubleUp: !!detail.doubleUp,
            contact_mobile: detail.contact_mobile,
            imp_mobile: detail.imp_mobile,
            commissioned_hours_per_week : scheduler.commissioned_hours_per_week ?? 0,
            client_preferred_employees : scheduler.client_preferred_employees || "",
          });

          if (detail.actual_visit_start_time && detail.actual_visit_end_time) {
            data.push({
              id: detail.id,
              resourceId: detail.employee_id ? detail.employee_id : "0000",
              isRegular: detail.is_pinned_employee,
              assigned_by: detail.assigned_by ? detail.assigned_by : '',
              is_published: detail.is_published,
              title: scheduler.full_name ? scheduler.full_name : "Unassigned",
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              textColor: 'white',
              start: detail.actual_visit_start_time,
              end: detail.actual_visit_end_time,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              client_group_area: client_gp_area_str,
              employee_group_area: emp_gp_area_str,
              serviceId: detail.client_service_visit_id,
              employee_name: detail.employee_fullname,
              visit_status: detail.visit_status_name,
              actual_visit_end_time: detail.actual_visit_end_time,
              actual_visit_start_time: detail.actual_visit_start_time,
              actual_visit_duration: detail.actual_visit_duration,
              schedule_duration: sch_duration_cal && sch_duration_cal.toFixed(2),
              run_view_id: detail.run_view_id,
              address: detail.address,
              zipcode: detail.zip_code,
              visit_type: detail.visit_type_name,
              service_category: detail.service_category,
              service_category_type: capitalizeText(detail.service_category_type),
              gender: genderName,
              earliest_start_time: detail.earliest_start_time,
              latest_start_time: detail.latest_start_time,
              start_travel_time: detail.start_travel_time,
              end_travel_time: detail.end_travel_time,
              run_view_name: runViewName,
              doubleUp: !!detail.doubleUp,
              orderEvent: 5,
              clocked: true,
              contact_mobile: detail.contact_mobile,
              imp_mobile: detail.imp_mobile,
              commissioned_hours_per_week : scheduler.commissioned_hours_per_week ?? 0,
            client_preferred_employees : scheduler.client_preferred_employees || "",
            });
          }
          if (detail.employee_id && detail.start_travel_time !== detail.end_travel_time) {
            travel_data.push({
              id: detail.id,
              resourceId: detail.employee_id,
              typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: scheduler.full_name,
              backgroundColor: TRAVEL_TIME_COLOR,
              borderColor: TRAVEL_TIME_COLOR,
              textColor: GRAY_820,
              start: detail.start_travel_time,
              end: detail.end_travel_time,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              serviceId: detail.client_service_visit_id,
              openModal: true,
              traveltime: true,
              visitTravel: true,
              travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
              orderEvent: 2,
              travel_mode_name: detail.travel_mode,
              travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
                : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON
            });
          }
          if (detail.employee_id && detail.tolerance_durations && detail.tolerance_durations >= 60) {
            let toleranceEnd = detail.end_travel_time && moment(detail.end_travel_time.split("T")[0] + " " + detail.end_travel_time.split("T")[1].split(".")[0], 'YYYY-MM-DD HH:mm:ss').add(detail.tolerance_durations, 'seconds')
              .format('YYYY-MM-DDTHH:mm:ss');
            travel_data.push({
              id: detail.id,
              resourceId: detail.employee_id,
              typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: scheduler.full_name,

              backgroundColor: '#FFFF00',
              borderColor: '#FFFF00',
              textColor: GRAY_820,
              start: detail.end_travel_time,
              end: toleranceEnd,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              serviceId: detail.client_service_visit_id,
              openModal: true,
              traveltime: true,
              visitTravel: true,
              travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
              orderEvent: 4,
              travel_mode_name: detail.travel_mode,
              travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
                : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON,
              typeVisit: "run",
              tolerance: true
            });
          }
        }
        else if (view === 'location' && scheduler.is_location) {
          locationData.push({
            id: detail.id,
            resourceId: scheduler.id,
            typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            isRegular: detail.is_pinned_employee,
            assigned_by: detail.assigned_by ? detail.assigned_by : '',
            is_published: detail.is_published,
            title: detail.employee_id
              ? detail.employee_fullname
              : detail.is_visit_cancelled
                ? 'Cancelled'
                : 'Unassigned',
            unassigned: detail.employee_id ? false : true,
            backgroundColor: visitStatusColourClient(detail, !!detail.doubleUp),
            borderColor: visitStatusColourClient(detail, !!detail.doubleUp),
            textColor: detail.employee_id ? GRAY_820 : WHITE,
            constraint: {
              resourceIds: [scheduler.id],
            },
            start: detail.start_date,
            end: detail.end_date,
            client_group_area: client_gp_area_str,
            employee_group_area: emp_gp_area_str,
            client: scheduler.full_name,
            clientId: scheduler.id && scheduler.id,
            employeeId: detail.employee_id,
            employee_name: detail.employee_fullname,
            visit_status: detail.visit_status_name,
            serviceId: detail.client_service_visit_id,
            run_view_id: detail.run_view_id,
            actual_visit_end_time: detail.actual_visit_end_time,
            actual_visit_start_time: detail.actual_visit_start_time,
            actual_visit_duration: detail.actual_visit_duration,
            schedule_duration: sch_duration_cal && sch_duration_cal.toFixed(2),
            gender: genderName,
            visit_priority: visitPriority,
            clientView: true,
            priority_icon: (detail.visit_priority == '8575958b-a470-4cae-9c7c-faa8daa75da1'
              || detail.visit_priority == '99b04f38-0a30-4b40-9b24-d6cad1837c29'
              || detail.visit_priority == '0e3c36c0-a033-4284-b908-9ecc8a1435a4') ? High_PRIORITY
              : (detail.visit_priority == '5ffb1daf-e149-4a2b-bad6-c3a7e46cd646'
                || detail.visit_priority == 'c3cf46a4-fd8c-42b9-b7b0-86dbfe55b165')
                ? LOW_PRIORITY : MEDIUM_PRIORITY,
            address: detail.address,
            zipcode: detail.zip_code,
            visit_type: detail.visit_type_name,
            service_category: detail.service_category,
            service_category_type: capitalizeText(detail.service_category_type),
            isLocation: scheduler.is_location,
            earliest_start_time: detail.earliest_start_time,
            latest_start_time: detail.latest_start_time,
            run_view_name: runViewName,
            doubleUp: !!detail.doubleUp,
            visit: true
          });
        }
        else {

          //  if (detail.is_regular_visit) {
          let runviewid = detail.is_regular_visit
            ? detail.run_view_id ? detail.run_view_id : "001"
            : "000"
          if (runViewData[runviewid] == null) {
            runViewData[runviewid] = [];
          }
          if (detail.start_travel_time != detail.end_travel_time) {
            runViewData[runviewid].push({ start: detail.start_travel_time, end: detail.end_travel_time });
          }
          runViewData[runviewid].push({ start: detail.start_date, end: detail.end_date });
          data.push({
            id: detail.id,
            resourceId: detail.is_regular_visit ?
              detail.run_view_id ? detail.run_view_id : "000" : null,
            typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
            isRegular: detail.is_pinned_employee,
            unassigned: detail.employee_id ? false : true,
            title: (scheduler.full_name ? scheduler.full_name : "") + " " + (detail.employee_id ? detail.employee_fullname : "Unassigned"),
            backgroundColor: detail.employee_id ?
              (detail.is_pinned_employee) ? '#00FF7F' : SCHEDULER_ASSIGNED
              : SCHEDULER_UNASSIGNED,
            borderColor: detail.employee_id ?
              (detail.is_pinned_employee) ? '#00FF7F' : SCHEDULER_ASSIGNED
              : SCHEDULER_UNASSIGNED,
            textColor: detail.employee_id ? GRAY_820 : WHITE,
            start: detail.start_date,
            end: detail.end_date,
            client_group_area: client_gp_area_str,
            employee_group_area: emp_gp_area_str,
            client: scheduler.full_name,
            clientId: scheduler.id,
            employeeId: detail.employee_id,
            employee_name: detail.employee_fullname,
            serviceId: detail.client_service_visit_id,
            run_view_id: detail.run_view_id,
            visit: true,
            typeVisit: "run",
            earliest_start_time: detail.earliest_start_time,
            latest_start_time: detail.latest_start_time,
            visit_status: detail.visit_status_name,
            actual_visit_end_time: detail.actual_visit_end_time,
            actual_visit_start_time: detail.actual_visit_start_time,
            actual_visit_duration: detail.actual_visit_duration,
            schedule_duration: sch_duration_cal && sch_duration_cal.toFixed(2),
            service_category_type: capitalizeText(detail.service_category_type),
            isLocation: scheduler.is_location,
            service_category: detail.service_category,
          });
          if (detail.employee_id && detail.start_travel_time !== detail.end_travel_time) {
            travel_data.push({
              id: detail.id,
              resourceId: detail.is_regular_visit ? detail.run_view_id ? detail.run_view_id : "000" : null,
              typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: (scheduler.full_name ? scheduler.full_name : "") + " " + (detail.employee_id ? detail.employee_fullname : "Unassigned"),
              backgroundColor: TRAVEL_TIME_COLOR,
              borderColor: TRAVEL_TIME_COLOR,
              textColor: GRAY_820,
              start: detail.start_travel_time,
              end: detail.end_travel_time,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              serviceId: detail.client_service_visit_id,
              openModal: true,
              traveltime: true,
              orderEvent:2,
              travel_mode_name: detail.travel_mode,
              travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
              travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
                : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON,

              typeVisit: "run"
            });
          }
          if (detail.employee_id && detail.tolerance_durations && detail.tolerance_durations >= 60) {
            let toleranceEnd = moment(detail.end_travel_time.split("T")[0] + " " + detail.end_travel_time.split("T")[1].split(".")[0], 'YYYY-MM-DD HH:mm:ss').add(detail.tolerance_durations, 'seconds')
              .format('YYYY-MM-DDTHH:mm:ss');

            travel_data.push({
              id: detail.id,
              resourceId: detail.is_regular_visit ? detail.run_view_id ? detail.run_view_id : "000" : null,
              typeEvent: detail.employee_id ? TYPE_AVAILABLE : TYPE_UNAVAILABLE,
              title: (scheduler.full_name ? scheduler.full_name : "") + " " + (detail.employee_id ? detail.employee_fullname : "Unassigned"),
              backgroundColor: '#FFFF00',
              borderColor: '#FFFF00',
              textColor: GRAY_820,
              start: detail.end_travel_time,
              end: toleranceEnd,
              client: scheduler.full_name,
              clientId: scheduler.id,
              employeeId: detail.employee_id,
              serviceId: detail.client_service_visit_id,
              openModal: true,
              traveltime: true,
              travel_mode_name: detail.travel_mode,
              travel_code: detail.travel_code && detail.travel_code.toUpperCase(),
              travelMode: detail.travel_mode === 'Walk' ? FOOT_ICON
                : (detail.travel_mode === 'Scooter' || detail.travel_mode === 'Motorbike' || detail.travel_mode === 'Bike') ? BIKE_ICON : CAR_ICON,
              typeVisit: "run",
              orderEvent:4,
              tolerance: true
            });
          }
          //  }
        }

      });
    });
    
 


    let final_data = (view === "client") ? [...data] :
      (view === 'location') ? [...locationData] :
        [...data, ...travel_data, ...final_wait_data, ...shift_data, ...locationData]
    let wait_time_temp = [];
    // debugger
    if (view === 'team') {

      let temp_filter_data = data ? data.filter((item) => item.employeeId) : [];
      let temp_obj = {}

      temp_filter_data.forEach((item) => {
        if (temp_obj[item.resourceId]) {
          temp_obj[item.resourceId].push({ ...item })
        } else {
          temp_obj[item.resourceId] = [{ ...item }]
        }
      })
    
      let blank_emp = []
      // let emp_count = 0;
      allEmployeeData.forEach((val) => {
        let checkEmp = final_data.findIndex((item) => item.resourceId == val.id)
        // let checkEmpCount = final_data.findIndex((item) => ((item.resourceId == val.id) && item.visit))
        if ((checkEmp == -1)) {
          blank_emp.push({
            resourceId: val.id,
            title: '',
            backgroundColor: 'rgba(54, 25, 25, .00004)',
            borderColor: 'rgba(54, 25, 25, .00004)',
            start: values.start_date,
            //end: moment(values.start_date).add(60, 'seconds'),                   
            allDay: true,
            blank: true
          })
        }
        // if (!(checkEmpCount == -1)) {
        //   emp_count = emp_count + 1
        // }
      })

      // setEmployeeResource(emp_count);
      if (values.employees && (values.employees.length > 0)) {
        let add_filtered_emp = []
        values.employees.forEach((val) => {
          let checkEmpBlank = blank_emp.findIndex((item) => item.resourceId == val.id)
          let check_val = filterExcludeCheck.employee ? (checkEmpBlank == -1) : !(checkEmpBlank == -1);
          if (check_val) {
            add_filtered_emp.push({
              resourceId: val.id,
              title: '',
              backgroundColor: 'rgba(54, 25, 25, .00004)',
              borderColor: 'rgba(54, 25, 25, .00004)',
              start: values.start_date,
              //end: moment(values.start_date).add(60, 'seconds'),                   
              allDay: true,
              blank: true
            })
          }
        })
        final_data = [...final_data, ...wait_time_temp, ...add_filtered_emp]
      } else {
        final_data = [...final_data, ...wait_time_temp, ...blank_emp]
      }
    }
    // setEmpData(allEmployeeData)
    // updateEmployeeResource(allEmployeeData)
    // setBlankResource(blank_resource)
    setEmployeeVisitData(visit_tooltip_data)
    console.log(final_data,rota_shift_data,"final_data")
    setEventsSchedular([...new Set(final_data.flat())])
    // setTotalEvents([...new Set(final_data.flat())])
    // setClientHoverData(client_hover_data ?? {})
    // setLoading(false)
    
        setTimeout(() => setLoading(false), 3000)
    // myLoading= false
    console.log(new Date(),loading,"loading")
  }

  const searchScheduler = async () => {
    if (!loading) {
      setLoading(true)
    }
    setEventsSchedular([]);
    TOTAL_VISITS = 0;

    //schedular api
    const response = await getScheduler({ ...branchData, ...values }, filterExcludeCheck, 800)
    setScheduledData(response.data)
    VISITS_ASSIGNED = 0;
    VISITS_UNASSIGNED = 0;

    const rota_res = await getSchedulerRotaShifts({ ...branchData, ...values }, filterExcludeCheck, 800)
    setRotashiftData(rota_res.data)
    setRotaShiftScreenData(rota_res.shiftScreenData)
    setloadingCount(prev => prev +1)
    //updateSchData(response, rota_res, 'team')

    let data = calculateTotalCounts(response?.visitScreenData, rota_res?.shiftScreenData)
    setVisitScreenData(data)
    setloadingCount(prev => prev +1)
  };

  const handleShowHideWait = (showWait, type) => {
    if (showWait) {
      if (type == "planned"){
        plannedScreenData.totalWaitHours = 0;
        setPlannedScreenData({...plannedScreenData});
        setWaitTimeData(prev => ({ ...prev, planned: [] }))
      }else if (type == "scheduled"){
        setWaitTimeData(prev => ({ ...prev, scheduled: [] }))
        visitScreenData.totalWaitHours = 0;
        setVisitScreenData({...visitScreenData});
      }
      return;
    }
    setLoading(true)
    const payload = { ...branchData, ...values };
    if (type == "planned") {
      payload.planning = selectedId;
    }
    getSchedulerWaitTime(payload, filterExcludeCheck, 800)
      .then(response => {
        let wait_time = [];
        let tooltip = {}
        if (response.data && response.data?.length) {
          response.data.forEach(item => {
            // Tootip data
            tooltip[item?.employee_id] = roundToTwoDecimals(item?.wait_time_hrs) || 0;
            //Wait data
            item.wait_time_list.length && item.wait_time_list.forEach(wait => {
              if (wait.start_date != wait.end_date) {
                let zipcode = (wait.journey_start_zipcode || "") + (wait.journey_end_zipcode ? " - " + wait.journey_end_zipcode : "");
                wait_time.push({
                  resourceId: item?.employee_id,
                  backgroundColor: WAIT_TIME_COLOR,
                  borderColor: WAIT_TIME_COLOR,
                  title: zipcode || '',
                  textColor: GRAY_820,
                  start: wait.start_date,
                  end: wait.end_date,
                  openModal: true,
                  waittime: true,
                });
              }
            })
          })
        }
        const totalWaitHrs = response.screen_data ? Number(response.screen_data).toFixed(2) : 0;
        if (type == "planned"){
          setWaitTimeData(prev => ({ ...prev, planned: wait_time }));
          plannedScreenData.totalWaitHours = totalWaitHrs;
          setPlannedScreenData({...plannedScreenData});
          setWaitTooltip(prev => ({ ...prev, planned: tooltip }));
        } else if (type == "scheduled"){
          setWaitTimeData(prev => ({ ...prev, scheduled: wait_time }));
          visitScreenData.totalWaitHours = totalWaitHrs;
          setVisitScreenData({...visitScreenData});
          setWaitTooltip(prev => ({ ...prev, scheduled: tooltip }));
        }
        setLoading(false)
        console.log(wait_time ,"wait_time responce")
      }).catch(error => {
        setLoading(false)
        console.error("Error: error fetching wait time in scheduler", error)
      })
  }
  const backToSch = () => {
    history.push({
      pathname: `/scheduler`,
      search: `?start=${query_params_data.start}&end=${query_params_data.end}`
      // state: {}
    })
  };
  const dropDownHandleSelect = (item) => {
    setValues({ ...values, ...item });
  };

  const setClientfilter = (item) => {
    setClientsFilter([...item])
  }
  const setEmloyeefilter = (item) => {
    setEmployeesFilter([...item])
  }
  const setRunroutefilter = (item) => {
    setRunRoutesFilter([...item])
  }
  const setRunrouteclientfilter = (item) => {
    setRunRoutesclientFilter([...item])
  }
  const setJobdeptfilter = (item) => {
    setJobDepartmentFilter([...item])
  }

  const setFilterBranch = (item) => {
    setBranchFilter([...item])
  }

  const setFilterBranchRun = (item) => {
    setBranchRunSchedularFilter([...item])
  }

  const setVisitStatusfilter = (item) => {
    setVisitStatusFilter([...item])
  }
  const handleRunNameFilter = (item) => {
    setRunNameFilter([...item])
  }

  const handleService = (item) => {
    setServiceFilter([...item])
  }


  const totalSchduledEvents = showWait.scheduled ?
    [...eventsSchedular, ...waitTimeData.scheduled] :
    [...eventsSchedular];
  const totalPlannedEvents = showWait.planned ?
    [...eventsSchedular, ...waitTimeData.planned] :
    [...eventsPlanned];

  console.log('planner', eventsPlanned, eventsSchedular, plannedVisitScreenData)
 
  return (
    <>
     {
        (loading) &&
        <Spinner
          spinnerTop={"70%"}
          textTop={'68%'}
        />
      }
      <SchedulerContainer loading={loading}>
        <div className='d-flex justify-content-between'>
          <Title>Comparison Calendars</Title>
          <div className='my-2'>
            <MdKeyboardArrowLeft
              style={{
                fontSize: '2rem',
                color: PURPLE,
                cursor: 'pointer'
              }}
              onClick={() => backToSch()} />
            <span>Back to schedular</span>
          </div>
        </div>
        {!decreaseWidthPlan && !decreaseWidthSch &&
        <div className="col-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center justify-content-md-start px-0" style={{ flexWrap: 'wrap' }}>
          <FiltersContainerRotaShifts>
            <FiltersLeftGroup
              className={'d-flex justify-content-center justify-content-md-start mb-2'} style={{ flexWrap: 'wrap' }}>
              <FiltersTypesContainerRotaShifts className="me-2">
                <CustomDropdown
                  items={employeeForFilter ? employeeForFilter : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ employees: items });
                  }}
                  itemsSelected={employeesFilter}
                  setSelectedFilter={setEmloyeefilter}
                  placeHolder={'Employees'}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}
                  exclude={{
                    name: 'employee',
                    checked: filterExcludeCheck.employee || false,
                    handleChangeFilterCheckbox: handleChangeFilterCheckbox
                  }}
                />
              </FiltersTypesContainerRotaShifts>
              <FiltersTypesContainerRotaShifts className="me-2">
                <CustomDropdown
                  items={clients ? clients : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ client: items });
                  }}
                  itemsSelected={clientsFilter}
                  setSelectedFilter={setClientfilter}
                  placeHolder={'Clients'}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}
                  exclude={{
                    name: 'client',
                    checked: filterExcludeCheck.client || false,
                    handleChangeFilterCheckbox: handleChangeFilterCheckbox
                  }}
                // filterExcludeCheck={filterExcludeCheck.client || false}
                // handleChangeFilterCheckbox={handleChangeFilterCheckbox}
                />
              </FiltersTypesContainerRotaShifts>

              <FiltersTypesContainerRotaShifts className="me-2">

                <CustomDropdown
                  items={runRouteData ? runRouteData : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ runroutesclient: items });
                  }}
                  itemsSelected={runRoutesclientFilter}
                  setSelectedFilter={setRunrouteclientfilter}
                  placeHolder={'Client Group Area'}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}

                />

              </FiltersTypesContainerRotaShifts>

              <FiltersTypesContainerRotaShifts className="me-2">

                <CustomDropdown
                  items={runRouteData ? runRouteData : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ runroutesteam: items });
                  }}
                  itemsSelected={runRoutesFilter}
                  setSelectedFilter={setRunroutefilter}
                  placeHolder={'Team Group Area'}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}

                />

              </FiltersTypesContainerRotaShifts>

              <FiltersTypesContainerRotaShifts className="me-2">
                <CustomDropdown
                  items={jobDepartment ? jobDepartment : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ jobDepartment: items });
                  }}
                  itemsSelected={jobDepartmentFilter}
                  setSelectedFilter={setJobdeptfilter}
                  placeHolder={' Job Department '}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}
                />
              </FiltersTypesContainerRotaShifts>
              <FiltersTypesContainerRotaShifts className="me-2">
                <CustomDropdown
                  items={branch ? branch : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ branch: items });
                  }}
                  itemsSelected={branchFilter}
                  setSelectedFilter={setFilterBranch}
                  placeHolder={'Branch'}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}
                />
              </FiltersTypesContainerRotaShifts>


              <FiltersTypesContainerRotaShifts className="me-2">
                <CustomDropdown
                  items={visitStatusData ? visitStatusData : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ visitStatus: items });
                  }}
                  itemsSelected={visitStatusFilter}
                  setSelectedFilter={setVisitStatusfilter}
                  placeHolder={'Visit Status'}
                  isObject
                  allOption
                  hitApiAfterClear={hitApiAfterClear}
                />
              </FiltersTypesContainerRotaShifts>

              <FiltersTypesContainerRotaShifts className="me-2">
                <CustomDropdown
                  items={runNameData ? runNameData : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ run_view_name: items });
                  }}
                  itemsSelected={runNameFilter}
                  setSelectedFilter={handleRunNameFilter}
                  placeHolder={'Run Name'}
                  isObject
                  allOption
                />
              </FiltersTypesContainerRotaShifts>
              <FiltersTypesContainerRotaShifts className="me-2">
                <CustomDropdown
                  items={contextChoices.service_category || []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ service_category: items });
                  }}
                  itemsSelected={serviceFilter}
                  setSelectedFilter={handleService}
                  placeHolder={'Service Category'}
                  isObject
                  allOption
                />
              </FiltersTypesContainerRotaShifts>
              <PrimaryButtonForm minWidth="6rem" className={'me-1'} onClick={() => {
                activeEmployeeResourceFn(empData)
                searchScheduler()
              }}
              >
                <span className={'me-1'}>Search</span>
              </PrimaryButtonForm>
            </FiltersLeftGroup>
          </FiltersContainerRotaShifts>

        </div>} 
        <PlannerCalendar
          handleShowHideWait={handleShowHideWait}
          showWait={showWait}
          setShowWait={setShowWait}
          waitTooltip={waitTooltip}
          plannedVisitScreenData={plannedVisitScreenData}
          resourceAreaHeaderContent="Team"
          resources={[...resourcesSchedular]}
          events={totalSchduledEvents}
          eventsPlanned={totalPlannedEvents}
          setEventsSchedular={setEventsSchedular}
          setPlannedEvents={setPlannedEvents}
          setShiftType={setShiftType}
          shiftType={shiftType}
          values={values}
          employeeVisitData={employeeVisitData || []}
          employeeVisitDataPlanned={employeeVisitDataPlanned || []}
          setValues={setValues}
          eventClickHandler={data => {
          }}
          useInput={useInput}
          employeeResource={employeeResource}
          employeeResourcePlanned={employeeResourcePlanned}
          setDecreaseWidthSch={setDecreaseWidthSch}
          decreaseWidthSch={decreaseWidthSch}
          setDecreaseWidthPlan={setDecreaseWidthPlan}
          decreaseWidthPlan={decreaseWidthPlan}
          // waitHoursBetweenVisit={waitHoursBetweenVisit}
          // waitHoursExcludeMoreThanTwo={waitHoursExcludeMoreThanTwo}
          // waitHoursCapacity={waitHoursCapacity}
          // waitHoursBetweenVisitPlanned={waitHoursBetweenVisitPlanned}
          // waitHoursExcludeMoreThanTwoPlanned={waitHoursExcludeMoreThanTwoPlanned}
          // waitHoursCapacityPlanned={waitHoursCapacityPlanned}
          visitScreenData={visitScreenData}
        />
      </SchedulerContainer>
    </>
  );
}

export default Planner;
