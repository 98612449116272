import React, { useState, useEffect } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import LineContainer from 'components/SharedComponents/LineContainer';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import DateSelector from 'components/SharedComponents/DateSelector';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import DurationInput from 'components/SharedComponents/DurationInput';
import { KeyPress } from 'utils/constants/regularExpressions';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import RateCard from 'components/DashboardComponents/Client/AddModal/RateCard';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  IconWrapper,
  ModalContainer,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import { SOFT_RED } from 'shared/styles/constants/colors';
import { DELETE, COPY } from 'utils/constants/icons';
import ServiceEmployees from './ServiceEmployees';
import { SkillAdded } from 'utils/constants/messages';
import { WeekStyle, WeekContainer, ButtonWeek } from './Styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Fragment } from 'react';
import { InvalidRange } from 'utils/constants/messages';
import { validateDate } from 'shared/methods';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
} from 'utils/constants/serviceVisits';
import {
  deleteServiceVisitEmployeeRequirement,
  saveSchedulerVisit,
  shouldRemoveVisitEmployeeApi,
} from 'utils/api/ClientApi';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import AddMedication from './AddMedication';
import AddPlannedTask from 'components/SharedComponents/FormSections/PlannedtaskForms/AddPlannedTask';
import { getMedicationPrescription } from 'utils/api/SettingsApi';
import Spinner from 'components/SharedComponents/Spinner';
const ServiceVisit = ({
  useInput,
  nameSection,
  choices,
  values,
  serviceId,
  clientId,
  dropDownHandleVisits = () => { },
  index,
  dataVisit,
  addVisitCopy,
  handleDeleteVisit = () => { },
  toDeleteVisitSkill,
  toDeleteVisitEmployee,
  setValues = () => { },
  setToDeleteVisitSkill = () => { },
  setToDeleteVisitEmployee = () => { },
  errors,
  readOnly = false,
  setDateError = () => { },
  dateError = [],
  setTimeError = () => { },
  timeError = [],
  deletePermission,
  subSectionServiceContractDelete,
  billRate,
  preferredSkills,
  preferredCompetency,
  payRate,
  clientTaskOptions,
  groupTaskOptions,
  plannedTasks,
  editMode,
  earlieErrors = [],
  setShowEarlieError,
  setEarlieMessage,
  earlieMessage,
  setEarlieError = () => { },
  showEarlieError,
  travelRate,
  visitRender,
  medDoseTimingList,
  setFormErrors,
  editTypeSelected,
  templateContract = false,
  categoryOptions = '',
  loading = false,
}) => {
  const toast = useRef(null)
  const myInputHook = useInput
  const [skillAddedMessage, setSkillAddedMessage] = useState('');
  const [daysAdded, setDaysAdded] = useState([]);
  const [isFullDay, setIsFullDay] = useState(false);
  const [isOverNight, setIsOverNight] = useState(false);
  const [daysOfWeek, setDaysOfWeek] = useState([
    { day: 'MON', selected: false },
    { day: 'TUE', selected: false },
    { day: 'WED', selected: false },
    { day: 'THU', selected: false },
    { day: 'FRI', selected: false },
    { day: 'SAT', selected: false },
    { day: 'SUN', selected: false },
  ]);
  const [timeSelected, setTimeSelected] = useState('');
  const [dateErrorMessage, setErrorMessage] = useState('');
  const [timeErrorMessage, setTimeErrorMessage] = useState('');
  const [timeEarliestMessage, setEarliestMessage] = useState('');
  const [error, setError] = useState(false);
  const [optionNumberData, setOptionNumberData] = useState([]);
  const earliest_start_time = values.visits[index].earliest_start_time;
  const preferred_start_time = values.visits[index].preferred_start_time;
  const preferred_end_time = values.visits[index].preferred_end_time;
  const start_date = values.visits[index].start_date;
  const end_date = values.visits[index].end_date;
  const is_overnight = values.visits[index].is_overnight;
  const [loadingCount, setLoadingCount] = useState(0);
  const { setValue: setModalVisitDeleteValue, bind: bindModalVisitDelete } =
    useModal(false);

  useEffect(() => {
    if (values.visits[index].full_day) {
      setIsFullDay(true);
    }
    numberDropdown();
  }, []);

  useEffect(() => {
    setValues({ ...values });
    setToDeleteVisitSkill([]);
    setSkillAddedMessage('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataVisit.id, values.visits[index].skills_required]);

  // calculate duration
  useEffect(() => {
    if (
      values.visits[index].preferred_start_time &&
      values.visits[index].preferred_end_time
    ) {
      let start_time = moment(
        values.visits[index].preferred_start_time,
        'h:mma'
      );
      let end_time = moment(values.visits[index].preferred_end_time, 'h:mma');

      if (end_time > start_time) {
        values.visits[index].duration = end_time.diff(start_time, 'seconds');
      } else {
        let add_date = end_time.add(1, 'days');
        values.visits[index].duration = add_date.diff(start_time, 'seconds');
      }
    } else {
      values.visits[index].duration = 0;
    }
    setValues({ ...values });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferred_start_time, preferred_end_time, is_overnight]);

  const handleClick = timeSelected => {
    setTimeSelected(timeSelected);
  };
  const handelFullDay = () => {
    setIsFullDay(prevState => !prevState);
    setIsOverNight(false)
    values.visits[index].is_overnight = false
    setValues({...values})
  };
  const handelOverNight = () => {
    setIsOverNight(prevState => !prevState);
    setIsFullDay(false)
    values.visits[index].full_day = false
    setValues({...values})
  };
  const toggleDeleteModal = () => {
    setModalVisitDeleteValue(true);
  };
  // format duration from string to seconds

  useEffect(() => {
    if (
      values.visits[index].duration &&
      typeof values.visits[index].duration === 'string'
    ) {
      const res = values.visits[index].duration
        .split(':')
        .reduce((acc, time) => 60 * acc + +time);

      values.visits[index].duration = res;
      setValues({ ...values });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.visits[index].duration]);

  // format from string to int
  useEffect(() => {
    let repeat = values.visits[index].repeat;
    repeat = parseInt(repeat);
    values.visits[index].repeat = repeat;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.visits[index].repeat]);

  // format from string to int
  useEffect(() => {
    //  if end type is END_TYPE_AFTER then format end_val;ue
    if (values.visits[index].end_type === END_TYPE_AFTER) {
      let end_value = values.visits[index].end_value;
      end_value = parseInt(end_value);
      values.visits[index].end_value = end_value;
    }
    setValues({ ...values });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.visits[index].end_value]);
  useEffect(() => {
    if (!values.visits[index].bill_rate) {
      values.visits[index].bill_rate = [];
      setValues({ ...values });
    }
    if (!values.visits[index].travel_pay_rate) {
      values.visits[index].travel_pay_rate = [];
      setValues({ ...values });
    }

    const auxArray = [];
    if (values.visits[index].repeat_on) {
      const reviewStringVelue = values.visits[index].repeat_on.split(',');

      reviewStringVelue.forEach(dayValue => {
        daysOfWeek.forEach(dayItem => {
          if (dayItem.day.toString() === dayValue.toString()) {
            dayItem.selected = true;
            auxArray.push(dayValue.toString());
          }
        });
      });
    }

    var addUniqs = auxArray.filter(function (item, indexUniq) {
      return auxArray.indexOf(item) === indexUniq;
    });
    const weekDays = daysOfWeek.map((item) => {
      if (auxArray.findIndex((f) => f === item.day) === -1) {
        item.selected = false;
      } else {
        item.selected = true;
      }
      return item;
    });

    setDaysOfWeek(weekDays);
    setDaysAdded(auxArray);
    console.log('service visit repeat on', auxArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitRender, values.visits[index].repeat_on]);

  useEffect(() => {
    const reviewErrors = dateError;
    if (
      values.visits[index].start_date &&
      typeof values.visits[index].end_date === 'string'
    ) {
      const validateDates = validateDate(true, start_date, end_date);

      if (!validateDates) {
        reviewErrors[index] = true;
        setDateError([...reviewErrors]);
        setErrorMessage(InvalidRange);
      } else {
        reviewErrors[index] = false;
        setDateError([...reviewErrors]);
        setErrorMessage('');
      }
    } else {
      reviewErrors[index] = false;
      setDateError([...reviewErrors]);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date]);
  useEffect(() => {
    const reviewErrors = timeError;
    const earliErrors = earlieErrors;
    if (values.visits[index].is_overnight) {
      const start_time = moment(preferred_start_time, 'h:mma');
      const end_time = moment(preferred_end_time, 'h:mma');
      if (!end_time.isBefore(start_time)) {
        reviewErrors[index] = true;
        setTimeError([...reviewErrors]);
        setTimeErrorMessage(InvalidRange);
      } else {
        reviewErrors[index] = false;
        setTimeError([...reviewErrors]);
        setTimeErrorMessage('');
      }
    } else {
      const start_time = moment(preferred_start_time, 'h:mma');
      const end_time = moment(preferred_end_time, 'h:mma');
      const earliest_time = moment(earliest_start_time, 'h:mma');
      if (end_time.isBefore(start_time)) {
        reviewErrors[index] = true;
        setTimeError([...reviewErrors]); // reviewErrors[index] = false;
        // setTimeError([...reviewErrors]);
        // setTimeErrorMessage('');
        setTimeErrorMessage(InvalidRange);
      } else if (!end_time.isBefore(start_time)) {
        reviewErrors[index] = false;
        setTimeError([...reviewErrors]);
        setTimeErrorMessage('');
      }

      if (start_time.isBefore(earliest_time)) {
        earliErrors[index] = true;
        setEarlieError([...earliErrors]);
        setEarliestMessage(
          'earliest start time should be less than preferred start time'
        );
      } else if (!start_time.isBefore(earliest_time)) {
        earliErrors[index] = false;
        setEarlieError([...earliErrors]);
        setEarliestMessage(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    preferred_start_time,
    preferred_end_time,
    earliest_start_time,
    is_overnight,
  ]);

  const dropDownHandleRate = (
    item,
    nameSection,
    sectionIndex,
    list_key,
    category
  ) => {
    if (list_key === 'service_bill_rate') {
      values[category][index][nameSection][sectionIndex].bill_rate_per_hour =
        billRate.find(rate => rate.id === item.rate).rate_per_hour;
    }

    if (list_key === 'service_travel_pay_rate') {
      values[category][index][nameSection][sectionIndex].travel_pay_rate_per_hour = travelRate.find(rate => rate.id === item.rate)?.rate_per_hour
    }
    values[category][index][nameSection][sectionIndex][list_key] = item.rate;
    setValues({ ...values });
  };
  const dropDownHandlePayRate = (
    item,
    nameSection,
    sectionIndex,
    list_key,
    category,
    empIndex
  ) => {
    if (list_key === 'service_pay_rate' && item.rate) {
      values[category][index]['employee_required'][empIndex][nameSection][
        sectionIndex
      ].pay_rate_per_hour = payRate.find(
        rate => rate.id === item.rate
      ).rate_per_hour;
    }
    values[category][index]['employee_required'][empIndex][nameSection][
      sectionIndex
    ][list_key] = item.rate;
    setValues({ ...values });
  };
  const deleteRate = (nameSection, category, sectionIndex) => {
    setFormErrors([])
    values[category][index][nameSection].splice(sectionIndex, 1);
    setValues({ ...values });
  };
  const deletePayRate = (nameSection, category, sectionIndex, empIndex) => {
    setFormErrors([])
    values[category][index]['employee_required'][empIndex][nameSection].splice(
      sectionIndex,
      1
    );
    setValues({ ...values });
  };
  const addNewRate = (nameSection, emp, indexEmp) => {
    if (emp) {
      if (
        !values['visits'][index]['employee_required'][indexEmp][nameSection]
      ) {
        values['visits'][index]['employee_required'][indexEmp][nameSection] = [
          {},
        ];
        setValues({ ...values });
      } else {
        values['visits'][index]['employee_required'][indexEmp][nameSection] = [
          ...values['visits'][index]['employee_required'][indexEmp][
          nameSection
          ],
          {},
        ];
        setValues({ ...values });
      }
    } else {
      values['visits'][index][nameSection] = [
        ...values['visits'][index][nameSection],
        {},
      ];
      setValues({ ...values });
    }
  };
  console.log(values, "TTTTTTTT")
  const dropDownHandleSkill = (item, indexEmployee) => {
    if (item.skill) {
      const reviewSkills = values.visits[index].employee_required;
      reviewSkills.forEach(employeeElement => {
        if (!employeeElement.skills) {
          employeeElement.skills = [];
        }
      });

      setSkillAddedMessage('');
      values.visits[index].employee_required[indexEmployee].skills = item.skill && item.skill.map((val) => {
        if (!serviceId || (serviceId === '')) {
          delete val.id;
        }
        return val;
      });
      values.visits[index].employee_required[indexEmployee].skillEdit = true;
      setValues({ ...values });
    }
    if (item.competency) {
      const reviewCompetency = values.visits[index].employee_required;
      reviewCompetency.forEach(employeeElement => {
        if (!employeeElement.competency) {
          employeeElement.competency = [];
        }
      });

      // setSkillAddedMessage('');
      values.visits[index].employee_required[indexEmployee].competency =
        item.competency && item.competency.map((val) => {
          if (!serviceId || (serviceId === '')) {
            delete val.id;
          }
          return val;
        });;
      values.visits[index].employee_required[indexEmployee].skillEdit = true;
      setValues({ ...values });
    }
    if (item.specific_groups) {
      const reviewCompetency = values.visits[index].employee_required;
      reviewCompetency.forEach(employeeElement => {
        if (!employeeElement.specific_groups) {
          employeeElement.specific_groups = [];
        }
      });

      // setSkillAddedMessage('');

      values.visits[index].employee_required[indexEmployee].specific_groups =
        item.specific_groups;

      setValues({ ...values });
    }

    if (item.medication) {
      const reviewCompetency = values.visits[index].employee_required;
      reviewCompetency.forEach(employeeElement => {
        if (!employeeElement.medication) {
          employeeElement.medication = [];
        }
      });

      // setSkillAddedMessage('');

      values.visits[index].employee_required[indexEmployee].medication =
        item.medication;

      setValues({ ...values });
    }
    // if(item.task_id){
    //   const reviewCompetency =
    //   values.visits[index].employee_required;
    //   reviewCompetency.forEach(employeeElement => {
    //     if (!employeeElement.task_id) {
    //       employeeElement.task_id = [];
    //     }
    //   });
    //   values.visits[index].employee_required[
    //     indexEmployee
    //   ].task_id = item.task_id;
    //   setValues({...values});
    // }
  };

  const dropDownHandleEmployee = (item, indexEmployee, name) => {
    const reviewEmployees = values.visits[index].employee_required;

    reviewEmployees.forEach(employeeElement => {
      if (!employeeElement.skill_level) {
        employeeElement.skill_level = '';
      }
    });
    if (name === "job_title") {
      values.visits[index].employee_required[indexEmployee].skill_level =
        item.job_title;
    }
    if (name === "preferred_visit_employee") {
      values.visits[index].employee_required[indexEmployee].preferred_visit_employee = item[name]
    }
    setValues({ ...values });
  };

  console.log(values.visits[index].employee_required, "employee_required")
  const dropDownHandleGender = (item, indexGender) => {
    // const reviewEmployees =
    //   values.visits[index].employee_required;

    // reviewEmployees.forEach(employeeElement => {

    //   if (!employeeElement.preferred_gender) {
    //     employeeElement.preferred_gender = '';
    //   }
    // });

    values.visits[index].employee_required[indexGender].preferred_gender =
      item.preferred_gender;

    // values.visits[index].preferred_gender[indexGender] = item.gender;

    setValues({ ...values });
  };

  const dropDownHandleQuantity = (item, indexEmployee) => {
    values.visits[index].employee_required[indexEmployee].no_of_employees =
      item.no_of_employees;
    setValues({ ...values });
  };

  const addNewVisitEmployee = () => {
    if (!values.visits[index].employee_required)
      values.visits[index].employee_required = [];
    if (preferredSkills.length > 0 || preferredCompetency.length > 0) {
      values.visits[index].employee_required.push({
        id: '',
        client_service_visit: dataVisit.id || '',
        skillEdit: true,
        ...(preferredSkills.length > 0 && { skills: [...preferredSkills] }),
        ...(preferredCompetency.length > 0 && {
          competency: [...preferredCompetency],
        }),
      });
    } else {
      values.visits[index].employee_required.push({
        id: '',
        client_service_visit: dataVisit.id || '',
        skillEdit: true,
      });
    }

    setValues({ ...values });
    setSkillAddedMessage('');
  };

  const shouldRemoveVisitEmployee = async (data, indexEmployee, indexVisit, serviceId, clientId) => {
    if (!data.id) {
      deleteVisitEmployee(data, indexEmployee, indexVisit, serviceId, clientId)
      return;
    }
    try {
      const res = await shouldRemoveVisitEmployeeApi(data.id)
      if (res.count === 0 && res.status) {
        deleteVisitEmployee(data, indexEmployee, indexVisit, serviceId, clientId)
      } else if (res.count > 0 && !res.status) {
        const message = `This employee have more than ${res?.count} In-progress/Completed visits, Please revert them or add new employee requirement.`
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${message}`,
        });
      }
    } catch (error) {

    }
  }

  const deleteVisitEmployee = (
    data,
    indexEmployee,
    indexVisit,
    serviceId,
    clientId
  ) => {
    setFormErrors([])
    // if(data.id){
    //   deleteServiceVisitEmployeeRequirement(clientId,serviceId,data.service_visit,data.id)
    //   .then((res)=>{
    //     saveSchedulerVisit(clientId,serviceId,data.service_visit)
    //   })
    //   }
    toDeleteVisitEmployee.push({ ...data });

    if (
      values.visits[indexVisit] &&
      values.visits[indexVisit].employee_required.length > 0
    ) {
      values.visits[indexVisit].employee_required.splice(indexEmployee, 1);
    }
    setValues({ ...values });
    setSkillAddedMessage('');
  };

  const selectedDay = (day, indexWeek) => {
    if (readOnly) {
      return;
    }

    const removeValue = daysAdded.indexOf(day);
    const weekDays = daysOfWeek;
    const addedDays = daysAdded;

    if (removeValue > -1) {
      daysAdded.splice(removeValue, 1);

      weekDays[indexWeek].selected = false;

      values.visits[index].repeat_on = addedDays.join();

      setDaysOfWeek(weekDays);
      setDaysAdded(daysAdded);
      setValues({ ...values });
      return;
    }

    addedDays.push(day);

    var addUniqs = addedDays.filter(function (item, indexUniq) {
      return addedDays.indexOf(item) === indexUniq;
    });

    weekDays[indexWeek].selected = true;

    values.visits[index].repeat_on = addedDays.join();

    setDaysOfWeek(weekDays);
    setDaysAdded(addUniqs);
    setValues({ ...values });
  };

  const handleEndType = value => {
    // clear current end_value value
    values.visits[index].end_value = null;
    setValues({ ...values });
    dropDownHandleVisits({ end_type: value ? value : null }, 'end_type', index);
  };

  const switchEndValueInput = props => {
    // render correct input type depending of the end_type selected
    switch (values.visits[index].end_type) {
      case END_TYPE_ON:
        // check if current value has date format
        const validDate = props.value
          ? moment(props.value, 'YYYY-MM-DD', true).isValid()
          : true;
        // if current value has date format then send props
        const dateProps = validDate ? props : {};
        return (
          <DateSelector
            disabled={!editMode}
            isRequired
            labelTop={'End value'}
            {...dateProps}
          />
        );
      case END_TYPE_AFTER:
        return (
          <InputLabelTop
            disabled={readOnly}
            required
            onKeyPress={values.end_value ? KeyPress.POSITIVE : KeyPress.NATURAL}
            label={'End value'}
            type="text"
            id={`${[nameSection]}_end_value`}
            {...props}
          />
        );
      case END_TYPE_NEVER:
        return <Fragment />;
      default:
        return <Fragment />;
    }
  };

  const calculateEndValueProps = value => {
    switch (value) {
      case END_TYPE_ON:
        return 'isRequired';
      case END_TYPE_AFTER:
        return 'isInt, isRequired';
      default:
        return '';
    }
  };

  const numberDropdown = () => {
    let array = [];
    for (var i = 1; i <= 31; i++) {
      array.push({ id: i, name: i });
    }
    setOptionNumberData(array);
  };

  const addNewMedication = (nameSection, emp, indexEmp) => {
    if (!values['visits'][index][nameSection])
      values['visits'][index][nameSection] = [];
    values['visits'][index][nameSection].push({})
    setValues({ ...values });
  }
  const addNewPlannedTask = (nameSection, emp, indexEmp) => {
    if (!values['visits'][index][nameSection])
      values['visits'][index][nameSection] = [];
    values['visits'][index][nameSection].push({
      tasks: [
        {
          is_essential: false,
          defaultExpand: true,
          support_level: null,
          care_task: null,
          care_category: null,
          task_type: null,
          message: null,
        }]
    });
    setValues({ ...values });
  }

  const deletePlanedTask = (category, visitIndex, nameSection, index) => {
    let error = errors.filter((el => !el.includes(`${category}[${visitIndex}].${nameSection}[${index}]`)))
    setFormErrors(error)
    values[category][visitIndex][nameSection].splice(index, 1)
    setValues({ ...values })
  }
  const dropDownHandlePlanedTask = (value, name, category, visitIndex, nameSection, index) => {
    values[category][visitIndex][nameSection][index][name] = value[name]
    setValues({ ...values })
  }

  console.log(errors, "errors")

  const deleteMedication = (category, visitIndex, nameSection, index) => {
    let error = errors.filter((el => !el.includes(`${category}[${visitIndex}].${nameSection}[${index}]`)))
    setFormErrors(error)
    values[category][visitIndex][nameSection].splice(index, 1)
    setValues({ ...values })
  }

  const getMedicationPrescriptionData = async (doseId, item) => {
    console.log(item, "item")
    if (!clientId || !doseId || !item.effective_from) {
      return { data: []};
    }
    const body = {
      client: clientId,
      medicationDose: doseId,
      serviceStartDate: item.effective_from,
      serviceEndDate: item.effective_to || null
    }
    try {
      const res = await getMedicationPrescription(body)
      return res
    } catch (error) {

    }
  }

  const dropDownHandleMedication = async (value, name, category, visitIndex, nameSection, index) => {
    if (name == "doseTime") {
      const res = await getMedicationPrescriptionData(value[name], values[category][visitIndex][nameSection][index])
        values[category][visitIndex][nameSection][index]["prescriptionData"] = 
        res?.data.length ? res?.data?.map(pres => ({ id: pres.id, name: pres.prescriptionName })): []
       values[category][visitIndex][nameSection][index]["prescriptions"] = null
    
    }

    values[category][visitIndex][nameSection][index][name] = value[name]
    setValues({ ...values })
  }
  // const medicationLength = values?.visits[index]?.medicationLength

  let service_category_hook = useInput(`${[nameSection]}.service_category`, 'isRequired')
  let time_critical_hook = useInput(`${[nameSection]}.time_critical`)
  let notes_hook = useInput(`${[nameSection]}.notes`)
  let start_date_hook = useInput(`${[nameSection]}.start_date`, templateContract ? "" : 'isRequired')
  let end_date_hook = useInput(`${[nameSection]}.end_date`)
  let include_holiday_hook = useInput(`${[nameSection]}.include_holiday`)
  let preferred_start_time_hook = useInput(`${[nameSection]}.preferred_start_time`, !isFullDay && 'isRequired')
  let preferred_end_time_hook = useInput(`${[nameSection]}.preferred_end_time`, !isFullDay && 'isRequired')
  let duration_hook = useInput(`${[nameSection]}.duration`)
  let earliest_start_time_hook = useInput(`${[nameSection]}.earliest_start_time`)
  let latest_start_time_hook = useInput(`${[nameSection]}.latest_start_time`)
  let effective_date_hook = useInput(`${[nameSection]}.effective_date`)
  let full_day_hook = useInput(`${[nameSection]}.full_day`)
  let is_overnight_hook = useInput(`${[nameSection]}.is_overnight`)
  let every_hook = useInput(`${[nameSection]}.every`, 'isRequired')
  let repeat_hook = useInput(`${[nameSection]}.repeat`, 'isInt')
  let end_type_hook = useInput(`${[nameSection]}.end_type`, 'isRequired')
  let end_value_hook = useInput(`${[nameSection]}.end_value`, calculateEndValueProps(values.visits[index].end_type)  )
  
  
  return (
    <div>
       <Toast ref={toast} />
      <>
      {!loading ? 
      <LineContainer className={'ps-2 pe-2 pt-2 pb-4'}>
        {((editTypeSelected !== 'create')) ? (
          <div className={'pt-2'} />
        ) : (
          <div className={'pb-2 d-flex justify-content-end'}>
            <IconWrapper
              disabled={!editMode}
              style={{ marginRight: '8px' }}
              color={SOFT_RED}
              onClick={() => addVisitCopy(index)}>
              {COPY}
            </IconWrapper>
            {(subSectionServiceContractDelete || deletePermission) && (
              <IconWrapper
                disabled={!editMode}
                color={SOFT_RED}
                onClick={() => toggleDeleteModal()}>
                {DELETE}
              </IconWrapper>
            )}
          </div>
        )}

        <FlexGrid3Container className="mb-2">
          <FlexGrid3Item className="pe-1 pb-2">
            <DropdownSearchable
              disabled={!editMode}
              placeHolder={'Client type'}
              options={choices.client_type}
              selectedOption={
                values.visits[index] ? values.visits[index].type : null
              }
              onChange={value =>
                dropDownHandleVisits(
                  { type: value ? value : null },
                  'type',
                  index
                )
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
            <DropdownSearchable
              disabled={!editMode}
              required
              {...service_category_hook}
              placeHolder={'Service category'}
              options={choices.service_category ? (categoryOptions ? choices.service_category.filter((i) => i.category_type == categoryOptions) : choices.service_category) : []}
              selectedOption={
                values.visits[index]
                  ? values.visits[index].service_category
                  : null
              }
              onChange={value =>
                dropDownHandleVisits(
                  { service_category: value ? value : null },
                  'service_category',
                  index
                )
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0 pb-2">
            <DropdownSearchable
              disabled={!editMode}
              placeHolder={'Visit type'}
              options={choices.visit_type}
              selectedOption={
                values.visits[index] ? values.visits[index].visit_type : null
              }
              onChange={value =>
                dropDownHandleVisits(
                  { visit_type: value ? value : null },
                  'visit_type',
                  index
                )
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pe-0 ps-1 ps-md-0 pe-md-1">
            <DropdownSearchable
              disabled={!editMode}
              placeHolder={'Care group'}
              options={choices.care_group}
              selectedOption={
                values.visits[index] ? values.visits[index].care_group : null
              }
              onChange={value =>
                dropDownHandleVisits(
                  { care_group: value ? value : null },
                  'care_group',
                  index
                )
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-0 ps-md-1 pe-1">
            <DropdownSearchable
              disabled={!editMode}
              placeHolder={'Visit priority'}
              options={choices.visit_priority}
              selectedOption={
                values.visits[index]
                  ? values.visits[index].visit_priority
                  : null
              }
              onChange={value =>
                dropDownHandleVisits(
                  { visit_priority: value ? value : null },
                  'visit_priority',
                  index
                )
              }
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="pe-0 ps-1 pt-1 pb-1">
            <ToggleButton
              disabled={!editMode}
              label="Time critical"
              useInput={time_critical_hook}
              id="time_critical"
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
            <DropdownSearchable
              placeHolder={'PPE Category'}
              options={choices.ppe_category}
              disabled={!editMode}
              selectedOption={
                values.visits[index] ? values.visits[index].ppe_category : null
              }
              onChange={value =>
                dropDownHandleVisits(
                  { ppe_category: value ? value : null },
                  'ppe_category',
                  index
                )
              }
            />
          </FlexGrid3Item>
          {values.service_address && values.service_address.destination && (
            <FlexGrid3Item className="ps-0 ps-md-1 pe-1">
              <DropdownSearchable
                disabled={!editMode}
                placeHolder={'Service destination'}
                options={choices.service_destination}
                selectedOption={
                  values.service_address
                    ? values.service_address
                      ? values.service_address.destination
                      : null
                    : null
                }
              // onChange={(value) =>
              //   dropDownHandleSelectAddress(
              //     {destination: value ? value : null},
              //     'destination',
              //   )
              // }
              />
            </FlexGrid3Item>
          )}

          <FlexGrid3Item
            style={{ minWidth: '100%' }}
            className="pe-1 pe-md-0 ps-0 ps-md-1 mt-2 ">
            {/* <p><small>Service-notes</small></p> */}
            <InputTextArea
              disabled={!editMode}
              // label={'Service notes'}
              type="text"
              id={`${[nameSection]}_notes`}
              {...notes_hook}
              placeholder={' Service - notes'}
            />
          </FlexGrid3Item>
        </FlexGrid3Container>

        <WeekContainer>
          <div className={'formContainer'}>
            <FlexGrid3Container className={'mt-3'}>
              <FlexGrid3Item className="pe-1 ps-0 pb-2 pb-md-0">
                <DateSelector
                  disabled={!editMode}
                  isRequired={!templateContract}
                  data-error={dateError[index]}
                  errorMessage={dateErrorMessage}
                  labelTop={'Service start date'}
                  {...start_date_hook}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pb-2 pb-md-0">
                <DateSelector
                  disabled={!editMode}
                  data-error={dateError[index]}
                  errorMessage={dateErrorMessage}
                  labelTop={'Service end date'}
                  {...end_date_hook}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 ps-0 ps-md-1 pb-1 pt-1">
                <ToggleButton
                  disabled={!editMode}
                  label={'Include public holidays'}
                  useInput={include_holiday_hook}
                  id={`${[nameSection]}_include_holiday`}
                />
              </FlexGrid3Item>
              <FlexGrid3Item
                className="pe-0 pe-md-1 ps-1 ps-md-0 mb-2"
                onClick={() => handleClick(1)}>
                <TimeSelector
                  disabled={!editMode}
                  data-error={timeError[index]}
                  errorMessage={timeErrorMessage}
                  isRequired={isFullDay ? false : true}
                  labelTop={'Preferred start time'}
                  {...preferred_start_time_hook}
                  //added below code  for time in 24hrs format
                  timeFormat={'HH:mm'}
                  dateFormat={'HH:mm'}
                />
              </FlexGrid3Item>
              <FlexGrid3Item
                className="ps-0 ps-md-1 pe-1 pb-2 pb-md-0"
                onClick={() => handleClick(2)}>
                <TimeSelector
                  disabled={!editMode}
                  data-error={timeError[index]}
                  errorMessage={timeErrorMessage}
                  isRequired={isFullDay ? false : true}
                  labelTop={'Preferred end time'}
                  {...preferred_end_time_hook}
                  //added below code  for time in 24hrs format
                  timeFormat={'HH:mm'}
                  dateFormat={'HH:mm'}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pb-2 pb-md-0">
                <DurationInput
                  disabled={!editMode}
                  label_top={'Duration'}
                  {...duration_hook}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 ">
                <TimeSelector
                  disabled={!editMode}
                  labelTop={'Earliest start time'}
                  data-error={earlieErrors[index]}
                  errorMessage={timeEarliestMessage}
                  {...earliest_start_time_hook}
                  //added below code  for time in 24hrs format
                  timeFormat={'HH:mm'}
                  dateFormat={'HH:mm'}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                <TimeSelector
                  disabled={!editMode}
                  labelTop={'Latest start time'}
                  {...latest_start_time_hook}
                  //added below code  for time in 24hrs format
                  timeFormat={'HH:mm'}
                  dateFormat={'HH:mm'}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                <DateSelector
                  disabled={!editMode}
                  labelTop={'Effective Date'}
                  {...effective_date_hook}
                />
              </FlexGrid3Item>
            </FlexGrid3Container>
            <FlexGrid3Container>
              <FlexGrid3Item className="pe-0 ps-1 pt-1 pb-1">
                <ToggleButton
                  disabled={!editMode}
                  label="Full Day"
                  useInput={full_day_hook}
                  id="full_day"
                  onClick={handelFullDay}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-0 ps-1 pt-1 pb-1">
                <ToggleButton
                  disabled={!editMode}
                  label="Is Overnight"
                  useInput={is_overnight_hook}
                  id="overnight"
                  onClick={handelOverNight}
                />
              </FlexGrid3Item>
            </FlexGrid3Container>
            <FlexGrid3Container>
              <FlexGrid3Item className="pe-1 ps-0 ps-md-1 mt-2 mt-md-0">
                <DropdownSearchable
                  required
                  disabled={!editMode}
                  {...every_hook}
                  data_error={errors.indexOf(`${[nameSection]}.every`)}
                  placeHolder={'Repeat Every'}
                  options={[
                    { id: EVERY_DAY, name: 'Day' },
                    { id: EVERY_WEEK, name: 'Week' },
                    { id: EVERY_MONTH, name: 'Month' },
                    { id: EVERY_YEAR, name: 'Year' },
                  ]}
                  selectedOption={
                    values.visits[index] ? values.visits[index].every : null
                  }
                  onChange={value =>
                    dropDownHandleVisits(
                      { every: value ? value : null },
                      'every',
                      index
                    )
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 ps-0 ps-md-1 mt-2 mt-md-0">
                <InputLabelTop
                  disabled={!editMode}
                  label={'Repeat No.'}
                  type="text"
                  id={`${[nameSection]}_repeat`}
                  onKeyPress={values.visits[index].repeat ? KeyPress.POSITIVE : KeyPress.NATURAL}
                  {...repeat_hook}
                  maxlength={5}
                />
                {/* <DropdownSearchable
                  disabled={!editMode}
                  placeHolder={'Repeat No.'}
                  options={optionNumberData ? optionNumberData : []}
                  selectedOption={
                    values.visits[index] ? values.visits[index].repeat : null
                  }
                  onChange={value =>
                    dropDownHandleVisits(
                      {repeat: value ? value : null},
                      'repeat',
                      index
                    )
                  }
                /> */}
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 ps-0 ps-md-1 mt-2 mt-md-0">
                <DropdownSearchable
                  required
                  {...end_type_hook}
                  data_error={errors.indexOf(`${[nameSection]}.end_type`)}
                  disabled={!editMode}
                  placeHolder={'Repeat End'}
                  options={[
                    { id: END_TYPE_ON, name: 'On' },
                    { id: END_TYPE_AFTER, name: 'After' },
                    { id: END_TYPE_NEVER, name: 'Never' },
                  ]}
                  selectedOption={
                    values.visits[index] ? values.visits[index].end_type : null
                  }
                  onChange={handleEndType}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="ps-1 mt-0 mt-md-2 pe-0 pe-md-1">
                {switchEndValueInput({...end_value_hook,})}
              </FlexGrid3Item>
            </FlexGrid3Container>
          </div>
          <div className={'formWeek d-block'}>
            <div
              className={'pt-2 ms-md-1 ms-0 text-center'}
              style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
              Repeat on
            </div>
            <WeekStyle daysAdded={daysAdded}>
              {daysOfWeek.map((itemWeek, indexWeek) => {
                return (
                  <ButtonWeek
                    key={itemWeek.day}
                    add_background={itemWeek.selected}
                    disabled={!editMode}
                    onClick={() => {
                      selectedDay(itemWeek.day, indexWeek);
                    }}>
                    <div>
                      <label>{itemWeek.day}</label>
                    </div>
                  </ButtonWeek>
                );
              })}
            </WeekStyle>
          </div>
        </WeekContainer>

        <div className="mt-2 mb-2">
          <ServiceEmployees
            editMode={editMode}
            setValues={setValues}
            index={index}
            billRate={billRate}
            payRate={payRate}
            dropDownHandlePayRate={dropDownHandlePayRate}
            addNewRate={addNewRate}
            deletePayRate={deletePayRate}
            useInput={useInput}
            nameSection={nameSection}
            values={values}
            allEmployees={
              values.visits[index].employee_required
                ? values.visits[index].employee_required
                : []
            }
            dropDownHandleSkill={dropDownHandleSkill}
            dropDownHandleEmployee={dropDownHandleEmployee}
            dropDownHandleQuantity={dropDownHandleQuantity}
            dropDownHandleGender={dropDownHandleGender}
            choices={choices}
            addNewVisitEmployee={addNewVisitEmployee}
            deleteVisitEmployee={deleteVisitEmployee}
            indexVisit={index}
            addedSkillMessage={skillAddedMessage}
            readOnly={readOnly}
            serviceId={serviceId}
            clientId={clientId}
            addPayRate={true}
            clientTaskOptions={clientTaskOptions}
            groupTaskOptions={groupTaskOptions}
            plannedTasks={plannedTasks}
            medDoseTimingList={medDoseTimingList}
            templateContract={templateContract}
            shouldRemoveVisitEmployee={shouldRemoveVisitEmployee}
          />
        </div>

        {!templateContract &&
          <div>
            <TitleSection>Planned Task</TitleSection>
            <div>
              {values.visits &&
                values.visits[index].planned_task &&
                values.visits[index].planned_task.map((task, taskIndex) => {
                  return (
                    <AddPlannedTask
                      hideAddTaskButton={task.id ? true : false}
                      values={values}
                      setValues={setValues}
                      nameSection={'planned_task'}
                      visitIndex={index}
                      index={taskIndex}
                      useInput={useInput}
                      category={'visits'}
                      plannedTasks={plannedTasks}
                      readOnly={false}
                      editMode={editMode}
                      deletePlanedTask={deletePlanedTask}
                      dropDownHandlePlanedTask={dropDownHandlePlanedTask}
                      toast={toast}
                    />
                  );
                })}

              {!readOnly && (
                <PrimaryButtonForm
                  disabled={!editMode}
                  className={'ms-3 mt-3 mb-2'}
                  bgcolor="BLUE"
                  minWidth="3rem"
                  onClick={() => addNewPlannedTask('planned_task')}>
                  <span>{<BsFillPlusSquareFill />}Planned Task</span>
                </PrimaryButtonForm>
              )}
            </div>
          </div>}



        {!templateContract &&
          <div>
            <TitleSection>Medication</TitleSection>
            <div>
              {values.visits &&
                values.visits[index].medication &&
                values.visits[index].medication.map((itemMed, indexMed) => {
                  return (
                    <AddMedication
                      values={values}
                      setValues={setValues}
                      nameSection={'medication'}
                      visitIndex={index}
                      index={indexMed}
                      useInput={useInput}
                      category={'visits'}
                      medicationOption={medDoseTimingList ? [...medDoseTimingList] : []}
                      readOnly={false}
                      editMode={editMode}
                      toDelete={deleteMedication}
                      dropDownHandleSelect={dropDownHandleMedication}
                      itemMed={itemMed}
                      getMedicationPrescriptionData={getMedicationPrescriptionData}
                      setLoadingCount={setLoadingCount}
                    />
                  );
                })}
            </div>

            {!readOnly && (
              <PrimaryButtonForm
                disabled={!editMode}
                className={'ms-3 mt-2 mb-2'}
                bgcolor="BLUE"
                minWidth="3rem"
                onClick={() => addNewMedication('medication')}>
                <span>{<BsFillPlusSquareFill />}New Medication</span>
              </PrimaryButtonForm>
            )}
          </div>}

        {/* <TitleSection>Bill Rate Card</TitleSection>
        <div>
          {values.visits &&
            values.visits[index].bill_rate &&
            values.visits[index].bill_rate.map((bill_rate, indexBill) => {
              return (
                <RateCard
                  editMode={editMode}
                  values={values}
                  readOnly={readOnly}
                  data={billRate}
                  list_key1={'service_bill_rate'}
                  dropDownHandleRate={dropDownHandleRate}
                  useInput={useInput}
                  category={'visits'}
                  nameSection={'bill_rate'}
                  visitRate={true}
                  outerIndex={index}
                  index={indexBill}
                  deleteRate={deleteRate}
                />
              );
            })}
        </div> */}

        {/* {!readOnly && (
          <PrimaryButtonForm
            disabled={!editMode}
            className={'ms-3 mt-1 mb-2'}
            bgcolor="BLUE"
            minWidth="3rem"
            onClick={() => addNewRate('bill_rate')}>
            <span>{<BsFillPlusSquareFill />}Rate Card</span>
          </PrimaryButtonForm>
        )} */}
        {/* <TitleSection>Travel Bill Rate Card</TitleSection>
        <div>
          {values.visits &&
            values.visits[index].travel_pay_rate &&
            values.visits[index].travel_pay_rate.map((travel_pay_rate, indexBill) => {
              return (
                <RateCard
                  editMode={editMode}
                  values={values}
                  readOnly={readOnly}
                  data={travelRate}
                  list_key1={'service_travel_pay_rate'}
                  dropDownHandleRate={dropDownHandleRate}
                  useInput={useInput}
                  category={'visits'}
                  nameSection={'travel_pay_rate'}
                  visitRate={true}
                  outerIndex={index}
                  index={indexBill}
                  deleteRate={deleteRate}
                  travelrate={true}
                />
              );
            })}
        </div>
        {!readOnly && (
          <PrimaryButtonForm
            disabled={!editMode}
            className={'ms-3 mt-1 mb-2'}
            bgcolor="BLUE"
            minWidth="3rem"
            onClick={() => addNewRate('travel_pay_rate')}>
            <span>{<BsFillPlusSquareFill />}Rate Card</span>
          </PrimaryButtonForm>

        )} */}

        {/* <FlexGrid3Container className={'mt-4'}>
          <FlexGrid3Item className="pe-1 ps-0 pt-1">
            <ToggleButton
              disabled={!editMode}
              label={'Service suspended'}
              useInput={useInput(`${[nameSection]}.is_suspended`)}
              id={`${[nameSection]}_is_suspended`}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
            <DateSelector
              disabled={!editMode}
              labelTop={'Service suspended from date'}
              {...useInput(`${[nameSection]}.suspended_from`)}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0 pt-1 pt-md-0">
            <DateSelector
              disabled={!editMode}
              labelTop={'Service suspended to date'}
              {...useInput(`${[nameSection]}.suspended_to`)}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-1 ps-md-0 pe-0 pe-md-1 pt-1">
            <InputLabelTop
              disabled={!editMode}
              label={'Suspended reason'}
              type="text"
              id={`${[nameSection]}_suspended_reason`}
              {...useInput(`${[nameSection]}.suspended_reason`)}
              maxlength={255}
            />
          </FlexGrid3Item>
          <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pt-0 pt-md-1">
            <InputLabelTop
              disabled={!editMode}
              label={'Service amendment reason'}
              type="text"
              id={`${[nameSection]}_amendment_reason`}
              {...useInput(`${[nameSection]}.amendment`)}
              maxlength={100}
            />
          </FlexGrid3Item>
        </FlexGrid3Container> */}



      </LineContainer>
       : <div>
        <Spinner />
       </div> }</>
      <ModalContainer max_width={'40%'} >
        <ModalDecision
          type="delete"
          title="Warning"
          width={'50%'}
          body={'Are you sure you want to delete this Visit?'}
          subBody={''}
          onOk={() => {
            handleDeleteVisit(dataVisit, index);
            setModalVisitDeleteValue(false);
          }}
          onCancel={() => { }}
          okTitle={'DELETE'}
          {...bindModalVisitDelete}
        />
      </ModalContainer>
    </div>
  );
};

ServiceVisit.propTypes = {
  choices: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  useInput: PropTypes.func.isRequired,
  nameSection: PropTypes.string.isRequired,
  handleDeleteVisit: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  dataVisit: PropTypes.object.isRequired,
  dropDownHandleVisits: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  toDeleteVisitEmployee: PropTypes.array.isRequired,
  errors: PropTypes.array,
};

export default ServiceVisit;
