import React, { useState, Fragment, useRef, useEffect } from 'react';
import { FlexEqual } from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { useForm } from 'hooks/FormHook';
import DateSelector from 'components/SharedComponents/DateSelector';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import _, { stubTrue } from 'lodash';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { KeyPress } from 'utils/constants/regularExpressions';
import AddReferences from 'components/DashboardComponents/Team/AddReferences'
import { Link } from "react-router-dom"
import {
  ContainerResponsive,
} from 'shared/styles/constants/tagsStyles';
import {
  TitleSection,
  ErrorMessage,
  NoPermissionContainer
} from './styles';
import {
  setComplianceDet,
  getComplianceDetails
} from 'utils/api/EmployeeApi';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import {
  VISA_RIGHT_TO_WORK,
  DISCLOSURE_BARRING,
  VACCINATION,
  DRIVER,
  VEHICLE_DETAILS,
  EMPLOYEE_ID,
  COMPILANCE_REFERENCES,
  WEB_CREATE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION
} from '../../../../utils/constants/permissions';
import { useHistory, useLocation } from 'react-router';
import { pickFile } from 'utils/files';
import { compliance_emp_choices } from 'utils/choiceConstant';
import { useContext } from 'react';
import { AppContext } from 'context';
const Compliance = ({
  choices,
  reportsTo,
  selectedId,
  empId,
  compDet,
  setCompDet,
  filterAdmin,
  filterSuperAdmin,
  rightPermission,
  contextSubscription,
  createPermissionCompliance,
  updatePermissionCompliance,
  readPermissionCompliance,
  contextIsAdmin,
  userEmployee
}) => {
  const { values, setValues, useInput, isValid, errors, setFormErrors, setCheckRequires } =
    useForm({});
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [loadingSave, setLoadingSave] = useState(false);
  const [proofWorkUK, setProofWorkUk] = useState('No File Chosen')
  const [toggleUkProof, setToggleUkProof] = useState(false)
  const [toggleExcProof, setToggleExcProof] = useState(false)
  const [toggleDriverProof, setToggleDriverProof] = useState(false)
  const [toggleMotProof, setToggleMotProof] = useState(false)
  const [toggleInsProof, setToggleInsProof] = useState(false)
  const [proofExcemption, setProofExcemption] = useState('No File Chosen')
  const [proofDriver, setProofDriver] = useState('No File Chosen')
  const [proofVecMot, setProofVecMot] = useState('No File Chosen')
  const [proofVecIns, setProofVecIns] = useState('No File Chosen')
  const [modalLoading, setModalLoading] = useState(true)
  const [textStatus, setTextStatus] = useState(SAVE);
  const [complianceVisaRightToWorkCreate, setComplianceVisaRightToWorkCreate] = useState(false);
  const [complianceVisaRightToWorkUpdate, setComplianceVisaRightToWorkUpdate] = useState(false);
  const [complianceVisaRightToWorkRead, setComplianceVisaRightToWorkRead] = useState(false);
  const [complianceDisclosureBarringCreate, setComplianceDisclosureBarringCreate] = useState(false);
  const [complianceDisclosureBarringUpdate, setComplianceDisclosureBarringUpdate] = useState(false);
  const [complianceDisclosureBarringRead, setComplianceDisclosureBarringRead] = useState(false);
  const [complianceVaccinationRead, setComplianceVaccinationRead] = useState(false);
  const [complianceVaccinationCreate, setComplianceVaccinationCreate] = useState(false);
  const [complianceVaccinationUpdate, setComplianceVaccinationUpdate] = useState(false);
  const [complianceDriverRead, setComplianceDriverRead] = useState(false);
  const [complianceDriverCreate, setComplianceDriverCreate] = useState(false);
  const [complianceDriverUpdate, setComplianceDriverUpdate] = useState(false);
  const [complianceVehicleDetailsRead, setComplianceVehicleDetailsRead] = useState(false);
  const [complianceVehicleDetailsCreate, setComplianceVehicleDetailsCreate] = useState(false);
  const [complianceVehicleDetailsUpdate, setComplianceVehicleDetailsUpdate] = useState(false);
  const [complianceEmployeeIdRead, setComplianceEmployeeIdRead] = useState(false);
  const [complianceEmployeeIdCreate, setComplianceEmployeeIdCreate] = useState(false);
  const [complianceEmployeeIdUpdate, setComplianceEmployeeIdUpdate] = useState(false);
  const [complianceCompilanceReferencesRead, setComplianceCompilanceReferencesRead] = useState(false);
  const [complianceCompilanceReferencesCreate, setComplianceCompilanceReferencesCreate] = useState(false);
  const [complianceCompilanceReferencesUpdate, setComplianceCompilanceReferencesUpdate] = useState(false);
  const history = useHistory()
  const location = useLocation()
  const fileRightToWork = useRef(null);
  const fileExcemptionProof = useRef(null);
  const fileDriverProof = useRef(null);
  const fileVecMot = useRef(null);
  const fileVecIns = useRef(null);
  const {handleGetChoices} = useContext(AppContext)
  useEffect(()=>{
    handleGetChoices(compliance_emp_choices)
  },[])
  useEffect(() => {
    if (filterSuperAdmin||contextIsAdmin) {
      setComplianceVisaRightToWorkCreate(true);
      setComplianceVisaRightToWorkUpdate(true);
      setComplianceVisaRightToWorkRead(true);
      setComplianceDisclosureBarringCreate(true)
      setComplianceDisclosureBarringUpdate(true)
      setComplianceDisclosureBarringRead(true)
      setComplianceVaccinationRead(true)
      setComplianceVaccinationUpdate(true)
      setComplianceVaccinationCreate(true)
      setComplianceDriverRead(true)
      setComplianceDriverCreate(true)
      setComplianceDriverUpdate(true)
      setComplianceVehicleDetailsRead(true)
      setComplianceVehicleDetailsUpdate(true)
      setComplianceVehicleDetailsCreate(true)
      setComplianceEmployeeIdRead(true)
      setComplianceEmployeeIdUpdate(true)
      setComplianceEmployeeIdCreate(true)
      setComplianceCompilanceReferencesRead(true)
      setComplianceCompilanceReferencesUpdate(true)
      setComplianceCompilanceReferencesCreate(true)
    }else if(filterAdmin||contextIsAdmin){
      setComplianceVisaRightToWorkCreate(true);
      setComplianceVisaRightToWorkUpdate(true);
      setComplianceVisaRightToWorkRead(contextSubscription?.[VISA_RIGHT_TO_WORK]?.[WEB_PERMISSION]);
      setComplianceDisclosureBarringCreate(true)
      setComplianceDisclosureBarringUpdate(true)
      setComplianceDisclosureBarringRead(contextSubscription?.[DISCLOSURE_BARRING]?.[WEB_PERMISSION])
      setComplianceVaccinationRead(contextSubscription?.[VACCINATION]?.[WEB_PERMISSION])
      setComplianceVaccinationUpdate(true)
      setComplianceVaccinationCreate(true)
      setComplianceDriverRead(contextSubscription?.[DRIVER]?.[WEB_PERMISSION])
      setComplianceDriverCreate(true)
      setComplianceDriverUpdate(true)
      setComplianceVehicleDetailsRead(contextSubscription?.[VEHICLE_DETAILS]?.[WEB_PERMISSION])
      setComplianceVehicleDetailsUpdate(true)
      setComplianceVehicleDetailsCreate(true)
      setComplianceEmployeeIdRead(contextSubscription?.[EMPLOYEE_ID]?.[WEB_PERMISSION])
      setComplianceEmployeeIdUpdate(true)
      setComplianceEmployeeIdCreate(true)
      setComplianceCompilanceReferencesRead(contextSubscription?.[COMPILANCE_REFERENCES]?.[WEB_PERMISSION])
      setComplianceCompilanceReferencesUpdate(true)
      setComplianceCompilanceReferencesCreate(true)
    }
    else {
      if (rightPermission[VISA_RIGHT_TO_WORK] !== undefined) {
        setComplianceVisaRightToWorkCreate(
          rightPermission[VISA_RIGHT_TO_WORK][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[VISA_RIGHT_TO_WORK][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
        setComplianceVisaRightToWorkUpdate(
          rightPermission[VISA_RIGHT_TO_WORK][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[VISA_RIGHT_TO_WORK][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setComplianceVisaRightToWorkRead(contextSubscription?.[VISA_RIGHT_TO_WORK]?.[WEB_PERMISSION] && (
          rightPermission[VISA_RIGHT_TO_WORK][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[VISA_RIGHT_TO_WORK][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
      }

      if (rightPermission[DISCLOSURE_BARRING] !== undefined) {

        setComplianceDisclosureBarringCreate(
          rightPermission[DISCLOSURE_BARRING][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[DISCLOSURE_BARRING][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
        setComplianceDisclosureBarringUpdate(
          rightPermission[DISCLOSURE_BARRING][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[DISCLOSURE_BARRING][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setComplianceDisclosureBarringRead(contextSubscription?.[DISCLOSURE_BARRING]?.[WEB_PERMISSION] && (
          rightPermission[DISCLOSURE_BARRING][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[DISCLOSURE_BARRING][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
      }

      if (rightPermission[VACCINATION] !== undefined) {
        setComplianceVaccinationRead(contextSubscription?.[VACCINATION]?.[WEB_PERMISSION] && (
          rightPermission[VACCINATION][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[VACCINATION][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
        setComplianceVaccinationUpdate(
          rightPermission[VACCINATION][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[VACCINATION][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setComplianceVaccinationCreate(
          rightPermission[VACCINATION][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[VACCINATION][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
      }
      if (rightPermission[DRIVER]) {
        setComplianceDriverRead(contextSubscription?.[DRIVER]?.[WEB_PERMISSION] && (
          rightPermission[DRIVER][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[DRIVER][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        )
        setComplianceDriverCreate(
          rightPermission[DRIVER][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[DRIVER][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        )
        setComplianceDriverUpdate(
          rightPermission[DRIVER][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[DRIVER][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        )
      }
      if (rightPermission[VEHICLE_DETAILS] !== undefined) {
        setComplianceVehicleDetailsRead(contextSubscription?.[VEHICLE_DETAILS]?.[WEB_PERMISSION] && (
          rightPermission[VEHICLE_DETAILS][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[VEHICLE_DETAILS][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
        setComplianceVehicleDetailsUpdate(
          rightPermission[VEHICLE_DETAILS][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[VEHICLE_DETAILS][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setComplianceVehicleDetailsCreate(
          rightPermission[VEHICLE_DETAILS][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[VEHICLE_DETAILS][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
      }
      if (rightPermission[EMPLOYEE_ID] !== undefined) {
        setComplianceEmployeeIdRead(contextSubscription?.[EMPLOYEE_ID]?.[WEB_PERMISSION] && (
          rightPermission[EMPLOYEE_ID][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[EMPLOYEE_ID][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
        setComplianceEmployeeIdUpdate(
          rightPermission[EMPLOYEE_ID][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_ID][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setComplianceEmployeeIdCreate(
          rightPermission[EMPLOYEE_ID][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[EMPLOYEE_ID][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
      }
      if (rightPermission[COMPILANCE_REFERENCES] !== undefined) {
        setComplianceCompilanceReferencesRead(contextSubscription?.[COMPILANCE_REFERENCES]?.[WEB_PERMISSION] && (
          rightPermission[COMPILANCE_REFERENCES][WEB_PERMISSION][WEB_READ_PERMISSION] ||
          (rightPermission[COMPILANCE_REFERENCES][WEB_PERMISSION][SELF_WEB_READ_PERMISSION] && (userEmployee == selectedId)))
        );
        setComplianceCompilanceReferencesUpdate(
          rightPermission[COMPILANCE_REFERENCES][WEB_PERMISSION][WEB_UPDATE_PERMISSION] ||
          (rightPermission[COMPILANCE_REFERENCES][WEB_PERMISSION][SELF_WEB_UPDATE_PERMISSION] && (userEmployee == selectedId))
        );
        setComplianceCompilanceReferencesCreate(
          rightPermission[COMPILANCE_REFERENCES][WEB_PERMISSION][WEB_CREATE_PERMISSION] ||
          (rightPermission[COMPILANCE_REFERENCES][WEB_PERMISSION][SELF_WEB_CREATE_PERMISSION] && (userEmployee == selectedId))
        );
      }
    }

  }, [filterAdmin, filterSuperAdmin, rightPermission,contextSubscription,contextIsAdmin])
  useEffect(() => {
    if (selectedId || compDet) {
      loadComplianceDetails()

    }
    else if (empId) {
      setModalLoading(false)
    }
  }, [])
  const handleUpload = ref => {
    ref.current.click();
  };
  const handleChange = async (event, key, keyName) => {
    console.log(event.target, "558888")
    const fileUploaded = event.target.files[0];
    const fileResult = await pickFile(event)
    const result = _.merge(values, { [key]: { [keyName]: fileResult.file } });
    setValues({ ...result });
    if (key === 'employee_visa') {
      setProofWorkUk(fileUploaded.name)
      setToggleUkProof(true)
    }
    if (key === 'employee_vaccination') {
      setProofExcemption(fileUploaded.name)
      setToggleExcProof(true)
    }

  }
  const handleChangeFile = async (event, key) => {
    console.log(event.target, "558888")
    const fileUploaded = event.target.files[0];
    const fileResult = await pickFile(event)
    const result = _.merge(values, { [key]: fileResult.file });
    setValues({ ...result });
    if (key === 'driving_licence_copy') {
      setProofDriver(fileUploaded.name)
      setToggleDriverProof(true)
    }
    if (key === 'vehicle_mot_copy') {
      setProofVecMot(fileUploaded.name)
      setToggleMotProof(true)
    }
    if (key === 'vehicle_insurance_copy') {
      setProofVecIns(fileUploaded.name)
      setToggleInsProof(true)
    }
  }
  const addNewReference = () => {
    const referenceDefault = {
      reference_status: null,
      reference_received_date: null,
      reference_updated_by: null,
    };

    let newReferences = [];
    if (values.reference_details) {
      newReferences = values.reference_details.concat([referenceDefault]);
    } else {
      newReferences = [referenceDefault];
    }
    values.reference_details = newReferences;
    setValues({ ...values });
  };
  const deleteReference = index => {
    let newReferences = [...values.reference_details];
    newReferences.splice(index, 1);
    values.reference_details = newReferences;
    setValues({ ...values });
  };
  const setReferences = reference_details => {
    values.reference_details = reference_details;
    setValues({ ...values });
  };
  const dropDownHandleSelect = item => {
    //recursive objects merge
    const result = _.merge(values, item);
    setValues({ ...result });
  };

  const loadComplianceDetails = async () => {
    let id = selectedId ? selectedId : compDet
    const response = await getComplianceDetails(id)
    if (response.results.length > 0) {
      if (response.results[0].is_handbook_read === true) {
        response.results[0].is_handbook_read = 1
      }
      else if (response.results[0].is_handbook_read === false) {
        response.results[0].is_handbook_read = 2
      }
      if (response.results[0].driving_licence_copy) {
        setProofDriver(response.results[0].driving_licence_copy.split('/').reverse()[0])
      }
      if (response.results[0].employee_visa && response.results[0].employee_visa.proof_right_to_work_uk) {
        setProofWorkUk(response.results[0].employee_visa.proof_right_to_work_uk.split('/').reverse()[0])
      }
      if (response.results[0].employee_vaccination && response.results[0].employee_vaccination.proof_vaccination_exemption) {
        setProofExcemption(response.results[0].employee_vaccination.proof_vaccination_exemption.split('/').reverse()[0])
      }
      if (response.results[0].vehicle_mot_copy) {
        setProofVecMot(response.results[0].vehicle_mot_copy.split('/').reverse()[0])
      }
      if (response.results[0].vehicle_insurance_copy) {
        setProofVecIns(response.results[0].vehicle_insurance_copy.split('/').reverse()[0])
      }
      setValues({ ...values, ...response.results[0] })
    }
    setModalLoading(false)
  }
  const handleSubmit = (evt) => {

    evt.preventDefault();
    if (loadingSave) {
      return;
    }
    let employeeId = ''
    if (location.pathname.includes('/team/add')) {
      employeeId = empId
    } else {
      employeeId = selectedId
    }
    let body = {
      ...values,
      employee: employeeId
    };

    if ((!isValid && errors.length > 0)) {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(InvalidOrEmptyValues);
      return;
    }
    if (body.employee_visa && !body.employee_visa.is_right_to_work) {
      body.employee_visa.is_right_to_work = false
    }
    if (body.employee_vaccination && !body.employee_vaccination.is_fully_covid_vaccinated) {
      body.employee_vaccination.is_fully_covid_vaccinated = false
    }
    if (!body.is_id_badge_issued) {
      body.is_id_badge_issued = false
    }
    if (body.is_handbook_read && body.is_handbook_read) {
      if (body.is_handbook_read == 1) {
        body.is_handbook_read = true
      }
      else if (body.is_handbook_read == 2) {
        body.is_handbook_read = false
      }
    }
    if (!toggleUkProof && body.employee_visa) {
      delete body.employee_visa.proof_right_to_work_uk
    }
    if (!toggleExcProof && body.employee_vaccination) {
      delete body.employee_vaccination.proof_vaccination_exemption
    }
    if (!toggleDriverProof && body.driving_licence_copy) {
      delete body.driving_licence_copy
    }
    if (!toggleMotProof && body.vehicle_mot_copy) {
      delete body.vehicle_mot_copy
    }
    if (!toggleInsProof && body.vehicle_insurance_copy) {
      delete body.vehicle_insurance_copy
    }
    setLoadingSave(true);
    setCheckRequires(false);
    setShowError(false);
    let value = [];

    setComplianceDet(body, employeeId)
      .then(response => {
        console.log(response, "447888")
        if (response.status === 400) {

          if (response.message.message) {
            value = response.message.message
          } else {
            value = Object.keys(response.message)[0] + "-" + Object.values(response.message)[0]
          }
          throw value;
        }

        setShowError(false);
        onSuccess(response);
      })
      .catch(onError);
  };

  const onSuccess = async (response) => {
    if (response.status === 200 || response.status === 201) {
      let employeeId = ''
      if (location.pathname.includes('/team/add')) {
        employeeId = empId
      } else {
        employeeId = selectedId
      }


      let employeeComplianceId = response.message.id;
      // if employee id

      setValues({
        ...values,
        id: employeeComplianceId,
      });

      setCompDet(employeeId)
      setLoadingSave(false);
      setButtonIcon('');
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
    }
  };

  const onError = err => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(
      err
    );
  };

  const is_right_to_work_hook = useInput('employee_visa.is_right_to_work')
  const name_hook = useInput('employee_visa.name')
  const checked_date_hook = useInput('employee_visa.checked_date')
  const updated_date_hook = useInput('employee_visa.updated_date')
  const employee_visa_reference_no_hook = useInput('employee_visa.reference_no')
  const issued_date_hook = useInput('employee_visa.issued_date')
  const employee_visa_expiration_date_hook = useInput('employee_visa.expiration_date')
  const type_hook = useInput('employee_visa.type')
  const notes_hook = useInput('employee_visa.notes')
  const no_hours_weekly_visa_allowable_hook = useInput('employee_visa.no_hours_weekly_visa_allowable')
  const requested_date_hook = useInput('employee_dbs.requested_date')
  const application_no_hook = useInput('employee_dbs.application_no')
  const expiration_date_hook = useInput('employee_dbs.expiration_date')
  const reference_no_hook = useInput('employee_dbs.reference_no')
  const employee_dbs_date_checked_hook = useInput('employee_dbs.date_checked')
  const is_fully_covid_vaccinated_hook = useInput('employee_vaccination.is_fully_covid_vaccinated')
  const covid_vaccination_part_1_date_hook = useInput('employee_vaccination.covid_vaccination_part_1_date')
  const covid_vaccination_part_2_date_hook = useInput('employee_vaccination.covid_vaccination_part_2_date')
  const covid_vaccination_part_3_date_hook = useInput('employee_vaccination.covid_vaccination_part_3_date')
  const covid_vaccination_part_4_date_hook = useInput('employee_vaccination.covid_vaccination_part_4_date')
  const vaccination_exemption_note_hook = useInput('employee_vaccination.vaccination_exemption_note')
  const date_checked_hook = useInput('employee_vaccination.date_checked')
  const last_flu_vaccination_date_hook = useInput('employee_vaccination.last_flu_vaccination_date')
  const driving_licence_type_hook = useInput('driving_licence_type')
  const driving_licence_start_date_hook = useInput('driving_licence_start_date')
  const driving_licence_expiry_date_hook = useInput('driving_licence_expiry_date')
  const vehicle_make_hook = useInput('vehicle_make')
  const vehicle_model_hook = useInput('vehicle_model')
  const vehicle_colour_hook = useInput('vehicle_colour')
  const vehicle_registration_number_hook = useInput('vehicle_registration_number')
  const vehicle_mot_expiry_date_hook = useInput('vehicle_mot_expiry_date')
  const vehicle_insurance_expiry_date_hook = useInput('vehicle_insurance_expiry_date')
  const is_id_badge_issued_hook = useInput('is_id_badge_issued')
  const id_badge_start_date_hook = useInput('id_badge_start_date')
  const id_badge_end_date_hook = useInput('id_badge_end_date')
  const handbook_read_completed_date_hook = useInput('handbook_read_completed_date')
  const employee_visa_requested_date = useInput('employee_visa.requested_date')
  console.log(contextIsAdmin,"contextIsAdmin00000")
  return (
    <>
      
        <ComponentDynamic
          loading={modalLoading}
        >
          {(selectedId ? readPermissionCompliance : createPermissionCompliance) ?
          <div className={'mt-3'}>
            <FlexEqual>
              {(selectedId ? complianceCompilanceReferencesRead : complianceCompilanceReferencesCreate)
                &&
                <div className={'mt-4 mb-3'}>
                  <AddReferences
                    employee_reference={values.reference_details}
                    reference_status={choices.reference_status}
                    employee_list={reportsTo}
                    addNewReferenceCallback={addNewReference}
                    deleteReferenceCallback={deleteReference}
                    setReferencesCallback={setReferences}
                    readOnly={selectedId ? !complianceCompilanceReferencesUpdate : !complianceCompilanceReferencesCreate}
                    noSave
                  />
                </div>
              }
              <ContainerResponsive className="d-flex justify-content-between mt-5">
                {(selectedId ? complianceVisaRightToWorkRead : complianceVisaRightToWorkCreate)
                  &&
                  <FlexEqual className="me-sm-0 me-md-1">
                    <TitleSection>Visa - (Right to Work)</TitleSection>
                    <div className="d-flex flex-column mt-2">
                      <div className="d-flex align-items-center mb-2">
                        <div className="w-100 me-2">
                          <ToggleButton
                            label="Right To Work"
                            useInput={is_right_to_work_hook}
                            id="jd_is_right_to_work"
                          />
                        </div>
                        <div className="w-100 me-2">
                          <DropdownSearchable
                            placeHolder={'Visa Status'}
                            options={choices.visa_status}
                            disabled={selectedId ? !complianceVisaRightToWorkUpdate : !complianceVisaRightToWorkCreate}
                            selectedOption={
                              values.employee_visa
                                ? values.employee_visa.visa_status
                                  ? values.employee_visa.visa_status
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect({
                                employee_visa: { visa_status: value ? value : null },
                              })
                            }
                          />
                        </div>
                        <div className="w-100 align-self-start">
                          <DateSelector
                            labelTop="Requested Date"
                            {...employee_visa_requested_date}
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="w-100 me-2">
                          <InputLabelTop
                            type="text"
                            id="jd_name"
                            label={'Name'}
                            readOnly={selectedId ? !complianceVisaRightToWorkUpdate : !complianceVisaRightToWorkCreate}
                            {...name_hook}
                            maxlength={100}
                          />
                        </div>
                        <div className="w-100">
                          <DropdownSearchable
                            placeHolder={'Checked By'}
                            options={reportsTo}
                            disabled={selectedId ? !complianceVisaRightToWorkUpdate : !complianceVisaRightToWorkCreate}
                            selectedOption={
                              values.employee_visa
                                ? values.employee_visa.checked_by
                                  ? values.employee_visa.checked_by
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect({
                                employee_visa: { checked_by: value ? value : null },
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="w-100 me-2 mb-2">
                          <DateSelector
                            labelTop="Date Checked"
                            {...checked_date_hook}
                          />
                        </div>
                        <div className="w-100">
                          <DateSelector
                            labelTop="Updated Date"
                            {...updated_date_hook}
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="w-100 me-2">
                          <InputLabelTop
                            type="text"
                            id="jd_reference_no"
                            label={'Reference Number'}
                            readOnly={selectedId ? !complianceVisaRightToWorkUpdate : !complianceVisaRightToWorkCreate}
                            {...employee_visa_reference_no_hook}
                            maxlength={25}
                          />
                        </div>

                        <div className="w-100">
                          <DropdownSearchable
                            placeHolder={'Issuing Country'}
                            options={choices.country}
                            disabled={selectedId ? !complianceVisaRightToWorkUpdate : !complianceVisaRightToWorkCreate}
                            selectedOption={
                              values.employee_visa
                                ? values.employee_visa.country
                                  ? values.employee_visa.country
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect({
                                employee_visa: { country: value ? value : null },
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="d-flex mb-2">
                        <div className="w-100 me-2">
                          <DateSelector
                            labelTop="Issued Date"
                            {...issued_date_hook}
                          />
                        </div>
                        <div className="w-100 ">
                          <DateSelector
                            labelTop="Expiration Date"
                            {...employee_visa_expiration_date_hook}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="w-100">
                          <InputLabelTop
                            type="text"
                            id="jd_type"
                            readOnly={selectedId ? !complianceVisaRightToWorkUpdate : !complianceVisaRightToWorkCreate}
                            label={'Type'}
                            {...type_hook}
                            maxlength={100}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="w-100">
                          <InputLabelTop
                            type="text"
                            id="jd_notes"
                            readOnly={selectedId ? !complianceVisaRightToWorkUpdate : !complianceVisaRightToWorkCreate}
                            label={'Notes'}
                            {...notes_hook}
                            maxlength={255}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="w-100">
                          <InputLabelTop
                            type="text"
                            id="jd_notes"
                            readOnly={selectedId ? !complianceVisaRightToWorkUpdate : !complianceVisaRightToWorkCreate}
                            onKeyPress={KeyPress.DECIMAL}
                            label={'No. of Weekly Visa Working Hours Allowable'}
                            {...no_hours_weekly_visa_allowable_hook}
                            maxlength={10}
                          />
                        </div>

                      </div>
                      <div className="d-flex align-items-center">
                        <div className="w-100">
                          <span className={'pb-0 pt-0'} htmlFor="exemption-proof">Proof Of Right To Work In UK</span>
                          <input type="file"
                            style={{ display: 'none' }}
                            ref={fileRightToWork}
                            onChange={(e) => handleChange(e, 'employee_visa', 'proof_right_to_work_uk')}

                          />
                          <PrimaryButtonForm
                            minWidth="2rem"
                            onClick={() => handleUpload(fileRightToWork)}
                            className={'ms-2 me-2'}
                          >
                            <span className={''}>{'Choose File'}</span>

                          </PrimaryButtonForm>
                          <a href={values.employee_visa && values.employee_visa.proof_right_to_work_uk} style={{
                            pointerEvents: proofWorkUK === 'No File Chosen' ? "none"
                              : toggleUkProof ? "none" : "", color: "#696969", fontStyle: "italic"
                          }}
                            target="_blank" rel="noopener noreferrer"
                            classname={'ms-1 mt-2'}
                          >
                            {proofWorkUK}
                          </a>
                          {/* <span 
                      classname={'ms-1 mt-2'}
                      style={{color:"#696969",fontStyle:"italic"}}
                      >{proofWorkUK}</span> */}
                        </div>

                      </div>
                    </div>
                  </FlexEqual>
                }
                {(selectedId ? complianceDisclosureBarringRead : complianceDisclosureBarringCreate)
                  &&
                  <FlexEqual marginTopSm noMarginLeft className="ms-sm-0 ms-md-1">
                    <TitleSection>
                      Disclosure & Barring (DBS) - (Criminal Checks){' '}
                    </TitleSection>
                    <div className="d-flex flex-column mt-2">
                      <div className="w-100 mb-2">
                        <DropdownSearchable
                          placeHolder={'DBS Application Type'}
                          options={choices.application_type}
                          disabled={selectedId ? !complianceDisclosureBarringUpdate : !complianceDisclosureBarringCreate}
                          selectedOption={
                            values.employee_dbs
                              ? values.employee_dbs.application_type
                                ? values.employee_dbs.application_type
                                : null
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect({
                              employee_dbs: { application_type: value ? value : null },
                            })
                          }
                        />
                      </div>
                      <div className="d-flex align-items-start">
                        <div className="w-100 me-2">
                          <DateSelector
                            labelTop="Requested Date"
                            {...requested_date_hook}
                          />
                        </div>
                        <div className="w-100">
                          <InputLabelTop
                            type="text"
                            id="jd_dbs_application_no"
                            readOnly={selectedId ? !complianceDisclosureBarringUpdate : !complianceDisclosureBarringCreate}
                            label={'Application Number'}
                            {...application_no_hook}
                            maxlength={25}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-start">
                        <div className="w-100 me-2">
                          <DateSelector
                            labelTop="Expiry Date"
                            {...expiration_date_hook}
                          />
                        </div>
                        <div className="w-100">
                          <InputLabelTop
                            type="text"
                            readOnly={selectedId ? !complianceDisclosureBarringUpdate : !complianceDisclosureBarringCreate}
                            id="jd_dbs_reference_no"
                            label={'Reference Number'}
                            {...reference_no_hook}
                            maxlength={25}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-start">
                        <div className="w-100 me-2">
                          <DateSelector
                            labelTop="Date Checked"
                            {...employee_dbs_date_checked_hook}
                          />
                        </div>
                        <div className="w-100">
                          <DropdownSearchable
                            placeHolder={'Checked By'}
                            disabled={selectedId ? !complianceDisclosureBarringUpdate : !complianceDisclosureBarringCreate}
                            options={reportsTo}
                            selectedOption={
                              values.employee_dbs
                                ? values.employee_dbs.dbs_checked_by
                                  ? values.employee_dbs.dbs_checked_by
                                  : null
                                : null
                            }
                            onChange={value =>
                              dropDownHandleSelect({
                                employee_dbs: { dbs_checked_by: value ? value : null },
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </FlexEqual>
                }
              </ContainerResponsive>
              {(selectedId ? complianceVaccinationRead : complianceVaccinationCreate)
                &&
                <>
                  <TitleSection className={'mt-4 mb-2'}>Vaccinations</TitleSection>
                  <div className="d-flex mb-2">
                    <div className="w-100 me-2">
                      <ToggleButton
                        label="Fully Covid Vaccinated"
                        useInput={is_fully_covid_vaccinated_hook}
                        id="jd_is_right_to_work"
                      />
                    </div>
                    <div className="w-100 me-2">
                      <DropdownSearchable
                        placeHolder={'Vaccination Country'}
                        disabled={selectedId ? !complianceVaccinationUpdate : !complianceVaccinationCreate}
                        options={choices.country}
                        selectedOption={
                          values.employee_vaccination
                            ? values.employee_vaccination.vaccination_country
                              ? values.employee_vaccination.vaccination_country
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect({
                            employee_vaccination: { vaccination_country: value ? value : null },
                          })
                        }
                      />
                    </div>
                    <div className="w-100">
                      <DateSelector
                        labelTop="Covid Vaccination Date Part 1"
                        {...covid_vaccination_part_1_date_hook}
                      />
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="w-100 me-2">
                      <DateSelector
                        labelTop="Covid Vaccination Date Part 2"
                        {...covid_vaccination_part_2_date_hook}
                      />
                    </div>
                    <div className="w-100 me-2">
                      <DateSelector
                        labelTop="Covid Vaccination Date Booster 1"
                        {...covid_vaccination_part_3_date_hook}
                      />
                    </div>
                    <div className="w-100">
                      <DateSelector
                        labelTop="Covid Vaccination Date Booster 2"
                        {...covid_vaccination_part_4_date_hook}
                      />
                    </div>
                  </div>
                  <div className="d-flex mb-1">
                    <div className="w-100 me-2">
                      <DropdownSearchable
                        placeHolder={'Vaccination Exemption'}
                        options={choices.vaccination_exemption_type}
                        disabled={selectedId ? !complianceVaccinationUpdate : !complianceVaccinationCreate}
                        selectedOption={
                          values.employee_vaccination
                            ? values.employee_vaccination.vaccination_exemption_type
                              ? values.employee_vaccination.vaccination_exemption_type
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect({
                            employee_vaccination: { vaccination_exemption_type: value ? value : null },
                          })
                        }
                      />
                    </div>
                    <div className="w-100 me-2">
                      <InputLabelTop
                        type="text"
                        id="jd_dbs_checked_by"
                        readOnly={selectedId ? !complianceVaccinationUpdate : !complianceVaccinationCreate}
                        label={'Vaccination Exemption Note'}
                        {...vaccination_exemption_note_hook}
                        maxlength={255}
                      />
                    </div>
                    <div className="w-100 me-2">
                      <DateSelector
                        labelTop="Date Checked"
                        {...date_checked_hook}
                      />
                    </div>
                    <div className="w-100">
                      <DropdownSearchable
                        placeHolder={'Checked By'}
                        options={reportsTo}
                        disabled={selectedId ? !complianceVaccinationUpdate : !complianceVaccinationCreate}
                        selectedOption={
                          values.employee_vaccination
                            ? values.employee_vaccination.checked_by
                              ? values.employee_vaccination.checked_by
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect({
                            employee_vaccination: { checked_by: value ? value : null },
                          })
                        }
                      />
                    </div>
                  </div>

                  <div className="d-flex mb-2">
                    <div className="w-100 me-2">
                      <DropdownSearchable
                        placeHolder={'Flu Vaccination'}
                        options={choices.flu_vaccination}
                        disabled={selectedId ? !complianceVaccinationUpdate : !complianceVaccinationCreate}
                        selectedOption={
                          values.employee_vaccination
                            ? values.employee_vaccination.flu_vaccination
                              ? values.employee_vaccination.flu_vaccination
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect({
                            employee_vaccination: { flu_vaccination: value ? value : null },
                          })
                        }
                      />
                    </div>
                    <div className="w-100">
                      <DateSelector
                        labelTop="Last Flu Vaccination Date"
                        {...last_flu_vaccination_date_hook}
                      />
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <span className={'pb-0 pt-0'} htmlFor="exemption-proof">Proof Of Exemption</span>
                    <input type="file"
                      style={{ display: 'none' }}
                      ref={fileExcemptionProof}
                      onChange={(e) => handleChange(e, 'employee_vaccination', 'proof_vaccination_exemption')}

                    />
                    <PrimaryButtonForm
                      minWidth="2rem"
                      onClick={() => handleUpload(fileExcemptionProof)}
                      className={'ms-2 me-2'}
                    >
                      <span className={''}>{'Choose File'}</span>

                    </PrimaryButtonForm>
                    <a href={values.employee_vaccination && values.employee_vaccination.proof_vaccination_exemption} style={{
                      pointerEvents: proofExcemption === 'No File Chosen' ? "none"
                        : toggleExcProof ? "none" : "", color: "#696969", fontStyle: "italic"
                    }}
                      target="_blank" rel="noopener noreferrer"
                      classname={'ms-1 mt-2'}
                    >
                      {proofExcemption}
                    </a>
                    {/* <span 
                      classname={'ms-1 mt-2'}
                      style={{color:"#696969",fontStyle:"italic"}}
                      >{proofExcemption}</span> */}



                  </div>
                </>
              }

              {(selectedId ? complianceDriverRead : complianceDriverCreate)
                &&
                <>
                  <TitleSection className={'mt-4 mb-2'}>Driver</TitleSection>
                  <div className="d-flex mb-2">
                    {/* <div className="w-100 me-2">
              <ToggleButton
                          label="Driver"
                          // useInput={useInput('visa.is_right_to_work')}
                          id="jd_is_right_to_work"
                        />
                      </div> */}
                    <div className="w-100 me-2">
                      <InputLabelTop
                        type="text"
                        readOnly={selectedId ? !complianceDriverUpdate : !complianceDriverCreate}
                        id="jd_dbs_checked_by"
                        label={'Driving Licence Type'}
                        {...driving_licence_type_hook}
                        maxlength={255}
                      />
                    </div>
                    <div className="w-100">
                      <DropdownSearchable
                        placeHolder={'Driving Licence Country'}
                        options={choices.country}
                        disabled={selectedId ? !complianceDriverUpdate : !complianceDriverCreate}
                        selectedOption={
                          values
                            ? values.driving_licence_issue_country
                              ? values.driving_licence_issue_country
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect(
                            { driving_licence_issue_country: value ? value : null }

                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="w-100 me-2">
                      <DateSelector
                        labelTop="Driving Licence Start Date"
                        {...driving_licence_start_date_hook}
                      />
                    </div>
                    <div className="w-100 me-2">
                      <DateSelector
                        labelTop="Driving Licence Expiry Date"
                        {...driving_licence_expiry_date_hook}
                      />
                    </div>

                  </div>
                  <div className="d-flex mb-2">
                    <span className={'pb-0 pt-0'} htmlFor="exemption-proof">Driver Licence</span>
                    <input type="file"
                      style={{ display: 'none' }}
                      ref={fileDriverProof}
                      onChange={(e) => handleChangeFile(e, 'driving_licence_copy')}

                    />
                    <PrimaryButtonForm
                      minWidth="2rem"
                      onClick={() => handleUpload(fileDriverProof)}
                      className={'ms-2 me-2'}
                    >
                      <span className={''}>{'Choose File'}</span>

                    </PrimaryButtonForm>
                    <a href={values.driving_licence_copy && values.driving_licence_copy} style={{
                      pointerEvents: proofDriver === 'No File Chosen' ? "none"
                        : toggleDriverProof ? "none" : "", color: "#696969", fontStyle: "italic"
                    }}
                      target="_blank" rel="noopener noreferrer"
                      classname={'ms-1 mt-2'}
                    >
                      {proofDriver}
                    </a>
                    {/* <span 
                      classname={'ms-1 mt-2'}
                      style={{color:"#696969",fontStyle:"italic"}}
                      >{proofDriver}</span> */}
                  </div>
                </>
              }

              {(selectedId ? complianceVehicleDetailsRead : complianceVehicleDetailsCreate)
                &&
                <>
                  <TitleSection className={'mt-4 mb-2'}>Vehicle Details</TitleSection>
                  <div className="d-flex mb-2">
                    <div className="w-100 me-2">
                      <InputLabelTop
                        type="text"
                        id="jd_dbs_checked_by"
                        label={'Car Make'}
                        readOnly={selectedId ? !complianceVehicleDetailsUpdate : !complianceVehicleDetailsCreate}
                        {...vehicle_make_hook}
                        maxlength={255}
                      />
                    </div>
                    <div className="w-100 me-2">
                      <InputLabelTop
                        type="text"
                        id="jd_dbs_checked_by"
                        label={'Car Modal'}
                        readOnly={selectedId ? !complianceVehicleDetailsUpdate : !complianceVehicleDetailsCreate}
                        {...vehicle_model_hook}
                        maxlength={255}
                      />
                    </div>
                    <div className="w-100 me-2">
                      <InputLabelTop
                        type="text"
                        id="jd_dbs_checked_by"
                        readOnly={selectedId ? !complianceVehicleDetailsUpdate : !complianceVehicleDetailsCreate}
                        label={'Car Colour'}
                        {...vehicle_colour_hook}
                        maxlength={255}
                      />
                    </div>
                    <div className="w-100">
                      <InputLabelTop
                        type="text"
                        id="jd_dbs_checked_by"
                        readOnly={selectedId ? !complianceVehicleDetailsUpdate : !complianceVehicleDetailsCreate}
                        label={'Car Registration Number'}
                        {...vehicle_registration_number_hook}
                        maxlength={15}
                      />
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="w-100 me-2">
                      <DateSelector
                        labelTop="Vehicle MOT Expiry Date"
                        {...vehicle_mot_expiry_date_hook}
                      />
                    </div>
                    <div className="w-100">
                      <span className={'pb-0 pt-0'} htmlFor="exemption-proof">Vehicle Mot</span>
                      <input type="file"
                        style={{ display: 'none' }}
                        ref={fileVecMot}
                        onChange={(e) => handleChangeFile(e, 'vehicle_mot_copy')}
                      />
                      <PrimaryButtonForm
                        minWidth="2rem"
                        onClick={() => handleUpload(fileVecMot)}
                        className={'ms-2 me-2'}
                      >
                        <span className={''}>{'Choose File'}</span>

                      </PrimaryButtonForm>
                      <a href={values.vehicle_mot_copy && values.vehicle_mot_copy} style={{
                        pointerEvents: proofVecMot === 'No File Chosen' ? "none"
                          : toggleMotProof ? "none" : "", color: "#696969", fontStyle: "italic"
                      }}
                        target="_blank" rel="noopener noreferrer"
                        classname={'ms-1 mt-2'}
                      >
                        {proofVecMot}
                      </a>
                      {/* <span 
                      classname={'ms-1 mt-2'}
                      style={{color:"#696969",fontStyle:"italic"}}
                      >{proofVecMot}</span> */}
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="w-100 me-2">
                      <DateSelector
                        labelTop="Vehicle Insurance Expiry Date"
                        {...vehicle_insurance_expiry_date_hook}
                      />
                    </div>
                    <div className="w-100">
                      <span className={'pb-0 pt-0'} htmlFor="exemption-proof">Vehicle Insurance</span>
                      <input type="file"
                        style={{ display: 'none' }}
                        ref={fileVecIns}
                        onChange={(e) => handleChangeFile(e, 'vehicle_insurance_copy')}
                      />
                      <PrimaryButtonForm
                        minWidth="2rem"
                        onClick={() => handleUpload(fileVecIns)}
                        className={'ms-2 me-2'}
                      >
                        <span className={''}>{'Choose File'}</span>

                      </PrimaryButtonForm>
                      <a href={values.vehicle_insurance_copy} style={{
                        pointerEvents: proofVecIns === 'No File Chosen' ? "none"
                          : toggleInsProof ? "none" : "", color: "#696969", fontStyle: "italic"
                      }}
                        target="_blank" rel="noopener noreferrer"
                        classname={'ms-1 mt-2'}
                      >
                        {proofVecIns}
                      </a>
                      {/* <span 
                      classname={'ms-1 mt-2'}
                      style={{color:"#696969",fontStyle:"italic"}}
                      >{proofVecIns}</span> */}
                    </div>
                  </div>
                </>
              }
              {(selectedId ? complianceEmployeeIdRead : complianceEmployeeIdCreate)
                &&
                <>
                  <TitleSection className={'mt-4 mb-2'}>Employee ID</TitleSection>
                  <div className="d-flex mb-2">
                    <div className="w-100 me-2">
                      <ToggleButton
                        label="ID Badge Issued"
                        useInput={is_id_badge_issued_hook}
                        id="jd_is_right_to_work"
                      />
                    </div>
                    <div className="w-100 me-2">
                      <DateSelector
                        labelTop="Id Badge Start Date"
                        {...id_badge_start_date_hook}
                      />
                    </div>
                    <div className="w-100">
                      <DateSelector
                        labelTop="Id Badge Expiry date"
                        {...id_badge_end_date_hook}
                      />
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="w-100 me-2">
                      <DropdownSearchable
                        placeHolder={'Employee Handbook Read And UnderStood'}
                        disabled={selectedId ? !complianceEmployeeIdUpdate : !complianceEmployeeIdCreate}
                        options={[
                          { id: 1, name: "Yes" },
                          { id: 2, name: "No" }
                        ]}
                        selectedOption={
                          values
                            ? values.is_handbook_read
                              ? values.is_handbook_read
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect(
                            { is_handbook_read: value ? value : null }

                          )
                        }
                      />
                    </div>
                    <div className="w-100 me-2">
                      <DropdownSearchable
                        placeHolder={'Employee Handbook Read And UnderStood-Checked By'}
                        options={reportsTo}
                        disabled={selectedId ? !complianceEmployeeIdUpdate : !complianceEmployeeIdCreate}
                        selectedOption={
                          values
                            ? values.handbook_read_checked_by
                              ? values.handbook_read_checked_by
                              : null
                            : null
                        }
                        onChange={value =>
                          dropDownHandleSelect(
                            { handbook_read_checked_by: value ? value : null }

                          )
                        }
                      />
                    </div>
                    <div className="w-100">
                      <DateSelector
                        labelTop="Employee Handbook Read And UnderStood-Date"
                        {...handbook_read_completed_date_hook}
                      />
                    </div>
                  </div>
                  <div
                    className={
                      'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-3'
                    }>
                    <div className={'mb-3'}>
                      {showError ? (
                        <ErrorMessage>
                          <label className={'mt-2'}>{errorMessage}</label>
                        </ErrorMessage>
                      ) : (
                        <Fragment />
                      )}
                    </div>

                    <div
                      className={
                        'd-flex justify-content-center justify-content-md-end'
                      }>
                      <div className={'ms-0 ms-md-3 me-2'}>
                        <PrimaryButtonForm
                          bgcolor="PURPLE_GRAY_500"
                          minWidth="6rem"
                          className={'me-2'}
                          onClick={() => {
                            history.push('/team')
                          }}>
                          <span className={'me-2'}>{'Cancel'}</span>
                        </PrimaryButtonForm>
                        {(selectedId ? updatePermissionCompliance : true) && (
                          <PrimaryButtonForm
                            bgcolor="PURPLE_GRAY_500"
                            minWidth="6rem"
                            onClick={handleSubmit}
                          >
                            <span className={'me-2'}>{textStatus}</span>
                            {!loadingSave ? (
                              buttonIcon
                            ) : (
                              <span
                                className="spinner-border spinner-border-sm me-1"
                                role="status"
                              />
                            )}
                          </PrimaryButtonForm>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              }
            </FlexEqual>
          </div>
           :
           <NoPermissionContainer>
             You don't have permission to {selectedId ? 'read' : 'create'} the information.
           </NoPermissionContainer>
         }
        </ComponentDynamic>
       
    </>
  );
}

export default Compliance;
