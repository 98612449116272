import React from 'react'
import { Switch, TextField } from '@mui/material'
import { useRef, useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { UploadInput } from 'shared/styles/buttons';
import { UploadPhotoContainer } from 'shared/styles/constants/tagsStyles';
import { attachImage } from 'utils/api/FormDataApi';
import { pickFile } from 'utils/files';
import { ERR_COLOR } from 'shared/styles/constants/colors';

const FileDownloadCom = ({ setJson, json }) => {
    const editor = useRef(null);


    const handlePickFile = async (evt, section) => {

        const result = await pickFile(evt);
        console.log(result, 555333)
        switch (section) {
          case 'photo':
            const photoUrl = await attachImage({file : result.file})
            if(photoUrl.message.file){
                // setUploadedFile(photoUrl.message.file);
                setJson({
                    ...json, fileDownload: {
                        ...json.fileDownload,
                        file: photoUrl.message.id,
                        placeholder: result.file.name,
                        value: photoUrl.message.file
                    }
                })
            }
            break;
          case 'nok':
            // setNokPhoto(result.file);
            break;
          default:
        }
      };
    return (
        <div className='mb-4'>
              <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "center", marginBottom: 10 }}>
                <div style={{ marginBottom: 10, display: "flex", flex: 1 }}>

                    <TextField
                        id="outlined-error"
                        label="Input Label *"
                        placeholder='Input label'
                        defaultValue={json?.fileDownload?.label}
                        style={{ marginRight: 10, flex: 1 }}
                        onChange={(event) => {
                            console.log(event.target.value)
                            setJson({
                                ...json, fileDownload: {
                                    ...json.fileDownload,
                                    label: event.target.value
                                }
                            })
                        }}
                    />
                    {/* <TextField
                        id="outlined-error-helper-text"
                        label="Placeholder"
                        placeholder="placeholder"
                        defaultValue={json.fileDownload?.placeholder}
                        style={{ flex: 1 }}
                        onChange={(event) => {
                            console.log(event.target.value)
                            setJson({
                                ...json, fileDownload: {
                                    ...json.fileDownload,
                                    placeholder: event.target.value
                                }
                            })
                        }}
                    /> */}
                </div>

               <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.fileDownload?.description}
                    placeholder="Description"
                    onChange={(event) => {
                        setJson({
                            ...json, fileDownload: {
                                ...json.fileDownload,
                                description: event.target.value
                            }
                        })
                    }}
                />
                 <TextField
                    id="outlined-multiline-static"
                    label="Instruction"
                    multiline
                    rows={3}
                    value={json.fileDownload?.instruction}
                    placeholder="Instruction"
                    onChange={(event) => {
                        setJson({
                            ...json, fileDownload: {
                                ...json.fileDownload,
                                instruction: event.target.value
                            }
                        })
                    }}
                />
                <div className='d-flex'>
                    <div className='d-flex'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.fileDownload?.required}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, fileDownload: {
                                                ...json.fileDownload,
                                                required: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ms-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.fileDownload?.readOnly}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, fileDownload: {
                                                ...json.fileDownload,
                                                readOnly: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    <div className='d-flex ms-2'>
                        <label for="" style={{ marginTop: 'auto' }} >{'Show Expression'}:</label>
                        <Switch
                                    color="primary"
                                    checked={json.fileDownload?.showCondition}
                                    onChange={(item) => {
                                        setJson({
                                            ...json, fileDownload: {
                                                ...json.fileDownload,
                                                showCondition: item.target.checked
                                            }
                                        })
                                    }}
                                />
                    </div>
                    
                </div>
               {json.fileDownload?.showCondition &&
                <TextField
                    id="outlined-multiline-static"
                    label="Rule Expression"
                    className='mb-2'
                    multiline
                    rows={3}
                    value={json.fileDownload?.ruleExpression}
                    placeholder="Rule Expression"
                    onChange={(event) => {
                        setJson({
                            ...json, fileDownload: {
                                ...json.fileDownload,
                                ruleExpression: event.target.value
                            }
                        })
                    }}
                />}
            </div>
            <UploadPhotoContainer className="d-flex align-items-center mt-2">
                <UploadInput
                    onInput={e => {
                        handlePickFile(e, 'photo');
                    }}
                    onClick={event => {
                        event.target.value = null;
                    }}
                    type="file"
                    // accept={'.png,.jpg,.jpeg'}
                    id="employee-photo"
                    name="employee-photo"
                    placeholder={'Employee Photo'}
                />
                <FiUpload />
                <label htmlFor="employee-photo">Upload</label><span style={{color: ERR_COLOR}}>*</span>

            </UploadPhotoContainer>
            <div>
           {json?.fileDownload?.placeholder && 
           <div className="copy-list mt-1 w-100">
                     
                                <div className='copy-item'>
                             {json?.fileDownload?.placeholder}
                          </div>
                      </div>}
            </div>
        </div>

    )
}

export default FileDownloadCom;
