import React, { useState, useEffect, useContext } from 'react';
import { Title } from "./style"
// import {getCategoryPermission, getPermissions,getStaff,getClient,getEmployee} from '../../../utils/localStorage/user';
import { AppContext } from 'context'
import { PieChart } from 'react-minimal-pie-chart';
import { Chart } from "react-google-charts";

import moment from 'moment';
import { getDasboardMedicationKpis, getDasboardTeamKpis } from 'utils/api/DashboardApi';
import { DropdownTypesContainer, ErrorMessage } from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { Toast } from 'primereact/toast';
import { useForm } from 'hooks/FormHook';
import { useRef } from 'react';
import { Fragment } from 'react';
import { LoadingContainer } from 'components/DashboardComponents/ModalDynamic/styles';
import Spinner from 'components/SharedComponents/Spinner';
import './teamkpi.css'
import { dashboard_choices } from 'utils/choiceConstant';
const MedicationKpis = () => {
  //   const[employeeData,setEmployeeData]=useState({})
  let start_date_today = moment().subtract(7, 'days').format('YYYY-MM-DD')
  let end_date_today = moment().format('YYYY-MM-DD')

  const { contextIsAdmin, contextIsSuperAdmin, contextIsStaff, contextChoices,handleGetChoices } = useContext(AppContext)
  const { values, setValues, useInput } = useForm({ start_date: start_date_today, end_date: end_date_today, kpi: 'day' });
  const toast = useRef();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  let startDate = useInput('start_date', 'isRequired');
  let endDate = useInput('end_date', 'isRequired');

  useEffect(() => {
    if(contextChoices.kpi){
      loadTeamKpiData()
    }
  }, [contextChoices.kpi])
  useEffect(() => {
    handleGetChoices(dashboard_choices)
    
  }, [])
  useEffect(() => {
    if (values.start_date) {
      var daysDiff = moment(values.end_date).diff(
        moment(values.start_date),
        'days'
      );
      console.log(daysDiff, '9999999');
      if (values.kpi === 'day' && daysDiff > 60) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(60, 'days')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        values.start_date = moment(values.end_date)
          .subtract(60, 'days')
          .format('YYYY-MM-DD');

        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max  range should range  be 60 days',
        });
      }

      if (values.kpi === 'month' && daysDiff > 365) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(12, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should  be 12 months',
        });
      }
      if (values.kpi === 'week' && daysDiff > 91) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(13, 'week')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should be 13 weeks',
        });
      }
      if (values.kpi === 'quarter' && daysDiff > 4567) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(15, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Warning',
          detail: 'max range  should be 1.5 year',
        });
      }
    }
  }, [values.start_date || values.kpi]);

  useEffect(() => {
    if (values.end_date) {
      var daysDiff = moment(values.end_date).diff(
        moment(values.start_date),
        'days'
      );

      if (values.kpi === 'day' && daysDiff > 60) {
        values.start_date = moment(values.end_date)
          .subtract(60, 'days')
          .format('YYYY-MM-DD');
        setValues({ ...values });

        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max  range should range  be 60 days',
        });
      }
      if (values.kpi === 'month' && daysDiff > 365) {
        values.start_date = moment(values.end_date)
          .subtract(12, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should  be 12 months',
        });
      }
      if (values.kpi === 'week' && daysDiff > 91) {
        values.start_date = moment(values.end_date)
          .subtract(13, 'week')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should be 13 weeks',
        });
      }
      if (values.kpi === 'quarter' && daysDiff > 457) {
        values.start_date = moment(values.end_date)
          .subtract(15, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Warning',
          detail: 'max range  should be 1.5 year',
        });
      }
      setValues({ ...values });
    }
  }, [values.end_date || values.kpi]);

  const loadTeamKpiData = () => {
    if(!loading){
      setLoading(true)
    }
    if (!values.kpi) {
      setShowError(true)
      setErrorMessage('Please select Frequency ');
      return
    }
    getDasboardMedicationKpis(values.start_date, values.end_date, values.kpi)
      .then(res => {
        setLoading(false)
        if (res.data) {
          values.one_day_data = null
          if (res.data.length == 1) {
            values.one_day_data = res.data[0];
            setValues({ ...values })
          }else{
            values.medication_total = res.data.map((item)=>{
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.totalFamilyAdministered || 0, item.totalFullyTaken || 0, item.totalNotObserved || 0, item.totalNotReported || 0, item.totalNotTaken || 0, item.totalPartiallyTaken || 0, item.totalSelfAdministered || 0]
            })
            values.medication_incorrect = res.data.map((item)=>{
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.inCorrectDose || 0, item.inCorrectDrug || 0, item.inCorrectFormulation || 0, item.inCorrectLabel || 0, item.inCorrectQuantity || 0]
            })
            setValues({ ...values })
          }
          
        }
      })
  }

  const dropDownHandleSelect = item => {
    setValues({ ...values, ...item });

    if (item.kpi) {

      setShowError(false)
      var daysDiff = moment(values.end_date).diff(
        moment(values.start_date),
        'days'
      );

      console.log(daysDiff, 'EEEEEEEE');
      var month = moment(values.end_date).diff(
        moment(values.start_date),
        'months'
      );

      if (daysDiff > 60 && item.kpi === 'day') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max  range should range  be 60 days',
        });
        values.start_date = moment(values.end_date)
          .subtract(60, 'days')
          .format('YYYY-MM-DD');

        setValues({ ...values });
      }
      if (daysDiff > 365 && item.kpi === 'month') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should  be 12 months',
        });
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(12, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
      }

      if (daysDiff > 91 && item.kpi === 'week') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should be 13 weeks',
        });
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(13, 'week')
          .format('YYYY-MM-DD');
        setValues({ ...values });
      }
      if (daysDiff > 457 && item.kpi === 'quarter') {
        toast.current.show({
          severity: 'error',
          summary: 'Warning',
          detail: 'max range  should be 1.5 year',
        });
        values.start_date = moment(values.end_date)
          .subtract(15, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
      }
      setValues({ ...values, ...item });
    }
  };

  const dataChartTotalData = [
    ["Total Family Administered", "Total Fully Taken"],
    ["Total Family Administered", values.one_day_data ? values.one_day_data.totalFamilyAdministered : 0],
    ["Total Fully Taken", values.one_day_data ? values.one_day_data.totalFullyTaken : 0],
    ["Total Not Observed", values.one_day_data ? values.one_day_data.totalNotObserved : 0],
    ["Total Not Reported", values.one_day_data ? values.one_day_data.totalNotReported : 0],
    ["Total Not Taken", values.one_day_data ? values.one_day_data.totalNotTaken : 0],
    ["Total Partially Taken", values.one_day_data ? values.one_day_data.totalPartiallyTaken : 0],
    ["Total Self Administered", values.one_day_data ? values.one_day_data.totalSelfAdministered : 0],
  ];

  const dataChartTotalIncorrectData = [
    ["InCorrect Dose", "InCorrect Drug"],
    ["InCorrect Dose", values.one_day_data ? values.one_day_data.inCorrectDose : 0],
    ["InCorrect Drug", values.one_day_data ? values.one_day_data.inCorrectDrug : 0],
    ["InCorrect Formulation", values.one_day_data ? values.one_day_data.inCorrectFormulation : 0],
    ["InCorrect Label", values.one_day_data ? values.one_day_data.inCorrectLabel : 0],
    ["InCorrect Quantity", values.one_day_data ? values.one_day_data.inCorrectQuantity : 0],
  ];

  const dataAbsenceHrs = [
    ["Tasks", "Absence Count"],
    ["Employee Hrs", values.one_day_data ? values.one_day_data.employeeAbsenceHrs : 0],
    ["Holiday Hrs", values.one_day_data ? values.one_day_data.holidayAbsenceHrs : 0],
    ["Medical Hrs", values.one_day_data ? values.one_day_data.medicalAbsenceHrs : 0],
    ["Medical Short Notice Hrs", values.one_day_data ? values.one_day_data.medicalShortNoticeAbsenceHrs : 0],
    ["Other Hrs", values.one_day_data ? values.one_day_data.otherAbsenceHrs : 0],
    ["Other Short Hrs", values.one_day_data ? values.one_day_data.otherShortAbsenceHrs : 0],
  ];

  const dataAbsence = [
    ["Tasks", "Absence Count"],
    ["Employee", values.one_day_data ? values.one_day_data.employeeAbsence : 0],
    ["Holiday", values.one_day_data ? values.one_day_data.holidayAbsence : 0],
    ["Medical", values.one_day_data ? values.one_day_data.medicalAbsence : 0],
    ["Medical Short Notice", values.one_day_data ? values.one_day_data.medicalShortNoticeAbsence : 0],
    ["Other", values.one_day_data ? values.one_day_data.otherAbsence : 0],
    ["Other Short", values.one_day_data ? values.one_day_data.otherShortAbsence : 0],
  ];


  const dataBar = [
    ["Date", "value"],
    ["January", 1000],
    ["Feb", 1170],
    ["March", 660],
    ["April", 342],
    ["May", 245],
    ["June", 1030],
    ["July", 657],
    ["August", 535],
    ["September", 1030],
    ["Octobor", 768],
    ["November", 465],
    ["December", 30],
  ];

  const optionsBar = {
    chart: {
      title: "Active employee vs Active probation employee",
      //subtitle: "Sales, Expenses, and Profit: 2014-2017",
    },
  };
  const optionsBarAvailability = {
    chart: {
      title: "Employee availability",
    },
   //chartArea:{left:20,top:10,width: '100%', height: '80%'}
  };
  const optionsLineAvailability = {
      title: "Employee availability",
      curveType: "function",
      legend: { position: "top", maxLines: 5 },
  };

  const optionsLineAvailabilityHrs = {
    title: "Medication Data",
    curveType: "function",
    legend: { position: "top", maxLines: 5 },
};

  const optionsChartTotalIncorrectData = {
    title: "Medication InCorrect",
    pieHole: 0.5,
    is3D: false,
    // slices: [{color: ''},{color: 'red'}]
  };

  const optionsChartTotalData = {
    title: "Medication Data",
    pieHole: 0.5,
    is3D: false,
  };
  const optionsAbsence = {
    title: "Absence Count",
  };

  const optionsAbsenceHours = {
    title: "Absence Hours",
  };

  return (
    <div>
      {loading ? (
        <LoadingContainer maxHeight={null}>
          <Spinner />
        </LoadingContainer>
      ) : (
        <>
        <Toast ref={toast} position="top-right" />
      <Title>Medication KPIs</Title>
      <div className="d-flex mt-2">
        <DropdownTypesContainer className="me-2">
          {' '}
          <DateSelector
            isRequired
            // data-error={true}
            typeFilter={true}
            labelTop="Date from"
            value={values.start_date ? values.start_date : ''}
            //onChange={(val)=>onDateChange(val)}
            {...startDate}
          />
        </DropdownTypesContainer>
        <DropdownTypesContainer className="me-2">
          {' '}
          <DateSelector
            isRequired
            typeFilter={true}
            labelTop="Date to"
            value={values.end_date ? values.end_date : ''}
            // minDate={values.date_from}
            {...endDate}
          />
        </DropdownTypesContainer>
        <DropdownTypesContainer className="me-2">
          <DropdownSearchable
            typeFilter
            required
            placeHolder={'Frequency'}
            options={contextChoices && contextChoices.kpi}
            selectedOption={values.kpi}
            onChange={value =>
              dropDownHandleSelect({ kpi: value ? value : null })
            }
          />
        </DropdownTypesContainer>
        <PrimaryButtonForm minWidth="6rem" onClick={() => loadTeamKpiData()}>
          Search
        </PrimaryButtonForm>
      </div>
      <div className={'mb-3'}>
        {showError ? (
          <ErrorMessage>
            <label className={'mt-2'}>{errorMessage}</label>
          </ErrorMessage>
        ) : (
          <Fragment />
        )}
      </div>
      {values.one_day_data ?
        <div>
        <div className='mt-3'>
          <div className="d-flex justify-content-between">
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Total Family Administered</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.totalFamilyAdministered}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Total Fully Taken</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.totalFullyTaken}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Total Not Observed</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.totalNotObserved}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Total Not Reported</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.totalNotReported}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Total Not Taken</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.totalNotTaken}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-3'>
          <div className="d-flex justify-content-between">
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Total Partially Taken</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.totalPartiallyTaken}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">Total SelfAdministered</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.totalSelfAdministered}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">No. Medication Error</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.noMedicationError}</h4>
                </div>
              </div>
            </div>
            {/* <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">omitted/Missed Drug</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.omitted/MissedDrug}</h4>
                </div>
              </div>
            </div>
            <div>
              <div className="card text-center shadow" style={{ width: '200px', height: '120px' }}>
                <div className="card-body">
                  <p className="card-text">medicationExpired/UnfitForPurpose</p>
                  <h4 className="card-title">{values.one_day_data && values.one_day_data.medicationExpired/UnfitForPurpose
}</h4>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className='mt-2'>
          <div className="row">
          {values.one_day_data && !((values.one_day_data.totalCare === 0) && (values.one_day_data.totalNonCare === 0)) && 
            <div className="col-6">
              <div className='pie_chart_box'>
                <Chart
                  chartType="PieChart"
                  data={dataChartTotalIncorrectData}
                  options={optionsChartTotalIncorrectData}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
            </div>
          }
            {values.one_day_data && !((values.one_day_data.maleActiveEmployee === 0) && (values.one_day_data.femaleActiveEmployee === 0)) && 
            <div className="col-6">
              <div className='pie_chart_box'>
                <Chart
                  chartType="PieChart"
                  data={dataChartTotalData}
                  options={optionsChartTotalData}
                  width={"100%"}
                  height={"400px"}
                />
              </div>
            </div>}
          </div>
        </div>
        </div>
        :

        <div>
         
        
         <div className='mt-2'>
          {values.medication_total &&
            <div className='chart_box'> 
              <Chart
                chartType="LineChart"
                width="100%"
                height="500px"
                data={[["Dates", "Total Family Administered", "Total Fully Taken", "Total Not Observed", "Total Not Reported", "Total Not Taken", 'Total Partially Taken', 'Total Self Administered'],...values.medication_total]}
                options={optionsLineAvailabilityHrs}
              />
          </div>}
         </div>
          {values.medication_incorrect && <div className='mt-2'>
          <div className='chart_box'>
              <Chart
                chartType="Bar"
                width="95%"
                height="440px"
                style={{left:20,top:20,position:'relative'}}
                data={[["Date", "InCorrect Dose", "InCorrect Drug", "InCorrect Formulation", "InCorrect Label", "InCorrect Quantity"],...values.medication_incorrect]}
                options={{
                  chart: {
                    title: "Medication InCorrect",
                  },
                  legend: { position: "none",alignment: 'end' },
                }}
              />
            </div>
          </div>}
         
        </div>
      }
        </>
      )}
    </div>
  );
};

export default MedicationKpis;
