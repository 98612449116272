import React, {useState, useEffect, Fragment} from 'react';
import moment from 'moment';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import {useForm} from 'hooks/FormHook';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {
  setTriageApi,
  getTriageById,
  getNotificationData,
} from 'utils/api/AlertsApi';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  TabStyled,
  ShowTab,
} from 'shared/styles/constants/tagsStyles';
import {ERROR, SAVE, SAVED} from 'utils/constants/messages';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import MultiFileUpload from 'components/DashboardComponents/Alerts/AddModal/MultiFileUpload';
import CommentList from 'components/DashboardComponents/Alerts/AddModal/CommentList';
import DateSelector from 'components/SharedComponents/DateSelector';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import {getClientVisits} from 'utils/api/RegularVisitApi';
import {getVisitsId, getModifiedVisitsId} from 'utils/api/AlertsApi';
import {
  setTriageLabel,
  setOnlyforTriagePopupTriageLabel,
} from 'utils/api/SettingsApi';
import {PLUS, TIMES} from 'utils/constants/icons';
import NotificationRule from 'components/DashboardComponents/Alerts/AddModal/NotificationRule';

const ClientTriageModal = ({
  fullBind,
  idSelected,
  clientName,
  setTitle,
  type,
  alertStatus,
  severity,
  labels,
  employeeName,
  alertPrivacy,
  setModalValue,
  loadTriage,
  alerts,
  contextChoices,readonly,update,
  handleChangeChoices = () => {},
}) => {
  const {values, setValues, useInput, isValid, errors, setCheckRequires} =
    useForm({});
  const tab1 = 1;
  const tab2 = 2;
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [securityType, setSecurityType] = useState([]);
  const [clientsnameFilter, setClientNameFilter] = useState([]);
  const [visitIDData, setVisitIDData] = useState([]);
  const [timeRange, setTimeRange] = useState([]);
  const [showError, setShowError] = useState(false);
  const [tabSelected, setTabSelected] = useState(tab1);
  const [addedSkillMessage, setAddedSkillMessage] = useState('');
  // const edit_alert = 'Edit Alert';
  // const new_alerth = 'Add Alert';
  const edit_alert = 'Edit Triage';
  const new_alerth = 'Add Triage';

  useEffect(() => {
    if (idSelected) {
      handleChangeChoices();
      IndividualData();
      setTitle(edit_alert);
      setLoadingModal(false);
      values.id = idSelected;
      setValues({...values});
    } else {
      setTitle(new_alerth);
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);

  // useEffect(()=>{
  //   if(values.client && idSelected){
  //     getClientVisits(values.client)
  //         .then(res => {
  //    if(res){
  // let TimeData=res.data.map(time=>{
  // return{id: time.id, name:time.preferred_start_time+ '-'+time.preferred_end_time}
  // });
  // setTimeRange(TimeData);
  //    } } ) }
  //   setValues({...values});
  // },[values.client, idSelected])

  useEffect(() => {
    if (values.client && values.client_service_visit_date && contextChoices)
      visitCompleteData();
  }, [values.client, values.client_service_visit_date, idSelected]);
  

  const IndividualData = async () => {
    //    let x=  alerts?.map(data=>{
    //   if((data.id == idSelected) && data){
    //     console.log(data,75391) ;
    // values.alert_created_by = data && data.alert_created_by && data.alert_created_by;
    // values.employee_assigned=data?.employee_assigned?.map(x=>{return{value: x.employee_assigned, label:employeeName?.find(dataid=>dataid.id == x.employee_assigned)?.name}});
    // values.client=data && data.client && data.client;
    // values.tittle=data && data.tittle && data.tittle;
    // values.description=data && data.description && data.description;
    // values.alert_type=data && data.alert_type && data.alert_type;
    // values.alert_severity=data && data.alert_severity && data.alert_severity;
    // values.alert_status=data && data.alert_status && data.alert_status;
    // values.alert_privacy=data &&  data.alert_privacy && data.alert_privacy;
    // values.alert_label=  data.alert_label?.map(x=>{return{value: x.label, label:labels?.find(dataid=>dataid.id == x.label)?.name}});
    // if(data.reminder_notification){
    // values.reminder_notification_date=data.reminder_notification?.split('T')[0];
    // values.reminder_notification_time=moment(data.reminder_notification?.split('T')[1],'HH:mm').format('HH:mm');
    // }else{
    //   values.reminder_notification_date='';
    //   values.reminder_notification_time='';
    // }
    // values.post_alert_comment='' ;
    // if(data.file_upload.length >0){
    // values.file_upload=data.file_upload?.map((fileupload)=>{
    //   return{ id:fileupload.id , name:fileupload.file  }
    // });
    // }else{
    //  values.file_upload=[];
    // }
    // values.alert_comment=data.alert_comment.length>0 ? data.alert_comment: [] ;
    // setValues({...values});
    //       }
    //     })
    if (idSelected) {
      const data = await getTriageById(idSelected);

      values.alert_created_by =
        data && data.alert_created_by && data.alert_created_by;
      values.employee_assigned = data?.employee_assigned?.map(x => {
        return {
          value: x.employee_assigned,
          label: employeeName?.find(dataid => dataid.id == x.employee_assigned)
            ?.name,
          employee_assigned: x.employee_assigned,
          id: x.id,
        };
      });
      values.client = data && data.client && data.client;
      values.tittle = data && data.tittle && data.tittle;
      values.description = data && data.description && data.description;
      values.alert_type = data && data.alert_type && data.alert_type;
      values.alert_severity =
        data && data.alert_severity && data.alert_severity;
      values.alert_status = data && data.alert_status && data.alert_status;
      values.alert_privacy = data && data.alert_privacy && data.alert_privacy;
      values.alert_label = data.alert_label?.map(x => {
        return {
          value: x.label,
          label: labels?.find(dataid => dataid.id == x.label)?.name,
          id: x.id,
          labelid: x.label,
        };
      });
      if (data.reminder_notification) {
        values.reminder_notification_date =
          data.reminder_notification?.split('T')[0];
        values.reminder_notification_time = moment(
          data.reminder_notification?.split('T')[1],
          'HH:mm'
        ).format('HH:mm');
      } else {
        values.reminder_notification_date = '';
        values.reminder_notification_time = '';
      }
      values.post_alert_comment = '';
      if (data.file_upload.length > 0) {
        values.file_upload = data.file_upload?.map(fileupload => {
          return {id: fileupload.id, name: fileupload.file};
        });
      } else {
        values.file_upload = [];
      }

      values.alert_comment =
        data.alert_comment.length > 0 ? data.alert_comment : [];
      // values.client_service_visit_timerange=timeRange?.find(x=>x.id == data.client_service_visit)?.name;
      values.client_service_visit_timerange =
        data.client_service_visit && data.client_service_visit;
      values.client_service_visit_date = data && data.visit_date;
      values.visit = visitIDData?.find(
        getname => getname.id == values.visit
      )?.name;
      values.visit = data.visit && data.visit;
      values.no_of_days = data.no_of_days.days ? data.no_of_days.days : '';
      values.no_of_hrs = data.no_of_days.hours
        ? (Math.round(data.no_of_days.hours * 100) / 100).toFixed(2)
        : '';
      setValues({...values});
    }
  };

  const dropDownHandleSelect = (item, nameItem) => {
    //     if(nameItem == 'client'){
    //       getClientVisits(item.client)
    //           .then(res => {
    //      if(res){
    // let TimeData=res.data.map(time=>{
    //   return{id: time.id, name:time.preferred_start_time+ '-'+time.preferred_end_time}
    // });
    // setTimeRange(TimeData);
    //      }
    //         } ) }

    //     if(nameItem  == 'client_service_visit_timerange'){
    //    values.client_service_visit_timerange =item.client_service_visit_timerange;
    //     }

    setValues({...values, ...item});
  };

  const HandleMultiSelect = (preferred, diffKeyname) => {
    setValues({...values, ...preferred});
  };

  const deleteCommentList = (data, index) => {
    if (values.alert_comment) {
      values.alert_comment.splice(index, 1);
    }
    setValues({...values});
  };

  const employeeAssigned = values?.employee_assigned?.map(emp => {
    // return{ employee_id : emp.value }
    if (emp.id) {
      return {id: emp.id, employee_id: emp.employee_assigned};
    } else {
      return {employee_id: emp.value};
    }
  });

  const commentspatch = values?.alert_comment?.map(commentdata => {
    return {
      id: commentdata.id ? commentdata.id : '',
      comment: commentdata.comment,
    };
  });
  const file_upload_id = values?.file_upload?.map(files =>
    files.id ? files.id : ''
  );
  const date = values.reminder_notification_date
    ? moment(values.reminder_notification_date).format('YYYY-MM-DD')
    : '';
  const time = values.reminder_notification_time
    ? moment(values.reminder_notification_time, 'HH:mm').format('HH:mm:ss')
    : '';
  const dateAndTime = date && time ? date + 'T' + time : '';

  const visitCompleteData = async () => {
    if (values.client && values.client_service_visit_date) {
      const response = await getModifiedVisitsId(
        values.client,
        values.client_service_visit_date
      );

      let VisitDropdown = response?.data?.map(visitData => {
        return {
          id: visitData.id,
          name: visitData.job_title
            ? visitData.job_title +
              '' +
              ' ' +
              visitData.start_date?.split('T')[1] +
              '-' +
              visitData.end_date?.split('T')[1]
            : visitData.start_date?.split('T')[1] +
              '-' +
              visitData.end_date?.split('T')[1],
        };
      });

      setVisitIDData(VisitDropdown);
      setValues({...values});
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (loadingSave) {
      return;
    }

    if (values.alert_label) {
      let alertNewName = [];
      let data = values?.alert_label?.forEach(name => {
        if (name.className === 'Select-create-option-placeholder') {
          alertNewName.push(name.label);
        }
      });
      let arrIds = [];

      await setOnlyforTriagePopupTriageLabel(alertNewName)
        .then(response => {
          if (response) {
            let data = values?.alert_label?.forEach(dataname => {
              if (dataname.className === 'Select-create-option-placeholder') {
                let alertNewIds = response.data.find(
                  alertData => alertData.name == dataname.label
                ).id;
                delete dataname.value;
                arrIds.push({...dataname, value: alertNewIds});
              } else {
                arrIds.push({...dataname});
              }
            });
            values.alert_label_data = arrIds.map(label => {
              if (label.id) {
                return {id: label.id, label_id: label.value};
              } else if (
                label.className === 'Select-create-option-placeholder'
              ) {
                return {label_id: label.value};
              } else {
                return {label_id: label.value};
              }
            });
          }
        })
        .catch(onError);
    }

    if (!values.id && values.post_alert_comment) {
      values.comments = [{comment: values.post_alert_comment}];
      setValues({...values});
    } else if (values.id && values.post_alert_comment) {
      values.comments = [
        {comment: values.post_alert_comment},
        ...commentspatch,
      ];
      setValues({...values});
    }

    if (
      !values.alert_severity ||
      !values.alert_type ||
      !values.alert_status ||
      !values.tittle
    ) {
      onError('Please fill the  mandatory fields ');
    } else {
      const bodyData = {
        employee_assigned: employeeAssigned,
        tittle: values.tittle ? values.tittle : '',
        // alert_label: alertLabel? alertLabel : '',
        alert_label: values.alert_label_data ? values.alert_label_data : '',
        alert_comment: values.comments ? values.comments : '',
        reminder_notification: dateAndTime ? dateAndTime : '',
        alert_privacy: values.alert_privacy ? values.alert_privacy : '',
        client: values.client ? values.client : '',
        alert_severity: values.alert_severity ? values.alert_severity : '',
        description: values.description ? values.description : '',
        alert_type: values.alert_type ? values.alert_type : '',
        alert_status: values.alert_status ? values.alert_status : '',
        visit: values.visit ? values.visit : '',
        client_service_visit: values.client_service_visit_timerange
          ? values.client_service_visit_timerange
          : '',
        visit_date: values.client_service_visit_date
          ? values.client_service_visit_date
          : '',
        alert_created_by: values.alert_created_by
          ? values.alert_created_by
          : '',
        file_upload_id: file_upload_id,
        file_upload: values?.file_upload ? values.file_upload : null,
      };

      setLoadingSave(true);
      setTriageApi(bodyData, values.id)
        .then(response => {
          if (response.message && response?.message?.message) {
            onError(response?.message?.message);
          } else {
            onSuccess(response);
          }
        })
        .catch(onError);
    }
  };

  const onSuccess = response => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setModalValue(true);
    handleChangeChoices();
    loadTriage();
  };

  const onError = err => {
    let getErrorMessage = err ? err : 'something went wrong !!';
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(getErrorMessage);
  };
  const checkTabSwitch = async tabName => {
    setTabSelected(tabName);
    if (tabName === tab2) {
      const response = await getNotificationData(idSelected);
      if (response) {
        values.notificationrule = response.results;
        setValues({...values});
      }
    }
  };

  const isCurrentTab = tab => {
    return tabSelected === tab;
  };

  const addNewnotificationrule = () => {
    if (!values.notificationrule) {
      values.notificationrule = [];
      values.notificationrule.push({});
      setValues({...values});
      setAddedSkillMessage('');
    } else {
      values.notificationrule.push({});
      setValues({...values});
    }
  };

  const dropDownHandleNotificationrule = (item, nameItem, index) => {
    values.notificationrule[index][nameItem] = item[nameItem];
    setValues({...values});
  };
  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        {idSelected && (
          <div className="p-2 p-sm-3 p-md-4">
            <div className="d-flex justify-content-around text-center">
              <TabStyled
                active={isCurrentTab(tab1)}
                onClick={() => checkTabSwitch(tab1)}>
                Triage
              </TabStyled>

              <TabStyled
                active={isCurrentTab(tab2)}
                onClick={() => checkTabSwitch(tab2)}>
                Notification rule
              </TabStyled>
            </div>
          </div>
        )}

        <ShowTab show={isCurrentTab(tab1)}>
          <div className="ps-3 pe-3 pb-3 pt-0">
            <FlexGrid3Container className={'w-100 mb-2'}>
              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'50%'}>
                <DropdownSearchable
                disabled={readonly}
                  placeHolder={'Client'}
                  options={clientName ? clientName : []}
                  selectedOption={values.client}
                  onChange={value =>
                    dropDownHandleSelect(
                      {client: value ? value : null},
                      'client'
                    )
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'50%'}>
                <DropdownSearchable
                                disabled={readonly}

                  placeHolder={'Employee'}
                  options={employeeName ? employeeName : []}
                  selectedOption={values.alert_created_by}
                  onChange={value =>
                    dropDownHandleSelect({
                      alert_created_by: value ? value : null,
                    })
                  }
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'50%'}>
                <DateSelector
                                disabled={readonly}

                  labelTop="Client service visit date"
                  {...useInput(`client_service_visit_date`)}
                />
              </FlexGrid3Item>

              {/* <FlexGrid3Item className="pe-1 mt-1 " ResizeWidth={'25%'}>
            <DropdownSearchable
              //   required
              //   {...useInput(
              //    `visit`,
              //    'isRequired'
              //  )}
                placeHolder={'Client service  visit'}
                width={'100%'}
                options={timeRange?timeRange:[]}
                selectedOption={
                  values ? values.client_service_visit_timerange : null
                }
                onChange={value =>
                  dropDownHandleSelect(
                    {client_service_visit_timerange: value ? value : null}, 'client_service_visit_timerange'
                  )
                }
              />
            </FlexGrid3Item> */}

              <FlexGrid3Item className="pe-1 mt-1 " ResizeWidth={'50%'}>
                <DropdownSearchable
                                disabled={readonly}

                  //   required
                  //   {...useInput(
                  //    `visit`,
                  //    'isRequired'
                  //  )}
                  placeHolder={'visit'}
                  width={'100%'}
                  options={visitIDData ? visitIDData : []}
                  selectedOption={values ? values.visit : null}
                  onChange={value =>
                    dropDownHandleSelect({visit: value ? value : null}, 'visit')
                  }
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pe-1 mt-1 " ResizeWidth={'50%'}>
                <InputLabelTop
                                disabled={readonly}

                  required
                  type="text"
                  id="name"
                  label={'Triage title'}
                  {...useInput('tittle', 'isRequired')}
                  maxlength={50}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'50%'}>
                <DropdownSearchable
                                disabled={readonly}

                  required
                  {...useInput(`alert_type`, 'isRequired')}
                  placeHolder={'Type'}
                  options={type ? type : []}
                  selectedOption={values.alert_type}
                  onChange={value =>
                    dropDownHandleSelect({alert_type: value ? value : null})
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 mb-2" ResizeWidth={'100%'}>
                <small className="pe-1 " style={{color: 'black'}}>
                  Triage Description
                </small>
                <InputTextArea
                                disabled={readonly}

                  type="text"
                  height={'4rem'}
                  id="description"
                  label={'Triage Description'}
                  {...useInput('description')}
                  maxlength={5}
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
                <DropdownSearchable
                                disabled={readonly}

                  required
                  {...useInput(`alert_severity`, 'isRequired')}
                  placeHolder={'Severity'}
                  options={severity ? severity : []}
                  selectedOption={values.alert_severity}
                  onChange={value =>
                    dropDownHandleSelect({alert_severity: value ? value : null})
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
                <DropdownSearchable
                                disabled={readonly}

                  required
                  {...useInput(`alert_status`, 'isRequired')}
                  placeHolder={'Triage Status'}
                  options={alertStatus ? alertStatus : []}
                  selectedOption={values.alert_status}
                  onChange={value =>
                    dropDownHandleSelect({alert_status: value ? value : null})
                  }
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
                <DropdownSearchable
                                disabled={readonly}

                  placeHolder={'Privacy Type'}
                  options={alertPrivacy ? alertPrivacy : []}
                  selectedOption={values.alert_privacy}
                  onChange={value =>
                    dropDownHandleSelect({alert_privacy: value ? value : null})
                  }
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'100%'}>
                <DropdownSearchable
                                disabled={readonly}

                  isCreate={true}
                   // to add items not present in the options(dropdown items)
                  isMulti={true}
                  placeHolder={'Labels'}
                  options={labels ? labels : []}
                  selectedOptionsProps={values?.alert_label}
                  selectedOption={values?.alert_label}
                  onChange={value =>
                    HandleMultiSelect(
                      {alert_label: value ? value : null},
                      
                    )
                  }
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'100%'}>
                <DropdownSearchable
                                disabled={readonly}

                  isMulti={true}
                  placeHolder={'Employees Assigned'}
                  options={employeeName}
                  selectedOptionsProps={values.employee_assigned}
                  selectedOption={values.employee_assigned}
                  onChange={value =>
                    HandleMultiSelect({employee_assigned: value ? value : null})
                  }
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
                <DateSelector
                                disabled={readonly}

                  labelTop="reminder / notification date"
                  {...useInput(`reminder_notification_date`)}
                />
              </FlexGrid3Item>

              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
                <TimeSelector
                                disabled={readonly}

                  labelTop="reminder / notification time"
                  {...useInput(`reminder_notification_time`)}
                  timeFormat={'HH:mm'}
                  dateFormat={'HH:mm'}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'33.33%'}>
                <InputLabelTop
                  disabled={true}
                  type="text"
                  id="no_of_days"
                  label={
                    values.no_of_days > 0 ? 'Number of days' : 'Number of hours'
                  }
                  {...useInput(
                    values.no_of_days > 0 ? 'no_of_days' : 'no_of_hrs'
                  )}
                />
              </FlexGrid3Item>
{update&&(
              <FlexGrid3Item className="pe-1 mt-1 mb-1" ResizeWidth={'100%'}>

                <MultiFileUpload
                          
                            

                  setValues={setValues}
                  values={values}
                  MAX_COUNT={20}
                  keyName={'file_upload'}
                />
              </FlexGrid3Item>
)}
              <FlexGrid3Item className="pe-1 mt-2 mb-2" ResizeWidth={'100%'}>
                <InputTextArea
                                disabled={readonly}

                  type="text"
                  height={'2.5rem'}
                  id="Comments"
                  placeholder={'New comments ....'}
                  {...useInput('post_alert_comment')}
                />
              </FlexGrid3Item>

              {values.alert_comment?.length > 0 ? (
                <FlexGrid3Item
                disabled={readonly}

                  className="pe-1 pt-1 mt-1 mb-2"
                  ResizeWidth={'100%'}
                  style={{
                    maxHeight: '160px',
                    overflowY: 'auto',
                    height: '160px',
                  }}>
                  {values.alert_comment?.length > 0 ? (
                    <small className="pe-1 ">Previous comments</small>
                  ) : null}
                  {values.alert_comment?.length > 0 &&
                    values.alert_comment?.map((commentData, index) => {
                      return (
                        <div key={commentData.id}>
                          <CommentList
                            commentData={commentData}
                            index={index}
                            deleteCommentList={deleteCommentList}
                            useInput={useInput}
                            values={values}
                            setValues={setValues}
                            employeeName={employeeName}
                            contextChoices={contextChoices}
                          />
                        </div>
                      );
                    })}
                </FlexGrid3Item>
              ) : null}
            </FlexGrid3Container>

            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {errorMessage ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>
              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                <div className={'ms-0 ms-md-3 me-2'}>
                  
                  <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit} disabled={readonly &&!update?true:false}>
                    <span className={'me-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                  
                </div>
              </div>
            </div>
          </div>
        </ShowTab>

        <ShowTab show={isCurrentTab(tab2)}>
          <div className="ps-3 pe-3 pb-3 ">
            <div className={'mb-2 pb-2 '}>
              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                <div className={'mt-3'}>
                  
                  <PrimaryButtonForm
                  disabled={readonly &&!update?true:false}
                    minWidth="6rem"
                    onClick={addNewnotificationrule}>
                    <span>{PLUS} Notification rule</span>
                  </PrimaryButtonForm>
                
                </div>
              </div>
            </div>
            {values.notificationrule?.length >= 1 &&
              values.notificationrule?.map((data, index) => {
                return (
                  <div key={data.id}>
                    <NotificationRule
                      data={data}
                      index={index}
                      values={values}
                      setValues={setValues}
                      addNewnotificationrule={addNewnotificationrule}
                      dropDownHandleNotificationrule={
                        dropDownHandleNotificationrule
                      }
                      addedSkillMessage={addedSkillMessage}
                      idSelected={idSelected}
                      setModalValue={setModalValue}
                      loadTriage={loadTriage}
                      setErrorMessage={setErrorMessage}
                      setShowError={setShowError}
                      setCheckRequires={setCheckRequires}
                      useInput={useInput}
                      errorMessage={errorMessage}
                    />
                  </div>
                );
              })}
          </div>
        </ShowTab>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default ClientTriageModal;
