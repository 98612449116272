import React from 'react'
import { TitleSection } from 'shared/styles/constants/tagsStyles'
import "./AccrualStyle.css"
import { PrimaryButtonForm, Toggle, ToggleLabel, ToggleWrapper } from 'shared/styles/buttons'
import { useState } from 'react'
import { getAccrualSetting, updateAccrualSetting } from 'utils/api/SettingsApi'
import { useEffect } from 'react'
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic'
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable'
import { useForm } from 'hooks/FormHook'
import { Toast } from 'primereact/toast'
import { useRef } from 'react'
import _ from 'lodash'
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles'
import { ERROR, SAVE, SAVED, SERVER_ERROR } from 'utils/constants/messages'
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons'
import InputLabelTop from 'components/SharedComponents/InputLabelTop'
import { KeyPress } from 'utils/constants/regularExpressions'
import { useContext } from 'react'
import { AppContext } from 'context'
import { settings_accrual_leaves_choices } from 'utils/choiceConstant'

function AccrualLeaves({
  branch,
  history,
  choices,
  readPermission,
  deletePermission,
  readOnly,
}) {
  const toast = useRef(null);
  const { values, setValues, useInput, setCheckRequires, errors } = useForm({ visit_status: [], travel_status: [], body: {} });
  const { handleGetChoices, contextChoices } = useContext(AppContext)
  const [accrualData, setAccrualData] = useState()
  const [isShowIncludesCancel, setIsShowIncludesCancel] = useState({ client: false, location: false })
  const [loadingValue, setLoadingValue] = useState(true)
  const [canceledVisitStatusId, setCanceledVisitStatusId] = useState(null)
  const [textStatus, setTextStatus] = useState(SAVE);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [loadingSave, setLoadingSave] = useState(false);
  const [alHoursTypeOption, setAlHoursTypeOption] = useState([])
  const [commuteSettings, setCommuteSettings] = useState([
    { name: "Home-Client", travelTime: false, },
    { name: "Home-Office", travelTime: false, },
    { name: "Office-Client", travelTime: false, },
    { name: "Office-Home", travelTime: false, },
    { name: "Office-Office", travelTime: false, },
    { name: "Client-Client", travelTime: false, },
    { name: "Client-Office", travelTime: false, },
    { name: "Client-Home", travelTime: false, },
  ])


  useEffect(() => {
    handleGetChoices(settings_accrual_leaves_choices);
  }, []);

  const getAccrualSettingList = async () => {
    try {
      const res = await getAccrualSetting(branch?.id)
      if (res.status) {

        values.consider_all_travel_commute = res.consider_all_travel_commute || false
        values.al_capped_hours = res?.data?.al_capped_hours;
        values.al_hours_type = res?.data?.al_hours_type;
        setAccrualData(res.data)
        values.visit_status = res?.data?.scheduleHrsVisitStatus[0] !== null
          ? res?.data?.scheduleHrsVisitStatus?.map(item => item.visit_status) : []
        values.travel_status = res?.data?.travelHrsVisitStatus[0] !== null
          ? res?.data?.travelHrsVisitStatus?.map(item => item.visit_status) : []

        values.location_visit_status = res?.data?.locationScheduleVisitHrs[0] !== null
          ? res?.data?.locationScheduleVisitHrs.map(item => item.visit_status) : []

        values.location_travel_status = res?.data?.locationTravelVisitHrs[0] !== null
          ? res?.data?.locationTravelVisitHrs.map(item => item.visit_status) : []

        setValues({ ...values });
        updateValues(res.data)
        updateTravelSettingState(
          !!res.data.non_commute_travel_accrual_leave
            ? res.data.non_commute_travel_accrual_leave
            : {})
        setLoadingValue(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const updateTravelSettingState = (item) => {
    let data = commuteSettings.map(setting => ({
      ...setting,
      id: item.id,
      travelTime: item[`${splitName(setting.name)}_travel`] || false,
    }))
    setCommuteSettings(data)
  }

  function updateValues(data) {

    const updateField = (field) => {
      const keys = [`${field}_scheduled`, `${field}_actuals`, `${field}_rounded_actuals`, `${field}_banded_actuals`];
      for (const key of keys) {
        if (data[key]) {
          values[field] = key;
          return;
        }
      }
      values[field] = null;
    };

    // Visit hours
    updateField('visit_hours');
    updateField('location_visit_hours');

    // Travel hours
    updateField('travel_hours');
    updateField('location_travel_hours');

    // Office hours
    updateField('office_hours');

    // Wait contracted
    if (data.wait_hours_scheduled) {
      values.wait_contracted = 'wait_hours_scheduled';
    } else if (data.contracted_hours) {
      values.wait_contracted = 'contracted_hours';
    } else {
      values.wait_contracted = null;
    }
    setValues({ ...values })
  }

  useEffect(() => {
    let opt = contextChoices?.accrual_hours_type && contextChoices?.accrual_hours_type?.map(el => ({ id: el, name: el }))
    setAlHoursTypeOption(opt || [])
  }, [contextChoices?.accrual_hours_type])

  useEffect(() => {
    getAccrualSettingList()
  }, [])

  useEffect(() => {
    let cancelledId = choices?.visit_status?.find((el) => el.name === "Cancelled")
    if (cancelledId)
      setCanceledVisitStatusId(cancelledId?.id)
  }, [choices.visit_status])

  const updateAccrualLeaveSetting = async (body) => {
    if (loadingSave) {
      return;
    }
    // const body = {}
    // body[accrualName] = !status
    setLoadingSave(true)

    try {
      const res = await updateAccrualSetting(branch?.id, body)
      if (res.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${res.message}`,
        })
        onSuccess()
      }
    } catch (error) {
      onError(error)
    }
  }

  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    // getAccrualSettingList()
  }

  const onError = err => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
  };

  const handleChange = (accrualName, status) => {

    const body = {};

    ///   Updated code
    //visit hours
    body['visit_hours_scheduled'] = (values.visit_hours === 'visit_hours_scheduled');
    body['visit_hours_actuals'] = (values.visit_hours === 'visit_hours_actuals')
    body['visit_hours_rounded_actuals'] = (values.visit_hours === 'visit_hours_rounded_actuals')
    body['visit_hours_banded_actuals'] = (values.visit_hours === 'visit_hours_banded_actuals')
    // travel hours
    body['travel_hours_scheduled'] = (values.travel_hours === 'travel_hours_scheduled')
    body['travel_hours_actuals'] = (values.travel_hours === 'travel_hours_actuals')

    //Location visit hours
    body['location_visit_hours_scheduled'] = (values.location_visit_hours === 'location_visit_hours_scheduled');
    body['location_visit_hours_actuals'] = (values.location_visit_hours === 'location_visit_hours_actuals')
    body['location_visit_hours_rounded_actuals'] = (values.location_visit_hours === 'location_visit_hours_rounded_actuals')
    body['location_visit_hours_banded_actuals'] = (values.location_visit_hours === 'location_visit_hours_banded_actuals')
    //Location travel hours
    body['location_travel_hours_scheduled'] = (values.location_travel_hours === 'location_travel_hours_scheduled')
    body['location_travel_hours_actuals'] = (values.location_travel_hours === 'location_travel_hours_actuals')

    //office_hours
    body['office_hours_scheduled'] = (values.office_hours === 'office_hours_scheduled')
    body['office_hours_actuals'] = (values.office_hours === 'office_hours_actuals')
    body['office_hours_rounded_actuals'] = (values.office_hours === 'office_hours_rounded_actuals')
    body['office_hours_banded_actuals'] = (values.office_hours === 'office_hours_banded_actuals')
    //Wait & Contracted
    body['wait_hours_scheduled'] = (values.wait_contracted === 'wait_hours_scheduled')
    body['contracted_hours'] = (values.wait_contracted === 'contracted_hours')

    if (accrualName === "include_cancelled") {
      body['include_cancelled'] = accrualName === 'include_cancelled' ? !status : false
    }
    if (accrualName === "consider_all_travel_commute") {
      body['consider_all_travel_commute'] = accrualName === 'consider_all_travel_commute' ? !status : false
    }
    if (accrualName === 'location_include_cancelled') {
      body['location_include_cancelled'] = accrualName === 'location_include_cancelled' ? !status : false
    }
    if (accrualName === "location_consider_all_travel_commute") {
      body['location_consider_all_travel_commute'] = accrualName === 'location_consider_all_travel_commute' ? !status : false
    }


    setAccrualData({ ...accrualData, ...body });
  };

  useEffect(() => {
    if (values?.visit_status?.length && values?.travel_status?.length) {
      setCheckRequires(false)
    }
    handleChange('accrualName', 'status')
  }, [values])

  const handleSubmit = async () => {
    if (errors.length) {
      setCheckRequires(true);
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Please fill the mandatory fields!`,
      });
      return;
    }


    const body = { ...accrualData };
    delete body.non_commute_travel_accrual_leave;

    let non_commute_travel_accrual_leave = {};
    commuteSettings.forEach(setting => {
      non_commute_travel_accrual_leave['id'] = setting.id;

      non_commute_travel_accrual_leave[`${splitName(setting.name)}_travel`] = setting.travelTime;

    });

    body.branch = branch?.id;
    body.scheduleHrsVisitStatus = values.visit_status.map(id => ({ visit_status: id }));
    body.travelHrsVisitStatus = values.travel_status.map(id => ({ visit_status: id }));

    body.locationScheduleHrsVisitStatus = values.location_visit_status.map(id => ({ visit_status: id }));
    body.locationTravelHrsVisitStatus = values.location_travel_status.map(id => ({ visit_status: id }));

    if (body.include_cancelled && !values.visit_status.length) {
      delete body.include_cancelled;
    }
    // for location
    if (body.location_include_cancelled && !values.location_visit_status.length) {
      delete body.location_include_cancelled;
    }

    if (!accrualData.consider_all_travel_commute) {
      body.non_commute_travel_accrual_leave = non_commute_travel_accrual_leave;
    }
    if (values.al_capped_hours) {
      body.al_capped_hours = values.al_capped_hours
    }
    if (values.al_capped_hours) {
      body.al_capped_hours = values.al_capped_hours
    } else {
      body.al_capped_hours = null;
    }
    if (values.al_hours_type) {
      body.al_hours_type = values.al_hours_type
    } else {
      body.al_hours_type = null;
    }
    await updateAccrualLeaveSetting(body);
  };


  const dropDownHandleSelect = (item, name) => {
    // if (name === 'visit_status') {
    //   values.visit_status = item.visit_status.map((item) => (item.value));
    // }
    // else if (name === "travel_status") {
    //   values.travel_status = item.travel_status.map((item) => (item.value));
    // }
    values[name] = item[name].length && item[name].map((item) => (item.value))
    setValues({ ...values })
  }

  useEffect(() => {
    let hasClientCancelledStatus = values?.visit_status && values?.visit_status?.includes(canceledVisitStatusId)
    hasClientCancelledStatus
      ? setIsShowIncludesCancel(prev => ({ ...prev, client: true }))
      : setIsShowIncludesCancel(prev => ({ ...prev, client: false }))
    let hasLocatiuonCancelledStatus = values?.location_visit_status && values?.location_visit_status?.includes(canceledVisitStatusId)
    hasLocatiuonCancelledStatus
      ? setIsShowIncludesCancel(prev => ({ ...prev, location: true }))
      : setIsShowIncludesCancel(prev => ({ ...prev, location: false }))
  }, [values?.visit_status, values?.location_visit_status, canceledVisitStatusId])

  const travelHoursDropdown = [
    { id: 'travel_hours_scheduled', name: "Scheduled" },
    { id: 'travel_hours_actuals', name: "Actuals" }
  ]

  const locationTravelHoursDropdown = [
    { id: 'location_travel_hours_scheduled', name: "Scheduled" },
    { id: 'location_travel_hours_actuals', name: "Actuals" }
  ]
  const visitHoursDropdown = [
    { id: 'visit_hours_scheduled', name: "Scheduled" },
    { id: 'visit_hours_actuals', name: "Actuals" },
    { id: 'visit_hours_rounded_actuals', name: "Rounded Actuals" },
    { id: 'visit_hours_banded_actuals', name: "Banded Actuals" },
  ]

  const locationVisitHoursDropdown = [
    { id: 'location_visit_hours_scheduled', name: "Scheduled" },
    { id: 'location_visit_hours_actuals', name: "Actuals" },
    { id: 'location_visit_hours_rounded_actuals', name: "Rounded Actuals" },
    { id: 'location_visit_hours_banded_actuals', name: "Banded Actuals" },
  ]

  const officeHoursDropdown = [
    { id: 'office_hours_scheduled', name: "Scheduled" },
    { id: 'office_hours_actuals', name: "Actuals" },
    { id: 'office_hours_rounded_actuals', name: "Rounded Actuals" },
    { id: 'office_hours_banded_actuals', name: "Banded Actuals" },
  ]

  const waitContractedDropdown = [
    { id: 'wait_hours_scheduled', name: "Scheduled" },
    { id: 'contracted_hours', name: "Contracted" }
  ]

  const dropDownHandleSingleSelect = (item, name) => {
    values[name] = item[name];
    setValues({ ...values });
  };

  const splitName = (name) => {
    const arr = name.split("-")
    let n = arr[0].toLowerCase() + '_to_' + arr[1].toLowerCase()
    return n
  }

  const handleChangeCommute = (name, value, index) => {
    commuteSettings[index][name] = !value
    setCommuteSettings([...commuteSettings])
  }


  const visitStatusInput = useInput(`visit_status`, 'isRequired')
  const locationVisitStatusInput = useInput(`location_visit_status`, 'isRequired')
  const travelStatusInput = useInput(`travel_status`, 'isRequired')
  const locationTravelStatusInput = useInput(`location_travel_status`, 'isRequired')
  const travelHoursInput = useInput(`travel_hours`, 'isRequired') // location_travel_hours
  const locationTravelHoursInput = useInput(`location_travel_hours`, 'isRequired') // location_travel_hours
  const visitHoursInput = useInput(`visit_hours`, 'isRequired') //visit_hours  location_visit_hours
  const locationVisitHoursInput = useInput(`location_visit_hours`, 'isRequired') //visit_hours  
  const waitContractedInput = useInput(`wait_contracted`, 'isRequired')
  const al_capped_hours_hook = useInput(`al_capped_hours`, 'isRequired', 'isRequired')
  const al_hours_type_hook = useInput(`al_hours_type`, 'isRequired')

  console.log({ values, isShowIncludesCancel }, "values")
  return (
    <>
      {readPermission ? (
        <div className='w-100' style={{ marginTop: "10rem" }}>
          <Toast ref={toast} position='top-right' />
          <ComponentDynamic loading={loadingValue}>
            <div className='bg-white p-3 mb-4'>
              {accrualData !== undefined ?
                <div className='accrual-main'>

                  <TitleSection>
                    Client Visit Hours
                  </TitleSection>
                  <div className='d-flex justify-content-start flex-wrap mb-3'>
                    <div style={{ width: '300px' }} className='d-flex me-2 mt-3'>
                      <div className='w-100'>
                        <DropdownSearchable
                          required
                          style={{ width: "100%" }}
                          isMulti={true}
                          {...visitStatusInput}
                          placeHolder={'Visit Status'}
                          className="w-100"
                          options={choices.visit_status || []}
                          disabled={readOnly}
                          selectedOptionsProps={values.visit_status ? values.visit_status : null}
                          onChange={value =>
                            dropDownHandleSelect(
                              { visit_status: value ? value : null },
                              'visit_status'
                            )
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: '300px' }} className='d-flex me-2 mt-3'>
                      <div className='w-100'>
                        <DropdownSearchable
                          required
                          {...visitHoursInput}
                          placeHolder={'Visit Hours'}
                          disabled={readOnly}
                          options={visitHoursDropdown}
                          selectedOption={values.visit_hours ? values.visit_hours : null}
                          onChange={value =>
                            dropDownHandleSingleSelect({ visit_hours: value ? value : null }, 'visit_hours')
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: '300px' }} className=' d-flex mt-3'>
                      {isShowIncludesCancel.client ? <>
                        <div>
                          <ToggleWrapper>
                            <Toggle
                              id={`include_cancelled`}
                              type="checkbox"
                              disabled={readOnly}
                              checked={accrualData?.include_cancelled}
                              onClick={() => {
                                handleChange('include_cancelled', accrualData?.include_cancelled)
                              }}
                            />
                            <ToggleLabel htmlFor={`include_cancelled`} />
                          </ToggleWrapper>
                        </div>
                        <small style={{ marginLeft: '10px' }}>
                          <b>
                            <TitleSection>
                              Includes only billed once
                            </TitleSection>
                          </b>
                        </small>
                      </> : null}
                    </div>
                  </div>


                  <TitleSection>
                    Client Travel Hours
                  </TitleSection>
                  <div className='d-flex justify-content-start flex-wrap mb-3'>
                    <div style={{ width: '300px' }} className='d-flex me-2  mt-3'>
                      <div className='w-100'>
                        <DropdownSearchable
                          required
                          style={{ width: "100%" }}
                          isMulti={true}
                          {...travelStatusInput}
                          placeHolder={'Travel Status'}
                          className="w-100"
                          options={choices.visit_status || []}
                          disabled={readOnly}
                          selectedOptionsProps={
                            values.travel_status
                              ? values.travel_status
                              : null

                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              { travel_status: value ? value : null },
                              'travel_status'
                            )
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: '300px' }} className='d-flex me-2  mt-3'>
                      <div className='w-100'>
                        <DropdownSearchable
                         required
                          {...travelHoursInput}
                          placeHolder={'Travel Hours'}
                          disabled={readOnly}
                          options={travelHoursDropdown}
                          selectedOption={values.travel_hours ? values.travel_hours : null}
                          onChange={value =>
                            dropDownHandleSingleSelect({ travel_hours: value ? value : null }, 'travel_hours')
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: "230px" }} className=' d-flex  mt-3'>
                      <div>
                        <ToggleWrapper>
                          <Toggle
                            id={`consider_all_travel_commute`}
                            type="checkbox"
                            disabled={readOnly}
                            checked={accrualData.consider_all_travel_commute}
                            onClick={() => {
                              handleChange('consider_all_travel_commute', accrualData?.consider_all_travel_commute)
                            }}
                          />
                          <ToggleLabel htmlFor={`consider_all_travel_commute`} />
                        </ToggleWrapper>
                      </div>
                      <small style={{ marginLeft: '10px' }}>
                        <b>
                          <TitleSection>
                            Consider all Travel Commute
                          </TitleSection>
                        </b>
                      </small>
                    </div>
                  </div>


                  <TitleSection>Location Visit Hours</TitleSection>
                  <div className='d-flex justify-content-start flex-wrap mb-3'>
                    <div style={{ width: '300px' }} className='d-flex me-2 mt-3'>
                      <div className='w-100'>
                        <DropdownSearchable
                          required
                          style={{ width: "100%" }}
                          isMulti={true}
                          {...locationVisitStatusInput}
                          placeHolder={'Visit Status'}
                          className="w-100"
                          options={choices.visit_status || []}
                          disabled={readOnly}
                          selectedOptionsProps={values.location_visit_status ? values.location_visit_status : null}
                          onChange={value =>
                            dropDownHandleSelect(
                              { location_visit_status: value ? value : null },
                              'location_visit_status'
                            )
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: '300px' }} className='d-flex me-2 mt-3'>
                      <div className='w-100'>
                        <DropdownSearchable
                          required
                          {...locationVisitHoursInput}
                          placeHolder={'Visit Hours'}
                          disabled={readOnly}
                          options={locationVisitHoursDropdown}
                          selectedOption={values.location_visit_hours ? values.location_visit_hours : null}
                          onChange={value =>
                            dropDownHandleSingleSelect({ location_visit_hours: value ? value : null }, 'location_visit_hours')
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: '300px' }} className=' d-flex mt-3'>
                      {isShowIncludesCancel.location ? <>
                        <div>
                          <ToggleWrapper>
                            <Toggle
                              id={`location_include_cancelled`}
                              type="checkbox"
                              disabled={readOnly}
                              checked={accrualData?.location_include_cancelled}
                              onClick={() => {
                                handleChange('location_include_cancelled', accrualData?.location_include_cancelled)
                              }}
                            />
                            <ToggleLabel htmlFor={`location_include_cancelled`} />
                          </ToggleWrapper>
                        </div>
                        <small style={{ marginLeft: '10px' }}>
                          <b>
                            <TitleSection>
                              Includes only billed once
                            </TitleSection>
                          </b>
                        </small>
                      </> : null}
                    </div>
                  </div>


                  <TitleSection>Location Travel Hours</TitleSection>
                  <div className='d-flex justify-content-start flex-wrap mb-3'>
                    <div style={{ width: '300px' }} className='d-flex me-2  mt-3'>
                      <div className='w-100'>
                        <DropdownSearchable
                          required
                          style={{ width: "100%" }}
                          isMulti={true}
                          {...locationTravelStatusInput}
                          placeHolder={'Travel Status'}
                          className="w-100"
                          options={choices.visit_status || []}
                          disabled={readOnly}
                          selectedOptionsProps={
                            values.location_travel_status
                              ? values.location_travel_status
                              : null

                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              { location_travel_status: value ? value : null },
                              'location_travel_status'
                            )
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: '300px' }} className='d-flex me-2  mt-3'>
                      <div className='w-100'>
                        <DropdownSearchable
                        required
                          {...locationTravelHoursInput}
                          placeHolder={'Travel Hours'}
                          disabled={readOnly}
                          options={locationTravelHoursDropdown}
                          selectedOption={values.location_travel_hours ? values.location_travel_hours : null}
                          onChange={value =>
                            dropDownHandleSingleSelect({ location_travel_hours: value ? value : null }, 'location_travel_hours')
                          }
                        />
                      </div>
                    </div>
                    <div style={{ width: "230px" }} className=' d-flex  mt-3'>
                      <div>
                        <ToggleWrapper>
                          <Toggle
                            id={`location_consider_all_travel_commute`}
                            type="checkbox"
                            disabled={readOnly}
                            checked={accrualData.location_consider_all_travel_commute}
                            onClick={() => {
                              handleChange('location_consider_all_travel_commute', accrualData?.location_consider_all_travel_commute)
                            }}
                          />
                          <ToggleLabel htmlFor={`location_consider_all_travel_commute`} />
                        </ToggleWrapper>
                      </div>
                      <small style={{ marginLeft: '10px' }}>
                        <b>
                          <TitleSection>
                            Consider all Travel Commute
                          </TitleSection>
                        </b>
                      </small>
                    </div>
                  </div>



                  <div>
                    {!accrualData.consider_all_travel_commute && (
                      <table width={'40%'} >
                        <thead>
                          <tr>
                            <td style={{
                              fontSize: "1rem",
                              fontWeight: 600,
                              borderCollapse: 'collapse',
                              border: 'none'
                            }} className='header-text'>Non Commute Travel</td>
                            <td style={{
                              fontSize: "1rem",
                              fontWeight: 600,
                              borderCollapse: 'collapse',
                              border: 'none'
                            }} className='header-text'>Travel Time</td>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            commuteSettings.map((item, index) => {
                              return (<tr>
                                <td>{item.name}</td>
                                <td>
                                  <ToggleWrapper>
                                    <Toggle
                                      id={`travel-time-${index}`}
                                      type="checkbox"
                                      disabled={readOnly}
                                      checked={item.travelTime}
                                      onClick={() => {
                                        handleChangeCommute('travelTime', item.travelTime, index)
                                      }}
                                    // disabled={readOnly}
                                    />
                                    <ToggleLabel htmlFor={`travel-time-${index}`} />
                                  </ToggleWrapper>
                                </td>
                              </tr>)
                            })
                          }
                        </tbody>


                      </table>)}
                  </div>
                  <TitleSection>
                    Office Hours
                  </TitleSection>
                  <div className='d-flex justify-content-start flex-wrap mb-3'>
                    <div style={{ width: '300px' }} className='d-flex me-2  mt-3'>
                      <div className='w-100'>
                        <DropdownSearchable
                          required
                          {...travelHoursInput}
                          placeHolder={'Office Hours'}
                          disabled={readOnly}
                          options={officeHoursDropdown}
                          selectedOption={values.office_hours ? values.office_hours : null}
                          onChange={value =>
                            dropDownHandleSingleSelect({ office_hours: value ? value : null }, 'office_hours')
                          }
                        />
                      </div>
                    </div>

                  </div>
                  <TitleSection>
                    Wait & Contracted Hours
                  </TitleSection>
                  <div className='d-flex justify-content-start flex-wrap mb-3'>
                    <div style={{ width: '300px' }} className='d-flex me-2  mt-3'>
                      <div className='w-100'>
                        <DropdownSearchable
                          required
                          {...waitContractedInput}
                          placeHolder={'Wait & Contracted Hours'}
                          disabled={readOnly}
                          options={waitContractedDropdown}
                          selectedOption={values.wait_contracted ? values.wait_contracted : null}
                          onChange={value =>
                            dropDownHandleSingleSelect({ wait_contracted: value ? value : null }, 'wait_contracted')
                          }
                        />

                      </div>
                    </div>
                  </div>

                  <TitleSection>
                    Capped Hours
                  </TitleSection>
                  <div className='d-flex justify-content-start flex-wrap mb-3'>
                    <div style={{ width: '300px' }} className='d-flex me-2  mt-3'>
                      <div className='w-100'>
                        <InputLabelTop
                          required
                          label='Capped Hours'
                          type="number"
                          onKeyPress={KeyPress.POSITIVE}
                          id={`al_capped_hours`}
                          {...al_capped_hours_hook}
                          maxlength={100}
                        />
                      </div>
                    </div>
                    <div style={{ width: '300px' }} className='d-flex me-2  mt-3'>
                      <div className='w-100'>
                        <DropdownSearchable
                          {...al_hours_type_hook}
                          required
                          placeHolder={'Hours Type'}
                          disabled={readOnly}
                          options={alHoursTypeOption || []}
                          selectedOption={values.al_hours_type ? values.al_hours_type : null}
                          onChange={value =>
                            dropDownHandleSingleSelect({ al_hours_type: value ? value : null }, 'al_hours_type')
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className='d-flex justify-content-end'>
                    <PrimaryButtonForm
                      minWidth="6rem"
                      disabled={readOnly}
                      onClick={handleSubmit}
                      style={{ marginBottom: '5px' }}>
                      <span className={'me-2'}>{textStatus}</span>
                      {!loadingSave ? (
                        buttonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  </div>
                </div>
                :
                <div className='d-flex justify-content-center'>
                  <h4 style={{ fontSize: "1rem" }}>No data found</h4>
                </div>}
            </div>
          </ComponentDynamic>
        </div>
      ) : (
        <div className='d-flex w-100 justify-content-center'>
          <NoPermissionContainer>
            You don't have permission to read the information.
          </NoPermissionContainer>
        </div>
      )}
    </>
  )
}

export default AccrualLeaves