import React from 'react'
import { ErrorMessage, ModalContainer } from '../AddModal/styles'
import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo'
import { FlexGrid3Item } from 'shared/styles/constants/tagsStyles'
import InputLabelTop from 'components/SharedComponents/InputLabelTop'
import InputTextArea from 'components/SharedComponents/InputTextArea'
import { Fragment } from 'react'
import { PrimaryButtonForm } from 'shared/styles/buttons'
import { FLOPPY } from 'utils/constants/icons'
import { useState } from 'react'
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable'
import DateSelector from 'components/SharedComponents/DateSelector'

function UpdateModal({
    fullBind,
    useInput,
    values,
    setValues,
    errors,
    setCheckRequires,
    errorMessage,
    setErrorMessage,
    showError,
    setShowError,
    contextChoices,
    handleSubmit,
    setLoadingSave,
    loadingSave,
    readOnly
}) {

    const start_date_hook = useInput('start_date')

    const dropDownHandleVisits = (item, name) => {
        //recursive objects merge
        values[name] = item[name];
        setValues({ ...values });
    };
    return (
        <ModalContainer max_width={'60%'}>
            <ModalNoInfo {...fullBind} minWidthTitle="8.5rem" loading={false}>
                <div
                    style={{ padding: "0 1rem 1rem 1rem" }}
                    className="d-flex flex-column w-100">
                    
                    <div className='w-100 d-flex mt-2'>
                        <div className="w-50 me-3">
                            <DropdownSearchable
                                required
                                disabled={readOnly}
                                {...useInput(`core_hr_task`, 'isRequired')}
                                //   data_error={errors.indexOf(`${[nameSection]}.every`)}
                                placeHolder={'QA Task'}
                                options={(contextChoices.hr_task && contextChoices.hr_task.map((i)=>({id:i.id, name: i.title}))) || []}
                                selectedOption={
                                    values.core_hr_task ? values.core_hr_task : null
                                }
                                onChange={value =>
                                    dropDownHandleVisits({ core_hr_task: value ? value : null }, 'core_hr_task')
                                }
                            />
                        </div>
                        <div className="w-50 ">
                                <InputLabelTop
                                    disabled={readOnly}
                                    type="text"
                                    id="name"
                                    label={'Title'}
                                    {...useInput('title')}
                                    maxlength={50}
                                />
                        </div>
                    </div>
                    <div className='w-100 d-flex mt-1'>
                        <div className="w-50 me-3">
                            <DropdownSearchable
                                disabled={readOnly}
                                {...useInput(`status`)}
                                placeHolder={'Status'}
                                options={[
                                    {id: 'Done', name:'Done'},
                                    {id: 'Not Done', name:'Not Done'}
                                 ]}
                                selectedOption={
                                    values.status ? values.status : null
                                }
                                onChange={value =>
                                    dropDownHandleVisits({ status: value ? value : null }, 'status')
                                }
                            />
                        </div>
                        <div className="w-50">
                            <DateSelector
                                disabled={readOnly}
                                labelTop={'Due Date'}
                                {...useInput('due_date')}
                            />
                        </div>
                    </div>
                    <div className="w-100 d-flex mt-2">
                            <div className='w-50 me-2'>
                            <InputLabelTop
                                disabled={readOnly}
                                type="text"
                                id="name"
                                label={'Instruction'}
                                {...useInput('instruction')}
                                maxlength={50}
                            />
                            </div>
                           {!values.id && 
                           <div className="w-50">
                            <DateSelector
                                disabled={readOnly}
                                labelTop={'Start Date'}
                                {...start_date_hook}
                            />
                        </div>}
                    </div>
                    <div className="w-100 me-3 mt-1">
                        <FlexGrid3Item className="pe-1 mb-2">
                            <small className="pe-1 " style={{ color: 'black' }}>
                                Note
                            </small>
                            <InputTextArea
                                disabled={readOnly}
                                type="text"
                                height={'4rem'}
                                id="nok_no"
                                label={'notes'}
                                {...useInput('notes')}
                                maxlength={8}
                            />
                        </FlexGrid3Item>
                    </div>
                    <div
                        className={'d-flex justify-content-center justify-content-md-end mt-3'}>
                        <div className={'mb-3'}>
                            {errors.length && showError ? (
                                <ErrorMessage>
                                    <label className={'mt-2'}>{errorMessage}</label>
                                </ErrorMessage>
                            ) : (
                                <Fragment />
                            )}
                        </div>
                        <div className={'ms-0 ms-md-3 me-2'}>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                disabled={readOnly}
                                onClick={handleSubmit}
                                style={{ marginBottom: '5px' }}>
                                <span className={'me-2'}>SAVE</span>
                                {!loadingSave ? (
                                    FLOPPY
                                ) : (
                                    <span
                                        className="spinner-border spinner-border-sm me-1"
                                        role="status"
                                    />
                                )}
                            </PrimaryButtonForm>
                        </div>
                    </div>
                </div>
            </ModalNoInfo>
        </ModalContainer>
    )
}

export default UpdateModal