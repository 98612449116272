import React, {Fragment} from 'react';
import {useEffect, useState, useRef, useContext} from 'react';
import {AppContext, BranchItemsContext} from 'context';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';

import {PrimaryButtonForm} from 'shared/styles/buttons';
import {PrimaryButtonModal} from 'components/SharedComponents/ModalDecision/styles';
import {Title} from 'shared/styles/constants/tagsStyles';
import {IconStyle} from 'components/SharedComponents/ModalInformation/styles';
import {CancelButton} from 'components/SharedComponents/ModalDecision/styles';
import Modal from 'react-bootstrap4-modal';
import LineContainer from 'components/SharedComponents/LineContainer';
import {Toast} from 'primereact/toast';
import {MessageOptaPlanner} from 'components/DashboardComponents/Scheduler/styles';
import {setClientContract} from 'utils/api/ClientApi';
import {IoIosArrowDroprightCircle} from 'react-icons/io';
import _ from 'lodash';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  StylePointer,
} from 'shared/styles/constants/tagsStyles';
// import {} from './styles';
import {CHECK_CIRCLE} from 'utils/constants/icons';
import PropTypes from 'prop-types';
import {red} from '@mui/material/colors';
const ConfirmRegularShift = ({
  fullBind,
  setModalValue,
  values,
  checkBeforeSend,
  toDeleteVisits,
  toDeleteSplitFunder,
  toDeleteVisitSkill,
  toDeleteVisitEmployee,
  type,
  title,
  icon,
  children,
  showModal,
  onOk,
  okTitle,
  onCancel,
  onCancelTitle,
  hideOnCancel,
  setShowDeleteVisitsModal,
  handleSubmit,
  getClientContracts,
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const {contextChoices} = useContext(AppContext);
  const [newDetails, setDetails] = useState([]);
  const toast = useRef();
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: '',
    });
  }, [fullBind]);
  // useEffect(() => {
  //   handleData();
  // }, []);
  console.log(values, 'FFFFFF');
  const handleToast = async (event) => {
   
    // toast.current.show({
    //   severity: 'success',
    //   summary: 'Success',
    //   detail: 'Data Updated Successfully',
    // });
    // setTimeout(() => {
      setModalValue(false);
    // }, 950);
   
  };

  console.log(contextChoices, 'HHHHHH');
  const moment = require('moment');
  const today = moment();
  let date = today.format().substring(0, 10);
  const handleData = () => {
    let newData =
      values.regularShifts.shifts &&
      values.regularShifts.shifts.map(item => {
        let ShiftName =
          item.type && contextChoices.regular_shift_type
            ? contextChoices.regular_shift_type.find(element => {
                if (element.id == item.type) {
                  return element;
                }
              })
            : '';
        return {
          preferredStart: item.start_time.toString().substring(0, 5),
          preferredEnd: item.end_time.toString().substring(0, 5),
          EffectiveDate: item.effective_date ? item.effective_date : date,
          ShiftName: ShiftName.name ? ShiftName.name : '',
        };
      });
    console.log(values, 'ppppp');
    setDetails(newData);
  };

  console.log(newDetails, 'vvvvvvvv');
  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <ModalContainer min_width={'40%'} max_width={'50%'}>
        <ModalDynamic {...addFullBind} loading={false}>
          <IconStyle type={type}>{icon}</IconStyle>
          <MessageOptaPlanner className={'mt-4 mb-3'}>
            <div className={'warningMessage ps-3 pe-3'}>
              <h5>
                This action will update all shift data on the regular shift from
                effective date mentioned below. Do you confirm for the changes?
              </h5>
            </div>
            <div style={{marginTop: '20px'}}>
              {newDetails &&
                newDetails.map(item => {
                  return (
                    <div
                      style={{
                        marginRight: '3px',
                        textAlign: 'left',
                        paddingLeft: '30px',
                        paddingRight: '10px',
                      }}>
                      <IoIosArrowDroprightCircle />
                      {item.ShiftName} ({item.preferredStart} -{' '}
                      {item.preferredEnd}) Form Date({item.EffectiveDate})
                    </div>
                  );
                })}
              <h6></h6>
            </div>
          </MessageOptaPlanner>
          <div className="d-flex justify-content-center modal-footer border-0">
            <CancelButton
              type="button"
              className="btn btn-secondary"
              onClick={() => setModalValue(false)}>
              NO, CANCEL
            </CancelButton>

            <PrimaryButtonModal
              onClick={e => {
                handleSubmit(e, getClientContracts);
                handleToast();
              }}>
              YES, PROCEED
            </PrimaryButtonModal>
          </div>
        </ModalDynamic>
      </ModalContainer>
    </div>
  );
};
export default ConfirmRegularShift;
