import React, {
  useState,
  useEffect,
  useContext,
  useModal,
  useRef,
  Fragment,
} from 'react';
import { AppContext, BranchItemsContext } from 'context';

import { setAbsenceOverview } from 'utils/api/EmployeeApi';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { Toast } from 'primereact/toast';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { PLUS, TIMES } from 'utils/constants/icons';
import {
  CloseButtonWrapper,
  SampleMessage,
  IconWrapper,
} from 'shared/styles/constants/tagsStyles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {
  ERROR,
  SAVE,
  SAVED,
  InvalidOrEmptyValues,
} from 'utils/constants/messages';
import DateSelector from 'components/SharedComponents/DateSelector';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import CustomYearInput from 'components/SharedComponents/DateSelector/CustomYearInput';

const edit_absence = 'Edit Absence Overview';
const new_absence = 'Add Absence Overview';

const AddNewAbsence = ({
  fullBind,
  idSelected,
  setTitle,
  editDatas,
  setDataCheck,
  setModalValue,
  selectedClientId,
  setDataChecking,
  addNewAvoid,
  useInput,
  addData,
  data,
  loadData,
  index,
  values,
  setValues,
  namesection, setCheckRequires
}) => {
  const toast = useRef();

  const { contextChoices } = useContext(AppContext);
  const [employees, setEmployees] = useState([]);
  const [absenceReasonList, setAbsenceReasonList] = useState([])
  useEffect(() => {
    setAbsenceType(
      contextChoices.absence_type &&
      contextChoices.absence_type.map(y => {
        return { id: y.id, name: y.name };
      })
    );
    setAbsenceReason(
      contextChoices.absence_reason &&
      contextChoices.absence_reason.map(y => {
        return { id: y.id, name: y.name };
      })
    );
    setAbsencePaid(
      contextChoices.absence_paid &&
      contextChoices.absence_paid.map(y => {
        return { id: y.id, name: y.name === 'n/a' ? y?.name?.toUpperCase() : y.name };
      })
    );

    setEmployees(
      contextChoices.employee_listing &&
      contextChoices.employee_listing.map(item => {
        return {
          id: item.id,
          name: item.full_name,
        };
      })
    );

    if (idSelected && data && employees) {
      // setTitle(add);

      values.absence_type = data.absenceType;
      values.absence_planneds = data.absencePlanned;
      values.absence_reason = data.absenceReason;
      values.time_unit = data.timeUnit;
      values.absence_paid = data.absencePaid;
      values.absence_allowance = data.absenceAllowance;
      values.allowanceDate = data.allowanceDate;

      setValues({ ...values });
    } else {
      setLoadingModal(false);
    }
  }, [contextChoices || employees || values.employee]);
  useEffect(() => {
    if (contextChoices.absence_type && contextChoices.absence_reason && values[namesection][index].absence_type) {
      let arr = contextChoices.absence_reason.filter(
        val => val.absence_type === values[namesection][index].absence_type
      );
      setAbsenceReasonList(arr);
    }
  }, [values[namesection][index].absence_type]);

  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [loadingSave, setLoadingSave] = useState(false);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingModal, setLoadingModal] = useState(false);
  const [absenceType, setAbsenceType] = useState([]);
  const [absencePaid, setAbsencePaid] = useState([]);
  const [timeUnit, setTimeUnit] = useState([]);
  const [absenceReason, setAbsenceReason] = useState([]);

  const dropDownHandleSelect = (item, nameSection, index) => {
    values[namesection][index][nameSection] = item[nameSection];
    setValues({ ...values });
  };

  const dropDownHandleSelect1 = item => {
    setValues({ ...values, ...item });
  };


  const deleteAddRate = (val, indexs) => {
    values.absence_overview.splice(indexs, 1);
    setValues({ ...values });
  };

  return (
    <div>
      <Toast ref={toast} position="top-right" />

      <div className="d-flex flex-column mt-3">
        <div className="d-flex mb-1">
          {values.absence_overview?.length >= 1 && (
            <CloseButtonWrapper
              onClick={() => {
                deleteAddRate(values, index);
              }}>
              {TIMES}
            </CloseButtonWrapper>
          )}

          <div className="w-100 me-2">
            <DropdownSearchable
              required
              {...useInput(
                `absence_overview[${index}].absence_type`,
                'isRequired'
              )}
              placeHolder={'Absence Type'}
              options={absenceType ? absenceType : []}
              selectedOption={values[namesection][index].absence_type}
              onChange={value =>
                dropDownHandleSelect(
                  {
                    absence_type: value ? value : null,
                  },
                  'absence_type',
                  index
                )
              }
            />
          </div>

          {/* <div
            className="w-100 me-2">
            <DropdownSearchable
              disabled={values[namesection][index].absence_type ? false : true}
              placeHolder={'Absence Reason'}

              {...useInput(
                `absence_overview[${index}].absence_reason`,

              )}
              options={absenceReasonList ? absenceReasonList : []}
              selectedOption={values[namesection][index].absence_reason}
              onChange={value =>
                dropDownHandleSelect(
                  {
                    absence_reason: value ? value : null,
                  },
                  'absence_reason',
                  index
                )
              }
            />
          </div>
          <div
            className="w-100 me-2">
            <DropdownSearchable
              placeHolder={'Absence Planned'}
              options={
                contextChoices?.absence_planned
                  ? contextChoices?.absence_plannedmap?.map(x => ({...x,name:x.name === 'n/a' ? x.name.toUpperCase() : x.name}))
                  : []
              }
              selectedOption={values[namesection][index].absence_planneds}
              onChange={value =>
                dropDownHandleSelect(
                  {
                    absence_planneds: value ? value : null,
                  },
                  'absence_planneds',
                  index
                )
              }
            />
          </div> */}
          <div className="w-100 me-2">
            <DropdownSearchable
              required
              disabled={false}
              placeHolder={'Time Unit'}
              options={contextChoices.absence_time_unit}
              selectedOption={values[namesection][index].time_unit}
              {...useInput(
                `absence_overview[${index}].time_unit`,
                'isRequired'
              )}
              onChange={value =>
                dropDownHandleSelect(
                  {
                    time_unit: value ? value : null,
                  },
                  'time_unit',
                  index
                )
              }
            />
          </div>

        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="d-flex">
          <div className="w-100 me-2"
           style={{marginLeft:"28px"}} 
          >
            <DropdownSearchable
              disabled={false}
              required
              placeHolder={'Absence Paid'}
              options={absencePaid ? absencePaid : []}
              selectedOption={values[namesection][index].absence_paid}
              {...useInput(
                `absence_overview[${index}].absence_paid`,
                'isRequired'
              )}
              onChange={value =>
                dropDownHandleSelect(
                  {
                    absence_paid: value ? value : null,
                  },
                  'absence_paid',
                  index
                )
              }
            />
          </div>

          <div
            className="w-100 me-2">
            <InputLabelTop
              // margin={'5px'}
              required
              label='Absence Allowance'
              type="number"
              id={`absence_allowance`}
              {...useInput(`absence_overview[${index}].absence_allowance`,'isRequired')}
              maxlength={100}
            />
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="d-flex">
        <div className="w-50 me-2"
         style={{marginLeft:"28px", paddingRight:"22px"}} 
        >
            {/* <DateSelector
              isRequired
              labelTop="Select Date"
              {...useInput(`absence_overview[${index}].allowanceDate`, 'isRequired')}

            /> */}
            <CustomYearInput
              isRequired
              labelTop="Select year"
              {...useInput(`absence_overview[${index}].allowance_year`, 'isRequired')}
            />
          </div>
        </div>
      </div>
      
 
      {errorMessage ? ( 
      <div style={{ display: 'flex', justifyContent: 'right' }}>
            <ErrorMessage>
              <label className={'mt-0'}>{errorMessage}</label>
            </ErrorMessage>
        </div>
         ) : (
          <Fragment />
        )}  

    </div>
  );
};

export default AddNewAbsence;
