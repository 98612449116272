import React from 'react';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {
    IconWrapper,
  } from 'components/SharedComponents/Table/styles';
  import {RiDeleteBinLine} from 'react-icons/ri';
const AdjustEmployee = ({
    index,
    employees,
    deleteEmployeeAdjust,
    nameSection,
    useInput,
    dropDownHandleAdjust,
    values,
    outerIndex
}) => {
  
    return (
        <div  className={`d-flex mt-2 mb-3 ms-5 me-5`}>
          <div className={'w-100 me-2'}>
          <DateSelector
                    labelTop="Date from"
                    {...useInput(`${[nameSection]}.adjustment_from_date`)}
                  />
          </div>
        <div className={'w-100 me-2'}>
        <DateSelector
                    labelTop="Date To"
                    {...useInput(`${[nameSection]}.adjustment_to_date`)}
                  />
        </div>
        <div className={'w-100'}>
        <DropdownSearchable
             required
            //  {...useInput(
            //   `employee_day_detail[${index}].employee`,
            //   'isRequired'
            // )}
              placeHolder={'Employee Name'}
              options={employees}
              selectedOption={values.employee_day_detail[outerIndex]?
                values.employee_day_detail[outerIndex].adjustment_details?
                values.employee_day_detail[outerIndex].adjustment_details[index].adjusted_employee
                :null
                :null
              }
              onChange={value =>
                dropDownHandleAdjust({adjusted_employee: value ? value : null}, 'adjusted_employee',index)
              }
            />
        </div>
           
             <IconWrapper
             className={'ms-2'}
             onClick={() => {
               deleteEmployeeAdjust(index);
              }}
              >
            <RiDeleteBinLine 
            color="red"
            cursor='pointer'
            width='6rem'
            />
        </IconWrapper>
            
            </div>
    );
}

export default AdjustEmployee;
