import React, { useState, useEffect, useRef, useContext } from 'react';
import Table from 'components/SharedComponents/Table';
import { headerColumns } from 'components/DashboardComponents/Alerts/headerColumnList';


import { PrimaryButton } from 'shared/styles/buttons';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import { FloatingMobileButton } from 'shared/styles/buttons';
import { MdEdit } from 'react-icons/md';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import DateSelector from 'components/SharedComponents/DateSelector';
import { CloseButtonWrapper } from 'shared/styles/constants/tagsStyles';
import { TIMES } from 'utils/constants/icons';

import {
  Header,
  SearchAddContainer,
  SearchContainer,
  HeaderLgBreak,
  Title,
  DropdownTypesContainer,
  HeaderVisits,
  FiltersLeftGroup,
  FiltersContainer,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';

import { LARGE_COL, EX_SMALL_COL4, EX_LARGE_COL, EX_SMALL_COL, SMALL_COL } from 'shared/styles/constants/columns';
import { useForm } from 'hooks/FormHook';
import { useHistory, useLocation } from 'react-router-dom';
import { AppContext, BranchItemsContext } from 'context';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import moment from 'moment';
import BulkTriageModal from 'components/DashboardComponents/Alerts/BulkAlertModal';
import BulkNotificationRule from 'components/DashboardComponents/Alerts/BulkNotificationRule';
import { Toast } from 'primereact/toast';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { getClientVisit } from 'utils/api/AlertsApi';
import { ClientVisitheaderColumns } from './ClientVisitHeader';

const ClientVisitModal = props => {
  const { values, setValues, useInput } = useForm({
    ordering: '-start_date'
  });
  const [clientVisitList, setClientVisitList] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const history = useHistory();
  const { contextChoices } = useContext(AppContext);
  const { branchData } = useContext(BranchItemsContext);

  const [branchFilter, setBranchFilter] = useState([]);
  const [runRoutesFilter, setRunRoutesFilter] = useState([]);

  const [countCheck, setCountCheck] = useState([])
  const [runRouteData, setRunRouteData] = useState([]);
  const [branch, setBranch] = useState([]);

  const [visitStatusFilter, setVisitStatusFilter] = useState([]);
  const [visitStatus, setVisitStatus] = useState([]);
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employeeFilter, setEmployeeFilter] = useState([]);


  const [loadingModal, setLoadingModal] = useState(true);

  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;

    let date = new Date().toJSON().slice(0, 10)
    values.date_from = date
    values.date_to = date
    setValues({ ...values });
    loadClientVisit()
  }, []);
  useEffect(() => {
    loadClientVisit()

  }, [props?.props?.location.search ||
    values.ordering ||
    values?.limitperpage])
  useEffect(() => {
    setRunRouteData(contextChoices && contextChoices.run_routes && contextChoices.run_routes.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))

    setBranch(contextChoices && contextChoices.branch && contextChoices.branch.map(y => {
      return { id: y && y.id, name: y && y.name }
    }));

    setVisitStatus(contextChoices.visit_status && contextChoices.visit_status.map(y => {
      return { id: y && y.id, name: y && y.name }
    }));

    setEmployees(contextChoices && contextChoices.employee_listing && contextChoices.employee_listing.map(y => {
      return { id: y && y.id, name: y && y.full_name }
    }));
  }, [contextChoices]);




  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };

  const setRunroutefilter = item => {
    setRunRoutesFilter([...item]);
  };
  const loadClientVisit = () => {
    const pageToSearch = getPageToSearchParam(
      props?.props?.location?.search,
      setCurrentPage
    );
    let id = props.selectedClientId;
    getClientVisit(
      { id, ...branchData, ...values, ...pageToSearch },
      values.limitperpage
    ).then(response => {
      if (response) {
        setLoadingModal(false);


        setCountCheck(response.count)
      }
      setClientVisitList(response.results || []);
      setPages(Math.ceil(response.count / values.limitperpage));
    });
  };

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };


  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };

  const handleAllSelectChange = e => {
    if (!values.checkbox_alerdata) {
      values.checkbox_alerdata = [];
    }

    if (e.target.checked) {
      clientVisitList.map(triageData => {
        values.checkbox_alerdata.push({
          ...triageData,
          checkbox_rowId: triageData.id,
        });
      });
    } else {

      values.checkbox_alerdata = [];
    }
    setValues({ ...values });
  };
  const handleAllCheck = () => {
    let checkAll = true;

    const alertCheckBoxExists = id => {
      return values.checkbox_alerdata.some(function (el) {
        return el.checkbox_rowId === id;
      });
    };
    if (values.checkbox_alerdata && values.checkbox_alerdata.length > 0) {
      clientVisitList.map(triageData => {
        if (!alertCheckBoxExists(triageData.id)) {
          checkAll = false;
        }
      });
    } else {
      checkAll = false;
    }

    return checkAll;
  };

  const dropDownHandleSelectPagination = (item, namesection) => {

    const urlParams = queryString.parse(props?.props?.location?.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: props?.props.location.pathname,
      search: stringified,
    });
    values.limitperpage = item.limitperpage
    setValues({ ...values });
  };

  const toast = useRef();

  const setFiltersetVisitStatus = (item) => {
    setVisitStatusFilter([...item])
  }
  const dropDownHandleSelect = (items) => {
    setValues({ ...values, ...items })
  }
  const handleSearch = () => {
    if (
      values.branch ||
      values.clientname ||
      values.date_to ||
      values.date_from ||
      values.employee__id__in ||
      values.runroutes ||
      values.visitstatus ||
      props?.props?.location.search ||
      values.ordering ||
      values?.limitperpage


    ) {
      loadClientVisit();
    }


  }
  return (
    <div>
        <Toast ref={toast} position="top-right" />
        <FiltersContainer>
          <div
            className={'ms-1 me-3 me-md-0 flex-wrap d-flex justify-content-center'}>
            <FiltersLeftGroup
              className={'d-flex mb-2'} style={{ flexWrap: 'wrap', gap: "0.5rem" }}>
              <DropdownTypesContainer>
                <NoSelectAllDropdownFilter
                  items={employees ? employees : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ employee__id__in: items });
                  }}
                  itemsSelected={employeeFilter}
                  setSelectedFilter={(items) => setEmployeeFilter([...items])}
                  placeHolder={'Employee name'}
                  isObject
                  allOption

                />
              </DropdownTypesContainer>



              <DropdownTypesContainer>
                <NoSelectAllDropdownFilter
                  items={visitStatus ? visitStatus : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ visitstatus: items });
                  }}
                  itemsSelected={visitStatusFilter}
                  setSelectedFilter={setFiltersetVisitStatus}
                  placeHolder={'Visit status'}
                  isObject
                  allOption
                />
              </DropdownTypesContainer>
              <DropdownTypesContainer>
                <NoSelectAllDropdownFilter
                  items={branch ? branch : []}

                  setItemsSelected={items => {
                    dropDownHandleSelect({ branch: items });
                  }}
                  itemsSelected={branchFilter}
                  setSelectedFilter={setFilterBranch}
                  placeHolder={'Branch'}
                  isObject
                  allOption
                />
              </DropdownTypesContainer>
              <DropdownTypesContainer>
                <NoSelectAllDropdownFilter
                  items={runRouteData ? runRouteData : []}
                  setItemsSelected={items => {
                    dropDownHandleSelect({ runroutes: items });
                  }}
                  itemsSelected={runRoutesFilter}
                  setSelectedFilter={setRunroutefilter}
                  placeHolder={'Group Area'}
                  isObject
                  allOption
                />
              </DropdownTypesContainer>
              <DropdownTypesContainer>
                <DateSelector
                  typeFilter={true}
                  labelTop="Date from"
                  {...useInput('date_from')}
                />
              </DropdownTypesContainer>
              <DropdownTypesContainer>
                <DateSelector
                  typeFilter={true}
                  labelTop="Date to"
                  {...useInput('date_to')}
                />
              </DropdownTypesContainer>
              <PrimaryButton style={{ minWidth: "6rem"}} onClick={handleSearch} >
                Search
              </PrimaryButton>
            </FiltersLeftGroup>
          </div>
        </FiltersContainer>
      {countCheck !== 0 ? (
        <div>
          <ComponentDynamic loading={loadingModal}>
            <Table
              headerColumns={ClientVisitheaderColumns}
              callBackQuerySort={setQuerySort}
              headerPadding={false}
              noEditOption
              handleAllCheck={handleAllCheck}
              handleAllSelectChange={handleAllSelectChange}>
              <div>
                {clientVisitList.map((list, index) => {
                  return (
                    <div>
                      <Row bgColor>

                        <Col
                          max_width={LARGE_COL}
                          Center
                          // className="sm-hidden"
                          overlap={true}>
                            <span style={{marginRight:'40px'}}>
                          {list?.client_name}
                          </span>
                        </Col>
                        <Col
                        className=' sm-hidden'
                          max_width={LARGE_COL}
                          Center
                          // className="sm-hidden"
                          overlap={true}>
                          {list?.employee_name}
                        </Col>
                        <Col
                        className=' sm-hidden md-hidden'
                          max_width={LARGE_COL}
                          Center
                          // className="sm-hidden"
                          overlap={true}>
                          {list?.scheduler_start_time}

                        </Col>
                        <Col
                        className=' sm-hidden md-hidden'
                          max_width={LARGE_COL}
                          Center
                          //  className="sm-hidden"
                          overlap={true}>
                          {list?.scheduler_end_time}
                        </Col>
                        <Col
                        className=' sm-hidden md-hidden'
                          max_width={LARGE_COL}
                          Center
                          // className="sm-hidden"
                          overlap={true}>
                          <div>
                            {list?.scheduler_start_date ? list?.scheduler_start_date : list.start_date.slice(0, 10)}
                          </div>
                        </Col>

                        <Col
                        className=' sm-hidden md-hidden'
                          max_width={LARGE_COL}
                          Center
                          // className="md-hidden"
                          overlap={true}>

                          {list?.scheduler_end_date ? list?.scheduler_end_date : list.end_date.slice(0, 10)}

                        </Col>
                        <Col
                        className=' sm-hidden md-hidden'
                          max_width={LARGE_COL}

                          // className="sm-hidden"
                          overlap={true}>
                          {list?.start_date.slice(11, 16)}
                        </Col>
                        <Col
                        className=' sm-hidden md-hidden'
                          max_width={LARGE_COL}

                          // className="sm-hidden"
                          overlap={true}>
                          {list?.end_date.slice(11, 16)}
                        </Col>
                        <Col
                          max_width={LARGE_COL}

                          // className="sm-hidden"
                          overlap={true}>
                          {list?.visit_status_name}
                        </Col>


                      </Row>

                      <div className="mb-1" />
                    </div>
                  );
                })}
                <div className="mt-3">
                  <Pagination
                    setCurrentPage={setCurrentPage}
                    totalPages={pages}
                    currentPage={currentPage}
                    values={values}
                    dropDownHandleSelect={dropDownHandleSelectPagination}
                    number={PAGE_SIZE_RENDER}
                    dataLength={clientVisitList.length}
                  />
                </div>
              </div>
            </Table>
          </ComponentDynamic>
        </div>
      ) : <TitleSection>No data found</TitleSection>}


    </div>
  );
};

export default ClientVisitModal;
