
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './styles.css';

import {
  getNotificationSettings,
  getNotificationSettingsSections,
  updateEmployeeNotification,
  updateAllNotification,
} from 'utils/api/NotificationSettingsApi';
import Spinner from 'components/SharedComponents/Spinner';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NotificationTable from './NotificationTable';
import { NoPermissionContainer } from '../styles';

function Notification({
  selectedId,
  permissions
}) {
  const history = useHistory();
  const toast = useRef();

  const [selectEmailAll, setSelectEmailAll] = useState(false);
  const [selectDesktopAll, setSelectDesktopAll] = useState(false);
  const [selectInAppAll, setSelectInAppAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [groupedData, setGroupedData] = useState();


  // Fetch notification section data
  const notificationSectionsData = async () => {
    try {
      const res = await getNotificationSettingsSections()
      return res
    } catch (error) {

    }
  };

  // Fetch notification setting data
  const notificationSettingData = async () => {
    try {
      const res = await getNotificationSettings(selectedId)
      return res
    } catch (err) {

    }
  };


  const notificationSettingMain = async () => {
    setIsLoading(true);
    const section = await notificationSectionsData();
    const settings = await notificationSettingData();

    let res = section?.results?.map(element => {
      let findId = settings?.data?.find(el => el.item == element.id);
      return {
        item: element.id,
        id: findId ? findId.id : null,
        name: element.name,
        isDesktop: findId ? findId.is_desktop : false,
        isEmail: findId ? findId.is_email : false,
        isInApp: findId ? findId.is_in_app : false,
        category: element.category,
      };
    });
    setCheckboxes(res || []);
    setIsLoading(false);
  };

  useEffect(() => {
    notificationSettingMain();
  }, []);

  const updateAllSettings = async (body, allChecked) => {
    setIsLoading(true);
    try {
      let res = await updateAllNotification(body, selectedId);
      if (res.status == "False") {
        throw res.message
      }
      if (res.status) {
        setCheckboxes(allChecked || []);
        setSelectEmailAll(!selectEmailAll);

        const settings = await notificationSettingData();
        let updateCheckboxes = checkboxes.map(element => {
          let findId = settings?.data?.find(el => el.item == element.item);
          return {
            ...element,
            id: findId?.id || null,
            isDesktop: findId ? findId.is_desktop : element.isDesktop,
            isEmail: findId ? findId.is_email : element.isEmail,
            isInApp: findId ? findId.is_in_app : element.isInApp,
          };
        });
        setCheckboxes(updateCheckboxes || []);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${res?.message}`,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${error}`,
      });
    }

  }

  const handleAllCheck = async name => {
    switch (name) {
      case 'email':
        {
          const allChecked = checkboxes.map(item => ({
            ...item,
            isEmail: !selectEmailAll,
          }));
          const isEmail = allChecked.every(el => el.isEmail);
          const body = { is_email: isEmail };
          updateAllSettings(body, allChecked)
        }
        break;
      case 'desktop':
        {
          const allChecked = checkboxes.map(item => ({
            ...item,
            isDesktop: !selectDesktopAll,
          }));
          const isDesktop = allChecked.every(el => el.isDesktop);
          const body = { is_desktop: isDesktop };
          updateAllSettings(body, allChecked)
        }
        break;
      case 'inapp':
        {
          const allChecked = checkboxes.map(item => ({
            ...item,
            isInApp: !selectInAppAll,
          }));
          const isInApp = allChecked.every(el => el.isInApp);
          const body = { is_in_app: isInApp };
          updateAllSettings(body, allChecked)
        }
        break;
      default:
        return null;
    }
  };

  const updateList = async (value, data, allSelect) => {
    const body = {
      notification_id: value.item,
      is_email: value.isEmail,
      is_desktop: value.isDesktop,
      is_in_app: value.isInApp,
    };
    if (value.id) {
      body.id = value.id;
    }
    try {
      let res = await updateEmployeeNotification(body, selectedId);
      if (res.status == "False") {
        throw res.message
      }

      if (res.status) {
        let data = checkboxes.map(item =>
          item.item === res?.data?.item
            ? {
              ...item,
              item: res.data.item,
              id: res.data.id,
              isDesktop: res.data.is_desktop,
              isEmail: res.data.is_email,
              isInApp: res.data.is_in_app,
            }
            : item
        );
        setSelectInAppAll(allSelect);
        setCheckboxes(data || [])
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${res?.message}`,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${error}`,
      });
    }
  };

  const updateOneByOne = (id, name) => {
    switch (name) {
      case 'email':
        {
          const data = checkboxes.map(item =>
            item.item === id ? { ...item, isEmail: !item.isEmail } : item
          );
          // setCheckboxes(data)
          const allSelect = data.every(item => item.isEmail);
          // setSelectEmailAll(allSelect);
          const findItem = data.find(el => el.item === id);
          updateList(findItem, data, allSelect);
        }
        break;
      case 'desktop':
        {
          const data = checkboxes.map(item =>
            item.item === id ? { ...item, isDesktop: !item.isDesktop } : item
          );
          // setCheckboxes(data);
          const allSelect = data.every(item => item.isDesktop);
          // setSelectDesktopAll(allSelect);
          const findItem = data.find(el => el.item === id);
          updateList(findItem, data, allSelect);
        }
        break;
      case 'inapp':
        {
          const data = checkboxes.map(item =>
            item.item === id ? { ...item, isInApp: !item.isInApp } : item
          );
          // setCheckboxes(data);
          const allSelect = data.every(item => item.isInApp);
          // setSelectDesktopAll(allSelect);
          const findItem = data.find(el => el.item === id);
          updateList(findItem, data, allSelect);
        }
        break;
      default:
        return null;
    }
  };

  const handleEmailChange = (id, name) => {
    updateOneByOne(id, name);
  };

  useEffect(() => {
    const isEmail = checkboxes?.every(el => el.isEmail);
    const isDesktop = checkboxes?.every(el => el.isDesktop);
    const isInApp = checkboxes?.every(el => el.isInApp);
    setSelectDesktopAll(isDesktop);
    setSelectEmailAll(isEmail);
    setSelectInAppAll(isInApp);

    const groupBy = checkboxes?.reduce((acc, cur) => {
      const { category } = cur;
      acc[category] = acc[category] || [];
      cur['category'] = category;
      acc[category].push(cur);
      return acc;
    }, {});

    if (groupBy) {
      const keys = Object.keys(groupBy);
      const formatedData = Object.values(groupBy).map((el, i) => {
        return { category: keys[i], items: el };
      });
      setGroupedData(formatedData);
    }

  }, [checkboxes]);

  return (
    <div >
      <Toast ref={toast} position="top-right" />
      {permissions?.read ?
        (isLoading ? (
          <Spinner />
        ) : checkboxes.length ? (
          <div className="w-100 bg-white p-2 mt-3">
            <NotificationTable
              groupedData={groupedData}
              handleAllCheck={handleAllCheck}
              selectEmailAll={selectEmailAll}
              selectDesktopAll={selectDesktopAll}
              selectInAppAll={selectInAppAll}
              handleEmailChange={handleEmailChange}
            />
          </div>
        ) : (
          <div className="w-100 mt-3 d-flex justify-content-center">
            <div className="text-danger">No data found</div>
          </div>
        ))
        :
        <NoPermissionContainer>
          You don't have permission to access the information.
        </NoPermissionContainer>
      }
    </div>
  );
}

export default Notification;

