import React, { useState, useEffect, Fragment } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { AddBulkNotification } from 'utils/api/AlertsApi';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';

const BulkNotificationRule = ({
  fullBind,
  setModalValue,
  loadTriage,
  contextChoices,
  dataTriageIds,
  resetAllCheck,
  clearSelectedElId
}) => {
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setCheckRequires,
  } = useForm({});
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [appNotification, setAppNotification] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Add Notification',
    });
    setLoadingModal(false);
  }, [fullBind]);

  const dropDownHandleSelect = (item, nameItem) => {
    setValues({ ...values, ...item });
  };


  const handleSelectChange = (e, data) => {
    if (data === 'app_notification') {
      setAppNotification(!appNotification);
    }

    if (data === 'email_notification') {
      setEmailNotification(!emailNotification);
    }

  }




  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loadingSave) {
      return;
    }

    if (appNotification == false && emailNotification == false) {
      onError('alteast one chekbox must be checked ');
    } else {
      const bodyData = {
        alert: dataTriageIds,
        app: appNotification,
        email: emailNotification,
        every: values.every,
        value: values.repeat_no,
      }

      setLoadingSave(true);
      await AddBulkNotification(bodyData)
        .then(response => {
          if (response.status == "Success") {
            onSuccess(response);
          }
          else {
            onError('Something went wrong !!');
          }
        })
        .catch(onError);
    }
  }

  const onSuccess = response => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setModalValue(false);
    resetAllCheck();
    clearSelectedElId();
    loadTriage();
  };

  const onError = err => {
    let getErrorMessage = err ? err : 'something went wrong !!'
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    setCheckRequires(true);
    setShowError(true);
    setErrorMessage(getErrorMessage || 'something went wrong !!');
  };

  return (
    <>
      <ModalContainer max_width={'60%'}>
        <ModalDynamic
          {...addFullBind}
          max_width={'60%'}
          minWidthTitle="8.5rem"
          loading={loadingModal}>
          <div className="ps-3 pe-3 pb-3 pt-0">
            <FlexGrid3Container className={'w-100 mb-2'}>

              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'50%'}>
                <DropdownSearchable
                  placeHolder={'Repeat Every'}
                  options={[
                    { id: 'Minute', name: 'Minute' },
                    { id: 'Hour', name: 'Hour' },
                    { id: 'Day', name: 'Day' },
                    { id: 'Week', name: 'Week' },
                    { id: 'Month', name: 'Month' },
                    { id: 'Year', name: 'Year' },
                  ]}
                  selectedOption={values.every}
                  onChange={value =>
                    dropDownHandleSelect({ every: value ? value : null })}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'50%'}>
                <InputLabelTop
                  type="text"
                  id="repeat_no"
                  onKeyPress={values.repeat_no ? KeyPress.POSITIVE : KeyPress.NATURAL}
                  label={'Repeat no.'}
                  {...useInput('repeat_no')}
                  maxlength={50}
                />
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'50%'}>
                <div className='d-flex'>
                  <div className="ms-1">
                    <input
                      type="checkbox"
                      checked={appNotification}
                      onChange={(e) => { handleSelectChange(e, 'app_notification') }}
                    />

                  </div>
                  <div className="ms-1 ps-1"><p>In app notification</p></div>
                </div>
              </FlexGrid3Item>
              <FlexGrid3Item className="pe-1 mt-1 mb-2" ResizeWidth={'50%'}>
                <div className='d-flex'>
                  <div className="ms-1">
                    <input
                      type="checkbox"
                      checked={emailNotification}
                      onChange={(e) => { handleSelectChange(e, 'email_notification') }}
                    />

                  </div>
                  <div className="ms-1 ps-1"><p>Email notification</p></div>
                </div>
              </FlexGrid3Item>
            </FlexGrid3Container>
            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {errorMessage ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>
              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                <div className={'ms-0 ms-md-3 me-2'}>
                  <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                    <span className={'me-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                </div>
              </div>
            </div>
          </div>
        </ModalDynamic>
      </ModalContainer>
    </>
  )
}

export default BulkNotificationRule