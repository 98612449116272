import React, {Fragment, useState, useEffect} from 'react';
import {AiFillMinusSquare, AiFillPlusSquare} from 'react-icons/ai';
import {FaAngleDown, FaAngleUp, FaPlus} from 'react-icons/fa';
import {PURPLE} from 'shared/styles/constants/colors';
import {useForm} from 'hooks/FormHook';

import {FaUserShield} from 'react-icons/fa';
import {SquareStyle, ArrowStyle} from './styles';
import {IconWrapper} from 'components/SharedComponents/Table/styles';
import {MdEdit} from 'react-icons/md';
import {RiDeleteBinLine} from 'react-icons/ri';
import {StylePointer} from 'shared/styles/constants/tagsStyles';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import {getCareTask} from 'utils/api/UsersApi';
import {setCareTaskActive} from 'utils/api/SettingsApi';
import {useInput} from 'hooks/InputHook';

import {event} from 'jquery';
let TITLE_PADDING = 4;
let TITLE_FONT_WEIGHT = 800;
const CREATE_TITLE = 'Create';
const READ_TITLE = 'Read';
const UPDATE_TITLE = 'Update';
const DELETE_TITLE = 'Delete';
const _ = require('lodash');
const BodyTable = ({
  rolValues,
  selectedRol,
  setRolValues,
  handleCheckboxClick,
  handleOnClick,
  handleDelete,
}) => {
  const [data, setData] = useState([]);
  const [expandable, setExpandable] = useState({});
  useEffect(() => {
    getCareTask().then(response => {
      setData(response.results);
    });
  }, []);
  console.log(data, 'data');
  useEffect(() => {
    _.forEach(expandable, function (value, key) {
      expandable[key] = false;
    });

    setExpandable({...expandable});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRol]);
  // const isActive = useInput(`is_active`)
  let isActive = useInput('tableValue.isActive');

  const SubHeader = () => {
    return (
      <tr className={'border'}>
        <th className={'sticky-col'}>
          <div className={'d-flex justify-content-center'}>
            <FaUserShield className={'mt-1'} size={18} />
          </div>
        </th>
        <td className={' border-start '}>
          <div className={'d-flex justify-content-between font-weight-bold'}>
            <div>{CREATE_TITLE}</div>
            <div>{READ_TITLE}</div>
            <div>{UPDATE_TITLE}</div>
            <div>{DELETE_TITLE}</div>
          </div>
        </td>
        <td className={' border-start '}>
          <div className={'d-flex justify-content-between font-weight-bold'}>
            <div>{CREATE_TITLE}</div>
            <div>{READ_TITLE}</div>
            <div>{UPDATE_TITLE}</div>
            <div>{DELETE_TITLE}</div>
          </div>
        </td>
      </tr>
    );
  };

  const createRows = (sections, keyValue = '') => {
    let tableToReturn = [];

    let valuesToMap = sections && sections ? sections : rolValues;
    console.log(valuesToMap, 'valuesToMap');
    valuesToMap.forEach((tableValue, index) => {
      console.log(tableValue, 'tableValue');
      const myKey = tableValue.name.replace(/\s/g, '') + '/' + keyValue;
      expandable[`${keyValue ? keyValue : myKey}`] =
        expandable[`${keyValue ? keyValue : myKey}`] === undefined
          ? false
          : expandable[`${keyValue ? keyValue : myKey}`];
      tableToReturn.push(
        <Fragment key={index}>
          {expandable[`${keyValue ? keyValue : myKey}`] &&
            index === 0 &&
            (tableValue.principal ? null : (
              <tr
                key={index}
                className={'border'}
                style={{backgroundColor: '#ededed'}}>
                <th
                  className={'sticky-col'}
                  style={{
                    paddingLeft: TITLE_PADDING,
                    fontWeight: TITLE_FONT_WEIGHT,
                  }}>
                  <div className={'d-flex align-items-center'}>
                    <StylePointer
                      onClick={() =>
                        handleOnClick(
                          tableValue,
                          'add',
                          `${
                            tableValue.isSubsection ? 'care_task' : 'task_type'
                          }`
                        )
                      }
                      style={{paddingRight: 10}}>
                      <FaPlus color={PURPLE} size={16} />
                    </StylePointer>
                    {`${
                      tableValue.isSubsection
                        ? 'Care Task For'
                        : 'Task Type For'
                    } ${
                      tableValue.category_name
                        ? tableValue.category_name
                        : tableValue.task_type_name
                    }`}
                  </div>
                </th>
                <td
                  className={
                    'align-content-center border-start checkbox Position'
                  }></td>
                <td
                  className={
                    'align-self-center border-start checkbox Position'
                  }></td>
                <td
                  className={
                    'align-self-center border-start checkbox Position'
                  }></td>
                <td
                  className={
                    'align-self-center border-start checkbox Position'
                  }></td>
              </tr>
            ))}
        </Fragment>
      );

      tableToReturn = [
        ...tableToReturn,
        <Fragment key={index}>
          {!expandable[`${keyValue ? keyValue : myKey}`] &&
          !tableValue.principal ? null : (
            // adding rows
            <tr key={index} className={'border'}>
              <th
                className={'sticky-col'}
                style={{
                  paddingLeft: TITLE_PADDING,
                  fontWeight: TITLE_FONT_WEIGHT,
                }}>
                <div className={'d-flex align-items-center'}>
                  {/**adding square */}
                  {tableValue.principal
                    ? addSquare(tableValue, expandable, keyValue, myKey)
                    : null}

                  {/**adding arrows */}
                  {tableValue.sections && !tableValue.principal ? (
                    <Fragment>{addArrows(expandable, myKey)}</Fragment>
                  ) : null}
                  {/**adding name */}

                  {tableValue.name}
                </div>
              </th>
              {/**adding web checkboxes */}
              <td
                className={'align-content-center border-start checkbox Position'}>
                {checkboxComponent(tableValue, 'edit')}
              </td>
              {/**adding mobile checkboxes */}
              <td className={'align-self-center border-start checkbox Position'}>
                {checkboxComponent(tableValue, 'delete')}
              </td>
              <td className={'align-self-center border-start checkbox Position'}>
                {checkboxComponent(tableValue, 'active')}
              </td>
              <td className={'align-self-center border-start checkbox Position'}>
                {checkboxComponent(tableValue, 'checkbox')}
              </td>
            </tr>
          )}
        </Fragment>,
      ];

      if (tableValue.sections) {
        if (tableValue.sections.length >= 1) {
          TITLE_PADDING += 20;
          TITLE_FONT_WEIGHT -= 150;

          tableToReturn = [
            ...tableToReturn,
            createRows(tableValue.sections, `${myKey}`),
          ];
          TITLE_PADDING -= 20;
          TITLE_FONT_WEIGHT += 150;
        } else {
          if (!tableValue.principal && !tableValue.isSubsection) {
            tableToReturn.push(
              <Fragment key={index}>
                {expandable[`${keyValue ? keyValue : myKey}`] && (
                  <tr
                    key={index}
                    className={'border'}
                    style={{backgroundColor: '#ededed'}}>
                    <th
                      className={'sticky-col'}
                      style={{
                        paddingLeft: TITLE_PADDING,
                        fontWeight: TITLE_FONT_WEIGHT,
                      }}>
                      <div className={'d-flex align-items-center'}>
                        <StylePointer
                          onClick={() =>
                            handleOnClick(tableValue, 'add', 'care_task')
                          }
                          style={{paddingRight: 10}}>
                          <FaPlus color={PURPLE} size={16} />
                        </StylePointer>
                        {`${'Care Task For'} ${tableValue.name}`}
                      </div>
                    </th>
                    <td
                      className={
                        'align-content-center border-start checkbox Position'
                      }></td>
                    <td
                      className={
                        'align-self-center border-start checkbox Position'
                      }></td>
                  </tr>
                )}
              </Fragment>
            );
          } else {
            tableToReturn.push(
              <Fragment key={index}>
                {expandable[`${keyValue ? keyValue : myKey}`] &&
                  (!tableValue.principal ? null : (
                    <tr
                      key={index}
                      className={'border'}
                      style={{backgroundColor: '#ededed'}}>
                      <th
                        className={'sticky-col'}
                        style={{
                          paddingLeft: TITLE_PADDING,
                          fontWeight: TITLE_FONT_WEIGHT,
                        }}>
                        <div className={'d-flex align-items-center'}>
                          <StylePointer
                            onClick={() =>
                              handleOnClick(
                                tableValue,
                                'add',
                                `${
                                  tableValue.isSubsection
                                    ? 'care_task'
                                    : 'task_type'
                                }`
                              )
                            }
                            style={{paddingRight: 10}}>
                            <FaPlus color={PURPLE} size={16} />
                          </StylePointer>
                          {`${
                            tableValue.isSubsection
                              ? 'Care Task For'
                              : 'Task Type For'
                          } ${
                            tableValue.category_name
                              ? tableValue.category_name
                              : tableValue.name
                          }`}
                        </div>
                      </th>
                      <td
                        className={
                          'align-content-center border-start checkbox Position'
                        }></td>
                      <td
                        className={
                          'align-self-center border-start checkbox Position'
                        }></td>
                    </tr>
                  ))}
              </Fragment>
            );
          }
        }
      }

      if (tableValue.principal) {
        TITLE_PADDING = 3;
      }
    });

    return tableToReturn;
  };

  const addSquare = (tableValue, expandable, keyValue, myKey) => {
    if (!expandable[`${keyValue ? keyValue : myKey}`] && tableValue.principal) {
      return (
        <SquareStyle className={'pb-1'}>
          <AiFillPlusSquare
            color={PURPLE}
            onClick={() => {
              setExpandable({
                ...expandable,
                [`${keyValue ? keyValue : myKey}`]:
                  !expandable[`${keyValue ? keyValue : myKey}`],
              });
            }}
          />
        </SquareStyle>
      );
    } else {
      return (
        <SquareStyle className={'pb-1'}>
          <AiFillMinusSquare
            color={PURPLE}
            onClick={() => {
              _.forEach(expandable, function (value, key) {
                if (key.includes(myKey) && key !== myKey) {
                  expandable[key] = false;
                }
              });

              setExpandable({
                ...expandable,
                [`${keyValue ? keyValue : myKey}`]:
                  !expandable[`${keyValue ? keyValue : myKey}`],
              });
            }}
          />
        </SquareStyle>
      );
    }
  };

  const addArrows = (expandable, myKey) => {
    if (expandable[`${myKey}`]) {
      return (
        <ArrowStyle className={'pb-1 ps-0'}>
          <FaAngleUp
            color={PURPLE}
            onClick={() => {
              _.forEach(expandable, function (value, key) {
                if (key.includes(myKey) && key !== myKey) {
                  expandable[key] = false;
                }
              });

              setExpandable({
                ...expandable,
                [`${myKey}`]: !expandable[`${myKey}`],
              });
            }}
          />
        </ArrowStyle>
      );
    } else {
      return (
        <ArrowStyle className={'pb-1 ps-0'}>
          <FaAngleDown
            color={PURPLE}
            onClick={() => {
              setExpandable({
                ...expandable,
                [`${myKey}`]: !expandable[`${myKey}`],
              });
            }}
          />
        </ArrowStyle>
      );
    }
  };

  /**
   * Return horizontal checkboxes
   * @param {Object} tableValue
   * @param {String} columnType
   * @returns
   */

  const checkboxComponent = (tableValue, columnType) => {
    console.log(tableValue, '11111');
    return (
      <div className={'d-flex  justify-content-center'}>
        <div className={'checkbox-container justify-content-center ps-3'}>
          {columnType === 'edit' && (
            <IconWrapper
              onClick={() => {
                handleOnClick(
                  tableValue,
                  'edit',
                  `${
                    tableValue.isSubsection
                      ? 'care_task'
                      : tableValue.principal
                      ? 'care_category'
                      : 'task_type'
                  }`
                );
              }}>
              <MdEdit />
            </IconWrapper>
          )}
          {columnType === 'delete' && (
            <IconWrapper
              onClick={() => {
                handleDelete(
                  tableValue,
                  `${
                    tableValue.isSubsection
                      ? 'care_task'
                      : tableValue.principal
                      ? 'care_category'
                      : 'task_type'
                  }`
                );
              }}>
              <RiDeleteBinLine />
            </IconWrapper>
          )}
          {columnType === 'active' && (
            <ToggleButton
              label=" "
              disabled={false}
              // isActive
              {...isActive}
              id="mobile_permission"
              // onClick={handelOverNight}
            />
          )}
          {columnType === 'checkbox' && (
            <label className={'checkbox-label'}>
              <input
                type="checkbox"
                aria-label={CREATE_TITLE}
                checked={tableValue.mobile_permission}
                onChange={e => {
                  changeCheckboxValues(
                    tableValue,
                    rolValues,
                    event,
                    'mobile_permission',
                    tableValue.mobile_permission,
                    e.target.checked
                  );
                }}
              />
              <span className="checkbox-custom" />
            </label>
          )}
        </div>
      </div>
    );
  };

  const changeCheckboxValues = (
    tableValue,
    rolValues,
    columnType,
    typeCheckbox,
    type,
    checked
  ) => {
    console.log(tableValue, 'tableValue');

    console.log(rolValues, 'rolValues');
    let myMenuIndex = 0;
    if (tableValue.principal) {
      myMenuIndex = rolValues.findIndex(
        element => element.id === tableValue.id
      );
    } else {
      myMenuIndex = rolValues.findIndex(
        element => element.id === tableValue.category
      );
    }

    console.log(myMenuIndex, 'myMenuIndex');
    if (checked) {
      if (tableValue.sections) {
        const temp =
          tableValue.sections &&
          tableValue.sections.map(item => {
            return {
              ...item,
              mobile_permission: true,
            };
          });
        console.log(temp, 'temp');
        tableValue.mobile_permission = temp;
        rolValues[myMenuIndex].tableValue = tableValue.mobile_permission;
      } else {
        tableValue.mobile_permission = true;
      }

      console.log(rolValues, 'rolValues');
    }
    setRolValues(rolValues);
  };
  return (
    <Fragment>
      {/* <SubHeader /> */}
      {createRows()}
    </Fragment>
  );
};

export default BodyTable;
