import React, {Fragment, useEffect, useState} from 'react';
import Fade from 'react-reveal/Fade';
import {
  FormContent,
  ImageWrapper,
  InputLogin,
  InputsContent,
  LoginContent,
  PasswordStyle,
  SmallText,
  UpperText,
} from 'components/AuthComponents/Login/styles';
import {ErrorMessageShake, SuccessMessage} from 'shared/styles/AuthComponents';
import {PrimaryButton} from 'shared/styles/buttons';
import {useInput} from 'hooks/InputHook';
import {verifyPin} from 'utils/api/SessionApi';
import {Link, useHistory, useParams} from 'react-router-dom';
import logo from 'images/logoOutBgd.png';
import {AUTH} from 'utils/constants/routes';
import {CHECK_CIRCLE} from '../../../utils/constants/icons';
import {MessageOptaPlanner} from '../../DashboardComponents/Scheduler/styles';
import ModalInformation from '../../SharedComponents/ModalInformation';
import PinInput from 'react-pin-input';

const ResetPin = () => {
const [code, setCode] = useState('');
  const [codeConfirm, setCodeConfirm] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState({
    status: false,
    message: '',
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    status: false,
    message: '',
  });
  const [loadingButton, setLoadingButton] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  let {token} = useParams();
  const history = useHistory();

  let timerPointer = '';


  const handleSubmit = e => {
    try {
      e.preventDefault();

      if (loadingButton) return;

      setShowErrorMessage({
        status: false,
        message: '',
      });

      setLoadingButton(true);

      if(code.length < 4){
        throw new Error('The Pin should be 4 character long.');
      }
      if(codeConfirm.length < 4){
        throw new Error('The confirmPin should be 4 character long.');
      }

      if (code !== codeConfirm) {
        throw new Error('Confirm Pin is not same.');
      }

      if (!token) {
        throw new Error('Verification token is not available.');
      }

      verifyPin(token, code, codeConfirm)
        .then(response => {
          // TODO: error codes
          if (response.status === 'FAIL') {
            setShowErrorMessage({
                status: true,
                message: response.message,
              });
          } else {
            timerPointer = setTimeout(() => {
                history.push(AUTH);
                setShowSuccessMessage({
                  message: 'Pin is set successfully',
                  status: true,
                });
              }, 4000);
    
              setShowSuccessMessage({
                message: 'Pin is set successfully',
                status: true,
              });
          }
          setLoadingButton(false);
        })
        .catch(e => {
          setLoadingButton(false);
          setShowErrorMessage({
            status: true,
            message: 'Sign In Failure: unknown user name or bad password.',
          });
        });
    } catch (err) {
      setLoadingButton(false);
      setShowErrorMessage({
        status: true,
        message: err.message,
      });
    }
  };

  useEffect(() => {
    setShowErrorMessage({status: false, message: ''});
  }, [code]);

  const handleSuccessModelCancel = () => {
    setShowSuccessMessage({...showSuccessMessage, status: false});
    clearTimeout(timerPointer);
    history.push(AUTH);
  };

  const handleOnChange=(code)=>{
    setCode(code)
    if(code.length === 4){
        setConfirmDisabled(false);
        //setConfirmPin(true);
    }
}

const handleOnChangeConfirm=(code)=>{
setCodeConfirm(code)
}

  return (
    <LoginContent>
      <div
        className={
          'd-flex justify-content-md-end justify-content-center mt-4 me-lg-5 me-sm-5 me-0'
        }>
      </div>

      <Fade>
        <Link to={AUTH}>
          <ImageWrapper>
            <img src={logo} alt="logo-img" />
          </ImageWrapper>
        </Link>
      </Fade>

      <FormContent>
        <InputsContent>
          <div className={'mb-3'}>
            <UpperText>Enter New Pin</UpperText>

            <SmallText>
              <div className={'text-black-50'}>
              Please enter your new secure Pin (of 4 digits) here
              </div>
            </SmallText>
          </div>

          <form onSubmit={handleSubmit} className={'mb-5'} noValidate>
            <div>
              <div className={'font-weight-bold mb-2'}>Pin</div>
              <div>
                <PasswordStyle>
                <PinInput
                    length={4}
                    initialValue=""
                    secret
                    onChange={(value, index) => handleOnChange(value)}
                    type="numeric"
                    inputMode="number"
                    inputStyle={{ borderColor: 'red' }}
                    inputFocusStyle={{ borderColor: 'blue' }}
                    onComplete={(value, index) => { }}
                    autoSelect={confirmDisabled}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
                </PasswordStyle>
              </div>
            </div>
            <div className={'mt-4 p-0'}>
              <div className={'font-weight-bold mb-2'}>
                <div>Confirm Pin</div>
              </div>
              <PasswordStyle>
                <PinInput 
                    disabled={confirmDisabled}
                    length={4} 
                    initialValue=""
                    secret 
                    onChange={(value, index) => handleOnChangeConfirm(value)} 
                    type="numeric" 
                    inputMode="number"
                    inputStyle={{borderColor: 'red'}}
                    inputFocusStyle={{borderColor: 'blue'}}
                    onComplete={(value, index) => {}}
                    autoSelect={!confirmDisabled}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </PasswordStyle>
            </div>

            <div>
              {showErrorMessage.status ? (
                <ErrorMessageShake className=" mt-3">
                  {showErrorMessage.message}
                </ErrorMessageShake>
              ) : (
                <Fragment />
              )}
              {showSuccessMessage.status ? (
                <SuccessMessage className=" mt-3">
                  {showSuccessMessage.message}
                </SuccessMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={`${
                showErrorMessage.status ? 'mt-3' : 'mt-5'
              } d-flex justify-content-md-start justify-content-center`}>
              <PrimaryButton
                type="submit"
                shadow={true}
                disabled={loadingButton ? true : false}>
                {loadingButton ? (
                  <Fragment>
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                    <span>Loading...</span>
                  </Fragment>
                ) : (
                  <span className={'m-5 font-weight-bold'}>CONFIRM</span>
                )}
              </PrimaryButton>
            </div>
          </form>
        </InputsContent>
      </FormContent>

      <ModalInformation
        type={'success'}
        title={'Password is set'}
        onCancelTitle={'Cancel'}
        onOkTitle={'Ok'}
        icon={CHECK_CIRCLE}
        hideOnCancel={true}
        onOk={handleSuccessModelCancel}
        showModal={showSuccessMessage.status}>
        <MessageOptaPlanner className={'mt-4 mb-3'}>
          <div className={'warningMessage ps-3 pe-3'}>
            The Pin has been set.
          </div>
        </MessageOptaPlanner>
      </ModalInformation>
    </LoginContent>
  );
};

export default ResetPin;
