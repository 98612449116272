import React from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
const FunderBilling = ({
    choices,
    values,
    useInput,
    dropDownHandleSelect,
    readOnly,
    newAddFunder,editMode
}) => {
    return (
        <>
           <div className='d-flex mt-2 mb-2'>
            <div className='w-100 me-2'>
            <DropdownSearchable
            
            disabled={!editMode}
               placeHolder={'Invoice Type'}
               options={choices.Invoice_type}
              selectedOption={
                newAddFunder?
                values.addFunder
                  ? values.addFunder.invoice_type
                  : null
                :values.invoice_type
                  ? values.invoice_type
                  : null
              }
              onChange={(value) =>
                dropDownHandleSelect(
                  {invoice_type: value ? value : null},
                  'invoice_type',newAddFunder
                )
              }
            />
            </div>
            <div className='w-100 me-2'>
            <DropdownSearchable
            disabled={!editMode}
               placeHolder={'Billing Pattern'}
               options={choices.Billing_patterns}
              selectedOption={
                newAddFunder?
                values.addFunder
                  ? values.addFunder.billing_pattern
                  : null
                :values.billing_pattern
                  ? values.billing_pattern
                  : null
              }
              onChange={(value) =>
                dropDownHandleSelect(
                  {billing_pattern: value ? value : null},
                  'billing_pattern',newAddFunder
                )
              }
            />
            </div>
            <div className='w-100'>
            <DropdownSearchable
      disabled={!editMode}
               placeHolder={'Billing Term'}
               options={choices.Billing_terms}
              selectedOption={
                newAddFunder?
                values.addFunder
                  ? values.addFunder.billing_terms
                  : null
                :values.billing_terms
                  ? values.billing_terms
                  : null
              }
              onChange={(value) =>
                dropDownHandleSelect(
                  {billing_terms: value ? value : null},
                  'billing_terms',newAddFunder
                )
              }
            />
            </div>
        </div> 
        <div className='d-flex mb-2'>
            <div className='w-100 me-2'>
            <DropdownSearchable
            disabled={!editMode}
               placeHolder={'Payment Method'}
               options={choices.Payment_method}
              selectedOption={
                newAddFunder
                ?values.addFunder
                ? values.addFunder.billing_payment_method
                : null
                :values.billing_payment_method
                  ? values.billing_payment_method
                  : null
              }
              onChange={(value) =>
                dropDownHandleSelect(
                  {billing_payment_method: value ? value : null},
                  'billing_payment_method',newAddFunder
                )
              }
            />
            </div>
            <div className='w-100 me-2'>
            <InputLabelTop
              disabled={!editMode}
              type="number"
              id="credit limit"
              label={'Credit Limit'}
              {...useInput(newAddFunder?`addFunder.credit_limit`:`credit_limit`)}
              maxlength={255}
            />
            </div>
            <div className='w-100'>
            <InputLabelTop
              disabled={!editMode}
              type="text"
              id="funder_discount"
              label={'Funder Discount'}
              {...useInput(newAddFunder?`addFunder.discount`:`discount`)}
              maxlength={255}
            />
            </div>
        </div> 
        <div className='d-flex mb-2'>
            <div className='w-100 me-2'>
            <InputLabelTop
            disabled={!editMode}
              type="number"
              id="cancel_fees"
              label={'Funder Cancellation Fees'}
              {...useInput(newAddFunder?`addFunder.funder_cancellation_fee`:`funder_cancellation_fee`)}
              maxlength={255}
            />
            </div>
            <div className='w-100 me-2'>
            <DropdownSearchable
              disabled={!editMode}
               placeHolder={'Bill Rate Modal'}
               options={choices.bill_rate_model}
              selectedOption={
                newAddFunder?
                values.addFunder
                  ? values.addFunder.bill_rate_model
                  : null
                :values.bill_rate_model
                  ? values.bill_rate_model
                  : null
              }
              onChange={(value) =>
                dropDownHandleSelect(
                  {bill_rate_model: value ? value : null},
                  'bill_rate_model',newAddFunder
                )
              }
            />
            </div>
            <div className='w-100'>
            <DropdownSearchable
             disabled={!editMode}
               placeHolder={'Bill Duration Type'}
               options={choices.billing_duration_type}
              selectedOption={
                newAddFunder?
                values.addFunder
                  ? values.addFunder.billing_duration_type
                  : null
                :values.billing_duration_type
                  ? values.billing_duration_type
                  : null
              }
              onChange={(value) =>
                dropDownHandleSelect(
                  {billing_duration_type: value ? value : null},
                  'billing_duration_type',newAddFunder
                )
              }
            />
            </div>
        </div> 
        <div className='d-flex mb-2'>
            <div className='w-100 me-2'>
            <DropdownSearchable
            disabled={!editMode}
               placeHolder={'Bill Duration Source'}
               options={choices.billing_duration_source}
              selectedOption={
                newAddFunder?
                values.addFunder
                  ? values.addFunder.billing_duration_source
                  : null
                :values.billing_duration_source
                  ? values.billing_duration_source
                  : null
              }
              onChange={(value) =>
                dropDownHandleSelect(
                  {billing_duration_source: value ? value : null},
                  'billing_duration_source',newAddFunder
                )
              }
            />
            </div>
            <div className='w-100'>
            <DropdownSearchable
            disabled={!editMode}
               placeHolder={'Bill Time Unit'}
               options={choices.bill_time_unit}
              selectedOption={
                newAddFunder?
                values.addFunder
                  ? values.addFunder.bill_time_unit
                  : null
                :values.bill_time_unit
                  ? values.bill_time_unit
                  : null
              }
              onChange={(value) =>
                dropDownHandleSelect(
                  {bill_time_unit: value ? value : null},
                  'bill_time_unit',newAddFunder
                )
              }
            />
            </div>
        </div>  
        </>
    );
}

export default FunderBilling;
