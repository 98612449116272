import React, {useState, useEffect, useRef, useContext} from 'react';
import {FloatingMobileButton} from 'shared/styles/buttons';
import {DropdownTypesContainer} from 'shared/styles/constants/tagsStyles';
import {Header, SearchAddContainer} from './styles';
import {getClientTypes} from 'utils/api/CoreApi';
import {useForm} from 'hooks/FormHook';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {AppContext} from 'context';
import {BranchItemsContext} from 'context';
import moment from 'moment';
import {getClientsTaskFilter, getClientsList} from 'utils/api/ClientApi';
import DateSelector from 'components/SharedComponents/DateSelector';
import { reports_clientTask_choices } from 'utils/choiceConstant';
const TaskFilter = ({tabClick}) => {
  const [activeTab, setActiveTab] = useState(tabClick);
  const {values, setValues, useInput} = useForm({});

  const [clientStatus, setClientStatus] = useState([]);
  const [branch, setBranch] = useState([]);
  const {branchData} = useContext(BranchItemsContext);
  const mountedRef = useRef(true);
  const {contextChoices,handleGetChoices} = useContext(AppContext);
  const [branchFilter, setBranchFilter] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientsFilter, setClientsFilter] = useState([]);
  useEffect(() => {
    loadClientTypes();
  }, []);
  useEffect(() => {
    handleGetChoices(reports_clientTask_choices)
    
  }, [])
  useEffect(() => {
    setClients(
      contextChoices &&
      contextChoices.client_listing &&
      contextChoices.client_listing.map(y => {
        return {id: y && y?.id, name: y && y?.full_name};
      })
  );
    setBranch(
      contextChoices &&
        contextChoices.branch &&
        contextChoices.branch.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );
  }, [contextChoices]);
  const loadClientTypes = () => {
    getClientTypes().then(response => {
      if (!mountedRef.current) return null;
      // Y
    });
  };
console.log(clients,"clients")
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    setActiveTab(tabClick);
  }, [tabClick]);

  const handleDownload = () => {
    let fileName =
      'task_' + activeTab + '_' + moment().format('YYYY-MM-DD');
    getClientsTaskFilter({...branchData, ...values}, activeTab)
      .then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
        });
      })
      .catch(err => {});
  };
  const dropDownHandleSelect = item => {
    setValues({...values, ...item});
  };
  // useEffect(() => {
  //   let clients = [];
  //   let clientsResources = [];

  //   if (contextChoices.client_listing) {
  //     clients = contextChoices.client_listing.map(client => {
  //       return {id: client.id, name: client.full_name};
  //     });

  //     clientsResources = contextChoices.client_listing.map(client => {
  //       return {
  //         id: client.id,
  //         name: client.full_name,
  //       };
  //     });

  //     setClients(clients || []);
  //   }
  // }, []);
  const setClientfilter = (item) => {
    setClientsFilter([...item])
  }
  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };
  console.log(clients,"clients")
  return (
    <div>
      <Header>
        <div className="d-flex ">
        <DropdownTypesContainer className="me-2">
            <DateSelector
             isRequired
              typeFilter={true}
              labelTop="Date from"
              value={values.start_date ? values.start_date : ''}
              //onChange={(val)=>onDateChange(val)}
              {...useInput('start_date',' isRequired')}
            />
          </DropdownTypesContainer>
          <DropdownTypesContainer className="me-2">
            {' '}
            <DateSelector
            isRequired
              typeFilter={true}
              labelTop="Date to"
              value={values.end_date ? values.end_date : ''}
              // minDate={values.date_from}
              {...useInput('end_date',' isRequired')}
            />
          </DropdownTypesContainer>
          <DropdownTypesContainer className="me-2">
            <NoSelectAllDropdownFilter
             items={clients ? clients : []}
             setItemsSelected={items => {
               dropDownHandleSelect({ clients: items });
             }}
             itemsSelected={clientsFilter}
             setSelectedFilter={setClientfilter}
             placeHolder={'Client Name'}
             isObject
             allOption
            //  hitApiAfterClear={hitApiAfterClear}
            />
          </DropdownTypesContainer>
          <DropdownTypesContainer className="me-2">
            <NoSelectAllDropdownFilter
              items={branch ? branch : []}
              setItemsSelected={items => {
                dropDownHandleSelect({branch: items});
              }}
              itemsSelected={branchFilter}
              setSelectedFilter={setFilterBranch}
              placeHolder={'Branch'}
              isObject
              allOption
            />
          </DropdownTypesContainer>
         
        </div>
      </Header>
      <SearchAddContainer className="d-flex align-items-center">
        <FloatingMobileButton onClick={handleDownload}>
          <label>Download List</label>
        </FloatingMobileButton>
      </SearchAddContainer>
    </div>
  );
};

export default TaskFilter;
