import React, {Fragment,useState,useEffect} from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {PLUS, TIMES} from 'utils/constants/icons';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import {
  TitleSection,
  CloseButtonWrapper,
  SampleMessage,
} from 'shared/styles/constants/tagsStyles';
import TimeNewEmployee from './TimeNewEmployee';
import {getSchedulerPlanned} from 'utils/api/ClientApi';
const AddNewEmployee = ({
  setValues,
  useInput,
  index,
  nameSection,
  values,
  allEmployees,
  dropDownHandleSkill,
  dropDownHandleEmployee,
  dropDownHandleQuantity,
  choices,
  addNewVisitEmployee,
  deleteVisitSkill,
  deleteVisitEmployee,
  indexVisit,
  addedSkillMessage,
  readOnly = false,
  serviceId,
  clientId,
  dropDownHandleGender,
  editMode,
  indexEmployee,
  data,
  clientDropdownId
}) => {
  
  const [plannedTasks, setPlannedTasks] = useState([]);

  useEffect(() => {
    if (clientDropdownId) {
      getPlannedTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDropdownId])


  if (readOnly && allEmployees && allEmployees.length <= 0) {
    return <Fragment />;
  }





  const getPlannedTasks = async () => {
    const response = await getSchedulerPlanned(clientDropdownId);

    if(response.results){
      setPlannedTasks(response.results.map((item)=>{
        return {id: item.id, name: item.group_name}
      }));

    }
    
  };



  return (
    <div>
      <div className={'mb-2'}>
        <TitleSection>Employees and Skills Required</TitleSection>
      </div>

            <div key={indexEmployee} className={' mb-2'}>
             <div className='d-flex'>
                <Fragment />
                <CloseButtonWrapper
                disabled={!editMode}
                  onClick={() => {
                    deleteVisitEmployee(data, indexEmployee );
                  }}>
                  {TIMES}
                </CloseButtonWrapper>
     
              <div className={'w-100 p-0 d-flex'}>
                <DropdownSearchable
                  disabled={!editMode}
                   required
                  width={'30%'}
                  placeHolder={'No. of Employees'}
                  options={[
                    {id: 1, name: 1},
                    {id: 2, name: 2},
                    {id: 3, name: 3},
                    {id: 4, name: 4},
                    {id: 5, name: 5},
                  ]}
                 
      
                  selectedOption={
                    values[nameSection].new_requirement[indexEmployee]
                      ?    values[nameSection].new_requirement[indexEmployee].no_of_employees
                      : null
                  }
                  onChange={value =>
                    dropDownHandleQuantity(
                      {no_of_employees: value ? value : null},
                      indexEmployee
                    )
                  }
                />
                <DropdownSearchable
                  disabled={!editMode}
                    required
                  placeHolder={'Employee Type'}
                  marginLeft={'0.5%'}
                  width={'21%'}
                  options={choices.job_title}
                 
                  selectedOption={
                    values[nameSection].new_requirement
                      ?    values[nameSection].new_requirement[indexEmployee].skill_level
                      : null
                  }
                  onChange={value =>
                    dropDownHandleEmployee(
                      {job_title: value ? value : null},
                      indexEmployee
                    )
                  }
                />
                <DropdownSearchable
                
                  width={'68.6%'}
                  marginLeft={'0.5%'}
                  placeHolder={'Skills required'}
                  options={choices.skill}
                  isMulti={true}
               disabled={!editMode}
                  selectedOptionsProps={
                    values[nameSection].new_requirement[indexEmployee].skills
                    ?  values[nameSection].new_requirement[indexEmployee].skills
                    : null
                  }
                  onChange={value =>
                    dropDownHandleSkill(
                      {skill: value.length ? value : []},
                      indexEmployee
                    )
                    
                  }
                />
                              
              </div>
             </div>
             <div className={'w-100 ps-4 d-flex '}>
                 <DropdownSearchable
                   disabled={!editMode}
                  width={'100%'}
                  marginLeft={'2.4%'}
                  placeHolder={'Competency required'}
                  options={choices.competency_name}
                  isMulti={true}
                  selectedOptionsProps={
                    values[nameSection].new_requirement[indexEmployee].competency
                  }
                  onChange={value =>
                    dropDownHandleSkill(
                      {competency: value.length ? value : []},
                      indexEmployee
                    )
                    
                  }
                />
    
                {/* <DropdownSearchable
                  disabled={!editMode}
                   width={'30%'}
                   marginLeft={'0.5%'}
                  placeHolder={'Planned Tasks'}
                  options={plannedTasks ? plannedTasks : []}
                  isMulti={true}
                  selectedOptionsProps={
                    values[nameSection].new_requirement[indexEmployee].specific_groups
                  }
                  onChange={value =>
                    dropDownHandleSkill(
                      {specific_groups: value.length ? value : []},
                      indexEmployee
                    )
                    
                  }
                /> */}
                  </div> 
              <div className={'w-100 p-0 mt-2 d-flex'}>
              <DropdownSearchable
              
                  marginLeft={'2.4%'}
                  width={'15%'}
                  placeHolder={'Gender'}
                  options={choices.gender}
                  disabled={!editMode}
                   selectedOption={
                    values[nameSection].new_requirement ?
                    values[nameSection].new_requirement[indexEmployee].gender
                      : null
                  } 
            
                   onChange={value =>
                    dropDownHandleGender(
                      {gender: value ? value : null},
                      indexEmployee
                    )
                  } 
                />
              <>
              <TimeNewEmployee 
                disabled={!editMode}
                indexEmployee={indexEmployee}
                setValues={setValues}
                values={values}
                useInput={useInput}
                index={index}
                nameSection={ nameSection}
                // nameSection={`values[nameSection].new_requirement[${indexEmployee}]`}
                editMode={ editMode}            
                />
    </> 
      {/* <>
      <div className='ms-2'>
      <TimeSelector
       disabled={!editMode}
        labelTop={'Start time'}
        {...useInput(
          `${[nameSection]}.new_requirement[${indexEmployee}].start_time`
        )}
      />
      </div>
      <div className='ms-2'>
      <TimeSelector
       disabled={!editMode}
        labelTop={'End time'}
        {...useInput(
         '${[nameSection]}.new_requirement[${indexEmployee}]..end_time'
        )}
      />
      </div>
      <div className='ms-2'>
      <DurationInput
        disabled={!editMode}
        label_top={'Duration'}
        {...useInput(`${[nameSection]}.new_requirement[${indexEmployee}]..duration`)}
      />
      </div>
    </>  */}
                </div>
            </div>

      {readOnly ? (
        <Fragment />
      ) : (
        <Fragment>
          <SampleMessage   disabled={!editMode}>{addedSkillMessage}</SampleMessage>
          <div className={'mt-2'}>
            <PrimaryButtonForm minWidth="6rem" onClick={addNewVisitEmployee}   >
              {PLUS}
              ADD NEW EMPLOYEE
            </PrimaryButtonForm>
          </div>
        </Fragment>
      )}
    </div>
  );
};


export default AddNewEmployee;
