import React, {useEffect, useState, Fragment, useRef, useContext} from 'react';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import Dropdown from 'components/SharedComponents/Dropdown';
import AddModal from 'components/DashboardComponents/Settings/SettingsClient/Funder/AddModal';
// imported  new files
import {getClientTypes} from 'utils/api/CoreApi';
import {
  setClientContract,
  getFunderDetails,
  getClientTask,
  getClientGroupTask,
  getPlanned,
  deletePlannedTasks,
} from 'utils/api/ClientApi';
import {getFundersList} from 'utils/api/ClientApi';
import {useHistory} from 'react-router';
import _ from 'lodash';

import moment from 'moment';
import {
  InvalidOrEmptyValues,
  DetailsNotCreated,
  SAVE,
  SAVED,
  ERROR,
} from 'utils/constants/messages';
import {
  Row,
  Col,
  IconWrapper,
  DayPill,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
// import ModalServiceClient from './ModalServiceClient';
import {
  getClientsServicesById,
  getClientsServices,
  deleteServiceContract,
} from 'utils/api/ClientApi';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {useForm} from 'hooks/FormHook';
import {RiDeleteBinLine} from 'react-icons/ri';
import {TiArrowBackOutline} from 'react-icons/ti';
import {BsFolderPlus} from 'react-icons/bs';
import {headerColumns} from './headerColumsList';
import {
  LARGE_COL,
  MEDIUM_COL,
  EX_LARGE_COL,
  SMALL_COL,
  EX_SMALL_COL,
  EX_VERY_LARGE_COL,
} from 'shared/styles/constants/columns';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {getPageToSearchParam} from 'shared/methods';
import {useModal} from 'hooks/ModalHook';
import {FLOPPY, CROSS, CHECK} from 'utils/constants/icons';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router';
import {AppContext} from 'context';

import {
  getCategoryPermission,
  getPermissions,
  getSubSectionPermissions,
} from '../../../../utils/localStorage/user';
import {NoPermissionContainer} from 'components/DashboardComponents/Team/AddModal/styles';
import ClientTask from '../ClientTask';
import {MdEdit} from 'react-icons/md';
import {CloseButtonWrapper} from 'shared/styles/constants/tagsStyles';
import {IoIosArrowDown, IoIosArrowForward} from 'react-icons/io';
import {Toast} from 'primereact/toast';
import {client_Planned_task} from 'utils/choiceConstant';

const NEW_CONTRACT = 'new';

const PlannedTasks = ({
  selectedClientId,
  choices,
  allErrors,
  errorSplitPercentage,
  setErrorSplitPercentage,
  loadingFinish,
  textStatus,
  contractId,
  savedContract,
  setSavedContract,
  addressValues,
  clientFullName,
  setTextStatus,
  clientErrors,
  promisesWithErrors,
  // cliId,
  filterAdmin,
  filterSuperAdmin,
  contextSubscription,
  rightPermission,
  userClient,
  readPlannedTaskPermissions,
  createPlannedTaskPermissions,
  updatePlannedTaskPermissions,
  deletePlannedTaskPermissions,
  handleChangeChoices,
}) => {
  const {values, setValues, useInput, isValid, errors, setCheckRequires} =
    useForm({});
  const [toastData, setToastData] = useState();

  const [idSelected, setIdSelected] = useState('');
  const [selectedData, setSelectedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalValue, setModalValue] = useState(false);
  const [pages, setPages] = useState(0);
  const [plannedTasks, setPlannedTasks] = useState([]);
  const [plannedTasksLength, setPlannedTasksLength] = useState();
  const [simplePagination] = useState(true);
  const [dateError, setDateError] = useState([]);
  const [timeError, setTimeError] = useState([]);
  const [clientTaskOptions, setClientTaskOptions] = useState([]);
  const [groupTaskOptions, setGroupTaskOptions] = useState([]);
  const [clientTypes, setClientTypes] = useState([]); //clienttype
  const mountedRef = useRef(true);
  const [expandFields, setExpandFields] = useState({});

  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
  const {
    setValue: setModalFunderValue,
    value: modalFunderValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const toast = useRef();

  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [modalLoading, setModalLoading] = useState(true);

  const servAddressSection = 'service_address';
  const history = useHistory();
  const location = useLocation();
  const {handleGetChoices} = useContext(AppContext);

  useEffect(() => {
    handleGetChoices(client_Planned_task);
  }, []);
  useEffect(() => {
    if (toastData) {
      if (toastData?.status === 'True') {
        setLoadingSave(false);

        handleModal();
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: `${toastData.message}`,
        });
        setValues({});
      } else {
        setLoadingSave(false);

        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: `${toastData.message}`,
        });
      }
    }
  }, [toastData]);
  useEffect(() => {
    if (selectedClientId) {
      getPlannedTasks();
    } else {
      setModalLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId, currentPage, savedContract]);

  useEffect(() => {
    if (!selectedClientId) {
      setModalValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId]);

  useEffect(() => {
    if (contractId) {
      values.service_clients[0].id = contractId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);

  useEffect(() => {
    if (choices && choices.care_task && choices.group_task) {
      groupTaskFn();
    }
  }, [choices]);

  useEffect(() => {
    const haveDateErrors = dateError.find(data => data === true);

    if (showError && errors.length === 0 && !allErrors && !haveDateErrors) {
      setShowError(false);
      setCheckRequires(false);
    }

    if (allErrors) {
      setShowError(true);
      setCheckRequires(true);
      if (clientErrors.length >= 1) {
        setErrorMessage(DetailsNotCreated);
      } else {
        setErrorMessage(InvalidOrEmptyValues);
      }
    }

    if (promisesWithErrors.length >= 1) {
      setShowError(true);
      setCheckRequires(true);

      let error = '';

      promisesWithErrors.forEach(promise => {
        error += `*${promise} `;
      });

      setErrorMessage(`Error while saving ${error}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, allErrors, dateError]);

  const getPlannedTasks = async () => {
    const pageToSearch = getPageToSearchParam(
      '',
      setCurrentPage,
      simplePagination,
      currentPage
    );
    const response = await getPlanned({
      selectedClientId,
      idSelected,
      ...pageToSearch,
    });

    if (response.results) {
      setPlannedTasksLength(response.results.length);
      setPlannedTasks(response.results || []);
      setPages(Math.ceil(response.count / PAGE_SIZE_RENDER));
      setModalLoading(false);

      const initialExpansionState = {};

      response.results.forEach((employee, cIndex) => {
        initialExpansionState[cIndex] = {open: false};

        employee.care_task &&
          employee.care_task.forEach((employee, eIndex) => {
            initialExpansionState[cIndex][eIndex] = {open: false};
          });
      });

      setExpandFields({...initialExpansionState});
    }
  };

  const getValueName = (res, type) => {
    let value = [];
    if (type) {
      res.forEach(resData => {
        choices.care_task.forEach(careData => {
          if (careData.id === resData.care_task) {
            value.push(careData);
          }
        });
      });
    } else {
      res.forEach(resData => {
        choices.group_task.forEach(careData => {
          if (careData.id === resData.group_task) {
            value.push(careData);
          }
        });
      });
    }
    return value;
  };

  const groupTaskFn = async () => {
    // let clientId = cliId ? cliId : selectedClientId;
    const responseGroupTask = await getClientGroupTask(selectedClientId);
    const responseTask = await getClientTask(selectedClientId);

    if (responseTask) {
      const tempTask = getValueName(responseTask, 1);
      setClientTaskOptions(tempTask);
    }
    if (responseGroupTask) {
      const tempGroupTask = getValueName(responseGroupTask, 0);
      setGroupTaskOptions(tempGroupTask);
    }
  };

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };

  const handleRowClick = id => {
    setIdSelected(id);
    setModalValue(true);
  };
  const handleModalAfterSave = event => {
    setModalValue(false);
    setIdSelected('');
    setValues({});
    setSelectedData([]);
    getPlannedTasks();
  };

  const handleModal = event => {
    handleChangeChoices();
    setModalValue(!modalValue);
    setIdSelected('');
    setValues({});
    setSelectedData([]);
    if (event === NEW_CONTRACT) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    } else {
      getPlannedTasks();
    }
  };

  const handleDeleteContract = async () => {
    try {
      await deletePlannedTasks(selectedClientId, idSelected);
      //Todo: confirm deleted client message
    } catch (error) {
      //Todo: error message and add the methods of finally in to ok modal function
    } finally {
      setModalDeleteValue(false);
      getPlannedTasks();
      setIdSelected('');
    }
  };

  const toDeleteClient = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };

  const getCareTaskName = id => {
    let name = '';
    if (choices && choices.care_task) {
      choices.care_task.forEach(careData => {
        if (careData.id === id) {
          name = careData.name;
        }
      });
    }
    return name;
  };

  const handleEditClick = item => {
    setIdSelected(item);
    setModalValue(true);
    setValues({});
  };

  const handleExpansion = (clientIndex, employeeIndex) => {
    if (employeeIndex === null) {
      if (expandFields[clientIndex].open) {
        expandFields[clientIndex].open = false;
        setExpandFields({...expandFields});
      } else {
        expandFields[clientIndex].open = true;
        setExpandFields({...expandFields});
      }
    } else {
      if (
        expandFields[clientIndex] &&
        expandFields[clientIndex][employeeIndex] &&
        expandFields[clientIndex][employeeIndex].open
      ) {
        expandFields[clientIndex][employeeIndex].open = false;
        setExpandFields({...expandFields});
      } else {
        if (
          expandFields[clientIndex] &&
          expandFields[clientIndex][employeeIndex] &&
          !expandFields[clientIndex][employeeIndex].open
        )
          expandFields[clientIndex][employeeIndex].open = true;
        setExpandFields({...expandFields});
      }
    }
  };

  console.log('planned data', createPlannedTaskPermissions, plannedTasks, modalValue)

  return (
    <ComponentDynamic loading={modalLoading}>
      <Toast ref={toast} position="top-right" />

      {(selectedClientId ? readPlannedTaskPermissions : createPlannedTaskPermissions) ?
      <div>
        {
          createPlannedTaskPermissions &&
          (plannedTasks.length >= 1) &&
          !modalValue ? (
            <div className={'w-100 d-flex justify-content-center mb-3 mt-1'}>
              <div className={'text-primary font-weight-bold'}>
                <div>
                  <PrimaryButtonForm
                    minWidth="6rem"
                    onClick={() => handleModal(NEW_CONTRACT)}>
                    <BsFolderPlus size={18} />
                    <span className={'ms-2'}>ADD PLANNED TASK</span>
                  </PrimaryButtonForm>
                </div>
              </div>
            </div>
          ) : (
            <Fragment />
          )
        }
        {modalValue ? (
          <div className={'w-100 d-flex justify-content-center'}>
            <div>
              <PrimaryButtonForm
                bgcolor={'PURPLE_GRAY_500'}
                minWidth="6rem"
                onClick={handleModal}>
                <div className={'d-flex align-items-center'}>
                  <TiArrowBackOutline size={24} />
                  <div>BACK</div>
                </div>
              </PrimaryButtonForm>
            </div>
          </div>
        ) : (
          <Fragment />
        )}

        {plannedTasks && plannedTasks.length >= 1 && !modalValue ? (
          <>
            <Table
              headerColumns={headerColumns}
              noEditOption={true}
              //noNeed={false}
              headerPadding={false}
              fontSize="0.75rem"
              callBackQuerySort={setQuerySort}>
              <div>
                {plannedTasks.map((task, index) => {
                  // const endDate = clientContract.end_date
                  //   ? moment(clientContract.end_date).format('DD-MM-YYYY')
                  //   : 'Unset';
                  // const startDate = clientContract.start_date
                  //   ? moment(clientContract.start_date).format('DD-MM-YYYY')
                  //   : 'Unset';

                  // const daysSelected = getDaysSelected(clientContract.visits);
                  return (
                    <div key={index}>
                      <Row bgColor Shadow zIndex={plannedTasksLength - index}>
                        <Col
                          onClick={() => {
                            handleRowClick(task.id);
                          }}
                          // style={{maxWidth: '100%', paddingRight: '18%'}}
                          max_width={SMALL_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          overlap={true}>
                          {task.group_name}
                        </Col>
                        <Col
                          onClick={() => {
                            handleRowClick(task.id);
                          }}
                          // style={{maxWidth: '100%', paddingRight: '18%'}}
                          max_width={EX_VERY_LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          overlap={true}>
                          {/* {task.group_name} */}
                        </Col>
                        <Col
                          onClick={() => {
                            handleRowClick(task.id);
                          }}
                          // style={{maxWidth: '100%', paddingRight: '18%'}}
                          max_width={EX_VERY_LARGE_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          overlap={true}>
                          {/* {task.group_name} */}
                        </Col>

                       {updatePlannedTaskPermissions && <Col
                          max_width={EX_SMALL_COL}
                          Shrink
                          reducePadding
                          Capitalize
                          overlap={true}
                          Purple>
                          <IconWrapper
                            onClick={() => {
                              handleRowClick(task.id);
                            }}>
                            <MdEdit />
                          </IconWrapper>
                        </Col>}
                        {deletePlannedTaskPermissions && <Col
                          max_width={EX_SMALL_COL}
                          className="sm-hidden"
                          // Shrink
                          NoFlexGrow>
                          {
                            <IconWrapper
                              onClick={() => {
                                toDeleteClient(task.id);
                              }}>
                              <RiDeleteBinLine />
                            </IconWrapper>
                          }
                        </Col>}
                      </Row>
                      <div className="mb-1" />
                    </div>
                  );
                })}
                {/* {plannedTasks && plannedTasks.map((runPin, runIndex) => {
                    return (
                      <div key={runIndex}>
                        <Row bgColor className="mb-1">
                          <ColsGrouper className="d-flex">
                            <Col
                              max_width={EX_SMALL_COL}
                              className="d-flex justify-content-center align-content-center">
                              <CloseButtonWrapper
                                className="closeButtonWrapper"
                                onClick={() => {
                                  handleExpansion(runIndex, null);
                                }}>
                                {expandFields[runIndex] && expandFields[runIndex].open ? (
                                  <IoIosArrowDown
                                    style={{ color: '#6200f9', fontWeight: 'bold' }}
                                  />
                                ) : (
                                  <IoIosArrowForward
                                    style={{ color: '#6200f9', fontWeight: 'bold' }}
                                  />
                                )}
                              </CloseButtonWrapper>
                            </Col>
                            <Col max_width={LARGE_COL} Center>{runPin.group_name}</Col>
                            <Col
                              max_width={SMALL_COL}
                              Center
                              className='p-2'
                              overlap={true}
                              Selected={
                                expandFields[runIndex] && expandFields[runIndex].open ? true : false
                              }>
                              {runPin && runPin.care_task && runPin.care_task.length > 1 ? (
                                <p>Various</p>
                              ) : (
                                <p>{runPin && runPin.care_task && runPin.care_task[0] && getCareTaskName(runPin.care_task[0].care_task)}</p>
                              )}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              // className='p-2 pe-4'
                              Center
                              overlap={true}
                              Selected={
                                expandFields[runIndex] && expandFields[runIndex].open ? true : false
                              }>
                              {runPin && runPin.care_task && runPin.care_task.length > 1 ? (
                                <>Various</>
                              ) : runPin && runPin.care_task && runPin.care_task.length > 1 ? (
                                <p>Various</p>
                              ) : (
                                <p>{runPin && runPin.care_task && runPin.care_task[0] && runPin.care_task[0].message}</p>
                              )}
                            </Col>
                            <Col
                              bgColor
                              className="sm-hidden"
                              Center
                              Icon
                              Purple>
                              <IconWrapper
                                onClick={() => {
                                  handleEditClick(runPin.id);
                                }}
                              >
                                <MdEdit />
                              </IconWrapper>
                            </Col>
                            <Col
                              bgColor
                              className="sm-hidden"
                              Center
                              Shrink
                              NoFlexGrow>
                              <IconWrapper
                              // onClick={() => {
                              //   toDeletePinning(
                              //     pinnedEmployee.repeat_on_data[visitIndex].client_pinned_employee_id,
                              //     pinnedEmployee.repeat_on_data[visitIndex].client_pinned_employee_day_id
                              //   );
                              // }}
                              >
                                <RiDeleteBinLine />
                              </IconWrapper>
                            </Col>
                          </ColsGrouper>
                        </Row>
                        <div>

                          {expandFields[runIndex] &&
                            expandFields[runIndex].open ? (
                            runPin && runPin.care_task && runPin.care_task.map(
                              (pinnedVisit, employeeIndex) => {
                                return (
                                  <div key={employeeIndex}>
                                    <Row className="mb-1">
                                      <ColsGrouper className="d-flex">
                                        <Col max_width={EX_SMALL_COL}></Col>
                                        <Col max_width={LARGE_COL}></Col>
                                        <Col
                                          bgColor
                                          Center
                                          className="p-2"
                                          overlap={true}
                                          max_width={LARGE_COL}>
                                          {getCareTaskName(pinnedVisit.care_task)}
                                        </Col>
                                        <Col
                                          bgColor
                                          Center
                                          className="p-2"
                                          overlap={true}
                                          max_width={LARGE_COL}>
                                          {pinnedVisit.message}
                                        </Col>

                                      </ColsGrouper>
                                    </Row>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    );
                  })} */}
                <div className="mt-3">
                  <Pagination
                    simplePagination={simplePagination}
                    setCurrentPage={setCurrentPage}
                    totalPages={pages}
                    currentPage={currentPage}
                    dataLength={plannedTasks.length}
                    dropdownEntriesLimit={false}
                  />
                </div>
              </div>
            </Table>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div>
                <PrimaryButtonForm
                  bgcolor="PURPLE_GRAY_500"
                  minWidth="6rem"
                  className={'me-2'}
                  onClick={() => {
                    history.push('/client');
                  }}>
                  <span className={'me-2'}>{'Cancel'}</span>
                </PrimaryButtonForm>
              </div>
            </div>
          </>
        ) : (
          <>
            <Fragment />
            {!modalValue && (
              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                <div>
                  {/*  this extra btn used 
          <PrimaryButtonForm
                      bgcolor="PURPLE_GRAY_500"
                      minWidth="6rem"
                      className={'me-2'}
                      onClick={()=>{
                        history.push('/client')
                      }}>
                      <span className={'me-2'}>{'Cancel'}</span>
                    </PrimaryButtonForm> */}
                </div>
              </div>
            )}
          </>
        )}

        {!modalValue && plannedTasks.length >= 1 ? null : (
          <ClientTask
            setToastData={setToastData}
            selectedClientId={selectedClientId}
            cliId={selectedClientId}
            choices={choices}
            selectedData={idSelected}
            handleModal={handleModalAfterSave}
            handleChangeChoices={handleChangeChoices}
          />
        )}
        <div>
          <ModalDecision
            type="delete"
            title="Warning"
            body={
              'Are you sure you want to delete this task? \n DELETING the task will remove ALL records from database.'
            }
            // subBody={
            //   'TIP (Recommended) - you could change the task status to discard instead to retain database records.'
            // }
            onOk={handleDeleteContract}
            onCancel={() => {
              setIdSelected('');
            }}
            okTitle={'DELETE'}
            {...bindModalDelete}
          />
        </div>
      </div>
       :
         <NoPermissionContainer>
           You don't have permission to {selectedClientId ? 'read' : 'create'} the information.
         </NoPermissionContainer>
         } 
    </ComponentDynamic>
  );
};

PlannedTasks.propTypes = {
  choices: PropTypes.object.isRequired,
  selectedClientId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  allErrors: PropTypes.bool.isRequired,
  setErrorSplitPercentage: PropTypes.func.isRequired,
  errorSplitPercentage: PropTypes.string.isRequired,
  loadingFinish: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  textStatus: PropTypes.string.isRequired,
  contractId: PropTypes.string.isRequired,
  savedContract: PropTypes.string.isRequired,
  setSavedContract: PropTypes.func.isRequired,
  addressValues: PropTypes.object.isRequired,
  setTextStatus: PropTypes.func.isRequired,
  clientErrors: PropTypes.array.isRequired,
  promisesWithErrors: PropTypes.array.isRequired,
};

export default PlannedTasks;