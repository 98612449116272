import React, { Fragment, useContext, useEffect, useState } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { PLUS, TIMES } from 'utils/constants/icons';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { SOFT_RED } from 'shared/styles/constants/colors';
import DateSelector from 'components/SharedComponents/DateSelector';
import { COPY } from 'utils/constants/icons';
import {
  CloseButtonWrapper,
  SampleMessage,
  IconWrapper,
} from 'shared/styles/constants/tagsStyles';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import WeekDays from './Table/WeekDays';
import RateCode from './Table/RateCode';
import RateDetails from 'components/DashboardComponents/Settings/SettingsRateCard/BillRate/AddModal/RateDetails';
import { AppContext } from "context"

const AddRate = ({
  setValues,
  useInput,
  indexRate,
  nameSection,
  values,
  deleteAddRate,
  addedSkillMessage,
  readOnly = false,
  data,
  addRate,
  hitNewRate,
  addRateCopy,
  addRateDetails,
  checkBoxTick,
  travelTab,
  copyToken,
  dummyID,
  filterShiftType,
  onCallTab,
  officeTab,
  officeShiftType,
  rateSource,
  absencePaid,
  absenceType,
  abscenceTab,
  setForcedRateperHoursDataPassed,
  isPayRate,
  shiftTab,
  shiftType,
  waitTab
}) => {

  const [serviceCategoryData, setServiceCategoryData] = useState([]);
  const [travelModeOptions, setTravelModeOptions] = useState([]);
  const [rateCodeRowStatus, setRateCodeRowStatus] = useState(false);
  const { contextChoices } = useContext(AppContext);
  const [forcedRateperHours, setForcedRateperHours] = useState(false);
  const [categoryType, setCategoryType] = useState('')
  useEffect(() => {
    if (values.id) {
      setRateCodeRowStatus(values[nameSection][indexRate].is_active)
    }
  }, [])
  useEffect(() => {
    const final = contextChoices && contextChoices?.service_category &&
      contextChoices?.service_category?.filter(y => y.category_type === categoryType)
    setServiceCategoryData(final && final.map(y => ({ id: y && y.id, name: y && y.name })))
    
    setTravelModeOptions(contextChoices && contextChoices.travel_type && contextChoices.travel_type.map(y => {
      return { id: y && y.id, name: y && y.name }
    }))

  }, [contextChoices, categoryType])

  useEffect(() => {
    if (values[nameSection][indexRate].rate_source == "Over Ride") {
      setForcedRateperHours(true);
    } else if (values[nameSection][indexRate].rate_source !== "Over Ride") {
      setForcedRateperHours(false);
    }

  }, [])

  const dropDownHandleRate = (item, nameItem) => {

    if (nameItem == 'rate_source' && item[nameItem] !== "Over Ride") {
      setForcedRateperHours(false);
      setForcedRateperHoursDataPassed(false);
    } else if (nameItem == 'rate_source' && item[nameItem] == "Over Ride") {
      setForcedRateperHours(true);
      setForcedRateperHoursDataPassed(true);
    }
    else if (nameItem === 'service_categories_type') {
      setCategoryType(item[nameItem])
      values[nameSection][indexRate].service_categories = null;

    }
    values[nameSection][indexRate][nameItem] = item[nameItem];
    setValues({ ...values })
  };
  const dropDownHandleSelect = (item, nameItem, index) => {
    values[nameSection][indexRate].details_rate[index][nameItem] = item[nameItem]
    setValues({ ...values })
  }
  const handleRateCodeRowStatus = (indexRate) => {
    setRateCodeRowStatus(!rateCodeRowStatus)
    values[nameSection][indexRate].is_active = !rateCodeRowStatus;
    setValues({ ...values })
  }
  const deleteRateDetails = (index) => {
    if (values.rateValue) {
      values.rateValue[indexRate].details_rate.splice(index, 1)
      setValues({ ...values })
    }
  }
  useEffect(() => {
    if (values[nameSection][indexRate]?.service_categories) {
      const serviceCategoryType = contextChoices && contextChoices?.service_category &&
        contextChoices?.service_category?.find(item => item.id === values[nameSection][indexRate].service_categories)
      setCategoryType(serviceCategoryType?.category_type)
    }
  }, [values[nameSection][indexRate]?.service_categories])

  const switchDropdownTraveMode = (firstProps) => {
    if (travelTab && !(values[nameSection][indexRate].travel_type === 'fixed')) {
      return (
        <div style={{width: "15.5rem"}}>
          <DropdownSearchable
            // data-error
            required={(values[nameSection][indexRate].travel_type === 'fixed') ? false : true}
            {...firstProps}
            placeHolder={'Travel mode'}
            options={travelModeOptions ? travelModeOptions : []}
            selectedOption={
              values[nameSection][indexRate].travel_mode
                ? values[nameSection][indexRate].travel_mode
                : null
            }
            onChange={value =>
              dropDownHandleRate(
                { travel_mode: value ? value : null },
                'travel_mode', (values[nameSection][indexRate].travel_type === 'fixed') ? 'isRequired' : ''
              )
            }

          />
        </div>
      )
    } else {
      return
    }
  };


  const switchDropdownAbsencePaid = (firstProps) => {
    if (abscenceTab) {
      return (
        <div style={{width: "15.5rem"}}>
          <DropdownSearchable
            required
            {...firstProps}
            placeHolder={'Paid/Unpaid'}
            options={absencePaid}
            selectedOption={
              values[nameSection][indexRate]
                ? values[nameSection][indexRate].absence_paid
                : null
            }
            onChange={value =>
              dropDownHandleRate(
                { absence_paid: value ? value : null },
                'absence_paid', 'isRequired'
              )
            }
          />
        </div>
      )
    } else {
      return
    }
  };
  const getRateCardOption = input => {
    if (!input) return;
    let pay_type = contextChoices?.pay_type && contextChoices?.pay_type.find(el => el.name === input)
    let shift_type = contextChoices?.regular_shift_type
    debugger
    switch (input) {
      case 'Office':
        return shift_type && shift_type?.filter(shift => shift?.pay_type === pay_type?.id)
      case 'On Call':
        return shift_type && shift_type?.filter(shift => shift?.pay_type === pay_type?.id)
      case 'Shift':
        return shift_type && shift_type?.filter(shift => shift?.pay_type === pay_type?.id)
      default:
        return [];
    };
  }
  const switchDropdownSearchable = (firstProps, secondProps, thirdProps, fourthprops, fifthProps) => {
    // render correct input type depending of the end_type selected
    if (travelTab) {
      return (
        <div style={{width: "15.5rem"}}>
          <DropdownSearchable
            required
            {...secondProps}
            placeHolder={'Travel category'}
            options={[
              { id: 'Fixed', name: 'Fixed' },
              { id: 'Travel Time', name: 'Travel Time' },
              { id: 'Mileage Rate', name: 'Mileage Rate' },
            ]}
            selectedOption={
              values[nameSection][indexRate]
                ? values[nameSection][indexRate].travel_type
                : null
            }
            onChange={value =>
              dropDownHandleRate(
                { travel_type: value ? value : null },
                'travel_type', 'isRequired'
              )
            }
          />
        </div>
      )
    } else if (onCallTab || officeTab || shiftTab ) {
      return (
        <div style={{width: "15.5rem"}}>
          <DropdownSearchable
            required={!shiftTab}
            {...thirdProps}
            placeHolder={'Shift Type'}
            options={getRateCardOption(onCallTab ? 'On Call' : shiftTab ? "Shift" : "Office")}
            selectedOption={
              values[nameSection][indexRate]
                ? values[nameSection][indexRate].shift_type
                : null
            }
            onChange={value =>
              dropDownHandleRate(
                { shift_type: value ? value : null },
                'shift_type', shiftTab ? "" : 'isRequired'
              )
            }

          />
        </div>
      )
    } else if (abscenceTab) {
      return (
        <div style={{width: "15.5rem"}}>
            <DropdownSearchable
              required
              {...fourthprops}
              placeHolder={'Abscence Type'}
              options={absenceType}
              selectedOption={
                values[nameSection][indexRate]
                  ? values[nameSection][indexRate].absence_type
                  : null
              }
              onChange={value =>
                dropDownHandleRate(
                  { absence_type: value ? value : null },
                  'absence_type', 'isRequired'
                )
              }

            />
          
        </div>
      )
    } else {
      return (
        <div style={{ display: 'flex'}}>
          <div style={{width: "15.5rem"}} className='me-1'>
            <DropdownSearchable
              required
              {...fifthProps}
              placeHolder={'Service category type'}
              options={[
                { id: 'client', name: 'Client' },
                { id: 'location', name: 'Location' }
              ]}
              selectedOption={
                categoryType
                  ? categoryType
                  : null
              }
              onChange={value =>
                dropDownHandleRate(
                  { service_categories_type: value ? value : null },
                  'service_categories_type', 'isRequired')
              }

            />
          </div>
          <div style={{width: "15.5rem"}} className='ms-1'>
            <DropdownSearchable
              required
              {...firstProps}
              placeHolder={'Service category'}
              options={serviceCategoryData}
              selectedOption={
                values[nameSection][indexRate]
                  ? values[nameSection][indexRate].service_categories
                  : null
              }
              onChange={value =>
                dropDownHandleRate(
                  { service_categories: value ? value : null },
                  'service_categories', 'isRequired'
                )
              }

            />
          </div>
        </div>
      )
    }
  };

  const DynamicOvertime = (firstProps, secondProps) => {
    if (onCallTab) {
      return
    } else if (officeTab || abscenceTab || shiftTab || waitTab) {
      return (
        <div style={{width: "15.5rem"}}>
          <DropdownSearchable
            required
            {...secondProps}
            placeHolder={'Rate source'}
            options={rateSource}
            selectedOption={
              values[nameSection][indexRate]
                ? values[nameSection][indexRate].rate_source
                : null
            }
            onChange={value =>
              dropDownHandleRate(
                { rate_source: value ? value : null },
                'rate_source', 'isRequired'
              )
            }

          />
        </div>
      )
    } else {
      return (
        <div style={{width: "15.5rem" , marginBottom: '-5px'}}>
          <InputLabelTop
            {...firstProps}
            type="number"
            id="over_time_rate"
            label={(values[nameSection][indexRate].travel_type === 'fixed') ? 'Overtime rate' : 'Overtime rate/hr'}
            maxlength={5}
          />
        </div>
      )
    }
  }

  const conditionalRateperHours = (propsone, propstwo) => {
    if ((abscenceTab || officeTab || shiftTab || waitTab) && forcedRateperHours) {
      return (
        <div  style={{width: "15.5rem", marginBottom: '-5px'}}>
        <InputLabelTop
          required
          type="number"
          id="rate_per_hours"
          label={'Rate/hr'}
          {...propsone}
          maxlength={5}
        />
        </div>)
    } else if ((abscenceTab || officeTab || shiftTab || waitTab) && !forcedRateperHours) {
      return (
        <div  style={{width: "15.5rem", marginBottom: '-5px'}}>
        <InputLabelTop
          disabled={true}
          type="number"
          id="rate_per_hours"
          label={'Rate/hr'}
          {...propstwo}
          maxlength={5}
        />
        </div>)
    } else if ((!abscenceTab || !officeTab || !shiftTab || !waitTab) && !forcedRateperHours) {
      return (
        <div style={{width: "15.5rem", marginBottom: '-5px'}}>
        <InputLabelTop
          required
          type="number"
          id="rate_per_hours"
          label={(values[nameSection][indexRate].travel_type === 'fixed') ? 'Rate' : 'Rate/hr'}
          {...propstwo}
          maxlength={5}
        />
        </div>)
    }
  }
  const service_categories_hook = useInput('service_categories', 'isRequired')
  const travel_categories_hook = useInput('travel_categories', 'isRequired')
  const shift_type_hook = useInput('shift_type', 'isRequired')
  const absence_type_hook = useInput('absence_type', 'isRequired')
  const service_categories_type_hook = useInput('service_categories_type', 'isRequired')
  const  is_multiplier_inclusive_hook = useInput(`${[nameSection]}[${indexRate}].is_multiplier_inclusive`)

  return (
    <div>
      <div key={indexRate} className={`mt-1 ${indexRate == 0 ? 'ms-2' : ''}`}>
        <div className='d-flex'>
          {indexRate !== 0 &&
            <CloseButtonWrapper
              onClick={() => {
                deleteAddRate(data, indexRate);
              }}>
              {TIMES}
            </CloseButtonWrapper>
          }

          <div className={'d-flex align-items-center flex-wrap'} style={{gap: "0.5rem"}}>
            
              {!waitTab && 
                switchDropdownSearchable(
                  {...service_categories_hook},
                  {...travel_categories_hook},
                  {...shift_type_hook},
                  {...absence_type_hook},
                  {...service_categories_type_hook}

                  // { ...useInput('service_categories', 'isRequired') },
                  // { ...useInput('travel_categories', 'isRequired') },
                  // { ...useInput('shift_type', 'isRequired') },
                  // { ...useInput('absence_type', 'isRequired') },
                  // { ...useInput('service_categories_type', 'isRequired') },

                  // {...useInput('absence_paid', 'isRequired')},
                  
                )
              }

              {switchDropdownTraveMode(
                { ...useInput(`travel_type`, (values[nameSection][indexRate].travel_type === 'fixed') ? '' : 'isRequired') }
              )}

              {switchDropdownAbsencePaid(
                { ...useInput('absence_paid', 'isRequired') }
              )}

              
                <WeekDays
                  values={values}
                  setValues={setValues}
                  indexRate={indexRate}
                  nameSection={nameSection}
                  checkedCondition={false}
                  copyToken={copyToken}
                  dummyID={dummyID}
                // checkBoxTick={checkBoxTick}
                />
              
              <>
                
                  <RateCode
                    contextChoices={contextChoices}
                    values={values}
                    setValues={setValues}
                    indexRate={indexRate}
                    nameSection={nameSection}
                    hitNewRate={hitNewRate}
                    data={data}
                    copyToken={copyToken}
                    dummyID={dummyID}
                  />
                
               {!abscenceTab && 
               
                  <ToggleButton
                    label="Include multiplier"
                    disabled={false}
                    useInput={is_multiplier_inclusive_hook}
                    id="is_multiplier_inclusive"
                  //onClick={handelOverNight}
                  />
                }
              </>
              
                <DateSelector
                  isRequired
                  labelTop="Date From"
                  {...useInput(`${[nameSection]}[${indexRate}].date_from`, 'isRequired')}
                />
              
              
                <DateSelector
                  labelTop="Date To"
                  {...useInput(`${[nameSection]}[${indexRate}].date_to`)}
                />
              
              
                <TimeSelector
                  isRequired
                  labelTop={'Time from(hrs)'}
                  timeFormat={"HH:mm"}
                  dateFormat={"HH:mm"}
                  {...useInput(`${[nameSection]}[${indexRate}].time_from`, 'isRequired')}
                  placeholderText={'hh:mm'}
                  locale={"sv-sv"}
                  timeCaption={'Time from'}
                />
              
              
                <TimeSelector
                  isRequired
                  labelTop={'Time to(hrs)'}
                  timeFormat={"HH:mm"}
                  dateFormat={"HH:mm"}
                  {...useInput(`${[nameSection]}[${indexRate}].time_to`, 'isRequired')}
                  placeholderText={'hh:mm'}
                  locale={"sv-sv"}
                  timeCaption={'Time to'}
                />
              

              {isPayRate && 
                <DropdownSearchable
                  width="15.5rem"
                  required
                  placeHolder={'Pay Time Unit'}
                  options={contextChoices.bill_time_unit || []
                    // [
                    //   { id: 'hour', name: 'Hour' },
                    //   { id: 'minute', name: 'Minute' },
                    //   { id: 'week', name: 'Week' },
                    //   { id: '4-Week', name: '4-Week' }
                    // ]
                  }
                  selectedOption={
                    values[nameSection][indexRate]
                      ? values[nameSection][indexRate].time_unit
                      : null
                  }
                  onChange={(value) =>
                    dropDownHandleRate(
                      { time_unit: value ? value : null },
                      'time_unit'
                    )
                  }
                />
              }

              
              {DynamicOvertime(
                { ...useInput(`${[nameSection]}[${indexRate}].over_time_rate`) },
                { ...useInput(`rate_source`, 'isRequired') }
                )}
            

              

                {conditionalRateperHours(
                  { ...useInput(`${[nameSection]}[${indexRate}].bill_rate_per_hour`, 'isRequired') },
                  { ...useInput(`${[nameSection]}[${indexRate}].bill_rate_per_hour`) }
                  )}
              

              
                <div style={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                }}>
                  <div >
                    <IconWrapper
                      className={'ms-1 me-1'}
                      color={SOFT_RED}
                      onClick={() => addRateCopy(indexRate)}
                    >
                      {COPY}
                    </IconWrapper>
                  </div>
                  <div className='d-flex justify-content-end align-right' >
                    <ToggleButton
                      onClick={() => handleRateCodeRowStatus(indexRate)}
                      status={values[nameSection][indexRate].is_active}
                    />
                  </div>
                </div>
              
          </div>

        </div>

        <div className='ms-1 mb-4'>
          {
            values[nameSection] && values[nameSection][indexRate] &&
            values[nameSection] && values[nameSection][indexRate].details_rate
            && values[nameSection] && values[nameSection][indexRate].details_rate.map((detailRate, index) => {
              return (
                <div key={detailRate.id + index} >
                  <RateDetails
                    detailRate={detailRate}
                    contextChoices={contextChoices}
                    useInput={useInput}
                    index={index}
                    indexRate={indexRate}
                    deleteRateDetails={deleteRateDetails}
                    dropDownHandleSelect={dropDownHandleSelect}
                    travel_cat={values[nameSection][indexRate].travel_type}
                  />
                </div>
              )
            })
          }
          <PrimaryButtonForm minWidth="3rem" onClick={() => addRateDetails(indexRate)}>
            {PLUS}
            Rate Details
          </PrimaryButtonForm>
        </div>

      </div>

      {readOnly ? (
        <Fragment />
      ) : (
        <Fragment>
          <SampleMessage>{addedSkillMessage}</SampleMessage>
          <div className={' '}>
            <PrimaryButtonForm minWidth="6rem" onClick={addRate}>
              {PLUS}
              RATE
            </PrimaryButtonForm>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default AddRate;
