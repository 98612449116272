import React from "react";
import { StyledCheck } from "./styles";
import { useEffect } from "react";

const Checkbox = ({ item, id, label, checked , typeHeader = false,
  handleSelectItem = () => {}
}) => {

  useEffect(() => {
    checked=checked
  },[checked])

  return (
    <StyledCheck paddingLeft={typeHeader ?"10px !important" : ""} className="form-check">
      <div className="form-check">
        <input
          id={id}
          // {...props.useInput}
          // onClick={(e) => {
          //   console.log("handleSelectItem",checked[item.id])
          //   handleSelectItem(e, item)
          // }}
          type="checkbox"
          className="form-check-input"
          checked={checked[item.id] === true}
          // readOnly
        />
        <label 
        // onClick={(e) => handleSelectItem(e, item)}
         className="form-check-label" >
          {label}
        </label>
      </div>
    </StyledCheck>
  );
};

export default Checkbox;
