import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DateSelector from 'components/SharedComponents/DateSelector';
import { KeyPress } from 'utils/constants/regularExpressions';
import {
  ButtonWeek,
  CloseButtonWrapper,
  FlexGrid3Container,
  FlexGrid3Item,
  TitleSection,
  WeekStyle,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import React, { useContext } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER, HOUR, MINUTE
} from 'utils/constants/serviceVisits';
import { Fragment } from 'react';
import moment from 'moment';
import { useState } from 'react';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import { AppContext } from 'context';
import Dropdown from 'components/SharedComponents/Dropdown';
import DropdownMedication from 'components/SharedComponents/DropdownMedication';
import { TIMES } from 'utils/constants/icons';

const AddMedicationReview = ({
  nameSection,
  useInput,
  sectionStyle,
  setValues,
  editMode,
  choices,
  values,
  itemData,
  indexReview,
  deleteReview,
}) => {
  const ins_hook = useInput(`review_data[${indexReview}].review_comment`);
  const newDoseTime = useInput(`review_data[${indexReview}].review_time`);
  const review_date_hook = useInput(`review_data[${indexReview}].review_date`);

  const dropDownHandleSelect= (item, nameItem)=>{
      values.review_data[indexReview][nameItem] = item[nameItem];
    setValues({ ...values }); //item added
  }

  return (
    <div className={sectionStyle}>
      <div className="d-flex mt-2">
      <CloseButtonWrapper
          onClick={() => {
            deleteReview(nameSection, indexReview);
          }}
        >
          {TIMES}
        </CloseButtonWrapper>
        <div className="w-100 me-2">
                <DropdownSearchable
                  required
                  // disabled={!editMode}
                  placeHolder={'Review Status'}
                  options={choices?.medication_review_status || []}
                  {...useInput(`review_data[${indexReview}].review_status`, 'isRequired')}
                  selectedOption={
                    (values.review_data && (values.review_data.length > 0) && values.review_data[indexReview]) ? values.review_data[indexReview].review_status : null
                  }
                  onChange={value =>
                    dropDownHandleSelect(
                      {review_status: value ? value : null},
                      'review_status'
                    )
                  }
                />
              </div>
        <div className="w-100 pe-1">
        <DateSelector
                disabled={false}
                labelTop="Review Date"
                {...review_date_hook}
              />
        </div>
        <div className="w-100 pe-1">
        <TimeSelector
                disabled={false}
                labelTop={'Review Time'}
                {...newDoseTime}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              />
           </div>
      </div>
      <div className="d-flex mt-2">
            <div className="w-100 pe-1">
              <div
                className={'pt-2 ms-md-1 ms-0 text-center'}
                style={{ fontSize: '0.6rem', fontWeight: 'bold' }}>
                Review Comment
              </div>
              <InputTextArea
                type="text"
                height={'3.4rem'}
                id="emp_desc"
                placeHolder={'Review Comment'}
                label={'Review Comment'}
                {...ins_hook}
              //   maxlength={5}
              />
            </div>
          </div>
    </div >
  );
};

AddMedicationReview.propTypes = {
  useInput: PropTypes.func.isRequired,
  sectionStyle: PropTypes.string,
  nameSection: PropTypes.string.isRequired,
};

AddMedicationReview.defaultProps = {
  sectionStyle: '',
};

export default AddMedicationReview;
