import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { getBranchById, setCompanyBranch } from 'utils/api/SettingsApi';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  StylePointer,
} from 'shared/styles/constants/tagsStyles';
import {
  getTaskType,
  setTaskType,
  deleteTaskType,
  getCareCategory,
  setCareCategory,
  deleteCareCategory,
  setCareTask,
  deleteCareTask
} from 'utils/api/SettingsApi';
import _ from 'lodash';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';
import Geocode from 'react-geocode'
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { FaPlus } from 'react-icons/fa';
import { PURPLE } from 'shared/styles/constants/colors';
import { IconWrapper } from 'components/SharedComponents/Table/styles';
import { RiDeleteBinLine } from 'react-icons/ri';
const edit_branch = 'Edit branch';
const new_branch = 'Add branch';

const Branches = ({
  selectedData,
  selectedMethod,
  selectedType,
  setModalValue,
  fullBind,
  setTitle,
  setselectedData,
  setselectedMethod,
  setselectedType,
  loadRolPermission,
  handleChangeChoices
}) => {
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setCheckRequires,
  } = useForm({});

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const [optionsArray, setOptionsArray] = useState([]);

  const getModalTitleText = (type) => {
    let title = ''
    switch (type) {
      case 'care_category':
        title = 'Care Category';
        break;
      case 'task_type':
        title = 'Task Type';
        break;
      case 'care_task':
        title = 'Care Task';
        break;
      default:
        break;
    }
    return title
  }

  useEffect(() => {
    if (selectedMethod === 'edit') {
      setTitle(`Edit ${getModalTitleText(selectedType)}`)
      setValues({ ...values, name: selectedData.name, result_type: selectedData.result_type, result_option: selectedData.result_option, header_msg: selectedData.header_msg})
      setLoadingModal(false);
      //setOptionsArray(selectedData.result_option)
    } else {
      setTitle(`Add ${getModalTitleText(selectedType)}`)
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMethod]);
  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);


  const handleSubmit = () => {
    if (!isValid && errors.length > 0) {
      setErrorMessage('Name required');
      setCheckRequires(true);
      return;
    }
    setLoadingSave(true);
    if (selectedType === 'care_category') {
      let body = (selectedMethod === 'add') ? {
        name: values.name
      } : {
        name: values.name,
        id: selectedData.id
      }
      setCareCategory(body)
        .then(response => {
          values.id = response.id;
          onSuccess(response);
        })
        .catch(onError);
    } else if (selectedType === 'task_type') {
      let body = (selectedMethod === 'add') ? {
        name: values.name,
        care_category: selectedData.category ? selectedData.category : selectedData.id
      } : {
        name: values.name,
        id: selectedData.id
      }
      setTaskType(body)
        .then(response => {
          values.id = response.id;
          onSuccess(response);
        })
        .catch(onError);
    } else if (selectedType === 'care_task') {
      let body = (selectedMethod === 'add') ? {
        name: values.name,
        task_type: selectedData.task_type_id ? selectedData.task_type_id : selectedData.id,
        feedback: false,
        result_type: values.result_type,
        result_option: values.result_option ? values.result_option.join(",") : '',
        header_msg: values.header_msg

      } : {
        name: values.name,
        task_type: selectedData.task_type_id,
        feedback: false,
        id: selectedData.id,
        result_type: values.result_type,
        result_option: values.result_option ? values.result_option.join(",") : '',
        header_msg: values.header_msg
      }
      setCareTask(body)
        .then(response => {
          values.id = response.id;
          onSuccess(response);
        })
        .catch(onError);
    }
  };

  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    setModalValue();
    loadRolPermission()
    handleChangeChoices()
  };

  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
  };

  const dropDownHandleSelect = item => {
    //recursive objects merge
    let result;
    if (item.option) {
      item.option = item.option;
    }

    result = _.merge(values, item);

    setValues({ ...result });
  };

  const addNewService = () => {
    // const haveNew = optionsArray.find(data => data.id === '');
    
    // if (haveNew) {
    // return;
    // }
    setValues({...values, result_option: values.result_option ? [...values.result_option, ''] : ['']})
   // setOptionsArray([...optionsArray, '']);
    };

    const handleInputChange = (e, attribute, index) => {
       const newOption = values.result_option ? [...values.result_option] : [];

       //newOption[e.target.dataset.idx][attribute] = e.target.value;
       newOption[index] = e.target.value;
       values.result_option = newOption;
      // setOptionsArray(...values.result_option)
      setValues({...values})
  
    };

    const deleteRow = (selIndex) => {
      const deleteTemp = [];
      values.result_option.forEach((item, index) => {
          if (!(index === selIndex)) {
              deleteTemp.push(item)
          }
      })
      values.result_option = deleteTemp;
      setValues({...values});
  }

const header_message_hook = useInput('header_msg', ((values.result_type == 'text') || (values.result_type == 'option')) ?'isRequired' :"")
  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="ps-3 pe-3 pb-3 pt-0">
          <FlexGrid3Container className={'w-100 mb-2'}>
            <InputLabelTop
              required
              type="text"
              id="name"
              label={'Name'}
              {...useInput('name', 'isRequired')}
              maxlength={5000}
            />
          </FlexGrid3Container>
          <FlexGrid3Container>
            <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pb-1">
           { (selectedType === 'care_task') &&
           <DropdownSearchable
              placeHolder={'Result Type'}
              options={[
                { id: 'none', name: 'None' },
                { id: 'text', name: 'Text' },
                { id: 'option', name: 'Option' }
              ]}
              selectedOption={values.result_type}
              required={true}
              onChange={value =>
                dropDownHandleSelect({
                  result_type: value ? value : null,
                })
              }
            />}
            </FlexGrid3Item>
            {((values.result_type == 'text') || (values.result_type == 'option')) &&
             <FlexGrid3Item>
            <InputLabelTop
              required
              type="text"
              id="name"
              label={'Header Message'}
              {...header_message_hook}
              maxlength={5000}
            />
            </FlexGrid3Item>}
            <FlexGrid3Item>
            {(values.result_type === 'option') &&
              <StylePointer onClick={addNewService} className='pt-1 ps-1'>
              <FaPlus color={PURPLE} size={26} />
            </StylePointer>}
            </FlexGrid3Item>
            </FlexGrid3Container>
            <FlexGrid3Container>
            {values.result_option && values.result_option.map((item,index)=>{
              return (
                <FlexGrid3Item className="ps-0 ps-md-1 pe-1">
               <div className='d-flex'>
               <InputLabelTop
                type="text"
                id=""
                value={
                  item ? item : ''
                }
                label={`Result option ${index+1}`}
                onChange={e => {
                  handleInputChange(e, 'result_option', index);
                }}
                //maxlength={30}
              />
            <div className='pt-1 ps-1'>
            <IconWrapper
                onClick={() => {
                        deleteRow(index);
                }}>
                <RiDeleteBinLine />
            </IconWrapper>
            </div>
               </div>
              </FlexGrid3Item>
              )
            })
            }
             </FlexGrid3Container>
            
         
          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ms-0 ms-md-3 me-2'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default Branches;
