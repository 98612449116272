import React, {Fragment} from 'react';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import {Header, Title, TitleSection} from 'shared/styles/constants/tagsStyles';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {PLUS, TIMES} from 'utils/constants/icons';
import {CloseButtonWrapper} from 'shared/styles/constants/tagsStyles';
export const AddClockOutRes = ({
  useInput,
  values,
  filterShiftType,
  setValues,
  dropDownHandleSelectAvailablity,
  deleteAvailability,
  data,
  index,
}) => {
    console.log(values,"888")
  return (
    <div>
      {/* className={`mt-1 ${index==0?'ms-2':''}`}        */}
      <>
        <div key={index} className={`mt-1`}>
          <div className="d-flex">
            <Fragment />
            {/* {index!==0 &&  */}
            <CloseButtonWrapper
              onClick={() => {
                deleteAvailability(data, index);
              }}>
              {TIMES}
            </CloseButtonWrapper>
            {/* } */}
            <div className="p-0  w-100 d-flex align-items-center  ">
              <FlexGrid3Container className={'w-100 mb-1 mt-1 '}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <DropdownSearchable
                    placeHolder="shift type"
                    {...useInput(
                      `ShiftTypeClockRestriction[${index}].shift_type`
                    )}
                    options={filterShiftType? filterShiftType : []}
                    selectedOption={
                      values.ShiftTypeClockRestriction
                        ? values.ShiftTypeClockRestriction[index].shift_type
                        : null
                    }
                    onChange={value =>
                      dropDownHandleSelectAvailablity(
                        {shift_type: value ? value : null},
                        'shift_type',
                        'ShiftTypeClockRestriction',
                        index
                      )
                    }
                  />
                </FlexGrid3Item>
              </FlexGrid3Container>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};
