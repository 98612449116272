import React, { useState, Fragment } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { FlexGrid3Container, FlexGrid3Item, ErrorMessage, ModalContainer, } from 'shared/styles/constants/tagsStyles';
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import DateSelector from 'components/SharedComponents/DateSelector';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import moment from 'moment';
import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';
import { setEmployeeCareNotes } from 'utils/api/EmployeeApi';
import { TiArrowBackOutline } from 'react-icons/ti';

const AddCareNote = ({
    fullBind,
    idSelected,
    choices,
    rowData,
    displayPhoto,
    values,
    setValues,
    useInput,
    errors,
    setCheckRequires,
    getCareNotes,
    setModalValue,
    toast,
    updatePermisstion,
    selectedEmpId,
    readOnly
}) => {

    const [loadingSave, setLoadingSave] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [textStatus, setTextStatus] = useState(SAVE);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false)


    const dropDownHandleSelect = (item, nameItem) => {
        setValues({ ...values, ...item });
    };

    const handleSubmit = async () => {
        if (errors.length) {
            setCheckRequires(true)
            setShowError(true);
            setErrorMessage(InvalidOrEmptyValues)
            return;
        }
        setLoadingSave(true)
        const body = {
            care_notes_created_by: values.care_notes_created_by,
            notes_privacy: values.notes_privacy,
            care_notes: values.care_notes,
            notes_date: values.notes_date,
            employee: selectedEmpId
        }
        if (values.id) {
            body.id = values.id
        }
        try {
            const res = await setEmployeeCareNotes(body, selectedEmpId)
            if (res.id) {
                onSuccess(res)
            } else {
                onError(res.detail)
            }
        } catch (error) {
            onError(error)
        }
    }
    const onSuccess = response => {
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `Success`,
        });
        setLoadingSave(false);
        setButtonIcon(CHECK);
        setTextStatus(SAVED);
        setModalValue(false);
        getCareNotes()
    };

    const onError = err => {
        let getErrorMessage = err ? err : 'Something went wrong!';
        toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `${getErrorMessage}`,
        });

        setLoadingSave(false);
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        setCheckRequires(true);
        setShowError(true);
        setErrorMessage(getErrorMessage);
    };

    const headerInfo = () => {
        return (
            <RowInfoModel
                name={rowData.first_name || rowData.full_name}
                lastName={rowData.last_name}
                photoRow={
                    rowData.photo
                        ? URL.createObjectURL(rowData.photo)
                        : displayPhoto
                            ? displayPhoto
                            : ''
                }
                rows={[
                    { label: 'Name:', value: rowData.full_name },
                    {
                        label: 'DOB:',
                        value: rowData.date_of_birth
                            ? `${moment(rowData.date_of_birth).format(
                                'DD-MM-YYYY'
                            )} (${moment().diff(rowData.date_of_birth, 'years')})`
                            : '',
                    },
                ]}
            // dateOfBirth={dateOfBirth}
            />
        )
    }
    return (
        <>
            <ModalContainer max_width={'60%'}>
                <ModalNoInfo
                    {...fullBind}
                    max_width={'60%'}
                    minWidthTitle="8.5rem"
                    loading={false}
                    rowInfoData={headerInfo}
                >
                    <div className="ps-3 pe-3 pb-3 pt-0">
                        <div className={'w-100 mb-2'}>
                            <div className=" d-flex w-100">
                                <div style={{ width: '100%' }} className="me-3 mt-2">
                                    <DateSelector
                                        disabled={readOnly}
                                        isRequired
                                        labelTop="Date"
                                        {...useInput(`notes_date`, 'isRequired')}
                                    />
                                </div>
                                <div style={{ width: '100%' }} className="me-3 mt-2">
                                    <DropdownSearchable
                                        // required
                                        {...useInput(`notes_privacy`)}
                                        disabled={readOnly}
                                        placeHolder={'Privacy Type'}
                                        options={choices.alert_privacy || []}
                                        selectedOption={values.notes_privacy}
                                        onChange={value =>
                                            dropDownHandleSelect({ notes_privacy: value ? value : null })
                                        }
                                    />
                                </div>
                                {values.id && (<div style={{ width: '100%' }} className={'mt-2'}>

                                    <FlexGrid3Item className="pe-1">
                                        <DropdownSearchable
                                            disabled={true}
                                            placeHolder={'Employee'}
                                            options={choices?.user_list || []}
                                            selectedOption={values.created_by || null}
                                        />
                                    </FlexGrid3Item>
                                </div>)}
                            </div>
                            {/* <FlexGrid3Item className="pe-1 mt-1 mb-2" style={{width:"100%"}} >
                               
                            </FlexGrid3Item>
                           
                            <FlexGrid3Item className="pe-1 mt-1 mb-2" style={{width:"100%"}}>
                                
                            </FlexGrid3Item> */}
                        </div>
                        <FlexGrid3Container>
                            <FlexGrid3Item className="pe-1 mb-2" ResizeWidth={'100%'}>
                                <small className="pe-1 " style={{ color: 'black' }}>
                                    Care Notes
                                </small>
                                <InputTextArea
                                    required
                                    disabled={readOnly}
                                    type="text"
                                    height={'4rem'}
                                    id="care_notes"
                                    placeholder={'Care Notes'}
                                    {...useInput('care_notes', 'isRequired')}
                                    maxlength={5}
                                />
                            </FlexGrid3Item>
                        </FlexGrid3Container>
                        <div
                            className={'d-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'}>
                            <div className={'mb-3'}>
                                {showError ? (
                                    <ErrorMessage>
                                        <label className={'mt-2'}>{errorMessage}</label>
                                    </ErrorMessage>
                                ) : (
                                    <Fragment />
                                )}
                            </div>

                            <div className={'d-flex justify-content-center justify-content-md-end'}>
                                <div className={'ms-0 ms-md-3 me-2'}>
                                    <PrimaryButtonForm
                                        disabled={loadingSave || readOnly}
                                        minWidth="6rem"
                                        onClick={handleSubmit}>
                                        <span className={'me-2'}>{textStatus}</span>
                                        {!loadingSave ? (
                                            buttonIcon
                                        ) : (
                                            <span className="spinner-border spinner-border-sm me-1" role="status" />
                                        )}
                                    </PrimaryButtonForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalNoInfo>
            </ModalContainer>
        </>
    );
};

export default AddCareNote;


