import React, { useState, useEffect, Fragment } from 'react';
import {
  ModalContainer,
  FlexGrid3Item,
  FlexGrid3Container,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import {
  ERROR,
  InvalidOrEmptyValues,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { AssignAllVisits, setShadowVisits } from "utils/api/SchedulerApi"
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { bulkActionPostBillingDetails } from 'utils/api/finance';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';

const AssignBulk = ({
  fullBind,
  checkedRows,
  bulkActionKeys,
  handleClick,
  setActionPopup,
  billRate,
  payRate,
  travelRate,
  setCheckedRows,
  clearSelectedElId = () => {}
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const { values, setValues, useInput, errors, setCheckRequires } = useForm({});
  const [errorMessage, setErrorMessage] = useState('')
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);
  const [visitFailData, setvisitFailData] = useState({});
  const [disableButton, setDisableButton] = useState([]);

  const amt_hook = useInput(`${bulkActionKeys.apiKey}`)
  const rate_card_hook = useInput(`rate_card`)
  const recalculate_type_hook = useInput(`recalculate_type`, bulkActionKeys?.recalculate ? 'isRequired' : "" )


  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: bulkActionKeys.title
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);


  const dropDownHandler = (item, nameItem) => {
    values[nameItem] = item[nameItem];
    setValues({ ...values });
  }

  const handleSubmit = () => {
    if (errors.length > 0 || loadingSave) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    setLoadingSave(true)
    setErrorMessage('');
    let body = {
      visit_list: checkedRows,
    }

    if (bulkActionKeys.rate_card) {
      body.rate_card = values.rate_card
    } else if (bulkActionKeys?.recalculate) {
      body.is_actual = values.recalculate_type === 'both' || values.recalculate_type == 'is_actual' ? true : false
      body.is_schedule = values.recalculate_type === 'both' || values.recalculate_type == 'is_schedule' ? true : false
    }else {
      body[bulkActionKeys.apiKey] = values[bulkActionKeys.apiKey]
    }

    bulkActionPostBillingDetails(body, bulkActionKeys.apiKey)
      .then(res => {
        if (res.statusCode == 400) {
          throw res.message
        }
        setSaveButtonIcon(CHECK);
        setTextStatus(SAVED);
        setLoadingSave(false);
        setErrorMessage('')
        handleClick()
        setActionPopup(false)
        setCheckedRows([])
        clearSelectedElId()
      })
      .catch(err => {
        setSaveButtonIcon(CROSS);
        setTextStatus(ERROR);
        setLoadingSave(false);
        setErrorMessage(err)
      })



  }


  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="6.5rem"
        loading={false}
      >
        <div className={' ps-3 pe-3 pb-2'}>

          <FlexGrid3Container className={'w-100 mt-2'}>
            {bulkActionKeys.rate_card ?
             <div className='w-100'>
                <DropdownSearchable
                disabled={false}
                placeHolder={bulkActionKeys.title}
                // width="145%"
                options={(bulkActionKeys.rate_card === 'bill') ? billRate : ((bulkActionKeys.rate_card === 'travel') ? travelRate : payRate)}
                {...rate_card_hook}
                selectedOption={
                  values ? values.rate_card : null
                }
                onChange={value =>
                  dropDownHandler(
                    { rate_card: value ? value : null },
                    'rate_card'
                  )
                }
              />
             </div>
              : bulkActionKeys?.recalculate ? 
                <div className='w-100'>
                  <DropdownSearchable
                    required
                    placeHolder={bulkActionKeys.title}
                    // width="145%"
                    options={[
                      {id:"is_actual", name:"Only Actual"},
                      {id:"is_schedule", name:"Only Schedule"},
                      {id:"both", name:"Both"}
                    ]}
                    {...recalculate_type_hook}
                    selectedOption={
                      values ? values.recalculate_type : null
                    }
                    onChange={value =>
                      dropDownHandler(
                        { recalculate_type: value ? value : null },
                        'recalculate_type'
                      )
                    }
                  />
                </div>
              : <InputLabelTop
                readOnly={false}
                disabled={false}
                label={bulkActionKeys.title}
                type="text"
                {...amt_hook}
                maxlength={5}
              />}
          </FlexGrid3Container>
          <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            {!(visitFailData && visitFailData.length > 0) && <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>

              <div className={'ms-0 ms-md-3 mb-1'}>
                <PrimaryButtonForm minWidth="6rem"
                  onClick={handleSubmit}

                >
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    saveButtonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>}
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
}

export default AssignBulk;
