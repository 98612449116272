export const apiURL =
  process.env.REACT_APP_API_URL || `https://stage.myhelpa.com/org`;

export const apiLoginURL =
  process.env.REACT_APP_API_URL || 'https://stage.myhelpa.com/api/v1';
export const mapboxToken =
  'pk.eyJ1IjoibWF0dC1oYWRkb24tbXloZWxwYSIsImEiOiJja3E2a2FpbmkwMWp0Mm9vNG51ZnkzZzFhIn0.JDN28Tvs-qSYqvAP-jrnxw';

export const  apiHijackURL =
  process.env.REACT_APP_API_URL || 'https://stage.myhelpa.com';

export const apiURLReplica =
process.env.REACT_APP_API_URL || `https://stage.myhelpa.com/org-replica`;

export const apiURLReport =
process.env.REACT_APP_API_URL || `https://stage.myhelpa.com/org-report`;

export const apiLoginURLV2 =
process.env.REACT_stage_API_URL || 'https://stage.myhelpa.com/api/v2';

