import React from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import DateSelector from 'components/SharedComponents/DateSelector';
import {KeyPress} from 'utils/constants/regularExpressions';
import {TitleSection} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
// import { values } from 'lodash';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';

const Service = ({
  nameSection, 
  useInput, 
  sectionStyle,
  setValues,
  values,
  choices,
  editMode,
  dropDownHandleSelectBranch=()=>{}, 
  templateContract = false,
  visitCount = 0,
  editTypeSelected = 'create'
}) => {

  const template_name_hook = useInput(`template_name`,templateContract ? 'isRequired' : '')
  const branch_temp_hook = useInput(
    `branch`,
    templateContract ? 'isRequired' : ''
  )

  const cat_type_temp_hook = useInput(
    `service_category_type`,
    templateContract ? 'isRequired' : ''
  )

  return (
    <div className={sectionStyle}>
      {templateContract &&  
      <div className="d-flex">
          <div className="w-100 pe-1">
            <InputLabelTop
            required
            disabled={!editMode}
              type="text"
              id="template_service_contract_name"
              label={'Template name'}
              {...template_name_hook}
              maxlength={255}
            />
          </div>
          <div className="w-100 ps-1">
          <DropdownSearchable
          required
            disabled={false}
            placeHolder={'Branch'}
               {...branch_temp_hook}
            options={choices.branch ? choices.branch : ''}
            selectedOption={
              values.branch
            }
            onChange={(value) =>
              dropDownHandleSelectBranch(
                { branch: value ? value : null },
                'branch'
              )
            }
          />
          </div>
          <div className="w-100 ps-1">
            <DropdownSearchable
              required
              disabled={(editTypeSelected == 'create') ? false : (visitCount > 0)}
              placeHolder={'Category Type'}
              {...cat_type_temp_hook}
              options={[
                { id: 'location', name: 'Location' },
                { id: 'client', name: 'Client' }
              ]}
              selectedOption={
                values.service_category_type
              }
              onChange={(value) =>
                dropDownHandleSelectBranch(
                  { service_category_type: value ? value : null },
                  'service_category_type'
                )
              }
            />
          </div>
        </div>}
      <TitleSection>Service</TitleSection>
      <div className={'mt-2'}>
        <div className="d-flex">
          <div className="w-100 pe-1">
            <InputLabelTop
            disabled={!editMode}
              type="text"
              id="service_contract_name"
              label={'Service contract name'}
              {...useInput(`contract_name`)}
              maxlength={255}
            />
          </div>
          <div className="w-100 ps-1">
            <DateSelector
             disabled={!editMode}
              labelTop="Date of Service Agreement"
              {...useInput(`agreement_date`)}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="w-100 pe-1">
            <InputLabelTop
             disabled={!editMode}
              type="text"
              id="service_contract_no"
              label={'Service contract reference no.'}
              {...useInput(`reference_no`)}
              maxlength={25}
            />
          </div>
          <div className="w-100 ps-1">
            <InputLabelTop
             disabled={!editMode}
              // required
              type="text"
              id="service_no_clients"
              label={'No. of clients'}
              onKeyPress={KeyPress.INTEGER}
              {...useInput(`no_clients`, 'isInt ')}
              maxlength={5}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Service.propTypes = {
  useInput: PropTypes.func.isRequired,
  sectionStyle: PropTypes.string,
  nameSection: PropTypes.string.isRequired,
};

Service.defaultProps = {
  sectionStyle: '',
};

export default Service;
