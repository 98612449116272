import React, {useState, useEffect,useContext, Fragment,useRef} from 'react';
import moment from 'moment';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {KeyPress} from 'utils/constants/regularExpressions';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
import {useForm} from 'hooks/FormHook';
import {deleteCompanyBranchAssigned, getBranchAssignedToById, getBranchById, getFormBuilderAssignedToById, setCompanyBranch, setCompanyBranchAssign, setFormBuilderAssign} from 'utils/api/SettingsApi';
import {AppContext, BranchItemsContext} from 'context';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import {ERROR, SAVE, SAVED} from 'utils/constants/messages';
import {Toast} from 'primereact/toast';

import Geocode from 'react-geocode' 
import Table from 'components/SharedComponents/Table';
import { EX_LARGE_COL, LARGE_COL } from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper
} from 'components/SharedComponents/Table/styles';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useModal } from 'hooks/ModalHook';
import DateSelector from 'components/SharedComponents/DateSelector';
import { attachClientEmp_choices } from 'utils/choiceConstant';
const AttachClientEmp = ({
  fullBind,
  idSelected,
  setTitle,
  setModalValue,
  attachTypeEmp
}) => {
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setCheckRequires,
  } = useForm({});

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const {contextChoices,handleGetChoices} = useContext(AppContext);
  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
    useEffect(() => {
      handleGetChoices(attachClientEmp_choices)
      
    }, [])
  const toast = useRef();
  useEffect(() => {
    if (idSelected) {
      loadBranchById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };
  const headerColumnsEmp = [
      {
        label: 'Employee Name',
        name: 'employee',
        status: 0,
        className: 'grow-mobile  sm-hidden',
        max_width: EX_LARGE_COL,
      }
    ];

    const headerColumnsClient = [
      {
        label: 'Client Name',
        name: 'client',
        status: 0,
        className: 'grow-mobile  sm-hidden',
        max_width: EX_LARGE_COL,
      }
    ];

  useEffect(() => {
      setTitle('Assign employees and clients');
      setLoadingModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const loadBranchById = async () => {
    setValues({})
    const response = await getFormBuilderAssignedToById(idSelected);
    if(response.data){
      values.employee_assigned_list = response.data.employee&&response?.data?.employee.map((val)=>{
        return {id:val.employee_id, name:val.employee_name}
      })

      values.client_assigned_list = response.data.client&&response?.data?.client.map((val)=>{
        return {id:val.client_id, name:val.client_name}
      })
    }
    setLoadingModal(false);
    setValues({...values});
  };

  const deleteClick=(id, item)=>{
    const body = (item === 'employee') ? 
    {
      employee_id: id,
      branch_id: idSelected
    } 
   : 
    {
        client_id: id,
        branch_id: idSelected
    }
    deleteCompanyBranchAssigned(body)
    .then((res)=>{
      if(res.status){
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Unassigned Successfully',
        });
        loadBranchById()
      }
    })
  }

  const handleSubmit = async() => {
    setLoadingSave(true);
    const body = 
       {
        form: idSelected,
        start_date: values.start_day,
        end_date: values.end_day,
        client_list: values.client_assigned ? values.client_assigned : [],
        employee_list: values.employee_assigned ? values.employee_assigned : []
    }
  let response = await  setFormBuilderAssign( body)
  if (response.status === true) {
    setLoadingSave(false);

    setButtonIcon(CHECK);
    setTextStatus(SAVED);
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Saved Successfully',
    });
  } else {
    setLoadingSave(false);
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    toast.current.show({
      severity: 'error',
      summary: 'Failed',
      detail: `${response.message}`,
    });
  }
  setTimeout(()=>{
    setModalValue()
  },1000)
  };
const dropDownHandleSelect=(item)=>{
if(item.employee_assigned){
  const emp = item.employee_assigned.map((val)=>{
    return val.value
  })
  setValues({...values,employee_assigned:emp})
}

if(item.client_assigned){
  const client = item.client_assigned.map((val)=>{
    return val.value
  })
  setValues({...values,client_assigned: client})
}

}
  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="ps-3 pe-3 pb-3 pt-0">
          <FlexGrid3Container className={'w-100 mb-2'}>
          <Toast ref={toast} position="top-right" />

            {/* {attachTypeEmp && */}
            <FlexGrid3Item className="pe-1">
            <DropdownSearchable
            isMulti={true}
                  placeHolder={'Employee'}
                  disabled={false}
                  options={contextChoices.employee_listing}
                  selectedOptionsProps={values.employee_assigned ? values.employee_assigned : null}
                  onChange={value =>
                    dropDownHandleSelect({
                      employee_assigned: value ? value : null,
                    })
                  }
                />
            </FlexGrid3Item>
            {/* {!attachTypeEmp &&  */}
            <FlexGrid3Item className="pe-1">
              <DropdownSearchable
               isMulti={true}
              placeHolder={'Client'}
              disabled={false}
              options={contextChoices.client_listing}
              selectedOptionsProps={values.client_assigned ? values.client_assigned : null}
              onChange={value =>
                dropDownHandleSelect({
                  client_assigned: value ? value : null,
                })
              }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-0 pe-1">
              <DateSelector
                isRequired
                disabled={false}
                //errorMessage={dateMessage}
                labelTop="Start day"
                //data-error={dateError}
                {...useInput('start_day', 'isRequired')}
              />
            </FlexGrid3Item>

            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pt-2">
              <DateSelector
                isRequired
                disabled={false}
                //errorMessage={dateMessage}
                labelTop="End day"
                //data-error={dateError}
                {...useInput('end_day', 'isRequired')}
              />
            </FlexGrid3Item>
           
          </FlexGrid3Container>
          <div
              className={
                'd-flex justify-content-end'
              }>
              <div className={'ms-0 ms-md-3 me-2'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
        </div>
        <div className={'ps-3 pe-3 pb-3'}>
       
        
        
       <div className='d-flex'>
         <div style={{width: '50%'}}>
       {values.employee_assigned_list && values.employee_assigned_list.length > 0 &&
        <>
          <Table
        headerColumns={headerColumnsEmp}
        headerPadding={false}
        noEditOption
        fontSize="0.75rem"
        callBackQuerySort={setQuerySort}
        >

        <div>
          {values.employee_assigned_list &&  values.employee_assigned_list.map((list, index) => {
            return (
              <div key={index}>
                <Row bgColor Shadow>
                <ColsGrouper
                   className="d-flex justify-content-between">

                     
                  <Col
                    max_width={EX_LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    className="sm-hidden"
                     overlap={true}
                    Center>
                    {
              list && list.name      
                    }
                  </Col>
                  <Col className="sm-hidden" Center Shrink NoFlexGrow>
                      {<IconWrapper
                        onClick={() => {
                          deleteClick(list.id, 'employee');
                        }}
                        >
                        <RiDeleteBinLine />
                      </IconWrapper>}
                    </Col>
                  </ColsGrouper>
                </Row>
                <div className="mb-1" />
              </div>
            );
          })}
         {/* <div className="mt-3">
          <Pagination   totalPages={pages}  currentPage={currentPage}   values={values}   dropDownHandleSelect={dropDownHandleSelectPagination}     number={PAGE_SIZE_RENDER}    dataLength={absenceList.length}     />
          </div> */}
        </div> 
  
      </Table>
        </>
      }
      </div>
      <div  style={{width: '50%', marginLeft:'20px'}}>
       {values.client_assigned_list && values.client_assigned_list.length > 0 &&
        <>
          <Table
        headerColumns={headerColumnsClient}
        headerPadding={false}
        noEditOption
        fontSize="0.75rem"
        callBackQuerySort={setQuerySort}
        >

        <div>
          {values.client_assigned_list &&  values.client_assigned_list.map((list, index) => {
            return (
              <div key={index}>
                <Row bgColor Shadow>
                <ColsGrouper
                    className="d-flex justify-content-between">
                  <Col
                    max_width={EX_LARGE_COL}
                    Shrink
                    reducePadding
                    Capitalize
                    className="sm-hidden"
                     overlap={true}
                    Center>
                    {
              list && list.name              
                    }
                  </Col>
                  <Col className="sm-hidden" Center Shrink NoFlexGrow>
                      {<IconWrapper
                        onClick={() => {
                          deleteClick(list.id, 'client');
                        }}
                        >
                        <RiDeleteBinLine />
                      </IconWrapper>}
                    </Col>
                  </ColsGrouper>
                </Row>
                <div className="mb-1" />
              </div>
            );
          })}
        </div> 
  
      </Table>
        </>
      }
      </div>
       </div>
      </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default AttachClientEmp;
