import React from 'react';
import './carePlans.css';
import { TitleSection } from 'shared/styles/constants/tagsStyles';
import { getCarePlans } from 'utils/api/ClientApi';
import { useEffect } from 'react';
import { useState } from 'react';
import moment from 'moment';
import Spinner from 'components/SharedComponents/Spinner';
import CareTable from './CareTable';
import Card from './Card';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { AccordionArrow, AccordionContainer } from 'components/DashboardComponents/LeftSidebar/styles';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { PURPLE } from 'shared/styles/constants/colors';
import Medication from './Medication';
import NoLinkedMedication from './NoLinkedMedication';

function CarePlans({ clientId, clientFullName }) {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const [clientCarePalns, setClientCarePlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hideExportBtn, setHideExportBtn] = useState(false)
  const [isShowMore, setIsShowMore] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(false)
  const [formatedCarePlans, setFormatedCarePlans] = useState([])
  const [noLinkedMedications, setNoLinkedMedications] = useState([])
  const [isOpenNoLinkedMed, setIsOpenNoLinkedMed] = useState(true)

  const [isOpenCareTask, setIsOpenCareTask] = useState("open")
  const [isOpenMedication, setIsOpenMedication] = useState("open")

  const getClientCarePlansData = async () => {
    setIsLoading(true);
    const response = await getCarePlans(clientId);
    if (response?.status) {
      formatData(response)
      setClientCarePlans(response?.data);
      setNoLinkedMedications(response?.unlinkedMedication)

    }
    setIsLoading(false);
  };


  useEffect(() => {
    getClientCarePlansData();
  }, []);

  const formatData = (response) => {
    let formatedRes = response?.data?.map((item, index) => {
      const { medicationData, plannedTask } = item;
      if (Object.keys(medicationData).length || Object.keys(plannedTask).length) {
        let data = {
          serviceCategory: item.serviceCategory,
          visitType: item.visitType,
          preferredStartEnd: item.preferredStartEnd,
          startDate: item.startDate,
          endDate: item.endDate
        }

        if (medicationData && Object.keys(medicationData).length) {
          data["medicationData"] = Object.entries(medicationData).map(([medicationType, medications]) => {
            return {
              medicationType: medicationType,
              medications: medications.map((medication, index) => medication)
            }
          }
          )
        } else {
          data["medicationData"] = []
        }

        if (plannedTask && Object.keys(plannedTask).length) {
          data["plannedTaskData"] = Object.entries(plannedTask).map(([plannedTaskType, plannedTasks]) => {
            return {
              plannedTaskType: plannedTaskType,
              plannedTasks: plannedTasks.map((medication, index) => medication)
            }
          }
          )
        } else {
          data["plannedTaskData"] = []
        }

        return data;
      }
    })
    setFormatedCarePlans(formatedRes)
  }

  const splitDate = date => {
    let a = date.slice(0, 5);
    let b = date.slice(9, 14);
    return a + ' - ' + b;
  };

  const isChecked = (days, day) => {
    if (typeof days == 'string') {
      const isTrue = days?.toLowerCase().split(',').includes(day?.toLowerCase());
      if (!days || isTrue) {
        return true;
      } else {
        return false;
      }
    } else {
      const isTrue = days.find(el => el.toLowerCase() == day?.toLowerCase());
      if (!days || isTrue) {
        return true;
      } else {
        return false;
      }
    }
  };
  const formatDateDDMMMYY = date => {
    return moment(date).format('Do MMM YY');
  };

  const handleDownloadPdf = () => {
    setHideExportBtn(true)
    setIsShowMore(true)
    setIsDisabledButton(true)
    setIsOpenCareTask('open')
    setIsOpenMedication('open')
    setTimeout(() => { setIsDisabledButton(false) }, 1000)
    const input = document.querySelector('#care-plans');
    // const input = document.querySelector('#care-plans-pdf');
    setTimeout(() => {
      html2canvas(input).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt', 'letter');
        let position = 0;
        let pageHeight = 798;
        let imgProps = pdf.getImageProperties(imgData);
        let pdfWidth = pdf.internal.pageSize.getWidth();
        let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        var heightLeft = pdfHeight;
        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - pdfHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
          heightLeft -= pageHeight;
        }
        const current_date = moment().format('DD-MM-YYY');
        pdf.save(`${clientFullName}-CarePlan`);
      });
      setHideExportBtn(false)
      setIsShowMore(false)
    }, 50)
  };

  const handleCareTaskAccordian = (id) => {
    if (isOpenCareTask) {
      setIsOpenCareTask("")
    } else {
      setIsOpenCareTask(id)
    }
  }
  const handleMedicationAccordian = (id) => {
    if (isOpenMedication) {
      setIsOpenMedication("")
    } else {
      setIsOpenMedication(id)
    }
  }
  return (
    <React.Fragment>
      {isLoading ? (
        <Spinner />
      ) : (
        <div
          id="care-plans"
          style={{ padding: "1rem 2rem" }}
          className="mt-3 bg-white page-break-before-element">
          {clientCarePalns?.length ? (
            <React.Fragment>
              {hideExportBtn &&
                <div className='w-100 d-flex justify-content-center'>
                  <TitleSection>{clientFullName} - Care plan</TitleSection>
                </div>}
              <div className="w-100 d-flex justify-content-between custom-mt-2" >
                <TitleSection>Care Visits</TitleSection>
                {!hideExportBtn && <PrimaryButtonForm
                  style={{ marginRight: '0.50rem' }}
                  minWidth="6rem"
                  disabled={isDisabledButton}
                  onClick={handleDownloadPdf}>
                  <span className={'me-2'}>Export to pdf</span>
                </PrimaryButtonForm>}
              </div>

              <div id="care-plans-pdf">
                <div className="d-flex custom-mt-2 w-100 scrollable-table">
                  <table className="bg-white">
                    <thead style={{ border: '1px solid #857f7f' }}>
                      <tr className="bg-gray">
                        <th style={{ textAlign: 'start' }}>Visit Name</th>
                        {days?.map(el => {
                          return <th key={Math.random()}>{el}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {clientCarePalns?.map((el, i) => {
                        return (
                          <CareTable
                            key={Math.random()}
                            index={i}
                            el={el}
                            startDate={formatDateDDMMMYY(el.startDate)}
                            endDate={formatDateDDMMMYY(el.endDate)}
                            splitDate={splitDate}
                            isChecked={isChecked}
                            days={days}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {
                  formatedCarePlans.map((item, m_index) => {
                    if (!item) {
                      return null
                    } else {
                      return <div className="w-100 mt-1 ms-1 me-1">
                        <div style={{ marginTop: "2rem" }} className="m-s-1">
                          <span className="title-text">
                            {item.serviceCategory}
                          </span>
                          <p className="sub-text mt-1 mb-1">
                            {item.visitType ? item.visitType : 'Care visit'},{' '}
                            {splitDate(item.preferredStartEnd)}
                          </p>
                          <p className="sub-text">{`${formatDateDDMMMYY(
                            item.startDate
                          )} - ${item.endDate
                            ? formatDateDDMMMYY(item.endDate)
                            : 'Ongoing'
                            }`}</p>
                        </div>
                        {
                          item?.plannedTaskData?.map((el, i) => {
                            const idd = "care-task" + m_index
                            return (
                              <React.Fragment key={Math.random()}>
                                {i === 0 && <div className="care-task-title" style={{ marginTop: "1rem" }}>
                                  <AccordionContainer>
                                    <AccordionArrow style={{ marginLeft: "-30px" }} onClick={() => { handleCareTaskAccordian(idd) }}>
                                      <div>
                                        {(isOpenCareTask === idd || isOpenCareTask === 'open') ? (
                                          <IoIosArrowUp size={18} color={PURPLE} />
                                        ) : (
                                          <IoIosArrowDown size={18} color={PURPLE} />
                                        )}
                                      </div>
                                    </AccordionArrow>
                                    <TitleSection>Care Tasks</TitleSection>
                                  </AccordionContainer>
                                </div>}
                                {(isOpenCareTask === idd || isOpenCareTask === 'open') &&
                                  <React.Fragment>
                                    <div className="care-task-title">
                                      <TitleSection>{el.plannedTaskType}</TitleSection>
                                    </div>
                                    {el.plannedTasks?.map((elm, index) => {
                                      return (
                                        <Card
                                          index={index}
                                          key={Math.random()}
                                          isShowMore={isShowMore}
                                          setIsShowMore={setIsShowMore}
                                          hideExportBtn={hideExportBtn}
                                          days={days}
                                          elm={elm}
                                          el={item}
                                          isChecked={isChecked}
                                          startDate={formatDateDDMMMYY(item.startDate)}
                                          endDate={formatDateDDMMMYY(item.endDate)}
                                        />
                                      );
                                    })}
                                  </React.Fragment>}
                              </React.Fragment>
                            )
                          })

                        }
                        {
                          item.medicationData.map((el, index) => {
                            const idd = "medication" + m_index
                            return (<React.Fragment>
                              {index === 0 && (
                                // <div className='mt-4 mb-3'>
                                //   <TitleSection>Medication</TitleSection>
                                // </div>
                                <div className="care-task-title" style={{ marginTop: "1rem" }}>
                                  <AccordionContainer>
                                    <AccordionArrow style={{ marginLeft: "-30px" }} onClick={() => handleMedicationAccordian(idd)}>
                                      <div>
                                        {(isOpenMedication === idd || isOpenMedication === 'open') ? (
                                          <IoIosArrowUp size={18} color={PURPLE} />
                                        ) : (
                                          <IoIosArrowDown size={18} color={PURPLE} />
                                        )}
                                      </div>
                                    </AccordionArrow>
                                    <TitleSection>Medications</TitleSection>
                                  </AccordionContainer>
                                </div>
                              )}
                              {(isOpenMedication === idd || isOpenMedication === 'open') &&
                                <React.Fragment>
                                  <div className="care-task-title">
                                    <TitleSection>{el.medicationType}</TitleSection>
                                  </div>
                                  {el.medications.map((elm, i) => (
                                    <Medication
                                      key={Math.random()}
                                      elm={elm}
                                      days={days}
                                      splitDate={splitDate}
                                      isChecked={isChecked}
                                    />
                                  ))}
                                </React.Fragment>
                              }
                            </React.Fragment>)
                          })
                        }

                      </div>
                    }
                  })
                }
                {noLinkedMedications.length ? <>
                  <div className="care-task-title" style={{ marginTop: "1rem" }}>
                    <AccordionContainer>
                      <AccordionArrow style={{ marginLeft: "-30px" }} onClick={() => setIsOpenNoLinkedMed(prev => !prev)}>
                        <div>
                          {!isOpenNoLinkedMed ? (
                            <IoIosArrowUp size={18} color={PURPLE} />
                          ) : (
                            <IoIosArrowDown size={18} color={PURPLE} />
                          )}
                        </div>
                      </AccordionArrow>
                      <TitleSection>Medication not linked with visit</TitleSection>
                    </AccordionContainer>
                  </div>
                  {isOpenNoLinkedMed &&
                    <React.Fragment>

                      {noLinkedMedications.map((elm, i) => (
                        <NoLinkedMedication
                          key={Math.random()}
                          elm={elm}
                          days={days}
                          splitDate={splitDate}
                          isChecked={isChecked}
                        />
                      ))}
                    </React.Fragment>
                  }
                </> : null}
              </div>
            </React.Fragment>
          ) : (
            <div className="w-100 d-flex align-items-center justify-content-center m-3">
              <h5 className="text-center">No Data Found</h5>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default CarePlans;