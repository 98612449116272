export const KeyPress = {
  PHONE(e) {
    const re = /^-*[0-9 +()-]+$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  },
  INTEGER(e) {
    const re = /^-*[0-9]+$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  },
  NON_ZERO_INTEGER(e) {
    const input = e.target.value + e.key;
    const pattern = /^\s*[1-9]\d{0,5}\s*$/;
    if (!pattern.test(input)) {
      e.preventDefault();
    }
  },
  DECIMAL(e) {
    const re = /^-*[0-9.-]+$/;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  },
  POSITIVE(e) {
    const re = /^\d*$/
    if (!re.test(e.key)) {
      e.preventDefault()
    }
  },
  NATURAL(e) {
    const re = /^[1-9]\d*$/;
    if (!re.test(e.key)) {
      e.preventDefault()
    }
  },
  TWO_DECIMALS(e) {
    const newInputValue = e.target.value + e.key;
    const re = /^\d+(\.\d{0,2})?$/;
    if (!re.test(newInputValue)) {
      e.preventDefault()
    }
  },
  DURATION(e){
    const newInputValue = e.target.value + e.key;
    const re = /^(\d{1,5})?(:)?([0-5]?[0-9])?$/; // Matches "hh:mm", where hh >= 2 digits and mm is 0-59
    // const match = value.match(re);
    if (!re.test(newInputValue)) {
      e.preventDefault()
    }
  }
};

export const onChangeValidations = {
  longitude: (e) => {
    if(e.target.value==="-") return {valid:true, value:e.target.value}
    // check if the value is a number
    if (isNaN(e.target.value))
      return {valid:false, value:""};
    // check if it has more than 7 decimal places
    if (e.target.value.includes('.') && e.target.value.split('.')[1].length > 7)
      return {valid:false, value:""};
    // check if it is in valid range
    if (parseFloat(e.target.value) < -180 || parseFloat(e.target.value) > 180)
      return {valid:false, value:""};
    return {valid:true, value:e.target.value}
  },
  latitude: (e) => {
    if(e.target.value==="-") return {valid:true, value:e.target.value}
    // check if the value is a number
    if (isNaN(e.target.value))
      return {valid:false, value:""};
    // check if it has more than 7 decimal places
    if (e.target.value.includes('.') && e.target.value.split('.')[1].length > 7)
      return {valid:false, value:""};
    // check if it is in valid range
    if (parseFloat(e.target.value) < -90 || parseFloat(e.target.value) > 90)
      return {valid:false, value:""};
    return {valid:true, value:e.target.value}
  }
}