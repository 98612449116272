import React, { useState, useEffect, Fragment } from 'react';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import DurationInput from 'components/SharedComponents/DurationInput';
import DateSelector from 'components/SharedComponents/DateSelector';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFillPlusSquareFill } from "react-icons/bs"
import { PLUS } from 'utils/constants/icons';
import moment from 'moment';
import RateCardAdhoc from "components/SharedComponents/FormSections/Service/RateCardAdhoc"
import { InvalidRange } from 'utils/constants/messages';
import { getMedicationDoseTimingOptionList, getPayRates, getSchedulerPlanned } from 'utils/api/ClientApi'
import {
  ModalContainer,
  FlexGrid3Container,
  FlexGrid3Item,
  MessageSpan,
  ErrorMessage,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import ServiceAdhocEmployee from '../../../SharedComponents/FormSections/Service/ServiceAdhocEmployee';
import { getFundersList } from 'utils/api/ClientApi'
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import AddPlannedTask from 'components/SharedComponents/FormSections/PlannedtaskForms/AddPlannedTask';
import AddMedication from 'components/SharedComponents/FormSections/Service/AddMedication';
import { getMedicationPrescription } from 'utils/api/SettingsApi';

const AdhocVisit = ({
  fullBind,
  dropDownHandleAdhocVisit,
  dropdownHandleAdhocEmployee,
  dropDownHandleClient,
  values,
  useInput,
  nameSection,
  setValues,
  errorMessage,
  choices,
  clients,
  dropdownHandleAdhocEmployeeSkill,
  handleSaveAdhocVist,
  saveButtonIcon,
  loadingSave,
  textStatus,
  errors,
  setFormErrors,
  clientDropdownId,
  editMode,
  locations
}) => {
  const toast = useRef();
console.log(errors,"errors")
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [timeSelected, setTimeSelected] = useState('');
  const [isFullDay, setIsFullDay] = useState(false);
  const [isOverNight, setIsOverNight] = useState(false);
  const [timeError, setTimeError] = useState(false)
  const [billRate, setBillRate] = useState([]);
  const [payRate, setPayRate] = useState([]);
  const [timeErrorMessage, setTimeErrorMessage] = useState('');
  const [funderList, setFunderList] = useState([]);
  const [serviceCategory, setServiceCategory] = useState([])
  const [plannedTasks, setPlannedTasks] = useState([]);
  const [medDoseTimingList, setMedDoseTimingList] = useState([])

  const preferred_start_time =
    values[nameSection].preferred_start_time;
  const preferred_end_time =
    values[nameSection].preferred_end_time;
  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Add Adhoc',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);

  useEffect(() => {
    values.user_type = 'client'
    funderLists();
  }, [])

  // const createAddressFormat = address => {
  //   const name = address.name ? `${address.name}, ` : '';
  //   const line_1 = address.line_1 ? `${address.line_1}, ` : '';
  //   const city = address.city ? `${address.city}, ` : '';
  //   const zip_code = address.zip_code ? `${address.zip_code}` : '';

  //   return `${name}${line_1}${city}${zip_code}`;
  // };
  const createAddressDropdown = addressArr => {
    let dropdown = addressArr && addressArr.map(add => {
      const name = add.service_address.name ? `${add.service_address.name}, ` : '';
      const line_1 = add.service_address.line_1 ? `${add.service_address.line_1}, ` : '';
      const city = add.service_address.city ? `${add.service_address.city}, ` : '';
      const zip_code = add.service_address.zip_code ? `${add.service_address.zip_code}` : '';
      return { id: add.service_address.id, name: `${name}${line_1}${city}${zip_code}` }
    })
    return dropdown
  }


  const handleClick = timeSelected => {
    setTimeSelected(timeSelected);
  };
  const dropDownHandleRate = (item, nameSection, sectionIndex, list_key, category) => {
    if (list_key == 'service_bill_rate') {
      if (item.rate === null) {
        values[category][nameSection][sectionIndex].bill_rate_per_hour = null
      } else {
        values[category][nameSection][sectionIndex].bill_rate_per_hour = billRate.find(rate => rate.id === item.rate).rate_per_hour
      }

    }
    values[category][nameSection][sectionIndex][list_key] = item.rate
    setValues({ ...values })
  }
  const addNewRate = (nameKey) => {
    if (!values[nameSection].bill_rates) {
      values[nameSection].bill_rates = [{}]
      setValues({ ...values })
    }
    else {
      values[nameSection][nameKey] = [...values[nameSection][nameKey], {}]
      setValues({ ...values })
    }

  }
  const deleteRate = (nameKey, category, sectionIndex) => {
    values[category][nameKey].splice(sectionIndex, 1)
    setValues({ ...values })
  }
  useEffect(() => {
    if (values[nameSection].is_overnight) {
      const start_time = moment(values[nameSection].preferred_start_time, 'h:mma')
      const end_time = moment(values[nameSection].preferred_end_time, 'h:mma')
      if (!end_time.isBefore(start_time)) {
        setTimeError(true)
        setTimeErrorMessage(InvalidRange);
      } else {
        setTimeError(false)
        setTimeErrorMessage('');
      }
    } else {
      const start_time = moment(values[nameSection].preferred_start_time, 'h:mma')
      const end_time = moment(values[nameSection].preferred_end_time, 'h:mma')
      if (end_time.isBefore(start_time)) {
        setTimeError(true)
        setTimeErrorMessage(InvalidRange);
      } else {
        setTimeError(false)
        setTimeErrorMessage('');
      }

    }
  }, [values[nameSection].preferred_start_time,
  values[nameSection].preferred_end_time,
  values[nameSection].is_overnight])

  useEffect(() => {
    getPayRates()
      .then(res => {
        setBillRate(res.filter(data => data.rate_card_type == 'Bill Rates') || []);
        setPayRate(res.filter(data => data.rate_card_type == 'Pay Rates') || []);
      })
    if (!values[nameSection].employee_required) {
      values[nameSection].employee_required = [{}]
    }

  }, [])

  useEffect(() => {
    if (
      values[nameSection].preferred_start_time &&
      values[nameSection].preferred_end_time
    ) {
      let start_time = moment(
        values[nameSection].preferred_start_time,
        'HH:mm:ss'
      );
      let end_time = moment(
        values[nameSection].preferred_end_time,
        'HH:mm:ss'
      );


      if (end_time > start_time) {

        values[nameSection].duration = end_time.diff(
          start_time,
          'seconds'
        );
      } else {
        let add_date = end_time.add(1, 'days')
        values[nameSection].duration = add_date.diff(
          start_time,
          'seconds'
        );
      }
    } else {
      values[nameSection].duration = 0;
    }
    setValues({ ...values });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferred_start_time, preferred_end_time]);

  const handelFullDay = () => {
    setIsFullDay(prevState => !prevState);
    setIsOverNight(false)
    values[nameSection].is_overnight = false
    setValues({ ...values })
  };
  const handelOverNight = () => {
    setIsOverNight(prevState => !prevState);
    setIsFullDay(false)
    values[nameSection].full_day = false
    setValues({ ...values })
  };
 

  // useEffect(() => {
  //   if(isFullDay){
  //     setIsOverNight(false)
  //     values[nameSection].is_overnight = false
  //   }
  //    if(isOverNight){
  //     setIsFullDay(false)
  //     values[nameSection].full_day = false
  //   }
  //   setValues({...values})
  // },[isFullDay,isOverNight])

  const addNewVisitEmployee = () => {
    values[nameSection].employee_required.push({ id: '' })
    setValues({ ...values })
  }

  const deleteVisitEmployee = (index) => {
    if (values[nameSection].employee_required.length > 0) {
      values[nameSection].employee_required.splice(index, 1)
      let newErrors = errors.filter(err => !err.includes('adhocValues.employee_required'))
      setFormErrors([...newErrors])
      setValues({ ...values })
    }
  }


  const funderLists = () => {
    getFundersList()
      .then(res => {
        if (res.length > 0) {
          let newFunders = res.map(funder => {
            return {
              id: funder.id,
              name: funder.funder_name
            }
          })
          setFunderList([...newFunders])
        }
      })
  }



  const changeFunderName = (e) => {
    values[nameSection].name = e.target.value
    setValues({ ...values })
  }

  const handleDateInput = (key, e) => {
    let inputValue = e.target.value ? moment(e.target.value).format('YYYY-MM-DD') : ''
    let diffTime = 0
    if (e.target.value && (values[nameSection].start_date || values[nameSection].end_date)) {
      let start = new Date(values[nameSection].start_date) || '';
      let end = new Date(values[nameSection].end_date) || ''
      if (key == 'start_date') {
        diffTime = end - e.target.value;
      } else {
        diffTime = e.target.value - start;
      }
    }
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 7) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: 'You can not add visit for more than 7 days. If you want to create so, please add from client service contract.',
      });
    } else {
      values[nameSection][key] = inputValue
      setValues({ ...values })
    }


  }

  console.log('adhoc', values[nameSection])
  const is_location_hook = useInput('is_location')

  const handleToggleChange = () => {
    values.is_location = !values.is_location
    setValues({ ...values })
  }

  useEffect(() => {
    const filtered = choices?.service_category && choices?.service_category.filter(item => values.is_location ? item.category_type === 'location' : item.category_type === 'client')
    setServiceCategory(filtered)
  }, [values.is_location, choices?.service_category])

  useEffect(() => {

    if (values.user_type == 'location' && locations?.length) {
      let find = locations.find(item => item.id === values[nameSection].client)
      if (find) {
        values[nameSection].client = find.id
      } else {
        values[nameSection].client = null
      }
    } else {
      let find = clients.find(item => item.id === values[nameSection].client)
      if (find) {
        values[nameSection].client = find.id
      } else {
        values[nameSection].client = null
      }
    }

    let found = serviceCategory?.find(item => item.id === values[nameSection].service_category)
    if (found) {
      values[nameSection].service_category = found.id
    } else {
      values[nameSection].service_category = null
    }
    if (values[nameSection].selected_service_address) {
      if (values.user_type === 'location' && values[nameSection].service_address) {
        if (values[nameSection].selected_service_address !== values[nameSection].service_address.id) {
          values[nameSection].selected_service_address = null
        }
      } else if (values.user_type === 'client' && values[nameSection].service_address) {
        const find = values[nameSection]?.service_address.client_services?.find(item => item.service_address.id === values[nameSection]?.selected_service_address)
        if (!find) {
          values[nameSection].selected_service_address = null
        }
      }
    }
    setValues({ ...values })
  }, [values.user_type, serviceCategory])

  const dropDownHandle = (value, name) => {
    values[name] = value[name]
    setValues({ ...values })
  }

  // },[values[nameSection].client])

  useEffect(() => {
    if (values[nameSection].client) {
      getPlannedTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values[nameSection].client]);

  const getPlannedTasks = async () => {
    const response = await getSchedulerPlanned(values[nameSection].client);

    if (response.results) {
      setPlannedTasks(
        response.results.map(item => {
          return { id: item.id, name: item.group_name };
        })
      );
    }
  };

  const deletePlanedTask = (category, visitIndex, nameSection, index) => {
  let error =  errors.filter(err => !err.includes(`${category}.${nameSection}[${index}]`))
  setFormErrors(error)
    values[category][nameSection].splice(index, 1)
    setValues({ ...values })
  }
 const dropDownHandlePlanedTask = (value, name, category, visitIndex, nameSection, index) => {
  values[category][nameSection][index][name] = value[name]
  setValues({ ...values })
 }
 const deleteMedication = (category, visitIndex, nameSection, index) => {
  let error =  errors.filter(err => !err.includes(`${category}.${nameSection}[${index}]`))
  setFormErrors(error)
    values[category][nameSection].splice(index, 1)
    setValues({ ...values })
}


const getMedicationPrescriptionData = async (doseId, item) => {
  console.log(item, "item")
  const clientId = values[nameSection].client
  if(!clientId || !doseId || !item.effective_from){
    return {data: []}
  }
  const body = {
    client: clientId,
    medicationDose: doseId,
    serviceStartDate: item.effective_from,
    serviceEndDate: item.effective_to || null
  }
  try {
    const res = await getMedicationPrescription(body)
    return res
  } catch (error) {

  }
}
  const dropDownHandleMedication = async (value, name, category, visitIndex, nameSection, index) => {
    if (name == "doseTime") {
      const res = await getMedicationPrescriptionData(value[name], values[category][nameSection][index])
        values[category][nameSection][index]["prescriptionData"] = res?.data.length 
        ? res?.data?.map(pres => ({ id: pres.id, name: pres.prescriptionName }))
        :[]
      values[category][nameSection][index]["prescriptions"] = null
    }
    values[category][nameSection][index][name] = value[name]
    setValues({ ...values })
  }


  // const dropDownHandleMedication = async (value, name, category, visitIndex, nameSection, index) => {
  //   if(name == "doseTime"){
  //     const res = await getMedicationPrescriptionData(value[name], values[category][visitIndex][nameSection][index])
  //     if (res?.data)
  //       values[category][visitIndex][nameSection][index]["prescriptionData"] = res?.data?.map(pres => ({ id: pres.id, name: pres.prescriptionName }));
  //     values[category][visitIndex][nameSection][index]["prescriptions"] = null
  //   }
  //    values[category][visitIndex][nameSection][index][name] = value[name]
  //   setValues({ ...values })
  // }


 const addNewPlannedTask = (category,nameSection, emp, indexEmp) => {
  if (!values[category][nameSection])
    values[category][nameSection] = [];
  values[category][nameSection].push({
    tasks: [
      {
        is_essential: false,
        defaultExpand: true,
        support_level: null,
        care_task: null,
        care_category: null,
        task_type: null,
        message: null,
      }]
  })
  setValues({ ...values });
}

const addNewMedication = (section, emp, indexEmp) => {
  if (!values[nameSection][section])
    values[nameSection][section] = [];
  values[nameSection][section].push({})
  setValues({ ...values });
}


const medicationDoseTimeLists = (client) => {
  getMedicationDoseTimingOptionList(client)
    .then(res => {
      if (res.message.length > 0) {
        let madMap = {}
        let newMed = res?.message?.map(med => {
          madMap[med.id] = {
            id: med.id,
            name: med.name
          }
          return {
            id: med.id,
            name: med.name
          }
        })
        // setMedDoseTimingListMap(madMap)
        setMedDoseTimingList([...newMed])
      }
    })
}

useEffect(() => {
  if(values.user_type == "client" && values[nameSection].client){
    medicationDoseTimeLists(values[nameSection].client)
  }
},[values[nameSection].client])

console.log(values[nameSection], "nameSection")
  return (
    <ModalContainer 
    style={{    maxHeight: '90vh',   overflowY: 'scroll'}}
     max_width={'70%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={false}>
        <Toast ref={toast} position="top-right" />
        <div className={'ps-3 pe-3'}>
          <FlexGrid3Container className='mb-2'>
            <div style={{ minWidth: '33%' }}>
              <DropdownSearchable
                required
                {...useInput(`user_type`, 'isRequired')}
                placeHolder={'User Type'}
                options={[
                  { id: 'client', name: 'Client' },
                  { id: 'location', name: 'Location' },
                ]}
                selectedOption={values.user_type ? values.user_type : null}
                onChange={value =>
                  dropDownHandle({ user_type: value ? value : null }, 'user_type')
                }
              />
            </div>
            <div className={'ms-2'} style={{ minWidth: '66%' }}>
              <DropdownSearchable
                required
                {...useInput(`${[nameSection]}.client`, 'isRequired')}
                placeHolder={
                  values.user_type === 'location'
                    ? ' Location Name'
                    : 'Client Name'
                }
                options={values.user_type === 'location' ? locations : clients}
                selectedOption={
                  values[nameSection].client ? values[nameSection].client : null
                }
                onChange={value =>
                  dropDownHandleClient(
                    { client: value ? value : null },
                    'client',
                    values.user_type === 'location'
                  )
                }
              />
            </div>
          </FlexGrid3Container>

          <FlexGrid3Container >
            <FlexGrid3Item className="pe-1 pb-2">
              <DropdownSearchable
                placeHolder={
                  values.user_type === 'location'
                    ? 'Location type'
                    : 'Client type'
                }
                options={choices.client_type}
                selectedOption={
                  values[nameSection] ? values[nameSection].client_type : null
                }
                onChange={value =>
                  dropDownHandleAdhocVisit(
                    { client_type: value ? value : null },
                    'client_type'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
              <DropdownSearchable
                required
                {...useInput(`${[nameSection]}.service_category`, 'isRequired')}
                placeHolder={'Service category'}
                options={
                  values?.user_type
                    ? choices?.service_category && choices?.service_category?.filter(
                      item => item.category_type === values.user_type
                    )
                    : []
                }
                selectedOption={
                  values[nameSection]
                    ? values[nameSection].service_category
                    : null
                }
                onChange={value =>
                  dropDownHandleAdhocVisit(
                    { service_category: value ? value : null },
                    'service_category'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0 pb-2">
              <DropdownSearchable
                placeHolder={'Visit type'}
                options={choices.visit_type}
                selectedOption={
                  values[nameSection] ? values[nameSection].visit_type : null
                }
                onChange={value =>
                  dropDownHandleAdhocVisit(
                    { visit_type: value ? value : null },
                    'visit_type'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-0 ps-1 ps-md-0 pe-md-1">
              <DropdownSearchable
                placeHolder={'Visit priority'}
                options={choices.visit_priority}
                selectedOption={
                  values[nameSection]
                    ? values[nameSection].visit_priority
                    : null
                }
                onChange={value =>
                  dropDownHandleAdhocVisit(
                    { visit_priority: value ? value : null },
                    'visit_priority'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
              <DropdownSearchable
                placeHolder={'Service destination'}
                options={choices.service_destination}
                selectedOption={
                  values[nameSection]
                    ? values[nameSection].service_destination_id
                    : null
                }
                onChange={value =>
                  dropDownHandleAdhocVisit(
                    { service_destination_id: value ? value : null },
                    'service_destination_id'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-0 ps-1 pt-1 pb-1">
              <ToggleButton
                label="Time critical"
                useInput={useInput(`${[nameSection]}.time_critical`)}
                id="time_critical"
              />
            </FlexGrid3Item>
          </FlexGrid3Container>
          <FlexGrid3Container className='mt-2'>
            <FlexGrid3Item className="pe-0 pe-md-1 pb-2 pb-md-0">
              <DateSelector
                isRequired
                // data-error={dateError[index]}
                // errorMessage={dateErrorMessage}
                labelTop={'Service start date'}
                {...useInput(`${[nameSection]}.start_date`, 'isRequired')}
                onChange={e => {
                  handleDateInput('start_date', e);
                }}
              />  
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pb-2 pb-md-0">
              <DateSelector
                isRequired
                // data-error={dateError[index]}
                // errorMessage={dateErrorMessage}
                labelTop={'Service end date'}
                {...useInput(`${[nameSection]}.end_date`, 'isRequired')}
                onChange={e => {
                  handleDateInput('end_date', e);
                }}
              />
            </FlexGrid3Item>
            <FlexGrid3Item
              className="ps-0 ps-md-1 pe-1 pe-md-0 pb-2"
              onClick={() => handleClick(1)}>
              <TimeSelector
                isRequired={isFullDay ? false : true}
                data-error={timeError}
                errorMessage={timeErrorMessage}
                disabled={isFullDay}
                labelTop={'Preferred start time'}
                {...useInput(
                  `${[nameSection]}.preferred_start_time`,
                  'isRequired'
                )}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              />
            </FlexGrid3Item>
            <FlexGrid3Item
              className="ps-0 pe-0 pe-md-1 pb-2 pb-md-0"
              onClick={() => handleClick(2)}>
              <TimeSelector
                isRequired={isFullDay ? false : true}
                data-error={timeError}
                errorMessage={timeErrorMessage}
                disabled={isFullDay}
                labelTop={'Preferred end time'}
                {...useInput(
                  `${[nameSection]}.preferred_end_time`,
                  'isRequired'
                )}
                timeFormat={'HH:mm'}
                dateFormat={'HH:mm'}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1 ps-0 ps-md-1 pb-1">
              <DurationInput
                disabled={true}
                label_top={'Duration'}
                {...useInput(`${[nameSection]}.duration`)}
              />
            </FlexGrid3Item>
            <div className="d-flex pe-1 ps-0 ps-md-1 pb-1 mt-2">
              <div style={{ marginRight: '10px' }}>
                <ToggleButton
                  label="Full Day"
                  useInput={useInput(`${[nameSection]}.full_day`)}
                  id="full_day"
                  onClick={handelFullDay}
                />
              </div>

              <ToggleButton
                label="Is Overnight"
                useInput={useInput(`${[nameSection]}.is_overnight`)}
                id="is_overnight"
                onClick={handelOverNight}
              />
            </div>
          </FlexGrid3Container>
          <InputTextArea
            // label={'Service notes'}
            type="text"
            id={`${[nameSection]}_vnotes`}
            {...useInput(`${[nameSection]}.notes`)}
            placeholder={' Visit - notes   '}
          />

          <div className={'mt-1 mb-2'}>
            <TitleSection>Funder</TitleSection>
            <FlexGrid3Container className="d-flex pb-2">
              <FlexGrid3Item className="pe-1">
                <DropdownSearchable
                  placeHolder={'Is Self Funded'}
                  options={[
                    { id: 1, name: 'Yes' },
                    { id: 2, name: 'No' },
                  ]}
                  selectedOption={
                    values[nameSection] ? values[nameSection].self_funded : null
                  }
                  onChange={value =>
                    dropDownHandleAdhocVisit(
                      { self_funded: value ? value : null },
                      'self_funded'
                    )
                  }
                />
              </FlexGrid3Item>
              {values[nameSection] &&
                values[nameSection].self_funded &&
                values[nameSection].self_funded == 1 && (
                  <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                    <InputLabelTop
                      type="text"
                      id="funder_name"
                      label={'Funder name'}
                      onChange={changeFunderName}
                      value={values[nameSection].name}
                      maxlength={255}
                    />
                  </FlexGrid3Item>
                )}

              {values[nameSection] &&
                values[nameSection].self_funded &&
                values[nameSection].self_funded == 2 && (
                  <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                    <DropdownSearchable
                      placeHolder={'Funder Name'}
                      options={funderList}
                      selectedOption={
                        values[nameSection] ? values[nameSection].funder : null
                      }
                      onChange={value =>
                        dropDownHandleAdhocVisit(
                          { funder: value ? value : null },
                          'funder'
                        )
                      }
                    />
                  </FlexGrid3Item>
                )}

              <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
                <DropdownSearchable
                  placeHolder={'Client / Funder category'}
                  options={choices.funder_source}
                  selectedOption={
                    values[nameSection] ? values[nameSection].category : null
                  }
                  onChange={value =>
                    dropDownHandleAdhocVisit(
                      { category: value ? value : null },
                      'category'
                    )
                  }
                />
              </FlexGrid3Item>
            </FlexGrid3Container>
          </div>

          {/* <TitleSection>Bill Rate Card</TitleSection>
          <div>
            {values[nameSection].bill_rates &&
              values[nameSection].bill_rates &&
              values[nameSection].bill_rates.map((bill_rate, indexBill) => {
                return (
                  <RateCardAdhoc
                    readOnly={true}
                    editMode={editMode}
                    values={values}
                    bill_rate={billRate}
                    dataList={billRate}
                    list_key1={'service_bill_rate'}
                    dropDownHandleRate={dropDownHandleRate}
                    useInput={useInput}
                    category={nameSection}
                    nameSection={'bill_rates'}
                    index={indexBill}
                    deleteRate={deleteRate}
                  />
                );
              })}
          </div>
          <PrimaryButtonForm
            className={'ms-3 mt-1 mb-2'}
            bgcolor="BLUE"
            minWidth="3rem"
            onClick={() => addNewRate('bill_rates')}>
            <span>{<BsFillPlusSquareFill />}Add Rate Card</span>
          </PrimaryButtonForm> */}
          <div className={'mt-2 mb-2'}>
            <TitleSection>Employees and Skills Required</TitleSection>
          </div>
          {values[nameSection].employee_required &&
            values[nameSection].employee_required.length >= 1 ? (
            values[nameSection].employee_required.map((data, indexEmployee) => {
              return (
                <ServiceAdhocEmployee
                  readOnly={false}
                  editMode={editMode}
                  choices={choices}
                  useInput={useInput}
                  nameSection={nameSection}
                  handleClick={handleClick}
                  values={values}
                  indexEmployee={indexEmployee}
                  deleteVisitEmployee={deleteVisitEmployee}
                  dropDownHandleAdhocVisit={dropDownHandleAdhocVisit}
                  dropdownHandleAdhocEmployee={dropdownHandleAdhocEmployee}
                  dropdownHandleAdhocEmployeeSkill={
                    dropdownHandleAdhocEmployeeSkill
                  }
                  setValues={setValues}
                  addPayrate={true}
                  data={data}
                  bill_rate={billRate}
                  dataList={payRate}
                  payRateDrodownList={payRate}
                  clientDropdownId={clientDropdownId}
                  isAdhoc={true}
                />
              );
            })
          ) : (
            <Fragment />
          )}
          <div className={'mt-2'}>
            <PrimaryButtonForm
              minWidth="6rem"
              // disabled={!editMode}
              onClick={addNewVisitEmployee}>
              {PLUS}
              ADD NEW EMPLOYEE
            </PrimaryButtonForm>
          </div>

          {values.user_type === "client" && (
            <div className='mt-2'>
              <TitleSection>Planned Task</TitleSection>
              <div>
                {values[nameSection] &&
                  values[nameSection].planned_task &&
                  values[nameSection].planned_task.map((task, taskIndex) => {
                    return (
                      <AddPlannedTask
                        values={values}
                        setValues={setValues}
                        nameSection={'planned_task'}
                        // visitIndex={index}
                        index={taskIndex}
                        useInput={useInput}
                        category={nameSection}
                        plannedTasks={plannedTasks}
                        readOnly={false}
                        editMode={!editMode}
                        deletePlanedTask={deletePlanedTask}
                        dropDownHandlePlanedTask={dropDownHandlePlanedTask}
                        toast={toast}
                      />
                    );
                  })}
              </div>
              {(
                <PrimaryButtonForm
                  disabled={editMode}
                  className={'ms-3 mt-1 mb-2'}
                  bgcolor="BLUE"
                  minWidth="3rem"
                  onClick={() => addNewPlannedTask(nameSection, 'planned_task')}>
                  <span>{<BsFillPlusSquareFill />}Planned Task</span>
                </PrimaryButtonForm>
              )}

            </div>)}

            {values.user_type === "client" &&
          <div>
            <TitleSection>Medication</TitleSection>
            <div>
              {values[nameSection] &&
                values[nameSection].medication &&
                values[nameSection].medication.map((itemMed, indexMed) => {
                  return (
                    <AddMedication
                      values={values}
                      setValues={setValues}
                      nameSection={'medication'}
                      // visitIndex={index}
                      index={indexMed}
                      useInput={useInput}
                      category={nameSection}
                      medicationOption={medDoseTimingList ? [...medDoseTimingList] : []}
                      readOnly={false}
                      editMode={!editMode}
                      toDelete={deleteMedication}
                      dropDownHandleSelect={dropDownHandleMedication}
                      toast={toast}
                      itemMed={itemMed}
                      getMedicationPrescriptionData={getMedicationPrescriptionData}
                    />
                  );
                })}
            </div>

            {(
              <PrimaryButtonForm
                disabled={editMode}
                className={'ms-3 mt-2 mb-2'}
                bgcolor="BLUE"
                minWidth="3rem"
                onClick={() => addNewMedication('medication')}>
                <span>{<BsFillPlusSquareFill />}New Medication</span>
              </PrimaryButtonForm>
            )}
          </div>}



          <div className="mt-3 mb-2">
            <FlexGrid3Item>
              <DropdownSearchable
                required
                {...useInput(
                  `${[nameSection]}.selected_service_address`,
                  'isRequired'
                )}
                placeHolder={'Service address'}

                options={
                  values[nameSection].service_address
                    ? createAddressDropdown(values[nameSection].service_address)
                    : []

                }
                selectedOption={
                  values[nameSection]
                    ? values[nameSection].selected_service_address
                    : null
                }
                onChange={value =>
                  dropDownHandleAdhocVisit(
                    { selected_service_address: value ? value : null },
                    'selected_service_address'
                  )
                }
              />
            </FlexGrid3Item>
          </div>
          <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5 mb-4'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ms-0 ms-md-3'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={() => handleSaveAdhocVist(timeError)}>
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    saveButtonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

AdhocVisit.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  fullBind: PropTypes.object.isRequired,
  dropDownHandleVisit: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default AdhocVisit;
