import React, { useState, useEffect, Fragment, useRef, useContext, useMemo } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import DateSelector from 'components/SharedComponents/DateSelector';
import { PLUS, TIMES } from 'utils/constants/icons';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { useForm } from 'hooks/FormHook';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import "./style.css"
import {
  Header,
  ModalContainer,
  Title,
  TitleSection,
} from 'shared/styles/constants/tagsStyles';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import { Toast } from 'primereact/toast';
import moment from 'moment';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
} from 'shared/styles/constants/tagsStyles';
import TimeSelector from 'components/SharedComponents/TimeSelector';
import {
  editSettingEvv,
  editSettingEvvData,
  getSettingEvv,
  getSettingEsvById,
} from 'utils/api/SettingsApi';
import BandedActualDurations from './BandedActualDurations';
import ClockInToVisit from './ClockInToVisit';
import ClockOutOfVisit from './ClockOutOfVisit';
import TravelModeRestriction from './TravelModeRestriction';
import { AppContext } from 'context';
import TravelTimeEstimate from './TravelTimeEstimate';
import EvvConfimation from './EvvConfirmation';
import CustomMultiSelectDropdown from 'components/SharedComponents/CustomMultiSelectDropdown';
import NoSelectAllDropdownFilterCustom from 'components/SharedComponents/NoSelectAllDropdownFilterCustom';
import MultiDropDownSearchable from 'components/SharedComponents/MultiDropDownSearchable/MultiDropDownSearchable';
import { setting_evv_choices } from 'utils/choiceConstant';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import RowInfoModel from 'components/SharedComponents/RowInfoModel';
import CustomHeaderLabel from 'components/SharedComponents/FormSections/Service/CustomHeaderShow/CustomHeaderLabel';
import InputTextArea from 'components/SharedComponents/InputTextArea';

const SettingsEvv = props => {
  const {closeModal,toast} = props || {};
  const [loading, setLoading] = useState(true);

  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [branchIdName, setBranchIdName] = useState('');
  const [StoredMobilReminderData, setStoredMobilReminderData] = useState();
  const [
    dataClockDistanceTimeVerification,
    setDataClockDistanceTimeVerification,
  ] = useState();
  const [vistsStatusMissed, setVistsStatusMissed] = useState();
  const [vistsStatusCompleted, setVistsStatusCompleted] = useState();
  const [vistsStatusInProgress, setVistsStatusInProgress] = useState();
  const [vistssStatusNotStatrted, setVistsStatusNotStatrted] = useState();

  const [employeeAbscenceHoursLength, setEmployeeAbscenceHoursLength] =
    useState();
  const [employeeAbscenceHoursValue, setEmployeeAbscenceHoursValue] =
    useState();
  const [employeeAbscenceMinuteLength, setEmployeeAbscenceMinuteLength] =
    useState();
  const [employeeAbscenceMinuteValue, setEmployeeAbscenceMinuteValue] =
    useState();
  const [
    employeeUnavailabilityHoursLength,
    setEmployeeUnavailabilityHoursLength,
  ] = useState();
  const [
    employeeUnavailabilityHoursValue,
    setEmployeeUnavailabilityHoursValue,
  ] = useState();
  const [
    employeeUnavailabilityMinuteLength,
    setEmployeeUnavailabilityMinuteLength,
  ] = useState();

  const [
    employeeUnavailabilityMinuteValue,
    setEmployeeUnavailabilityMinuteValue,
  ] = useState();
  const [erorEmployeAbscence, setErorEmployeAbscence] = useState('');
  const [erorEmployeUnavailability, setErorEmployeUnavailability] =
    useState('');
  const [erorTimeFormatMag, setErorTimeFormatMag] = useState();
  const [loadingModal, setLoadingModal] = useState(true);

  const [visitStatus, setVisitStatus] = useState([]);
  const { contextChoices } = useContext(AppContext);
  const [visitStatusNoClocked, setVisitStatusNoClocked] = useState([]);
  const [visitStatusClockedIn, setVisitStatusClockedIn] = useState([]);
  const [VisitStatusClockedInOut, setVisitStatusClockedInOut] = useState([]);
  const [settingEvv, setSettingEvv] = useState({});
  const [clockInWarningMessage, setClockInWarningMessage] = useState('');
  const [travelType, setTravelType] = useState([]);
  const [actualTravelTimeHoursLength, setActualTravelTimeHoursLength] =
    useState();
  const [actualTravelTimeHoursValue, setActualTravelTimeHoursValue] =
    useState();
  const [actualTravelTimeMinuteLength, setActualTravelTimeMinuteLength] =
    useState();
  const [actualTravelTimeMinuteValue, setActualTravelTimeMinuteValue] =
    useState();
  const [
    actualTravelTimeErorTimeFormatMag,
    setActualTravelTimeErorTimeFormatMag,
  ] = useState();
  const [erorActualTravelTime, setErorActualTravelTime] = useState();

  const [timeRescrtictionHoursLength, setTimeRescrtictionHoursLength] =
    useState();
  const [timeRescrtictionHoursValue, setTimeRescrtictionHoursValue] =
    useState();
  const [timeRescrtictionMinuteLength, setTimeRescrtictionMinuteLength] =
    useState();
  const [timeRescrtictionMinuteValue, setTimeRescrtictionMinuteValue] =
    useState();
  const [erorTimeRescrtiction, setErorTimeRescrtiction] = useState();
  const [erorTimeRescrtictionMag, setErorTimeRescrtictionMag] = useState();
  const [checkBeforeSubmit, setCheckBeforeSubmit] = useState(false);

  //below for second field home time restriction
  const [homeTimeRescrtictionHoursLength, setHomeTimeRescrtictionHoursLength] =
    useState();
  const [homeTimeRescrtictionHoursValue, setHomeTimeRescrtictionHoursValue] =
    useState();
  const [
    homeTimeRescrtictionMinuteLength,
    setHomeTimeRescrtictionMinuteLength,
  ] = useState();
  const [homeTimeRescrtictionMinuteValue, setHomeTimeRescrtictionMinuteValue] =
    useState();
  const [homeErorTimeRescrtiction, setHomeErorTimeRescrtiction] = useState();
  const [homeErorTimeRescrtictionMag, setHomeErorTimeRescrtictionMag] =
    useState();
  const [homeCheckBeforeSubmit, setHomeCheckBeforeSubmit] = useState(false);
  const [travelModeOptions, setTravelModeOptions] = useState([]);
  const [travelName, setTravelName] = useState([]);
  const [unitName, setUnitName] = useState([]);


  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});

  const underPro = useInput(`under_provision`);
  const overPro = useInput('over_provision');
  const underProvisionRation = useInput(`under_provision_ratio`);
  const overProvisionRatio = useInput('over_provision_ratio');
  const mountedRef = useRef(true);
  const { handleGetChoices } = useContext(AppContext);
  const debounce = (fn, d) => {
    let timer;
    return function (...args) {
      let context = this;
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, d);
    };
  };
  useEffect(() => {
    if (
      values.round_up_duration_nearest_validation
    ) {
      values.round_down_duration_nearest_validation = false;
      setValues({ ...values });
    }
    if (
      values.round_down_duration_nearest_validation
    ) {
      values.round_up_duration_nearest_validation = false;
      setValues({ ...values });
    }
  }, [
    values.round_up_duration_nearest_validation,
    values.round_down_duration_nearest_validation,
  ]);
  useEffect(() => {
    handleGetChoices(setting_evv_choices);
  }, []);
  const adjustTravelTime = useInput(`adjust_travel_time`);
  const adjustTravelDistance = useInput(`adjust_travel_distance`);

  useEffect(() => {
    setTravelModeOptions(
      contextChoices &&
      contextChoices.travel_type &&
      contextChoices.travel_type.map(y => {
        return { id: y && y.id, name: y && y.name };
      })
    );
    let branchNeme = contextChoices.branch.find(
      item => item.id === props.branchID
    )?.name;
    setBranchIdName(branchNeme);
  }, [contextChoices]);

  const filterClientName = item => {
    setClientFilter([...item]);
  };
  const filterClientName1 = item => {
    setClientFilter1([...item]);
  };
  const travelCodes = [
    'Home-Office',
    'Client-Office',
    'Office-Office',
    'Office-Home',
    'Home-Home',
    'Home-Client',
    'Client-Home',
    'Client-Client',
    'Office-Client',
    'Client-Home'
  ]
  const travel_pay_confirmation = [
    {
      id: values.homeToClient?.id,
      travelCode: values.homeToClient?.travelCode,
      isConsiderTravelPay: values.homeToClient?.isConsiderTravelPay,
    },
    {
      id: values.clientToClient?.id,
      travelCode: values.clientToClient?.travelCode,
      isConsiderTravelPay: values.clientToClient?.isConsiderTravelPay,
    },
    {
      id: values.officeToClient?.id,
      travelCode: values.officeToClient?.travelCode,
      isConsiderTravelPay: values.officeToClient?.isConsiderTravelPay,
    },
    {
      id: values.clientToOffice?.id,
      travelCode: values.clientToOffice?.travelCode,
      isConsiderTravelPay: values.clientToOffice?.isConsiderTravelPay,
    },
    {
      id: values.clientToHome?.id,
      travelCode: values.clientToHome?.travelCode,
      isConsiderTravelPay: values.clientToHome?.isConsiderTravelPay,
    },

    //For OFFICE-OFFICE, OFFICE-HOME, HOME-HOME
    {
      id: values.officeToOffice?.id,
      travelCode: values.officeToOffice?.travelCode,
      isConsiderTravelPay: values.officeToOffice?.isConsiderTravelPay,
    },
    {
      id: values.officeToHome?.id,
      travelCode: values.officeToHome?.travelCode,
      isConsiderTravelPay: values.officeToHome?.isConsiderTravelPay,
    },
    {
      id: values.homeToHome?.id,
      travelCode: values.homeToHome?.travelCode,
      isConsiderTravelPay: values.homeToHome?.isConsiderTravelPay,
    },
    {
      id: values.homeToOffice?.id,
      travelCode: values.homeToOffice?.travelCode,
      isConsiderTravelPay: values.homeToOffice?.isConsiderTravelPay,
    }
  ];

  const dataClockValidationCheck = {
    id:
      values &&
      values.ClockValidationCheck &&
      values.ClockValidationCheck[0] &&
      values.ClockValidationCheck[0].id,
    clock_in_verification_distance: values.verification_distance_clock_in
      ? values.verification_distance_clock_in
      : null,
    clock_out_verification_distance: values.verification_distance_clock_out
      ? values.verification_distance_clock_out
      : null,
    clock_in_verification_time: values.verification_time_clock_in
      ? values.verification_time_clock_in
      : null,
    clock_out_verification_time: values.verification_time_clock_out
      ? values.verification_time_clock_out
      : null,
    incorporate_auto_clock_out_rule: values.incorporate_auto_clock_out_rule,
    incorporate_auto_clock_out_rule_message:
      values.incorporate_auto_clock_out_rule_message,
    undo_clock_in: values.undo_clock_in,
    undo_clock_out: values.undo_clock_out,
    undo_cancel_clock: values.undo_cancel_clock,
    home_to_client: values.home_to_client,
    client_to_client: values.client_to_client,
    office_to_client: values.office_to_client,
    client_to_office: values.client_to_office,
    client_to_home: values.client_to_home,
    office_to_office: values.office_to_office,
    office_to_home: values.office_to_home,
    home_to_home: values.home_to_home,
    home_to_office: values.home_to_office,
    travel_time_mobile: values.schedule_travel_time,
    visit_missed_status: values.visit_missed_status,
    visit_missed_status_time: values.visit_missed_status_time,
    employee_unavailability: values.employee_unavailability,
    employee_absence: values.employee_absence,
    is_manual_clock_in: values.is_manual_clock_in,
    is_manual_clock_out: values.is_manual_clock_out,
    clock_in_qr: values.clock_in_qr,
    clock_in_nfc: values.clock_in_nfc,
    clock_out_qr: values.clock_out_qr,
    clock_out_nfc: values.clock_out_nfc,
    actual_travel_distance: values.actual_travel_distance
      ? values.actual_travel_distance
      : null,
    adjust_travel_distance: values.adjust_travel_distance,
    actual_travel_time: values.actual_travel_time
      ? values.actual_travel_time
      : null,
    adjust_travel_time: values.adjust_travel_time || '00:00' ,
    visit_start_location: values.visit_start_location,
    visit_end_location: values.visit_end_location,
    clock_out_time_state:
      values.clock_out_time_state &&
      values.clock_out_time_state.map(item => item.value).toString(),
    clock_in_time_state:
      values.clock_in_time_state &&
      values.clock_in_time_state.map(item => item.value).toString(),
  };

  const dataClockRoundUpDuration = [
    {
      id:
        values.ClockRoundUpDuration &&
        values.ClockRoundUpDuration[0] &&
        values.ClockRoundUpDuration[0].id &&
        values.ClockRoundUpDuration[0].id,
      round_up_validation: values.round_up_validation,
      round_up_duration_nearest: values.round_up_duration_nearest,
      round_up_duration_nearest_validation:
        values.round_up_duration_nearest_validation,
      round_down_duration_nearest_validation:
        values.round_down_duration_nearest_validation,
      round_down_duration_nearest: values.round_down_duration_nearest,
    },
  ];

  const dataBandedDuration =
    values &&
    values.BandedActualDuration &&
    values.BandedActualDuration.map(bandedData => {
      return {
        id: bandedData.id,
        time_from: bandedData.time_from,
        time_to: bandedData.time_to,
        apply_time: bandedData.apply_time,
      };
    });

  const dataVisitProvision = [
    {
      id:
        values &&
        values.VisitProvision &&
        values.VisitProvision[0] &&
        values.VisitProvision[0].id,
      under_provision: values.under_provision,
      over_provision: values.over_provision,
      on_time_provision:
        values &&
        values.VisitProvision &&
        values.VisitProvision[0] &&
        values.VisitProvision[0].on_time_provision,
      under_provision_ratio: parseInt(values.under_provision_ratio),
      over_provision_ratio: parseInt(values.over_provision_ratio),
      time_unit: values.time_unit === 'Percent' ? 'Ratio' : values.time_unit,
    },
  ];

  const dataVisitTimeClassified = [
    {
      id:
        values.VisitTimeClassified &&
        values.VisitTimeClassified.find(data => data.visit_type == 'Start').id,
      early: values.visit_early_start,
      late: values.visit_late_start,
      on_time:
        values.VisitTimeClassified &&
        values.VisitTimeClassified.find(data => data.visit_type == 'Start')
          .on_time,
      visit_type:
        values.VisitTimeClassified &&
        values.VisitTimeClassified.find(data => data.visit_type == 'Start')
          .visit_type,
    },
    {
      id:
        values.VisitTimeClassified &&
        values.VisitTimeClassified.find(data => data.visit_type == 'End').id,
      early: values.visit_early_end,
      late: values.visit_late_end,
      on_time:
        values.VisitTimeClassified &&
        values.VisitTimeClassified.find(data => data.visit_type == 'End')
          .on_time,
      visit_type:
        values.VisitTimeClassified &&
        values.VisitTimeClassified.find(data => data.visit_type == 'End')
          .visit_type,
    },
  ];

  const dataTravel_mode_restriction_serializer =
    values.travel_mode_restriction_serializer &&
    values.travel_mode_restriction_serializer.map(traveldata => {
      return {
        travel_mode: traveldata.travel_mode,
        travel_time: traveldata.travel_time,
        home_client_restriction: traveldata.home_client_restriction,
      };
    });

  const dataTravel_estimate_serializer =
    values.travel_estimate_serializer &&
    values.travel_estimate_serializer.map(traveldata => {
      return {
        id: traveldata.id,
        travel_mode: traveldata.travel_mode,
        travel_name: traveldata.travel_name,
        unit_name: traveldata.unit_name,
        travel_time: parseFloat(traveldata.travel_time).toFixed(2),
      };
    });
  useEffect(() => {
    if (props.idSelected) {
      props.setTitle('Setting EVV');
      setLoadingModal(false);
      values.id = props.idSelected;
      setValues({ ...values });
    } else {
      props.setTitle('Setting EVV');
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (contextChoices) {
      setVistsStatusMissed(
        contextChoices &&
        contextChoices &&
        contextChoices.visit_status &&
        contextChoices.visit_status.find(
          visitname => visitname.name == 'Missed'
        ).name
      );

      setVistsStatusCompleted(
        contextChoices &&
        contextChoices &&
        contextChoices.visit_status &&
        contextChoices.visit_status.find(
          visitname => visitname.name == 'Completed'
        ).name
      );

      setVistsStatusInProgress(
        contextChoices &&
        contextChoices &&
        contextChoices.visit_status &&
        contextChoices.visit_status.find(
          visitname => visitname.name == 'In Progress'
        ).name
      );

      setVistsStatusNotStatrted(
        contextChoices &&
        contextChoices &&
        contextChoices.visit_status &&
        contextChoices.visit_status.find(
          visitname => visitname.name == 'Not Started'
        ).name
      );

      setTravelType(
        contextChoices &&
        contextChoices &&
        contextChoices.travel_type &&
        contextChoices.travel_type.map(traveltype => {
          return { id: traveltype.id, name: traveltype.name };
        })
      );
    }
  }, [contextChoices]);

  useEffect(() => {
    if (values.clock_in_to_visit && values.clock_out_of_visit) {
      const clock_in_to_visit =
        values.clock_in_to_visit &&
        values.clock_in_to_visit.map(data => {
          return {
            id: data.id,
            reminder_time: data.reminder_time,
            clock_type: data.clock_type ? data.clock_type : 'Clock In',
            reminder_message: data.reminder_message
              ? data.reminder_message
              : '',
            clock_time_type: data.clock_time_type,
            is_active: data.is_active,
          };
        });

      const clock_out_of_visit =
        values.clock_out_of_visit &&
        values.clock_out_of_visit.map(data => {
          return {
            id: data.id,
            reminder_time: data.reminder_time,
            clock_type: data.clock_type ? data.clock_type : 'Clock Out',
            reminder_message: data.reminder_message
              ? data.reminder_message
              : '',
            clock_time_type: data.clock_time_type,
            is_active: data.is_active,
          };
        });
      const dataVisitMobileReminder = [
        ...clock_in_to_visit,
        ...clock_out_of_visit,
      ];
      setStoredMobilReminderData(dataVisitMobileReminder);
    }
  }, [values]);

  useEffect(() => {
    if (values.ClockDistanceTimeVerification) {
      const clockDistanceTimeVerification = [
        {
          id:
            values.ClockDistanceTimeVerification &&
            values.ClockDistanceTimeVerification[0] &&
            values.ClockDistanceTimeVerification[0].id &&
            values.ClockDistanceTimeVerification[0].id,

          clock_in_distance_within_time:
            values.clock_in_distance_within_time &&
            values.clock_in_distance_within_time,
          clock_in_distance_within_time_message:
            values.clock_in_distance_within_time
              ? values.clock_in_warning
              : values.ClockDistanceTimeVerification[0] &&
              values.ClockDistanceTimeVerification[0]
                .clock_in_distance_within_time_message,

          clock_in_distance_outwith_time:
            values.clock_in_distance_outwith_time &&
            values.clock_in_distance_outwith_time,
          clock_in_distance_outwith_message:
            values.clock_in_distance_outwith_time
              ? values.clock_in_warning
              : values.ClockDistanceTimeVerification[0] &&
              values.ClockDistanceTimeVerification[0]
                .clock_in_distance_outwith_message,

          clock_in_outwith_distance_within_time:
            values.clock_in_outwith_distance_within_time &&
            values.clock_in_outwith_distance_within_time,
          clock_in_outwith_distance_within_time_message:
            values.clock_in_outwith_distance_within_time
              ? values.clock_in_warning
              : values.ClockDistanceTimeVerification[0] &&
              values.ClockDistanceTimeVerification[0]
                .clock_in_outwith_distance_within_time_message,

          clock_in_outwith_distance_outwith_time:
            values.clock_in_outwith_distance_outwith_time &&
            values.clock_in_outwith_distance_outwith_time,
          clock_in_outwith_distance_outwith_time_message:
            values.clock_in_outwith_distance_outwith_time
              ? values.clock_in_warning
              : values.ClockDistanceTimeVerification[0] &&
              values.ClockDistanceTimeVerification[0]
                .clock_in_outwith_distance_outwith_time_message,

          clock_out_distance_within_time:
            values.clock_out_distance_within_time &&
            values.clock_out_distance_within_time,
          clock_out_distance_within_time_message:
            values.clock_out_distance_within_time
              ? values.clock_out_warning
              : values.ClockDistanceTimeVerification[0] &&
              values.ClockDistanceTimeVerification[0]
                .clock_out_distance_within_time_message,

          clock_out_distance_outwith_time:
            values.clock_out_distance_outwith_time &&
            values.clock_out_distance_outwith_time,
          clock_out_distance_outwith_time_message:
            values.clock_out_distance_outwith_time
              ? values.clock_out_warning
              : values.ClockDistanceTimeVerification[0] &&
              values.ClockDistanceTimeVerification[0]
                .clock_out_distance_outwith_time_message,

          clock_out_outwith_distance_within_time:
            values.clock_out_outwith_distance_within_time &&
            values.clock_out_outwith_distance_within_time,
          clock_out_outwith_distance_within_time_message:
            values.clock_out_outwith_distance_within_time
              ? values.clock_out_warning
              : values.ClockDistanceTimeVerification[0] &&
              values.ClockDistanceTimeVerification[0]
                .clock_out_outwith_distance_within_time_message,

          clock_out_outwith_distance_outwith_time:
            values.clock_out_outwith_distance_outwith_time &&
            values.clock_out_outwith_distance_outwith_time,
          clock_out_outwith_distance_outwith_time_message:
            values.clock_out_outwith_distance_outwith_time
              ? values.clock_out_warning
              : values.ClockDistanceTimeVerification[0] &&
              values.ClockDistanceTimeVerification[0]
                .clock_out_outwith_distance_outwith_time_message,
        },
      ];

      setDataClockDistanceTimeVerification(clockDistanceTimeVerification);
    }
  }, [values]);

  useEffect(() => {
    loadSettingEvv();
  }, [contextChoices]);

  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
  }, [values]);

  useEffect(() => {
    setHoursMinute();
  }, [
    values.employee_unavailability,
    values.employee_absence,
    values.adjust_travel_time,
  ]);
  const [clientFilter, setClientFilter] = useState([]);
  const [clockInFilter, setClockInFilter] = useState([]);
  const [clockOutFilter, setClockOutFilter] = useState([]);
  const [clientFilter1, setClientFilter1] = useState([]);
  const loadSettingEvv = async () => {
    await getSettingEsvById(props.idSelected).then(response => {
      if (!mountedRef.current) return null;
      if (response) {
        if (
          response.ClockDistanceTimeVerification[0] &&
          response.ClockDistanceTimeVerification[0]
            .clock_in_distance_within_time
        ) {
          values.clock_in_verfication = 'clock_in_distance_within_time';
          values.clock_in_warning =
            response.ClockDistanceTimeVerification[0].clock_in_distance_within_time_message;
        }
        if (
          response.ClockDistanceTimeVerification[0]
            .clock_in_distance_outwith_time
        ) {
          values.clock_in_verfication = 'clock_in_distance_outwith_time';
          values.clock_in_warning =
            response.ClockDistanceTimeVerification[0].clock_in_distance_outwith_message;
        }
        if (
          response.ClockDistanceTimeVerification[0]
            .clock_in_outwith_distance_within_time
        ) {
          values.clock_in_verfication = 'clock_in_outwith_distance_within_time';
          values.clock_in_warning =
            response.ClockDistanceTimeVerification[0].clock_in_outwith_distance_within_time_message;
        }
        if (
          response.ClockDistanceTimeVerification[0]
            .clock_in_outwith_distance_outwith_time
        ) {
          values.clock_in_verfication =
            'clock_in_outwith_distance_outwith_time';
          values.clock_in_warning =
            response.ClockDistanceTimeVerification[0].clock_in_outwith_distance_outwith_time_message;
        }
        if (
          response.ClockDistanceTimeVerification[0]
            .clock_out_distance_outwith_time
        ) {
          values.clock_out_verfication = 'clock_out_distance_outwith_time';
          values.clock_out_warning =
            response.ClockDistanceTimeVerification[0].clock_out_distance_outwith_time_message;
        }
        if (
          response.ClockDistanceTimeVerification[0]
            .clock_out_distance_within_time
        ) {
          values.clock_out_verfication = 'clock_out_distance_within_time';
          values.clock_out_warning =
            response.ClockDistanceTimeVerification[0].clock_out_distance_within_time_message;
        }
        if (
          response.ClockDistanceTimeVerification[0]
            .clock_out_outwith_distance_outwith_time
        ) {
          values.clock_out_verfication =
            'clock_out_outwith_distance_outwith_time';
          values.clock_out_warning =
            response.ClockDistanceTimeVerification[0].clock_out_outwith_distance_outwith_time_message;
        }
        if (
          response.ClockDistanceTimeVerification[0] &&
          response.ClockDistanceTimeVerification[0]
            .clock_out_outwith_distance_within_time
        ) {
          values.clock_out_verfication =
            'clock_out_outwith_distance_within_time';
          values.clock_out_warning =
            response.ClockDistanceTimeVerification[0].clock_out_outwith_distance_within_time_message;
        }
        values.effective_date = response.EvvSettingsCore[0].effective_date;
        if (response.EvvSettings) {
          values.is_offer_approval = response.EvvSettings.is_offer_approval;
          values.is_offer_approval_Id = response.EvvSettings.id;
          values.is_handBack = response.EvvSettings.is_handBack;
          values.is_swap = response.EvvSettings.is_swap;
        }
        //from get api  responsse  fieldvalues assigned below
        values.verification_distance_clock_in =
          response.ClockValidationCheck[0].clock_in_verification_distance;
        values.verification_time_clock_in =
          response.ClockValidationCheck[0].clock_in_verification_time;
        values.verification_distance_clock_out =
          response.ClockValidationCheck[0].clock_out_verification_distance;
        values.verification_time_clock_out =
          response.ClockValidationCheck[0].clock_out_verification_time;
        values.is_manual_clock_in =
          response.ClockValidationCheck[0].is_manual_clock_in;
        values.clock_in_qr = response.ClockValidationCheck[0].clock_in_qr;
        values.clock_in_nfc = response.ClockValidationCheck[0].clock_in_nfc;
        values.visit_end_location =
          response.ClockValidationCheck[0].visit_end_location;
        values.visit_start_location =
          response.ClockValidationCheck[0].visit_start_location;
        values.is_manual_clock_out =
          response.ClockValidationCheck[0].is_manual_clock_out;
        values.clock_out_nfc = response.ClockValidationCheck[0].clock_out_nfc;
        values.clock_out_qr = response.ClockValidationCheck[0].clock_out_qr;
        values.clock_out_time_state =
          response.ClockValidationCheck[0].clock_out_time_state &&
          response?.ClockValidationCheck[0]?.clock_out_time_state
            .split(',')
            .map(item => {
              return {
                id: item,
                name: item,
                label: item,
                value: item,
                labelid: item,
              };
            });
        values.clock_in_time_state =
          response.ClockValidationCheck[0].clock_in_time_state &&
          response?.ClockValidationCheck[0]?.clock_in_time_state
            .split(',')
            .map(item => {
              return {
                id: item,
                name: item,
                label: item,
                value: item,
                labelid: item,
              };
            });

        values.incorporate_auto_clock_out_rule =
          response.ClockValidationCheck[0].incorporate_auto_clock_out_rule;
        values.incorporate_auto_clock_out_rule_message =
          response.ClockValidationCheck[0].incorporate_auto_clock_out_rule_message;
        ///incorporate_auto_clock_out_rule -text warning missing
        values.undo_clock_in = response.ClockValidationCheck[0].undo_clock_in;
        values.undo_clock_out = response.ClockValidationCheck[0].undo_clock_out;
        values.undo_cancel_clock =
          response.ClockValidationCheck[0].undo_cancel_clock;

        values.actual_travel_distance =
          response.ClockValidationCheck[0].actual_travel_distance;
        values.adjust_travel_distance =
          response.ClockValidationCheck[0].adjust_travel_distance;
        values.actual_travel_time =
          response.ClockValidationCheck[0].actual_travel_time;
        values.adjust_travel_time =
          response.ClockValidationCheck[0]?.adjust_travel_time?.slice(0, 5);

        values.visit_early_start =
          response.VisitTimeClassified &&
          response.VisitTimeClassified &&
          response.VisitTimeClassified.find(data => data.visit_type == 'Start')
            .early;
        values.visit_late_start =
          response.VisitTimeClassified &&
          response.VisitTimeClassified &&
          response.VisitTimeClassified.find(data => data.visit_type == 'Start')
            .late;

        values.visit_early_end =
          response.VisitTimeClassified &&
          response.VisitTimeClassified &&
          response.VisitTimeClassified.find(data => data.visit_type == 'End')
            .early;
        values.visit_late_end =
          response.VisitTimeClassified &&
          response.VisitTimeClassified &&
          response.VisitTimeClassified.find(data => data.visit_type == 'End')
            .late;
        values.over_provision = response.VisitProvision[0]?.over_provision;
        values.under_provision = response.VisitProvision[0]?.under_provision;
        values.time_unit =
          response.VisitProvision[0]?.time_unit === 'Ratio'
            ? 'Percent'
            : response?.VisitProvision[0]?.time_unit;
        values.under_provision_ratio =
          response.VisitProvision[0]?.under_provision_ratio;
        values.over_provision_ratio =
          response.VisitProvision[0]?.over_provision_ratio;

        //Missed visit status categorisation filed key missing from BE
        values.visit_missed_status =
          response.ClockValidationCheck[0].visit_missed_status;

        values.visit_status = response.VisitProvision[0]?.over_provision;

        //Visit Status 4 point -  dropdown   needs  to be binded
        values.visit_missed_status_time =
          response.ClockValidationCheck[0]?.visit_missed_status_time;

        values.round_up_validation =
          response.ClockRoundUpDuration[0]?.round_up_validation;
        values.round_down_duration_nearest_validation =
          response.ClockRoundUpDuration[0]?.round_down_duration_nearest_validation;
        values.round_down_duration_nearest =
          response.ClockRoundUpDuration[0]?.round_down_duration_nearest;
        values.round_up_duration_nearest_validation =
          response.ClockRoundUpDuration[0]?.round_up_duration_nearest_validation;
        values.round_up_duration_nearest =
          response.ClockRoundUpDuration[0]?.round_up_duration_nearest;

        values.BandedActualDuration = response?.BandedDuration;

        values.clock_in_to_visit = response?.VisitMobileReminder.filter(
          clockindata => clockindata?.clock_type == 'Clock In'
        );

        values.clock_out_of_visit = response?.VisitMobileReminder.filter(
          clockoutdata => clockoutdata?.clock_type == 'Clock Out'
        );

        values.home_to_client =
          response?.ClockValidationCheck[0]?.home_to_client;

        values.client_to_client =
          response.ClockValidationCheck[0]?.client_to_client;
        values.office_to_client =
          response.ClockValidationCheck[0]?.office_to_client;
        values.client_to_office =
          response.ClockValidationCheck[0]?.client_to_office;
        values.client_to_home =
          response?.ClockValidationCheck[0]?.client_to_home;

        //New Travel Code Clock Validation Values (office_to_office, office_to_home and home_to_home)
        values.office_to_office = response.ClockValidationCheck[0]?.office_to_office;
        values.office_to_home = response.ClockValidationCheck[0]?.office_to_home;
        values.home_to_home = response.ClockValidationCheck[0]?.home_to_home;
        values.home_to_office = response.ClockValidationCheck[0]?.home_to_office;
        const myfun = (travelCode) => {
          return travelCode
            .toLowerCase()
            .split("-")
            .map(str => str.charAt(0).toUpperCase() + str.slice(1))
            .join("-");
        };
        //for clockTimeDistanceConfirmation
        const travelPayConfirmationValues = {}
        response && response.travelPayConfirmation && response.travelPayConfirmation.forEach((item) => {
          if (item?.travelCode) {
            travelPayConfirmationValues[myfun(item.travelCode)] = {
              id: item.id,
              isConsiderTravelPay: item.isConsiderTravelPay,
              travelCode: item.travelCode
            }
          }
        })
console.log(travelPayConfirmationValues,"travelPayConfirmationValues")

        // New Travel Code Travel Pay Confirmation Values for officeToOffice, officeToHome, homeToHome
        values.homeToClient =  travelPayConfirmationValues?.['Home-Client'];
        values.clientToClient = travelPayConfirmationValues?.['Client-Client'];
        values.officeToClient = travelPayConfirmationValues?.['Office-Client'];
        values.clientToOffice = travelPayConfirmationValues?.['Client-Office'];
        values.clientToHome = travelPayConfirmationValues?.['Client-Home'];
        values.officeToOffice = travelPayConfirmationValues?.['Office-Office'];
        values.officeToHome = travelPayConfirmationValues?.['Office-Home'];
        values.homeToHome = travelPayConfirmationValues?.['Home-Home'];
        values.homeToOffice = travelPayConfirmationValues?.['Home-Office'];
    
        values.schedule_travel_time =
          response.ClockValidationCheck[0].travel_time_mobile;
        values.employee_absence =
          response.ClockValidationCheck[0].employee_absence;
        values.employee_unavailability =
          response.ClockValidationCheck[0].employee_unavailability;

        values.travel_mode_restriction_serializer =
          response.travel_mode_restriction_serializer;

        values.travel_estimate_serializer =
          response?.travel_estimate_serializer;
        values.bulk_update = response.visitAutoPublish && response.visitAutoPublish[0]?.bulk_update;
        values.bulk_update_future = response.visitAutoPublish && response.visitAutoPublish[0]?.bulk_update_future;
        values.copy_scheduler = response.visitAutoPublish && response.visitAutoPublish[0]?.copy_scheduler;
        values.copy_scheduler_future = response.visitAutoPublish && response.visitAutoPublish[0]?.copy_scheduler_future;
        values.run_scheduler = response.visitAutoPublish && response.visitAutoPublish[0]?.run_scheduler;
        values.run_scheduler_future = response.visitAutoPublish && response.visitAutoPublish[0]?.run_scheduler_future;
        values.visit_pop = response.visitAutoPublish && response.visitAutoPublish[0]?.visit_pop
        values.visit_pop_future = response.visitAutoPublish && response.visitAutoPublish[0]?.visit_pop_future
        values.visitAutoPublishId = response.visitAutoPublish && response.visitAutoPublish[0]?.id
        values.drag_and_drop = response.visitAutoPublish && response.visitAutoPublish[0]?.drag_and_drop;
        values.drag_and_drop_future = response.visitAutoPublish && response.visitAutoPublish[0]?.drag_and_drop_future;
        values.days = response.visitAutoPublish[0]?.days ? response.visitAutoPublish[0]?.days : 0;
        values.regular_visit = response.visitAutoPublish && response.visitAutoPublish[0]?.regular_visit
        values.regular_visit_future = response.visitAutoPublish && response.visitAutoPublish[0]?.regular_visit_future
        setTravelName(
          response.drop_down_menu?.travel_name.map(travelname => {
            return { id: travelname, name: travelname };
          })
        );
        setUnitName(
          response.drop_down_menu?.unit_name.map(unit => {
            return { id: unit, name: unit };
          })
        );
      }
      setValues({ ...response, ...values });
      setLoading(false)
    }).catch( err => {
      setLoading(false)
    }).finally(f => setLoading(false));
  };
console.log(values,"values")
 

  const handleSubmit = async evt => {
    evt.preventDefault();
    if (textStatus === 'SAVED') {
      return;
    }
    setLoadingSave(true);
    if (
      employeeAbscenceHoursLength > 3 ||
      employeeAbscenceMinuteLength > 2 ||
      employeeUnavailabilityHoursLength > 3 ||
      employeeAbscenceHoursValue > 999 ||
      employeeUnavailabilityHoursValue > 999 ||
      employeeUnavailabilityMinuteValue > 59 ||
      employeeAbscenceMinuteValue > 59 ||
      employeeUnavailabilityMinuteLength > 2 ||
      (employeeAbscenceMinuteValue == 0 && employeeAbscenceMinuteLength < 2) ||
      (employeeUnavailabilityMinuteValue == 0 &&
        employeeUnavailabilityMinuteLength < 2)
    ) {
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    }
    if (
      actualTravelTimeHoursLength > 3 ||
      actualTravelTimeHoursValue > 999 ||
      actualTravelTimeMinuteValue > 59 ||
      actualTravelTimeMinuteLength > 2 ||
      (actualTravelTimeMinuteValue == 0 && actualTravelTimeMinuteLength < 2)
    ) {
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    }
    if (
      values.employee_absence &&
      values.employee_absence.toString().includes(':') == false
    ) {
      setErorEmployeAbscence('absence');
      setErorTimeFormatMag(
        ' Please enter hours & minutes separated with  semicolon in hh:mm format. If only hours then in hh:00 format . If only minutes then in 00:mm format . '
      );
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    }
    if (
      values.employee_unavailability &&
      values.employee_unavailability.toString().includes(':') == false
    ) {
      setErorEmployeUnavailability('unavailability');
      setErorTimeFormatMag(
        ' Please enter hours & minutes separated with  semicolon in hh:mm format. If only hours then enter in hh:00 format . If only minutes then enter in 00:mm format. '
      );
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    }
    if (
      values.adjust_travel_time &&
      values.adjust_travel_time.toString().includes(':') == false
    ) {
      setErorActualTravelTime('actualTraveltime');
      setActualTravelTimeErorTimeFormatMag(
        ' Please enter hours & minutes separated with  semicolon in hh:mm format. If only hours then in hh:00 format . If only minutes then in 00:mm format . '
      );
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    }
    if (checkBeforeSubmit) {
      setErorTimeRescrtiction('client - client time restriction');
      setErorTimeRescrtictionMag(
        ' Please  check the client - client time restriction - enter hours & minutes separated with  semicolon in hh:mm format. If only hours then enter in hh:00 format . If only minutes then enter in 00:mm format. '
      );
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    }
    if (homeCheckBeforeSubmit) {
      setHomeErorTimeRescrtiction('home - client time restriction');
      setHomeErorTimeRescrtictionMag(
        ' Please  check the home - client time restriction field - enter hours & minutes separated with  semicolon in hh:mm format. If only hours then enter in hh:00 format . If only minutes then enter in 00:mm format. '
      );
      onError();
      setErrorMessage(
        'Some of the fields have invalid or empty values. Please review.'
      );
    } else {
      const bodyData = {
        effective_date: values.effective_date,
        ClockValidationCheck: dataClockValidationCheck,
        ClockDistanceTimeVerification: dataClockDistanceTimeVerification,
        ClockRoundUpDuration: dataClockRoundUpDuration,
        VisitMobileReminder: StoredMobilReminderData,
        BandedDuration: dataBandedDuration,
        VisitProvision: dataVisitProvision,
        VisitTimeClassified: dataVisitTimeClassified,
        travel_mode_restriction_serializer:
          dataTravel_mode_restriction_serializer,
        travel_estimate_serializer: dataTravel_estimate_serializer,
        EvvSettings: {
          is_offer_approval: values.is_offer_approval,
          id: values.is_offer_approval_Id,
          is_handBack: values.is_handBack,
          is_swap: values.is_swap,
        },
        visitAutoPublish: {
          bulk_update: values.bulk_update,
          bulk_update_future: values.bulk_update_future,
          copy_scheduler: values.copy_scheduler,
          copy_scheduler_future: values.copy_scheduler_future,
          drag_and_drop: values.drag_and_drop,
          drag_and_drop_future: values.drag_and_drop_future,
          run_scheduler: values.run_scheduler,
          run_scheduler_future: values.run_scheduler_future,
          visit_pop: values.visit_pop,
          visit_pop_future: values.visit_pop_future,
          regular_visit: values.regular_visit,
          regular_visit_future: values.regular_visit_future,
          days: values.days ? Number(values.days) : 0,
          id: values.visitAutoPublishId
        },
        clockTimeDistanceConfirmation: values.clockTimeDistanceConfirmation,
        travelPayConfirmation: travel_pay_confirmation,
      };

      await editSettingEvvData(props.idSelected, bodyData)
        .then(res => {
          if (res.status === true) {
            // props.loadClientVisit();
            loadSettingEvv();
            closeModal(false)
            onSuccess();
            toast.current.show({
              severity: 'success',
              summary: 'Success',
              detail: `${res.message}`,
            });
          } else {
            onError();
            toast.current.show({
              severity: 'error',
              summary: 'Failed',
              detail: `${res.message}`,
            });
          }
        })
        .catch(onError);
    }
  };

  const onSuccess = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CHECK);
    setTextStatus(SAVED);
  };

  const onError = () => {
    setLoadingSave(false);
    setButtonIcon('');
    setButtonIcon(CROSS);
    setTextStatus(ERROR);
    // setShowError(true);
    setCheckRequires(true);
    setErrorMessage(
      'Some of the fields have invalid or empty values. Please review.'
    );
  };
  const dropDownHandleSelect1 = item => {
    setValues({ ...values, ...item });
  };
  const dropDownHandleSelect = (item, nameItem, index) => {
    values[nameItem] = item[nameItem];
    if (nameItem == 'travel_mode') {
      values.travel_mode_restriction_serializer[index].travel_mode =
        item[nameItem];
    }
    if (nameItem == 'clock_in_verfication') {
      if (item[nameItem] == 'clock_in_distance_outwith_time') {
        values.clock_in_warning =
          'Are you sure you want to clock-in now? The clock-in time is later than expected';
        values.clock_in_distance_outwith_time = true;
      } else {
        values.clock_in_distance_outwith_time = false;
      }

      if (item[nameItem] == 'clock_in_outwith_distance_within_time') {
        values.clock_in_warning =
          'Are you sure you want to clock-in now? The clock-in time is later than expected';
        values.clock_in_outwith_distance_within_time = true;
      } else {
        values.clock_in_outwith_distance_within_time = false;
      }

      if (item[nameItem] == 'clock_in_outwith_distance_outwith_time') {
        values.clock_in_warning =
          'clock-in warning - are you sure you want to clock-in now? You are outwith distance from service address and the clock-in time is late';
        values.clock_in_outwith_distance_outwith_time = true;
      } else {
        values.clock_in_outwith_distance_outwith_time = false;
      }

      if (item[nameItem] == 'clock_in_distance_within_time') {
        values.clock_in_warning =
          'Are you sure you want to clock-in now? The clock-in time is earlier than expected';
        values.clock_in_distance_within_time = true;
      } else {
        values.clock_in_distance_within_time = false;
      }

      if (item[nameItem] == null) {
        values.clock_in_warning = null;
      }
    }

    if (nameItem == 'clock_out_verfication') {
      if (item[nameItem] == 'clock_out_distance_within_time') {
        values.clock_out_warning =
          'Are you sure you want to clock-in now? The clock-in time is earlier than expected';

        values.clock_out_distance_within_time = true;
      } else {
        values.clock_out_distance_within_time = false;
      }

      if (item[nameItem] == 'clock_out_distance_outwith_time') {
        values.clock_out_warning =
          'clock-out warning - are you sure you want to clock-out now? You are outwith distance from service address and the clock-out time is late';
        values.clock_out_distance_outwith_time = true;
      } else {
        values.clock_out_distance_outwith_time = false;
      }
      if (item[nameItem] == 'clock_out_outwith_distance_within_time') {
        values.clock_out_warning =
          'clock-in warning - are you sure you want to clock-in now? You are outwith distance from service address and the clock-in time is early';
        values.clock_out_outwith_distance_within_time = true;
      } else {
        values.clock_out_outwith_distance_within_time = false;
      }
      if (item[nameItem] == 'clock_out_outwith_distance_outwith_time') {
        values.clock_out_warning =
          'Are you sure you want to clock-in now? The clock-in time is earlier than expected';
        values.clock_out_outwith_distance_outwith_time = true;
      } else {
        values.clock_out_outwith_distance_outwith_time = false;
      }
      if (item[nameItem] == null) {
        values.clock_out_warning = null;
      }
    }

    setValues({ ...values });
  };

  const addNewBandedActualDurations = () => {
    if (!values.BandedActualDuration) values.BandedActualDuration = [];
    values.BandedActualDuration.push({});
    setValues({ ...values });
  };

  const deleteBandedActualDurations = (data, index) => {
    // toDeleteVisitEmployee.push({...data});
    if (values.BandedActualDuration && values.BandedActualDuration.length > 0) {
      values.BandedActualDuration.splice(index, 1);
    }
    setValues({ ...values });
  };

  const addTravelModeRestriction = () => {
    if (!values.travel_mode_restriction_serializer)
      values.travel_mode_restriction_serializer = [];
    values.travel_mode_restriction_serializer.push({});
    setValues({ ...values });
  };

  const deleteTravelModeRestriction = (data, index) => {
    if (
      values.travel_mode_restriction_serializer &&
      values.travel_mode_restriction_serializer.length > 0
    ) {
      values.travel_mode_restriction_serializer.splice(index, 1);
    }
    setValues({ ...values });
  };

  const addClockInToVisit = () => {
    if (!values.clock_in_to_visit) values.clock_in_to_visit = [];
    values.clock_in_to_visit.push({});
    setValues({ ...values });
  };

  const deleteClockInToVisit = (data, index) => {
    if (values.clock_in_to_visit && values.clock_in_to_visit.length > 0) {
      values.clock_in_to_visit.splice(index, 1);
    }
    setValues({ ...values });
  };
  const deleteAutoVerification = (data, index) => {
    if (
      values.clockTimeDistanceConfirmation &&
      values.clockTimeDistanceConfirmation.length > 0
    ) {
      values.clockTimeDistanceConfirmation.splice(index, 1);
    }
    setValues({ ...values });
  };
  const addClockOutToVisit = () => {
    if (!values.clock_out_of_visit) values.clock_out_of_visit = [];
    values.clock_out_of_visit.push({});
    setValues({ ...values });
  };

  const deleteClockOutToVisit = (data, index) => {
    if (values.clock_out_of_visit && values.clock_out_of_visit.length > 0) {
      values.clock_out_of_visit.splice(index, 1);
    }
    setValues({ ...values });
  };

  const setHoursMinute = () => {
    var EmployeeAbscence =
      values.employee_absence && values.employee_absence.toString().split(':');
    if (
      EmployeeAbscence &&
      EmployeeAbscence[0] &&
      EmployeeAbscence[0] !== undefined
    ) {
      setEmployeeAbscenceHoursLength(EmployeeAbscence[0].length);
      setEmployeeAbscenceHoursValue(EmployeeAbscence[0]);
    }
    if (
      EmployeeAbscence &&
      EmployeeAbscence[1] &&
      EmployeeAbscence[1] !== undefined
    ) {
      setEmployeeAbscenceMinuteLength(EmployeeAbscence[1].length);
      setEmployeeAbscenceMinuteValue(EmployeeAbscence[1]);
    }

    var EmployeeUnavailability =
      values.employee_unavailability &&
      values.employee_unavailability.toString().split(':');
    if (
      EmployeeUnavailability &&
      EmployeeUnavailability[0] &&
      EmployeeUnavailability[0] !== undefined
    ) {
      setEmployeeUnavailabilityHoursLength(EmployeeUnavailability[0].length);
      setEmployeeUnavailabilityHoursValue(EmployeeUnavailability[0]);
    }
    if (
      EmployeeUnavailability &&
      EmployeeUnavailability[1] &&
      EmployeeUnavailability[1] !== undefined
    ) {
      setEmployeeUnavailabilityMinuteLength(EmployeeUnavailability[1].length);
      setEmployeeUnavailabilityMinuteValue(EmployeeUnavailability[1]);
    }

    var actualTimeTravel =
      values.adjust_travel_time &&
      values.adjust_travel_time.toString().split(':');
    if (
      actualTimeTravel &&
      actualTimeTravel[0] &&
      actualTimeTravel[0] !== undefined
    ) {
      setActualTravelTimeHoursLength(actualTimeTravel[0].length);
      setActualTravelTimeHoursValue(actualTimeTravel[0]);
    }
    if (
      actualTimeTravel &&
      actualTimeTravel[1] &&
      actualTimeTravel[1] !== undefined
    ) {
      setActualTravelTimeMinuteLength(actualTimeTravel[1].length);
      setActualTravelTimeMinuteValue(actualTimeTravel[1]);
    }

    if (
      values.employee_absence &&
      values.employee_absence.toString().includes(':')
    ) {
      setErorEmployeAbscence('');
      setErorTimeFormatMag(null);
    }

    if (
      values.employee_unavailability &&
      values.employee_unavailability.toString().includes(':')
    ) {
      setErorEmployeUnavailability('');
      setErorTimeFormatMag(null);
    }

    if (
      values.adjust_travel_time &&
      values.adjust_travel_time.toString().includes(':')
    ) {
      setErorActualTravelTime('');
      setActualTravelTimeErorTimeFormatMag(null);
    }
  };

  const addNewTravelTimeEstimate = () => {
    if (!values.travel_estimate_serializer)
      values.travel_estimate_serializer = [];
    values.travel_estimate_serializer.push({});
    setValues({ ...values });
  };

  const deleteTravelTimeEstimate = (data, index) => {
    // toDeleteVisitEmployee.push({...data});
    if (
      values.travel_estimate_serializer &&
      values.travel_estimate_serializer.length > 0
    ) {
      values.travel_estimate_serializer.splice(index, 1);
    }
    setValues({ ...values });
  };

  const dropDownHandleSelectTravelEstimate = (
    item,
    nameItem,
    TraveEstimate,
    index
  ) => {
    values[TraveEstimate][index][nameItem] = item[nameItem];
    setValues({ ...values });
  };
  const handleAddConfirmation = () => {
    if (!values.clockTimeDistanceConfirmation) {
      values.clockTimeDistanceConfirmation = [];
      setValues({ ...values });
    }

    values.clockTimeDistanceConfirmation.push({});
    setValues({ ...values });
  };

  const positionOnly = (val) => {
    const re = /^\d*$/
    if (re.test(val)) {
      return val
    }
  }

  const handleIncrement = (field) => {
    const cur = values.days || 0
    const fur = values.future_days || 0
    if (field == "current_days") {
      setValues({ ...values, days: positionOnly(cur + 1) })
    } else {
      setValues({ ...values, future_days: positionOnly(fur + 1) })
    }
  }

  const handleDecrement = (field) => {
    const cur = values.days || 0
    const fur = values.future_days || 0
    if (field == "current_days") {
      setValues({ ...values, days: positionOnly(cur - 1) })
    } else {
      setValues({ ...values, future_days: positionOnly(fur - 1) })
    }
  }

  return (
    <div>
      <ModalContainer max_width={'92%'}>
        <ModalDynamic
          fontSize={'larger'}
          {...props.fullBind}
          minWidthTitle="8.5rem"
          loading={loading}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 15,
            }}>
            <CustomHeaderLabel
              rows={[{ label: 'Branch:', value: branchIdName }]}
            />
          </div>
          <>
            <div className="mt-2  mb-1 pt-1 ms-1">
              <TitleSection style={{ marginLeft: '15px', fontSize: 'larger' }}>
                Effective Date
              </TitleSection>

              <FlexGrid3Item
                className="ps-1 pe-0 pe-md-1 pt-1"
                style={{ width: '24%', marginLeft: '10px' }}>
                <DateSelector
                  max_width={'20%'}
                  //   disabled={!checkUpdatePermission}
                  type="text"
                  {...useInput(`effective_date`)}
                />
              </FlexGrid3Item>
            </div>
            <FlexGrid3Container className={'w-100 mb-1 mt-1 '}>
              <FlexGrid3Item className="  pe-0 pe-md-1 pt-1 me-4 '">
                <div className=" mt-1 d-flex justify-content-start ms-3 ">
                  <div className=" ">
                    <TitleSection
                      style={{
                        marginBottom: '15px',
                        fontSize: 'larger',
                      }}>
                      Clock-in
                    </TitleSection>
                  </div>
                </div>
              </FlexGrid3Item>
              <FlexGrid3Item className=" pe-md-1 pt-0 ms-8 me-0 ms-4 ">
                <div className=" mt-1 d-flex justify-content-center ms-1">
                  <div className=" ">
                    {' '}
                    <TitleSection style={{ fontSize: 'larger' }}>
                      Clock-out{' '}
                    </TitleSection>
                  </div>
                </div>
              </FlexGrid3Item>
              <FlexGrid3Item></FlexGrid3Item>
            </FlexGrid3Container>
            <div
              className="d-md-flex align-items-left"
              style={{ marginLeft: '15px', marginRight: '15px' }}>
              <div className="w-100 me-3">
                <InputLabelTop
                  //   disabled={!checkUpdatePermission}
                  type="number"
                  id="verification_distance_clock_in"
                  label={'Distance (meters)'}
                  onKeyPress={KeyPress.DECIMAL}
                  {...useInput(`verification_distance_clock_in`)}
                  maxlength={4}
                />
              </div>
              <div className="w-100 me-2 mb-2">
                <MultiDropDownSearchable
                  isCreate={false}
                  // to add items not present in the options(dropdown items)
                  isMulti={true}
                  placeHolder={'  Time '}
                  options={[
                    { id: 'Early', name: 'Early', value: 'Early' },
                    { id: 'Late', name: 'Late', value: 'Late' },
                    { id: 'On Time', name: 'On Time', value: 'On Time' },
                  ]}
                  selectedOptionsProps={values.clock_in_time_state}
                  selectedOption={values.clock_in_time_state}
                  onChange={value =>
                    dropDownHandleSelect1({
                      clock_in_time_state: value ? value : null,
                    })
                  }
                />
              </div>
              <div className="w-100 me-2" style={{ marginLeft: '5px' }}>
                <InputLabelTop
                  //   disabled={!checkUpdatePermission}
                  type="text"
                  id="verification_distance"
                  label={'Distance  (meters)'}
                  onKeyPress={KeyPress.DECIMAL}
                  {...useInput(`verification_distance_clock_out`)}
                  maxlength={4}
                />
              </div>
              <div className="w-100 mb-2" style={{ marginRight: '15px' }}>
                <MultiDropDownSearchable
                  isCreate={false}
                  // to add items not present in the options(dropdown items)
                  isMulti={true}
                  options={[
                    { id: 'Early', name: 'Early', value: 'Early' },
                    { id: 'Late', name: 'Late', value: 'Late' },
                    { id: 'On Time', name: 'On Time', value: 'On Time' },
                  ]}
                  placeHolder={'Time'}
                  selectedOptionsProps={values.clock_out_time_state}
                  selectedOption={values.clock_out_time_state}
                  onChange={value =>
                    dropDownHandleSelect1({
                      clock_out_time_state: value ? value : null,
                    })
                  }
                />
              </div>
            </div>
            <div className="mt-3 mb-3">
              <div className="mt-2 pt-1">
                <TitleSection style={{ marginLeft: '15px', fontSize: 'larger' }}>
                  Auto EVV Verfication
                </TitleSection>
              </div>
              {values.clockTimeDistanceConfirmation &&
                values.clockTimeDistanceConfirmation.length >= 1 &&
                values.clockTimeDistanceConfirmation.map((data, index) => {
                  return (
                    <EvvConfimation
                      index={index}
                      data={data}
                      contextChoices={contextChoices}
                      setValues={setValues}
                      namesection={'clockTimeDistanceConfirmation'}
                      useInput={useInput}
                      values={values}
                      addNewTravelTimeEstimate={addNewTravelTimeEstimate}
                      deleteAutoVerification={deleteAutoVerification}
                      travelModeOptions={travelModeOptions}
                      travelName={travelName}
                      unitName={unitName}
                      dropDownHandleSelectTravelEstimate={
                        dropDownHandleSelectTravelEstimate
                      }
                    />
                  );
                })}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-start'
              }
              style={{ marginLeft: '15px' }}>
              <div className={'mt-2'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={handleAddConfirmation}>
                  <span>{PLUS}Auto Evv Confirmation</span>
                </PrimaryButtonForm>
              </div>
            </div>
            {/* <div className="mt-3  mb-1 pt-1 ms-1" style={{marginLeft: '15px'}}>
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                <div className=" mt-1 d-flex justify-content-start ms-3 " >

                  <small>
                    <b>
                      <TitleSection
                        style={{marginLeft: '15px', fontSize: 'larger'}}>
                        Clock-in Method
                      </TitleSection>
                    </b>{' '}
                  </small>
                  </div>
                  <div className=" mt-1 d-flex">
                    <div className=" ">
                      <small>
                        {' '}
                        <TitleSection style={{marginLeft: '15px'}}>
                          Manual
                        </TitleSection>
                      </small>
                    </div>
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('is_manual_clock_in')}
                        id="is_manual_clock_in"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>

                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1  me-2" >
                <div className=" mt-1 d-flex justify-content-start  " >
                <div className=" ">
                  <small>
                    <b>
                      <TitleSection
                        style={{ fontSize: 'larger'}}>
                        Clock-out Method
                      </TitleSection>
                    </b>{' '}
                  </small>
                  </div>
                  </div>
                  <div className=" mt-1 d-flex">
                    <div className=" ">
                      <small>
                        {' '}
                        <TitleSection style={{marginLeft: '236px'}}>
                          Manual{' '}
                        </TitleSection>
                      </small>
                    </div>
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('is_manual_clock_out')}
                        id="is_manual_clock_out"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>
            </div> */}

            <div
              className="d-flex flex-row justify-content-between pt-2 mt-2 "
              style={{ width: '40%' }}>
              <div className="me-2  w-30 " style={{ marginLeft: '10px' }}>
                <TitleSection
                  style={{ fontSize: 'larger', marginLeft: '15px' }}
                  className=" ms-2  ">
                  Clock-in Method
                </TitleSection>
                <div className="d-flex flex-row justify-content-center pt-2 mt-2 ms-2 ">
                  <TitleSection>Manual </TitleSection>{' '}
                  <div className="   d-flex  justify-content-center  ms-2 ">
                    <ToggleButton
                      useInput={useInput('is_manual_clock_in')}
                      id="is_manual_clock_in"
                    />
                  </div>
                </div>
              </div>
              <div>
                <TitleSection style={{ fontSize: 'larger' }} className="  w-30 ">
                  Clock-out Method
                </TitleSection>
                <div className="d-flex flex-row justify-content-center pt-2 mt-2 ms-2 ">
                  <TitleSection>Manual </TitleSection>{' '}
                  <div className="   d-flex  justify-content-center  ms-2 ">
                    <ToggleButton
                      useInput={useInput('is_manual_clock_out')}
                      id="is_manual_clock_out"
                    />
                  </div>
                </div>
              </div>
            </div>

            <TitleSection
              style={{ fontSize: 'larger', marginLeft: '17px' }}
              className="  w-30 ">
              Auto clock-out rules
            </TitleSection>
            <div
              className="d-flex flex-row justify-content-between pt-2 mt-2 ps-2"
              style={{
                marginLeft: '15px',
                marginRight: '1.4rem',
              }}>
              <div className="d-flex flex-row " style={{ width: '49.6%' }}>
                <InputTextArea
                  height={'2.5rem'}
                  //   disabled={!checkUpdatePermission}
                  type="text"
                  // id="verification_distance"
                  label={'text-warning'}
                  {...useInput(`incorporate_auto_clock_out_rule_message`)}
                // maxlength={4}
                />
              </div>

              <div className="d-flex flex-row  " style={{ width: '49%' }}>
                {' '}
                <TitleSection style={{ fontSize: 'ligther' }}>
                  Incorporate auto-clock out rule
                </TitleSection>
                <div style={{ marginLeft: '2rem' }}>
                  <ToggleButton
                    useInput={useInput('incorporate_auto_clock_out_rule')}
                    id="incorporate_auto_clock_out_rule"
                  />
                </div>
              </div>
            </div>

            <div className="mt-3  mb-1 pt-2 ms-1" style={{ marginLeft: '15px' }}>
              <TitleSection style={{ marginLeft: '15px', fontSize: 'larger' }}>
                A visit is classified as Early or Late Start/End after the
                following time differences from scheduled start/end times:
              </TitleSection>
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ">
                      <small>
                        {' '}
                        <TitleSection
                          style={{
                            marginLeft: '15px',
                            marginBottom: '20px',
                            fontSize: 'larger',
                          }}>
                          Visit Start Late/Early
                        </TitleSection>
                      </small>
                    </div>
                  </div>
                </FlexGrid3Item>

                <FlexGrid3Item className=" pe-md-1 pt-0 ms-8 me-0 ms-4 ">
                  <div className=" mt-1 d-flex justify-content-center ">
                    {' '}
                    <div className="  ">
                      <small>
                        {' '}
                        <TitleSection
                          style={{ fontSize: 'larger', marginLeft: '7rem' }}>
                          Visit End Late/Early
                        </TitleSection>
                      </small>
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>
              <div
                className="d-md-flex align-items-center"
                style={{ marginLeft: '15px', marginRight: '15px' }}>
                <div className="w-100 me-2">
                  <TimeSelector
                    labelTop={'Visit Early Start'}
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                    {...useInput('visit_early_start')}
                    placeholderText={'hh:mm'}
                    locale={'sv-sv'}
                    timeCaption={'Duration'}
                  />
                </div>
                <div className="w-100 me-2">
                  <TimeSelector
                    labelTop={'Visit Late Start'}
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                    {...useInput('visit_late_start')}
                    placeholderText={'hh:mm'}
                    locale={'sv-sv'}
                    timeCaption={'Duration'}
                  />
                </div>
                <div className="w-100 me-2" style={{ marginLeft: '5px' }}>
                  <TimeSelector
                    labelTop={'Visit Early End'}
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                    {...useInput('visit_early_end')}
                    placeholderText={'hh:mm'}
                    locale={'sv-sv'}
                    timeCaption={'Duration'}
                  />
                </div>
                <div className="w-100 me-2">
                  <TimeSelector
                    labelTop={'Visit Late End'}
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                    {...useInput('visit_late_end')}
                    placeholderText={'hh:mm'}
                    locale={'sv-sv'}
                    timeCaption={'Duration'}
                  />
                </div>
              </div>
            </div>
            {/* <div className="mt-3  mb-1 pt-3 ms-1">
              <small>
                {' '}
                <TitleSection style={{marginLeft: '15px', fontSize: 'larger'}}>
                  Visit Status
                </TitleSection>
              </small>
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item
                  className="pe-1  pe-0 pe-md-1 pt-1"
                  style={{marginRight: '-5.8rem'}}>
                  <div className=" ">
                    <small>
                      {' '}
                      <TitleSection
                        style={{
                          marginLeft: '15px',
                          marginBottom: '20px',
                          marginRight: '-2rem',
                        }}>
                        Not clocked-in and not clocked-out
                      </TitleSection>
                    </small>
                  </div>
                </FlexGrid3Item>
                <FlexGrid3Item
                  className="pe-1  pe-0 pe-md-1 pt-1"
                  style={{marginRight: '-7rem'}}>
                  <div className=" ">
                    <small>
                      {' '}
                      <TitleSection
                        style={{
                          marginBottom: '20px',
                        }}>
                        Clocked-in but not clocked-out
                      </TitleSection>
                    </small>
                  </div>
                </FlexGrid3Item>
                <FlexGrid3Item
                  className="pe-1  pe-0 pe-md-1 pt-1"
                  style={{marginRight: '-7rem'}}>
                  <div className=" ">
                    <small>
                      {' '}
                      <TitleSection
                        style={{
                          marginBottom: '20px',
                        }}>
                        Clocked-in and clocked-out
                      </TitleSection>
                    </small>
                  </div>
                </FlexGrid3Item>

                <FlexGrid3Item
                  className="pe-1  pe-0 pe-md-1 pt-1"
                  style={{marginRight: '-8rem'}}>
                  <div className="  ">
                    <small>
                      {' '}
                      <TitleSection
                        style={{
                          marginBottom: '20px',
                        }}>
                        If not clocked-in by xx hh:mm after
                      </TitleSection>
                    </small>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>
              <div
                className="d-md-flex align-items-center"
                style={{marginLeft: '15px', marginRight: '15px'}}>
                <div className="w-100 me-2">
                  <InputLabelTop
                    disabled={true}
                    type="text"
                    label={'Not Started'}
                    // {...useInput(`visit_status_name_not_started`) }
                    value={vistssStatusNotStatrted}
                  />
                </div>
                <div className="w-100 me-2">
                  <InputLabelTop
                    disabled={true}
                    type="text"
                    label={'In Progress'}
                    value={vistsStatusInProgress}
                  />
                </div>
                <div className="w-100 me-2" style={{marginLeft: '5px'}}>
                  <InputLabelTop
                    disabled={true}
                    type="text"
                    label={'Completed'}
                    value={vistsStatusCompleted}
                  />
                </div>
                <div className="w-100 me-2" style={{marginRight: '35px'}}>
                  <InputLabelTop
                    disabled={true}
                    type="text"
                    label={'Missed'}
                    value={vistsStatusMissed}
                  />
                </div>
              </div>
            </div> */}

            <TitleSection
              style={{
                marginLeft: '15px',
                fontSize: 'larger',
                marginBottom: '-1rem',
                marginTop: '2rem',
              }}>
              Visit Status
            </TitleSection>

            <div
              className="d-flex flex-row justify-content-around pt-2 mt-2 w-100"
              style={{ marginLeft: '-1rem' }}>
              <div
                style={{
                  width: '24%',
                  marginRight: '-1rem',
                  marginLeft: '28px',
                }}
                className="pt-2 mt-2">
                <TitleSection style={{ marginBottom: '1.5rem' }}>
                  Not clocked-in and not clocked-out
                </TitleSection>

                <InputLabelTop
                  disabled={true}
                  type="text"
                  label={'Not Started'}
                  // {...useInput(`visit_status_name_not_started`) }
                  value={vistssStatusNotStatrted}
                />
              </div>
              <div
                style={{ width: '24%', marginLeft: '20px', marginRight: '-1rem' }}
                className="pt-2 mt-2">
                <TitleSection style={{ marginBottom: '1.5rem' }}>
                  Clocked-in but not clocked-out
                </TitleSection>

                <InputLabelTop
                  disabled={true}
                  type="text"
                  label={'In Progress'}
                  value={vistsStatusInProgress}
                />
              </div>
              <div
                style={{ width: '24%', marginLeft: '20px' }}
                className="pt-2 mt-2">
                <TitleSection style={{ marginBottom: '1.5rem' }}>
                  Clocked-in and clocked-out
                </TitleSection>
                <InputLabelTop
                  disabled={true}
                  type="text"
                  label={'Completed'}
                  value={vistsStatusCompleted}
                />
              </div>
              <div
                style={{ width: '24%', marginLeft: '6px' }}
                className="pt-2 mt-2">
                <TitleSection style={{ marginBottom: '1.5rem' }}>
                  {' '}
                  If not clocked-in by xx hh:mm after
                </TitleSection>
                <InputLabelTop
                  disabled={true}
                  type="text"
                  label={'Missed'}
                  value={vistsStatusMissed}
                />
              </div>
            </div>

            <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
              <div
                className="mt-3  mb-1 pt-2 ms-1"
                style={{ marginLeft: '15px' }}>
                {' '}
                <div className=" ">
                  <small>
                    {' '}
                    <TitleSection
                      style={{
                        marginLeft: '15px',
                        marginBottom: '15px',
                        fontSize: 'larger',
                      }}>
                      A visit is classified as Under or Over Provision after the
                      following duration difference from scheduled durations:
                    </TitleSection>
                  </small>
                </div>
              </div>
            </FlexGrid3Container>
            <div
              className="d-md-flex align-items-center"
              style={{ marginLeft: '14px', width: '74%' }}>
              <div
                className="w-100 me-2"
                style={{ marginBottom: '10px', marginTop: '3px' }}>
                <DropdownSearchable
                  placeHolder={'Time Unit'}
                  options={[
                    { id: 'Fixed', name: 'Fixed' },
                    { id: 'Percent', name: 'Percent' },
                  ]}
                  selectedOption={values.time_unit ? values.time_unit : null}
                  onChange={value =>
                    dropDownHandleSelect(
                      { time_unit: value ? value : null },
                      'time_unit'
                    )
                  }
                />
              </div>

              {values.time_unit === 'Fixed' ? (
                <div
                  className="w-100 me-2"
                  style={{ marginLeft: '2px', marginBottom: '7px' }}>
                  <TimeSelector
                    labelTop={'Underprovision'}
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                    {...underPro}
                    placeholderText={'hh:mm'}
                    locale={'sv-sv'}
                    timeCaption={'Duration'}
                  />
                </div>
              ) : (
                <div className="w-100 me-2" style={{ marginLeft: '2px' }}>
                  <InputLabelTop
                    label={'Underprovision'}
                    type="number"
                    id="under_provision"
                    // placeholder={'hh:mm'}
                    {...underProvisionRation}
                  />

                  <small>
                    {values.under_provision >= 100 ? (
                      <p style={{ color: 'red' }}>
                        Under Provision should be less than 100
                      </p>
                    ) : null}
                  </small>
                </div>
              )}
              {values.time_unit === 'Fixed' ? (
                <div
                  className="w-100 me-2"
                  style={{ marginLeft: '3px', marginBottom: '5px' }}>
                  <TimeSelector
                    labelTop={'Overprovision'}
                    timeFormat={'HH:mm'}
                    dateFormat={'HH:mm'}
                    {...overPro}
                    placeholderText={'hh:mm'}
                    locale={'sv-sv'}
                    timeCaption={'Duration'}
                  />
                </div>
              ) : (
                <div className="w-100 me-2" style={{ marginLeft: '5px' }}>
                  <InputLabelTop
                    label={'Overprovision'}
                    type="number"
                    id="under_provision"
                    // placeholder={'hh:mm'}
                    {...overProvisionRatio}
                  />
                  <small>
                    {values.over_provision && values.over_provision <= 100 ? (
                      <p style={{ color: 'red' }}>
                        Overprovision should be grater then 100{' '}
                      </p>
                    ) : null}
                  </small>
                </div>
              )}
            </div>
            <div className="mt-3  mb-1 pt-1 ms-1">
              <small>
                <b>
                  <TitleSection
                    style={{ marginLeft: '15px', fontSize: 'larger' }}>
                    Rounding settings on actual durations
                  </TitleSection>
                </b>{' '}
              </small>
              <div className=" mt-1 d-flex">
                <div className=" ">
                  <small>
                    {' '}
                    <TitleSection style={{ marginLeft: '15px' }}>
                      Do not round - actual times only
                    </TitleSection>
                  </small>
                </div>
                <div className=" ps-1  ms-1">
                  <ToggleButton
                    useInput={useInput('round_up_validation')}
                    id="round_up_validation"
                  />
                </div>
              </div>

              <div
                className="d-flex flex-row justify-content-between  "
                style={{ marginLeft: '1rem', marginRight: '4rem' }}>
                <div
                  className="d-flex flex-row justify-content-between  "
                  style={{ width: '52%' }}>
                  <div>
                    <TitleSection style={{ marginBottom: '2rem' }}>
                      {' '}
                      Round up duration to nearest
                    </TitleSection>
                    <div className="d-flex flex-row" style={{ width: '138%' }}>
                      <div >
                        <TimeSelector
                          labelTop={'Round up duration to nearest'}
                          timeFormat={'HH:mm'}
                          dateFormat={'HH:mm'}
                          {...useInput('round_up_duration_nearest')}
                          placeholderText={'hh:mm'}
                          locale={'sv-sv'}
                          timeCaption={'Duration'}
                        />
                      </div>
                      <div style={{ marginLeft: '1rem' }}>
                        <ToggleButton
                          useInput={useInput(
                            'round_up_duration_nearest_validation'
                          )}
                          id="round_up_duration_nearest_validation"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="d-flex flex-row justify-content-between  "
                  style={{ width: '48%' }}>
                  <div>
                    <TitleSection style={{ marginBottom: '2rem' }}>
                      {' '}
                      Round down duration to nearest
                    </TitleSection>
                    <div className="d-flex flex-row" style={{ width: '190%' }}>
                      <div className="d-flex flex-row   ">
                        <TimeSelector
                          labelTop={'Round down duration to nearest<'}
                          timeFormat={'HH:mm'}
                          dateFormat={'HH:mm'}
                          {...useInput('round_down_duration_nearest')}
                          placeholderText={'hh:mm'}
                          locale={'sv-sv'}
                          timeCaption={'Duration'}
                        />
                      </div>
                      <div style={{ marginLeft: '1rem' }}>
                        <ToggleButton
                          useInput={useInput(
                            'round_down_duration_nearest_validation'
                          )}
                          id="round_down_duration_nearest_validation"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3  mb-1 pt-1 ms-1">
              <small>
                <b>
                  <TitleSection
                    style={{ marginLeft: '15px', fontSize: 'larger' }}>
                    Missed visit status categorisation
                  </TitleSection>
                </b>{' '}
              </small>
              <div
                className="mt-3  mb-1 pt-1 ms-1"
                style={{ marginLeft: '15px' }}>
                <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                  <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                    <small>
                      <b>
                        <TitleSection style={{ marginLeft: '15px' }}>
                          If a visit has passed xx number of hh:mm after
                          scheduled visit start time
                        </TitleSection>
                      </b>{' '}
                    </small>
                    <FlexGrid3Item
                      className="pe-1  pe-0 pe-md-1 pt-1"
                      style={{ marginLeft: '15px', width: '73%' }}>
                      {' '}
                      <TimeSelector
                        timeFormat={'HH:mm'}
                        dateFormat={'HH:mm'}
                        {...useInput('visit_missed_status_time')}
                        placeholderText={'hh:mm'}
                        locale={'sv-sv'}
                        timeCaption={'Duration'}
                      />
                    </FlexGrid3Item>
                  </FlexGrid3Item>
                  <FlexGrid3Item
                    className="pe-1  pe-0 pe-md-1 pt-1"
                    style={{ marginLeft: '13.1rem' }}>
                    <small>
                      <b>
                        <TitleSection style={{ marginLeft: '15px' }}>
                          If a visit has passed and the next client visit has
                          been reached then classify as missed
                        </TitleSection>
                      </b>{' '}
                    </small>
                    <FlexGrid3Item
                      className="pe-1  pe-0 pe-md-1 pt-1"
                      style={{ marginLeft: '15px', width: '100.5%' }}>
                      {' '}
                      <ToggleButton
                        useInput={useInput('visit_missed_status')}
                        id="visit_missed_status"
                      />
                    </FlexGrid3Item>
                  </FlexGrid3Item>
                </FlexGrid3Container>
              </div>
            </div>
            <div className="mt-3 mb-3">
              <div className="mt-1">
                {' '}
                <small>
                  {' '}
                  <TitleSection
                    style={{ marginLeft: '15px', fontSize: 'larger' }}>
                    Banded actual durations
                  </TitleSection>
                </small>
              </div>
              {values.BandedActualDuration &&
                values.BandedActualDuration.length >= 1 &&
                values.BandedActualDuration.map((data, index) => {
                  return (
                    <BandedActualDurations
                      index={index}
                      data={data}
                      setValues={setValues}
                      useInput={useInput}
                      values={values}
                      addNewBandedActualDurations={addNewBandedActualDurations}
                      deleteBandedActualDurations={deleteBandedActualDurations}
                    />
                  );
                })}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-start'
              }
              style={{ marginLeft: '15px' }}>
              <div className={'mt-2'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={addNewBandedActualDurations}>
                  <span>{PLUS} Banded Actual Durations</span>
                </PrimaryButtonForm>
              </div>
            </div>
            <div className="mt-3  pt-2  mb-2">
              <div className="mt-1 ">
                {' '}
                <small>
                  <b>
                    <TitleSection
                      style={{ marginLeft: '15px', fontSize: 'larger' }}>
                      Reminders / Notifications to mobile
                    </TitleSection>
                  </b>{' '}
                </small>
              </div>
              <div className="mt-1 ">
                {' '}
                <small>
                  {' '}
                  <TitleSection
                    style={{ marginLeft: '15px', fontSize: 'larger' }}>
                    Clock-in to visit
                  </TitleSection>
                </small>
              </div>
              {values.clock_in_to_visit &&
                values.clock_in_to_visit.length >= 1 &&
                values.clock_in_to_visit.map((data, index) => {
                  return (
                    <ClockInToVisit
                      index={index}
                      data={data}
                      setValues={setValues}
                      useInput={useInput}
                      values={values}
                      addClockInToVisit={addClockInToVisit}
                      deleteClockInToVisit={deleteClockInToVisit}
                    />
                  );
                })}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-start'
              }
              style={{ marginLeft: '15px' }}>
              <div className={'mt-1 mb-1'}>
                <PrimaryButtonForm minWidth="6rem" onClick={addClockInToVisit}>
                  <span>{PLUS} Clock-in to visit</span>
                </PrimaryButtonForm>
              </div>
            </div>
            <div className="mt-2 pt-1 mb-2">
              <div className="mt-2">
                <small>
                  {' '}
                  <TitleSection
                    style={{ marginLeft: '15px', fontSize: 'larger' }}>
                    Clock-out to visit
                  </TitleSection>
                </small>
              </div>
              {values.clock_out_of_visit &&
                values.clock_out_of_visit.length >= 1 &&
                values.clock_out_of_visit.map((data, index) => {
                  return (
                    <ClockOutOfVisit
                      index={index}
                      data={data}
                      setValues={setValues}
                      useInput={useInput}
                      values={values}
                      addClockOutToVisit={addClockOutToVisit}
                      deleteClockOutToVisit={deleteClockOutToVisit}
                    />
                  );
                })}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-start'
              }
              style={{ marginLeft: '15px' }}>
              <div className={'mt-2'}>
                <PrimaryButtonForm minWidth="6rem" onClick={addClockOutToVisit}>
                  <span>{PLUS}Clock-out of visit</span>
                </PrimaryButtonForm>
              </div>
            </div>

            {/* For Travel Time Value ( Home-Client , Office-Client and so on) */}
            <div className="mt-3  mb-1 pt-2 ms-1">
              {/* Heading */}
              <small>
                <b>
                  <TitleSection
                    style={{ marginLeft: '15px', fontSize: 'larger' }}>
                    Which travel times should be included in the scheduler
                    travel time value:
                  </TitleSection>

                  <TitleSection
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '-1.2rem',
                      marginLeft: '10rem',
                      fontSize: 'larger',
                    }}>
                    Travel Pay Confirmation
                  </TitleSection>
                </b>{' '}
              </small>

              {/* Home-Client */}
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item>
                  <div style={{ marginLeft: '15px' }}>
                    <small>
                      <b>Home-Client</b>{' '}
                    </small>
                  </div>
                </FlexGrid3Item>

                <FlexGrid3Item
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: '-9rem',
                  }}>
                  <ToggleButton
                    useInput={useInput('home_to_client')}
                    id="home_to_client"
                  />
                </FlexGrid3Item>

                <FlexGrid3Item style={{ marginRight: '9rem' }}>
                  <div className=" mt-1 d-flex">
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('homeToClient.isConsiderTravelPay')}
                        id="homeToClient"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>

              {/* Client-Client */}
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <small>
                    <b>
                      <TitleSection style={{ marginLeft: '15px' }}>
                        Client-Client
                      </TitleSection>
                    </b>{' '}
                  </small>
                </FlexGrid3Item>
                <FlexGrid3Item
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: '-9rem',
                  }}>
                  <ToggleButton
                    useInput={useInput('client_to_client')}
                    id="client_to_client"
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('clientToClient.isConsiderTravelPay')}
                        id="clientToClient"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>

              {/* Office-Client */}
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <small>
                    <b>
                      <TitleSection style={{ marginLeft: '15px' }}>
                        Office-Client
                      </TitleSection>
                    </b>{' '}
                  </small>
                </FlexGrid3Item>
                <FlexGrid3Item
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: '-9rem',
                  }}>
                  <ToggleButton
                    useInput={useInput('office_to_client')}
                    id="office_to_client"
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('officeToClient.isConsiderTravelPay')}
                        id="officeToClient"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>

              {/* Client-Office */}
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <small>
                    <b>
                      <TitleSection style={{ marginLeft: '15px' }}>
                        Client-Office
                      </TitleSection>
                    </b>{' '}
                  </small>
                </FlexGrid3Item>
                <FlexGrid3Item
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: '-9rem',
                  }}>
                  <ToggleButton
                    useInput={useInput('client_to_office')}
                    id="client_to_office"
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('clientToOffice.isConsiderTravelPay')}
                        id="clientToOffice"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>

              {/* Client-Home */}
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <small>
                    <b>
                      <TitleSection style={{ marginLeft: '15px' }}>
                        Client-Home
                      </TitleSection>
                    </b>{' '}
                  </small>
                </FlexGrid3Item>
                <FlexGrid3Item
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: '-9rem',
                  }}>
                  <ToggleButton
                    useInput={useInput('client_to_home')}
                    id="client_to_home"
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('clientToHome.isConsiderTravelPay')}
                        id="clientToHome"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>

              {/* New Travel Code Added */}

              {/* Office-Office */}
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <small>
                    <b>
                      <TitleSection style={{ marginLeft: '15px' }}>
                        Office-Office
                      </TitleSection>
                    </b>{' '}
                  </small>
                </FlexGrid3Item>
                <FlexGrid3Item
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: '-9rem',
                  }}>
                  <ToggleButton
                    useInput={useInput('office_to_office')}
                    id="office_to_office"
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('officeToOffice.isConsiderTravelPay')}
                        id="officeToOffice"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>

              {/* Office-Home */}
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <small>
                    <b>
                      <TitleSection style={{ marginLeft: '15px' }}>
                        Office-Home
                      </TitleSection>
                    </b>{' '}
                  </small>
                </FlexGrid3Item>
                <FlexGrid3Item
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: '-9rem',
                  }}>
                  <ToggleButton
                    useInput={useInput('office_to_home')}
                    id="office_to_home"
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('officeToHome.isConsiderTravelPay')}
                        id="officeToHome"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>

              {/* Home-Home */}
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <small>
                    <b>
                      <TitleSection style={{ marginLeft: '15px' }}>
                        Home-Home
                      </TitleSection>
                    </b>{' '}
                  </small>
                </FlexGrid3Item>
                <FlexGrid3Item
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: '-9rem',
                  }}>
                  <ToggleButton
                    useInput={useInput('home_to_home')}
                    id="home_to_home"
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('homeToHome.isConsiderTravelPay')}
                        id="homeToHome"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>

              {/* Home-Office */}
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <small>
                    <b>
                      <TitleSection style={{ marginLeft: '15px' }}>
                        Home-Office
                      </TitleSection>
                    </b>{' '}
                  </small>
                </FlexGrid3Item>
                <FlexGrid3Item
                  style={{
                    display: 'flex',
                    justifyContent: 'left',
                    marginRight: '-9rem',
                  }}>
                  <ToggleButton
                    useInput={useInput('home_to_office')}
                    id="home_to_home"
                  />
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('homeToOffice.isConsiderTravelPay')}
                        id="homeToOffice"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>



              <div className="mt-3  mb-2 pt-2 ms-2">
                <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                  <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                    <small>
                      <b>
                        <TitleSection
                          style={{ marginLeft: '15px', fontSize: 'larger' }}>
                          Add in scheduled travel time on mobile schedule
                        </TitleSection>
                      </b>{' '}
                    </small>
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                    <ToggleButton
                      useInput={useInput('schedule_travel_time')}
                      id="schedule_travel_time"
                    />
                  </FlexGrid3Item>
                </FlexGrid3Container>
              </div>
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ">
                      <small>
                        {' '}
                        <TitleSection
                          style={{
                            marginLeft: '20px',
                            marginBottom: '15px',
                            fontSize: 'larger',
                          }}>
                          Actual distance and time
                        </TitleSection>
                      </small>
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>
              <div
                className="d-md-flex align-items-center"
                style={{ marginLeft: '15px', marginRight: '15px' }}>
                <div className="w-100 me-2">
                  <DropdownSearchable
                    placeHolder={'Actual travel time'}
                    options={[
                      {
                        id: 'Schedule Travel Time',
                        name: 'Schedule Travel Time',
                      },
                      { id: 'Fixed', name: 'Fixed' },
                      { id: 'Manual', name: 'Manual' },
                    ]}
                    selectedOption={
                      values.actual_travel_time
                        ? values.actual_travel_time
                        : null
                    }
                    onChange={value =>
                      dropDownHandleSelect(
                        { actual_travel_time: value ? value : null },
                        'actual_travel_time'
                      )
                    }
                  />
                </div>
                <div className="w-100 me-2" style={{ marginTop: '8px' }}>
                  {values.actual_travel_time === 'Fixed' ? (
                    <div>
                      <InputLabelTop

                        label={'Actual travel time (hh:mm)'}
                        type="text"
                        id="actual_travel_time"
                        // placeholder={'hh:mm'}
                        {...adjustTravelTime}
                      />

                      <small style={{ marginTop: '2rem' }}>
                        {actualTravelTimeHoursLength > 3 ? (
                          <p style={{ color: 'red' }}>
                            Enter the hours and minute in hh: mm format .Hours max.
                            upto 3 digits .{' '}
                          </p>
                        ) : null}
                        {actualTravelTimeHoursValue > 999 ? (
                          <p style={{ color: 'red' }}>
                            {' '}
                            Hours should be less than 999 .
                          </p>
                        ) : null}

                        {actualTravelTimeMinuteLength > 2 ? (
                          <p style={{ color: 'red' }}>
                            Enter the hours and minute in hh: mm format . Minute
                            max. upto 2 digits.
                          </p>
                        ) : null}
                        {actualTravelTimeMinuteValue > 59 ? (
                          <p style={{ color: 'red' }}>
                            Enter the hours and minute in hh: mm format . Minutes
                            should be less than 59 .
                          </p>
                        ) : null}
                        {actualTravelTimeMinuteValue == 0 &&
                          actualTravelTimeMinuteLength < 2 ? (
                          <p style={{ color: 'red' }}>
                            Enter the hours and minute in hh: mm format . If minutes
                            is 0 then enter in hh:00 format .
                          </p>
                        ) : null}
                        {erorActualTravelTime == 'actualTraveltime' ? (
                          <p style={{ color: 'red' }}>
                            {' '}
                            {actualTravelTimeErorTimeFormatMag}
                          </p>
                        ) : null}
                      </small>
                    </div>) : <Fragment></Fragment>}

                </div>

                <div className="w-100 me-2" style={{ marginLeft: '5px' }}>
                  <DropdownSearchable
                    placeHolder={'Actual travel distance '}
                    options={[
                      {
                        id: 'Scheduler Travel Distance',
                        name: 'Scheduler Travel Distance',
                      },
                      { id: 'Fixed', name: 'Fixed' },
                      { id: 'Manual', name: 'Manual' },
                    ]}
                    selectedOption={
                      values.actual_travel_distance
                        ? values.actual_travel_distance
                        : null
                    }
                    onChange={value =>
                      dropDownHandleSelect(
                        { actual_travel_distance: value ? value : null },
                        'actual_travel_distance'
                      )
                    }
                  />
                </div>
                <div className="w-100 mb-2" style={{ marginRight: '15px' }}>
                  <div className="w-100 me-2" style={{ marginTop: '13px' }}>
                    {values.actual_travel_distance === 'Fixed' ? (

                      <InputLabelTop

                        label={'Actual travel distance (miles)'}
                        type="number"
                        id="adjust_travel_distance"
                        // placeholder={'hh:mm'}
                        {...adjustTravelDistance}
                      />
                    ) : <Fragment></Fragment>}
                  </div>
                </div>
              </div>
              <div
                className="mt-3  mb-1 pt-1 ms-1"
                style={{ marginLeft: '15px' }}>
                <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                  <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                    <div className=" mt-1 d-flex">
                      <div className=" ">
                        <small>
                          <b>
                            <TitleSection
                              style={{
                                marginLeft: '15px',
                                fontSize: 'larger',
                                width: '76%',
                              }}>
                              Employee is allowed to update unavailability
                              within xx hh:mm of visit date
                            </TitleSection>
                          </b>{' '}
                        </small>
                      </div>
                    </div>
                    <FlexGrid3Item
                      className="pe-1  pe-0 pe-md-1 pt-1"
                      style={{ marginLeft: '15px', width: '73%' }}>
                      {' '}
                      <InputLabelTop
                        //   disabled={!checkUpdatePermission}
                        type="text"
                        id="verification_distance"
                        placeholder={'hh:mm'}
                        // onKeyPress={KeyPress.DECIMAL}
                        {...useInput(`employee_unavailability`)}
                        maxlength={6}
                      />
                      <small>
                        {employeeUnavailabilityHoursLength > 3 ? (
                          <p style={{ color: 'red' }}>
                            Enter the hours and minute in hh: mm format .Hours
                            max. upto 3 digits{' '}
                          </p>
                        ) : null}
                        {employeeUnavailabilityHoursValue > 999 ? (
                          <p style={{ color: 'red' }}>
                            Hours should be less than 999 .{' '}
                          </p>
                        ) : null}

                        {employeeUnavailabilityMinuteLength > 2 ? (
                          <p style={{ color: 'red' }}>
                            Enter the hours and minute in hh: mm format . Minute
                            max. upto 2 digits
                          </p>
                        ) : null}
                        {employeeUnavailabilityMinuteValue > 59 ? (
                          <p style={{ color: 'red' }}>
                            Enter the hours and minute in hh: mm format .
                            Minutes should be less than 59 .
                          </p>
                        ) : null}
                        {employeeUnavailabilityMinuteValue == 0 &&
                          employeeUnavailabilityMinuteLength < 2 ? (
                          <p style={{ color: 'red' }}>
                            Enter the hours and minute in hh: mm format . If
                            minutes is 0 then enter in hh:00 format .
                          </p>
                        ) : null}
                        {erorEmployeUnavailability == 'unavailability' ? (
                          <p style={{ color: 'red' }}>{erorTimeFormatMag}</p>
                        ) : null}
                      </small>
                    </FlexGrid3Item>
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                    <small>
                      <b>
                        <TitleSection
                          style={{
                            fontSize: 'larger',
                            marginLeft: '-6rem',
                            width: '75%',
                          }}>
                          Employee is allowed to update absence within xx hh:mm
                          of visit date
                        </TitleSection>
                      </b>{' '}
                    </small>
                    <FlexGrid3Item
                      className="pe-1  pe-0 pe-md-1 pt-1"
                      style={{
                        marginLeft: '15px',
                        width: '73%',
                        marginLeft: '-6.3rem',
                        marginTop: '3.4px',
                      }}>
                      {' '}
                      <InputLabelTop
                        //   disabled={!checkUpdatePermission}
                        type="text"
                        id="verification_distance"
                        // label={'Time(hh:mm)'}
                        placeholder={'hh:mm'}
                        // onKeyPress={KeyPress.DECIMAL}
                        {...useInput(`employee_absence`)}
                        maxlength={6}
                      />
                      <small>
                        {employeeAbscenceHoursLength > 3 ? (
                          <p style={{ color: 'red' }}>
                            Enter the hours and minute in hh: mm format .Hours
                            max. upto 3 digits .{' '}
                          </p>
                        ) : null}
                        {employeeAbscenceHoursValue > 999 ? (
                          <p style={{ color: 'red' }}>
                            {' '}
                            Hours should be less than 999 .
                          </p>
                        ) : null}

                        {employeeAbscenceMinuteLength > 2 ? (
                          <p style={{ color: 'red' }}>
                            Enter the hours and minute in hh: mm format . Minute
                            max. upto 2 digits.
                          </p>
                        ) : null}
                        {employeeAbscenceMinuteValue > 59 ? (
                          <p style={{ color: 'red' }}>
                            Enter the hours and minute in hh: mm format .
                            Minutes should be less than 59 .
                          </p>
                        ) : null}

                        {erorEmployeAbscence == 'absence' ? (
                          <p style={{ color: 'red' }}> {erorTimeFormatMag}</p>
                        ) : null}

                        {employeeAbscenceMinuteValue == 0 &&
                          employeeAbscenceMinuteLength < 2 ? (
                          <p style={{ color: 'red' }}>
                            Enter the hours and minute in hh: mm format . If
                            minutes is 0 then enter in hh:00 format .
                          </p>
                        ) : null}
                      </small>
                    </FlexGrid3Item>
                  </FlexGrid3Item>
                </FlexGrid3Container>
              </div>

              <div
                className="mt-3  mb-1 pt-1 ms-1"
                style={{ marginLeft: '15px' }}>
                <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                  <FlexGrid3Item
                    className="pe-1  pe-0 pe-md-1 pt-1"
                    style={{
                      marginLeft: '15px',
                      width: '73%',
                      marginTop: '3px',
                    }}>
                    {' '}
                    <div className=" mt-1 d-flex">
                      <div className=" ">
                        <small>
                          <b>
                            <TitleSection
                              style={{
                                marginLeft: '5px',
                                fontSize: 'larger',
                              }}>
                              Start/End Points
                            </TitleSection>
                          </b>{' '}
                        </small>
                      </div>
                    </div>
                    <FlexGrid3Item
                      className="pe-1  pe-0 pe-md-1 pt-1"
                      style={{ marginLeft: '3px', width: '73%' }}>
                      {' '}
                      <DropdownSearchable
                        placeHolder={'Employee Start location'}
                        options={[
                          { id: 'Home', name: 'Home' },
                          { id: 'Office', name: 'Office' },
                        ]}
                        selectedOption={
                          values ? values.visit_start_location : null
                        }
                        onChange={value =>
                          dropDownHandleSelect(
                            { visit_start_location: value ? value : null },
                            'visit_start_location'
                          )
                        }
                      />
                    </FlexGrid3Item>
                  </FlexGrid3Item>
                  <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                    <FlexGrid3Item
                      className="pe-1  pe-0 pe-md-1 pt-1"
                      style={{
                        width: '73%',
                        marginLeft: '-7rem',
                        marginTop: '30px',
                      }}>
                      {' '}
                      <DropdownSearchable
                        options={[
                          { id: 'Home', name: 'Home' },
                          { id: 'Office', name: 'Office' },
                        ]}
                        selectedOption={
                          values ? values.visit_end_location : null
                        }
                        onChange={value =>
                          dropDownHandleSelect(
                            { visit_end_location: value ? value : null },
                            'visit_end_location'
                          )
                        }
                        placeHolder={'Employee End location'}
                      />
                    </FlexGrid3Item>
                  </FlexGrid3Item>
                </FlexGrid3Container>
              </div>
              {/* //new section  */}

              {/* //new section  */}

              <div className="mt-3  pt-2   pb-2 mb-2">
                <div className="mt-1 ">
                  {' '}
                  <small>
                    <b>
                      <TitleSection
                        style={{ marginLeft: '15px', fontSize: 'larger' }}>
                        Travel mode restriction
                      </TitleSection>
                    </b>{' '}
                  </small>
                </div>
                {values.travel_mode_restriction_serializer &&
                  values.travel_mode_restriction_serializer.length >= 1 &&
                  values.travel_mode_restriction_serializer.map(
                    (data, index) => {
                      return (
                        <TravelModeRestriction
                          index={index}
                          data={data}
                          setValues={setValues}
                          useInput={useInput}
                          values={values}
                          addTravelModeRestriction={addTravelModeRestriction}
                          deleteTravelModeRestriction={
                            deleteTravelModeRestriction
                          }
                          travelType={travelType}
                          dropDownHandleSelect={dropDownHandleSelect}
                          timeRescrtictionHoursLength={
                            timeRescrtictionHoursLength
                          }
                          setTimeRescrtictionHoursLength={
                            setTimeRescrtictionHoursLength
                          }
                          timeRescrtictionHoursValue={
                            timeRescrtictionHoursValue
                          }
                          setTimeRescrtictionHoursValue={
                            setTimeRescrtictionHoursValue
                          }
                          timeRescrtictionMinuteLength={
                            timeRescrtictionMinuteLength
                          }
                          setTimeRescrtictionMinuteLength={
                            setTimeRescrtictionMinuteLength
                          }
                          setTimeRescrtictionMinuteValue={
                            setTimeRescrtictionMinuteValue
                          }
                          timeRescrtictionMinuteValue={
                            timeRescrtictionMinuteValue
                          }
                          erorTimeRescrtiction={erorTimeRescrtiction}
                          setErorTimeRescrtiction={setErorTimeRescrtiction}
                          erorTimeRescrtictionMag={erorTimeRescrtictionMag}
                          setErorTimeRescrtictionMag={
                            setErorTimeRescrtictionMag
                          }
                          setCheckBeforeSubmit={setCheckBeforeSubmit}
                          //props below for second field
                          homeTimeRescrtictionHoursLength={
                            homeTimeRescrtictionHoursLength
                          }
                          setHomeTimeRescrtictionHoursLength={
                            setHomeTimeRescrtictionHoursLength
                          }
                          homeTimeRescrtictionHoursValue={
                            homeTimeRescrtictionHoursValue
                          }
                          setHomeTimeRescrtictionHoursValue={
                            setHomeTimeRescrtictionHoursValue
                          }
                          homeTimeRescrtictionMinuteLength={
                            homeTimeRescrtictionMinuteLength
                          }
                          setHomeTimeRescrtictionMinuteLength={
                            setHomeTimeRescrtictionMinuteLength
                          }
                          setHomeTimeRescrtictionMinuteValue={
                            setHomeTimeRescrtictionMinuteValue
                          }
                          homeTimeRescrtictionMinuteValue={
                            homeTimeRescrtictionMinuteValue
                          }
                          homeErorTimeRescrtiction={homeErorTimeRescrtiction}
                          setHomeErorTimeRescrtiction={
                            setHomeErorTimeRescrtiction
                          }
                          homeErorTimeRescrtictionMag={
                            homeErorTimeRescrtictionMag
                          }
                          setHomeErorimeRescrtictionMag={
                            setHomeErorTimeRescrtictionMag
                          }
                          setHomeCheckBeforeSubmit={setHomeCheckBeforeSubmit}
                        />
                      );
                    }
                  )}
              </div>
              <div
                className={
                  'd-flex justify-content-center justify-content-md-start'
                }
                style={{ marginLeft: '15px' }}>
                <div className={'mt-1 mb-1'}>
                  <PrimaryButtonForm
                    minWidth="6rem"
                    onClick={addTravelModeRestriction}>
                    <span>{PLUS}Travel mode restriction</span>
                  </PrimaryButtonForm>
                </div>
              </div>
              {/* <small>
            <b>
              <TitleSection>
              Travel Pay Confirmation
              </TitleSection>
            </b>{' '}
          </small> */}

              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <small>
                    {timeRescrtictionHoursLength > 3 ? (
                      <p style={{ color: 'red' }}>
                        Please check 'client - client time restriction ' : Enter
                        the hours and minute in hh: mm format .Hours max. upto 3
                        digits .{' '}
                      </p>
                    ) : null}
                    {timeRescrtictionHoursValue > 999 ? (
                      <p style={{ color: 'red' }}>
                        {' '}
                        Please check 'client - client time restriction ' : Hours
                        should be less than 999 .
                      </p>
                    ) : null}

                    {timeRescrtictionMinuteLength > 2 ? (
                      <p style={{ color: 'red' }}>
                        Please check 'client - client time restriction ' : Enter
                        the hours and minute in hh: mm format . Minute max. upto
                        2 digits.
                      </p>
                    ) : null}
                    {timeRescrtictionMinuteValue > 59 ? (
                      <p style={{ color: 'red' }}>
                        Please check 'client - client time restriction ' : Enter
                        the hours and minute in hh: mm format . Minutes should
                        be less than 59 .
                      </p>
                    ) : null}
                    {timeRescrtictionMinuteValue == 0 &&
                      timeRescrtictionMinuteLength < 2 ? (
                      <p style={{ color: 'red' }}>
                        Enter the hours and minute in hh: mm format . If minutes
                        is 0 then enter in hh:00 format .
                      </p>
                    ) : null}
                    {erorTimeRescrtiction ==
                      'client - client time restriction ' ? (
                      <p style={{ color: 'red' }}> {erorTimeRescrtictionMag}</p>
                    ) : null}
                  </small>

                  <small>
                    {homeTimeRescrtictionHoursLength > 3 ? (
                      <p style={{ color: 'red' }}>
                        Please check 'home - client time restriction field ' :
                        Enter the hours and minute in hh: mm format .Hours max.
                        upto 3 digits .{' '}
                      </p>
                    ) : null}
                    {homeTimeRescrtictionHoursValue > 999 ? (
                      <p style={{ color: 'red' }}>
                        {' '}
                        Please check 'home - client time restriction field ' :
                        Hours should be less than 999 .
                      </p>
                    ) : null}

                    {homeTimeRescrtictionMinuteLength > 2 ? (
                      <p style={{ color: 'red' }}>
                        Please check 'home - client time restriction field' :
                        Enter the hours and minute in hh: mm format . Minute
                        max. upto 2 digits.
                      </p>
                    ) : null}
                    {homeTimeRescrtictionMinuteValue > 59 ? (
                      <p style={{ color: 'red' }}>
                        Please check 'home - client time restriction field' :
                        Enter the hours and minute in hh: mm format . Minutes
                        should be less than 59 .
                      </p>
                    ) : null}
                    {homeTimeRescrtictionMinuteValue == 0 &&
                      homeTimeRescrtictionMinuteLength < 2 ? (
                      <p style={{ color: 'red' }}>
                        Enter the hours and minute in hh: mm format . If minutes
                        is 0 then enter in hh:00 format .
                      </p>
                    ) : null}
                    {homeErorTimeRescrtiction ==
                      'home - client time restriction' ? (
                      <p style={{ color: 'red' }}>
                        {' '}
                        {homeErorTimeRescrtictionMag}
                      </p>
                    ) : null}
                  </small>
                </FlexGrid3Item>
              </FlexGrid3Container>
            </div>
            {/* travel time estime start */}
            <div className="mt-3 mb-3">
              <div className="mt-2 pt-1">
                {' '}
                <small>
                  {' '}
                  <TitleSection
                    style={{ marginLeft: '15px', fontSize: 'larger' }}>
                    Travel time estimate
                  </TitleSection>
                </small>
              </div>
              {values.travel_estimate_serializer &&
                values.travel_estimate_serializer.length >= 1 &&
                values.travel_estimate_serializer.map((data, index) => {
                  return (
                    <TravelTimeEstimate
                      index={index}
                      data={data}
                      setValues={setValues}
                      useInput={useInput}
                      values={values}
                      addNewTravelTimeEstimate={addNewTravelTimeEstimate}
                      deleteTravelTimeEstimate={deleteTravelTimeEstimate}
                      travelModeOptions={travelModeOptions}
                      travelName={travelName}
                      unitName={unitName}
                      dropDownHandleSelectTravelEstimate={
                        dropDownHandleSelectTravelEstimate
                      }
                    />
                  );
                })}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-start'
              }
              style={{ marginLeft: '15px' }}>
              <div className={'mt-2'}>
                <PrimaryButtonForm
                  minWidth="6rem"
                  onClick={addNewTravelTimeEstimate}>
                  <span>{PLUS}Travel time estimate</span>
                </PrimaryButtonForm>
              </div>
            </div>
            {/* travel time estime end  */}











            <div className="mt-3  mb-1 pt-1 ms-1" style={{ marginLeft: '15px' }}>
              <small>
                <b>
                  <TitleSection
                    style={{ marginLeft: '15px', fontSize: 'larger' }}>
                    Allow care worker for approval amends on mobile.
                  </TitleSection>
                </b>{' '}
              </small>
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ">
                      <small>
                        {' '}
                        <TitleSection style={{ marginLeft: '15px' }}>
                          Offer-approval-process
                        </TitleSection>
                      </small>
                    </div>
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('is_offer_approval')}
                        id="is_offer_approval"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ">
                      <small>
                        {' '}
                        <TitleSection style={{ marginLeft: '15px' }}>
                          Handback-approval
                        </TitleSection>
                      </small>
                    </div>
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('is_handBack')}
                        id="is_handBack"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ">
                      <small>
                        {' '}
                        <TitleSection style={{ marginLeft: '15px' }}>
                          Visit swap approval process
                        </TitleSection>
                      </small>
                    </div>
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('is_swap')}
                        id="is_swap"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>
            </div>
            {/* <div className="mt-3  mb-1 pt-1 ms-1" style={{ marginLeft: '15px' }}>
              <small>
                <b>
                  <TitleSection
                    style={{ marginLeft: '15px', fontSize: 'larger' }}>
                    Visit Auto Publish

                  </TitleSection>
                </b>{' '}
              </small>
              <FlexGrid3Container className={'w-100 mb-1 mt-1'}>
                <FlexGrid3Item className="  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ">
                      <small>
                        {' '}
                        <TitleSection style={{ marginLeft: '5px' }}>
                          Bulk-update
                        </TitleSection>
                      </small>
                    </div>
                    <div >
                      <ToggleButton
                        useInput={useInput('bulk_update')}
                        id="bulk_update"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ">
                      <small>
                        {' '}
                        <TitleSection style={{ marginLeft: '5px' }}>
                          Copy-Scheduler                        </TitleSection>
                      </small>
                    </div>
                    <div >
                      <ToggleButton
                        useInput={useInput('copy_scheduler')}
                        id="copy_scheduler"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ">
                      <small>
                        {' '}
                        <TitleSection style={{ marginLeft: '5px' }}>
                          Drag and Drop                        </TitleSection>
                      </small>
                    </div>
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('drag_and_drop')}
                        id="drag_and_drop"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ">
                      <small>
                        {' '}
                        <TitleSection style={{ marginLeft: '5px' }}>
                          Run -Scheduler

                        </TitleSection>
                      </small>
                    </div>
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('run_scheduler')}
                        id="run_scheduler"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
                <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1">
                  <div className=" mt-1 d-flex">
                    <div className=" ">
                      <small>
                        {' '}
                        <TitleSection style={{ marginLeft: '5px' }}>
                          Visit-Pop
                        </TitleSection>
                      </small>
                    </div>
                    <div className=" ps-1  ms-1">
                      <ToggleButton
                        useInput={useInput('visit_pop')}
                        id="visit_pop"
                      />
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>

            </div> */}

            <div className="mt-3  mb-3 pt-1 ms-1" style={{ marginLeft: '15px' }}>
              <TitleSection
                style={{
                  marginLeft: '15px',
                  fontSize: 'larger',
                  marginBottom: '-1rem',
                  marginTop: '2rem',
                }}>
                Visit Auto Publish
              </TitleSection>
            </div>
            <div style={{ marginLeft: "35px" }}>
              <div class="p-2">
                <div className='d-flex align-items-center row'>
                  <small>
                    <b>
                      <TitleSection>
                        Current +
                      </TitleSection>
                    </b>
                  </small>
                  <div className="input-container">
                    <div style={{ marginLeft: '5px', marginRight: '10px', width: "50px",    marginTop: "10px"}}>
                      <InputLabelTop
                        style={{height: "30px"}}
                        type="number"
                        id="days"
                        placeholder={'0'}
                        onKeyPress={KeyPress.POSITIVE}
                        {...useInput(`days`)}
                        maxlength={6}
                      />
                    </div>
                    <div className="triangle-buttons">
                      <span className="triangle up" onClick={() => handleIncrement("current_days")} ></span>
                      <span className="triangle down" onClick={() => handleDecrement("current_days")} ></span>
                    </div>
                  </div>
                  <small>
                    <b>
                      <TitleSection>
                        Days
                      </TitleSection>
                    </b>
                  </small>
                </div>
              </div>

              <div class="d-flex justify-content-between" style={{ marginRight: '3rem' }}>

                <div class="p-2">
                  <div className='d-flex row'>
                    <small>
                      <b>
                        <TitleSection>
                          Bulk-Update
                        </TitleSection>
                      </b>
                    </small>
                    <div style={{ marginLeft: '10px' }}>
                      <ToggleButton
                        useInput={useInput('bulk_update')}
                        id="bulk_update"
                      />
                    </div>
                  </div>
                </div>

                <div class="p-2">
                  <div className='d-flex row'>
                    <small>
                      <b>
                        <TitleSection>
                          Copy-Scheduler
                        </TitleSection>
                      </b>
                    </small>
                    <div style={{ marginLeft: '10px' }}>
                      <ToggleButton
                        useInput={useInput('copy_scheduler')}
                        id="copy_scheduler"
                      />
                    </div>
                  </div>
                  </div>
                <div class="p-2">
                  <div className='d-flex row'>
                    <small>
                      <b>
                        <TitleSection>
                          Drag-and-Drop
                        </TitleSection>
                      </b>
                    </small>
                    <div style={{ marginLeft: '10px' }}>
                      <ToggleButton
                        useInput={useInput('drag_and_drop')}
                        id="drag_and_drop"
                      />
                    </div>
                  </div>
                 </div>
                <div class="p-2">
                  <div className='d-flex row'>
                    <small>
                      <b>
                        <TitleSection>
                          Run-Scheduler
                        </TitleSection>
                      </b>
                    </small>
                    <div style={{ marginLeft: '10px' }}>
                      <ToggleButton
                        useInput={useInput('run_scheduler')}
                        id="run_scheduler"
                      />
                    </div>
                  </div>
               </div>
               <div class="p-2">
                  <div className='d-flex row'>
                    <small>
                      <b>
                        <TitleSection>
                          Regular-Visit
                        </TitleSection>
                      </b>
                    </small>
                    <div style={{ marginLeft: '10px' }}>
                      <ToggleButton
                        useInput={useInput('regular_visit')}
                        id="regular_visit"
                      />
                    </div>
                  </div>
                </div>
                <div class="p-2">
                  <div className='d-flex row'>
                    <small>
                      <b>
                        <TitleSection>
                          Visit-Pop
                        </TitleSection>
                      </b>
                    </small>
                    <div style={{ marginLeft: '10px' }}>
                      <ToggleButton
                        useInput={useInput('visit_pop')}
                        id="visit_pop"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <small>
              <b>
                <TitleSection
                  style={{ marginLeft: '25px', fontSize: 'larger' }}>
                  Future
                </TitleSection>
              </b>{' '}
            </small>
            <div style={{ marginLeft: "35px" }}>

              <div class="d-flex justify-content-between" style={{ marginRight: '3rem' }}>

                <div class="p-2">
                    <div className='d-flex row mt-3'>
                    <small>
                      <b>
                        <TitleSection>
                          Bulk-Update
                        </TitleSection>
                      </b>
                    </small>
                    <div style={{ marginLeft: '10px' }}>
                      <ToggleButton
                        useInput={useInput('bulk_update_future')}
                        id="bulk_update_future"
                      />
                    </div>
                  </div>
                </div>

                <div class="p-2">
                  <div className='d-flex row mt-3'>
                    <small>
                      <b>
                        <TitleSection>
                          Copy-Scheduler
                        </TitleSection>
                      </b>
                    </small>
                    <div style={{ marginLeft: '10px' }}>
                      <ToggleButton
                        useInput={useInput('copy_scheduler_future')}
                        id="copy_scheduler_future"
                      />
                    </div>
                  </div>
                </div>
                <div class="p-2">
                  <div className='d-flex row mt-3'>
                    <small>
                      <b>
                        <TitleSection>
                          Drag-and-Drop
                        </TitleSection>
                      </b>
                    </small>
                    <div style={{ marginLeft: '10px' }}>
                      <ToggleButton
                        useInput={useInput('drag_and_drop_future')}
                        id="drag_and_drop_future"
                      />
                    </div>
                  </div>
                </div>
                <div class="p-2">
                  <div className='d-flex row mt-3'>
                    <small>
                      <b>
                        <TitleSection>
                          Run-Scheduler
                        </TitleSection>
                      </b>
                    </small>
                    <div style={{ marginLeft: '10px' }}>
                      <ToggleButton
                        useInput={useInput('run_scheduler_future')}
                        id="run_scheduler_future"
                      />
                    </div>
                  </div>
                </div>
                <div class="p-2">
                  <div className='d-flex row mt-3'>
                    <small>
                      <b>
                        <TitleSection>
                          Regular-Visit
                        </TitleSection>
                      </b>
                    </small>
                    <div style={{ marginLeft: '10px' }}>
                      <ToggleButton
                        useInput={useInput('regular_visit_future')}
                        id="regular_visit_future"
                      />
                    </div>
                  </div>
                </div>

                <div class="p-2">
                  <div className='d-flex row mt-3'>
                    <small>
                      <b>
                        <TitleSection>
                          Visit-Pop
                        </TitleSection>
                      </b>
                    </small>
                    <div style={{ marginLeft: '10px' }}>
                      <ToggleButton
                        useInput={useInput('visit_pop_future')}
                        id="visit_pop_future"
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div
              className={
                'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
              }>
              <div className={'mb-3'}>
                {errorMessage ? (
                  <ErrorMessage>
                    <label className={'mt-2'}>{errorMessage}</label>
                  </ErrorMessage>
                ) : (
                  <Fragment />
                )}
              </div>

              <div
                className={
                  'd-flex justify-content-center justify-content-md-end'
                }>
                <div className={'ms-0 ms-md-3 mb-2 pb-1 mt-1 pt-1 me-2'}>
                  <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit} disabled={loadingSave}>
                    <span className={'me-2 mb-2 pb-1 pt-1'}>{textStatus}</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                </div>
              </div>
            </div>
          </>
        </ModalDynamic>
      </ModalContainer>
    </div>
  );
};

export default SettingsEvv;
