import React, {useState, useEffect, Fragment, useContext, useRef} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'hooks/FormHook';
import {MdEdit} from 'react-icons/md';
import {RiDeleteBinLine} from 'react-icons/ri';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import Pagination from 'components/SharedComponents/Pagination';
import queryString from 'query-string';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import {deleteClientTadks, deleteEmployeeFormBuilder, editcareForumClient, editcareForumEmployee, getClientFormBuilder, getClientTasks, getTeamFormBuilder} from 'utils/api/ClientApi';
import {useModal} from 'hooks/ModalHook';
import {getPageToSearchParam} from 'shared/methods';
import {PrimaryButtonForm} from 'shared/styles/buttons';

import {
  Row,
  Col,
  IconWrapper,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import _ from 'lodash';
import Table from 'components/SharedComponents/Table';
import {Toast} from 'primereact/toast';

import {
  EX_LARGE_COL,
  EX_SMALL_COL4,
  EX_VERY_LARGE_COL,
  LARGE_COL,
  MEDIUM_COL,
  SMALL_COL,
} from 'shared/styles/constants/columns';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import moment from 'moment';
import FormViewer from "components/SharedComponents/FormViewer"
import ShowFormModal from './showFormModal/showFormModal';
import { FLOPPY } from 'utils/constants/icons';
import { NoPermissionContainer } from './styles';

export const headerColumns = [
  {
    label: 'Form Name',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Start Date',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'End Date',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
];

const CareForum = ({
  selectedEmpId,
  contextChoices,
  location,
  name,
  handleChangeChoices,
  allPermission
}) => {
  const {values, setValues, useInput, errors, setCheckRequires} = useForm({
    validation: false,
    limitperpage: PAGE_SIZE_RENDER
  });
  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const toast = useRef();
  const history = useHistory();
  const [taskData, setTaskData] = useState([]);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [idSelected, setIdSelected] = useState('');
  const [simplePagination] = useState(true);
  const [createdForms, setCreatedForms] = useState([])
  const [showForm, setShowForm] = useState({ name: "", visible: false, id: null })
  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [formData, setFormData] = useState(null)

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };
  useEffect(() => {
    loadCareForum()
  }, [values.limitperpage, history.location.search]);

  const loadCareForum=()=>{
    values.emp_id = selectedEmpId;
    setValues({...values});
    const pageToSearch = getPageToSearchParam(
      history.location.search,
      setCurrentPage
    );

    getTeamFormBuilder({...values, ...pageToSearch}).then(res => {
      // setTaskData(res.data);
      if (res.results) {
        setTaskData(res.results || []);
        setPages(Math.ceil(res.count / values.limitperpage));
      }
    });

    values.ordering = 'requirement_visit_wise__visit__start_date';
    setValues({...values});
  }


  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({...values});
  };

  const toDeleteBranch = id => {
    setModalDeleteValue(true);
    setIdSelected(id);
  };
  const handleDeleteBranch = async () => {
    let response = await deleteEmployeeFormBuilder(idSelected);
    if (response.status === 'SUCCESS') {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `${response.message}`,
      });
      loadCareForum()
      setModalDeleteValue(false);
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });
      setModalDeleteValue(false);
      setIdSelected('');
    }
  };

  const editEmployeeform = async () => {
    const body = {
        "id": formData?.id,
        "start_date": formData?.start_date,
        "end_date": formData?.end_date,
        "employee": selectedEmpId,
        "form": formData?.form,
        "name": formData?.name,
        "json_data": JSON.stringify(createdForms)
    }

    await editcareForumEmployee(body, formData?.id)
        .then((resp) => {
            if(resp.status){
              toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: `${resp.message}`,
              });
              setShowForm({ name: "", visible: false, id: null})
              loadCareForum()
            }else{
              toast.current.show({
                severity: 'error',
                summary: 'Failed',
                detail: `${resp.message}`,
              });
            }
        }).catch((err) => {
        })
}

  return (
    <div className='mt-4'>
       <Toast ref={toast} position="top-right" />
       <>{allPermission.read ?
       <Table
          headerColumns={headerColumns}
          headerPadding={false}
          noEditOption
          fontSize="0.75rem">
        <div>
          {contextChoices &&
            taskData.map((item, index) => {
              return (
                <div key={index}>
                  <Row bgColor>
                  <ColsGrouper
                        className="d-flex"
                        >
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className="sm-hidden"
                        overlap={true}>
                        {item.name}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className="md-hidden"
                        overlap={true}>
                          {item.start_date ? moment(item.start_date).format('YYYY-MM-DD') : ''}
                      </Col>
                      <Col
                        max_width={LARGE_COL}
                        Center
                        className="md-hidden"
                        overlap={true}>
                          {item.end_date ? moment(item.end_date).format('YYYY-MM-DD') : ''}
                      </Col>
                      </ColsGrouper>
                      <Col className="sm-hidden" Center Shrink NoFlexGrow>
                          <Col className="sm-hidden" Center Icon Purple>
                            <IconWrapper
                          onClick={() => {
                            setCreatedForms(() => {
                                  return JSON.parse(item.json_data)
                          })
                          setShowForm({ name: item.name, visible: true, id: item.id })
                          setFormData(item)
                          }}
                            >
                              <MdEdit />
                            </IconWrapper>
                          </Col>
                         {allPermission.delete && <IconWrapper
                            onClick={() => {
                              toDeleteBranch(item.id);
                            }}>
                            <RiDeleteBinLine />
                          </IconWrapper>}
                        </Col>
                       
                  </Row>
                  <div className="mb-1" />
                </div>
              );
            })}
          <div className="mt-3">
            <Pagination
              totalPages={pages}
              currentPage={currentPage}
              values={values}
              dropDownHandleSelect={dropDownHandleSelectPagination}
              number={PAGE_SIZE_RENDER}
              dataLength={taskData.length}
            />
          </div>
        </div>
      </Table>
      : 
      <NoPermissionContainer>
        You don't have permission to access the information.
      </NoPermissionContainer>
      }</>
      <div
            className={'d-flex justify-content-center justify-content-md-end'}>
            <div>
            <PrimaryButtonForm
                        bgcolor="PURPLE_GRAY_500"
                        minWidth="6rem"
                        className={'me-2'}
                        onClick={()=>{
                          history.push('/team')
                        }}>
                        <span className={'me-2'}>{'Cancel'}</span>
                      </PrimaryButtonForm>
            </div>
          </div>

          <ShowFormModal
                children={
                    <>
                    <FormViewer jsonData={createdForms} setJsonData={setCreatedForms} name={name} title={showForm.name} handleChangeChoices={handleChangeChoices} />
                    <div style={{alignSelf:'flex-end'}} className='mb-2 me-2'>
                    <PrimaryButtonForm
                    minWidth="6rem"
                    onClick={editEmployeeform}
                    disabled={showForm.id ? !allPermission.update : !allPermission.create}
                    >
                    <span className={'me-2'}>Save</span>
                    {!loadingSave ? (
                      buttonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                  </PrimaryButtonForm>
                    </div>
                    </>
                }
                title={showForm.name}
                onClose={() => {
                    setShowForm({ name: "", visible: false, id: null})
                }}
                showModal={showForm.visible}
                max_width={'60%'}
                minWidthTitle="8.5rem"
                loading={false}
                data={[]}
            />
          

      <ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this company? \n deleting the task will remove all records from database.'
        }
        subBody={
          'TIP (Recommended) - you could change the task to discard instead to retain database records.'
        }
        onOk={handleDeleteBranch}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
    </div>
  );
};

export default CareForum;
