import React, { useState, useEffect, Fragment } from 'react';
import {
  ModalContainer
} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import { getClientBulkPreviewData, getClientDetails } from 'utils/api/ClientApi';
import './preview.css'
import moment from 'moment'
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { jsPDF } from "jspdf";
import * as html2canvas from 'html2canvas';
import { DOWNLOAD } from 'utils/constants/icons';

const BulkPreviewModal = ({
  useInput,
  values,
  fullBind,
  selectedRow,
  setEmailPreviewModalBulk
}) => {

  const [addFullBind, setAddFullBind] = useState(fullBind);
  const [templateData, setTemplateData] = useState([]);
  const [maxSize, setMaxSize] = useState(0);
  const [modalLoading, setModalLoading] = useState(true)
  const [loadingSave, setLoadingSave] = useState(false);
  const [buttonIcon, setButtonIcon] = useState(DOWNLOAD);


  const tableData = (table_val,i) => {
    const arrayOne = table_val.day_1 || [];
    const arrayTwo = table_val.day_2 || [];
    const arrayThree = table_val.day_3 || [];
    const arrayFour = table_val.day_4 || [];
    const arrayFive = table_val.day_5 || [];
    const arraySix = table_val.day_6 || [];
    const arraySeven = table_val.day_7 || [];


    let arr = []
    arrayOne[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >
          <tr>
            <td style={{ width: '100%', height: '100px' }}>
              <img
                className="img-circle"
                alt="image"
                style={{
                  paddingBottom: 1,
                  backgroundColor: "#f6f6f6",
                  width: '100px',
                  height: '100px'
                }}
                src={`https://${arrayOne[i].employee_photo}`}
              />
            </td>
          </tr>
          <tr>
            <td
              style={{
                overflowWrap: "display:block",
                padding: "2px 0",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayOne[i].employee_full_name}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayOne[i].visit_time}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayOne[i].visit_type}
            </td>
          </tr>
        </td>
      )
      : arr.push(<td></td>);

    arrayTwo[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >
          <tr>
            <td style={{ width: '100%', height: '100px' }}>
              <img
                className="img-circle"
                alt="image"
                style={{
                  paddingBottom: 1,
                  width: '100px',
                  height: '100px',
                  backgroundColor: "#f6f6f6",

                }}
                src={`https://${arrayTwo[i].employee_photo}`}
              />
            </td>
          </tr>
          <tr>
            <td
              style={{
                overflowWrap: "display:block",
                padding: "2px 0",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayTwo[i].employee_full_name}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayTwo[i].visit_time}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayTwo[i].visit_type}
            </td>
          </tr>
        </td>
      )
      : arr.push(<td></td>);

    arrayThree[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >
          <tr>
            <td style={{ width: '100%', height: '100px' }}>
              <img
                className="img-circle"
                alt="image"
                style={{
                  paddingBottom: 1,
                  width: '100px',
                  height: '100px',
                  backgroundColor: "#f6f6f6",

                }}
                src={`https://${arrayThree[i].employee_photo}`}
              />
            </td>
          </tr>
          <tr>
            <td
              style={{
                overflowWrap: "display:block",
                padding: "2px 0",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayThree[i].employee_full_name}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayThree[i].visit_time}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayThree[i].visit_type}
            </td>
          </tr>
        </td>
      )
      : arr.push(<td></td>);

    arrayFour[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >
          <tr>
            <td style={{ width: '100%', height: '100px' }}>
              <img
                className="img-circle"
                alt="image"
                style={{
                  paddingBottom: 1,
                  width: '100px',
                  height: '100px',
                  backgroundColor: "#f6f6f6",

                }}
                src={`https://${arrayFour[i].employee_photo}`}
              />
            </td>
          </tr>
          <tr>
            <td
              style={{
                overflowWrap: "display:block",
                padding: "2px 0",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayFour[i].employee_full_name}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayFour[i].visit_time}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayFour[i].visit_type}
            </td>
          </tr>
        </td>
      )
      : arr.push(<td></td>);

    arrayFive[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >
          <tr>
            <td style={{ width: '100%', height: '100px' }}>
              <img
                className="img-circle"
                alt="image"
                style={{
                  paddingBottom: 1,
                  width: '100px',
                  height: '100px',
                  backgroundColor: "#f6f6f6",

                }}
                src={`https://${arrayFive[i].employee_photo}`}
              />
            </td>
          </tr>
          <tr>
            <td
              style={{
                overflowWrap: "display:block",
                padding: "2px 0",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayFive[i].employee_full_name}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayFive[i].visit_time}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arrayFive[i].visit_type}
            </td>
          </tr>
        </td>
      )
      : arr.push(<td></td>);

    arraySix[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >
          <tr>
            <td style={{ width: '100%', height: '100px' }}>
              <img
                className="img-circle"
                alt="image"
                style={{
                  paddingBottom: 1,
                  width: '100px',
                  height: '100px',
                  backgroundColor: "#f6f6f6",

                }}
                src={`https://${arraySix[i].employee_photo}`}
              />
            </td>
          </tr>
          <tr>
            <td
              style={{
                overflowWrap: "display:block",
                padding: "2px 0",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arraySix[i].employee_full_name}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arraySix[i].visit_time}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arraySix[i].visit_type}
            </td>
          </tr>
        </td>
      )
      : arr.push(<td></td>);

    arraySeven[i] ?
      arr.push(
        <td
          style={{
            textAlign: "center",
            overflowWrap: "break-word",
            backgroundColor: "#f6f6f6",
            fontFamily: "sans-serif",
            fontSize: 12,
            marginBottom: 10,
            border: "1px solid #ccc"
          }}
        >
          <tr>
            <td style={{ width: '100%', height: '100px' }}>
              <img
                className="img-circle"
                alt="image"
                style={{
                  paddingBottom: 1,
                  width: '100px',
                  height: '100px',
                  backgroundColor: "#f6f6f6",

                }}
                src={`https://${arraySeven[i].employee_photo}`}
              />
            </td>
          </tr>
          <tr>
            <td
              style={{
                overflowWrap: "display:block",
                padding: "2px 0",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arraySeven[i].employee_full_name}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arraySeven[i].visit_time}
            </td>
          </tr>
          <tr>
            <td
              style={{
                padding: "2px 0",
                overflowWrap: "display:block",
                backgroundColor: "#f6f6f6",
                fontSize: 14
              }}
            >
              {arraySeven[i].visit_type}
            </td>
          </tr>
        </td>
      )
      : arr.push(<td></td>);


    return arr;
  }

  useEffect(() => {
    if (selectedRow) {
      const start = values.date_from;
      const end = moment(values.date_to, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
      getClientBulkPreviewData(start, end, selectedRow)
        .then((res) => {
          setModalLoading(false)
          if (res.data) {
            let data_temp = res.data.map((item)=>{
            let day_1 = item.day_1 ? item.day_1.length : 0;
            let day_2 = item.day_2 ? item.day_2.length : 0;
            let day_3 = item.day_3 ? item.day_3.length : 0;
            let day_4 = item.day_4 ? item.day_4.length : 0;
            let day_5 = item.day_5 ? item.day_5.length : 0;
            let day_6 = item.day_6 ? item.day_6.length : 0;
            let day_7 = item.day_7 ? item.day_7.length : 0;
            let maxSizeArr = Math.max(day_1, day_2, day_3, day_4, day_5, day_6, day_7);
            return {...item, maxSizeArr: maxSizeArr}
          })
        
          setTemplateData(data_temp);
          }


        })
        .catch((err) => { })
    }
  }, [])

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Email Preview',
    });

  }, [fullBind]);

  
  const handleDownlaodPdf = () => {
    const input = document.querySelector('#mypdf');

    html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            orientation: 'landscape',
          });
          let position = 0;
          let pageHeight = 210;
          let imgProps = pdf.getImageProperties(imgData);
          let pdfWidth = pdf.internal.pageSize.getWidth();
          let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          var heightLeft = pdfHeight;
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - pdfHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
            heightLeft -= pageHeight;
          }
          const current_date = moment().format('DD-MM-YYY')
          pdf.save(`report_${current_date}`);
        });
       setTimeout(() => {
        setEmailPreviewModalBulk(false)
       }, 100);
  };

  const handleDownlaodPdfEmployeeWise = () => {
    setLoadingSave(true);
    templateData.forEach((item,index)=>{
      const input = document.querySelector(`#employee${index}`);

    html2canvas(input)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            orientation: 'landscape',
          });
          let position = 0;
          let pageHeight = 210;
          let imgProps = pdf.getImageProperties(imgData);
          let pdfWidth = pdf.internal.pageSize.getWidth();
          let pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          var heightLeft = pdfHeight;
          pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - pdfHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
            heightLeft -= pageHeight;
          }
          const current_date = moment().format('DD-MM-YYY')
          pdf.save(`${item.client_full_name} ${item.schema_name} ${item.start_date} ${item.end_date}`);
          if((index + 1) == templateData.length){
            setLoadingSave(false)
          }
        });
     
    })
      //  setTimeout(() => {
      //   setEmailPreviewModalBulk(false)
      //  }, 100);
  };

  return (
    <ModalContainer max_width={'80%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'80%'}
        loading={modalLoading}>
        <div className='preview_style'>
          <div className='m-2 text-end'>
          <PrimaryButtonForm className='me-1' minWidth="6rem" onClick={handleDownlaodPdfEmployeeWise}>
              <span className={'me-1'}>Export clientwise pdf</span>
              {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
            </PrimaryButtonForm>
            <PrimaryButtonForm minWidth="6rem" onClick={handleDownlaodPdf}>
              <span className={'me-1'}>Export single pdf</span>
              {buttonIcon}
            </PrimaryButtonForm>
          </div>
          <div
            id='mypdf'>
           {templateData && templateData.map((val,index)=>{
            return(
          <div
          id={`employee${index}`}
            style={{
              backgroundColor: "#f6f6f6",
              fontFamily: "sans-serif",
              WebkitFontSmoothing: "antialiased",
              fontSize: 14,
              lineHeight: "1.4",
              margin: 0,
              padding: 0,
              msTextSizeAdjust: "100%",
              WebkitTextSizeAdjust: "100%"
            }}
          >
            <span
              className="preheader"
              style={{
                color: "transparent",
                display: "none",
                height: 0,
                maxHeight: 0,
                maxWidth: 0,
                opacity: 0,
                overflow: "hidden",
                msoHide: "all",
                visibility: "hidden",
                width: 0
              }}
            >

            </span>
            <table
              role="presentation"
              border={0}
              cellPadding={0}
              cellSpacing={0}
              className="body"
              style={{
                borderCollapse: "separate",
                msoTableLspace: "0pt",
                msoTableRspace: "0pt",
                backgroundColor: "#f6f6f6",
                width: "100%"
              }}
              width="100%"
              bgcolor="#f6f6f6"
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      verticalAlign: "top"
                    }}
                    valign="top"
                  >
                    &nbsp;
                  </td>
                  <td
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      verticalAlign: "top",
                      display: "block",
                      padding: 10,
                      margin: "0 auto"
                    }}
                    valign="top"
                  >
                    <div
                      className="content"
                      style={{
                        boxSizing: "border-box",
                        display: "block",
                        margin: "0 auto",
                        padding: 10
                      }}
                    >
                      {/* widt:580START CENTERED WHITE CONTAINER */}
                      <table
                        role="presentation"
                        className="main"
                        style={{
                          borderCollapse: "separate",
                          msoTableLspace: "0pt",
                          msoTableRspace: "0pt",
                          background: "#ffffff",
                          borderRadius: 3,
                          width: "100%"
                        }}
                        width="100%"
                      >
                        {/* START MAIN CONTENT AREA */}
                        <tbody>
                          <tr>
                            <td
                              className="wrapper"
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: 14,
                                verticalAlign: "top",
                                boxSizing: "border-box",
                                padding: 20
                              }}
                              valign="top"
                            >
                              <table
                                role="presentation"
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                style={{
                                  borderCollapse: "separate",
                                  msoTableLspace: "0pt",
                                  msoTableRspace: "0pt",
                                  width: "100%"
                                }}
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        fontFamily: "sans-serif",
                                        fontSize: 14,
                                        verticalAlign: "top"
                                      }}
                                      valign="top"
                                    >
                                      <p
                                        style={{
                                          fontFamily: "sans-serif",
                                          fontSize: 14,
                                          fontWeight: "normal",
                                          margin: 0,
                                          marginBottom: 10,
                                          textAlign: "right",
                                          paddingBottom: 5
                                        }}
                                      >
                                        <small>{val && val.schema_name}</small>
                                      </p>
                                      <table
                                        role="presentation"
                                        border={0}
                                        cellPadding={0}
                                        cellSpacing={0}
                                        style={{
                                          borderCollapse: "separate",
                                          msoTableLspace: "0pt",
                                          msoTableRspace: "0pt",
                                          boxSizing: "border-box",
                                          width: "100%"
                                        }}
                                        width="100%"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              align="left"
                                              style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 14,
                                                verticalAlign: "top",
                                                paddingBottom: 15
                                              }}
                                              valign="top"
                                            >
                                              <p
                                                style={{
                                                  fontFamily: "sans-serif",
                                                  fontSize: 14,
                                                  fontWeight: "normal",
                                                  margin: 0,
                                                  marginTop: 15,
                                                  padding: "12px 0",
                                                  marginBottom: 10,
                                                  textAlign: "center",
                                                  backgroundColor: "#f6f6f6"
                                                }}
                                              >
                                                {val && val.client_full_name} {val && val.start_date && '- from'} {val && val.start_date} {val && val.start_date && 'to'} {val && val.end_date}
                                              </p>
                                              <table
                                                role="presentation"
                                                border={0}
                                                cellPadding={0}
                                                cellSpacing={0}
                                                style={{
                                                  borderCollapse: "separate",
                                                  msoTableLspace: "0pt",
                                                  msoTableRspace: "0pt",
                                                  width: "100%"
                                                }}
                                              >
                                                <tbody>
                                                  {/*- for  th date get  dynamice  day+date fro  each 7dayswith date  dynamically and  display     -*/}
                                                  <tr>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize"
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {val && val.weekday_1 && val.weekday_1}
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize"
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {val && val.weekday_2 && val.weekday_2}
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize"
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {val && val.weekday_3 && val.weekday_3}
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize"
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {val && val.weekday_4 && val.weekday_4}
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize"
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {val && val.weekday_5 && val.weekday_5}
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize"
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {val && val.weekday_6 && val.weekday_6}
                                                    </th>
                                                    <th
                                                      style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 14,
                                                        verticalAlign: "top",
                                                        textAlign: "center",
                                                        padding: "15px 10px",
                                                        textTransform: "capitalize"
                                                      }}
                                                      valign="top"
                                                      align="center"
                                                    >
                                                      {val && val.weekday_7 && val.weekday_7}
                                                    </th>
                                                  </tr>
                                                  <tr></tr>
                                                </tbody>
                                                <tbody
                                                  id="table-content"
                                                  style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 14,
                                                    verticalAlign: "top",
                                                    borderRadius: 5,
                                                    textAlign: "center",
                                                    width: "2%"
                                                  }}
                                                  valign="top"
                                                  align="center"
                                                >
                                                  {[...Array(val.maxSizeArr)].map((item, i) => {
                                                      return (
                                                        <tr>
                                                          {tableData(val,i)}
                                                        </tr>
                                                      )
                                                    })}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          {/* END MAIN CONTENT AREA */}
                        </tbody>
                      </table>
                      {/* END CENTERED WHITE CONTAINER */}
                    </div>
                  </td>
                  <td
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      verticalAlign: "top"
                    }}
                    valign="top"
                  >
                    &nbsp;
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
            )})}
            </div>

        </div>
      </ModalDynamic>
    </ModalContainer>

  );
}

export default BulkPreviewModal;
