import React, { useRef, } from 'react';
import { useForm } from 'hooks/FormHook';
import { Toast } from 'primereact/toast';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { useState } from 'react';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages';
import AddForm from './AddForm';
import { Fragment } from 'react';
import { ErrorMessage } from 'shared/styles/AuthComponents';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import { Header, ModalContainer } from 'shared/styles/constants/tagsStyles';
import { getSplitShiftById, setSplitShiftApi } from 'utils/api/SettingsApi';
import { useEffect } from 'react';
import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';

const SplitShiftModal = ({
    branch,
    fullBind,
    permission,
    idSelected,
    setIdSelected,
    setModalValue,
    loadSplitShift,
    toast,
    contextChoices
}) => {

    const SPLIT_SHIFT = 'split_shift';
    const { values, setValues, useInput, errors, setCheckRequires, setFormErrors, } = useForm({ [SPLIT_SHIFT]: [{}] });

    const [isExist, setIsExist] = useState(false)

    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true)
    const [textStatus, setTextStatus] = useState(SAVE);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [loadingSave, setLoadingSave] = useState(false);
    const [options, setOptions] = useState({})
    const [showError, setShowError] = useState(false)
    const [payTypeOption, setPayTypeOption] = useState([])


    const addNewShift = () => {
        values[SPLIT_SHIFT].push({});
        setValues({ ...values });
        setLoadingSave(false);
        setTextStatus(SAVE);
        setButtonIcon(FLOPPY);
    };
    const findFromChoice = (name) => {
        let match = name && contextChoices?.pay_type?.find(item => item.name == name);
        return match ? match?.id : null
    }


    const getSplitShiftByIdData = async () => {
        try {
            const res = await getSplitShiftById(idSelected)

            if (res.length) {
                setIsExist(res.length > 0)
                let data = res.map(item => {
                    return {
                        id: item.id,
                        shift_type: item.shift_type_id,
                        split_shift_type: item.split_shifts.map((el) => {
                            return {
                                row_id: el.id,
                                id: el.split_shift_type_id,
                                value: el.split_shift_type_id,
                                name: el.split_shift_type,
                                label: el.split_shift_type
                            }
                        })
                    }
                })
                setValues({ [SPLIT_SHIFT]: data })
            }
            setLoading(false)

        } catch (error) {
            setLoading(false)
        }
    }



    useEffect(() => {
        if (idSelected)
            getSplitShiftByIdData()
    }, [idSelected])

    useEffect(() => {
        setPayTypeOption(contextChoices?.pay_type)
    }, [contextChoices?.pay_type])

    const handleSubmit = async () => {
        if (errors.length) {
            setShowError(true)
            setCheckRequires(true)
            setErrorMessage(InvalidOrEmptyValues)
            return;
        }
        setLoadingSave(true)
        const body = {
            branch_id: idSelected,
            shift_details: values[SPLIT_SHIFT] && values[SPLIT_SHIFT]?.length ?
                values[SPLIT_SHIFT]?.map(item => {
                    return {
                        ...(item.id && { id: item?.id}),
                        shift_type: item?.shift_type,
                        split_shift: item?.split_shift_type && item?.split_shift_type?.length ?
                            item?.split_shift_type?.map(el => {
                                return {
                                    ...(el.row_id && { id: el?.row_id }),
                                    split_shift_type: el?.id
                                };
                            }) : []
                    };
                }) : []
        };

        try {
            const res = await setSplitShiftApi(body)
            if (res?.message === "Split Shift created successfully") {
                onSuccess(res.message)
            } else {
                throw new Error("Something went wrong")
            }
        } catch (error) {
            onError(error)
        }
    }



    const onSuccess = (message) => {
        // getSplitShiftByIdData()
        setModalValue(false)
        loadSplitShift()
        setLoadingSave(false);
        setCheckRequires(false)
        setShowError(false)
        setButtonIcon('');
        setButtonIcon(CHECK);
        setTextStatus(SAVED);
        toast.current && toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: message,
        });
    }


    const onError = err => {
        setLoadingSave(false);
        setShowError(false)
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        toast.current && toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: err ? err : `Something went wrong!`,
        });
        setCheckRequires(false)
    };
    console.log(values[SPLIT_SHIFT],"SPLIT_SHIFT")
    return (
        <ModalContainer max_width={'70%'}>
            <ModalNoInfo
                {...fullBind}
                max_width={'100%'}
                minWidthTitle="8.5rem"
                title={"Add Allow Overlap"}
                loading={loading}
                padding={true}
            //   rowInfoData={headerInfo}
            >
                {permission.read ? (
                    <div className='w-100 '>
                        <Toast ref={toast} position='top-right' />

                        <div className='w-100 d-flex justify-content-end'>
                            {permission.create &&
                                <PrimaryButtonForm
                                    className={'ms-3 mt-1 mb-2'}
                                    bgcolor="BLUE"
                                    // disabled={values.privacy.length > 3}
                                    style={{ width: '5px', marginTop: "0px" }}
                                    onClick={() => addNewShift()}>
                                    <span>{<BsFillPlusSquareFill />}NEW ALLOW OVERLAP</span>
                                </PrimaryButtonForm>}
                        </div>
                        <Header marginTop={"0rem"} marginBottom={"1rem"}>  </Header>
                        <div className='mt-2'>
                            <ComponentDynamic loading={loading}>
                                {values[SPLIT_SHIFT]?.length >= 1 &&
                                    values[SPLIT_SHIFT]?.map((item, index) => {
                                        return (
                                            <div>
                                                <AddForm
                                                    useInput={useInput}
                                                    readOnly={isExist ? !permission.update : !permission.create}
                                                    payTypeOption={payTypeOption}
                                                    values={values}
                                                    index={index}
                                                    setValues={setValues}
                                                    SPLIT_SHIFT={SPLIT_SHIFT}
                                                    options={options}
                                                    setOptions={setOptions}
                                                    setFormErrors={setFormErrors}
                                                    errors={errors}
                                                    toast={toast}
                                                />
                                            </div>
                                        )
                                    })}
                                <div>
                                    <div
                                        className={'ms-0 ms-md-3 me-2 mt-3'}
                                        style={{ display: 'flex', justifyContent: 'right', marginTop: '5px', height: "46px", }}>
                                        <div className={'mb-3'}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'right',
                                                marginRight: '4px',

                                            }}>
                                            {(showError && errors.length) ? (
                                                <ErrorMessage style={{ color: "#e44011" }}>
                                                    <label className={'mt-2'}>{errorMessage}</label>
                                                </ErrorMessage>
                                            ) : (
                                                <Fragment />
                                            )}
                                        </div>

                                        <PrimaryButtonForm
                                            minWidth="6rem"
                                            disabled={isExist ? !permission.update : !permission.create}
                                            onClick={handleSubmit}
                                            style={{ marginBottom: '5px' }}>
                                            <span className={'me-2'}>{textStatus}</span>
                                            {!loadingSave ? (
                                                buttonIcon
                                            ) : (
                                                <span
                                                    className="spinner-border spinner-border-sm me-1"
                                                    role="status"
                                                />
                                            )}
                                        </PrimaryButtonForm>

                                    </div>
                                </div>

                            </ComponentDynamic>
                        </div>
                    </div>
                ) : (
                    <div className='d-flex w-100 justify-content-center'>
                        <NoPermissionContainer>
                            You don't have permission to read the information.
                        </NoPermissionContainer>
                    </div>
                )}
            </ModalNoInfo>
        </ModalContainer>
    );
};

export default SplitShiftModal;
