import React, {useEffect, useState, useRef ,useContext} from 'react';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {KeyPress} from 'utils/constants/regularExpressions';
import {ESV, ESV_TRAVEL, EVV, EVV_TRAVEL} from 'utils/constants/routes';
import {LARGE_COL,MEDIUM_COL} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import {
  HeaderVisits,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
  PublishButton
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import {getEvvTravel,getEvvExport, getESvExport} from 'utils/api/EvvApi';
import {useHistory, useLocation} from 'react-router-dom';
import {
  Row,
  Col,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import Pagination from 'components/SharedComponents/Pagination';
import queryString from 'query-string';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {getAdmin,getSuperAdmin,getStaff} from "../../../../utils/localStorage/token"
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import {DOWNLOAD} from 'utils/constants/icons';
import NoSelectAllDropdownFilter from  'components/SharedComponents/NoSelectAllDropdownFilter'; 
import {AppContext} from "context"
import {BranchItemsContext} from "context"  
import moment from 'moment';
import { calender_esv_shift } from 'utils/choiceConstant';
const EvvTravel = ({
  values,
  setValues,
  useInput,
  dropDownHandleSelect,
  events,
  choices,
  clients,
  employees,
  eventClickHandler,
  loadEvvs,
  loadingCalendar,
  filterClient,
  filterEmployee,
  pages,
  runRouteData,
  branch,
  setFilterBranch,
	setRunroutefilter,
  branchFilter,
  runRoutesFilter,
  loadingModal,
  setFiltersetVisitStatus,
visitStatusFilter,
setFiltersetTravelMode,
travelModeFilter,
setFiltersetTravelCode,
travelCodeFilter,
visitStatus,
employeeFilter,
setFiltersetEmployee,
shiftTypeFilter,
setFiltersetShiftType,
}) => {
  const columns_values = [
    // {
    //   label: 'Visit #',
    //   name: 'visit',
    //   status: 0,
    //   className: 'grow-mobile',
    //   max_width: LARGE_COL,
    // },
    // {
    //   label: 'Client name',
    //   name: 'client__full_name',
    //   status: 1,
    //   className: 'grow-mobile',
    //   max_width: LARGE_COL,
    // },
    {
      label: 'Employee name',
      name: 'employee__full_name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Start date',
      name: 'start_date',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'End date',
      name: 'end_date',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Travel code',
      name: 'travel_code',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Travel mode',
      name: 'travel_mode',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Journey Start Postcode',
      name: 'journey_start_zipcode',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Journey End Postcode',
      name: 'journey_end_zipcode',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Scheduled Distance (Miles)',
      name: 'schedule_travel_miles',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Scheduled Travel Time (h:mm)',
      name: 'schedule_travel_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Scheduled Travel Start Time (h:mm)',
      name: 'start_travel_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Scheduled Travel End Time (h:mm)',
      name: 'end_travel_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Actual Distance (Miles)',
      name: 'actual_travel_miles',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Actual Travel Time (h:mm)',
      name: 'actual_time',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
  ];

  //added  4 datas  of travel to dispalyed -usestate below 
  const [numTravels, setNumTravels] = useState(0);

  const [sumTravelDuration, setSumTravelDuration] = useState(0);
  const [sumTravelDistance, setSumTravelDistance] = useState(0);
  const [numClients, setNumClients] = useState(0);
  const[downloadDisable,setDownloadDisable]=useState(false)
  const [numEmployees, setNumEmployees] = useState(0);
  const[currentPage,setCurrentPage]=useState(1);
  const[clearAll,setClearAll]=useState(false);
  const {headerFilterClearValue}=useContext(BranchItemsContext) ;
  const [travelModeOptions, setTravelModeOptions] = useState([]);

  const {branchData}=useContext(BranchItemsContext) ;
  const {contextChoices, handleGetChoices, setContextChoices,contextIsAdmin,contextIsSuperAdmin,contextIsStaff} = useContext(AppContext);

const mountedRef = useRef(true);
let location=useLocation()
const history=useHistory();
const filterAdmin= contextIsAdmin;
const filterSuperAdmin= contextIsSuperAdmin;
const filterStaff= contextIsStaff;
useEffect(() => {
  handleGetChoices(calender_esv_shift);
}, []);

useEffect(()=>{
  values.limitperpage =PAGE_SIZE_RENDER;
  setValues({...values})
  },[])


  
  useEffect(() => {
    if(values.client_id || values.employee_id ||   values.visit_no  || values.runroutes || values.branch | values.date_from || values.date_to ||  location.search   || values.ordering ||  values.visitstatus || values.travel_mode || values.travel_code || values.shiftType){ 
    const obj_travel = {
      date_from: values.date_from,
      date_to: values.date_to,
      client_id: values.client_id,
      employee_id: values.employee_id,
      visit_no: values.visit_no,
      runroutes: values.runroutes,
      branch: values.branch,
      visitstatus:  values.visitstatus,
      travel_code: values.travel_code,
      travel_mode: values.travel_mode

    };
    const pageToSearch = getPageToSearchParam(location.search);
    loadEvvs({...branchData,...obj_travel,...pageToSearch}, ESV + ESV_TRAVEL,values.limitperpage);
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.date_from,
    values.date_to,
    values.client_id,
    values.employee_id,
    values.visit_no,
    values.runroutes,
    values.branch,
    values.visitstatus,
    values.travel_mode,
    values.travel_code,
    values.shiftType,
    values.limitperpage,
    location.search,
    values.ordering,
  ]);


  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };
  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =  offset * values.limitperpage; return {
      offset,
    };
  };

  // useEffect( ()=>{
  //   if(values.client_id || values.employee_id ||   values.visit_no  || values.runroutes || values.branch | values.date_from || values.date_to ||  location.search   || values.ordering  ||  values.visitstatus || values.travel_mode || values.travel_code){ 
  //   displayData();
  //   }
  // },[values.date_from,
  //   values.date_to,
  //   values.client_id,
  //   values.employee_id,
  //   values.visit_no,
  //   values.runroutes,
  //   values.visitstatus,
  //   values.branch,
  //   values.travel_mode,
  //   values.travel_code,
  //   branchData,
  //   location.search,
  //   values.ordering,
  // ])


    const displayData = () => { 
      getEvvTravel({...branchData, ...values}).then(response => {
           if (!mountedRef.current) return null;
     setNumTravels(Math.ceil(response.travel_count)) ;
     setSumTravelDuration((response.sum_of_travel_durations).toPrecision()) ;
     setSumTravelDistance((response.travel_distance).toPrecision()) ;
     setNumClients(Math.ceil(response.no_of_client)) ;
     setNumEmployees(Math.ceil(response.no_of_employee)) ;
         });
       };
    

  

      const dropDownHandleSelectPagination=(item,namesection)=>{
        const urlParams = queryString.parse(location.search);
        urlParams[PAGINATION_PARAM] = 1;
        const stringified = queryString.stringify(urlParams);
        history.push({
          pathname: "/esv/travel",
          search: stringified,
        });
        values.limitperpage=item[namesection]
        setValues({...values});
      }
      
      
      const handleDownload = () => {
        let fileName = "Employee_Travel_Report_" + values.date_from + "_" + values.date_to
        setDownloadDisable(true)
        getESvExport(values.date_from, values.date_to,'Travel').then(response => {
          response.blob().then(blob => {
            console.log(blob, "response")
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            console.log(url);
            a.href = url;
            a.download = fileName;
            a.click();
            setDownloadDisable(false)
          })
        }
        )
          .catch(error => {
            setDownloadDisable(false)
          })
      }
  return (
    <div>
      <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
           <FiltersContainer>
            <div
              className={'ms-1 me-3 me-md-0 flex-wrap d-flex justify-content-center'}>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ms-1 me-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('date_from')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ms-1 me-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date to"
                    {...useInput('date_to')}
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>
              {/* {((filterAdmin || filterSuperAdmin || filterStaff) || filterClient==='null') &&
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                 <DropdownTypesContainer className="ms-1 me-1">
                  <DropdownSearchable
                    typeFilter={true}
                    placeHolder={'Client name'}
                    options={clients?clients:[]}
                    selectedOption={values.client_id}
                    onChange={value =>
                      dropDownHandleSelect({
                        client_id: value ? value : null,
                      })
                    }
                  />
                </DropdownTypesContainer>

              </FiltersLeftGroup>
} */}
            
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                  {((filterAdmin || filterSuperAdmin)) &&
                <DropdownTypesContainer className="ms-1 me-1">
                  {/* <DropdownSearchable
                    typeFilter={true}
                    placeHolder={'Employee name'}
                    options={employees?employees: []}
                    selectedOption={values.employee_id}
                    onChange={value =>
                      dropDownHandleSelect({
                        employee_id: value ? value : null,
                      })
                    }
                  /> */}
                  <NoSelectAllDropdownFilter
                    items={employees?employees:[]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee_id: items });
                    }}
                    itemsSelected={employeeFilter}
                    setSelectedFilter={setFiltersetEmployee}
                    placeHolder={'Employee name'}
                    isObject
                    allOption
   
                  />
                </DropdownTypesContainer>
}<DropdownTypesContainer>
                <NoSelectAllDropdownFilter
                    items={visitStatus?visitStatus:[]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visitstatus: items });
                    }}
                    itemsSelected={visitStatusFilter}
                    setSelectedFilter={setFiltersetVisitStatus}
                    placeHolder={'Shift status'}
                    isObject
                    allOption
   
                  />
                </DropdownTypesContainer>
              <DropdownTypesContainer>
              <NoSelectAllDropdownFilter
                    items={contextChoices.travel_type ? contextChoices.travel_type : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({travel_mode: items ? items : null},  'travel_mode');
                    }}
                    itemsSelected={travelModeFilter}
                    setSelectedFilter={setFiltersetTravelMode}
                    placeHolder={'Travel mode'}
                    isObject
                    allOption
   
                  />
            </DropdownTypesContainer>
            <DropdownTypesContainer>
              <NoSelectAllDropdownFilter
                    items={[
                      {id: 'Home-Office', name: 'Home Office'},
                      {id: 'Client-Office', name: 'Client Office'},
                      {id: 'Office-Office', name: 'Office Office'}
                    ]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({travel_code: items});
                    }}
                    itemsSelected={travelCodeFilter}
                    setSelectedFilter={setFiltersetTravelCode}
                    placeHolder={'Travel code'}
                    isObject
                    allOption
   
                  />
            </DropdownTypesContainer>
            <DropdownTypesContainer>
                    <NoSelectAllDropdownFilter
                      items={
                        contextChoices && contextChoices.regular_shift_type
                          ? contextChoices.regular_shift_type
                          : []
                      }
                      setItemsSelected={items => {
                        dropDownHandleSelect({shiftType: items});
                      }}
                      itemsSelected={shiftTypeFilter}
                      setSelectedFilter={setFiltersetShiftType}
                      placeHolder={'Shift Type'}
                      isObject
                      allOption
                      // hitApiAfterClear={hitApiAfterClear}
                    />
                  </DropdownTypesContainer>
                {/* <DropdownTypesContainer className="ms-1 me-1">
                  <InputLabelTop
                    no_internal_margin="true"
                    type="text"
                    typeFilter={true}
                    label={'Visit #'}
                    {...useInput('visit_no')}
                    onKeyPress={KeyPress.INTEGER}
                  />
                </DropdownTypesContainer> */}
              </FiltersLeftGroup>
         {/* <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ms-1 me-2">
                  <NoSelectAllDropdownFilter
                    items={branch ?branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ branch: items });
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
            
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                <NoSelectAllDropdownFilter
                    items={runRouteData?runRouteData:[]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ runroutes: items });
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Group Area'}
                    isObject
                    allOption
               
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup> */}
            </div>
          </FiltersContainer>
        </div>
        <PublishButton className=" ms-auto ps-2 me-4" disable={downloadDisable} onClick={!downloadDisable && handleDownload}>
  {DOWNLOAD}
</PublishButton>
      </HeaderVisits>
{/* travel dat a displayed below  */}
      {/* visit data dispalyed below  */}
      {/* <div>
          <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-start" >
        <div className="d-flex align-items-center me-2">
        </div>
        <div className="data-container">
          <div className="me-2">
            <p>{' Travels: ' +  numTravels  }</p>
          </div>
        </div>

        <div className="d-flex align-items-center me-2 ps-2" >
        </div>
        <div className="data-container">
          <div className="me-2">
            <p>{' Travel (hrs) : ' +   sumTravelDuration   }</p>
          </div>
        </div>

        <div className="d-flex align-items-center me-2 ps-2">
        </div>
        <div className="data-container">
          <div className="me-2">
            <p>{'Distance: ' +   sumTravelDistance  }</p>
          </div>
        </div>


        <div className="d-flex align-items-center me-2 ps-2">
        </div>
        <div className="data-container">
          <div className="me-2">
            <p>{'Clients: ' +   numClients }</p>
          </div>
        </div>

        <div className="d-flex align-items-center me-2  ps-2">
        </div>
        <div className="data-container">
          <div className="me-2">
            <p>{'Employees: ' +   numEmployees }</p>
          </div>
        </div>
        <PublishButton  className=" ms-auto ps-2 me-4" disable={downloadDisable}  onClick={!downloadDisable && handleDownload}>
            {DOWNLOAD}
        </PublishButton>
</DataContainer>

          </div> */}
      <div className={'mt-5'}>
      <>    <ComponentDynamic loading={loadingModal} >
      <Table
        headerColumns={columns_values}
        headerPadding={false}
        noEditOption
        fontSize="0.75rem"
        callBackQuerySort={setQuerySort}
        >
          <div>
          {events && events.map((evvTravel,index)=>{
             const   schedule_start_Date = evvTravel.start_date
             ? moment(evvTravel.start_date).format('YYYY-MM-DD')
             : ' ';  
 
             const   schedule_end_date = evvTravel.end_date
             ? moment(evvTravel.end_date).format('YYYY-MM-DD')
             : ' ';  
            return (
              <div key={index}>
              <Row className="mb-1" bgColor>
              <ColsGrouper
                    className="d-flex"
                    //onClick={()=>eventClickHandler(evvTravel)}
                    >
                   {/* <Col className="sm-hidden" max_width={LARGE_COL} Left   overlap={true}>
                        {evvTravel.visit_obj && evvTravel.visit_obj.visit_number}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Left   overlap={true}>
                        {evvTravel.client_obj && evvTravel.client_obj.full_name}
                        </Col> */}
                        <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                        {evvTravel.employee && evvTravel.employee}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                        {schedule_start_Date}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center    overlap={true}>
                        {schedule_end_date}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Left   overlap={true}>
                        {evvTravel.travel_code}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                        {evvTravel.travel_mode && choices.travel_type && choices.travel_type.find((item)=>evvTravel.travel_mode===item.id) && choices.travel_type.find((item)=>evvTravel.travel_mode===item.id).name} 
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Left   overlap={true}>
                        {evvTravel.journey_start_zipcode}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Left   overlap={true}>
                        {evvTravel.journey_end_zipcode}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                        {evvTravel.schedule_travel_miles}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                        {evvTravel.schedule_travel_time
                        ?evvTravel.schedule_travel_time.substring(0, 5)
                        :''
                        }
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                        {evvTravel.schedule_travel_start_datetime
                        ? ((evvTravel.schedule_travel_start_datetime === 'None') ? '' : moment(evvTravel.schedule_travel_start_datetime).format('HH:mm'))
                        :''
                        }
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                        {evvTravel.schedule_travel_end_datetime
                        ?((evvTravel.schedule_travel_end_datetime === 'None') ? '' : moment(evvTravel.schedule_travel_end_datetime).format('HH:mm'))
                        :''
                        }
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center   overlap={true}>
                        {evvTravel.actual_travel_miles}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center  overlap={true}>
                        {evvTravel.actual_travel_time
                        ?evvTravel.actual_travel_time.substring(0, 5)
                        :''
                        }
                        </Col>
                    </ColsGrouper>
                    </Row>
                    </div>
            )
          })}
                   <div className="mt-3">
<Pagination t
 totalPages={pages}  
currentPage={currentPage}    
values={values}  
     dropDownHandleSelect={dropDownHandleSelectPagination} 
         number={PAGE_SIZE_RENDER}    
         dataLength={events.length}
         />
</div>
          </div>
          </Table>
          </ComponentDynamic></>
      </div>
    </div>
  );
};

EvvTravel.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  eventClickHandler: PropTypes.func.isRequired,
  loadEvvs: PropTypes.func.isRequired,
  loadingCalendar: PropTypes.bool.isRequired,
};

export default EvvTravel;
