import React, { useEffect, useState, useContext } from 'react';
import Dropdown from 'components/SharedComponents/Dropdown';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import { ESV, ESV_GPS, EVV, EVV_GPS } from 'utils/constants/routes';
import { LARGE_COL } from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import queryString from 'query-string';
import { PAGINATION_PARAM } from 'utils/constants/queryParams';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { useHistory, useLocation } from 'react-router-dom';
import {
  HeaderVisits,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  PublishButton,
  DataContainer
} from 'shared/styles/constants/tagsStyles';
import {
  Row,
  Col,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import PropTypes from 'prop-types';
import { DOWNLOAD } from 'utils/constants/icons';
import { getAdmin, getSuperAdmin, getStaff } from "../../../../utils/localStorage/token"
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { getESvExport, getEvvExport, getEvvVisits } from 'utils/api/EvvApi';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import { AppContext } from "context"
import { BranchItemsContext } from "context"
import moment from 'moment';
import { calender_esv_shift } from 'utils/choiceConstant';


const EvvGps = ({
  values,
  setValues,
  useInput,
  dropDownHandleSelect,
  events,
  clients,
  employees,
  eventClickHandler,
  loadEvvs,
  loadingCalendar,
  filterClient,
  filterEmployee,
  pages,
  choices,
  runRouteData,
  branch,
  setFilterBranch,
  setRunroutefilter,
  branchFilter,
  runRoutesFilter,
  loadingModal,
  setFiltersetVisitStatus,
visitStatusFilter,
visitStatus,
employeeFilter,
setFiltersetEmployee,
shiftTypeFilter,
setFiltersetShiftType,
}) => {
  const [numVisits, setNumVisits] = useState(0);
  const [sumVisitDuration, setSumVisitDuration] = useState(0);
  const [numClients, setNumClients] = useState(0);
  const [numEmployees, setNumEmployees] = useState(0);
  const [currentPage, setCurrentPage] = useState(1)
  const [downloadDisable, setDownloadDisable] = useState(false)
  const { branchData } = useContext(BranchItemsContext);
  const {contextChoices, handleGetChoices, setContextChoices,contextIsAdmin,contextIsSuperAdmin,contextIsStaff} = useContext(AppContext);
  const { headerFilterClearValue } = useContext(BranchItemsContext);
  let location = useLocation();
  const history = useHistory();
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const filterStaff = contextIsStaff;
  useEffect(() => {
    handleGetChoices(calender_esv_shift);
  }, []);

  const columns_values = [
    // {
    //   label: 'Visit #',
    //   name: 'visit',
    //   status: 0,
    //   className: 'grow-mobile',
    //   max_width: LARGE_COL,
    // },
    // {
    //   label: 'Client name',
    //   name: 'client__full_name',
    //   status: 1,
    //   className: 'grow-mobile',
    //   max_width: LARGE_COL,
    // },
    {
      label: 'Employee name',
      name: 'employee__full_name',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Start date',
      name: 'start_date',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'End date',
      name: 'end_date',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock in method',
      name: 'actual_start_location',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock in location',
      name: 'clock_in_location',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock in verification',
      name: 'actual_start_verification',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock out method',
      name: 'clock_out_method',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock out location',
      name: 'actual_end_location',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
    {
      label: 'Clock out verification',
      name: 'actual_end_verification',
      status: 0,
      className: 'grow-mobile',
      max_width: LARGE_COL,
    },
  ];


  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [])



  useEffect(() => {
    if (values.client_id || values.employee_id || values.visit_no || values.runroutes || values.branch || values.date_from || values.date_to || location.search || values.ordering || values.visitstatus || values.shiftType) {
      const obj_gps = {
        date_from: values.date_from,
        date_to: values.date_to,
        client_id: values.client_id,
        employee_id: values.employee_id,
        clock_in_verification_status: values.clock_in_verification_status,
        clock_out_verification_status: values.clock_out_verification_status,
        visit_no: values.visit_no,
        runroutes: values.runroutes,
        branch: values.branch,
        visitstatus:  values.visitstatus
      };
      const pageToSearch = getPageToSearchParam(location.search);
      loadEvvs({ ...branchData, ...obj_gps, ...pageToSearch }, ESV + ESV_GPS, values.limitperpage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.date_from,
    values.date_to,
    values.client_id,
    values.employee_id,
    values.visit_no,
    values.clock_in_verification_status,
    values.clock_out_verification_status,
    values.runroutes,
    values.branch,
    branchData,
    values.visitstatus,
    values.shiftType,
    values.limitperpage,
    location.search,
    values.ordering,
  ]);

  // useEffect(() => {
  //   if (values.client_id || values.employee_id || values.visit_no || values.runroutes || values.branch | values.date_from || values.date_to || location.search || values.ordering || values.visitstatus) {
  //     displayData();
  //   }
  // }, [
  //   values.date_from,
  //   values.date_to,
  //   values.client_id,
  //   values.employee_id,
  //   values.visit_no,
  //   values.runroutes,
  //   values.branch,
  //   branchData,
  //   location.search,
  //   values.visitstatus,
  //   values.ordering,
  //   values.clock_in_verification_status,
  //   values.clock_out_verification_status,
  // ])


  const displayData = () => {
    getEvvVisits({ ...branchData, ...values }).then(response => {

      setNumVisits(Math.ceil(response.no_of_visit));
      setSumVisitDuration(Math.round((response.visit_duration + Number.EPSILON) * 100) / 100);
      setNumClients(Math.ceil(response.no_of_client));
      setNumEmployees(Math.ceil(response.no_of_employee));

    });
  };


  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =  offset * values.limitperpage; return {
      offset,
    };
  };
  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };


  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/esv/gps",
      search: stringified,
    });
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }

  const handleDownload = () => {
    let fileName = "Employee_GPS_Report_" + values.date_from + "_" + values.date_to
    setDownloadDisable(true)
    getESvExport(values.date_from, values.date_to,'GPS').then(response => {
      response.blob().then(blob => {
        console.log(blob, "response")
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        console.log(url);
        a.href = url;
        a.download = fileName;
        a.click();
        setDownloadDisable(false)
      })
    }
    )
      .catch(error => {
        setDownloadDisable(false)
      })
  }
  return (
    <div>
      <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
           <FiltersContainer>
            <div
              className={'ms-1 me-3 me-md-0 flex-wrap d-flex justify-content-center'}>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ms-1 me-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('date_from')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer className="ms-1 me-1">
                  <DateSelector
                    typeFilter={true}
                    labelTop="Date to"
                    {...useInput('date_to')}
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup>
              <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                  {/* {((filterAdmin || filterSuperAdmin || filterStaff) || filterClient==='null') &&
                <DropdownTypesContainer className="ms-1 me-1">
                  <DropdownSearchable
                    typeFilter={true}
                    placeHolder={'Client name'}
                    options={clients?clients:[]}
                    selectedOption={values.client_id}
                    onChange={value =>
                      dropDownHandleSelect({
                        client_id: value ? value : null,
                      })
                    }
                  />
                </DropdownTypesContainer>
} */}
              {((filterAdmin || filterSuperAdmin)) &&
                <DropdownTypesContainer className="ms-1 me-1">
                  <NoSelectAllDropdownFilter
                    items={employees?employees:[]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ employee_id: items });
                    }}
                    itemsSelected={employeeFilter}
                    setSelectedFilter={setFiltersetEmployee}
                    placeHolder={'Employee name'}
                    isObject
                    allOption
   
                  />
                </DropdownTypesContainer>
}
<DropdownTypesContainer>
                <NoSelectAllDropdownFilter
                    items={visitStatus?visitStatus:[]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ visitstatus: items });
                    }}
                    itemsSelected={visitStatusFilter}
                    setSelectedFilter={setFiltersetVisitStatus}
                    placeHolder={'Shift status'}
                    isObject
                    allOption
   
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                    <NoSelectAllDropdownFilter
                      items={
                        contextChoices && contextChoices.regular_shift_type
                          ? contextChoices.regular_shift_type
                          : []
                      }
                      setItemsSelected={items => {
                        dropDownHandleSelect({shiftType: items});
                      }}
                      itemsSelected={shiftTypeFilter}
                      setSelectedFilter={setFiltersetShiftType}
                      placeHolder={'Shift Type'}
                      isObject
                      allOption
                      // hitApiAfterClear={hitApiAfterClear}
                    />
                  </DropdownTypesContainer>
              </FiltersLeftGroup>

              {/* <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ms-1 me-1">
                  <Dropdown
                    items={[
                      {id: '', name: 'All'},
                      {id: true, name: 'True'},
                      {id: false, name: 'False'},
                    ]}
                    onSelect={item => {
                      dropDownHandleSelect({
                        clock_in_verification_status: item,
                      });
                    }}
                    placeHolder={'Clock in verification status'}
                    appendItem=""
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                 <DropdownTypesContainer className="ms-1 me-1">
                  <Dropdown
                    items={[
                      {id: '', name: 'All'},
                      {id: true, name: 'True'},
                      {id: false, name: 'False'},
                    ]}
                    onSelect={item => {
                      dropDownHandleSelect({
                        clock_out_verification_status: item,
                      });
                    }}
                    placeHolder={'Clock out verification status'}
                    appendItem=""
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup> */}

              {/* <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ms-1 me-1">
                  <InputLabelTop
                    no_internal_margin="true"
                    type="text"
                    typeFilter={true}
                    label={'Visit #'}
                    {...useInput('visit_no')}
                    onKeyPress={KeyPress.INTEGER}
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup> */}
         {/* <FiltersLeftGroup
                className={'d-flex justify-content-center mb-2'}>
                <DropdownTypesContainer className="ms-1 me-2">
                  <NoSelectAllDropdownFilter
                    items={branch?branch:[]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ branch: items });
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
      
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                <NoSelectAllDropdownFilter
                    items={runRouteData?runRouteData:[]}
                    setItemsSelected={items => {
                      dropDownHandleSelect({ runroutes: items });
                    }}
                    itemsSelected={runRoutesFilter}
                    setSelectedFilter={setRunroutefilter}
                    placeHolder={'Group Area'}
                    isObject
                    allOption
             
                  />
                </DropdownTypesContainer>
              </FiltersLeftGroup> */}
            </div>
          </FiltersContainer> 
        </div>
        <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-start">


<PublishButton className=" ms-auto ps-2 me-4" disable={downloadDisable} onClick={!downloadDisable && handleDownload}>
  {DOWNLOAD}
</PublishButton>
</DataContainer>
      </HeaderVisits>
      {/* <div>
          <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-start" >
        <div className="d-flex align-items-center me-2">
        </div>
        <div className="data-container">
          <div className="me-2">
            <p>{'Visits: ' +   numVisits }</p>
          </div>
        </div>

        <div className="d-flex align-items-center me-2 ps-2" >
        </div>
        <div className="data-container">
          <div className="me-2">
            <p>{' Visit (hrs) : ' +  sumVisitDuration   }</p>
          </div>
        </div>

        <div className="d-flex align-items-center me-2 ps-2">
        </div>
        <div className="data-container">
          <div className="me-2">
            <p>{'Clients: ' +   numClients  }</p>
          </div>
        </div>

        <div className="d-flex align-items-center me-2  ps-2">
        </div>
        <div className="data-container">
          <div className="me-2">
            <p>{' Employees: ' +   numEmployees }</p>
          </div>
        </div>
        <PublishButton  className=" ms-auto ps-2 me-4" disable={downloadDisable}  onClick={!downloadDisable && handleDownload}>
            {DOWNLOAD}
        </PublishButton>
</DataContainer>

          </div> */}

      <div className={'mt-5'}>
        <>    <ComponentDynamic loading={loadingModal} >
          <Table
            headerColumns={columns_values}
            headerPadding={true}
            noEditOption
            fontSize="0.75rem"
            callBackQuerySort={setQuerySort}
          >
            <div>
              {events && events.map((evvGps, index) => {
                const schedule_start_Date = evvGps.start_date
                  ? moment(evvGps.start_date).format('YYYY-MM-DD')
                  : ' ';

                const schedule_end_date = evvGps.end_date
                  ? moment(evvGps.end_date).format('YYYY-MM-DD')
                  : ' ';
                return (
                  <div key={index}>
                    <Row className="mb-1" bgColor>
                      <ColsGrouper
                        className="d-flex"
                      // onClick={()=>eventClickHandler(evvGps)}
                      >
                        {/* <Col className="sm-hidden" max_width={LARGE_COL} Left  overlap={true}>
                        {evvGps.visit_obj && evvGps.visit_obj.visit_number}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Left  overlap={true}>
                        {evvGps.client_obj && evvGps.client_obj.full_name}
                        </Col> */}
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvGps.employee && evvGps.employee}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {schedule_start_Date}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {schedule_end_date}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {'Manual'}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvGps.actual_start_location && ((evvGps.actual_start_location === 'None') ? '' : evvGps.actual_start_location)}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvGps.start_time_verification && ((evvGps.start_time_verification === 'None') ? '' : evvGps.start_time_verification)}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {'Manual'}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvGps.actual_end_location && ((evvGps.actual_end_location === 'None') ? '' : evvGps.actual_end_location)}
                        </Col>
                        <Col className="sm-hidden" max_width={LARGE_COL} Center overlap={true}>
                          {evvGps.end_time_verification && ((evvGps.end_time_verification === 'None') ? '' : evvGps.end_time_verification)}
                        </Col>
                      </ColsGrouper>
                    </Row>
                  </div>
                )
              }
              )}
              <div className="mt-3">
                <Pagination totalPages={pages}
                  currentPage={currentPage}
                  values={values}
                  dropDownHandleSelect={dropDownHandleSelectPagination}
                  number={PAGE_SIZE_RENDER}
                  dataLength={events.length}
                />
              </div>
            </div>
          </Table>
        </ComponentDynamic></>
      </div>
    </div>
  );
};

EvvGps.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired,
  dropDownHandleSelect: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  clients: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  eventClickHandler: PropTypes.func.isRequired,
  loadEvvs: PropTypes.func.isRequired,
  loadingCalendar: PropTypes.bool.isRequired,
};

export default EvvGps;
