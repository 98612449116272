import React, {useEffect, useState, useRef, useCallbac, useContext } from 'react';
import {LARGE_COL, SMALL_COL, MEDIUM_COL,EX_LARGE_COL,COL_CONTAIN_PARA,COL_CONTAIN_WORD,EX_SMALL_COL} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {
  Row,
  Col,
  IconWrapper,
} from 'components/SharedComponents/Table/styles';
import {
  HeaderVisits,
  Title,
  DataContainer,
} from 'shared/styles/constants/tagsStyles';
import {getCategoryPermission , getPermissions} from '../../../../../utils/localStorage/user';
import {
  SETTINGS_ALGO_SCORE,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_PERMISSION,
  WEB_UPDATE_PERMISSION,
  WEB_READ_PERMISSION,
} from '../../../../../utils/constants/permissions';
import {getAdmin, getSuperAdmin} from "../../../../../utils/localStorage/token"
import {getAlgoScore,statusAlgo,resetAlgoRow,resetAllAlgo} from 'utils/api/SettingsApi';
import {MdEdit} from 'react-icons/md';
import {BiReset} from 'react-icons/bi'
import {ResetButton}from'components/SharedComponents/ProgressInformation/styles'
import {useForm} from 'hooks/FormHook';
import {useModal} from 'hooks/ModalHook';
import AddModal from './AddModal'
import {AppContext} from "context"  

const headerColumns = [
  {
    label: 'ALGORITHM RULES',
    name: 'algorithm_rules',
    status: 0,
    className: 'grow-mobile',
    max_width: COL_CONTAIN_PARA,
  },
  {
    label: 'MIN(HRS)',
    name: 'min_time',
    status: 0,
    className: 'sm-hidden    grow-mobile   ',
    max_width: COL_CONTAIN_WORD,
  },

  {
    label: 'MAX(HRS)',
    name: 'max_time',
    status: 0,
    className: 'sm-hidden   grow-mobile ',
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'CONSTRAINTS',
    name: 'score_level',
    status: 0,
    className: ' grow-mobile   ',
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'REWARD',
    name: 'reward',
    status: 0,
    className: ' md-hidden  grow-mobile ' ,
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'PENALISE',
    name: 'penalise',
    status: 0,
    className: 'sm-hidden    grow-mobile ',
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'UNASSIGNED VIOLATED',
    name: 'consider_hard_rule',
    status: 0,
    className: 'md-hidden   grow-mobile  '  ,
    max_width: COL_CONTAIN_WORD,
  },
  {
    label: 'STATUS',
    name: 'status',
    status: 0,
    className: 'md-hidden   '  ,
    max_width: COL_CONTAIN_WORD,
  },
  
];

function  Wtr( ) {
  const {values, setValues, useInput,setCheckRequires} = useForm({});  //used useInput
  const [wtr, setWtr] =  useState([ ]);
  const [update, setUpdatePermissions] = useState(false);
  const [idSelected, setIdSelected] = useState('');
  const {contextPermissions, contextIsAdmin, contextIsSuperAdmin, contextIsStaff}=useContext(AppContext) ;

  const filterAdmin= contextIsAdmin;
  const filterSuperAdmin= contextIsSuperAdmin;
  const[numberValue,setNumberValue]=useState() ;

  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind,
  } = useModal(false);
  const {setValue: setModalDeleteValue, bind: bindModalDelete} = useModal(
    false,
  );
  
  const fullNameCol='algorithm_rules';
  const tabName='Working Time Restrictions';

  const handleModal = () => {
      setModalValue(!modalValue);
    }

  useEffect(() => {

    const roles = contextPermissions;
    let rolePermissions = {};
    if (roles.length > 0) {
      roles.forEach(item => {
        if (item?.section?.name === SETTINGS_ALGO_SCORE) {
          rolePermissions[SETTINGS_ALGO_SCORE] = {};
          rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION] = {};
          rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
            WEB_READ_PERMISSION
          ] = item?.[WEB_READ_PERMISSION];
          rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
            WEB_CREATE_PERMISSION
          ] = item?.[WEB_CREATE_PERMISSION];
          rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
            WEB_DELETE_PERMISSION
          ] = item?.[WEB_DELETE_PERMISSION];
          rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
            WEB_UPDATE_PERMISSION
          ] = item?.[WEB_UPDATE_PERMISSION];
        }
      });


      if(filterAdmin  == true  ||  filterSuperAdmin == true  ){
        setUpdatePermissions(true);
      }
      else {

      // if(rolePermissions[SETTINGS_ALGO_SCORE] && rolePermissions  [SETTINGS_ALGO_SCORE][WEB_PERMISSION])
      setUpdatePermissions(
        rolePermissions[SETTINGS_ALGO_SCORE][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ]
      );
      }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadAlgoScore();
}, [
  values.ordering, 
]);

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  }

  useEffect(()=>{
    values.ordering='algorithm_rules';
    setNumberValue(1);
      setValues({...values});
     },[])
     
useEffect(()=>{
if(numberValue == 0){
values.ordering='-algorithm_rules';
setValues({...values});
}
},[numberValue])


  const changeNumber=(value)=>{
    setNumberValue(value);
  }
  

  const loadAlgoScore = () => {
     getAlgoScore({...values},tabName).then(response => {
      setWtr( response['Working Time Restrictions'])
    });
  };

  const handleRowClick = async(id , status,togglename ) => { 
    if(status ==null || status ==false){
      status = true;
    }else{
      status = false;
    }
         await  statusAlgo(id, status,togglename ) 
         loadAlgoScore()
         } 


         const handleRowClickEdit = id => {
          setIdSelected(id);
          setModalValue(true);
          // setValues({...values});
        };
    
        const toAlgoClientrRowData =async (id )=> {
          // setModalDeleteValue(true);
          // setIdToDelete(id);
                await resetAlgoRow( id);
                // setModalDeleteValue(false);
                loadAlgoScore();
        };
    
        const resetAll =async ( )=> {
          // setModalDeleteValue(true);
          // setIdToDelete(id);
                await resetAllAlgo('Working Time Restrictions');
                // setModalDeleteValue(false);
                loadAlgoScore();
        };
    



    return (
        <div>
<div   className="d-flex justify-content-end"  data-toggle="tooltip" data-placement="top" title="Reset All" >
<ResetButton    onClick={resetAll} >
   <BiReset/>
  </ResetButton>
</div>
      <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-start">
      </DataContainer>
      <Table
        headerColumns={headerColumns}
        headerPadding={false}
        fontSize="0.75rem"
        callBackQuerySort={setQuerySort} 
        statusNumber={numberValue}   setStatusNumber={changeNumber}     fullNameCol={fullNameCol}
        >
        <div>
          {wtr && wtr.map((wtrs, index) => {

            return (
              <div key={index}>
                <Row bgColor Shadow>
                  <Col
                    max_width={COL_CONTAIN_PARA}
                    // Shrink
                    // reducePadding
                    Capitalize
                    Center>
                    {
                       wtrs.algorithm_rules && wtrs.algorithm_rules
                      }
                  </Col>
                  <Col
                  max_width={COL_CONTAIN_WORD}
                    // Shrink
                    // reducePadding
                    className="sm-hidden"
                    Capitalize
                    Center>
                     {
                      wtrs.min_time  &&  wtrs.min_time
                      }
                  </Col>
                  <Col
                   max_width={COL_CONTAIN_WORD}
                    // Shrink
                    // reducePadding
                    className="sm-hidden"
                    Capitalize
                    Center>
                     {
                      wtrs.max_time  &&  wtrs.max_time
                      }
                  </Col>
                  <Col
                      max_width={COL_CONTAIN_WORD}
                    //   Shrink
                    // reducePadding
                    // className="   "
                    Capitalize
                    >
                  {
                       wtrs.score_level &&  wtrs.score_level
                      }
                  </Col>
                  <Col
                    max_width={COL_CONTAIN_WORD}
                    // Shrink
                    // reducePadding
                    className="md-hidden"
                    Capitalize
                    Center>
                     {
                      wtrs.reward  &&     wtrs.reward  
                      }
                  </Col>
                  <Col
                   max_width={COL_CONTAIN_WORD}
                    // Shrink
                    // reducePadding
                    className="sm-hidden"
                    Capitalize
                    Center>
                    {
                      wtrs.penalise &&  wtrs.penalise
                      }
                  </Col>
                  <Col
                        max_width={COL_CONTAIN_WORD}
                       Shrink
                       reducePadding
                       className="md-hidden"
                       Capitalize
                       Center>
                         {/* <div  className="me-4 d-flex justify-content-center"> */}
                         {update && (
                       <ToggleButton  
                       onClick= {()=> handleRowClick(wtrs.id , wtrs.consider_hard_rule,'consider_hard_rule')}
                   status = { wtrs.consider_hard_rule}
                       />          )}
                       {!update && ( wtrs.status ?'Active' : 'InActive' ) }
                       {/* </div>  */}
                    </Col>
                       
                  <Col
                        max_width={COL_CONTAIN_WORD}
                       Shrink
                       reducePadding
                       className="md-hidden"
                       Capitalize
                       Center>
                         {/* <div  className="me-4 d-flex justify-content-center"> */}
                         {update && (
                       <ToggleButton  
                       onClick= {()=> handleRowClick(wtrs.id , wtrs.status,'status')}
                   status = { wtrs.status}
                       />          )}
                       {!update && ( wtrs.status ?'Active' : 'InActive' ) }
                       {/* </div>  */}
                    </Col>

                    <Col   className="sm-hidden" Center Icon Purple     Shrink
                       reducePadding>  
                     <IconWrapper 
                     onClick={() => {
                      handleRowClickEdit(wtrs.id);
                    }}
                      >
                     <MdEdit    />
                     </IconWrapper>
                     </Col>
      
                  <Col  className="sm-hidden"  Center Shrink 
                  NoFlexGrow   reducePadding> 
              <div   data-toggle="tooltip" data-placement="top" title="Reset " >
              <IconWrapper    onClick={() => {
                  toAlgoClientrRowData(wtrs.id );
                }}   >
                     <BiReset/>
                     </IconWrapper>
                     </div>
                     </Col> 

                </Row>
                <div className="mb-1" />
              </div>
            );
           })} 
         
        </div>
      </Table>
      {!modalValue ? null : (
        <AddModal
          idSelected={idSelected}
          setModalValue={handleModal}
          setAlgoClientModalValue={setModalValue}
          fullBind={fullBind}        
          setTitle={setTitle}
          setIdSelected={setIdSelected}
          values={values}
          useInput={useInput}
          loadAlgoScore={loadAlgoScore}
        />
      )}
        </div>
    )
}

export default  Wtr;
