import React from 'react';
import Template from 'components/SharedComponents/SettingsTemplate';
import {
  getServiceCategories,
  deleteServiceCategory,
  setServiceCategory,
  getVisitType,
  deleteVisitType,
  setVisitType,
  getVisitTypePriority,
  deleteVisitTypePriority,
  setVisitTypePriority,
  getRunNames,
  setRunName,
  deleteRunName
} from 'utils/api/SettingsApi';

const Priority = ({
  deletePermissionPrioritySettings,
  readPermissionPrioritySettings,
  updatePrioritySettings,
  filterAdmin,
  filterSuperAdmin,
   userStaff,
   createPrioritySettings,
   handleChangeChoices
}) => {
  return (
    <div>
      <div className="row p-0 m-0">
        {/* <div className="col-xl-6 col-lg-6 ps-0 pe-0 ps-lg-1 pe-lg-1 pb-3">
          <Template
            titleSection={'Visit type'}
            labelInputTitle={'Visit type name'}
            getMethod={getVisitType}
            deleteMethod={deleteVisitType}
            setMethod={setVisitType}
            textToMessageDelete={'visit type'}
            textToEmptyDataValues={'No visits types have been added'}
            fieldAttributes={{maxLength: 50}}
            handleChangeChoices={()=>handleChangeChoices('visit_type')}
            updatePrioritySettings={updatePrioritySettings}
            createPrioritySettings={createPrioritySettings}
            deletePermissionPrioritySettings={deletePermissionPrioritySettings}
            readPermissionPrioritySettings={readPermissionPrioritySettings}
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
             userStaff={userStaff}
          />
        </div>
        <div className="col-xl-6 col-lg-6 ps-0 pe-0 ps-lg-1 pe-lg-1 pb-3">
          <Template
            titleSection={'Service category'}
            labelInputTitle={'Service category name'}
            getMethod={getServiceCategories}
            deleteMethod={deleteServiceCategory}
            setMethod={setServiceCategory}
            textToMessageDelete={'service category'}
            textToEmptyDataValues={'No services categories have been added'}
            fieldAttributes={{maxLength: 50}}
            handleChangeChoices={()=>handleChangeChoices('service_category')}
            updatePrioritySettings={updatePrioritySettings}
            createPrioritySettings={createPrioritySettings}
            deletePermissionPrioritySettings={deletePermissionPrioritySettings}
            readPermissionPrioritySettings={readPermissionPrioritySettings}
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
             userStaff={userStaff}
          />
        </div> */}
        <div className="col-xl-6 col-lg-6 ps-0 pe-0 ps-lg-1 pe-lg-1 pb-3">
          <Template
            titleSection={'Run Name'}
            labelInputTitle={'Run name'}
            getMethod={getRunNames}
            deleteMethod={deleteRunName}
            setMethod={setRunName}
            textToMessageDelete={'run name'}
            fieldAttributes={{maxLength: 50}}
            updatePrioritySettings={updatePrioritySettings}
            handleChangeChoices={()=>handleChangeChoices('run_view')}
            createPrioritySettings={createPrioritySettings}
            deletePermissionPrioritySettings={deletePermissionPrioritySettings}
            readPermissionPrioritySettings={readPermissionPrioritySettings}
            filterAdmin={filterAdmin}
            filterSuperAdmin={filterSuperAdmin}
             userStaff={userStaff}
          />
        </div>
      </div>
    </div>
  );
};

export default Priority;
