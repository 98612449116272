import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { useModal } from 'hooks/ModalHook';
import React from 'react';
import { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {
    ErrorMessage,
    FlexGrid3Item,
    ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import { addBulkEventTask, updateEventTask } from 'utils/api/SettingsApi';
import { FLOPPY } from 'utils/constants/icons';

const eventDropdownValues = [
    { id: 'ON-BOARDING EMPLOYEE', name: 'ON-BOARDING EMPLOYEE' },
    { id: 'OFF-BOARDING EMPLOYEE', name: 'OFF-BOARDING EMPLOYEE' },
    { id: 'ON-BOARDING CLIENT', name: 'ON-BOARDING CLIENT' },
    { id: 'OFF-BOARDING CLIENT', name: 'OFF-BOARDING CLIENT' },
];

function AddEventTask({
    fullBind,
    useInput,
    values,
    setValues,
    setModalValue,
    toast,
    choices,
    getEventTaskListData,
    rowClicked,
    allEvents,
    readOnly,
    getAllEvents,
    errors,
    setCheckRequires,
    errorMessage,
    setErrorMessage,
    showError,
    setShowError,
    ADD_MESSAGE,
    UPDATE_MESSAGE,
    SOMETHING_WENT_WRORNG
}) {
    const [loadingSave, setLoadingSave] = useState(false);
    const [updateEventTaskValue, setUpdateEventTaskValue] = useState([])
    const [saveDisabled, setSaveDisabled] = useState(false)

    const {
        setValue: setModalEventExistsValue,
        bind: bindModalEventExists
    } = useModal(false);

    const handleSubmit = async (force) => {

        if (errors.length) {
            setCheckRequires(true)
            setShowError(true)
            setErrorMessage('Some of the fields have invalid or empty values. Please review');
            return;
        }
        setSaveDisabled(true)
        setLoadingSave(true);
        const body = {
            branch_id: values.branch,
            event: values.event,
            event_hr_task: values.tasks.map((i) => ({ core_hr_task: i })),
            force: force,
        };
        try {
            const res = await addBulkEventTask(body);
            setLoadingSave(false);
            setSaveDisabled(false)
            if (res.status) {
                console.log(res, "is force")
                if (res?.is_force) {
                    setModalEventExistsValue(true)
                } else {
                    setModalEventExistsValue(false)
                    getEventTaskListData()
                    setErrorMessage("")
                    getAllEvents()
                    setModalValue(false);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: ADD_MESSAGE,
                    });
                }
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: SOMETHING_WENT_WRORNG,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpadate = async () => {
        setLoadingSave(true);
        setSaveDisabled(true)
        const body = {
            branch_id: values?.branch,
            event_id: values?.event_id,
            event_hr_task: updateEventTaskValue,
            event: values?.event
        };
        try {
            const res = await updateEventTask(body);
            setLoadingSave(false);
            setSaveDisabled(false)
            if (res.status) {
                getEventTaskListData()
                setModalValue(false);
                getAllEvents()
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: UPDATE_MESSAGE,
                });
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Failed',
                    detail: SOMETHING_WENT_WRORNG,
                });
            }
        } catch (error) {
            console.error(error);
        }
    };


    const dropDownHandleSelect = (item, name) => {
        if (name == 'branch') {
            values.branch = item.branch;
        } else if (name == 'event') {
            values.event = item.event;
        } else if (name == 'tasks') {
            values.tasks = item?.tasks?.map(item => item.value);
        }
        setValues({ ...values });
    };
    useEffect(() => {
        if (rowClicked?.length) {
            const res = values?.tasks?.map(el => {
                let obj = {}
                let a = rowClicked?.map(item => {
                    if (item.core_hr_task === el) {
                        obj.core_hr_task = item.core_hr_task
                        obj.id = item.id
                    } else {
                        obj.core_hr_task = el
                    }
                })

                return obj
            })
            setUpdateEventTaskValue(res)
        }
    }, [rowClicked, values])


    const handleWarningEventExists = () => {
        handleSubmit(true);
    }

    console.log(values, "values")
    return (
        <div>

            <ModalContainer max_width={'60%'}>
                <ModalNoInfo {...fullBind} minWidthTitle="8.5rem" loading={false}>
                    <div
                        style={{ padding: "0 1rem 1rem 1rem" }}
                        className="d-flex flex-column w-100 mt-2">
                        <div className="w-100 d-flex">
                            <div className=" w-50 pe-1 mb-2">
                                <DropdownSearchable
                                    required
                                    {...useInput(`branch`, 'isRequired')}
                                    placeHolder={'Branch'}
                                    disabled={readOnly}
                                    options={choices?.branch}
                                    selectedOption={values.branch ? values.branch : null}
                                    onChange={value =>
                                        dropDownHandleSelect({ branch: value ? value : null }, 'branch')
                                    }
                                />
                            </div>
                            <div className="w-50 pe-1 mb-2">
                                <DropdownSearchable
                                    required
                                    {...useInput(`event`, 'isRequired')}
                                    placeHolder={'Event'}
                                    disabled={readOnly}
                                    options={eventDropdownValues}
                                    selectedOption={values.event ? values.event : null}
                                    onChange={value =>
                                        dropDownHandleSelect({ event: value ? value : null }, 'event')
                                    }
                                />
                            </div>
                        </div>
                        <div className="w-100 ">
                            <FlexGrid3Item className="pe-1 mb-2">
                                <DropdownSearchable
                                    required
                                    isMulti={true}
                                    {...useInput(`tasks`, 'isRequired')}
                                    placeHolder={'Tasks'}
                                    options={choices?.hr_task?.map(el => ({ ...el, name: el?.title }))}
                                    disabled={readOnly}
                                    selectedOptionsProps={
                                        values.tasks ? values.tasks : null
                                    }
                                    onChange={value =>
                                        dropDownHandleSelect({ tasks: value ? value : null }, 'tasks')
                                    }
                                />
                            </FlexGrid3Item>
                        </div>
                        <div
                            className={
                                'd-flex justify-content-center justify-content-md-end mt-3'
                            }>
                            <div className={'mb-3'}>
                                {showError ? (
                                    <ErrorMessage>
                                        <label className={'mt-2'}>{errorMessage}</label>
                                    </ErrorMessage>
                                ) : (
                                    <Fragment />
                                )}
                            </div>
                            <div className={'ms-0 ms-md-3 me-2'}>
                                <PrimaryButtonForm
                                    minWidth="6rem"
                                    disabled={readOnly || saveDisabled}
                                    onClick={() => rowClicked ? handleUpadate() : handleSubmit(false)}
                                    style={{ marginBottom: '5px' }}>
                                    <span className={'me-2'}>SAVE</span>
                                    {!loadingSave ? (
                                        FLOPPY
                                    ) : (
                                        <span
                                            className="spinner-border spinner-border-sm me-1"
                                            role="status"
                                        />
                                    )}
                                </PrimaryButtonForm>
                            </div>
                        </div>
                    </div>
                </ModalNoInfo>
            </ModalContainer>

            <ModalDecision

                title="Warning"
                // isdisabled={isdisabledDeleteButton}
                body={'Event already exists, Do you want to create a new?'}
                onOk={() => handleWarningEventExists()}
                onCancel={() => { setModalValue(false) }}
                okTitle={'Confirm'}
                {...bindModalEventExists}
            />
        </div>
    );
}

export default AddEventTask;
