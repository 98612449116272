import React, { useState, useEffect, useRef, useContext, Fragment } from 'react';
import moment from 'moment';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { Toast } from 'primereact/toast';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { AppContext, BranchItemsContext } from 'context';

import {
  getBranchById,
  getCompanyById,
  setCompany,
  setCompanyBranch, setCompaniesLicense, getCompanyLicense, getCompanyLicenseById
} from 'utils/api/SettingsApi';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
} from 'shared/styles/constants/tagsStyles';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';
import Geocode from 'react-geocode';
const edit_company = 'Edit Company License';
const new_company = 'Add Company License';

const CompanyLicensePopup = ({
  fullBind,
  idSelected,
  company_pk,
  setTitle,
  loadBranches,
  setIdSelected,
  COMPANY_NOT_FOUND,
  handleChangeChoices,
  setIsChecked,
  setModalValue,
  setDataChecking,
  loadCompany
}) => {
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});

  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const { contextChoices } = useContext(AppContext);
  const [dataRequire, setDataRequire] = useState(false)
  const toast = useRef();
  useEffect(() => {
    if (idSelected) {
      loadBranchById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(idSelected, "idSelected")
  Geocode.setApiKey(process.env.REACT_APP_GOOGLEAPI_KEY);
  Geocode.setLanguage('en');
  useEffect(() => {
    if (values) {
      if (values.line_1 && values.city && values.country && values.zip_code) {
        const final_add =
          values.line_1 +
          ' ' +
          values.city +
          ' ' +
          values.country +
          ' ' +
          values.zip_code;
        Geocode.fromAddress(final_add).then(
          response => {
            const { lat, lng } = response.results[0].geometry.location;
            values.latitude = lat;
            values.longitude = lng;
            setValues({ ...values });
          },
          error => {
            console.error(error);
          }
        );
      }
    }
  }, [
    values && values.line_1,
    values && values.city,
    values && values.country,
    values && values.zip_code,
  ]);
  useEffect(() => {
    if (idSelected) {
      setTitle(edit_company);
    } else {
      setTitle(new_company);
      setLoadingModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSelected]);

  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const loadBranchById = async () => {
    const response = await getCompanyLicenseById(idSelected);

    setLoadingModal(false);
    setValues({ ...values, ...response });
  };

  const handleSubmit = async () => {
    if (!company_pk) {
      setErrorMessage(COMPANY_NOT_FOUND);
      return;
    }

    if (!isValid && errors.length > 0) {
      setErrorMessage('Some of the fields have invalid or empty values. Please review');
      setCheckRequires(true);
      return;
    }

    setLoadingSave(true);
    let body = values.id
      ? {
        id: values.id,
        license_expiration_date: values.license_expiration_date,
        no_active_clients: values.no_active_clients,
        no_active_employees: values.no_active_employees,
        type_scheduling: values.type_scheduling,
        account_name: values.account_name,
        account_no: values.account_no,
        sort_code_no: values.sort_code_no,

      }
      : {
        license_expiration_date: values.license_expiration_date,
        no_active_clients: values.no_active_clients,
        no_active_employees: values.no_active_employees,
        type_scheduling: values.type_scheduling,
        account_name: values.account_name,
        account_no: values.account_no,
        sort_code_no: values.sort_code_no,
        company: values.company,
      };
    let response = await setCompaniesLicense(body);
    console.log(response, '2222');

    if (response.status == true) {
      setLoadingSave(false);
      loadCompany()
      setButtonIcon(CHECK);
      setTextStatus(SAVED);
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Saved Successfully',
      });
      // setIdSelected(response.id);
      // setDataChecking(true)

      setIsChecked(true)
    } else {
      setLoadingSave(false);

      setButtonIcon(CROSS);
      setTextStatus(ERROR);
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });
    }
    setIsChecked(true);
    setTimeout(() => {
      setModalValue()
    }, 1000)
  };
  const dropDownHandleSelect = (item) => {
    setValues({ ...values, ...item })
  }
  return (
    <ModalContainer max_width={'60%'}>
      <ModalDynamic
        {...fullBind}
        max_width={'60%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className="ps-3 pe-3 pb-3 pt-0">
          <Toast ref={toast} position="top-right" />
          <FlexGrid3Container className={'w-100 mb-2'}>
            <FlexGrid3Item className="pe-1">
              <DropdownSearchable
                required
                disabled={idSelected ? true : false}
                {...useInput(`company`, 'isRequired')}
                placeHolder={'Company Name'}
                options={contextChoices.company}
                selectedOption={values.company}
                onChange={value =>
                  dropDownHandleSelect({ company: value ? value : null }, 'company')
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1">
              <DateSelector
                id="licence_expiration_date"
                labelTop={'License expiry date'}
                {...useInput(`license_expiration_date`)}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1">
              <InputLabelTop
                type="text"
                id="no_active_clients"
                label={'No. of active clients'}
                onKeyPress={KeyPress.INTEGER}
                {...useInput(`no_active_clients`, 'isInt')}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-0 ps-md-1 pe-1 pe-md-0">
              <InputLabelTop
                type="text"
                id="no_active_employees"
                label={'No. of active employees'}
                onKeyPress={KeyPress.INTEGER}
                {...useInput(`no_active_employees`, 'isInt')}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-0 pe-md-1 ps-1 ps-md-0">
              <InputLabelTop
                type="text"
                id="type_scheduling"
                label={'Type – Scheduling'}
                {...useInput(`type_scheduling`)}
                maxlength={50}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-0 ps-md-1 pe-1">
              <InputLabelTop
                type="text"
                id="account_name"
                label={'Bank account name'}
                {...useInput(`account_name`)}
                maxlength={50}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1">
              <InputLabelTop
                type="text"
                id="account_no"
                label={'Bank account No.'}
                {...useInput(`account_no`)}
                maxlength={50}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1">
              <InputLabelTop
                type="text"
                id="sort_code_no"
                label={'Sort code Number'}
                {...useInput(`sort_code_no`)}
                maxlength={50}
              />
            </FlexGrid3Item>



          </FlexGrid3Container>

          <div
            className={
              'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>
            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ms-0 ms-md-3 me-2'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                    buttonIcon
                  ) : (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                  )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>
  );
};

export default CompanyLicensePopup;
