import React from 'react'
import { FaPlus } from 'react-icons/fa'
import { FloatingMobileButton, PrimaryButtonForm } from 'shared/styles/buttons'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import DropDownTableOptions from '../FormDesigns/dropDownTableOptions'
import DropDownTableValue from '../FormDesigns/dropDownTableValue'
import { HTML_NAMES } from '../constants'
import {
    Unstable_NumberInput as BaseNumberInput,
    numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import { Box, Button, ButtonGroup, Checkbox, Fade, FormControlLabel, FormGroup, FormLabel, Modal, Radio, RadioGroup, Switch, Typography } from '@mui/material'
import ReactDatePicker from 'react-datepicker'
import { IconWrapper } from 'shared/styles/constants/tagsStyles'
import { RiDeleteBinLine } from 'react-icons/ri'
import { ERROR } from 'shared/styles/constants/colors'
import './../formdata.css'
const blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const StyledInputRoot = styled('div')(
    ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 400;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
        };
    display: grid;
    grid-template-columns: 1fr 19px;
    grid-template-rows: 1fr 1fr;
    overflow: hidden;
    column-gap: 8px;
    padding: 4px;
  
    &.${numberInputClasses.focused} {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
    }
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const StyledInputElement = styled('input')(
    ({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.5;
    grid-column: 1/2;
    grid-row: 1/3;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: inherit;
    border: none;
    border-radius: inherit;
    padding: 8px 12px;
    outline: 0;
  `,
);

const StyledButton = styled('button')(
    ({ theme }) => `
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    appearance: none;
    padding: 0;
    width: 19px;
    height: 19px;
    font-family: system-ui, sans-serif;
    font-size: 0.875rem;
    line-height: 1;
    box-sizing: border-box;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 0;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
      cursor: pointer;
    }
  
    &.${numberInputClasses.incrementButton} {
      grid-column: 2/3;
      grid-row: 1/2;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid;
      border-bottom: 0;
      border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  
      &:hover {
        cursor: pointer;
        color: #FFF;
        background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
        border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
      }
    }
  
    &.${numberInputClasses.decrementButton} {
      grid-column: 2/3;
      grid-row: 2/3;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid;
      border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    }
  
    &:hover {
      cursor: pointer;
      color: #FFF;
      background: ${theme.palette.mode === 'dark' ? blue[600] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[400] : blue[600]};
    }
  
    & .arrow {
      transform: translateY(-1px);
    }
  
    & .arrow {
      transform: translateY(-1px);
    }
  `,
);


const TableData = ({ setJson, json, choiceList, contextChoices, InputFields, setOpenSignature }) => {
    const addNewColumn = () => {
        setJson({
            ...json, table: {
                ...json.table,
                cols: [...json.table.cols, { column_name: '' }],
            }
        })
    }

    const deleteNewColumn = (item, index) => {
        delete json.table.cols[index]
        setJson({
            ...json
        })
    }

    const CustomNumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
        return (
            <BaseNumberInput
                slots={{
                    root: StyledInputRoot,
                    input: StyledInputElement,
                    incrementButton: StyledButton,
                    decrementButton: StyledButton,
                }}
                slotProps={{
                    incrementButton: {
                        children: '▴',
                    },
                    decrementButton: {
                        children: '▾',
                    },
                }}
                {...props}
                ref={ref}
            />
        );
    });

    const renderAddOptionsDropdown = (item = {}, index) => {
        return (
            <FormControl style={{ marginBottom: '20px', marginLeft: '32px' }}>
                <div>
                    {
                        item?.manual_options?.map((item_opt, index_opt) => {
                            return (
                                <>
                                    <div style={{ display: 'flex', marginTop: '5px' }} >
                                        <TextField
                                            id="outlined-error"
                                            label="Option value*"
                                            placeholder='Option value'
                                            value={item_opt.label}
                                            style={{ marginRight: 10 }}
                                            onChange={(event) => {
                                                let col = json.table.cols ? [...json.table.cols] : []
                                                col[index].manual_options[index_opt].label = event.target.value
                                                setJson({
                                                    ...json, table: {
                                                        ...json.table,
                                                        cols: col,
                                                        rows: [{}]
                                                    }
                                                })
                                            }}
                                        />
                                        <IconWrapper
                                            onClick={() => {
                                                let col = json.table.cols ? [...json.table.cols] : []
                                                col[index].manual_options = (json.table.cols && json.table.cols[index] && json.table.cols[index]?.manual_options) ? [...json.table.cols[index]?.manual_options, {}] : [{}];
                                                setJson({
                                                    ...json, table: {
                                                        ...json.table,
                                                        cols: col,
                                                        rows: [{}]
                                                    }
                                                })
                                            }}>
                                            <RiDeleteBinLine />
                                        </IconWrapper>
                                    </div>
                                </>
                            )
                        })

                    }

                </div>
                <div style={{ marginTop: 10 }}>

                    <FloatingMobileButton onClick={() => {
                        let col = json.table.cols ? [...json.table.cols] : []
                        col[index].manual_options = (json.table.cols && json.table.cols[index] && json.table.cols[index]?.manual_options) ? [...json.table.cols[index]?.manual_options, {}] : [{}];
                        setJson({
                            ...json, table: {
                                ...json.table,
                                cols: col,
                                rows: [{}]
                            }
                        })
                    }}>
                        <FaPlus />
                        <label>
                            ADD OPTIONS
                        </label>
                    </FloatingMobileButton>
                </div>
            </FormControl>
        )
    }

    const renderTableRow = (item = {}, index) => {
        switch (item?.type) {
            case HTML_NAMES.headingText:
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FormControl style={{ flex: 1 }} >
                            <InputLabel id="demo-simple-select-label">Tag</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={json.table.cols[index]?.tag}
                                label="Tag"
                                onChange={(event) => {
                                    let col = json.table.cols ? [...json.table.cols] : []
                                    col[index].tag = event.target.value;
                                    setJson({
                                        ...json, table: {
                                            ...json.table,
                                            cols: col,
                                            rows: [{}]
                                        }
                                    })
                                }}
                            >
                                <MenuItem value={"h1"}>H1</MenuItem>
                                <MenuItem value={"h2"}>H2</MenuItem>
                                <MenuItem value={"h3"}>H3</MenuItem>
                                <MenuItem value={"h4"}>H4</MenuItem>
                                <MenuItem value={"h5"}>H5</MenuItem>
                                <MenuItem value={"h6"}>H6</MenuItem>
                            </Select>
                        </FormControl>

                    </div>
                )

            case HTML_NAMES.hyperLink:
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        {<p style={{ marginBottom: 8, fontSize: 12 }}>
                            <input
                                type="text"
                                id="myInput"
                                name="inputBox"
                                value={item.placeholder}
                                placeholder={'Label'}
                                style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                                onChange={(event) => {
                                    let col = json.table.cols ? [...json.table.cols] : []
                                    col[index].placeholder = event.target.value;
                                    setJson({
                                        ...json, table: {
                                            ...json.table,
                                            cols: col,
                                            rows: [{}]
                                        }
                                    })
                                }}
                            />

                            <input
                                type="text"
                                id="myInput"
                                name="inputBox"
                                value={item.description}
                                placeholder={'Link'}
                                style={{ padding: 13, borderRadius: 6, borderWidth: .5, borderStyle: "solid", borderColor: "#c8c8c8" }}
                                onChange={(event) => {
                                    let col = json.table.cols ? [...json.table.cols] : []
                                    col[index].description = event.target.value;
                                    setJson({
                                        ...json, table: {
                                            ...json.table,
                                            cols: col,
                                            rows: [{}]
                                        }
                                    })
                                }}
                            />
                        </p>}
                    </div>
                )
            case HTML_NAMES.radioGroup:
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            {
                                item?.options?.map((val, index) => {
                                    return (
                                        <FormControlLabel key={index} value={val.value} control={<Radio />} label={val.value} />
                                    )
                                })
                            }
                        </RadioGroup>
                    </div>
                )
            case HTML_NAMES.checkbox:
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <FormGroup row >
                            {
                                item?.options?.map((val, index) => {
                                    return (
                                        <FormControlLabel control={<Checkbox />} label={val.value} />
                                    )
                                })
                            }
                        </FormGroup>
                    </div>
                )

            case HTML_NAMES.signature:
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button onClick={() => setOpenSignature(true)} variant={item.html}>{item.label}</Button>
                    </div>
                )
            case HTML_NAMES.datePicker:
                console.log(json.table.cols[index])
                return (
                    <FormControl fullWidth>
                        <InputLabel id="date-format">Date Format</InputLabel>
                        <Select
                            labelId="date-format"
                            id="date-format-id"
                            value={json.table.cols[index]?.format}
                            label="Date Format"
                            onChange={(event) => {
                                let col = json.table.cols ? [...json.table.cols] : []
                                col[index].format = event.target.value;
                                setJson({
                                    ...json, table: {
                                        ...json.table,
                                        cols: col,
                                        rows: [{}]
                                    }
                                })
                            }}
                        >
                            <MenuItem value={""}>System Format</MenuItem>
                            <MenuItem value={"dd-MM-yyyy"}>DD-MM-YYYY</MenuItem>
                            <MenuItem value={"MM-dd-yyyy"}>MM-DD-YYYY</MenuItem>
                            <MenuItem value={"yyyy-MM-dd"}>YYYY-MM-DD</MenuItem>
                        </Select>
                    </FormControl>
                )
            case HTML_NAMES.timePicker:
                return (
                    <FormControl fullWidth>
                        <InputLabel id="time-format">Time Format</InputLabel>
                        <Select
                            labelId="time-format"
                            id="time-format-id"
                            value={json.table.cols[index]?.format}
                            label="Time Format"
                            onChange={(event) => {
                                let col = json.table.cols ? [...json.table.cols] : []
                                col[index].format = event.target.value;
                                setJson({
                                    ...json, table: {
                                        ...json.table,
                                        cols: col,
                                        rows: [{}]
                                    }
                                })
                            }}
                        >
                            <MenuItem value={""}>System Format</MenuItem>
                            <MenuItem value={"hh:mm a"}>12 hours</MenuItem>
                            <MenuItem value={"HH:mm"}>24 hours</MenuItem>
                        </Select>
                    </FormControl>
                )
            case HTML_NAMES.dateTime:
                return (
                    <FormControl fullWidth>
                        <InputLabel id="date-format">Date/Time Format</InputLabel>
                        <Select
                            labelId="date-format"
                            id="date-format-id"
                            value={json.table.cols[index]?.format}
                            label="Date/Time Format"
                            onChange={(event) => {
                                let col = json.table.cols ? [...json.table.cols] : []
                                col[index].format = event.target.value;
                                setJson({
                                    ...json, table: {
                                        ...json.table,
                                        cols: col,
                                        rows: [{}]
                                    }
                                })
                            }}
                        >
                            <MenuItem value={""}>System Format</MenuItem>
                            <MenuItem value={"dd-MM-yyyy hh:mm a"}>DD-MM-YYYY 12h</MenuItem>
                            <MenuItem value={"dd-MM-yyyy HH:mm"}>DD-MM-YYYY 24h</MenuItem>
                            <MenuItem value={"MM-dd-yyyy hh:mm a"}>MM-DD-YYYY 12h</MenuItem>
                            <MenuItem value={"MM-dd-yyyy HH:mm"}>MM-DD-YYYY 24h</MenuItem>
                            <MenuItem value={"yyyy-MM-dd hh:mm a"}>YYYY-MM-DD 12h</MenuItem>
                            <MenuItem value={"yyyy-MM-dd HH:mm"}>YYYY-MM-DD 24h</MenuItem>
                        </Select>
                    </FormControl>
                )
            default:
                return null;
        }
    }


    return (
        <div style={{ display: "flex", flexDirection: "column", marginBottom: 10 }}>
            <TextField
                id="outlined-error"
                label="Input Label *"
                placeholder='Input label'
                defaultValue={json.table.label}
                style={{ marginRight: 10, flex: 1 }}
                onChange={(event) => {
                    console.log(event.target.value)
                    setJson({
                        ...json, table: {
                            ...json.table,
                            label: event.target.value
                        }
                    })
                }}
            />
            <TextField
                id="outlined-multiline-static"
                label="Description"
                className='mb-2 mt-2'
                multiline
                rows={3}
                value={json.table.description}
                placeholder="Description"
                onChange={(event) => {
                    setJson({
                        ...json, table: {
                            ...json.table,
                            description: event.target.value
                        }
                    })
                }}
            />
            <TextField
                id="outlined-multiline-static"
                label="Instruction"
                className='mb-2'
                multiline
                rows={3}
                value={json.table.instruction}
                placeholder="Instruction"
                onChange={(event) => {
                    setJson({
                        ...json, table: {
                            ...json.table,
                            instruction: event.target.value
                        }
                    })
                }}
            />
            {json && json.table && json.table.cols.map((item, index) => {
                return (
                    <>
                        <div className='table-formdata'>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10, alignItems: 'center' }}>
                                <div className='me-2'>
                                    <IconWrapper
                                        style={{ color: ERROR }}
                                        onClick={() => {
                                            deleteNewColumn(item, index)
                                        }}>
                                        <RiDeleteBinLine />
                                    </IconWrapper>
                                </div>
                                <FormControl style={{ flex: 1, marginRight: '10px' }} >
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={json.table.cols[index]?.type}
                                        label="Type"
                                        onChange={(event) => {
                                            let col = json.table.cols ? [...json.table.cols] : []
                                            col[index].type = event.target.value;
                                            // let col_val = json.table.rows ? [...json.table.rows] : [{}]
                                            // col_val[0][`${index}`].type = event.target.value;
                                            setJson({
                                                ...json, table: {
                                                    ...json.table,
                                                    cols: col,
                                                    rows: [{}]
                                                }
                                            })
                                        }}
                                    >
                                        {InputFields.map((inpf) => {
                                            if (inpf.name === HTML_NAMES.table) {
                                                return null
                                            } else {
                                                return (
                                                    <MenuItem value={inpf.name}>{inpf.name}</MenuItem>
                                                )
                                            }
                                        })}
                                    </Select>
                                </FormControl>
                                <TextField
                                    id="outlined-error"
                                    label={`column ${index + 1}`}
                                    placeholder={`column ${index + 1}`}
                                    defaultValue={item?.column_name}
                                    style={{ marginRight: 10 }}
                                    onChange={(event) => {
                                        let col = json.table.cols ? [...json.table.cols] : []
                                        col[index].column_name = event.target.value;
                                        setJson({
                                            ...json, table: {
                                                ...json.table,
                                                cols: col,
                                            }
                                        })
                                    }}
                                />

                                {(json.table.cols[index]?.type == 'Choice Dropdown') &&
                                    <FormControl style={{ flex: 1, marginRight: '10px' }} >
                                        <InputLabel id="demo-simple-select-label">Options</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={json.table.cols[index]?.options}
                                            label="Type"
                                            onChange={(event) => {
                                                let col = json.table.cols ? [...json.table.cols] : []
                                                col[index].options = event.target.value;
                                                setJson({
                                                    ...json, table: {
                                                        ...json.table,
                                                        cols: col,
                                                        rows: [{}]
                                                    }
                                                })
                                            }}
                                        >
                                            {
                                                choiceList && choiceList.map((val) => {
                                                    return (
                                                        <MenuItem value={val.choices}>{val.name}</MenuItem>
                                                    )
                                                })

                                            }
                                        </Select>
                                    </FormControl>
                                }
                                <FormControl style={{ flex: 1 }} >
                                    {renderTableRow(item, index)}
                                </FormControl>



                            </div>
                            {((json.table.cols[index]?.type == HTML_NAMES.dropdown) || (json.table.cols[index]?.type == HTML_NAMES.multidropdown)) && renderAddOptionsDropdown(item, index)}
                            {/* <div className='d-flex' style={{marginLeft:'36px'}}>
                            <div className='d-flex'>
                                <label for="" style={{ marginTop: 'auto' }} >{'Required'}:</label>
                                <Switch
                                    color="primary"
                                    checked={item.required}
                                    onChange={(val) => {
                                        let col = json.table.cols ? [...json.table.cols] : []
                                        col[index].required = val.target.checked
                                        setJson({
                                            ...json, table: {
                                                ...json.table,
                                                cols: col,
                                            }
                                        })

                                    }}
                                />
                            </div>
                            <div className='d-flex ms-2'>
                                <label for="" style={{ marginTop: 'auto' }} >{'ReadOnly'}:</label>
                                <Switch
                                    color="primary"
                                    checked={item.readOnly}
                                    onChange={(val) => {
                                        let col = json.table.cols ? [...json.table.cols] : []
                                        col[index].readOnly = val.target.checked
                                        setJson({
                                            ...json, table: {
                                                ...json.table,
                                                cols: col,
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div> */}
                        </div>
                    </>

                )
            })
            }

            <div style={{ width: '100px', marginTop: '10px' }}>
                <PrimaryButtonForm minWidth="1rem" onClick={addNewColumn}>
                    <FaPlus />
                </PrimaryButtonForm>
            </div>
        </div>
    )
}

export default TableData;
