import React, { Fragment } from 'react';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { PLUS, TIMES } from 'utils/constants/icons';
import PayRateCard from "./PayRateCard"
import { BsFillPlusSquareFill } from "react-icons/bs"
import {
  TitleSection,
  CloseButtonWrapper,
  SampleMessage,
} from 'shared/styles/constants/tagsStyles';
import PropTypes from 'prop-types';
import ServiceTime from './ServiceTime';
import { useState } from 'react';
import { useWindowSize } from 'hooks/useWindowSize';

const ServiceEmployees = ({
  setValues,
  useInput,
  index,
  nameSection,
  values,
  allEmployees,
  dropDownHandleSkill,
  dropDownHandleEmployee,
  dropDownHandleQuantity,
  choices,
  addNewVisitEmployee,
  deleteVisitSkill,
  deleteVisitEmployee,
  indexVisit,
  addedSkillMessage,
  readOnly = false,
  serviceId,
  clientId,
  dropDownHandleGender,
  dropDownHandlePayRate,
  deletePayRate,
  addPayRate,
  billRate,
  payRate,
  addNewRate,
  clientTaskOptions,
  groupTaskOptions,
  plannedTasks, editMode,
  medDoseTimingList,
  templateContract = false,
  shouldRemoveVisitEmployee
}) => {

  const screenSize = useWindowSize();


  if (readOnly && allEmployees && allEmployees.length <= 0) {
    return <Fragment />;
  }

  return (
    <div>
      <div className={'mb-2'}>
        <TitleSection>Employees and Skills Required</TitleSection>
      </div>
      {allEmployees && allEmployees.length >= 1 ? (
        allEmployees.map((data, indexEmployee) => {
          console.log(data, "data")
          return (
            <div key={indexEmployee} className={`mb-2 ${indexEmployee > 0 ? "mt-3" : ""}`}>
              <div className='d-flex'>
                {readOnly ? (
                  <Fragment />
                ) : (
                  <CloseButtonWrapper
                    disabled={!editMode}
                    onClick={() => {
                      // deleteVisitEmployee(data, indexEmployee, indexVisit,serviceId,clientId);
                      shouldRemoveVisitEmployee(data, indexEmployee, indexVisit, serviceId, clientId)
                    }}>
                    {TIMES}
                  </CloseButtonWrapper>
                )}
                <div className={'w-100 d-flex p-0'}>
                  <div className={'w-100'}>
                    <DropdownSearchable
                      disabled={!editMode ||
                        (values.visits[indexVisit].employee_required &&
                          values.visits[indexVisit].employee_required[indexEmployee].preferred_visit_employee)}
                      width={'100%'}
                      placeHolder={'No. of Employees'}
                      options={[
                        { id: 1, name: 1 },
                        { id: 2, name: 2 },
                        { id: 3, name: 3 },
                        { id: 4, name: 4 },
                        { id: 5, name: 5 },
                      ]}
                      selectedOption={
                        (values.visits[indexVisit]
                          .employee_required[indexEmployee] && values.visits[indexVisit]
                            .employee_required[indexEmployee].no_of_employees)
                          ? values.visits[indexVisit]
                            .employee_required[indexEmployee].no_of_employees
                          : null
                      }
                      onChange={value =>
                        dropDownHandleQuantity(
                          { no_of_employees: value ? value : null },
                          indexEmployee
                        )
                      }
                    />
                  </div>
                  <div className='w-100 ms-2'>
                    <DropdownSearchable
                      disabled={!editMode}
                      placeHolder={'Employee Type'}
                      width={'100%'}
                      options={choices.job_title}
                      selectedOption={
                        values.visits[indexVisit]
                          .employee_required
                          ? values.visits[indexVisit]
                            .employee_required[indexEmployee].skill_level
                          : null
                      }
                      onChange={value =>
                        dropDownHandleEmployee(
                          { job_title: value ? value : null },
                          indexEmployee,
                          "job_title"
                        )
                      }
                    />
                  </div>

                  {/* preferred_visit_employee */}
                 {!templateContract && <div className='w-100 ms-2'>
                    <DropdownSearchable
                      disabled={!editMode}
                      placeHolder={'Employee'}
                      width={'100%'}
                      options={choices && choices?.employee_listing ? choices?.employee_listing : []}
                      selectedOption={
                        values.visits[indexVisit]
                          .employee_required
                          ? values.visits[indexVisit]
                            .employee_required[indexEmployee].preferred_visit_employee
                          : null
                      }
                      onChange={value =>{
                        if (value) {
                          values.visits[indexVisit].employee_required[indexEmployee].no_of_employees = 1;
                          setValues({ ...values })
                        }
                        dropDownHandleEmployee(
                          { preferred_visit_employee: value ? value : null },
                          indexEmployee,
                          "preferred_visit_employee"
                        )
                      }} 
                    />
                  </div>}
                  <div className='w-100 ms-2'>
                    <DropdownSearchable
                      disabled={!editMode}
                      // marginLeft={'2.4%'}
                      width={'100%'}
                      placeHolder={'Gender'}
                      options={choices.gender}
                      selectedOption={
                        values.visits[indexVisit] ?
                          values.visits[indexVisit]
                            .employee_required[indexEmployee].preferred_gender
                            ? values.visits[indexVisit]
                              .employee_required[indexEmployee].preferred_gender
                            : null
                          : null
                      }

                      onChange={value =>
                        dropDownHandleGender(
                          { preferred_gender: value ? value : null },
                          indexEmployee
                        )
                      }
                    />
                  </div>

                  <ServiceTime
                    editMode={editMode}
                    indexEmployee={indexEmployee}
                    setValues={setValues}
                    values={values}
                    useInput={useInput}
                    index={index}
                    nameSection={`${nameSection}.employee_required[${indexEmployee}]`}
                  />
                </div>

              </div>
              <div className={'w-100 mt-2'}>
                <DropdownSearchable
                  disabled={!editMode}
                  width={'100'}
                  marginLeft={'2.4%'}
                  placeHolder={'Skills required'}
                  options={choices.skill}
                  isMulti={true}
                  // disabled={
                  //   values.visits[indexVisit]
                  //     .employee_required[indexEmployee].skillEdit
                  //     ? false
                  //     : true
                  // }
                  selectedOptionsProps={
                    values.visits[indexVisit]
                      .employee_required[indexEmployee].skills
                  }
                  onChange={value =>
                    dropDownHandleSkill({ skill: value.length ? value : [] }, indexEmployee)

                  }
                />
              </div>
              <div className={'w-100 mt-2'}>
                <DropdownSearchable
                  disabled={!editMode}
                  width={'100'}
                  marginLeft={'2.4%'}
                  placeHolder={'Competency required'}
                  options={choices.competency_name}
                  isMulti={true}
                  // disabled={
                  //   values.visits[indexVisit]
                  //     .employee_required[indexEmployee].skillEdit
                  //     ? false
                  //     : true
                  // }
                  selectedOptionsProps={
                    values.visits[indexVisit]
                      .employee_required[indexEmployee].competency
                  }
                  onChange={value =>
                    dropDownHandleSkill(
                      { competency: value.length ? value : [] },
                      indexEmployee
                    )

                  }
                />
              </div>
              {/* <div className='w-100 mt-2'>
                <DropdownSearchable
                  disabled={!editMode}
                  width={'100'}
                  marginLeft={'2.4%'}
                  placeHolder={'Medication'}
                  options={medDoseTimingList ? [...medDoseTimingList] : []}
                  // options={choices.medication_dose_timing || []}
                  isMulti={true}
                  selectedOptionsProps={
                    values.visits[indexVisit]
                      .employee_required[indexEmployee].medication
                  }
                  onChange={value =>
                    dropDownHandleSkill(
                      { medication: value.length ? value : [] },
                      indexEmployee
                    )
                  }
                />
              </div> */}
              {/*  <div className='w-100 mt-2 d-flex'>
               

                {addPayRate && data && data.pay_rates && data.pay_rates.map((pay_rate, indexPay) => {
                return (
                  <PayRateCard
                    editMode={editMode}
                    values={values}
                    data={payRate}
                    list_key1={'service_pay_rate'}
                    dropDownHandlePayRate={dropDownHandlePayRate}
                    useInput={useInput}
                    category={'visits'}
                    readOnly={readOnly}
                    nameSection={'pay_rates'}
                    index={indexPay}
                    empIndex={indexEmployee}
                    deletePayRate={deletePayRate}
                    outerIndex={indexVisit}
                    visitRate={true}
                  />
                )

              })
              }
              {(addPayRate && !readOnly) &&
                <PrimaryButtonForm
                  disabled={!editMode}
                  className={'ms-3 mt-1 mb-2'}
                  bgcolor="BLUE"
                  minWidth="3rem"
                  onClick={() => addNewRate('pay_rates', 1, indexEmployee)}
                >
                  <span>{<BsFillPlusSquareFill />}Rate Card</span>
                </PrimaryButtonForm>
              } 
                
              </div>*/}

            </div>
          )
        })
      ) : (
        <Fragment />
      )}
      {readOnly ? (
        <Fragment />
      ) : (
        <Fragment>
          <SampleMessage>{addedSkillMessage}</SampleMessage>
          <div className={'mt-2'}>
            <PrimaryButtonForm minWidth="6rem" onClick={addNewVisitEmployee} disabled={!editMode}>
              {PLUS}
              NEW EMPLOYEE
            </PrimaryButtonForm>
          </div>
        </Fragment>
      )}
    </div>
  );
};

ServiceEmployees.propTypes = {
  values: PropTypes.object.isRequired,
  allEmployees: PropTypes.array.isRequired,
  dropDownHandleSkill: PropTypes.func.isRequired,
  choices: PropTypes.object.isRequired,
  addNewVisitEmployee: PropTypes.func.isRequired,
  deleteVisitSkill: PropTypes.func.isRequired,
  indexVisit: PropTypes.number.isRequired,
  addedSkillMessage: PropTypes.string.isRequired,
};

export default ServiceEmployees;
