import React, {useState, useEffect, useContext} from 'react';
import {EX_SMALL_COL4, LARGE_COL, SMALL_COL} from 'shared/styles/constants/columns';
import {headerColumns, headerColumnsSelector, headerColumnsSelectorByDefault} from './TableHeaders/BillingDetailsHeaderExpanse';
import {PrimaryButton, PrimaryButtonForm} from 'shared/styles/buttons';
import DateSelector from 'components/SharedComponents/DateSelector';
import {useForm} from 'hooks/FormHook';
import queryString from 'query-string';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import {getBillingDetails, billDetailExport, bulkActionPostBillingDetails, getBillingDetailsReimbursement, billDetailExportReimbursement} from 'utils/api/finance';
import Pagination from 'components/SharedComponents/Pagination';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import {AppContext, BranchItemsContext} from 'context';
import {
  HeaderVisits,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
} from 'shared/styles/constants/tagsStyles';
import Table from 'components/SharedComponents/Table';
import {Row, Col} from 'components/SharedComponents/Table/styles';
import {useHistory, useLocation} from 'react-router-dom';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { getPayRates } from 'utils/api/ClientApi';
import ConfirmDecision from 'components/SharedComponents/ConfirmDecision';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useModal } from 'hooks/ModalHook';
import AssignBulk from './Modal/AssignBulk';
import { getClientFundersList } from 'utils/api/ClientApi';
import NoSelectAllDropdownColumnSelector from 'components/SharedComponents/NoSelectAllDropdownColumnSelector';

const BillingExpanse = props => {
  const [detailData, setDetailData] = useState([]);
  const {values, setValues, useInput, errors, isValid, setCheckRequires} =
    useForm({});
  const [pages, setPages] = useState(0);

  const [clientStatusFilter, setClientStatusFilter] = useState([]);


  const [clientFilter,setClientFilter]=useState([])
  const [scheduleAmount, setScheduleAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingModalExport, setLoadingModalExport] = useState(false);

  const [branchFilter, setBranchFilter] = useState([]);
  const [checkedRows, setCheckedRows] = useState([])
  const [checkedValue, setCheckedValue] = useState(true)
  const {contextChoices: choices} = useContext(AppContext);
  const history = useHistory();
  const [funderList,setFunderList]=useState([])
  const [bulkActionKeys, setBulkActionKeys] = useState({})
  const [billRate, setBillRate] = useState([]);
  const [payRate, setPayRate] = useState([]);
  const [travelRate, setTravelRate] = useState([]);
  const headerSelectedData = headerColumns.map((val)=>(val.columnSelected && val.id))
  const [runTableOptionData, setrunTableOptionData] = useState(headerSelectedData);
  const [dropdownArraySelected, setDropdownArraySelected] = useState(headerColumns.filter((val)=>val.columnSelected));
  const { setValue: setModalBulkConfirmation, bind: bindModalBulkConfirmation } = useModal(false);
  const toast = useRef()
  const {
    setValue: setActionPopup,
    value: ActionPopup,
    fullBind: fullBindActionPopup,
  } = useModal(false);

  const { branchData } = useContext(BranchItemsContext);

  useEffect(() => {
    if (values.date_from && values.date_to) {
      handleClick()
    }
  }, [branchData?.headerbranch?.length,
    values.limitperpage,
    props.location.search,
    values.ordering
  ]);

  useEffect(()=>{
    const urlParams = queryString.parse(props.location.search);
      urlParams[PAGINATION_PARAM] = 1;
      const stringified = queryString.stringify(urlParams);
      history.push({
        pathname: '/finance/billing/details',
        search: stringified,
      });
  },[])
  useEffect(() => {
    getPayRates()
      .then(res => {
        setTravelRate(res.filter(data => data.rate_card_type == 'Travel Rates') || []);
        setBillRate(res.filter(data => data.rate_card_type == 'Bill Rates') || []);
        setPayRate(res.filter(data => data.rate_card_type == 'Pay Rates') || []);
      })
  }, [])

  const funderLists=()=>{
    getClientFundersList(values)
    .then(res=>{
      if(res.data && (res.data.length > 0)){
       let newFunders=res.data.map(funder=>{
         return {
           id:funder.id,
           is_client: funder.is_client,
           name:funder.name
         }
       })
       setFunderList([...newFunders])
      }
    })
  }

  useEffect(()=>{
    if(values.date_from && values.date_to){
      funderLists();
    }
  },[values.date_from, values.date_to])

  const [clientOptions,setClientOptions]=useState([])
    useEffect(()=>{
      if(choices.client_listing){
        let temp = choices.client_listing.map(client => {
          return { id: client.id, name: client.full_name };
        })
        setClientOptions(temp);
      }
    },[choices.client_listing])
  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({...values});
  }, []);


  const dropDownHandleSelectPagination = (item, namesection) => {
    const urlParams = queryString.parse(props.location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: '/finance/billing/details',
      search: stringified,
    });
    values.limitperpage = item[namesection];
    // setValues({...values});
    setValues({...values, ...item});
  };

  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset =  offset * values.limitperpage; return {
      offset,
    };
  };

  const handleClick = (search) => {
    let pageToSearch = {}
    if(search){
      pageToSearch = {offset : 0}
      setCurrentPage(1);
    }else {
      pageToSearch = getPageToSearchParam(props.location.search);
    }
    if (!values.date_from || !values.date_to) {
      return;
    } else {
      setLoadingModal(true)

      getBillingDetailsReimbursement({...values, ...pageToSearch,...branchData}).then(res => {
        if (res) {
          setLoadingModal(false);
        }
        setDetailData(res.results);
        setScheduleAmount(res.total_amount?.toFixed(2));
        setPages(Math.ceil(res.count / values.limitperpage));
      });
    }
  };
  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };

  const handleExport = (format) => {
    setLoadingModalExport(true)
    const pageToSearch = getPageToSearchParam(props.location.search);
    billDetailExportReimbursement({...values, ...pageToSearch,...branchData},{is_distance_bill : true}, {column_selector: runTableOptionData}, format).then(response => {
      setLoadingModalExport(false)
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `Bill_details_${values.date_from}_${values.date_to}`;
        a.click();
      });
    });
  };
  const dropDownHandleSelect = item => {
    if (item.visit__client_service_visit__client__id__in) {
      item.visit__client_service_visit__client__id__in = item.visit__client_service_visit__client__id__in.length === (clientOptions ? clientOptions : 0).length || item.visit__client_service_visit__client__id__in.length === 0 ? [] : item.visit__client_service_visit__client__id__in
    }
    setValues({...values, ...item});
  };

  const handleSelectChange = (e, visit) => {
    if (e.target.checked) {
      setCheckedRows([...checkedRows, visit.visit_id])
    } else {
      let index = checkedRows.indexOf(visit.visit_id)
      if (index != -1) {
        let tempCheckedRows = [...checkedRows]
        tempCheckedRows.splice(index, 1)
        setCheckedRows([...tempCheckedRows])
      }
    }
  }

  const handleAllCheck=()=>{
    let checkAll = true;
  
      const visitExists=(id)=>{
        return checkedRows.some(function(el) {
          return el === id;
        });
      }
      if (checkedRows && checkedRows.length > 0) {
        detailData.map((visit)=>{
            if(!visitExists(visit.visit_id)){
              checkAll = false
            }
          })
      }else{
        checkAll = false;
      }
      return checkAll;
  }
  
  const handleAllSelectChange=(e)=>{
    setCheckedValue(!checkedValue)
    if (!checkedRows) {
      checkedRows = [];
    }
    if (e.target.checked) {
      detailData.map((visit)=>{
        checkedRows.push(visit.visit_id)
      })
    } else {
      detailData.map((visit)=>{
        checkedRows.map((item, index) => {
          if (item === visit.visit_id) {
            checkedRows.splice(index, 1);
          }
        })
      })
    }
    setCheckedRows([...checkedRows])
  }

  const handleSubmit = () => {
    let body = {
      visit_list: checkedRows,
    }
    bulkActionPostBillingDetails(body, bulkActionKeys.apiKey)
      .then(res => {
        if(res.status){
          setCheckedRows([])
          toast.current.show({ severity: 'success', summary: '', detail: res.message });
          handleClick()
          
        }
      })
      .catch(err => {
      })



  }

  const dropDownHandleSelectTable = item => {
    const data = [];
    if (item.tableDropdown) {
      item && item.tableDropdown.map((item) => {
        data.push(item.id);
      })
    }
    setrunTableOptionData(data)
  };

  return (
    <div>
      <Toast ref={toast} position="top-right" />
      <HeaderVisits>
        <div className="d-flex align-items-center mt-1">
          <FiltersContainer>
            <div
              className={'ms-1 me-3 me-md-0 flex-wrap d-flex justify-content-center'}>
              <FiltersLeftGroup
                className={'d-flex mb-2'} style={{flexWrap:'wrap', gap: "0.5rem"}}>
                <DropdownTypesContainer>
                  <DateSelector
                    isRequired
                    typeFilter={true}
                    labelTop="Date from"
                    {...useInput('date_from')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <DateSelector
                    isRequired
                    typeFilter={true}
                    labelTop="Date to"
                    minDate={values.date_from}
                    {...useInput('date_to')}
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                    items={choices.client_status ? choices.client_status : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({client_status: items});
                    }}
                    itemsSelected={clientStatusFilter}
                    setSelectedFilter={items =>
                      setClientStatusFilter([...items])
                    }
                    placeHolder={'Client Status'}
                    isObject
                    allOption
                  />
                </DropdownTypesContainer>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                      items={clientOptions ? clientOptions: []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ visit__client_service_visit__client__id__in: items });
                      }}
                      itemsSelected={clientFilter}
                      setSelectedFilter={(items)=>setClientFilter([...items])}
                      placeHolder={'Clients'}
                      isObject
                      allOption
                    />
                  </DropdownTypesContainer>
                  <DropdownTypesContainer>
                  <NoSelectAllDropdownFilter
                      items={choices.branch || []}
                      setItemsSelected={items => {
                        dropDownHandleSelect({ employee__job_detail__branch__id: items });
                      }}
                      itemsSelected={branchFilter}
                      setSelectedFilter={items =>
                        setBranchFilter([...items])
                      }
                      placeHolder={'Branch'}
                      isObject
                      allOption
                    />
                    </DropdownTypesContainer>
                <PrimaryButtonForm onClick={()=>handleClick(true)} disabled={loadingModal || loadingModalExport}>
                 <div className='d-flex'>
                 <span className={'ms-2 me-2 font-weight-bold'}>Search</span>
                 </div>
                </PrimaryButtonForm>
                {detailData.length > 0 && (
                   <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-md-end">
                     <div class="dropdown me-3">
                       <PrimaryButtonForm disabled={loadingModalExport || loadingModal} minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                         <span className={'me-2'}>Export</span>
                       </PrimaryButtonForm>
                       <div class="dropdown-menu me-2" aria-labelledby="dropdownMenuButton">
                         <a class="dropdown-item"
                           onClick={() => {
                            handleExport('')
                           }
                           }>
                           Export Single File
                         </a>
                         <a class="dropdown-item"
                           onClick={() => {
                            handleExport('pdf')
                           }
                           }>
                           Export Single PDF
                         </a>
                       
                       </div>
                     </div>
                 </DataContainer>
                )}
              </FiltersLeftGroup>
            </div>
          </FiltersContainer>
        </div>
      </HeaderVisits>
      <ComponentDynamic loading={loadingModal} >

      {detailData.length > 0 && (
        <>
          <DataContainer className=" ms-2 mt-2 mb-3 d-flex justify-content-md-end">
          {(checkedRows.length > 0) &&
                <div class="dropdown me-3">
                  <PrimaryButtonForm minWidth="6rem" class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className={'me-2'}>Action</span>
                  </PrimaryButtonForm>
                  <div class="dropdown-menu me-2" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item"
                      onClick={() => {
                        setModalBulkConfirmation(true);
                        setBulkActionKeys({ apiKey: 'distance_bill_confirmation', title: 'Finance Schedule Manual Travel Distance Bill Amount' })
                      }
                      }>
                      Finance Distance Bill Confirmation
                    </a>
                    <a class="dropdown-item"
                      onClick={() => {
                        setActionPopup(true);
                        setBulkActionKeys({ apiKey: 'schedule_manual_travel_distance_bill_amount', title: 'Finance Schedule Manual Travel Distance Bill Amount' })
                      }
                      }>
                      Finance Schedule Manual Travel Distance Bill Amount
                    </a>
                    <a class="dropdown-item"
                      onClick={() => {
                        setActionPopup(true);
                        setBulkActionKeys({ apiKey: 'actual_manual_travel_distance_bill_amount', title: 'Finance Actual Manual Travel Distance Bill Amount' })
                      }
                      }>
                      Finance Actual Manual Travel Distance Bill Amount
                    </a>
                  </div>
                </div>
              }
               <div className='d-flex justify-content-between'>
                <DropdownTypesContainer>
                  <NoSelectAllDropdownColumnSelector
                    items={headerColumns ? headerColumns.filter((item) => !item.removeCusCol) : []}
                    setItemsSelected={items => {
                      dropDownHandleSelectTable({ tableDropdown: items });
                    }}
                    itemsSelected={dropdownArraySelected}
                    setSelectedFilter={(item) => setDropdownArraySelected([...item])}
                    placeHolder={'Column'}
                    isObject
                    allOption

                  />
                </DropdownTypesContainer>
            <div className="data-container">
              <div className="me-2">
                <p>{'Total Amount: ' + scheduleAmount}</p>
              </div>
            </div>
            </div>
          </DataContainer>
          <>
          <div style={{overflowX: 'scroll', width:'100%'}}>
            <div style={{width:'max-content', minWidth:'-webkit-fill-available'}}>
              <Table
                headerColumns={headerColumns}
                headerPadding={true}
                noEditOption={true}
                noNeed={false}
                fontSize="0.75rem"
                callBackQuerySort={setQuerySort}
                handleAllCheck={handleAllCheck} 
                handleAllSelectChange={handleAllSelectChange}
                values={runTableOptionData}
                >
                {detailData.map((det, index) => {
                  return (
                    <Row bgColor>
                      {/* <Col
                      className={!(runTableOptionData && runTableOptionData.includes("checkbox")) && 'd-none'}
                        max_width={EX_SMALL_COL4}
                        Shrink={false}
                        NoFlexGrow={true}
                        reducePadding
                        Capitalize
                        Center

                      >
                        <input class="form-check-input" type="checkbox"
                          checked={checkedRows.includes(det.visit_id)}
                          id="flexCheckDefault"
                          onChange={(e) => { handleSelectChange(e, det) }}
                        />

                      </Col> */}
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("branch_name")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.client__branch__name}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("client_name")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.client__full_name}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("employee_name")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.employee__full_name}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("reimbursement")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.pay_adjustment__name}
                        </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("reimbursement_date")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.pay_adjustment_date}
                      </Col>
                      <Col
                      overlap={true}
                      className={!(runTableOptionData && runTableOptionData.includes("reimbursement_type")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.pay_adjustment_type__name}
                      </Col>

                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("amount")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.amount}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("client_reference")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                        {det.client_reference}
                      </Col>
                      <Col
                      className={!(runTableOptionData && runTableOptionData.includes("commisoned_hours")) && 'd-none'}
                        max_width={LARGE_COL}
                        Shrink
                        reducePadding
                        Capitalize
                        Center>
                       {det.commisoned_hours && det.commisoned_hours.toFixed(2)}
                      </Col>

                      

                    </Row>
                  );
                })}
                <div className="mt-3">
                  <Pagination
                    totalPages={pages}
                    currentPage={currentPage}
                    values={values}
                    dropDownHandleSelect={dropDownHandleSelectPagination}
                    number={PAGE_SIZE_RENDER}
                    dataLength={detailData.length}
                  />
                </div>
              </Table>
              </div>
            </div>
          </>
        </>
      )}
                  </ComponentDynamic>
                  {
        ActionPopup &&
        <AssignBulk
          fullBind={fullBindActionPopup}
          checkedRows={checkedRows}
          bulkActionKeys={bulkActionKeys}
          handleClick={handleClick}
          setActionPopup={setActionPopup}
          billRate={billRate}
          payRate={payRate}
          travelRate={travelRate}
          setCheckedRows={setCheckedRows}
        />
      }

     <ConfirmDecision
          type="confirm"
          title="Confirm"
          body={
            `Are you sure you want to ${bulkActionKeys.title} ? `
          }
          onOk={() => {
            handleSubmit()
            setModalBulkConfirmation(false)
          }
          }
          onNo={
            () => {
              setModalBulkConfirmation(false)
            }
          }
          {...bindModalBulkConfirmation}
        />
    </div>
  );
};

export default BillingExpanse;
