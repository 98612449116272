import React, {useState} from 'react';
import Navbar from 'components/DashboardComponents/Navbar';
import LeftSidebar from 'components/DashboardComponents/LeftSidebar';
import {BadGatewayContainer, ContainerOffset, MainContainer, ResponsiveNavbar} from './styles';
import {withRouter} from 'react-router-dom';
import { useEffect } from 'react';
import { BAD_GATEWAY_ERROR } from 'utils/constants/messages';
import { getBadGateway } from 'utils/localStorage/token';

function DashboardRouter(props) {
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [containerWidth, setContainerWidth] = useState(false);
  const [showBadGatwayError, setShowBadGatewayError] = useState(false)
  const badGateWayStatus = getBadGateway()
  const handleClick = () => {
    setShowLeftSidebar(!showLeftSidebar);
  };

  const changeContainerWidth = changeWidth => {
    setContainerWidth(changeWidth);
  };

  useEffect(() => {
    if (badGateWayStatus)
      setShowBadGatewayError(true)
  }, [badGateWayStatus])


  return (
    <div className="Dashboard">
      <Navbar showMenu={handleClick} showLeftSidebar={showLeftSidebar} branchData={props.getBranchItem}  filterClearValue={props.getFilterClearValue} permissionsNotificationIcon={props.permissionsNotificationIcon}  />

      <ResponsiveNavbar showLeftSidebar={showLeftSidebar}>
        <LeftSidebar
         contextIsAdmin={props.contextIsAdmin} 
         contextIsSuperAdmin={props.contextIsSuperAdmin} 
         contextIsStaff={props.contextIsStaff}
        contextPermissions={props.contextPermissions}
        contextCategoryPermissions={props.contextCategoryPermissions}
        contextSubSectionPermissions={props.contextSubSectionPermissions}
        contextSubscription={props.contextSubscription}
          locationName={props.location.pathname}
          showMenu={handleClick}
          changeWidth={changeContainerWidth}
          decreaseWidth={containerWidth}
          contextOrganization={props.contextOrganization}
        />
      </ResponsiveNavbar>

      <MainContainer>
        <ContainerOffset
          containerWidth={containerWidth}
          className="d-flex flex-wrap h-100">
          <main role="main" className="custom-column mt-2 col-md-12 ms-sm-auto col-lg-12">
           {
            showBadGatwayError ? 
            <BadGatewayContainer>{BAD_GATEWAY_ERROR}</BadGatewayContainer>
           : props.children }
            
          </main>
        </ContainerOffset>
      </MainContainer>
    </div>
  );
}

export default withRouter(DashboardRouter);
