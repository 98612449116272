import React, { useState, useEffect, useContext } from 'react';
import { Title } from "./style"
// import {getCategoryPermission, getPermissions,getStaff,getClient,getEmployee} from '../../../utils/localStorage/user';
import { AppContext } from 'context'
import { PieChart } from 'react-minimal-pie-chart';
import { Chart } from "react-google-charts";
import { dashboard_choices } from 'utils/choiceConstant';
import moment from 'moment';
import { getDasboardTeamKpis, getDasboardVisitKpis } from 'utils/api/DashboardApi';
import { DropdownTypesContainer, ErrorMessage } from 'shared/styles/constants/tagsStyles';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { Toast } from 'primereact/toast';
import { useForm } from 'hooks/FormHook';
import { useRef } from 'react';
import { Fragment } from 'react';
import { LoadingContainer } from 'components/DashboardComponents/ModalDynamic/styles';
import Spinner from 'components/SharedComponents/Spinner';
import './visitkpi.css'

const VisitKpis = () => {
  //   const[employeeData,setEmployeeData]=useState({})
  let start_date_today = moment().subtract(7, 'days').format('YYYY-MM-DD')
  let end_date_today = moment().format('YYYY-MM-DD')

  const { contextIsAdmin, contextIsSuperAdmin, contextIsStaff, contextChoices,handleGetChoices } = useContext(AppContext)
  const { values, setValues, useInput } = useForm({ start_date: start_date_today, end_date: end_date_today, kpi: 'day' });
  const toast = useRef();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);


  let startDate = useInput('start_date', 'isRequired');
  let endDate = useInput('end_date', 'isRequired');

  useEffect(() => {
    if(contextChoices.kpi){
      loadTeamKpiData()
    }
  }, [contextChoices.kpi])
  useEffect(() => {
    handleGetChoices(dashboard_choices)
    
  }, [])
  useEffect(() => {
    if (values.start_date) {
      var daysDiff = moment(values.end_date).diff(
        moment(values.start_date),
        'days'
      );
      console.log(daysDiff, '9999999');
      if (values.kpi === 'day' && daysDiff > 60) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(60, 'days')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        values.start_date = moment(values.end_date)
          .subtract(60, 'days')
          .format('YYYY-MM-DD');

        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max  range should range  be 60 days',
        });
      }

      if (values.kpi === 'month' && daysDiff > 365) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(12, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should  be 12 months',
        });
      }
      if (values.kpi === 'week' && daysDiff > 91) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(13, 'week')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should be 13 weeks',
        });
      }
      if (values.kpi === 'quarter' && daysDiff > 4567) {
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(15, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Warning',
          detail: 'max range  should be 1.5 year',
        });
      }
    }
  }, [values.start_date || values.kpi]);

  useEffect(() => {
    if (values.end_date) {
      var daysDiff = moment(values.end_date).diff(
        moment(values.start_date),
        'days'
      );

      if (values.kpi === 'day' && daysDiff > 60) {
        values.start_date = moment(values.end_date)
          .subtract(60, 'days')
          .format('YYYY-MM-DD');
        setValues({ ...values });

        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max  range should range  be 60 days',
        });
      }
      if (values.kpi === 'month' && daysDiff > 365) {
        values.start_date = moment(values.end_date)
          .subtract(12, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should  be 12 months',
        });
      }
      if (values.kpi === 'week' && daysDiff > 91) {
        values.start_date = moment(values.end_date)
          .subtract(13, 'week')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should be 13 weeks',
        });
      }
      if (values.kpi === 'quarter' && daysDiff > 457) {
        values.start_date = moment(values.end_date)
          .subtract(15, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
        toast.current.show({
          severity: 'error',
          summary: 'Warning',
          detail: 'max range  should be 1.5 year',
        });
      }
      setValues({ ...values });
    }
  }, [values.end_date || values.kpi]);

  const loadTeamKpiData = () => {
    if(!loading){
      setLoading(true)
    }
    if (!values.kpi) {
      setShowError(true)
      setErrorMessage('Please select Frequency ');
      return
    }
    getDasboardVisitKpis(values.start_date, values.end_date, values.kpi)
      .then(res => {
        setLoading(false)
        if (res.data) {
          values.one_day_data = null
          if(res.data.length == 0){
            setNoData(true)
          }
          if (res.data.length == 1) {
            values.one_day_data = res.data[0];
            setValues({ ...values })
          } else {
            //values.one_day_data = res.data[0];
            values.visit_status = res.data.map((item) => {
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.clockedCompleted || 0, item.inProgressVisit || 0, item.missedVisit || 0]
            })
            values.visit_status_hrs = res.data.map((item) => {
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.clockedCompleteHrs || 0, item.inProgressVisitHrs || 0, item.missedVisitHrs || 0]
            })
            values.clocked_visit_duration = res.data.map((item) => {
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.clocked85To115 || 0, item.clockedLt85 || 0, item.clockedGt115 || 0]
            })
            values.clocked_visit_provision = res.data.map((item) => {
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.underProvisionVisit || 0, item.overProvisionVisit || 0, item.onTimeProvisionVisit || 0]
            })
            values.visit_clocked_start = res.data.map((item) => {
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.onTimeStartClocked || 0, item.lateStartClocked || 0, item.earlyStartClocked || 0]
            })
            values.visit_clocked_end = res.data.map((item) => {
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.onTimeEndClocked || 0, item.lateEndClocked || 0, item.earlyEndClocked || 0]
            })
            values.visit_time_confirmation = res.data.map((item) => {
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.timeVerifiedInOut || 0, item.timeVerifiedIn || 0, item.timeVerifiedOut || 0, item.timeVerified || 0]
            })
            values.visit_distance_confirmation = res.data.map((item) => {
              let dateOfRecord = moment(item.dateOfRecord).format('DD/MM')
              return [dateOfRecord, item.distanceVerifiedInOut || 0, item.distanceVerifiedIn || 0, item.distanceVerifiedOut || 0, item.distanceVerified || 0]
            })
            setValues({ ...values })
          }

        }
      })
  }

  const dropDownHandleSelect = item => {
    setValues({ ...values, ...item });

    if (item.kpi) {

      setShowError(false)
      var daysDiff = moment(values.end_date).diff(
        moment(values.start_date),
        'days'
      );

      var month = moment(values.end_date).diff(
        moment(values.start_date),
        'months'
      );

      if (daysDiff > 60 && item.kpi === 'day') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max  range should range  be 60 days',
        });
        values.start_date = moment(values.end_date)
          .subtract(60, 'days')
          .format('YYYY-MM-DD');

        setValues({ ...values });
      }
      if (daysDiff > 365 && item.kpi === 'month') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should  be 12 months',
        });
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(12, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
      }

      if (daysDiff > 91 && item.kpi === 'week') {
        toast.current.show({
          severity: 'error',
          summary: 'Failed',
          detail: 'max range  should be 13 weeks',
        });
        values.end_date = moment(values.start_date, 'YYYY-MM-DD')
          .add(13, 'week')
          .format('YYYY-MM-DD');
        setValues({ ...values });
      }
      if (daysDiff > 457 && item.kpi === 'quarter') {
        toast.current.show({
          severity: 'error',
          summary: 'Warning',
          detail: 'max range  should be 1.5 year',
        });
        values.start_date = moment(values.end_date)
          .subtract(15, 'month')
          .format('YYYY-MM-DD');
        setValues({ ...values });
      }
      setValues({ ...values, ...item });
    }
  };

  const dataVisitsHrsStatus = [
    ["No. of Visit Hrs Clocked Complete", "No. of Visit with InProgress Status"],
    ["No. of Visit Hrs Clocked Complete", values.one_day_data ? values.one_day_data.clockedCompleteHrs : 0],
    ["No. of Visit Hrs with InProgress Status", values.one_day_data ? values.one_day_data.inProgressVisitHrs : 0],
    ["No. of Visit Hrs with Missed Status", values.one_day_data ? values.one_day_data.missedVisitHrs : 0],
  ];

  const dataVisitsStatus = [
    ["No. of Visit Clocked Complete", "No. of Visit with InProgress Status"],
    ["No. of Visit Clocked Complete", values.one_day_data ? values.one_day_data.clockedCompleted : 0],
    ["No. of Visit with InProgress Status", values.one_day_data ? values.one_day_data.inProgressVisit : 0],
    ["No. of Visit with Missed Status", values.one_day_data ? values.one_day_data.missedVisit : 0],
  ];

  const dataVisitsClockedVisitProvision = [
    ["Tasks", "Count"],
    ["Under Provision", values.one_day_data ? values.one_day_data.underProvisionVisit : 0],
    ["Over Provision", values.one_day_data ? values.one_day_data.overProvisionVisit : 0],
    ["On Time Provision", values.one_day_data ? values.one_day_data.onTimeProvisionVisit : 0],
  ];

  const dataClockedVisitDuration = [
    ["Tasks", "Count"],
    ["No. of Visits with Clocked Visit Duration 85% <> 115%", values.one_day_data ? values.one_day_data.clocked85To115 : 0],
    ["No. of Visits with Clocked Visit Duration <85%", values.one_day_data ? values.one_day_data.clockedLt85 : 0],
    ["No. of Visits with Clocked Visit Duration > 115%", values.one_day_data ? values.one_day_data.clockedGt115 : 0],
  ];

  const dataVisitsTimeConfirmation = [
    ["Tasks", "Count"],
    ["Verified - Verified", values.one_day_data ? values.one_day_data.timeVerifiedInOut : 0],
    ["Verified - Not Verified", values.one_day_data ? values.one_day_data.timeVerifiedIn : 0],
    ["Not Verified - Verified", values.one_day_data ? values.one_day_data.timeVerifiedOut : 0],
    ["Not Verified - Not Verified", values.one_day_data ? values.one_day_data.timeVerified : 0],
  ];

  const dataVisitsDistanceConfirmation = [
    ["Tasks", "Count"],
    ["Verified - Verified", values.one_day_data ? values.one_day_data.distanceVerifiedInOut : 0],
    ["Verified - Not Verified", values.one_day_data ? values.one_day_data.distanceVerifiedIn : 0],
    ["Not Verified - Verified", values.one_day_data ? values.one_day_data.distanceVerifiedOut : 0],
    ["Not Verified - Not Verified", values.one_day_data ? values.one_day_data.distanceVerified : 0],
  ];

  const dataVisitClockedStart = [
    ["Tasks", "Count"],
    ["OnTime Status", values.one_day_data ? values.one_day_data.onTimeStartClocked : 0],
    ["Late Status", values.one_day_data ? values.one_day_data.lateStartClocked : 0],
    ["Early Status", values.one_day_data ? values.one_day_data.earlyStartClocked : 0],
  ];


  const dataVisitHrsClockedStart = [
    ["Tasks", "Count"],
    ["OnTime Status", values.one_day_data ? values.one_day_data.onTimeStartClockedHrs : 0],
    ["Late Status", values.one_day_data ? values.one_day_data.lateStartClockedHrs : 0],
    ["Early Status", values.one_day_data ? values.one_day_data.earlyStartClockedHrs : 0],
  ];


  const dataVisitClockedEnd = [
    ["Tasks", "Count"],
    ["OnTime Status", values.one_day_data ? values.one_day_data.onTimeEndClocked : 0],
    ["Late Status", values.one_day_data ? values.one_day_data.lateEndClocked : 0],
    ["Early Status", values.one_day_data ? values.one_day_data.earlyEndClocked : 0],
  ];


  const dataVisitHrsClockedEnd = [
    ["Tasks", "Count"],
    ["OnTime Status", values.one_day_data ? values.one_day_data.onTimeEndClockedHrs : 0],
    ["Late Status", values.one_day_data ? values.one_day_data.lateEndClockedHrs : 0],
    ["Early Status", values.one_day_data ? values.one_day_data.earlyEndClockedHrs : 0],
  ];


  const dataBar = [
    ["Date", "value"],
    ["January", 1000],
    ["Feb", 1170],
    ["March", 660],
    ["April", 342],
    ["May", 245],
    ["June", 1030],
    ["July", 657],
    ["August", 535],
    ["September", 1030],
    ["Octobor", 768],
    ["November", 465],
    ["December", 30],
  ];

  const optionsBar = {
    chart: {
      title: "Active employee vs Active probation employee",
      //subtitle: "Sales, Expenses, and Profit: 2014-2017",
    },
  };
  const optionsBarAvailability = {
    chart: {
      title: "Employee availability",
    },
    //chartArea:{left:20,top:10,width: '100%', height: '80%'}
  };
  const optionsLineAvailability = {
    title: "Employee availability",
    curveType: "function",
    legend: { position: "top", maxLines: 5 },
  };

  const optionsLineAvailabilityHrs = {
    title: "No. of Visits with Clocked Visit Duration",
    curveType: "function",
    legend: { position: "top", maxLines: 5 },
  };


  const optionsAbsence = {
    title: "Absence Count",
  };

  const optionsAbsenceHours = {
    title: "Absence Hours",
  };

  return (
    <div>
       {loading ? (
        <LoadingContainer maxHeight={null}>
          <Spinner />
        </LoadingContainer>
      ) : (
        <>
      <Toast ref={toast} position="top-right" />
      <Title>Visit KPIs</Title>
      <div className="d-flex mt-2">
        <DropdownTypesContainer className="me-2">
          {' '}
          <DateSelector
            isRequired
            // data-error={true}
            typeFilter={true}
            labelTop="Date from"
            value={values.start_date ? values.start_date : ''}
            //onChange={(val)=>onDateChange(val)}
            {...startDate}
          />
        </DropdownTypesContainer>
        <DropdownTypesContainer className="me-2">
          {' '}
          <DateSelector
            isRequired
            typeFilter={true}
            labelTop="Date to"
            value={values.end_date ? values.end_date : ''}
            // minDate={values.date_from}
            {...endDate}
          />
        </DropdownTypesContainer>
        <DropdownTypesContainer className="me-2">
          <DropdownSearchable
            typeFilter
            required
            placeHolder={'Frequency'}
            options={contextChoices && contextChoices.kpi}
            selectedOption={values.kpi}
            onChange={value =>
              dropDownHandleSelect({ kpi: value ? value : null })
            }
          />
        </DropdownTypesContainer>
        <PrimaryButtonForm minWidth="6rem" onClick={() => loadTeamKpiData()}>
          Search
        </PrimaryButtonForm>
      </div>
      <div className={'mb-3'}>
        {showError ? (
          <ErrorMessage>
            <label className={'mt-2'}>{errorMessage}</label>
          </ErrorMessage>
        ) : (
          <Fragment />
        )}
      </div>
      {values.one_day_data ?
        <div>
          <div className='mt-2'>
            <div className="row">
              {values.one_day_data &&
                <div className="col-6">
                  <div className='shadow' style={{ backgroundColor: 'white' }}>
                    <Chart
                      chartType="PieChart"
                      data={dataVisitsStatus}
                      options={{
                        title: "No. of Visit with status",
                        is3D: false,
                      }}
                      width={"100%"}
                      height={"400px"}
                    />
                  </div>
                </div>
              }
              {values.one_day_data &&
                <div className="col-6">
                  <div className='shadow' style={{ backgroundColor: 'white' }}>
                    <Chart
                      chartType="PieChart"
                      data={dataVisitsHrsStatus}
                      options={{
                        title: "No. of Visit Hrs with status",
                        is3D: false,
                      }}
                      width={"100%"}
                      height={"400px"}
                    />
                  </div>
                </div>}
            </div>
          </div>
          <div className='mt-2'>
            <div className="row">
              <div className="col-6">
                <div className='shadow' style={{ backgroundColor: 'white' }}>
                  <Chart
                    chartType="PieChart"
                    data={dataClockedVisitDuration}
                    options={{
                      title: "No. of Visits with Clocked Visit Duration",
                      pieHole: 0.5,
                      is3D: false,
                    }}
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className='shadow' style={{ backgroundColor: 'white' }}>
                  <Chart
                    chartType="PieChart"
                    data={dataVisitsClockedVisitProvision}
                    options={{
                      title: "No. of Visits Clocked Visit Provision",
                      //pieHole: 0.4,
                      is3D: false,
                    }}
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='mt-2'>
            <div className="row">
              <div className="col-6">
                <div className='shadow' style={{ backgroundColor: 'white' }}>
                  <Chart
                    chartType="PieChart"
                    data={dataVisitsTimeConfirmation}
                    options={{
                      title: "No.of Visits with Time Confirmation",
                      pieHole: 0.5,
                      is3D: false,
                    }}
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className='shadow' style={{ backgroundColor: 'white' }}>
                  <Chart
                    chartType="PieChart"
                    data={dataVisitsDistanceConfirmation}
                    options={{
                      title: "No.of Visits with Distance Confirmation",
                      //pieHole: 0.4,
                      is3D: false,
                    }}
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='mt-2'>
            <div className="row">
              <div className="col-6">
                <div className='shadow' style={{ backgroundColor: 'white' }}>
                  <Chart
                    chartType="PieChart"
                    data={dataVisitClockedStart}
                    options={{
                      title: "No. of Visit with Clocked Start",
                      //pieHole: 0.5,
                      is3D: false,
                    }}
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className='shadow' style={{ backgroundColor: 'white' }}>
                  <Chart
                    chartType="PieChart"
                    data={dataVisitHrsClockedStart}
                    options={{
                      title: "No. of Visit Hrs with Clocked Start",
                      pieHole: 0.6,
                      is3D: false,
                    }}
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='mt-2'>
            <div className="row">
              <div className="col-6">
                <div className='shadow' style={{ backgroundColor: 'white' }}>
                  <Chart
                    chartType="PieChart"
                    data={dataVisitClockedEnd}
                    options={{
                      title: "No. of Visit with Clocked End",
                      //pieHole: 0.5,
                      is3D: false,
                    }}
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className='shadow' style={{ backgroundColor: 'white' }}>
                  <Chart
                    chartType="PieChart"
                    data={dataVisitHrsClockedEnd}
                    options={{
                      title: "No. of Visit Hrs with Clocked End",
                      pieHole: 0.6,
                      is3D: false,
                    }}
                    width={"100%"}
                    height={"400px"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        :

       (!noData && 
       <div>

          {values.visit_status && <div className='mt-2'>
             <div className='chart_box'>
              <Chart
                chartType="Bar"
                width="95%"
                height="440px"
                style={{ left: 20, top: 20, position: 'relative' }}
                data={[["Date", "Clocked Complete", ' InProgress Status', "Missed Status"], ...values.visit_status]}
                options={{
                  chart: {
                    title: "No. of Visit with Status",
                  }
                }}
              />
            </div>
          </div>}

          {values.visit_status_hrs && <div className='mt-2'>
             <div className='chart_box'>
              <Chart
                chartType="Bar"
                width="95%"
                height="440px"
                style={{ left: 20, top: 20, position: 'relative' }}
                data={[["Date", "Clocked Complete Hrs", ' InProgress Status Hrs', "Missed Status Hrs"], ...values.visit_status_hrs]}
                options={{
                  chart: {
                    title: "No. of Visit Hrs with Status",
                  }
                }}
              />
            </div>
          </div>}
          <div className='row mt-2'>
            {values.clocked_visit_duration && <div className='col-6'>
               <div className='chart_box'>
                <Chart
                  chartType="LineChart"
                  width="100%"
                  height="500px"
                  data={[["Dates", " 85% <> 115%", '<85%', "> 115%"], ...values.clocked_visit_duration]}
                  options={{
                    title: "No. of Visits with Clocked Visit Duration",
                    curveType: "function",
                    legend: { position: "top", maxLines: 5 },
                  }}
                />
              </div>
            </div>}
            {values.clocked_visit_provision && <div className='col-6'>
               <div className='chart_box'>
                <Chart
                  chartType="LineChart"
                  width="100%"
                  height="500px"
                  data={[["Dates", "Under Provision", 'Over Provision', "On Time Provision"], ...values.clocked_visit_provision]}
                  options={{
                    title: "No. of Visits Clocked Visit Provision",
                    curveType: "function",
                    legend: { position: "top", maxLines: 5 },
                  }}
                />
              </div>
            </div>}
          </div>
          {values.visit_clocked_start && <div className='mt-2'>
             <div className='chart_box'>
              <Chart
                chartType="Bar"
                width="95%"
                height="440px"
                style={{ left: 20, top: 20, position: 'relative' }}
                data={[["Date", "OnTime Status", 'Late Status', 'Early Status'], ...values.visit_clocked_start]}
                options={{
                  chart: {
                    title: "No. of Visit with Clocked Start",
                  },
                  legend: { position: "none", alignment: 'end' },
                }}
              />
            </div>
          </div>}
          {values.visit_clocked_end && <div className='mt-2'>
             <div className='chart_box'>
              <Chart
                chartType="Bar"
                width="95%"
                height="440px"
                style={{ left: 20, top: 20, position: 'relative' }}
                data={[["Date", "OnTime Status", 'Late Status', 'Early Status'], ...values.visit_clocked_end]}
                options={{
                  chart: {
                    title: "No. of Visit with Clocked End",
                  },
                  legend: { position: "none", alignment: 'end' },
                }}
              />
            </div>
          </div>}
          <div className='row mt-2'>
            {values.visit_time_confirmation && <div className='col-6'>
               <div className='chart_box'>
                <Chart
                  chartType="LineChart"
                  width="100%"
                  height="500px"
                  data={[["Dates", " Verified-Verified", 'Verified - Not Verified', "Not Verified - Verified", " Not Verified - Not Verified"], ...values.visit_time_confirmation]}
                  options={{
                    title: "No.of Visits with Time Confirmation",
                    curveType: "function",
                    legend: { position: "top", maxLines: 5 },
                  }}
                />
              </div>
            </div>}
            {values.visit_distance_confirmation && <div className='col-6'>
               <div className='chart_box'>
                <Chart
                  chartType="LineChart"
                  width="100%"
                  height="500px"
                  data={[["Dates", " Verified-Verified", 'Verified - Not Verified', "Not Verified - Verified", " Not Verified - Not Verified"], ...values.visit_distance_confirmation]}
                  options={{
                    title: "No.of Visits with Distance Confirmation",
                    curveType: "function",
                    legend: { position: "top", maxLines: 5 },
                  }}
                />
              </div>
            </div>}
          </div>
        </div>)
      }
      </>
      )}
    </div>
  );
};

export default VisitKpis;
