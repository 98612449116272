import React,{useState,useEffect,Fragment} from 'react';
import {
    ModalContainer,
    FlexGrid3Container,
    FlexGrid3Item,
    ErrorMessage,
  } from 'shared/styles/constants/tagsStyles';
  import {
    EVERY_DAY,
    EVERY_WEEK,
    EVERY_MONTH,
    EVERY_YEAR,
    END_TYPE_AFTER,
    END_TYPE_ON,
    END_TYPE_NEVER,
  } from 'utils/constants/serviceVisits';
  import { useModal } from 'hooks/ModalHook';
  import {KeyPress} from 'utils/constants/regularExpressions';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import DateSelector from 'components/SharedComponents/DateSelector';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import ToggleButton from 'components/SharedComponents/ToggleButton';
import {getRegularVisitById, getShiftDetailById} from 'utils/api/RegularVisitApi'
import {validateDate} from 'shared/methods';
import {InvalidRange} from 'utils/constants/messages';
import moment from 'moment'
import {BsFillPlusSquareFill} from "react-icons/bs"
import ClientSpecific from "./ClientSpecific"
import EmployeeSpecific from "./EmployeeSpecific"
import RunSpecific from "./RunSpecific"
import SingleSwapRun from './SingleSwapRun';
const RegularVisitModal = ({
    fullBind,
    values,
    useInput,
    setValues,
    errorMessage,
    choices,
    clients,
    employees,
    shiftArray,
    setShiftArray,
    idSelected,
    employeeDayId,
    saveButtonIcon,
    loadingSave,
    textStatus,
    dropDownHandleRegVisit,
    dropDownHandleRegEmpDet,
    visit,
    dayOptions,
    handleSaveVisit,
    handleSaveVisitSwapRun,
    loadVisitOnEdit,
    // loadVisitOnEditEmpSpecific,
    setFormErrors,
    errors,
    employeeRequired,
    empEmployeeReq,
    empDayOptions,
    empVisit,
    setEmpEmployeeReq,
   setEmpVisit,
   getVisitName,
  getSkillLevelName,
  getClientVisits,
  setSingleRun,
  singleRun,
  fullBindSingleRun,
  setEmpDayOptions,
  setRegularVisit,
  regularVisit,
  toast
}) => {
    const [addFullBind, setAddFullBind] = useState(fullBind);
    const[dateError,setDateError]=useState(false)
    const[dateErrorMessage,setDateErrorMessage]=useState('')
    const [loadingModal, setLoadingModal] = useState(true);
    
    const check_start_date = values.start_date;
  const check_end_date = values.end_date;
    useEffect(() => {
        setAddFullBind({
          ...addFullBind,
          title: 'Regular Visit',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [fullBind]);
     
      useEffect(() => {
       if(!idSelected){
        values={}
        setLoadingModal(false);
      
       }else{
         loadRegularVisitById(idSelected,employeeDayId)
       }
          
      }, [idSelected]);
      useEffect(()=>{
        if(!values.employee_day_detail){
          values.employee_day_detail=[]
          values.employee_day_detail[0]={}
        setValues({...values})
        }
          if(!values.specific && !idSelected){
            values.specific="client"
            setValues({...values})
          }
        },[])
      useEffect(()=>{
      if(check_start_date && check_end_date){
        const validateDates = validateDate(true, check_start_date, check_end_date);
        if (!validateDates) {
          setDateError(true);
          setDateErrorMessage(InvalidRange);
        } else {
          setDateError(false);
          setDateErrorMessage('');
        }
      }else{
        setDateError(false);
        setDateErrorMessage('');
      }
    },[check_start_date,check_end_date])

    const switchEndValueInput = props => {
      // render correct input type depending of the end_type selected
      switch (values.end_type) {
        case END_TYPE_ON:
          // check if current value has date format
          const validDate = props.value
            ? moment(props.value, 'YYYY-MM-DD', true).isValid()
            : true;
          // if current value has date format then send props
          const dateProps = validDate ? props : {};
          return (
            <DateSelector
              isRequired
              labelTop={'End value'}
              {...dateProps}
            />
          );
        case END_TYPE_AFTER:
          return (
            <InputLabelTop
              required
              onKeyPress={values.end_value ? KeyPress.POSITIVE : KeyPress.NATURAL}
              label={'End value'}
              type="text"
              id={`end_value`}
              {...props}
            />
          );
        case END_TYPE_NEVER:
          return <Fragment />;
        default:
          return <Fragment />;
      }
    };
const addNewEmployeeDet=()=>{
  values.employee_day_detail=[...values.employee_day_detail,{}]
  setValues({...values})
}
const getEmployeeWeekDay=(day)=>{
  if(day==='MON')
  return {id:'1',label:day}
  if(day==='TUE')
  return {id:'2',label:day}
  if(day==='WED')
  return {id:'3',label:day}
  if(day==='THU')
  return {id:'4',label:day}
  if(day==='FRI')
  return {id:'5',label:day}
  if(day==='SAT')
  return {id:'6',label:day}
  if(day==='SUN')
  return {id:'7',label:day}
}
const deleteEmployeeDetails=(index)=>{
  if(values.specific=='client'){
    if(values.employee_day_detail.length>0){
      values.employee_day_detail.splice(index,1)
      let newErrors=errors.filter(err=>!err.includes('employee_day_detail'))
      setFormErrors([...newErrors])
      setValues({...values})
    }
  }
  else{
    if(values.employee_day_detail.length>0){
      values.employee_day_detail.splice(index,1)
      empVisit.splice(index,1)
      empEmployeeReq.splice(index,1)
      empDayOptions.splice(index,1)
      let newErrors=errors.filter(err=>!err.includes('employee_day_detail'))
      setFormErrors([...newErrors])
      setValues({...values})
      // setEmpVisit([...empVisit])
      // setEmpEmployeeReq([empEmployeeReq])
    }
  }
 
}
    const calculateEndValueProps = value => {
      switch (value) {
        case END_TYPE_ON:
          return 'isRequired';
        case END_TYPE_AFTER:
          return 'isInt, isRequired';
        default:
          return '';
      }
    };

    const getShiftArray= async(id,index)=>{
     if(id){
      getShiftDetailById(id)
      .then((res) => {
        if (res.message === "Success") {
          let employees = [];
          if (res.data) {
            employees = res.data.map(employee => {
              return { id: employee.value, name: employee.label };
            });
          }
         if(index === 'employee'){
          setShiftArray(employees || []);
         }else{
          values.employee_day_detail[index].shiftArray = employees;
          setValues({ ...values });
         }
        }
      })
    .catch((error)=>{})
     }
    }
    

  const loadRegularVisitById = async (id, empDayId) => {
    const response = await getRegularVisitById(id, empDayId);
    if(response.id && !response.specific){
      setLoadingModal(false);
      setRegularVisit(!regularVisit);
      toast.current.show({severity: 'error', summary: 'Failed', detail: 'Bad data, please enter data again'});
    } else if (response.id && response.specific === 'client') {
      values.id = id
      values.client = response.client
      values.start_date = response.start_date
      values.end_date = response.end_date
      values.specific = response.specific
      values.every = response.every
      values.repeat = response.repeat
      values.end_type = response.end_type
      values.end_value = response.end_value
      values.run_name_view = response.run_name_view
      values.is_suspended = response.is_suspended
      values.suspended_from_date = response.suspended_from_date
      values.suspended_to_date = response.suspended_to_date
      await loadVisitOnEdit(response.client, response.client_service_visit)
      values.visit = response.client_service_visit
      values.employee_day_detail = response.details && response.details.map((det,index) => {
        getShiftArray(det.employee,index)
        return {
          id: det.id,
          employee: det.employee,
          shift: det.shift,
          repeat_on: getEmployeeWeekDay(det.repeat_on).id,
          requirement: det.requirement,
          adjustment_details: det.adjustment_details,
        }
      })
      setLoadingModal(false);
      setValues({ ...values })
    }
    else if (response.id && response.specific === 'employee') {
      getShiftArray(response.employee,'employee')
      values.id = id
      values.start_date = response.start_date
      values.shift = response.shift
      values.end_date = response.end_date
      values.employee = response.employee
      values.specific = response.specific
      values.every = response.every
      values.repeat = response.repeat
      values.end_type = response.end_type
      values.end_value = response.end_value
      values.run_name_view = response.run_name_view
      values.is_suspended = response.is_suspended
      values.suspended_from_date = response.suspended_from_date
      values.suspended_to_date = response.suspended_to_date
      Promise.all(response.details && response.details.map(async (det, index) => {
        loadVisitOnEditEmpSpecific(det.client_details.id, det.client_service_visit, index, det, response)
      }))
    }
    else if (response.id && response.specific === 'run') {
      values.id = id
      values.start_date = response.start_date
      values.end_date = response.end_date
      values.specific = response.specific
      values.every = response.every
      values.repeat = response.repeat
      values.end_type = response.end_type
      values.end_value = response.end_value
      values.run_name_view = response.run_name_view
      values.is_suspended = response.is_suspended
      values.suspended_from_date = response.suspended_from_date
      values.suspended_to_date = response.suspended_to_date
      Promise.all(response.details && response.details.map(async (det, index) => {
        loadVisitOnEditEmpSpecific(det.client_details.id, det.client_service_visit, index, det, response)
      }))
    }
    setLoadingModal(false)
  }

  const getEmployeeWeekDayOptions=(day)=>{
    if(day==='MON')
    return {id:'1',name:day}
    if(day==='TUE')
    return {id:'2',name:day}
    if(day==='WED')
    return {id:'3',name:day}
    if(day==='THU')
    return {id:'4',name:day}
    if(day==='FRI')
    return {id:'5',name:day}
    if(day==='SAT')
    return {id:'6',name:day}
    if(day==='SUN')
    return {id:'7',name:day}
  }
   
  const loadVisitOnEditEmpSpecific = (client, client_visit, index, det, response) => {
    getClientVisits(client)
      .then(res => {
        let tempVisit = []
        let tempReq = []
        let tempDay = []
        // setVisitData([...res.data])
        // tempVisit = res && res.data && res.data.map(visit => {
        //   let visitName = getVisitName(visit)
        //   return (
        //     { id: visit.id, name: visitName }
        //   )

        // })

        // setVisitData([...res.data])
        tempVisit = det && det.repeat_on && res && res.data && res.data.map(visit => {
          let dayArray = visit && ((visit.repeat_on.length > 0) ? visit.repeat_on.split(',') : []);
              let checkArray = true;
            if(det && det.repeat_on && dayArray){
              const visitExists=(id)=>{
                return dayArray.some(function(el) {
                  return el === id;
                });
              }
              det.repeat_on.split(',').forEach((item)=>{
                if(!visitExists(item)){
                  checkArray = false
                }
              })
            }
              
              if(checkArray){
                let visitName = getVisitName(visit)
              return (
                { id: visit.id, name: visitName }
              )
              }

        }).filter(notUndefined => notUndefined !== undefined);
        
        let selectedVisit = res && res.data && res.data.find(visit => visit.id == client_visit)
        tempReq = selectedVisit && selectedVisit.employee_required
          && selectedVisit.employee_required.map(empReq => {
            //let skillLevelName = getSkillLevelName(empReq)
            return (
              {
                id: empReq.id,
              name: empReq.skill_level__name
              }
            )
          })

        tempDay = selectedVisit && (selectedVisit.repeat_on.length > 0) && selectedVisit.repeat_on.split(',').map((i)=>{return getEmployeeWeekDayOptions(i)});

        empVisit[index] = tempVisit
        empEmployeeReq[index] = tempReq
        empDayOptions[index] = tempDay
        setEmpVisit(empVisit)
        setEmpEmployeeReq(empEmployeeReq)
        setEmpDayOptions([])
        if (response.specific === 'employee') {
          values.employee_day_detail[index] = {
            id: det.id,
            client: det.client_details.id,
            repeat_on: getEmployeeWeekDay(det.repeat_on).id,
            visit: det.client_service_visit,
            requirement: det.requirement
          }
        }
        else if (response.specific === 'run') {
          getShiftArray(det.employee, index);
          values.employee_day_detail[index] = {
            id: det.id,
            client: det.client_details.id,
            repeat_on: getEmployeeWeekDay(det.repeat_on).id,
            visit: det.client_service_visit,
            shift: det.shift,
            requirement: det.requirement,
            employee: det.employee,
            adjustment_details: det.adjustment_details
          }
        }

        // if(response.details.length===index+1){
        setValues({ ...values })
        
        // }
      })

  }
     console.log(values,"5587444")
    return (
        <ModalContainer max_width={'80%'}>
        <ModalDynamic
        {...addFullBind}
        max_width={'80%'}
        minWidthTitle="8.5rem"
        loading={loadingModal}>
        <div className={' ps-3 pe-3 pb-3'}>
        <div className={'ms-5 mb-2 me-5 ps-5 pe-5'}>
            <DropdownSearchable
                placeHolder={'Select Form Type'}
                options={[
                  {id:"client",name:"Client Specific"},
                  {id:"employee",name:"Employee Specific"},
                  {id:"run",name:"Run Specific"}
                ]}
                selectedOption={values.specific}
                onChange={value =>
                  dropDownHandleRegVisit({specific: value ? value : null},
                    'specific'
                    
                    )
                }
              />
          </div>
          {
            values.specific=='client' &&
            <ClientSpecific
            useInput={useInput}
            values={values}
            setValues={setValues}
            choices={choices}
            dropDownHandleRegVisit={dropDownHandleRegVisit}
            dropDownHandleRegEmpDet={dropDownHandleRegEmpDet}
            clients={clients}
            employees={employees}
            visit={visit}
            dayOptions={dayOptions}
            dateError={dateError}
            setSingleRun={setSingleRun}
            dateErrorMessage={dateErrorMessage}
            deleteEmployeeDetails={deleteEmployeeDetails}
            addNewEmployeeDet={addNewEmployeeDet}
            switchEndValueInput={switchEndValueInput}
            calculateEndValueProps={calculateEndValueProps}
            employeeRequired={employeeRequired}
            idSelected={idSelected}
            />
          }
          {values.specific=='employee' && 
          <EmployeeSpecific
          useInput={useInput}
          values={values}
          setValues={setValues}
          choices={choices}
          dropDownHandleRegVisit={dropDownHandleRegVisit}
          dropDownHandleRegEmpDet={dropDownHandleRegEmpDet}
          clients={clients}
          employees={employees}
          shiftArray={shiftArray}
          dateError={dateError}
          dateErrorMessage={dateErrorMessage}
          deleteEmployeeDetails={deleteEmployeeDetails}
          addNewEmployeeDet={addNewEmployeeDet}
          switchEndValueInput={switchEndValueInput}
          calculateEndValueProps={calculateEndValueProps}
          empEmployeeReq={empEmployeeReq}
          empDayOptions={empDayOptions}
          empVisit={empVisit}
          setSingleRun={setSingleRun}
          idSelected={idSelected}
          />
          }
        {values.specific=='run' && 
          <RunSpecific
          useInput={useInput}
          values={values}
          setValues={setValues}
          choices={choices}
          dropDownHandleRegVisit={dropDownHandleRegVisit}
          dropDownHandleRegEmpDet={dropDownHandleRegEmpDet}
          clients={clients}
          employees={employees}
          dateError={dateError}
          dateErrorMessage={dateErrorMessage}
          deleteEmployeeDetails={deleteEmployeeDetails}
          addNewEmployeeDet={addNewEmployeeDet}
          switchEndValueInput={switchEndValueInput}
          calculateEndValueProps={calculateEndValueProps}
          empEmployeeReq={empEmployeeReq}
          empDayOptions={empDayOptions}
          empVisit={empVisit}
          setSingleRun={setSingleRun}
          idSelected={idSelected}
          />
          }
          {singleRun && 
          <SingleSwapRun
            useInput={useInput}
            values={values} 
            dataValues={choices.run_view}
            dropDownHandleSelect={dropDownHandleRegVisit}
            fullBind={fullBindSingleRun}
            saveButtonIcon={saveButtonIcon}
            loadingSave={loadingSave}
            errorMessage={errorMessage}
            handleSaveVisitSwapRun={handleSaveVisitSwapRun}
            />
          }
        
    
          <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
              <div className={'ms-0 ms-md-3'}>
                <PrimaryButtonForm minWidth="6rem" onClick={handleSaveVisit} >
                  <span className={'me-2'}>{textStatus}</span>
                  {!loadingSave ? (
                      saveButtonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
        </div>
        </ModalDynamic>
        </ModalContainer>
    );
}

export default RegularVisitModal;
