import React, {Fragment} from 'react';
import DynamicList from 'components/SharedComponents/DynamicList';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {FaTimes} from 'react-icons/fa';
import DateSelector from 'components/SharedComponents/DateSelector';
import moment from 'moment';
import {KeyPress} from 'utils/constants/regularExpressions';
import {DELETE, COPY} from 'utils/constants/icons';
import {TitleSection} from '../AddModal/styles';
import {PURPLE, SOFT_RED, GRAY_300} from 'shared/styles/constants/colors';
import {IconWrapper} from 'components/SharedComponents/Table/styles';

import {
  CloseButtonWrapper,
  SkillRow,
  LeftSkillRow,
  BorderContainer,
} from './styles';

function AddCompetencies(props) {
  const dropDownHandleSelect = (item, index, attribute) => {
    const newCompetencies = [...props.employee_competencies];
    newCompetencies[index][attribute] = item;

    props.setCompetenciesCallback(newCompetencies);
  };

  const handleInputChange = (e, attribute) => {
    const newCompetencies = [...props.employee_competencies];
    if (e.target.type === 'date') {
      e.target.value = e.target.value
        ? moment(e.target.value).format('YYYY-MM-DD')
        : null;
    }
    newCompetencies[e.target.dataset.idx][attribute] = e.target.value;

    props.setCompetenciesCallback(newCompetencies);
  };
const addCompentency=(index)=>{
  props.addCompentencyCopy(index)
}
  return (
    <DynamicList
      title="Competencies"
      titleAdd={props.readOnly ? false : 'ADD COMPETENCIES'}
      titleSave="SAVE SKILLS"
      addNew={props.addNewCompetenciesCallback}
      save={() => {}}
      noSave={props.noSave}>
      <Fragment>
        {props.employee_competencies ? (
          props.employee_competencies.map((competency, index) => {
            return (
              <BorderContainer className={'ps-1 pe-2 pt-3 pb-1 mb-3'}>
                <SkillRow className="d-flex ms-1 mb-2" key={index}>
                  <LeftSkillRow className="d-flex flex-column w-100">
                    <div className={'d-flex mb-2'}>
                      <CloseButtonWrapper>
                        <FaTimes
                          onClick={() => {
                            props.deleteCompetencyCallback(index);
                          }}
                        />
                        <IconWrapper
                        style={{marginRight:"10px"}}

color={PURPLE}
onClick={() => addCompentency(index)}>
{COPY}
</IconWrapper>
                      </CloseButtonWrapper>
                      <div className="w-100 me-2">
                        <InputLabelTop
                          type="text"
                          id="comp_no"
                          readOnly={props.readOnly}
                          data-idx={index}
                          label={'Competency Number'}
                          onKeyPress={KeyPress.INTEGER}
                          value={
                            props.employee_competencies[index].competency_number
                          }
                          onChange={e => {
                            handleInputChange(e, 'competency_number');
                          }}
                          maxlength={5}
                        />
                      </div>
                      <div className="w-100 me-2">
                        <InputLabelTop
                          type="text"
                          id="asses_method"
                          readOnly={props.readOnly}
                          data-idx={index}
                          label={'Assessment Method'}
                          value={
                            props.employee_competencies[index].assessment_method
                          }
                          onChange={e => {
                            handleInputChange(e, 'assessment_method');
                          }}
                          maxlength={50}
                        />
                      </div>
                      <div className="w-100">
                        <DropdownSearchable
                          placeHolder={'Competency Name'}
                          disabled={props.readOnly}
                          id="comp_name"
                          options={props.competency_name}
                          selectedOption={
                            props.employee_competencies[index].competency_name
                              ? props.employee_competencies[index]
                                  .competency_name
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              value ? value : null,
                              index,
                              'competency_name'
                            )
                          }
                        />
                      </div>
                      <div className="w-100 me-1">
                        {' '}
                        <InputLabelTop
                          type="text"
                          data-idx={index}
                          readOnly={props.readOnly}
                          id="assesor_name"
                          label={'Assessor Name'}
                          value={
                            props.employee_competencies[index].assessor_name
                          }
                          onChange={e => {
                            handleInputChange(e, 'assessor_name');
                          }}
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-1 ms-4">
                      <div className="w-100 me-1">
                        {' '}
                        <DropdownSearchable
                          placeHolder={'Competent'}
                          options={[
                            {id: 1, name: 'Yes'},
                            {id: 2, name: 'No'},
                          ]}
                          disabled={props.readOnly}
                          selectedOption={
                            props.employee_competencies[index].is_competent
                              ? props.employee_competencies[index].is_competent
                              : null
                          }
                          onChange={value =>
                            dropDownHandleSelect(
                              value ? value : null,
                              index,
                              'is_competent'
                            )
                          }
                        />
                      </div>
                      <div className="w-100 me-1">
                        {' '}
                        <DateSelector
                          labelTop="Completed Date"
                          dataIdx={index}
                          value={
                            props.employee_competencies[index].competency_date
                              ? props.employee_competencies[index]
                                  .competency_date
                              : ''
                          }
                          onChange={e => {
                            handleInputChange(e, 'competency_date');
                          }}
                        />
                      </div>
                      <div className="w-100 me-1">
                        {' '}
                        <InputLabelTop
                          type="text"
                          id="comp_rating"
                          readOnly={props.readOnly}
                          data-idx={index}
                          label={'Competency Rating'}
                          value={
                            props.employee_competencies[index].competency_rating
                          }
                          onChange={e => {
                            handleInputChange(e, 'competency_rating');
                          }}
                          maxlength={5}
                        />
                      </div>
                      <div className="w-100 me-1">
                        {' '}
                        <InputLabelTop
                          type="text"
                          id="achive_lv"
                          readOnly={props.readOnly}
                          data-idx={index}
                          label={'Level Of Achievement'}
                          value={
                            props.employee_competencies[index].achivement_level
                          }
                          onChange={e => {
                            handleInputChange(e, 'achivement_level');
                          }}
                          maxlength={50}
                        />
                      </div>
                      <div className="w-100 me-1">
                        {' '}
                        <InputLabelTop
                          type="text"
                          id="reap_freq"
                          readOnly={props.readOnly}
                          data-idx={index}
                          label={'Reapplication Frequency'}
                          value={
                            props.employee_competencies[index]
                              .reapplication_frequency
                          }
                          onChange={e => {
                            handleInputChange(e, 'reapplication_frequency');
                          }}
                          maxlength={50}
                        />
                      </div>
                      <div className="w-100 me-1">
                        {' '}
                        <DateSelector
                          labelTop="Renewal Date"
                          dataIdx={index}
                          value={
                            props.employee_competencies[index].renewal_date
                              ? props.employee_competencies[index].renewal_date
                              : ''
                          }
                          onChange={e => {
                            handleInputChange(e, 'renewal_date');
                          }}
                        />
                      </div>
                    </div>
                  </LeftSkillRow>
                </SkillRow>
              </BorderContainer>
            );
          })
        ) : (
          <></>
        )}
      </Fragment>
    </DynamicList>
  );
}

export default AddCompetencies;
