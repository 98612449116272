import React, { useRef, } from 'react';
import { useForm } from 'hooks/FormHook';
import { Toast } from 'primereact/toast';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { useState } from 'react';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED } from 'utils/constants/messages';
import AddForm from './AddForm';
import { Fragment } from 'react';
import { ErrorMessage } from 'shared/styles/AuthComponents';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import { Header } from 'shared/styles/constants/tagsStyles';
import { getPrivacySettingApi, setPrivacySetting } from 'utils/api/SettingsApi';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AppContext } from 'context';
import { privacy_setting_choices } from 'utils/choiceConstant';

const PrivacySetting = ({
    branch,
    readPermission,
    createPermission,
    updatePermission,
    deletePermission
}) => {

    const toast = useRef(null);
    const { handleGetChoices, contextChoices } = useContext(AppContext);
    const PRIVACY = 'privacy';
    const { values, setValues, useInput, errors, setCheckRequires, setFormErrors, } = useForm({ [PRIVACY]: [{}] });

    const [isExist, setIsExist] = useState(false)

    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true)
    const [textStatus, setTextStatus] = useState(SAVE);
    const [buttonIcon, setButtonIcon] = useState(FLOPPY);
    const [loadingSave, setLoadingSave] = useState(false);
    const [options, setOptions] = useState({})
    const [showError, setShowError] = useState(false)


    const addNewAvoid = () => {
        values[PRIVACY].push({});
        setValues({ ...values });
        setLoadingSave(false);
        setTextStatus(SAVE);
        setButtonIcon(FLOPPY);
    };



    const getPrivacySetting = async () => {
        try {
            const res = await getPrivacySettingApi(branch?.id)
            if (res.list.length > 0) {
                setIsExist(res.list.length > 0)
                setValues({ branch: res.branch, [PRIVACY]: res.list })
            }
            setLoading(false)

        } catch (error) {

        }
    }



    useEffect(() => {
        getPrivacySetting()
        handleGetChoices(privacy_setting_choices)
    }, [])



    const handleSubmit = async () => {
        if (errors.length) {
            setShowError(true)
            setCheckRequires(true)
            setErrorMessage(InvalidOrEmptyValues)
            return;
        }
        setLoadingSave(true)
        const body = {
            branch: branch.id,
            list: values.privacy
        }
        try {
            const res = await setPrivacySetting(body, branch?.id, isExist)
            if (res.status === true) {
                onSuccess()
            } else {
                onError(res.message)
            }
        } catch (error) {
            onError(error)
        }
    }



    const onSuccess = () => {
        getPrivacySetting()
        setLoadingSave(false);
        setCheckRequires(false)
        setShowError(false)
        setButtonIcon('');
        setButtonIcon(CHECK);
        setTextStatus(SAVED);
        toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `Success!`,
        });
    }


    const onError = err => {
        setLoadingSave(false);
        setShowError(false)
        setButtonIcon('');
        setButtonIcon(CROSS);
        setTextStatus(ERROR);
        toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: err ? err : `Something went wrong!`,
        });
        setCheckRequires(false)
    };

    return (
        <div style={{ width: "100%", marginTop: "9rem" }}>
            <Toast ref={toast} position="top-right" />
            {readPermission ? (
                <div className='w-100 mb-4'>
                    <Toast ref={toast} position='top-right' />
                    <Header className='mb-2'>
                        <div className='w-100 d-flex justify-content-end'>
                            {createPermission && <PrimaryButtonForm
                                className={'ms-3 mt-1 mb-2'}
                                bgcolor="BLUE"
                                disabled={values.privacy.length > 3}
                                style={{ width: '5px' }}
                                onClick={() => addNewAvoid()}>
                                <span>{<BsFillPlusSquareFill />}NEW PRIVACY</span>
                            </PrimaryButtonForm>}
                        </div>
                    </Header>
                    <div className='mt-3'>
                        <ComponentDynamic loading={loading}>
                            {values.privacy?.length >= 1 &&
                                values.privacy?.map((item, index) => {
                                    return (
                                        <div>
                                            <AddForm
                                                useInput={useInput}
                                                readOnly={isExist ? !updatePermission : !createPermission}
                                                choices={contextChoices}
                                                values={values}
                                                index={index}
                                                setValues={setValues}
                                                PRIVACY={PRIVACY}
                                                options={options}
                                                setOptions={setOptions}
                                                setFormErrors={setFormErrors}
                                                errors={errors}
                                                toast={toast}
                                            />
                                        </div>
                                    )
                                })}
                            <div>
                                <div
                                    className={'ms-0 ms-md-3 me-2'}
                                    style={{ display: 'flex', justifyContent: 'right', marginTop: '5px', height: "46px", }}>
                                    <div className={'mb-3'}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'right',
                                            marginRight: '4px',

                                        }}>
                                        {(showError && errors.length) ? (
                                            <ErrorMessage style={{ color: "#e44011" }}>
                                                <label className={'mt-2'}>{errorMessage}</label>
                                            </ErrorMessage>
                                        ) : (
                                            <Fragment />
                                        )}
                                    </div>

                                    <PrimaryButtonForm
                                        minWidth="6rem"
                                        disabled={isExist ? !updatePermission : !createPermission}
                                        onClick={handleSubmit}
                                        style={{ marginBottom: '5px' }}>
                                        <span className={'me-2'}>{textStatus}</span>
                                        {!loadingSave ? (
                                            buttonIcon
                                        ) : (
                                            <span
                                                className="spinner-border spinner-border-sm me-1"
                                                role="status"
                                            />
                                        )}
                                    </PrimaryButtonForm>

                                </div>
                            </div>

                        </ComponentDynamic>
                    </div>
                </div>
            ) : (
                <div className='d-flex w-100 justify-content-center'>
                    <NoPermissionContainer>
                        You don't have permission to read the information.
                    </NoPermissionContainer>
                </div>
            )}
        </div>
    );
};

export default PrivacySetting;
