import React, { useState, useEffect, Fragment } from 'react';
import { ModalContainer, ErrorMessage, } from 'shared/styles/constants/tagsStyles';
import { ERROR, InvalidOrEmptyValues, SAVE, SAVED, } from 'utils/constants/messages';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { updateVisitTravelMode } from "utils/api/SchedulerApi"
import { useForm } from 'hooks/FormHook';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import ModalNoInfo from 'components/DashboardComponents/ModalDynamic/ModalNoInfo';

const UpdateTravel = ({
  fullBind,
  checkedRows,
  searchScheduler,
  choices,
  setModalUpdateTravel,
  toast,
  rightClickSelectedId,
setRightClickSeletcedId,
setSelectedEvents,
clearSelectedElId
}) => {
  const [addFullBind, setAddFullBind] = useState(fullBind);
  const { values, setValues, useInput, errors, setCheckRequires } = useForm({ validation: false });
  const [errorMessage, setErrorMessage] = useState('')
  const [textStatus, setTextStatus] = useState(SAVE);
  const [loadingSave, setLoadingSave] = useState(false);
  const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);
  const [visitFailData, setvisitFailData] = useState({});
  const [disableButton, setDisableButton] = useState([]);


  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Update Travel Mode'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullBind]);


  const dropDownHandler = (item, nameItem) => {
    values[nameItem] = item[nameItem];
    setValues({ ...values });
  }

  const handleSubmit = () => {
    if (errors.length > 0 || loadingSave) {
      setCheckRequires(true)
      setErrorMessage(InvalidOrEmptyValues);
      return
    }
    setLoadingSave(true)
    setErrorMessage('');
    let body = {
      visit: rightClickSelectedId.length ? [...rightClickSelectedId] :  checkedRows || [],
      travel_mode: values.travel_mode
    }
    updateVisitTravelMode(body)
      .then(res => {
        if (res.statusCode == 400) {
          throw res.message
        }
        let visitf = []
        if (!res.status && res.visitFail) {
          checkedRows.forEach((item) => {
            if (res.visitFail[item]) {
              visitf.push({ ...res.visitFail[item], visit_id: item })
            }
          })
          setvisitFailData(visitf);
          toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: res.message
          });
          setSaveButtonIcon(FLOPPY);
          setLoadingSave(false);
          setTextStatus(SAVE);
        } else if (!res.status && !res.visitFail) {
          setSaveButtonIcon(CROSS);
          setTextStatus(ERROR);
          setLoadingSave(false);
          setErrorMessage(res.message)
          toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: res.message
          });
        } else {
          setSaveButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          setModalUpdateTravel(false)
          clearSelectedElId();
          setErrorMessage('')
          toast.current.show({
            severity: 'success',
            summary: '',
            detail: res.message
          });
          searchScheduler()
          setRightClickSeletcedId([])
          setSelectedEvents([])
        }

      })
      .catch(err => {
        setSaveButtonIcon(CROSS);
        setTextStatus(ERROR);
        setLoadingSave(false);
        setErrorMessage(err)
      })
  }

  return (
    <ModalContainer max_width={'50%'}>
      <ModalNoInfo
        {...addFullBind}
        max_width={'50%'}
        minWidthTitle="6.5rem"
        loading={false}
      >
        <div className={' ps-3 pe-3 pb-2'}>
          <div className={'w-100 ps-3 pe-3 pb-2'}>
            <DropdownSearchable
              disabled={(visitFailData && visitFailData.length > 0)}
              required
              placeHolder={'Select Travel Mode'}
              options={choices?.travel_type || []}
              {...useInput(`travel_mode`, 'isRequired')}
              selectedOption={values ? values.travel_mode : null}
              onChange={value => dropDownHandler({ travel_mode: value ? value : null }, 'travel_mode')
              }
            />
          </div>
          <>
            {
              (visitFailData && visitFailData.length > 0) ? (
                visitFailData.map((item, index) => {
                  return (
                    <div className='d-flex justify-content-between mt-1 mb-2'>
                      <div>{index + 1}. {item.HEADING} -  {item.MESSAGE}</div>
                    </div>
                  )
                })
              ) : (
                <div className={'d-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'}>
                  <div className={'mb-3'}>
                    {errorMessage ? (
                      <ErrorMessage>
                        <label className={'mt-2'}>{errorMessage}</label>
                      </ErrorMessage>
                    ) : (
                      <Fragment />
                    )}
                  </div>

                  <div className={'d-flex justify-content-center justify-content-md-end'}>
                    <div className={'ms-0 ms-md-3 mb-1'}>
                      <PrimaryButtonForm minWidth="6rem"
                        onClick={handleSubmit}
                        disabled={(visitFailData && visitFailData.length > 0)}
                      >
                        <span className={'me-2'}>{textStatus}</span>
                        {!loadingSave ? (
                          saveButtonIcon
                        ) : (
                          <span
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                          />
                        )}
                      </PrimaryButtonForm>
                    </div>
                  </div>
                </div>
              )



            }
          </>
        </div>
      </ModalNoInfo>
    </ModalContainer>
  );
}

export default UpdateTravel;
