import React, {useState, useEffect, Fragment} from 'react';
import { Title,TitleSection} from 'shared/styles/constants/tagsStyles';
import {
    FlexGrid3Container,
    FlexGrid3Item,
    ErrorMessage,
    ModalContainer,
  } from 'shared/styles/constants/tagsStyles';
  import InputLabelTop from 'components/SharedComponents/InputLabelTop';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {NameWrapper}  from 'shared/styles/constants/tagsStyles';

const ScheduledVisitPay = ({
  dropDownHandleSelectPay,
  billRate,
    useInput,
    values,
    setValues,
    datapay,
    editMode,
    payRate,
}) => {

// console.log( scheduled_visit_pay_rate ,789789)

  return (
    <div  className='mt-1   mb-1' >
        <>
        <div 
        //  key={indexpay} className={' mb-1'}
         >
      {/* <NameWrapper  className='ms-1'>{datapay.employee_name && datapay.employee_name } </NameWrapper> */}
     {/* <div className='d-flex'> */}
        <Fragment />
       
            <FlexGrid3Container className={'w-100  ms-1 '}>
          <FlexGrid3Item className="pe-1  pe-0 pe-md-1 pt-1"   ResizeWidth={'40%'} >
          <DropdownSearchable
          placeHolder={'Visit Pay Rate Card'}
       disabled={!editMode}
       options={payRate}
       selectedOption={datapay  ? datapay.visit_pay_rate_card  : null }
       onChange={value =>
        dropDownHandleSelectPay(
        {visit_pay_rate_card: value ? value : null},  'visit_pay_rate_card' , 'scheduled_visit_pay_rate'  
         )  }
        />
            </FlexGrid3Item>
            <FlexGrid3Item className=" pe-0 pe-md-1 pt-1  " ResizeWidth={'15%'} >
            <InputLabelTop
             readOnly={true}
             disabled={true}
                label={'Visit  Pay Rate/hr'}
                type="text"
                {...useInput(`scheduled_visit_pay_rate.visit_pay_rate_per_hour`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1  pt-1 "  ResizeWidth={'20%'} >          
            <InputLabelTop
                 readOnly={true}
                 disabled={true}
                label={'Visit  Pay Rate'}
                type="text"
                {...useInput(`scheduled_visit_pay_rate.visit_pay_rate`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pt-1  "  ResizeWidth={'20%'} >          
            <InputLabelTop
                   readOnly={true}
                   disabled={true}
                label={'Visit Pay Amount'}
                type="text"
                {...useInput(`scheduled_visit_pay_rate.visit_pay_amount`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            </FlexGrid3Container > 
            <FlexGrid3Container className={'w-100  ms-1 '}>
            <FlexGrid3Item className="ps-1 pe-0 pe-md-1 pt-1  "  ResizeWidth={'18%'} >          
            <InputLabelTop
                    readOnly={true}
                    disabled={true}
                label={'Bill Ratio'}
                type="text"
                {...useInput(`scheduled_visit_pay_rate.visit_pay_bill_ratio`)}
                maxlength={5}
              />
            </FlexGrid3Item>
            </FlexGrid3Container >    
             {/* </div> */}
            </div>
        </>
    </div>
  )
}

export default ScheduledVisitPay