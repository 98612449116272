
import TimeSelector from 'components/SharedComponents/TimeSelector'
import moment from 'moment'
import React from 'react'
import { CloseButtonWrapper, FlexGrid3Item } from 'shared/styles/constants/tagsStyles'
import { TIMES } from 'utils/constants/icons'
import ToggleButton from '../ToggleButton'

function FloatingBreak({
    values,
    setValues,
    useInput,
    nameSection,
    index,
    shiftIndex,
    deleteBreakRow = () => { },
    deletePermission,
    readOnly = false,
    isRotaShift = false 
}) {

    // const break_start_time_hook = useInput(`regularShifts.shifts[${shiftIndex}].${nameSection}[${index}].break_start_time`, "isRequired")
    const break_start_time_hook = useInput(!isRotaShift
        ? `regularShifts.shifts[${shiftIndex}].${nameSection}[${index}].break_start_time`
        : `${nameSection}[${index}].break_start_time`, "isRequired")

    const break_end_time_hook = useInput(!isRotaShift
        ? `regularShifts.shifts[${shiftIndex}].${nameSection}[${index}].break_end_time`
        : `${nameSection}[${index}].break_end_time`, "isRequired")

    const break_duration_hook = useInput(!isRotaShift
        ? `regularShifts.shifts[${shiftIndex}].${nameSection}[${index}].break_duration`
        : `${nameSection}[${index}].break_duration`, "isRequired")

    const preferd_break_start_time_hook = useInput(`${nameSection}[${index}].actual_break_start_time`)
    const preferd_break_end_time_hook = useInput(`${nameSection}[${index}].actual_break_end_time`)



    const break_start_time = isRotaShift ? values[nameSection][index]?.break_start_time : values?.regularShifts?.shifts[shiftIndex]?.[nameSection][index]?.break_start_time
    const break_end_time = isRotaShift ? values[nameSection][index]?.break_end_time : values?.regularShifts?.shifts[shiftIndex]?.[nameSection][index]?.break_end_time
    const break_duration = isRotaShift ? values[nameSection][index]?.break_duration : values?.regularShifts?.shifts[shiftIndex]?.[nameSection][index]?.break_duration

    const actual_break_start_time = isRotaShift && values[nameSection][index]?.actual_break_start_time

    const isPaid = isRotaShift ? values[nameSection][index]?.is_paid : values?.regularShifts?.shifts[shiftIndex]?.[nameSection][index]?.is_paid
    const shift_start_time = isRotaShift ? values?.start : values?.regularShifts?.shifts[shiftIndex]?.start_time;
    const shift_end_time = isRotaShift ? values?.end : values?.regularShifts?.shifts[shiftIndex]?.end_time;

// const end_date ="2024-12-05T00:00:00.000Z"
// const start_date ="2024-12-04T00:00:00.000Z"


    const getDalidateDuration = () => {

        if (!break_start_time || !break_end_time) {
            return false;
        }

        const start = new Date(`1970-01-01T${break_start_time}:00`);
        const end = new Date(`1970-01-01T${break_end_time}:00`);
        const durationSeconds = (end - start) / 1000;
        const totalSeconds = durationSeconds >= 0 ? durationSeconds : durationSeconds + 24 * 3600;
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);


        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    const validateTime = (type) => {
        if (!break_start_time || !break_end_time) return false;

        const startMoment = moment(break_start_time, 'HH:mm');
        const endMoment = moment(break_end_time, 'HH:mm');

        if (type === "start") {
            return !startMoment.isBefore(endMoment);
        } else if (type === "end") {
            return !endMoment.isAfter(startMoment);
        }

        return false;
    };


    const validateTimeAndDuration = () => {
        if (!break_start_time || !break_end_time || !break_duration) {
            return false;
        }

        const start_end_duration = getDalidateDuration()
        const duration = moment(start_end_duration, 'HH:mm');
        const my_break_duration = moment(break_duration, 'HH:mm');
        const diffMinutes = my_break_duration.diff(duration, 'minutes');

        if (diffMinutes > 0) {
            return true;
        } else {
            return false;
        }
    };




    const validateDuration = getDalidateDuration()
    const handlePaid = () => {
        if (isRotaShift) {
            values[nameSection][index].is_paid = !isPaid
        } else {
            values.regularShifts.shifts[shiftIndex][nameSection][index].is_paid = !isPaid
        }
        setValues({ ...values })

    }
    console.log(isRotaShift ? values[nameSection][index] : values.regularShifts, "isRotaShift")
console.log(values,"valuesvalues")
    return (
        <div className=''>
            <div className='d-flex'>
                <CloseButtonWrapper
                   disabled={readOnly}
                    onClick={() => {
                        deleteBreakRow(index);
                    }}
                >
                    {TIMES}
                </CloseButtonWrapper>
                <FlexGrid3Item ResizeWidth={isRotaShift ? isRotaShift ? "32%" : null : null} className="pe-1 ps-1 mt-2">
                    <TimeSelector
                        disabled={readOnly}
                        isRequired={true}
                        data-error={validateTime("start")}
                        errorMessage={validateTime("start") && "Invalid Time"}
                        id="break_start_time"
                        labelTop={'Min Break Start Time'}
                        //added below code  for time in 24hrs format 
                        timeFormat={"HH:mm"}
                        dateFormat={"HH:mm"}
                        minTime={shift_start_time && shift_start_time}
                        maxTime={shift_end_time && shift_end_time}
                        {...break_start_time_hook}
                    />
                </FlexGrid3Item>
                <FlexGrid3Item ResizeWidth={isRotaShift ? "32%" : null} className="pe-1 ps-1 mt-2">
                    <TimeSelector
                        disabled={readOnly}
                        isRequired={true}
                        data-error={validateTime("end")}
                        errorMessage={validateTime("end") && "Invalid Time"}
                        id="break_end_time"
                        labelTop={'Max Break End Time'}
                        //added below code  for time in 24hrs format 
                        timeFormat={"HH:mm"}
                        dateFormat={"HH:mm"}
                        minTime={break_start_time && break_start_time}
                        maxTime={shift_end_time && shift_end_time}
                        {...break_end_time_hook}
                    />
                </FlexGrid3Item>
                <FlexGrid3Item ResizeWidth={isRotaShift ? "32%" : null} className="pe-1 ps-1 mt-2">
                    <TimeSelector
                        disabled={readOnly}
                        isRequired={true}
                        defaultFocus={"00:00"}
                        data-error={validateTimeAndDuration()}
                        errorMessage={validateTimeAndDuration() && "Invalid Duration"}
                        id="break_duration"
                        labelTop={'Break Duration'}
                        timeCaption={"Duration"}
                        //added below code  for time in 24hrs format 
                        timeFormat={"HH:mm"}
                        dateFormat={"HH:mm"}
                        maxTime={validateDuration && validateDuration}
                        {...break_duration_hook}
                    />
                </FlexGrid3Item>

                {!isRotaShift &&
                    <FlexGrid3Item ResizeWidth={isRotaShift ? "32%" : null} className="pe-1 ps-1 mt-2">
                        <ToggleButton
                            label="Is Paid"
                            disabled={readOnly}
                            // useInput={make_user_hook}
                            status={isPaid}
                            id="is_paid"
                            onClick={() => handlePaid()}
                        />
                    </FlexGrid3Item>}
            </div>



            {isRotaShift &&
                <div className='d-flex'>
                    <div style={{ minWidth: "28px", width: "28px" }} />
                    <FlexGrid3Item ResizeWidth={isRotaShift ? "32%" : null} className="pe-1 ps-1 mt-2">
                        <TimeSelector
                            disabled={readOnly}
                            // isRequired={true}
                            // data-error={validateTime("end")}
                            // errorMessage={validateTime("end") && "Invalid Time"}
                            id="actual_break_start_time"
                            labelTop={'Actual Break Start Time'}
                            //added below code  for time in 24hrs format 
                            timeFormat={"HH:mm"}
                            dateFormat={"HH:mm"}
                            maxTime={break_end_time && break_end_time}
                            minTime={break_start_time && break_start_time}
                            {...preferd_break_start_time_hook}
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item ResizeWidth={isRotaShift ? "32%" : null} className="pe-1 ps-1 mt-2">
                        <TimeSelector
                            disabled={readOnly}
                            // isRequired={true}
                            // data-error={validateTime("end")}
                            // errorMessage={validateTime("end") && "Invalid Time"}
                            id="actual_break_end_time"
                            labelTop={'Actual Break End Time'}
                            //added below code  for time in 24hrs format 
                            timeFormat={"HH:mm"}
                            dateFormat={"HH:mm"}
                            maxTime={break_end_time && break_end_time}
                            minTime={actual_break_start_time && actual_break_start_time}
                            {...preferd_break_end_time_hook}
                        />
                    </FlexGrid3Item>
                    <FlexGrid3Item ResizeWidth={isRotaShift ? "32%" : null} className="pe-1 ps-1 mt-2">
                        <ToggleButton
                            label="Is Paid"
                            // useInput={make_user_hook}
                            status={isPaid}
                            id="is_paid"
                            onClick={() => handlePaid()}
                        />
                    </FlexGrid3Item>
                </div>}
        </div>
    )
}

export default FloatingBreak