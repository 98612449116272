import DateSelector from 'components/SharedComponents/DateSelector';
import CustomYearInput from 'components/SharedComponents/DateSelector/CustomYearInput';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { Toast } from 'primereact/toast';
import React from 'react'
import { useEffect } from 'react';
import { useMemo } from 'react';
import { CloseButtonWrapper } from 'shared/styles/constants/tagsStyles';
import { TIMES } from 'utils/constants/icons';

function AddForm({
    values,
    setValues,
    useInput,
    toast,
    choices,
    index,
    errorMessage,
    readOnly,
    errors,
    setCheckRequires,
    setFormErrors,
    setErrorMessage,
    NAME_SPACE
}) {

    const dropDownHandleSelect = (item, nameSection, index) => {
        values[NAME_SPACE][index][nameSection] = item[nameSection];
        console.log(values[NAME_SPACE][index], nameSection, item, "drop")
        setValues({ ...values });
    };
    const deleteAddRate = (val, indexs) => {
       let filterdErr =  errors?.filter(err => !err.includes(`${NAME_SPACE}[${index}]`))
       setFormErrors(filterdErr)
        values[NAME_SPACE].splice(indexs, 1);
        setValues({ ...values });
    };
  

    return (
        <div className=''>
            <>
                <div className="d-flex flex-column">
                    <div className="d-flex">
                        {(values[NAME_SPACE]?.length >= 1) && (
                            <CloseButtonWrapper
                                // disabled={readOnly}
                                onClick={() => {
                                    deleteAddRate(values, index);
                                }}>
                                {TIMES}
                            </CloseButtonWrapper>
                        )}

                        <div className="w-100 pe-2">
                            <DropdownSearchable
                                required
                                {...useInput(
                                    `${[NAME_SPACE]}[${index}].absence_type`,
                                    'isRequired'
                                )}
                                disabled={readOnly}
                                placeHolder={'Absence Type'}
                                options={choices.absence_type || []}
                                selectedOption={values[NAME_SPACE][index].absence_type}
                                onChange={value =>
                                    dropDownHandleSelect({
                                        absence_type: value ? value : null,
                                    },
                                        'absence_type',
                                        index
                                    )
                                }
                            />
                        </div>

                        <div className="w-100">
                            <DropdownSearchable
                                required
                                {...useInput(
                                    `${[NAME_SPACE]}[${index}].absence_paid`,
                                    'isRequired'
                                )}
                                disabled={readOnly}
                                placeHolder={'Absence Paid'}
                                options={choices.absence_paid || []}
                                selectedOption={values[NAME_SPACE][index].absence_paid}
                                onChange={value =>
                                    dropDownHandleSelect({
                                        absence_paid: value ? value : null,
                                    },
                                        'absence_paid',
                                        index
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column ">
                    <div className="d-flex">
                        <div style={{ width: "3.8rem" }} />
                        <div
                            className="w-100 pe-2">
                            <InputLabelTop
                                // margin={'5px'}
                                required
                                disabled={readOnly}
                                label='Absence Allowance'
                                type="number"
                                id={`absence_allowance`}
                                {...useInput(
                                    `${[NAME_SPACE]}[${index}].absence_allowance`,
                                    'isRequired'
                                )}
                                maxlength={100}
                            />
                        </div>
                        <div className="w-100 ">
                            <DropdownSearchable
                                required
                                disabled={readOnly}
                                placeHolder={'Time Unit'}
                                options={choices.absence_time_unit}
                                selectedOption={values[NAME_SPACE][index].time_unit}
                                {...useInput(
                                    `${[NAME_SPACE]}[${index}].time_unit`,
                                    'isRequired'
                                )}
                                onChange={value =>
                                    dropDownHandleSelect(
                                        {
                                            time_unit: value ? value : null,
                                        },
                                        'time_unit',
                                        index
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className={'d-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'}>
                    <div className={'d-flex justify-content-center justify-content-md-end'}></div>
                </div>
            </>
        </div>
    )
}

export default AddForm