import React from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {KeyPress} from 'utils/constants/regularExpressions';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
const FunderContact = ({
    choices,
    values,
    useInput,
    dropDownHandleSelect,
    readOnly,
    newAddFunder
}) => {
    return (
        <>
           <div className='d-flex mt-2 mb-2'>
            <div className='w-100 me-2'>
            <InputLabelTop
            disabled={readOnly}
              type="text"
              id="funder_no"
              label={'Funder Contact No'}
              {...useInput(newAddFunder?`addFunder.funder_contact_no`:`funder_contact_no`)}
              maxlength={255}
            />
            </div>
            <div className='w-100 me-2'>
            <InputLabelTop
             disabled={readOnly}
              type="text"
              id="funder_name"
              label={'Full Name'}
              {...useInput(newAddFunder?`addFunder.funder_contact_full_name`:`funder_contact_full_name`)}
              maxlength={255}
            />
            </div>
            <div className='w-100'>
            <InputLabelTop
             disabled={readOnly}
              type="email"
              id="funder_email"
              label={'Email'}
              {...useInput(newAddFunder?`addFunder.funder_contact_email`:`funder_contact_email`,'isEmail')}
              maxlength={254}
            />
            </div>
        </div> 
        <div className='d-flex mb-2'>
            <div className='w-100 me-2'>
            <InputLabelTop
             disabled={readOnly}
              type="text"
              id="phone_no"
              label={'Phone No'}
              onKeyPress={KeyPress.PHONE}
              {...useInput(newAddFunder?`addFunder.funder_contact_phone_numbers`:`funder_contact_phone_numbers`,'isMobilePhone')}
              maxlength={25}
            />
            </div>
            <div className='w-100'>
            <InputLabelTop
             disabled={readOnly}
              type="text"
              id="org_title"
              label={'Organization Title'}
              {...useInput(newAddFunder?`addFunder.funder_contact_organisation_title`:`funder_contact_organisation_title`)}
              maxlength={255}
            />
            </div>
         
        </div> 
        <div className='d-flex mb-2'>
            <div className='w-100 me-2'>
            <InputLabelTop
             disabled={readOnly}
              type="text"
              id="house_name"
              label={'House Name/Company Name'}
              {...useInput(newAddFunder?`addFunder.name`:`name`)}
              maxlength={255}
            />
            </div>
            <div className='w-100 me-2'>
            <InputLabelTop
             disabled={readOnly}
              type="text"
              id="org_title"
              label={'Address Line 1'}
              {...useInput(newAddFunder?`addFunder.line_1`:`line_1`)}
              maxlength={255}
            />
            </div>
            <div className='w-100'>
            <InputLabelTop
             disabled={readOnly}
              type="text"
              id="org_title"
              label={'Address Line 2'}
              {...useInput(newAddFunder?`addFunder.line_2`:`line_2`)}
              maxlength={255}
            />
            </div>
        </div> 
        <div className='d-flex mb-2'>
            <div className='w-100 me-2'>
            <InputLabelTop
             disabled={readOnly}
              type="text"
              id="town"
              label={'Town/City'}
              {...useInput(newAddFunder?`addFunder.city`:`city`)}
              maxlength={255}
            />
            </div>
            <div className='w-100 me-2'>
            <InputLabelTop
             disabled={readOnly}
              type="text"
              id="state"
              label={'Country/State'}
              {...useInput(newAddFunder?`addFunder.state`:`state`)}
              maxlength={255}
            />
            </div>
            <div className='w-100 me-2'>
            <InputLabelTop
             disabled={readOnly}
              type="text"
              id="zip_code"
              label={'Postcode/zipcode'}
              {...useInput(newAddFunder?`addFunder.zip_code`:`zip_code`)}
              maxlength={255}
            />
            </div>
            <div className='w-100'>
            <DropdownSearchable
             disabled={readOnly}
               placeHolder={'Country'}
               options={choices.country}
              selectedOption={
                newAddFunder?
                values.addFunder
                  ? values.addFunder.country
                  : null
                :values.country
                  ? values.country
                  : null
              }
              onChange={(value) =>
                dropDownHandleSelect(
                  {country: value ? value : null},
                  'country',newAddFunder
                )
              }
            />
            </div>
        </div> 
        </>
    );
}

export default FunderContact;
