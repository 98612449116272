import React, { useState, useEffect, useContext, Fragment, useRef } from 'react';
import moment from 'moment';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { KeyPress } from 'utils/constants/regularExpressions';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, FLOPPY } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import {
  deleteCompanyBranchAssigned,
  deleteFormBuilderListChoiceData,
  getBranchAssignedToById,
  getBranchById,
  getDropdownData,
  getFormBuilderListChoiceData,
  setCompanyBranch,
  setCompanyBranchAssign,
  setFormBuilderChoices,
} from 'utils/api/SettingsApi';
import { AppContext, BranchItemsContext } from 'context';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {
  FlexGrid3Container,
  FlexGrid3Item,
  ErrorMessage,
  ModalContainer,
  Title,
} from 'shared/styles/constants/tagsStyles';
import { ERROR, SAVE, SAVED } from 'utils/constants/messages';
import { Toast } from 'primereact/toast';

import Geocode from 'react-geocode';
import Table from 'components/SharedComponents/Table';
import {
  EX_LARGE_COL,
  EX_VERY_LARGE_COL,
  LARGE_COL,
} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper,
} from 'components/SharedComponents/Table/styles';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useModal } from 'hooks/ModalHook';
import { Header } from 'components/DashboardComponents/Team/styles';
import { get } from 'utils/api/Api.js';
import { getTenant } from 'utils/localStorage/token';
import { apiURL } from 'utils/env';

const Choices = ({
  choicesReadPermission,
  choicesUpdatePermission,
  choicesDeletePermission,
  choicesCreatePermission,
}) => {
  const { values, setValues, useInput, isValid, errors, setCheckRequires } =
    useForm({});

  const [loadingSave, setLoadingSave] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);
  const [listData1, setListData1] = useState([]);
  const [listData2, setListData2] = useState([]);
  const [listData3, setListData3] = useState([]);
  const [loadingModal, setLoadingModal] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [textStatus, setTextStatus] = useState(SAVE);
  const [deleteId, setDeleteId] = useState(SAVE);
  const [errorMessage, setErrorMessage] = useState('');
  const { contextChoices } = useContext(AppContext);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);

  const getBaseUrl = () => {
    let tenant = getTenant();
    return `${apiURL}/${tenant}/api/v1`;
  };
  const baseUrl = getBaseUrl();

  const toast = useRef();
  useEffect(() => {
    loadListData();
    loadDropdownItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
  const headerColumnsEmp = [
    {
      label: '',
      name: 'name',
      status: 0,
      className: 'grow-mobile  sm-hidden',
      max_width: EX_LARGE_COL,
    },
  ];

  const headerColumnsClient = [
    {
      label: '',
      name: 'name',
      status: 0,
      className: 'grow-mobile  sm-hidden',
      max_width: EX_LARGE_COL,
    },
  ];

  useEffect(() => {
    setLoadingModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (buttonIcon !== FLOPPY) {
      setButtonIcon(FLOPPY);
      setTextStatus(SAVE);
    }

    if (isValid && errors.length <= 0) {
      setCheckRequires(false);
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const split = (array, n) => {
    const p = array.length % n,
      q = Math.ceil(array.length / n),
      r = Math.floor(array.length / n);
    return [...Array(n)].reduce(
      (a, _, i) => (a[0].push(array.slice(a[1], (a[1] += i < p ? q : r))), a),
      [[], 0]
    )[0];
  };

  const loadListData = async () => {

    const response = await getFormBuilderListChoiceData();
    if (response.results) {
      const list = [...response.results];
      // const middleIndex = Math.ceil(list.length / 2);

      // const firstHalf = list.splice(0, middleIndex);
      // const secondHalf = list.splice(-middleIndex);
      // setListData1(firstHalf)
      // setListData2(secondHalf)
      const arr = split(list, 3);
      setListData1(arr[0]);
      setListData2(arr[1]);
      setListData3(arr[2]);
    }
    setLoadingModal(false);
  };

  const loadDropdownItems = async () => {

    const response = await getDropdownData();
    if (response) {
      const temp = response?.data?.map(item => ({name: item.key_name, id: item.name})) || [];
      setDropdownData([...temp] || []);
      
    }
  };

  const deleteClick = () => {
    deleteFormBuilderListChoiceData(deleteId).then(res => {
      if (res?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Delete Successfully',
        });
        setModalDeleteValue(false);
        loadListData();
      }
    });
  };

  const handleSubmit = async () => {
    setLoadingSave(true);
    const body = {
      choice_key: values?.choice_key,
      name: values?.choice_name,
    };
    let response = await setFormBuilderChoices(body);
    if (response.status === true) {
      setLoadingSave(false);
      loadListData();
      values.choice_name = '';
      setValues({ ...values });
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Saved Successfully',
      });
    } else {
      setLoadingSave(false);
      setButtonIcon(CROSS);
      setTextStatus(ERROR);
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `${response.message}`,
      });
    }
  };
  const dropDownHandleSelect = (item, name, obj) => {
    values.choice_name = item.choice_name;
    values.choice_key = name;

    setValues({ ...values });
  };

  const choicesName = useInput(`choice_name`, '');

  return (
    <div>
      {choicesReadPermission && (
        <div className="ps-3 pe-3 pb-3 pt-0">
          <Header>
            {choicesCreatePermission && (
              <FlexGrid3Container className={'w-100'}>
                <Toast ref={toast} position="top-right" />

                <FlexGrid3Item className="pe-1">
                  <DropdownSearchable
                    // isMulti={true}
                    placeHolder={'Choices'}
                    disabled={false}
                    options={dropdownData}
                    selectedOption={
                      values.choice_name ? values.choice_name : null
                    }
                    {...choicesName}
                    onChange={(value, name, obj) =>
                      dropDownHandleSelect(
                        {
                          choice_name: value ? value : null,
                        },
                        name,
                        obj
                      )
                    }
                  />
                </FlexGrid3Item>

                <FlexGrid3Item className="pe-1">
                  <div className={'d-flex'}>
                    <div className={'ms-0 ms-md-3 me-2'}>
                      <PrimaryButtonForm minWidth="6rem" onClick={handleSubmit}>
                        <span className={'me-2'}>{textStatus}</span>
                        {!loadingSave ? (
                          buttonIcon
                        ) : (
                          <span
                            className="spinner-border spinner-border-sm me-1"
                            role="status"
                          />
                        )}
                      </PrimaryButtonForm>
                    </div>
                  </div>
                </FlexGrid3Item>
              </FlexGrid3Container>
            )}
          </Header>
          <div className={'pe-3 pb-2'}>
            <Title>Saved Choices</Title>
            <div className="d-flex">
              <div style={{ width: '33%' }}>
                {listData1 && listData1.length > 0 && (
                  <>
                    <Table
                      headerColumns={headerColumnsEmp}
                      headerPadding={false}
                      noEditOption
                      fontSize="0.75rem"
                      callBackQuerySort={setQuerySort}>
                      <div>
                        {listData1 &&
                          listData1.map((list, index) => {
                            return (
                              <div key={index}>
                                <Row bgColor Shadow>
                                  <ColsGrouper className="d-flex justify-content-between">
                                    <Col
                                      max_width={EX_VERY_LARGE_COL}
                                      Capitalize
                                      className="sm-hidden"
                                      overlap={true}
                                      Center>
                                      {list && list.choice_key}
                                    </Col>
                                    <Col
                                      className="sm-hidden"
                                      Center
                                      Shrink
                                      NoFlexGrow>
                                      {choicesDeletePermission && (
                                        <IconWrapper
                                          onClick={() => {
                                            setModalDeleteValue(true);
                                            setDeleteId(list.id);
                                          }}>
                                          <RiDeleteBinLine />
                                        </IconWrapper>
                                      )}
                                    </Col>
                                  </ColsGrouper>
                                </Row>
                                <div className="mb-1" />
                              </div>
                            );
                          })}
                        {/* <div className="mt-3">
          <Pagination   totalPages={pages}  currentPage={currentPage}   values={values}   dropDownHandleSelect={dropDownHandleSelectPagination}     number={PAGE_SIZE_RENDER}    dataLength={absenceList.length}     />
          </div> */}
                      </div>
                    </Table>
                  </>
                )}
              </div>
              <div style={{ width: '33%', marginLeft: '20px' }}>
                {listData2 && listData2.length > 0 && (
                  <>
                    <Table
                      headerColumns={headerColumnsClient}
                      headerPadding={false}
                      noEditOption
                      fontSize="0.75rem"
                      callBackQuerySort={setQuerySort}>
                      <div>
                        {listData2 &&
                          listData2.map((list, index) => {
                            return (
                              <div key={index}>
                                <Row bgColor Shadow>
                                  <ColsGrouper className="d-flex justify-content-between">
                                    <Col
                                      max_width={EX_VERY_LARGE_COL}
                                      Capitalize
                                      className="sm-hidden"
                                      overlap={true}
                                      Center>
                                      {list && list.choice_key}
                                    </Col>
                                    <Col
                                      className="sm-hidden"
                                      Center
                                      Shrink
                                      NoFlexGrow>
                                      {choicesDeletePermission && (
                                        <IconWrapper
                                          onClick={() => {
                                            setModalDeleteValue(true);
                                            setDeleteId(list.id);
                                          }}>
                                          <RiDeleteBinLine />
                                        </IconWrapper>
                                      )}
                                    </Col>
                                  </ColsGrouper>
                                </Row>
                                <div className="mb-1" />
                              </div>
                            );
                          })}
                      </div>
                    </Table>
                  </>
                )}
              </div>
              <div style={{ width: '33%', marginLeft: '20px' }}>
                {listData3 && listData3.length > 0 && (
                  <>
                    <Table
                      headerColumns={headerColumnsClient}
                      headerPadding={false}
                      noEditOption
                      fontSize="0.75rem"
                      callBackQuerySort={setQuerySort}>
                      <div>
                        {listData3 &&
                          listData3.map((list, index) => {
                            return (
                              <div key={index}>
                                <Row bgColor Shadow>
                                  <ColsGrouper className="d-flex justify-content-between">
                                    <Col
                                      max_width={EX_VERY_LARGE_COL}
                                      Shrink
                                      reducePadding
                                      Capitalize
                                      className="sm-hidden"
                                      overlap={true}
                                      Center>
                                      {list && list.choice_key}
                                    </Col>
                                    <Col
                                      className="sm-hidden"
                                      Center
                                      Shrink
                                      NoFlexGrow>
                                      {choicesDeletePermission && (
                                        <IconWrapper
                                          onClick={() => {
                                            setModalDeleteValue(true);
                                            setDeleteId(list.id);
                                          }}>
                                          <RiDeleteBinLine />
                                        </IconWrapper>
                                      )}
                                    </Col>
                                  </ColsGrouper>
                                </Row>
                                <div className="mb-1" />
                              </div>
                            );
                          })}
                      </div>
                    </Table>
                  </>
                )}
              </div>
            </div>
          </div>
          <ModalDecision
            type="delete"
            title="Warning"
            body={
              'Are you sure you want to delete this ? \n DELETING the data will remove ALL records from database.'
            }
            onOk={deleteClick}
            onCancel={() => { }}
            okTitle={'DELETE'}
            {...bindModalDelete}
          />
        </div>
      )}
    </div>
  );
};

export default Choices;
