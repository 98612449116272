import React from 'react';
import {
    TitleSection,
    FlexEqual,
  } from '../styles';
  import InputLabelTop from 'components/SharedComponents/InputLabelTop';
  import { KeyPress } from 'utils/constants/regularExpressions';
  import EmpContact from '../../../../SharedComponents/FormSections/EmpContact';
const PersonalContact = ({
    useInput,
    values,
    choices,
    dropDownHandleSelect,
    readOnly,
    inviteUserStatus,
    
}) => {
    const contactSection = 'contact';
    return (
        <>
        <FlexEqual marginTopSm noMarginLeft className="">
                  <TitleSection>Contact</TitleSection>
                  <div className="d-flex flex-column mt-2">
                    <InputLabelTop
                      required
                      readOnly={readOnly}
                      type="text"
                      id="mobile_number"
                      label={'Mobile Number'}
                      onKeyPress={KeyPress.PHONE}
                      {...useInput(
                        'contact.mobile_number',
                        'isMobilePhone, isRequired'
                      )}
                      maxlength={25}
                    />
                    <InputLabelTop
                      type="text"
                      readOnly={readOnly}
                      id="telephone_home"
                      label={'Telephone (Home)'}
                      onKeyPress={KeyPress.PHONE}
                      {...useInput('contact.telephone_home', 'isMobilePhone')}
                      maxlength={25}
                    />
                    <InputLabelTop
                      type="text"
                      readOnly={readOnly}
                      id="telephone_work"
                      label={'Telephone (Work)'}
                      onKeyPress={KeyPress.PHONE}
                      {...useInput('contact.telephone_work', 'isMobilePhone')}
                      maxlength={25}
                    />
                    <div className="d-flex">
                      <div className="w-100 pe-1">
                        <InputLabelTop                       
                          required={inviteUserStatus?'required':''}
                          readOnly={readOnly}
                          type="email"
                          id="work_email"
                          label={'Work email'}
                          // id="personal_email"
                          // label={'Personal email'}
                          {...useInput('contact.email_personal', inviteUserStatus?'isEmail, isRequired' :'')}
                          // {...useInput('contact.email_personal','isEmail' )}
                      maxLength={254}
                        />
                      </div>
                      <div className="w-100 ps-1">
                        <InputLabelTop
                          type="email"
                          // readOnly={readOnly}
                          // id="work_email"
                          // label={'Work email'}
                          id="personal_email"
                          label={'Personal email'}
                          {...useInput('contact.email_work', 'isEmail')}
                          maxlength={254}
                        />
                      </div>

                    </div>
                
                    <EmpContact
                      useInput={useInput}
                      nameSection={contactSection}
                      sectionStyle={''}
                      dropDownHandleSelect={dropDownHandleSelect}
                      choices={choices}
                      values={values}
                    />


                  </div>
                </FlexEqual>
        </>
    );
}

export default PersonalContact;
