
import React, {useState, Fragment, useEffect} from 'react';
import Fade from 'react-reveal/Fade';
import {
  FormContent,
  ImageWrapper,
  LoginContent,
  LoginPageContent,
  SmallText,
  InputsContent,
  InputLogin,
  InputFieldLogin,
  UpperText,
  ColumnSwap,
} from 'components/AuthComponents/Login/styles';
import {ErrorMessageShake} from 'shared/styles/AuthComponents';
import {SuccessMessage} from 'shared/styles/AuthComponents';
import {PrimaryButton} from 'shared/styles/buttons';
import {useInput} from 'hooks/InputHook';
import {forgotPassword} from 'utils/api/SessionApi';
import {Link} from 'react-router-dom';
import logo from 'images/logoOutBgd.png';
import {AUTH} from 'utils/constants/routes';

const ForgotPassword = () => {
  const {value: email, bind: bindEmail} = useInput('');
  const [showErrorMessage, setShowErrorMessage] = useState({
    status: false,
    message: '',
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    status: false,
    message: '',
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    if (loadingButton) return;

    setShowErrorMessage({
      status: false,
      message: '',
    });
    setShowSuccessMessage({
      status: false,
      message:
        '',
    });
    setLoadingButton(true);

    forgotPassword(email)
      .then(response => {
        // TODO: error codes
        if (!response.message) {
          throw response;
        }
        setShowSuccessMessage({
          status: true,
          message:
            'If we find a user with that email we will sent you a recover link',
        });
        setLoadingButton(false);
      })
      .catch(e => {
        setLoadingButton(false);
        setShowErrorMessage({
          status: true,
          message: 'Unknown user email.',
        });
      });
  };

  useEffect(() => {
    setShowErrorMessage({status: false, message: ''});
  }, [email]);

  return (
    <>
    <LoginPageContent>
       
       <div
        className={
          'd-flex justify-content-md-end justify-content-center mt-4 me-lg-5 me-sm-5 me-0'
        }>
        {/* <div className={'pt-1 me-3'}>Don't have an account?</div>
        <div>
          <PrimaryButton type="button" outline={true}>
            <span className={'ms-2 me-2 font-weight-bold'}>Sign up</span>
          </PrimaryButton>
        </div> */}
        
      </div>

      <Fade>
        <Link to={AUTH}>
          <ImageWrapper>
            <img src={logo} alt="logo-img" />
          </ImageWrapper>
        </Link>
      </Fade>

      <FormContent style={{marginLeft:'5rem'}}>
        <InputsContent>
          <div className={'mb-3 mt-3 pt-1 '}>
            <UpperText>Recover password</UpperText>

            <SmallText>
              <div className={'text-black-50'}>
              Simply click below and we’ll help you log back into your account.
               </div>
            </SmallText>
          </div>

          <form onSubmit={handleSubmit} className={'mb-5'} noValidate>
            <div>
              <div className={'font-weight-bold mb-1 mt-2  pt-1 h5'}>Email</div>
              <div>
                <InputFieldLogin
                  type="text"
                  id="email"
                  placeholder="email@example.com"
                  autoComplete="email"
                  error={showErrorMessage.status}
                  {...bindEmail}
                />
              </div>
            </div>

            <div
              className={`${
                showErrorMessage.status ? 'mt-3' : 'mt-5'
              } d-flex justify-content-md-start justify-content-center`}>
              <PrimaryButton
                type="submit"
                shadow={true}
                disabled={loadingButton ? true : false}>
                {loadingButton ? (
                  <Fragment>
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                    />
                    <span>Loading...</span>
                  </Fragment>
                ) : (
                  <span className={'  m-5  font-weight-bold'}>RECOVER</span>
                )}
              </PrimaryButton>
            </div>

            <div>
              {showErrorMessage.status ? (
                <ErrorMessageShake className=" mt-3">
                  {showErrorMessage.message}
                </ErrorMessageShake>
              ) : (
                <Fragment />
              )}
              {showSuccessMessage.status ? (
                <SuccessMessage className=" mt-3">
                  {showSuccessMessage.message}
                </SuccessMessage>
              ) : (
                <Fragment />
              )}
            </div>

          </form>
        </InputsContent>
      </FormContent>
     
    </LoginPageContent>
 
    </>
  );
};

export default ForgotPassword;
