import React,{useState, useEffect} from 'react';
import './realtimeNotification.css';
import { useHistory } from 'react-router';
import { IoMdNotificationsOutline } from 'react-icons/io';
import{RiNotificationBadgeLine} from 'react-icons/ri';
import{AiOutlineCheck} from 'react-icons/ai';
import { FaTimes} from 'react-icons/fa';
import{BsBook} from 'react-icons/bs';
import {getRealTimeNotification ,readNotification, readAllNotification} from 'utils/api/SettingsApi';
import moment from 'moment/moment';
const RealTimeNotification = ({

}) => {

  const [messageCount, setMessageCount] = useState(0);
  const [notificationData, setNotificationData] = useState([]);
  const[toggleMenu,setToggleMenu] = useState(true);
  const userID = localStorage.getItem("user_id");
  const history = useHistory();

  const MINUTE_MS = 300000;

useEffect(() => {
  const interval = setInterval(() => {
    loadNotification();
  }, MINUTE_MS);

  return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
}, [userID])

  useEffect(() => {
    loadNotification();
    }, []);

const loadNotification=async()=>{
  const response =  await getRealTimeNotification(userID);
  setMessageCount(response?.count);
   setNotificationData(response?.results);
}

const handleRadio=async(index, is_notified, id)=>{
  if(!is_notified){
    const response = await readNotification([id], !is_notified);
    if(response.message ==  
      "User Notification is updated"){
        notificationData[index].is_notified=true;
        setNotificationData(notificationData);
        setMessageCount(messageCount-1);
      }
  }

}


const handleAllMessage=async(messageCount)=>{
  // if(messageCount >0){
  //   const response = await readAllNotification(userID);
  //   if(response.message ==
  //     "Data successfully updated"){
  //     let AllData= notificationData.map(data=>{
  //         return{ id:data.id, message:data.message, is_notified:true, created_at:data.created_at }
  //       })
  //       // setNotificationData(AllData);
  //       // setMessageCount(0);
  //       loadNotification();
  //     }
  // }
}




  return (
    <div>
      <div className="dropdown " >
    <button className="btn btn-secondary dropdown-toggle " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  class="icon-button">
    <IoMdNotificationsOutline  size="50px"
    color="blue" />
    {messageCount >0?(
    <span class="icon-button__badge">{messageCount}</span>
    ): null}
  </button>
  {/* {notificationData.length>0 ?( */}

  <div className="dropdown-menu " style={{border: 'none', top:'80%',left:'-200px', padding:'0px' , maxHeight:'90vh', overflowY: 'auto',  }} aria-labelledby="dropdownMenuButton">
  {notificationData?.length>0 ?(
  <div className="dropdown-header bg-primary text-white text-start" style={{width:'250px',whiteSpace: 'normal',fontSize: '12px' , }}><RiNotificationBadgeLine color="white"  />Notifications</div>
  ): null}

{/* {messageCount >0 ? ( 
  <div className="dropdown-header  text-left" style={{width:'250px',whiteSpace: 'normal',fontSize: '12px' , cursor:'pointer', borderBottom: "0.8px solid #6200f9" }} onClick={()=>{handleAllMessage(messageCount)}}
    ><BsBook color={messageCount >0? "blue" :""}  style={{paddingRight:'1px'}} />Mark all as read</div>
): null} */}

{notificationData?.length>0 ?(
  <div > 

    {notificationData.length>0 && notificationData?.map((data,index)=>{
      return(
        <div key={index}> 
         <div className="dropdown-item d-flex" style={{width:'250px',whiteSpace: 'normal', borderBottom: "0.8px solid #6200f9", fontSize: '12px' , padding:' 0.25rem 0.5rem', }}>
          <div className="pe-1"  style={{cursor:'pointer'}} 
        onClick={()=>{handleRadio(index, data.is_notified , data.id )}}
          > 
          {data.is_notified ?(
           <AiOutlineCheck  color={'green'}   />
          ): (
            <FaTimes color={'red'} />
          )
          }
         
          </div>

        <div className="ms-1   d-flex flex-column">
         <div className='text-secondary'>{data?.message}</div>
         <div className='text-secondary   d-flex flex-row justify-content-start'>
        <div className='text-secondary me-1 '><small>{data?.created_at?.split('T')[0]}</small></div>
        <div className='text-secondary m1-1 '><small>{moment(data?.created_at?.split('T')[1], 'HH:mm').format('HH:mm')}</small></div>
          </div>
          </div>
          </div>
        </div>
      )
    })}
    </div>

  ) : (
    
    <div className="dropdown-item d-flex text-center text-danger border border-primary" style={{width:'250px',whiteSpace: 'normal', fontSize: '12px' , padding:' 0.25rem 0.5rem', textAlign:'center'}} >
  <span  style={{textAlign:'center', margin:'0 auto'}}> no notifications ! </span> 
    </div>
  ) }

  <button className="dropdown-item  text-white text-center bg-primary " style={{width:'250px' ,whiteSpace: 'normal',fontSize: '12px' , cursor:'pointer',border: 'none' , height:'31.5px',  }}    onClick={() => {history.push('/dashboard-notification') }}  >
  Show all
  </button>

  </div>
 {/* ) : null } */}
</div>
    </div>
  )
}

export default RealTimeNotification