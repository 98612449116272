import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import {
  EX_LARGE_COL,
  LARGE_COL,
  EX_SMALL_COL4,
  SMALL_COL,
  MEDIUM_COL,
  EX_VERY_LARGE_COL,
  EX_VERY_SMALL_COL,
  EX_SMALL_COL,
} from 'shared/styles/constants/columns';
import Table from 'components/SharedComponents/Table';
import Pagination from 'components/SharedComponents/Pagination';
import {CLIENT_HANDBACK} from 'utils/constants/permissions';
import {useHistory, useLocation} from 'react-router-dom';
import {useForm} from 'hooks/FormHook';
import {useModal} from 'hooks/ModalHook';

import {EmploymentTypesContainer} from 'components/DashboardComponents/Navbar/styles';
import {
  WEB_PERMISSION,
  WEB_READ_PERMISSION,
  WEB_CREATE_PERMISSION,
  WEB_DELETE_PERMISSION,
  WEB_UPDATE_PERMISSION,
  SELF_WEB_READ_PERMISSION,
  SELF_WEB_CREATE_PERMISSION,
  SELF_WEB_UPDATE_PERMISSION,
  SELF_WEB_DELETE_PERMISSION,
} from 'utils/constants/permissions';
import {getHandBackList, patchApprovalHandBack} from 'utils/api/ClientApi';
import {PrimaryButtonForm} from 'shared/styles/buttons';

import {patchDeleteHandBack} from 'utils/api/ClientApi';
import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
import ModalDecision from 'components/SharedComponents/ModalDecision';

import {RiDeleteBinLine} from 'react-icons/ri';
import {PAGINATION_PARAM} from 'utils/constants/queryParams';
import queryString from 'query-string';
import {AppContext} from 'context';
import {
  Row,
  Col,
  ColsGrouper,
  IconWrapper,
  DayPill,
} from 'components/SharedComponents/Table/styles';
import {
  HeaderVisits,
  Title,
  SearchIcon,
  SearchAddContainer,
  SearchContainer,
  DropdownTypesContainer,
  FiltersLeftGroup,
  FiltersContainer,
  DataContainer,
} from 'shared/styles/constants/tagsStyles';
import NoSelectAllDropdownFilter from 'components/SharedComponents/NoSelectAllDropdownFilter';
import {client_hand_bag_choices} from 'utils/choiceConstant';

const headerColumns = [
  {
    label: 1,
    checkbox: true,
    name: 'checkbox',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_SMALL_COL4,
  },
  {
    label: 'EMPLOYEE NAME',
    name: 'visit_number',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'CLIENT FULL NAME',
    name: 'client_service_visit__client__full_name',
    status: 1,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },

  {
    label: 'START DATE',
    name: 'start_date',
    status: 0,
    className: '  sm-hidden ',
    max_width: EX_VERY_LARGE_COL,
  },

  {
    label: 'VISIT END DATE',
    name: 'end_date',
    status: 0,
    className: ' sm-hidden ',
    max_width: SMALL_COL,
  },
  {
    label: '',
    name: '',
    status: 0,
    className: ' sm-hidden ',
    max_width: EX_SMALL_COL,
  },
];

function HandBack(props) {
  const {values, setValues, useInput} = useForm({
    ordering: '-start_date',
  });
  const [cancelledvisits, setCancelledVisits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const mountedRef = useRef(true);

  const {
    contextChoices,
    contextIsAdmin,
    contextIsSuperAdmin,
    contextPermissions,
    contextIsStaff,
    contextSubscription,
    contextCategoryPermissions,
  } = useContext(AppContext);
  const {setValue: setModalDeleteValue, bind: bindModalDelete} =
    useModal(false);
  const {setValue: setModalWarningValue, bind: bindModalWarning} =
    useModal(false);

  const [employees, setEmployees] = useState([]);

  const [idToDelete, setIdToDelete] = useState([]);
  const [HandBackList, setHandBackList] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [events, setEvents] = useState([]);
  const [checkedId, setCheckedId] = useState([]);
  const [deleteId, setDeleteId] = useState([]);
  const [checkedIdArr, setCheckedIdArr] = useState([]);
  const [employeeFilter, setEmployeeFilter] = useState([]);
  const [branch, setBranch] = useState([]);
  const [branchFilter, setBranchFilter] = useState([]);
  const [update, setUpdatePermissions] = useState(false);
  const [create, setCreatePermissions] = useState(false);
  const [deletePermission, setDeletePermissions] = useState(false);
  const [readPermission, setReadPermissions] = useState(false);
  const filterAdmin = contextIsAdmin;
  const filterSuperAdmin = contextIsSuperAdmin;
  const {
    setValue: setAddEvent,
    value: addEvent,
    fullBind: fullBindAddEvent,
  } = useModal(false);
  const {
    setValue: setCancelledVisit,
    value: cancelledVisit,
    fullBind: fullBindCancelledVisit,
  } = useModal(false);
  const {handleGetChoices} = useContext(AppContext);

  useEffect(() => {
    handleGetChoices(client_hand_bag_choices);
  }, []);
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    let limitperpage = {};
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({...values});
  }, [PAGE_SIZE_RENDER]);
  useEffect(() => {
    if(values.branch||
      values.employees||
      values.ordering||
      props.location.search||
      values.limitperpage)
    search();
  }, [
    values.branch,
    values.employees,
    values.ordering,
    props.location.search,
    values.limitperpage,
  ]);
  useEffect(() => {
    setBranch(
      contextChoices?.branch &&
        contextChoices?.branch?.map(y => {
          return {id: y && y.id, name: y && y.name};
        })
    );

    setEmployees(
      () =>
        contextChoices?.branch &&
        contextChoices?.employee_listing?.map(item => {
          return {id: item.id, name: item.full_name};
        })
    );
  }, [contextChoices]);

  const setQuerySort = querySort => {
    setValues({...values, ...querySort});
  };
  useEffect(() => {
    let selfPermission = {};
    const self = contextPermissions;
    if (self.length > 0) {
      self.forEach(item => {
        selfPermission[item.section.name] = {};
        selfPermission[item.section.name][WEB_PERMISSION] = {};
        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_READ_PERMISSION
        ] = item?.[SELF_WEB_READ_PERMISSION];

        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_CREATE_PERMISSION
        ] = item?.[SELF_WEB_CREATE_PERMISSION];

        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_UPDATE_PERMISSION
        ] = item?.[SELF_WEB_UPDATE_PERMISSION];

        selfPermission[item.section.name][WEB_PERMISSION][
          SELF_WEB_DELETE_PERMISSION
        ] = item?.[SELF_WEB_DELETE_PERMISSION];
      });
    }
    let rolePermissions = {};

    const roles = contextPermissions;
    if (roles.length > 0) {
      roles.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
      });
      contextPermissions.forEach(item => {
        rolePermissions[item.section.name] = {};
        rolePermissions[item.section.name][WEB_PERMISSION] = {};
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_READ_PERMISSION
        ] = item?.[WEB_READ_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_CREATE_PERMISSION
        ] = item?.[WEB_CREATE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_DELETE_PERMISSION
        ] = item?.[WEB_DELETE_PERMISSION];
        rolePermissions[item.section.name][WEB_PERMISSION][
          WEB_UPDATE_PERMISSION
        ] = item?.[WEB_UPDATE_PERMISSION];
      });
    }
    if (filterSuperAdmin) {
      setReadPermissions(true);
      setUpdatePermissions(true);
      setDeletePermissions(true);
      setCreatePermissions(true);
    }
    if (filterAdmin) {
      setReadPermissions(
        contextSubscription?.[CLIENT_HANDBACK]?.[WEB_PERMISSION]
      );
      setUpdatePermissions(true);
      setDeletePermissions(true);
      setCreatePermissions(true);
    }

    if (rolePermissions[CLIENT_HANDBACK] !== undefined) {
      setCreatePermissions(
        (contextSubscription?.[CLIENT_HANDBACK]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_HANDBACK]?.[WEB_PERMISSION]?.[
            WEB_CREATE_PERMISSION
          ]) ||
          selfPermission[CLIENT_HANDBACK]?.[WEB_PERMISSION]?.[
            SELF_WEB_CREATE_PERMISSION
          ]
      );
      setDeletePermissions(
        (contextSubscription?.[CLIENT_HANDBACK]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_HANDBACK]?.[WEB_PERMISSION]?.[
            WEB_DELETE_PERMISSION
          ]) ||
          selfPermission[CLIENT_HANDBACK]?.[WEB_PERMISSION]?.[
            SELF_WEB_DELETE_PERMISSION
          ]
      );
      setUpdatePermissions(
        (contextSubscription?.[CLIENT_HANDBACK]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_HANDBACK]?.[WEB_PERMISSION]?.[
            WEB_UPDATE_PERMISSION
          ]) ||
          selfPermission[CLIENT_HANDBACK]?.[WEB_PERMISSION]?.[
            SELF_WEB_UPDATE_PERMISSION
          ]
      );

      setReadPermissions(
        (contextSubscription?.[CLIENT_HANDBACK]?.[WEB_PERMISSION] &&
          rolePermissions[CLIENT_HANDBACK]?.[WEB_PERMISSION]?.[
            WEB_READ_PERMISSION
          ]) ||
          selfPermission[CLIENT_HANDBACK]?.[WEB_PERMISSION]?.[
            SELF_WEB_READ_PERMISSION
          ]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contextIsAdmin,
    contextIsSuperAdmin,
    contextPermissions,
    contextCategoryPermissions,
    contextSubscription,
  ]);
  const getPageToSearchParam = searchQuery => {
    const urlParams = queryString.parse(searchQuery);
    let offset = urlParams[PAGINATION_PARAM]
      ? urlParams[PAGINATION_PARAM] - 1
      : 0;
    setCurrentPage(offset + 1);
    offset = offset * values.limitperpage;
    return {
      offset,
    };
  };
  const handleRowClick = type => {
    if (type == 'edit') {
      setCancelledVisit(!cancelledVisit);
    } else {
      setCancelledVisit(!cancelledVisit);
    }
  };
  const search = () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage
    );
    getHandBackList({...values, ...pageToSearch}).then(response => {
      if (response) {
        setHandBackList(response.data);
      }
      setPages(Math.ceil(response.count / values.limitperpage));
      if (!mountedRef.current) return null;
    });
  };

  const setFilterBranch = item => {
    setBranchFilter([...item]);
  };

  const setEmployeefilter = item => {
    setEmployeeFilter([...item]);
  };
  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection];
    setValues({...values});
  };
  const toDeleteVisit = async id => {
    setModalDeleteValue(true);
    setIdToDelete(id);
  };
  const toApproval = async id => {
    setModalWarningValue(true);
    setIdToDelete(checkedId);
  };
  const handleDeleteVisit = async () => {
    if (idToDelete || checkedId) {
      const is_deleted = true;
      await patchDeleteHandBack([idToDelete] || checkedId);
      setModalDeleteValue(false);
    }
    search();
  };
  const handleWarningVisit = async () => {
    if (checkedId) {
      const is_deleted = true;
      await patchApprovalHandBack(idToDelete, is_deleted);
      search();
      setModalWarningValue(false);
      setCheckedRows([]);
    }
  };
  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists = id => {
      return checkedRows.some(function (el) {
        return el.id === id;
      });
    };
    if (checkedRows && checkedRows.length > 0) {
      HandBackList.map(visit => {
        if (!visitExists(visit.id)) {
          checkAll = false;
        }
      });
    } else {
      checkAll = false;
    }
    return checkAll;
  };
  const handleAllSelectChange = valcheck => {
    let checked = [...checkedRows];
    if (valcheck.target.checked) {
      HandBackList.map(visit => {
        checked.push(visit);
      });
    } else {
      HandBackList.map(visit => {
        checked.map((item, index) => {
          if (item.id === visit.id) {
            checked.splice(index, 1);
          }
        });
      });
    }
    setCheckedRows(checked);
    setCheckedId(() => checked.map(item => item.id));
  };
  const handleCheck = visit => {
    let check = false;
    if (checkedRows) {
      checkedRows.map(item => {
        if (visit.id === item.id) {
          check = true;
        }
      });
    }
    return check;
  };
  const handleSelectChange = (valcheck, visit, selectedIndex) => {
    setCheckedIdArr(visit.id);
    let checked = [...checkedRows];
    if (valcheck.target.checked) {
      checked.push(visit);
    } else {
      checked.map((item, index) => {
        if (item.id === visit.id) {
          checked.splice(index, 1);
        }
      });
    }
    setCheckedRows(checked);
    setCheckedId(() => checked.map(item => item.id));

    //setClients(arr);
  };
  const dropDownHandleSelect = (item, namesection) => {
    setValues({...values, ...item});
  };
  return (
    <div>
      <Title>HandBack Request</Title>
      <HeaderVisits>
        <div className="d-flex align-items-center">
          <FiltersContainer>
            <EmploymentTypesContainer className="me-2 mb-1">
              <NoSelectAllDropdownFilter
                items={employees ? employees : []}
                setItemsSelected={items => {
                  dropDownHandleSelect({employees: items});
                }}
                itemsSelected={employeeFilter}
                setSelectedFilter={setEmployeefilter}
                placeHolder={'Employees'}
                isObject
                allOption
                // hitApiAfterClear={hitApiAfterClear}
              />
            </EmploymentTypesContainer>

            <EmploymentTypesContainer className="me-2 mb-1">
              <NoSelectAllDropdownFilter
                items={branch ? branch : []}
                setItemsSelected={items => {
                  dropDownHandleSelect({branch: items});
                }}
                itemsSelected={branchFilter}
                setSelectedFilter={setFilterBranch}
                placeHolder={'Branch'}
                isObject
                allOption
                // hitApiAfterClear={hitApiAfterClear}
              />
            </EmploymentTypesContainer>
          </FiltersContainer>
        </div>

        <SearchAddContainer
          className="d-flex align-items-center"
          style={{paddingLeft: '100px'}}>
          {checkedRows && checkedRows.length > 0 && (
            <div class="dropdown  me-2">
              {update && (
                <PrimaryButtonForm
                  minWidth="6rem"
                  class="btn dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  // aria-expanded="false"
                >
                  <span className={'me-2'}>Action</span>
                </PrimaryButtonForm>
              )}
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item"
                  onClick={() => {
                    toApproval();
                  }}>
                  Approval
                </a>
                <a
                  class="dropdown-item"
                  onClick={() => {
                    setModalDeleteValue(true);
                  }}>
                  Decline
                </a>
              </div>
            </div>
          )}
        </SearchAddContainer>
      </HeaderVisits>
      <Table
        headerColumns={headerColumns}
        headerPadding={false}
        fontSize="0.75rem"
        callBackQuerySort={setQuerySort}
        handleAllCheck={handleAllCheck}
        handleAllSelectChange={handleAllSelectChange}
        // callBackQuerySort={setQuerySort}
      >
        {HandBackList &&
          HandBackList.map((item, index) => {
            return (
              <div key={index}>
                <Row bgColor Shadow>
                  <ColsGrouper
                    className="d-flex"
                    // onClick={() => eventClickHandler(visitcancelled)}
                  >
                    <Col
                      max_width={EX_SMALL_COL4}
                      Shrink={false}
                      NoFlexGrow={true}
                      reducePadding
                      Capitalize
                      Center>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        checked={handleCheck(item)}
                        id="flexCheckDefault"
                        onChange={val => {
                          handleSelectChange(val, item, index);
                        }}
                      />
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      overlap={true}
                      Center>
                      {item.evv_visits__employee__full_name}
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      Capitalize
                      overlap={true}
                      Center>
                      <div style={{marginRight: '66px'}}>
                        {item.client_service_visit__client__full_name}
                      </div>
                    </Col>

                    <Col
                      max_width={SMALL_COL}
                      Shrink
                      reducePadding
                      className="sm-hidden"
                      Capitalize
                      overlap={true}
                      Center>
                      <div style={{marginRight: '20px'}}>
                        {
                          item.start_date.slice(0, 10) //visit start date
                        }
                      </div>
                    </Col>
                    <Col
                      max_width={LARGE_COL}
                      Shrink
                      reducePadding
                      className="sm-hidden"
                      Capitalize
                      overlap={true}
                      Center>
                      <div style={{marginRight: '60px'}}>
                        {
                          item.end_date.slice(0, 10) //visite start time
                        }
                      </div>
                    </Col>
                    <Col className="sm-hidden" Center Shrink NoFlexGrow>
                      <Col className="sm-hidden" Center Icon Purple>
                        {/* <IconWrapper
                          
                            //   onClick={() => { eventClickHandler(visitcancelled); handleRowClick('edit') }}


                            >
                              <MdEdit />
                            </IconWrapper> */}
                      </Col>
                      {deletePermission && (
                        <IconWrapper
                          onClick={() => {
                            toDeleteVisit(item.id);
                          }}>
                          <RiDeleteBinLine />
                        </IconWrapper>
                      )}
                    </Col>
                  </ColsGrouper>
                </Row>
                <div className="mb-1" />
              </div>
            );
          })}

        <div>
          <div className="mt-3">
            <Pagination
              setCurrentPage={setCurrentPage}
              totalPages={pages}
              currentPage={currentPage}
              values={values}
              dropDownHandleSelect={dropDownHandleSelectPagination}
              number={PAGE_SIZE_RENDER}
              dataLength={HandBackList ? HandBackList.length : 0}
            />
          </div>
        </div>
      </Table>
      <ModalDecision
        type="delete"
        title="Warning"
        body={`Are you sure you want to delete this Handback ? \n delete the Handback will remove all records from database.`}
        onOk={handleDeleteVisit}
        onCancel={() => {
          setIdToDelete('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
      <ModalDecision
        title="Warning"
        body={`Are you sure you want to Approval Handback ?`}
        onOk={handleWarningVisit}
        onCancel={() => {
          setIdToDelete('');
        }}
        okTitle={'Approve'}
        {...bindModalWarning}
      />
    </div>
  );
}
export default HandBack;
