import React, { useState, useEffect, Fragment, useRef } from 'react';
import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic';
import { useForm } from 'hooks/FormHook';
import InputTextArea from 'components/SharedComponents/InputTextArea';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import { CHECK, CROSS, ERROR_OUTLINE, FLOPPY } from 'utils/constants/icons';
import { useHistory, useLocation } from 'react-router';
import {
  setClientUploadFile,
  getUploadedFiles,
  deleteUploadedFile,
  getUploadedFilesDownload,
  setClientTask,
  getClientTaskTab,
  getPlannedGroupTask,
  deleteCareTasks,
  getPlannedDataById,
  changeOrderingPlannedDragDrop,
} from 'utils/api/ClientApi';
import { DOWNLOAD } from 'utils/constants/icons';
import {
  TitleSection,
  ErrorMessage,
  CloseButtonWrapper,
  WeekStyle,
} from 'shared/styles/constants/tagsStyles';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import {
  InvalidOrEmptyValues,
  ERROR,
  SAVE,
  SAVED,
} from 'utils/constants/messages';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import { pickFile } from 'utils/files';
import _ from 'lodash';
import Table from 'components/SharedComponents/Table';
import {
  EX_LARGE_COL,
  EX_SMALL_COL,
  EX_SMALL_COL4,
  EX_VERY_LARGE_COL,
  LARGE_COL,
  MEDIUM_COL,
  SMALL_COL,
} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  IconWrapper,
  DayPill,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';
import { RiDeleteBinLine } from 'react-icons/ri';
import moment from 'moment';
import { useModal } from 'hooks/ModalHook';
import ModalDecision from 'components/SharedComponents/ModalDecision';
import { NoPermissionContainer } from 'components/DashboardComponents/Team/AddModal/styles';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import { PURPLE, RED } from 'shared/styles/constants/colors';
import { FaTimes } from 'react-icons/fa';
import { Toast } from 'primereact/toast';
import AddNewCare from '../AddModal/AddNewCare';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DateSelector from 'components/SharedComponents/DateSelector';
import { CustomButtonWeek } from 'components/SharedComponents/FormSections/Service/Styles';
import PlannedConfirmationModal from './PlannedConfirmationModal';
import { TextAreaInput } from 'shared/styles/inputs';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
//import getUseInputData from './getUseInputData'
import './taskStyle.css'
import { useMemo } from 'react';
import { useDebounce } from 'shared/methods';
import { useCallback } from 'react';
import RichTextEditor from 'components/SharedComponents/RichTextEditor';
export const headerColumns = [
  {
    label: '',
    name: 'expand',
    status: 0,
    className: 'm-0 p-0 md-hidden',
    max_width: EX_SMALL_COL,
  },
  {
    label: 'Swap',
    name: 'file_name',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_SMALL_COL4,
  },
  {
    label: 'Essential',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Care Category',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: MEDIUM_COL,
  },

  {
    label: 'Task Type',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Care Task',
    name: 'description',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  {
    label: 'Result',
    name: 'uploaded',
    status: 0,
    className: 'grow-mobile',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Support Level',
    name: 'description',
    status: 0,
    className: 'grow-mobile',
    max_width: LARGE_COL,
  },
  // {
  //   label: 'Days Of Week',
  //   name: 'description',
  //   status: 0,
  //   className: 'grow-mobile',
  //   max_width: LARGE_COL,
  // },

  // {
  //   label: 'Message',
  //   name: 'uploaded',
  //   status: 0,
  //   className: 'grow-mobile',
  //   max_width: EX_VERY_LARGE_COL,
  // },
];


const ClientTask = ({
  cliId,
  selectedClientId,
  choices,
  selectedData,
  handleModal,
  handleChangeChoices,
  setToastData,
}) => {
  const [modalLoading, setModalLoading] = useState(true);
  const [buttonIcon, setButtonIcon] = useState(FLOPPY);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const [showError, setShowError] = useState(false);
  const [UploadTeamFile, setUploadTeamFile] = useState('No File Chosen');
  const [toggleUploadFile, setToggleUploadFile] = useState(false);
  const [clientTaskData, setClientTaskData] = useState([]);
  const [idSelected, setIdSelected] = useState('');
  const [idSelectedGroup, setIdSelectedGroup] = useState('');
  const [selectedIndex, setSelectedIndex] = useState('');
  const [idSelectedCare, setIdSelectedCare] = useState('');
  const [checkRequired, setCheckRequired] = useState(false);
  const [expandFields, setExpandFields] = useState({});

  const [perffredId, setPerffredId] = useState('');
  const {
    values,
    setValues,
    useInput,
    isValid,
    errors,
    setFormErrors,
    setCheckRequires,
  } = useForm({});
  const {
    setValue: setModalValue,
    value: modalValue,
    setTitle,
    fullBind: fullBindBulkAlert,
  } = useModal(false);
  const { setValue: setModalDeleteValue, bind: bindModalDelete } =
    useModal(false);
  const details = useInput('support_level');
  const {
    setValue: setAddNewCareTask,
    value: AddNewCareTask,
    fullBind: fullBindCareTask,
  } = useModal(false);
  const history = useHistory();
  const location = useLocation();
  const toast = useRef();

  const days_by_default = [
    { day: 'MON', dayValue: 1, selected: false },
    { day: 'TUE', dayValue: 2, selected: false },
    { day: 'WED', dayValue: 3, selected: false },
    { day: 'THU', dayValue: 4, selected: false },
    { day: 'FRI', dayValue: 5, selected: false },
    { day: 'SAT', dayValue: 6, selected: false },
    { day: 'SUN', dayValue: 7, selected: false },
  ];

  const hook_effective = useInput(`effective_date`);

  useEffect(() => {
    if (selectedData) {
      loadDataOnSelected();
    }
    setModalLoading(false);
    //   }
  }, []);
  useEffect(() => {
    if (values.group_name) {
      setErrorMessage('');
    }
  }, [values.group_name]);
  const loadDataOnSelected = async () => {
    setModalLoading(true)
    const response = await getPlannedDataById(selectedData);
    if (response.data) {
      const tempData = response.data.map(item => {
        let temp_days = [];
        let arr_days = item.day_repeat_on && item.day_repeat_on.split(',');
        if (item.day_repeat_on) {
          days_by_default.forEach(val => {
            if (arr_days.includes(val.day)) {
              temp_days.push({ ...val, selected: true });
            } else {
              temp_days.push({ ...val, selected: false });
            }
          });
        } else {
          temp_days = days_by_default;
        }
        return {
          id: item.id,
          care_task: item.care_task.id,
          ordered: item.ordered,
          message: item.message,
          care_task_name: item.care_task.name,
          care_task_id: item.care_task.id,
          result_type: item.care_task.result_type,
          result_option: item.care_task.result_option,
          task_type_name:
            item.care_task &&
            item.care_task.task_type &&
            item.care_task.task_type.name,
          care_category_name:
            item.care_task &&
            item.care_task.task_type &&
            item.care_task.task_type.care_category.name,
          group_task_id: item.group_task,
          support_level: item.support_level,
          specific_group: item.specific_group,
          support_level: item.support_level,
          days_of_week: temp_days,
          is_essential: item.is_essential,
        };
      });
      let newSupportLevel = response.data.find(item => {
        if (item.support_level) {
          return item;
        }
      });
      values.group_name = response.specific_group_name;
      setValues({
        ...values,
        ...{ support_level: newSupportLevel?.support_level },
      });

      let setTaskData = [...clientTaskData, ...tempData]

      const initialExpansionState = {};
      setTaskData && setTaskData.forEach((client, cIndex) => {
        initialExpansionState[cIndex] = { open: true };
      });
      setExpandFields({ ...initialExpansionState });

      setClientTaskData([...clientTaskData, ...tempData]);
      setModalLoading(false)
    }
    // setModalLoading(false)
  };

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const getValueName = (res, type) => {
    let value = [];
    if (type) {
      res.forEach(resData => {
        choices.care_task.forEach(careData => {
          if (careData.id === resData.care_task) {
            value.push({ value: careData.id, label: careData.name });
          }
        });
      });
    } else {
      res.forEach(resData => {
        choices.group_task.forEach(careData => {
          if (careData.id === resData.group_task) {
            value.push({ value: careData.id, label: careData.name });
          }
        });
      });
    }
    return value;
  };

  const loadTaskData = async id => {
    setModalLoading(true)
    try {
      const response = await getPlannedGroupTask(id);
      if (response) {
        let arrLength = clientTaskData.length;
        let tempData = response.data.map((item, index) => {
          return {
            care_task: item.care_task.id,
            ordered: arrLength + index + 1,
            message: '',
            care_task_name: item.care_task.name,
            care_task_id: item.care_task.id,
            result_type: item.care_task.result_type,
            result_option: item.care_task.result_option,
            task_type_name:
              item.care_task &&
              item.care_task.task_type &&
              item.care_task.task_type.name,
            care_category_name:
              item.care_task &&
              item.care_task.task_type &&
              item.care_task.task_type.care_category.name,
            group_task_id: item.group_task,
            support_level: item.support_level,
            is_essential: item.is_essential,
            days_of_week: [
              { day: 'MON', dayValue: 1, selected: true },
              { day: 'TUE', dayValue: 2, selected: true },
              { day: 'WED', dayValue: 3, selected: true },
              { day: 'THU', dayValue: 4, selected: true },
              { day: 'FRI', dayValue: 5, selected: true },
              { day: 'SAT', dayValue: 6, selected: true },
              { day: 'SUN', dayValue: 7, selected: true },
            ],
          };
        });
        values.group_id = null;
        values.task_id = null;
        let setTaskData = [...clientTaskData, ...tempData]

        const initialExpansionState = {};
        setTaskData && setTaskData.forEach((client, cIndex) => {
          initialExpansionState[cIndex] = { open: true };
        });
        setExpandFields({ ...initialExpansionState });
        setClientTaskData([...clientTaskData, ...tempData]);
        setValues({ ...values });
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Care task added at the bottom',
        });
      }
      setModalLoading(false);
    } catch {
      setModalLoading(false);
    }
  };

  const loadDataOnCareTask = id => {
    let careTaskArr = [];
    let arrLength = clientTaskData.length;
    choices.care_category.forEach(item => {
      item.task_type &&
        item.task_type.forEach(task_item => {
          task_item &&
            task_item.care_task.forEach(care_item => {
              if (id === care_item.id) {
                careTaskArr.push({
                  care_task: care_item.id,
                  message: '',
                  care_task_name: care_item.name,
                  task_type_name: task_item.name,
                  care_category_name: item.name,
                  support_level: item.support_level,
                  result_type: care_item.result_type,
                  result_option: care_item.result_option,
                });
              }
            });
        });
    });
    let careData = careTaskArr.map((val, index) => {
      return {
        care_task: val.care_task,
        message: '',
        ordered: arrLength + index + 1,
        care_task_name: val.care_task_name,
        task_type_name: val.task_type_name,
        care_category_name: val.care_category_name,
        result_type: val.result_type,
        result_option: val.result_option,
        support_level: val.support_level,
        days_of_week: [
          { day: 'MON', dayValue: 1, selected: true },
          { day: 'TUE', dayValue: 2, selected: true },
          { day: 'WED', dayValue: 3, selected: true },
          { day: 'THU', dayValue: 4, selected: true },
          { day: 'FRI', dayValue: 5, selected: true },
          { day: 'SAT', dayValue: 6, selected: true },
          { day: 'SUN', dayValue: 7, selected: true },
        ],
      };
    });
    values.group_id = null;
    values.task_id = null;
    let setTaskData = [...clientTaskData, ...careData]

    const initialExpansionState = {};
    setTaskData && setTaskData.forEach((client, cIndex) => {
      initialExpansionState[cIndex] = { open: true };
    });
    setExpandFields({ ...initialExpansionState });
    setClientTaskData([...clientTaskData, ...careData]);
    setValues({ ...values });
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Care task added at the bottom',
    });
  };

  const handleConfirmModal = evt => {
    evt.preventDefault();

    if (values && values.group_name) {
      setCheckRequires(false);
      setShowError(false);
      setModalValue(true);
    } else {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(`Please Select group name.`);
      return;
    }

    let require = false;
    let check_require_arr = clientTaskData.map((val, index) => {
      let require_child = true;
      val.days_of_week &&
        val.days_of_week.forEach(day => {
          if (day.selected) {
            require_child = false;
          }
        });
      if (require_child) {
        require = true;
      }
      return { ...val, require: require_child };
    });

    if (require) {

      const initialExpansionState = {};
      check_require_arr && check_require_arr.forEach((client, cIndex) => {
        initialExpansionState[cIndex] = { open: true };
      });
      setExpandFields({ ...initialExpansionState });
      setClientTaskData(check_require_arr);
      return;
    }
    if (!loadingSave) {
      setLoadingSave(true);
    }
  };
  const handleSubmit = evt => {
    evt.preventDefault();

    if (values && values.group_name) {
      setCheckRequires(false);
      setShowError(false);
    } else {
      setCheckRequires(true);
      setShowError(true);
      setErrorMessage(`Please Select group name.`);
      return;
    }
    // let client_preferred_task = [];
    // let client_preferred_group_task = [];

    // values.task_id.forEach((val) => {
    //     client_preferred_task.push({ value: val.value })
    // })

    // values.group_id.forEach((val) => {
    //     client_preferred_group_task.push({ value: val.value })
    // })

    // let body = {
    //     client_preferred_task: client_preferred_task,
    //     client_preferred_group_task: client_preferred_group_task
    // };
    let require = false;
    let check_require_arr = clientTaskData.map((val, index) => {
      let require_child = true;
      val.days_of_week &&
        val.days_of_week.forEach(day => {
          if (day.selected) {
            require_child = false;
          }
        });
      if (require_child) {
        require = true;
      }
      return { ...val, require: require_child };
    });

    if (require) {
      const initialExpansionState = {};
      check_require_arr && check_require_arr.forEach((client, cIndex) => {
        initialExpansionState[cIndex] = { open: true };
      });
      setExpandFields({ ...initialExpansionState });
      setClientTaskData(check_require_arr);
      return;
    }
    if (!loadingSave) {
      setLoadingSave(true);
    }

    let clientTaskArr = clientTaskData.map((val, index) => {
      let days_week = [];
      val.days_of_week &&
        val.days_of_week.forEach(day => {
          if (day.selected) {
            days_week.push(day.day);
          }
        });
      return val.id
        ? {
          id: val.id,
          care_task: val.care_task,
          message: val.message,
          ordered: index + 1,
          support_level: val.support_level,
          day_repeat_on: days_week.join(','),
          is_essential: val.is_essential,
        }
        : {
          care_task: val.care_task,
          message: val.message,
          ordered: index + 1,
          support_level: val.support_level,
          day_repeat_on: days_week.join(','),
          is_essential: val.is_essential,
        };
    });

    let Id = location.pathname.includes('/client/add')
      ? cliId
      : selectedClientId;
    let body = {
      client: Id,
      group_name: values.group_name,
      effective_date: values.effective_date,
      client_task: clientTaskArr,
    };
    if (selectedData) {
      body.id = selectedData;
    }
    setClientTask(body)
      .then(response => {
        setToastData(response);
        if (response.status === 'True') {
          setLoadingSave(false);

          handleModal();
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `${response.message}`,
          });
          setValues({});
        } else {
          setLoadingSave(false);

          toast.current.show({
            severity: 'error',
            summary: 'Failed',
            detail: `${response.message}`,
          });
        }
      })
      .catch(error => {
        setLoadingSave(false);
      });
  };

  const preferredHandleSelect = (preferred, data) => {
    setPerffredId(preferred.group_id);

    if (preferred.group_id) {
      loadTaskData(preferred.group_id);
      values.group_id = preferred.group_id;
      setValues({ ...values });
    }
    if (preferred.task_id) {
      // if(data.className === "Select-create-option-placeholder"){
      //     setAddNewCareTask(true)
      // }else{
      if (preferred.task_id === 'new') {
        setAddNewCareTask(true);
      } else {
        loadDataOnCareTask(preferred.task_id);
        values.task_id = preferred.task_id;
        setValues({ ...values });
      }

      //}
    }
  };
  const handleDropDownSelect = (item, nameItem, index) => {
    clientTaskData[index][nameItem] = item[nameItem];
    setValues({ ...values });
  };



  const handleGroupName = val => {
    val.preventDefault();
    values.group_name = val.target.value;
    setValues({ ...values });
  };

  const handleSwap = (item, index, arrow) => {
    let array = [...clientTaskData];
    if (arrow === 'up') {
      if (index) {
        let temp = array[index];
        array[index] = array[index - 1];
        array[index - 1] = temp;
      }
    } else {
      let temp = array[index];
      array[index] = array[index + 1];
      array[index + 1] = temp;
    }
    const initialExpansionState = {};
    array && array.forEach((client, cIndex) => {
      initialExpansionState[cIndex] = { open: true };
    });
    setExpandFields({ ...initialExpansionState });
    setClientTaskData(array);
  };

  const toDeleteGroupTasks = (group_id, care_id, index) => {
    setModalDeleteValue(true);
    setIdSelectedGroup(group_id);
    setIdSelectedCare(care_id);
    setSelectedIndex(index);
    //setIdSelected(id);
  };

  const handleDeleteContract = async () => {
    try {
      await deleteCareTasks(idSelectedGroup, idSelectedCare, selectedClientId);
      const deleteTemp = [];
      clientTaskData.forEach((item, index) => {
        if (!(index === selectedIndex)) {
          deleteTemp.push({ ...item });
        }
      });
      const initialExpansionState = {};
      deleteTemp && deleteTemp.forEach((client, cIndex) => {
        initialExpansionState[cIndex] = { open: true };
      });
      setExpandFields({ ...initialExpansionState });
      setClientTaskData(deleteTemp);
      //Todo: confirm deleted client message
    } catch (error) {
      //Todo: error message and add the methods of finally in to ok modal function
    } finally {
      setModalDeleteValue(false);
      setIdSelected('');
    }
  };

  const deleteUnSavedRow = selIndex => {
    const deleteTemp = [];
    clientTaskData.forEach((item, index) => {
      if (!(index === selIndex)) {
        deleteTemp.push({ ...item });
      }
    });
    const initialExpansionState = {};
    deleteTemp && deleteTemp.forEach((client, cIndex) => {
      initialExpansionState[cIndex] = { open: true };
    });
    setExpandFields({ ...initialExpansionState });
    setClientTaskData(deleteTemp);
  };

  const getOptions = value => {
    let arr = value.split(',');
    let finalArray = arr.map(item => {
      return {
        id: item,
        name: item,
      };
    });
    return finalArray;
  };

  const onDragComplete = result => {
    if (!result.destination) return;
    handleSwapDrop(result.source.index, result.destination.index);
  };

  const handleSwapDrop = (src, des) => {
    let element = clientTaskData[src];
    clientTaskData.splice(src, 1);
    clientTaskData.splice(des, 0, element);
    const initialExpansionState = {};
    clientTaskData && clientTaskData.forEach((client, cIndex) => {
      initialExpansionState[cIndex] = { open: true };
    });
    setExpandFields({ ...initialExpansionState });
    setClientTaskData([...clientTaskData]);
  };

  const selectedDay = (item, index, index_day) => {
    let tem_all = [];
    clientTaskData.forEach((item, i) => {
      if (index == i) {
        item.days_of_week[index_day].selected =
          !item.days_of_week[index_day].selected;
      }
      tem_all.push({ ...item });
    });
    const initialExpansionState = {};
    tem_all && tem_all.forEach((client, cIndex) => {
      initialExpansionState[cIndex] = { open: true };
    });
    setExpandFields({ ...initialExpansionState });
    setClientTaskData(tem_all || []);
  };
  const hideAllService = () => {
    setModalValue(false);
  };
  const newfresh = () => {
    window.location.reload(true);
  };
  const handleSelectChange = (inx, e) => {
    handleChangeChoices();
    let newTem = [];

    clientTaskData.forEach((item, index) => {
      if (index === inx) {
        item.is_essential = !item.is_essential;
      }
      newTem.push({ ...item });
    });
    const initialExpansionState = {};
    newTem && newTem.forEach((client, cIndex) => {
      initialExpansionState[cIndex] = { open: true };
    });
    setExpandFields({ ...initialExpansionState });
    setClientTaskData([...newTem]);
  };

  const handleExpansion = (clientIndex) => {
    if (expandFields[clientIndex].open) {
      expandFields[clientIndex].open = false;
      setExpandFields({ ...expandFields });
    } else {
      expandFields[clientIndex].open = true;
      setExpandFields({ ...expandFields });
    }
  };

  const handleMessage = useCallback((val, item, index) => {
    clientTaskData[index]['message'] = val;
    setClientTaskData([...clientTaskData])
    // clientTaskData[index]['message'] = val.target.value;
    // setValues({ ...values });
  }, [clientTaskData])

  return (
    <div>
      <ComponentDynamic loading={modalLoading}>
        {/* <TitleSection>Client Task</TitleSection> */}
        <Toast ref={toast} position="top-right" />
        <div className="d-flex align-items-center py-3">
          <div className="w-100 me-2" style={{ marginTop: '5px' }}>
            <InputLabelTop
              required
              type="text"
              id="group_name"
              label={'Group Name'}
              readOnly={false}
              {...useInput('group_name', 'isRequired')}
              // onChange={(val) => handleGroupName(val)}
              maxlength={100}
            />
          </div>
          <div className="w-100 me-2" style={{ zIndex: 101 }}>
            <DropdownSearchable
              //  width={'33%'}
              marginLeft={'0.5%'}
              placeHolder={'Group Tasks'}
              options={choices.group_task}
              // isMulti={true}
              selectedOption={values.group_id ? values.group_id : null}
              onChange={value =>
                preferredHandleSelect({ group_id: value.length ? value : [] })
              }
            />
          </div>
          <div className="w-100 me-2" style={{ zIndex: 101 }}>
            <DropdownSearchable
              //  width={'33%'}
              //isCreate={true} // to add items not present in the options(dropdown items)
              marginLeft={'0.5%'}
              placeHolder={'Care Tasks'}
              options={
                choices.care_task
                  ? [{ id: 'new', name: '+ New Care Task' }, ...choices.care_task]
                  : []
              }
              // isMulti={true}
              // selectedOption={
              //     values.task_id ? values.task_id : null
              // }
              onChange={(value, name, data) =>
                preferredHandleSelect(
                  { task_id: value.length ? value : [] },
                  data
                )
              }
            />
          </div>
          {selectedData && (
            <div className="w-100 me-2" style={{ zIndex: 101 }}>
              <DateSelector
                disabled={false}
                id="rota_start_date"
                labelTop={'Effective date'}
                {...hook_effective}
              />
            </div>
          )}
        </div>

        <div
          className={
            'd-sm-block d-lg-flex text-center text-md-right justify-content-end mt-2'
          }>
          <div className={'mb-3'}>
            {showError ? (
              <ErrorMessage>
                <label className={'mt-2'}>{errorMessage}</label>
              </ErrorMessage>
            ) : (
              <Fragment />
            )}
          </div>
        </div>
        <>
          <div
            className="mt-3"
            style={{
              paddingBottom: '200px',
              overflow: 'hidden',
            }}>
            {clientTaskData && clientTaskData.length > 0 && (
              <Table
                headerColumns={headerColumns}
                headerPadding={false}
                noEditOption={true}
                fontSize="0.75rem"
                callBackQuerySort={setQuerySort}>
                <DragDropContext onDragEnd={onDragComplete}>
                  <Droppable droppableId="drag-drop-list">
                    {(provided, snapshot) => (
                      <div
                        className="drag-drop-list-container"
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        {clientTaskData &&
                          clientTaskData.map((shift, index) => {
                            return (
                              <Draggable
                                key={`${shift.care_task}${index}`}
                                draggableId={`${shift.care_task}${index}`}
                                index={index}>
                                {provided => (
                                  <div
                                    className="item-card"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}>
                                    <div key={index}>
                                      <Row
                                        bgColor
                                        Shadow
                                        zIndex={clientTaskData.length - index}>
                                        <ColsGrouper className="d-flex">
                                          <Col
                                            max_width={EX_SMALL_COL}
                                            className="d-flex justify-content-center align-content-center">
                                            <CloseButtonWrapper
                                              className="closeButtonWrapper"
                                              onClick={() => {
                                                handleExpansion(index);
                                              }}>
                                              {expandFields && expandFields[index] && expandFields[index]?.open ? (
                                                <IoIosArrowDown
                                                  style={{ color: '#6200f9', fontWeight: 'bold' }}
                                                />
                                              ) : (
                                                <IoIosArrowForward
                                                  style={{ color: '#6200f9', fontWeight: 'bold' }}
                                                />
                                              )}
                                            </CloseButtonWrapper>
                                          </Col>
                                          <Col
                                            max_width={EX_SMALL_COL4}
                                            Shrink={false}
                                            NoFlexGrow={true}
                                            reducePadding
                                            Capitalize
                                            Center>
                                            <div className="d-flex flex-column">
                                              <FiChevronUp
                                                className="align-self-center"
                                                size={18}
                                                color={PURPLE}
                                                onClick={() =>
                                                  handleSwap(shift, index, 'up')
                                                }
                                              />
                                              <FiChevronDown
                                                className="align-self-center"
                                                size={18}
                                                color={PURPLE}
                                                onClick={() =>
                                                  handleSwap(
                                                    shift,
                                                    index,
                                                    'down'
                                                  )
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col
                                            max_width={MEDIUM_COL}
                                            Shrink
                                            reducePadding
                                            Capitalize
                                            Center>
                                            <input
                                              style={{
                                                width: '20px',
                                                height: '20px',
                                              }}
                                              type="checkbox"
                                              checked={shift.is_essential}
                                              onChange={() => {
                                                handleSelectChange(index);
                                              }}
                                            />
                                          </Col>
                                          <Col
                                            max_width={MEDIUM_COL}
                                            Shrink
                                            reducePadding
                                            Capitalize
                                            Center>
                                            {shift.care_category_name}
                                          </Col>

                                          <Col
                                            max_width={LARGE_COL}
                                            Shrink
                                            reducePadding
                                            Capitalize
                                            Center>
                                            {shift.task_type_name}
                                          </Col>
                                          <Col
                                            max_width={LARGE_COL}
                                            Shrink
                                            reducePadding
                                            Capitalize
                                            Center>
                                            {shift.care_task_name}
                                          </Col>

                                          <Col
                                            max_width={MEDIUM_COL}
                                          // Shrink
                                          // reducePadding
                                          // Capitalize
                                          >
                                            {shift.result_type === 'option' ? (
                                              <DropdownSearchable
                                                width={'111%'}
                                                // margin={'3px'}
                                                placeHolder="result"
                                                // value={
                                                //   values[TASK_VALUE][index]
                                                //     .newdata_care_task
                                                // }
                                                options={
                                                  shift.result_option
                                                    ? getOptions(
                                                      shift.result_option
                                                    )
                                                    : []
                                                }
                                                selectedOption={null}
                                                // onChange={value =>
                                                //   handleDropDown(
                                                //     {
                                                //       result_option: value ? value : null,
                                                //     },
                                                //     'result_option',
                                                //     [index],
                                                //     value
                                                //   )
                                                // }
                                                style={{ max_width: '500px' }}
                                              />
                                            ) : shift.result_type === 'text' ? (
                                              <InputLabelTop
                                                margin={'5px'}
                                                readOnly={
                                                  shift.result_type === 'text'
                                                    ? false
                                                    : true
                                                }
                                                style={{ margin: '2px' }}
                                                type="text"
                                                value={() => { }}
                                                id={`result${index}`}
                                                // onChange={val =>
                                                //   handleResult(val, shift, index)
                                                // }
                                                maxlength={100}
                                              // key={contextChoices}
                                              />
                                            ) : (
                                              ''
                                            )}
                                          </Col>

                                          <Col
                                            max_width={LARGE_COL}
                                            // Shrink

                                            // reducePadding
                                            // Capitalize

                                            Center
                                            style={{ width: '112%' }}>
                                            <div
                                              className="w-100 ms-2 me-2"
                                              // style={{ height: '50px' }}
                                              >
                                              <DropdownSearchable
                                                marginLeft={'0.5%'}
                                                value={shift.support_level}
                                                options={choices.support_level}
                                                selectedOption={
                                                  shift.support_level
                                                }
                                                onChange={value =>
                                                  handleDropDownSelect(
                                                    {
                                                      support_level: value
                                                        ? value
                                                        : null,
                                                    },
                                                    'support_level',
                                                    index
                                                  )
                                                }
                                              />
                                            </div>
                                          </Col>

                                          {/* <Col
                                            max_width={LARGE_COL}
                                            Shrink
                                            reducePadding
                                            Capitalize
                                            Center>
                                            <div>
                                              <WeekStyle required style={{ flexWrap: 'wrap' }}>
                                                {shift.days_of_week &&
                                                  shift.days_of_week.map(
                                                    (item, indexI) => {
                                                      return (
                                                        <CustomButtonWeek
                                                          style={{
                                                            maxWidth: '35px',
                                                            marginBottom: "2px"
                                                          }}
                                                          isRequired
                                                          add_background={
                                                            item.selected
                                                          }
                                                          onClick={() => {
                                                            selectedDay(
                                                              item,
                                                              index,
                                                              indexI
                                                            );
                                                          }}>
                                                          <label>
                                                            {item.day}
                                                          </label>
                                                        </CustomButtonWeek>
                                                      );
                                                    }
                                                  )}
                                              </WeekStyle>
                                              <span style={{ color: RED }}>
                                                {shift.require
                                                  ? 'is Required'
                                                  : ''}
                                              </span>
                                            </div>
                                          </Col> */}


                                          <Col
                                            className="sm-hidden ps-2"
                                            Center
                                            Shrink
                                            NoFlexGrow>
                                            {/* {shift.specific_group ? */}
                                            <IconWrapper
                                              onClick={() => {
                                                shift.specific_group
                                                  ? toDeleteGroupTasks(
                                                    shift.specific_group,
                                                    shift.care_task_id,
                                                    index
                                                  )
                                                  : deleteUnSavedRow(index);
                                              }}>
                                              <RiDeleteBinLine />
                                            </IconWrapper>
                                            {/* :
                                                        <CloseButtonWrapper>
                                                            <FaTimes
                                                                onClick={() => {
                                                                    deleteUnSavedRow(index);
                                                                }}
                                                            />
                                                        </CloseButtonWrapper>
                                                        } */}
                                          </Col>
                                        </ColsGrouper>
                                      </Row>
                                      <div className="mb-1" />
                                      {expandFields && expandFields[index] && expandFields[index]?.open &&
                                        <div className='wrapper-class'>
                                          <RichTextEditor
                                            value={shift.message}
                                            tabIndex={index} // tabIndex of textarea
                                            onBlur={newContent => handleMessage(newContent, shift, index)}
                                          />
                                        </div>
                                      }
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            )}
          </div>
          <div
            className={
              'd-flex justify-content-center justify-content-md-end position-fixed py-1 px-2'
            }
            style={{
              width: 'calc(100% - 263px)',
              backgroundColor: 'white',
              right: 0,
              bottom: 0,
              zIndex: 9999,
            }}>
            <div className={'ms-0 me-2'}>
              <PrimaryButtonForm
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                className={'me-2'}
                onClick={() => {
                  history.push('/client');
                }}>
                <span className={'me-2'}>{'Cancel'}</span>
              </PrimaryButtonForm>
              <PrimaryButtonForm
                bgcolor="PURPLE_GRAY_500"
                minWidth="6rem"
                onClick={handleConfirmModal}>
                Save
              </PrimaryButtonForm>
            </div>
            {AddNewCareTask && (
              <AddNewCare
                loadTaskData={loadTaskData}
                fullBind={fullBindCareTask}
                newfresh={newfresh}
                perffredId={perffredId}
                setModalLoading={setModalLoading}
                setAddNewCareTask={setAddNewCareTask}
                modalLoading={modalLoading}
                handleChangeChoices={handleChangeChoices}
                choices={choices}
                loadDataOnCareTask={loadDataOnCareTask}
                effective_date={values.effective_date}
              />
            )}
            {modalValue && (
              <PlannedConfirmationModal
                type={'warning'}
                title={'CONFIRM SERVICE CONTRACT DATA'}
                okTitle={'NO, CANCEL'}
                onCancelTitle={'YES, PROCEED'}
                icon={ERROR_OUTLINE}
                hideOnCancel={false}
                onCancel={hideAllService}
                setModalValue={setModalValue}
                handleSubmit={handleSubmit}
                fullBind={fullBindBulkAlert}
                effective_date={values.effective_date}
              />
            )}
            <ModalDecision
              type="delete"
              title="Warning"
              body={
                'Are you sure you want to delete this task? \n DELETING the task will remove ALL records from database.'
              }
              onOk={handleDeleteContract}
              onCancel={() => {
                setIdSelected('');
              }}
              okTitle={'DELETE'}
              {...bindModalDelete}
            />
          </div>
        </>
      </ComponentDynamic>
    </div>
  );
};

export default ClientTask;