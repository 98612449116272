import React from 'react';
import {PrimaryButtonForm} from 'shared/styles/buttons';
import {
  TitleSection,
  FlexEqual,
} from 'components/DashboardComponents/Team/AddModal/styles';

function DynamicList(props) {
  const addNewRow = () => {
    props.addNew();
  };
  const saveRow = () => {
    props.save();
  };

  return (
    <FlexEqual>
      <TitleSection>{props.title}</TitleSection>
      <div className="d-flex  flex-column mt-2">{props.children}</div>

      <div className="d-flex mb-2">
        {props.titleAdd && <PrimaryButtonForm minWidth="6rem" onClick={addNewRow}>
          {props.titleAdd}
        </PrimaryButtonForm>}
        {props.noSave ? 
          <></> 
        : 
          <div className="ms-3">
            <PrimaryButtonForm minWidth="6rem" onClick={saveRow}>
              {props.titleSave}
            </PrimaryButtonForm>
          </div>
        }
      </div>
    </FlexEqual>
  );
}

export default DynamicList;
