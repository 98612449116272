import React,{useState,useEffect,Fragment} from 'react';
import {
    ModalContainer,
    FlexGrid3Item,
    ErrorMessage,
  } from 'shared/styles/constants/tagsStyles';
  import {
    ERROR,
    InvalidOrEmptyValues,
    SAVE,
    SAVED,
  } from 'utils/constants/messages';
  import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
  import {CHECK, CROSS, FLOPPY} from 'utils/constants/icons';
  import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
  import {AssignAllVisits,setShadowVisits, setTravelRate} from "utils/api/SchedulerApi"
  import {useForm} from 'hooks/FormHook';
  import {PrimaryButtonForm} from 'shared/styles/buttons';

const AssignTravelRate = ({
    fullBind,
    employees,
    checkedRows,
    searchScheduler,
    choices,
    travelRate,
}) => {
    const [addFullBind, setAddFullBind] = useState(fullBind);
    const {values, setValues, useInput,errors,setCheckRequires} = useForm({});
    const [errorMessage,setErrorMessage]=useState('')
    const [textStatus, setTextStatus] = useState(SAVE);
    const [loadingSave, setLoadingSave] = useState(false);
    const [saveButtonIcon, setSaveButtonIcon] = useState(FLOPPY);
  

    useEffect(() => {
        setAddFullBind({
          ...addFullBind,
          title: 'Travel Rate'
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [fullBind]);

    const dropDownHandler=(item,nameItem)=>{
        values[nameItem] = item[nameItem];
        setValues({...values});
      }

      const handleSubmit=()=>{
        if(errors.length>0 || loadingSave){
            setCheckRequires(true)
            setErrorMessage(InvalidOrEmptyValues);
            return
          }
          setLoadingSave(true)
          setErrorMessage('');
          let body= {
            detail_ids:[...checkedRows],
            travel_mode_id: values.travel_mode,
            rate_card_id:values.travel_rate_card
          }
          setTravelRate(body)
         .then(res=>{
           if(res.statusCode==400){
             throw res.message
           }
          setSaveButtonIcon(CHECK);
          setTextStatus(SAVED);
          setLoadingSave(false);
          //closeAssignModal()
          setErrorMessage('')
          //setActionType('')
          searchScheduler()
        })
        .catch(err=>{
          setSaveButtonIcon(CROSS);
          setTextStatus(ERROR);
          setLoadingSave(false);
          setErrorMessage(err)
          // closeAssignModal()
        })
      
      }

    return (
        <ModalContainer max_width={'60%'}>
                <ModalDynamic
        {...addFullBind}
        max_width={'60%'}
        minWidthTitle="6.5rem"
        loading={false}
        >
               <div className={' ps-3 pe-3 pb-3'}>
    <div className="mb-2">
            <div className='d-flex'> 
             <FlexGrid3Item className="ps-1 pe-0 pe-md-1 mb-2">
             <DropdownSearchable
             required
               placeHolder={'Travel method'}
               //disabled={readOnly}
               options={choices.travel_type}
               selectedOption={values.travel_mode}
               onChange={value =>
                dropDownHandler({travel_mode: value ? value : null},'travel_mode')
               }
             />
           </FlexGrid3Item>
            <FlexGrid3Item>
              <DropdownSearchable
              //disabled={readOnly}
                placeHolder={'Travel Rate'}
                options={travelRate}
                selectedOption={
                  values.travel_rate_card
                }
                onChange={value =>
                  dropDownHandler(
                    {
                      travel_rate_card: value ? value : null,
                    },'travel_rate_card'
                  )
                }
              />
            </FlexGrid3Item>
           </div>
        </div>
        <div
            className={
              'd-sm-block d-md-flex text-center text-md-right justify-content-end mt-5'
            }>
            <div className={'mb-3'}>
              {errorMessage ? (
                <ErrorMessage>
                  <label className={'mt-2'}>{errorMessage}</label>
                </ErrorMessage>
              ) : (
                <Fragment />
              )}
            </div>

            <div
              className={
                'd-flex justify-content-center justify-content-md-end'
              }>
                
              <div className={'ms-0 ms-md-3'}>
                <PrimaryButtonForm minWidth="6rem" 
                onClick={handleSubmit}
                
                >
                <span className={'me-2'}>{textStatus}</span>
                    {!loadingSave ? (
                      saveButtonIcon
                    ) : (
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                      />
                    )}
                </PrimaryButtonForm>
              </div>
            </div>
          </div>
    </div>
            </ModalDynamic> 
        </ModalContainer>
    );
}

export default AssignTravelRate;
