import React,{useState,useEffect,useContext} from 'react';
import {Header, SearchAddContainer
  } from 'shared/styles/constants/tagsStyles';
  import {FloatingMobileButton} from 'shared/styles/buttons';
  import { headerColumns } from './headerColumnsList';
  import Table from 'components/SharedComponents/Table';
  import {AppContext,BranchItemsContext} from "context"
  import {FaPlus} from 'react-icons/fa';
  import Pagination from 'components/SharedComponents/Pagination';
  import ModalDecision from 'components/SharedComponents/ModalDecision';
  import {PAGE_SIZE_RENDER} from 'utils/constants/pagination';
  import {useModal} from 'hooks/ModalHook';
  import AddModal from './AddModal'
  import {RiDeleteBinLine} from 'react-icons/ri';
  import {getImpContacts,deleteImpContact} from 'utils/api/SettingsApi'
  import {PAGINATION_PARAM} from 'utils/constants/queryParams';
  import queryString from 'query-string';
  import {MdEdit} from 'react-icons/md';
  import {useForm} from 'hooks/FormHook';
  import {useHistory, useLocation} from 'react-router-dom';
  import {
    Row,
    Col,
    IconWrapper,
    ColsGrouper,
  } from 'components/SharedComponents/Table/styles';
  import {LARGE_COL} from 'shared/styles/constants/columns';
import { setting_client_important_contact_choices } from 'utils/choiceConstant';
const ImportantContact = ({
    location,
}) => {
    const {values, setValues,useInput,errors,isValid, setCheckRequires} = useForm({});
    const[numberValue,setNumberValue]=useState() ; 
    const [currentPage, setCurrentPage] = useState(1); 
    const [idSelected, setIdSelected] = useState('');
    const [impContacts,setImpContacts]=useState([])
    const [pages, setPages] = useState(0);
    const {setValue: setModalDeleteValue, bind: bindModalDelete} = useModal(
      false,
    );
    const {contextChoices:choices}=useContext(AppContext)
    const {branchData}=useContext(BranchItemsContext) ;
    const fullNameCol="full_name" ;
    const history=useHistory()
    const {handleGetChoices} = useContext(AppContext)
    useEffect(()=>{
      handleGetChoices(setting_client_important_contact_choices)
    },[])
    const {
        setValue: setModalValue,
        value: modalValue,
        setTitle,
        fullBind,
      } = useModal(false);

      const handleAddContact = () => {
        setIdSelected('');
        handleModal();
      };

      const handleModal = () => {
        setModalValue(!modalValue);
        setValues({})
      };

      useEffect(()=>{
        let limitperpage={};
        values.limitperpage =PAGE_SIZE_RENDER;
        setValues({...values})
        },[PAGE_SIZE_RENDER])

    useEffect(()=>{
        if(numberValue == 0){
        values.ordering='-funder_name';
        setValues({...values});
        }
        },[numberValue])


        useEffect(()=>{
          const urlParams = queryString.parse(location.search);
    urlParams[PAGINATION_PARAM] = 1;
    const stringified = queryString.stringify(urlParams);
    history.push({
      pathname: "/settings/client/important-contact",
      search: stringified,
    });
          loadImpContacts()
        },[values.limitperpage])



        useEffect(()=>{
            loadImpContacts()
          },[values.ordering,
            location.search,
            ])

    const setQuerySort = querySort => {
        setValues({...values, ...querySort});
      };

      const getPageToSearchParam = searchQuery => {
        const urlParams = queryString.parse(searchQuery);
        let offset = urlParams[PAGINATION_PARAM]
          ? urlParams[PAGINATION_PARAM] - 1
          : 0;
        setCurrentPage(offset + 1);
        offset =  offset * values.limitperpage; return {
          offset,
        };
      };
      const handleDeleteContact = async () => {
        await deleteImpContact(idSelected);
        setModalDeleteValue(false);
        setIdSelected('');
        loadImpContacts()
      };

      const handleRowClick = id => {
        setIdSelected(id);
        setModalValue(true);
      };
      const toDeleteContact = id => {
        setModalDeleteValue(true);
        setIdSelected(id);
      };
      const changeNumber=(value)=>{
        setNumberValue(value);
      }

      const loadImpContacts=()=>{
        const pageToSearch = getPageToSearchParam(location.search);
        getImpContacts({...branchData,...values,...pageToSearch},values.limitperpage)
        .then(response=>{
          setImpContacts(response.results || []);
            setPages(Math.ceil(response.count /  values.limitperpage));
        })
      }

      const getName=(name,id)=>{
        let obj=choices && choices[name] && choices[name].find(ele=>ele.id==id)
        if(obj){
          return obj.name
        }
        else{
          return ''
        }
      }

      const dropDownHandleSelectPagination=(item,namesection)=>{
        values.limitperpage=item[namesection]
        setValues({...values});
      }


    return (
        <div>
          <Header>
            {/* <DropdownTypesContainer className="me-2">
             <NoSelectAllDropdownFilter
                    items={choices.branch ? choices.branch : []}
                    setItemsSelected={items => {
                      dropDownHandleSelect({branch: items});
                    }}
                    itemsSelected={branchFilter}
                    setSelectedFilter={setFilterBranch}
                    placeHolder={'Branch'}
                    isObject
                    allOption
                    // hitApiAfterClear={hitApiAfterClear}    
                  />
          </DropdownTypesContainer> */}
          
        <div className="d-flex align-items-center" />
        <SearchAddContainer className="d-flex mb-2 align-items-center">
        {/* <SearchIcon
            onClick={() => {
              setShowSearch(!showSearch);
            }}>
            <GrSearch />
          </SearchIcon> */}

          {/* <SearchContainer show={showSearch}>
            <Fade collapse when={showSearch}>
            <InputLabelTop
                no_internal_margin="true"
                type="text"
                id="search"
                label={'Search'}
                // {...useInput('search')}
                // onBlur={() => {
                //   search();
                // }}
                // onKeyPress={e => {
                //   keyPressed(e);
                // }}       
                onChange={(e)=>{
                  keyPressed(e)
              }
            }
              value={searchKeyword}
              />
            </Fade>
          </SearchContainer> */}
          {/* {(filterAdmin || filterSuperAdmin || createFunderSettings) && */}
           <FloatingMobileButton 
           onClick={handleAddContact}
           >
           <FaPlus />
           <label>NEW CONTACT</label>
         </FloatingMobileButton>
          {/* }
          */}
        </SearchAddContainer>
      </Header>  
      <Table 
   headerColumns={headerColumns}
   headerPadding={false}
   fontSize="0.75rem"
    callBackQuerySort={setQuerySort}
    statusNumber={numberValue}   
    setStatusNumber={changeNumber}   
      fullNameCol={fullNameCol}
    >
   <div>
     {impContacts && impContacts.length>0 && impContacts.map((contact, index) => {
       return (
        <div key={index}>
        <Row bgColor>
          <ColsGrouper
            className="d-flex"
            onClick={() => {
              handleRowClick(contact.id);
            }}>
            <Col max_width={LARGE_COL} Center className="sm-hidden">
              {contact.full_name}
            </Col>
            <Col
              max_width={LARGE_COL}
              Center
              className="sm-hidden">
           {
           contact.contact_type &&
          getName('care_contact_type',contact.contact_type)
          }
            </Col>
            <Col
              max_width={LARGE_COL}
              Center
              className="sm-hidden">
         {contact.contact_status  &&
           getName('contact_status',contact.contact_status)
          }
            </Col>
            <Col max_width={LARGE_COL} Center className="sm-hidden">
             {contact.mobile}
            </Col>
            <Col max_width={LARGE_COL} Center className="sm-hidden">
             {contact.email}
            </Col>
           
            {/* {(( (filterAdmin == true) || (filterSuperAdmin ==true) ) || ((userStaff == true)&& (updateFunderSettings)))  && (  */}
            <Col className="sm-hidden" Center Icon Purple>
              <IconWrapper
                onClick={() => {
                  handleRowClick(contact.id);
                }}>
                <MdEdit />
              </IconWrapper>
            </Col>
                  {/* )} */}
          </ColsGrouper>
          {/* { ( ( (filterAdmin == true) || (filterSuperAdmin ==true) )    || ((userStaff == true)&&(deletePermissionFunderSettings) ) )  && (  */}
          <Col className="sm-hidden" Center Shrink NoFlexGrow>
            <IconWrapper
              onClick={() => {
                toDeleteContact(contact.id);
              }}>
              <RiDeleteBinLine />
            </IconWrapper>
          </Col>
               {/* )}  */}
        </Row>
        <div className="mb-1" />
      </div>
       );
     })}
     <div className="mt-3">
       <Pagination totalPages={pages} 
       values={values}   
       dropDownHandleSelect={dropDownHandleSelectPagination}     
       number={PAGE_SIZE_RENDER} 
       currentPage={currentPage} 
       dataLength={impContacts.length}/>
     </div>
   </div>
 </Table>

 {!modalValue ? null : (
        <AddModal
          idSelected={idSelected}
          choices={choices}
          values={values}
          setValues={setValues}
          isValid={isValid}
          errors={errors}
          useInput={useInput}
        //   readOnly={(filterAdmin || filterSuperAdmin)?false
        //   :(readPermissionFunderSettings && !updateFunderSettings && !createFunderSettings)
        //   ?true:false
        //   }
          fullBind={fullBind}
          setTitle={setTitle}
          setIdSelected={setIdSelected}
          setCheckRequires={setCheckRequires}
          loadImpContacts={loadImpContacts}
        />
      )}
<ModalDecision
        type="delete"
        title="Warning"
        body={
          'Are you sure you want to delete this Contact? \n DELETING the Contact will remove ALL records from database.'
        }
        subBody={
          ''
        }
        onOk={handleDeleteContact}
        onCancel={() => {
          setIdSelected('');
        }}
        okTitle={'DELETE'}
        {...bindModalDelete}
      />
        </div>
    );
}

export default ImportantContact;
