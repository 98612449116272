import React from 'react'
import { LoadingContainer } from '../ModalDynamic/styles'
import Spinner from 'components/SharedComponents/Spinner'
import { Toast } from 'primereact/toast'
import { Title } from 'shared/styles/constants/tagsStyles'
import { useState } from 'react'
import { useRef } from 'react'
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination'
import { useForm } from 'hooks/FormHook'
import { useEffect } from 'react'
import { getAuditRecords } from 'utils/api/DashboardApi'
import moment from 'moment'
import { WHITE } from 'shared/styles/constants/colors'
import './style.css'
import RenderTimeLine from './TimeLine'
import { NoDataFoundMessage } from '../Location/AddLocationModal/styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { NoPermissionContainer } from '../Team/AddModal/styles'

function DashboardAudit({ permission }) {
    const toast = useRef(null)
    // console.log("DashboardInsights")

    const { values, setValues } = useForm({
        limitperpage: PAGE_SIZE_RENDER,
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD")
    });

    // const { handleGetChoices, contextChoices } = useContext(AppContext)

    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState({
        show: false,
        msg: ""
    });
    const [listOfAudit, setListOfAudit] = useState([])
    const [page, setPage] = useState(0)
    const [isNext, setIsNext] = useState(true);
    const [seletedCard, setSeletedCard] = useState({});





    useEffect(() => {
        getListOfAudit()
    }, [])



    const getbackGrouncolor = (category) => {
        switch (category) {
            case "visit":
                return '#dab6c5'
            default:
                return '#9f9f9f'
        }
    }



    const getListOfAudit = async (c_page = false, index = null) => {
        if (loading) return;
        setLoading(true)
        setErrMsg({
            show: false,
            msg: ""
        })
        // if (new Date(values.start_date) > new Date(values.end_date)) {
        //     setErrMsg({
        //         show: true,
        //         msg: "Invalid date: start date must be less than end date!"
        //     })
        //     setLoading(false)
        //     return;
        // }
        getAuditRecords({ ...values }, c_page ? 0 : page, index,)
            .then(response => {
                let data = response?.results?.map(val => {
                    return ({
                        ...val,
                        backgroundColor: getbackGrouncolor(val?.category),
                        textColor: WHITE,
                        constraint: {
                            resourceIds: [val.id],
                        },
                    });
                });
                let newData = [...data]
                setListOfAudit(prev => [...prev, ...newData.flat()])
                setPage(prev => prev + 20)
                newData?.length > 0 ? setIsNext(true) : setIsNext(false);
                setLoading(false)
            })
            .catch(error => {
                console.error("Error: fetching insights api", error)
                setErrMsg({
                    show: true,
                    msg: "Something went wrong. Please try again later."
                })
                setLoading(false)
            })
    }

    // const handleSerch = () => {
    //     setPage(0)
    //     setListOfInsights([])
    //     getListOfInsights(true)
    // }



    const dropDownHandleSelect = item => {
        setValues({ ...values, ...item });
    };


    console.log(values, seletedCard, "listOfInsites")

    return (
        <>
            <div className='audit'>

                <Toast ref={toast} position="top-right" />
                <>
                    <div className="filter-container">
                        <Title className=''>Audits</Title>
                        {/* <div className='mt-2 d-flex flex-wrap'>
                            <DropdownTypesContainer className="mr-2">
                                <DateSelector
                                    typeFilter={true}
                                    labelTop="Date From"
                                    value={values.start_date ? values.start_date : ''}
                                    {...startDateHook}
                                />
                            </DropdownTypesContainer>
                            <DropdownTypesContainer className="mr-2">
                                <DateSelector
                                    typeFilter={true}
                                    labelTop="Date To"
                                    value={values.end_date ? values.end_date : ''}
                                    {...endDateHook}
                                />
                            </DropdownTypesContainer>
                            <PrimaryButtonForm
                                minWidth="6rem"
                                onClick={() => handleSerch()}
                            >
                                Search
                            </PrimaryButtonForm>
                        </div> */}
                    </div>
                    {permission ? (
                        <div className='pie_chart_box p-4' style={{ minHeight: '75vh' }}>
                            {errMsg.show && (
                                <NoDataFoundMessage top={"14%"} style={{ marginTop: "100px" }} >
                                    <h1 style={{ fontSize: '1rem' }}>{errMsg.msg}</h1>
                                </NoDataFoundMessage>
                            )}
                            {!errMsg.show && listOfAudit && (
                                listOfAudit.length > 0 ? (
                                    <InfiniteScroll
                                        dataLength={listOfAudit.length}
                                        next={getListOfAudit}
                                        hasMore={isNext}
                                        loader={
                                            <LoadingContainer>
                                                <Spinner />
                                            </LoadingContainer>}
                                    >
                                        {listOfAudit.map((item, index) => {
                                            return (
                                                <RenderTimeLine
                                                    toast={toast}
                                                    // choices={contextChoices}
                                                    item={item}
                                                    index={index}
                                                    data={listOfAudit}
                                                    setDate={setListOfAudit}
                                                    setSeletedCard={setSeletedCard}
                                                // setMarkReview={setMarkReviewModal}
                                                />
                                            )
                                        })}
                                    </ InfiniteScroll>
                                ) :
                                    (<NoDataFoundMessage top={"14%"} style={{ marginTop: "100px" }} >
                                        <h1 style={{ fontSize: '1rem' }}>No data found</h1>
                                    </NoDataFoundMessage>)
                            )
                            }
                        </div>
                    ) : (
                        <NoPermissionContainer>
                            You don't have permission to access the information.
                        </NoPermissionContainer>
                    )
                    }
                </>

            </div>


        </>)
}

export default DashboardAudit