import ComponentDynamic from 'components/DashboardComponents/ComponentDynamic'
import Table from 'components/SharedComponents/Table';
import { Col, ColsGrouper, Row } from 'components/SharedComponents/Table/styles';
import React from 'react'
import { useState } from 'react'
import { EX_LARGE_COL, LARGE_COL, MEDIUM_COL } from 'shared/styles/constants/columns';
import "./styles.css"
import { CloseButtonWrapper, DropdownTypesContainer, FiltersContainer, Header } from 'shared/styles/constants/tagsStyles';
import { PrimaryButton } from 'shared/styles/buttons';
import { EmploymentTypesContainer } from 'components/DashboardComponents/Team/styles';
import DateSelector from 'components/SharedComponents/DateSelector';
import moment from 'moment';
import { PLUS, TIMES } from 'utils/constants/icons';
import { useForm } from 'hooks/FormHook';
import { useEffect } from 'react';
import { getClientExpense } from 'utils/api/ClientApi';
import { useContext } from 'react';
import { AppContext } from 'context';
import { client_expense_choices } from 'utils/choiceConstant';
import Pagination from 'components/SharedComponents/Pagination';
import { PAGE_SIZE_RENDER } from 'utils/constants/pagination';
import { getPageToSearchParam } from 'shared/methods';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';

const headerColumns = [
  {
    label: 'DATE',
    name: 'pay_adjustment_date',
    status: 2,
    className: '',
    max_width: LARGE_COL,
  },
  {
    label: 'EMPLOYEE NAME',
    name: '',
    status: 0,
    className: '',
    max_width: LARGE_COL,
  },
  {
    label: 'ADJUSTMENT TYPE',
    name: '',
    status: 0,
    className: 'sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'PAY ADJUSTMENT',
    name: '',
    status: 0,
    className: 'sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'PAY ADJUSTMENT DESCRIPTION',
    name: '',
    status: 2,
    className: 'sm-hidden md-hidden',
    max_width: EX_LARGE_COL,
  },
  {
    label: 'AUTHORIZATION DESC.',
    name: '',
    status: 2,
    className: 'sm-hidden md-hidden',
    max_width: EX_LARGE_COL,
  },
  {
    label: 'STATUS',
    name: '',
    status: 2,
    className: 'sm-hidden md-hidden',
    max_width: MEDIUM_COL,
  },
  {
    label: 'AMOUNT',
    name: '',
    status: 2,
    className: 'sm-hidden md-hidden',
    max_width: LARGE_COL,
  },
];
function Expense({ clientId, props, readPermissionClientExpense }) {
  const toast = useRef(null)
  const endDate = moment().format('YYYY-MM-DD');
  // const startDate = moment(`${moment().format('YYYY')}-${moment().format('M')}-01`, 'YYYY-MM-DD').format('YYYY-MM-DD');
  const thirtyDaysAgo = moment().subtract(30, 'days').format('YYYY-MM-DD');
  const { values, setValues, useInput } = useForm({ start_date: thirtyDaysAgo, end_date: endDate, ordering: '-pay_adjustment_date' });
  const { handleGetChoices, contextChoices: choices } = useContext(AppContext)
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);


  const [loading, setLoading] = useState(true)
  const [expenseList, setExpenceList] = useState([])


  useEffect(() => {
    handleGetChoices(client_expense_choices)
  })


  const getClientExpenseList = async () => {
    const pageToSearch = getPageToSearchParam(
      props.location.search,
      setCurrentPage,
    )
    try {
      const res = await getClientExpense(clientId, { ...values, ...pageToSearch })
      setExpenceList(res.results)
      setPages(Math.ceil(res.count / values?.limitperpage));
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSearch = () => {
    if(values.end_date && values.start_date)
    if (new Date(values.end_date) >= new Date(values.start_date)) {
      getClientExpenseList()
    } else {
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Start date should be greater than end date !`,
      });
    }else{
      toast.current.show({
        severity: 'error',
        summary: 'Failed',
        detail: `Please apply date range filter for accurate results.`,
      });
    }

  }

  const findFromChoises = (name, id) => {
    if (name == 'employee_listing') {
      const findname = choices?.[name]?.find(item => item.id === id)?.full_name
      return findname || ''
    } else {
      const findname = choices?.[name]?.find(item => item.id === id)?.name
      return findname || ''
    }
  }
  const dropDownHandleSelectPagination = (item, namesection) => {
    values.limitperpage = item[namesection]
    setValues({ ...values });
  }

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };
  useEffect(() => {
    values.limitperpage = PAGE_SIZE_RENDER;
    setValues({ ...values })
  }, [PAGE_SIZE_RENDER])

  let startDateInput = useInput('start_date')
  let endDateInput = useInput('end_date')

  useEffect(() => {
    getClientExpenseList()
  }, [values?.limitperpage, props.location.search, values.ordering])

  return (
    <ComponentDynamic loading={loading}>
      <Toast ref={toast}></Toast>
      {
        readPermissionClientExpense ?
          (
            <>
                <FiltersContainer style={{ marginBottom: '4px' }}>
                  <EmploymentTypesContainer className="me-2 mb-1">
                    <DropdownTypesContainer>
                      <DateSelector
                        typeFilter={true}
                        labelTop="Date from"
                        {...startDateInput}
                      />
                    </DropdownTypesContainer>
                  </EmploymentTypesContainer>

                  <EmploymentTypesContainer className="me-2 mb-1">
                    <DropdownTypesContainer>
                      <DateSelector
                        typeFilter={true}
                        labelTop="Date to"
                        {...endDateInput}
                      />
                    </DropdownTypesContainer>
                  </EmploymentTypesContainer>
                  <PrimaryButton
                    type="button"
                    onClick={handleSearch}
                    style={{ minWidth: '6rem' }}>
                    <span className={'font-weight-bold'}>Search</span>
                  </PrimaryButton>
                </FiltersContainer>


              {expenseList.length ? (
                <div className='client-expense-table'>
                  <Table
                    headerColumns={headerColumns}
                    callBackQuerySort={setQuerySort}
                    headerPadding={false}
                    noNeed={false}
                    noEditOption
                  >
                    <div>
                      {expenseList.map(item => {
                        return (
                          <Row key={Math.random()} bgColor style={{ marginTop: '5px', marginBottom: '5px' }}>
                            <ColsGrouper className="d-flex">
                              <Col
                                max_width={LARGE_COL}
                                Center
                                className=""
                                overlap={true}>
                                <label>{item.pay_adjustment_date}</label>

                              </Col>
                              <Col
                                max_width={LARGE_COL}
                                Center
                                className=""
                                overlap={true}>
                                <label>{findFromChoises('employee_listing', item.employee)}</label>

                              </Col>
                              <Col
                                max_width={LARGE_COL}
                                Center
                                className="sm-hidden"
                                overlap={true}>
                                <label>{findFromChoises('pay_adjustment_type', item.pay_adjustment_type)}</label>

                              </Col>
                              <Col
                                max_width={LARGE_COL}
                                Center
                                className="sm-hidden md-hidden"
                                overlap={true}>
                                <label>{findFromChoises('pay_adjustment', item.pay_adjustment)}</label>

                              </Col>
                              <Col
                                // style={{ marginRight: '10px' }}
                                max_width={EX_LARGE_COL}
                                Center
                                className="sm-hidden md-hidden me-3"
                                overlap={true}>
                                <label>{item.pay_adjustment_desc}</label>

                              </Col>
                              <Col
                                // style={{ marginRight: '10px' }}
                                max_width={EX_LARGE_COL}
                                Center
                                className="sm-hidden md-hidden me-3"
                                overlap={true}>
                                <label>{item.authorization_description}</label>

                              </Col>
                              <Col
                                // style={{ marginRight: '10px' }}
                                max_width={MEDIUM_COL}
                                Center
                                className="sm-hidden md-hidden me-3"
                                overlap={true}>
                                <label>{item.status}</label>

                              </Col>
                              <Col
                                // style={{ marginRight: '10px' }}
                                max_width={LARGE_COL}
                                Center
                                className="sm-hidden md-hidden me-3"
                                overlap={true}>
                                <label>{item.amount}</label>

                              </Col>

                            </ColsGrouper>
                          </Row>
                        )
                      })}
                    </div>
                  </Table>
                  <div className="mt-3">
                    <Pagination
                      totalPages={pages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      values={values}
                      dropDownHandleSelect={dropDownHandleSelectPagination}
                      number={PAGE_SIZE_RENDER}
                      dataLength={expenseList.length}
                    />
                  </div>
                </div>
              ) : (
                <div className='d-flex align-items-center justify-content-center w-100'>
                  <h4 style={{ fontSize: "1rem" }}>No data found</h4>
                </div>
              )
              }
            </>
          ) :
          (
            <div className='d-flex align-items-center justify-content-center w-100'>
              <h4 className='' style={{ fontSize: "1rem" }}>No permissions given for expense list access !</h4>
            </div>
          )
      }


    </ComponentDynamic>
  )
}

export default Expense