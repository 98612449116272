import React, { useState, useEffect, Fragment } from 'react';
import InputLabelTop from 'components/SharedComponents/InputLabelTop';
import {
  FlexGrid3Container,
  FlexGrid3Item,
} from 'shared/styles/constants/tagsStyles';
import {
  ModalContainer
} from 'shared/styles/constants/tagsStyles';
import ModalDynamic from 'components/DashboardComponents/ModalDynamic';
import moment from 'moment';
import DateSelector from 'components/SharedComponents/DateSelector';
import DropdownSearchable from 'components/SharedComponents/DropdownSearchable';
import {
  EVERY_DAY,
  EVERY_WEEK,
  EVERY_MONTH,
  EVERY_YEAR,
  END_TYPE_AFTER,
  END_TYPE_ON,
  END_TYPE_NEVER,
} from 'utils/constants/serviceVisits';
import { KeyPress } from 'utils/constants/regularExpressions';
import { PrimaryButtonForm } from 'shared/styles/buttons';
import {
  ErrorMessage
} from 'shared/styles/constants/tagsStyles';
import Table from 'components/SharedComponents/Table';
import {
  FlexGrow,
  ModalTitle,
} from 'components/DashboardComponents/ModalDynamic/styles'
import {
  EX_SMALL_COL4,
  EX_SMALL_COL,
  SMALL_COL,
  MEDIUM_COL,
  LARGE_COL,
  EX_LARGE_COL,
} from 'shared/styles/constants/columns';
import {
  Row,
  Col,
  ColsGrouper,
} from 'components/SharedComponents/Table/styles';


const headerRunColumns = [
  {
    label: 1,
    checkbox: true,
    name: 'checkbox',
    status: 0,
    className: 'grow-mobile',
    max_width: EX_SMALL_COL4,
  },
  {
    label: 'Visits',
    name: 'repeat_on',
    status: 0,
    className: 'm-0 p-0  md-hidden',
    max_width: SMALL_COL,
  },
  {
    label: 'Requirement',
    name: 'requirement',
    status: 0,
    className: 'm-0 p-0  sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Start-End Time',
    name: 'time',
    status: 0,
    className: 'm-0 p-0  sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Repeat',
    name: 'repeat',
    status: 0,
    className: 'm-0 p-0   md-hidden',
    max_width: SMALL_COL,
  },
  {
    label: 'Start Date',
    name: 'start_date',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: MEDIUM_COL,
  },
  {
    label: 'End Date',
    name: 'end_date',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: MEDIUM_COL,
  },
  {
    label: 'Client Name',
    name: 'client_name',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Employee Name',
    name: 'employee_name',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Shift Name',
    name: 'shift_name',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: LARGE_COL,
  },
  {
    label: 'Status',
    name: 'status',
    status: 0,
    className: 'm-0 p-0   sm-hidden',
    max_width: MEDIUM_COL,
  },


];



const CreateEmployeeRun = ({
  useInput,
  values,
  setValues,
  choices,
  errorMessage,
  calculateEndValueProps,
  switchEndValueInput,
  dropDownHandleRegVisit,
  dataValues,
  dropDownHandleSelect,
  newRun,
  textStatus,
  fullBind,
  handleSaveVisitRun,
  saveButtonIcon,
  loadingSave,
  runData
}) => {

  const setQuerySort = querySort => {
    setValues({ ...values, ...querySort });
  };

  const [addFullBind, setAddFullBind] = useState(fullBind);

  useEffect(() => {
    setAddFullBind({
      ...addFullBind,
      title: 'Employee Run',
    });
  }, [fullBind]);

  useEffect(() => {
    DefaultAllSelect()
  }, [runData])

  const DefaultAllSelect = () => {
    if (!values.client_pinned_employee_day_id) {
      values.client_pinned_employee_day_id = [];
    }
    runData.map((visit) => {
      values.client_pinned_employee_day_id.push(visit.client_pinned_employee_day_id);
    })
    setValues({ ...values })
  }

  const handleSelectChange = (valcheck, visit, selectedIndex) => {
    if (!values.client_pinned_employee_day_id) {
      values.client_pinned_employee_day_id = [];
    }
    if (valcheck.target.checked) {
      values.client_pinned_employee_day_id.push(visit.client_pinned_employee_day_id)
    } else {
      values.client_pinned_employee_day_id.map((item, index) => {
        if (item === visit.client_pinned_employee_day_id) {
          values.client_pinned_employee_day_id.splice(index, 1);
        }
      })
    }
    setValues({ ...values })
  }

  const handleCheck = (visit) => {
    let check = false;
    if (values.client_pinned_employee_day_id) {
      values.client_pinned_employee_day_id.map((item) => {
        if (visit.client_pinned_employee_day_id === item) {
          check = true
        }
      })
    }
    return check;
  }

  const handleAllSelectChange = (valcheck) => {
    if (!values.client_pinned_employee_day_id) {
      values.client_pinned_employee_day_id = [];
    }
    if (valcheck.target.checked) {
      runData.map((visit) => {
        values.client_pinned_employee_day_id.push(visit.client_pinned_employee_day_id);
      })
    } else {
      runData.map((visit) => {
        values.client_pinned_employee_day_id.map((item, index) => {
          if (item === visit.client_pinned_employee_day_id) {
            values.client_pinned_employee_day_id.splice(index, 1);
          }
        })
      })
    }
    setValues({ ...values })
  }

  const handleAllCheck = () => {
    let checkAll = true;

    const visitExists = (id) => {
      return values.client_pinned_employee_day_id.some(function (el) {
        return el === id;
      });
    }
    if (values.client_pinned_employee_day_id && values.client_pinned_employee_day_id.length > 0) {
      runData.map((visit) => {
        if (!visitExists(visit.client_pinned_employee_day_id)) {
          checkAll = false
        }
      })
    } else {
      checkAll = false;
    }
    return checkAll;
  }


  return (
    <ModalContainer max_width={'80%'}>
      <ModalDynamic
        {...addFullBind}
        max_width={'80%'}
        minWidthTitle="8.5rem"
        loading={false}>
        <div className={' ps-3 pe-3 pb-3'}>
          <div className={'mb-2'}>
            <DropdownSearchable
              required
              placeHolder={'Select Run Name'}
              {...useInput(`values.run_name_id`, 'isRequired')}
              options={dataValues}
              selectedOption={values.run_name_id}
              onChange={value =>
                dropDownHandleSelect({ run_name_id: value ? value : null })
              }
            />
          </div>
          <FlexGrid3Container>
            <FlexGrid3Item className="pe-1 ps-0 pb-2 pb-md-0 mb-3">
              <DateSelector
                //   disabled={readOnly}
                isRequired
                // data-error={dateError}
                errorMessage={errorMessage}
                labelTop={'Service start date'}
                {...useInput(`start_date`, 'isRequired')}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1 ps-0 pb-2 pb-md-0 mb-3">
              <DateSelector
                //   disabled={readOnly}
                // data-error={dateError}
                errorMessage={errorMessage}
                labelTop={'Service end date'}
                {...useInput(`end_date`)}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1 ps-0 pb-2 pb-md-0 mb-3">
              <DropdownSearchable
                required
                {...useInput(`every`, 'isRequired')}
                placeHolder={'Repeat Every'}
                options={[
                  { id: EVERY_DAY, name: 'Day' },
                  { id: EVERY_WEEK, name: 'Week' },
                  { id: EVERY_MONTH, name: 'Month' },
                  { id: EVERY_YEAR, name: 'Year' },
                ]}
                selectedOption={
                  values
                    ? values.every
                    : null
                }
                onChange={value =>
                  dropDownHandleRegVisit(
                    { every: value ? value : null },
                    'every'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1 ps-0 pb-2 pb-md-0">
              <InputLabelTop
                //   disabled={readOnly}
                label={'Repeat No.'}
                onKeyPress={values.repeat ? KeyPress.POSITIVE : KeyPress.NATURAL}
                id={`repeat`}
                {...useInput(`repeat`, 'isInt')}
                maxlength={5}
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="pe-1 ps-0 pb-2 pb-md-0">
              <DropdownSearchable
                required
                {...useInput(`end_type`, 'isRequired')}
                // data_error={errors.indexOf(`end_type`)}
                //   disabled={readOnly}
                placeHolder={'End'}
                options={[
                  { id: END_TYPE_ON, name: 'On' },
                  { id: END_TYPE_AFTER, name: 'After' },
                  { id: END_TYPE_NEVER, name: 'Never' },
                ]}
                selectedOption={
                  values
                    ? values.end_type
                    : null
                }
                onChange={value =>
                  dropDownHandleRegVisit(
                    { end_type: value ? value : null },
                    'end_type'
                  )
                }
              />
            </FlexGrid3Item>
            <FlexGrid3Item className="ps-1 mt-0 md-2 pe-0 pe-md-1">
              {switchEndValueInput({
                ...useInput(
                  `end_value`,
                  calculateEndValueProps(
                    values.end_type
                  )
                ),
              })}
            </FlexGrid3Item>
          </FlexGrid3Container>
          <div style={{ maxHeight: '400px', paddingTop: '30px', overflowY: 'auto' }}>
            {runData && (runData.length > 0) &&
              <Table headerColumns={headerRunColumns} callBackQuerySort={setQuerySort} noEditOption headerPadding={false} handleAllSelectChange={handleAllSelectChange}
                handleAllCheck={handleAllCheck} >
                <div>
                  {runData && runData.map((runPin, runIndex) => {
                    return (
                      <div key={runIndex}>
                        <Row bgColor className="mb-1">
                          <ColsGrouper className="d-flex">
                            <Col
                              max_width={EX_SMALL_COL4}
                              Shrink={false}
                              NoFlexGrow={true}
                              reducePadding
                              Capitalize
                              Center
                            >
                              <input class="form-check-input" type="checkbox" checked={handleCheck(runPin)} id="flexCheckDefault" onChange={(val) => { handleSelectChange(val, runPin, runIndex) }} />
                            </Col>
                            <Col
                              max_width={SMALL_COL}
                              Center
                              className='p-2'
                            >
                              {runPin.repeat_on}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              // className='p-2 pe-4'
                              Center
                            >
                              {runPin.requirement}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              className='p-2 pe-4'
                              Center
                            >
                              {runPin.start_time + "-"
                                + runPin.end_time
                              }
                            </Col>

                            <Col
                              max_width={SMALL_COL}
                              className='p-2 pe-4'
                              Center
                            >
                              {runPin.repeat}
                            </Col>
                            <Col
                              max_width={MEDIUM_COL}
                              className='p-2 pe-4'
                              Center
                            >
                              {runPin.start_date}
                            </Col>
                            <Col
                              max_width={MEDIUM_COL}
                              className='p-2 pe-4'
                              Center
                            >
                              {runPin.end_date}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              className='p-2 pe-4'
                              Center
                            >
                              {runPin.client_name}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              className='p-2 pe-4'
                              Center
                            >
                              {runPin.employeeName}
                            </Col>
                            <Col
                              max_width={LARGE_COL}
                              className='p-2 pe-4'
                              Center
                            >
                              {runPin.shift_name}
                            </Col>
                            <Col
                              max_width={MEDIUM_COL}
                              className='pe-4'
                              Center
                            >
                              {runPin.is_suspended ? 'Inactive' : 'Active'}
                            </Col>

                          </ColsGrouper>
                        </Row>
                      </div>
                    );
                  })}

                </div>
              </Table>
            }
          </div>
          <div>
            <FlexGrow>
              <ModalTitle MinWidth={"8.5rem"} className="modal-title" >
                <div className={'d-flex justify-content-center justify-content-md-end p-3'} >
                  <div >
                    {errorMessage ? (
                      <ErrorMessage>
                        <label className={'mt-2'}>{errorMessage}</label>
                      </ErrorMessage>
                    ) : (
                      <Fragment />
                    )}
                  </div>
                  <div className={'ms-0 ms-md-3'}>
                    <PrimaryButtonForm minWidth="6rem" onClick={() => handleSaveVisitRun()} >
                      <span className={'me-2'}>{textStatus}</span>
                      {!loadingSave ? (saveButtonIcon
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm me-1"
                          role="status"
                        />
                      )}
                    </PrimaryButtonForm>
                  </div>
                </div>
              </ModalTitle>
            </FlexGrow>
          </div>
        </div>
      </ModalDynamic>
    </ModalContainer>

  );
}

export default CreateEmployeeRun;
